const getNumberSuffix = (number: string): string => {
  const n = parseInt(number, 10)
  if (n === 1 || n === 21 || n === 31) {
    return 'st'
  }
  if (n === 2 || n === 22) {
    return 'nd'
  }
  if (n === 3 || n === 23) {
    return 'rd'
  }
  return 'th'
}

export default getNumberSuffix
