import type { PurchaseTransfer } from '../../../definitions'
import { PurchaseTransferStatus } from '../../../enums'
import updatePurchaseTransferListItemFromPurchaseTransfer from './updatePurchaseTransferListItemFromPurchaseTransfer'
import migrateVersion from './migrateVersion'

const purchaseTransferHelpers = {
  isPurchaseTransferFinished: (purchaseTransfer: PurchaseTransfer): boolean => (
    !!purchaseTransfer &&
    (
      purchaseTransfer.transferStatus === PurchaseTransferStatus.ACCEPTED ||
      purchaseTransfer.transferStatus === PurchaseTransferStatus.INVALID
    )
  ),
  migrateVersion,
  updatePurchaseTransferListItemFromPurchaseTransfer,
}

export default purchaseTransferHelpers
