import { PurchaseStatus, PurchaseType } from '../../lib/core/enums'
import type { PurchaseListScope } from '../../lib/core/enums'
import type { PurchaseListItem } from '../../lib/core/definitions'
import isPurchaseInScope from './isPurchaseInScope'
import stringHelpers from '../../lib/core/helpers/string'

const filterPurchaseList = (
  list: PurchaseListItem[],
  searchText: string | undefined,
  scope: PurchaseListScope | undefined,
): PurchaseListItem[] => {
  // console.log('PageHelpers.filterPurchaseList called.', { searchText, scope, list })
  return list.filter((p: PurchaseListItem): boolean => {
    if (p.purchaseType !== PurchaseType.MARKETPLACE_PRODUCT) {
      return false
    }
    if (!searchText && !scope) {
      return true
    }
    if (scope && !isPurchaseInScope(
      scope,
      p.balance,
      p.metadata,
      p.status,
      p.recordStatus,
    )) {
      return false
    }
    if (!searchText || !p.merchantName) {
      return true
    }
    return stringHelpers.matchesSearchText(
      p.merchantName,
      searchText,
      2,
      false,
    )
  }).sort((card1: PurchaseListItem, card2: PurchaseListItem) => {
    if (card1.status === PurchaseStatus.RECEIVED && card2.status !== PurchaseStatus.RECEIVED) {
      return -1
    }
    if (card1.status !== PurchaseStatus.RECEIVED && card2.status === PurchaseStatus.RECEIVED) {
      return 1
    }
    if (card1.status === PurchaseStatus.RECEIVED && card2.status === PurchaseStatus.RECEIVED) {
      return parseInt(card1.updatedAt) - parseInt(card2.updatedAt)
    }
    if (card1.merchantName === card2.merchantName) {
      return card1.balance - card2.balance
    }
    return (card1.merchantName as string).localeCompare(card2.merchantName as string)
  })
}

export default filterPurchaseList
