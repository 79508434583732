import React from 'react'

import { TransactionProcessor } from '../../../lib/core/enums'
import DeletePendingOrderSection from './DeletePendingOrderSection'
import LegalSection from '../LegalSection/LegalSection'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'

interface Props {
  isActiveShoppingCart: boolean | undefined
  isProcessing: boolean | undefined
  onCreatePaymentRequest: () => void
  onDeletePendingOrder: () => void
}

const CreatePaymentRequestSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    isActiveShoppingCart,
    isProcessing,
    onCreatePaymentRequest,
    onDeletePendingOrder,
  } = props

  return (
    <div className='withDoubleTopMargin'>
      <div className='withDoubleBottomMargin'>
        <div className='sectionCaption'>Create Crypto Payment Request</div>
        When you create a crypto payment request we will display
        a QR code you can scan in with your crypto wallet.
      </div>
      <LegalSection
        transactionProcessor={TransactionProcessor.COINPAYMENTS}
      />
      <DeletePendingOrderSection
        isActiveShoppingCart={isActiveShoppingCart}
        onDeletePendingOrder={onDeletePendingOrder}
      />
      <div className='withStandardBottomMargin'>
        <SubmitButton
          expand='block'
          onClick={onCreatePaymentRequest}
          isProcessing={isProcessing}
        >
          Create Payment Request
        </SubmitButton>
      </div>
    </div>
  )
}

export default CreatePaymentRequestSection
