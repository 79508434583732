import React, { useEffect, useState } from 'react'
import {
  IonButton,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from '@ionic/react'
import { Editor } from '@tinymce/tinymce-react'

import { AppAction } from '../../../lib/core/enums'
import type { MemberMessageTemplate, MemberMessageTemplateInput } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import FormCheckbox from '../../../components/FormCheckbox/FormCheckbox'
import FormItem from '../../../components/FormItem/FormItem'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../helpers/validationHelpers'

type Props = {
  memberMessageTemplate?: MemberMessageTemplate | null;
  onGoBack: () => void;
  onSave: (memberMessageTemplate: MemberMessageTemplateInput) => void;
  onPreview: (memberMessageTemplate: MemberMessageTemplateInput) => void;
};

const MemberMessageTemplateForm: React.FC<Props> = ({
  memberMessageTemplate = null,
  onSave,
  onPreview,
  onGoBack,
}): JSX.Element => {
  const [name, setName] = useState<string | undefined>()
  const [nameValidationError, setNameValidationError] = useState<string | undefined>()
  const [title, setTitle] = useState<string | undefined>()
  const [titleValidationError, setTitleValidationError] = useState<string | undefined>()
  const [action0, setAction0] = useState<AppAction | undefined>()
  const [action0ValidationError, setAction0ValidationError] = useState<string | undefined>()
  const [action1, setAction1] = useState<AppAction | undefined>()
  const [action1ValidationError, setAction1ValidationError] = useState<string | undefined>()
  const [action2, setAction2] = useState<AppAction | undefined>()
  const [action2ValidationError, setAction2ValidationError] = useState<string | undefined>()

  const [messageTextEn, setMessageTextEn] = useState<string | undefined>()
  const [messageTextEnValidationError, setMessageTextEnValidationError] = useState<string | undefined>()

  const [shortMessageTextEn, setShortMessageTextEn] = useState<string | undefined>()
  const [shortMessageTextEnValidationError, setShortMessageTextEnValidationError] = useState<string | undefined>()

  const [htmlMessageEn, setHtmlMessageEn] = useState<string | undefined>()
  const [htmlMessageEnValidationError, setHtmlMessageEnValidationError] = useState<string | undefined>()

  const [adminNotes, setAdminNotes] = useState<string | undefined>()
  const [adminNotesValidationError, setAdminNotesValidationError] = useState<string | undefined>()

  const [sendInAppMessage, setSendInAppMessage] = useState<boolean | undefined>()
  const [sendInAppMessageValidationError, setSendInAppMessageValidationError] = useState<string | undefined>()

  const [sendChatMessage, setSendChatMessage] = useState<boolean | undefined>()
  const [sendChatMessageValidationError, setSendChatMessageValidationError] = useState<string | undefined>()

  const [sendPushNotification, setSendPushNotification] = useState<boolean | undefined>()
  const [sendPushNotificationValidationError, setSendPushNotificationValidationError] = useState<string | undefined>()

  const [sendSms, setSendSms] = useState<boolean | undefined>()
  const [sendSmsValidationError, setSendSmsValidationError] = useState<string | undefined>()

  const [sendEmail, setSendEmail] = useState<boolean | undefined>()
  const [sendEmailValidationError, setSendEmailValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const nameChanged = name !== undefined && !(memberMessageTemplate && name === memberMessageTemplate.name)
  const titleChanged = title !== undefined && !(memberMessageTemplate && title === memberMessageTemplate.titleEn)
  const action0Changed = action0 !== undefined && !(memberMessageTemplate && action0 === memberMessageTemplate.action0)
  const action1Changed = action1 !== undefined && !(memberMessageTemplate && action1 === memberMessageTemplate.action1)
  const action2Changed = action2 !== undefined && !(memberMessageTemplate && action2 === memberMessageTemplate.action2)
  const messageTextEnChanged = messageTextEn !== undefined && !(memberMessageTemplate && messageTextEn === memberMessageTemplate.messageTextEn)
  const shortMessageTextEnChanged = shortMessageTextEn !== undefined && !(memberMessageTemplate && shortMessageTextEn === memberMessageTemplate.shortMessageTextEn)
  const htmlMessageEnChanged = htmlMessageEn !== undefined && !(memberMessageTemplate && htmlMessageEn === memberMessageTemplate.htmlMessageEn)
  const sendChatMessageChanged = sendChatMessage !== undefined && !(memberMessageTemplate && sendChatMessage === memberMessageTemplate.sendChatMessage)
  const sendEmailChanged = sendEmail !== undefined && !(memberMessageTemplate && sendEmail === memberMessageTemplate.sendEmail)
  const sendInAppMessageChanged = sendInAppMessage !== undefined && !(memberMessageTemplate && sendInAppMessage === memberMessageTemplate.sendInAppMessage)
  const sendPushNotificationChanged = sendPushNotification !== undefined && !(memberMessageTemplate && sendPushNotification === memberMessageTemplate.sendPushNotification)
  const sendSmsChanged = sendSms !== undefined && !(memberMessageTemplate && sendSms === memberMessageTemplate.sendSms)
  const adminNotesChanged = adminNotes !== undefined && !(memberMessageTemplate && adminNotes === memberMessageTemplate.adminNotes)

  const isDirty = (
    nameChanged ||
    titleChanged ||
    action0Changed ||
    action1Changed ||
    action2Changed ||
    titleChanged ||
    messageTextEnChanged ||
    shortMessageTextEnChanged ||
    htmlMessageEnChanged ||
    sendChatMessageChanged ||
    sendEmailChanged ||
    sendInAppMessageChanged ||
    sendPushNotificationChanged ||
    sendSmsChanged ||
    adminNotesChanged
  )
  const isValid = (
    !nameValidationError &&
    !titleValidationError &&
    !action0ValidationError &&
    !action1ValidationError &&
    !action2ValidationError &&
    !messageTextEnValidationError &&
    !shortMessageTextEnValidationError &&
    !htmlMessageEnValidationError &&
    !adminNotesValidationError
  )
  // console.log('MemberMessageTemplateForm.render called.', {
  //   nameValidationError,
  //   action2ValidationError,
  //   adminNotesValidationError,
  //   sourceValidationError,
  //   isDirty,
  //   isValid,
  // });

  const resetForm = (): void => {
    setName(undefined)
    setNameValidationError(undefined)

    setTitle(undefined)
    setTitleValidationError(undefined)

    setAction0(undefined)
    setAction0ValidationError(undefined)

    setAction1(undefined)
    setAction1ValidationError(undefined)

    setAction2(undefined)
    setAction2ValidationError(undefined)

    setMessageTextEn(undefined)
    setMessageTextEnValidationError(undefined)

    setShortMessageTextEn(undefined)
    setShortMessageTextEnValidationError(undefined)

    setHtmlMessageEn(undefined)
    setHtmlMessageEnValidationError(undefined)

    setSendChatMessage(undefined)
    setSendChatMessageValidationError(undefined)

    setSendEmail(undefined)
    setSendEmailValidationError(undefined)

    setSendInAppMessage(undefined)
    setSendInAppMessageValidationError(undefined)

    setSendPushNotification(undefined)
    setSendPushNotificationValidationError(undefined)

    setSendSms(undefined)
    setSendSmsValidationError(undefined)

    setAdminNotes(undefined)
    setAdminNotesValidationError(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('MemberMessageTemplateForm: new memberMessageTemplate received - resetting form.')
    resetForm()
  }, [memberMessageTemplate])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeName = (event: any): void => {
    if (
      (memberMessageTemplate && (event.detail.value === memberMessageTemplate.name || (!event.detail.value && !memberMessageTemplate.name))) ||
      (!memberMessageTemplate && !event.detail.value)
    ) {
      setNameValidationError('')
      setName(undefined)
      return
    }
    setName(event.detail.value)
    if (event.detail.value) {
      // setNameValidationError(validationHelpers.validateMemberMessageTemplateName(event.detail.value))
    } else {
      setNameValidationError('')
    }
  }

  const onChangeTitle = (event: any): void => {
    if (
      (memberMessageTemplate && (event.detail.value === memberMessageTemplate.titleEn || (!event.detail.value && !memberMessageTemplate.titleEn))) ||
      (!memberMessageTemplate && !event.detail.value)
    ) {
      setTitleValidationError('')
      setTitle(undefined)
      return
    }
    setTitle(event.detail.value)
    if (event.detail.value) {
      // setTitleValidationError(validationHelpers.validateMemberMessageTemplateName(event.detail.value))
    } else {
      setTitleValidationError('')
    }
  }

  const onChangeMessageTextEn = (event: any): void => {
    const newMessageTextEn = event.detail.value
    // console.log('onChangeMessageTextEn: newMessageTextEn=', newMessageTextEn)
    if (
      (memberMessageTemplate && (newMessageTextEn === memberMessageTemplate.messageTextEn || (!newMessageTextEn && !memberMessageTemplate.messageTextEn))) ||
      (!memberMessageTemplate && !newMessageTextEn)
    ) {
      setMessageTextEnValidationError(undefined)
      setMessageTextEn(undefined)
      return
    }
    setMessageTextEn(newMessageTextEn)
    if (newMessageTextEn) {
      // setMessageTextEnValidationError(validationHelpers.validateMemberMessageTemplateMessageTextEn(newMessageTextEn))
    } else {
      setMessageTextEnValidationError(undefined)
    }
  }

  const onChangeShortMessageTextEn = (event: any): void => {
    const newShortMessageTextEn = event.detail.value
    // console.log('onChangeMessageTextEn: newMessageTextEn=', newMessageTextEn)
    if (
      (memberMessageTemplate && (newShortMessageTextEn === memberMessageTemplate.shortMessageTextEn || (!newShortMessageTextEn && !memberMessageTemplate.shortMessageTextEn))) ||
      (!memberMessageTemplate && !newShortMessageTextEn)
    ) {
      setShortMessageTextEnValidationError(undefined)
      setShortMessageTextEn(undefined)
      return
    }
    setShortMessageTextEn(newShortMessageTextEn)
    if (newShortMessageTextEn) {
      // setMessageTextEnValidationError(validationHelpers.validateMemberMessageTemplateMessageTextEn(newMessageTextEn))
    } else {
      setShortMessageTextEnValidationError(undefined)
    }
  }

  const onChangeHtmlMessageEn = (newHtmlMessageEn: string): void => {
    // console.log('onChangeMessageTextEn: newMessageTextEn=', newMessageTextEn)
    if (
      (memberMessageTemplate && (newHtmlMessageEn === memberMessageTemplate.htmlMessageEn || (!newHtmlMessageEn && !memberMessageTemplate.htmlMessageEn))) ||
      (!memberMessageTemplate && !newHtmlMessageEn)
    ) {
      setHtmlMessageEnValidationError(undefined)
      setHtmlMessageEn(undefined)
      return
    }
    setHtmlMessageEn(newHtmlMessageEn)
    if (newHtmlMessageEn) {
      // setMessageTextEnValidationError(validationHelpers.validateMemberMessageTemplateMessageTextEn(newMessageTextEn))
    } else {
      setHtmlMessageEnValidationError(undefined)
    }
  }

  const onChangeAction0 = (event: any): void => {
    if (
      (memberMessageTemplate && (event.detail.value === memberMessageTemplate.action0 || (!event.detail.value && !memberMessageTemplate.action0))) ||
      (!memberMessageTemplate && !event.detail.value)
    ) {
      setAction0ValidationError(undefined)
      setAction0(undefined)
      return
    }
    setAction0(event.detail.value)
    if (event.detail.value) {
      // setAction0ValidationError(validationHelpers.validateMemberMessageTemplateName(event.detail.value))
    } else {
      setAction0ValidationError(undefined)
    }
  }

  const onChangeAction1 = (event: any): void => {
    if (
      (memberMessageTemplate && (event.detail.value === memberMessageTemplate.action1 || (!event.detail.value && !memberMessageTemplate.action1))) ||
      (!memberMessageTemplate && !event.detail.value)
    ) {
      setAction1ValidationError(undefined)
      setAction1(undefined)
      return
    }
    setAction1(event.detail.value)
    if (event.detail.value) {
      // setAction1ValidationError(validationHelpers.validateMemberMessageTemplateName(event.detail.value))
    } else {
      setAction1ValidationError(undefined)
    }
  }

  const onChangeAction2 = (event: any): void => {
    if (
      (memberMessageTemplate && (event.detail.value === memberMessageTemplate.action2 || (!event.detail.value && !memberMessageTemplate.action2))) ||
      (!memberMessageTemplate && !event.detail.value)
    ) {
      setAction2ValidationError(undefined)
      setAction2(undefined)
      return
    }
    setAction2(event.detail.value)
    if (event.detail.value) {
      setAction2ValidationError(validationHelpers.validateImageSource(event.detail.value))
    } else {
      setAction2ValidationError(undefined)
    }
  }

  const onChangeSendChatMessage = (checked: boolean): void => {
    if (
      (memberMessageTemplate && checked === memberMessageTemplate.sendChatMessage) ||
      (!memberMessageTemplate && checked)
    ) {
      setSendChatMessage(undefined)
      return
    }
    setSendChatMessage(checked)
  }

  const onChangeSendEmail = (checked: boolean): void => {
    if (
      (memberMessageTemplate && checked === memberMessageTemplate.sendEmail) ||
      (!memberMessageTemplate && checked)
    ) {
      setSendEmail(undefined)
      return
    }
    setSendEmail(checked)
  }

  const onChangeSendInAppMessage = (checked: boolean): void => {
    if (
      (memberMessageTemplate && checked === memberMessageTemplate.sendInAppMessage) ||
      (!memberMessageTemplate && checked)
    ) {
      setSendInAppMessage(undefined)
      return
    }
    setSendInAppMessage(checked)
  }

  const onChangeSendPushNotification = (checked: boolean): void => {
    if (
      (memberMessageTemplate && checked === memberMessageTemplate.sendPushNotification) ||
      (!memberMessageTemplate && checked)
    ) {
      setSendPushNotification(undefined)
      return
    }
    setSendPushNotification(checked)
  }

  const onChangeSendSms = (checked: boolean): void => {
    if (
      (memberMessageTemplate && checked === memberMessageTemplate.sendSms) ||
      (!memberMessageTemplate && checked)
    ) {
      setSendSms(undefined)
      return
    }
    setSendSms(checked)
  }

  const onChangeAdminNotes = (event: any): void => {
    if (
      (memberMessageTemplate && (event.detail.value === memberMessageTemplate.adminNotes || (!event.detail.value && !memberMessageTemplate.adminNotes))) ||
      (!memberMessageTemplate && !event.detail.value)
    ) {
      setAdminNotesValidationError(undefined)
      setAdminNotes(undefined)
      return
    }
    setAdminNotes(event.detail.value)
    if (event.detail.value) {
      // setAdminNotesValidationError(validationHelpers.validateImageSource(event.detail.value))
    } else {
      setAdminNotesValidationError(undefined)
    }
  }

  const compileMemberMessageTemplate = (): MemberMessageTemplateInput => {
    const updatedMemberMessageTemplate: MemberMessageTemplateInput = {}
    if (memberMessageTemplate && memberMessageTemplate.id) {
      updatedMemberMessageTemplate.id = memberMessageTemplate.id
    }
    if (name !== undefined && (!memberMessageTemplate || name !== memberMessageTemplate.name)) {
      updatedMemberMessageTemplate.name = name || null
    }
    if (title !== undefined && (!memberMessageTemplate || title !== memberMessageTemplate.titleEn)) {
      updatedMemberMessageTemplate.titleEn = title || null
    }
    if (messageTextEn !== undefined && (!memberMessageTemplate || messageTextEn !== memberMessageTemplate.messageTextEn)) {
      updatedMemberMessageTemplate.messageTextEn = messageTextEn || null
    }
    if (shortMessageTextEn !== undefined && (!memberMessageTemplate || shortMessageTextEn !== memberMessageTemplate.shortMessageTextEn)) {
      updatedMemberMessageTemplate.shortMessageTextEn = shortMessageTextEn || null
    }
    if (htmlMessageEn !== undefined && (!memberMessageTemplate || htmlMessageEn !== memberMessageTemplate.htmlMessageEn)) {
      updatedMemberMessageTemplate.htmlMessageEn = htmlMessageEn || null
    }
    if (action0 !== undefined && (!memberMessageTemplate || action0 !== memberMessageTemplate.action0)) {
      updatedMemberMessageTemplate.action0 = action0 || null
    }
    if (action1 !== undefined && (!memberMessageTemplate || action1 !== memberMessageTemplate.action1)) {
      updatedMemberMessageTemplate.action1 = action1 || null
    }
    if (action2 !== undefined && (!memberMessageTemplate || action2 !== memberMessageTemplate.action2)) {
      updatedMemberMessageTemplate.action2 = action2 || null
    }
    if (sendChatMessage !== undefined && (!memberMessageTemplate || sendChatMessage !== memberMessageTemplate.sendChatMessage)) {
      updatedMemberMessageTemplate.sendChatMessage = sendChatMessage
    }
    if (sendEmail !== undefined && (!memberMessageTemplate || sendEmail !== memberMessageTemplate.sendEmail)) {
      updatedMemberMessageTemplate.sendEmail = sendEmail
    }
    if (sendInAppMessage !== undefined && (!memberMessageTemplate || sendInAppMessage !== memberMessageTemplate.sendInAppMessage)) {
      updatedMemberMessageTemplate.sendInAppMessage = sendInAppMessage
    }
    if (sendPushNotification !== undefined && (!memberMessageTemplate || sendPushNotification !== memberMessageTemplate.sendPushNotification)) {
      updatedMemberMessageTemplate.sendPushNotification = sendPushNotification
    }
    if (sendSms !== undefined && (!memberMessageTemplate || sendSms !== memberMessageTemplate.sendSms)) {
      updatedMemberMessageTemplate.sendSms = sendSms
    }
    if (adminNotes !== undefined && (!memberMessageTemplate || adminNotes !== memberMessageTemplate.adminNotes)) {
      updatedMemberMessageTemplate.adminNotes = adminNotes || null
    }
    return updatedMemberMessageTemplate
  }

  const onClickSaveButton = (event: any): void => {
    event.preventDefault()
    onSave(compileMemberMessageTemplate())
  }

  const onClickPreviewButton = (event: any): void => {
    event.preventDefault()
    onPreview({ ...memberMessageTemplate, ...compileMemberMessageTemplate() })
  }

  const appActionOptions = Object.values(AppAction).map((action) => (
    <IonSelectOption key={action} value={action}>
      {coreHelpers.type.appAction.getLabel(action)}
    </IonSelectOption>
  ))

  let sendChatMessageDisplay = memberMessageTemplate ? memberMessageTemplate.sendChatMessage === true : true
  if (sendChatMessage !== undefined) {
    sendChatMessageDisplay = sendChatMessage
  }
  let sendEmailDisplay = memberMessageTemplate ? memberMessageTemplate.sendEmail === true : true
  if (sendEmail !== undefined) {
    sendEmailDisplay = sendEmail
  }
  let sendInAppMessageDisplay = memberMessageTemplate ? memberMessageTemplate.sendInAppMessage === true : true
  if (sendInAppMessage !== undefined) {
    sendInAppMessageDisplay = sendInAppMessage
  }
  let sendPushNotificationDisplay = memberMessageTemplate ? memberMessageTemplate.sendPushNotification === true : true
  if (sendPushNotification !== undefined) {
    sendPushNotificationDisplay = sendPushNotification
  }
  let sendSmsDisplay = memberMessageTemplate ? memberMessageTemplate.sendSms === true : true
  if (sendSms !== undefined) {
    sendSmsDisplay = sendSms
  }

  // console.log('>>>>>>', {
  //   memberMessageTemplate,
  //   sendChatMessage,
  //   sendEmail,
  //   sendInAppMessage,
  //   sendPushNotification,
  //   sendSms,
  //   sendChatMessageDisplay,
  //   sendEmailDisplay,
  //   sendInAppMessageDisplay,
  //   sendPushNotificationDisplay,
  //   sendSmsDisplay,
  // })

  let previewButton: JSX.Element | undefined
  if (
    memberMessageTemplate &&
    memberMessageTemplate.sendInAppMessage &&
    memberMessageTemplate.htmlMessageEn
  ) {
    previewButton = (
      <SubmitButton
        onClick={onClickPreviewButton}
        disabled={!isValid}
      >
        Preview
      </SubmitButton>
    )
  }

  return (
    <form onSubmit={onClickSaveButton}>
      <FormItem label='Name' validationError={nameValidationError} withBottomMargin>
        <IonInput
          autofocus
          onIonChange={onChangeName}
          placeholder='name'
          value={name !== undefined ? name : (memberMessageTemplate ? memberMessageTemplate.name : '')}
        />
      </FormItem>
      <FormItem label='Title' validationError={titleValidationError} withBottomMargin>
        <IonInput
          autofocus
          onIonChange={onChangeTitle}
          placeholder='title'
          value={title !== undefined ? title : (memberMessageTemplate ? memberMessageTemplate.titleEn : '')}
        />
      </FormItem>
      <FormItem
        label='Message Text (Long)'
        validationError={messageTextEnValidationError}
        withBottomMargin
      >
        <IonTextarea
          rows={8}
          autocapitalize='sentences'
          onIonChange={onChangeMessageTextEn}
          value={(messageTextEn !== undefined) ? messageTextEn : (memberMessageTemplate && memberMessageTemplate.messageTextEn ? memberMessageTemplate.messageTextEn : '')}
        />
      </FormItem>
      <FormItem
        label='Message Text (Short)'
        validationError={shortMessageTextEnValidationError}
        withBottomMargin
      >
        <IonTextarea
          rows={4}
          autocapitalize='sentences'
          onIonChange={onChangeShortMessageTextEn}
          value={(shortMessageTextEn !== undefined) ? shortMessageTextEn : (memberMessageTemplate && memberMessageTemplate.shortMessageTextEn ? memberMessageTemplate.shortMessageTextEn : '')}
        />
      </FormItem>
      <FormItem label='Html Message' validationError={htmlMessageEnValidationError} withBottomMargin>
        <Editor
          apiKey='dv7dwodu7u7ujzaik6cr3g42xsq8jdtmbcovnwmsuniiindk'
          onEditorChange={onChangeHtmlMessageEn}
          init={{
            content_css: './styles.css',
            min_height: 500,
            max_height: 1000,
            browser_spellcheck: true,
            contextmenu: false,
            autosave_interval: '15s',
            style_formats: [
              { title: 'Image formats' },
              { title: 'Gift Card', selector: 'img', styles: { border: '1px solid lightgrey', 'border-radius': '12px' } },
            ],
            style_formats_merge: true,
          }}
          value={(htmlMessageEn !== undefined) ? htmlMessageEn : (memberMessageTemplate && memberMessageTemplate.htmlMessageEn ? memberMessageTemplate.htmlMessageEn : '')}
          plugins='print preview paste searchreplace autolink autosave save directionality code visualblocks
           visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc
            insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
          toolbar='undo redo | bold italic underline strikethrough forecolor | fontselect fontsizeselect styleselect |
           alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |
            pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl'
        />
      </FormItem>
      <FormItem label='Action 1' validationError={action0ValidationError} withBottomMargin>
        <IonSelect
          value={action0 !== undefined ? action0 : (memberMessageTemplate ? memberMessageTemplate.action0 : '')}
          interface='popover'
          onIonChange={onChangeAction0}
        >
          {appActionOptions}
        </IonSelect>
      </FormItem>
      <FormItem label='Action 2' validationError={action1ValidationError} withBottomMargin>
        <IonSelect
          value={action1 !== undefined ? action1 : (memberMessageTemplate ? memberMessageTemplate.action1 : '')}
          interface='popover'
          onIonChange={onChangeAction1}
        >
          {appActionOptions}
        </IonSelect>
      </FormItem>
      <FormItem label='Action 3' validationError={action1ValidationError} withBottomMargin>
        <IonSelect
          value={action2 !== undefined ? action2 : (memberMessageTemplate ? memberMessageTemplate.action2 : '')}
          interface='popover'
          onIonChange={onChangeAction2}
        >
          {appActionOptions}
        </IonSelect>
      </FormItem>
      <FormCheckbox
        isChecked={sendChatMessageDisplay}
        label='Send chat message?'
        // className='withStandardBottomMargin'
        onChange={onChangeSendChatMessage}
      />
      <FormCheckbox
        isChecked={sendEmailDisplay}
        label='Send email?'
        onChange={onChangeSendEmail}
      />
      <FormCheckbox
        isChecked={sendInAppMessageDisplay}
        label='Send in-app message?'
        // className='withStandardBottomMargin'
        onChange={onChangeSendInAppMessage}
      />
      <FormCheckbox
        isChecked={sendPushNotificationDisplay}
        label='Send push notification?'
        // className='withStandardBottomMargin'
        onChange={onChangeSendPushNotification}
      />
      <FormCheckbox
        isChecked={sendSmsDisplay}
        label='Send SMS?'
        className='withDoubleBottomMargin'
        onChange={onChangeSendSms}
      />
      <FormItem label='Admin Notes' validationError={adminNotesValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeAdminNotes}
          placeholder='admin notes'
          value={adminNotes !== undefined ? adminNotes : (memberMessageTemplate ? memberMessageTemplate.adminNotes : '')}
        />
      </FormItem>
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onGoBack}
        >
          Back
        </IonButton>
        {previewButton}
        <SubmitButton
          onClick={onClickSaveButton}
          disabled={!isDirty || !isValid}
        >
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default MemberMessageTemplateForm
