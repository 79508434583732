import type { PurchaseTransfer, UserIdentInfo } from '../../../lib/core/definitions'
import { PurchaseTransferStatus } from '../../../lib/core/enums'
import { DataEvaluation } from '../definitions'
import coreHelpers from '../../../lib/core/helpers'

const evaluateData = (
  purchaseTransfer: PurchaseTransfer | undefined,
  purchaseTransferId: string | null | undefined,
  toPurchaseId: string | null | undefined,
  userIdentInfo: UserIdentInfo | undefined,
  activeUserId: string | null | undefined,
  isSignedIn: boolean,
): DataEvaluation => {
  const recipientIsSignedIn = isSignedIn
  const dataEvaluation: DataEvaluation = {
    signedInUserIsSender: undefined as boolean | undefined,
    recipientIsSignedIn,
    recipientAlreadyExists: recipientIsSignedIn || !!userIdentInfo,
    transferHasCorrectStatus: undefined as boolean | undefined,
    transferMatchesRequest: undefined as boolean | undefined,
  }
  if (purchaseTransfer) {
    // Is the transfer in the right state?
    dataEvaluation.transferHasCorrectStatus = (
      purchaseTransfer.transferStatus === PurchaseTransferStatus.AVAILABLE ||
      purchaseTransfer.transferStatus === PurchaseTransferStatus.SCHEDULED
    )

    // Is this the correct transfer?
    if (
      purchaseTransfer &&
      purchaseTransferId &&
      !coreHelpers.models.compareId(purchaseTransfer.id, purchaseTransferId)
    ) {
      console.error('GiftReceivedPage: purchase transfer ID mismatch',
        { 'purchaseTransfer.id': purchaseTransfer.id, purchaseTransferId })
      dataEvaluation.transferMatchesRequest = false
    }
    if (
      purchaseTransfer &&
      toPurchaseId &&
      !coreHelpers.models.compareId(purchaseTransfer.toPurchaseId, toPurchaseId)
    ) {
      console.error('GiftReceivedPage: target purchase ID mismatch',
        { 'purchaseTransfer.toPurchaseId': purchaseTransfer.toPurchaseId, toPurchaseId })
      dataEvaluation.transferMatchesRequest = false
    }
  }

  if (purchaseTransfer) {
    const { fromPurchase } = purchaseTransfer
    if (fromPurchase && fromPurchase.user) {
      const fromUser = fromPurchase.user
      if (fromUser && fromUser.id) {
        dataEvaluation.signedInUserIsSender = (
          !!fromUser.id &&
          coreHelpers.models.compareId(fromUser.id, activeUserId)
        )
      }
    }
  }

  return dataEvaluation
}

export default evaluateData
