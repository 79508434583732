import React, { useEffect, useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import './styles.css'
import { AppRoute } from '../../../enums'
import { FundType, TransactionType } from '../../../lib/core/enums'
import type { TransactionListItem } from '../../../lib/core/definitions'
import type { TransactionsQueryData, TransactionsQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import TransactionGrid from './TransactionGrid'

enum Scope {
  ALL = 'all',
  ORDERS = 'orders',
  PROMOTIONS = 'promotions',
  TOKEN_TRANSFERS = 'mit-transfers',
}

type Props = {
  refreshIndex: number
  onNavigate: (route: AppRoute | string) => void
  showUiMessage: (message: string) => void
  onBuyTokens: () => void
  onFinishedLoading: () => void
}

const TransactionsTab: React.FC<Props> = (props): JSX.Element | null => {
  const {
    refreshIndex,
    onBuyTokens,
    onNavigate,
    showUiMessage,
    onFinishedLoading,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser, getActiveUserMimbleWallet } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const activeUserWallet = getActiveUserMimbleWallet && getActiveUserMimbleWallet()

  const [scope, setScope] = useState<Scope>(Scope.ALL)

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: userTransactionsData,
    refetch: reloadTransactions,
  } = useQuery<TransactionsQueryData, TransactionsQueryVariables>(
    apollo.queries.transactions, {
      variables: {
        filter: {
          userId: activeUserId,
          fundTypes: [FundType.TOKEN],
          orderBy: 'createdAt',
          orderByDirection: 'desc',
        },
      },
      skip: !activeUserId,
      notifyOnNetworkStatusChange: true,
      onCompleted: onFinishedLoading,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
    },
  )
  let transactions: TransactionListItem[] | undefined
  if (userTransactionsData && Array.isArray(userTransactionsData.transactions) && userTransactionsData.transactions.length > 0) {
    transactions = userTransactionsData.transactions.filter((t) => {
      switch (scope) {
        case Scope.ALL:
          return true
        case Scope.ORDERS:
          return !!t.orderId
        case Scope.TOKEN_TRANSFERS:
          return t.transactionType === TransactionType.TOKEN_TRANSFER
        case Scope.PROMOTIONS:
          return t.transactionType === TransactionType.USER_PROMOTION
      }
      return false
    })
  }

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (refreshIndex > 0 && reloadTransactions) {
      reloadTransactions()
    }
  }, [refreshIndex])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeScope = (event: any): void => {
    setScope(event.target.value)
  }

  const onOpenTransaction = (transactionId: string): void => {
    api.loadTransaction(
      transactionId,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then((transaction) => {
      if (transaction) {
        onNavigate(`${AppRoute.TRANSACTION}/${transactionId}`)
      }
    }, (error) => {
      console.error(error)
    })
  }

  // ===================================================================================================================
  // Rendering:
  if (!activeUser) {
    return null
  }

  return (
    <div className='transactions-tab'>
      <div className='withStandardTopMargin withStandardBottomMargin'>
        <FormControl
          variant='outlined'
          fullWidth
        >
          <InputLabel id='select-time-label'>Transaction Type</InputLabel>
          <Select
            labelId='select-time-label'
            id='select-time'
            value={scope}
            onChange={onChangeScope}
            label='Transaction Type'
          >
            <MenuItem key={Scope.ALL} value={Scope.ALL}>All</MenuItem>
            <MenuItem key={Scope.ORDERS} value={Scope.ORDERS}>Orders</MenuItem>
            <MenuItem key={Scope.TOKEN_TRANSFERS} value={Scope.TOKEN_TRANSFERS}>MIT transfers</MenuItem>
            <MenuItem key={Scope.PROMOTIONS} value={Scope.PROMOTIONS}>Promotions</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TransactionGrid
        activeUserId={activeUserId}
        activeUserWallet={activeUserWallet}
        transactions={transactions}
        onOpenTransaction={onOpenTransaction}
      />
    </div>
  )
}

export default TransactionsTab
