import acceptReceivedPurchase from './acceptReceivedPurchase'
import cache from './cache'
import deleteContactEvent from './deleteContactEvent'
import deleteContactEventReminder from './deleteContactEventReminder'
import deleteFavoriteOrganization from './deleteFavoriteOrganization'
import deleteInboxItem from './deleteInboxItem'
import deleteWish from './deleteWish'
import findUser from './findUser'
import init from './init'
import loadActiveUser from './loadActiveUser'
import loadAsyncTask from './loadAsyncTask'
import loadCampaign from './loadCampaign'
import loadChat from './loadChat'
import loadChatHelpers from './loadChat/helpers'
import loadChatMessage from './loadChatMessage'
import loadChats from './loadChats'
import loadContact from './loadContact'
import loadContactEvent from './loadContactEvent'
import loadContactEventReminder from './loadContactEventReminder'
import loadContactUsers from './loadContactUsers'
import loadFavoriteOrganization from './loadFavoriteOrganization'
import loadInvitation from './loadInvitation'
import loadMemberMessageTemplate from './loadMemberMessageTemplate'
import loadMerchant from './loadMerchant'
import loadMerchants from './loadMerchants'
import loadOrder from './loadOrder'
import loadProduct from './loadProduct'
import loadProductOption from './loadProductOption'
import loadPurchase from './loadPurchase'
import loadPurchases from './loadPurchases'
import loadPurchaseTransfer from './loadPurchaseTransfer'
import loadReport from './loadReport'
import loadReward from './loadReward'
import loadRewardConfigs from './loadRewardConfigs'
import loadShoppingCart from './loadShoppingCart'
import loadStoredProduct from './loadStoredProduct'
import loadTransaction from './loadTransaction'
import loadUser from './loadUser'
import loadUserDevice from './loadUserDevice'
import loadUserSensitiveInfo from './loadUserSensitiveInfo'
// import loadUserSystemMessages from './loadUserSystemMessages'
import loadWallets from './loadWallets'
import loadWalletWithNewBalance from './loadWalletWithNewBalance'
import loadUnreadInAppMessages from './loadUnreadInAppMessages'
import loadWish from './loadWish'
import markChatMessagesOfChatAsReceived from './markChatMessagesOfChatAsReceived'
import markInAppMessageReceived from './markInAppMessageReceived'
import reportSessionState from './reportSessionState'
import signIn from './signIn'
import signInOAuthUser from './signInOAuthUser'
import updateUser from './updateUser'
import upsertAsyncTask from './upsertAsyncTask'
import upsertCampaign from './upsertCampaign'
import upsertChat from './upsertChat'
import upsertChatMessage from './upsertChatMessage'
import upsertContact from './upsertContact'
import upsertContactEvent from './upsertContactEvent'
import upsertContactEventReminder from './upsertContactEventReminder'
import upsertFavoriteOrganization from './upsertFavoriteOrganization'
import upsertMemberMessageTemplate from './upsertMemberMessageTemplate'
import upsertMerchant from './upsertMerchant'
import upsertProduct from './upsertProduct'
import upsertProductOption from './upsertProductOption'
import upsertPurchase from './upsertPurchase'
import upsertPurchaseTransfer from './upsertPurchaseTransfer'
import upsertReward from './upsertReward'
import upsertShoppingCart from './upsertShoppingCart'
import upsertStoredProduct from './upsertStoredProduct'
import upsertTransaction from './upsertTransaction'
import upsertUserDevice from './upsertUserDevice'
import upsertUserSensitiveInfo from './upsertUserSensitiveInfo'
import upsertWish from './upsertWish'

const apiService = {
  acceptReceivedPurchase,
  cache,
  deleteContactEvent,
  deleteContactEventReminder,
  deleteFavoriteOrganization,
  deleteInboxItem,
  deleteWish,
  findUser,
  init,
  loadActiveUser,
  loadAsyncTask,
  loadCampaign,
  loadChat,
  loadChatMessage,
  loadChats,
  loadContact,
  loadContactEvent,
  loadContactEventReminder,
  loadContactUsers,
  loadFavoriteOrganization,
  loadInvitation,
  loadOrder,
  loadProduct,
  loadProductOption,
  loadPurchase,
  loadPurchases,
  loadPurchaseTransfer,
  loadReport,
  loadReward,
  loadRewardConfigs,
  loadShoppingCart,
  loadMemberMessageTemplate,
  loadTransaction,
  loadUser,
  loadMerchant,
  loadMerchants,
  loadStoredProduct,
  loadUnreadInAppMessages,
  loadUserDevice,
  loadUserSensitiveInfo,
  // loadUserSystemMessages,
  loadWallets,
  loadWalletWithNewBalance,
  loadWish,
  markChatMessagesOfChatAsReceived,
  markInAppMessageReceived,
  reportSessionState,
  signIn,
  signInOAuthUser,
  updateUser,
  upsertAsyncTask,
  upsertChat,
  upsertCampaign,
  upsertChatMessage,
  upsertContact,
  upsertContactEvent,
  upsertContactEventReminder,
  upsertFavoriteOrganization,
  upsertProduct,
  upsertProductOption,
  upsertPurchase,
  upsertPurchaseTransfer,
  upsertReward,
  upsertShoppingCart,
  upsertStoredProduct,
  upsertMemberMessageTemplate,
  upsertTransaction,
  upsertUserDevice,
  upsertUserSensitiveInfo,
  upsertMerchant,
  upsertWish,
  helpers: {
    handleUnreadChatMessages: loadChatHelpers.handleUnreadMessages,
  },
}

export default apiService
