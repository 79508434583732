import type { Currency, Order, OrderListItem } from '../../../definitions'
import { FiatCurrency, FundType, RecordStatus, TransactionProcessor, TransactionStatus } from '../../../enums'

const updateOrderListItemFromOrder = (orderListItem: OrderListItem | undefined, order: Order): OrderListItem => {
  let userFullName: string | null = null
  let paymentTransactionId: string | null | undefined
  let paymentAmount: number | null | undefined
  let paymentFundType: FundType | null | undefined
  let paymentCurrency: Currency | null | undefined
  let paymentProcessor: TransactionProcessor | null | undefined
  let paymentStatus: TransactionStatus | null | undefined

  if (order.user && order.user.fullName) {
    userFullName = order.user.fullName
  }

  if (order.paymentTransaction) {
    paymentTransactionId = order.paymentTransaction.id
    paymentAmount = order.paymentTransaction.amount
    paymentFundType = order.paymentTransaction.fundType
    paymentCurrency = order.paymentTransaction.currency
    paymentStatus = order.paymentTransaction.status
  }

  if (order.paymentTransaction && order.paymentTransaction.processor) {
    paymentProcessor = order.paymentTransaction.processor
  }

  if (!orderListItem) {
    return {
      id: order.id || '',
      userId: order.userId || null,
      donationTargetId: order.donationTargetId || null,
      metadata: order.metadata || { revision: 0 },
      status: null,
      userFullName,
      paymentTransactionId: paymentTransactionId || '',
      paymentAmount: paymentAmount || 0,
      paymentFundType: paymentFundType || FundType.UNKNOWN,
      paymentCurrency: paymentCurrency || FiatCurrency.USD,
      paymentProcessor: paymentProcessor || TransactionProcessor.STRIPE,
      paymentStatus: paymentStatus || TransactionStatus.NOT_STARTED,
      recordStatus: order.recordStatus || RecordStatus.ACTIVE,
      createdAt: order.createdAt || '',
      updatedAt: order.updatedAt || '',
    }
  }

  const updatedItem: OrderListItem = { ...orderListItem }

  if (order.userId && order.userId !== updatedItem.userId) {
    updatedItem.userId = order.userId
  }
  if (order.donationTargetId && order.donationTargetId !== updatedItem.donationTargetId) {
    updatedItem.donationTargetId = order.donationTargetId
  }
  if (order.metadata) {
    updatedItem.metadata = order.metadata
  }
  if (userFullName && userFullName !== updatedItem.userFullName) {
    updatedItem.userFullName = userFullName
  }
  if (paymentTransactionId && paymentTransactionId !== updatedItem.paymentTransactionId) {
    updatedItem.paymentTransactionId = paymentTransactionId
  }
  if (paymentAmount && paymentAmount !== updatedItem.paymentAmount) {
    updatedItem.paymentAmount = paymentAmount
  }
  if (paymentFundType && paymentFundType !== updatedItem.paymentFundType) {
    updatedItem.paymentFundType = paymentFundType
  }
  if (paymentCurrency && paymentCurrency !== updatedItem.paymentCurrency) {
    updatedItem.paymentCurrency = paymentCurrency
  }
  if (paymentProcessor && paymentProcessor !== updatedItem.paymentProcessor) {
    updatedItem.paymentProcessor = paymentProcessor
  }
  if (paymentStatus && paymentStatus !== updatedItem.paymentStatus) {
    updatedItem.paymentStatus = paymentStatus
  }
  if (order.recordStatus && order.recordStatus !== updatedItem.recordStatus) {
    updatedItem.recordStatus = order.recordStatus
  }
  if (order.createdAt && order.createdAt !== updatedItem.createdAt) {
    updatedItem.createdAt = order.createdAt
  }
  if (order.updatedAt && order.updatedAt !== updatedItem.updatedAt) {
    updatedItem.updatedAt = order.updatedAt
  }

  return updatedItem
}

export default updateOrderListItemFromOrder
