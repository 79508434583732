import { IonList } from '@ionic/react'
import React from 'react'

import './styles.css'
import type { Purchase, PurchaseListItem } from '../../../lib/core/definitions'
import type { PurchaseListScope } from '../../../lib/core/enums'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import GiftCardListRow from './GiftCardListRow'
import pageHelpers from '../../../helpers/pageHelpers'

interface Props {
  purchase?: Purchase;
  purchases?: PurchaseListItem[] | null;
  searchText?: string;
  scope: PurchaseListScope;
  isProcessing?: boolean;
  onOpenPurchase?: (purchaseId: string) => void;
}

const GiftCardList: React.FC<Props> = ({
  purchases,
  searchText,
  scope,
  isProcessing,
  onOpenPurchase,
}): JSX.Element | null => {
  const { getActiveUserPrefs } = useGlobalCache()
  const userPrefs = getActiveUserPrefs()
  const contrastPennies = userPrefs ? userPrefs.contrastPennies : false

  if (!Array.isArray(purchases) || purchases.length < 1) {
    return null
  }

  const items = pageHelpers
    .filterPurchaseList(purchases, searchText, scope)
    .map((p): JSX.Element => (
      <GiftCardListRow
        key={p.id as string}
        purchase={p}
        contrastPennies={contrastPennies}
        onOpenPurchase={onOpenPurchase}
      />
    ))

  return (
    <IonList>
      {items}
    </IonList>
  )
}

export default GiftCardList
