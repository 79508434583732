import React, { useContext, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import { PurchaseType, UiMessage } from '../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import type { OrderQueryData, OrderQueryVariables } from '../../services/apollo/definitions'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import GiftCardObjectInfo from '../../components/objectInfo/GiftCardObjectInfo/GiftCardObjectInfo'
import MimbleTokenPurchaseObjectInfo from '../../components/objectInfo/MimbleTokenPurchaseObjectInfo/MimbleTokenPurchaseObjectInfo'
import NavBar from '../../components/NavBar/NavBar'
import OrderObjectInfo from '../../components/objectInfo/OrderObjectInfo/OrderObjectInfo'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import RewardObjectInfo from '../../components/objectInfo/TransactionObjectInfo/TransactionObjectInfo'

const appPageId = AppPage.OrderPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  orderId: string
}

const OrderPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { orderId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { activeUser, isLoadingActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading order:
  const {
    data: orderLoadedData,
    loading: isLoadingOrder,
    refetch: reloadOrder,
  } = useQuery<OrderQueryData, OrderQueryVariables>(
    apollo.queries.order, {
      variables: { orderId: orderId as string },
      skip: !orderId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: OrderQueryData) => {
        const returnedOrder = data ? data.order : undefined
        if (returnedOrder === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const order = orderLoadedData ? orderLoadedData.order : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingOrder

  // ===================================================================================================================
  // Event Handlers:
  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !orderId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadOrder({ orderId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenPurchase = (purchaseId: string): void => {
    navigate(`${AppRoute.GIFT_CARD}/${purchaseId}`)
  }

  const onOpenTransaction = (transactionId: string): void => {
    navigate(`${AppRoute.TRANSACTION}/${transactionId}`)
  }

  // const onOpenTransaction = (): void => {
  //   if (!order || !order.transactionId) {
  //     return
  //   }
  //   navigate(`${AppRoute.TRANSACTION}/${order.transactionId}`)
  // }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const sections: JSX.Element[] = []
  if (order) {
    sections.push(
      <OrderObjectInfo
        key='order'
        order={order}
      />,
    )

    if (order.paymentTransaction) {
      sections.push(
        <RewardObjectInfo
          key='trans'
          transaction={order.paymentTransaction}
          onOpenTransaction={onOpenTransaction}
        />,
      )
    }

    if (Array.isArray(order.items) && order.items.length > 0) {
      order.items.forEach((purchase) => {
        if (purchase.purchaseType === PurchaseType.TOKEN) {
          sections.push(
            <MimbleTokenPurchaseObjectInfo
              key={purchase.id}
              purchase={purchase}
              onOpenPurchase={onOpenPurchase}
            />,
          )
        } else if (purchase.purchaseType === PurchaseType.MARKETPLACE_PRODUCT) {
          sections.push(
            <GiftCardObjectInfo
              key={purchase.id}
              purchase={purchase}
              onOpenPurchase={onOpenPurchase}
            />,
          )
        }
      })
    }
  }

  const content = (
    <div>
      {sections}
    </div>
  )

  return (
    <IonPage className='app-page-public order-page'>
      <NavBar
        title='Order'
        // goBackUri={`${AppRoute.TOKENS}/orders`}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default OrderPage
