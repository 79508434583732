import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonIcon,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { cloudDownloadOutline } from 'ionicons/icons'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Update } from 'history'
import { useApolloClient } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import { ReportDataFormat, ReportStatus } from '../../../lib/core/enums'
import { AppPage, AppRoute } from '../../../enums'
import type {
  Report,
  ReportFilter,
} from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import helpers from './helpers'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import ReportForm from './ReportForm'

const reportIsProcessed = (report: Report): boolean => (
  report.status === ReportStatus.SUCCEEDED ||
  report.status === ReportStatus.FAILED
)

const appPageId = AppPage.AdminProductsPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const ReportsPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  // const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [report, setReport] = useState<Report | undefined>()
  const [isProcessing, setIsProcessing] = useState(false)

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  // })

  // ===================================================================================================================
  // Event Handlers:
  const onSubmitReportForm = (filter: ReportFilter): void => {
    setIsProcessing(true)
    api.loadReport(
      filter,
      reportIsProcessed,
      apolloClient,
    ).then((report) => {
      setIsProcessing(false)
      setReport(report)
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      setIsProcessing(false)
      console.error(error)
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    })
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !report || !report.filter) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    // todo: have onSubmitReportForm return a promise, then cancel the refreshEvent
    onSubmitReportForm(report.filter)
    // onSubmitReportForm(report.filter).then(() => {
    //   if (refreshEvent) {
    //     refreshEvent.detail.complete()
    //     refreshEvent = undefined
    //   }
    // }, (error) => {
    //   console.error(error)
    // })
  }

  const onExport = (): void => {
    if (!report || !(report.filter)) {
      return
    }
    const filter = { ...report.filter, format: ReportDataFormat.TSV }
    api.loadReport(filter, reportIsProcessed, apolloClient)
      .then((report) => {
        setIsProcessing(false)
        if (report && Array.isArray(report.data) && report.data.length > 0) {
          // see https://stackoverflow.com/questions/16761927/aw-snap-when-data-uri-is-too-large
          // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
          // https://stackoverflow.com/questions/17103398/convert-javascript-variable-value-to-csv-file
          const data = new Blob([report.data.join('\n')], { type: 'text/tsv' });
          const url = URL.createObjectURL(data)
          const link = document.createElement('a')
          link.href = url
          link.target = '_blank'
          link.download = `${report.reportType || 'export'}.tsv`
          link.click()
        }
      }, (error) => {
        setIsProcessing(false)
        console.error(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        // pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
        setToastMessage('Error connecting.')
        setShowToast(true)
      })
  }

  // const onRowClick = (param: any): void => {
  // }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let dataGrid: JSX.Element | undefined
  if (report && Array.isArray(report.data) && report.data.length > 0) {
    const { columns, rows } = helpers.getDataGridConfig(report) || {}
    if (rows && columns) {
      dataGrid = (
        <div className='x-grid-data-view'>
          <div className='actions-row'>
            <IonButton
              size='small'
              fill='clear'
              color='medium'
              disabled={!report || !Array.isArray(report.data) || report.data.length < 1 || isProcessing}
              onClick={onExport}
            >
              <IonIcon icon={cloudDownloadOutline} />
            </IonButton>
          </div>
          <div className='x-grid-wrapper'>
            <DataGridPro
              rows={rows}
              columns={columns}
              rowHeight={36}
              // onRowClick={onRowClick}
            />
          </div>
        </div>
      )
    }
  }

  return (
    <IonPage className='app-page-admin reports-page'>
      <NavBar
        title='Internal Reports'
        goBackUri={AppRoute.ADMIN_HOME}
      />
      <IonContent className='g-content-with-padding' scrollY={false}>
        <div className='g-non-scroll-content-wrapper'>
          <PageMessages />
          <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <ReportForm
            onSubmit={onSubmitReportForm}
            isProcessing={isProcessing}
          />
          {dataGrid}
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default ReportsPage
