import deleteActiveUserWish from './deleteActiveUserWish'
import deleteContactWish from './deleteContactWish'
import deleteFavoriteOrganization from './deleteFavoriteOrganization'
import deleteInboxItem from './deleteInboxItem'
import setMessagesInfoInContactUser from './setMessagesInfoInContactUser'
import updateCampaign from './updateCampaign'
import updateChat from './updateChat'
import updateChatMessage from './updateChatMessage'
import updateContact from './updateContact'
import updateFavoriteOrganization from './updateFavoriteOrganization'
import updateInboxListItem from './updateInboxListItem'
import updateInvitation from './updateInvitation'
import updateMemberMessageTemplate from './updateMemberMessageTemplate'
import updateMerchant from './updateMerchant'
import updateOrder from './updateOrder'
import updateProduct from './updateProduct'
import updatePurchase from './updatePurchase'
import updatePurchaseTransfer from './updatePurchaseTransfer'
import updateReward from './updateReward'
import updateShoppingCart from './updateShoppingCart'
import updateStoredProduct from './updateStoredProduct'
import updateTransaction from './updateTransaction'
import updateUser from './updateUser'
import updateUserDevice from './updateUserDevice'
import updateWallet from './updateWallet'
import updateWish from './updateWish'

const apiCache = {
  deleteActiveUserWish,
  deleteContactWish,
  deleteFavoriteOrganization,
  deleteInboxItem,
  setMessagesInfoInContactUser,
  updateCampaign,
  updateChat,
  updateChatMessage,
  updateContact,
  updateFavoriteOrganization,
  updateInboxListItem,
  updateInvitation,
  updateMemberMessageTemplate,
  updateMerchant,
  updateOrder,
  updateProduct,
  updatePurchase,
  updatePurchaseTransfer,
  updateReward,
  updateShoppingCart,
  updateStoredProduct,
  updateTransaction,
  updateUser,
  updateUserDevice,
  updateWallet,
  updateWish,
}

export default apiCache
