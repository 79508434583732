import { isPlatform } from '@ionic/react'
import type { ApolloClient } from '@apollo/client'

import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import type { User, UserDeviceInput } from '../../lib/core/definitions'
import api from '../api'
import getPushNotificationToken from './getPushNotificationToken'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import logger from '../logger'

const report = (localUser: Partial<User>, token: string | null, apolloClient: ApolloClient<any>) => {
  console.log('services.firebase.reportPushNotificationTokenToServer.report called; token=', token)
  const deviceUuid = globalCacheData.getDeviceId()
  const userDeviceInput: UserDeviceInput = {
    deviceUuid,
    pushNotificationToken: token,
    userId: localUser.id,
    authToken: localUser.authToken,
  }

  if (localUser.phoneNumber) {
    userDeviceInput.phoneNumber = localUser.phoneNumber
  }

  console.log('services.firebase.reportPushNotificationTokenToServer: sending.', { userDeviceInput })
  api.upsertUserDevice(
    userDeviceInput,
    undefined,
    undefined,
    localUser.id as string,
    apolloClient,
    undefined,
    { updateList: false },
  ).then((userDevice) => {
    if (
      userDevice &&
      userDevice.deviceUuid &&
      userDevice.deviceUuid !== deviceUuid
    ) {
      console.log('services.firebase.reportPushNotificationTokenToServer: Received a different device UUID; saving that now.')
      globalCacheData.setValue(GlobalCacheDataKey.FCM_TOKEN, userDevice.deviceUuid)
    }
  }, (error) => {
    logger.error('services.firebase.reportPushNotificationTokenToServer: upsertUserDevice returned error.', error)
  })
}

const reportPushNotificationTokenToServer = (token: string | undefined, apolloClient: ApolloClient<any>): void => {
  console.log('services.firebase.reportPushNotificationTokenToServer called; token=', token)

  if (!isPlatform('capacitor')) {
    console.log('services.firebase.reportPushNotificationTokenToServer: not a capacitor app.')
    return
  }

  // Is the user signed in?
  const localUser = globalCacheData.getActiveUser()
  if (
    !localUser ||
    !localUser.id ||
    !globalCacheData.getIsSignedIn()
  ) {
    console.log('services.firebase.reportPushNotificationTokenToServer: user not (yet) signed in.')
    return
  }

  if (token) {
    report(localUser, token, apolloClient)
    return
  }

  getPushNotificationToken().then((token) => {
    if (!token) {
      console.log('services.firebase.reportPushNotificationTokenToServer: no token available.')
      return
    }
    report(localUser, token, apolloClient)
  }, (error) => {
    console.error('services.firebase.reportPushNotificationTokenToServer: report() threw error.', error)
  })
}

export default reportPushNotificationTokenToServer
