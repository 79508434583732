import React, { useContext, useState } from 'react'
import { IonContent, IonPage, IonToast, useIonViewDidLeave } from '@ionic/react'
import { Update } from 'history'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm'

const appPageId = AppPage.ResetPasswordPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

type Params = {
  taskId: string
  token: string
}

const ResetPasswordPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const {
    taskId: taskIdFromRequestParams,
    token: confirmTokenFromRequestParam,
  } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const isProcessing = false

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // useIonViewWillEnter(() => {
  // })

  useIonViewDidLeave(() => {
    pageMessages && pageMessages.clear()
    setToastMessage('')
  })

  // ===================================================================================================================
  // Event Handlers:
  const onCancelResetPasswordForm = (): void => {
    pageMessages && pageMessages.clear()
    navigate(AppRoute.SIGN_IN, true)
  }

  const onNextFromResetPasswordForm = (): void => {
    setToastMessage('Successfully reset password')
    setShowToast(true)
    pageMessages && pageMessages.clear()
    navigate(AppRoute.HOME)
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('ResetPasswordPage.render called.', { taskIdFromRequestParams, confirmTokenFromRequestParam })
  return (
    <IonPage className='app-page-public reset-password-page'>
      <NavBar
        title='Reset Password'
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <ResetPasswordForm
          taskIdFromRequestParams={taskIdFromRequestParams}
          confirmTokenFromRequestParams={confirmTokenFromRequestParam}
          onCancel={onCancelResetPasswordForm}
          onNext={onNextFromResetPasswordForm}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={3000}
      />
    </IonPage>
  )
}

export default ResetPasswordPage
