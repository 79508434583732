import moment from 'moment'
import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { bagHandleOutline } from 'ionicons/icons'

import '../styles.css'
import type { Order } from '../../../lib/core/definitions'
import type { InfoPaneEntryDef } from '../../InfoPane/InfoPane'
import coreHelpers from '../../../lib/core/helpers'
import InfoPane from '../../InfoPane/InfoPane'

type Props = {
  order: Order
  className?: string
  onOpenOrder?: (orderId: string) => void
}

const OrderObjectInfo: React.FC<Props> = (props): JSX.Element | null => {
  const { order, className, onOpenOrder } = props

  const entries: InfoPaneEntryDef[] = [
    {
      key: 'date',
      label: 'Date',
      value: moment(Number(order.createdAt)).format('MM/DD/YYYY hh:mm a'),
    },
    {
      key: 'status',
      label: 'Status',
      value: coreHelpers.type.orderStatus.getLabel(order.status),
    },
    {
      key: 'id',
      label: 'Reference ID',
      value: coreHelpers.ui.formatModelId(order.id),
    },
  ]

  let openButton: JSX.Element | undefined
  if (onOpenOrder && order.id) {
    openButton = (
      <IonButton
        size='small'
        fill='outline'
        onClick={() => onOpenOrder(order.id as string)}
      >
        Open
      </IonButton>
    )
  }
  return (
    <div className='object-info'>
      <div className={`withStandardBottomMargin section-header ${className || ''}`}>
        <IonIcon icon={bagHandleOutline} className='section-icon' /> Order
      </div>
      <div className='section-content'>
        <InfoPane
          entries={entries}
        />
        {openButton}
      </div>
    </div>
  )
}

export default OrderObjectInfo
