import React, { useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { add, chevronForward, informationCircleOutline } from 'ionicons/icons'
import { Fab } from '@mui/material'

import './styles.css'
import { AppFeature, FiatCurrency, FundType, TokenName } from '../../lib/core/enums'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import type { Wallet, WalletListItem } from '../../lib/core/definitions'
import coreHelpers from '../../lib/core/helpers'
import MimbleTokenImage from '../../components/MimbleTokenImage/MimbleTokenImage'

type Props = {
  wallet: Wallet | WalletListItem | null | undefined
  className?: string
  onBuyTokens?: () => void
  onOpenEarnRewardsPage?: () => void
};

let isShowingFiatBalanceTimeout: NodeJS.Timeout | undefined

const TokensHeader: React.FC<Props> = (props): JSX.Element | null => {
  const {
    wallet,
    className,
    onBuyTokens,
    onOpenEarnRewardsPage,
  } = props

  // ===================================================================================================================
  // State:
  const { getActiveUser } = useGlobalCache()

  const localUser = getActiveUser()
  // const userCanBuyTokens = coreHelpers.models.user.canPurchase(localUser)
  const userCanBuyTokens = coreHelpers.models.user.hasAppFeature(
    localUser && localUser.appFeatures,
    AppFeature.BUY_WITH_CREDIT_CARD,
  )

  const [isShowingFiatBalance, setIsShowingFiatBalance] = useState(false)

  if (!wallet) {
    return null
  }

  let formattedWalletBalance = ''
  let balanceCurrency = ''
  if (isShowingFiatBalance) {
    const fiatAmount = coreHelpers.cryptoCurrency.convertMimbleTokenToFiat(
      wallet.balanceToken || 0,
    )
    formattedWalletBalance = coreHelpers.ui.formatAmount(
      fiatAmount,
      FundType.FIAT,
      FiatCurrency.USD,
      false,
    )
    balanceCurrency = FiatCurrency.USD
  } else {
    formattedWalletBalance = coreHelpers.ui.formatAmount(
      wallet.balanceToken,
      FundType.TOKEN,
      TokenName.MIMBLE_TOKEN,
      false,
    )
    balanceCurrency = TokenName.MIMBLE_TOKEN
  }

  // ===================================================================================================================
  // Event Handlers:
  const onShowFiatBalance = () => {
    const newValue = !isShowingFiatBalance
    setIsShowingFiatBalance(newValue)
    if (newValue && !isShowingFiatBalanceTimeout) {
      isShowingFiatBalanceTimeout = setTimeout(() => {
        setIsShowingFiatBalance(false)
        isShowingFiatBalanceTimeout = undefined
      }, 2500)
    }
  }

  const handleNext = () => {
    if (onOpenEarnRewardsPage) {
      onOpenEarnRewardsPage()
    }
    // if (onShowChart) {
    //   onShowChart()
    // }
  }

  // ===================================================================================================================
  // Rendering:
  // We're disabling the buttons downline, and making them transparent here
  const buttonOpacity = onOpenEarnRewardsPage ? 100 : 0

  let addMITButton: JSX.Element | undefined
  if (onBuyTokens) {
    addMITButton = (
      <Fab
        color='primary'
        aria-label='add'
        className='add-mit-button'
      >
        <IonIcon icon={add} size='large' />
      </Fab>
    )
  }

  return (
    <div className={`wallet-header ${className || ''}`}>
      <div className='content-row0'>
        <div
          className='content-col0'
          onClick={userCanBuyTokens ? onBuyTokens : onOpenEarnRewardsPage}
        >
          <div className='mit-button-wrapper'>
            <MimbleTokenImage className='token-image' />
            {addMITButton}
          </div>
        </div>
        <div className='content-col1'>
            <div className='balance-wrapper'>
              <span className='currency' onClick={onShowFiatBalance}>{balanceCurrency}</span>
              <span className='balance' onClick={onShowFiatBalance}>{formattedWalletBalance}</span>
              <IonButton
                size='small'
                fill='clear'
                style={{ opacity: buttonOpacity }}
                className='info-button'
                slot='icon-only'
                onClick={onOpenEarnRewardsPage}
                disabled={!onOpenEarnRewardsPage}
              >
                <IonIcon className='lightText' icon={informationCircleOutline} />
              </IonButton>
            </div>
            <div className='lightText withCenteredTextAlign'>
              <span className='xsmallText' onClick={onShowFiatBalance}>Your Mimble Token Balance</span>
            </div>
        </div>
        <div className='content-col2'>
          <IonButton
            fill='clear'
            color='medium'
            style={{ opacity: buttonOpacity }}
            onClick={handleNext}
            disabled={!onOpenEarnRewardsPage}
          >
            <IonIcon icon={chevronForward} size='large' className='withSmallRightMargin' />
          </IonButton>
        </div>
      </div>
    </div>
  )
}

export default TokensHeader
