import { Badge } from '@ionic-native/badge'
import { PushNotifications } from '@capacitor/push-notifications'
import logger from '../logger'

export default (): void => {
  console.log('services.capacitor.clearAppIconBadge called.')

  if (PushNotifications && PushNotifications.removeAllDeliveredNotifications) {
    console.log('services.capacitor.clearAppIconBadge: calling PushNotifications.removeAllDeliveredNotifications.')
    PushNotifications.removeAllDeliveredNotifications()
  }

  if (!Badge || !Badge.clear) {
    logger.warn('services.capacitor.clearAppIconBadge: badge plugin not available.')
    return
  }
  Badge.clear().then(() => {
    console.log('services.capacitor.clearAppIconBadge succeeded.')
  }, (error) => {
    logger.error('services.capacitor.clearAppIconBadge: received error.', { error })
  })
}
