import numberHelpers from '../../helpers/number'

const formatWeekday = (weekday: number): string => {
  if (!numberHelpers.isPositiveNumber(weekday)) {
    return ''
  }
  switch (weekday) {
    case 0:
      return 'Sun'
    case 1:
      return 'Mon'
    case 2:
      return 'Tue'
    case 3:
      return 'Wed'
    case 4:
      return 'Thu'
    case 5:
      return 'Fri'
    case 6:
      return 'Sat'
    default:
      return ''
  }
}

export default formatWeekday
