import type { PurchaseTransfer } from '../../../lib/core/definitions'

const getRecipientIdent = (purchaseTransfer?: PurchaseTransfer): string | null | undefined => {
  if (!purchaseTransfer) {
    return
  }
  return purchaseTransfer.toUserUsername ||
    purchaseTransfer.toUserEmail ||
    purchaseTransfer.toUserPhoneNumber ||
    purchaseTransfer.toUserId
}

export default getRecipientIdent
