import { PurchaseTransferStatus } from '../../enums'

const labelsEn = Object.freeze({
  [PurchaseTransferStatus.CREATING]: 'processing',
  [PurchaseTransferStatus.SCHEDULED]: 'scheduled',
  [PurchaseTransferStatus.AVAILABLE]: 'available',
  [PurchaseTransferStatus.ACCEPTED]: 'accepted',
  [PurchaseTransferStatus.DECLINED]: 'declined',
  [PurchaseTransferStatus.INVALID]: 'invalid',
  [PurchaseTransferStatus.CANCELED]: 'canceled',
})

export default {
  getLabel: (status: PurchaseTransferStatus | null | undefined, language = 'en'): string => {
    if (!status) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[status]
    }
    return ''
  },
  isValid: (status: PurchaseTransferStatus): boolean => Object.values(PurchaseTransferStatus).includes(status),
  isValidTransition: (
    status1: PurchaseTransferStatus | null | undefined,
    status2: PurchaseTransferStatus,
  ): boolean => {
    if (!status1) {
      // Should not happen, but not sure how to handle it.
      return true
    }
    if (status2 === PurchaseTransferStatus.ACCEPTED) {
      return status1 === PurchaseTransferStatus.AVAILABLE
    }
    return true
  },
}
