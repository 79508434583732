import type { ImportStoredProductItem, Vendor, VendorListItem } from '../../../definitions'
import type { InventoryImportFormat } from '../../../enums'
import parseImportStoredProductsLine from './parseImportStoredProductsLine'

export type PreflightImportStoredProductsDataResult = {
  ok: boolean;
  items?: ImportStoredProductItem[];
  itemsThatSucceeded?: ImportStoredProductItem[];
  error?: string;
};

const preflightImportStoredProductsData = (
  importData: string,
  productId: string,
  importIdPrefix: string,
  poNumber: string,
  source: string,
  format: InventoryImportFormat,
  vendors: Partial<Vendor | VendorListItem>[] | undefined,
): PreflightImportStoredProductsDataResult => {
  if (!importData) {
    return { ok: false, error: 'import data is required' }
  }
  const lines = importData.split('\n')
    .map(line => line.trim())
    .filter(line => !!line && !line.startsWith('#'))
  if (!Array.isArray(lines) || lines.length < 1) {
    return { ok: false, error: 'no valid rows found' }
  }
  const items = lines.map((line, index) =>
    parseImportStoredProductsLine(
      line,
      index,
      productId,
      importIdPrefix,
      poNumber,
      source,
      format,
      vendors,
    ))
    .filter(l => !l.ignore)

  if (!Array.isArray(items) || items.length < 1) {
    return { ok: false, error: 'no valid rows found' }
  }

  let error: string | undefined
  const itemsThatSucceeded = items.filter(item => item.ok)
  const itemsWithError = items.filter(item => item.error)

  if (itemsWithError.length > 0) {
    error = itemsWithError.map((item, index) => `Line #${index}: ${item.error}`).join(', ')
  }

  return {
    ok: Array.isArray(itemsThatSucceeded) && itemsThatSucceeded.length === items.length,
    error,
    items,
    itemsThatSucceeded,
  }
}

export default preflightImportStoredProductsData
