import React from 'react'
import { IonItem } from '@ionic/react'

import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import Avatar from '../Avatar/Avatar'

interface Props {
  onOpenAccountPage: () => void;
  className?: string;
}

export const ActiveUserInfo: React.FC<Props> = (props): JSX.Element | null => {
  const { onOpenAccountPage, className } = props
  const { activeUser } = useMimbleData()

  if (!activeUser) {
    return null
  }
  let text
  if (activeUser.fullName) {
    text = (
      <div className='profile-name-section'>
        <div>
          {activeUser.fullName.split(' ')[0]}
        </div>
        <div className='handle'>
          <span className='handleGlyph'>@</span>{activeUser.username}
        </div>
      </div>
    )
  } else {
    text = (
      <div className='large-handle'>
        <span className='handleGlyph'>@</span>{activeUser.username}
      </div>
    )
  }
  return (
    <IonItem
      className={`active-user-info ${className || ''}`}
      onClick={onOpenAccountPage}
      lines='none'
    >
      <Avatar
        user={activeUser}
        className='avatar'
      />
      {text}
    </IonItem>
  )
}

export default ActiveUserInfo
