import type { UserListFilter } from '../../../lib/core/definitions'
import type { UsersQueryVariables } from '../definitions'
import getUserListFilter from './getUserListFilter'

const getUsersQueryVariables = (
  listFilter: UserListFilter | undefined,
): UsersQueryVariables => ({
  filter: listFilter || getUserListFilter(),
})

export default getUsersQueryVariables
