import React, { useEffect, useState } from 'react'
import { IonButton, IonInput } from '@ionic/react'

import type { Currency, Purchase, PurchaseInput } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import formHelpers from '../../../helpers/formHelpers'
import FormItem from '../../../components/FormItem/FormItem'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../helpers/validationHelpers'

type Props = {
  purchase: Purchase;
  isProcessing?: boolean;
  onSubmit: (purchase: PurchaseInput) => void;
  onCancel: () => void;
};

const EditPurchaseTab: React.FC<Props> = ({
  purchase,
  isProcessing,
  onSubmit,
  onCancel,
}: Props): JSX.Element | null => {
  // ===================================================================================================================
  // State:
  const [balance, setBalance] = useState<number | undefined>()
  const [balanceValidationError, setBalanceValidationError] = useState<string | undefined>()
  const [currency, setCurrency] = useState<Currency | undefined>()
  const [currencyValidationError, setCurrencyValidationError] = useState<string | undefined>()
  const [code, setCode] = useState<string | undefined>()
  const [codeValidationError, setCodeValidationError] = useState<string | undefined>()
  const [pin, setPin] = useState<string | undefined>()
  const [pinValidationError, setPinValidationError] = useState<string | undefined>()
  const [notes, setNotes] = useState<string | undefined>()
  const [hasBarcode, setHasBarcode] = useState(undefined as boolean | undefined)
  const [hasBarcodeValidationError, setHasBarcodeValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const balanceChanged = balance !== undefined && balance !== purchase.balance
  const currencyChanged = currency !== undefined && currency !== purchase.currency
  const codeChanged = (code !== undefined && code !== purchase.code) || code === null
  const pinChanged = (pin !== undefined && pin !== purchase.pin) || pin === null

  const isDirty = balanceChanged || currencyChanged || codeChanged || pinChanged
  const isValid = (
    !balanceValidationError &&
    !currencyValidationError &&
    !codeValidationError &&
    !pinValidationError &&
    !hasBarcodeValidationError
  )

  const resetForm = (): void => {
    setBalance(undefined)
    setCurrency(undefined)
    setCode(undefined)
    setPin(undefined)
    setHasBarcode(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('ProfileForm: new purchase received - resetting form.')
    resetForm()
  }, [purchase])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeBalance = (event: any): void => {
    if (
      (purchase && (event.detail.value === purchase.balance || (!event.detail.value && !purchase.balance))) ||
      (!purchase && !event.detail.value)
    ) {
      setBalanceValidationError('')
      setBalance(undefined)
      return
    }
    setBalance(parseFloat(event.detail.value))
    if (event.detail.value) {
      setBalanceValidationError(validationHelpers.validatePurchaseBalance(event.detail.value))
    } else {
      setBalanceValidationError('')
    }
  }

  const onChangeCurrency = (event: any): void => {
    if (
      (purchase && (event.detail.value === purchase.currency || (!event.detail.value && !purchase.currency))) ||
      (!purchase && !event.detail.value)
    ) {
      setCurrencyValidationError('')
      setCurrency(undefined)
      return
    }
    setCurrency(event.detail.value)
    if (event.detail.value) {
      setCurrencyValidationError(validationHelpers.validateCurrency(event.detail.value))
    } else {
      setCurrencyValidationError('')
    }
  }

  const onChangeCode = (event: any): void => {
    if (
      (purchase && (event.detail.value === purchase.code || (!event.detail.value && !purchase.code))) ||
      (!purchase && !event.detail.value)
    ) {
      setCodeValidationError('')
      setCode(undefined)
      return
    }
    setCode(event.detail.value)
    if (event.detail.value) {
      setCodeValidationError(validationHelpers.validatePurchaseCode(event.detail.value))
    } else {
      setCodeValidationError('')
    }
  }

  const onChangePin = (event: any): void => {
    if (
      (purchase && (event.detail.value === purchase.pin || (!event.detail.value && !purchase.pin))) ||
      (!purchase && !event.detail.value)
    ) {
      setPinValidationError('')
      setPin(undefined)
      return
    }
    setPin(event.detail.value)
    if (event.detail.value) {
      setPinValidationError(validationHelpers.validatePurchasePin(event.detail.value))
    } else {
      setPinValidationError('')
    }
  }

  const onChangeNotes = (event: any): void => {
    setNotes(event.detail.value)
  }

  const onSubmitForm = (event: any): void => {
    event.preventDefault()
    const changes: PurchaseInput = { id: purchase.id }
    if (balance !== undefined && balance !== purchase.balance) {
      changes.balance = coreHelpers.ui.getInternalMoneyAmount(
        balance,
        purchase.fundType,
        currency || purchase.currency,
      )
    }
    if (currency !== undefined && currency !== purchase.currency) {
      changes.currency = currency
    }
    if (code !== undefined && code !== purchase.code) {
      changes.code = code
    }
    if (pin !== undefined && pin !== purchase.pin) {
      changes.pin = pin
    }
    if (hasBarcode !== undefined && hasBarcode !== purchase.hasBarcode) {
      changes.hasBarcode = hasBarcode
    }
    if (notes) {
      changes.historyEventNotes = notes
    }
    onSubmit(changes)
  }

  // ===================================================================================================================
  // Rendering:
  const showCurrency = false
  let currencySection
  if (showCurrency) {
    currencySection = (
      <FormItem
        label='Currency'
        validationError={currencyValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeCurrency}
          type='text'
          placeholder='currency'
          value={currency}
        />
      </FormItem>
    )
  }

  return (
    <div className='g-with-safe-padding'>
      <form onSubmit={onSubmitForm}>
        <FormItem
          label='Balance'
          validationError={balanceValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeBalance}
            type='number'
            inputmode='decimal'
            placeholder='Balance'
            value={formHelpers.getAmountFormDisplayValue(balance, purchase, 'balance', 1000)}
          />
        </FormItem>
        {currencySection}
        <FormItem
          label='Code'
          validationError={codeValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeCode}
            type='text'
            placeholder='Code'
            value={code !== undefined ? code : (purchase ? purchase.code : '')}
          />
        </FormItem>
        <FormItem
          label='PIN'
          validationError={pinValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangePin}
            type='text'
            placeholder='PIN'
            value={pin !== undefined ? pin : (purchase ? purchase.pin : '')}
          />
        </FormItem>
        <FormItem label='Notes'>
          <IonInput
            onIonChange={onChangeNotes}
            type='text'
            maxlength={255}
            inputmode='text'
            placeholder="i.e. 'climbing shoes'"
            value={notes}
          />
        </FormItem>
        <div className='formLabel'>
          {notes ? `${255 - notes.length} characters left` : ''}
        </div>
        <div className='formButtonWrapper'>
          <IonButton
            color='light'
            className='withStandardRightMargin'
            onClick={onCancel}
          >
            Back
          </IonButton>
          <SubmitButton
            disabled={!isDirty || !isValid}
            onClick={onSubmitForm}
          >
            Submit
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

export default EditPurchaseTab
