import { IonButton, IonItem } from '@ionic/react'
import React from 'react'

import './styles.css'
import type { Tag } from '../../../lib/core/definitions'

type Props = {
  tag: Tag;
  showDetails?: boolean;
  onDeleteTag: (tagId: string) => void;
  onEditTag: (tagId: string) => void;
  onSelectTag: (tagId: string) => void;
};

const TagListRow: React.FC<Props> = (props): JSX.Element | null => {
  const {
    tag,
    showDetails,
    onDeleteTag,
    onEditTag,
    onSelectTag,
  } = props

  if (!tag) {
    return null
  }

  // ===================================================================================================================
  // Helpers:
  const tagId: string = tag.id as string

  // ===================================================================================================================
  // Event Handlers:

  // ===================================================================================================================
  // Rendering:
  let details
  if (showDetails) {
    details = (
      <div className='tagListItemRow1'>
        <IonButton className='tagListItemButton' onClick={() => onEditTag(tagId)}>
          Edit
        </IonButton>
        <IonButton className='tagListItemButton' onClick={() => onDeleteTag(tagId)}>
          Delete
        </IonButton>
      </div>
    )
  }

  return (
    <IonItem lines='none' className='tagListRow' onClick={() => onSelectTag(tagId)}>
      <div className='tagListRowItemContent'>
        <div className='tagListItemRow0'>
          <div className='tagListItemCol0'>
            {tag.name}
          </div>
          <div className='tagListItemCol1'>
            {tag.captionEn}
          </div>
          <div className='tagListItemCol2'>
            {tag.tagType}
          </div>
        </div>
        {details}
      </div>
    </IonItem>
  )
}

export default TagListRow
