import type { ReactElement } from 'react'
import React from 'react'
import { Text as PdfText, View } from '@react-pdf/renderer'
import type { Style } from '@react-pdf/types'
import Text from './Text'

type Props = {
  htmlElement: ReactElement | null
  style: Style
  itemStyle: Style
}

const UnorderedList: React.FC<Props> = (props): JSX.Element | null => {
  console.log('OrderedList.render called.', { props })
  const { htmlElement, style, itemStyle } = props
  if (!htmlElement) {
    return null
  }

  const mergedTextStyle = itemStyle
    ? { ...itemStyle, ...htmlElement.props.style, alignItems: 'baseline', marginBottom: 3 }
    : htmlElement.props.style
  const mergedBulletStyle = itemStyle
    ? { ...itemStyle, ...htmlElement.props.style, marginRight: 0.5 }
    : { ...htmlElement.props.style, marginRight: 0.5 }

  const listItems = htmlElement.props.children
    .filter((c: ReactElement | string): boolean => typeof c !== 'string')
    .map((item: JSX.Element, idx: number): JSX.Element => {
      return (
        <View key={idx} style={{ display: 'flex', flexDirection: 'row' }}>
          <PdfText style={mergedBulletStyle}>{idx + 1}.</PdfText>
          <Text style={mergedTextStyle} htmlElement={item} />
        </View>
      )
    })

  return (
    <View style={style}>
      {listItems}
    </View>
  )
}
export default UnorderedList
