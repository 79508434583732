import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { ApiQueryOptions, PurchaseTransfer } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  AcceptReceivedPurchaseMutationData,
  AcceptReceivedPurchaseMutationVariables,
  ApiClientOptions,
} from '../apollo/definitions'
import apollo from '../apollo'
import coreHelpers from '../../lib/core/helpers'
import deleteInboxItem from './cache/deleteInboxItem'
import loadPurchase from './loadPurchase'
import loadPurchaseTransfer from './loadPurchaseTransfer'
import logger from '../logger'

const acceptReceivedPurchase = (
  purchaseTransferId: string,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<PurchaseTransfer | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.acceptReceivedPurchase called.', { purchaseTransferId })

    if (!apolloClient) {
      logger.error('api.acceptReceivedPurchase: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<AcceptReceivedPurchaseMutationData, AcceptReceivedPurchaseMutationVariables> = {
      mutation: apollo.mutations.acceptReceivedPurchase,
      variables: { purchaseTransferId: purchaseTransferId },
    }

    apolloClient.mutate<AcceptReceivedPurchaseMutationData, AcceptReceivedPurchaseMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.acceptReceivedPurchase: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.acceptReceivedPurchase ||
          !resp.data.acceptReceivedPurchase.id
        ) {
          logger.error('api.acceptReceivedPurchase: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        setTimeout(() => {
          // console.log('api.acceptReceivedPurchase: calling loadPurchaseTransfer')
          loadPurchaseTransfer(
            purchaseTransferId,
            coreHelpers.models.purchaseTransfer.isPurchaseTransferFinished,
            apollo.getPurchaseTransferListFilter(activeUserId),
            activeUserId,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((purchaseTransfer) => {
              if (purchaseTransfer && purchaseTransfer.toPurchaseId) {
                deleteInboxItem(
                  undefined,
                  purchaseTransfer.toPurchaseId,
                  purchaseTransfer.toUserId as string,
                  apolloClient,
                )
                loadPurchase(
                  purchaseTransfer.toPurchaseId,
                  undefined,
                  apollo.getPurchaseListFilter(activeUserId),
                  activeUserId,
                  apolloClient,
                  undefined,
                ).then(() => {
                  // console.log('api.acceptReceivedPurchase: resolving with purchase transfer', purchaseTransfer)
                  resolve(purchaseTransfer)
                }, (error) => {
                  logger.error('api.acceptReceivedPurchase: error', { error })
                  reject(error)
                })
              }
            }, (error) => {
              logger.error('api.acceptReceivedPurchase: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.acceptReceivedPurchase: error', { error })
        reject(error)
      })
  })
)

export default acceptReceivedPurchase
