export enum PageMessageType {
  NOTICE = 'notice',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum LogLevel {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export enum SelectPersonMode {
  NOT_SET = '',
  CONTACT_LIST = 'contact-list',
  IDENT = 'ident',
  ID = 'id',
  USERNAME = 'username',
  FULL_NAME = 'full-name',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone-number'
}

export enum AppPage {
  AboutPage = 'AboutPage',
  AdminCampaignPage = 'AdminCampaignPage',
  AdminCampaignsPage = 'AdminCampaignsPage',
  AdminDashboardPage = 'AdminDashboardPage',
  AdminEditCampaignPage = 'AdminEditCampaignPage',
  AdminEditMemberMessageTemplatePage = 'AdminEditMemberMessageTemplatePage',
  AdminEditMerchantPage = 'AdminEditMerchantPage',
  AdminEditProductOptionPage = 'AdminEditProductOptionPage',
  AdminEditProductPage = 'AdminEditProductPage',
  AdminEditStoredProductPage = 'AdminEditStoredProductPage',
  AdminEditTagPage = 'AdminEditTagPage',
  AdminEditUserPage = 'AdminEditUserPage',
  AdminExecuteAdminTaskPage = 'AdminExecuteAdminTaskPage',
  AdminImportDataPage = 'AdminImportDataPage',
  AdminMemberMessageTemplatePage = 'AdminMemberMessageTemplatePage',
  AdminMemberMessageTemplatesPage = 'AdminMemberMessageTemplatesPage',
  AdminMerchantsPage = 'AdminMerchantsPage',
  AdminNewTransactionPage = 'AdminNewTransactionPage',
  AdminOrgChatPage = 'AdminOrgChatPage',
  AdminOrgMessagesPage = 'AdminOrgMessagesPage',
  AdminProductPage = 'AdminProductPage',
  AdminProductsPage = 'AdminProductsPage',
  AdminPurchasesPage = 'AdminPurchasesPage',
  AdminReportsPage = 'AdminReportsPage',
  AdminSignInAsAnotherUserPage = 'AdminSignInAsAnotherUserPage',
  AdminStoredProductsPage = 'AdminStoredProductsPage',
  AdminTagsPage = 'AdminTagsPage',
  AdminUserPage = 'AdminUserPage',
  AdminUsersPage = 'AdminUsersPage',
  BarcodePage = 'BarcodePage',
  BuyTokenPage = 'BuyTokenPage',
  ChatPage = 'ChatPage',
  CheckoutPaymentReceivedPage = 'CheckoutPaymentReceivedPage',
  CheckoutProductOptionPage = 'CheckoutProductOptionPage',
  ConfirmAsyncTaskTokenPage = 'ConfirmAsyncTaskTokenPage',
  ContactPage = 'ContactPage',
  ContactsPage = 'ContactsPage',
  DashboardPage = 'DashboardPage',
  GiftReceivedPage = 'GiftReceivedPage',
  HelpPage = 'HelpPage',
  InvitePage = 'InvitePage',
  JoinPage = 'JoinPage',
  LoggingPage = 'LoggingPage',
  MarketplacePage = 'MarketplacePage',
  MessagesPage = 'MessagesPage',
  NewInvitationPage = 'NewInvitationPage',
  NewUploadedPurchasePage = 'NewUploadedPurchasePage',
  OrderPage = 'OrderPage',
  PurchasePage = 'PurchasePage',
  ResetPasswordPage = 'ResetPasswordPage',
  RewardPage = 'RewardPage',
  SelectMerchantPage = 'SelectMerchantPage',
  SendGiftPage = 'SendGiftPage',
  ShoppingCartPage = 'ShoppingCartPage',
  SignInPage = 'SignInPage',
  TokensPage = 'TokensPage',
  TransactionPage = 'TransactionPage',
  UserAccountPage = 'UserAccountPage',
  WalletPage = 'WalletPage',
  WelcomePage = 'WelcomePage',
}

export enum AppRoute {
  ABOUT = '/about',
  ADMIN_CAMPAIGN = '/admin/campaign',
  ADMIN_CAMPAIGNS = '/admin/campaigns',
  ADMIN_DASHBOARD = '/admin',
  ADMIN_EDIT_CAMPAIGN = '/admin/edit-campaign',
  ADMIN_EDIT_MEMBER_MESSAGE_TEMPLATE = '/admin/edit-mem-msg-templ',
  ADMIN_EDIT_USER = '/admin/edit-user',
  ADMIN_EXECUTE_ADMIN_TASK = '/admin/admin-task',
  ADMIN_HOME = '/admin',
  ADMIN_MEMBER_MESSAGE_TEMPLATE = '/admin/mem-msg-templ',
  ADMIN_MEMBER_MESSAGE_TEMPLATES = '/admin/mem-msg-templs',
  ADMIN_MERCHANT = '/admin/merchant',
  ADMIN_MERCHANTS = '/admin/merchants',
  ADMIN_ORG_CHAT = '/admin/mimble-team-chat',
  ADMIN_PRODUCT = '/admin/product',
  ADMIN_PRODUCTS = '/admin/products',
  ADMIN_REPORTS = '/admin/reports',
  ADMIN_SIGN_IN_AS = '/admin/sign-in-as',
  ADMIN_TAGS = '/admin/tags',
  ADMIN_USER = '/admin/user',
  ADMIN_USERS = '/admin/users',
  BARCODE = '/barcode',
  BUY_MIMBLE_TOKENS = '/buy-mit',
  CHECKOUT_PRODUCT_OPTION = '/checkout-option',
  CONTACT = '/contact',
  CONTACT_MESSAGES = '/messages',
  CONTACTS = '/contacts',
  CREATE_TAG = '/admin/add-tag',
  DASHBOARD = '/dashboard',
  EDIT_TAG = '/admin/edit-tag',
  GIFT_CARD = '/card',
  GIFT_RECEIVED = '/gift',
  HELP = '/help',
  HOME = '/wallet',
  INVITE = '/invite',
  JOIN = '/join',
  LOGGING = '/logging',
  MARKETPLACE = '/marketplace',
  MESSAGES = '/messages',
  NEW_INVITATION = '/new-invite',
  NEW_TAG = '/admin/new-tag',
  ORDER = '/order',
  ADMIN_ORG_MESSAGES = '/admin/mimble-team-messages',
  PAYMENT_RECEIVED = '/payment-received',
  RESET_PASSWORD = '/reset-password',
  REWARD = '/reward',
  SEND_GIFT = '/send-gift',
  SHOPPING_CART = '/cart',
  SIGN_IN = '/sign-in',
  TAGS = '/admin/tags',
  TOKENS = '/mit',
  TRANSACTION = '/transaction',
  UPLOAD_GIFT_CARD = '/select-brand/upload-card',
  USER_ACCOUNT = '/account',
  WALLET = '/wallet',
  WELCOME = '/welcome',
}

export enum GiftFlowStep {
  RECIPIENT = 'recipient',
  GIFT = 'gift',
  SCHEDULE = 'schedule',
  MESSAGE = 'message',
  CONFIRM = 'confirm',
  FINISHED = 'finished',
}

export enum GiftFlowDeliveryType {
  NOW = 'now',
  LATER = 'later',
  BY_USER = 'by-user',
}

export enum GiftFlowDeliveryTimeChoice {
  MORNING = 'morning',
  MIDDAY = 'midday',
  AFTERNOON = 'afternoon',
  NIGHT = 'night',
  ADVANCED_INPUT = 'advanced-input',
}

export enum LocalPreference {
  SHOW_NEW_USER_UPLOAD_HELP = 'show-new-user-upload-help',
  SHOW_NEW_USER_SEND_HELP = 'show-new-user-send-help',
  EXPAND_CONTACT_HEADER = 'expand-contact-header',
  SHOW_TOKEN_TRANSFER_NOTICE = 'show-token-transfer-notice',
}

export enum InputVerificationStatus {
  VERIFY = 'verify',
  PROCESSING = 'processing',
  VALID = 'valid',
  INVALID = 'invalid',
}
