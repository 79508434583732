import React, { useContext, useEffect, useState } from 'react'
import { IonButton } from '@ionic/react'
import { useApolloClient } from '@apollo/client'
import { alertCircleOutline } from 'ionicons/icons'

import './styles.css'
import type { AsyncTask, AsyncTaskInput } from '../../../../lib/core/definitions'
import {
  AsyncTaskStatus,
  AsyncTaskType,
  NotificationMethod,
} from '../../../../lib/core/enums'
import { useMimbleData } from '../../../../contexts/MimbleDataContext/MimbleDataContext'
import type { FormRadioItem } from '../../../../components/FormRadioGroup/FormRadioGroup'
import api from '../../../../services/api'
import auth from '../../../../services/auth'
import coreHelpers from '../../../../lib/core/helpers'
import FormRadioGroup, { FormRadioLayout } from '../../../../components/FormRadioGroup/FormRadioGroup'
import InlineMessage from '../../../../components/InlineMessage/InlineMessage'
import PageMessagesContext from '../../../../contexts/pageMessagesContext'
import SubmitButton from '../../../../components/SubmitButton/SubmitButton'

type Props = {
  asyncTaskId: string | undefined
  onNotificationSent: (asyncTaskId: string) => void
  onClose: () => void
  showUiMessage: (message: string) => void
}

const InitialScreen: React.FC<Props> = (props): JSX.Element => {
  // console.log('InitialScreen.render called.', { props })
  const {
    asyncTaskId,
    onClose,
    onNotificationSent,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [notificationMethod, setNotificationMethod] = useState(NotificationMethod.SMS)
  const [errorMessage, setErrorMessage] = useState<string | undefined>('HI')
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    setErrorMessage(undefined)
  }, [asyncTaskId])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeNotificationMethod = (newNotificationMethod: NotificationMethod): void => {
    // console.log('ResetPasswordForm.onChangeNotificationMethod called.', newNotificationMethod)
    setNotificationMethod(newNotificationMethod)
  }

  const onSendNotification = (): void => {
    console.log('InitialScreen.onSendNotification called.')

    const processErrorResponse = (error?: Error, errorMessage?: string): void => {
      console.error('InitialScreen.onSendNotification.processReturnedAsyncTask: error recorded.', { error, errorMessage })
      setIsProcessing(false)
      let reason = errorMessage
      if (!reason && error) {
        reason = error.message
      }
      let msg = 'Failed to send notification.'
      if (reason) {
        msg += ` (${reason})`
      }
      // pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SENDING_NOTIFICATION)
      setErrorMessage(msg)
      showUiMessage(msg)
    }

    const processReturnedAsyncTask = (task: AsyncTask | undefined): void => {
      setIsProcessing(false)
      if (!task) {
        console.error('InitialScreen.onSendNotification.processReturnedAsyncTask: no task returned.', { task })
        return
      }

      console.log('InitialScreen.onSendNotification.processReturnedAsyncTask: task returned.', { task })

      if (coreHelpers.type.asyncTaskResult.isFailedToSendNotificationResult(task.result)) {
        processErrorResponse(undefined, coreHelpers.type.asyncTaskResult.getLabel((task.result)))
        return
      }

      if (task.taskStatus === AsyncTaskStatus.NOTIFICATION_FAILED) {
        processErrorResponse()
        return
      }

      if (task.taskStatus === AsyncTaskStatus.NOTIFICATION_SENT) {
        onNotificationSent(task.id as string)
        return
      }

      console.error('InitialScreen.onSendNotification.processReturnedAsyncTask: task in unexpected state.', task)
    }

    pageMessages && pageMessages.clear()

    if (asyncTaskId) {
      setIsProcessing(true)
      setErrorMessage(undefined)
      auth.sendAsyncTaskNotification(
        asyncTaskId,
        notificationMethod,
        undefined,
        undefined,
        undefined,
        apolloClient,
      )
        .then(processReturnedAsyncTask, processErrorResponse)
      return
    }
    const taskInput: AsyncTaskInput = {
      userId: activeUserId,
      taskType: AsyncTaskType.RESET_PASSWORD,
      notificationMethod,
    }
    api.upsertAsyncTask(
      taskInput,
      true,
      coreHelpers.models.asyncTask.isNewTaskSendingNotificationCompleted,
      apolloClient,
    ).then(processReturnedAsyncTask, processErrorResponse)
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('InitialScreen: rending...', {
  //   confirmToken, enteredConfirmToken, confirmTokenFromRequestParams, formState, asyncTask, userIdent,
  // })
  let notificationMethodSection: JSX.Element | undefined
  if (
    activeUser &&
    activeUser.email &&
    activeUser.emailVerified &&
    activeUser.phoneNumber &&
    activeUser.phoneNumberVerified
  ) {
    const notificationMethodOptions: FormRadioItem[] = [
      {
        value: NotificationMethod.SMS,
        label: 'Text message to my phone',
        isChecked: notificationMethod === NotificationMethod.SMS,
      },
      {
        value: NotificationMethod.EMAIL,
        label: 'Email',
        isChecked: notificationMethod === NotificationMethod.EMAIL,
      },
    ]
    notificationMethodSection = (
      <FormRadioGroup
        items={notificationMethodOptions}
        layout={FormRadioLayout.vertical}
        className='withStandardBottomMargin'
        onChange={onChangeNotificationMethod}
      />
    )
  }

  return (
    <div className='user-account-reset-password-form'>
      <div key='intro' className='withStandardBottomMargin'>
        <div key='intro' className='withStandardBottomMargin'>
          To reset your password, we will send you a 6-digit code.
        </div>
        {notificationMethodSection}
        <InlineMessage
          text={errorMessage}
          icon={alertCircleOutline}
          color='var(--ion-color-warning)'
        />
      </div>
      <div key='buttons' className='formButtonWrapper'>
        <IonButton
          size='small'
          color='medium'
          className='withStandardRightMargin'
          onClick={onClose}
        >
          Cancel
        </IonButton>
        <SubmitButton
          size='small'
          onClick={onSendNotification}
          isProcessing={isProcessing}
        >
          Send Token
        </SubmitButton>
      </div>
    </div>
  )
}

export default InitialScreen
