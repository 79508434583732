import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  DeleteInboxItemMutationData,
  DeleteInboxItemMutationVariables,
} from '../apollo/definitions'
import type { ApiQueryOptions, InboxItem } from '../../lib/core/definitions'
import apollo from '../apollo'
import deleteInboxItemInCache from './cache/deleteInboxItem'
import logger from '../logger'

const deleteInboxItem = (
  inboxItemId: string | undefined,
  modelId: string | undefined,
  userId: string | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<InboxItem | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.deleteInboxItem called.', { inboxItemId, modelId, userId, options })

    if (!apolloClient) {
      logger.error('api.deleteInboxItem: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<DeleteInboxItemMutationData, DeleteInboxItemMutationVariables> = {
      mutation: apollo.mutations.deleteInboxItem,
      variables: {
        inboxItemId: inboxItemId,
        modelId: modelId,
        userId: userId,
      },
    }

    apolloClient.mutate<DeleteInboxItemMutationData, DeleteInboxItemMutationVariables>(queryParams)
      .then((resp) => {
        if (
          !resp ||
          !resp.data ||
          !resp.data.deleteInboxItem ||
          !resp.data.deleteInboxItem.id
        ) {
          logger.error('api.deleteInboxItem: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }
        if (clientOptions && clientOptions.updateList) {
          deleteInboxItemInCache(
            resp.data.deleteInboxItem.id,
            undefined,
            undefined,
            apolloClient,
          )
        }
        // console.log('api.deleteInboxItem: mutation succeeded.', resp)
        resolve(resp.data.deleteInboxItem)
      }, (error) => {
        logger.error('api.deleteInboxItem: error', { error })
        reject(error)
      })
  })
)

export default deleteInboxItem
