import React, { useEffect, useRef } from 'react'
import type { ToBufferOptions } from 'bwip-js'
import bwipjs from 'bwip-js'
import { IonIcon, IonButton } from '@ionic/react'
import { search } from 'ionicons/icons'

import './styles.css'
import { BarcodeType } from '../../lib/core/enums'

const barcodeTypeMapping = {
  [BarcodeType.TYPE_39]: 'code128',
  [BarcodeType.TYPE_128]: 'code128',
  [BarcodeType.CODE_25]: 'code128',
  [BarcodeType.ITF]: 'code128',
  [BarcodeType.I125]: 'code128',
  [BarcodeType.UPC_A]: 'code128',
  [BarcodeType.UPC_E]: 'code128',
  [BarcodeType.EAN_13]: 'code128',
  [BarcodeType.EAN_8]: 'code128',
  [BarcodeType.QR_CODE]: 'qrcode',
  [BarcodeType.PDF417]: 'pdf417',
  [BarcodeType.DATA_MATRIX]: 'code128',
}

type Props = {
  value: string
  format?: BarcodeType | null
  scale?: number
  scaleX?: number
  scaleY?: number
  width?: number
  height?: number
  rotate?: 'N' | 'R' | 'L' | 'I'
  displayValue?: boolean
  displayZoomButton?: boolean
};

const Barcode: React.FC<Props> = (props): JSX.Element | null => {
  const {
    value,
    format,
    scale,
    scaleX,
    scaleY,
    width,
    height,
    rotate,
    displayValue,
    displayZoomButton,
  } = props
  const renderElement = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (renderElement.current) {
      // see https://github.com/metafloor/bwip-js
      const opts: ToBufferOptions = {
        bcid: barcodeTypeMapping[format || BarcodeType.TYPE_128], // Barcode type
        text: value, // Text to encode
        rotate: rotate, // N, R, L, or I
        includetext: displayValue, // Show human-readable text
      }
      if (scale) {
        opts.scale = scale
      }
      if (scaleX) {
        opts.scaleX = scaleX
      }
      if (scaleY) {
        opts.scaleY = scaleY
      }
      if (width) {
        opts.width = width
      }
      if (height) {
        opts.height = height
      }
      bwipjs.toCanvas(renderElement.current, opts)
    }
  }, [renderElement])

  let zoomButton: JSX.Element | undefined
  if (displayZoomButton) {
    zoomButton = (
      <div className='zoom-button-wrapper'>
        <IonButton
          fill='clear'
          size='small'
          shape='round'
          slot='icon-only'
        >
          <IonIcon icon={search} className='zoom-icon' />
        </IonButton>
      </div>
    )
  }

  return (
    <div className='gift-card-barcode'>
      <canvas ref={renderElement} />
      {zoomButton}
    </div>
  )
}

export default Barcode
