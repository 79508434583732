import { useEffect, useState } from 'react'

//   see:
//   https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
//   https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
export default function useOnScreen (ref: React.RefObject<Element>): boolean {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => {
      // console.log('>>>>>>>>>>IntersectionObserver callback called.', entry.isIntersecting)
      setIntersecting(entry.isIntersecting)
    },
  )

  useEffect(() => {
    if (ref.current) {
      // console.log('>>>>>>>>>>>>inViewport: have ref, starting to observe.')
      observer.observe(ref.current)
    }

    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}
