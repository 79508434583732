import React from 'react'
import { IonButton } from '@ionic/react'

import { useGiftFlow } from '../../../contexts/GiftFlowContext'
import type { UserIdentInfo } from '../../../lib/core/definitions'
import UserInfoCard from '../../../components/UserInfoCard/UserInfoCard'

type Props = {
  onStartEditing: (() => void) | undefined
}

const CurrentVal: React.FC<Props> = (props): JSX.Element | null => {
  const { onStartEditing } = props

  // ===================================================================================================================
  // State:
  const {
    giftChanges,
    purchaseTransfer,
    recipientUserIdentInfo,
  } = useGiftFlow()

  const getDisplayFullName = () => {
    if (giftChanges && giftChanges.toUserFullName) {
      return giftChanges.toUserFullName
    }
    if (purchaseTransfer && purchaseTransfer.toUserFullName) {
      return purchaseTransfer.toUserFullName
    }
    return ''
  }

  const getDisplayEmail = () => {
    if (giftChanges && giftChanges.toUserEmail) {
      return giftChanges.toUserEmail
    }
    if (purchaseTransfer && purchaseTransfer.toUserEmail) {
      return purchaseTransfer.toUserEmail
    }
    return ''
  }

  const getDisplayPhoneNumber = () => {
    if (giftChanges && giftChanges.toUserPhoneNumber) {
      return giftChanges.toUserPhoneNumber
    }
    if (purchaseTransfer && purchaseTransfer.toUserPhoneNumber) {
      return purchaseTransfer.toUserPhoneNumber
    }
    return ''
  }

  // ===================================================================================================================
  // Rendering:
  const giftTarget: UserIdentInfo | undefined = recipientUserIdentInfo ||
  {
    fullName: getDisplayFullName(),
    email: getDisplayEmail(),
    phoneNumber: getDisplayPhoneNumber(),
  }

  return (
    <div className='current-value'>
      <div className='withStandardBottomMargin'>
        Your gift will be sent to:
      </div>
      <UserInfoCard
        userIdentInfo={giftTarget}
        className='withStandardBottomMargin'
      />
      <div className='withRightTextAlign'>
        <IonButton
          size='small'
          className='withStandardLeftMargin'
          onClick={onStartEditing}
        >
          Change
        </IonButton>
      </div>
    </div>
  )
}

export default CurrentVal
