import moment from 'moment'
import type { MouseEvent } from 'react'
import { chevronDown } from 'ionicons/icons'
import React, { useState } from 'react'
import { IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonPopover,
  IonRow,
  IonTextarea
} from '@ionic/react'
import { useApolloClient } from '@apollo/client'
import { TextField } from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'

import './styles.css'
import type { Contact, ContactEvent, ContactEventInput, ContactEventReminderInput } from '../../../../../lib/core/definitions'
import { GiftingEventType } from '../../../../../lib/core/enums'
import { useMimbleData } from '../../../../../contexts/MimbleDataContext/MimbleDataContext'
import type { InfoPaneEntryDef } from '../../../../../components/InfoPane/InfoPane'
import api from '../../../../../services/api'
import ContactEventIcon from '../../../../../components/ContactEventIcon/ContactEventIcon'
import coreHelpers from '../../../../../lib/core/helpers'
import FormItem from '../../../../../components/FormItem/FormItem'
import InfoPane from '../../../../../components/InfoPane/InfoPane'
import ReminderForm from './ReminderForm'
import SubmitButton from '../../../../../components/SubmitButton/SubmitButton'

type Props = {
  event: ContactEvent
  contact: Contact
  onClose: () => void
  setEditingEventId: (eventId: string | undefined) => void
  showUiMessage: (message: string) => void
}

const EventForm: React.FC<Props> = (props): JSX.Element => {
  const {
    event,
    contact,
    onClose,
    setEditingEventId,
    showUiMessage,
  } = props
  // console.log('EventForm.render called.', { event, contact })

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [eventDate, setEventDate] = useState<Date | null | undefined>()
  const [eventDateValidationError, setEventDateValidationError] = useState<string | undefined>()
  const [name, setName] = useState<string | undefined | null>()
  const [nameValidationError, setNameValidationError] = useState<string | undefined>()
  const [notes, setNotes] = useState<string | undefined | null>()
  const [notesValidationError, setNotesValidationError] = useState<string | undefined>()
  const [eventType, setEventType] = useState<GiftingEventType | null | undefined>()
  const [isSaving, setIsSaving] = useState(false)
  const [isShowingNewReminderForm, setIsShowingNewReminderForm] = useState(false)
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false)

  const [
    contextMenuClickEvent,
    setContextMenuClickEvent,
  ] = useState(undefined as MouseEvent<Element, MouseEvent> | undefined)

  // const oldEventType = event && event.eventType
  const oldName = event && event.name
  const oldNotes = event && event.notes

  const newEventDate = eventDate || eventDate === null
    ? eventDate
    : (event && event.eventDate ? event.eventDate : null)
  const newEventType = (eventType || eventType === null) ? eventType : (event && event.eventType)
  const newName = name === null ? '' : (name || oldName)
  const newNotes = notes === null ? '' : (notes || oldNotes)

  // ===================================================================================================================
  // Helpers:
  const isDirty = (
    eventDate !== undefined ||
    eventType !== undefined ||
    name !== undefined ||
    notes !== undefined
  )
  const isValid = (
    !eventDateValidationError &&
    !nameValidationError &&
    !!newEventDate &&
    (
      !!newName || !!newEventType
    )
  )
  // console.log('>>>>>>>>>>>>>>>>>', { isDirty, isValid, name, reminder2, curName, curReminder2 })

  // ===================================================================================================================
  // Event Handlers:
  const onChangeName = (uiEvent: any): void => {
    if (uiEvent.detail.value) {
      setName(uiEvent.detail.value === oldName ? undefined : uiEvent.detail.value)
    } else {
      setName((oldName === null || oldName === undefined) ? undefined : null)
      setNameValidationError(undefined)
    }
  }

  const onChangeNotes = (uiEvent: any): void => {
    if (uiEvent.detail.value) {
      setNotes(uiEvent.detail.value === oldNotes ? undefined : uiEvent.detail.value)
    } else {
      setNotes((oldNotes === null || oldNotes === undefined) ? undefined : null)
      setNotesValidationError(undefined)
    }
  }

  const onChangeEventDate = (date: Date | null): void => {
    // console.log('EventForm.onChangeEventDate called.', { date })
    setEventDate(date)
  }

  const onSave = (uiEvent: any): void => {
    uiEvent.preventDefault()
    // console.log('EventForm.onSave called.', { event })

    if (!contact) {
      return
    }

    const isNew = !event || !event.id
    const contactEventInput: ContactEventInput = {}
    if (event && event.id) {
      contactEventInput.id = event.id
    } else {
      contactEventInput.contactId = contact.id as string
      contactEventInput.eventType = event.eventType || null
    }
    if (eventType && eventType !== event.eventType) {
      contactEventInput.eventType = eventType
    }
    if (eventDate || eventDate === null) {
      contactEventInput.eventDate = eventDate ? moment(eventDate).format('YYYY-MM-DD') : null
    }
    if (name) {
      contactEventInput.name = name
    }
    if (notes || notes === null) {
      contactEventInput.notes = notes
    }

    setIsSaving(true)
    api.upsertContactEvent(
      contactEventInput,
      isNew ? undefined : 'watch-updated-at',
      undefined,
      apolloClient,
    )
      .then((contactEvent) => {
        // console.log('api.upsertContactEvent returned.', { contactEvent })
        if (isNew && contactEvent) {
          setEditingEventId(contactEvent.id as string)
        }
        api.loadContact(
          contact.id,
          undefined,
          undefined,
          undefined,
          undefined,
          activeUserId as string,
          apolloClient,
        ).then(() => {
          showUiMessage && showUiMessage('Saved successfully')
          setIsSaving(false)
          setEventType(undefined)
          if (
            !isNew &&
            Array.isArray(event.reminders) &&
            event.reminders.length > 0
          ) {
            setEditingEventId(undefined)
            onClose()
            return
          }
          if (isNew) {
            onClose()
          }
        }, (error) => {
          console.error(error)
          setIsSaving(false)
        })
        // reloadContacts().then(() => {
        //   setIsSaving(false)
        //   showUiMessage && showUiMessage('Saved successfully')
        //   onClose()
        // }, (error) => {
        //   console.error(error)
        // })
      }, (error) => {
        setIsSaving(false)
        console.error(error)
        // showUiMessage && showUiMessage('Failed to save')
      })
  }

  const onCancel = () => {
    setEditingEventId(undefined)
    onClose()
  }

  const onCreateReminder = () => {
    setIsShowingNewReminderForm(true)
  }

  const openCategoryDropdown = (event: MouseEvent<Element, MouseEvent>): void => {
    event.persist()
    setShowCategoryDropdown(true)
    setContextMenuClickEvent(event)
  }

  const onDidDismissCategoryDropdown = (): void => {
    if (showCategoryDropdown) {
      setShowCategoryDropdown(false)
    }
  }

  const handleCategoryClick = (newEventType: GiftingEventType | null | undefined): void => {
    setShowCategoryDropdown(false)
    if (newEventType !== eventType) {
      if (newEventType === null) {
        setEventType(null)
      }
      setEventType(newEventType)
    }
  }

  // ===================================================================================================================
  // Rendering:
  const eventTypes = Object.values(GiftingEventType)
  let categoryDropdown: JSX.Element | undefined
  if (Array.isArray(eventTypes) && eventTypes.length > 0) {
    // In order to free up the cake for use outside birthdays,
    // we're providing NAME-DAY which comes with cake, and omitting BIRTHDAY events from being set at a Contact level.
    const filteredEventTypes = eventTypes.filter(et => et !== GiftingEventType.BIRTHDAY)
    const categoryItems = filteredEventTypes.map((e: GiftingEventType): JSX.Element => {
      return (
        <ContactEventIcon
          key={e.toString()}
          eventType={e}
          className='withSmallTopMargin'
          onClick={(): void => { handleCategoryClick(e) }}
        />
      )
    })
    categoryDropdown = (
    <>
      <IonPopover
        isOpen={showCategoryDropdown}
        event={contextMenuClickEvent as Event | undefined}
        onDidDismiss={onDidDismissCategoryDropdown}
        cssClass='pop-over'
      >
      <IonGrid>
        <IonRow key='onlyrow'>
          <IonCol key='col1'>
            <ContactEventIcon
              key='default'
              eventType={undefined}
              className='withSmallTopMargin'
              onClick={(): void => { handleCategoryClick(null) }}
            />
            {categoryItems.slice(0, 3)}
          </IonCol>
          <IonCol key='col2'>
            {categoryItems.slice(3, 7)}
          </IonCol>
          <IonCol key='col3'>
            {categoryItems.slice(7)}
          </IonCol>
        </IonRow>
      </IonGrid>
      </IonPopover>
      <div
        className='category-dropdown rowWithCenterAlignedItems withSmallRightMargin'
        onClick={openCategoryDropdown as unknown as any}
      >
        <ContactEventIcon eventType={eventType || newEventType} />
        <IonIcon icon={chevronDown} className='withSmallLeftMargin' class='category-dropdown-icon' />
      </div>
    </>
    )
  }

  let remindersSection: JSX.Element | undefined
  let addReminderSection: JSX.Element | undefined
  if (isShowingNewReminderForm) {
    const newReminder: ContactEventReminderInput = {
      eventId: event.id as string,
    }
    addReminderSection = (
      <div className='withStandardTopMargin withStandardBottomMargin'>
        <ReminderForm
          reminder={newReminder}
          event={event}
          contact={contact}
          onClose={() => setIsShowingNewReminderForm(false)}
          showUiMessage={showUiMessage}
        />
      </div>
    )
  } else {
    if (
      event.id &&
      (!Array.isArray(event.reminders) || event.reminders.length < 10)
    ) {
      addReminderSection = (
        <IonButton
          fill='outline'
          size='small'
          className='withStandardTopMargin withStandardBottomMargin'
          onClick={onCreateReminder}
        >
          Add Reminder
        </IonButton>
      )
    }
  }

  let reminderFormsSection: JSX.Element[] | undefined
  if (
    event &&
    Array.isArray(event.reminders) &&
    event.reminders.length > 0
  ) {
    reminderFormsSection = event.reminders.map((reminder) => (
      <ReminderForm
        key={reminder.id}
        reminder={reminder}
        event={event}
        contact={contact}
        showUiMessage={showUiMessage}
      />
    ))
  }
  const className = !isShowingNewReminderForm ? 'rowWithSpaceBetween' : 'withColumnDirection'
  remindersSection = (
    <>
      <div className={className}>
        <div className='smallText lightText withStandardBottomMargin'>Reminders</div>
        {addReminderSection}
      </div>
        {reminderFormsSection}
    </>
  )

  const notesFormItem = (
    <FormItem
      label='Notes'
      validationError={notesValidationError}
      withBottomMargin
    >
      <div className='rowWithCenterAlignedItems'>
        <IonTextarea
          autoGrow
          rows={3}
          maxlength={1000}
          onIonChange={onChangeNotes}
          value={newNotes}
        />
      </div>
    </FormItem>
  )

  let eventSection: JSX.Element | undefined
  if (event && event.eventType === GiftingEventType.BIRTHDAY) {
    const infoPaneEntries: InfoPaneEntryDef[] = [
      {
        key: 'type',
        label: 'Type',
        value: coreHelpers.models.contactEvent.getLabel(event),
      },
      {
        key: 'date',
        label: 'Date',
        value: coreHelpers.models.contactEvent.getDisplayValue(event),
      },
    ]
    eventSection = (
      <div className='withDoubleBottomMargin'>
        <InfoPane
          entries={infoPaneEntries}
          className='withDoubleBottomMargin'
        />
        {notesFormItem}
        {remindersSection}
      </div>
    )
  } else {
    const eventDateAsMoment = newEventDate
      ? moment(newEventDate, 'YYYY-MM-DD')
      : null
    const dateValue = eventDateAsMoment ? eventDateAsMoment.toDate() : null

    eventSection = (
      <div className='withStandardBottomMargin'>
        <FormItem
          label='Event Name'
          validationError={nameValidationError}
          withBottomMargin
        >
          <div className='rowWithCenterAlignedItems'>
            {categoryDropdown}
            <IonInput
              value={newName}
              placeholder='i.e. Wedding Anniversary'
              minlength={2}
              clearInput
              required
              onIonChange={onChangeName}
            />
          </div>
        </FormItem>
        <div className='withStandardBottomMargin'>
          <DatePicker
            label='Event Date'
            renderInput={(params) => (
              <TextField {...params} helperText={params?.inputProps?.placeholder} />
            )}
            value={dateValue}
            onChange={onChangeEventDate}
          />
        </div>
        {notesFormItem}
        {remindersSection}
      </div>
    )
  }

  return (
    <div className='event-form'>
      <form onSubmit={onSave}>
        {eventSection}
        <div className='form-buttons'>
          <IonButton
            size='small'
            color='light'
            className='withStandardRightMargin'
            onClick={onCancel}
          >
            Close
          </IonButton>
          <SubmitButton
            size='small'
            disabled={!isDirty || !isValid}
            isProcessing={isSaving}
            onClick={onSave}
          >
            Save
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

export default EventForm
