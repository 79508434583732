import type { ContactEventReminder } from '../../../definitions'
import { RecordStatus } from '../../../enums'
import contactEventReminderTimingHelpers from '../../type/contactEventReminderTimingHelpers'

const getReminderShortLabel = (reminder: ContactEventReminder): string => {
  if (
    !reminder ||
    reminder.timing === null ||
    reminder.timing === undefined ||
    !Number.isInteger(reminder.timing) ||
    reminder.recordStatus !== RecordStatus.ACTIVE
  ) {
    return ''
  }

  return contactEventReminderTimingHelpers.getShortLabel(reminder.timing)
}

const getRemindersText = (reminders: ContactEventReminder[] | null | undefined): string => {
  if (!Array.isArray(reminders) || reminders.length < 1) {
    return '(no reminders set)'
  }

  return reminders
    .map(r => getReminderShortLabel(r))
    .filter(t => t !== '')
    .join(', ')
}

export default getRemindersText
