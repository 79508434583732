import type { OrderListFilter } from '../../../lib/core/definitions'
import type { OrdersQueryVariables } from '../definitions'
import getOrderListFilter from './getOrderListFilter'

const getOrdersQueryVariables = (
  listFilter: OrderListFilter | undefined,
  activeUserId: string,
): OrdersQueryVariables => ({
  filter: listFilter || getOrderListFilter(activeUserId),
})

export default getOrdersQueryVariables
