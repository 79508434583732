import React, { useEffect, useState } from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import { callOutline, mailOutline, personOutline } from 'ionicons/icons'
import { parsePhoneNumber } from 'libphonenumber-js/mobile'

import { NotificationMethod } from '../../../lib/core/enums'
import type { PurchaseTransferInput } from '../../../lib/core/definitions'
import { useGiftFlow } from '../../../contexts/GiftFlowContext'
import FormItem from '../../../components/FormItem/FormItem'
import logger from '../../../services/logger'
import StepButtons from '../StepButtons'
import validationHelpers from '../../../helpers/validationHelpers'

type Props = {
  currentValue? : string
  cancelButtonLabel?: string
  onPrevStep: () => void
  onSaved: () => void
}

const NonUserForm: React.FC<Props> = (props): JSX.Element | null => {
  const {
    currentValue,
    cancelButtonLabel,
    onPrevStep,
    onSaved,
  } = props

  // ===================================================================================================================
  // State:
  const {
    flowId,
    giftChanges,
    purchaseTransfer,
    isProcessing,
    setGiftChanges,
  } = useGiftFlow()

  const [toUserFullName, setToUserFullName] = useState<string | undefined>()
  const [toUserFullNameValidationError, setToUserFullNameValidationError] = useState<string | undefined>()

  const [toUserEmail, setToUserEmail] = useState<string | null | undefined>()
  const [toUserEmailValidationError, setToUserEmailValidationError] = useState<string | undefined>()

  const [toUserPhoneNumber, setToUserPhoneNumber] = useState<string | null | undefined>()
  const [toUserPhoneNumberValidationError, setToUserPhoneNumberValidationError] = useState<string | undefined>()

  const getDisplayFullName = () => {
    if (toUserFullName === null) {
      return ''
    }
    if (currentValue && !toUserFullName) {
      return currentValue
    }
    if (toUserFullName) {
      return toUserFullName
    }
    if (giftChanges && giftChanges.toUserFullName) {
      return giftChanges.toUserFullName
    }
    if (purchaseTransfer && purchaseTransfer.toUserFullName) {
      return purchaseTransfer.toUserFullName
    }
    return ''
  }

  const getDisplayEmail = () => {
    if (toUserEmail === null) {
      return ''
    }
    if (toUserEmail) {
      return toUserEmail
    }
    if (giftChanges && giftChanges.toUserEmail) {
      return giftChanges.toUserEmail
    }
    if (purchaseTransfer && purchaseTransfer.toUserEmail) {
      return purchaseTransfer.toUserEmail
    }
    return ''
  }

  const getDisplayPhoneNumber = () => {
    if (toUserPhoneNumber === null) {
      return ''
    }
    if (toUserPhoneNumber) {
      return toUserPhoneNumber
    }
    if (giftChanges && giftChanges.toUserPhoneNumber) {
      return giftChanges.toUserPhoneNumber
    }
    if (purchaseTransfer && purchaseTransfer.toUserPhoneNumber) {
      return purchaseTransfer.toUserPhoneNumber
    }
    return ''
  }

  const displayFullName = getDisplayFullName()
  const displayEmail = getDisplayEmail()
  const displayPhoneNumber = getDisplayPhoneNumber()
  const isValid = (
    !!displayFullName &&
    (!!displayEmail || !!displayPhoneNumber) &&
    (!toUserFullNameValidationError &&
    !toUserEmailValidationError &&
    !toUserPhoneNumberValidationError)
  )

  // ===================================================================================================================
  // Helpers:
  const resetState = (): void => {
    setToUserFullName(undefined)
    setToUserFullNameValidationError(undefined)

    setToUserEmail(undefined)
    setToUserEmailValidationError(undefined)

    setToUserPhoneNumber(undefined)
    setToUserPhoneNumberValidationError(undefined)
  }

  // ===================================================================================================================
  // Effect Hooks:
  useEffect((): void => {
    resetState()
  }, [flowId, onPrevStep])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeToUserFullName = (event: any): void => {
    setToUserFullName(event.detail.value || null)
    if (event.detail.value) {
      setToUserFullNameValidationError(validationHelpers.validateUserFullName(event.detail.value))
    } else {
      setToUserFullNameValidationError('required')
    }
  }

  const onChangeToUserEmail = (event: any): void => {
    setToUserEmail(event.detail.value || null)
    if (event.detail.value) {
      setToUserEmailValidationError(validationHelpers.validateEmail(event.detail.value))
    } else {
      setToUserEmailValidationError(undefined)
    }
  }

  const onChangeToUserPhoneNumber = (event: any): void => {
    setToUserPhoneNumber(event.detail.value || null)
    if (event.detail.value) {
      setToUserPhoneNumberValidationError(validationHelpers.validatePhoneNumber(event.detail.value))
    } else {
      setToUserPhoneNumberValidationError(undefined)
    }
  }

  const onFormSubmit = (event?: any): void => {
    event && event.preventDefault()

    if (!isValid) {
      return
    }

    const changes: PurchaseTransferInput = {
      ...giftChanges,
      toUserId: null,
    }

    if (
      displayFullName &&
      (
        !purchaseTransfer ||
        displayFullName !== purchaseTransfer.toUserFullName
      )
    ) {
      changes.toUserFullName = displayFullName
    } else {
      delete changes.toUserFullName
    }

    if (
      displayEmail &&
      (
        !purchaseTransfer ||
        displayEmail !== purchaseTransfer.toUserEmail
      )
    ) {
      changes.toUserEmail = displayEmail
    } else {
      delete changes.toUserEmail
    }

    if (displayPhoneNumber) {
      try {
        const phoneNumberInfo = parsePhoneNumber(displayPhoneNumber, 'US')
        if (!phoneNumberInfo || !phoneNumberInfo.isValid()) {
          logger.error('NonUserForm.onFormSubmit: invalid phone number', { displayPhoneNumber })
          setToUserPhoneNumberValidationError('not a valid phone number')
          return
        }
        const phoneNumberValue = phoneNumberInfo.number as string
        if (purchaseTransfer && phoneNumberValue === purchaseTransfer.toUserPhoneNumber) {
          delete changes.toUserPhoneNumber
        } else {
          changes.toUserPhoneNumber = phoneNumberValue
        }
      } catch (error) {
        logger.warn('NonUserForm.onFormSubmit: invalid phone number', { displayPhoneNumber, error })
      }
    } else {
      delete changes.fromUserPhoneNumber
    }

    changes.notificationMethod = displayPhoneNumber ? NotificationMethod.SMS : NotificationMethod.EMAIL
    if (purchaseTransfer && changes.notificationMethod === purchaseTransfer.notificationMethod) {
      delete changes.notificationMethod
    }

    setGiftChanges(changes, false)
    onSaved()
  }

  // ===================================================================================================================
  // Rendering:
  let changeStateButton: JSX.Element | undefined
  if (!isProcessing) {
    changeStateButton = (
      <div
        className='rowWithCenterJustifiedItems lightText smallText withPointerCursor'
        onClick={onPrevStep}>{cancelButtonLabel || 'Return to search'}</div>
    )
  }

  return (
    <form onSubmit={onFormSubmit}>
      <div key='non-member'>
          <FormItem
            validationError={toUserFullNameValidationError}
            withBottomMargin
          >
            <IonInput
              onIonChange={onChangeToUserFullName}
              type='text'
              autocapitalize='on'
              placeholder='Joe Smith'
              className='input-padding'
              value={displayFullName}
            >
               <IonIcon size='small'
                slot='start'
                color='medium'
                className='withStandardLeftMargin withStandardRightMargin'
                icon={personOutline}
              />
            </IonInput>
          </FormItem>
          <FormItem
            validationError={toUserEmailValidationError}
            withBottomMargin
          >
            <IonInput
              onIonChange={onChangeToUserEmail}
              type='email'
              placeholder='their-email@domain.com'
              className='input-padding'
              value={displayEmail}
            >
              <IonIcon size='small'
                slot='start'
                color='medium'
                className='withStandardLeftMargin withStandardRightMargin'
                icon={mailOutline}
              />
            </IonInput>
          </FormItem>
          <FormItem
            validationError={toUserPhoneNumberValidationError}
            withBottomMargin
          >
            <IonInput
              onIonChange={onChangeToUserPhoneNumber}
              type='tel'
              placeholder='123 456 7890'
              className='input-padding'
              value={displayPhoneNumber}
            >
              <IonIcon size='small'
                slot='start'
                color='medium'
                className='withStandardLeftMargin withStandardRightMargin'
                icon={callOutline}
              />
            </IonInput>
          </FormItem>
      </div>
      {changeStateButton}
      <StepButtons
        isProcessing={isProcessing}
        disableNextButton={!isValid || isProcessing}
        onPrevStep={() => onPrevStep()}
        onNextStep={onFormSubmit}
      />
    </form>
  )
}

export default NonUserForm
