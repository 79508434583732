import React, { useContext, useState } from 'react'
import { IonContent, IonPage, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import { ShoppingCartCommand } from '../../lib/core/enums'
import type { ProductOption } from '../../lib/core/definitions'
import { TopNavTabId } from '../../components/TopNavBar/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useShoppingCart } from '../../contexts/ShoppingCartContext/ShoppingCartContext'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import coreHelpers from '../../lib/core/helpers'
import GiftCardHeader, { GiftCardHeaderLayout } from '../../components/GiftCardHeader/GiftCardHeader'
import GiftFlowStepper from '../../components/GiftFlowStepper/GiftFlowStepper'
import MerchantInfo from '../../components/MerchantInfo/MerchantInfo'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import ProductInfo from '../../components/ProductInfo/ProductInfo'
import ProductOptionItem from './ProductOptionItem/ProductOptionItem'
import TopNavBar from '../../components/TopNavBar/TopNavBar'

const appPageId = AppPage.CheckoutProductOptionPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

type Params = {
  shoppingCartItemId: string
}

const CheckoutProductOptionPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { shoppingCartItemId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { activeUser, isLoadingActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const {
    shoppingCart,
    setShoppingCart,
    isLoadingShoppingCart,
    isSavingShoppingCart,
  } = useShoppingCart()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [activeTopNavTabId, setActiveTopNavTabId] = useState(TopNavTabId.SELECT_PRODUCT_OPTION)

  // ===================================================================================================================
  // Helpers:
  const shoppingCartItem = (shoppingCart && Array.isArray(shoppingCart.items) && shoppingCart.items.length > 0)
    ? shoppingCart.items.find(item => coreHelpers.models.compareId(item.id, shoppingCartItemId))
    : undefined
  const productOptionId = shoppingCartItem && shoppingCartItem.productOptionId
  const product = shoppingCartItem && shoppingCartItem.product
  const merchant = product ? product.merchant : undefined
  const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromProduct(product)
  const isProcessing = isLoadingShoppingCart || isSavingShoppingCart || isLoadingActiveUser

  const topNavTabDefs = [
    { id: TopNavTabId.SELECT_PRODUCT_OPTION, label: 'Buy' /* , icon: informationCircle */ },
    { id: TopNavTabId.PRODUCT_INFO, label: 'Info' /* , icon: informationCircle */ },
    { id: TopNavTabId.MERCHANT_INFO, label: 'Brand' /* , icon: logoIonic */ },
  ]

  // ===================================================================================================================
  // Event Handlers:
  const onChangeProductOption = (productOptionId: string): void => {
    // console.log('CheckoutProductOptionPage.onChangeProductionOption called.')
    if (!product || !product.productOptions || !shoppingCart || !shoppingCart.items) {
      console.error('CheckoutProductOptionPage.onChangeProductionOption: missing data.')
      // todo: surface error
      return
    }

    const selectedProductOption = product.productOptions
      .find((l: ProductOption) => coreHelpers.models.compareId(l.id, productOptionId))

    if (!selectedProductOption) {
      console.error('CheckoutProductOptionPage.onChangeProductionOption: product option not found.')
      // todo: surface error
      return
    }

    if (!shoppingCartItem) {
      console.error('CheckoutProductOptionPage.onChangeProductionOption: shopping cart item not found.')
      // todo: surface error
      return
    }

    setShoppingCart({
      userId: activeUserId,
      command: ShoppingCartCommand.UPDATE_ITEM,
      items: [{
        id: shoppingCartItem.id,
        productOptionId: selectedProductOption.id,
        productCount: 1,
      }],
    },
    true,
    (cart) => {
      if (!cart || !Array.isArray(cart.items) || cart.items.length < 1) {
        console.error('CheckoutProductOptionPage.onChangeProductionOption: no valid shopping cart returned.')
        return true
      }

      const pendingItem = cart.items.find(item => (
        coreHelpers.models.compareId(item.productId, selectedProductOption.productId) &&
        !item.productOptionId
      ))
      if (pendingItem) {
        // The back-end hasn't yet converted the pending item into a real order item:
        return false
      }

      const item = cart.items.find(item =>
        coreHelpers.models.compareId(item.productOptionId, selectedProductOption.id)
      )
      if (!item) {
        console.error('CheckoutProductOptionPage.onChangeProductionOption: shopping cart item not found.')
        return false
      }
      return !!item.productCount
    },
    ).then((shoppingCart) => {
      console.log('CheckoutProductOptionPage.onChangeProductionOption: done - forwarding.', shoppingCart)
      navigate(AppRoute.SHOPPING_CART)
    }, (error) => {
      // todo: surface error
      console.error(error)
    })
  }

  const onClickTopNavTab = (navKey: TopNavTabId): void => {
    setActiveTopNavTabId(navKey)
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)
  // console.log('>>>>>>>>>>CheckoutProductOptionPage.render called.',
  //   { shoppingCart, shoppingCartItem, product, merchant })

  let content
  if (merchant) {
    let tabContent

    if (activeTopNavTabId === TopNavTabId.SELECT_PRODUCT_OPTION) {
      let choices: JSX.Element[] | undefined
      if (
        product &&
        Array.isArray(product.productOptions) &&
        product.productOptions.length > 0
      ) {
        choices = product.productOptions.reduce<JSX.Element[]>((list, po) => {
          list.push(
            <ProductOptionItem
              key={po.id as string}
              productOption={po}
              isSelected={po.id === productOptionId}
              onClick={onChangeProductOption}
            />,
          )
          return list
        }, [])
      }
      tabContent = (
        <div className='withDoubleTopMargin g-with-safe-padding'>
          <div className='withDoubleBottomMargin'>
            <div className='formLabel'>Brand</div>
            {merchant.name}
          </div>
          <div className='formLabel withSmallBottomMargin'>Gift Card Amount</div>
          {choices}
        </div>
      )
    } else if (activeTopNavTabId === TopNavTabId.PRODUCT_INFO) {
      tabContent = <ProductInfo product={product} />
    } else if (activeTopNavTabId === TopNavTabId.MERCHANT_INFO) {
      tabContent = <MerchantInfo merchant={merchant} />
    }

    content = (
      <>
        <GiftCardHeader
          backgroundImageUri={giftCardBackgroundUri}
          merchantLogoUri={merchantLogoUri}
          layout={GiftCardHeaderLayout.LARGE}
        />
        <TopNavBar
          tabs={topNavTabDefs}
          onClickTab={onClickTopNavTab}
          activeTabId={activeTopNavTabId || TopNavTabId.USE_CARD}
        />
        {tabContent}
      </>
    )
  }

  return (
    <IonPage className='app-page-public checkout-product-option-page'>
      <NavBar
        title='Buy Gift Card'
        goBackUri={AppRoute.MARKETPLACE}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <GiftFlowStepper
        size='large'
        className='withStandardTopMargin'
        parent='marketplace'
      />
      <IonContent>
        <PageMessages />
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default CheckoutProductOptionPage
