import type { Inbox, InboxItem } from '../../../lib/core/definitions'
import { InboxItemType } from '../../../lib/core/enums'

const shouldReloadContacts = (oldInbox: Inbox | undefined, newInbox: Inbox): boolean => {
  // console.log('shouldReloadContacts called.', { oldInbox, newInbox })
  if (
    !newInbox ||
    !Array.isArray(newInbox.items) ||
    newInbox.items.length < 1
  ) {
    return !!oldInbox && !!oldInbox.chatMessageCount
  }

  const newChatItems: InboxItem[] = newInbox.items.filter(i => (
    i.inboxItemType === InboxItemType.CHAT
  ))

  if (!newChatItems || newChatItems.length < 1) {
    return !!oldInbox && !!oldInbox.chatMessageCount
  }

  if (
    !oldInbox ||
    !Array.isArray(oldInbox.items) ||
    oldInbox.items.length < 1
  ) {
    return true
  }

  if (newInbox.chatMessageCount !== oldInbox.chatMessageCount) {
    return true
  }

  const oldChatItems: InboxItem[] = oldInbox.items.filter(i => (
    i.inboxItemType === InboxItemType.CHAT
  ))

  if (!oldChatItems || oldChatItems.length < 1) {
    return true
  }

  if (newChatItems.length !== oldChatItems.length) {
    return true
  }

  for (let i = 0; i < newChatItems.length; i++) {
    const newChatItem = newChatItems[i]
    const oldChatItem = oldInbox.items.find(item => item.modelId === newChatItems[i].modelId)
    if (!oldChatItem) {
      return true
    }
    const oldChatMetadata = oldChatItem.metadata && oldChatItem.metadata.chat
    const newChatMetadata = newChatItem && newChatItem.metadata && newChatItem.metadata.chat
    if (
      (oldChatMetadata && !newChatMetadata) ||
      (!oldChatMetadata && newChatMetadata)
    ) {
      return true
    }
    if (
      oldChatMetadata &&
      newChatMetadata &&
      (
        oldChatMetadata.unreadMessageCount !== newChatMetadata.unreadMessageCount ||
        oldChatMetadata.unreadSystemMessageCount !== newChatMetadata.unreadSystemMessageCount
      )
    ) {
      return true
    }
  }

  return false
}

export default shouldReloadContacts
