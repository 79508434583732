import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertMerchantMutationData,
  UpsertMerchantMutationVariables,
} from '../apollo/definitions'
import type {
  ApiQueryOptions,
  Merchant,
  MerchantInput,
  MerchantListFilter,
} from '../../lib/core/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadMerchant from './loadMerchant'
import logger from '../logger'

const upsertMerchant = (
  merchantInput: MerchantInput,
  isInTargetStateFunc: ((merchant: Merchant) => boolean) | 'watch-updated-at' | undefined,
  listFilter: MerchantListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Merchant | undefined> => (
  // console.log('api.upsertMerchant called.', { merchantInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertMerchant: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertMerchantMutationData, UpsertMerchantMutationVariables> = {
      mutation: apollo.admin.mutations.upsertMerchant,
      variables: { merchant: merchantInput },
    }

    apolloClient.mutate<UpsertMerchantMutationData, UpsertMerchantMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertMerchant: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertMerchant ||
          !resp.data.upsertMerchant.id
        ) {
          logger.error('api.upsertMerchant: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((merchant: Merchant) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!merchantInput.id) {
            logger.error('api.upsertMerchant: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertMerchant.updatedAt
            activeIsInTargetStateFunc = (merchant: Merchant) => merchant.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertMerchant)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getMerchantsQueryVariables(listFilter)
            : undefined
          cache.updateMerchant(
            resp.data.upsertMerchant,
            queryListVariables,
            apolloClient,
          )
          resolve(resp.data.upsertMerchant)
          return
        }

        const merchantId = resp.data.upsertMerchant.id

        setTimeout(() => {
          // console.log('api.upsertMerchant: calling loadMerchant')
          loadMerchant(
            merchantId,
            activeIsInTargetStateFunc,
            listFilter,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((merchant) => {
              // console.log('api.upsertMerchant: resolving with merchant', merchant)
              resolve(merchant)
            }, (error) => {
              logger.error('api.upsertMerchant: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertMerchant: error', { error })
        reject(error)
      })
  })
)

export default upsertMerchant
