import type { EnvironmentVal } from './core/definitions'

const env = (key: string, environment: EnvironmentVal): string | undefined => {
  if (key === 'NODE_ENV') {
    return process.env.NODE_ENV
  }

  if (key === 'ENVIRONMENT') {
    return environment
  }

  if (process.env.NODE_ENV === 'development' && !environment) {
    return process.env[`REACT_APP_${key}_DEVELOPMENT`]
  }

  if (process.env.NODE_ENV === 'test') {
    return process.env[`REACT_APP_${key}_TEST`]
  }

  if (!environment || environment === 'production') {
    return process.env[`REACT_APP_${key}`]
  }

  return process.env[`REACT_APP_${key}_${environment.toUpperCase()}`]
}

export default env
