import { gql } from '@apollo/client'

export default gql`
  query Q($userId: String!) {
    unreadInAppMessages (userId: $userId) {
      id
      fromUserId
      toUserId
      templateId
      campaignId
      asyncTaskId
      chatMessageId
      contactEventReminderId
      invitationId
      purchaseId
      purchaseTransferId
      rewardId
      title
      messageText
      shortMessageText
      htmlMessage
      appLink
      context
      action0
      action1
      action2

      sendChatMessage
      sendEmail
      sendInAppMessage
      sendPushNotification
      sendSms

      emailSentAt
      inAppMessageSentAt
      inAppMessageReceivedAt
      pushNotificationSentAt
      smsSentAt

      emailSendReport
      pushNotificationSendReport
      smsSendReport

      sentMessagesCount
      actionTaken

      adminNotes
      metadata

      recordStatus
      createdAt
      updatedAt
      updatedBy
    }
  }`
