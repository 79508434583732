import React, { useEffect, useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { animated, useSpring } from 'react-spring'
import { close } from 'ionicons/icons'

import './styles.css'

type ActionModalAnimationProps = {
  isShowing: boolean
  maxHeight: string
}

type BackdropAnimationProps = {
  isShowing: boolean
  backgroundColor: string
}

type Props = {
  show: boolean
  title: string
  className?: string
  onCancel: () => void
}

const animationProps = {
  collapsed: {
    content: {
      isShowing: false,
      maxHeight: '0vh',
    },
    backdrop: {
      isShowing: false,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  expanded: {
    content: {
      isShowing: true,
      maxHeight: '70vh',
    },
    backdrop: {
      isShowing: true,
      backgroundColor: 'rgba(0,0,0,.52)',
    },
  },
}

const ActionModal: React.FC<Props> = ({
  show,
  title,
  className,
  onCancel,
  children,
}): JSX.Element | null => {
  const [isShowingComponent, setIsShowingComponent] = useState(false)

  const [backdropStyle, backdropStyleRef] = useSpring<BackdropAnimationProps>({
    from: animationProps.expanded.backdrop,
    to: animationProps.collapsed.backdrop,
    config: { duration: 250 },
    onRest: (ds) => {
      if (!ds.value.isShowing) {
        setIsShowingComponent(false)
      }
    },
  }, [show])

  const [actionModalStyle, actionModalStyleRef] = useSpring<ActionModalAnimationProps>(
    () => animationProps.collapsed.content,
  )

  useEffect(() => {
    actionModalStyleRef.start(show
      ? animationProps.expanded.content
      : animationProps.collapsed.content,
    )
    backdropStyleRef.start(show
      ? animationProps.expanded.backdrop
      : animationProps.collapsed.backdrop,
    )
    if (show) {
      setIsShowingComponent(true)
    }
  }, [show, actionModalStyleRef, backdropStyleRef])

  if (!isShowingComponent) {
    return null
  }

  return (
    <>
      <animated.div
        className='action-modal-backdrop'
        style={backdropStyle}
        onClick={onCancel}
      />
      <animated.div
        className={`action-modal ${className || ''}`}
        style={{ ...actionModalStyle, bottom: 0 }}
      >
        <div className='header'>
          <div className='header-title'>{title}</div>
          <IonButton fill='clear' onClick={onCancel} className='close-button' size='small' slot='icon'>
            <IonIcon icon={close} />
          </IonButton>
        </div>
        <div className='child-container'>
          {children}
        </div>
      </animated.div>
    </>
  )
}

export default ActionModal
