import React, { useState } from 'react'
import { IonButton, IonIcon, IonSpinner } from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'
import { MenuItem, Select } from '@mui/material'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import type {
  Contact,
  ContactEvent,
  ContactEventReminder,
  ContactEventReminderInput,
} from '../../../../../lib/core/definitions'
import { ContactEventReminderTiming, NotificationMethod } from '../../../../../lib/core/enums'
import { useMimbleData } from '../../../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../../../services/api'
import coreHelpers from '../../../../../lib/core/helpers'
import SubmitButton from '../../../../../components/SubmitButton/SubmitButton'

type Props = {
  reminder: ContactEventReminder
  event: ContactEvent
  contact: Contact
  onClose?: (reminderId: string) => void
  showUiMessage: (message: string) => void
}

const EventForm: React.FC<Props> = (props): JSX.Element => {
  const {
    reminder,
    event,
    contact,
    onClose,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [timing, setTiming] = useState<ContactEventReminderTiming | -1 | undefined>(
    (reminder && reminder.timing !== null && reminder.timing !== undefined)
      ? reminder.timing
      : -1,
  )
  const [isDeleting, setIsDeleting] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const oldTiming = reminder && reminder.timing
  const newTiming = timing === undefined
    ? (reminder && Number.isInteger(reminder.timing) ? reminder.timing : undefined)
    : timing

  // ===================================================================================================================
  // Helpers:
  const isDirty = timing !== undefined && timing !== -1
  const isValid = Number.isInteger(newTiming) && newTiming !== -1

  const resetForm = (): void => {
    setTiming(-1)
  }

  // ===================================================================================================================
  // Event Handlers:

  const onSave = (newTiming?: ContactEventReminderTiming): void => {
    const contactEventReminderInput: ContactEventReminderInput = {
      eventId: reminder.eventId,
      notificationMethod: NotificationMethod.PUSH_NOTIFICATION,
    }
    if (reminder && reminder.id) {
      contactEventReminderInput.id = reminder.id
    }
    if (newTiming !== undefined) {
      contactEventReminderInput.timing = newTiming
    } else if (timing !== undefined) {
      contactEventReminderInput.timing = timing
    }
    setIsSaving(true)
    api.upsertContactEventReminder(
      contactEventReminderInput,
      undefined,
      undefined,
      apolloClient,
    ).then((newReminder) => {
      api.loadContact(
        event.contactId,
        undefined,
        undefined,
        undefined,
        undefined,
        activeUserId as string,
        apolloClient,
      ).then(() => {
        setIsSaving(false)
        onClose && onClose(newReminder ? newReminder.id as string : '')
      }, (error) => {
        console.error(error)
        setIsSaving(false)
      })
    }, (error) => {
      console.error(error)
      setIsSaving(false)
    })
  }

  const onChangeTiming = (event: any): void => {
    if (event.target.value === oldTiming) {
      setTiming(undefined)
      return
    }
    setTiming(event.target.value)
    onSave(event.target.value)
  }

  const onDelete = (): void => {
    setIsDeleting(true)
    api.deleteContactEventReminder(
      reminder.id as string,
      apolloClient,
    ).then(() => {
      api.loadContact(
        event.contactId,
        undefined,
        undefined,
        undefined,
        undefined,
        activeUserId as string,
        apolloClient,
      ).then(() => {
        setIsDeleting(false)
      }, (error) => {
        console.error(error)
        setIsDeleting(false)
      })
    }, (error) => {
      console.error(error)
      setIsDeleting(false)
    })
  }

  // ===================================================================================================================
  // Rendering:
  const options = Object.values(ContactEventReminderTiming)
    .filter((timing) => {
      if (typeof timing !== 'number') {
        return false
      }
      if (
        !event ||
        !Array.isArray(event.reminders) ||
        event.reminders.length < 1 ||
        timing === newTiming
      ) {
        return true
      }
      return !event.reminders.find(r => r.timing === timing)
    })
    .map((timing) => (
      <MenuItem
        key={timing}
        value={timing}
      >
        {coreHelpers.type.contactEventReminderTiming.getLongLabel(timing as ContactEventReminderTiming)}
      </MenuItem>
    ))

  let actionSection: JSX.Element | undefined
  if (reminder && reminder.id) {
    if (isDeleting || isSaving) {
      actionSection = (
        <IonSpinner className='delete-spinner' />
      )
    } else {
      actionSection = (
        <IonButton
          size='small'
          fill='clear'
          className='withStandardLeftMargin'
          onClick={onDelete}
        >
          <IonIcon icon={closeCircleOutline} className='delete-icon' />
        </IonButton>
      )
    }
  }

  return (
    <div className='reminder-form'>
      <Select
        labelId='select-time-label'
        value={newTiming}
        onChange={onChangeTiming}
        label='Reminder'
      >
        <MenuItem
          key='empty'
          value={-1}
        >
          Reminder Date
        </MenuItem>
        {options}
      </Select>
      {actionSection}
    </div>
  )
}

export default EventForm
