import { PaymentRequestPart } from '../helpers'
import type { ShoppingCart } from '../../../../lib/core/definitions'
import coreHelpers from '../../../../lib/core/helpers'

type MenuItemDef = {
  value: PaymentRequestPart
  label: 'Address' | 'Amount' | 'Message' | 'Request String'
  show: boolean
}

const getCopyMenuItems = (shoppingCart: ShoppingCart): MenuItemDef[] => [
  {
    value: PaymentRequestPart.RECEIVING_ADDRESS,
    label: 'Address',
    show: coreHelpers.type.transactionProcessor.isUsingCryptoReceivingAddress(shoppingCart.paymentProcessor),
  },
  {
    value: PaymentRequestPart.AMOUNT,
    label: 'Amount',
    show: true,
  },
  {
    value: PaymentRequestPart.MESSAGE,
    label: 'Message',
    show: true,
  },
  {
    value: PaymentRequestPart.REQUEST_STRING,
    label: 'Request String',
    show: true,
  },
]

const helpers = {
  getCopyMenuItems,
}

export default helpers
