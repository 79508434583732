import type { ApolloClient } from '@apollo/client'
import type { Inbox } from '../../../lib/core/definitions'
import apollo from '../../../services/apollo'

const updateCachedInbox = (
  inbox: Inbox,
  apolloClient: ApolloClient<any>,
  activeUserId: string,
): void => {
  apolloClient.cache.writeQuery({
    query: apollo.queries.inbox,
    variables: { filter: { userId: activeUserId } },
    data: { inbox },
  })
}

export default updateCachedInbox
