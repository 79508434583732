import type { ShoppingCart, ShoppingCartListItem } from '../../../definitions'

const updateShoppingCartListItemFromShoppingCart = (shoppingCartListItem: ShoppingCartListItem | undefined, shoppingCart: ShoppingCart): ShoppingCartListItem => {
  if (!shoppingCartListItem) {
    return {
      userId: shoppingCart.userId,
      orderId: shoppingCart.orderId,
      donationTargetId: shoppingCart.donationTargetId,
      // userFullName: shoppingCart.userFullName,
      status: shoppingCart.status,
      paymentProcessor: shoppingCart.paymentProcessor,
      paymentAmount: shoppingCart.paymentAmount,
      paymentFundType: shoppingCart.paymentFundType,
      paymentCurrency: shoppingCart.paymentCurrency,
      // todo: ??
      // paymentStatus: shoppingCart.paymentStatus,
      errorCode: shoppingCart.errorCode,
      errorMessage: shoppingCart.errorMessage,
      metadata: shoppingCart.metadata,
    }
  }

  const updatedItem: ShoppingCartListItem = { ...shoppingCartListItem }

  if (shoppingCart.userId && shoppingCart.userId !== shoppingCartListItem.userId) {
    updatedItem.userId = shoppingCart.userId
  }
  if (shoppingCart.orderId && shoppingCart.orderId !== shoppingCartListItem.orderId) {
    updatedItem.orderId = shoppingCart.orderId
  }
  if (shoppingCart.donationTargetId && shoppingCart.donationTargetId !== shoppingCartListItem.donationTargetId) {
    updatedItem.donationTargetId = shoppingCart.donationTargetId
  }
  // if (shoppingCart.userFullName && shoppingCart.userFullName !== shoppingCartListItem.userFullName) {
  //   updatedItem.userFullName = shoppingCart.userFullName
  // }
  if (shoppingCart.status && shoppingCart.status !== shoppingCartListItem.status) {
    updatedItem.status = shoppingCart.status
  }
  if (shoppingCart.paymentProcessor && shoppingCart.paymentProcessor !== shoppingCartListItem.paymentProcessor) {
    updatedItem.paymentProcessor = shoppingCart.paymentProcessor
  }
  if (shoppingCart.paymentAmount && shoppingCart.paymentAmount !== shoppingCartListItem.paymentAmount) {
    updatedItem.paymentAmount = shoppingCart.paymentAmount
  }
  if (shoppingCart.paymentFundType && shoppingCart.paymentFundType !== shoppingCartListItem.paymentFundType) {
    updatedItem.paymentFundType = shoppingCart.paymentFundType
  }
  if (shoppingCart.paymentCurrency && shoppingCart.paymentCurrency !== shoppingCartListItem.paymentCurrency) {
    updatedItem.paymentCurrency = shoppingCart.paymentCurrency
  }
  if (shoppingCart.paymentStatus && shoppingCart.paymentStatus !== shoppingCartListItem.paymentStatus) {
    updatedItem.paymentStatus = shoppingCart.paymentStatus
  }
  if (shoppingCart.errorCode && shoppingCart.errorCode !== shoppingCartListItem.errorCode) {
    updatedItem.errorCode = shoppingCart.errorCode
  }
  if (shoppingCart.errorMessage && shoppingCart.errorMessage !== shoppingCartListItem.errorMessage) {
    updatedItem.errorMessage = shoppingCart.errorMessage
  }
  if (shoppingCart.metadata && shoppingCart.metadata !== shoppingCartListItem.metadata) {
    updatedItem.metadata = shoppingCart.metadata
  }

  return updatedItem
}

export default updateShoppingCartListItemFromShoppingCart
