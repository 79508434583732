import React, { useState } from 'react'

import './styles.css'
import { IonButton, IonInput } from '@ionic/react'
import type { PurchaseTransfer } from '../../lib/core/definitions'
import FormItem from '../../components/FormItem/FormItem'
import helpers from './helpers'
import coreHelpers from '../../lib/core/helpers'
import validationHelpers from '../../helpers/validationHelpers'
import { IdentType } from '../../lib/core/enums'

type Props = {
  purchaseTransfer?: PurchaseTransfer;
  onGoToSignIn: () => void;
  onAcceptGift: (password: string) => void;
  onDeclineGift: () => void;
}

const AcceptGiftAndSignInContent: React.FC<Props> = ({
  purchaseTransfer,
  onGoToSignIn,
  onAcceptGift,
  onDeclineGift,
}): JSX.Element => {
  // ===================================================================================================================
  // State:
  const [password, setPassword] = useState<string | undefined>()
  const [passwordValidationError, setPasswordValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const recipientIdent = helpers.getRecipientIdent(purchaseTransfer)
  const recipientIdentType = helpers.getRecipientIdentType(purchaseTransfer)
  const recipientIdentTypeLabel = coreHelpers.string.capitalize(coreHelpers.type.identType.getLabel(recipientIdentType))

  const isValid = !!password && !passwordValidationError

  // ===================================================================================================================
  // Event Handlers:
  const onChangePassword = (event: any): void => {
    setPassword(event.detail.value)
    if (event.detail.value) {
      setPasswordValidationError(validationHelpers.validatePassword(event.detail.value))
    } else {
      setPasswordValidationError('')
    }
  }

  const onActivateCardAndSignIn = (): void => {
    if (!password) {
      console.error('ActivateAndSignInContent.onActivateCardAndSignIn: no password given.')
      return
    }
    onAcceptGift(password)
  }

  // ===================================================================================================================
  // Rendering:
  let identFieldSection
  if (recipientIdentType === IdentType.EMAIL || recipientIdentType === IdentType.PHONE_NUMBER) {
    identFieldSection = (
      <>
        <div className='formLabel withSmallBottomMargin'>
          {recipientIdentTypeLabel}
        </div>
        <div className='withDoubleBottomMargin'>
          {recipientIdent}
        </div>
      </>
    )
  }
  return (
    <div key='acceptGiftContent'>
      <div key='sign-in'>
        <div className='withStandardTopMargin'>
          We found your Mimble member account and will add this gift card to your gift card wallet.
          <br /><br />
          Please sign in to accept this gift.
        </div>
        <div key='signInForm' className='withStandardTopMargin'>
          {identFieldSection}
          <FormItem
            label='Password'
            validationError={passwordValidationError}
            withBottomMargin
          >
            <IonInput
              onIonChange={onChangePassword}
              type='password'
              value={password}
              placeholder='password'
            />
          </FormItem>
        </div>
        <div className='lightText smallText withDoubleBottomMargin' onClick={onGoToSignIn}>
          If you don&apos;t remember your password, or want to sign in with a token
          just <span className='linkText'>head over to the Sign In page</span>. Once you are signed in, you
          will see this gift card in your inbox.
        </div>
        <div key='activateFormButtons' className='formButtonWrapper'>
          <IonButton
            color='light'
            className='withStandardRightMargin'
            onClick={onDeclineGift}
            disabled={!isValid}
          >
            Decline
          </IonButton>
          <IonButton
            onClick={onActivateCardAndSignIn}
            disabled={!isValid}
          >
            Accept Gift
          </IonButton>
        </div>
      </div>
    </div>
  )
}

export default AcceptGiftAndSignInContent
