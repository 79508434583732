import { gql } from '@apollo/client'

export default gql`
  mutation M($userSensitiveInfo: UserSensitiveInfoInput!) {
    upsertUserSensitiveInfo (userSensitiveInfo: $userSensitiveInfo) {
      id
      userId
      legalName
      gender
      dob
      addressLine1
      addressLine2
      city
      regionCode
      postalCode
      countryCode
      ccNameOnCard
      ccAddressLine1
      ccAddressLine2
      ccCity
      ccRegionCode
      ccPostalCode
      ccCountryCode
      ipAddress
      metadata
      adminNotes
      recordStatus
      createdAt
      updatedAt
    }
  }
`
