import type { DocumentNode } from '@apollo/client'
import { gql } from '@apollo/client'

const merchants = (addAliases = false): DocumentNode => {
  let aliases: string | undefined
  if (addAliases) {
    aliases = 'alias1 alias2 alias3'
  }
  return gql`
    query Q($filter: MerchantListFilter) {
      merchants (filter: $filter) {
        id
        userId
        name
        ${aliases}
        organizationType
        status
        isDonationTarget
        listed
        url
        smallLogoImageSource
        largeLogoImageSource
        source
        mainProductId
        mainProductListed
        mainProductStock
        recordStatus
        createdAt
        updatedAt
      }
    }`
}
export default merchants
