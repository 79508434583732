import logger from '../../../logger'
import type { TfaCommand } from '../../../enums'
import { NotificationMethod } from '../../../enums'
import typeHelpers from '../../../helpers/type'

type Args = {
  action: TfaCommand;
  method: NotificationMethod;
  value: any;
  emailVerified: boolean;
  phoneNumberVerified: boolean;
  userHasLiveBanking: boolean;
};

type Result = {
  result: boolean;
  error?: string;
};

const isTfaMethodAvailable = (args: Args): Result => {
  // logger.debug('isTfaMethodAvailable called.', args)
  const {
    action,
    method,
    value,
    emailVerified,
    phoneNumberVerified,
    userHasLiveBanking,
  } = args
  if (!action) {
    logger.error('isTfaMethodAvailable: no TFA action specified', { args }, { remote: true })
    return { result: false, error: 'SYSTEM_ERROR' }
  }
  if (!typeHelpers.tfaCommand.isValid(action)) {
    logger.error('isTfaMethodAvailable: invalid TFA action specified', { args }, { remote: true })
    return { result: false, error: 'SYSTEM_ERROR' }
  }
  if (!method) {
    logger.error('isTfaMethodAvailable: no method specified', { args }, { remote: true })
    return { result: false, error: 'SYSTEM_ERROR' }
  }
  if (!typeHelpers.notificationMethod.isValid(method)) {
    logger.error('isTfaMethodAvailable: invalid method specified', { args }, { remote: true })
    return { result: false, error: 'SYSTEM_ERROR' }
  }
  if (!value) {
    logger.error('isTfaMethodAvailable: no value specified', { args }, { remote: true })
    return { result: false, error: 'SYSTEM_ERROR' }
  }
  if (
    (method === NotificationMethod.EMAIL && emailVerified) ||
    (method === NotificationMethod.SMS && phoneNumberVerified)
  ) {
    return { result: true }
  }
  if (userHasLiveBanking) {
    return { result: false, error: 'NOT_VERIFIED' }
  }
  return { result: true }
}

export default isTfaMethodAvailable
