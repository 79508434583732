import moment from 'moment'
import type { MouseEvent } from 'react'
import React, { useState } from 'react'
import {
  IonButton, IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonProgressBar,
} from '@ionic/react'

import './styles.css'
import type { CryptoCurrency } from '../../../../lib/core/enums'
import { BarcodeType } from '../../../../lib/core/enums'
import type { PaymentRequestPart } from '../helpers'
import type { ShoppingCart } from '../../../../lib/core/definitions'
import Barcode from '../../../../components/Barcode/Barcode'
import coreHelpers from '../../../../lib/core/helpers'
import helpers from './helpers'

interface Props {
  shoppingCart: ShoppingCart | null | undefined
  copyRequestMenuClickEvent?: MouseEvent
  showCopyPaymentRequestMenu?: boolean
  onCloseContextMenu: () => void
  onCopyPaymentRequestPart: (part: PaymentRequestPart) => void
  onCancelPaymentRequest: () => void
  onDeletePendingOrder: () => void
  onOpenCopyRequestMenu: (event: MouseEvent) => void
  onRefreshExchangeRate: () => void
}

const RequestInfoSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    shoppingCart,
    copyRequestMenuClickEvent,
    showCopyPaymentRequestMenu,
    onCloseContextMenu,
    onCopyPaymentRequestPart,
    onCancelPaymentRequest,
    onDeletePendingOrder,
    onOpenCopyRequestMenu,
    onRefreshExchangeRate,
  } = props

  // ===================================================================================================================
  // State:
  const [displayContentType, setDisplayContentType] = useState('address' as 'request' | 'address')

  const paymentCryptoAddress = shoppingCart && shoppingCart.paymentCryptoAddress
  const requestTimesOutAt = shoppingCart && shoppingCart.paymentCryptoAddress && shoppingCart.paymentTimesOutAt
  const requestTimesOutInSecs = requestTimesOutAt && moment(Number(requestTimesOutAt)).diff(moment(), 'seconds')
  const paymentAmount = shoppingCart && coreHelpers.ui.formatAmount(
    Number(shoppingCart.paymentAmount),
    shoppingCart.paymentFundType,
    shoppingCart.paymentCurrency,
    true,
  )

  // ===================================================================================================================
  // Effect Hooks:
  // useEffect(() => {
  //   if (refreshTimer) {
  //     clearTimeout(refreshTimer)
  //   }
  //   if (
  //     shoppingCart &&
  //     shoppingCart.paymentCryptoAddress &&
  //     requestTimesOutAt &&
  //     requestTimesOutInSecs &&
  //     requestTimesOutInSecs > 0 &&
  //     !refreshTimer
  //   ) {
  //     if (refreshTimer) {
  //       clearTimeout(refreshTimer)
  //     }
  //     const t = setTimeout(refresh, 1000)
  //     setRefreshTimer(t)
  //   }
  // }, [paymentCryptoAddress, requestTimesOutAt])

  // useIonViewDidLeave(() => {
  //   if (refreshTimer) {
  //     clearTimeout(refreshTimer)
  //     setRefreshTimer(undefined)
  //   }
  // }, [refreshTimer])

  // ===================================================================================================================
  // Event Handlers:
  function onChangeDisplayContentType () {
    setDisplayContentType(displayContentType === 'request' ? 'address' : 'request')
  }

  // ===================================================================================================================
  // Rendering:
  if (
    !shoppingCart ||
    !coreHelpers.type.transactionProcessor.isCrypto(shoppingCart.paymentProcessor) ||
    !shoppingCart.paymentCryptoAddress
  ) {
    return null
  }
  const paymentRequestString = coreHelpers.cryptoCurrency.getPaymentRequestString(
    shoppingCart.paymentCryptoAddress || '',
    Number(shoppingCart.paymentAmount) || 0,
    shoppingCart.paymentCurrency as CryptoCurrency,
    'Mimble marketplace payment',
  )

  let qrImage: JSX.Element | undefined
  if (shoppingCart.paymentQrcodeUrl) {
    qrImage = (
      <div className='withCenteredColumnContent'>
        <IonImg src={shoppingCart.paymentQrcodeUrl} className='withMaxWidth200px' />
      </div>
    )
  } else {
    // const qrImageUri = displayContentType === 'request'
    //   ? appLinks.cryptoPaymentRequestQrCode(shoppingCartId as string)
    //   : appLinks.cryptoPaymentAddressQrCode(shoppingCartId as string)
    // qrImage = (
    //   <div className='withCenteredColumnContent'>
    //     <IonImg src={qrImageUri} className='withMaxWidth200px' />
    //   </div>
    // )
    let qrImageValue = ''
    if (displayContentType === 'request') {
      qrImageValue = coreHelpers.cryptoCurrency.getPaymentRequestString(
        shoppingCart.paymentCryptoAddress || '',
        Number(shoppingCart.paymentAmount) || 0,
        shoppingCart.paymentCurrency as CryptoCurrency,
        'Mimble marketplace payment',
      )
    } else {
      qrImageValue = shoppingCart.paymentCryptoAddress || ''
    }
    qrImage = (
      <Barcode
        value={qrImageValue}
        format={BarcodeType.QR_CODE}
        scale={2}
        height={28}
      />
    )
  }

  const copyRequestMenuItems = helpers.getCopyMenuItems(shoppingCart)
    .filter(item => item.show)
    .map((item) => (
      <IonItem
        key={item.value}
        lines='none'
        onClick={(): void => { onCopyPaymentRequestPart(item.value) }}
      >
        <IonLabel>{item.label}</IonLabel>
      </IonItem>
    ))

  let requestExpiresInSection
  if (
    shoppingCart &&
    !coreHelpers.type.transactionRemoteStatus.isTimedOutOrCanceled(shoppingCart.paymentStatus)
  ) {
    if (shoppingCart.paymentTimeout && shoppingCart.paymentTimesOutAt) {
      const expirationInSeconds = moment(Number(shoppingCart.paymentTimesOutAt)).diff(moment(), 'seconds')
      const percent = (shoppingCart.paymentTimeout - expirationInSeconds) / shoppingCart.paymentTimeout
      let formattedTime
      if (expirationInSeconds > 60) {
        const minutes = Math.floor(expirationInSeconds / 60)
        formattedTime = `${minutes} minutes`
      } else {
        formattedTime = `${expirationInSeconds} seconds`
      }
      requestExpiresInSection = (
        <div className='expires-in-section'>
          <IonProgressBar value={percent} />
          <div className='text'>
            Payment request expires in {formattedTime}
          </div>
          <div className='withStandardTopMargin'>
            <IonButton
              onClick={onCancelPaymentRequest}
              className='smallButton'
            >
              Cancel Request
            </IonButton>
            <IonButton
              onClick={onRefreshExchangeRate}
              className='smallButton'
            >
              Renew
            </IonButton>
          </div>
        </div>
      )
    }
  }

  const displayContentButtonLabel = displayContentType === 'request' ? 'Show Target Address' : 'Show Payment Request'
  const displayText = displayContentType === 'request' ? paymentRequestString : shoppingCart.paymentCryptoAddress

  return (
    <div className='request-info-section'>
      <div className='lightText'>
        <span>Use your crypto wallet to send </span>
        <span className='withBoldText'>{paymentAmount}</span>
        <span> to address </span>
        <span className='crypto-address'>{shoppingCart.paymentCryptoAddress}</span>
        <span>.</span>
      </div>
      <div className='request-wrapper'>
        {qrImage}
        <div>
          <IonInput
            className='request-string'
            type='text'
            value={displayText}
            name='req-input'
            readonly
          />
        </div>
        <IonPopover
          isOpen={showCopyPaymentRequestMenu === true}
          event={copyRequestMenuClickEvent as Event | undefined}
          onDidDismiss={onCloseContextMenu}
        >
          <IonList>
            {copyRequestMenuItems}
          </IonList>
        </IonPopover>
        <div className='withCenteredColumnContent'>
          <div className='rowWithCenterAlignedItems'>
            <IonButton onClick={onOpenCopyRequestMenu} className='smallButton'>
              Copy
            </IonButton>
            <IonButton onClick={onChangeDisplayContentType} className='smallButton'>
              {displayContentButtonLabel}
            </IonButton>
          </div>
        </div>
        {requestExpiresInSection}
      </div>
    </div>
  )
}

export default RequestInfoSection
