import { gql } from '@apollo/client'

export default gql`
  query Q($filter: CampaignListFilter!) {
    campaigns (filter: $filter) {
      id
      memberMessageTemplateId
      name
      campaignType
      status
      metadata
      recordStatus
      createdAt
      updatedAt
      updatedBy
    }
  }`
