import React, { useContext, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import { AppRoute } from '../../../enums'
import { AppPage } from '../../../enums'
import type { OrgChatsQueryData } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import NavBar from '../../../components/NavBar/NavBar'
import OrgChatList from '../../../admin/components/OrgChatList/OrgChatList'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'

const appPageId = AppPage.AdminOrgMessagesPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const OrgMessagesPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const {
    data: orgChatsLoadedData,
    loading: isLoadingOrgChats,
    refetch: reloadOrgChats,
    error: orgChatsLoadingError,
  } = useQuery<OrgChatsQueryData>(
    apollo.admin.queries.orgChats, {
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.error(error)
        pageHelpers.checkForUnauthorized(orgChatsLoadingError, navigate)
        // pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const orgChats = orgChatsLoadedData ? orgChatsLoadedData.orgChats : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingOrgChats

  const onOpenChat = (chatId: string): void => {
    pageMessages && pageMessages.clear()
    navigate(`${AppRoute.ADMIN_ORG_CHAT}/${chatId}`)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onRefreshPage = (): void => {
    if (reloadOrgChats) {
      reloadOrgChats()
    }
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadOrgChats().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  // todo: delete?
  // let orgChatList
  // if (orgChats && Array.isArray(orgChats) && orgChats.length > 0) {
  //   orgChatList = orgChats.map((chat) => {
  //     const onOpenChat = () => {
  //       if (!apolloClient || !activeUserId) {
  //         return
  //       }
  //
  //       api.loadChat(
  //         chat.id as string,
  //         activeUserId,
  //         apolloClient,
  //         undefined,
  //         undefined,
  //         true,
  //         inbox,
  //       ).then(() => {
  //         navigate(`${AppRoute.CONTACT_MESSAGES}/${chat.id}`)
  //       })
  //     }
  //
  //     let ident: string
  //     if (chat.toUser) {
  //       ident = chat.toUser.fullName || chat.toUser.username as string
  //     } else {
  //       ident = chat.id as string
  //     }
  //     return (
  //       <IonItem key={chat.id as string} onClick={onOpenChat}>
  //         {ident}
  //       </IonItem>
  //     )
  //   })
  // }

  return (
    <IonPage className='app-page-admin admin-org-messages'>
      <NavBar
        title='User Help Messages'
        onRefresh={onRefreshPage}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <OrgChatList
          chats={orgChats}
          onOpenChat={onOpenChat}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default OrgMessagesPage
