import type { Product, ProductListItem } from '../../../definitions'
import { RecordStatus } from '../../../enums'
import type { OrgProductType } from '../../../enums'

const updateProductListItemFromProduct = (productListItem: ProductListItem | undefined, product: Product): ProductListItem => {
  if (!productListItem) {
    return {
      id: product.id as string,
      merchantId: product.merchantId as string,
      offerId: product.offerId || null,
      productType: product.productType as OrgProductType,
      messageEn: product.messageEn || null,
      imageSourceFrontSide: product.imageSourceFrontSide || null,
      isMainProduct: product.isMainProduct as boolean,
      stock: product.stock as boolean,
      listed: product.listed,
      metadata: product.metadata,
      merchantName: '',
      merchantUrl: '',
      merchantSmallLogoImageSource: '',
      merchantLargeLogoImageSource: '',
      merchantListed: false,
      merchantRecordStatus: RecordStatus.ACTIVE,
      recordStatus: product.recordStatus || RecordStatus.ACTIVE,
      createdAt: product.createdAt || '',
      updatedAt: product.updatedAt || '',
      updatedBy: product.updatedBy || '',

      // todo: deprecated
      vendorId: product.merchantId as string,
      vendorName: '',
      vendorUrl: '',
      vendorSmallLogoImageSource: '',
      vendorLargeLogoImageSource: '',
      vendorListed: false,
      vendorRecordStatus: RecordStatus.ACTIVE,
    }
  }

  const updatedItem: ProductListItem = { ...productListItem }

  if (product.merchantId && product.merchantId !== productListItem.merchantId) {
    updatedItem.merchantId = product.merchantId
  }
  if (product.offerId && product.offerId !== productListItem.offerId) {
    updatedItem.offerId = product.offerId
  }
  if (product.productType && product.productType !== productListItem.productType) {
    updatedItem.productType = product.productType
  }
  if (product.messageEn && product.messageEn !== productListItem.messageEn) {
    updatedItem.messageEn = product.messageEn
  }
  if (product.imageSourceFrontSide && product.imageSourceFrontSide !== productListItem.imageSourceFrontSide) {
    updatedItem.imageSourceFrontSide = product.imageSourceFrontSide
  }
  if ((product.isMainProduct === true || product.isMainProduct === false) && product.isMainProduct !== productListItem.isMainProduct) {
    updatedItem.isMainProduct = product.isMainProduct
  }
  if ((product.stock === true || product.stock === false) && product.stock !== productListItem.stock) {
    updatedItem.stock = product.stock
  }
  if ((product.listed === true || product.listed === false) && product.listed !== productListItem.listed) {
    updatedItem.listed = product.listed
  }
  if (product.metadata) {
    updatedItem.metadata = product.metadata
  }
  if (product.metadata) {
    updatedItem.metadata = product.metadata
  }
  if (product.recordStatus && product.recordStatus !== productListItem.recordStatus) {
    updatedItem.recordStatus = product.recordStatus
  }
  if (product.createdAt && product.createdAt !== productListItem.createdAt) {
    updatedItem.createdAt = product.createdAt
  }
  if (product.updatedAt && product.updatedAt !== productListItem.updatedAt) {
    updatedItem.updatedAt = product.updatedAt
  }

  // todo: deprecated
  if (product.vendorId && product.vendorId !== productListItem.vendorId) {
    updatedItem.vendorId = product.vendorId
  }

  return updatedItem
}

export default updateProductListItemFromProduct
