import React, { useState } from 'react'
import { animated, useSpring } from 'react-spring'

import type { ContactUserListItem, PurchaseTransferInput, UserIdentInfo } from '../../../lib/core/definitions'
import { ContextHelpId } from '../../../contexts/AppHelpersContext/enums'
import { useGiftFlow } from '../../../contexts/GiftFlowContext'
import CurrentVal from './CurrentVal'
import FindUserForm from '../../../components/FindUserForm/FindUserForm'
import NonUserForm from './NonUserForm'
import StepButtons from '../StepButtons'

type Props = {
  onPrevStep: () => void
  onNextStep: () => void
}

const RecipientStep: React.FC<Props> = (props): JSX.Element | null => {
  const {
    onPrevStep: onPrevStepFromProps,
    onNextStep,
  } = props

  // ===================================================================================================================
  // State:
  const {
    purchaseTransfer,
    giftChanges,
    isProcessing,
    getCurToUserInfo,
    setGiftChanges,
  } = useGiftFlow()
  const [isEditing, setIsEditing] = useState(false)
  const curRecipientInfo = getCurToUserInfo()

  const [inputFirstName, setInputFirstName] = useState<string | undefined>()
  const [showNonUserForm, setShowNonUserForm] = useState(false)

  const nonUserFormAnimation = useSpring({ opacity: showNonUserForm ? 1 : 0 })
  const userListAnimation = useSpring({ opacity: !showNonUserForm ? 1 : 0 })

  // ===================================================================================================================
  // Effect Handlers:
  // useEffect((): void => {
  //   if (giftFlowStep === GiftFlowStep.RECIPIENT) {
  //     setIsEditing(
  //       !curRecipientInfo ||
  //       (!curRecipientInfo.id && !curRecipientInfo.fullName),
  //     )
  //     setScope(FindUserScope.NON_MEMBERS)
  //   }
  // }, [flowId, giftFlowStep, toUserId, purchaseTransfer])

  // ===================================================================================================================
  // Event Handlers:
  const onStartEditing = (): void => {
    if (giftChanges && giftChanges.toUserId) {
      setGiftChanges({
        ...giftChanges,
        toUserId: undefined,
      }, false)
    }
    setShowNonUserForm(!!curRecipientInfo && !curRecipientInfo.id)
    setIsEditing(true)
  }

  const onSaved = (): void => {
    setIsEditing(false)
    setShowNonUserForm(false)
  }

  // If & when a feature for selecting a user before performing an action is implemented

  // const onClearSelectedUser = (): void => {
  //   setSelectedUser(undefined)
  // }

  const onSelectUser = (
    userIdentInfo?: UserIdentInfo | undefined,
    contactUser?: ContactUserListItem | undefined,
  ): void => {
    if (userIdentInfo) {
      const changes: PurchaseTransferInput = {
        ...giftChanges,
        toUserId: userIdentInfo.id,
        toUserFullName: undefined,
        toUserEmail: undefined,
        toUserPhoneNumber: undefined,
      }
      if (purchaseTransfer && userIdentInfo.id === purchaseTransfer.toUserId) {
        delete changes.toUserId
      }
      setGiftChanges(changes, false)
      setIsEditing(false)
    } else if (contactUser) {
      const changes: PurchaseTransferInput = {
        ...giftChanges,
        toUserId: contactUser.id,
        toUserFullName: undefined,
        toUserEmail: undefined,
        toUserPhoneNumber: undefined,
      }
      if (purchaseTransfer && contactUser.id === purchaseTransfer.toUserId) {
        delete changes.toUserId
      }
      setGiftChanges(changes, false)
      setIsEditing(false)
    }
  }

  const onSelectNonUser = (firstName?: string): void => {
    if (firstName) {
      setInputFirstName(firstName)
    }
    setShowNonUserForm(true)
  }

  const onBackToSearch = (): void => {
    setShowNonUserForm(false)
    setInputFirstName(undefined)
  }

  // ===================================================================================================================
  // Rendering:
  let content: JSX.Element | undefined
  if (isEditing || !curRecipientInfo) {
    if (showNonUserForm) {
      content = (
        <animated.div style={nonUserFormAnimation}>
          <NonUserForm
            currentValue={inputFirstName}
            onPrevStep={onBackToSearch}
            onSaved={onSaved}
          />
        </animated.div>
      )
    } else {
      content = (
        <animated.div style={userListAnimation}>
          <FindUserForm
            // selectedUser={selectedUser || undefined}
            searchInputPlaceholder='Who are you sending this gift to?'
            contextHelpId={ContextHelpId.SEND_GIFT_RECIPIENT}
            onSelectUser={onSelectUser}
            onSelectNonUser={onSelectNonUser}
          />
        </animated.div>
      )
    }
  } else {
    content = (
      <>
        <CurrentVal
          onStartEditing={onStartEditing}
        />
        <StepButtons
          isProcessing={isProcessing}
          onNextStep={onNextStep}
        />
      </>
    )
  }

  return (
    <div className='recipient-step'>
      {content}
    </div>
  )
}

export default RecipientStep
