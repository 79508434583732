import React from 'react'
import { IonButton } from '@ionic/react'

import type { ShoppingCart } from '../../lib/core/definitions'
import LegalSection from './LegalSection/LegalSection'
import SubmitButton from '../../components/SubmitButton/SubmitButton'

type Props = {
  shoppingCart: ShoppingCart | undefined
  isActiveShoppingCart: boolean | undefined
  isSavingShoppingCart: boolean | undefined
  enableSubmitButton: boolean | undefined
  onClearShoppingCart: () => void
  onDeletePendingOrder: () => void
  onPlaceOrder: () => void
}

const SubmitSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    shoppingCart,
    isActiveShoppingCart,
    isSavingShoppingCart,
    enableSubmitButton,
    onClearShoppingCart,
    onDeletePendingOrder,
    onPlaceOrder,
  } = props

  const handleSubmit = (event: any): void => {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault()
    onPlaceOrder()
  }

  // let clearShoppingCartSection: JSX.Element | undefined
  // if (shoppingCart) {
  //   const label = isActiveShoppingCart ? 'Clear cart' : 'Delete pending order'
  //   clearShoppingCartSection = (
  //     <div className='withCenteredTextAlign withStandardBottomMargin'>
  //       <IonButton>
  //         {label}
  //       </IonButton>
  //     </div>
  //   )
  // }

  let deletePendingShoppingCartSection: JSX.Element | undefined
  if (shoppingCart && !isActiveShoppingCart) {
    deletePendingShoppingCartSection = (
      <div className='withCenteredTextAlign'>
        <IonButton
          onClick={onDeletePendingOrder}
          fill='clear'
          size='small'
        >
          <div className='g-gray-text-button-label'>Delete pending order</div>
        </IonButton>
      </div>
    )
  }

  return (
    <div className='submit-section'>
      <LegalSection
        transactionProcessor={shoppingCart && shoppingCart.paymentProcessor}
      />
      {/* {clearShoppingCartSection} */}
      {deletePendingShoppingCartSection}
      <div className='withCenteredTextAlign withStandardBottomMargin'>
        <SubmitButton
          expand='block'
          onClick={handleSubmit}
          disabled={!enableSubmitButton}
          isSavingShoppingCart={isSavingShoppingCart}
        >
          Place Order
        </SubmitButton>
      </div>
    </div>
  )
}

export default SubmitSection
