import type { ApolloClient, MutationOptions } from '@apollo/client'

import type {
  ApiQueryOptions,
  StoredProductListFilter,
  StoredProduct,
  StoredProductInput,
} from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertStoredProductMutationData,
  UpsertStoredProductMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadStoredProduct from './loadStoredProduct'
import logger from '../logger'

const upsertStoredProduct = (
  storedProductInput: StoredProductInput,
  isInTargetStateFunc: ((storedProduct: StoredProduct) => boolean) | 'watch-updated-at' | undefined,
  listFilter: StoredProductListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<StoredProduct | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.upsertStoredProduct called.', { storedProductInput })
    if (!apolloClient) {
      logger.error('api.upsertStoredProduct: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertStoredProductMutationData, UpsertStoredProductMutationVariables> = {
      mutation: apollo.admin.mutations.upsertStoredProduct,
      variables: { storedProduct: storedProductInput },
    }

    apolloClient.mutate<UpsertStoredProductMutationData, UpsertStoredProductMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertStoredProduct: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertStoredProduct ||
          !resp.data.upsertStoredProduct.id
        ) {
          logger.error('api.upsertStoredProduct: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((storedProduct: StoredProduct) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!storedProductInput.id) {
            logger.error('api.upsertStoredProduct: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertStoredProduct.updatedAt
            activeIsInTargetStateFunc = (storedProduct: StoredProduct) => storedProduct.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertStoredProduct)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getStoredProductsQueryVariables(listFilter)
            : undefined
          cache.updateStoredProduct(
            resp.data.upsertStoredProduct,
            queryListVariables,
            apolloClient,
          )
          resolve(resp.data.upsertStoredProduct)
          return
        }

        const storedProductId = resp.data.upsertStoredProduct.id

        setTimeout(() => {
          // console.log('api.upsertStoredProduct: calling loadStoredProduct')
          loadStoredProduct(
            storedProductId,
            activeIsInTargetStateFunc,
            listFilter,
            apolloClient,
            undefined,
          )
            .then((storedProduct) => {
              // console.log('api.upsertStoredProduct: resolving with storedProduct', storedProduct)
              resolve(storedProduct)
            }, (error) => {
              logger.error('api.upsertStoredProduct: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertStoredProduct: error', { error })
        reject(error)
      })
  })
)

export default upsertStoredProduct
