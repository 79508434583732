import React, { useEffect, useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { close } from 'ionicons/icons'
import { animated, useSpring } from 'react-spring'

import { AppRoute } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import ActiveUserInfo from './ActiveUserInfo'
import AppMenu from './AppMenu'

type ControlCenterAnimationProps = {
  isShowing: boolean
  maxHeight: string
}
type BackdropAnimationProps = {
  isShowing: boolean
  backgroundColor: string
}

type Props = {
  show: boolean
  userIsAdmin: boolean
  onNavigateTo: (route: string) => void
  onSignOut: () => void
  onClose: () => void
}

const animationProps = {
  collapsed: {
    controlCenter: {
      isShowing: false,
      maxHeight: '0vh',
    },
    backdrop: {
      isShowing: false,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  expanded: {
    controlCenter: {
      isShowing: true,
      maxHeight: '80vh',
    },
    backdrop: {
      isShowing: true,
      backgroundColor: 'rgba(0,0,0,.52)',
    },
  },
}

const ControlCenter: React.FC<Props> = (props): JSX.Element | null => {
  const {
    show,
    userIsAdmin,
    onNavigateTo,
    onSignOut,
    onClose,
  } = props

  // ===================================================================================================================
  // State:
  const { activeUser } = useMimbleData()
  const [isShowingComponent, setIsShowingComponent] = useState(false)
  const [controlCenterStyle, controlCenterStyleRef] = useSpring<ControlCenterAnimationProps>(
    () => animationProps.collapsed.controlCenter,
  )

  const [backdropStyle, backdropStyleRef] = useSpring<BackdropAnimationProps>({
    from: animationProps.expanded.backdrop,
    to: animationProps.collapsed.backdrop,
    config: { duration: 500 },
    onRest: (ds) => {
      if (!ds.value.isShowing) {
        setIsShowingComponent(false)
      }
    },
  }, [show])

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    controlCenterStyleRef.start(show
      ? animationProps.expanded.controlCenter
      : animationProps.collapsed.controlCenter,
    )
    backdropStyleRef.start(show
      ? animationProps.expanded.backdrop
      : animationProps.collapsed.backdrop,
    )
    if (show) {
      setIsShowingComponent(true)
    }
  }, [show])

  // ===================================================================================================================
  // Event Handlers:
  const handleOnNavigateTo = (route: string) => {
    onNavigateTo(route)
    onClose()
  }

  const onOpenAccountPage = (): void => {
    handleOnNavigateTo(AppRoute.USER_ACCOUNT)
  }

  // ===================================================================================================================
  // Rendering:
  if (!isShowingComponent) {
    return null
  }

  return (
    <>
      <animated.div
        className='control-center-backdrop'
        style={backdropStyle}
        onClick={onClose}
      />
      <animated.div className='control-center' style={controlCenterStyle}>
        <div className='header'>
          <ActiveUserInfo
            onOpenAccountPage={onOpenAccountPage}
            className='g-with-flex-1'
          />
          <IonButton fill='clear' onClick={onClose} className='close-button'>
            <IonIcon icon={close} />
          </IonButton>
        </div>
        <AppMenu
          userIsAdmin={userIsAdmin}
          isSignedIn={!!activeUser}
          onNavigateTo={handleOnNavigateTo}
          onSignOut={onSignOut}
          onClose={onClose}
        />
      </animated.div>
    </>
  )
}

export default ControlCenter
