import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import type { AppRoute } from '../../../enums'
import { AppPage, PageMessageType } from '../../../enums'
import type { ContextMenuOptions } from '../../../components/ContextMenu/contextMenuDefs'
import { ContextMenuId, ContextMenuItemId } from '../../../components/ContextMenu/contextMenuDefs'
import { StoredProductListScope, UiMessage, UserRole } from '../../../lib/core/enums'
import type { StoredProduct, StoredProductListFilter } from '../../../lib/core/definitions'
import type {
  StoredProductsQueryData,
  StoredProductsQueryVariables,
} from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import coreHelpers from '../../../lib/core/helpers'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import StoredProductGrid from './StoredProductGrid'
import StoredProductListHeader from './StoredProductListHeader'

const appPageId = AppPage.AdminStoredProductsPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  merchantId: string
}

const StoredProductsPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { merchantId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const { getActiveUserHasRole } = useGlobalCache()
  const isAdmin = getActiveUserHasRole(UserRole.ADMIN)
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [storedProduct, setStoredProduct] = useState<StoredProduct | undefined>()
  const [isLoadingStoredProduct, setIsLoadingStoredProduct] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [searchText, setSearchText] = useState<string | undefined>()
  const [scope, setScope] = useState<StoredProductListScope>(StoredProductListScope.AVAILABLE)

  const filter: StoredProductListFilter = {
    offset: 0,
    limit: 1000,
    // version: new Date().getTime(),
  }

  if (merchantId) {
    filter.merchantId = merchantId
  }

  // ===================================================================================================================
  // Helpers:
  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const getQueryVariables = (): StoredProductsQueryVariables => {
    return {
      filter,
      options: { includeInactive: true },
    }
  }

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading stored products:
  const {
    data: storedProductsData,
    loading: isLoadingStoredProducts,
    refetch,
    client: apolloClient,
  } = useQuery<StoredProductsQueryData, StoredProductsQueryVariables>(
    apollo.admin.queries.storedProducts, {
      variables: getQueryVariables(),
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const storedProducts = storedProductsData ? storedProductsData.storedProducts : undefined
  const isProcessing = isLoadingActiveUser || isLoadingStoredProducts

  // ===================================================================================================================
  // Helpers:
  const reloadStoredProducts = () => {
    console.log('StoredProductsPage.reloadStoredProducts called.')
    refetch(getQueryVariables()).then(undefined, (error) => {
      console.error(error)
    })
  }

  const loadOrSelectStoredProduct = (
    storedProductId: string,
    nextRoute: string,
  ): void => {
    console.log('StoredProductsPage.loadOrSelectStoredProduct called.', { storedProductId, nextRoute })
    if (storedProduct && coreHelpers.models.compareId(storedProduct.id, storedProductId)) {
      if (nextRoute === 'edit') {
        navigate(`/admin/edit-stored-product/${storedProduct.productId}/${storedProduct.productOptionId}/${storedProductId}`)
        return
      }
      // navigate(`/${nextRoute || 'add-stored-product'}/${storedProduct}`);
      return
    }
    setIsLoadingStoredProduct(true)
    api.loadStoredProduct(
      storedProductId,
      undefined,
      undefined,
      apolloClient,
    ).then((loadedStoredProduct) => {
      setIsLoadingStoredProduct(false)
      if (!loadedStoredProduct) {
        showUiMessage('Failed to load the inventory item.')
        return
      }
      setStoredProduct(loadedStoredProduct)
      if (nextRoute === 'edit') {
        navigate(`/admin/edit-stored-product/${loadedStoredProduct.productId}/${loadedStoredProduct.productOptionId}/${storedProductId}`)
      }
    }, (error) => {
      setIsLoadingStoredProduct(false)
      console.error(error)
      showUiMessage('Failed to load the inventory item.')
    })
  }

  // const getLoadingVariables = (): void => {
  //   const dataFetchFilter: StoredProductListFilter = Object.assign({}, filter)
  //   delete dataFetchFilter.searchText
  //   const variables: any = { filter: dataFetchFilter, options: { includeInactive: true } }
  //   reloadStoredProducts(variables)
  // }

  // const refresh = !filter || !filter.version
  // if (!filter) {
  //   filter = {
  //     offset: 0,
  //     limit: 1000,
  //     version: new Date().getTime(),
  //   }
  // }
  // if (filter.searchText !== searchText) {
  //   filter.searchText = searchText
  //   // For now we don't pull a new, filtered list from the server, but filter it client-side:
  //   // filter.version = new Date().getTime();
  //   // refresh = true;
  // }

  // ===================================================================================================================
  // Event Handlers:
  const onApplyListFilter = (newSearchText: string, newScope: StoredProductListScope): void => {
    // console.log('StoredProductsPage.onApplyListFilter called.', { newSearchText, newScope });
    if (newSearchText !== searchText) {
      setSearchText(newSearchText)
    }
    if (newScope !== scope) {
      setScope(newScope)
    }
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadStoredProducts()
  }

  const onContextMenu = (id: ContextMenuItemId): void => {
    // console.log('Context menu clicked:', id);
    switch (id) {
      case ContextMenuItemId.REFRESH:
        reloadStoredProducts()
        break
      default:
        console.error('StoredProductsPage.onContextMenu: Unexpected context menu item received.', id)
    }
  }

  const onOpenStoredProduct = (storedProductId: string): void => {
    loadOrSelectStoredProduct(storedProductId, 'edit')
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const contextMenuOptions: ContextMenuOptions = {
    addAdminItems: isAdmin,
  }

  return (
    <IonPage className='app-page-admin stored-products-page'>
      <NavBar
        title='Manage Inventory'
        contextMenuId={ContextMenuId.STORED_PRODUCTS_PAGE}
        contextMenuOptions={contextMenuOptions}
        onContextMenu={onContextMenu}
        isProcessing={isProcessing}
      />
      <StoredProductListHeader
        searchText={searchText}
        scope={scope}
        onApply={onApplyListFilter}
      />
      <IonContent className='g-content-with-padding' scrollY={false}>
        <div className='g-non-scroll-content-wrapper'>
          <PageMessages />
          <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <StoredProductGrid
            storedProducts={storedProducts}
            filter={{ ...filter, searchText }}
            scope={scope}
            onOpenStoredProduct={onOpenStoredProduct}
          />
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default StoredProductsPage
