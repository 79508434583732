import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, ContactEventListFilter, ContactEventReminder } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  ContactEventReminderQueryData,
  ContactEventReminderQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadContactEventReminder = (
  contactEventReminderId: string,
  isInTargetStateFunc: ((contactEventReminder: ContactEventReminder) => boolean) | 'watch-updated-at' | 'expect-null' | undefined,
  listFilter: ContactEventListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ContactEventReminder | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadContactEventReminder called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadContactEventReminder: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<ContactEventReminderQueryVariables> = {
      query: apollo.queries.contactEventReminder,
      variables: { contactEventReminderId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<ContactEventReminderQueryData, ContactEventReminderQueryVariables>(queryOptions)
        .then((resp) => {
          if (!resp || !resp.data) {
            logger.error('api.loadContactEventReminder: query did not return anything.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((contactEventReminder: ContactEventReminder) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.contactEventReminder.updatedAt
            activeIsInTargetStateFunc = (contactEventReminder: ContactEventReminder) => contactEventReminder.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc === 'expect-null') {
            if (!resp.data.contactEventReminder) {
              resolve(undefined)
              return
            }
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !resp.data.contactEventReminder ||
            !resp.data.contactEventReminder.id
          ) {
            console.log('api.loadContactEventReminder: query did not return expected data.', { resp })
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.contactEventReminder)
          ) {
            // console.log('api.loadContactEventReminder: resolving with info.', resp.data.contactEventReminder)
            // if (clientOptions && clientOptions.updateList) {
            //   const queryListVariables = clientOptions && clientOptions.updateList && listFilter
            //     ? { filter: listFilter }
            //     : undefined
            //   cache.updateContactEventReminder(
            //     resp.data.contactEventReminder,
            //     options.queryListVariables as ContactEventReminderesQueryVariables | undefined,
            //     apolloClient,
            //   )
            // }
            resolve(resp.data.contactEventReminder)
            return
          }

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadContactEventReminder: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadContactEventReminder.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadContactEventReminder
