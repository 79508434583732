import React, { useContext, useEffect, useState } from 'react'
import { IonContent, IonPage, IonToast, useIonViewWillLeave } from '@ionic/react'
import { Update } from 'history'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import type { AppRoute } from '../../enums'
import { AppPage, PageMessageType } from '../../enums'
import { UiMessage } from '../../lib/core/enums'
import type {
  InvitationQueryData,
  InvitationQueryVariables,
  UpsertInvitationData,
  UpsertInvitationVariables,
} from '../../services/apollo/definitions'
import type { InvitationInput } from '../../lib/core/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import NavBar from '../../components/NavBar/NavBar'
// import NewInvitationForm from './NewInvitationForm'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import SendByMemberContent from './SendByMemberContent'

const appPageId = AppPage.InvitePage
const appPageDef = pageHelpers.appPageDefs[appPageId]

/*
  upsertInvitation -> wait 2sec -> loadInvitation -> check invitation sent? -> poll until it was sent out
 */

// type Params = {
//   id: string
// }

const NewInvitationPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [isWaitingToCreateInvitation, setIsWaitingToCreateInvitation] = useState(false)
  const [isPollingInvitation, setIsPollingInvitation] = useState(false)

  // ===================================================================================================================
  // Helpers:
  const onResetPage = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (isPollingInvitation && stopPollingInvitation) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      stopPollingInvitation()
      setIsPollingInvitation(false)
    }
    setIsWaitingToCreateInvitation(false)
    pageMessages && pageMessages.clear()
  }

  const onNavigateNext = (invitationId: string): void => {
    setToastMessage('Your invitation has been sent.')
    setShowToast(true)
    navigate(-1)
    onResetPage()
  }

  // ===================================================================================================================
  // Apollo Hooks:
  const [
    loadInvitation,
    {
      data: invitationLoadedData,
      loading: isLoadingInvitation,
      error: invitationLoadingError,
      startPolling: startPollingInvitation,
      stopPolling: stopPollingInvitation,
    },
  ] = useLazyQuery<InvitationQueryData, InvitationQueryVariables>(
    apollo.queries.invitation,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // console.log('NewInvitationPage: purchase transfer loaded:', data)
        const invitation = data ? data.invitation : undefined

        if (!invitation) {
          console.log('NewInvitationPage: no purchase transfer returned from loading.')
          return
        }

        if (invitation.messageSentAt && invitation.messageSendReport === 'ok') {
          setToastMessage('Your invitation has been sent out!')
          setShowToast(true)
          onNavigateNext(invitation.id as string)
          return
        }

        if (invitation.messageSendReport && invitation.messageSendReport !== 'ok') {
          setToastMessage('We could not send out your invitation. Please double-check the recipient and try again!')
          setShowToast(true)
          return
        }

        if (startPollingInvitation && !isPollingInvitation) {
          setIsPollingInvitation(true)
          startPollingInvitation(1000)
        }

        // if (!isWaitingToCreateInvitation || invitationLoadingError) {
        //   return
        // }
      },
    },
  )
  const invitation = invitationLoadedData ? invitationLoadedData.invitation : undefined

  useEffect((): void => {
    if (invitationLoadingError) {
      pageHelpers.checkForUnauthorized(invitationLoadingError, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      if (isPollingInvitation && stopPollingInvitation) {
        stopPollingInvitation()
        setIsPollingInvitation(false)
      }
    }
  }, [invitationLoadingError])

  // -------------------------------------------------------------------------------------------------------------------
  // Upserting invitation:
  const [
    upsertInvitation, {
      loading: isUpsertingInvitation,
      error: upsertInvitationError,
    },
  ] = useMutation<UpsertInvitationData, UpsertInvitationVariables>(apollo.mutations.upsertInvitation, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (upsertInvitationData) => {
      if (
        !upsertInvitationData ||
        !upsertInvitationData.upsertInvitation ||
        !upsertInvitationData.upsertInvitation.id ||
        upsertInvitationError
      ) {
        return
      }
      setIsWaitingToCreateInvitation(true)
      setTimeout(() => {
        loadInvitation({
          variables: {
            invitationId: upsertInvitationData.upsertInvitation.id as string,
          },
        })
      }, 2000)
    },
  })

  useEffect((): void => {
    if (upsertInvitationError) {
      pageHelpers.checkForUnauthorized(upsertInvitationError, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SENDING_GIFT_CARD)
      if (isPollingInvitation && stopPollingInvitation) {
        stopPollingInvitation()
        setIsPollingInvitation(false)
      }
    }
  }, [upsertInvitationError])

  // ===================================================================================================================
  // Helpers:
  const isProcessing = (
    isLoadingActiveUser ||
    isLoadingInvitation ||
    isUpsertingInvitation ||
    isWaitingToCreateInvitation
  )

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  useIonViewWillLeave(() => {
    onResetPage()
  })

  // ===================================================================================================================
  // Event Handlers:
  const goBack = (): void => {
    navigate(-1)
  }

  const onUpsertInvitation = (invitation: InvitationInput): void => {
    if (isProcessing) {
      return
    }
    upsertInvitation({ variables: { invitation } })
  }

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  return (
    <IonPage className='app-page-public new-invitation-page'>
      <NavBar
        title='Invite a Friend'
        isProcessing={false}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <SendByMemberContent
          invitation={invitation}
          isProcessing={isProcessing}
          isUpsertingInvitation={isUpsertingInvitation}
          onUpsertInvitation={onUpsertInvitation}
          goBack={goBack}
          showUiMessage={showUiMessage}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default NewInvitationPage
