import { AppPage, AppRoute } from '../../enums'
import { AppTabScope } from '../../components/AppPageFooter/enums'
import { UserRole } from '../../lib/core/enums'

const appPageDefs = {
  [AppPage.AboutPage]: {
    name: 'AboutPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/about$/),
    requireAuth: false,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.AdminCampaignPage]: {
    name: 'AdminCampaignPage',
    route: AppRoute.ADMIN_CAMPAIGN,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/campaign\/?/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminCampaignsPage]: {
    name: 'AdminCampaignsPage',
    route: AppRoute.ADMIN_CAMPAIGNS,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/campaigns$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminDashboardPage]: {
    name: 'AdminDashboardPage',
    route: AppRoute.ADMIN_DASHBOARD,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminPurchasesPage]: {
    name: 'AdminPurchasesPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/purchases$/),
    requireAuth: true,
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminEditCampaignPage]: {
    name: 'EditCampaignPage',
    route: AppRoute.ADMIN_EDIT_MEMBER_MESSAGE_TEMPLATE,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/(add|edit)-sys-msg\/?[a-zA-Z\d-]*\/?[a-zA-Z\d-]*\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminEditMerchantPage]: {
    name: 'EditMerchantPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/(add|edit)-merchant\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminEditMemberMessageTemplatePage]: {
    name: 'EditMemberMessageTemplatePage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/(add|edit)-member-msg-templ\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminEditProductOptionPage]: {
    name: 'EditProductOptionPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/(add|edit)-product-option\/[a-zA-Z\d-]*\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminEditProductPage]: {
    name: 'EditProductPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/(add|edit)-product\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminEditStoredProductPage]: {
    name: 'EditStoredProductPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/(add|edit)-stored-product\/?[a-zA-Z\d-]*\/?[a-zA-Z\d-]*\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminEditTagPage]: {
    name: 'EditTagPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/(add|edit)-tag\/?[a-zA-Z\d-]*\/?[a-zA-Z\d-]*\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminEditUserPage]: {
    name: 'EditUserPage',
    route: AppRoute.ADMIN_USER,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/\/admin\/edit-user\/\w*$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminExecuteAdminTaskPage]: {
    name: 'ExecuteAdminTaskPage',
    route: AppRoute.ADMIN_EXECUTE_ADMIN_TASK,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/admin-task\/.*/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminImportDataPage]: {
    name: 'ImportDataPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/(import-stored-products|data-import)$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminMemberMessageTemplatePage]: {
    name: 'MemberMessageTemplatePage',
    route: AppRoute.ADMIN_MEMBER_MESSAGE_TEMPLATE,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/member-msg-templ\/?/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminMemberMessageTemplatesPage]: {
    name: 'MemberMessageTemplatesPage',
    route: AppRoute.ADMIN_MEMBER_MESSAGE_TEMPLATES,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/member-msg-templs$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminNewTransactionPage]: {
    name: 'NewTransactionPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/send-funds$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminOrgChatPage]: {
    name: 'OrgChatPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/mimble-team-chat\//),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminOrgMessagesPage]: {
    name: 'OrgMessagesPage',
    route: AppRoute.ADMIN_ORG_MESSAGES,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/mimble-team-messages$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminProductPage]: {
    name: 'ProductPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/product\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminProductsPage]: {
    name: 'ProductsPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/products$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminReportsPage]: {
    name: 'ReportsPage',
    route: AppRoute.ADMIN_REPORTS,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/reports\/?/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminSignInAsAnotherUserPage]: {
    name: 'SignInAsAnotherUserPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/sign-in-as$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    allowAuthUserRoles: true,
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminStoredProductsPage]: {
    name: 'StoredProductsPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/inventory\/?/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminTagsPage]: {
    name: 'TagsPage',
    route: AppRoute.TAGS,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/tags\/?/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminUserPage]: {
    name: 'UserPage',
    route: AppRoute.ADMIN_USER,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/\/admin\/user\/\w*$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminUsersPage]: {
    name: 'UsersPage',
    route: AppRoute.ADMIN_USERS,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/\/admin\/users\/\w*$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.AdminMerchantsPage]: {
    name: 'MerchantsPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/admin\/merchants$/),
    requireAuth: true,
    roles: [UserRole.ADMIN],
    appTabScope: AppTabScope.ADMIN,
  },
  [AppPage.BarcodePage]: {
    name: 'BarcodePage',
    route: AppRoute.BARCODE,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/barcode$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.BuyTokenPage]: {
    name: 'BuyTokenPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/buy-mit$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.ChatPage]: {
    name: 'ChatPage',
    route: AppRoute.MESSAGES,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/messages\/?/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.CheckoutPaymentReceivedPage]: {
    name: 'CheckoutPaymentReceivedPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/payment-received\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.CheckoutProductOptionPage]: {
    name: 'CheckoutProductOptionPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/checkout-option\//),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.ConfirmAsyncTaskTokenPage]: {
    name: 'ConfirmAsyncTaskTokenPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/confirm\//),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.ContactsPage]: {
    name: 'ContactsPage',
    route: AppRoute.CONTACTS,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/contacts\//),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.ContactPage]: {
    name: 'ContactPage',
    route: AppRoute.CONTACT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/contact\/?/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.DashboardPage]: {
    name: 'DashboardPage',
    route: AppRoute.DASHBOARD,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/dashboard$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.GiftReceivedPage]: {
    name: 'GiftReceivedPage',
    route: AppRoute.GIFT_RECEIVED,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/gift\/?/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.HelpPage]: {
    name: 'HelpPage',
    route: AppRoute.HELP,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/help\/?/),
    requireAuth: false,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.InvitePage]: {
    name: 'InvitePage',
    route: AppRoute.INVITE,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/invite\/?/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.JoinPage]: {
    name: 'JoinPage',
    route: AppRoute.JOIN,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/sign-up$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.LoggingPage]: {
    name: 'LoggingPage',
    route: AppRoute.LOGGING,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/logging$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.MarketplacePage]: {
    name: 'MarketplacePage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/marketplace/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.MessagesPage]: {
    name: 'MessagesPage',
    route: AppRoute.CONTACTS,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/messages$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.NewInvitationPage]: {
    name: 'NewInvitationPage',
    route: AppRoute.NEW_INVITATION,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/new-invite\/?/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.NewUploadedPurchasePage]: {
    name: 'NewUploadedPurchasePage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/upload-card\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.OrderPage]: {
    name: 'OrderPage',
    route: AppRoute.ORDER,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/order\/?[a-zA-Z\d-]*/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.PurchasePage]: {
    name: 'PurchasePage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/card\/?/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.ResetPasswordPage]: {
    name: 'ResetPasswordPage',
    route: AppRoute.RESET_PASSWORD,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/reset-password\/?/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.RewardPage]: {
    name: 'RewardPage',
    route: AppRoute.REWARD,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/reward\//),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.SelectMerchantPage]: {
    name: 'SelectMerchantPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/select-brand\/?/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.SendGiftPage]: {
    name: 'SendGiftPage',
    route: AppRoute.SEND_GIFT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/send-gift$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.ShoppingCartPage]: {
    name: 'ShoppingCartPage',
    route: AppRoute.ABOUT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/checkout-cc$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.SignInPage]: {
    name: 'SignInPage',
    route: AppRoute.SIGN_IN,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/sign-in$/),
    requireAuth: false,
    appTabScope: AppTabScope.UNAUTHENTICATED,
  },
  [AppPage.TokensPage]: {
    name: 'TokensPage',
    route: AppRoute.TOKENS,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/tokens$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.TransactionPage]: {
    name: 'TransactionPage',
    route: AppRoute.TRANSACTION,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/transaction\//),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.UserAccountPage]: {
    name: 'UserAccountPage',
    route: AppRoute.USER_ACCOUNT,
    routeMatches: (pathname: string): boolean => !!pathname && !!pathname.match(/^\/account$/),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.WalletPage]: {
    name: 'WalletPage',
    route: AppRoute.WALLET,
    routeMatches: (pathname: string): boolean => !!pathname && (pathname === '/' || !!pathname.match(/^\/wallet\/?/)),
    requireAuth: true,
    appTabScope: AppTabScope.MAIN,
  },
  [AppPage.WelcomePage]: {
    name: 'WelcomePage',
    route: AppRoute.WELCOME,
    routeMatches: (pathname: string): boolean => !!pathname && (pathname === '/' || !!pathname.match(/^\/welcome\/?/)),
    requireAuth: false,
    appTabScope: AppTabScope.UNAUTHENTICATED,
  },
}

export default appPageDefs
