import { gql } from '@apollo/client'
import type { DocumentNode } from 'graphql/language/ast'

export interface ProductQueryOptions {
  includeMerchant?: boolean,
  includeProductOptions?: boolean,
  includeStoredProducts?: boolean,
  includeTags?: boolean,
}

const products = (options?: ProductQueryOptions): DocumentNode => {
  const merchant = options && options.includeMerchant
    ? `merchant {
        id
        name
        description
        url
        smallLogoImageSource
        largeLogoImageSource
        balanceLookupUri
        listed
        paymentTypes
        source
        recordStatus
        createdAt
        updatedAt
      }`
    : ''
  const productOptions = options && options.includeProductOptions
    ? `productOptions {
         id
         productId
         orderIndex
         labelEn
         amount
         fundType
         currency
         reward
         discount
         inventory
         listed
         source
         recordStatus
         createdAt
         updatedAt
       }`
    : ''
  const storedProducts = options && options.includeStoredProducts
    ? `storedProducts {
         id
         productId
         productOptionId
         purchaseId
         merchantId
         merchantName
         status
         code
         amount
         fundType
         currency
         discount
         receivedAt
         paidAt
         poNumber
         source
         recordStatus
         createdAt
         updatedAt
      }`
    : ''
  const tags = options && options.includeTags
    ? `tags {
         id
         name
       }`
    : ''

  return gql`
      query Q($productId: String!) {
          product (productId: $productId) {
              id
              genericProductId
              merchantId
              offerId
              productType
              code
              hasBarcode
              barcodeFormat
              referenceUrl
              messageEn
              contentFrontSide
              contentBackSide
              termsEn
              termsUrl
              instructionsEn
              instructionsUrl
              imageSourceFrontSide
              imageSourceBackSide
              isMainProduct
              hasPin
              stock
              listed
              # metadata
              recordStatus
              createdAt
              updatedAt
              ${productOptions}
              ${storedProducts}
              ${merchant}
              ${tags}
          }
      }`
}

export default products
