import { gql } from '@apollo/client'

export default gql`
  query Q($userId: String!) {
    inbox(userId: $userId) {
      id
      appBadge
      chatMessageCount
      systemMessageCount
      giftCount
      # metadata
      recordStatus
      createdAt
      updatedAt
      items {
        id
        inboxItemType
        modelId
        metadata {
          chat {
            contactId
            contactFullName
            contactUsername
            contactImageUrl
            unreadMessageCount
            latestMessageText
            latestMessageSentAt
          }
          purchase {
            balance
            fundType
            currency
            productType
            productImageSourceFrontSide
            merchantSmallLogoImageSource
            merchantLargeLogoImageSource
          }
        }
        recordStatus
        createdAt
        updatedAt
      }
    }
  }`
