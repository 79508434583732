const simpleTimezones = [
  { value: 'Pacific/Honolulu', label: 'HST - Honolulu', labelLong: 'Hawaii Standard Time - Honolulu' },
  { value: 'America/Anchorage', label: 'AST - Anchorage', labelLong: 'Alaska Standard Time - Anchorage' },
  { value: 'America/Los_Angeles', label: 'PST - Los Angeles', labelLong: 'Pacific Standard Time - Los Angeles' },
  { value: 'America/Denver', label: 'MST - Salt Lake City', labelLong: 'Mountain Standard Time - Salt Lake City' },
  { value: 'America/Chicago', label: 'CST - Chicago', labelLong: 'Central Standard Time - Chicago' },
  { value: 'America/New_York', label: 'EST - New York', labelLong: 'Eastern Standard Time - New York' },
]

export default {
  simpleTimezones,
  getSimpleTimezoneLabel: (val: string): string | undefined => {
    const def = simpleTimezones.find(z => z.value === val)
    return def ? def.label : undefined
  },
  groupNames: [
    'Africa',
    'America',
    'Antarctica',
    'Asia',
    'Atlantic',
    'Australia',
    'Europe',
    'Indian',
    'Pacific',
  ],
  groups: {
    Africa: [
      'Abidjan',
      'Accra',
      'Algiers',
      'Bissau',
      'Cairo',
      'Casablanca',
      'Ceuta',
      'El_Aaiun',
      'Johannesburg',
      'Juba',
      'Khartoum',
      'Lagos',
      'Maputo',
      'Monrovia',
      'Nairobi',
      'Ndjamena',
      'Sao_Tome',
      'Tripoli',
      'Tunis',
      'Windhoek',
    ],
    America: [
      'Adak',
      'Anchorage',
      'Araguaina',
      'Argentina/Buenos_Aires',
      'Argentina/Catamarca',
      'Argentina/Cordoba',
      'Argentina/Jujuy',
      'Argentina/La_Rioja',
      'Argentina/Mendoza',
      'Argentina/Rio_Gallegos',
      'Argentina/Salta',
      'Argentina/San_Juan',
      'Argentina/San_Luis',
      'Argentina/Tucuman',
      'Argentina/Ushuaia',
      'Asuncion',
      'Atikokan',
      'Bahia',
      'Bahia_Banderas',
      'Barbados',
      'Belem',
      'Belize',
      'Blanc-Sablon',
      'Boa_Vista',
      'Bogota',
      'Boise',
      'Cambridge_Bay',
      'Campo_Grande',
      'Cancun',
      'Caracas',
      'Cayenne',
      'Chicago',
      'Chihuahua',
      'Costa_Rica',
      'Creston',
      'Cuiaba',
      'Curacao',
      'Danmarkshavn',
      'Dawson',
      'Dawson_Creek',
      'Denver',
      'Detroit',
      'Edmonton',
      'Eirunepe',
      'El_Salvador',
      'Fort_Nelson',
      'Fortaleza',
      'Glace_Bay',
      'Godthab',
      'Goose_Bay',
      'Grand_Turk',
      'Guatemala',
      'Guayaquil',
      'Guyana',
      'Halifax',
      'Havana',
      'Hermosillo',
      'Indiana/Indianapolis',
      'Indiana/Knox',
      'Indiana/Marengo',
      'Indiana/Petersburg',
      'Indiana/Tell_City',
      'Indiana/Vevay',
      'Indiana/Vincennes',
      'Indiana/Winamac',
      'Inuvik',
      'Iqaluit',
      'Jamaica',
      'Juneau',
      'Kentucky/Louisville',
      'Kentucky/Monticello',
      'La_Paz',
      'Lima',
      'Los_Angeles',
      'Maceio',
      'Managua',
      'Manaus',
      'Martinique',
      'Matamoros',
      'Mazatlan',
      'Menominee',
      'Merida',
      'Metlakatla',
      'Mexico_City',
      'Miquelon',
      'Moncton',
      'Monterrey',
      'Montevideo',
      'Nassau',
      'New_York',
      'Nipigon',
      'Nome',
      'Noronha',
      'North_Dakota/Beulah',
      'North_Dakota/Center',
      'North_Dakota/New_Salem',
      'Ojinaga',
      'Panama',
      'Pangnirtung',
      'Paramaribo',
      'Phoenix',
      'Port-au-Prince',
      'Port_of_Spain',
      'Porto_Velho',
      'Puerto_Rico',
      'Punta_Arenas',
      'Rainy_River',
      'Rankin_Inlet',
      'Recife',
      'Regina',
      'Resolute',
      'Rio_Branco',
      'Santarem',
      'Santiago',
      'Santo_Domingo',
      'Sao_Paulo',
      'Scoresbysund',
      'Sitka',
      'St_Johns',
      'Swift_Current',
      'Tegucigalpa',
      'Thule',
      'Thunder_Bay',
      'Tijuana',
      'Toronto',
      'Vancouver',
      'Whitehorse',
      'Winnipeg',
      'Yakutat',
      'Yellowknife',
    ],
    Antarctica: [
      'Casey',
      'Davis',
      'DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
      'Macquarie',
      'Mawson',
      'Palmer',
      'Rothera',
      'Syowa',
      'Troll',
      'Vostok',
    ],
    Asia: [
      'Almaty',
      'Amman',
      'Anadyr',
      'Aqtau',
      'Aqtobe',
      'Ashgabat',
      'Atyrau',
      'Baghdad',
      'Baku',
      'Bangkok',
      'Barnaul',
      'Beirut',
      'Bishkek',
      'Brunei',
      'Chita',
      'Choibalsan',
      'Colombo',
      'Damascus',
      'Dhaka',
      'Dili',
      'Dubai',
      'Dushanbe',
      'Famagusta',
      'Gaza',
      'Hebron',
      'Ho_Chi_Minh',
      'Hong_Kong',
      'Hovd',
      'Irkutsk',
      'Jakarta',
      'Jayapura',
      'Jerusalem',
      'Kabul',
      'Kamchatka',
      'Karachi',
      'Kathmandu',
      'Khandyga',
      'Kolkata',
      'Krasnoyarsk',
      'Kuala_Lumpur',
      'Kuching',
      'Macau',
      'Magadan',
      'Makassar',
      'Manila',
      'Nicosia',
      'Novokuznetsk',
      'Novosibirsk',
      'Omsk',
      'Oral',
      'Pontianak',
      'Pyongyang',
      'Qatar',
      'Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
      'Qyzylorda',
      'Riyadh',
      'Sakhalin',
      'Samarkand',
      'Seoul',
      'Shanghai',
      'Singapore',
      'Srednekolymsk',
      'Taipei',
      'Tashkent',
      'Tbilisi',
      'Tehran',
      'Thimphu',
      'Tokyo',
      'Tomsk',
      'Ulaanbaatar',
      'Urumqi',
      'Ust-Nera',
      'Vladivostok',
      'Yakutsk',
      'Yangon',
      'Yekaterinburg',
      'Yerevan',
    ],
    Atlantic: [
      'Azores',
      'Bermuda',
      'Canary',
      'Cape_Verde',
      'Faroe',
      'Madeira',
      'Reykjavik',
      'South_Georgia',
      'Stanley',
    ],
    Australia: [
      'Adelaide',
      'Brisbane',
      'Broken_Hill',
      'Currie',
      'Darwin',
      'Eucla',
      'Hobart',
      'Lindeman',
      'Lord_Howe',
      'Melbourne',
      'Perth',
      'Sydney',
    ],
    Europe: [
      'Amsterdam',
      'Andorra',
      'Astrakhan',
      'Athens',
      'Belgrade',
      'Berlin',
      'Brussels',
      'Bucharest',
      'Budapest',
      'Chisinau',
      'Copenhagen',
      'Dublin',
      'Gibraltar',
      'Helsinki',
      'Istanbul',
      'Kaliningrad',
      'Kiev',
      'Kirov',
      'Lisbon',
      'London',
      'Luxembourg',
      'Madrid',
      'Malta',
      'Minsk',
      'Monaco',
      'Moscow',
      'Oslo',
      'Paris',
      'Prague',
      'Riga',
      'Rome',
      'Samara',
      'Saratov',
      'Simferopol',
      'Sofia',
      'Stockholm',
      'Tallinn',
      'Tirane',
      'Ulyanovsk',
      'Uzhgorod',
      'Vienna',
      'Vilnius',
      'Volgograd',
      'Warsaw',
      'Zaporozhye',
      'Zurich',
    ],
    Indian: [
      'Chagos',
      'Christmas',
      'Cocos',
      'Kerguelen',
      'Mahe',
      'Maldives',
      'Mauritius',
      'Reunion',
    ],
    Pacific: [
      'Apia',
      'Auckland',
      'Bougainville',
      'Chatham',
      'Chuuk',
      'Easter',
      'Efate',
      'Enderbury',
      'Fakaofo',
      'Fiji',
      'Funafuti',
      'Galapagos',
      'Gambier',
      'Guadalcanal',
      'Guam',
      'Honolulu',
      'Kiritimati',
      'Kosrae',
      'Kwajalein',
      'Majuro',
      'Marquesas',
      'Nauru',
      'Niue',
      'Norfolk',
      'Noumea',
      'Pago_Pago',
      'Palau',
      'Pitcairn',
      'Pohnpei',
      'Port_Moresby',
      'Rarotonga',
      'Tahiti',
      'Tarawa',
      'Tongatapu',
      'Wake',
      'Wallis',
    ],
  },
  all: [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Algiers',
    'Africa/Bissau',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/El_Aaiun',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Khartoum',
    'Africa/Lagos',
    'Africa/Maputo',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Sao_Tome',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Asuncion',
    'America/Atikokan',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Cayenne',
    'America/Chicago',
    'America/Chihuahua',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Fort_Nelson',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Sitka',
    'America/St_Johns',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Colombo',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kathmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Qyzylorda',
    'Asia/Riyadh',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ulaanbaatar',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faroe',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/Stanley',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/Perth',
    'Australia/Sydney',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Reunion',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Wake',
    'Pacific/Wallis',
  ],
}
