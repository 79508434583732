import React, { useState } from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import { MenuItem, Select } from '@mui/material'
import { alertCircleOutline } from 'ionicons/icons'

import type { TransactionProcessor } from '../../../lib/core/enums'
import type { ShoppingCart, ShoppingCartItem } from '../../../lib/core/definitions'
import ChipButton from '../../../components/ChipButton/ChipButton'
import coreHelpers from '../../../lib/core/helpers/'
import FormItem from '../../../components/FormItem/FormItem'
import GiftCard from '../../../components/GiftCard/GiftCard'
import ItemReward from './ItemReward'
import pageHelpers from '../../../helpers/pageHelpers'

type Props = {
  shoppingCart: ShoppingCart
  item: ShoppingCartItem
  index: number
  transactionProcessor: TransactionProcessor
  readonly: boolean | undefined
  canEdit: boolean
  isEditing: boolean
  onStartEdit: (itemId: string) => void
  onStopEdit: (itemId: string) => void
  onRemoveItem: (itemId: string) => void
}

const ShoppingCartRowGiftCard: React.FC<Props> = (props): JSX.Element | null => {
  const {
    shoppingCart,
    item,
    index,
    transactionProcessor,
    readonly,
    canEdit,
    isEditing,
    onStartEdit,
    onStopEdit,
    onRemoveItem,
  } = props
  const { productCount } = item

  const paymentFundType = shoppingCart && shoppingCart.paymentFundType
  const paymentCurrency = shoppingCart && shoppingCart.paymentCurrency
  const paymentExchangeRate = shoppingCart && shoppingCart.paymentExchangeRate
  const amountsInfo = coreHelpers.models.shoppingCartItem.getAmountsInfo(
    item,
    paymentFundType,
    paymentCurrency,
    paymentExchangeRate,
  )
  const product = item && item.product
  const availableInventory = coreHelpers.models.shoppingCartItem.getAvailableInventory(item)
  const merchant = product && product.merchant
  const merchantName = merchant && merchant.name
  const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromProduct(product)

  const [newProductCount, setNewProductCount] = useState<number | undefined>()
  const [isEditingAmount, setIsEditingAmount] = useState(false)

  // -------------------------------------------------------------------------------------------------------------------
  // Event Handlers:
  const handleStartEdit = () => {
    if (item && item.id) {
      onStartEdit(item.id)
    }
  }

  const handleOpenEditAmount = () => {
    setIsEditingAmount(true)
  }

  const handleCloseEditAmount = () => {
    setIsEditingAmount(false)
  }

  const handleRemoveItem = () => {
    if (!item || !item.id) {
      return
    }
    onRemoveItem(item.id)
  }

  const onEditProductCount = (event: any) => {
    // if (!item || !item.id) {
    //   return
    // }
    // // console.log('productCount, newProductCount', productCount, newProductCount)
    // const count = event.target.value === productCount ? undefined : event.target.value
    // setNewProductCount(count)
    // if (count || count === 0) {
    //   setShoppingCart({
    //     ...shoppingCart,
    //     items: [{
    //       id: item.id,
    //       productCount: count,
    //     }],
    //     command: ShoppingCartCommand.UPDATE_ITEM,
    //   }).then(() => {
    //     onStopEdit(item.id as string)
    //   }, (error) => {
    //     console.error(error)
    //   })
    //   console.log('newProductCount set', count, newProductCount)
    // } else {
    //   console.log('newProductCount, no change', newProductCount)
    //   onStopEdit(item.id)
    // }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Rendering:
  const formattedProductAmount = coreHelpers.ui.formatAmount(
    amountsInfo.productAmount.amount,
    amountsInfo.productAmount.fundType,
    amountsInfo.productAmount.currency,
    true,
    amountsInfo.productAmount.precision,
  )
  const formattedPaymentAmount = amountsInfo.paymentAmount.amount && amountsInfo.paymentAmount.amount > 0
    ? coreHelpers.ui.formatAmount(
      amountsInfo.paymentAmount.amount,
      amountsInfo.paymentAmount.fundType,
      amountsInfo.paymentAmount.currency,
      false,
      amountsInfo.paymentAmount.precision,
    )
    : ''
  // console.log('ShoppingCartRowGiftCard.render called.', { props, formattedProductAmount, formattedPaymentAmount })
  const key = `${merchantName}-${index}`
  const productCountIsOutOfStock = (productCount || 1) > (availableInventory || 0)

  let countSection: JSX.Element | undefined
  if (isEditing) {
    countSection = (
      <div>
        <FormItem>
          <IonInput
            id={'count-input-' + index}
            type='number'
            value={productCount}
            onIonChange={onEditProductCount} // todo onEditProductAmount
            debounce={2000}
            clearOnEdit={true}
            enterkeyhint='done'
            className='count-input'
          >
          </IonInput>
        </FormItem>
      </div>
    )
  } else if (item) {
    const productCountClass = productCountIsOutOfStock
      ? 'product-count-out-of-stock'
      : ''
    const oosIcon = productCountIsOutOfStock
      ? <IonIcon icon={alertCircleOutline} className='withSmallRightMargin'/>
      : undefined
    countSection = (
      <div className={productCountClass}>
        {oosIcon}
        {productCount || 1}
      </div>
    )
  }

  let amountSection: JSX.Element | undefined
  if (isEditing) {
    if (!product || !product.productOptions) {
      return null
    }
    const amountOptions = product.productOptions
      .map(v => (
        <MenuItem
          key={v.id}
          value={v.amount}
        >
          {formattedProductAmount}
        </MenuItem>
      ))
    amountSection = (
      <div>
        <Select
          labelId="demo-controlled-open-select-label"
          label={formattedProductAmount}
          id={item.id as string}
          open={isEditingAmount}
          onClose={handleCloseEditAmount}
          onOpen={handleOpenEditAmount}
          value={amountsInfo.productAmount}
          onChange={onEditProductCount}
          variant='outlined'
        >
          <MenuItem value='' disabled>{formattedProductAmount}</MenuItem>
          {amountOptions}
        </Select>
      </div>
    )
  } else if (product && product.productOptions) {
    amountSection = (
      <div>
        <span>{formattedPaymentAmount}</span>
      </div>
    )
  }

  let oosWarning: JSX.Element | undefined
  if (productCountIsOutOfStock) {
    const oosWarningMessage = availableInventory === 0 ? 'Back-ordered item' : 'Low inventory'
    oosWarning = (
      <div className='out-of-stock-row-note'>
        Note: {oosWarningMessage}
      </div>
    )
  }

  let editButton: JSX.Element | undefined
  if (!readonly && canEdit && !isEditing) {
    editButton = (
      <ChipButton
        text='Edit'
        block={false}
        className='withStandardRightMargin'
        onClick={handleStartEdit}
      />
    )
  }

  let removeButton: JSX.Element | undefined
  if (!readonly && !isEditing) {
    removeButton = (
      <ChipButton
        text='Remove'
        block={false}
        color='var(--ion-color-medium)'
        onClick={handleRemoveItem}
      />
    )
  }

  return (
    <div key={key} className='cart-row'>
      <div className='cart-col cart-col0'>
        <GiftCard
          purchaseId=''
          backgroundImageUri={giftCardBackgroundUri}
          merchantLogoUri={merchantLogoUri}
          small
        />
      </div>
      <div className='cart-col cart-col1'>
        <div className='cart-col1-row0'>
          {formattedProductAmount} Gift card to {merchantName}
          {oosWarning}
          <div className='withSmallTopMargin'>
            {editButton}
            {removeButton}
          </div>
        </div>
        <ItemReward
          amount={amountsInfo.rewardAmount.amount || 0}
          fundType={amountsInfo.rewardAmount.fundType}
          currency={amountsInfo.rewardAmount.currency}
          precision={amountsInfo.rewardAmount.precision || 0}
        />
      </div>
      <div className='cart-col cart-col2 withRightTextAlign'>
        {countSection}
      </div>
      <div className='cart-col cart-col3 withRightTextAlign'>
        {amountSection}
      </div>
    </div>
  )
}

export default ShoppingCartRowGiftCard
