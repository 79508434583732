import type { ShoppingCart } from '../../../definitions'
import isItemInStock from '../shoppingCartItem/isInStock'

const isInStock = (shoppingCart: ShoppingCart): boolean => {
  if (
    !shoppingCart ||
    !Array.isArray(shoppingCart.items) ||
    shoppingCart.items.length < 1
  ) {
    return true
  }
  for (let i = 0; i < shoppingCart.items.length; i++) {
    if (!isItemInStock(shoppingCart.items[i])) {
      return false
    }
  }
  return true
}

export default isInStock
