import { gql } from '@apollo/client'

export default gql`
  mutation M($wish: WishInput!) {
    upsertWish (wish: $wish) {
      id
      contactId
      userId
      merchantId
      title
      notes
      productUrl
      budgetAmount
      sortIndex
      recordStatus
      createdAt
      updatedAt
    }
  }`
