import type { ContactMetadata, EventDate } from '../../../definitions'
import parseEventDate from './parseEventDate'

const addTransientMetadata = (metadata: ContactMetadata | null): ContactMetadata | null => {
  if (!metadata || !Array.isArray(metadata.events) || metadata.events.length < 1) {
    return metadata
  }

  const metadata2 = { ...metadata }
  delete metadata2.nextEventDate

  let nextEventDate: EventDate | undefined
  metadata2.events = metadata2.events.map((e) => {
    if (!e.eventDate) {
      return e
    }
    const parsedDate = parseEventDate(e.eventDate, metadata.fromUserTimezone)
    if (!parsedDate) {
      return e
    }
    if (!nextEventDate || parsedDate.daysUntil < nextEventDate.daysUntil) {
      nextEventDate = parsedDate
    }
    return {
      ...e,
      nextEventDate: parsedDate.nextDate,
      isBeforeToday: parsedDate.isBeforeToday,
      parsedDate,
    }
  })
    .sort((e1, e2) => {
      if (
        (!e1.parsedDate || !e1.parsedDate.daysUntil) &&
        e2.parsedDate &&
        e2.parsedDate.daysUntil
      ) {
        return 1
      }
      if (
        e1.parsedDate &&
        e1.parsedDate.daysUntil &&
        (!e2.parsedDate || !e2.parsedDate.daysUntil)
      ) {
        return -1
      }
      if (
        e1.parsedDate &&
        e1.parsedDate.daysUntil &&
        e2.parsedDate &&
        e2.parsedDate.daysUntil
      ) {
        return e1.parsedDate.daysUntil - e2.parsedDate.daysUntil
      }
      return 0
    })

  metadata2.nextEventDate = nextEventDate

  return metadata2
}

export default addTransientMetadata
