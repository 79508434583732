import React, { useContext, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast, useIonViewWillEnter } from '@ionic/react'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import { UiMessage } from '../../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type { User, UserInput } from '../../../lib/core/definitions'
import type { UserQueryData, UserQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import auth from '../../../services/auth'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import Avatar from '../../../components/Avatar/Avatar'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import UserForm from './UserForm'

const appPageId = AppPage.AdminEditUserPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  userId: string
}

const EditUserPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { userId } = useParams<keyof Params>() as unknown as Params
  const goBackUri = AppRoute.ADMIN_USERS

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const apolloClient = useApolloClient()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [isUpsertingUser, setIsUpsertingUser] = useState(false)

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading user:
  const {
    data: userLoadedData,
    loading: isLoadingUser,
    refetch: reloadUser,
  } = useQuery<UserQueryData, UserQueryVariables>(
    apollo.admin.queries.user, {
      variables: { userId: userId as string },
      skip: !userId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: UserQueryData) => {
        const returnedUser = data ? data.user : undefined
        if (returnedUser === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const user = userLoadedData ? userLoadedData.user : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingUser || isUpsertingUser

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  useIonViewWillEnter(() => {
    // if (
    //   userId &&
    //   (!user || !coreHelpers.models.compareId(user.id, userId)) &&
    //   !isLoadingUser &&
    //   !isUpsertingUser
    // ) {
    //   getUser({ userId, options: { includeInactive: true, includeUnlisted: true } })
    // }
  })

  // ===================================================================================================================
  // Event Handlers:
  const onUpdateUser = (userInput: UserInput): void => {
    if (!user || !userInput) {
      console.error('EditUserPage.onUpdateUser: invalid input.')
      return
    }
    setIsUpsertingUser(true)
    const previousUpdatedAt = user.updatedAt
    api.updateUser(
      userInput,
      user,
      (user: User): boolean => user.updatedAt !== previousUpdatedAt,
      undefined,
      apolloClient,
    ).then(() => {
      setIsUpsertingUser(false)
      setToastMessage('Successfully saved changes.')
      setShowToast(true)
      navigate(AppRoute.ADMIN_USERS)
    }, (error) => {
      console.error(error)
      setIsUpsertingUser(false)
      setToastMessage('Failed to save changes.')
      setShowToast(true)
    })
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !userId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadUser({ userId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onGoBack = (): void => {
    navigate(-1)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  // console.log('EditUserPage.render called.', { user });
  return (
    <IonPage className='app-page-admin edit-user-page'>
      <NavBar
        title='Edit User'
        goBackUri={goBackUri}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <div className='rowWithCenterAlignedItems withDoubleBottomMargin'>
          <Avatar
            user={user}
            className='avatar'
          />
          <div className='sectionCaption withStandardLeftMargin'>Edit User {user ? user.fullName : ''}</div>
        </div>
        <UserForm
          user={userId ? user : undefined}
          onGoBack={onGoBack}
          onSave={onUpdateUser}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default EditUserPage
