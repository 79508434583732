export default (): void => {
  if (caches) {
    console.log('versionUtils.clearAppCacheAndReload called.', { caches })
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names: string[]): void => {
      console.log('versionUtils.clearAppCacheAndReload: found caches', { names })
      for (const name of names) {
        caches.delete(name)
      }
    }, (error: Error): void => {
      console.error(error)
    })
  } else {
    console.log('versionUtils.clearAppCacheAndReload: caches is not defined.')
  }
  // delete browser cache and hard reload
  console.log('versionUtils.clearAppCacheAndReload: reloading app.')
  window.location.reload()
}
