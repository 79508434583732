import React from 'react'

import './styles.css'
import { PurchaseStatus, PurchaseTagType, RecordStatus } from '../../lib/core/enums'
import typeHelpers from '../../lib/core/helpers/type'

type Props = {
  recordStatus: RecordStatus;
  status: PurchaseStatus;
  showSmallLayout?: boolean;
  showActiveStatus?: boolean;
  onClickTag?: (tagType: PurchaseTagType, value: RecordStatus | PurchaseStatus | null | undefined) => void;
};

type TagDef = {
  key: string;
  type: PurchaseTagType;
  label: string;
  value: RecordStatus | PurchaseStatus | null | undefined;
}

const PurchaseStatusTags: React.FC<Props> = ({
  recordStatus,
  status,
  showSmallLayout = false,
  showActiveStatus = false,
  onClickTag,
}: Props): JSX.Element | null => {
  const objectStatusTag: TagDef = {
    key: PurchaseTagType.RECORD_STATUS,
    type: PurchaseTagType.RECORD_STATUS,
    value: recordStatus,
    label: typeHelpers.recordStatus.getLabel(recordStatus),
  }
  if (recordStatus === RecordStatus.INACTIVE) {
    objectStatusTag.label = 'archived'
  }
  const tags: TagDef[] = []
  const statusTag: TagDef = {
    key: PurchaseTagType.PURCHASE_STATUS,
    type: PurchaseTagType.PURCHASE_STATUS,
    value: status,
    label: typeHelpers.purchaseStatus.getLabel(status),
  }
  if (
    (recordStatus !== RecordStatus.ACTIVE || showActiveStatus) &&
    status !== PurchaseStatus.RECEIVED
  ) {
    tags.push(objectStatusTag)
  }
  if (
    status === PurchaseStatus.PENDING_DELIVERY ||
    status === PurchaseStatus.TRANSFERRED ||
    status === PurchaseStatus.TRANSFERRING ||
    status === PurchaseStatus.RECEIVED
  ) {
    tags.push(statusTag)
  }
  const tagClass = showSmallLayout ? 'purchaseStatusTagsWrapper purchaseStatusTagsTagSmall' : 'purchaseStatusTagsTag'
  const renderedTags = tags.map((tag: TagDef): any => {
    const cssClass = tag.value === PurchaseStatus.RECEIVED ? `${tagClass} received` : tagClass
    return (
      <div key={tag.key} className={cssClass} onClick={(): void => { if (onClickTag) onClickTag(tag.type, tag.value) }}>
        {tag.label}
      </div>
    )
  })
  return (
    <div className='purchaseStatusTagsWrapper'>
      {renderedTags}
    </div>
  )
}

export default PurchaseStatusTags
