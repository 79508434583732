const containsOneOf = (string: string, listOfSubStrings: string[]): boolean => {
  if (!string) {
    return false
  }
  for (let i = 0; i < listOfSubStrings.length; i += 1) {
    if (string.includes(listOfSubStrings[i])) {
      return true
    }
  }
  return false
}

export default containsOneOf
