import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { heartOutline } from 'ionicons/icons'

import './styles.css'
import type { AppRoute } from '../../../enums'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import type { WishesQueryData, WishesQueryVariables } from '../../../services/apollo/definitions'
import apollo from '../../../services/apollo'
import WishList from '../../../components/WishList/WishList'

type Props = {
  refreshId: number
  onNavigate: (route: AppRoute | string) => void
  showUiMessage: (message: string) => void
}

const WishListTab: React.FC<Props> = (props): JSX.Element | null => {
  const { refreshId, showUiMessage } = props

  // ===================================================================================================================
  // State:
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: wishesData,
    refetch: reloadWishes,
  } = useQuery<WishesQueryData, WishesQueryVariables>(
    apollo.queries.wishes, {
      variables: {
        filter: {
          userId: activeUserId,
          orderBy: 'title',
        },
      },
      skip: !activeUserId,
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
      // setToastMessage(error.message)
      // setShowToast(true)
      },
    },
  )
  const wishes = wishesData ? wishesData.wishes : undefined

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (reloadWishes) {
      reloadWishes()
    }
  }, [refreshId, reloadWishes])

  // ===================================================================================================================
  // Event Handlers:
  const onOpenWishExternalUrl = (wishId: string, url: string): void => {
    if (url) {
      window.open(url as string, '_system')
    }
  }

  // ===================================================================================================================
  // Rendering:
  if (!activeUser) {
    return null
  }
  // console.log('UserAccountPage.WishListTab.render.', { activeUser })

  return (
    <div className='user-account-wish-list-tab'> 
      <WishList
        className='wishlist-wrapper'
        wishes={wishes}
        owner='active-user'
        readOnly={false}
        helpText='Add wishes to provide some gift ideas!'
        userId={activeUserId}
        icon={heartOutline}
        emptyListText='What are you wishing for? Build your personal wish list and share it with your contacts!'
        onOpenExternalUrl={onOpenWishExternalUrl}
        showUiMessage={showUiMessage}
      />
    </div>
  )
}

export default WishListTab
