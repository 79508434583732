import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { ApiQueryOptions, ContactEventListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  DeleteContactEventMutationData,
  DeleteContactEventMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import loadContactEvent from './loadContactEvent'
import logger from '../logger'

const deleteContactEvent = (
  contactEventId: string,
  apolloClient: ApolloClient<any>,
  listFilter?: ContactEventListFilter,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<void> => (
  new Promise((resolve, reject) => {
    // console.log('api.deleteContactEvent called.', { contactEventInput })

    if (!apolloClient) {
      logger.error('api.deleteContactEvent: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<DeleteContactEventMutationData, DeleteContactEventMutationVariables> = {
      mutation: apollo.mutations.deleteContactEvent,
      variables: { contactEventId },
    }

    apolloClient.mutate<DeleteContactEventMutationData, DeleteContactEventMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.deleteContactEvent: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.deleteContactEvent ||
          !resp.data.deleteContactEvent.id
        ) {
          logger.error('api.deleteContactEvent: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        const contactEventId = resp.data.deleteContactEvent.id

        setTimeout(() => {
          // console.log('api.deleteContactEvent: calling loadContactEvent')
          loadContactEvent(
            contactEventId,
            'expect-null',
            undefined,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((contactEvent) => {
              // console.log('api.deleteContactEvent: resolving with contactEvent', contactEvent)
              resolve()
            }, (error) => {
              logger.error('api.deleteContactEvent: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.deleteContactEvent: error', { error })
        reject(error)
      })
  })
)

export default deleteContactEvent
