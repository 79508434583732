import { isPlatform } from '@ionic/react'
import type { ApolloClient } from '@apollo/client'
import { App as CapacitorApp } from '@capacitor/app'
import type { AppState, AppUrlOpen } from '@capacitor/app'
// import { CapacitorFirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links/dist/esm/web'
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links'
import type { IDynamicLink } from '@ionic-native/firebase-dynamic-links'
import { initializeApp } from 'firebase/app'
import { Location } from 'history'

import type { AppRoute } from '../../../enums'
import type { EnvironmentVal } from '../../../lib/core/definitions'
import handleParsedLink from '../handleParsedLink'
import initFcm from './initFcm'
import logger from '../../logger'
import parseDeeplink from '../parseDeeplink'
import serviceData from '../serviceData'

// See upgrading from v8 to v9: https://firebase.google.com/docs/web/modular-upgrade
const initFirebase = (
  environment: EnvironmentVal,
  apolloClient: ApolloClient<any>,
  location: Location | null,
  navigate: (route: AppRoute | string, replace?: boolean) => void,
): void => {
  console.log('firebase.init called.', { environment })

  if (serviceData.isServiceActive(environment)) {
    console.log('firebase.init: already active.')
    return
  }

  const config = serviceData.config(environment)
  console.log('Initializing firebase...', { environment, config })
  const firebaseApp = initializeApp(config, environment)
  // initFirebaseAuth()

  if (isPlatform('capacitor')) {
    CapacitorApp.addListener('appStateChange', (appState: AppState) => {
      console.log('App.onAppStateChange called.', { appState })
      if (appState && appState.isActive) {
        CapacitorApp.getLaunchUrl().then((appLaunchUrl) => {
          if (appLaunchUrl && appLaunchUrl.url && navigate) {
            console.log('App.calling appLaunchUrlReceived.', { appLaunchUrl })
            if (
              location &&
              location.pathname &&
              location.pathname.match(/^\/join/)
            ) {
              // If the user is already in the sign-up process, we don't want to forward
              // to another route. The user might background the app to retrieve the SMS
              // code, in which case this callback is executed once the Mimble app is re-activated.
              console.log('services.firebase.callbacks.appLaunchUrlReceived: sign-up page open, ignoring.')
              return
            }
            const { action, code, route } = parseDeeplink(appLaunchUrl.url) || {}
            console.log('services.firebase.callbacks.appLaunchUrlReceived: url parsed.', { action, code, route })
            if (action || route) {
              console.log('services.firebase.callbacks.appLaunchUrlReceived: calling handleParsedLink.')
              handleParsedLink(action, code, route, navigate)
            }
          }
        })
      }
    })

    FirebaseDynamicLinks
      .onDynamicLink()
      .subscribe((res: IDynamicLink) => {
        logger.info('Dynamic link callback executed.', { res })
      }, (error: Error) => {
        logger.error('Error subscribing to Firebase Dynamic Links', { error })
      })

    // CapacitorFirebaseDynamicLinks.addListener('deepLinkOpen', (data: { url: string }) => {
    //   logger.info('services.firebase: listener[deepLinkOpen] called.', data)
    //
    //   const { action, code, route } = parseDeeplink(data.url) || {}
    //   if (action || route) {
    //     handleParsedLink(action, code, route, navigate)
    //   }
    // })

    CapacitorApp.addListener('appUrlOpen', (data: AppUrlOpen) => {
      logger.info('services.firebase listener[appUrlOpen] called.', data)

      const { action, code, route } = parseDeeplink(data.url) || {}
      if (action || route) {
        handleParsedLink(action, code, route, navigate)
      }
    })

    setTimeout(() => {
      initFcm(true, apolloClient).then(undefined, (error) => {
        console.error(error)
      })
    }, 1000)
  }

  serviceData.setFirebaseApp(environment, firebaseApp)
}

export default initFirebase
