import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { Wallet, WalletListItem } from '../../../lib/core/definitions'
import type {
  WalletQueryData,
  WalletQueryVariables,
  WalletsQueryData,
  WalletsQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  wallet: Wallet,
  queryListVariables: WalletsQueryVariables,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateWallet.updateInList called.', { wallet })
  if (!wallet) {
    logger.error('api.cache.updateWallet.updateInList: no wallet given.')
    return
  }

  try {
    const cachedWalletData = apolloClient.readQuery<WalletsQueryData, WalletsQueryVariables>({
      query: apollo.queries.wallets,
      variables: queryListVariables,
    })

    if (
      !cachedWalletData ||
      !Array.isArray(cachedWalletData.wallets) ||
      cachedWalletData.wallets.length < 1
    ) {
      const listItem = coreHelpers.models.wallet.updateWalletListItemFromWallet(undefined, wallet)
      apolloClient.writeQuery<WalletsQueryData, WalletsQueryVariables>({
        query: apollo.queries.wallets,
        variables: queryListVariables,
        data: { wallets: [listItem] },
      })
      return
    }

    const updatedWallets = coreHelpers.models.updateModelInList<WalletListItem, Wallet>(
      cachedWalletData.wallets,
      wallet,
      undefined,
      true,
      coreHelpers.models.wallet.updateWalletListItemFromWallet,
    )

    apolloClient.writeQuery<WalletsQueryData>({
      query: apollo.queries.wallets,
      variables: queryListVariables,
      data: { wallets: updatedWallets },
    })
  } catch (error) {
    logger.error('api.cache.updateWallet.updateInList: error.', { wallet, error })
  }
}

const updateWallet = (
  wallet: Wallet,
  queryListVariables: WalletsQueryVariables | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateWallet called.', { wallet })
  if (!apolloClient) {
    logger.error('api.cache.updateWallet: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!wallet || !wallet.id) {
    logger.error('api.cache.updateWallet: no wallet given.', { wallet })
    return
  }

  try {
    apolloClient.writeQuery<WalletQueryData, WalletQueryVariables>({
      query: apollo.queries.wallet,
      variables: { walletId: wallet.id },
      data: { wallet },
    })

    if (queryListVariables) {
      updateInList(
        wallet,
        queryListVariables,
        activeUserId,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateWallet: error.', { wallet, error })
  }
}

export default updateWallet
