import React from 'react'
import { IonText } from '@ionic/react'

import type { PurchaseTransfer, User } from '../../lib/core/definitions'

type Props = {
  purchaseTransfer?: PurchaseTransfer
  fromUserId?: string | null
  senderName?: string
  user?: User
  signedInUserValidationResult: string | boolean
}

const WrongRecipientContent: React.FC<Props> = ({
  purchaseTransfer,
  fromUserId,
  senderName,
  user,
  signedInUserValidationResult,
}): JSX.Element => {
  return (
    <IonText>
      <p>
        This gift card was sent to another recipient. If you are sure it was intended for you, please
        log out of Mimble and open this page again.
      </p>
      <p className='lightText'>
        Reason:
      </p>
      <p className='withMonospaceText lightText'>
        {signedInUserValidationResult}
      </p>
    </IonText>
  )
}

export default WrongRecipientContent
