import type { ReactElement } from 'react'
import React from 'react'
import { Text as PdfText } from '@react-pdf/renderer'
import type { Style } from '@react-pdf/types'
import Text from './Text'
import Span from './Span'
import Strong from './Strong'

type Props = {
  htmlElement: ReactElement | null
  style: Style
}

const TextParagraph: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('###########################TextParagraph.render called.', { props })
  const { htmlElement, style } = props
  if (!htmlElement) {
    return null
  }

  const renderedChildren = htmlElement.props.children.map((element: string | JSX.Element, idx: number): JSX.Element => {
    if (typeof element === 'string') {
      const ele = {
        props: {
          children: [
            element,
          ],
        },
      }
      return <Text key={idx} htmlElement={ele} style={style} />
    }
    if (element.type === 'strong') {
      return <Strong key={idx} htmlElement={element} />
    }
    if (element.type === 'span') {
      return <Span key={idx} htmlElement={element} />
    }
    return <Text key={idx} htmlElement={element} style={style} />
  })

  const mergedStyle = style
    ? { ...style, ...htmlElement.props.style }
    : htmlElement.props.style

  return (
    <PdfText style={mergedStyle}>
      {renderedChildren}
    </PdfText>
  )
}
export default TextParagraph
