import type { ApolloClient } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  SignInMutationData,
  SignInMutationVariables,
} from '../apollo/definitions'
import type { User } from '../../lib/core/definitions'
import apollo from '../apollo'
import logger from '../logger'

const signIn = (
  ident: string,
  password: string,
  apolloClient: ApolloClient<any>,
): Promise<User | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.signIn called.', { ident, password })

    if (!apolloClient) {
      logger.error('api.signIn: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    apolloClient.mutate<SignInMutationData, SignInMutationVariables>({
      mutation: apollo.mutations.signIn,
      variables: { ident, password },
      context: { skipAuthentication: true },
    })
      .then((resp) => {
        // console.log('api.signIn: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.signIn
        ) {
          logger.error('api.signIn: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }
        resolve(resp.data.signIn)
      }, (error) => {
        logger.error('api.signIn: error', { error })
        reject(error)
      })
  })
)

export default signIn
