import React, { useContext, useEffect, useState } from 'react'
import { IonAlert, IonContent, IonPage, IonToast, isPlatform } from '@ionic/react'
import { Keyboard } from '@capacitor/keyboard'
import { Update } from 'history'
import { useLocation, useHistory } from 'react-router-dom'

import { AppPage, AppRoute, GiftFlowStep, PageMessageType } from '../../enums'
import { UiMessage } from '../../lib/core/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import GiftCardImage from './GiftCardImage'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import SendGiftForm from './SendGiftForm'

const appPageId = AppPage.SendGiftPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const SendGiftPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [showConfirmDiscardFlowAlert, setShowConfirmDiscardFlowAlert] = useState(false)
  const [isKeyboardShown, setIsKeyboardShown] = useState(false)
  const { activeUser } = useMimbleData()
  const {
    flowId,
    fromPurchaseId,
    fromPurchase,
    fromPurchaseLoadingError,
    purchaseTransfer,
    giftFlowStep,
    clear,
  } = useGiftFlow()

  useEffect((): void => {
    if (fromPurchaseLoadingError) {
      pageHelpers.checkForUnauthorized(fromPurchaseLoadingError, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
    }
  }, [fromPurchaseLoadingError])

  // ===================================================================================================================
  // Helpers:
  const resetPage = (): void => {
    pageMessages && pageMessages.clear()
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    if (isPlatform('capacitor')) {
      Keyboard.addListener('keyboardWillShow', () => {
        // console.log('SendGiftPage: keyboard will show with height', info.keyboardHeight)
        setIsKeyboardShown(true)
      })

      Keyboard.addListener('keyboardDidHide', () => {
        // console.log('SendGiftPage: keyboard did hide')
        setIsKeyboardShown(false)
      })
    }
  }, [])

  useEffect((): void => {
    resetPage()
  }, [flowId])

  // ===================================================================================================================
  // Event Handlers:
  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenMarketplace = (): void => {
    navigate(AppRoute.MARKETPLACE)
  }

  const onOpenUploadPurchase = (): void => {
    navigate(AppRoute.UPLOAD_GIFT_CARD)
  }

  const onNavigateBack = (): void => {
    navigate(-1)
  }

  const onDiscardFlow = (confirmed?: any): void => {
    if (confirmed !== true) {
      setShowConfirmDiscardFlowAlert(true)
      return
    }
    setShowConfirmDiscardFlowAlert(false)
    clear()
    navigate(-1)
  }

  const onOpenWallet = (): void => {
    navigate(AppRoute.WALLET)
  }

  const onOpenEvents = (): void => {
    navigate(`${AppRoute.CONTACTS}/events`)
  }

  const onOpenPurchaseTransfer = (): void => {
    const targetRoute = fromPurchaseId ? `${AppRoute.GIFT_CARD}/${fromPurchaseId}` : AppRoute.WALLET
    navigate(targetRoute)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let giftCardHeader: JSX.Element | undefined
  if (fromPurchase) {
    const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromPurchase(fromPurchase)

    giftCardHeader = (
      <GiftCardImage
        fromPurchaseId={fromPurchase.id as string}
        giftCardBackgroundUri={giftCardBackgroundUri}
        merchantLogoUri={merchantLogoUri}
        minimized={giftFlowStep === GiftFlowStep.GIFT || isKeyboardShown}
      />
    )
  }

  return (
    <IonPage className='app-page-public send-gift-page'>
      <NavBar
        title={purchaseTransfer ? 'Edit Gift' : 'Send Gift'}
        goBackUri={fromPurchaseId ? `${AppRoute.GIFT_CARD}/${fromPurchaseId}` : AppRoute.WALLET}
        userInfo={activeUser}
        onOpenUserAccount={onOpenUserAccount}
      />
      <PageMessages />
      <IonContent className='g-content-with-padding'>
        <div className='viewWrapper'>
          {giftCardHeader}
          <SendGiftForm
            onDiscardFlow={onDiscardFlow}
            onNavigateBack={onNavigateBack}
            onOpenMarketplace={onOpenMarketplace}
            onOpenWallet={onOpenWallet}
            onOpenEvents={onOpenEvents}
            onOpenPurchaseTransfer={onOpenPurchaseTransfer}
            onOpenUploadPurchase={onOpenUploadPurchase}
            showUiMessage={showUiMessage}
          />
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      <IonAlert
        isOpen={showConfirmDiscardFlowAlert}
        onDidDismiss={(): void => { setShowConfirmDiscardFlowAlert(false) }}
        header='Discard New Gift?'
        subHeader=''
        message='Are you sure you want to cancel this gift card transfer?'
        buttons={[
          { text: 'Discard Gift', handler: (): void => { onDiscardFlow(true) } },
          { text: 'Cancel', cssClass: 'secondary', handler: (): void => { setShowConfirmDiscardFlowAlert(false) } },
        ]}
      />
    </IonPage>
  )
}

export default SendGiftPage
