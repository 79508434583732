import { AppAction } from '../../enums'

const labelsEn = Object.freeze({
  [AppAction.EDIT_PROFILE]: 'Edit profile',
  [AppAction.INVITE_FRIEND]: 'Invite a friend',
  [AppAction.OPEN_CONTACTS]: 'Contacts',
  [AppAction.PURCHASE]: 'Shop',
  [AppAction.UPLOAD_GIFT_CARD]: 'Upload',
  [AppAction.UPDATE_APP]: 'Update app',
})

const appActionHelpers = {
  getLabel: (status?: AppAction | null, language?: string): string => {
    if (!status) {
      return ''
    }
    if (!language || language === 'US') {
      return labelsEn[status]
    }
    return ''
  },
  isValid: (type: AppAction | string | null | undefined): boolean => Object.values(AppAction).includes(type as AppAction),
}

export default appActionHelpers
