import type { CampaignsQueryVariables } from '../definitions'
import type { CampaignListFilter } from '../../../lib/core/definitions'
import getCampaignListFilter from './getCampaignListFilter'

const getCampaignsQueryVariables = (
  listFilter: CampaignListFilter | undefined,
): CampaignsQueryVariables => ({
  filter: listFilter || getCampaignListFilter(),
})

export default getCampaignsQueryVariables
