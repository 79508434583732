import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, Chat } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type { ChatsQueryData, ChatsQueryVariables, ApiClientOptions } from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadChats = (
  variables: ChatsQueryVariables,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Chat[] | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadChats called.')

    if (!apolloClient) {
      logger.error('api.loadChats: no Apollo client available.')
      throw (new Error(ErrorCode.SYSTEM_ERROR))
    }

    const queryOptions: QueryOptions<ChatsQueryVariables> = {
      query: apollo.queries.chats,
      variables,
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    apolloClient.query<ChatsQueryData, ChatsQueryVariables>(queryOptions)
      .then((resp) => {
        if (
          !resp ||
          !resp.data ||
          !resp.data.chats
        ) {
          logger.error('api.loadChats.load: query did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        resolve(resp.data.chats)
      }, (error) => {
        logger.error('api.loadChats: error.', { error })
        reject(error)
      })
  })
)

export default loadChats
