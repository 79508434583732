import moment from 'moment'
import React, { useState } from 'react'
import { IonButton } from '@ionic/react'
import { TextField } from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'

import './styles.css'
import type { UserInput } from '../../../../lib/core/definitions'
import { useMimbleData } from '../../../../contexts/MimbleDataContext/MimbleDataContext'
import FormCheckbox from '../../../../components/FormCheckbox/FormCheckbox'
import SubmitButton from '../../../../components/SubmitButton/SubmitButton'

type Props = {
  onClose: () => void
  showUiMessage: (message: string) => void
}

const BirthdayForm: React.FC<Props> = ({
  onClose,
  showUiMessage,
}): JSX.Element => {
  // ===================================================================================================================
  // State:
  const {
    activeUser,
    isUpdatingActiveUser,
    updateActiveUser,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [value, setValue] = useState<Date | null | undefined>()
  const [isSharingDay, setIsSharingDay] = useState<boolean | undefined>()
  const [isSharingYear, setIsSharingYear] = useState<boolean | undefined>()

  const oldValue = activeUser && activeUser.birthday
  const oldIsSharingDay = !(activeUser && activeUser.prefs && activeUser.prefs.shareBirthday === false)
  const oldIsSharingYear = activeUser && activeUser.prefs && activeUser.prefs.shareBirthday && activeUser.prefs.shareBirthdayYear

  // ===================================================================================================================
  // Helpers:
  const isDirty = (
    ((!!value && moment(value).format('YYYY-MM-DD') !== oldValue) || value === null) ||
    isSharingYear !== undefined ||
    isSharingDay !== undefined
  )
  const isValid = !value || (!!value && moment(value).isValid())

  const resetForm = (): void => {
    setValue(undefined)
    setIsSharingDay(undefined)
    setIsSharingYear(undefined)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeValue = (val: Date | null): void => {
    // console.log('BirthdayForm.onChangeValue called.', { val })
    if (
      activeUser &&
      moment(val).format('YYYY-MM-DD') === activeUser.birthday) {
      setValue(undefined)
      return
    }
    setValue(val)
  }

  const onSave = (event: any): void => {
    event.preventDefault()

    const userInput: UserInput = { id: activeUserId }

    if (value || value === null) {
      userInput.birthday = value ? moment(value).format('YYYY-MM-DD') : null
    }

    if (isSharingDay === true || isSharingDay === false) {
      if (userInput.prefs) {
        userInput.prefs.shareBirthday = isSharingDay
      } else {
        userInput.prefs = { shareBirthday: isSharingDay }
      }
    }
    if (isSharingYear === true || isSharingYear === false) {
      if (userInput.prefs) {
        userInput.prefs.shareBirthdayYear = isSharingYear
      } else {
        userInput.prefs = { shareBirthdayYear: isSharingYear }
      }
    }

    updateActiveUser(userInput).then((updatedUser) => {
      // console.log('TfaFieldForm: updateActiveUser succeeded.', { updatedUser })
      showUiMessage('Successfully updated your birthday.')
      onClose()
      resetForm()
    }, (error) => {
      console.error(error)
      showUiMessage('Failed to update your birthday. Please try again.')
    })
  }

  const onCancel = () => {
    onClose()
    resetForm()
  }

  const onChangeIsSharingDay = (checked: boolean) => {
    setIsSharingDay(checked)
  }

  const onChangeIsSharingYear = (checked: boolean) => {
    setIsSharingYear(checked)
    if (checked && !isSharingDay) {
      setIsSharingDay(true)
    }
  }

  // ===================================================================================================================
  // Rendering:
  const birthdayAsMoment = value !== null && (value || (activeUser && activeUser.birthday))
    ? moment((value || (activeUser && activeUser.birthday)), 'YYYY-MM-DD')
    : null
  const birthdayValue = birthdayAsMoment ? birthdayAsMoment.toDate() : null

  const sharingDayIsChecked = isSharingDay === undefined
    ? oldIsSharingDay
    : isSharingDay

  const sharingYearIsChecked = isSharingYear === undefined
    ? oldIsSharingYear
    : isSharingYear

  const disableSharing = value === null || (!oldValue && !value)

  return (
    <div className='user-account-birthday-form'>
      <form onSubmit={onSave}>
        <div className={'withStandardBottomMargin ' + (value ? 'inputWrapperWithNoError withStandardPadding' : '')}>
          {/* see https://next.material-ui.com/api/date-picker/ */}
          <DatePicker
            label='Birthday'
            disableFuture
            clearable
            renderInput={(params) => (
              <TextField {...params} helperText={params?.inputProps?.placeholder} />
            )}
            value={birthdayValue}
            onChange={onChangeValue}
          />
          <div className='withStandardLeftMargin'>
            <FormCheckbox
              isChecked={sharingDayIsChecked}
              isDisabled={disableSharing}
              label='Share month, day with my contacts'
              className='withSmallBottomMargin withSmallTopMargin'
              onChange={onChangeIsSharingDay}
            />
            <FormCheckbox
              isChecked={sharingYearIsChecked}
              isDisabled={disableSharing}
              label='Share year with my contacts'
              // isDisabled={user && user.prefs && !user.prefs.shareBirthday}
              className='withStandardBottomMargin'
              onChange={onChangeIsSharingYear}
            />
          </div>
        </div>
        <div className='formButtonWrapper'>
          <IonButton
            size='small'
            color='light'
            className='withStandardRightMargin'
            onClick={onCancel}
          >
            Cancel
          </IonButton>
          <SubmitButton
            size='small'
            disabled={!isDirty || !isValid}
            isProcessing={isUpdatingActiveUser}
            onClick={onSave}
          >
            Save
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

export default BirthdayForm
