import { gql } from '@apollo/client'

export default gql`
  query Q($tagId: String!) {
    tag (tagId: $tagId) {
      id
      parentTagId
      name
      captionEn
      tagType
      aliases
      priority
      lat
      lon
      place
      region
      countryCode
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }
`
