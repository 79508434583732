import type { Logger } from './definitions'

let logger: Logger

const loggerApi = {
  setLogger (l: Logger): void {
    logger = l
  },

  trace (message?: any, ...optionalParams: any[]): void {
    if (logger && logger.trace) {
      logger.trace(message, ...optionalParams)
    } else {
      console.log(message, ...optionalParams)
    }
  },

  debug (message?: any, ...optionalParams: any[]): void {
    if (logger && logger.debug) {
      logger.debug(message, ...optionalParams)
    } else {
      console.log(message, ...optionalParams)
    }
  },

  info (message?: any, ...optionalParams: any[]): void {
    if (logger && logger.info) {
      logger.info(message, ...optionalParams)
    } else {
      console.log(message, ...optionalParams)
    }
  },

  warn (message?: any, ...optionalParams: any[]): void {
    if (logger && logger.warn) {
      logger.warn(message, ...optionalParams)
    } else {
      console.log(message, ...optionalParams)
    }
  },

  error (message?: any, ...optionalParams: any[]): void {
    if (logger && logger.error) {
      logger.error(message, ...optionalParams)
    } else {
      console.error(message, ...optionalParams)
    }
  },
}

export default loggerApi
