import React from 'react'
import './styles.css'
import type { ChoicesItem } from '../../definitions'

type Props = {
  currentValue?: any;
  items?: ChoicesItem[];
  extraLargeLine1?: boolean;
  onChange?: (value: any) => void;
}
const Choices: React.FC<Props> = (props): JSX.Element | null => {
  const {
    currentValue,
    items,
    extraLargeLine1,
    onChange,
  } = props

  if (!items) {
    return null
  }

  const renderedItems = items
    .filter(item => item.show !== false)
    .map((item): JSX.Element => {
      const onClick = (): void => {
        if (!item.enable) {
          return
        }
        if (item.onSelect) {
          item.onSelect(item.value)
          return
        }
        if (onChange) {
          onChange(item.value)
        }
      }
      let itemClass = currentValue && item.value === currentValue
        ? 'choicesItem choicesItemSelected'
        : 'choicesItem'
      if (item.enable === false) {
        itemClass += ' choicesItemDisabled'
      }
      let line1Class = item.enable === false
        ? 'choicesItemLine1 choicesItemLine1Disabled'
        : 'choicesItemLine1'
      if (extraLargeLine1) {
        line1Class += ' choicesItemLine1Xl'
      }
      const line2Class = item.enable === false
        ? 'choicesItemLine2 choicesItemLine2Disabled'
        : 'choicesItemLine2'
      return (
        <div key={item.value} className={itemClass} onClick={onClick}>
          <div className={line1Class}>{item.line1}</div>
          <div className={line2Class}>{item.line2}</div>
        </div>
      )
    })
  return (
    <div className='formItem'>
      <div className='choicesControl'>
        {renderedItems}
      </div>
    </div>
  )
}

export default Choices
