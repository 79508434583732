import React from 'react'
import { create } from 'ionicons/icons'
import { IonIcon, IonItem } from '@ionic/react'

import './styles.css'
import type { ProductOption } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers/'

type Props = {
  productOption: ProductOption;
  showDetails?: boolean;
  onSelectProductOption?: (productOptionId: string) => void;
  onOpenProductOption?: (productOptionId: string) => void;
  onEditProductOption?: (productOptionId: string) => void;
  onAddStoredProduct?: (productOptionId: string) => void;
};

const ProductOptionRow: React.FC<Props> = (props): JSX.Element | null => {
  const {
    productOption,
    showDetails,
    onSelectProductOption,
    onOpenProductOption,
    onEditProductOption,
    onAddStoredProduct,
  } = props

  const productOptionId: string = productOption.id as string

  const onClickProductOption = (productOptionId: string): void => {
    if (onSelectProductOption) {
      onSelectProductOption(productOptionId)
    }
  }

  const onOpenProductOptionLocal = (): void => {
    if (onOpenProductOption) {
      onOpenProductOption(productOptionId)
    }
  }

  const onEditProductOptionLocal = (): void => {
    if (onEditProductOption) {
      onEditProductOption(productOptionId)
    }
  }

  const onAddStoredProductLocal = (): void => {
    if (onAddStoredProduct) {
      onAddStoredProduct(productOptionId)
    }
  }

  let details
  if (showDetails) {
    details = (
      <div className='productOptionListRow0Col1'>
        {/* <IonButton className="productOptionListRowButton" onClick={onClickEditProductOption}>Edit</IonButton> */}
      </div>
    )
  }
  const formattedAmount = coreHelpers.ui.formatAmount(
    productOption.amount,
    productOption.fundType,
    productOption.currency,
    false,
    0,
  )

  return (
    <IonItem lines='none' button className='productOptionListRow'>
      <div className='productOptionListRowItemContent'>
        <div className='productOptionListRow0' onClick={(): void => onClickProductOption(productOptionId)}>
          <div className='productOptionListRowCol productOptionListRowCol0'>
            {productOption.orderIndex}
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col1'>
            {productOption.labelEn}
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col2'>
            {formattedAmount}
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col3'>
            {productOption.fundType}
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col4'>
            {productOption.currency}
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col5'>
            {productOption.listed ? 'y' : 'n'}
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col6' onClick={onAddStoredProductLocal}>
            {productOption.inventory}
            <span className='largeText withStandardLeftMargin'>+</span>
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col7' onClick={onEditProductOptionLocal}>
            <IonIcon icon={create} />
          </div>
        </div>
        {details}
      </div>
    </IonItem>
  )
}

export default ProductOptionRow
