import moment from 'moment'
import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { receipt } from 'ionicons/icons'

import '../styles.css'
import type { Transaction } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import type { InfoPaneEntryDef } from '../../InfoPane/InfoPane'
import InfoPane from '../../InfoPane/InfoPane'

type Props = {
  transaction: Transaction | undefined
  className?: string
  onOpenTransaction?: (transactionId: string) => void
}

const TransactionObjectInfo: React.FC<Props> = (props): JSX.Element | null => {
  const { transaction, className, onOpenTransaction } = props

  if (!transaction) {
    return null
  }

  const entries: InfoPaneEntryDef[] = [
    {
      key: 'date',
      label: 'Date',
      value: moment(Number(transaction.createdAt)).format('MM/DD/YYYY hh:mm a'),
    },
    {
      key: 'amount',
      label: 'Amount',
      value: coreHelpers.ui.formatAmount(
        transaction.amount,
        transaction.fundType,
        transaction.currency,
        true,
        0,
      ),
    },
    {
      key: 'status',
      label: 'Status',
      value: coreHelpers.type.transactionStatus.getLabel(transaction.status),
    },
    {
      key: 'type',
      label: 'Type',
      value: coreHelpers.type.transactionType.getLabel(
        transaction.transactionType,
        undefined, // transaction.purchase ? transaction.purchase.purchaseType : undefined,
      ),
    },
    {
      key: 'processor',
      label: 'Paid with',
      value: coreHelpers.type.transactionProcessor.getLabel(transaction.processor),
    },
    {
      key: 'msg',
      label: 'Message',
      value: transaction.message,
    },
    {
      key: 'refundTransactionId',
      label: 'Refund Transaction Reference ID',
      value: coreHelpers.ui.formatModelId(transaction.refundTransactionId),
    },
    {
      key: 'id',
      label: 'Reference ID',
      value: coreHelpers.ui.formatModelId(transaction.id),
    },
  ]

  let openButton: JSX.Element | undefined
  if (onOpenTransaction && transaction.id) {
    openButton = (
      <IonButton
        size='small'
        fill='outline'
        onClick={() => onOpenTransaction(transaction.id as string)}
      >
        Open
      </IonButton>
    )
  }
  return (
    <div className='object-info'>
      <div className={`withStandardBottomMargin section-header ${className || ''}`}>
        <IonIcon icon={receipt} className='section-icon' /> Transaction
      </div>
      <div className='section-content'>
        <InfoPane
          entries={entries}
        />
        {openButton}
      </div>
    </div>
  )
}

export default TransactionObjectInfo
