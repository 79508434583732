import { isPlatform } from '@ionic/react'
import { Badge } from '@ionic-native/badge'
import clearAppIconBadge from './clearAppIconBadge'
import logger from '../logger'

const setAppIconBadgeValue = (value: number): void => {
  // console.log('services.capacitor.setAppIconBadgeValue called.')

  if (!isPlatform('capacitor')) {
    return
  }

  // The badge feature is currently broken on Android.
  if (isPlatform('android')) {
    return
  }

    if (value < 1) {
    clearAppIconBadge()
    return
  }

  if (!Badge || !Badge.set) {
    logger.warn('services.capacitor.setAppIconBadgeValue: badge plugin not available.')
    return
  }

  Badge.set(value).then(() => {
    // console.log('services.capacitor.setAppIconBadgeValue succeeded.')
  }, (error) => {
    logger.error('services.capacitor.setAppIconBadgeValue: received error.', { error })
  })
}

export default setAppIconBadgeValue
