import React from 'react'
import { Step, StepLabel, Stepper as MaterialUiStepper } from '@mui/material'

type Props = {
  step: string | undefined
  steps: string[]
  getLabel: (step: string) => string
};

const Stepper: React.FC<Props> = (props): JSX.Element | null => {
  const { step, steps, getLabel } = props

  if (!step) {
    return null
  }

  const renderedSteps = steps.map((step) => {
    const labelText = getLabel(step)
    return (
      <Step key={step}>
        <StepLabel>{labelText}</StepLabel>
      </Step>
    )
  })
  return (
    <MaterialUiStepper
      activeStep={steps.indexOf(step)}
      alternativeLabel className='stepper'
    >
      {renderedSteps}
    </MaterialUiStepper>
  )
}

export default Stepper
