import React, { useEffect, useRef, useState } from 'react'
import type { AppState } from '@capacitor/app'
import type Stripe from '@stripe/stripe-js'
import { App as CapacitorApp } from '@capacitor/app'
import { Elements } from '@stripe/react-stripe-js'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { Location } from 'history'
import { loadStripe } from '@stripe/stripe-js'
import { LocalizationProvider } from '@mui/lab'
import { Redirect, Route } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import './styles.css'
import type { AnimationName } from '../../lib/core/enums'
import { AppRoute } from '../../enums'
import type { ContextHelpId } from '../../contexts/AppHelpersContext/enums'
import type { EnvironmentVal, OAuthUser } from '../../lib/core/definitions'
import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
// import ProtectedRoute from './ProtectedRoute'
import AboutPage from '../../pages/AboutPage/AboutPage'
import ActivePageDataProvider from '../../contexts/ActivePageDataContext'
import AdminDashboardPage from '../../admin/pages/AdminDashboardPage/AdminDashboardPage'
import AdminCampaignPage from '../../admin/pages/CampaignPage/CampaignPage'
import AdminCampaignsPage from '../../admin/pages/CampaignsPage/CampaignsPage'
import AdminEditCampaignPage from '../../admin/pages/EditCampaignPage/EditCampaignPage'
import AdminEditMemberMessageTemplatePage from '../../admin/pages/EditMemberMessageTemplatePage/EditMemberMessageTemplatePage'
import AdminMerchantsPage from '../../admin/pages/MerchantsPage/MerchantsPage'
import AdminNewTransactionPage from '../../admin/pages/NewTransactionPage/NewTransactionPage'
import AdminPurchasesPage from '../../admin/pages/PurchasesPage/PurchasesPage'
import AdminMemberMessageTemplatePage from '../../admin/pages/MemberMessageTemplatePage/MemberMessageTemplatePage'
import AdminMemberMessageTemplatesPage from '../../admin/pages/MemberMessageTemplatesPage/MemberMessageTemplatesPage'
import AdminUserPage from '../../admin/pages/UserPage/UserPage'
import AppHelpersProvider from '../../contexts/AppHelpersContext/AppHelpersContext'
import AppStateProvider from '../../contexts/AppStateContext'
import BarcodePage from '../../pages/BarcodePage/BarcodePage'
import BuyTokenPage from '../../pages/BuyTokenPage/BuyTokenPage'
import ChatPage from '../../pages/ChatPage/ChatPage'
import CheckoutPaymentReceivedPage from '../../pages/CheckoutPaymentReceivedPage/CheckoutPaymentReceivedPage'
import CheckoutProductOptionPage from '../../pages/CheckoutProductOptionPage/CheckoutProductOptionPage'
import ConfirmAsyncTaskTokenPage from '../../pages/ConfirmAsyncTaskTokenPage/ConfirmAsyncTaskTokenPage'
import ContactPage from '../../pages/ContactPage/ContactPage'
import ContactsPage from '../../pages/ContactsPage/ContactsPage'
import ContextHelpModal from '../../components/ContextHelpModal/ContextHelpModal'
import DashboardPage from '../../pages/DashboardPage/DashboardPage'
import Dialog from '../../components/Dialog/Dialog'
import DialogProvider from '../../contexts/DialogContext/DialogContext'
import EditMerchantPage from '../../admin/pages/EditMerchantPage/EditMerchantPage'
import EditProductOptionPage from '../../admin/pages/EditProductOptionPage/EditProductOptionPage'
import EditProductPage from '../../admin/pages/EditProductPage/EditProductPage'
import EditStoredProductPage from '../../admin/pages/EditStoredProductPage/EditStoredProductPage'
import EditTagPage from '../../admin/pages/EditTagPage/EditTagPage'
import EditUserPage from '../../admin/pages/EditUserPage/EditUserPage'
import env from '../../lib/env'
import ExecuteAdminTaskPage from '../../admin/pages/ExecuteAdminTaskPage/ExecuteAdminTaskPage'
import firebase from '../../services/firebase'
import FullPageAnimation from '../../components/FullPageAnimation/FullPageAnimation'
import GiftFlowProvider from '../../contexts/GiftFlowContext'
import GiftReceivedPage from '../../pages/GiftReceivedPage/GiftReceivedPage'
import HelpPage from '../../pages/HelpPage/HelpPage'
import ImportDataPage from '../../admin/pages/ImportDataPage/ImportDataPage'
import JoinPage from '../../pages/JoinPage/JoinPage'
import LoggingPage from '../../pages/LoggingPage/LoggingPage'
import MarketplacePage from '../../pages/MarketplacePage/MarketplacePage'
import MessagesPage from '../../pages/MessagesPage/MessagesPage'
import MimbleDataContextProvider from '../../contexts/MimbleDataContext/MimbleDataContext'
import NewInvitationPage from '../../pages/NewInvitationPage/NewInvitationPage'
import NewUploadedPurchasePage from '../../pages/NewUploadedPurchasePage/NewUploadedPurchasePage'
import OrderPage from '../../pages/OrderPage/OrderPage'
import OrgChatPage from '../../admin/pages/OrgChatPage/OrgChatPage'
import OrgMessagesPage from '../../admin/pages/OrgMessagesPage/OrgMessagesPage'
import PageMessagesContext, { DEFAULT_PAGE_MESSAGE_CONTEXT_DATA } from '../../contexts/pageMessagesContext'
import ProductPage from '../../admin/pages/ProductPage/ProductPage'
import ProductsPage from '../../admin/pages/ProductsPage/ProductsPage'
import PurchasePage from '../../pages/PurchasePage/PurchasePage'
import ReportsPage from '../../admin/pages/ReportsPage/ReportsPage'
import ResetPasswordPage from '../../pages/ResetPasswordPage/ResetPasswordPage'
import RewardPage from '../../pages/RewardPage/RewardPage'
import SelectMerchantPage from '../../pages/SelectMerchantPage/SelectMerchantPage'
import SendGiftPage from '../../pages/SendGiftPage/SendGiftPage'
import ShoppingCartPage from '../../pages/ShoppingCartPage/ShoppingCartPage'
import ShoppingCartProvider from '../../contexts/ShoppingCartContext/ShoppingCartContext'
import SignInAsAnotherUserPage from '../../admin/pages/SignInAsAnotherUserPage/SignInAsAnotherUserPage'
import SignInPage from '../../pages/SignInPage/SignInPage'
import StoredProductsPage from '../../admin/pages/StoredProductsPage/StoredProductsPage'
import TagsPage from '../../admin/pages/TagsPage/TagsPage'
import TokensPage from '../../pages/TokensPage/TokensPage'
import TransactionPage from '../../pages/TransactionPage/TransactionPage'
import UserAccountPage from '../../pages/UserAccountPage/UserAccountPage'
import UsersPage from '../../admin/pages/UsersPage/UsersPage'
import WalletPage from '../../pages/WalletPage/WalletPage'
import WelcomePage from '../../pages/WelcomePage/WelcomePage'

// -------------------------------------------------------------------------------------------------------------------
// Stripe
// We could move this into the credit card checkout page, but would lose the fraud protection
// logic Stripe runs throughout the session.
// see https://dev.to/stripe/importing-stripe-js-as-an-es-module-1ni
let stripePromise: Promise<Stripe.Stripe | null>
const getStripe = (environment: EnvironmentVal) => {
  const stripeKey = env('STRIPE_KEY', environment)
  if (!stripePromise && stripeKey) {
    console.log('Initializing Stripe...')
    stripePromise = loadStripe(stripeKey)
  }
  return stripePromise
}

type Props = {
  onChangeEnvironment: (environment: EnvironmentVal) => void
}

const ContentApp: React.FC<Props> = (props): JSX.Element => {
  const { onChangeEnvironment } = props

  // -------------------------------------------------------------------------------------------------------------------
  // State:
  const apolloClient = useApolloClient()
  const routerRef = useRef<IonReactRouter | null>(null)
  const {
    init: initGlobalCache,
    getEnvironment,
    getValue: getGlobalCacheValue,
    getIsSignedIn,
  } = useGlobalCache()
  initGlobalCache()
  const environment = getEnvironment()

  const [pageMessageVersion, setPageMessageVersion] = useState(0)
  const [fullPageAnimationName, setFullPageAnimationName] = useState<AnimationName | undefined>()
  const pageMessagesData = Object.assign(
    {},
    DEFAULT_PAGE_MESSAGE_CONTEXT_DATA,
    {
      setVersion: setPageMessageVersion,
    },
  )
  const [pageMessagesContextData] = useState(pageMessagesData)
  const [ContextHelpId, setContextHelpId] = useState<ContextHelpId | undefined>()
  const [isAppActive, setAppIsActive] = useState<boolean>(true)

  const navigate = (route: AppRoute | string, replace?: boolean): void => {
    if (
      !routerRef ||
      !routerRef.current ||
      !routerRef.current.history
    ) {
      console.error('ContentApp.navigate: no router available')
      return
    }
    if (replace) {
      routerRef.current.history.replace(route)
      return
    }
    routerRef.current.history.push(route)
  }

  const location: Location | null = (
    routerRef &&
    routerRef.current &&
    routerRef.current.history &&
    routerRef.current.history.location
  )

  // -------------------------------------------------------------------------------------------------------------------
  // Effect Hooks:
  useEffect(() => {
    console.log('ContentApp.useEffect[environment] called.', environment)
    onChangeEnvironment(environment)
  }, [environment])

  useEffect(() => {
    // console.log('App.useEffect[routerRef.current] called.', routerRef.current)
    if (!routerRef.current || !routerRef.current.history) {
      console.log('App.useEffect[routerRef.current]: no history')
      return
    }

    console.log('ContentApp: rendering.')

    CapacitorApp.addListener('appStateChange', (appState: AppState) => {
      // console.log('App.onAppStateChange called.', { state })
      setAppIsActive(appState && appState.isActive)
    })

    // Firebase:
    if (
      // For some reason I wanted to delay the initialization
      // of Firebase until after the user has been authenticated.
      // However, for dynamic links to work during installation,
      // Firebase must be initialized earlier.
      env('FIREBASE_API_KEY', environment) // &&
      // getIsSignedIn()
    ) {
      // console.log('Firebase API key found - initializing.')
      firebase.init(
        getEnvironment(),
        apolloClient,
        location,
        navigate,
      )
      if (firebase.isAuthActive()) {
        const onFirebaseAuthStateChange = (user: OAuthUser) => {
          console.log('ContentApp.onFirebaseAuthStateChange called.', { user })
        }
        firebase.addAuthStateChangeListener('App', onFirebaseAuthStateChange)
      }
    } else {
      console.log('ContentApp: No firebase API key found or not signed in - not activating.', { environment, env: process.env })
    }

    const xGridLicense = env('X_GRID_LICENSE', environment)
    if (xGridLicense) {
      LicenseInfo.setLicenseKey(xGridLicense)
    }
  }, [routerRef.current])

  // const AuthenticatedRoute = ({ component: Component, path }: RouteProps) => {
  //   if (!globalCache.isSignedIn()) {
  //     console.log('>>>>>>>>>>>returning Redirect')
  //     return <Redirect to={AppRoute.WELCOME} />
  //   }
  //
  //   return <Route component={Component} path={path} />
  // }

  // -------------------------------------------------------------------------------------------------------------------
  // Event Handlers:
  const Root: React.FC = (): JSX.Element => {
    if (getIsSignedIn()) {
      // react-router@5 fix (switch to Navigate after upgrading to @6)
      // return <Navigate to={AppRoute.WALLET} />
      return <Redirect to={AppRoute.WALLET} />
    }
    if (!getGlobalCacheValue(GlobalCacheDataKey.SIGNED_OUT_USER_ID, true)) {
      // react-router@5 fix (switch to Navigate after upgrading to @6)
      // return <Navigate to={AppRoute.WELCOME} />
      return <Redirect to={AppRoute.WELCOME} />
    }
    // react-router@5 fix (switch to Navigate after upgrading to @6)
    // return <Navigate to={AppRoute.SIGN_IN} />
    return <Redirect to={AppRoute.SIGN_IN} />
  }

  const showContextHelpModal = (id: ContextHelpId) => {
    setContextHelpId(id)
  }

  const hideContextHelpModal = () => {
    setContextHelpId(undefined)
  }

  const showFullPageAnimation = (animation: AnimationName): void => {
    setFullPageAnimationName(animation)
  }

  const hideFullPageAnimation = (): void => {
    setFullPageAnimationName(undefined)
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Rendering:
  return (
    <AppStateProvider isActive={isAppActive}>
      <DialogProvider>
        <AppHelpersProvider
          showFullPageAnimation={showFullPageAnimation}
          showModal={showContextHelpModal}
          hideModal={hideContextHelpModal}
        >
          <ActivePageDataProvider>
            <PageMessagesContext.Provider value={pageMessagesContextData}>
              <MimbleDataContextProvider>
                <ShoppingCartProvider>
                  <GiftFlowProvider>
                    <Elements stripe={getStripe(environment)}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <IonApp>
                          <IonReactRouter ref={routerRef}>
                            <IonRouterOutlet>
                              {/* }
                              <Route exact path='/' render={(): JSX.Element => <Redirect to={AppRoute.WALLET} />} />
                              <ProtectedRoute path='/ppppp2' component={WalletPage} />
                              <Route path='/' component={AboutPage} render={(): JSX.Element => <Redirect to={AppRoute.WELCOME} />} />
                              <Route exact path='/' render={(): JSX.Element => <Redirect to={AppRoute.WELCOME} />} />
                              <Route path='/' component={WalletPage} render={(): JSX.Element => <Redirect to={AppRoute.WELCOME} />} />
                              <Route exact path='/' render={() => <Redirect to={AppRoute.WELCOME} />} />
                              <ProtectedRoute path='/' component={WalletPage} />
                              <AuthenticatedRoute path='/' component={WalletPage} />
                              { */}
                              <Route exact path='/' component={Root}/>
                              <Route exact path={AppRoute.WELCOME} component={WelcomePage}/>
                              <Route exact path={AppRoute.ABOUT} component={AboutPage}/>
                              <Route exact path={`${AppRoute.USER_ACCOUNT}/:tab?`} component={UserAccountPage}/>
                              <Route exact path='/upload-card/:merchantId?' component={NewUploadedPurchasePage}/>
                              <Route exact path={AppRoute.BUY_MIMBLE_TOKENS} component={BuyTokenPage}/>
                              <Route exact path={`${AppRoute.BARCODE}/:purchaseId`} component={BarcodePage}/>
                              <Route exact path={`${AppRoute.GIFT_RECEIVED}/:purchaseTransferId/:step?`}
                                     component={GiftReceivedPage}/>
                              <Route exact path='/card/:purchaseId/:tab?' component={PurchasePage}/>
                              <Route exact path={`${AppRoute.MESSAGES}/:chatId/:contactUserId?`} component={ChatPage}/>
                              <Route exact path={`${AppRoute.SHOPPING_CART}/:shoppingCartId?`} component={ShoppingCartPage}/>
                              <Route exact path={`${AppRoute.CHECKOUT_PRODUCT_OPTION}/:shoppingCartItemId`}
                                     component={CheckoutProductOptionPage}/>
                              <Route exact path='/confirm/:taskId?/:taskType?/:token?'
                                     component={ConfirmAsyncTaskTokenPage}/>
                              <Route exact path={`${AppRoute.CONTACTS}/:tab?`} component={ContactsPage}/>
                              <Route exact path={`${AppRoute.CONTACT}/:contactId/:userId?`} component={ContactPage}/>
                              <Route exact path={AppRoute.DASHBOARD} component={DashboardPage}/>
                              <Route exact path={AppRoute.HELP} component={HelpPage}/>
                              <Route exact path='/marketplace/:pageMode?' component={MarketplacePage}/>
                              <Route exact path={AppRoute.MESSAGES} component={MessagesPage}/>
                              <Route exact path={`${AppRoute.ORDER}/:orderId`} component={OrderPage}/>
                              <Route exact path={`${AppRoute.PAYMENT_RECEIVED}/:orderId`} component={CheckoutPaymentReceivedPage}/>
                              <Route exact path={`${AppRoute.RESET_PASSWORD}/:taskId?/:token?`}
                                     component={ResetPasswordPage}/>
                              <Route exact path={`${AppRoute.REWARD}/:rewardId?`} component={RewardPage}/>
                              <Route exact path={AppRoute.NEW_INVITATION} component={NewInvitationPage}/>
                              <Route exact path={AppRoute.SEND_GIFT} component={SendGiftPage}/>
                              <Route exact path='/select-brand/:nextRoute?' component={SelectMerchantPage}/>
                              <Route exact path={AppRoute.SIGN_IN} component={SignInPage}/>
                              <Route exact path={`${AppRoute.TOKENS}/:tab?`} component={TokensPage}/>
                              <Route exact path={`${AppRoute.JOIN}/:inviteCode?`} component={JoinPage}/>
                              <Route exact path={`${AppRoute.INVITE}/:inviteCode?`} component={JoinPage}/>
                              <Route exact path={`${AppRoute.TRANSACTION}/:transactionId?`} component={TransactionPage}/>
                              <Route exact path={`${AppRoute.WALLET}/:tab?/:searchText?`} component={WalletPage}/>

                              {/* ==================================================================================== */}
                              {/* Admin console: */}
                              <Route exact path='/admin/add-merchant' component={EditMerchantPage}/>
                              <Route exact path={`${AppRoute.ADMIN_CAMPAIGN}/:campaignId`} component={AdminCampaignPage}/>
                              <Route exact path={AppRoute.ADMIN_CAMPAIGNS} component={AdminCampaignsPage}/>
                              <Route exact path={AppRoute.ADMIN_DASHBOARD} component={AdminDashboardPage}/>
                              <Route exact path='/admin/add-product-option/:productId' component={EditProductOptionPage}/>
                              <Route exact path='/admin/add-stored-product/:productId/:productOptionId'
                                     component={EditStoredProductPage}/>
                              <Route exact path={`${AppRoute.ADMIN_EDIT_CAMPAIGN}/:campaignId?`} component={AdminEditCampaignPage}/>
                              <Route exact path={AppRoute.ADMIN_EXECUTE_ADMIN_TASK} component={ExecuteAdminTaskPage}/>
                              <Route exact path='/admin/data-import/:asyncTaskId?' component={ImportDataPage}/>
                              <Route exact path={AppRoute.LOGGING} component={LoggingPage}/>
                              <Route exact path='/admin/edit-product-option/:productId/:productOptionId'
                                     component={EditProductOptionPage}/>
                              <Route exact path='/admin/edit-product/:productId' component={EditProductPage}/>
                              <Route exact path='/admin/edit-stored-product/:productId/:productOptionId/:storedProductId'
                                     component={EditStoredProductPage}/>
                              <Route exact path='/admin/edit-tag/:tagId' component={EditTagPage}/>
                              <Route exact path='/admin/edit-user/:userId' component={EditUserPage}/>
                              <Route exact path={AppRoute.CREATE_TAG} component={EditTagPage}/>
                              <Route exact path='/admin/edit-merchant/:merchantId' component={EditMerchantPage}/>
                              <Route exact path='/admin/import-stored-products/:productId?' component={ImportDataPage}/>
                              <Route exact path='/admin/inventory/:merchantId?' component={StoredProductsPage}/>
                              <Route exact path={`${AppRoute.ADMIN_EDIT_MEMBER_MESSAGE_TEMPLATE}/:memberMessageTemplateId?`}
                                     component={AdminEditMemberMessageTemplatePage}/>
                              <Route exact path={`${AppRoute.ADMIN_ORG_CHAT}/:chatId/:contactUserId?`} component={OrgChatPage}/>
                              <Route exact path='/admin/mimble-team-messages' component={OrgMessagesPage}/>
                              <Route exact path='/admin/product/:productId' component={ProductPage}/>
                              <Route exact path='/admin/products' component={ProductsPage}/>
                              <Route exact path='/admin/purchases' component={AdminPurchasesPage}/>
                              <Route exact path='/admin/send-funds' component={AdminNewTransactionPage}/>
                              <Route exact path='/admin/sign-in-as' component={SignInAsAnotherUserPage}/>
                              <Route exact path='/admin/tags' component={TagsPage}/>
                              <Route exact path={AppRoute.ADMIN_REPORTS} component={ReportsPage}/>
                              <Route exact path={AppRoute.ADMIN_USERS} component={UsersPage}/>
                              <Route exact path={`${AppRoute.ADMIN_USER}/:userId`} component={AdminUserPage}/>
                              <Route exact path={AppRoute.ADMIN_EDIT_USER} component={EditUserPage}/>
                              <Route exact path={AppRoute.ADMIN_MERCHANTS} component={AdminMerchantsPage}/>
                              <Route exact path={`${AppRoute.ADMIN_MEMBER_MESSAGE_TEMPLATE}/:memberMessageTemplateId`} component={AdminMemberMessageTemplatePage}/>
                              <Route exact path={AppRoute.ADMIN_MEMBER_MESSAGE_TEMPLATES} component={AdminMemberMessageTemplatesPage}/>
                            </IonRouterOutlet>
                          </IonReactRouter>
                          <ContextHelpModal ContextHelpId={ContextHelpId} onClose={hideContextHelpModal} />
                          <FullPageAnimation
                            name={fullPageAnimationName}
                            loop={1}
                            onComplete={hideFullPageAnimation}
                            onClick={hideFullPageAnimation}
                          />
                          <Dialog />
                        </IonApp>
                      </LocalizationProvider>
                    </Elements>
                  </GiftFlowProvider>
                </ShoppingCartProvider>
              </MimbleDataContextProvider>
            </PageMessagesContext.Provider>
          </ActivePageDataProvider>
        </AppHelpersProvider>
      </DialogProvider>
    </AppStateProvider>
  )
}

export default ContentApp
