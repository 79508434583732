import type { AsyncTaskType } from './enums'
import type { EnvironmentVal } from './definitions'
import logger from './logger'
import uiHelpers from './helpers/ui'
import urlSafe from './helpers/string/urlSafe'

const localApiHost = '127.0.0.1'
// const localApiHost = '192.168.10.9';
let httpProtocol = 'https'
let environment: EnvironmentVal = (process.env.ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT) as EnvironmentVal

let mimbledataHost = 'appdata.mimble.app'
let mimblemodHost = 'mimble.app'
let mimblelinkHost = 'link.mimble.app'

const getCdnRoot = (): string => 'https://s3-us-west-2.amazonaws.com/co-mimble-cdn'
const getMimbledataBaseUrl = (): string => `${httpProtocol}://${mimbledataHost}/appdata`
const getMimblemodBaseUrl = (): string => `${httpProtocol}://${mimblemodHost}`

const setEnvironment = (newEnvironment: 'test' | 'development' | 'sandbox' | 'production'): void => {
  logger.info('lib.core.appLinks.setEnvironment called.', { newEnvironment })
  environment = newEnvironment
  if (environment === 'test' || environment === 'development') {
    mimbledataHost = `${localApiHost}:3000`
    mimblemodHost = `${localApiHost}:3001`
    // mimblelinkHost = `${localApiHost}:3002`
    httpProtocol = 'http'
  } else if (environment === 'sandbox') {
    mimbledataHost = 'appdata-sandbox.mimble.app'
    mimblemodHost = 'sandbox.mimble.app'
    mimblelinkHost = 'link-sandbox.mimble.app'
  } else {
    mimbledataHost = 'appdata.mimble.app'
    mimblemodHost = 'mimble.app'
    mimblelinkHost = 'link.mimble.app'
  }
}

const appLinks = {
  setEnvironment,
  getCdnRoot,
  getMimbledataHost: (): string => mimbledataHost,
  getMimblemodHost: (): string => mimblemodHost,
  getMimblelinkHost: (): string => mimblelinkHost,

  getMimbledataBaseUrl,
  getMimblemodBaseUrl,

  barcode: (purchaseId: string): string => (
    `${getMimbledataBaseUrl()}/barcode?p=${purchaseId}&h=100`
  ),

  chat: (chatId: string | undefined, contactId: string | undefined): string => {
    if (chatId) {
      return `${getMimblemodBaseUrl()}/messages/${chatId}`
    }
    return `${getMimblemodBaseUrl()}/contact/${contactId}`
  },

  coinpaymentsCallback: (): string => {
    return `${getMimbledataBaseUrl()}/crypto/coinpayments-callback`
  },

  confirmCode: (taskId: string, taskType: AsyncTaskType, tfaCode: string): string => {
    return `${getMimblemodBaseUrl()}/confirm/${uiHelpers.formatModelId(taskId)}/${taskType}/${tfaCode}`
  },

  cryptoPaymentAddressQrCode: (purchaseId: string): string => {
    return `${getMimbledataBaseUrl()}/purchases/${purchaseId}/address-qr`
  },

  cryptoPaymentRequestQrCode: (purchaseId: string): string => {
    return `${getMimbledataBaseUrl()}/purchases/${purchaseId}/payment-qr`
  },

  fileUpload: (): string => {
    return `${getMimbledataBaseUrl()}/file-upload`
  },

  gift: (purchaseTransferId: string | null | undefined, purchaseId: string | null | undefined): string => {
    const objId = purchaseId
      ? `tp:${uiHelpers.formatModelId(purchaseId)}`
      : uiHelpers.formatModelId(purchaseTransferId)
    if (environment === 'development') {
      return `http://${mimblemodHost}/gift/${objId}`
    }
    if (environment === 'sandbox') {
      return `https://gift-sandbox.mimble.app/${objId}`
    }
    return `https://gift.mimble.app/${objId}`
  },

  giftCardImage: (imageSource: string): string => (
    `${getCdnRoot()}/giftcards/${imageSource}`
  ),

  invite: (code: string): string => {
    if (environment === 'development') {
      return `http://${mimblemodHost}/join/${code}`
    }
    if (environment === 'sandbox') {
      return `https://invite-sandbox.mimble.app/${code}`
    }
    return `https://invite.mimble.app/${code}`
  },

  inviteQrCode: (userId: string): string => {
    return `${getMimbledataBaseUrl()}/member/${userId}/invite-qr`
  },

  merchantLogo: (imageSource: string): string => (
    `${getCdnRoot()}/vendors/${imageSource}`
  ),

  mimbleWallet: (): string => {
    return `${getMimblemodBaseUrl()}/mit`
  },

  purchaseCard: (purchaseId: string): string => {
    return `${getMimblemodBaseUrl()}/card/${purchaseId}`
  },

  resetPassword: (taskId: string, tfaCode: string): string => {
    return `${getMimblemodBaseUrl()}/reset-password/${taskId}/${tfaCode}`
  },

  updatePassword: (userId: string, userEmail: string, tfaCode: string): string => {
    const urlSafeEmail = urlSafe(userEmail)
    return `${getMimblemodBaseUrl()}/update-password?id=${userId}&email=${urlSafeEmail}&code=${tfaCode}`
  },

  verifyEmail: (userId: string, userEmail: string, tfaCode: string): string => {
    const urlSafeEmail = urlSafe(userEmail)
    return `${getMimblemodBaseUrl()}/verify-email?id=${userId}&email=${urlSafeEmail}&command=verify-email&code=${tfaCode}`
  },

  welcome: (userId: string, userEmail: string, tfaCode: string): string => {
    const urlSafeEmail = urlSafe(userEmail)
    return `${getMimblemodBaseUrl()}/verify-email?id=${userId}&email=${urlSafeEmail}&command=welcome&code=${tfaCode}`
  },
}

export default appLinks
