import { gql } from '@apollo/client'

export default gql`
  query Q($filter: ContactEventReminderListFilter!) {
    contactEventReminders (filter: $filter) {
      id
      eventId
      timing
      notificationMethod
      messageText
      sentAt
      sendReport
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
