import { gql } from '@apollo/client'

export default gql`
  query Q($filter: ChatListFilter!) {
    chats (filter: $filter) {
      id
      fromUserId
      toUserId
      status
      metadata {
        fromUser {
          username
          fullName
          email
          phoneNumber
          imageUrl
          unreadMessageCount
        }
        toUser {
          username
          fullName
          email
          phoneNumber
          imageUrl
          unreadMessageCount
        }
        updatedAt
      }
      recordStatus
      createdAt
      updatedAt
      fromUser { 
        id
        fullName
        username
        email
        phoneNumber
        imageUrl
      }
      toUser { 
        id
        fullName
        username
        email
        phoneNumber
        imageUrl
      } 
    }
  }`
