import { TransactionStatus } from '../../enums'

const labelsEn = Object.freeze({
  [TransactionStatus.NOT_STARTED]: 'not started',
  [TransactionStatus.REQUEST_CREATED]: 'request created',
  [TransactionStatus.PENDING]: 'pending',
  [TransactionStatus.PROCESSING]: 'processing',
  [TransactionStatus.CONFIRMING]: 'confirming',
  [TransactionStatus.SETTLED]: 'settled',
  [TransactionStatus.REFUNDED]: 'refunded',
  [TransactionStatus.FAILED]: 'failed',
})

export default {
  getLabel: (status?: TransactionStatus | null, language?: string): string => {
    if (!status) {
      return ''
    }
    if (!language || language === 'US') {
      return labelsEn[status]
    }
    return ''
  },
  isValid: (status: TransactionStatus): boolean => Object.values(TransactionStatus).includes(status),
  isComplete: (status: TransactionStatus): boolean => (
    status === TransactionStatus.SETTLED ||
    status === TransactionStatus.REFUNDED ||
    status === TransactionStatus.FAILED
  ),
}
