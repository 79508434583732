import adminTaskType from './adminTaskTypeHelpers'
import appAction from './appActionHelpers'
import assetType from './assetTypeHelpers'
import asyncTaskResult from './asyncTaskResultHelpers'
import asyncTaskType from './asyncTaskTypeHelpers'
import campaignStatus from './campaignStatusHelpers'
import campaignType from './campaignTypeHelpers'
import codeSignInStage from './codeSignInStageHelpers'
import contactEventReminderTiming from './contactEventReminderTimingHelpers'
import cryptoCurrencyHelpers from './cryptoCurrencyHelpers'
import errorCode from './errorCodeHelpers'
import fiatCurrencyHelpers from './fiatCurrencyHelpers'
import fundType from './fundTypeHelpers'
import identType from './identTypeHelpers'
import notificationMethod from './notificationMethodHelpers'
import oAuthUserProvider from './oAuthUserProviderHelpers'
import orderStatus from './orderStatusHelpers'
import orgProductType from './orgProductTypeHelpers'
import paymentErrorCode from './paymentErrorCodeHelpers'
import paymentType from './paymentTypeHelpers'
import purchaseStatus from './purchaseStatusHelpers'
import purchaseTransferStatus from './purchaseTransferStatusHelpers'
import purchaseType from './purchaseTypeHelpers'
import purchaseUpdateType from './purchaseUpdateTypeHelpers'
import recordStatus from './objectStatusHelpers'
import reportDataFormat from './reportDataFormatHelpers'
import reportType from './reportTypeHelpers'
import rewardType from './rewardTypeHelpers'
import shoppingCartStatus from './shoppingCartStatusHelpers'
import storedProductStatus from './storedProductStatusHelpers'
import systemMessageStatus from './systemMessageStatusHelpers'
import systemMessageType from './systemMessageTypeHelpers'
import tagType from './tagTypeHelpers'
import tfaCommand from './tfaCommandHelpers'
import transactionProcessor from './transactionProcessorHelpers'
import transactionRemoteStatus from './transactionRemoteStatusHelpers'
import transactionStatus from './transactionStatusHelpers'
import transactionType from './transactionTypeHelpers'
import uiMessage from './uiMessageHelpers'

const typeHelpers = {
  adminTaskType,
  appAction,
  assetType,
  asyncTaskResult,
  asyncTaskType,
  campaignStatus,
  campaignType,
  codeSignInStage,
  contactEventReminderTiming,
  cryptoCurrencyHelpers,
  errorCode,
  fiatCurrencyHelpers,
  fundType,
  identType,
  notificationMethod,
  oAuthUserProvider,
  orderStatus,
  orgProductType,
  paymentErrorCode,
  paymentType,
  purchaseStatus,
  purchaseTransferStatus,
  purchaseType,
  purchaseUpdateType,
  recordStatus,
  reportDataFormat,
  reportType,
  rewardType,
  shoppingCartStatus,
  storedProductStatus,
  systemMessageStatus,
  systemMessageType,
  tagType,
  tfaCommand,
  transactionProcessor,
  transactionRemoteStatus,
  transactionStatus,
  transactionType,
  uiMessage,
}

export default typeHelpers
