import type { ApolloClient } from '@apollo/client'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

const clearSignInAsUser = (apolloClient: ApolloClient<any>): void => {
  console.log('auth.signOut: clearing global cache.')
  globalCacheData.clearProxyUser()

  if (apolloClient) {
    console.log('auth.signOut: clearing Apollo cache.')
    apolloClient.clearStore()
  }
}

export default clearSignInAsUser
