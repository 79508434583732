import type { RewardInput } from '../../../definitions'
import { RewardType } from '../../../enums'

const setForObjectId = (rewardInput: RewardInput, objectId: string): RewardInput => {
  if (!rewardInput || !objectId || !rewardInput.rewardType) {
    return rewardInput
  }

  switch (rewardInput.rewardType) {
    case RewardType.CARD_BALANCE_UPDATE:
      rewardInput.forPurchaseId = objectId
      break
    case RewardType.GIFT_CARD_UPLOAD:
      rewardInput.forPurchaseId = objectId
      break
    case RewardType.INVITED_BY_USER:
      rewardInput.forInvitationId = objectId
      break
    case RewardType.MARKETPLACE_PURCHASE:
      rewardInput.forPurchaseId = objectId
      break
    case RewardType.NEW_USER_INVITATION:
      rewardInput.forInvitationId = objectId
      break
    case RewardType.PURCHASE_BY_INVITED_USER:
      rewardInput.forPurchaseId = objectId
      break
    case RewardType.PURCHASE_TRANSFER:
      rewardInput.forPurchaseTransferId = objectId
      break
  }

  return rewardInput
}

export default setForObjectId
