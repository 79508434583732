import React from 'react'
import { IonIcon, IonImg } from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'

import type { FavoriteOrganizationListItem } from '../../../lib/core/definitions'
import { CdnAssetType } from '../../../services/cdn/enums'
import cdn from '../../../services/cdn'

type Props = {
  favoriteMerchant: FavoriteOrganizationListItem
  onClick?: (merchantId: string, merchantInMarketplace: boolean) => void
  onDelete?: (merchantId: string) => void
}

const FavoriteMerchantItem: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('FavoriteMerchantItem.render called.', { props })
  const {
    favoriteMerchant,
    onClick,
    onDelete,
  } = props

  // ===================================================================================================================
  // Helpers:
  const organizationLogoImageUri = cdn.getUri(CdnAssetType.MERCHANT_LOGO, favoriteMerchant.largeLogoImageSource) || ''

  // ===================================================================================================================
  // Event Handlers:
  const onClickItem = (): void => {
    if (onClick && favoriteMerchant && favoriteMerchant.organizationId) {
      onClick(favoriteMerchant.organizationId, favoriteMerchant.merchantInMarketplace)
    }
  }

  const onDeleteItem = (): void => {
    if (onDelete && favoriteMerchant && favoriteMerchant.id) {
      onDelete(favoriteMerchant.id)
    }
  }

  // ===================================================================================================================
  // Rendering:
  if (!favoriteMerchant.id || !favoriteMerchant.organizationId) {
    return null
  }

  let deleteButton: JSX.Element | undefined
  if (onDelete) {
    deleteButton = (
      <IonIcon
        icon={closeCircleOutline}
        size='small'
        className='delete-button'
        onClick={onDeleteItem}
      />
    )
  }

  return (
    <div className='favorite-merchant-item'>
      {deleteButton}
      <div className='withCenteredColumnContent'>
        <IonImg src={organizationLogoImageUri} className='logo' onClick={onClickItem} />
      </div>
    </div>
  )
}

export default FavoriteMerchantItem
