import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import {
  IonButton,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  useIonViewDidLeave,
  useIonViewWillLeave,
} from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import {
  FundType,
  OrderStatus,
  PurchaseType,
  ShoppingCartCommand,
  TokenName,
  TransactionProcessor,
  UiMessage,
} from '../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import { DialogButtonId, DialogId } from '../../contexts/DialogContext/enums'
import type { PaymentMethod } from './definitions'
import type { RefresherEventDetail } from '@ionic/core'
import type { ShoppingCart, ShoppingCartInput } from '../../lib/core/definitions'
import type { ShoppingCartQueryData, ShoppingCartQueryVariables } from '../../services/apollo/definitions'
import type { ShoppingCartStateFunc } from '../../definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useDialog } from '../../contexts/DialogContext/DialogContext'
import { useShoppingCart } from '../../contexts/ShoppingCartContext/ShoppingCartContext'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import coreHelpers from '../../lib/core/helpers'
import CreditCardForm from './CreditCardForm/CreditCardForm'
import CryptoForm from './CryptoForm/CryptoForm'
import GiftFlowStepper from '../../components/GiftFlowStepper/GiftFlowStepper'
import helpers from './helpers'
import MimbleTokenForm from './MimbleTokenForm/MimbleTokenForm'
import NavBar from '../../components/NavBar/NavBar'
import OutOfStockWarning from './OutOfStockWarning/OutOfStockWarning'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import PaymentMethods from './PaymentMethods/PaymentMethods'
import ProcessingSection from './ProcessingSection'
import ShoppingCartComponent from './ShoppingCart/ShoppingCart'

const appPageId = AppPage.ShoppingCartPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  shoppingCartId: string
}

const ShoppingCartPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { shoppingCartId: shoppingCartIdFromParams } = useParams<keyof Params>() as unknown as Params
  const isActiveShoppingCart = !shoppingCartIdFromParams

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { openDialog } = useDialog()
  const {
    activeUser,
    activeUserWallets,
    isLoadingActiveUser,
    reloadPurchases,
    reloadActiveUserWallets,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  // const mimbleWallet = getActiveUserMimbleWallet && getActiveUserMimbleWallet()
  // const mitBalance = (mimbleWallet && mimbleWallet.balanceToken) || 0
  // const userCanDonate = coreHelpers.models.user.hasAppFeature(
  //   activeUser && activeUser.appFeatures,
  //   AppFeature.DONATE,
  // )

  const {
    shoppingCart: activeShoppingCart,
    isSavingShoppingCart: isSavingActiveShoppingCart,
    isLoadingShoppingCart: isLoadingActiveShoppingCart,
    reloadShoppingCart: reloadActiveShoppingCart,
    reloadPendingShoppingCarts,
    setShoppingCart,
  } = useShoppingCart()

  const [showToast, setShowToast] = useState(false)
  const [processingMessage, setProcessingMessage] = useState<string | undefined>()
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [stripeToken, setStripeToken] = useState<string | undefined>()
  const [refreshTimer, setRefreshTimer] = useState<NodeJS.Timeout | undefined>()
  const [processingOrderId, setProcessingOrderId] = useState<string | undefined>()
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const getRefreshInterval = (requestAgeInSecs: number): number => {
    if (requestAgeInSecs < 20) {
      return 1000
    }
    return 4000
  }

  const cleanUpPageAndReloadData = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const handleError = (error: Error): void => {
        console.error(error)
        setToastMessage(error.message)
        setShowToast(true)
        setProcessingMessage(undefined)
        reject(error)
      }

      reloadActiveShoppingCart().then(() => {
        console.log('ShoppingCartPage.cleanUpPageAndReloadData: done loading active shopping cart.')
        reloadPendingShoppingCarts().then(() => {
          console.log('ShoppingCartPage.cleanUpPageAndReloadData: done reloading pending orders.')
          reloadPurchases().then(() => {
            reloadActiveUserWallets().then(() => {
              console.log('ShoppingCartPage.cleanUpPageAndReloadData: done reloading user wallets.')
              setProcessingOrderId(undefined)
              resolve()
            }, handleError)
          }, handleError)
        }, handleError)
      }, handleError)
    })
  }

  const forwardToPaymentReceived = (orderId: string): void => {
    cleanUpPageAndReloadData().then(() => {
      console.log('ShoppingCartPage.forwardToPaymentReceived: forwarding to payment received.', { orderId })
      navigate(`${AppRoute.PAYMENT_RECEIVED}/${orderId}`, true)
    })
  }

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewDidLeave(() => {
    if (refreshTimer) {
      clearTimeout(refreshTimer)
      setRefreshTimer(undefined)
    }
  }, [refreshTimer])

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: pendingShoppingCartData,
    loading: isLoadingPendingShoppingCart,
    refetch: reloadPendingShoppingCart,
  } = useQuery<ShoppingCartQueryData, ShoppingCartQueryVariables>(
    apollo.queries.shoppingCart(true), {
      variables: {
        shoppingCartId: shoppingCartIdFromParams,
        userId: activeUserId as string,
      },
      skip: !shoppingCartIdFromParams,
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      onCompleted: (data) => {
        console.log('ShoppingCartPage.apollo-hook[orderedShoppingCart].onCompleted: new data received.', { data })

        if (!data || !data.shoppingCart) {
          console.error('ShoppingCartPage.apollo-hook[orderedShoppingCart].onCompleted: no valid data received', { data })
          return
        }

        const pendingShoppingCart = data.shoppingCart

        // Payment completed?
        const { order } = pendingShoppingCart
        if (
          order &&
          order.id &&
          (
            order.status === OrderStatus.PENDING_DELIVERY ||
            order.status === OrderStatus.DELIVERED
          )
        ) {
          console.log('ShoppingCartPage.apollo-hook[orderedShoppingCart].onCompleted: order received.', { data })
          // The payment has been processed and the order created:
          forwardToPaymentReceived(order.id)
          return
        }

        const isUsingCryptoPayment = coreHelpers.type.transactionProcessor.isCrypto(pendingShoppingCart.paymentProcessor)
        if (!isUsingCryptoPayment) {
          // We process other payment methods elsewhere
          return
        }

        if (!pendingShoppingCart.paymentCryptoAddress || !pendingShoppingCart.paymentTimesOutAt) {
          console.warn('ShoppingCartPage.apollo-hook[orderedShoppingCart].onCompleted: invalid response data')
          if (refreshTimer) {
            clearTimeout(refreshTimer)
            setRefreshTimer(undefined)
          }
          return
        }

        const requestAgeInSecs = moment().diff(moment(Number(pendingShoppingCart.paymentCreatedAt)), 'seconds')
        const requestTimesOutInSecs = moment(Number(pendingShoppingCart.paymentTimesOutAt)).diff(moment(), 'seconds')

        if (requestTimesOutInSecs < 1) {
          console.log('ShoppingCartPage.apollo-hook[orderedShoppingCart].onCompleted: request timed out')
          if (refreshTimer) {
            clearTimeout(refreshTimer)
            setRefreshTimer(undefined)
          }
          return
        }

        console.log('Refreshing component', { requestAgeInSecs, requestTimesOutInSecs })
        const t = setTimeout(reloadPendingShoppingCart, getRefreshInterval(requestAgeInSecs))
        setRefreshTimer(t)
      },
      onError: (error) => {
        console.error('Error fetching the order processing progress.', error)
      },
    },
  )
  const orderedShoppingCart = pendingShoppingCartData && pendingShoppingCartData.shoppingCart

  // ===================================================================================================================
  // Helpers:
  const shoppingCart = isActiveShoppingCart ? activeShoppingCart : orderedShoppingCart
  const shoppingCartId = shoppingCartIdFromParams || (activeShoppingCart && activeShoppingCart.id)
  const isLoadingShoppingCart = isActiveShoppingCart
    ? isLoadingActiveShoppingCart
    : isLoadingPendingShoppingCart
  const isSavingShoppingCart = isSavingActiveShoppingCart

  const currentPaymentMethod: PaymentMethod | undefined = shoppingCart
    ? {
        transactionProcessor: shoppingCart.paymentProcessor || TransactionProcessor.INTERNAL,
        fundType: shoppingCart.paymentFundType || FundType.TOKEN,
        currency: shoppingCart.paymentCurrency || TokenName.MIMBLE_TOKEN,
      }
    : undefined
  const paymentCryptoAddress = shoppingCart && shoppingCart.paymentCryptoAddress
  const isInStock = !shoppingCart || coreHelpers.models.shoppingCart.isInStock(shoppingCart)
  const isValid = !!shoppingCart && coreHelpers.models.shoppingCart.isValid(shoppingCart)
  const isActiveShoppingCartEmpty = (
    isActiveShoppingCart &&
    (!activeShoppingCart || coreHelpers.models.shoppingCart.isEmpty(activeShoppingCart))
  )

  const paymentMethodChoices = helpers.getPaymentMethodChoices(
    shoppingCart,
    activeUser,
    activeUserWallets,
  )
  const mitChoice = paymentMethodChoices.find(c => c.key === 'internal')
  const isTokenPaymentEnabled = !!(mitChoice && mitChoice.enable)

  // ===================================================================================================================
  // Event Handlers:
  let dialogButtonHandler: (buttonId: DialogButtonId) => void

  const onPlaceOrder = (
    paymentStripeToken?: string,
    warnIfNotInStock = true,
  ): void => {
    console.log('ShoppingCartPage.onPlaceOrder called.', { paymentStripeToken })

    if (!shoppingCart || !shoppingCart.id) {
      console.error('ShoppingCartPage.onPlaceOrder: shoppingCart missing.')
      return
    }

    if (processingOrderId) {
      // Already processing....is this a double click?
      return
    }

    setProcessingOrderId(shoppingCart.id)
    setPaymentErrorMessage(undefined)

    reloadActiveShoppingCart().then((reloadedShoppingCart) => {
      if (!reloadedShoppingCart) {
        console.error('reloadShoppingCart did not return a shopping cart')
        // todo: display to user
        setProcessingOrderId(undefined)
        return
      }

      if (warnIfNotInStock && !coreHelpers.models.shoppingCart.isInStock(reloadedShoppingCart)) {
        setProcessingOrderId(undefined)
        setStripeToken(paymentStripeToken)
        openDialog(DialogId.OUT_OF_STOCK_ITEMS_IN_SHOPPING_CART, dialogButtonHandler)
        return
      }

      // . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
      // We are now committed to placing the order.
      // . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .

      const handleError = (errorMessage: string | null | undefined): void => {
        const message = errorMessage || 'The payment failed to process. Please verify your input and try again.'
        setToastMessage(message)
        setShowToast(true)
        setPaymentErrorMessage(message)
      }

      const isUsingCryptoPayment = coreHelpers.type.transactionProcessor.isCrypto(shoppingCart.paymentProcessor)

      // Clearing Stripe info:
      if (shoppingCart.paymentProcessor === TransactionProcessor.STRIPE) {
        setStripeToken(undefined)
      }

      if (isUsingCryptoPayment) {
        setProcessingMessage('Creating payment request...')
      } else {
        setProcessingMessage('Placing order...')
      }

      const shoppingCartInput: ShoppingCartInput = {
        id: shoppingCart.id,
        userId: activeUserId,
        command: ShoppingCartCommand.PLACE_ORDER,
      }

      if (shoppingCart.paymentProcessor === TransactionProcessor.STRIPE) {
        shoppingCartInput.paymentStripeToken = paymentStripeToken
      }

      const oldUpdatedAt = shoppingCart && shoppingCart.updatedAt
      const stateFunc = (cart: ShoppingCart): boolean => {
        if (!cart) {
          return false
        }
        if (oldUpdatedAt && cart.updatedAt === oldUpdatedAt) {
          return false
        }
        if (cart.paymentErrorCode || cart.paymentErrorMessage) {
          return true
        }
        if (isUsingCryptoPayment) {
          return !!cart.paymentRemoteId
        }
        return !!cart.orderId
      }

      // Placing the order using the context:
      setShoppingCart(
        shoppingCartInput,
        false,
        stateFunc,
      ).then((updatedShoppingCart) => {
        console.log('ShoppingCartPage.placeOrder: context returned', { updatedShoppingCart })
        setProcessingMessage(undefined)
        if (!updatedShoppingCart) {
          console.error('ShoppingCartPage.onPlaceOrder: updatedShoppingCart missing.')
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
          setProcessingOrderId(undefined)
          return
        }

        if (updatedShoppingCart.paymentErrorCode || updatedShoppingCart.paymentErrorMessage) {
          let errorMessage = updatedShoppingCart.paymentErrorMessage || updatedShoppingCart.errorMessage
          console.log('ShoppingCartPage.placeOrder: error=',
            { updatedShoppingCart, errorMessage })
          handleError(errorMessage)
          setProcessingOrderId(undefined)
          return
        }

        // If this is a crypto order, and we are not yet on the payment request route, we navigate to it:
        if (isUsingCryptoPayment) {
          if (!shoppingCartIdFromParams) {
            // Crypto currency payment requests are handled by this component (ShoppingCartPage), but
            // with a different route.
            console.log('ShoppingCartPage.placeOrder: forwarding to payment request', { updatedShoppingCart })
            navigate(`${AppRoute.SHOPPING_CART}/${updatedShoppingCart.id}`)
            cleanUpPageAndReloadData().then(undefined, (error) => {
              console.error(error)
            })
          }
          return
        }

        // const { order } = updatedShoppingCart
        //
        // if (!order) {
        //   console.log('ShoppingCartPage.placeOrder: missing order in response.', { updatedShoppingCart })
        //   // todo: surface error
        //   setProcessingOrderId(undefined)
        //   return
        // }
        //
        // if (order.status === OrderStatus.INVALID) {
        //   console.log('ShoppingCartPage.placeOrder: error=', { order })
        //   // todo: extract the error from the metadata
        //   handleError(undefined)
        //   setProcessingOrderId(undefined)
        //   return
        // }

        if (!updatedShoppingCart.orderId) {
          console.log('ShoppingCartPage.placeOrder: missing orderId in shoppingCart')
          setProcessingOrderId(undefined)
          handleError(undefined)
          return
        }

        forwardToPaymentReceived(updatedShoppingCart.orderId)
        cleanUpPageAndReloadData()
      }, (error) => {
        console.error(error)
        setProcessingMessage(undefined)
        setProcessingOrderId(undefined)
        setToastMessage(error.message)
        setShowToast(true)
      })
    }, (error) => {
      console.error(error)
      setProcessingMessage(undefined)
      setProcessingOrderId(undefined)
      setToastMessage(error.message)
      setShowToast(true)
    })
  }

  const onClearShoppingCart = (showConfirmation = true): void => {
    // console.log('ShoppingCartPage.onDeleteShoppingCart called.', {
    //   showConfirmation,
    //   shoppingCartIdFromParams,
    //   activeShoppingCart,
    //   orderedShoppingCart,
    //   shoppingCart,
    // })

    if (!shoppingCart) {
      console.error('ShoppingCartPage.onDeleteShoppingCart: shoppingCart missing.')
      return
    }

    if (showConfirmation) {
      openDialog(
        isActiveShoppingCart
          ? DialogId.CONFIRM_CLEAR_SHOPPING_CART
          : DialogId.CONFIRM_DELETE_PENDING_ORDER,
        dialogButtonHandler,
      )
      return
    }

    setProcessingMessage(isActiveShoppingCart ? 'Clearing shopping cart' : 'Canceling pending order')
    setShoppingCart(
      {
        id: shoppingCart.id,
        userId: activeUserId,
        command: ShoppingCartCommand.CLEAR,
      },
      isActiveShoppingCart,
      isActiveShoppingCart ? undefined : 'expect-null',
    ).then(() => {
      setProcessingMessage(undefined)
      if (shoppingCartIdFromParams) {
        helpers.reloadPendingShoppingCartsUntilDeleted(
          shoppingCartIdFromParams,
          reloadPendingShoppingCarts,
        ).then(() => {
          navigate(AppRoute.SHOPPING_CART, true)
        }, (error) => {
          console.error(error)
        })
      }
    }, (error) => {
      console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
      setProcessingMessage(undefined)
    })
  }

  const onRefresh = (): void => {
    pageMessages && pageMessages.clear()
    if (isActiveShoppingCart) {
      reloadActiveShoppingCart()
    } else {
      reloadPendingShoppingCart()
    }
  }

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (
      shoppingCart &&
      processingMessage &&
      coreHelpers.type.transactionProcessor.isCrypto(shoppingCart.paymentProcessor) &&
      shoppingCart.paymentCryptoAddress
    ) {
      setProcessingMessage(undefined)
    }
  }, [paymentCryptoAddress])

  // useEffect(() => {
  //   if (waitingForOrderId && !coreHelpers.models.compareId(waitingForOrderId, shoppingCartId)) {
  //     // todo: navigate to the order page
  //     navigate(`${AppRoute.ORDER}/${waitingForOrderId}`)
  //   }
  // }, [shoppingCartId])

  useIonViewWillLeave(() => {
    if (stripeToken) {
      setStripeToken(undefined)
    }
  }, [stripeToken])

  const saveShoppingCart = (
    shoppingCartInput: ShoppingCartInput,
    isInTargetStateFunc: ShoppingCartStateFunc,
    processingMessage: string,
  ) => {
    shoppingCartInput.userId = activeUserId
    setProcessingMessage(processingMessage)
    if (isActiveShoppingCart) {
      delete shoppingCartInput.id
    } else if (shoppingCartIdFromParams) {
      shoppingCartInput.id = shoppingCartIdFromParams
    }
    setShoppingCart(shoppingCartInput, isActiveShoppingCart, isInTargetStateFunc).then(() => {
      setProcessingMessage(undefined)
    }, (error) => {
      console.error(error)
      setProcessingMessage(undefined)
    })
  }

  // ===================================================================================================================
  // Event Handlers:
  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    const fnc = isActiveShoppingCart
      ? reloadActiveShoppingCart
      : reloadPendingShoppingCart
    fnc().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onAddMarketplaceProduct = (): void => {
    navigate(AppRoute.MARKETPLACE)
  }

  const onAddMimbleTokens = (): void => {
    navigate(AppRoute.BUY_MIMBLE_TOKENS)
  }

  const onSelectDonationTarget = (donationTargetId: string): void => {
    if (!shoppingCart) {
      return
    }
    saveShoppingCart(
      { id: shoppingCart.id, donationTargetId },
      (shoppingCart) => shoppingCart.donationTargetId === donationTargetId,
      'Setting donation...',
    )
  }

  const onChangePaymentMethod = (newPaymentMethod: PaymentMethod): void => {
    console.log('onChangePaymentMethod: newPaymentMethod=', newPaymentMethod)
    if (!shoppingCart) {
      return
    }

    const isUsingCryptoPayment = coreHelpers.type.transactionProcessor.isCrypto(newPaymentMethod.transactionProcessor)

    saveShoppingCart(
      {
        paymentProcessor: newPaymentMethod.transactionProcessor,
        paymentFundType: newPaymentMethod.fundType,
        paymentCurrency: newPaymentMethod.currency,
        command: ShoppingCartCommand.UPDATE_PAYMENT_METHOD,
      },
      // (cart) => {
      //   console.log('>>>>>>>', {
      //     'cart-paymentProcessor': cart.paymentProcessor,
      //     'cart-paymentFundType': cart.paymentFundType,
      //     'cart-paymentCurrency': cart.paymentCurrency,
      //     'newPaymentMethod.transactionProcessor': newPaymentMethod.transactionProcessor,
      //     'newPaymentMethod.fundType': newPaymentMethod.fundType,
      //     'newPaymentMethod.currency': newPaymentMethod.currency,
      //     'cart.paymentAmount': cart.paymentAmount,
      //   })
      //   return (
      //     cart.paymentProcessor === newPaymentMethod.transactionProcessor &&
      //     cart.paymentFundType === newPaymentMethod.fundType &&
      //     cart.paymentCurrency === newPaymentMethod.currency &&
      //     !!cart.paymentAmount && cart.paymentAmount > 0 &&
      //     !!cart.paymentExchangeRate && cart.paymentExchangeRate > 0
      //   )
      // },
      (cart): boolean => {
        if (
          cart.paymentProcessor !== newPaymentMethod.transactionProcessor ||
          cart.paymentFundType !== newPaymentMethod.fundType ||
          cart.paymentCurrency !== newPaymentMethod.currency
        ) {
          return false
        }
        if (!isUsingCryptoPayment) {
          return true
        }
        return (
          !!cart.paymentAmount && cart.paymentAmount > 0 &&
          !!cart.paymentExchangeRate && cart.paymentExchangeRate > 0
        )
      },
      isUsingCryptoPayment
        ? 'Requesting exchange rate and calculating amount...'
        : 'Updating payment method...',
    )
  }

  const onRemoveOutOfStockItems = (): void => {
    if (!shoppingCart) {
      return
    }

    saveShoppingCart(
      {
        command: ShoppingCartCommand.REMOVE_OUT_OF_STOCK_ITEMS,
      },
      'watch-metadata-revision',
      'Removing back-ordered items...',
    )
  }

  const onRemoveItem = (itemId: string): void => {
    if (!shoppingCart) {
      return
    }

    saveShoppingCart(
      {
        command: ShoppingCartCommand.REMOVE_ITEM,
        items: [{
          id: itemId,
        }],
      },
      'watch-metadata-revision',
      'Removing item...',
    )
    if (Array.isArray(shoppingCart.items) && shoppingCart.items.length === 1) {
      // Given we're removing the last item, redirect to Marketplace
      onAddMarketplaceProduct()
    }
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenPurchase = (): void => {
    if (
      !shoppingCart ||
      !shoppingCart.order ||
      !Array.isArray(shoppingCart.order.items) ||
      shoppingCart.order.items.length < 1
    ) {
      return
    }

    const purchase = shoppingCart.order.items.find(p => p.purchaseType === PurchaseType.MARKETPLACE_PRODUCT)
    const purchaseId = purchase && purchase.id
    if (purchaseId) {
      navigate(`${AppRoute.GIFT_CARD}/${purchaseId}`)
    }
  }

  const onOpenWallet = (): void => {
    navigate(AppRoute.WALLET)
  }

  const onCancelPaymentRequest = (): void => {
    if (!orderedShoppingCart) {
      return
    }
    saveShoppingCart(
      { command: ShoppingCartCommand.CANCEL_PAYMENT },
      'watch-updated-at',
      'Canceling payment request...',
    )
  }

  const onRefreshExchangeRate = (): void => {
    if (!orderedShoppingCart) {
      return
    }
    saveShoppingCart(
      {
        command: orderedShoppingCart.paymentCryptoAddress
          ? ShoppingCartCommand.RECREATE_CRYPTO_PAYMENT_REQUEST
          : ShoppingCartCommand.UPDATE_EXCHANGE_RATE,
      },
      'watch-metadata-revision',
      'Refreshing exchange rate...',
    )
  }

  dialogButtonHandler = (buttonId: DialogButtonId): void => {
    // console.log(`dialogButtonHandler(${buttonId} called.`, { shoppingCart })

    if (buttonId === DialogButtonId.PLACE_ORDER) {
      onPlaceOrder(stripeToken, false)
    } else if (buttonId === DialogButtonId.CLEAR_SHOPPING_CART) {
      onClearShoppingCart(false)
    } else if (buttonId === DialogButtonId.DELETE_PENDING_ORDER) {
      onClearShoppingCart(false)
    }
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('ShoppingCartPage.render called.', {
  //   isActiveShoppingCart,
  //   shoppingCartId,
  //   shoppingCartIdFromParams,
  //   // waitingForOrderId,
  //   activeShoppingCart,
  //   orderedShoppingCart,
  //   shoppingCart,
  // })

  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const getContent = (): JSX.Element | JSX.Element[] | undefined => {
    if (
      !shoppingCart &&
      isLoadingShoppingCart &&
      !processingOrderId
    ) {
      return (
        <ProcessingSection
          message={undefined}
          shoppingCart={shoppingCart}
          isLoadingShoppingCart={isLoadingShoppingCart}
        />
      )
    }

    if (isActiveShoppingCart && isActiveShoppingCartEmpty) {
      if (processingOrderId) {
        return (
          <ProcessingSection
            message={undefined}
            shoppingCart={shoppingCart}
            isLoadingShoppingCart={isLoadingShoppingCart}
          />
        )
      } else if (!isSavingShoppingCart && !isLoadingShoppingCart) {
        return (
          <>
            Your shopping cart is currently empty.
            <div className='formButtonWrapper'>
              <IonButton
                onClick={onAddMarketplaceProduct}
              >
                Buy Gift Card
              </IonButton>
              <IonButton
                className='withStandardLeftMargin'
                onClick={onAddMimbleTokens}
              >
                Buy Mimble Tokens
              </IonButton>
            </div>
          </>
        )
      }
    }

    const sections: JSX.Element[] = []

    if (shoppingCart) {
      sections.push(
        <ShoppingCartComponent
          key='cart'
          shoppingCart={shoppingCart}
          isActiveShoppingCart={isActiveShoppingCart}
          onAddMarketplaceProduct={onAddMarketplaceProduct}
          onAddMimbleTokens={onAddMimbleTokens}
          onRemoveItem={onRemoveItem}
        />,
      )
    }

    if (processingMessage) {
      sections.push(
        <ProcessingSection
          key='processing'
          message={processingMessage}
          shoppingCart={shoppingCart}
          isLoadingShoppingCart={isLoadingShoppingCart}
        />,
      )
    }

    if (shoppingCart) {
      sections.push(
        <div key='payment-methods'>
          <div className='smallText lightText withSmallBottomMargin'>
            Payment Method
          </div>
          <PaymentMethods
            items={paymentMethodChoices}
            currentValue={currentPaymentMethod}
            readonly={false}
            onChange={onChangePaymentMethod}
          />
        </div>,
      )
    }

    if (!isInStock) {
      sections.push(
        <OutOfStockWarning
          key='oos'
          isInStock={isInStock}
          onRemoveOutOfStockItems={onRemoveOutOfStockItems}
        />,
      )
    }

    if (shoppingCart) {
      // if (
      //   userCanDonate &&
      //   productType === OrgProductType.GIFT_CARD &&
      //   productOption &&
      //   productOption.reward
      // ) {
      //   sections.push(
      //     <div key='donation' className='withDoubleBottomMargin'>
      //       <div className='sectionCaption'>Donation Options</div>
      //       <DonationOptions onSelectDonationTarget={onSelectDonationTarget}/>
      //     </div>,
      //   )
      // }

      if (shoppingCart.paymentProcessor === TransactionProcessor.STRIPE) {
        sections.push(
          <CreditCardForm
            key='form'
            shoppingCart={shoppingCart}
            paymentErrorMessage={paymentErrorMessage}
            isActiveShoppingCart={isActiveShoppingCart}
            isSavingShoppingCart={isSavingShoppingCart}
            isOrderPlaced={!!processingOrderId}
            onClearShoppingCart={onClearShoppingCart}
            onDeletePendingOrder={onClearShoppingCart}
            onPlaceOrder={onPlaceOrder}
          />,
        )
      } else if (shoppingCart.paymentProcessor === TransactionProcessor.INTERNAL) {
        sections.push(
          <MimbleTokenForm
            key='form'
            shoppingCart={shoppingCart}
            isActiveShoppingCart={isActiveShoppingCart}
            isSavingShoppingCart={isSavingShoppingCart}
            isTokenPaymentEnabled={isTokenPaymentEnabled}
            isShoppingCartValid={isValid}
            onClearShoppingCart={onClearShoppingCart}
            onDeletePendingOrder={onClearShoppingCart}
            onPlaceOrder={onPlaceOrder}
          />,
        )
      }

      if (shoppingCart.paymentFundType === FundType.CRYPTO) {
        sections.push(
          <CryptoForm
            key='form'
            shoppingCart={shoppingCart}
            isActiveShoppingCart={isActiveShoppingCart}
            onDeletePendingOrder={onClearShoppingCart}
            onPlaceOrder={onPlaceOrder}
            onOpenPurchase={onOpenPurchase}
            onOpenWallet={onOpenWallet}
            onCancelPaymentRequest={onCancelPaymentRequest}
            onRefreshExchangeRate={onRefreshExchangeRate}
            showUiMessage={showUiMessage}
          />,
        )
      }
    }
    return sections
  }

  const pageCaption = isActiveShoppingCart ? 'Shopping Cart' : 'Pending Order'

  return (
    <IonPage className='app-page-public shopping-cart-page'>
      <NavBar
        title={pageCaption}
        userInfo={activeUser}
        isProcessing={isLoadingActiveUser}
        onOpenUserAccount={onOpenUserAccount}
        onRefresh={onRefresh}
      />
      <GiftFlowStepper
        size='large'
        className='withStandardTopMargin'
        parent='marketplace'
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {getContent()}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default ShoppingCartPage
