import React from 'react'

import './styles.css'
import type { PaymentChoicesItem, PaymentMethod } from '../definitions'
import PaymentMethodItem from './PaymentMethodItem/PaymentMethodItem'
import helpers from '../helpers'

type Props = {
  currentValue: PaymentMethod | undefined
  items: PaymentChoicesItem[]
  readonly: boolean | undefined
  onChange: (value: PaymentMethod) => void
}
const PaymentMethods: React.FC<Props> = (props): JSX.Element | null => {
  const { currentValue, items, readonly, onChange } = props

  if (!items) {
    return null
  }

  const renderedItems = items
    .filter(item => item.show !== false)
    .map((item): JSX.Element => {
      const onClick = (): void => {
        // if (!item.enable) {
        //   return
        // }
        if (!readonly && onChange) {
          onChange(item.value)
        }
      }
      return (
        <PaymentMethodItem
          key={item.key}
          item={item}
          readonly={readonly}
          isSelected={!!currentValue && helpers.comparePaymentMethod(item.value, currentValue)}
          onClick={onClick}
        />
      )
    })
  return (
    <div className='formItem'>
      <div className='payment-method-choices-control'>
        {renderedItems}
      </div>
    </div>
  )
}

export default PaymentMethods
