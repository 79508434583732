import React, { useState } from 'react'
import { addOutline } from 'ionicons/icons'

import './styles.css'
import type { Contact, User } from '../../../../lib/core/definitions'
import EventForm from './EventForm/EventForm'
import EventListItem from './EventListItem/EventListItem'
import FormGroup from '../../../../components/FormGroup/FormGroup'
import IconButton from '../../../../components/IconButton/IconButton'

type Props = {
  contact: Contact | null | undefined
  user: User | null | undefined
  showUiMessage: (message: string) => void
}

const EventsSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contact,
    user,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const [isShowingNewEventForm, setIsShowingNewEventForm] = useState(false)
  const [editingEventId, setEditingEventId] = useState<string | undefined>()

  // ===================================================================================================================
  // Event Handlers:
  const onSetEditingEventId = (eventId: string | undefined): void => {
    setEditingEventId(eventId)
    setIsShowingNewEventForm(false)
  }

  // ===================================================================================================================
  // Rendering:
  if (!contact || !user) {
    return null
  }

  let renderedList: JSX.Element[] | undefined
  if (
    !isShowingNewEventForm &&
    Array.isArray(contact.events) &&
    contact.events.length > 0
  ) {
    renderedList = contact.events
      // .concat(contact.events).concat(contact.events).concat(contact.events).concat(contact.events).concat(contact.events)
      .filter((event) => !editingEventId || event.id === editingEventId)
      .map((event) => {
        return (
          <EventListItem
            key={event.id}
            eventId={event.id as string}
            contact={contact}
            editingEventId={editingEventId}
            setEditingEventId={onSetEditingEventId}
            showUiMessage={showUiMessage}
          />
        )
      })
  }

  let newEventSection: JSX.Element | undefined
  if (isShowingNewEventForm) {
    newEventSection = (
      <FormGroup label='New Event' className='withStandardLeftMargin withStandardRightMargin withDoubleTopMargin'>
        <EventForm
          event={{ contactId: contact.id as string }}
          contact={contact}
          onClose={() => setIsShowingNewEventForm(false)}
          setEditingEventId={setEditingEventId}
          showUiMessage={showUiMessage}
        />
      </FormGroup>
    )
  } else if (!editingEventId) {
    newEventSection = (
      <div className='new-event-button-section'>
        <IconButton
          icon={addOutline}
          text='Add'
          layout='vertical'
          className='button'
          iconSize={36}
          onClick={() => setIsShowingNewEventForm(true)}
        />
      </div>
    )
  }

  return (
    <div className='events-section'>
      {newEventSection}
      <div className='event-list autoOverflowContainer'>
        {renderedList}
      </div>
    </div>
  )
}

export default EventsSection
