export default function updateModelInList<ListItemType, Type = ListItemType> (
  list: ListItemType[] | null | undefined,
  model: Type | null,
  keyField: string | ((listItem: ListItemType, model: Type) => boolean) | undefined,
  addIfNotFoundInList = true,
  updateFunction?: (listItem: ListItemType | undefined, model: Type) => ListItemType,
): ListItemType[] {
  if (!model) {
    return list || []
  }
  if (!Array.isArray(list) || list.length < 1) {
    if (addIfNotFoundInList) {
      if (updateFunction) {
        return [updateFunction(undefined, model)]
      }
      return [model as unknown as ListItemType]
    }
    return list || []
  }
  let replaced = false
  const newList = list.map((item) => {
    let match = false
    if (!keyField) {
      match = (item as any).id === (model as any).id
    } else if (typeof keyField === 'string') {
      match = (item as any)[keyField] === (model as any)[keyField]
    } else if (typeof keyField === 'function') {
      match = keyField(item, model)
    }
    if (match) {
      replaced = true
      return updateFunction
        ? updateFunction(item, model)
        : model as unknown as ListItemType
    }
    return item
  })
  if (replaced || !addIfNotFoundInList) {
    return newList
  }
  // console.log('lib.core.modelHelpers.updateModelInList: adding new item to list', { model })
  newList.push(updateFunction
    ? updateFunction(undefined, model)
    : model as unknown as ListItemType,
  )
  return newList
}
