import React from 'react'

import './styles.css'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const hourOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  .map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)

type Props = {
  value: number | undefined;
  validationError?: string;
  className?: string;
  onChange: (value: number) => void;
}
const TimeSelect: React.FC<Props> = (props): JSX.Element | null => {
  const {
    value,
    className,
    onChange,
  } = props
  const hour = value && value > 12 ? value - 12 : (value || 8)
  const amPm = value && value > 12 ? 'PM' : 'AM'

  const onChangeHour = (event: any): void => {
    onChange(Number(event.target.value) + (amPm === 'AM' ? 0 : 12))
  }

  const onChangeAmPm = (event: any): void => {
    onChange(hour + (event.target.value === 'AM' ? 0 : 12))
  }

  return (
    <div className={`time-select ${className || ''}`}>
      <FormControl
        variant='outlined'
      >
        <InputLabel id='select-hour-label'>Hour</InputLabel>
        <Select
          labelId='select-hour-label'
          id='select-hour'
          value={hour}
          onChange={onChangeHour}
          label='Hour'
        >
          {hourOptions}
        </Select>
      </FormControl>
      <div className='withStandardLeftMargin'>
        <FormControl
          variant='outlined'
        >
          {/* <InputLabel id='select-tz-label'>AM/PM</InputLabel> */}
          <Select
            labelId='select-ampm-label'
            id='select-ampm'
            value={amPm}
            // label='AM/PM'
            onChange={onChangeAmPm}
          >
            <MenuItem key='AM' value='AM'>AM</MenuItem>
            <MenuItem key='PM' value='PM'>PM</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default TimeSelect
