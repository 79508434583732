import React from 'react'
import { IonGrid, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react'
import { star } from 'ionicons/icons'

import './styles.css'
import { CdnAssetType } from '../../services/cdn/enums'
import type { MerchantListScope } from '../../lib/core/enums'
import type { MerchantListItem } from '../../lib/core/definitions'
import cdn from '../../services/cdn'

type Props = {
  searchText?: string
  scope?: MerchantListScope
  merchants?: MerchantListItem[] | null
  favoriteIds?: string[]
  onSelectMerchant: (merchantId: string) => void
  onEditProduct?: (merchantId: string) => void
  onOpenProduct?: (merchantId: string) => void
  onEditMerchant?: (merchantId: string) => void
}

const filterList = (
  list: MerchantListItem[],
  searchText?: string,
): MerchantListItem[] => {
  if (!searchText) {
    return list
  }
  return list.filter((merchantListItem: MerchantListItem): boolean => {
    if (!merchantListItem.listed) {
      return false
    }
    if (!searchText) {
      return true
    }
    try {
      const reg = new RegExp(searchText, 'i')
      if (merchantListItem.name && merchantListItem.name.match(reg)) {
        return true
      }
    } catch (error) {
      return false
    }
    return false
  })
}

const MerchantGrid: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('MerchantGrid.render called.', { props });
  const {
    searchText,
    merchants,
    favoriteIds,
    onSelectMerchant,
  } = props
  // const isProcessing = isGettingMerchants || isGettingMerchant;

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(merchants) || merchants.length < 1) {
    return null
  }
  let favoriteIcon: JSX.Element | undefined
  if (Array.isArray(favoriteIds) && favoriteIds.length > 0) {
    favoriteIcon = <IonIcon icon={star} className='favorite-organization-icon' />
  }
  const colCount = 4
  const colSize = (12 / colCount).toString()
  let cols: JSX.Element[] = []
  const rows: JSX.Element[] = []
  const filteredList = filterList(merchants, searchText)

  for (let i = 0; i < filteredList.length; i++) {
    const merchantListItem = filteredList[i]
    const merchantLogoImageUri = cdn.getUri(CdnAssetType.MERCHANT_LOGO, merchantListItem.largeLogoImageSource) || ''
    const favoriteIconForThisItem = favoriteIds && favoriteIds.includes(merchantListItem.id)
      ? favoriteIcon
      : undefined
    cols.push(
      <IonCol
        key={merchantListItem.id as string}
        size={colSize}
        className='col'
      >
        <div className='withCenteredColumnContent' onClick={() => onSelectMerchant(merchantListItem.id as string)}>
          <div>
            {favoriteIconForThisItem}
            <IonImg src={merchantLogoImageUri} className='logo' />
          </div>
          <div className='label'>{merchantListItem.name}</div>
        </div>
      </IonCol>,
    )
    if (cols.length === colCount || i === filteredList.length - 1) {
      rows.push(
        <IonRow key={`merchantRow-${i}`} className='row'>
          {cols}
        </IonRow>,
      )
      cols = []
    }
  }

  return (
    <IonGrid className='merchant-grid'>
      {rows}
    </IonGrid>
  )
}

export default MerchantGrid
