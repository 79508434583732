import React from 'react'
import { IonButton } from '@ionic/react'
import { useApolloClient } from '@apollo/client'

import { ShoppingCartCommand } from '../../../lib/core/enums'
import type { ShoppingCart, ShoppingCartInput } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import coreHelpers from '../../../lib/core/helpers/'

type Props = {
  shoppingCart: ShoppingCart | undefined
  isActiveShoppingCart: boolean
}

const ExchangeRateSection: React.FC<Props> = (props): JSX.Element | null => {
  const { shoppingCart, isActiveShoppingCart } = props
  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  // ===================================================================================================================
  // Event Handlers:
  const onRefreshExchangeRate = () => {
    if (!shoppingCart) {
      return
    }
    const shoppingCartInput: ShoppingCartInput = {
      userId: activeUserId,
      command: ShoppingCartCommand.UPDATE_EXCHANGE_RATE,
    }
    api.upsertShoppingCart(
      shoppingCartInput,
      isActiveShoppingCart,
      false,
      'watch-metadata-revision',
      activeUserId as string,
      apolloClient,
    ).then(undefined, (error) => {
      console.error(error)
    })
  }

  // ===================================================================================================================
  // Rendering:
  if (!shoppingCart || shoppingCart.paymentCryptoAddress) {
    return null
  }

  const formattedDate = coreHelpers.ui.formatDate(shoppingCart.paymentExchangeRateUpdatedAt, 'M/D/Y h:m:s')

  return (
    <div className='exchange-rate-section'>
      <div className='sectionCaption'>Exchange Rate</div>
      <div>
        <p>
          The exchange rate for <span className='withBoldText'>1&nbsp;USD</span> is&nbsp;
          <span className='withBoldText'>{shoppingCart.paymentExchangeRate}&nbsp;{shoppingCart.paymentCurrency}</span>&nbsp;
          as of {formattedDate}.
        </p>
        <p>
          You can check the Bitcoin market price at&nbsp;
          <a href='https://www.blockchain.com/charts/market-price'>blockchain.info</a>. You
          can also update the exchange rate used for this payment with the button below. This will
          pull the latest exchange rate and update the payment request.
        </p>
      </div>
      <div className='withCenteredColumnContent withStandardTopMargin'>
        <IonButton onClick={onRefreshExchangeRate} className='smallButton'>
          Refresh
        </IonButton>
      </div>
    </div>
  )
}

export default ExchangeRateSection
