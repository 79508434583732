import React, { useEffect, useState } from 'react'

import { ApolloProvider } from '@apollo/client'
import { SplashScreen } from '@capacitor/splash-screen'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import '../theme/default.css'

import type { EnvironmentVal } from '../lib/core/definitions'
import apollo from '../services/apollo'
import ContentApp from './ContentApp/ContentApp'
import fetchLatestAppVersion from './helpers/fetchLatestAppVersion'
import globalCacheData from '../contexts/GlobalCacheContext/contextData'
import GlobalCacheProvider from '../contexts/GlobalCacheContext/GlobalCacheContext'
import initServices from './helpers/initServices'
import LoadingApp from './LoadingApp/LoadingApp'

let currentEnvironment: EnvironmentVal | undefined
let doneSwitchingEnvironment: (() => void) | undefined

const App: React.FC = (): JSX.Element | null => {
  const [renderIndex, setRenderIndex] = useState(0)
  const [showLoadingApp, setShowLoadingApp] = useState(true)

  useEffect(() => {
    currentEnvironment = globalCacheData.getEnvironment()
    // console.log(`App.useEffect[] called (environment=${currentEnvironment}).`)
    initServices(currentEnvironment)
    fetchLatestAppVersion()
    if (renderIndex === 0) {
      // see https://capacitorjs.com/docs/apis/splash-screen
      SplashScreen.hide().then(undefined, (error) => { console.error(error) })
    }
    setRenderIndex(renderIndex + 1)
  }, [])

  const onChangeEnvironment = (environment: EnvironmentVal, done?: () => void): void => {
    console.log(`App.onChangeEnvironment(${environment}) called.`)
    if (environment === currentEnvironment) {
      // console.log('App.onChangeEnvironment: no change.')
      return
    }
    initServices(environment)
    setRenderIndex(renderIndex + 1)
    if (done) {
      doneSwitchingEnvironment = done
    }
  }

  const onCompleteAnimation = () => {
    setShowLoadingApp(false)
  }

  const environment = globalCacheData.getEnvironment()
  const apolloClient = apollo.getApolloClient(environment)

  // console.log(`App: rendering #${renderIndex} for ${environment}.`)

  if (/* showLoadingApp || */ !apolloClient) {
    console.log('App: no Apollo client available yet - rendering LoadingApp.')
    setTimeout(() => {
      if (showLoadingApp) {
        setShowLoadingApp(false)
      }
    }, 10000)
    return (
      <LoadingApp
        showAnimation={false}
        onCompleteAnimation={onCompleteAnimation}
      />
    )
  }

  if (doneSwitchingEnvironment) {
    process.nextTick(() => {
      doneSwitchingEnvironment && doneSwitchingEnvironment()
      doneSwitchingEnvironment = undefined
    })
  }

  return (
    <GlobalCacheProvider onChangeEnvironment={onChangeEnvironment}>
      <ApolloProvider client={apolloClient}>
        <ContentApp onChangeEnvironment={onChangeEnvironment} />
      </ApolloProvider>
    </GlobalCacheProvider>
  )
}

export default App
