import type { Moment } from 'moment'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'

import { GiftFlowDeliveryTimeChoice, GiftFlowDeliveryType } from '../../enums'
import { NotificationMethod } from '../../lib/core/enums'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import helpers, { simpleTimes } from './helpers'
import coreHelpers from '../../lib/core/helpers'
import StepButtons from './StepButtons'
import TimeSelect from '../../components/TimeSelect/TimeSelect'
import TimezoneSelect from '../../components/TimezoneSelect/TimezoneSelect'

const simpleHours = Object.values(simpleTimes).slice(0, Object.keys(simpleTimes).length - 1)

const deliveryTypeLabels = {
  [GiftFlowDeliveryType.NOW]: 'Send my gift now',
  [GiftFlowDeliveryType.LATER]: 'Send it later',
  [GiftFlowDeliveryType.BY_USER]: 'I will send the gift link myself',
}

const timeChoiceLabels = {
  [GiftFlowDeliveryTimeChoice.MORNING]: 'In the morning (8AM)',
  [GiftFlowDeliveryTimeChoice.MIDDAY]: 'Noon',
  [GiftFlowDeliveryTimeChoice.AFTERNOON]: 'In the afternoon (4PM)',
  [GiftFlowDeliveryTimeChoice.NIGHT]: 'Midnight',
  [GiftFlowDeliveryTimeChoice.ADVANCED_INPUT]: 'More...',
}

const defaultTime = moment()
defaultTime.set('hour', 12)
defaultTime.set('minute', 0)

const getDeliveryTimeChoiceFromHour = (hour: number): GiftFlowDeliveryTimeChoice => {
  if (simpleHours.includes(hour)) {
    // @ts-ignore
    return Object.keys(simpleTimes).find(k => simpleTimes[k] === hour) as GiftFlowDeliveryTimeChoice
  }
  return GiftFlowDeliveryTimeChoice.ADVANCED_INPUT
}

type Props = {
  onPrevStep: () => void;
  onNextStep: () => void;
}

const ScheduleStep: React.FC<Props> = (props): JSX.Element | null => {
  const { onPrevStep, onNextStep } = props

  // ===================================================================================================================
  // State:
  const [deliveryType, setDeliveryType] = useState<GiftFlowDeliveryType | null>(null)
  const [deliveryDate, setDeliveryDate] = useState<Date | null | undefined>(null)
  const [timeChoice, setTimeChoice] = useState<GiftFlowDeliveryTimeChoice | null>(null)
  const [hour, setHour] = useState<number | undefined>()
  const [timezone, setTimezone] = useState<string | undefined>()
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const {
    flowId,
    toUserId,
    giftChanges,
    isProcessing,
    getCurMessageDeliveryInfo,
    getHourFromTimeChoice,
    setGiftChanges,
  } = useGiftFlow()

  const curMessageDeliveryInfo = getCurMessageDeliveryInfo()

  let curTimezone = timezone
  if (!curTimezone && curMessageDeliveryInfo && curMessageDeliveryInfo.messageScheduledTimezone) {
    curTimezone = curMessageDeliveryInfo.messageScheduledTimezone
  }

  let curDeliveryDate: Date | null | undefined = deliveryDate
  if (
    !curDeliveryDate &&
    curMessageDeliveryInfo &&
    curMessageDeliveryInfo.messageScheduledAt
  ) {
    const dateAsMoment = coreHelpers.string.parseDate(curMessageDeliveryInfo.messageScheduledAt, curTimezone)
    if (dateAsMoment) {
      curDeliveryDate = dateAsMoment.toDate()
    }
  }

  let curHour: number | undefined = hour
  if (!hour && curDeliveryDate) {
    curHour = curDeliveryDate.getHours()
  }

  let curTimeChoice = timeChoice
  if (!curTimeChoice) {
    curTimeChoice = getDeliveryTimeChoiceFromHour(curHour || 8)
  }

  console.log('ScheduleStep.render called.', {
    curMessageDeliveryInfo,
    curTimezone,
    curDeliveryDate: curDeliveryDate && curDeliveryDate.toString(),
    curHour,
    curTimeChoice,
  })

  let curDeliveryType = deliveryType
  if (!curDeliveryType && curMessageDeliveryInfo) {
    if (curMessageDeliveryInfo.notificationMethod === NotificationMethod.OFF) {
      curDeliveryType = GiftFlowDeliveryType.BY_USER
    } else {
      curDeliveryType = curMessageDeliveryInfo.messageScheduledAt
        ? GiftFlowDeliveryType.LATER
        : GiftFlowDeliveryType.NOW
    }
  }
  if (!curDeliveryType) {
    curDeliveryType = GiftFlowDeliveryType.NOW
  }

  let curNotificationMethod: NotificationMethod | null | undefined
  if (curMessageDeliveryInfo) {
    curNotificationMethod = curMessageDeliveryInfo.notificationMethod
  }

  // ===================================================================================================================
  // Helpers:
  const resetState = (): void => {
    setDeliveryDate(null)
    setDeliveryType(null)
    setTimeChoice(null)
    setHour(undefined)
    setTimezone(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    resetState()
  }, [flowId])

  // useEffect((): void => {
  //   if (schedule) {
  //     if (schedule.timeChoice && schedule.timeChoice !== timeChoice) {
  //       setTimeChoice(schedule.timeChoice)
  //     }
  //     if (schedule.time) {
  //       const t = schedule.timezone ? moment.tz(schedule.time, schedule.timezone) : schedule.time
  //       setDeliveryDate(t)
  //       setHour(t.get('hour'))
  //     }
  //     if (schedule.timezone) {
  //       setTimezone(schedule.timezone)
  //     }
  //   }
  // }, [schedule])

  // ===================================================================================================================
  // Helpers:
  const isValid = (
    curDeliveryType === GiftFlowDeliveryType.NOW ||
    curDeliveryType === GiftFlowDeliveryType.BY_USER ||
    !!curDeliveryDate
  )

  // ===================================================================================================================
  // Event Handlers:
  const onChangeDeliveryTypeChoice = (event: any): void => {
    const newDeliveryTypeChoice = event.target.value as GiftFlowDeliveryType
    setDeliveryType(newDeliveryTypeChoice)
    if (newDeliveryTypeChoice === GiftFlowDeliveryType.LATER) {
      setIsCalendarOpen(true)
    }
  }

  const onChangeTimeChoice = (event: any): void => {
    setTimeChoice(event.target.value)
  }

  const onChangeDeliveryDate = (date: Date | null): void => {
    if (!date) {
      setDeliveryDate(undefined)
      return
    }
    if (curDeliveryDate) {
      setDeliveryDate(
        moment(curDeliveryDate)
          .set('year', date.getFullYear())
          .set('month', date.getMonth())
          .set('date', date.getDate())
          .toDate(),
      )
    }
    setDeliveryDate(
      moment(date)
        .set('hour', curHour || 8)
        .set('minute', 0)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toDate(),
    )
  }

  const onChangeHour = (hour: number): void => {
    setHour(hour)
  }

  const onChangeTimezone = (newTimezone: string): void => {
    setTimezone(newTimezone)
  }

  const onFormSubmit = (event?: any): void => {
    event && event.preventDefault()

    if (!isValid) {
      console.error('not valid')
      return
    }

    if (deliveryType === GiftFlowDeliveryType.BY_USER) {
      setGiftChanges({
        ...giftChanges,
        notificationMethod: NotificationMethod.OFF,
        messageScheduledAt: null,
        messageScheduledTimezone: null,
      }, true)
      return
    }

    if (deliveryType === GiftFlowDeliveryType.NOW) {
      setGiftChanges({
        ...giftChanges,
        notificationMethod: NotificationMethod.AUTO,
        messageScheduledAt: null,
        messageScheduledTimezone: null,
      }, true)
      return
    }

    if (!deliveryDate && !timeChoice && hour === undefined) {
      onNextStep()
      return
    }

    const newTime = coreHelpers.string.parseDate(moment(curDeliveryDate), curTimezone)
    if (!newTime) {
      return
    }
    if (newTime) {
      if (timeChoice === GiftFlowDeliveryTimeChoice.ADVANCED_INPUT && curHour !== undefined) {
        newTime.set('hour', curHour)
        newTime.set('minute', 0)
      } else if (curTimeChoice) {
        if (newTime) {
          const hr = getHourFromTimeChoice(curTimeChoice, curHour || 8)
          newTime.set('hour', hr)
          newTime.set('minute', 0)
        }
      }
    }

    setGiftChanges({
      ...giftChanges,
      notificationMethod: curNotificationMethod || NotificationMethod.AUTO,
      messageScheduledAt: newTime ? (newTime as Moment).toISOString() : undefined,
      messageScheduledTimezone: curTimezone,
    }, true)
  }

  // ===================================================================================================================
  // Rendering:
  const sections: JSX.Element[] = []

  const deliveryTypeOptions = Object.values(GiftFlowDeliveryType)
    .filter(c => !toUserId || c !== GiftFlowDeliveryType.BY_USER)
    .map(choice => (
      <MenuItem key={choice} value={choice}>{deliveryTypeLabels[choice]}</MenuItem>
    ))

  if (curDeliveryType === GiftFlowDeliveryType.LATER) {
    sections.push(
      <div key='date-to-send' className='withDoubleBottomMargin'>
        <DatePicker
          disablePast
          label='Date'
          clearable
          renderInput={(params) => (
            <TextField {...params} helperText={params?.inputProps?.placeholder} />
          )}
          value={curDeliveryDate || null}
          onChange={onChangeDeliveryDate}
        />
      </div>,
    )
  }

  if (
    curDeliveryType === GiftFlowDeliveryType.LATER &&
    curTimeChoice !== GiftFlowDeliveryTimeChoice.ADVANCED_INPUT
  ) {
    const options = helpers.getAvailableSimpleTimeChoices(moment(curDeliveryDate)).map(choice => (
      <MenuItem key={choice} value={choice}>{timeChoiceLabels[choice]}</MenuItem>
    ))
    sections.push(
      <div key='simple-time-choices' className='withDoubleBottomMargin'>
        <FormControl
          variant='outlined'
          fullWidth
        >
          <InputLabel id='select-time-label'>Time to send</InputLabel>
          <Select
            labelId='select-time-label'
            id='select-time'
            value={curTimeChoice}
            onChange={onChangeTimeChoice}
            label='Time to send'
          >
            {options}
          </Select>
        </FormControl>
      </div>,
    )
  }

  if (
    curDeliveryType === GiftFlowDeliveryType.LATER &&
    curTimeChoice === GiftFlowDeliveryTimeChoice.ADVANCED_INPUT
  ) {
    sections.push(
      <div key='time-input' className='withDoubleBottomMargin'>
        <TimeSelect
          value={curHour || 8}
          className='withTripleBottomMargin'
          onChange={onChangeHour}
        />
        <TimezoneSelect
          value={curTimezone}
          scope='selected'
          onChange={onChangeTimezone}
        />
      </div>,
    )
  }

  let description: JSX.Element | undefined
  switch (curDeliveryType) {
    case GiftFlowDeliveryType.NOW:
      description = (
        <div className='withDoubleTopMargin'>
          Mimble will send your gift as a gift link, together with your message,
          to the recipient in the next step.
        </div>
      )
      break
    case GiftFlowDeliveryType.LATER:
      description = (
        <div className='withDoubleTopMargin'>
          Mimble will send your gift on the date and time you selected here.
        </div>
      )
      break
    case GiftFlowDeliveryType.BY_USER:
      description = (
        <div className='lightText smallText withStandardTopMargin'>
          Mimble will create a gift link. You can send this gift link
          to the recipient anyway you want.
        </div>
      )
      break
  }

  return (
    <form onSubmit={onFormSubmit}>
      <div className='withDoubleBottomMargin'>
        When do you want to send your gift?
      </div>
      <div key='delivery-options' className='withStandardTopMargin withStandardBottomMargin'>
        <FormControl
          variant='outlined'
          fullWidth
        >
          <InputLabel id='select-time-label'>When to send?</InputLabel>
          <Select
            labelId='select-time-label'
            id='select-time'
            value={curDeliveryType || GiftFlowDeliveryType.NOW}
            onChange={onChangeDeliveryTypeChoice}
            label='When to send?'
          >
            {deliveryTypeOptions}
          </Select>
        </FormControl>
        {description}
      </div>
      {sections}
      <StepButtons
        isProcessing={isProcessing}
        disableNextButton={!isValid || isProcessing}
        onPrevStep={onPrevStep}
        onNextStep={onFormSubmit}
      />
    </form>
  )
}

export default ScheduleStep
