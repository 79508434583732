import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertFavoriteOrganizationMutationData,
  UpsertFavoriteOrganizationMutationVariables
} from '../apollo/definitions'
import type {
  ApiQueryOptions,
  FavoriteOrganization,
  FavoriteOrganizationInput,
  FavoriteOrganizationListFilter,
} from '../../lib/core/definitions'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'
import loadFavoriteOrganization from './loadFavoriteOrganization'

const upsertFavoriteOrganization = (
  favoriteOrganizationInput: FavoriteOrganizationInput,
  isInTargetStateFunc: ((favoriteOrganization: FavoriteOrganization) => boolean) | 'watch-updated-at' | undefined,
  listFilter: FavoriteOrganizationListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<FavoriteOrganization | undefined> => (
  // console.log('api.upsertFavoriteOrganization called.', { favoriteOrganizationInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertFavoriteOrganization: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    console.log('input', favoriteOrganizationInput)

    const queryParams:
      MutationOptions<UpsertFavoriteOrganizationMutationData, UpsertFavoriteOrganizationMutationVariables> = {
        mutation: apollo.mutations.upsertFavoriteOrganization,
        variables: { favoriteOrganization: favoriteOrganizationInput },
      }

    apolloClient.mutate<UpsertFavoriteOrganizationMutationData, UpsertFavoriteOrganizationMutationVariables>(queryParams)
      .then((resp) => {
        console.log('api.upsertFavoriteOrganization: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertFavoriteOrganization ||
          !resp.data.upsertFavoriteOrganization.id
        ) {
          logger.error('api.upsertFavoriteOrganization: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((favoriteOrganization: FavoriteOrganization) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!favoriteOrganizationInput.id) {
            logger.error('api.upsertFavoriteOrganization: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertFavoriteOrganization.updatedAt
            activeIsInTargetStateFunc = (favoriteOrganization: FavoriteOrganization) => favoriteOrganization.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertFavoriteOrganization)
        ) {
          cache.updateFavoriteOrganization(
            resp.data.upsertFavoriteOrganization,
            activeUserId,
            apolloClient,
          )
          resolve(resp.data.upsertFavoriteOrganization)
          return
        }

        const favoriteOrganizationId = resp.data.upsertFavoriteOrganization.id

        setTimeout(() => {
          // console.log('api.upsertFavoriteOrganization: calling loadFavoriteOrganization')
          loadFavoriteOrganization(
            favoriteOrganizationId,
            activeIsInTargetStateFunc,
            listFilter,
            activeUserId,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((favoriteOrganization) => {
              // console.log('api.upsertFavoriteOrganization: resolving with favoriteOrganization', favoriteOrganization)
              resolve(favoriteOrganization)
            }, (error) => {
              logger.error('api.upsertFavoriteOrganization: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertFavoriteOrganization: error', { error })
        reject(error)
      })
  })
)

export default upsertFavoriteOrganization
