import React, { useState } from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import { addCircle, arrowDown, arrowUp, cart, search } from 'ionicons/icons'

import './styles.css'
import { PurchaseListScope } from '../../../lib/core/enums'
import type { FormRadioItem } from '../../../components/FormRadioGroup/FormRadioGroup'
import FormRadioGroup from '../../../components/FormRadioGroup/FormRadioGroup'

const showExpandControl = false

type Props = {
  searchText?: string;
  scope: PurchaseListScope;
  showBuyButton?: boolean;
  onApply: (searchText: string, scope: PurchaseListScope) => void;
  onAddUploadedPurchase: () => void;
  onCreatePurchase: () => void;
};

const PurchasesListFilterHeader: React.FC<Props> = ({
  searchText,
  scope,
  showBuyButton,
  onApply,
  onAddUploadedPurchase,
  onCreatePurchase,
}): JSX.Element => {
  const [showExpanded, setShowExpanded] = useState(false)

  const onChangeSearchText = (event: any): void => {
    // console.log('onChangeShowActive called', event)
    onApply(event.detail.value, scope)
  }

  const onClearFilter = (): void => {
    onApply('', scope)
  }

  const onChangeScope = (newScope: PurchaseListScope): void => {
    onApply(searchText || '', newScope)
  }

  const onClickExpandIcon = (): void => {
    // console.log('onChangeShowActive called', event)
    setShowExpanded(!showExpanded)
  }

  const scopeChoices: FormRadioItem[] = [
    {
      value: PurchaseListScope.ACTIVE,
      label: 'Active',
      isChecked: scope === PurchaseListScope.ACTIVE,
    },
    {
      value: PurchaseListScope.PENDING,
      label: 'Pending',
      isChecked: scope === PurchaseListScope.PENDING,
    },
    {
      value: PurchaseListScope.TRANSFERRED,
      label: 'Sent',
      isChecked: scope === PurchaseListScope.TRANSFERRED,
    },
    {
      value: PurchaseListScope.ARCHIVED,
      label: 'Archived',
      isChecked: scope === PurchaseListScope.ARCHIVED,
    },
  ]

  let expandedSection
  if (showExpanded) {
    expandedSection = (
      <div className='purchaseListFilterExpandedSection'>
        <FormRadioGroup
          items={scopeChoices}
          smallLabels
          onChange={onChangeScope}
        />
      </div>
    )
  }

  const expandIcon = showExpanded ? arrowUp : arrowDown
  let buyButton
  if (showBuyButton) {
    buyButton = (
      <IonIcon
        icon={cart}
        class='purchaseListCreatePurchaseIcon'
        onClick={onCreatePurchase}
      />
    )
  }

  let expandControl
  if (showExpandControl) {
    expandControl = (
      <IonIcon
        icon={expandIcon}
        class='expandFilterIcon'
        onClick={onClickExpandIcon}
      />
    )
  }

  return (
    <div className='purchasesListFilterHeaderWrapper'>
      <div className='purchasesListFilterRow0'>
        <div className='searchBarRow0'>
          <div className='searchBarWrapper'>
            <IonIcon icon={search} class='searchBarSearchIcon' />
            <IonInput
              value={searchText}
              placeholder='search'
              onIonChange={onChangeSearchText}
              className='searchBar'
              clearInput
            />
            {expandControl}
          </div>
          <div className='purchaseListHeaderAddPurchaseButtons'>
            <IonIcon
              icon={addCircle}
              class='purchaseListAddPurchaseIcon'
              onClick={onAddUploadedPurchase}
            />
            {buyButton}
          </div>
        </div>
        {expandedSection}
      </div>
    </div>
  )
}

export default PurchasesListFilterHeader
