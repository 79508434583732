import React from 'react'
import { IonButton, IonIcon, IonItem, IonList } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'

import type { AppRoute } from '../../../enums'
import type { OrderListItem } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers/'

type Props = {
  orders: OrderListItem[]
  showArrows?: boolean
  className?: string
  onSelectOrder: (orderId: string) => void
  onNavigate: (route: AppRoute | string) => void
}

const OrderList: React.FC<Props> = (props): JSX.Element | null => {
  const {
    orders,
    showArrows,
    className,
    onSelectOrder,
    onNavigate,
  } = props

  // ===================================================================================================================
  // Rendering:
  const items = orders.map((order): JSX.Element => {
    const onClick = () => {
      if (order.id) {
        onSelectOrder(order.id)
      }
    }
    const lines: JSX.Element[] = []
    const formattedDeliveryDate = coreHelpers.ui.formatDate(order.updatedAt)
    const formattedStatus = coreHelpers.type.orderStatus.getLabel(order.status)
    const formattedAmount = coreHelpers.ui.formatAmount(order.paymentAmount, order.paymentFundType, order.paymentCurrency, true)
    lines.push(
      <div key='line1' className='withSmallBottomMargin'>
        <span className='lightText'>Order Placed:</span>&nbsp;{formattedDeliveryDate}
      </div>,
    )
    lines.push(
      <div key='line2' className='withSmallBottomMargin'>
        <span className='lightText'>Total:</span>&nbsp;{formattedAmount}
      </div>,
    )
    lines.push(
      <div key='line3' className='lightText smallText'>
        <span className='lightText'>Status:</span>&nbsp;{formattedStatus}
      </div>,
    )

    return (
      <IonItem
        key={order.id as string}
        className='item'
        lines='none'
        detail={showArrows}
        onClick={onClick}
      >
        <div className='rowWithSpaceBetween with100PercentWidth'>
          <div>
            {lines}
          </div>
          <IonButton
            size='small'
            color='medium'
            fill='clear'
          >
            <IonIcon icon={chevronForward} />
          </IonButton>
        </div>
      </IonItem>
    )
  })

  return (
    <IonList className={`order-list ${className || ''}`}>
      {items}
    </IonList>
  )
}

export default OrderList
