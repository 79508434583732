import appVersion from './appVersion'
import array from './array'
import lists from './lists'
import crypto from './crypto'
import cryptoCurrency from './cryptoCurrency'
import models from './models'
import number from './number'
import string from './string'
import type from './type'
import ui from './ui'

export default {
  appVersion,
  array,
  crypto,
  cryptoCurrency,
  lists,
  models,
  number,
  string,
  type,
  ui,
}
