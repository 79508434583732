import React from 'react'
import { balloonOutline, chevronForward, gift } from 'ionicons/icons'
import { IonButton, IonIcon, IonImg } from '@ionic/react'

import './styles.css'
import type { AnimationName, ChatImageType } from '../../../lib/core/enums'
import { ChatAttachmentType } from '../../../lib/core/enums'
import Animations from './Animations/Animations'
import ChatImageModal from '../../../components/ChatImageModal/ChatImageModal'
import MimbleTokenImage from '../../MimbleTokenImage/MimbleTokenImage'
import TokenTransferForm from './TokenTransferForm/TokenTransferForm'

type Props = {
  newAttachmentType: ChatAttachmentType | 'select' | undefined
  canAttachTokens?: boolean
  canAttachGift?: boolean
  setNewAttachmentType: (attachmentType: ChatAttachmentType | 'select' | undefined) => void
  onAttachTokens?: (amount: number) => void
  onNewGift?: () => void
  onSelectImage: (imageUrl: string, imageType: ChatImageType) => void
  onSelectAnimation: (selectedAnimation: AnimationName) => void
}

const NewAttachmentSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    newAttachmentType,
    canAttachTokens,
    canAttachGift,
    setNewAttachmentType,
    onAttachTokens,
    onNewGift,
    onSelectImage,
    onSelectAnimation,
  } = props

  const onShowTokenTransferForm = (): void => {
    setNewAttachmentType(ChatAttachmentType.TRANSACTION)
  }

  const onShowGiphy = (): void => {
    setNewAttachmentType(ChatAttachmentType.GIPHY)
  }

  const onShowAnimations = (): void => {
    setNewAttachmentType(ChatAttachmentType.ANIMATION)
  }

  const handleClose = (): void => {
    setNewAttachmentType(undefined)
  }

  const handleAttachTokens = (tokenAmount: number): void => {
    if (onAttachTokens) {
      setNewAttachmentType(undefined)
      onAttachTokens(tokenAmount)
    }
  }

  const handleAttachGift = (): void => {
    if (onNewGift) {
      setNewAttachmentType(undefined)
      onNewGift()
    }
  }

  if (!newAttachmentType) {
    return null
  }

  // ===================================================================================================================
  // Rendering:
  let attachTokenSection: JSX.Element | undefined
  if (canAttachTokens) {
    attachTokenSection = (
      <div
        className='rowWithCenterAlignedItems withPointerCursor g-with-flex-1'
        onClick={onShowTokenTransferForm}
      >
        <MimbleTokenImage className='withStandardRightMargin' size={30} />
        <div className='g-with-flex-1'>
          Send Mimble Tokens
        </div>
        <IonButton
          size='small'
          color='medium'
          fill='clear'
        >
          <IonIcon icon={chevronForward} />
        </IonButton>
      </div>
    )
  }

  let attachGiftSection: JSX.Element | undefined
  if (canAttachGift) {
    attachGiftSection = (
      <div
        className='rowWithCenterAlignedItems withPointerCursor g-with-flex-1 withSmallTopMargin'
        onClick={handleAttachGift}
      >
        <IonIcon icon={gift} className='row-icon'/>
        <div className='g-with-flex-1'>
          Send a Gift
        </div>
        <IonButton
          size='small'
          color='medium'
          fill='clear'
        >
          <IonIcon icon={chevronForward}/>
        </IonButton>
      </div>
    )
  }

  if (!newAttachmentType || newAttachmentType === 'select') {
    return (
      <div className='new-attachment-section'>
        <div className='withStandardLeftMargin withStandardBottomMargin g-with-flex-1'>
          {attachGiftSection}
          {attachTokenSection}
          <div
            className='rowWithCenterAlignedItems withPointerCursor g-with-flex-1 withSmallTopMargin'
            onClick={onShowGiphy}
          >
            <IonImg
              className='row-icon'
              src={process.env.PUBLIC_URL + '/assets/images/giphy-logo-round.png'}
            />
            <div className='g-with-flex-1'>
              Giphy
            </div>
            <IonButton
              size='small'
              color='medium'
              fill='clear'
            >
              <IonIcon icon={chevronForward} />
            </IonButton>
          </div>
          <div
            className='rowWithCenterAlignedItems withPointerCursor g-with-flex-1 withSmallTopMargin'
            onClick={onShowAnimations}
          >
            <IonIcon
              icon={balloonOutline}
              className='row-icon'
            />
            <div className='g-with-flex-1'>
              Animation
            </div>
            <IonButton
              size='small'
              color='medium'
              fill='clear'
            >
              <IonIcon icon={chevronForward} />
            </IonButton>
          </div>
        </div>
      </div>
    )
  }

  if (newAttachmentType === ChatAttachmentType.ANIMATION) {
    return (
      <Animations
        onClose={handleClose}
        onSubmit={onSelectAnimation}
      />
    )
  }

  if (newAttachmentType === ChatAttachmentType.GIPHY) {
    return (
      <ChatImageModal
        show={newAttachmentType === ChatAttachmentType.GIPHY}
        onClose={handleClose}
        onSelectImage={onSelectImage}
      />
    )
  }

  if (newAttachmentType === ChatAttachmentType.TRANSACTION) {
    return (
      <TokenTransferForm
        onClose={handleClose}
        onSubmit={handleAttachTokens}
      />
    )
  }

  return null
}

export default NewAttachmentSection
