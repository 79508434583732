import type { GlobalCacheContextValue } from './definitions'
import contextData from './contextData'

const defaultValue: GlobalCacheContextValue = {
  init: contextData.init,
  clearValue: contextData.clearValue,
  clearSessionData: contextData.clearSessionData,
  clear: contextData.clear,
  setObj: contextData.setObj,
  getObj: contextData.getObj,
  setValue: contextData.setValue,
  getValue: contextData.getValue,
  getIntValue: contextData.getIntValue,
  getDeviceId: contextData.getDeviceId,
  clearAuthUser: contextData.clearAuthUser,
  clearOAuthProvider: contextData.clearOAuthProvider,
  clearProxyUser: contextData.clearProxyUser,
  setAuthUser: contextData.setAuthUser,
  setProxyUser: contextData.setProxyUser,
  getActiveUser: contextData.getActiveUser,
  getPrefs: contextData.getPrefs,
  getEnvironment: contextData.getEnvironment,
  setEnvironment: contextData.setEnvironment,
  getActiveUserId: contextData.getActiveUserId,
  getActiveUserIdent: contextData.getActiveUserIdent,
  getActiveUserUsername: contextData.getActiveUserUsername,
  getActiveUserFullName: contextData.getActiveUserFullName,
  getActiveUserEmail: contextData.getActiveUserEmail,
  getActiveUserPhoneNumber: contextData.getActiveUserPhoneNumber,
  getAuthToken: contextData.getAuthToken,
  getOAuthProvider: contextData.getOAuthProvider,
  getActiveUserRoles: contextData.getActiveUserRoles,
  getActiveUserHasRole: contextData.getActiveUserHasRole,
  getActiveUserAppFeatures: contextData.getActiveUserAppFeatures,
  getActiveUserPrefs: contextData.getActiveUserPrefs,
  getAuthUser: contextData.getAuthUser,
  getAuthUserId: contextData.getAuthUserId,
  getAuthUserUsername: contextData.getAuthUserUsername,
  getAuthUserFullName: contextData.getAuthUserFullName,
  getAuthUserRoles: contextData.getAuthUserRoles,
  getProxyUser: contextData.getProxyUser,
  getIsSignedIn: contextData.getIsSignedIn,
  setPreference: contextData.setPreference,
  getPreference: contextData.getPreference,
  getPreferenceFlag: contextData.getPreferenceFlag,

  setUnauthorizedRequestReportedByMimbleApi: (active: boolean): void => {},
  getUnauthorizedRequestReportedByMimbleApi: () => false,
}

export default defaultValue
