import React from 'react'
import { IonImg } from '@ionic/react'
import './styles.css'

type Props = {
  size?: number
  className?: string
  imageClassName?: string
  onClick?: () => void
}
const MimbleTokenImage: React.FC<Props> = (props): JSX.Element => {
  const { size, className, imageClassName, onClick } = props

  return (
    <div
      className={`mimble-token-image ${className || ''}`}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <IonImg
        className={imageClassName}
        src={process.env.PUBLIC_URL + 'assets/images/mimble-logo-round-white.png'}
      />
    </div>
  )
}

MimbleTokenImage.defaultProps = {
  size: 60,
}

export default MimbleTokenImage
