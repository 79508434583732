import { gql } from '@apollo/client'

export default gql`
  query Q($filter: RewardListFilter!) {
    rewards (filter: $filter) {
      id
      rewardType
      amount
      invitationLevel
      occurrence
      recordStatus
      createdAt
      updatedAt
    }
  }`
