import type { ShoppingCartItem } from '../../../definitions'
import compareId from '../compareId'

const getInventory = (item: ShoppingCartItem): number => {
  if (!item) {
    return 0
  }
  if (!item.product || !item.product.productOptions) {
    return 0
  }
  const productOption = item.product.productOptions.find(option => compareId(option.id, item.productOptionId))
  if (!productOption || !productOption.inventory) {
    return 0
  }
  return productOption.inventory
}

export default getInventory
