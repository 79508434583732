import moment from 'moment'
import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import {
  chatboxOutline,
  earthOutline,
  personOutline as personIcon,
  timeOutline as timeIcon,
} from 'ionicons/icons'

import { useGiftFlow } from '../../contexts/GiftFlowContext'
import { NotificationMethod } from '../../lib/core/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import AnimatedStatusChip from '../../components/AnimatedStatusChip/AnimatedStatusChip'
import coreHelpers from '../../lib/core/helpers'
import GiftMessageText from '../../components/GiftMessageText/GiftMessageText'
import helpers from './helpers'
import type { InfoPaneEntryDef } from '../../components/InfoPane/InfoPane'
import InfoPane from '../../components/InfoPane/InfoPane'
import pageHelpers from '../../helpers/pageHelpers'
import StepButtons from './StepButtons'
import UserInfoCard from '../../components/UserInfoCard/UserInfoCard'

const currentYear = moment().get('year')

type Props = {
  onPrevStep: () => void;
  onNavigate: () => void;
  onDiscardFlow: () => void;
}

const ConfirmStep: React.FC<Props> = (props): JSX.Element | null => {
  const {
    onPrevStep,
    onNavigate,
    onDiscardFlow,
  } = props

  const { contacts } = useMimbleData()

  const {
    toUserId,
    giftChanges,
    purchaseTransfer,
    isProcessing,
    getCurToUserInfo,
    getCurMessageText,
    getCurChatImageUrl,
    getCurChatImageType,
    getCurChatAnimation,
    getCurMessageDeliveryInfo,
    setGiftChanges,
    save: saveGiftFlowChanges,
  } = useGiftFlow()

  const toUserInfo = getCurToUserInfo()
  const contact = helpers.getRecipientContact(toUserId, toUserInfo, contacts)
  const recipientName = helpers.getRecipientName(toUserId, toUserInfo, contacts)
  const messageText = getCurMessageText()
  const chatImageUrl = getCurChatImageUrl()
  const chatImageType = getCurChatImageType()
  const animation = getCurChatAnimation()
  const messageDeliveryInfo = getCurMessageDeliveryInfo()

  // ===================================================================================================================
  // Event Handlers:
  const onChangeNotificationMethod = () => {
    setGiftChanges({
      ...giftChanges,
      notificationMethod: (
        messageDeliveryInfo &&
        messageDeliveryInfo.notificationMethod === NotificationMethod.EMAIL
      )
        ? NotificationMethod.SMS
        : NotificationMethod.EMAIL,
    }, false)
  }

  const onSubmit = (): void => {
    if (!giftChanges) {
      console.log('SendGiftForm.onSubmit: no changes pending, not saving')
      onNavigate()
      return
    }
    try {
      saveGiftFlowChanges().then(undefined, (error) => {
        console.error(error)
      })
    } catch (error) {
      console.warn(error)
    }
  }

  // ===================================================================================================================
  // Rendering:
  const sections: JSX.Element[] = []

  if (toUserId && toUserInfo) {
    sections.push(
      <div key='recipient' className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={personIcon} className='section-icon' />
          <div className='section-caption'>Recipient</div>
        </div>
        <UserInfoCard
          userIdentInfo={toUserInfo}
          contact={contact}
          className='section-content'
        />
      </div>,
    )
  } else {
    const entries: InfoPaneEntryDef[] = [
      {
        key: 'name',
        label: 'Name',
        value: recipientName,
      },
      {
        key: 'email',
        label: 'Email',
        value: toUserInfo && toUserInfo.email,
      },
      {
        key: 'phone',
        label: 'Phone number',
        value: toUserInfo && toUserInfo.phoneNumber,
      },
    ]
    sections.push(
      <div key='recipient' className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={personIcon} className='section-icon' />
          <div className='section-caption'>Recipient</div>
        </div>
        <InfoPane entries={entries} className='section-content' />
      </div>,
    )
  }

  if (messageDeliveryInfo && messageDeliveryInfo.notificationMethod === NotificationMethod.OFF) {
    sections.push(
      <div key='schedule' className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={timeIcon} className='section-icon' />
          <div className='section-caption'>Schedule</div>
        </div>
        <div className='section-content'>
          I am going to send the gift link to the recipient myself.
        </div>
      </div>,
    )
  } else if (messageDeliveryInfo && messageDeliveryInfo.messageScheduledAt) {
    const dateTime = coreHelpers.string.parseDate(
      messageDeliveryInfo.messageScheduledAt,
      messageDeliveryInfo.messageScheduledTimezone,
    )
    if (dateTime) {
      const year = dateTime.get('year')
      const date = year === currentYear
        ? dateTime.format('dddd, MMMM Do')
        : dateTime.format('dddd, MMMM Do, YYYY')
      const timezoneLabel = messageDeliveryInfo.messageScheduledTimezone
        ? pageHelpers.timezones.getSimpleTimezoneLabel(messageDeliveryInfo.messageScheduledTimezone) || messageDeliveryInfo.messageScheduledTimezone
        : undefined
      const time = messageDeliveryInfo.messageScheduledTimezone
        ? `${dateTime.format('hh:mm a')} (${timezoneLabel})`
        : dateTime.format('hh:mm a')
      sections.push(
        <div key='schedule' className='section'>
          <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
            <IonIcon icon={timeIcon} className='section-icon' />
            <div className='section-caption'>Schedule</div>
          </div>
          <div className='section-content'>
            Mimble will send your gift on&nbsp;
            <span className='withBoldText'>{date}</span>
            &nbsp;at&nbsp;
            <span className='withBoldText'>{time}</span>.
          </div>
        </div>,
      )
    }
  } else {
    sections.push(
      <div key='schedule' className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={timeIcon} className='section-icon' />
          <div className='section-caption'>Schedule</div>
        </div>
        <div className='section-content'>
          Mimble will send out your gift now.
        </div>
      </div>,
    )
  }

  if (
    messageDeliveryInfo &&
    messageDeliveryInfo.notificationMethod &&
    messageDeliveryInfo.notificationMethod !== NotificationMethod.OFF &&
    toUserInfo &&
    !toUserInfo.id &&
    toUserInfo.email &&
    toUserInfo.phoneNumber
  ) {
    const methodText = messageDeliveryInfo.notificationMethod === NotificationMethod.EMAIL
      ? 'an email'
      : 'a text message (SMS) to their phone'
    sections.push(
      <div key='notification' className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={earthOutline} className='section-icon' />
          <div className='section-caption'>How to send</div>
        </div>
        <div className='section-text'>
          Your gift will be sent as {methodText}.
          <br />
          <span className='linkText' onClick={onChangeNotificationMethod}>change</span>
        </div>
      </div>,
    )
  }

  if (
    messageText &&
    (!messageDeliveryInfo || messageDeliveryInfo.notificationMethod !== NotificationMethod.OFF)
  ) {
    sections.push(
      <div key='message' className='section'>
        <div className='rowWithSpaceBetween withStandardBottomMargin'>
          <div className='rowWithCenterAlignedItems'>
            <IonIcon icon={chatboxOutline} className='section-icon' />
            <div className='section-caption'>Message</div>
          </div>
          <AnimatedStatusChip animation={animation} />
        </div>
        <GiftMessageText
          value={messageText}
          chatImageUrl={chatImageUrl}
          chatImageType={chatImageType}
          className='section-content'
        />
      </div>,
    )
  }

  let submitButtonLabel: string
  if (purchaseTransfer) {
    submitButtonLabel = 'Save'
  } else {
    if (messageDeliveryInfo && messageDeliveryInfo.notificationMethod === NotificationMethod.OFF) {
      submitButtonLabel = 'Save'
    } else if (messageDeliveryInfo && messageDeliveryInfo.messageScheduledAt) {
      submitButtonLabel = 'Save & Schedule'
    } else {
      submitButtonLabel = 'Send Now!'
    }
  }

  if (!purchaseTransfer) {
    sections.push(
      <div key='discard' className='withCenteredColumnContent'>
        <IonButton
          fill='clear'
          size='small'
          onClick={onDiscardFlow}
        >
          <div className='g-gray-text-button-label'>Never mind, discard this gift</div>
        </IonButton>
      </div>,
    )
  }
  return (
    <div className='confirm-step'>
      <div className='sectionCaption'>Your Gift Is Ready!</div>
      <div className='withDoubleBottomMargin'>
        Review your gift before sending it. Changes can be made by using the Edit button below.
      </div>
      {sections}
      <StepButtons
        isProcessing={isProcessing}
        onPrevStep={onPrevStep}
        onNextStep={onSubmit}
        nextLabel={submitButtonLabel}
        prevLabel='Back'
      />
    </div>
  )
}

export default ConfirmStep
