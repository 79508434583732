import crypto from 'crypto'

const buildHmacPayload = (data: any, secret: string, timestamp: string): string => (
  `${secret.substring(0, 40)}-${data}-${timestamp}-${secret.substring(40)}`
)

export const createHmacSignature = ({
  data,
  timestamp,
  key,
  secret,
}: {
  data: any;
  timestamp: string;
  key: string;
  secret: string;
}): string => {
  if (!key || !secret) {
    throw new Error('Missing HMAC credentials')
  }

  const payload = buildHmacPayload(data || '', secret, timestamp)
  const hmac = crypto.createHmac('sha256', key)
  hmac.update(payload)
  const signature = hmac.digest('hex')

  // if (env('NODE_ENV') !== 'production') {
  //   console.log('HMAC:', JSON.stringify({
  //     secret,
  //     key,
  //     data,
  //     timestamp,
  //     payload,
  //     signature,
  //   }, null, '  '));
  // }

  return signature
  // return 'INVALID';
}
