import { parsePhoneNumber } from 'libphonenumber-js/mobile'

const isPhoneNumber = (input: string): boolean => {
  if (!input) {
    return false
  }
  try {
    const phoneNumberInfo = parsePhoneNumber(input, 'US')
    return phoneNumberInfo && phoneNumberInfo.isValid()
  } catch (error) {
    console.info('parsePhoneNumber threw error', { input, error })
    return false
  }
}

export default isPhoneNumber
