import React, { useState } from 'react'

const DEFAULT_CLICK_COUNT_TO_ACTIVATE = 7

type Props = {
  clickCountToActivate?: number
  useSpan?: boolean
  className?: string
  onActivate: () => void
}

const MultiPress: React.FC<Props> = (props): JSX.Element => {
  const {
    clickCountToActivate,
    useSpan,
    className,
    children,
    onActivate,
  } = props

  const [lastClickAt, setLastClickAt] = useState<number | undefined>()
  const [clickCount, setClickCount] = useState(0)

  const reset = (): void => {
    setLastClickAt(undefined)
    setClickCount(0)
  }

  const handleClick = (): void => {
    if (!lastClickAt) {
      setLastClickAt(new Date().getTime())
      setClickCount(1)
      return
    }

    const now = new Date().getTime()
    if (now - lastClickAt > 1500) {
      setLastClickAt(undefined)
      setClickCount(1)
      return
    }

    if (clickCount >= (clickCountToActivate || DEFAULT_CLICK_COUNT_TO_ACTIVATE) - 1) {
      reset()
      onActivate()
      return
    }

    setLastClickAt(new Date().getTime())
    setClickCount(clickCount + 1)
  }

  if (useSpan) {
    return (
      <span onClick={handleClick} className={className}>
        {children}
      </span>
    )
  }

  return (
    <div onClick={handleClick} className={className}>
      {children}
    </div>
  )
}

MultiPress.defaultProps = {
  clickCountToActivate: DEFAULT_CLICK_COUNT_TO_ACTIVATE,
}

export default MultiPress
