import { gql } from '@apollo/client'

export default gql`
  query Q($filter: TransactionListFilter!) {
    transactions (filter: $filter) {
      id
      orderId
      fromUserId
      toUserId
      fromWalletId
      toWalletId
      transactionType
      status
      amount
      fundType
      currency
      processor
      recordStatus
      createdAt
      updatedAt
    }
  }`
