import React from 'react'
import { IonLabel, IonChip, IonIcon } from '@ionic/react'
import { balloonOutline } from 'ionicons/icons'
import Lottie from 'react-lottie-player'

import type { AnimationName } from '../../lib/core/enums'
import { useAppHelpers } from '../../contexts/AppHelpersContext/AppHelpersContext'
import animations from '../../animations'

type Props = {
  animation: AnimationName | null | undefined
}

const AnimatedStatusChip: React.FC<Props> = (props): JSX.Element | null => {
  const { animation } = props
  const { showFullPageAnimation } = useAppHelpers()

  if (!animation) {
    return null
  }

  return (
    <IonChip
      className='rowWithCenterAlignedItems'
      style={{ position: 'relative', width: '100px' }}
      color='primary'
      outline
      onClick={() => showFullPageAnimation(animation)}
    >
      <Lottie
        play
        speed={1.5}
        animationData={animations.fullPage[animation]}
      />
      <IonLabel className='rowWithCenterAlignedItems' style={{ position: 'absolute', zIndex: 2 }}>
        <IonIcon icon={balloonOutline} />
        Animated
      </IonLabel>
    </IonChip>
  )
}

export default AnimatedStatusChip
