import addTransientMetadata from './addTransientMetadata'
import compareListItemsByFavorite from './compareListItemsByFavorite'
import compareListItemsByChat from './compareListItemsByChat'
import compareListItemsByEvent from './compareListItemsByEvent'
import compareListItemsByName from './compareListItemsByName'
import filterList from './filterList'
import getContactNames from './getContactNames'
import isItemATextSearchHit from './isItemATextSearchHit'
import parseEventDate from './parseEventDate'
import updateContactUserListItemFromContact from './updateContactUserListItemFromContact'

const contactModelHelpers = {
  addTransientMetadata,
  compareListItemsByFavorite,
  compareListItemsByChat,
  compareListItemsByEvent,
  compareListItemsByName,
  filterList,
  getContactNames,
  isItemATextSearchHit,
  parseEventDate,
  updateContactUserListItemFromContact,
}

export default contactModelHelpers
