import React, { useContext, useState } from 'react'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import type { MarketplaceProductsQueryData, MarketplaceProductsQueryVariables } from '../../services/apollo/definitions'
import type { MarketplaceSearchFilter } from './definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useShoppingCart } from '../../contexts/ShoppingCartContext/ShoppingCartContext'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import GiftFlowStepper from '../../components/GiftFlowStepper/GiftFlowStepper'
import helpers from './helpers'
import MarketplaceHeader from './MarketplaceHeader'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import ProductGrid from './ProductGrid/ProductGrid'
import ProcessingOverlay from '../../components/ProcessingOverlay/ProcessingOverlay'

const appPageId = AppPage.MarketplacePage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const MarketplacePage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser, isLoadingActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const { setShoppingCart } = useShoppingCart()
  const pageMessages = useContext(PageMessagesContext)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [marketplaceSearchFilter, setMarketplaceSearchFilter] = useState<MarketplaceSearchFilter>({
    categoryTag: undefined,
    searchText: undefined,
    featured: false,
    all: true,
  })
  const [isGettingProduct, setIsGettingProduct] = useState(false)

  const listFilter = helpers.getListFilter(marketplaceSearchFilter)

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: productsData,
    loading: isLoadingMarketplaceProducts,
    refetch: reloadMarketplaceProducts,
  } = useQuery<MarketplaceProductsQueryData, MarketplaceProductsQueryVariables>(
    apollo.queries.marketplaceProducts, {
      variables: { filter: listFilter },
      skip: !listFilter,
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
      },
    },
  )
  const products = productsData ? productsData.marketplaceProducts : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingMarketplaceProducts || isGettingProduct

  // ===================================================================================================================
  // Event Handlers:
  const onSearch = (searchFilter: MarketplaceSearchFilter): void => {
    setMarketplaceSearchFilter(searchFilter)
    if (searchFilter.categoryTag || searchFilter.searchText) {
      const filter = helpers.getListFilter(searchFilter)
      if (!filter) {
        return
      }
      reloadMarketplaceProducts({ filter }).then(() => {
        console.log('Done loading search result products')
      }, (error) => {
        console.error(error)
      })
    }
  }

  const onSelectProduct = (
    productId: string,
  ): void => {
    pageHelpers.addProductToShoppingCart({
      activeUserId,
      productId,
      apolloClient,
      setShoppingCart,
      navigate,
    })
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !marketplaceSearchFilter) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadMarketplaceProducts().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)
  // console.log('MarketplacePage.render.', { listFilter, products })

  let searchResultSection: JSX.Element | undefined
  if (Array.isArray(products) && products.length > 0) {
    searchResultSection = (
      <ProductGrid
        products={products}
        onSelectProduct={onSelectProduct}
      />
    )
  // } else {
  //   const message = marketplaceSearchFilter.all
  //     ? 'Please select from the product categories, or enter a search.'
  //     : 'This category currently does not contain any products.'
  //   searchResultSection = (
  //     <div className='g-centered-box-outer'>
  //       <div className='empty-list-content'>
  //         {message}
  //       </div>
  //     </div>
  //   )
  // } else {
  //   console.log('MarketplacePage: no products.')
  }

  return (
    <IonPage className='app-page-public marketplace-page'>
      <NavBar
        title='Mimble Marketplace'
        // contextMenuId={ContextMenuId.PRODUCTS_PAGE}
        // onContextMenu={onContextMenu}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <GiftFlowStepper
        size='large'
        className='withStandardTopMargin'
        parent='marketplace'
      />
      <PageMessages />
      <MarketplaceHeader
        onSearch={onSearch}
      />
      <IonContent style={{ flex: 1 }}>
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {searchResultSection}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      <ProcessingOverlay isProcessing={isLoadingMarketplaceProducts} />
    </IonPage>
  )
}

export default MarketplacePage
