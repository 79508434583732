import { SystemMessageType } from '../../enums'

const labelsEn = Object.freeze({
  [SystemMessageType.GIFT]: 'Gift',
  [SystemMessageType.MARKETING]: 'Marketing Message',
  [SystemMessageType.OPERATIONAL]: 'Operational Message',
  [SystemMessageType.PURCHASE_TRANSFER_RESULT]: 'Gift Message',
  [SystemMessageType.REWARD_ISSUED]: 'Reward Message',
  [SystemMessageType.WELCOME]: 'Welcome Message',
})

const defaultMessageTextUs = {
  [SystemMessageType.GIFT]: '',
  [SystemMessageType.MARKETING]: '',
  [SystemMessageType.OPERATIONAL]: '',
  [SystemMessageType.PURCHASE_TRANSFER_RESULT]: '',
  [SystemMessageType.REWARD_ISSUED]: 'Congrats! You received a reward!',
  [SystemMessageType.WELCOME]: 'Welcome to Mimble!',
}

export default {
  getLabel: (messageType: SystemMessageType | null | undefined, language = 'en'): string => {
    if (!messageType) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[messageType]
    }
    return ''
  },
  getDefaultMessageText: (messageType: SystemMessageType | null | undefined, language = 'en'): string => {
    if (!messageType) {
      return ''
    }
    if (language === 'en') {
      return defaultMessageTextUs[messageType]
    }
    return ''
  },
  isValid: (messageType: SystemMessageType | string): boolean => (
    Object.values(SystemMessageType).includes(messageType as SystemMessageType)
  ),
}
