import type { ImportStoredProductItem, Merchant, MerchantListItem } from '../../../../definitions'
import { FiatCurrency, FundType, StoredProductStatus } from '../../../../enums'
import getInternalMoneyAmount from '../../../ui/getInternalMoneyAmount'
import logger from '../../../../logger'

// Row ID,Brand,eGift Card Number,eGift Card Pin,Denomination,CVV2,Expiration Date,Proxy Number
enum LinePartIndex {
  rowId, // Row ID
  merchantName, // Brand
  code, // eGift Card Number
  pin, // eGift Card Pin
  amount, // Denomination
  ignore1, // CVV2
  ignore2, // Expiration Date
  ignore3, // Proxy Number
}
const PARTS_COUNT = Object.keys(LinePartIndex).length / 2

const cashstar = (
  line: string,
  lineIndex: number,
  productId: string,
  importIdPrefix: string | undefined,
  poNumber: string | undefined,
  source: string | undefined,
  merchants: Partial<Merchant | MerchantListItem>[] | undefined,
): ImportStoredProductItem => {
  logger.info('lib.core.helpers.models.asyncTask.parseImportStoredProductsLine.cashstar called.',
    { line, lineIndex })

  if (line.startsWith('Row') || line.length < 5) {
    return {
      lineIndex,
      ok: true,
      ignore: true,
    }
  }

  const parts = line.split(',')
    .map(p => p.replace('=', ''))
    .map(p => p.replace('"', ''))
    .map(p => p.replace('"', ''))
    .map(p => p.replace(')', ''))
  const partsCount = Array.isArray(parts) ? parts.length : 0
  const importItem: ImportStoredProductItem = {
    ok: false,
    importId: `${importIdPrefix}-${lineIndex}`,
    lineIndex,
    merchantName: parts[LinePartIndex.merchantName],
    status: StoredProductStatus.AVAILABLE,
    poNumber,
    source: source || 'cashstar',
    receivedAt: new Date().toISOString(),
  }

  if (partsCount !== PARTS_COUNT) {
    return {
      ...importItem,
      error: `Parsing returned incorrect number of line components (expected: ${PARTS_COUNT}, found: ${partsCount}).`,
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Merchant Name:
  const merchantName = parts[LinePartIndex.merchantName].trim()
  if (!merchantName) {
    return {
      ...importItem,
      error: `Invalid merchant name: '${merchantName}'.`,
    }
  }
  importItem.merchantName = merchantName
  if (
    Array.isArray(merchants) &&
    merchants.length > 0 &&
    !merchants.find(m => (
      merchantName === m.name ||
      merchantName === m.alias1 ||
      merchantName === m.alias2 ||
      merchantName === m.alias3
    ))
  ) {
    return {
      ...importItem,
      error: `Merchant not found by name: '${merchantName}'.`,
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Amount:
  const amountString = parts[LinePartIndex.amount].trim()
  if (amountString) {
    const amountParts = amountString.split(' ')
    if (amountParts.length !== 2) {
      return {
        ...importItem,
        error: `Invalid amount: '${amountString}'.`,
      }
    }
    const amount = parseFloat(amountParts[0])
    if (
      amount === undefined ||
      amount === null ||
      Number.isNaN(amount) ||
      amount < 0
    ) {
      return {
        ...importItem,
        error: `Invalid amount: '${amount}'.`,
      }
    }
    importItem.amount = getInternalMoneyAmount(
      amount,
      FundType.FIAT,
      FiatCurrency.USD,
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Code:
  importItem.code = parts[LinePartIndex.code].trim()
  if (!importItem.code) {
    return {
      ...importItem,
      error: 'Missing or invalid code.',
    }
  } else {
    importItem.code = importItem.code.replace(/ /g, '')
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // PIN:
  importItem.pin = parts[LinePartIndex.pin].trim().replace(/ /g, '')

  logger.info(`lib.core.helpers.models.asyncTask.parseImportStoredProductsLine.cashstar: parsed line #${lineIndex}.`,
    { importItem })

  return {
    ...importItem,
    ok: true,
  }
}

export default cashstar
