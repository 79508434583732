import type { ApolloClient } from '@apollo/client'

import type { ContactListFilter } from '../../../lib/core/definitions'
import api from '../../../services/api'

const reloadContact = (
  contactId: string | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  const listFilter: ContactListFilter = {
    fromUserId: activeUserId,
    orderBy: 'fullName',
  }
  if (contactId) {
    api.loadContact(
      contactId,
      undefined,
      undefined,
      undefined,
      undefined,
      activeUserId,
      apolloClient,
      // {
      //   queryListVariables: {
      //     filter: listFilter,
      //   } as ContactsQueryVariables,
      //   skipCache: true,
      // },
    ).then(() => {
      // console.log('Reloaded contacts.')
    }, (error) => {
      console.error(error)
    })
  } else {
    api.loadContactUsers(
      listFilter,
      apolloClient,
      undefined,
      { skipCache: true },
    ).then(() => {
      console.log('Reloaded contacts.')
    }, (error) => {
      console.error(error)
    })
  }
}

export default reloadContact
