import type { OldValues, TfaAttribute } from '../definitions'
import type { User } from '../../../../../lib/core/definitions'

const getOldValues = (
  activeUser: User | null | undefined,
  attribute: TfaAttribute,
): OldValues => {
  if (attribute === 'email') {
    return {
      oldValue: activeUser && activeUser.email,
      oldIsSharing: !(activeUser && activeUser.prefs && activeUser.prefs.shareEmail === false),
    }
  }

  if (attribute === 'phoneNumber') {
    return {
      oldValue: activeUser && activeUser.phoneNumber,
      oldIsSharing: !(activeUser && activeUser.prefs && activeUser.prefs.sharePhoneNumber === false),
    }
  }

  return {
    oldValue: undefined,
    oldIsSharing: undefined,
  }
}

export default getOldValues
