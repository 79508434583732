import React from 'react'
import { IonAlert } from '@ionic/react'
import './styles.css'

type Props = {
  isOpen: boolean;
  showNoPasswordRecoveryWarning: boolean;
  onDidDismiss: () => void;
  onCancel: () => void;
  onSignOut: () => void;
};

const ConfirmSignOutAlert: React.FC<Props> = (props): JSX.Element => {
  const {
    isOpen,
    showNoPasswordRecoveryWarning,
    onDidDismiss,
    onCancel,
    onSignOut,
  } = props
  const subHeader = showNoPasswordRecoveryWarning
    ? 'No email or phone number listed for this account. If you forget your password you will no longer be able to sign into Mimble!'
    : ''
  const message = showNoPasswordRecoveryWarning
    ? 'Are you certain you know your password, and want to sign out now?'
    : 'Are you sure you want to sign out?'
  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      header='Sign Out Of Mimble'
      subHeader={subHeader}
      message={message}
      cssClass='confirm-sign-out-alert'
      buttons={[
        { text: 'Yes', handler: onSignOut },
        { text: 'Cancel', cssClass: 'secondary', handler: onCancel },
      ]}
    />
  )
}

export default ConfirmSignOutAlert
