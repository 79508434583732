import type { ApolloClient, MutationOptions } from '@apollo/client'

import type {
  ApiQueryOptions,
  ContactEventReminder,
  ContactEventReminderInput,
  ContactEventReminderListFilter,
} from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertContactEventReminderMutationData,
  UpsertContactEventReminderMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import loadContactEventReminder from './loadContactEventReminder'
import logger from '../logger'

const upsertContactEventReminder = (
  contactEventReminderInput: ContactEventReminderInput,
  isInTargetStateFunc: ((contactEventReminder: ContactEventReminder) => boolean) | 'watch-updated-at' | undefined,
  listFilter: ContactEventReminderListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ContactEventReminder | undefined> => (
  // console.log('api.upsertContactEventReminder called.', { contactEventReminderInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertContactEventReminder: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertContactEventReminderMutationData, UpsertContactEventReminderMutationVariables> = {
      mutation: apollo.mutations.upsertContactEventReminder,
      variables: { contactEventReminder: contactEventReminderInput },
    }

    apolloClient.mutate<UpsertContactEventReminderMutationData, UpsertContactEventReminderMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertContactEventReminder: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertContactEventReminder ||
          !resp.data.upsertContactEventReminder.id
        ) {
          logger.error('api.upsertContactEventReminder: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((contactEventReminder: ContactEventReminder) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!contactEventReminderInput.id) {
            logger.error('api.upsertContactEventReminder: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertContactEventReminder.updatedAt
            activeIsInTargetStateFunc = (obj: ContactEventReminder) => obj.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertContactEventReminder)
        ) {
          // cache.updateContactEventReminder(
          //   resp.data.upsertContactEventReminder,
          //   apollo.getContactEventRemindersQueryVariables(listFilter),
          //   apolloClient,
          // )
          // console.log('api.upsertContactEventReminder: in target state, resolving.')
          resolve(resp.data.upsertContactEventReminder)
          return
        }

        const contactEventReminderId = resp.data.upsertContactEventReminder.id

        setTimeout(() => {
          // console.log('api.upsertContactEventReminder: calling loadContactEventReminder')
          loadContactEventReminder(
            contactEventReminderId,
            activeIsInTargetStateFunc,
            listFilter,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((contactEventReminder) => {
              // console.log('api.upsertContactEventReminder: resolving with contactEventReminder', contactEventReminder)
              resolve(contactEventReminder)
            }, (error) => {
              logger.error('api.upsertContactEventReminder: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertContactEventReminder: error', { error })
        reject(error)
      })
  })
)

export default upsertContactEventReminder
