import React from 'react'
import FavoriteMerchantsSection from '../../../../components/FavoritesSections/FavoriteMerchantsSection/FavoriteMerchantsSection'

import type { Contact } from '../../../../lib/core/definitions'

type Props = {
  contact: Contact | null | undefined
  onOpenFavoriteMerchant: (favoriteOrganizationId: string, merchantInMarketplace: boolean) => void
  showUiMessage: (message: string) => void
}

const FavoritesSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contact,
    onOpenFavoriteMerchant,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // Rendering:
  if (!contact) {
    return null
  }

  let content: JSX.Element | undefined
  if (contact.toUser && contact.toUserId) {
    content = (
      <FavoriteMerchantsSection
        favoriteOrganizations={contact.favoriteOrganizations}
        userId={contact.toUserId}
        onOpenFavoriteMerchant={onOpenFavoriteMerchant}
        showUiMessage={showUiMessage}
      />
    )
  }

  return (
    <div className='favorites-section withStandardMargin'>
      <div className='sectionCaption withStandardTopMargin'>Favorite Brands</div>
      {content}
    </div>
  )
}

export default FavoritesSection
