import { CdnAssetType } from './enums'

const CdnPaths = {
  [CdnAssetType.GIFT_CARD]: 'giftcards',
  [CdnAssetType.MERCHANT_LOGO]: 'vendors',
}

let cdnRoot = ''
let cdnS3Root = ''

export default {
  init: (options: { cdnRoot: string, cdnS3Root: string }): void => {
    cdnRoot = options.cdnRoot
    cdnS3Root = options.cdnS3Root
  },

  getUri: (
    assetType: CdnAssetType,
    objectName: string | null | undefined,
    useCloudfront = true,
  ): string | void => {
    if (!assetType || !objectName) {
      return
    }
    return `${useCloudfront ? cdnRoot : cdnS3Root}/${CdnPaths[assetType]}/${objectName}`
  },
}
