import React from 'react'

import './styles.css'
import { PurchaseListScope } from '../../lib/core/enums'
import type { TopNavTabDef } from './definitions'
import { TopNavTabId } from './enums'
import TopNavTab from './TopNavTab'

interface Props {
  tabs: TopNavTabDef[]
  activeTabId?: TopNavTabId
  onClickTab: (id: TopNavTabId) => void
}

const topTabIdToPurchaseListScopeMapping: [TopNavTabId, PurchaseListScope][] = [
  [TopNavTabId.ACTIVE_PURCHASES, PurchaseListScope.ACTIVE],
  [TopNavTabId.ACTIVE_PURCHASES, PurchaseListScope.FAVORITES],
  [TopNavTabId.PENDING_PURCHASES, PurchaseListScope.PENDING],
  [TopNavTabId.RECEIVED_PURCHASES, PurchaseListScope.RECEIVED],
  [TopNavTabId.TRANSFERRED_PURCHASES, PurchaseListScope.TRANSFERRED],
  [TopNavTabId.TRANSFERRED_PURCHASES, PurchaseListScope.SENT],
  // [TopNavTabId.TRANSFERRED_PURCHASES, PurchaseListScope.RECEIVED],
  [TopNavTabId.ARCHIVED_PURCHASES, PurchaseListScope.ARCHIVED],
]

export const getPurchaseListScopeFromTopNavTabId = (tabId: TopNavTabId): PurchaseListScope => {
  const row = topTabIdToPurchaseListScopeMapping.find(row => row[0] === tabId)
  if (!row) {
    throw new Error(`Invalid topNavTabId given: ${tabId}`)
  }
  return row[1]
}

export const getTopNavTabIdFromPurchaseListScope = (scope: PurchaseListScope): TopNavTabId => {
  const row = topTabIdToPurchaseListScopeMapping.find(row => row[1] === scope)
  if (!row) {
    throw new Error(`Invalid scope given: ${scope}`)
  }
  return row[0]
}

const TopNavBar: React.FC<Props> = (props): JSX.Element => {
  const {
    tabs,
    activeTabId,
    onClickTab,
  } = props
  const renderedTabs = tabs
    .filter((tab) => tab.show !== false)
    .map((tab) => (
      <TopNavTab
        key={tab.id}
        id={tab.id}
        label={tab.label}
        icon={tab.icon}
        details={tab.details}
        isActive={tab.id === activeTabId}
        onClick={onClickTab}
      />
    ))
  return (
    <div className='top-nav-bar'>
      {renderedTabs}
    </div>
  )
}

export default TopNavBar
