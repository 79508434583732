import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import type ReactPDF from '@react-pdf/renderer'
import { StyleSheet, View } from '@react-pdf/renderer'

import OrderedList from './elements/OrderedList'
import Span from './elements/Span'
import Strong from './elements/Strong'
import Text from './elements/Text'
import TextParagraph from './elements/TextParagraph'
import UnorderedList from './elements/UnorderedList'

type Props = {
  htmlText: string | undefined
  styles?: ReactPDF.Styles
}

// export const htmlParser = (instructions: string | undefined): React.Component<ViewProps> | undefined => {
const Html: React.FC<Props> = (props): JSX.Element | null => {
  console.log('Html.render called.', { props })
  const { htmlText, styles } = props

  if (!htmlText) {
    return null
  }

  // For style overrides, create a StyleSheet alongside the HTML Component with identical property names
  const activeStyles = StyleSheet.create(
    {
      htmlWrapper: { display: 'flex', flexDirection: 'column' },
      h1: { fontSize: 10, marginLeft: 0, marginBottom: 2, fontWeight: 'bold' },
      p: { fontSize: 10, marginBottom: 3 },
      ol: { display: 'flex', flexDirection: 'column', marginBottom: 3, marginRight: 2 },
      ul: { display: 'flex', flexDirection: 'column', marginBottom: 3, marginRight: 2 },
      li: { fontSize: 10, marginBottom: 3 },
      ...styles,
    },
  )

  const parsedHtml = ReactHtmlParser(htmlText.trim())
  const renderedElements: (JSX.Element | null)[] = parsedHtml.map((element, idx: number): JSX.Element | null => {
    // console.log('Html.render: found element.', { element })
    switch (element.type) {
      case 'p':
        // console.log('Html.render: found a <p>.', { element })
        return (
          <TextParagraph
            htmlElement={element}
            style={activeStyles.p}
            key={idx}
          />
        )
      case 'span':
        // console.log('Html.render: found a <span>.', { element })
        return (
          <Span
            htmlElement={element}
            key={idx}
          />
        )
      case 'strong':
        // console.log('Html.render: found a <strong>.', { element })
        return (
          <Strong
            htmlElement={element}
            key={idx}
          />
        )
      case 'h1':
        // console.log('Html.render: found a <h1>.', { element })
        return (
          <Text
            htmlElement={element}
            style={activeStyles.h1}
            key={idx}
          />
        )
      case 'ol':
        // console.log('Html.render: found a <ol>.', { element })
        return (
          <OrderedList
            htmlElement={element}
            style={activeStyles.ul}
            itemStyle={activeStyles.li}
            key={idx}
          />
        )
      case 'ul':
        // console.log('Html.render: found a <ul>.', { element })
        return (
          <UnorderedList
            htmlElement={element}
            style={activeStyles.ul}
            itemStyle={activeStyles.li}
            key={idx}
          />
        )
    }
    return null
  })

  return (
    <View style={activeStyles.htmlWrapper}>
      {renderedElements}
    </View>
  )
}

export default Html
