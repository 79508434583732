import React from 'react'
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
} from '@ionic/react'
import {
  cloudUpload,
  cog,
  constructOutline,
  informationCircle,
  key,
  logOut,
  logOutOutline,
  personAdd,
  pricetag,
  shareSocial,
  reader,
} from 'ionicons/icons'
import { useApolloClient } from '@apollo/client'

import { AppRoute } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import appVersion from '../../version'
import authService from '../../services/auth'
import coreHelpers from '../../lib/core/helpers'

type Props = {
  userIsAdmin: boolean;
  isSignedIn: boolean;
  onNavigateTo: (route: string) => void;
  onSignOut: () => void;
  onClose: () => void;
};

const AppMenu: React.FC<Props> = (props): JSX.Element | null => {
  const {
    userIsAdmin,
    isSignedIn,
    onNavigateTo,
    onSignOut,
    onClose,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { getProxyUser } = useGlobalCache()
  const { activeUser, reloadActiveUser } = useMimbleData()
  const userCanBuyMimbleTokens = coreHelpers.models.user.canBuyMimbleTokens(activeUser)

  // ===================================================================================================================
  // Event Handlers:
  const handleOnNavigateTo = (route: string) => {
    onNavigateTo(route)
    onClose()
  }

  const handleOnSignOut = () => {
    onSignOut()
    onClose()
  }

  const onClearSignInAs = (): void => {
    authService.clearSignInAsUser(apolloClient)
    setTimeout(() => {
      reloadActiveUser().then(() => {
        handleOnNavigateTo(AppRoute.HOME)
        onClose()
      }, (error) => {
        console.error(error)
      })
    }, 500)
  }

  // ===================================================================================================================
  // Rendering:
  type ItemDef = {
    title: string;
    path?: AppRoute | string;
    icon: string;
    show?: boolean;
    handler?: () => void;
  }

  const renderListItems = (list: ItemDef[]): JSX.Element[] => {
    return list
      .filter(p => p.show === undefined || p.show === true)
      .map((p): JSX.Element => {
        const clickHandler = (): void => {
          if (p.handler) {
            p.handler()
          } else if (p.path) {
            handleOnNavigateTo(p.path)
          }
        }
        return (
          <IonMenuToggle key={p.title} auto-hide='false'>
            <IonItem detail lines='none' button onClick={clickHandler}>
              <IonIcon slot='start' icon={p.icon} />
              <IonLabel>{p.title}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        )
      })
  }

  const items: JSX.Element[] = renderListItems(
    [
      { title: 'Buy Mimble Tokens', path: AppRoute.BUY_MIMBLE_TOKENS, icon: pricetag, show: userCanBuyMimbleTokens },
      { title: 'Upload Gift Card', path: '/select-brand/upload-card', icon: cloudUpload, show: isSignedIn },
      // { title: 'My Wallet', path: AppRoute.WALLET, icon: wallet, show: isSignedIn },
      // { title: 'Messages', path: AppRoute.CONTACTS, icon: chatbubbles, show: isSignedIn },
      { title: 'Invite a Friend', path: AppRoute.NEW_INVITATION, icon: shareSocial, show: isSignedIn },
      { title: 'Account & Settings', path: AppRoute.USER_ACCOUNT, icon: cog, show: isSignedIn },
      { title: 'My Wish List', path: `${AppRoute.USER_ACCOUNT}/wish-list`, icon: reader, show: isSignedIn },
      { title: 'Sign Out', handler: handleOnSignOut, icon: logOut, show: isSignedIn },
      { title: 'Sign In', path: AppRoute.SIGN_IN, icon: key, show: !isSignedIn },
      { title: 'Create Free Account', path: AppRoute.JOIN, icon: personAdd, show: !isSignedIn },
      { title: 'Clear Sign-In-As', handler: onClearSignInAs, icon: logOutOutline, show: !!getProxyUser(), signedIn: true },
      { title: 'Admin Console', path: AppRoute.ADMIN_HOME, icon: constructOutline, show: userIsAdmin },
      { title: `About Mimble${appVersion ? ' v.' + appVersion : ''}`, path: AppRoute.ABOUT, icon: informationCircle },
    ].filter((i) => i.show !== false),
  )

  return (
    <div className='menu'>
      <IonList>
        {items}
      </IonList>
    </div>
  )
}

export default AppMenu
