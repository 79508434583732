// import serviceData from './serviceData'
// import isNewAppWaiting from './isNewAppWaiting'

export default (): void => {
/*
  const workbox = serviceData.getWorkbox()
  const serviceWorkerRegistration = serviceData.getServiceWorkerRegistration()

  console.log('versionUtils.upgradeApp called.', { workbox, serviceWorkerRegistration })

  if (!isNewAppWaiting()) {
    console.log('versionUtils.upgradeApp: No new app version waiting.')
    return
  }

  if (!workbox) {
    console.log('versionUtils.upgradeApp: No workbox available.')
    return
  }

  // Assuming the user accepted the update, set up a listener
  // that will reload the page as soon as the previously waiting
  // service worker has taken control.
  workbox.addEventListener('controlling', () => {
    console.log('versionUtils.upgradeApp: workbox.controlling event called; reloading app.')
    window.location.reload()
  })

  // Send a message telling the service worker to skip waiting.
  // This will trigger the `controlling` event handler above.
  // Note: for this to work, you have to add a message
  // listener in your service worker. See below.
  // console.log('versionUtils.upgradeApp: sending SKIP_WAITING to workbox.');
  // workbox.messageSW({ type: 'SKIP_WAITING' }).then((response) => {
  //   console.error('versionUtils.upgradeApp: success response from workbox.messageSW', { response });
  // }, (error) => {
  //   console.error('Error received from workbox.messageSW', { error });
  // });

  if (serviceWorkerRegistration && serviceWorkerRegistration.waiting) {
    console.log('versionUtils.upgradeApp: calling serviceWorkerRegistration.waiting.postMessage.')
    workbox.messageSW({ type: 'SKIP_WAITING' })
    // serviceWorkerRegistration.waiting.postMessage('SKIP_WAITING');
  }
*/
}
