import type { AppFeature } from '../../../enums'

const getAppFeaturesAsArray = (appFeatures: AppFeature[] | string | null | undefined): AppFeature[] => {
  if (!appFeatures) {
    return []
  }
  if (Array.isArray(appFeatures)) {
    return appFeatures
  }
  return appFeatures.split(',') as AppFeature[]
}

export default getAppFeaturesAsArray
