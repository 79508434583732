import React, { useEffect, useState } from 'react'
import { close, megaphoneOutline } from 'ionicons/icons'
import { IonButton, IonIcon, IonModal } from '@ionic/react'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import { AppAction } from '../../lib/core/enums'
import type { MemberMessage } from '../../lib/core/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import coreHelpers from '../../lib/core/helpers'
import reportToServer from './reportToServer'
import SubmitButton from '../SubmitButton/SubmitButton'

type Props = {
  memberMessage?: MemberMessage | null
  onAction: (action: AppAction) => void
  onClose?: () => void
}

const InAppMessageModal: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('InAppMessageModal.render', { props })
  const {
    memberMessage: memberMessageFromProps,
    onAction,
    onClose,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const {
    activeUser,
    unreadInAppMessages,
    reloadUnreadInAppMessages,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const unreadInAppMessageCount = Array.isArray(unreadInAppMessages) && unreadInAppMessages.length > 0
    ? unreadInAppMessages.length
    : 0

  let memberMessage: MemberMessage | null | undefined = memberMessageFromProps
  if (!memberMessageFromProps) {
    const memberMessageFromContext = Array.isArray(unreadInAppMessages) && unreadInAppMessages.length > 0
      ? unreadInAppMessages[0]
      : undefined
    if (memberMessageFromContext) {
      memberMessage = memberMessageFromContext
    }
  }
//   memberMessage = {
//     __typename: 'MemberMessage',
//     action0: AppAction.PURCHASE,
//     action1: AppAction.OPEN_CONTACTS,
//     action2: null,
//     actionTaken: null,
//     adminNotes: null,
//     appLink: null,
//     asyncTaskId: null,
//     campaignId: null,
//     chatMessageId: null,
//     contactEventReminderId: null,
//     context: null,
//     createdAt: '1638723925596',
//     emailSendReport: null,
//     emailSentAt: null,
//     fromUserId: null,
//     htmlMessage: `<p>
//   Thank you for your MIT purchase!
// </p>
// <p>
//   The MITs you purchased have been added to your wallet. You can use them now in the marketplace, or send them to another Mimble member.
// </p>
// <p>
//   To send them to another member, open the Messages page for this contact.
//   Now tap on the paperclip icon next to the message text input. Select "Send Mimble Tokens".
// </p>`,
//     id: '8a34ee6c-55ed-11ec-b8dd-ff1884e08569',
//     inAppMessageReceivedAt: null,
//     inAppMessageSentAt: '1638723926809',
//     invitationId: null,
//     messageText: null,
//     metadata: null,
//     purchaseId: '89b89fce-55ed-11ec-96ad-070923e868a9',
//     purchaseTransferId: null,
//     pushNotificationSendReport: 'Error: 1262eaacb90211eb9123d3771e273b98: SenderId mismatch',
//     pushNotificationSentAt: '1638723927263',
//     recordStatus: RecordStatus.ACTIVE,
//     rewardId: null,
//     sendChatMessage: false,
//     sendEmail: true,
//     sendInAppMessage: true,
//     sendPushNotification: true,
//     sendSms: true,
//     sentMessagesCount: 0,
//     shortMessageText: null,
//     smsSendReport: null,
//     smsSentAt: null,
//     templateId: '38442c4c-3b05-11ec-bd39-875da1b65812',
//     title: 'MITs Now Available!',
//     toUserId: '78204570-c16d-11ea-be5a-6366e298b248',
//     updatedAt: '1638723927263',
//     updatedBy: null,
//   }

  const [show, setShow] = useState(true)

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (unreadInAppMessageCount > 0 && !show) {
      setShow(true)
    }
  }, [unreadInAppMessageCount])

  useEffect(() => {
    if (memberMessageFromProps && !show) {
      setShow(true)
    }
  }, [memberMessageFromProps])

  // ===================================================================================================================
  // Helpers:
  const handleActionButtonClick = () => {
    if (memberMessage && memberMessage.action0) {
      setShow(false)
      if (onClose) {
        onClose()
      }
      reportToServer(
        memberMessage,
        memberMessage.action0,
        activeUserId,
        reloadUnreadInAppMessages,
        apolloClient,
      )
      onAction(memberMessage.action0)
    }
  }

  // ===================================================================================================================
  // Event Handlers:
  const handleDidDismiss = (): void => {
    if (memberMessage && memberMessage.id) {
      reportToServer(
        memberMessage,
        undefined,
        activeUserId,
        reloadUnreadInAppMessages,
        apolloClient,
      )
    }
  }

  const handleCloseButton = (): void => {
    setShow(false)
    if (onClose) {
      onClose()
    }
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('InAppMessageModal.render', {
  //   unreadInAppMessages,
  //   unreadInAppMessageCount,
  //   memberMessage,
  // })

  if (!memberMessage || !memberMessage.title || !memberMessage.htmlMessage) {
    return null
  }

  let actionButtons: JSX.Element[] | undefined
  if (memberMessage && memberMessage.action0) {
    actionButtons = [
      memberMessage.action0,
      memberMessage.action1,
      memberMessage.action2,
    ]
      .filter(a => a)
      .map((action) => (
        <SubmitButton
          key={action}
          onClick={handleActionButtonClick}
          className='withStandardRightMargin'
        >
          {coreHelpers.type.appAction.getLabel(action) || 'Got it!'}
        </SubmitButton>
      ))
  }

  let body: JSX.Element | undefined
  if (memberMessage.htmlMessage.startsWith('<')) {
    body = (
      <div
        className='body-text'
        dangerouslySetInnerHTML={{ __html: memberMessage.htmlMessage as string }}
      />
    )
  } else {
    body = (
      <div className='body-text'>
        {memberMessage.htmlMessage.split('\n').map((item, key) => {
          return <span key={key}>{item}<br /></span>
        })}
      </div>
    )
  }

  let title: JSX.Element | undefined
  if (memberMessage.title) {
    title = (
      <div className='title'>
        {memberMessage.title}
      </div>
    )
  }

  return (
    <IonModal
      isOpen={show}
      cssClass='in-app-message-modal'
      backdropDismiss
      swipeToClose
      onDidDismiss={handleDidDismiss}
    >
      <div className='modal-content'>
        <div className='header'>
          <div className='announce-icon-wrapper'>
            <IonIcon icon={megaphoneOutline} className='announce-icon' />
          </div>
          <div>
            <IonButton
              fill='clear'
              onClick={handleCloseButton}
            >
              <IonIcon icon={close} className='close-button' />
            </IonButton>
          </div>
        </div>
        <div className='content'>
          {title}
          {body}
          <div className='form-button-wrapper'>
            {actionButtons}
            <IonButton
              fill='clear'
              onClick={handleCloseButton}
            >
              Close
            </IonButton>
          </div>
        </div>
      </div>
    </IonModal>
  )
}

export default InAppMessageModal
