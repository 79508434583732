import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  DeleteWishMutationData,
  DeleteWishMutationVariables,
} from '../apollo/definitions'
import type { ApiQueryOptions, Wish } from '../../lib/core/definitions'
import apollo from '../apollo'
import cache from './cache'
import coreHelpers from '../../lib/core/helpers'
import logger from '../logger'

const deleteWish = (
  wishId: string,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Wish | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.deleteWish called.', { wishId, options })

    if (!apolloClient) {
      logger.error('api.deleteWish: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<DeleteWishMutationData, DeleteWishMutationVariables> = {
      mutation: apollo.mutations.deleteWish,
      variables: { wishId },
    }

    apolloClient.mutate<DeleteWishMutationData, DeleteWishMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.deleteWish: apollo responded.', { resp })
        if (
          !resp ||
          !resp.data ||
          !resp.data.deleteWish ||
          !resp.data.deleteWish.id
        ) {
          logger.error('api.deleteWish: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }
        if (coreHelpers.models.compareId(resp.data.deleteWish.userId, activeUserId)) {
          // console.log('api.deleteWish: deleting active users wish in cache.', { resp })
          cache.deleteActiveUserWish(wishId, activeUserId, apolloClient)
        } else if (resp.data.deleteWish.contactId) {
          // console.log('api.deleteWish: deleting contacts gift idea in cache.', { resp })
          cache.deleteContactWish(
            wishId,
            resp.data.deleteWish.contactId,
            apolloClient,
          )
        }
        // console.log('api.deleteWish: mutation succeeded.', resp)
        resolve(resp.data.deleteWish)
      }, (error) => {
        logger.error('api.deleteWish: error', { error })
        reject(error)
      })
  })
)

export default deleteWish
