import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IonInput, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import type { ApolloClient } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import { AppRoute } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import apolloService from '../../services/apollo'
import coreHelpers from '../../lib/core/helpers'
import FormItem from '../../components/FormItem/FormItem'
import logger from '../../services/logger'
import pageHelpers from '../../helpers/pageHelpers'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../helpers/validationHelpers'

const SANDBOX_ACCOUNTS = [
  'appletester',
  'tester@apple.com',
  'googletester',
  'tester@google.com',
]

interface Props {
  onNavigate: (route: AppRoute | string, resetHistory?: boolean) => void;
  onSwitchToCodeSignInMethod: () => void;
  showUiMessage: (message: string) => void;
}

const PasswordForm: React.FC<Props> = (props): JSX.Element => {
  // const navigate = useNavigate()

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  const {
    onNavigate,
    onSwitchToCodeSignInMethod,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { getEnvironment, setEnvironment, setUnauthorizedRequestReportedByMimbleApi } = useGlobalCache()
  const { reloadActiveUser } = useMimbleData()

  const [ident, setIdent] = useState<string | undefined>()
  const [identValidationError, setIdentValidationError] = useState<string | undefined>()
  const identRef = useRef<HTMLIonInputElement>(null)

  const [password, setPassword] = useState<string | undefined>()
  const [passwordValidationError, setPasswordValidationError] = useState<string | undefined>()
  const passwordRef = useRef<HTMLIonInputElement>(null)

  const [autoFillInputs, setAutoFillInputs] = useState<string[]>([])

  const [isProcessing, setIsProcessing] = useState(false)

  const isValid = (
    (!!ident || autoFillInputs.includes('ident')) &&
    (!!password || autoFillInputs.includes('password')) &&
    !identValidationError &&
    !passwordValidationError
  )

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewDidEnter(() => {
    pageHelpers.setAutoFillStatus(['ident', 'password'], setAutoFillInputs)
  })

  useIonViewDidLeave(() => {
    setIdent(undefined)
    setIdentValidationError(undefined)
    setPassword(undefined)
    setPasswordValidationError(undefined)
  })

  // ===================================================================================================================
  // Event Handlers:
  const onChangeIdent = (event: any): void => {
    // console.log('SignInPage.onChangeIdent called.', { event })
    const val = event.detail.value || pageHelpers.getFormInputValue(identRef)
    setIdent(val)
    if (val) {
      setIdentValidationError(validationHelpers.validateUserIdent(val))
    }
  }

  const onBlurIdent = (): void => {
    if (!ident && !pageHelpers.getFormInputValue(identRef)) {
      setIdentValidationError('required')
    }
  }

  const onChangePassword = (event: any): void => {
    // console.log('onChangePassword called', event.detail.value)
    setPassword(event.detail.value)
    if (!event.detail.value) {
      return
    }
    setPasswordValidationError(validationHelpers.validatePassword(event.detail.value))
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onBlurPassword = (): void => {
    if (!password && !pageHelpers.getFormInputValue(passwordRef)) {
      setPasswordValidationError('required')
    }
  }

  const onSubmit = (event: any): void => {
    event.preventDefault()
    // console.log('SignUpForm.onSubmitPasswordForm: called.', { ident, password })

    if (!ident) {
      setIdentValidationError('required')
      return
    }
    if (!password) {
      setPasswordValidationError('required')
      return
    }

    const userInput = { ident, password }

    if (userInput.ident && coreHelpers.ui.isPhoneNumber(userInput.ident)) {
      try {
        const phoneNumberInfo = parsePhoneNumber(userInput.ident, 'US')
        if (phoneNumberInfo && phoneNumberInfo.isValid()) {
          userInput.ident = phoneNumberInfo.number as string
        } else {
          logger.warn('SignInPage.onSubmitPasswordForm: invalid phone number given.', { ident: userInput.ident })
        }
      } catch (error) {
        logger.warn('SignInPage.onSubmitPasswordForm: invalid phone number given.', { ident: userInput.ident, error })
      }
    }

    if (!userInput.ident || !userInput.password) {
      showUiMessage('Invalid input. Please correct and try again.')
      return
    }

    setIsProcessing(true)
    setUnauthorizedRequestReportedByMimbleApi(false)

    const callHandleSignIn = (apolloClientForThisRequest: ApolloClient<any>) => {
      pageHelpers.handleSignIn({
        ident: userInput.ident,
        password: userInput.password,
        apolloClient: apolloClientForThisRequest,
        reloadActiveUser,
        navigate,
      }).then(() => {
        setIsProcessing(false)
        onNavigate(AppRoute.HOME, true)
      }, (error) => {
        logger.error('SignInPage: received error.', { error })
        setIsProcessing(false)
        showUiMessage('Failed to sign you in. Please check your input and try again.')
      })
    }

    // We'll switch to the sandbox for the Apple/Google Review tester:
    if (
      SANDBOX_ACCOUNTS.includes(ident) &&
      getEnvironment() !== 'sandbox'
    ) {
      setEnvironment('sandbox', () => {
        const apolloClientForThisRequest = apolloService.getApolloClient('sandbox')
        callHandleSignIn(apolloClientForThisRequest || apolloClient)
      })
    } else {
      callHandleSignIn(apolloClient)
    }
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <form className='password-form' onSubmit={onSubmit}>
      <FormItem
        label='Handle/Email/Phone Number'
        validationError={identValidationError}
        withBottomMargin
      >
        <IonInput
          ref={identRef}
          id='ident'
          name='ident'
          autofocus
          value={ident}
          placeholder='i.e. @JoeTheSailor or joe-schmidt@supermail.net or 1234567890'
          required
          autocomplete='username'
          onIonChange={onChangeIdent}
          onIonBlur={onBlurIdent}
          onIonInput={onChangeIdent}
          // onInput={(event: any) => { console.log('SignInPage.onInput[ident] called.', event.detail.value) }}
          // onIonFocus={enableInputs}
        />
      </FormItem>
      <FormItem
        label='Password'
        validationError={passwordValidationError}
        withBottomMargin
      >
        <IonInput
          ref={passwordRef}
          id='password'
          name='password'
          type='password'
          value={password}
          placeholder='password'
          minlength={8}
          maxlength={30}
          required
          onIonChange={onChangePassword}
          onIonBlur={onBlurPassword}
          // onInput={(event: any) => { console.log('SignInPage.onInput[password] called.', event.detail.value) }}
          // onIonFocus={enableInputs}
          // readonly={readonlyInputs}
          autocomplete='current-password'
        />
      </FormItem>
      <div className='linkText withCenteredTextAlign withDoubleBottomMargin' onClick={() => onSwitchToCodeSignInMethod()}>
        Forgot password?
      </div>
      <div className='formButtonWrapper'>
        <SubmitButton
          expand='block'
          disabled={!isValid || isProcessing}
          isProcessing={isProcessing}
          onClick={onSubmit}
        >
          Sign In
        </SubmitButton>
      </div>
    </form>
  )
}

export default PasswordForm
