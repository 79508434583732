import React from 'react'
import { IonButton } from '@ionic/react'

import { GiftFlowStep } from '../../enums'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import type { Purchase } from '../../lib/core/definitions'
import GiftCard from '../../components/GiftCard/GiftCard'
import GiftFlowStepper from '../../components/GiftFlowStepper/GiftFlowStepper'
import pageHelpers from '../../helpers/pageHelpers'

type Props = {
  purchase: Purchase | null | undefined
  onOpenPurchase: () => void
  onSendGift: () => void
  onNavigateToGiftFlow: () => void
};

const ForMarketplaceProduct: React.FC<Props> = (props): JSX.Element | null => {
  const {
    purchase,
    onOpenPurchase,
    onSendGift,
    onNavigateToGiftFlow,
  } = props

  // ===================================================================================================================
  // State:
  const {
    flowId: giftFlowId,
    giftFlowStep,
    giftChanges,
    setGiftChanges,
  } = useGiftFlow()
  // console.log('CheckoutPaymentReceivedPage.ForMarketplaceProduct: rendering', { giftFlowId, giftFlowStep, giftChanges })

  // ===================================================================================================================
  // Event Handlers:
  const onUsePurchaseForGiftFlow = () => {
    if (!purchase) {
      return
    }
    setGiftChanges({
      ...giftChanges,
      fromPurchaseId: purchase.id,
    }, true)
    onNavigateToGiftFlow()
  }

  // ===================================================================================================================
  // Rendering:
  let giftCardHeader: JSX.Element | undefined
  if (purchase) {
    const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromPurchase(purchase)
    const giftCardCssClass = 'giftCardOuterWrapper with75PercentWidth withMaxWidth400px'

    giftCardHeader = (
      <div className='withCenteredColumnContent withDoubleBottomMargin'>
        <div className={giftCardCssClass}>
          <GiftCard
            purchaseId={purchase.id as string}
            backgroundImageUri={giftCardBackgroundUri}
            merchantLogoUri={merchantLogoUri}
          />
        </div>
      </div>
    )
  }
  if (giftFlowId && giftFlowStep === GiftFlowStep.GIFT) {
    return (
      <div className='g-with-safe-padding'>
        {giftCardHeader}
        <GiftFlowStepper
          size='large'
          className='withStandardTopMargin'
          parent='marketplace'
        />
        <h2>Thank you!</h2>
        Your payment has been received.
        The gift is available now.
        <div className='withStandardTopMargin'>
          <div key='activateFormButtons' className='formButtonWrapper'>
            <IonButton
              onClick={onUsePurchaseForGiftFlow}
            >
              Next
            </IonButton>
          </div>
        </div>
      </div>
    )
  }

  // console.log('PaymentReceivedForMarketplaceProductPurchase.render called.', { props });
  return (
    <div className='g-with-safe-padding'>
      {giftCardHeader}
      <h2>Thank you!</h2>
      Your payment has been received.
      You can now use your new gift card.
      Or you can send it to a friend as a gift!
      <div className='withStandardTopMargin'>
        <div key='activateFormButtons' className='formButtonWrapper'>
          <IonButton
            onClick={onOpenPurchase}
          >
            View Card
          </IonButton>
          <IonButton
            className='withStandardRightMargin'
            onClick={onSendGift}
          >
            Gift
          </IonButton>
        </div>
      </div>
    </div>
  )
}

export default ForMarketplaceProduct
