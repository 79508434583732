import type { ApolloClient } from '@apollo/client'
import type { Product } from '../../lib/core/definitions'
import api from '../../services/api'

const loadProduct = async (
  productId: string,
  apolloClient: ApolloClient<any>,
): Promise<Product | null | undefined> => {
  if (productId) {
    try {
      return api.loadProduct(
        productId,
        undefined,
        undefined,
        apolloClient,
        {
          includeMerchant: true,
          includeProductOptions: true,
        },
      )
    } catch (error) {
      console.error(error)
    }
  }
}

export default loadProduct
