import React, { useState } from 'react'
import type { GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro'
import { DataGridPro } from '@mui/x-data-grid-pro'

import './styles.css'
import type { LogEntry } from '../../../services/logger'
import logger from '../../../services/logger'
import pageHelpers from '../../../helpers/pageHelpers'

type Props = {
};

const LogHistory: React.FC<Props> = (props): JSX.Element | null => {
  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // State:
  const [showDetailInfoAlert, setShowDetailInfoAlert] = useState(false)
  const [selectedLogEntry, setSelectedLogEntry] = useState<LogEntry | undefined>()

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (param: GridRowParams): void => {
    const timestamp = param.getValue(param.id, 'timestamp') as number
    const level = param.getValue(param.id, 'level') as 'debug' | 'info' | 'error'
    const message = param.getValue(param.id, 'message') as string
    const data = param.getValue(param.id, 'data') as string

    if (selectedLogEntry && timestamp === selectedLogEntry.timestamp) {
      setSelectedLogEntry(undefined)
      setShowDetailInfoAlert(false)
      return
    }

    setSelectedLogEntry({ timestamp, level, message, data })
    setShowDetailInfoAlert(true)
  }

  const onCopyMessageToClipboard = (): void => {
    if (!selectedLogEntry || !selectedLogEntry.message) {
      return
    }
    pageHelpers.copyInputTextToClipboard(
      selectedLogEntry.message,
      'Log message',
    )
  }

  const onCopyDataToClipboard = (): void => {
    if (!selectedLogEntry || !selectedLogEntry.data) {
      return
    }
    pageHelpers.copyInputTextToClipboard(
      selectedLogEntry.data,
      'Log data',
    )
  }

  // ===================================================================================================================
  // Rendering:
  const columns: GridColumns = [
    { field: 'timestamp', width: 0 },
    { field: 'level', flex: 1 },
    { field: 'message', headerName: 'Message', flex: 5 },
    { field: 'data', headerName: 'Data', flex: 10 },
  ]

  const rows: GridRowsProp = logger.history.map((entry, index) => (
    {
      id: index,
      timestamp: entry.timestamp,
      level: entry.level,
      message: entry.message,
      data: JSON.stringify(entry.data, undefined, 2),
    }
  ))

  let detailSection: JSX.Element | undefined
  if (showDetailInfoAlert && selectedLogEntry) {
    detailSection = (
      <div className='detail-section'>
        <div className='withBoldText'>Timestamp</div>
        <div className='withDoubleBottomMargin'>{new Date(selectedLogEntry.timestamp).toISOString()}</div>

        <div className='withBoldText'>Level</div>
        <div className='withDoubleBottomMargin'>{selectedLogEntry.level}</div>

        <div className='withBoldText' onClick={onCopyMessageToClipboard}>Message</div>
        <div className='withDoubleBottomMargin' onClick={onCopyMessageToClipboard}>{selectedLogEntry.message}</div>

        <div className='withBoldText' onClick={onCopyDataToClipboard}>Data</div>
        <div className='data-display' onClick={onCopyDataToClipboard}>{selectedLogEntry.data}</div>
      </div>
    )
  }

  return (
    <div className='log-history'>
      <div className='data-grid'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          onRowClick={onRowClick}
          // style={{ height: '100%' }}
        />
      </div>
      {detailSection}
    </div>
  )
}

export default LogHistory
