import { IonInput, IonIcon, IonSelect, IonSelectOption } from '@ionic/react'
import { search } from 'ionicons/icons'
import React from 'react'

import './styles.css'
import { StoredProductListScope } from '../../../lib/core/enums'

type Props = {
  searchText?: string;
  scope: StoredProductListScope;
  isProcessing?: boolean;
  onApply: (searchText: string, scope: StoredProductListScope) => void;
};

const StoredProductListHeader: React.FC<Props> = (props): JSX.Element => {
  const {
    searchText,
    scope,
    isProcessing,
    onApply,
  } = props
  // console.log('ProductListHeader.render called.', props);
  // const isProcessing = isGettingStoredProducts || isGettingProduct;

  // ===================================================================================================================
  // State:

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Product List:

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSearchText = (event: any): void => {
    // console.log('onChangeShowActive called', event)
    onApply(event.detail.value, scope)
  }

  const onClearFilter = (): void => {
    onApply('', scope)
  }

  const onChangeScope = (event: any): void => {
    onApply(searchText || '', event.detail.value)
  }

  // ===================================================================================================================
  // Rendering:
  const scopeOptions = Object.values(StoredProductListScope).map((sc) => (
    <IonSelectOption
      key={sc}
      value={sc}
    >
      {sc}
    </IonSelectOption>
  ))

  return (
    <div className='rowWithCenterAlignedItems withStandardLeftMargin withStandardTopMargin withStandardRightMargin'>
      <div className='searchBarWrapper'>
        <IonIcon icon={search} class='searchBarSearchIcon' />
        <IonInput
          value={searchText}
          placeholder='search'
          onIonChange={onChangeSearchText}
          className='searchBar'
          clearInput
        />
      </div>
      <IonSelect
        value={scope}
        interface='popover'
        onIonChange={onChangeScope}
      >
        {scopeOptions}
      </IonSelect>

    </div>
  )
}

export default StoredProductListHeader
