import { gql } from '@apollo/client'

export default gql`
  query Q($filter: StoredProductListFilter!) {
    storedProducts (filter: $filter) {
      id
      productId
      productOptionId
      purchaseId
      importId
      merchantId
      merchantName
      status
      code
      amount
      fundType
      currency
      discount
      expiresAt
      receivedAt
      paidAt
      poNumber
      source
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
