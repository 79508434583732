import React from 'react'

import './styles.css'
import { GiftFlowStep } from '../../enums'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import ConfirmStep from './ConfirmStep'
import FinishedStep from './FinishedStep'
import GiftFlowStepper from '../../components/GiftFlowStepper/GiftFlowStepper'
import GiftStep from './GiftStep/GiftStep'
import logger from '../../services/logger'
import MessageStep from './MessageStep'
import RecipientStep from './RecipientStep/RecipientStep'
import ScheduleStep from './ScheduleStep'

type Props = {
  onDiscardFlow: () => void
  onNavigateBack: () => void
  onOpenMarketplace: () => void
  onOpenWallet: () => void
  onOpenEvents: () => void
  onOpenPurchaseTransfer: () => void
  onOpenUploadPurchase: () => void
  showUiMessage: (message: string) => void
}

const SendGiftForm: React.FC<Props> = (props): JSX.Element | null => {
  const {
    showUiMessage,
    onDiscardFlow,
    onNavigateBack,
    onOpenMarketplace,
    onOpenUploadPurchase,
    onOpenWallet,
    onOpenEvents,
    onOpenPurchaseTransfer,
  } = props

  // ===================================================================================================================
  // State:
  const {
    giftFlowStep,
    purchaseTransfer,
    goToNextStep,
    goToPrevStep,
  } = useGiftFlow()

  // const curToUserInfo = getCurToUserInfo()
  // const curMessageText = getCurMessageText && getCurMessageText()
  // const curMessageDeliveryInfo = getCurMessageDeliveryInfo && getCurMessageDeliveryInfo()
  // const curNotificationMethod = curMessageDeliveryInfo && curMessageDeliveryInfo.notificationMethod

  // console.log('SendGiftForm.render called.', {
  //   flowId,
  //   giftFlowStep,
  //   fromPurchaseId,
  //   toUserId,
  //   giftChanges,
  //   fromPurchase,
  //   purchaseTransfer,
  //   curToUserInfo,
  //   curMessageText,
  //   curMessageDeliveryInfo,
  //   curNotificationMethod,
  // })

  // ===================================================================================================================
  // Event Handlers:
  const onPrevStep = (): void => {
    if (!goToPrevStep()) {
      onNavigateBack()
    }
  }

  const onNextStep = (): void => {
    goToNextStep()
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('SendGiftForm.render called.', {
  //   flowId,
  //   giftFlowStep,
  //   fromPurchaseId,
  //   purchaseTransferId,
  //   toUserId,
  //   giftChanges,
  //   fromPurchase,
  //   purchaseTransfer,
  //   updatedPurchaseTransfer,
  // })

  // let giftFlowStep2: GiftFlowStep | undefined
  // if (new Date().getTime()) {
  //   giftFlowStep2 = GiftFlowStep.GIFT
  // }

  let stepContent: JSX.Element | undefined
  switch (giftFlowStep) {
    case GiftFlowStep.RECIPIENT:
      stepContent = (
        <RecipientStep
          onPrevStep={onPrevStep}
          onNextStep={onNextStep}
        />
      )
      break
    case GiftFlowStep.GIFT:
      stepContent = (
        <GiftStep
          onOpenMarketplace={onOpenMarketplace}
          onOpenUploadPurchase={onOpenUploadPurchase}
          onPrevStep={onPrevStep}
          onNextStep={onNextStep}
        />
      )
      break
    case GiftFlowStep.SCHEDULE:
      stepContent = (
        <ScheduleStep
          onPrevStep={onPrevStep}
          onNextStep={onNextStep}
        />
      )
      break
    case GiftFlowStep.MESSAGE:
      stepContent = (
        <MessageStep
          onPrevStep={onPrevStep}
          onNextStep={onNextStep}
        />
      )
      break
    case GiftFlowStep.CONFIRM:
      stepContent = (
        <ConfirmStep
          onPrevStep={onPrevStep}
          onDiscardFlow={onDiscardFlow}
          onNavigate={onOpenPurchaseTransfer}
        />
      )
      break
    case GiftFlowStep.FINISHED:
      if (purchaseTransfer) {
        stepContent = (
          <FinishedStep
            onOpenWallet={onOpenWallet}
            onOpenEvents={onOpenEvents}
            onOpenPurchase={onOpenPurchaseTransfer}
            showUiMessage={showUiMessage}
          />
        )
      } else {
        logger.error('SendGiftForm: no purchaseTransfer for FinishedStep.')
      }
      break
    default:
      stepContent = (
        <RecipientStep
          onPrevStep={onPrevStep}
          onNextStep={onNextStep}
        />
      )
  }

  return (
    <div className='send-gift-form'>
      <GiftFlowStepper
        size='large'
        parent='send-gift-page'
        className='withStandardTopMargin withStandardBottomMargin'
      />
      {stepContent}
    </div>
  )
}

export default SendGiftForm
