export enum AppTabId {
  ADMIN_DASHBOARD = 'admin-dashboard',
  ADMIN_USERS = 'admin-users',
  ADMIN_PRODUCTS = 'admin-products',
  ADMIN_MERCHANTS = 'admin-merchants',
  EXIT_ADMIN = 'exit-admin',
  WALLET = 'wallet',
  MARKETPLACE = 'marketplace',
  GIFT = 'gift',
  CONTACTS = 'contacts',
  CONTROL_CENTER = 'control-center',
  USER_ACCOUNT = 'user-account',
}

export enum AppTabScope {
  MAIN = 'main',
  UNAUTHENTICATED = 'unauthenticated',
  ADMIN = 'admin',
}
