import React, { useContext, useState } from 'react'
import {
  IonButton,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { useLocation, useHistory } from 'react-router-dom'
import { Update } from 'history'

import './styles.css'
import type { PurchaseListScope } from '../../lib/core/enums'
import { ChatAttachmentType, PurchaseStatus, UiMessage } from '../../lib/core/enums'
import type { ContextMenuOptions } from '../../components/ContextMenu/contextMenuDefs'
import { ContextMenuId, ContextMenuItemId } from '../../components/ContextMenu/contextMenuDefs'
import { getTopNavTabIdFromPurchaseListScope } from '../../components/TopNavBar/TopNavBar'
import { AppPage, PageMessageType, AppRoute } from '../../enums'
import { useApolloClient, useQuery } from '@apollo/client'
import type { ChatsQueryData, ChatsQueryVariables } from '../../services/apollo/definitions'
import type { UserInput, UserPrefs } from '../../lib/core/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import api from '../../services/api'
import coreHelpers from '../../lib/core/helpers'
import DashboardWidgets from './DashboardWidgets/DashboardWidgets'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'

const appPageId = AppPage.DashboardPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined
let lastListRefresh: number
let listTimestamp: number
let isPageActive = false

const DashboardPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { clearSessionData, getIsSignedIn } = useGlobalCache()
  const {
    activeUser,
    activeUserWallets,
    purchases: userPurchases,
    isLoadingActiveUser,
    isLoadingActiveUserWallets,
    isLoadingPurchases,
    isUpdatingActiveUser,
    reloadActiveUser,
    reloadPurchases,
    updateActiveUser,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const pageMessages = useContext(PageMessagesContext)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [forceReRenderId, setForceReRenderId] = useState(0)

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: userChatsData,
    loading: isLoadingUserChats,
    refetch: reloadUserChats,
  } = useQuery<ChatsQueryData, ChatsQueryVariables>(
    apollo.queries.chats, {
      variables: apollo.getChatsQueryVariables(undefined, activeUserId as string),
      skip: !activeUserId,
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
    },
  )
  const userChats = userChatsData ? userChatsData.chats : undefined

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  useIonViewWillEnter(() => {
    // console.log('DashboardPage[useIonViewWillEnter] called.')
    isPageActive = true
  })

  useIonViewWillLeave(() => {
    // console.log('DashboardPage[useIonViewWillLeave] called.')
    isPageActive = false
  })

  // ===================================================================================================================
  // Helpers:
  const isProcessing = (
    isLoadingActiveUser ||
    isLoadingPurchases ||
    isLoadingUserChats ||
    isLoadingActiveUserWallets ||
    isUpdatingActiveUser
  )
  const userCanPurchase = activeUser && coreHelpers.models.user.canPurchase(activeUser)
  // const mimbleWallet = pageHelpers.getUserWallet(activeUser)
  // const userMetadata = activeUser ? activeUser.metadata : undefined

  // ===================================================================================================================
  // Event Handlers:
  const onGetPurchases = (): void => {
    lastListRefresh = new Date().getTime()
    reloadPurchases()
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    lastListRefresh = new Date().getTime()
    const handleError = (error: Error): void => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
      console.error(error)
    }

    reloadActiveUser().then(() => {
      reloadPurchases().then(() => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      }, handleError)
    }, handleError)

    if (reloadUserChats) {
      reloadUserChats().then(undefined, handleError)
    }
  }

  const onOpenPurchase = (purchaseId: string): void => {
    api.loadPurchase(
      purchaseId,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then((purchase) => {
      if (!purchase) {
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.GIFT_CARD_NOT_FOUND)
        return
      }
      if (purchase.status === PurchaseStatus.RECEIVED) {
        navigate(`${AppRoute.GIFT_RECEIVED}/tp:${purchaseId}`)
      } else {
        navigate(`/card/${purchaseId}`)
      }
    })
  }

  const onOpenChat = (chatId: string): void => {
    navigate(`${AppRoute.CONTACT_MESSAGES}/${chatId}`)
  }

  const onOpenSupportChat = (): void => {
    pageMessages && pageMessages.clear()
    navigate(`${AppRoute.CONTACT_MESSAGES}/-/mimble-team`)
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenChatAttachment = (id: string, type: ChatAttachmentType): void => {
    if (type === ChatAttachmentType.PURCHASE) {
      onOpenPurchase(id)
    }
  }

  const onOpenDigitalWallet = (purchaseListScope: PurchaseListScope): void => {
    const scopePath = purchaseListScope ? `/${getTopNavTabIdFromPurchaseListScope(purchaseListScope)}` : ''
    navigate(`${AppRoute.WALLET}${scopePath}`)
  }

  const onOpenContactsPage = (): void => {
    navigate(AppRoute.CONTACTS)
  }

  const onAddUploadedPurchase = (): void => {
    navigate('/select-brand/upload-card')
  }

  const onCreatePurchase = (): void => {
    navigate(AppRoute.MARKETPLACE)
  }

  const onSignIn = (): void => {
    clearSessionData()
    navigate(AppRoute.SIGN_IN, true)
  }

  const onContextMenu = (id: ContextMenuItemId): void => {
    // console.log('Context menu clicked:', id);
    switch (id) {
      case ContextMenuItemId.REFRESH:
        onGetPurchases()
        break
      case ContextMenuItemId.ADD_UPLOADED_PURCHASE:
        onAddUploadedPurchase()
        break
      case ContextMenuItemId.CREATE_PURCHASE:
        onCreatePurchase()
        break
      default:
        console.error('DashboardPage.onContextMenu: Unexpected context menu item received.', id)
    }
  }

  const onAddFundsToWallet = (): void => {
    navigate(AppRoute.BUY_MIMBLE_TOKENS)
  }

  const onUpdateUserPrefs = (prefs: UserPrefs): void => {
    if (!activeUser) {
      console.error('DashboardPage.onUpdateUserPrefs: no activeUser')
      return
    }
    const userInput: UserInput = {
      id: activeUser.id,
      prefs,
    }
    updateActiveUser(userInput)
      .then(() => {
        console.log('Successfully updated user.')
      }, (error) => {
        console.log('Error updating user.', error)
      })
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('Dashboard.render called.', { activeUserContextData })
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content

  if (getIsSignedIn()) {
    content = (
      <DashboardWidgets
        userChats={userChats}
        userPurchases={userPurchases}
        userWallets={activeUserWallets}
        onAddUploadedPurchase={onAddUploadedPurchase}
        onCreatePurchase={onCreatePurchase}
        onAddFundsToWallet={onAddFundsToWallet}
        onOpenChat={onOpenChat}
        onOpenSupportChat={onOpenSupportChat}
        onOpenChatAttachment={onOpenChatAttachment}
        onOpenContactsPage={onOpenContactsPage}
        onOpenDigitalWallet={onOpenDigitalWallet}
        onOpenPurchase={onOpenPurchase}
        onOpenUserAccount={onOpenUserAccount}
        onUpdateUserPrefs={onUpdateUserPrefs}
      />
    )
  } else {
    content = (
      <div className='withColumnDirection'>
        You are currently not signed in.
        <div className='bottomButtonWrapper'>
          <IonButton onClick={onSignIn}>Sign In</IonButton>
        </div>
      </div>
    )
  }

  const contextMenuOptions: ContextMenuOptions = {
    addCreatePurchaseItem: userCanPurchase,
  }

  return (
    <IonPage className='app-page-public dashboard-page'>
      <NavBar
        title='Dashboard'
        contextMenuId={ContextMenuId.PURCHASES_PAGE}
        contextMenuOptions={contextMenuOptions}
        onContextMenu={onContextMenu}
        isProcessing={isProcessing}
      />
      <IonContent>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default DashboardPage
