import type { ShoppingCart, ShoppingCartInput, ShoppingCartListItem } from '../../lib/core/definitions'
import type { ShoppingCartContextValue } from './definitions'
import loadProduct from './loadProduct'

const defaultContextValue: ShoppingCartContextValue = {
  shoppingCart: undefined,
  pendingShoppingCarts: undefined,
  shoppingCartLoadingError: undefined,
  shoppingCartLoadingNetworkStatus: undefined,
  isLoadingShoppingCart: false,
  isLoadingPendingShoppingCarts: false,
  isSavingShoppingCart: false,

  setShoppingCart: (shoppingCart: ShoppingCartInput) => new Promise(() => {
    console.log('ShoppingCartContext.defaultValue.setShoppingCart called', shoppingCart)
  }),

  reloadShoppingCart: async (): Promise<ShoppingCart | undefined> => {
    console.log('ShoppingCartContext.defaultValue.reloadShoppingCart called')
    return undefined
  },

  reloadPendingShoppingCarts: async (): Promise<ShoppingCartListItem[] | undefined> => {
    console.log('ShoppingCartContext.defaultValue.reloadPendingShoppingCarts called')
    return undefined
  },
}

const helpers = {
  defaultContextValue,
  loadProduct,
}

export default helpers
