export enum ContextMenuItemId {
  ADD_LIST_ITEM = 'add-item',
  ADD_UPLOADED_PURCHASE = 'add-uploaded-purchase',
  ARCHIVE_PURCHASE = 'archive-purchase',
  CANCEL_PURCHASE_TRANSFER = 'delete-purchase-transfer',
  CREATE_PURCHASE = 'create-purchase',
  DELETE_PURCHASE = 'delete-purchase',
  EDIT = 'edit',
  EDIT_AVATAR_MODE_UPLOAD = 'edit-avatar-mode-upload',
  EDIT_AVATAR_MODE_URL = 'edit-avatar-mode-url',
  FILTER_LIST = 'filter-list',
  REFRESH = 'refresh',
  TRANSFER = 'transfer',
  UNARCHIVE_PURCHASE = 'unarchive-purchase',
}

export type ContextMenuItem = {
  id: ContextMenuItemId;
  icon?: string;
  label?: string;
  add?: boolean;
}

export type ContextMenuOptions = {
  addAdminItems?: boolean;
  addArchivePurchase?: boolean;
  addCancelPurchaseTransfer?: boolean;
  addCreatePurchaseItem?: boolean;
  addDeletePurchase?: boolean;
  addEditPurchase?: boolean;
  addTransferPurchase?: boolean;
  addUnarchivePurchase?: boolean;
  purchaseIsTransferred?: boolean;
}

export type ContextMenuDef = {
  items: (options?: ContextMenuOptions | null) => ContextMenuItem[];
}

export enum ContextMenuId {
  EDIT_AVATAR_MODE = 'edit-avatar-mode',
  PURCHASE_PAGE = 'purchase-page',
  PRODUCTS_PAGE = 'products-page',
  STORED_PRODUCTS_PAGE = 'stored-products-page',
  PURCHASES_PAGE = 'purchases-page',
  USER_ACCOUNT_PAGE = 'user-account-page',
  MERCHANTS_PAGE = 'merchants-page',
}

const PurchasePageContextMenuDef: ContextMenuDef = {
  items: (options): ContextMenuItem[] => {
    const list: ContextMenuItem[] = [
      {
        id: ContextMenuItemId.EDIT,
        icon: '',
        label: 'Edit Gift Card',
        add: !!options && options.addEditPurchase,
      },
      {
        id: ContextMenuItemId.TRANSFER,
        icon: '',
        label: 'Send Gift',
        add: !!options && options.addTransferPurchase,
      },
      {
        id: ContextMenuItemId.ARCHIVE_PURCHASE,
        icon: '',
        label: 'Archive Gift Card',
        add: !!options && options.addArchivePurchase,
      },
      {
        id: ContextMenuItemId.UNARCHIVE_PURCHASE,
        icon: '',
        label: 'Unarchive Gift Card',
        add: !!options && options.addUnarchivePurchase,
      },
      {
        id: ContextMenuItemId.DELETE_PURCHASE,
        icon: '',
        label: 'Delete Gift Card',
        add: !!options && options.addDeletePurchase,
      },
      {
        id: ContextMenuItemId.CANCEL_PURCHASE_TRANSFER,
        icon: '',
        label: 'Cancel Gift Card Transfer',
        add: !!options && options.addCancelPurchaseTransfer,
      },
      {
        id: ContextMenuItemId.REFRESH,
        icon: '',
        label: 'Refresh Page',
        add: true,
      },
      // {
      //   id: ContextMenuItemId.EDIT_AVATAR_MODE_URL,
      //   icon: '',
      //   label: 'Enter image link',
      //   add: true,
      // },
      {
        id: ContextMenuItemId.EDIT_AVATAR_MODE_UPLOAD,
        icon: '',
        label: 'Upload',
        add: true,
      },
    ]
    return list.filter((item: ContextMenuItem): boolean => item.add === true)
  },
}

const ProductsPageContextMenuDef: ContextMenuDef = {
  items: (options): ContextMenuItem[] => {
    return [
      {
        id: ContextMenuItemId.REFRESH,
        icon: '',
        label: 'Refresh Page',
      },
    ]
  },
}

const PurchasesPageContextMenuDef: ContextMenuDef = {
  items: (options): ContextMenuItem[] => {
    const items: ContextMenuItem[] = []
    if (options && options.addCreatePurchaseItem) {
      items.push({
        id: ContextMenuItemId.CREATE_PURCHASE,
        icon: '',
        label: 'Buy Gift card',
      })
    }
    return items.concat([
      {
        id: ContextMenuItemId.ADD_UPLOADED_PURCHASE,
        icon: '',
        label: 'Upload Gift Card',
      },
      {
        id: ContextMenuItemId.REFRESH,
        icon: '',
        label: 'Refresh Page',
      },
    ])
  },
}

const StoredProductsPageContextMenuDef: ContextMenuDef = {
  items: (options): ContextMenuItem[] => {
    return [
      {
        id: ContextMenuItemId.ADD_LIST_ITEM,
        icon: '',
        label: 'Upload Card',
      },
      {
        id: ContextMenuItemId.REFRESH,
        icon: '',
        label: 'Refresh Page',
      },
    ]
  },
}

const UserAccountPageContextMenuDef: ContextMenuDef = {
  items: (options): ContextMenuItem[] => {
    const items = [
      {
        id: ContextMenuItemId.EDIT,
        icon: '',
        label: 'Edit Profile',
      },
    ]
    return items
  },
}

const MerchantsPageContextMenuDef: ContextMenuDef = {
  items: (options): ContextMenuItem[] => {
    return [
      {
        id: ContextMenuItemId.ADD_LIST_ITEM,
        icon: '',
        label: 'Add a brand',
      },
      {
        id: ContextMenuItemId.REFRESH,
        icon: '',
        label: 'Refresh Page',
      },
    ]
  },
}

const EditAvatarModeContextMenuDef: ContextMenuDef = {
  items: (options): ContextMenuItem[] => {
    return [
      {
        id: ContextMenuItemId.EDIT_AVATAR_MODE_URL,
        icon: '',
        label: 'Image link',
      },
      {
        id: ContextMenuItemId.EDIT_AVATAR_MODE_UPLOAD,
        icon: '',
        label: 'Upload',
      },
    ]
  },
}

export default {
  [ContextMenuId.EDIT_AVATAR_MODE]: EditAvatarModeContextMenuDef,
  [ContextMenuId.PRODUCTS_PAGE]: ProductsPageContextMenuDef,
  [ContextMenuId.PURCHASE_PAGE]: PurchasePageContextMenuDef,
  [ContextMenuId.PURCHASES_PAGE]: PurchasesPageContextMenuDef,
  [ContextMenuId.STORED_PRODUCTS_PAGE]: StoredProductsPageContextMenuDef,
  [ContextMenuId.USER_ACCOUNT_PAGE]: UserAccountPageContextMenuDef,
  [ContextMenuId.MERCHANTS_PAGE]: MerchantsPageContextMenuDef,
}
