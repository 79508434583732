import type { ApolloClient } from '@apollo/client'
import type { Chat, ChatMessage } from '../../../lib/core/definitions'
import type {
  ChatMessageQueryData,
  ChatQueryData, ChatQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'
import { ErrorCode } from '../../../lib/core/enums'

const updateInList = (
  chatMessage: ChatMessage,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateChatMessage.updateInList called.', { chatMessage })
  if (!chatMessage) {
    logger.error('api.cache.updateChatMessage.updateInList: no chatMessage given.')
    return
  }

  try {
    const { chatId } = chatMessage
    if (!chatId) {
      logger.error('api.cache.updateChatMessage.updateInList: no chatId.')
      return
    }
    const cachedChatData = apolloClient.readQuery<ChatQueryData, ChatQueryVariables>({
      query: apollo.queries.chat,
      variables: { chatId },
    })
    // console.log('api.cache.updateChatMessage.updateInList: cachedChatData=', cachedChatData)
    if (cachedChatData && cachedChatData.chat) {
      const updatedChat: Chat = { ...cachedChatData.chat }
      updatedChat.messages = coreHelpers.models.updateModelInList<ChatMessage, ChatMessage>(
        updatedChat.messages,
        chatMessage,
        undefined,
        true,
      )
      // console.log('api.cache.updateChatMessage.updateInList: updating cache', updatedChat)
      apolloClient.writeQuery<ChatQueryData>({
        query: apollo.queries.chat,
        variables: { chatId },
        data: { chat: updatedChat },
      })
    }
  } catch (error) {
    logger.error('api.cache.updateChatMessage.updateInList: error.', { error })
  }
}

const updateChatMessage = (
  chatMessage: ChatMessage,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateChatMessage called.', { chatMessage })
  if (!apolloClient) {
    logger.error('api.cache.updateChatMessage: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!chatMessage || !chatMessage.id) {
    logger.error('api.cache.updateChatMessage: no chatMessage given.', { chatMessage })
    return
  }

  try {
    apolloClient.writeQuery<ChatMessageQueryData>({
      query: apollo.queries.chatMessage,
      variables: { chatMessageId: chatMessage.id },
      data: { chatMessage },
    })

    updateInList(chatMessage, apolloClient)
  } catch (error) {
    logger.error('api.cache.updateChatMessage: error.', { error })
  }
}

export default updateChatMessage
