import type { ContactUserListItem } from '../../../definitions'

const compareListItemsByFavorite = (contact1: ContactUserListItem, contact2: ContactUserListItem): number => {
  if (contact1.favorite && !contact2.favorite) {
    return -1
  }
  if (!contact1.favorite && contact2.favorite) {
    return 1
  }

  return (contact1.nickname || contact1.fullName || contact1.username || '')
    .localeCompare(contact2.nickname || contact2.fullName || contact2.username || '')
}

export default compareListItemsByFavorite
