import type { PurchaseTransfer } from '../../../definitions'
import { PurchaseTransferStatus } from '../../../enums'
import appVersionHelpers from '../../appVersion'

const migrateVersion = (
  purchaseTransfer: PurchaseTransfer | null | undefined,
  appVersion: string,
): PurchaseTransfer | null | undefined => {
  if (!purchaseTransfer) {
    return purchaseTransfer
  }
  if (appVersionHelpers.isOlder(appVersion, 11055)) {
    if (purchaseTransfer.transferStatus === PurchaseTransferStatus.CREATING) {
      // @ts-ignore
      purchaseTransfer.transferStatus = 'pending'
    } else if (
      purchaseTransfer.transferStatus === PurchaseTransferStatus.SCHEDULED ||
      purchaseTransfer.transferStatus === PurchaseTransferStatus.AVAILABLE
    ) {
      // @ts-ignore
      purchaseTransfer.transferStatus = 'sent'
    } else if (purchaseTransfer.transferStatus === PurchaseTransferStatus.ACCEPTED) {
      // @ts-ignore
      purchaseTransfer.transferStatus = 'activated'
    }
  }
  return purchaseTransfer
}

export default migrateVersion
