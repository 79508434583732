import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, AsyncTask } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type { ApiClientOptions, AsyncTaskQueryData, AsyncTaskQueryVariables } from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadAsyncTask = (
  taskId: string,
  isSignedIn: boolean,
  apolloClient: ApolloClient<any>,
  isAsyncTaskInTargetStateFunc?: (asyncTask: AsyncTask) => boolean,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<AsyncTask | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadAsyncTask called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadAsyncTask: no Apollo client available.')
      throw (new Error(ErrorCode.SYSTEM_ERROR))
    }

    const queryParams: QueryOptions<AsyncTaskQueryVariables> = {
      query: apollo.queries.asyncTask,
      variables: { taskId },
      fetchPolicy: 'network-only',
    }
    if (!isSignedIn) {
      queryParams.context = { skipAuthentication: true }
    }

    const load = (): void => {
      // console.log('api.loadAsyncTask.load called.')
      apolloClient.query<AsyncTaskQueryData, AsyncTaskQueryVariables>(queryParams)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.asyncTask ||
            !resp.data.asyncTask.id
          ) {
            logger.warn('api.loadAsyncTask.load: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }
          console.log('api.loadAsyncTask.load: received server response.', { resp })

          if (
            !isAsyncTaskInTargetStateFunc ||
            isAsyncTaskInTargetStateFunc(resp.data.asyncTask)
          ) {
            // console.log('api.loadAsyncTask.load: resolving with info.', resp.data.asyncTask)
            resolve(resp.data.asyncTask)
            return
          }

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            logger.warn('api.loadAsyncTask.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadAsyncTask: error received.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadAsyncTask
