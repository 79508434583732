import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertWishMutationData,
  UpsertWishMutationVariables,
} from '../apollo/definitions'
import type {
  ApiQueryOptions,
  Wish,
  WishInput,
  WishListFilter,
} from '../../lib/core/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadWish from './loadWish'
import logger from '../logger'

const upsertWish = (
  wishInput: WishInput,
  isInTargetStateFunc: ((wish: Wish) => boolean) | 'watch-updated-at' | undefined,
  listFilter: WishListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Wish | undefined> => (
  // console.log('api.upsertWish called.', { wishInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertWish: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertWishMutationData, UpsertWishMutationVariables> = {
      mutation: apollo.mutations.upsertWish,
      variables: { wish: wishInput },
    }

    apolloClient.mutate<UpsertWishMutationData, UpsertWishMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertWish: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertWish ||
          !resp.data.upsertWish.id
        ) {
          logger.error('api.upsertWish: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((wish: Wish) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!wishInput.id) {
            logger.error('api.upsertWish: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertWish.updatedAt
            activeIsInTargetStateFunc = (wish: Wish) => wish.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertWish)
        ) {
          cache.updateWish(
            resp.data.upsertWish,
            activeUserId,
            apolloClient,
          )
          resolve(resp.data.upsertWish)
          return
        }

        const wishId = resp.data.upsertWish.id

        setTimeout(() => {
          // console.log('api.upsertWish: calling loadWish')
          loadWish(
            wishId,
            activeIsInTargetStateFunc,
            listFilter,
            activeUserId,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((wish) => {
              // console.log('api.upsertWish: resolving with wish', wish)
              resolve(wish)
            }, (error) => {
              logger.error('api.upsertWish: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertWish: error', { error })
        reject(error)
      })
  })
)

export default upsertWish
