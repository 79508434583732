// import { Workbox } from 'workbox-window'

type VersionListener = (version: string) => void

let latestVersion: string | undefined
const versionListeners = new Map<string, VersionListener>()
// let workbox: Workbox | undefined
let serviceWorkerRegistration: ServiceWorkerRegistration | undefined

export default {
  setLatestVersion: (value: string): void => {
    latestVersion = value

    if (versionListeners.size > 0) {
      versionListeners.forEach((listener) => listener(value))
    }
  },

  getLatestVersion: (): string | undefined => latestVersion,

  addVersionListener: (name: string, listener: VersionListener): void => {
    versionListeners.set(name, listener)
  },

  removeVersionListener: (name: string): void => {
    versionListeners.delete(name)
  },

  getVersionListeners: (): Map<string, VersionListener> => versionListeners,

  // setWorkbox: (wb: Workbox | undefined): void => {
  //   workbox = wb
  // },

  // getWorkbox: (): Workbox | undefined => workbox,

  setServiceWorkerRegistration: (registration: ServiceWorkerRegistration | undefined): void => {
    serviceWorkerRegistration = registration
  },

  getServiceWorkerRegistration: (): ServiceWorkerRegistration | undefined => serviceWorkerRegistration,
}
