import React, { useRef } from 'react'
import {
  IonItem,
  IonButton,
  IonIcon,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from '@ionic/react'
import {
  chevronForwardOutline,
  giftOutline,
  personOutline,
  archiveOutline,
  calendarClearOutline,
} from 'ionicons/icons'

import './styles.css'
import { ContactListScope } from '../../enums'
import type { ContactUserListItem } from '../../../../lib/core/definitions'
import Avatar from '../../../../components/Avatar/Avatar'
import FavoriteContactButton from '../../../../components/FavoriteContactButton/FavoriteContactButton'
import LatestChatMessageInfo from '../../../../components/LatestChatMessageInfo/LatestChatMessageInfo'

type Props = {
  contact: ContactUserListItem
  activeUserId: string | null | undefined
  scope: ContactListScope
  onArchiveContact: (contactId: string) => void
  onOpenContact: (contactId: string, contactUserId: string, chatId: string) => void
  onSendGift: (userId: string) => void
  showUiMessage: (message: string) => void
}

const ContactAllListItem: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('ContactAllListItem.render called.', { props })
  const {
    contact,
    activeUserId,
    scope,
    onArchiveContact,
    onOpenContact,
    onSendGift,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const slidingRef = useRef<HTMLIonItemSlidingElement | null>(null)

  // ===================================================================================================================
  // Helpers:
  const {
    fullName,
    nickname,
    unreadChatMessageCount,
    imageUrl,
  } = contact

  // ===================================================================================================================
  // Event Handlers:
  const onArchiveThisContact = (event: any) => {
    event.preventDefault()
    if (contact && contact.contactId) {
      onArchiveContact(contact.contactId)
      if (slidingRef.current) {
        slidingRef.current.close()
      }
    }
  }

  const handleOpenContact = (event?: any): void => {
    if (event) {
      event.preventDefault()
    }
    if (onOpenContact && contact.contactId) {
      onOpenContact(contact.contactId, contact.userId, contact.chatId)
      if (slidingRef.current) {
        slidingRef.current.close()
      }
    }
  }

  const handleSendGift = (event?: any): void => {
    if (event) {
      event.preventDefault()
    }
    if (onSendGift && contact.userId) {
      onSendGift(contact.userId)
      if (slidingRef.current) {
        slidingRef.current.close()
      }
    }
  }

  // ===================================================================================================================
  // Rendering:
  const avatarUser = {
    fullName,
    imageUrl,
  }

  let latestChatSection: JSX.Element | undefined
  if (scope === ContactListScope.MESSAGES) {
    latestChatSection = (
      <LatestChatMessageInfo
        activeUserId={activeUserId}
        contact={contact}
        className='withSmallTopMargin'
      />
    )
  }

  let favoriteButton: JSX.Element | undefined
  let noEventsSection: JSX.Element | undefined
  if (scope === ContactListScope.EVENTS) {
    // In order to match the ContactEventListItem UI in our joined list...
    // ... include a line indicating this contact has no events; do not render the favorite button
    noEventsSection = (
      <div className='rowWithCenterAlignedItems withSmallTopMargin smallText lightText'>
        <IonIcon
          icon={calendarClearOutline}
          style={{ fontSize: 18 }}
          className='withSmallRightMargin'
        />
        No listed events
      </div>
    )
  } else { 
    favoriteButton = (
      <FavoriteContactButton
        contactId={contact.contactId}
        isFavorite={contact.favorite}
        showUiMessage={showUiMessage}
      />
    )
  }


  return (
    <IonItemSliding key={contact.id as string} ref={slidingRef} className='contact-list-item-sliding'>
      <IonItem className='contact-all-list-item'>
        <div className='item-content'>
          <div className='g-avatar-wrapper'>
            <Avatar
              user={avatarUser}
              size={50}
              badge={unreadChatMessageCount}
              padForBadge
              className='withSmallRightMargin withPointerCursor'
              onClick={handleOpenContact}
            />
          </div>
          <div className='content-col' onClick={handleOpenContact}>
            <div className='full-name'>
              {nickname || fullName}
            </div>
            {latestChatSection}
            {noEventsSection}
          </div>
          <div className='control-col'>
            {favoriteButton}
            <IonButton
              fill='clear'
              color='medium'
              className='next-button'
              onClick={handleOpenContact}
            >
              <IonIcon icon={chevronForwardOutline} />
            </IonButton>
          </div>
        </div>
      </IonItem>
      <IonItemOptions>
        <IonItemOption type='button' color='medium' expandable onClick={handleOpenContact}>
          <IonIcon icon={personOutline} color='light' slot='top' className='action-icon' />
          Open
        </IonItemOption>
        <IonItemOption type='button' color='medium' expandable onClick={handleSendGift}>
          <IonIcon icon={giftOutline} color='light' slot='top' className='action-icon' />
          Gift
        </IonItemOption>
        <IonItemOption type='button' color='danger' expandable onClick={onArchiveThisContact}>
          <IonIcon icon={archiveOutline} color='light' slot='top' className='action-icon' />
          Archive
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  )
}

export default ContactAllListItem
