import { gql } from '@apollo/client'

export default gql`
  query Q($orderId: String!) {
    order (orderId: $orderId) {
      id
      userId
      donationTargetId
      status
      source
      metadata
      adminNotes
      recordStatus
      createdAt
      updatedAt
      items {
        id
        userId
        offerId
        productId
        productOptionId
        purchaseType
        amount
        initialBalance
        balance
        currency
        fundType
        code
        hasBarcode
        barcodeFormat
        pin
        balanceUpdatedAt
        referenceUrl
        messageEn
        issuedAt
        expiresAt
        adminNotes
        status
        source
        metadata {
          amountPaid
          receivedTransfer {
            id
            fromUserId
            fromPurchaseId
            transferStatus
            recordStatus
          }
          sentTransfer {
            id
            toUserId
            toPurchaseId
            transferStatus
            recordStatus
          }
          latestError
          events {
            time
            type
            message
          }
        }
        recordStatus
        createdAt
        updatedAt
        #      purchaseTransfers {
        #        id
        #        fromPurchaseId
        #        toPurchaseId
        #        toUserId
        #        toUserFullName
        #        toUserUsername
        #        toUserEmail
        #        toUserPhoneNumber
        #        notificationMethod
        #        transferStatus
        #        messageText
        #        messageScheduledAt
        #        messageScheduledTimezone
        #        messageSentAt
        #        options
        #        adminNotes
        #        metadata
        #        recordStatus
        #        createdAt
        #        updatedAt
        #      }
        receivedPurchaseTransfers {
          id
          fromPurchaseId
          toPurchaseId
          toUserId
          toUserFullName
          toUserUsername
          toUserEmail
          toUserPhoneNumber
          notificationMethod
          transferStatus
          messageText
          messageScheduledAt
          messageScheduledTimezone
          messageSentAt
          options
          adminNotes
          metadata
          recordStatus
          createdAt
          updatedAt
          fromUser {
            id
            fullName
            username
            email
            phoneNumber
            imageUrl
          }
        }
        sentPurchaseTransfers {
          id
          fromPurchaseId
          toPurchaseId
          toUserId
          toUserFullName
          toUserUsername
          toUserEmail
          toUserPhoneNumber
          notificationMethod
          transferStatus
          messageText
          messageScheduledAt
          messageScheduledTimezone
          messageSentAt
          options
          adminNotes
          metadata
          recordStatus
          createdAt
          updatedAt
          toUser {
            id
            fullName
            username
            email
            phoneNumber
            imageUrl
          }
        }
        #      purchaseTransferListItems {
        #        id
        #        fromPurchaseId
        #        fromUserId
        #        fromUserUsername
        #        fromUserEmail
        #        fromUserPhoneNumber
        #        toPurchaseId
        #        toUserId
        #        toUserUsername
        #        toUserFullName
        #        toUserEmail
        #        toUserPhoneNumber
        #        transferStatus
        #        notificationMethod
        #        messageScheduledAt
        #        messageScheduledTimezone
        #        messageSentAt
        #        adminNotes
        #        metadata
        #        status
        #        recordStatus
        #        createdAt
        #        updatedAt
        #      }
        product {
          id
          merchantId
          productType
          code
          hasBarcode
          barcodeFormat
          referenceUrl
          messageEn
          termsEn
          termsUrl
          instructionsEn
          instructionsUrl
          imageSourceFrontSide
          imageSourceBackSide
          merchant {
            id
            name
            description
            url
            smallLogoImageSource
            largeLogoImageSource
            balanceLookupUri
          }
          #        tags {}
        }
      }
      paymentTransaction {
        id
        remoteId
        purchaseId
        fromUserId
        toUserId
        fromWalletId
        toWalletId
        transactionType
        status
        amount
        paid
        currency
        fundType
        exchangeRate
        exchangeRateUpdatedAt
        processor
        source
        blockchainAddress
        message
        requestMessage
        paymentRequest
        #      metadata
        recordStatus
        createdAt
        updatedAt
        fromUser { id username fullName imageUrl isOrganization createdAt }
        toUser { id username fullName imageUrl isOrganization createdAt }
        blockchainTransactions {
          id
          hash
          fromUserId
          toUserId
          fromWalletId
          toWalletId
          transactionId
          transactionType
          fromBlockchainAddress
          toBlockchainAddress
          amount
          currency
          confirmations
          approved
          message
          #        metadata
          recordStatus
          createdAt
          updatedAt
        }
        reward {
          id
          userId
          transactionId
          forContactId
          forInvitationId
          forPurchaseId
          forPurchaseTransferId
          forTransactionId
          forWishId
          rewardType
          amount
          fundType
          currency
          occurrence
          invitationLevel
          metadata
          adminNotes
          createdBy
          recordStatus
          createdAt
          updatedAt
        }
      }
    }
  }
`
