import { PaymentType } from '../../enums'

const labelsEn = Object.freeze({
  [PaymentType.CREDIT_CARD]: 'Credit Card',
  [PaymentType.CRYPTO]: 'Bitcoin',
  [PaymentType.MIT]: 'MIT',
})

export default {
  getLabel: (status?: PaymentType | null, language?: string): string => {
    if (!status) {
      return ''
    }
    if (!language || language === 'US') {
      return labelsEn[status]
    }
    return ''
  },
  isValid: (status: PaymentType): boolean => Object.values(PaymentType).includes(status),
}
