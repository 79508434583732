import type { MemberMessageContext, MemberMessageInput, MemberMessageTemplate } from '../../../definitions'
import logger from '../../../logger'
import setContextValues from './setContextValues'

/**
 * The member message will be composed out of:
 * 1) memberMessageInput - the input can fully describe the message
 * 2) The context with values that will be inserted into the message texts using the placeholder variables
 * 3) The referenced template - can provide text, send method options
 * @param memberMessageInput
 * @param memberMessageTemplate
 * @param context
 */
function mergeMemberMessageWithTemplate<ContextT extends MemberMessageContext> (
  memberMessageInput: MemberMessageInput,
  memberMessageTemplate: MemberMessageTemplate,
  context?: ContextT,
): MemberMessageInput | null {
  // console.log('services.messaging.mergeMemberMessageWithTemplate called.',
  //   { memberMessageInput, memberMessageTemplate, context })

  if (!memberMessageTemplate) {
    logger.error('services.messaging.mergeMemberMessageWithTemplate: failed to load template.',
      { memberMessageInput }, { remote: true })
    return null
  }

  try {
    let newMemberMessageInput: MemberMessageInput = {
      ...memberMessageInput,
      templateId: memberMessageTemplate.id,
    }

    if (!newMemberMessageInput.title && memberMessageTemplate.titleEn) {
      newMemberMessageInput.title = memberMessageTemplate.titleEn
    }
    if (!newMemberMessageInput.messageText && memberMessageTemplate.messageTextEn) {
      newMemberMessageInput.messageText = memberMessageTemplate.messageTextEn
    }
    if (!newMemberMessageInput.shortMessageText && memberMessageTemplate.shortMessageTextEn) {
      newMemberMessageInput.shortMessageText = memberMessageTemplate.shortMessageTextEn
    }
    if (!newMemberMessageInput.htmlMessage && memberMessageTemplate.htmlMessageEn) {
      newMemberMessageInput.htmlMessage = memberMessageTemplate.htmlMessageEn
    }
    if (context) {
      newMemberMessageInput.context = context
    }

    newMemberMessageInput = context
      ? setContextValues(
        newMemberMessageInput,
        memberMessageTemplate,
        context,
      )
      : memberMessageInput

    if (
      newMemberMessageInput.sendChatMessage !== true &&
      newMemberMessageInput.sendChatMessage !== false &&
      (memberMessageTemplate.sendChatMessage === true || memberMessageTemplate.sendChatMessage === false)
    ) {
      newMemberMessageInput.sendChatMessage = newMemberMessageInput.sendChatMessage = memberMessageTemplate.sendChatMessage
    }

    if (
      newMemberMessageInput.sendEmail !== true &&
      newMemberMessageInput.sendEmail !== false &&
      (memberMessageTemplate.sendEmail === true || memberMessageTemplate.sendEmail === false)
    ) {
      newMemberMessageInput.sendEmail = newMemberMessageInput.sendEmail = memberMessageTemplate.sendEmail
    }

    if (
      newMemberMessageInput.sendInAppMessage !== true &&
      newMemberMessageInput.sendInAppMessage !== false &&
      (memberMessageTemplate.sendInAppMessage === true || memberMessageTemplate.sendInAppMessage === false)
    ) {
      newMemberMessageInput.sendInAppMessage = newMemberMessageInput.sendInAppMessage = memberMessageTemplate.sendInAppMessage
    }

    if (
      newMemberMessageInput.sendPushNotification !== true &&
      newMemberMessageInput.sendPushNotification !== false &&
      (memberMessageTemplate.sendPushNotification === true || memberMessageTemplate.sendPushNotification === false)
    ) {
      newMemberMessageInput.sendPushNotification = newMemberMessageInput.sendPushNotification = memberMessageTemplate.sendPushNotification
    }

    if (
      newMemberMessageInput.sendSms !== true &&
      newMemberMessageInput.sendSms !== false &&
      (memberMessageTemplate.sendSms === true || memberMessageTemplate.sendSms === false)
    ) {
      newMemberMessageInput.sendSms = newMemberMessageInput.sendSms = memberMessageTemplate.sendSms
    }

    // console.log('services.messaging.mergeMemberMessageWithTemplate: message merged.',
    //   { memberMessageInput, newMemberMessageInput })

    return newMemberMessageInput
  } catch (error) {
    logger.error('services.messaging.mergeMemberMessageWithTemplate: failed to save member message.',
      { memberMessageInput, error }, { remote: true })
    return null
  }
}

export default mergeMemberMessageWithTemplate
