import { gql } from '@apollo/client'

export default gql`
  query Q($rewardId: String!) {
    reward(rewardId: $rewardId) {
      id
      userId
      transactionId
      forContactId
      forInvitationId
      forPurchaseId
      forPurchaseTransferId
      forTransactionId
      forWishId
      rewardType
      amount
      fundType
      currency
      occurrence
      invitationLevel
      metadata
      adminNotes
      createdBy
      recordStatus
      createdAt
      updatedAt
      transaction {
        id
        status
        amount
        currency
        fundType
        createdAt
        updatedAt
      }
      forContact {
        id
        contactType
        status
        createdAt
        updatedAt
        recordStatus
        fromUser {
          id
          username
          fullName
          imageUrl
          createdAt
          updatedAt
          recordStatus
        }
        toUser {
          id
          username
          fullName
          imageUrl
          createdAt
          updatedAt
          recordStatus
        }
      }
      forInvitation {
        id
        toUserId
        invitationType
        fromUser {
          id
          fullName
          username
          email
          phoneNumber
          imageUrl
        }
        toUser {
          id
          fullName
          username
          email
          phoneNumber
          imageUrl
        }
      }
      forPurchase {
        id
        purchaseType
        amount
        fundType
        currency
        createdAt
        updatedAt
        recordStatus
        product {
          id
          merchantId
          productType
          imageSourceFrontSide
          imageSourceBackSide
          merchant {
            id
            name
            largeLogoImageSource
          }
        }
      }
      forPurchaseTransfer {
        id
        toUserId
        fromPurchaseId
        createdAt
        updatedAt
        recordStatus
        fromPurchase {
          id
          productId
          balance
          fundType
          currency
          createdAt
          updatedAt
          recordStatus
          product {
            id
            merchantId
            productType
            imageSourceFrontSide
            imageSourceBackSide
            merchant {
              id
              name
              largeLogoImageSource
            }
          }
        }
        toUser {
          id
          fullName
          username
          email
          phoneNumber
          imageUrl
        }
      }
    }
  }`
