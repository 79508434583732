import React, { useState } from 'react'

import './styles.css'
import type { Contact, PurchaseListItem, User } from '../../../lib/core/definitions'
import { ContactPageProfileTabSectionId } from './enums'
import GiftsSection from './GiftsSection/GiftsSection'
import EventsSection from './EventsSection/EventsSection'
import InfoSection from './InfoSection/InfoSection'
import NavSection from './NavSection/NavSection'

type Props = {
  contact: Contact | null | undefined
  user: User | null | undefined
  purchasesISent: PurchaseListItem[] | undefined
  purchasesIReceived: PurchaseListItem[] | undefined
  showUiMessage: (message: string) => void
  onOpenPurchase: (purchaseId: string) => void
  onNewGift?: () => void
}

const ContactPageInfoTab: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contact,
    user,
    purchasesISent,
    purchasesIReceived,
    showUiMessage,
    onOpenPurchase,
    onNewGift
  } = props

  // ===================================================================================================================
  // State:
  const [sectionId, setSectionId] = useState(ContactPageProfileTabSectionId.PROFILE_INFO)

  // ===================================================================================================================
  // Event Handlers:
  const onOpenSection = (newSectionId: ContactPageProfileTabSectionId): void => {
    setSectionId(newSectionId)
  }

  // ===================================================================================================================
  // Rendering:
  if (!user) {
    return null
  }

  let content: JSX.Element | undefined
  if (sectionId === ContactPageProfileTabSectionId.PROFILE_INFO) {
    content = (
      <InfoSection
        contact={contact}
        user={user}
        showUiMessage={showUiMessage}
      />
    )
  }
  if (sectionId === ContactPageProfileTabSectionId.EVENTS) {
    content = (
    <EventsSection
      contact={contact}
      user={user}
      showUiMessage={showUiMessage}
    />
    )
  }
  if (sectionId === ContactPageProfileTabSectionId.PAST_GIFTS) {
    content = (
      <GiftsSection
        purchasesISent={purchasesISent}
        purchasesIReceived={purchasesIReceived}
        contactName={(contact && contact.nickname) || user.fullName}
        onOpenPurchase={onOpenPurchase}
        onNewGift={onNewGift}
      />
    )
  }

  return (
    <div className='contact-page-info-tab'>
        <NavSection
          sectionId={sectionId}
          onOpenSection={onOpenSection}
        />
        {content}
    </div>
  )
}

export default ContactPageInfoTab
