import { AsyncTaskResult } from '../../enums'

const labelsEn = Object.freeze({
  [AsyncTaskResult.CONFIRM_TOKEN_MISMATCH]: 'token did not match',
  [AsyncTaskResult.DATA_VALIDATION_FAILED]: 'data validation failed',
  [AsyncTaskResult.DEVICE_NOT_FOUND]: 'device not found',
  [AsyncTaskResult.EMAIL_MISMATCH]: 'email mismatch',
  [AsyncTaskResult.EMAIL_NOT_VERIFIED]: 'email not verified',
  [AsyncTaskResult.ERROR]: 'ERROR',
  [AsyncTaskResult.INVALID_EMAIL]: 'invalid email',
  [AsyncTaskResult.INVALID_PHONE_NUMBER]: 'invalid phone number',
  [AsyncTaskResult.MISSING_EMAIL]: 'missing email',
  [AsyncTaskResult.MISSING_PHONE_NUMBER]: 'missing phone number',
  [AsyncTaskResult.NOT_FOUND]: 'not found',
  [AsyncTaskResult.OK]: 'OK',
  [AsyncTaskResult.PASSED]: 'passed',
  [AsyncTaskResult.PASSWORD_MISMATCH]: 'password mismatch',
  [AsyncTaskResult.PASSWORD_UPDATED]: 'password updated',
  [AsyncTaskResult.PHONE_NUMBER_MISMATCH]: 'phone number mismatch',
  [AsyncTaskResult.PHONE_NUMBER_NOT_VERIFIED]: 'phone number not verified',
  [AsyncTaskResult.SYSTEM_ERROR]: 'system error',
  [AsyncTaskResult.USER_FAILED_VALIDATION]: 'member failed validation',
  [AsyncTaskResult.USER_NOT_FOUND]: 'member not found',
  [AsyncTaskResult.USER_NOT_SIGNED_IN]: 'member not signed in',
})

export default {
  getLabel: (type: AsyncTaskResult | null | undefined, language = 'en'): string => {
    if (!type) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[type]
    }
    return ''
  },

  isFailedToSendNotificationResult: (result: AsyncTaskResult | null | undefined): boolean => (
    !!result &&
    [
      AsyncTaskResult.DEVICE_NOT_FOUND,
      AsyncTaskResult.EMAIL_MISMATCH,
      AsyncTaskResult.EMAIL_NOT_VERIFIED,
      AsyncTaskResult.INVALID_EMAIL,
      AsyncTaskResult.INVALID_PHONE_NUMBER,
      AsyncTaskResult.MISSING_EMAIL,
      AsyncTaskResult.MISSING_PHONE_NUMBER,
      AsyncTaskResult.NOT_FOUND,
      AsyncTaskResult.PHONE_NUMBER_MISMATCH,
      AsyncTaskResult.PHONE_NUMBER_NOT_VERIFIED,
      AsyncTaskResult.SYSTEM_ERROR,
      AsyncTaskResult.USER_NOT_FOUND,
      AsyncTaskResult.USER_FAILED_VALIDATION,
      AsyncTaskResult.USER_NOT_SIGNED_IN,
      AsyncTaskResult.PASSWORD_MISMATCH,
    ].includes(result)
  ),

  isValid: (type: AsyncTaskResult): boolean => Object.values(AsyncTaskResult).includes(type),
}
