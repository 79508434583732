import { ContextHelpId } from './enums'

export default {
  [ContextHelpId.SEND_GIFT_RECIPIENT]: {
    title: 'Sending a Gift',
    text:
      `<p>You can send a gift to anyone!
      <br />
      <br />
      If they are not already a Mimble member, their email or phone number will be used to send it to them.
      <br />
      <br />
      Upon creating their account and receiving their new gift, it will then automatically show up
      in their wallet and can be used immediately.`,
  },
  [ContextHelpId.ADD_CONTACT]: {
    title: 'Adding a contact',
    text:
      `<p>You can search for members by name, handle, phone or email address.
      <br />
      <br />
      Inviting someone to join Mimble using your personal invite link will reward both of you,
      and connect you in your contact lists.`,
  },
}
