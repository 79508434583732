import type { FundType } from '../../lib/core/enums'
import type { User, Wallet } from '../../lib/core/definitions'
import getUserMimbleWalletBalance from './getUserMimbleWalletBalance'

export default (
  user: User | null | undefined, wallets: Wallet[] | null | undefined,
  amount: number,
  fundType: FundType,
): boolean => {
  const walletBalance = getUserMimbleWalletBalance(user, wallets, fundType)
  return walletBalance >= amount
}
