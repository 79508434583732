import React, { useContext, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import type { AppAction } from '../../../lib/core/enums'
import { UiMessage } from '../../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type { MemberMessage, MemberMessageTemplateInput } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { useApolloClient, useQuery } from '@apollo/client'
import type { MemberMessageTemplateQueryData, MemberMessageTemplateQueryVariables } from '../../../services/apollo/definitions'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import coreHelpers from '../../../lib/core/helpers'
import InAppMessageModal from '../../../components/InAppMessageModal/InAppMessageModal'
import MemberMessageTemplateForm from './MemberMessageTemplateForm'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'

const appPageId = AppPage.AdminEditMemberMessageTemplatePage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  memberMessageTemplateId: string
}

const EditMemberMessageTemplatePage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { memberMessageTemplateId } = useParams<keyof Params>() as unknown as Params
  const goBackUri = AppRoute.ADMIN_MEMBER_MESSAGE_TEMPLATES

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  // const { activeUser } = useMimbleData()
  // const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [previewMemberMessage, setPreviewMemberMessage] = useState<MemberMessage | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading memberMessageTemplate:
  const {
    data: memberMessageTemplateLoadedData,
    loading: isLoadingMemberMessageTemplate,
    refetch: reloadMemberMessageTemplate,
  } = useQuery<MemberMessageTemplateQueryData, MemberMessageTemplateQueryVariables>(
    apollo.queries.memberMessageTemplate, {
      variables: { memberMessageTemplateId: memberMessageTemplateId || '' },
      skip: !memberMessageTemplateId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: MemberMessageTemplateQueryData) => {
        const returnedMemberMessageTemplate = data ? data.memberMessageTemplate : undefined
        if (!returnedMemberMessageTemplate) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
          return
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const memberMessageTemplate = memberMessageTemplateLoadedData ? memberMessageTemplateLoadedData.memberMessageTemplate : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = false // isGettingMemberMessageTemplate || isUpsertingMemberMessageTemplate

  // ===================================================================================================================
  // Event Handlers:
  const onUpsertMemberMessageTemplate = (memberMessageTemplateInput: MemberMessageTemplateInput): void => {
    console.log('EditMemberMessageTemplatePage.onUpsertMemberMessageTemplate called.', memberMessageTemplateInput)
    api.upsertMemberMessageTemplate(
      memberMessageTemplateInput,
      'watch-updated-at',
      undefined,
      apolloClient,
    ).then(() => {
      setToastMessage(coreHelpers.type.uiMessage.getLabel(UiMessage.DATA_UPDATED_SUCCESSFULLY))
      setShowToast(true)
      navigate(-1)
    }, (error) => {
      console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
    })
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !reloadMemberMessageTemplate || !memberMessageTemplateId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadMemberMessageTemplate({ memberMessageTemplateId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onPreview = (memberMessageTemplate: MemberMessageTemplateInput): void => {
    const memberMessage = coreHelpers.models.memberMessage.mergeMemberMessageWithTemplate(
      {
      },
      memberMessageTemplate,
      {},
    ) as MemberMessage
    setPreviewMemberMessage(memberMessage)
    // setTimeout(() => {
    //   setShowInAppMessageModal(true)
    // }, 100)
  }

  const onAppAction = (action: AppAction): void => {
    alert(`You clicked on an action '${action}'`)
  }

  const onCloseInAppMessageModal = (): void => {
    // setShowInAppMessageModal(false)
  }

  const onGoBack = (): void => {
    navigate(-1)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const pageTitle = memberMessageTemplate ? 'Edit Member Message Template' : 'Add Member Message Template'

  // console.log('EditMemberMessageTemplatePage.render called.', { memberMessageTemplate });
  return (
    <IonPage className='app-page-admin edit-memberMessageTemplate-page'>
      <NavBar
        title={pageTitle}
        goBackUri={goBackUri}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <MemberMessageTemplateForm
          memberMessageTemplate={memberMessageTemplateId ? memberMessageTemplate : null}
          onGoBack={onGoBack}
          onSave={onUpsertMemberMessageTemplate}
          onPreview={onPreview}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      <InAppMessageModal
        memberMessage={previewMemberMessage}
        onAction={onAppAction}
        onClose={onCloseInAppMessageModal}
      />
    </IonPage>
  )
}

export default EditMemberMessageTemplatePage
