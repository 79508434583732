import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'
import type {
  GridColumns,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import {
  DataGridPro,
} from '@mui/x-data-grid-pro'

import './styles.css'
import type { MerchantListFilter, MerchantListItem } from '../../../lib/core/definitions'
import Moment from 'moment-timezone'

type Props = {
  merchants: MerchantListItem[] | null | undefined
  filter: MerchantListFilter | undefined
  isProcessing?: boolean
  onOpenMerchant: (merchantId: string) => void
  onOpenProduct: (productId: string) => void
}

const MerchantGrid: React.FC<Props> = (props): JSX.Element | null => {
  const {
    merchants,
    filter,
    isProcessing,
    onOpenProduct,
  } = props

  const filterList = (merchant: MerchantListItem): boolean => {
    if (
      filter &&
      (filter.listed === true || filter.listed === false) &&
      merchant.listed !== filter.listed
    ) {
      return false
    }
    if (filter && filter.searchText) {
      const reg = new RegExp(filter.searchText, 'i')
      if (
        (!merchant.name || !merchant.name.match(reg)) &&
        (!merchant.alias1 || !merchant.alias1.match(reg)) &&
        (!merchant.alias2 || !merchant.alias2.match(reg)) &&
        (!merchant.alias3 || !merchant.alias3.match(reg))
      ) {
        return false
      }
    }
    return true
  }

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (rowData: GridRowParams): void => {
    // onOpenMerchant((rowData.row.id as string).replace(/-/g, ''))
    onOpenProduct((rowData.row.mainProductId as string).replace(/-/g, ''))
  }

  const onExport = (): void => {
    if (!Array.isArray(merchants) || merchants.length < 1) {
      return
    }
    console.error('not implemented')
    // const data = coreHelpers.models.merchant.exportData(merchants, filter)
    // const link = document.createElement('a')
    // link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
    // link.target = '_blank'
    // link.download = 'members.csv'
    // link.click()
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(merchants) || merchants.length < 1) {
    return null
  }
  // see https://material-ui.com/components/data-grid/rendering/#components

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'listed', headerName: 'Listed?', flex: 1 },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      flex: 0.5,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? Moment(Number(params.value)).format('YYYY-MM-DD') : '-'
      ),
      sortComparator: (v1, v2) => (
        Number(v2) - Number(v1)
      ),
    },
  ]
  const rows: GridRowsProp = merchants
    .filter(filterList)
    .map((merchant): GridRowModel => {
      return {
        id: merchant.id as string,
        mainProductId: merchant.mainProductId as string,
        name: merchant.name || '-',
        listed: merchant.listed || false,
        updatedAt: merchant.updatedAt as string,
      }
    })

  return (
    <div className='x-grid-data-view'>
      <div className='actions-row'>
        <IonButton
          size='small'
          fill='clear'
          color='medium'
          disabled={!Array.isArray(merchants) || merchants.length < 1 || isProcessing}
          onClick={onExport}
        >
          <IonIcon icon={cloudDownloadOutline} />
        </IonButton>
      </div>
      <div className='x-grid-wrapper'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  )
}

export default MerchantGrid
