import type { ContactEventReminder } from '../../../definitions'
import { ContactEventReminderTiming } from '../../../enums'

const getUnusedConfigs = (
  reminders: ContactEventReminder[] | null | undefined,
): ContactEventReminderTiming[] => {
  const all = Object.values(ContactEventReminderTiming).filter(v => typeof v === 'number') as ContactEventReminderTiming[]
  if (!Array.isArray(reminders) || reminders.length < 1) {
    return all
  }

  return all.filter(offs => !reminders.find(r => r.timing === offs))
}

export default getUnusedConfigs
