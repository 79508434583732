import React, { useEffect, useState } from 'react'
import { IonButton, IonInput } from '@ionic/react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Editor } from '@tinymce/tinymce-react'

import './styles.css'
import { BarcodeType } from '../../../lib/core/enums'
import type { Product, ProductInput } from '../../../lib/core/definitions'
import FormCheckbox from '../../../components/FormCheckbox/FormCheckbox'
import FormItem from '../../../components/FormItem/FormItem'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../helpers/validationHelpers'

type Props = {
  product?: Product | null;
  onGoBack: () => void;
  onSave: (product: Product) => void;
};

const ProductForm: React.FC<Props> = ({
  product = null,
  onSave,
  onGoBack,
}): JSX.Element => {
  const [genericProductId, setGenericProductId] = useState<string | undefined>()
  const [genericProductIdValidationError, setGenericProductIdValidationError] = useState<string | undefined>()
  const [referenceUrl, setReferenceUrl] = useState<string | undefined>()
  const [referenceUrlValidationError, setReferenceUrlValidationError] = useState<string | undefined>()
  // const [contentFrontSide, setContentFrontSide] = useState<string | undefined>();
  // const [contentFrontSideValidationError, setContentFrontSideValidationError] = useState(');
  // const [contentBackSide, setContentBackSide] = useState<string | undefined>();
  // const [contentBackSideValidationError, setContentBackSideValidationError] = useState(');
  const [termsEn, setTermsEn] = useState<string | undefined>()
  const [termsEnValidationError, setTermsEnValidationError] = useState<string | undefined>()
  const [termsUrl, setTermsUrl] = useState<string | undefined>()
  const [termsUrlValidationError, setTermsUrlValidationError] = useState<string | undefined>()
  const [instructionsEn, setInstructionsEn] = useState<string | undefined>()
  const [instructionsEnValidationError, setInstructionsEnValidationError] = useState<string | undefined>()
  const [instructionsUrl, setInstructionsUrl] = useState<string | undefined>()
  const [instructionsUrlValidationError, setInstructionsUrlValidationError] = useState<string | undefined>()
  const [imageSourceFrontSide, setImageSourceFrontSide] = useState<string | undefined>()
  const [imageSourceFrontSideValidationError, setImageSourceFrontSideValidationError] = useState<string | undefined>()
  const [imageSourceBackSide, setImageSourceBackSide] = useState<string | undefined>()
  const [imageSourceBackSideValidationError, setImageSourceBackSideValidationError] = useState<string | undefined>()
  const [searchTerms, setSearchTerms] = useState<string | undefined>()
  const [searchTermsValidationError, setSearchTermsValidationError] = useState<string | undefined>()
  const [hasBarcode, setHasBarcode] = useState<boolean | undefined>()
  const [barcodeFormat, setBarcodeFormat] = useState<BarcodeType | undefined>()
  const [barcodeFormatValidationError, setBarcodeFormatValidationError] = useState<string | undefined>()
  const [hasPin, setHasPin] = useState<boolean | undefined>()
  const [listed, setListed] = useState<boolean | undefined>()

  // ===================================================================================================================
  // Helpers:
  const genericProductIdChanged = genericProductId !== undefined && !(product && genericProductId === product.genericProductId)
  const referenceUrlChanged = referenceUrl !== undefined && !(product && referenceUrl === product.referenceUrl)
  const termsEnChanged = termsEn !== undefined && !(product && termsEn === product.termsEn)
  const termsUrlChanged = termsUrl !== undefined && !(product && termsUrl === product.termsUrl)
  const instructionsEnChanged = instructionsEn !== undefined && !(product && instructionsEn === product.instructionsEn)
  const instructionsUrlChanged = instructionsUrl !== undefined && !(product && instructionsUrl === product.instructionsUrl)
  const imageSourceFrontSideChanged = imageSourceFrontSide !== undefined && !(product && imageSourceFrontSide === product.imageSourceFrontSide)
  const imageSourceBackSideChanged = imageSourceBackSide !== undefined && !(product && imageSourceBackSide === product.imageSourceBackSide)
  const searchTermsChanged = searchTerms !== undefined && !(product && searchTerms === product.searchTerms)
  const listedChanged = listed !== undefined && !(product && listed === product.listed)
  const hasBarcodeChanged = hasBarcode !== undefined && !(product && hasBarcode === product.hasBarcode)
  const barcodeFormatChanged = barcodeFormat !== undefined && !(product && barcodeFormat === product.barcodeFormat)
  const hasPinChanged = hasPin !== undefined && !(product && hasPin === product.hasPin)

  let hasBarcodeDisplay = product ? product.hasBarcode === true : true
  if (hasBarcode !== undefined) {
    hasBarcodeDisplay = hasBarcode
  }

  let hasPinDisplay = product ? product.hasPin === true : true
  if (hasPin !== undefined) {
    hasPinDisplay = hasPin
  }

  const isDirty = (
    genericProductIdChanged ||
    referenceUrlChanged ||
    termsEnChanged ||
    termsUrlChanged ||
    instructionsEnChanged ||
    instructionsUrlChanged ||
    imageSourceFrontSideChanged ||
    imageSourceBackSideChanged ||
    searchTermsChanged ||
    listedChanged ||
    hasBarcodeChanged ||
    barcodeFormatChanged ||
    hasPinChanged
  )
  const isValid = (
    !genericProductIdValidationError &&
    !referenceUrlValidationError &&
    !termsEnValidationError &&
    !termsUrlValidationError &&
    !instructionsEnValidationError &&
    !instructionsUrlValidationError &&
    !imageSourceFrontSideValidationError &&
    !imageSourceBackSideValidationError &&
    !searchTermsValidationError &&
    !barcodeFormatValidationError &&
    (
      !hasBarcodeDisplay ||
      ((barcodeFormat !== null && product && product.barcodeFormat) || !!barcodeFormat)
    )
  )

  // console.log('>>>>>>>', {
  //   isValid,
  //   isDirty,
  //   genericProductIdValidationError,
  //   referenceUrlValidationError,
  //   termsEnValidationError,
  //   termsUrlValidationError,
  //   instructionsEnValidationError,
  //   instructionsUrlValidationError,
  //   imageSourceFrontSideValidationError,
  //   imageSourceBackSideValidationError,
  //   searchTermsValidationError,
  //   barcodeFormatValidationError,
  //   hasBarcodeDisplay,
  //   barcodeFormat,
  //   'product && product.barcodeFormat': product && product.barcodeFormat,
  // })

  const resetForm = (): void => {
    setGenericProductId(undefined)
    setGenericProductIdValidationError('')
    setReferenceUrl(undefined)
    setReferenceUrlValidationError('')
    setTermsEn(undefined)
    setTermsEnValidationError('')
    setTermsUrl(undefined)
    setTermsUrlValidationError('')
    setInstructionsEn(undefined)
    setInstructionsEnValidationError('')
    setInstructionsUrl(undefined)
    setInstructionsUrlValidationError('')
    setImageSourceFrontSide(undefined)
    setImageSourceFrontSideValidationError('')
    setImageSourceBackSide(undefined)
    setImageSourceBackSideValidationError('')
    setSearchTerms(undefined)
    setSearchTermsValidationError('')
    setListed(undefined)
    setHasBarcode(undefined)
    setBarcodeFormat(undefined)
    setBarcodeFormatValidationError('')
    setHasPin(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('ProductForm: new product received - resetting form.')
    resetForm()
  }, [product])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeGenericProductId = (event: any): void => {
    if (
      (product && (event.detail.value === product.genericProductId || (!event.detail.value && !product.genericProductId))) ||
      (!product && !event.detail.value)
    ) {
      setGenericProductIdValidationError('')
      setGenericProductId(undefined)
      return
    }
    setGenericProductId(event.detail.value)
    if (event.detail.value) {
      setGenericProductIdValidationError(validationHelpers.validateUuid(event.detail.value))
    } else {
      setGenericProductIdValidationError('')
    }
  }

  const onChangeReferenceUrl = (event: any): void => {
    if (
      (product && (event.detail.value === product.referenceUrl || (!event.detail.value && !product.referenceUrl))) ||
      (!product && !event.detail.value)
    ) {
      setReferenceUrlValidationError('')
      setReferenceUrl(undefined)
      return
    }
    setReferenceUrl(event.detail.value)
    if (event.detail.value) {
      setReferenceUrlValidationError(validationHelpers.validateUrl(event.detail.value))
    } else {
      setReferenceUrlValidationError('')
    }
  }

  const onChangeTermsEn = (newTerms: string): void => {
    if (
      (product && (newTerms === product.termsEn || (!newTerms && !product.termsEn))) ||
      (!product && !newTerms)
    ) {
      setTermsEnValidationError('')
      setTermsEn(undefined)
      return
    }
    setTermsEn(newTerms)
    if (newTerms) {
      setTermsEnValidationError(validationHelpers.validateProductTerms(newTerms))
    } else {
      setTermsEnValidationError('')
    }
  }

  const onChangeTermsUrl = (event: any): void => {
    if (
      (product && (event.detail.value === product.termsUrl || (!event.detail.value && !product.termsUrl))) ||
      (!product && !event.detail.value)
    ) {
      setTermsUrlValidationError('')
      setTermsUrl(undefined)
      return
    }
    setTermsUrl(event.detail.value)
    if (event.detail.value) {
      setTermsUrlValidationError(validationHelpers.validateUrl(event.detail.value))
    } else {
      setTermsUrlValidationError('')
    }
  }

  const onChangeInstructionsEn = (newInstructions: string): void => {
    if (
      (product && (newInstructions === product.instructionsEn || (!newInstructions && !product.instructionsEn))) ||
      (!product && !newInstructions)
    ) {
      setInstructionsEnValidationError('')
      setInstructionsEn(undefined)
      return
    }
    setInstructionsEn(newInstructions)
    if (newInstructions) {
      setInstructionsEnValidationError(validationHelpers.validateProductInstructions(newInstructions))
    } else {
      setInstructionsEnValidationError('')
    }
  }

  const onChangeInstructionsUrl = (event: any): void => {
    if (
      (product && (event.detail.value === product.instructionsUrl || (!event.detail.value && !product.instructionsUrl))) ||
      (!product && !event.detail.value)
    ) {
      setInstructionsUrlValidationError('')
      setInstructionsUrl(undefined)
      return
    }
    setInstructionsUrl(event.detail.value)
    if (event.detail.value) {
      setInstructionsUrlValidationError(validationHelpers.validateUrl(event.detail.value))
    } else {
      setInstructionsUrlValidationError('')
    }
  }

  const onChangeImageSourceFrontSide = (event: any): void => {
    if (
      (product && (event.detail.value === product.imageSourceFrontSide || (!event.detail.value && !product.imageSourceFrontSide))) ||
      (!product && !event.detail.value)
    ) {
      setImageSourceFrontSideValidationError('')
      setImageSourceFrontSide(undefined)
      return
    }
    setImageSourceFrontSide(event.detail.value)
    if (event.detail.value) {
      setImageSourceFrontSideValidationError(validationHelpers.validateImageSource(event.detail.value))
    } else {
      setImageSourceFrontSideValidationError('')
    }
  }

  const onChangeImageSourceBackSide = (event: any): void => {
    if (
      (product && (event.detail.value === product.imageSourceBackSide || (!event.detail.value && !product.imageSourceBackSide))) ||
      (!product && !event.detail.value)
    ) {
      setImageSourceBackSideValidationError('')
      setImageSourceBackSide(undefined)
      return
    }
    setImageSourceBackSide(event.detail.value)
    if (event.detail.value) {
      setImageSourceBackSideValidationError(validationHelpers.validateImageSource(event.detail.value))
    } else {
      setImageSourceBackSideValidationError('')
    }
  }

  const onChangeSearchTerms = (event: any): void => {
    if (
      (product && (event.detail.value === product.searchTerms || (!event.detail.value && !product.searchTerms))) ||
      (!product && !event.detail.value)
    ) {
      setSearchTermsValidationError('')
      setSearchTerms(undefined)
      return
    }
    setSearchTerms(event.detail.value)
    if (event.detail.value) {
      setSearchTermsValidationError(validationHelpers.validateImageSource(event.detail.value))
    } else {
      setSearchTermsValidationError('')
    }
  }

  const onChangeListed = (checked: boolean): void => {
    if (
      (product && checked === product.listed) ||
      (!product && checked)
    ) {
      setListed(undefined)
      return
    }
    setListed(checked)
  }

  const onChangeHasBarcode = (checked: boolean): void => {
    if (
      (product && checked === product.hasBarcode) ||
      (!product && checked)
    ) {
      setHasBarcode(undefined)
      return
    }
    setHasBarcode(checked)
  }

  const onChangeBarcodeFormat = (event: any): void => {
    const val = !event.target.value || event.target.value === '' ? null : event.target.value
    if (
      (product && (val === product.barcodeFormat || (!val && !product.barcodeFormat))) ||
      (!product && !val)
    ) {
      // setBarcodeFormatValidationError('')
      setBarcodeFormat(undefined)
      return
    }
    setBarcodeFormat(val)
    // if (val) {
    //   setBarcodeFormatValidationError(validationHelpers.validateProductTerms(val))
    // } else {
    //   setBarcodeFormatValidationError('')
    // }
  }

  const onChangeHasPin = (checked: boolean): void => {
    if (
      (product && checked === product.hasPin) ||
      (!product && checked)
    ) {
      setHasPin(undefined)
      return
    }
    setHasPin(checked)
  }

  const onClickSaveButton = (event: any): void => {
    event.preventDefault()
    const updatedProduct: ProductInput = {}
    if (product && product.id) {
      updatedProduct.id = product.id
    }
    // We're sending updateAt so that we can compare it with what comes back and determine whether
    // what we received has been updated by the server:
    if (genericProductId !== undefined && (!product || genericProductId !== product.genericProductId)) {
      updatedProduct.genericProductId = genericProductId || null
    }
    if (referenceUrl !== undefined && (!product || referenceUrl !== product.referenceUrl)) {
      updatedProduct.referenceUrl = referenceUrl || null
    }
    if (termsEn !== undefined && (!product || termsEn !== product.termsEn)) {
      updatedProduct.termsEn = termsEn || null
    }
    if (termsUrl !== undefined && (!product || termsUrl !== product.termsUrl)) {
      updatedProduct.termsUrl = termsUrl || null
    }
    if (instructionsEn !== undefined && (!product || instructionsEn !== product.instructionsEn)) {
      updatedProduct.instructionsEn = instructionsEn || null
    }
    if (instructionsUrl !== undefined && (!product || instructionsUrl !== product.instructionsUrl)) {
      updatedProduct.instructionsUrl = instructionsUrl || null
    }
    if (imageSourceFrontSide !== undefined && (!product || imageSourceFrontSide !== product.imageSourceFrontSide)) {
      updatedProduct.imageSourceFrontSide = imageSourceFrontSide || null
    }
    if (imageSourceBackSide !== undefined && (!product || imageSourceBackSide !== product.imageSourceBackSide)) {
      updatedProduct.imageSourceBackSide = imageSourceBackSide || null
    }
    if (searchTerms !== undefined && (!product || searchTerms !== product.searchTerms)) {
      updatedProduct.searchTerms = searchTerms || null
    }
    if (listed !== undefined && (!product || listed !== product.listed)) {
      updatedProduct.listed = listed
    }
    if (hasBarcode !== undefined && (!product || hasBarcode !== product.hasBarcode)) {
      updatedProduct.hasBarcode = hasBarcode
    }
    if (barcodeFormat !== undefined && (!product || barcodeFormat !== product.barcodeFormat)) {
      updatedProduct.barcodeFormat = barcodeFormat || null
    }
    if (hasPin !== undefined && (!product || hasPin !== product.hasPin)) {
      updatedProduct.hasPin = hasPin
    }
    onSave(updatedProduct)
  }

  let listedDisplay = product ? product.listed === true : true
  let listedMerchantNote
  if (listed !== undefined) {
    listedDisplay = listed
  }
  if (product && product.merchant && listed !== product.merchant.listed) {
    if (product && product.merchant && product.merchant.listed) {
      listedMerchantNote = 'Note that the merchant of this product is marked as "listed".'
    } else {
      listedMerchantNote = 'Note that the merchant of this product is marked as "unlisted", so the member will not see this product even if you mark it as listed here.'
    }
  }

  // ===================================================================================================================
  // Rendering:
  let barcodeFormatInput: JSX.Element | undefined
  if (hasBarcodeDisplay) {
    const barcodeTypeOptions = Object.values(BarcodeType)
      .map(format => (
        <MenuItem key={format} value={format}>{format}</MenuItem>
      ))
    barcodeFormatInput = (
      <div className='withStandardBottomMargin'>
        <FormControl
          variant='outlined'
          fullWidth
        >
          <InputLabel id='select-time-label'>Barcode format</InputLabel>
          <Select
            labelId='select-time-label'
            id='select-time'
            value={barcodeFormat !== undefined ? barcodeFormat : (product ? product.barcodeFormat : '')}
            onChange={onChangeBarcodeFormat}
            label='Barcode format'
          >
            <MenuItem key='NULL' value=''>(none)</MenuItem>
            {barcodeTypeOptions}
          </Select>
        </FormControl>
      </div>
    )
  }

  return (
    <>
      <form onSubmit={onClickSaveButton}>
        <FormItem
          label='Generic Product ID'
          validationError={genericProductIdValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeGenericProductId}
            placeholder='generic product ID'
            value={genericProductId !== undefined ? genericProductId : (product ? product.genericProductId : '')}
          />
        </FormItem>
        <FormItem
          label='Reference Url'
          validationError={referenceUrlValidationError}
          withBottomMargin
        >
          <IonInput
            type='url'
            inputmode='url'
            onIonChange={onChangeReferenceUrl}
            placeholder='Reference Url'
            value={referenceUrl !== undefined ? referenceUrl : (product ? product.referenceUrl : '')}
          />
        </FormItem>
        <FormItem
          label='Terms of Use (Text)'
          validationError={termsEnValidationError}
          withBottomMargin
        >
          <Editor
            apiKey='dv7dwodu7u7ujzaik6cr3g42xsq8jdtmbcovnwmsuniiindk'
            textareaName='TOS'
            onEditorChange={onChangeTermsEn}
            // init={{ min_height: 300, max_height: 600, browser_spellcheck: true, contextmenu: false, autosave_interval: '15s' }}
            init={
              {
                content_css: './styles.css',
                min_height: 300,
                max_width: 600,
                browser_spellcheck: true,
                contextmenu: false,
                autosave_interval: '15s',
              }
            }
            value={(termsEn !== undefined) ? termsEn : (product && product.termsEn ? product.termsEn : '')}
            plugins='print preview paste importcss searchreplace autolink autosave save directionality code visualblocks
            visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc
              insertdatetime advlist lists wordcount textpattern noneditable help charmap emoticons'
            toolbar='undo redo | bold italic underline strikethrough forecolor | fontselect fontsizeselect formatselect |
            alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |
              pagebreak | charmap emoticons | fullscreen  preview save print | template link anchor codesample | ltr rtl'
          />
          {/* <IonInput
            onIonChange={onChangeTermsEn}
            placeholder='Terms of use'
            value={termsEn !== undefined ? termsEn : (product ? product.termsEn : '')}
          /> */}
        </FormItem>
        <FormItem
          label='Terms of Use (Hyperlink)'
          validationError={termsUrlValidationError}
          withBottomMargin
        >
          <IonInput
            type='url'
            inputmode='url'
            onIonChange={onChangeTermsUrl}
            placeholder='Terms of use Url'
            value={termsUrl !== undefined ? termsUrl : (product ? product.termsUrl : '')}
          />
        </FormItem>
        <FormItem
          label='Instructions (Text)'
          validationError={instructionsEnValidationError}
          withBottomMargin
        >
          <Editor
            apiKey='dv7dwodu7u7ujzaik6cr3g42xsq8jdtmbcovnwmsuniiindk'
            textareaName='INS'
            onEditorChange={onChangeInstructionsEn}
            // init={{ min_height: 300, max_height: 600, browser_spellcheck: true, contextmenu: false, autosave_interval: '15s' }}
            init={
              {
                content_css: './styles.css',
                min_height: 300,
                max_width: 600,
                browser_spellcheck: true,
                contextmenu: false,
                autosave_interval: '15s',
              }
            }
            value={(instructionsEn !== undefined) ? instructionsEn : (product && product.instructionsEn ? product.instructionsEn : '')}
            plugins='print preview paste importcss searchreplace autolink autosave save directionality code visualblocks
            visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc
              insertdatetime advlist lists wordcount textpattern noneditable help charmap emoticons'
            toolbar='undo redo | bold italic underline strikethrough forecolor | fontselect fontsizeselect formatselect |
            alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |
              pagebreak | charmap emoticons | fullscreen  preview save print | template link anchor codesample | ltr rtl'
          />
        </FormItem>
        <FormItem
          label='Instructions (Hyperlink)'
          validationError={instructionsUrlValidationError}
          withBottomMargin
        >
          <IonInput
            type='url'
            inputmode='url'
            onIonChange={onChangeInstructionsUrl}
            placeholder='Instructions Url'
            value={instructionsUrl !== undefined ? instructionsUrl : (product ? product.instructionsUrl : '')}
          />
        </FormItem>
        <FormItem
          label='Gift Card Front Side Image'
          validationError={imageSourceFrontSideValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeImageSourceFrontSide}
            placeholder='Gift Card Front Side Image'
            value={imageSourceFrontSide !== undefined ? imageSourceFrontSide : (product ? product.imageSourceFrontSide : '')}
          />
        </FormItem>
        {/* <FormItem label="Main Product ImageSource Back Side" validationError={imageSourceBackSideValidationError} withBottomMargin> */}
        {/*  <IonInput */}
        {/*    onIonChange={onChangeImageSourceBackSide} */}
        {/*    placeholder="Main Product ImageSource Back Side" */}
        {/*    value={imageSourceBackSide !== undefined ? imageSourceBackSide : (product ? product.imageSourceBackSide : '')} */}
        {/*  /> */}
        {/* </FormItem> */}
        <FormItem
          label='Search Terms'
          validationError={searchTermsValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeSearchTerms}
            placeholder='search terms, comma delimited'
            value={searchTerms !== undefined ? searchTerms : (product ? product.searchTerms : '')}
          />
        </FormItem>
        <FormCheckbox
          isChecked={listedDisplay}
          label='Listed'
          // className='withStandardBottomMargin'
          onChange={onChangeListed}
        />
        <div className='smallText lightText withStandardBottomMargin'>
          Uncheck to hide this product from the members. <strong>{listedMerchantNote}</strong>
        </div>
        <FormCheckbox
          isChecked={hasBarcodeDisplay}
          label='Has a barcode or QR code'
          className='withStandardBottomMargin'
          onChange={onChangeHasBarcode}
        />
        {barcodeFormatInput}
        <FormCheckbox
          isChecked={hasPinDisplay}
          label='Has PIN'
          className='withStandardBottomMargin'
          onChange={onChangeHasPin}
        />
        <div className='formButtonWrapper'>
          <IonButton
            color='light'
            className='withStandardRightMargin'
            onClick={onGoBack}
          >
            Back
          </IonButton>
          <SubmitButton
            onClick={onClickSaveButton}
            disabled={!isDirty || !isValid}
          >
            Save
          </SubmitButton>
        </div>
      </form>
    </>
  )
}

export default ProductForm
