import serviceData from './serviceData'

let isFetchingLatestVersion = false

export default async (): Promise<string | undefined> => {
  if (isFetchingLatestVersion) {
    throw new Error('Already fetching latest version.')
  }

  try {
    isFetchingLatestVersion = true

    const response = await fetch(`/meta.json?t=${new Date().getTime()}`)
    if (!response.ok) {
      console.error('versionUtils.fetchLatestVersion: failed.', response)
      return
    }
    const body = await response.json()
    serviceData.setLatestVersion(body.version)
    // console.log(`versionUtils.fetchLatestVersion: versionUtils.latestVersion=${versionUtils.latestVersion}`);
    isFetchingLatestVersion = false
    return body.version
  } catch (error) {
    console.error(error)
  }
}
