import moment from 'moment'
import { IonButton, IonInput, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react'
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import './styles.css'
import type { AsyncTaskInput, AsyncTaskMetadata } from '../../../lib/core/definitions'
import { AsyncTaskType, FiatCurrency, FundType, InventoryImportFormat } from '../../../lib/core/enums'
import type { PreflightImportStoredProductsDataResult } from '../../../lib/core/helpers/models/asyncTask/preflightImportStoredProductsData'
import type { MerchantsQueryData, MerchantsQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../../services/apollo'
import coreHelpers from '../../../lib/core/helpers'
import FormCheckbox from '../../../components/FormCheckbox/FormCheckbox'
import FormItem from '../../../components/FormItem/FormItem'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../helpers/validationHelpers'

type Props = {
  productId?: string
  isProcessing?: boolean
  showUiMessage: (message: string) => void
  onSubmit: (asyncTaskInput: AsyncTaskInput) => void
  onBack: () => void
}

const ImportStoredProductsForm: React.FC<Props> = (props): JSX.Element => {
  const {
    productId: productIdFromParams,
    isProcessing,
    showUiMessage,
    onSubmit,
    onBack,
  } = props

  // ===================================================================================================================
  // State:
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [productId, setProductId] = useState<string | undefined>()
  const [productIdValidationError, setProductIdValidationError] = useState<string | undefined>()
  const [importIdPrefix, setImportIdPrefix] = useState(moment().format('YYMMDDhhmm'))
  const [importIdPrefixValidationError, setImportIdPrefixValidationError] = useState<string | undefined>()
  const [poNumber, setPoNumber] = useState<string | undefined>()
  const [poNumberValidationError, setPoNumberValidationError] = useState<string | undefined>()
  const [source, setSource] = useState<string | undefined>()
  const [sourceValidationError, setSourceValidationError] = useState<string | undefined>()
  const [format, setFormat] = useState<InventoryImportFormat | undefined>()
  const [formatValidationError, setFormatValidationError] = useState<string | undefined>()
  const [importData, setImportData] = useState<string | undefined>()
  const [importDataValidationError, setImportDataValidationError] = useState<string | undefined>()
  const [preflightResult, setPreflightResult] = useState(undefined as PreflightImportStoredProductsDataResult | undefined)
  const [preflightOk, setPreflightOk] = useState<boolean | undefined>()
  const [preflightError, setPreflightError] = useState<string | undefined>()
  const [dryrun, setDryrun] = useState(false)

  // ===================================================================================================================
  // Effect Handlers:
  const { data: userMerchantsData } = useQuery<MerchantsQueryData, MerchantsQueryVariables>(
    apollo.queries.merchants(true),
  )
  const merchants = userMerchantsData ? userMerchantsData.merchants : undefined

  // ===================================================================================================================
  // Helpers:
  const isDirty = (
    !!productId ||
    !!productIdFromParams ||
    !!importIdPrefix ||
    !!poNumber ||
    !!importData ||
    !!source ||
    !!format
  )
  const isValid = (
    !productIdValidationError &&
    !importIdPrefixValidationError &&
    !poNumberValidationError &&
    !sourceValidationError &&
    !importDataValidationError &&
    !!importData &&
    !!format
  )

  const resetForm = (): void => {
    setProductId('')
    setImportIdPrefix('')
    setPoNumber('')
    setImportData('')
    setSource('')
    setFormat(InventoryImportFormat.INCOMM)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeProductId = (event: any): void => {
    setProductId(event.detail.value)
    if (event.detail.value) {
      setProductIdValidationError(validationHelpers.validateUuid(event.detail.value))
    } else {
      setProductIdValidationError('')
    }
  }

  const onChangeImportIdPrefix = (event: any): void => {
    setImportIdPrefix(event.detail.value)
    // if (event.detail.value) {
    //   setImportIdPrefixValidationError(validationHelpers.validateEmail(event.detail.value));
    // } else {
    //   setImportIdPrefixValidationError('')
    // }
  }

  const onChangePoNumber = (event: any): void => {
    setPoNumber(event.detail.value)
    // if (event.detail.value) {
    //   setPoNumberValidationError(validationHelpers.validateEmail(event.detail.value));
    // } else {
    //   setPoNumberValidationError('')
    // }
  }

  const onChangeSource = (event: any): void => {
    setSource(event.detail.value)
    // if (event.detail.value) {
    //   setSourceValidationError(validationHelpers.validateEmail(event.detail.value));
    // } else {
    //   setSourceValidationError('')
    // }
  }

  const onChangeFormat = (event: any): void => {
    setFormat(event.detail.value)
    if (!Object.values(InventoryImportFormat).includes(event.detail.value)) {
      setFormatValidationError('not a valid format')
    } else {
      setFormatValidationError('')
    }
  }

  const onChangeDryrun = (checked: boolean): void => {
    setDryrun(checked)
  }

  const onChangeImportData = (event: any): void => {
    setImportData(event.detail.value)
    if (event.detail.value) {
      // setImportDataValidationError(validateImportData(event.detail.value));
    } else {
      setImportDataValidationError('')
    }
  }

  const onPreflightData = (): void => {
    if (!importData || !format) {
      return
    }
    const result = coreHelpers.models.asyncTask.preflightImportStoredProductsData(
      importData,
      productId || productIdFromParams || '',
      importIdPrefix,
      poNumber || '',
      source || '',
      format,
      merchants,
    )
    console.log('Preflight result:', result)
    setPreflightResult(result)
    setPreflightOk(result.ok)
    setPreflightError(result.error || '')
  }

  const onFormSubmit = (event: any): void => {
    event.preventDefault()
    if (!isValid) {
      showUiMessage('Input invalid. Please fix and try again')
      return
    }
    const metadata: AsyncTaskMetadata = {
      format: format || InventoryImportFormat.GENERIC,
    }
    if (productId || productIdFromParams) {
      metadata.productId = productId || productIdFromParams
    }
    if (importIdPrefix) {
      metadata.importIdPrefix = importIdPrefix
    }
    if (source) {
      metadata.source = source
    }
    if (poNumber) {
      metadata.poNumber = poNumber
    }
    if (dryrun) {
      metadata.dryrun = dryrun
    }
    const task: AsyncTaskInput = {
      taskType: AsyncTaskType.IMPORT_STORED_PRODUCTS,
      userId: activeUserId,
      textData: importData,
      metadata,
    }
    console.log('ImportStoredProductsForm.onFormSubmit: calling onSubmit', { task })
    onSubmit(task)
  }

  let preflightSection: JSX.Element | undefined
  if (preflightResult) {
    let items
    if (Array.isArray(preflightResult.items) && preflightResult.items.length > 0) {
      items = preflightResult.items.map((item, index): JSX.Element => {
        const formattedAmount = item.amount
          ? coreHelpers.ui.formatAmount(
            item.amount,
            FundType.FIAT,
            FiatCurrency.USD,
            true,
            0,
          )
          : '-'

        if (item.ok) {
          return (
            <div key={item.importId}>
              <pre className='smallText'>
                Line {index + 1}:<br />
                ID={item.importId}<br />
                Merchant={item.merchantName}<br />
                Product ID={item.productId}<br />
                Product Option ID={item.productOptionId || '-'}<br />
                Amount={formattedAmount}<br />
                Discount={item.discount || '-'}<br />
                Code={item.code}<br />
                PIN={item.pin || '-'},<br />
                Status={item.status},<br />
                PO Number={item.poNumber || '-'}<br />
                Source={item.source || '-'}<br />
                URL={item.refUrl || '-'}
              </pre>
            </div>
          )
        }
        return (
          <div key={item.importId}>
            <pre className='preflight-error smallText'>
              Line {index}: Error: {item.error}
            </pre>
          </div>
        )
      })
    }

    let renderedPreflightResult: JSX.Element
    if (preflightError) {
      renderedPreflightResult = (
        <div>
          Result: <span className='preflight-error'>Failed</span>
        </div>
      )
    } else {
      renderedPreflightResult = (
        <div>
          Result: <span className='withSuccessBackground'>Passed</span>
        </div>
      )
    }

    let renderedPreflightError: JSX.Element | undefined
    if (preflightError) {
      renderedPreflightError = (
        <div>
          Error(s): <span className='preflight-error'>{preflightError}</span>
        </div>
      )
    }

    preflightSection = (
      <div className='section'>
        <h2>Preflight Report</h2>
        {renderedPreflightResult}
        {renderedPreflightError}
        <pre className='withDoubleBottomMargin'>
          {items}
        </pre>
        <div className='smallText lightText'>
          Note: This is just a preflight check. The import data may still fail validation during processing
          by the server. Make sure all amounts have a product option defined for this product.
        </div>
      </div>
    )
  }

  let formatInstructions: JSX.Element | undefined
  if (format === InventoryImportFormat.GENERIC) {
    formatInstructions = (
      <div className='smallText lightText withStandardBottomMargin'>
        <div>
          Specify a single inventory item in each row.
          The fields are separated by commas.
          Each line must have the following fields:
        </div>
        <ol>
          <li><strong>import_id</strong> - a unique ID to trace the item back to your import data (optional)</li>
          <li><strong>product_id</strong> - the ID of the product (can be left empty, if only one product is used and it is referenced above)</li>
          <li><strong>product_option_id</strong> - the ID of the product option (this or the amount are required)</li>
          <li><strong>amount</strong> - the amount; note: A product option must already exist for this amount! (will be ignored if product_option_is is given)</li>
          <li><strong>discount</strong> - the discount we received, i.e. <strong>5.25</strong> for 5.25%, which will be saved as 5250 (optional)</li>
          <li><strong>code</strong> - the gift card code</li>
          <li><strong>pin</strong> - the gift card PIN (optional)</li>
          <li><strong>status</strong> - the status of the inventory item (set to <strong>available</strong> if left empty here)</li>
          <li><strong>received_at</strong> - date we paid for this inventory item (optional)</li>
          <li><strong>paid_at</strong> - date we paid for this inventory item (optional)</li>
          <li><strong>url</strong> - reference URL for this gift card</li>
        </ol>
        <div>A minimum import can look something like this:</div>
        <pre>
          ,,,10,,8723567423487234,4642,,,,
          <br />
          ,,,20,,8923467698234352,8765,,,,
        </pre>
        <div>
          This sample assumes that a product ID was entered in above form (should be pre-filled).
          Empty lines and lines that start with a pound sign (#) are ignored.
        </div>
      </div>
    )
  }

  let productIdInput: JSX.Element | undefined
  if (format === InventoryImportFormat.GENERIC || format === InventoryImportFormat.BUY_A_TAB) {
    productIdInput = (
      <FormItem
        label='Product ID'
        validationError={productIdValidationError}
        withBottomMargin
      >
        <IonInput
          autofocus
          onIonChange={onChangeProductId}
          type='text'
          placeholder='product ID'
          value={productId || productIdFromParams}
        />
      </FormItem>
    )
  }

  let importIdPrefixInput: JSX.Element | undefined
  if (format === InventoryImportFormat.GENERIC) {
    importIdPrefixInput = (
      <>
        <FormItem
          label='Import ID Prefix'
          validationError={importIdPrefixValidationError}
        >
          <IonInput
            onIonChange={onChangeImportIdPrefix}
            type='text'
            placeholder='import ID prefix'
            value={importIdPrefix}
          />
        </FormItem>
        <div className='smallText lightText withStandardBottomMargin'>
          If you don&apos;t specify import IDs in your import data, an ID will be assigned automatically.
          Since in the database this ID has to be unique, this prefix is used for each import. The individual
          item IDs are composed of <strong>prefix-row</strong>. The prefix is ignored if you specified
          import IDs in your import data.
        </div>
      </>
    )
  }

  let sourceInput: JSX.Element | undefined
  if (format === InventoryImportFormat.GENERIC) {
    sourceInput = (
      <>
        <FormItem
          label='Source'
          validationError={sourceValidationError}
        >
          <IonInput
            onIonChange={onChangeSource}
            type='text'
            placeholder='source'
            value={source}
          />
        </FormItem>
        <div className='smallText lightText withStandardBottomMargin'>
          Enter something to identify the source of this import batch. If left empty, it means the inventory
          has come from the merchant directly. The source can also be specified in each line of the import data.
        </div>
      </>
    )
  }

  const formatOptions = Object.values(InventoryImportFormat).map(f => (
    <IonSelectOption
      key={f}
      value={f}
    >
      {f}
    </IonSelectOption>
  ))

  return (
    <form onSubmit={onFormSubmit}>
      <FormItem
        label='Format'
        validationError={formatValidationError}
        withBottomMargin
      >
        <IonSelect
          value={format}
          interface='popover'
          // okText='OK'
          // cancelText='Cancel'
          onIonChange={onChangeFormat}
        >
          {formatOptions}
        </IonSelect>
      </FormItem>
      {productIdInput}
      {importIdPrefixInput}
      <FormItem
        label='Purchase Order Number'
        validationError={poNumberValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangePoNumber}
          type='text'
          placeholder='purchase order number'
          value={poNumber}
        />
      </FormItem>
      {sourceInput}
      <FormItem
        label='Import Data'
        validationError={importDataValidationError}
      >
        <IonTextarea
          // autoGrow
          rows={12}
          onIonChange={onChangeImportData}
          value={importData}
          // style={{ minHeight: '200px' }}
        />
      </FormItem>
      {formatInstructions}
      {preflightSection}
      <FormCheckbox
        isChecked={dryrun}
        label='This is a dry-run (no inventory will actually be saved to the database)'
        className='withDoubleTopMargin withDoubleBottomMargin'
        onChange={onChangeDryrun}
      />
      <div className='formButtonWrapper'>
        <IonButton
          className='withStandardRightMargin'
          onClick={onPreflightData}
          disabled={!isDirty || !isValid || isProcessing}
        >
          Preflight Data
        </IonButton>
        <SubmitButton
          onClick={onFormSubmit}
          disabled={preflightOk && (!isDirty || !isValid || isProcessing)}
        >
          Import!
        </SubmitButton>
      </div>
    </form>
  )
}

export default ImportStoredProductsForm
