import { gql } from '@apollo/client'

export default gql`
  query Q($filter: ContactListFilter!) {
    contacts (filter: $filter) {
      id
      chatId
      favorite
      nickname
      recordStatus
      createdAt
      updatedAt
    }
  }`
