import { gql } from '@apollo/client'

export default gql`
  mutation M($shoppingCart: ShoppingCartInput!) {
    upsertShoppingCart (shoppingCart: $shoppingCart) {
      id
      userId
      orderId
      donationTargetId
      paymentTransactionId
      status
      paymentProcessor
      paymentAmount
      paymentFundType
      paymentCurrency
      paymentExchangeRate
      paymentExchangeRateUpdatedAt
      paymentStripeToken
      paymentStripeSecret
      paymentRemoteId
      paymentCryptoAddress
      paymentStatus
      paymentStatusUrl
      paymentQrcodeUrl
      paymentCreatedAt
      paymentTimesOutAt
      paymentTimeout
      paymentConfirmations
      paymentConfirmationsNeeded
      paymentErrorCode
      paymentErrorMessage
      source
      metadata
      adminNotes
      recordStatus
      createdAt
      updatedAt
      items {
        id
        shoppingCartId
        productId
        productOptionId
        purchaseId
        productType
        productCount
        paymentAmount
        metadata
        adminNotes
        product {
          id
          merchantId
          offerId
          productType
          code
          hasBarcode
          barcodeFormat
          referenceUrl
          messageEn
          contentFrontSide
          contentBackSide
          termsEn
          termsUrl
          instructionsEn
          instructionsUrl
          imageSourceFrontSide
          imageSourceBackSide
          isMainProduct
          hasPin
          stock
          listed
          recordStatus
          createdAt
          updatedAt
          productOptions {
            id
            productId
            orderIndex
            labelEn
            amount
            fundType
            currency
            reward
            discount
            inventory
            listed
            source
            recordStatus
            createdAt
            updatedAt
          }
          merchant {
            id
            name
            description
            url
            smallLogoImageSource
            largeLogoImageSource
            balanceLookupUri
          }
        }
      }
    }
  }
`
