import React, { useState, useEffect, useRef } from 'react'
import { IonButton, IonInput, IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react'

import type { Purchase } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import FormItem from '../../../components/FormItem/FormItem'
import validationHelpers from '../../../helpers/validationHelpers'

enum InputMode {
  NEW_BALANCE = 'new-balance',
  AMOUNT_REMOVED = 'amount-added-or-removed',
  AMOUNT_ADDED = 'amount-added',
}

type Props = {
  purchase: Purchase;
  isProcessing?: boolean;
  onChangeBalance: (balance: number | undefined) => void;
  onSave: (balance: number, notes?: string) => void;
  onCancel: () => void;
};

const getIsDirty = (balance: number, inputValue: number | undefined, inputMode: InputMode): boolean => {
  if (inputValue === undefined) {
    return false
  }
  if (inputMode === InputMode.NEW_BALANCE) {
    return inputValue !== balance
  }
  return inputValue > 0
}

const BalanceForm: React.FC<Props> = ({
  purchase,
  isProcessing,
  onChangeBalance,
  onSave,
  onCancel,
}: Props): JSX.Element | null => {
  let displayBalance = purchase && purchase.balance
    ? coreHelpers.ui.getDisplayMoneyAmount(purchase.balance, purchase.fundType, purchase.currency)
    : 0
  if (displayBalance < 0.01) {
    displayBalance = 0
  }
  const [inputValue, setInputValue] = useState<number | undefined>()
  const [inputMode, setInputMode] = useState(InputMode.NEW_BALANCE)
  const [balanceValidationError, setBalanceValidationError] = useState<string | undefined>()
  const [amountAddedOrRemovedValidationError, setAmountAddedOrRemovedValidationError] = useState<string | undefined>()
  const [notes, setNotes] = useState<string | undefined>()
  const amountInputRef = useRef<HTMLIonInputElement>(null)

  // ===================================================================================================================
  // Helpers:
  const isDirty = getIsDirty(displayBalance, inputValue, inputMode)
  const inputValueDisplay = coreHelpers.ui.getDisplayMoneyAmount(inputValue, purchase.fundType, purchase.currency)

  const resetForm = (): void => {
    setInputValue(undefined)
    setInputMode(InputMode.NEW_BALANCE)
  }

  const onClickCancel = (): void => {
    onCancel()
    resetForm()
  }

  const setIsValid = !balanceValidationError

  const setValidationError = (newValue: number | undefined, newInputMode?: InputMode): boolean => {
    const newDisplayValue = coreHelpers.ui.getDisplayMoneyAmount(newValue, purchase.fundType, purchase.currency)
    if (!newDisplayValue) {
      setBalanceValidationError('')
      setAmountAddedOrRemovedValidationError('')
      return true
    }
    let error = ''
    switch (newInputMode || inputMode) {
      case InputMode.NEW_BALANCE:
        error = validationHelpers.validatePurchaseBalance(newDisplayValue)
        setBalanceValidationError(error)
        setAmountAddedOrRemovedValidationError('')
        return !error
      case InputMode.AMOUNT_REMOVED:
        error = validationHelpers.validatePurchaseBalanceChange(displayBalance, newDisplayValue, false)
        setAmountAddedOrRemovedValidationError(error)
        setBalanceValidationError('')
        return !error
      case InputMode.AMOUNT_ADDED:
        error = validationHelpers.validatePurchaseBalanceChange(displayBalance, newDisplayValue, true)
        setAmountAddedOrRemovedValidationError(error)
        setBalanceValidationError('')
        return !error
    }
    return true
  }

  const updateParentBalance = (newValue: number | undefined, newInputMode?: InputMode): void => {
    if (!purchase) {
      return
    }
    switch (newInputMode || inputMode) {
      case InputMode.NEW_BALANCE:
        onChangeBalance(newValue)
        return
      case InputMode.AMOUNT_REMOVED:
        onChangeBalance((purchase.balance || 0) - (newValue || 0))
        return
      case InputMode.AMOUNT_ADDED:
        onChangeBalance((purchase.balance || 0) + (newValue || 0))
    }
  }

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Product:
  useEffect((): void => {
    resetForm()
  }, [purchase])

  if (!purchase) {
    return null
  }

  // ===================================================================================================================
  // Event Handlers:
  const onClickSave = (): void => {
    if (inputValue === undefined) {
      return
    }
    if (inputMode === InputMode.NEW_BALANCE) {
      onSave(Math.ceil(inputValue), (notes === '') ? undefined : notes)
    } else {
      const amountAddedOrRemoved = inputMode === InputMode.AMOUNT_ADDED ? inputValue : (-1) * inputValue
      onSave(
        (purchase.balance || 0) + amountAddedOrRemoved,
        (notes === '') ? undefined : notes,
      )
    }
  }

  const onChangeInputMode = (event: any): void => {
    setInputMode(event.detail.value)
    if (inputValue && setValidationError(inputValue, event.detail.value)) {
      updateParentBalance(inputValue, event.detail.value)
    }
    if (amountInputRef.current) {
      amountInputRef.current.focus()
    }
  }

  const onChangeInputValue = (event: any): void => {
    const internalValue = coreHelpers.ui.getInternalMoneyAmount(
      parseFloat(event.detail.value), purchase.fundType, purchase.currency)
    setInputValue(internalValue)
    if (setValidationError(internalValue)) {
      updateParentBalance(internalValue)
    }
  }

  const onChangeNotes = (event: any): void => {
    setNotes(event.detail.value)
  }

  return (
    <div className='section with100PercentWidth withCenteredColumnContent withStandardTopMargin'>
      <div className='with90PercentWidth withCenteredColumnContent'>
        <div className='withStandardBottomMargin'>
          <IonRadioGroup onIonChange={onChangeInputMode} value={inputMode}>
            <IonLabel>I am entering:</IonLabel>
            <IonItem lines='none' className='purchasePageBalanceFormInputModeChoice'>
              <IonLabel>the new card balance</IonLabel>
              <IonRadio slot='start' value={InputMode.NEW_BALANCE} className='purchasePageBalanceFormInputModeRadio' />
            </IonItem>

            <IonItem lines='none' className='purchasePageBalanceFormInputModeChoice'>
              <IonLabel>the amount removed</IonLabel>
              <IonRadio slot='start' value={InputMode.AMOUNT_REMOVED} className='purchasePageBalanceFormInputModeRadio' />
            </IonItem>

            <IonItem lines='none' className='purchasePageBalanceFormInputModeChoice'>
              <IonLabel>the amount added</IonLabel>
              <IonRadio slot='start' value={InputMode.AMOUNT_ADDED} className='purchasePageBalanceFormInputModeRadio' />
            </IonItem>
          </IonRadioGroup>
        </div>
        <div className='withCenteredColumnContent'>
          <div className='with50PercentWidth'>
            <FormItem
              validationError={balanceValidationError || amountAddedOrRemovedValidationError}
              withBottomMargin
            >
              <IonInput
                ref={amountInputRef}
                autofocus
                onIonChange={onChangeInputValue}
                type='number'
                inputmode='decimal'
                placeholder='balance'
                value={inputValue || inputValue === 0 ? inputValueDisplay.toString() : ''}
                className='purchasePageBalanceInput'
              />
            </FormItem>
          </div>
        </div>
        <div className='with100PercentWidth withDoubleBottomMargin'>
          <FormItem label='Notes'>
            <IonInput
              onIonChange={onChangeNotes}
              type='text'
              maxlength={255}
              inputmode='text'
              placeholder="i.e. 'climbing shoes'"
              value={notes}
            />
          </FormItem>
          <div className='formLabel'>
            {notes ? `${255 - notes.length} characters left` : ''}
          </div>
        </div>
      </div>
      <div>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onClickCancel}
        >
          Cancel
        </IonButton>
        <IonButton
          onClick={onClickSave}
          disabled={!isDirty || !setIsValid || isProcessing}
        >
          Save
        </IonButton>
      </div>
    </div>
  )
}

export default BalanceForm
