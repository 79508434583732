import type { User } from '../../../definitions'

const getOnboardingProgress = (user: User): any => {
  const onboardingProgress = {
    savingsPlan: false,
    bankingSetup: false,
  }
  // const onboardingProgress = {
  //   savingsPlan: (
  //     (Array.isArray(goals) && goals.length > 0)
  //     || (
  //       bankingUser
  //       && bankingUser.generalDepositSchedule
  //       && numberHelpers.isPositiveNumber(bankingUser.generalDepositAmount, false)
  //     )
  //   ),
  //   bankingSetup: (
  //     (user && (user.bankingStatus === RecordStatus.ACTIVE || user.bankingStatus === RecordStatus.PENDING))
  //     || (bankingUser && (bankingUser.remoteStatus === RemoteStatus.OK || bankingUser.remoteStatus === RemoteStatus.PROCESSING))
  //   ),
  // };
  if (user) {
    // if (hasUserCreatedSavingsPlanDuringOnboarding(user.onboardingStatus)) {
    //   onboardingProgress.savingsPlan = true;
    // }
    // if (hasUserCompletedBankingSetupDuringOnboarding(user.onboardingStatus)) {
    //   onboardingProgress.bankingSetup = true;
    // }
  }
  return onboardingProgress
}

export default getOnboardingProgress
