import React, { useRef, useState } from 'react'
import { IonInput, useIonViewDidLeave } from '@ionic/react'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import { AsyncTaskStatus, AsyncTaskType, CodeSignInStage, NotificationMethod } from '../../lib/core/enums'
import type { AsyncTask, AsyncTaskInput } from '../../lib/core/definitions'
import api from '../../services/api'
import FormItem from '../../components/FormItem/FormItem'
import logger from '../../services/logger'
import pageHelpers from '../../helpers/pageHelpers'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../helpers/validationHelpers'

type Props = {
  onNext: (asyncTask: AsyncTask) => void;
  showUiMessage: (message: string) => void;
};

const IdentForCodeForm: React.FC<Props> = (props): JSX.Element => {
  const { onNext, showUiMessage } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()

  const [ident, setIdent] = useState<string | undefined>()
  const [identValidationError, setIdentValidationError] = useState<string | undefined>()
  const identRef = useRef<HTMLIonInputElement>(null)
  const [codeSignInStage, setCodeSignInStage] = useState(CodeSignInStage.UNSET)
  const [isProcessing, setIsProcessing] = useState(false)

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewDidLeave(() => {
    setIdent(undefined)
    setIdentValidationError(undefined)
    setCodeSignInStage(CodeSignInStage.UNSET)
    setIsProcessing(false)
  })

  // ===================================================================================================================
  // Event Handlers:
  const onChangeIdent = (event: any): void => {
    // console.log('SignInPage.onChangeIdent called.', { event })
    const val = event.detail.value || pageHelpers.getFormInputValue(identRef)
    setIdent(val)
    if (val) {
      setIdentValidationError(validationHelpers.validateUserIdent(val))
    }
  }

  const onBlurIdent = (): void => {
    if (!ident && !pageHelpers.getFormInputValue(identRef)) {
      setIdentValidationError('required')
    }
  }

  const onSubmit = (event: any): void => {
    event.preventDefault()
    // console.log('SignInPage.IdentForCodeForm.onSubmit called.', { ident })

    let identVal = (ident || '').trim()
    if (!identVal) {
      return
    }

    try {
      const phoneNumberInfo = parsePhoneNumber(identVal, 'US')
      if (phoneNumberInfo && phoneNumberInfo.isValid()) {
        identVal = phoneNumberInfo.number as string
      }
    } catch (error) {
      console.log('SignInPage.onSubmitPasswordForm: input not a valid phone number.')
    }

    setCodeSignInStage(CodeSignInStage.NOTIFICATION_REQUESTED)

    const task: AsyncTaskInput = {
      userIdent: identVal,
      taskType: AsyncTaskType.TOKEN_SIGN_IN,
      notificationMethod: NotificationMethod.SMS,
    }
    setIsProcessing(true)
    api.upsertAsyncTask(
      task,
      false,
      (task: AsyncTask): boolean => (
        task.taskStatus === AsyncTaskStatus.NOTIFICATION_SENT ||
          task.taskStatus === AsyncTaskStatus.FINISHED
      ),
      apolloClient,
    ).then((reloadedTask) => {
      logger.info('SignInPage: upsertAsyncTask returned.', { reloadedTask })
      setIsProcessing(false)
      if (reloadedTask && reloadedTask.taskStatus === AsyncTaskStatus.NOTIFICATION_SENT) {
        setCodeSignInStage(CodeSignInStage.NOTIFICATION_SENT)
        onNext(reloadedTask)
      } else {
        showUiMessage('Failed to send out the unlock code.')
        setIdentValidationError('Failed to verify member info')
        setCodeSignInStage(CodeSignInStage.FAILED)
      }
    }, (error) => {
      logger.error('SignInPage: upsertAsyncTask returned error.', { error })
      setIsProcessing(false)
      showUiMessage('Failed to send out the unlock code.')
      setCodeSignInStage(CodeSignInStage.FAILED)
    })
  }

  // ===================================================================================================================
  // Event Handlers:
  let progressText: JSX.Element | undefined
  if (codeSignInStage === CodeSignInStage.UNSET) {
    progressText = (
      <div className='lightText withDoubleBottomMargin'>
        Enter your Mimble handle, email, or phone number to receive a 6-digit
        code that you can sign in with.
      </div>
    )
  }

  return (
    <form className='ident-for-code-form' onSubmit={onSubmit}>
      {progressText}
      <FormItem
        label='Handle/Email/Phone Number'
        validationError={identValidationError}
        withBottomMargin
      >
        <IonInput
          ref={identRef}
          id='ident'
          name='ident'
          autofocus
          value={ident}
          placeholder='i.e. @JoeTheSailor or joe-schmidt@supermail.net or 1234567890'
          required
          autocomplete='username'
          onIonChange={onChangeIdent}
          onIonBlur={onBlurIdent}
          onIonInput={onChangeIdent}
          // onInput={(event: any) => { console.log('SignInPage.onInput[ident] called.', event.detail.value) }}
          // onIonFocus={enableInputs}
        />
      </FormItem>
      <div className='formButtonWrapper'>
        <SubmitButton
          onClick={onSubmit}
          disabled={!ident || !!identValidationError || isProcessing}
          isProcessing={isProcessing}
        >
          Send Unlock Code
        </SubmitButton>
      </div>
    </form>
  )
}

export default IdentForCodeForm
