import type { MarketplaceListFilter } from '../../../lib/core/definitions'
import type { MarketplaceSearchFilter } from '../definitions'

const index = {
  getListFilter: (searchFilter: MarketplaceSearchFilter | undefined): MarketplaceListFilter | undefined => {
    if (!searchFilter) {
      return
    }
    if (
      !searchFilter.categoryTag &&
      !searchFilter.searchText &&
      !searchFilter.all &&
      !searchFilter.featured
    ) {
      return
    }
    return {
      offset: 0,
      limit: 1000,
      categoryTagId: searchFilter.categoryTag && searchFilter.categoryTag.id,
      searchText: searchFilter.searchText,
      all: searchFilter.all,
      featured: searchFilter.featured,
    }
  },
}

export default index
