import type { PurchaseTransfer, User } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'

const validateSignedInUser = (
  purchaseTransfer: PurchaseTransfer | undefined,
  signedInUser: User | undefined,
): boolean | string => {
  if (!signedInUser || !signedInUser.id || !purchaseTransfer) {
    return true
  }
  if (purchaseTransfer.toUserId && !coreHelpers.models.compareId(signedInUser.id, purchaseTransfer.toUserId)) {
    return `ID mismatch: recipient: ${purchaseTransfer.toUserId}, yours: ${signedInUser.id}`
  }
  if (
    purchaseTransfer.toUserUsername &&
    signedInUser.username &&
    purchaseTransfer.toUserUsername !== signedInUser.username
  ) {
    return `Handle mismatch: recipient: @${purchaseTransfer.toUserUsername}, yours: @${signedInUser.username}`
  }
  if (
    purchaseTransfer.toUserEmail &&
    signedInUser.email &&
    purchaseTransfer.toUserEmail !== signedInUser.email
  ) {
    return `Email mismatch: recipient: ${purchaseTransfer.toUserEmail}, yours: ${signedInUser.email}`
  }
  if (
    purchaseTransfer.toUserPhoneNumber &&
    signedInUser.phoneNumber &&
    purchaseTransfer.toUserPhoneNumber !== signedInUser.phoneNumber
  ) {
    return `Phone number mismatch: recipient: ${purchaseTransfer.toUserPhoneNumber}, yours: ${signedInUser.phoneNumber}`
  }
  return true
}

export default validateSignedInUser
