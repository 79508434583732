import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, PurchaseTransferListFilter, PurchaseTransfer } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  PurchaseTransferQueryData,
  PurchaseTransferQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'

const loadPurchaseTransfer = (
  purchaseTransferId: string,
  isInTargetStateFunc: ((purchaseTransfer: PurchaseTransfer) => boolean) | 'watch-updated-at' | undefined,
  listFilter: PurchaseTransferListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<PurchaseTransfer | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadPurchaseTransfer called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadPurchaseTransfer: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<PurchaseTransferQueryVariables> = {
      query: apollo.queries.purchaseTransfer,
      variables: { purchaseTransferId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<PurchaseTransferQueryData, PurchaseTransferQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.purchaseTransfer ||
            !resp.data.purchaseTransfer.id
          ) {
            logger.error('api.loadPurchaseTransfer.load: query did not return expected data.', { resp })
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((purchaseTransfer: PurchaseTransfer) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.purchaseTransfer.updatedAt
            activeIsInTargetStateFunc = (purchaseTransfer: PurchaseTransfer) => purchaseTransfer.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.purchaseTransfer)
          ) {
            const queryListVariables = clientOptions && clientOptions.updateList
              ? apollo.getPurchaseTransfersQueryVariables(listFilter, activeUserId as string)
              : undefined
            cache.updatePurchaseTransfer(
              resp.data.purchaseTransfer,
              queryListVariables,
              activeUserId as string,
              apolloClient,
            )
            // console.log('api.loadPurchaseTransfer.load: resolving with info.', resp.data.purchaseTransfer)
            resolve(resp.data.purchaseTransfer)
            return
          }

          // console.log('api.loadPurchaseTransfer.load: purchaseTransfer not in target state.',
          //   { order: resp.data.order })
          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadPurchaseTransfer.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadPurchaseTransfer.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadPurchaseTransfer
