import React, { useState } from 'react'

import './styles.css'
import type { Wallet } from '../../lib/core/definitions'
import { FiatCurrency, FundType, TokenName } from '../../lib/core/enums'
import coreHelpers from '../../lib/core/helpers'

type Props = {
  wallet: Wallet | null | undefined;
  className?: string;
};

let isShowingFiatBalanceTimeout: NodeJS.Timeout | undefined

const MimbleWalletBalance: React.FC<Props> = (props): JSX.Element | null => {
  const { wallet, className } = props

  // ===================================================================================================================
  // State:
  const [isShowingFiatBalance, setIsShowingFiatBalance] = useState(false)

  if (!wallet) {
    return null
  }

  let formattedWalletBalance = ''
  let balanceCurrency = ''
  if (isShowingFiatBalance) {
    const fiatAmount = coreHelpers.cryptoCurrency.convertMimbleTokenToFiat(
      wallet.balanceToken || 0,
    )
    formattedWalletBalance = coreHelpers.ui.formatAmount(
      fiatAmount,
      FundType.FIAT,
      FiatCurrency.USD,
      false,
    )
    balanceCurrency = FiatCurrency.USD
  } else {
    formattedWalletBalance = coreHelpers.ui.formatAmount(
      wallet.balanceToken,
      FundType.TOKEN,
      TokenName.MIMBLE_TOKEN,
      false,
    )
    balanceCurrency = TokenName.MIMBLE_TOKEN
  }

  // ===================================================================================================================
  // Event Handlers:
  const onShowFiatBalance = () => {
    const newValue = !isShowingFiatBalance
    setIsShowingFiatBalance(newValue)
    if (newValue && !isShowingFiatBalanceTimeout) {
      isShowingFiatBalanceTimeout = setTimeout(() => {
        setIsShowingFiatBalance(false)
        isShowingFiatBalanceTimeout = undefined
      }, 2500)
    }
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className={`mimble-wallet-balance ${className || ''}`} onClick={onShowFiatBalance}>
      <div className='withPointerCursor'>
        <span className='lightText withSmallRightMargin'>{balanceCurrency}</span>
        <span className='balance'>{formattedWalletBalance}</span>
      </div>
      <div className='smallText lightText withCenteredTextAlign withPointerCursor'>
        Mimble Token Balance
        <br />
        <span className='linkText'>tap to show USD</span>
      </div>
    </div>
  )
}

export default MimbleWalletBalance
