import type { ApolloClient } from '@apollo/client'
import type { User, UserInput, UserPrefs } from '../../lib/core/definitions'
import type { ReloadActiveUserOptions } from '../../contexts/MimbleDataContext/definitions'
import api from '../../services/api'
import logger from '../../services/logger'

const updateUserPref = (
  prefs: UserPrefs,
  activeUser: User,
  apolloClient: ApolloClient<any>,
  reloadActiveUser: (options?: ReloadActiveUserOptions) => Promise<User | undefined>,
  showUiMessage: (message: string) => void,
  successMessage = 'Successfully saved your changes.',
  failureMessage = 'Failed to save your changes. Please try again.',
): Promise<User | undefined> => {
  // console.log('pageHelpers.updateUserPref called.', { prefs })

  return new Promise((resolve, reject) => {
    const userInput: UserInput = {
      id: activeUser.id,
      prefs,
    }
    api.updateUser(
      userInput,
      activeUser,
      'watch-updated-at',
      undefined,
      apolloClient,
    ).then(() => {
      reloadActiveUser().then((reloadedActiveUser) => {
        if (showUiMessage && successMessage) {
          showUiMessage(successMessage)
        }
        resolve(reloadedActiveUser)
      }, (error) => {
        logger.error('pageHelpers.updateUserPref: received error.', { error })
        if (showUiMessage && failureMessage) {
          showUiMessage(failureMessage)
        }
        reject(error)
      })
    }, (error) => {
      logger.error('pageHelpers.updateUserPref: received error.', { error })
      if (showUiMessage && failureMessage) {
        showUiMessage(failureMessage)
      }
      reject(error)
    })
  })
}

export default updateUserPref
