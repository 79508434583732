import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, Purchase } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  PurchasesQueryData,
  PurchasesQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadPurchases = (
  variables: PurchasesQueryVariables,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Purchase[] | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadPurchases called.')

    if (!apolloClient) {
      logger.error('api.loadPurchases: no Apollo client available.')
      throw (new Error(ErrorCode.SYSTEM_ERROR))
    }

    const queryOptions: QueryOptions<PurchasesQueryVariables> = {
      query: apollo.queries.purchases,
      variables,
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    apolloClient.query<PurchasesQueryData, PurchasesQueryVariables>(queryOptions)
      .then((resp) => {
        if (
          !resp ||
          !resp.data ||
          !resp.data.purchases
        ) {
          logger.error('api.loadPurchases.load: query did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        resolve(resp.data.purchases)
      }, (error) => {
        logger.error('api.loadPurchases: error.', { error })
        reject(error)
      })
  })
)

export default loadPurchases
