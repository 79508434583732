import React, { useEffect, useState } from 'react'
import { IonPopover, IonSpinner } from '@ionic/react'
import { animated, useSpring } from 'react-spring'

import './styles.css'

enum AnimationState {
  PROCESSING,
  FINISHED,
  REMOVED
}

type OverlayAnimationProps = {
  state: AnimationState
  zIndex: number
  backgroundColor: string
  borderColor?: string
}

type ProcessingContentAnimationProps = {
  state: AnimationState
  zIndex: number
  width: number | string
  height: number | string
}

const animationProps = {
  overlay: {
    processing: {
      state: AnimationState.PROCESSING,
      zIndex: 1001,
      backgroundColor: 'rgba(255,255,255,.7)',
    },
    finished: {
      state: AnimationState.FINISHED,
      zIndex: 1001,
      backgroundColor: 'rgba(255,255,255,0)',
    },
    removed: {
      state: AnimationState.REMOVED,
      zIndex: -1001,
      backgroundColor: 'rgba(255,255,255,0)',
    },
  },
  content: {
    processing: {
      state: AnimationState.PROCESSING,
      zIndex: 1002,
      opacity: 1,
    },
    finished: {
      state: AnimationState.FINISHED,
      zIndex: 1002,
      opacity: 0,
    },
    removed: {
      state: AnimationState.REMOVED,
      zIndex: -1002,
      opacity: 0,
    },
  },
}

type Props = {
  caption?: string
  text?: string
  isProcessing?: boolean
  onClick?: () => void
}

const ProcessingOverlay: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('ProcessingOverlay.render called.', { props })
  const {
    caption,
    text,
    isProcessing,
    onClick,
  } = props

  // ===================================================================================================================
  // State:
  const [curIsProcessing, setCurIsProcessing] = useState<boolean | undefined>()
  const [isAnimating, setIsAnimating] = useState(false)

  const [overlayStyle, overlayAnimationRef] = useSpring<OverlayAnimationProps>(() => ({
    from: animationProps.overlay.processing,
    to: animationProps.overlay.finished,
    onRest: (ds) => {
      if (ds.value.state === AnimationState.FINISHED) {
        setIsAnimating(false)
        overlayAnimationRef.start(animationProps.overlay.removed)
      }
    },
    config: {
      duration: 400,
    },
  }))
  const [contentStyle, contentAnimationRef] = useSpring<ProcessingContentAnimationProps>(() => ({
    from: animationProps.content.processing,
    to: animationProps.content.finished,
    onRest: (ds) => {
      if (ds.value.state === AnimationState.FINISHED) {
        setIsAnimating(false)
        contentAnimationRef.start(animationProps.content.removed)
      }
    },
    config: {
      duration: 400,
    },
  }))

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    // console.log('ProcessingOverlay.useEffect[isProcessing]', { curIsProcessing, isProcessing, isAnimating })
    if (
      !caption &&
      (
        (isProcessing === true && !curIsProcessing) ||
        (isProcessing === false && curIsProcessing)
      )
    ) {
      // console.log('ProcessingOverlay.useEffect[isProcessing]: starting animation', { curIsProcessing, isProcessing, isAnimating })
      setCurIsProcessing(isProcessing)
      setIsAnimating(true)
      overlayAnimationRef.start(isProcessing
        ? animationProps.overlay.processing
        : animationProps.overlay.finished,
      )
      contentAnimationRef.start(isProcessing
        ? animationProps.content.processing
        : animationProps.content.finished,
      )
    }
  }, [isProcessing])

  // ===================================================================================================================
  // Rendering:
  // console.log('ProcessingOverlay.render', { curIsProcessing, isProcessing, isAnimating })

  if (caption && !isProcessing) {
    return null
  }

  if (caption) {
    return (
      <IonPopover
        isOpen
        cssClass='processing-popover'
        backdropDismiss={false}
        keyboardClose={false}
      >
        <div className='caption'>
          {caption}
        </div>
        <div className='withCenteredColumnContent'>
          <IonSpinner name='dots' className='spinner' />
        </div>
        <div className='text withDoubleTopMargin'>
          {text}
        </div>
      </IonPopover>
    )
  }

  let renderedText: JSX.Element | undefined
  if (text) {
    renderedText = <div className='text'>{text}</div>
  }

  return (
    <div className='processing-overlay' onClick={onClick} style={{ zIndex: (isProcessing || isAnimating) ? 1000 : -1000 }}>
      <animated.div
        className='backdrop'
        style={overlayStyle}
      />
      <animated.div
        className='content-wrapper'
        style={contentStyle}
      >
        <div className='content'>
          <IonSpinner name='dots' className='spinner' />
          {renderedText}
        </div>
      </animated.div>
    </div>
  )
}

export default ProcessingOverlay
