import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'

import '../../styles.css'
import type { UserMetadata, Wallet, WalletListItem } from '../../../../../lib/core/definitions'
import MimbleWalletBalance from '../../../../../components/MimbleWalletBalance/MimbleWalletBalance'

type Props = {
  wallet?: Wallet | WalletListItem | null;
  userCanBuyTokens?: boolean;
  userMetadata?: UserMetadata | null;
  onAddFundsToWallet: () => void;
}

const MimbleWalletDashboardWidget: React.FC<Props> = (props): JSX.Element | null => {
  const {
    wallet,
    userCanBuyTokens,
    userMetadata,
    onAddFundsToWallet,
  } = props

  if (!userMetadata || !wallet) {
    return null
  }

  let buyMimbleTokenButton
  if (userCanBuyTokens) {
    buyMimbleTokenButton = (
      <div className='bottom-buttons'>
        <IonButton onClick={onAddFundsToWallet} size='small'>
          Buy Mimble Tokens
        </IonButton>
      </div>
    )
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='dashboard-widget mimble-wallet'>
      <div className='header'>
        <span className='text'>
          Mimble Wallet
        </span>
        <IonButton
          size='small'
          color='medium'
          fill='clear'
        >
          <IonIcon icon={chevronForward} />
        </IonButton>
      </div>

      <MimbleWalletBalance
        wallet={wallet as Wallet}
        className='withStandardBottomMargin'
      />
      {/* <MimbleWalletChart wallet={wallet} /> */}

      {/* buyMimbleTokenButton */}
      {/* }
      <div className='bottom-buttons'>
        <IonButton onClick={onOpenMimbleWalletPage} size='small'>
          Open Wallet
        </IonButton>
      </div>
      { */}
    </div>
  )
}

export default MimbleWalletDashboardWidget
