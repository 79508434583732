import type { ContactUserListFilter } from '../../../lib/core/definitions'
import type { ContactUsersQueryVariables } from '../definitions'
import getContactUserListFilter from './getContactUserListFilter'

const getContactUsersQueryVariables = (
  listFilter: ContactUserListFilter | undefined,
  activeUserId: string,
): ContactUsersQueryVariables => ({
  filter2: listFilter || getContactUserListFilter(activeUserId),
})

export default getContactUsersQueryVariables
