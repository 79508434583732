import type { ApolloClient } from '@apollo/client'
import type { Inbox, InboxItem, PurchaseListItem } from '../../../lib/core/definitions'
import { InboxItemType } from '../../../lib/core/enums'

const reloadUserPurchasesIfNeeded = (
  oldInbox: Inbox | undefined,
  newInbox: Inbox,
  activeUserId: string,
  reloadPurchases: () => Promise<PurchaseListItem[] | undefined>,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('reloadUserPurchasesIfNeeded called.', { oldInbox, newInbox })
  if (
    !newInbox ||
    !Array.isArray(newInbox.items) ||
    newInbox.items.length < 1
  ) {
    // console.log('reloadUserPurchasesIfNeeded: newInbox empty.')
    return
  }

  const newPurchaseItems: InboxItem[] = newInbox.items.filter(i => (
    i.inboxItemType === InboxItemType.PURCHASE ||
    i.inboxItemType === InboxItemType.PURCHASE_TRANSFER
  ))

  if (!newPurchaseItems || newPurchaseItems.length < 1) {
    // console.log('reloadUserPurchasesIfNeeded: newInbox has no purchases.')
    return
  }

  if (
    !oldInbox ||
    !Array.isArray(oldInbox.items) ||
    oldInbox.items.length < 1
  ) {
    // console.log('reloadUserPurchasesIfNeeded: no oldInbox.')
    reloadPurchases()
    return
  }

  const oldPurchaseItems: InboxItem[] = oldInbox.items.filter(i => (
    i.inboxItemType === InboxItemType.PURCHASE ||
    i.inboxItemType === InboxItemType.PURCHASE_TRANSFER
  ))

  if (!oldPurchaseItems || oldPurchaseItems.length < 1) {
    // console.log('reloadUserPurchasesIfNeeded: no purchase items in oldInbox.')
    reloadPurchases()
    return
  }

  const oldPurchaseIds = oldPurchaseItems.map(i => i.modelId)
  // console.log('reloadUserPurchasesIfNeeded: oldPurchaseIds=.', oldPurchaseIds)

  if (newPurchaseItems.find(i => !oldPurchaseIds.includes(i.modelId))) {
    reloadPurchases()
  } else {
    console.log('reloadUserPurchasesIfNeeded: no new purchaseIds found in new list.')
  }
}

export default reloadUserPurchasesIfNeeded
