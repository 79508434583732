import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, ContactUserListItem, ContactListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ContactUsersQueryData,
  ContactUsersQueryVariables,
  ApiClientOptions,
} from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadContactUsers = (
  filter: ContactListFilter,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ContactUserListItem[] | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadContactUsers called.')

    if (!apolloClient) {
      logger.error('api.loadContactUsers: no Apollo client available.')
      throw (new Error(ErrorCode.SYSTEM_ERROR))
    }

    const queryOptions: QueryOptions<ContactUsersQueryVariables> = {
      query: apollo.queries.contactUsers,
      // todo: rename back to filter, once we can delete the deprecated filter
      variables: { filter2: filter },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    apolloClient.query<ContactUsersQueryData, ContactUsersQueryVariables>(queryOptions)
      .then((resp) => {
        if (
          !resp ||
          !resp.data ||
          !resp.data.contactUsers
        ) {
          logger.error('api.loadContactUsers.load: query did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        resolve(resp.data.contactUsers)
      }, (error) => {
        logger.error('api.loadContactUsers: error.', { error })
        reject(error)
      })
  })
)

export default loadContactUsers
