const formatPercentAmount = (
  inputNumber: number,
  precision = 0,
  addPercentSign = true,
): string => {
  const val = (inputNumber / 1000).toFixed(precision)
  return addPercentSign ? `${val}%` : val
}

export default formatPercentAmount
