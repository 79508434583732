import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IonButton, IonInput, useIonViewDidLeave } from '@ionic/react'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import { AppRoute } from '../../enums'
import type { AsyncTask, User } from '../../lib/core/definitions'
import { AsyncTaskResult } from '../../lib/core/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import auth from '../../services/auth'
import FormItem from '../../components/FormItem/FormItem'
import logger from '../../services/logger'
import pageHelpers from '../../helpers/pageHelpers'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../helpers/validationHelpers'

interface Props {
  asyncTask: AsyncTask
  onBack: () => void
  onNavigate: (route: AppRoute | string, resetHistory?: boolean) => void
  showUiMessage: (message: string) => void
}

const UnlockCodeForm: React.FC<Props> = (props): JSX.Element => {
  // const navigate = useNavigate()

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  const {
    asyncTask,
    onBack,
    onNavigate,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { reloadActiveUser } = useMimbleData()

  const [unlockCode, setUnlockCode] = useState<string | undefined>()
  const [unlockCodeValidationError, setUnlockCodeValidationError] = useState<string | undefined>()
  // const [codeSignInStage, setCodeSignInStage] = useState(CodeSignInStage.UNSET)
  // const [asyncTask, setAsyncTask] = useState<AsyncTask | undefined>()
  const [isProcessing, setIsProcessing] = useState(false)

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewDidLeave(() => {
    setUnlockCode(undefined)
    setUnlockCodeValidationError(undefined)
    // setCodeSignInStage(CodeSignInStage.UNSET)
    // setAsyncTask(undefined)
    setIsProcessing(false)
  })

  // ===================================================================================================================
  // Event Handlers:
  const onChangeUnlockCode = (event: any): void => {
    setUnlockCode(event.detail.value)
    if (event.detail.value) {
      setUnlockCodeValidationError(validationHelpers.validateConfirmationToken(event.detail.value))
    } else {
      setUnlockCodeValidationError(undefined)
    }
  }

  const onBlurUnlockCode = (): void => {
    if (!unlockCode) {
      setUnlockCodeValidationError('required')
    }
  }

  const onSubmit = (event: any): void => {
    event.preventDefault()
    // console.log('SignInPage.UnlockCodeForm.onSubmit: called.', { unlockCode })
    if (
      !asyncTask ||
      !asyncTask.id ||
      !unlockCode
    ) {
      logger.error('SignInPage.UnlockCodeForm.onSubmit: no async task or confirmation token for token verification.')
      showUiMessage('Failed to confirm your token.')
      return
    }
    // setCodeSignInStage(CodeSignInStage.CODE_SUBMITTED)
    auth.verifyAsyncTaskToken(
      asyncTask.id,
      unlockCode.trim(),
      undefined,
      undefined,
      apolloClient,
    ).then((task) => {
      if (!task) {
        logger.error('upsertAsyncTask did not return a task')
        showUiMessage('Failed to confirm your token.')
        setUnlockCodeValidationError('Failed to verify')
        return
      }
      if (
        task.result !== AsyncTaskResult.OK ||
        !task.userId ||
        !task.metadata ||
        !task.metadata.authToken
      ) {
        logger.warn('SignInPage.UnlockCodeForm: verification failed.', { task })
        showUiMessage('Failed to confirm your token.')
        setUnlockCodeValidationError('Failed to verify')
        return
      }
      const user: User = {
        id: task.userId,
        authToken: task.metadata.authToken,
      }
      if (task.username) {
        user.username = task.username
      }
      if (task.email) {
        user.email = task.email
      }
      if (task.phoneNumber) {
        user.phoneNumber = task.phoneNumber
      }

      setIsProcessing(true)
      pageHelpers.handleSignIn({
        user,
        reloadActiveUser,
        apolloClient,
        navigate,
      }).then(() => {
        setIsProcessing(false)
        onNavigate(AppRoute.HOME, true)
      }, (error) => {
        logger.error('SignInPage: received error.', { error })
        setIsProcessing(false)
        showUiMessage('Failed to sign you in. Please check your input and try again.')
        setUnlockCodeValidationError('Failed to verify')
      })
    }, (error) => {
      logger.error('SignInPage: verifyAsyncTaskToken returned error.', { error })
      showUiMessage('Failed to confirm your token.')
    })
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <form className='unlock-code-form' onSubmit={onSubmit}>
      <FormItem
        label='Enter the 6-digit unlock code in the text message or email'
        validationError={unlockCodeValidationError}
        withBottomMargin
      >
        <IonInput
          autofocus
          type='number'
          value={unlockCode}
          inputmode='numeric'
          pattern='[0-9]{6}'
          placeholder='000000'
          autocomplete='one-time-code'
          minlength={6}
          maxlength={6}
          required
          onIonBlur={onBlurUnlockCode}
          onIonChange={onChangeUnlockCode}
        />
      </FormItem>
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onBack}
        >
          Back
        </IonButton>
        <SubmitButton
          onClick={onSubmit}
          isProcessing={isProcessing}
          disabled={!unlockCode || !!unlockCodeValidationError || isProcessing}
        >
          Verify Unlock Code
        </SubmitButton>
      </div>
    </form>
  )
}

export default UnlockCodeForm
