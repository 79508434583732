import { ReportDataFormat } from '../../../enums'
import type { ProductListFilter, ProductListItem } from '../../../definitions'
import formatDate from '../../ui/formatDate'
import getMetadataInventory from './getMetadataInventory'

const exportData = (
  products: ProductListItem[] | null | undefined,
  filter: ProductListFilter | undefined,
  format = ReportDataFormat.TSV,
): string => {
  if (!Array.isArray(products) || products.length < 1) {
    return ''
  }

  const filterList = (product: ProductListItem): boolean => {
    if (filter && filter.searchText) {
      const reg = new RegExp(filter.searchText, 'i')
      if (!product.merchantName || !product.merchantName.match(reg)) {
        return false
      }
    }
    if (filter && filter.showOnlyMainProducts && !product.isMainProduct) {
      return false
    }
    if (filter && filter.showOnlyListed && !product.listed) {
      return false
    }
    if (filter && filter.showOnlyInStock && !product.stock) {
      return false
    }
    return true
  }

  let colDelimiter = '\t'
  const rowDelimiter = '\n'
  switch (format) {
    case ReportDataFormat.CSV:
      colDelimiter = ','
      break
    case ReportDataFormat.TSV:
      colDelimiter = '\t'
  }

  const lines = products
    .filter(filterList)
    .map((product): string => {
      const {
        inventory5,
        inventory10,
        inventory25,
        inventory30,
        inventory50,
        inventory100,
        inventory250,
      } = getMetadataInventory(product.metadata)
      return [
        (product.id as string).replace(/-/g, ''),
        product.merchantId ? (product.merchantId).replace(/-/g, '') : '-',
        product.merchantName,
        product.isMainProduct ? 'y' : 'n',
        product.listed ? 'y' : 'n',
        product.merchantListed ? 'y' : 'n',
        product.stock ? 'y' : 'n',
        inventory5,
        inventory10,
        inventory25,
        inventory30,
        inventory50,
        inventory100,
        inventory250,
        product.productType,
        product.imageSourceFrontSide,
        product.messageEn,
        product.merchantUrl,
        product.merchantLargeLogoImageSource,
        formatDate(product.createdAt),
        formatDate(product.updatedAt),
        product.recordStatus,
        product.merchantRecordStatus,
      ].join(colDelimiter)
    })
  lines.unshift([
    'ID',
    'Merchant ID',
    'Merchant',
    'Main Product?',
    'Product Listed?',
    'Merchant Listed?',
    'In Stock?',
    'Inventory $5',
    'Inventory $10',
    'Inventory $25',
    'Inventory $30',
    'Inventory $50',
    'Inventory $100',
    'Inventory $250',
    'Type',
    'Product Image',
    'Description',
    'Merchant URL',
    'Merchant Logo',
    'Created At',
    'Updated At',
    'Product Record Status',
    'Merchant Record Status',
  ].join(colDelimiter))

  return lines.join(rowDelimiter)
}

export default exportData
