import clearSignInAsUser from './clearSignInAsUser'
import createAsyncTask from './createAsyncTask'
import loadAsyncTask from './loadAsyncTask'
import redirectIfUnauthorized from './redirectIfUnauthorized'
import sendAsyncTaskNotification from './sendAsyncTaskNotification'
import setActiveUser from './setActiveUser'
import signIn from './signIn'
import signOut from './signOut'
import verifyAsyncTaskToken from './verifyAsyncTaskToken'

const authService = {
  clearSignInAsUser,
  createAsyncTask,
  loadAsyncTask,
  redirectIfUnauthorized,
  sendAsyncTaskNotification,
  setActiveUser,
  signIn,
  signOut,
  verifyAsyncTaskToken,
}

export default authService
