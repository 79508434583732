const formatPhoneNumber = (phoneNumber: string | number | null | undefined): string => {
  if (!phoneNumber) {
    return ''
  }
  const cleaned = (phoneNumber.toString()).replace(/\D/g, '')
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/)
  if (!match) {
    return cleaned
  }
  return '+1 (' + match[1] + ') ' + match[2] + '-' + match[3]
}

export default formatPhoneNumber
