import React from 'react'
import { IonItem } from '@ionic/react'

import './styles.css'
import type { Chat } from '../../lib/core/definitions'
import Avatar from '../Avatar/Avatar'
import pageHelpers from '../../helpers/pageHelpers'
// import { trashOutline } from 'ionicons/icons'

type Props = {
  chat: Chat;
  activeUserId: string;
  onArchiveContact?: (contactId: string | undefined, fromUserId: string | undefined, toUserId: string | undefined) => void;
  onClick: (chatId: string) => void;
}

const ChatListItem: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('ChatListItem.render called.', { props })
  const {
    chat,
    activeUserId,
    onArchiveContact,
    onClick,
  } = props

  // ===================================================================================================================
  // State:
  // const slidingRef = useRef<HTMLIonItemSlidingElement | null>(null)

  // ===================================================================================================================
  // Event Handlers:
  const handleClick = (): void => {
    if (onClick) {
      onClick(chat.id as string)
    }
  }

  // const onDeleteThisContact = (event: any) => {
  //   event.preventDefault()
  //   if (chat && activeUserId && onArchiveContact) {
  //     const toUserId = chat.toUserId === activeUserId ? chat.fromUserId : chat.toUserId
  //     onArchiveContact(undefined, activeUserId, toUserId || undefined)
  //     if (slidingRef.current) {
  //       slidingRef.current.close()
  //     }
  //   }
  // }

  // ===================================================================================================================
  // Rendering:
  const {
    contactInfo,
    unreadMessageCount,
    unreadSystemMessageCount,
  } = pageHelpers.getChatListInfo(chat, activeUserId, false)
  const {
    fullName: contactFullName,
    ident: contactIdent,
    username: contactUsername,
    imageUrl: contactImageUrl,
  } = contactInfo

  const lines: JSX.Element[] = []
  if (contactFullName) {
    lines.push(
      <div key='line1' className='contact-name'>
        {contactIdent}
      </div>,
    )
    lines.push(
      <div key='line2'>
        <span className='lightText smallText'><span className='handleGlyph'>@</span>{contactUsername}</span>
      </div>,
    )
  } else {
    lines.push(
      <div key='line1' className='contact-name'>
        <span className='handleGlyph'>@</span>{contactUsername}
      </div>,
    )
  }
  const avatarUser = {
    fullName: contactFullName,
    imageUrl: contactImageUrl,
  }

  return (
    <IonItem
      key={chat.id as string}
      detail
      className='withStandardRightMargin'
      onClick={handleClick}
    >
      <Avatar
        user={avatarUser}
        badge={unreadMessageCount + unreadSystemMessageCount}
        padForBadge
        className='withStandardRightMargin'
      />
      <div>
        {lines}
      </div>
    </IonItem>
  )

  // return (
  //   <IonItemSliding key={chat.id as string} ref={slidingRef}>
  //     <IonItem
  //       key={chat.id as string}
  //       detail
  //       onClick={handleClick}
  //     >
  //       <Avatar
  //         user={avatarUser}
  //         badge={unreadMessageCount + unreadSystemMessageCount}
  //         padForBadge
  //         className='withStandardRightMargin'
  //       />
  //       <div>
  //         {lines}
  //       </div>
  //     </IonItem>
  //     <IonItemOptions onIonSwipe={onDeleteThisContact}>
  //       <IonItemOption type='button' color='danger' expandable onClick={onDeleteThisContact}>
  //         <IonIcon icon={trashOutline} color='light' slot='top' />
  //         Remove
  //       </IonItemOption>
  //     </IonItemOptions>
  //   </IonItemSliding>
  // )
}

export default ChatListItem
