import type { AsyncTask } from '../../../definitions'
import { AsyncTaskStatus } from '../../../enums'
// import logger from '../../../logger'

const isNewTaskSendingNotificationCompleted = (task: AsyncTask): boolean => {
  if (!task) {
    return false
  }
  return (
    task.taskStatus === AsyncTaskStatus.NOTIFICATION_SENT ||
    task.taskStatus === AsyncTaskStatus.NOTIFICATION_FAILED ||
    task.taskStatus === AsyncTaskStatus.FINISHED
  )
}

export default isNewTaskSendingNotificationCompleted
