import React from 'react'
import { IonImg } from '@ionic/react'

type Props = {
  logoUri?: string;
  small?: boolean;
};

const MerchantLogoBackground: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('GiftCard.MerchantLogoBackground called.', props)
  const { logoUri, small } = props

  if (!logoUri) {
    return null
  }

  const cssClass = small
    ? 'gift-card-with-merchant-logo gift-card-with-merchant-logo-small'
    : 'gift-card-with-merchant-logo'

  return (
    <div className={cssClass}>
      <div className='absolutePositionCenteredFlexContainer'>
        <IonImg alt='Brand' src={logoUri} />
      </div>
    </div>
  )
}

export default MerchantLogoBackground
