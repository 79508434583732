import React from 'react'
import {
  IonIcon,
  IonCard,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react'
import { cart, cloudUpload, cog } from 'ionicons/icons'

type Props = {
  userCanPurchase: boolean;
  userCanBuyTokens: boolean;
  onAddUploadedPurchase: () => void;
  onCreatePurchase: () => void;
  onAddFundsToWallet: () => void;
  onOpenUserAccount: () => void;
}

const QuickActionsDashboardWidget: React.FC<Props> = (props): JSX.Element => {
  const {
    userCanPurchase,
    userCanBuyTokens,
    onAddUploadedPurchase,
    onCreatePurchase,
    onAddFundsToWallet,
    onOpenUserAccount,
  } = props

  const actions = [
    {
      icon: cart,
      label: 'Marketplace',
      handler: onCreatePurchase,
      show: userCanPurchase,
    },
    {
      icon: cart,
      label: 'Refill Wallet',
      handler: onAddFundsToWallet,
      show: userCanBuyTokens,
    },
    {
      icon: cloudUpload,
      label: 'Upload Card',
      handler: onAddUploadedPurchase,
      show: true,
    },
    {
      icon: cog,
      label: 'Settings',
      handler: onOpenUserAccount,
      show: true,
    },
  ].filter(a => a.show)
  const rows: JSX.Element[] = []
  let idx = 0
  for (let r = 0; r < actions.length / 2; r++) {
    const cols = [(
      <IonCol size='6' key={`${r}-${idx}`}>
        <IonButton size='small' expand='block' onClick={actions[idx].handler}>
          <IonIcon
            icon={actions[idx].icon}
            className='withStandardRightMargin'
          />
          {actions[idx].label}
        </IonButton>
      </IonCol>
    )]
    idx++
    if (idx < actions.length) {
      cols.push(
        <IonCol size='6' key={`${r}-${idx}`}>
          <IonButton size='small' expand='block' onClick={actions[idx].handler}>
            <IonIcon
              icon={actions[idx].icon}
              className='withStandardRightMargin'
            />
            {actions[idx].label}
          </IonButton>
        </IonCol>,
      )
    }
    idx++
    rows.push(
      <IonRow key={r}>
        {cols}
      </IonRow>,
    )
  }

  return (
    <IonCard className='dashboard-widget quick-actions'>
      <div className='header'>
        <span className='text'>Quick Actions</span>
      </div>
      <IonGrid>
        {rows}
      </IonGrid>
    </IonCard>
  )
}

export default QuickActionsDashboardWidget
