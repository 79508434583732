import React, { useContext, useEffect, useState } from 'react'
import {
  IonButton,
  IonCardContent,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient } from '@apollo/client'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import type { ContextMenuOptions } from '../../../components/ContextMenu/contextMenuDefs'
import { ContextMenuId, ContextMenuItemId } from '../../../components/ContextMenu/contextMenuDefs'
import { PageMessageType, AppRoute, AppPage } from '../../../enums'
import type { PurchaseListScope } from '../../../lib/core/enums'
import { PurchaseStatus, UiMessage } from '../../../lib/core/enums'
import type { TopNavTabDef } from '../../../components/TopNavBar/definitions'
import { TopNavTabId } from '../../../components/TopNavBar/enums'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import api from '../../../services/api'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import coreHelpers from '../../../lib/core/helpers'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers, { DEFAULT_PURCHASES_BY_SCOPE_COUNTS } from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import PurchaseList from '../../../components/PurchaseList/PurchaseList'
import PurchasesPageHeader from './PurchasesPageHeader'
import TopNavBar, { getPurchaseListScopeFromTopNavTabId } from '../../../components/TopNavBar/TopNavBar'

const DEFAULT_TOP_NAV_DEFS: TopNavTabDef[] = []

const appPageId = AppPage.AdminPurchasesPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined
let listTimestamp: number
let lastListRefresh: number

type Params = {
  searchText: string
  tab: TopNavTabId
}

const PurchasesPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { searchText: searchTextFromParams, tab: activeTopNavTabId } = useParams<keyof Params>() as unknown as Params
  const scope = getPurchaseListScopeFromTopNavTabId(activeTopNavTabId || TopNavTabId.ACTIVE_PURCHASES)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { getIsSignedIn, clearSessionData } = useGlobalCache()
  const {
    activeUser,
    purchases: userPurchases,
    isLoadingActiveUser,
    isLoadingPurchases,
    reloadPurchases,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [searchText, setSearchText] = useState<string | undefined>()
  const [topNavTabDefs, setTopNavTabDefs] = useState(DEFAULT_TOP_NAV_DEFS)
  const [scopeCounts, setScopeCounts] = useState(DEFAULT_PURCHASES_BY_SCOPE_COUNTS)

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingPurchases
  const userCanPurchase = coreHelpers.models.user.canPurchase(activeUser)
  const curSearchText = searchText || searchTextFromParams || ''

  const updateTopNavTabDefs = (): void => {
    // console.log('PurchasesPage.updateTopNavTabDefs called.')
    if (!Array.isArray(userPurchases) || userPurchases.length < 1) {
      if (Array.isArray(topNavTabDefs) && topNavTabDefs.length > 0) {
        setTopNavTabDefs([])
      }
    } else {
      const counts = pageHelpers.getPurchaseCountByScope(userPurchases)
      if (!pageHelpers.comparePurchasesByScopeCounts(counts, scopeCounts)) {
        setScopeCounts(counts)
        setTopNavTabDefs([
          {
            id: TopNavTabId.ACTIVE_PURCHASES,
            label: 'Active',
            details: { count: counts.activeCnt },
          },
          {
            id: TopNavTabId.PENDING_PURCHASES,
            label: 'Pending',
            details: { count: counts.pendingCnt },
            show: counts.pendingCnt > 0,
          },
          {
            id: TopNavTabId.RECEIVED_PURCHASES,
            label: 'Inbox',
            details: { count: counts.receivedCnt },
            show: counts.receivedCnt > 0,
          },
          {
            id: TopNavTabId.TRANSFERRED_PURCHASES,
            label: 'Transferred',
            details: { count: counts.transferredCnt },
            show: counts.transferredCnt > 0 && counts.receivedCnt < 1,
          },
          {
            id: TopNavTabId.ARCHIVED_PURCHASES,
            label: 'Archived',
            details: { count: counts.archivedCnt },
            show: counts.archivedCnt > 0 && counts.receivedCnt < 1,
          },
        ])
      }
    }
  }

  // ===================================================================================================================
  // Effect Hooks:
  useEffect((): void => {
    // console.log('PurchasesPage.effects[userPurchases] called.', userPurchases)
    updateTopNavTabDefs()
  }, [userPurchases])

  // ===================================================================================================================
  // Event Handlers:
  const onApplyListFilter = (newSearchText: string, newScope: PurchaseListScope): void => {
    // console.log('PurchasesPage.onApplyListFilter called.', { newSearchText, newScope })
    setSearchText(newSearchText)
  }

  const onGetPurchases = (): void => {
    lastListRefresh = new Date().getTime()
    reloadPurchases()
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    lastListRefresh = new Date().getTime()
    reloadPurchases().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenPurchase = (purchaseId: string): void => {
    api.loadPurchase(
      purchaseId,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then((purchase) => {
      if (!purchase) {
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.GIFT_CARD_NOT_FOUND)
        return
      }
      if (purchase.status === PurchaseStatus.RECEIVED) {
        navigate(`${AppRoute.GIFT_RECEIVED}/tp:${purchaseId}`)
      } else {
        navigate(`/card/${purchaseId}`)
      }
    })
  }

  const onAddUploadedPurchase = (): void => {
    navigate('/select-brand/upload-card')
  }

  const onCreatePurchase = (): void => {
    navigate(AppRoute.MARKETPLACE)
  }

  const onSignIn = (): void => {
    clearSessionData()
    navigate(AppRoute.SIGN_IN, true)
  }

  const onContextMenu = (id: ContextMenuItemId): void => {
    // console.log('Context menu clicked:', id);
    switch (id) {
      case ContextMenuItemId.REFRESH:
        onGetPurchases()
        break
      case ContextMenuItemId.ADD_UPLOADED_PURCHASE:
        onAddUploadedPurchase()
        break
      case ContextMenuItemId.CREATE_PURCHASE:
        onCreatePurchase()
        break
      default:
        console.error('PurchasesPage.onContextMenu: Unexpected context menu item received.', id)
    }
  }

  const onClickTopNavTab = (navTabId: TopNavTabId): void => {
    const scopePath = navTabId ? `/${navTabId}` : ''
    // todo Remove animation
    // see https://stackoverflow.com/questions/56621532/how-to-disable-page-transition-animation-in-ionic-4-angular
    navigate(`/purchases${scopePath}`)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content

  if (getIsSignedIn()) {
    // console.log('PurchasesPage.render called.', { searchText, scope });
    content = (
      <>
        <PurchasesPageHeader
          searchText={searchText}
          scope={scope}
          showBuyButton={userCanPurchase}
          onApply={onApplyListFilter}
          onAddUploadedPurchase={onAddUploadedPurchase}
          onCreatePurchase={onCreatePurchase}
        />
        <PurchaseList
          searchText={curSearchText}
          scope={scope}
          purchases={userPurchases}
          isProcessing={isProcessing}
          onOpenPurchase={onOpenPurchase}
        />
      </>
    )
  } else {
    content = (
      <IonCardContent className='g-ion-card-content' class='withColumnDirection'>
        You are currently not signed in.
        <div className='bottomButtonWrapper'>
          <IonButton onClick={onSignIn}>Sign In</IonButton>
        </div>
      </IonCardContent>
    )
  }

  const contextMenuOptions: ContextMenuOptions = {
    addCreatePurchaseItem: userCanPurchase,
  }

  return (
    <IonPage className='app-page-admin purchases-page'>
      <NavBar
        title='Purchases'
        contextMenuId={ContextMenuId.PURCHASES_PAGE}
        contextMenuOptions={contextMenuOptions}
        onContextMenu={onContextMenu}
        isProcessing={isProcessing}
      />
      <TopNavBar
        tabs={topNavTabDefs}
        onClickTab={onClickTopNavTab}
        activeTabId={activeTopNavTabId || TopNavTabId.ACTIVE_PURCHASES}
      />
      <IonContent>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default PurchasesPage
