import type { ApolloClient, MutationOptions } from '@apollo/client'

import type {
  ApiQueryOptions,
  Reward,
  RewardInput,
  RewardListFilter,
} from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertRewardMutationData,
  UpsertRewardMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadReward from './loadReward'
import logger from '../logger'

const upsertReward = (
  rewardInput: RewardInput,
  isInTargetStateFunc: ((reward: Reward) => boolean) | 'watch-updated-at' | undefined,
  listFilter: RewardListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Reward | undefined> => (
  // console.log('api.upsertReward called.', { rewardInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertReward: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertRewardMutationData, UpsertRewardMutationVariables> = {
      mutation: apollo.admin.mutations.upsertReward,
      variables: { reward: rewardInput },
    }

    apolloClient.mutate<UpsertRewardMutationData, UpsertRewardMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertReward: mutation succeeded.', resp)
        // This mutation will not return a reward object, if it is a new reward.
        if (!rewardInput.id) {
          resolve(undefined)
          return
        }

        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertReward ||
          !resp.data.upsertReward.id
        ) {
          logger.error('api.upsertReward: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((reward: Reward) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!rewardInput.id) {
            logger.error('api.upsertReward: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertReward.updatedAt
            activeIsInTargetStateFunc = (reward: Reward) => reward.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertReward)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getRewardsQueryVariables(listFilter, activeUserId)
            : undefined
          cache.updateReward(
            resp.data.upsertReward,
            queryListVariables,
            activeUserId,
            apolloClient,
          )
          resolve(resp.data.upsertReward)
          return
        }

        const rewardId = resp.data.upsertReward.id

        setTimeout(() => {
          // console.log('api.upsertReward: calling loadReward')
          loadReward(
            rewardId,
            activeIsInTargetStateFunc,
            listFilter,
            activeUserId,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((reward) => {
              // console.log('api.upsertReward: resolving with reward', reward)
              resolve(reward)
            }, (error) => {
              logger.error('api.upsertReward: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertReward: error', { error })
        reject(error)
      })
  })
)

export default upsertReward
