import React from 'react'
import { FormEvent } from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import './styles.css'
import { animated } from 'react-spring'

type Props = {
  icon?: string;
  text?: string;
  layout?: 'horizontal' | 'vertical';
  slot?: 'start' | 'primary' | 'secondary' | 'end'
  iconSize?: number;
  textSize?: number;
  className?: string
  iconClassName?: string
  iconColor?: string
  iconAnimation?: any
  textColor?: string
  disabled?: boolean
  isSelected?: boolean
  onClick?: (event?: FormEvent | undefined) => void;
};

const IconButton: React.FC<Props> = (props): JSX.Element => {
  const {
    icon,
    text,
    layout,
    slot,
    iconSize,
    textSize,
    className,
    iconClassName,
    iconColor,
    iconAnimation,
    textColor,
    disabled,
    isSelected,
    onClick,
  } = props

  // const [activeSelection, setActiveSelection] = useState(false)
  const colorHelper = !iconColor && !textColor && isSelected === true ? 'primary' : 'medium'

  if (layout === 'vertical') {
    return (
      <IonButton
        fill='clear'
        size='small'
        color={iconColor ? undefined : colorHelper || 'medium'}
        slot={slot}
        className={`icon-button ${className || ''}`}
        style={iconColor ? { color: iconColor } : {}}
        onClick={onClick}
        disabled={disabled}
      >
        <animated.div className='withCenteredColumnContent' style={iconAnimation}>
          <IonIcon
            icon={icon}
            className={iconClassName}
            style={{ width: iconSize, height: iconSize }}
          />
          <div style={{ color: textColor || colorHelper ||  'var(--ion-color-medium)', fontSize: textSize || '8px' }}>{text}</div>
        </animated.div>
      </IonButton>
    )
  }

  return (
    <IonButton onClick={onClick}>
      <IonIcon icon={icon} className={`withStandardRightMargin ${iconClassName}`} />
      <div style={{ color: textColor }}>{text}</div>
    </IonButton>
  )
}

export default IconButton
