import { gql } from '@apollo/client'

export default gql`
  query Q($userId: String!) {
    inboxItems(userId: $userId) {
      id
      inboxItemType
      modelId
      metadata {
        chat {
          contactId
          contactFullName
          contactUsername
          contactImageUrl
          unreadMessageCount
          latestMessageText
          latestMessageSentAt
        }
        purchase {
          balance
          fundType
          currency
          productType
          productImageSourceFrontSide
          merchantSmallLogoImageSource
          merchantLargeLogoImageSource
        }
      }
      recordStatus
      createdAt
      updatedAt
    }
  }`
