import type { Wallet } from '../../../definitions'
import logger from '../../../logger'

const isTokenBalanceUpdated = (wallet: Wallet, oldBalance?: number): boolean => {
  // logger.debug('lib.core.helpers.models.wallet.isTokenBalanceUpdated called.', { wallet })
  if (!wallet) {
    logger.warn('lib.core.helpers.models.wallet.isTokenBalanceUpdated: missing wallet.')
    return false
  }
  if (oldBalance !== undefined) {
    return wallet !== oldBalance
  }

  if (!Array.isArray(wallet.updateHistory) || wallet.updateHistory.length < 1) {
    // No transactions yet
    return false
  }

  if (!wallet.createdAt) {
    return false
  }

  return new Date().getTime() - parseInt(wallet.updatedAt as string) < 60000
}

export default isTokenBalanceUpdated
