import moment from 'moment'
import React from 'react'
import { IonIcon, isPlatform } from '@ionic/react'
import {
  chatboxOutline,
  earthOutline,
  linkOutline,
  personOutline as personIcon,
  timeOutline as timeIcon,
} from 'ionicons/icons'
import { Share } from '@capacitor/share'

import './styles.css'
import type { AnimationName, ChatImageType } from '../../lib/core/enums'
import { NotificationMethod, PurchaseTransferStatus } from '../../lib/core/enums'
import type { UserIdentInfo } from '../../lib/core/definitions'
import AnimatedStatusChip from '../../components/AnimatedStatusChip/AnimatedStatusChip'
import coreHelpers from '../../lib/core/helpers'
import GiftMessageText from '../../components/GiftMessageText/GiftMessageText'
import pageHelpers from '../../helpers/pageHelpers'
import UserInfoCard from '../UserInfoCard/UserInfoCard'

const currentYear = moment().get('year')

type Props = {
  purchaseTransferStatus: PurchaseTransferStatus
  giftLink: string | undefined
  userIdentInfo: UserIdentInfo | undefined
  toUserFullName: string | null | undefined
  toUserEmail: string | null | undefined
  toUserPhoneNumber: string | null | undefined
  messageScheduledAt: string | null | undefined
  messageScheduledTimezone: string | null | undefined
  messageSentAt: string | null | undefined
  messageText: string | null | undefined
  chatImageUrl: string | null | undefined
  chatImageType: ChatImageType | null | undefined
  animation: AnimationName | null | undefined
  notificationMethod: NotificationMethod | null | undefined
  onOpenContact: (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined
  ) => void
  onUpdateNotificationMethod?: (method: NotificationMethod) => void
  showUiMessage: (message: string) => void
}

const PurchaseTransferInfoPane: React.FC<Props> = (props): JSX.Element | null => {
  const {
    purchaseTransferStatus,
    giftLink,
    userIdentInfo,
    toUserFullName,
    toUserEmail,
    toUserPhoneNumber,
    messageScheduledAt,
    messageScheduledTimezone,
    messageSentAt,
    messageText,
    chatImageUrl,
    chatImageType,
    animation,
    notificationMethod,
    onOpenContact,
    onUpdateNotificationMethod,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // Event Handlers:
  const onChangeNotificationMethod = () => {
    if (!notificationMethod || !onUpdateNotificationMethod) {
      return
    }
    onUpdateNotificationMethod(
      notificationMethod === NotificationMethod.EMAIL
        ? NotificationMethod.SMS
        : NotificationMethod.EMAIL,
    )
  }

  const onCopyGiftLinkToClipboard = (): void => {
    if (giftLink) {
      pageHelpers.copyInputTextToClipboard(
        giftLink,
        'Gift Card Link',
        showUiMessage,
      )
    }
  }

  const onShare = () => {
    if (!giftLink) {
      console.log('FinishedStep.onShare: no giftLink available.')
      return
    }
    // console.log('FinishedStep.onShare called.', giftLink)
    if (isPlatform('capacitor')) {
      Share.share({
        url: giftLink,
        dialogTitle: 'Sending you a gift!',
      }).then(() => {
        console.log('FinishedStep.handleShareButtonClick: share was successful.')
      }, (error: Error) => {
        console.log(error)
        showUiMessage('Failed to share the gift link. Please copy and send it manually.')
      })
    }
  }

  // ===================================================================================================================
  // Rendering:
  const sections: JSX.Element[] = []

  // Recipient:
  const giftTarget: UserIdentInfo | undefined = userIdentInfo ||
  {
    fullName: toUserFullName,
    email: toUserEmail,
    phoneNumber: toUserPhoneNumber,
  }
  sections.push(
    <div key='recipient' className='section'>
      <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
        <IonIcon icon={personIcon} className='section-icon' />
        <div className='section-caption'>Recipient</div>
      </div>
        <UserInfoCard
          userIdentInfo={userIdentInfo || giftTarget}
          className='section-content'
          showChat
          onOpenContact={onOpenContact}
        />
    </div>,
  )

  // Time to send the gift:
  if (notificationMethod === NotificationMethod.OFF) {
    sections.push(
      <div key='schedule' className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={timeIcon} className='section-icon' />
          <div className='section-caption'>Schedule</div>
        </div>
        <div className='section-content'>
          I am going to send the gift link to the recipient myself.
        </div>
      </div>,
    )
  } else if (messageSentAt) {
    const t = coreHelpers.string.parseDate(messageSentAt, messageScheduledTimezone)
    if (t) {
      const year = t.get('year')
      const date = year === currentYear
        ? t.format('dddd MMMM Do')
        : t.format('dddd MMMM Do, YYYY')
      const timezoneLabel = messageScheduledTimezone
        ? pageHelpers.timezones.getSimpleTimezoneLabel(messageScheduledTimezone) || messageScheduledTimezone
        : undefined
      const time = messageScheduledTimezone
        ? `${t.format('hh:mm a')} (${timezoneLabel})`
        : t.format('hh:mm a')
      sections.push(
        <div key='schedule' className='section'>
          <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
            <IonIcon icon={timeIcon} className='section-icon' />
            <div className='section-caption'>Date Sent</div>
          </div>
          <div className='section-content'>
            Mimble sent your gift on&nbsp;
            <span className='withBoldText'>{date}</span>
            &nbsp;at&nbsp;
            <span className='withBoldText'>{time}</span>.
          </div>
        </div>,
      )
    }
  } else if (messageScheduledAt) {
    const t = coreHelpers.string.parseDate(messageScheduledAt, messageScheduledTimezone)
    if (t) {
      const year = t.get('year')
      const date = year === currentYear
        ? t.format('dddd MMMM Do')
        : t.format('dddd MMMM Do, YYYY')
      const timezoneLabel = messageScheduledTimezone
        ? pageHelpers.timezones.getSimpleTimezoneLabel(messageScheduledTimezone) || messageScheduledTimezone
        : undefined
      const time = messageScheduledTimezone
        ? `${t.format('hh:mm a')} (${timezoneLabel})`
        : t.format('hh:mm a')
      sections.push(
        <div key='schedule' className='section'>
          <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
            <IonIcon icon={timeIcon} className='section-icon' />
            <div className='section-caption'>Schedule</div>
          </div>
          <div className='section-content'>
            Mimble will send your gift on&nbsp;
            <span className='withBoldText'>{date}</span>
            &nbsp;at&nbsp;
            <span className='withBoldText'>{time}</span>.
          </div>
        </div>,
      )
    }
  } else {
    sections.push(
      <div key='schedule' className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={timeIcon} className='section-icon' />
          <div className='section-caption'>Schedule</div>
        </div>
        <div className='section-content'>
          Mimble will send out your gift now.
        </div>
      </div>,
    )
  }

  // Gift Link:
  if (
    [
      PurchaseTransferStatus.SCHEDULED,
      PurchaseTransferStatus.AVAILABLE,
    ].includes(purchaseTransferStatus) &&
    !userIdentInfo // if the recipient is already a member, we don't need to show info on the gift link
  ) {
    const copyButtonAction = isPlatform('capacitor') ? onShare : onCopyGiftLinkToClipboard

    sections.push(
      <div key='gift-link' className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={linkOutline} className='section-icon' />
          <div className='section-caption'>Gift Link</div>
        </div>
        <div className='section-content'>
          <span className='lightText linkText withBoldText' onClick={copyButtonAction}>
            {giftLink}
          </span>
        </div>
      </div>,
    )
  }

  if (
    notificationMethod !== NotificationMethod.OFF &&
    (!userIdentInfo || !userIdentInfo.id) &&
    toUserEmail &&
    toUserPhoneNumber
  ) {
    let changeLink: JSX.Element | undefined
    if (onChangeNotificationMethod) {
      changeLink = (
        <>
          <br />
          <span className='linkText' onClick={onChangeNotificationMethod}>change</span>
        </>
      )
    }
    const methodText = notificationMethod === NotificationMethod.EMAIL
      ? 'an email'
      : 'a text message (SMS) to their phone'
    sections.push(
      <div key='notification' className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={earthOutline} className='section-icon' />
          <div className='section-caption'>How to send</div>
        </div>
        <div className='section-text'>
          Your gift will be sent as {methodText}.
          {changeLink}
        </div>
      </div>,
    )
  }

  if (
    messageText &&
    notificationMethod !== NotificationMethod.OFF
  ) {
    sections.push(
      <div key='message' className='section'>
      <div className='rowWithSpaceBetween withStandardBottomMargin'>
        <div className='rowWithCenterAlignedItems'>
          <IonIcon icon={chatboxOutline} className='section-icon' />
          <div className='section-caption'>Message</div>
        </div>
        <AnimatedStatusChip animation={animation} />
      </div>
        <GiftMessageText
          value={messageText}
          chatImageUrl={chatImageUrl}
          chatImageType={chatImageType}
        />
    </div>,
    )
  }

  return (
    <div className='purchase-transfer-info-pane'>
      {sections}
    </div>
  )
}

export default PurchaseTransferInfoPane
