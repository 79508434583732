import type { ApolloConfig } from './definitions'

let apolloConfig: ApolloConfig

const config = {
  setConfig: (conf: ApolloConfig): void => {
    apolloConfig = conf
  },

  getConfig: (): ApolloConfig => apolloConfig,
}

export default config
