import { gql } from '@apollo/client'

export default gql`
  query Q($filter: WalletListFilter!) {
    wallets (filter: $filter) {
      id
      userId
      walletType
      balanceCrypto
      balanceFiat
      balanceToken
      cryptoCurrency
      fiatCurrency
      tokenName
      metadata
      recordStatus
      createdAt
      updatedAt
      updateHistory { 
        id
        walletId
        transactionId
        transactionType
        updateType
        balanceCrypto
        balanceFiat
        balanceToken
        createdAt
      }
    }
  }`
