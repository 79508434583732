import moment from 'moment'

import type { ImportStoredProductItem, Merchant, MerchantListItem } from '../../../../definitions'
import { FiatCurrency, FundType, StoredProductStatus } from '../../../../enums'
import getInternalMoneyAmount from '../../../ui/getInternalMoneyAmount'
import logger from '../../../../logger'

enum LinePartIndex {
  code, // GIFT CARD NUMBER
  pin, // PIN NUMBER
  amount, // AMOUNT
  merchantName, // BRAND
  ignore1, // PRODUCT NAME
  ignore2, // PURCHASER BUSINESS NAME
  ignore3, // PURCHASER FIRST NAME
  ignore4, // PURCHASER LAST NAME
  ignore5, // RECIPIENT FIRST NAME
  ignore6, // RECIPIENT LAST NAME
  receivedAt, // PURCHASE DATE
  refUrl, // GIFT CARD URL
  ignore7// REFERENCE ID
}
const PARTS_COUNT = Object.keys(LinePartIndex).length / 2

const incomm = (
  line: string,
  lineIndex: number,
  productId: string,
  importIdPrefix: string | undefined,
  poNumber: string | undefined,
  source: string | undefined,
  merchants: Partial<Merchant | MerchantListItem>[] | undefined,
): ImportStoredProductItem => {
  logger.info('lib.core.helpers.models.asyncTask.parseImportStoredProductsLine.incomm called.',
    { line, lineIndex })

  if (!line.startsWith('="')) {
    return {
      lineIndex,
      ok: true,
      ignore: true,
    }
  }

  const parts = line.split(',')
    .map(p => p.replace('=', ''))
    .map(p => p.replace('"', ''))
    .map(p => p.replace('"', ''))
    .map(p => p.replace('hyperlink(', ''))
    .map(p => p.replace(')', ''))
  const partsCount = Array.isArray(parts) ? parts.length : 0
  const importItem: ImportStoredProductItem = {
    ok: false,
    importId: `${importIdPrefix}-${lineIndex}`,
    lineIndex,
    merchantName: parts[LinePartIndex.merchantName],
    status: StoredProductStatus.AVAILABLE,
    poNumber,
    source: source || 'incomm',
  }

  if (partsCount !== PARTS_COUNT) {
    return {
      ...importItem,
      error: `Parsing returned incorrect number of line components (expected: ${PARTS_COUNT}, found: ${partsCount}).`,
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Merchant Name:
  const merchantName = parts[LinePartIndex.merchantName].trim()
  if (!merchantName) {
    return {
      ...importItem,
      error: `Invalid merchant name: '${merchantName}'.`,
    }
  }
  importItem.merchantName = merchantName
  if (
    Array.isArray(merchants) &&
    merchants.length > 0 &&
    !merchants.find(m => (
      merchantName === m.name ||
      merchantName === m.alias1 ||
      merchantName === m.alias2 ||
      merchantName === m.alias3
    ))
  ) {
    return {
      ...importItem,
      error: `Merchant not found by name: '${merchantName}'.`,
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Amount:
  const amountString = parts[LinePartIndex.amount].trim()
  if (amountString) {
    const amount = parseInt(amountString)
    if (
      amount === undefined ||
      amount === null ||
      Number.isNaN(amount) ||
      amount < 0
    ) {
      return {
        ...importItem,
        error: `Invalid amount: '${amount}'.`,
      }
    }
    importItem.amount = getInternalMoneyAmount(
      amount,
      FundType.FIAT,
      FiatCurrency.USD,
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Code:
  importItem.code = parts[LinePartIndex.code].trim()
  if (!importItem.code) {
    return {
      ...importItem,
      error: 'Missing or invalid code.',
    }
  } else {
    importItem.code = importItem.code.replace(/ /g, '')
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // PIN:
  importItem.pin = parts[LinePartIndex.pin].trim().replace(/ /g, '')

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Date we received this item:
  const receivedAtString = parts[LinePartIndex.receivedAt].trim()
  if (receivedAtString) {
    const date = moment(receivedAtString)
    if (!date.isValid()) {
      return {
        ...importItem,
        error: `Invalid receivedAt date '${receivedAtString}'.`,
      }
    }
    importItem.receivedAt = date.toISOString()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Reference URL:
  importItem.refUrl = parts[LinePartIndex.refUrl].trim().replace(/ /g, '')

  logger.info(`lib.core.helpers.models.asyncTask.parseImportStoredProductsLine.incomm: parsed line #${lineIndex}.`,
    { importItem })

  return {
    ...importItem,
    ok: true,
  }
}

export default incomm
