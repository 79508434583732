import React from 'react'
import { IonIcon } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'

import './styles.css'

type Props = {
  icon?: string
  text: string | undefined
  color?: string
  className?: string
  iconClassName?: string
}

const InlineMessage: React.FC<Props> = (props): JSX.Element | null => {
  const {
    icon,
    text,
    color,
    className,
    iconClassName,
  } = props

  if (!text) {
    return null
  }

  let renderedIcon: JSX.Element | undefined
  if (icon) {
    renderedIcon = (
      <IonIcon
        icon={icon}
        className={`icon ${iconClassName || ''}`}
        style={{ color }}
      />
    )
  }

  return (
    <div
      className={`inline-message ${className || ''}`}
      style={{ color }}
    >
      {renderedIcon}
      {text}
    </div>
  )
}

InlineMessage.defaultProps = {
  icon: alertCircleOutline,
  color: 'var(--ion-color-warning)',
}

export default InlineMessage
