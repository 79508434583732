import React, { useState } from 'react'
import { IonButton, IonIcon, IonTextarea } from '@ionic/react'
import { attachOutline, send } from 'ionicons/icons'
import { animated, useSpring } from 'react-spring'

import './styles.css'
import type { ChatMessageInput } from '../../lib/core/definitions'
import type { AnimationName, ChatImageType } from '../../lib/core/enums'
import { ChatAttachmentType } from '../../lib/core/enums'
import { DialogId } from '../../contexts/DialogContext/enums'
import { LocalPreference } from '../../enums'
import { useDialog } from '../../contexts/DialogContext/DialogContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import ChatAttachmentPreviewSection from './ChatAttachmentPreviewSection/ChatAttachmentPreviewSection'
import NewAttachmentSection from './NewAttachmentSection/NewAttachmentSection'

type NewAttachmentSectionAnimationProps = {
  isShowing: boolean
  maxHeight: number
}

const animationProps = {
  newAttachmentSection: {
    expanded: {
      isShowing: true,
      maxHeight: 800,
      opacity: 1,
    },
    collapsed: {
      isShowing: false,
      maxHeight: 0,
      opacity: 0,
    },
  },
  // sendButton: {
  //   disabled: {
  //     color: '#989aa2',
  //   },
  //   enabled: {
  //     color: '#4354ae',
  //   },
  // },
}

type Props = {
  activeUserId: string | null | undefined
  contactUserId?: string | null | undefined
  messageText?: string | null
  imageUrl?: string | null | undefined
  imageType?: ChatImageType | null | undefined
  animation?: AnimationName | null | undefined
  defaultMessageText?: string
  charCount?: boolean
  numberOfInputRows?: number
  canAttachTokens?: boolean
  canAttachGift?: boolean
  onCreateMessage?: (message: ChatMessageInput) => void
  onChangeAnimation?: (animation: AnimationName | null | undefined) => void
  onChangeImage?: (imageUrl: string | null | undefined, imageType: ChatImageType | null | undefined) => void
  onChangeMessageText?: (message: string | null | undefined) => void
  onClickInput?: () => void
  onNewGift?: () => void
}

const NewChatMessage: React.FC<Props> = (props): JSX.Element | null => {
  const {
    activeUserId,
    contactUserId,
    messageText,
    imageUrl,
    imageType,
    animation,
    defaultMessageText,
    charCount,
    numberOfInputRows,
    canAttachTokens,
    canAttachGift,
    onCreateMessage,
    onChangeAnimation,
    onChangeImage,
    onChangeMessageText,
    onClickInput,
    onNewGift,
  } = props
  const showChatUi = !!onCreateMessage

  // ===================================================================================================================
  // State:
  const { getPreferenceFlag } = useGlobalCache()
  const { openDialog } = useDialog()

  const [unmanagedMessageText, setUnmanagedMessageText] = useState<string | null| undefined>()
  const [unmanagedTokenTransferAmount, setUnmanagedTokenTransferAmount] = useState(0)
  const [newAttachmentType, setNewAttachmentType] = useState<ChatAttachmentType | 'select' | undefined>()
  const [unmanagedImageUrl, setUnmanagedImageUrl] = useState<string | null| undefined>()
  const [unmanagedImageType, setUnmanagedImageType] = useState<ChatImageType | null | undefined>()
  const [unmanagedAnimation, setUnmanagedAnimation] = useState<AnimationName | null | undefined>()
  const [newAttachmentSectionStyle, newAttachmentSectionStyleRef] = useSpring<NewAttachmentSectionAnimationProps>(() => ({
    from: animationProps.newAttachmentSection.collapsed,
    to: animationProps.newAttachmentSection.expanded,
    onRest: (ds) => {
      if (!ds.value.isShowing) {
        setNewAttachmentType(undefined)
      }
    },
  }))
  // const [sendButtonStyle, setSendButtonAnimation] = useSpring<SendButtonAnimationProps>(() => ({
  //   ...animationProps.sendButton.disabled,
  //   from: {
  //     ...animationProps.sendButton.enabled,
  //   },
  // }))

  // ===================================================================================================================
  // Helpers:
  const getDisplayMessageText = () => {
    // console.log('>>>>>getDisplayMessageText', { messageText })
    if (onChangeMessageText) {
      // We are in managed mode, so the value comes from the parent:
      if (messageText === null) {
        return ''
      }
      return messageText || defaultMessageText
    }
    // We are in unmanaged mode, so we maintain the value ourselves:
    if (unmanagedMessageText === null) {
      return ''
    }
    return unmanagedMessageText || defaultMessageText
  }

  const resetNewMessageState = (): void => {
    setUnmanagedMessageText(undefined)
    setUnmanagedImageUrl(undefined)
    setUnmanagedImageType(undefined)
    setUnmanagedAnimation(undefined)
    setUnmanagedTokenTransferAmount(0)
  }

  // ===================================================================================================================
  // Style helpers
  const curMessageText = getDisplayMessageText()
  const readyToSend = (
    curMessageText ||
    unmanagedImageUrl ||
    unmanagedAnimation ||
    unmanagedTokenTransferAmount
  )
  const attachmentButtonColor = newAttachmentType && newAttachmentType !== ChatAttachmentType.GIPHY
    ? 'var(--ion-color-primary)'
    : 'var(--ion-color-medium)'
  const sendButtonColor = readyToSend
    ? 'primary'
    : 'medium'

  // ===================================================================================================================
  // Event Handlers:
  const handleChangeMessageText = (event: any): void => {
    let newText: string | null | undefined

    if (event.target.value) {
      if (event.target.value === defaultMessageText) {
        newText = undefined
      } else {
        newText = event.target.value
      }
    } else {
      newText = null
    }

    if (onChangeMessageText) {
      onChangeMessageText(newText)
    } else {
      setUnmanagedMessageText(newText)
    }
  }

  const onResetMessageText = (): void => {
    if (onChangeMessageText) {
      onChangeMessageText(defaultMessageText)
    } else {
      setUnmanagedMessageText(defaultMessageText)
    }
  }

  const onHideNewAttachmentSector = (): void => {
    newAttachmentSectionStyleRef.start(animationProps.newAttachmentSection.collapsed)
  }

  const onShowNewAttachmentSection = (): void => {
    if (newAttachmentType === 'select') {
      onHideNewAttachmentSector()
    } else {
      if (!newAttachmentType) {
        newAttachmentSectionStyleRef.start(animationProps.newAttachmentSection.expanded)
      }
      setNewAttachmentType('select')
    }
  }

  const handleSelectImage = (newImageUrl: string, newImageType: ChatImageType): void => {
    if (imageUrl && imageType) {
      if (newImageUrl === imageUrl && newImageType === imageType) {
        return
      }
    }

    if (onChangeImage) {
      onChangeImage(newImageUrl, newImageType)
      onHideNewAttachmentSector()
    } else {
      setUnmanagedImageUrl(newImageUrl)
      setUnmanagedImageType(newImageType)
      onHideNewAttachmentSector()
    }
  }

  const handleSelectAnimation = (newAnimation: AnimationName): void => {
    if (animation && animation === newAnimation) {
      return
    }
    if (onChangeAnimation) {
      onChangeAnimation(newAnimation)
      onHideNewAttachmentSector()
    } else {
      setUnmanagedAnimation(newAnimation)
      onHideNewAttachmentSector()
    }
  }

  const handleAttachTokens = (tokenAmount: number): void => {
    if (canAttachTokens) {
      setUnmanagedTokenTransferAmount(tokenAmount)
      onHideNewAttachmentSector()
    }

    if (getPreferenceFlag(LocalPreference.SHOW_TOKEN_TRANSFER_NOTICE, true)) {
      openDialog(DialogId.TRANSFER_TOKENS_IN_CHAT)
    }
  }

  const onDeleteAttachment = (attachmentId: string): void => {
    if (attachmentId === 'token-transfer') {
      setUnmanagedTokenTransferAmount(0)
    } else if (attachmentId === 'image') {
      if (onChangeImage) {
        onChangeImage(null, null)
      } else {
        setUnmanagedImageUrl(null)
        setUnmanagedImageType(null)
      }
    } else if (attachmentId === 'animation') {
      if (onChangeAnimation) {
        onChangeAnimation(null)
      }
      setUnmanagedAnimation(null)
    }
  }

  const handleSubmit = (event: any): void => {
    event.preventDefault()
    if (!activeUserId || !contactUserId || !onCreateMessage) {
      return
    }
    const chatMessageInput: ChatMessageInput = {
      fromUserId: activeUserId,
      toUserId: contactUserId,
      messageText: getDisplayMessageText(),
    }

    if (unmanagedImageUrl && unmanagedImageType) {
      chatMessageInput.imageUrl = unmanagedImageUrl
      chatMessageInput.imageType = unmanagedImageType
    }

    if (unmanagedAnimation) {
      chatMessageInput.animation = unmanagedAnimation
    }

    if (unmanagedTokenTransferAmount) {
      chatMessageInput.tokenTransferAmount = unmanagedTokenTransferAmount
    }

    onCreateMessage(chatMessageInput)
    resetNewMessageState()
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('NewChatMessage: rendering: messageText, unmanaged, default, cur', { messageText, unmanagedMessageText, defaultMessageText, curMessageText })
  let resetMessageTextButton: JSX.Element | undefined
  if (messageText === null && defaultMessageText) {
    resetMessageTextButton = (
        <div className='g-gray-text-button-label linkText withStandardRightMargin' onClick={onResetMessageText}>
          Reset message text
        </div>
    )
  }

  let charCountLabel: JSX.Element | undefined
  const msgTextLabel = curMessageText || ''
  if (charCount) {
    charCountLabel = (
      <div className={'rowWithSpaceBetween lightText smallText withStandardRightMargin'}>
        {resetMessageTextButton}
        {Math.max(1000 - msgTextLabel.length, 0)} left
      </div>
    )
  }

  let sendButton: JSX.Element | undefined
  if (showChatUi) {
    sendButton = (
      <IonButton
        fill='clear'
        size='small'
        color={sendButtonColor}
        className='send-button'
        onClick={handleSubmit}
        disabled={!readyToSend}
      >
        <IonIcon
          icon={send}
          slot='icon-only'
          className='send-button-icon'
        />
      </IonButton>
    )
  }

  const cssClassComponent = showChatUi ? 'new-chat-message in-chat' : 'new-chat-message'
  const cssClassInputWrapper = showChatUi ? 'input-wrapper in-chat' : 'input-wrapper'

  return (
    <div className={cssClassComponent}>
      <ChatAttachmentPreviewSection
        tokenTransferAmount={unmanagedTokenTransferAmount}
        imageUrl={imageUrl || unmanagedImageUrl}
        imageType={imageType || unmanagedImageType}
        animation={animation || unmanagedAnimation}
        onDeleteAttachment={onDeleteAttachment}
      />
      <div className={cssClassInputWrapper}>
        <div className='rowWithSpaceBetween'>
          <IonButton
            className='attachment-button'
            fill='clear'
            size='small'
            onClick={onShowNewAttachmentSection}
          >
            <IonIcon
              icon={attachOutline}
              slot='icon-only'
              style={{ color: attachmentButtonColor }}
            />
          </IonButton>
          {charCountLabel}
        </div>
        <IonTextarea
          value={curMessageText}
          autoGrow={!!curMessageText}
          rows={numberOfInputRows}
          autocapitalize='sentences'
          onIonChange={handleChangeMessageText}
          onClick={onClickInput}
          placeholder='New Message'
          className='text-area'
        >
        </IonTextarea>
        {sendButton}
      </div>
      <animated.div className='new-message-attachment-section' style={newAttachmentSectionStyle}>
        <NewAttachmentSection
          newAttachmentType={newAttachmentType}
          onAttachTokens={handleAttachTokens}
          onNewGift={onNewGift}
          onSelectImage={handleSelectImage}
          onSelectAnimation={handleSelectAnimation}
          setNewAttachmentType={setNewAttachmentType}
          canAttachTokens={canAttachTokens}
          canAttachGift={canAttachGift}
        />
      </animated.div>
    </div>
  )
}

NewChatMessage.defaultProps = {
  numberOfInputRows: 1,
}

export default NewChatMessage
