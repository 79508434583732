import type { MerchantListFilter } from '../../../lib/core/definitions'
import type { MerchantsQueryVariables } from '../definitions'
import getMerchantListFilter from './getMerchantListFilter'

const getMerchantsQueryVariables = (
  listFilter: MerchantListFilter | undefined,
): MerchantsQueryVariables => ({
  filter: listFilter || getMerchantListFilter(),
})

export default getMerchantsQueryVariables
