import { TransactionRemoteStatus } from '../../../enums'
import type { Transaction, Wallet } from '../../../definitions'

const isUserWithLiveBanking = async (wallets: Wallet[], transactions: Transaction[]): Promise<boolean> => {
  // if (savingsAccount) {
  //   const savingsAccountBalance = parseFloat(savingsAccount.balance);
  //   if (savingsAccountBalance > 0) {
  //     return true;
  //   }
  // }
  // if (
  //   linkedBankAccount
  //   &&
  //   (linkedBankAccount.remoteStatus === RemoteStatus.PROCESSING || linkedBankAccount.remoteStatus === RemoteStatus.OK)
  // ) {
  //   return true;
  // }
  // return Array.isArray(transactions) && transactions.length > 0;
  return false
}

export default isUserWithLiveBanking
