import { DialogButtonId, DialogId } from './enums'
import type { DialogConfig } from './definitions'
import { LocalPreference } from '../../enums'

const outOfStockItemsInShoppingCart: DialogConfig = {
  title: 'Back-ordered Items In Shopping Cart',
  text:
`<div>
  You can still place your order as is, and the backordered items
  will be delivered to your digital wallet within 1-2 business days.
  The in-stock products will be delivered to your digital wallet
  immediately. 
  <br />
  <br />
  Or, you can adjust your shopping cart with the "Remove Backordered Items" button
  before placing the order. 
 </div>`,
  buttons: [
    {
      id: DialogButtonId.EDIT_SHOPPING_CART,
      label: 'Edit Cart',
    },
    {
      id: DialogButtonId.PLACE_ORDER,
      label: 'Place Order',
    },
  ],
}

const transferTokenInChat: DialogConfig = {
  title: 'Sending Tokens',
  text:
`<p>
  Fantastic that you are sending some of your MITs!
  <br />
  <br />
  They will be transferred when you send the chat message. 
  Once you sent your chat message the transfer can&apos;t be undone.
  <br />
  <br />
  So make sure you&apos;re sending it to the right person!
 </p>`,
  showAgainLocalPreference: LocalPreference.SHOW_TOKEN_TRANSFER_NOTICE,
}

const confirmClearShoppingCart: DialogConfig = {
  // title: 'Clear Shopping Cart',
  text:
`<p>
  Do you want to clear this shopping cart and remove all items?
 </p>`,
  buttons: [
    {
      id: DialogButtonId.CLEAR_SHOPPING_CART,
      label: 'Clear',
    },
    {
      id: DialogButtonId.CANCEL,
      label: 'Cancel',
    },
  ],
}

const confirmDeletePendingOrder: DialogConfig = {
  // title: 'Delete Pending Order',
  text:
`<p>
  Do you want to delete this pending order?
 </p>`,
  buttons: [
    {
      id: DialogButtonId.DELETE_PENDING_ORDER,
      label: 'Delete',
    },
    {
      id: DialogButtonId.CANCEL,
      label: 'Cancel',
    },
  ],
}

const configs = {
  [DialogId.CONFIRM_CLEAR_SHOPPING_CART]: confirmClearShoppingCart,
  [DialogId.CONFIRM_DELETE_PENDING_ORDER]: confirmDeletePendingOrder,
  [DialogId.OUT_OF_STOCK_ITEMS_IN_SHOPPING_CART]: outOfStockItemsInShoppingCart,
  [DialogId.TRANSFER_TOKENS_IN_CHAT]: transferTokenInChat,
}

export default configs
