import { gql } from '@apollo/client'

export default gql`
  query Q($filter: FavoriteOrganizationListFilter!) {
    favoriteOrganizations (filter: $filter) {
      id
      userId
      organizationId
      organizationName
      smallLogoImageSource
      largeLogoImageSource
      organizationIsMerchant
      organizationIsDonationTarget
      merchantInMarketplace
      metadata
    }
  }`
