// see http://stackoverflow.com/questions/1726630/formatting-a-number-with-exactly-two-decimals-in-javascript
import { ErrorCode } from '../enums'

export default {
  round (value: number, precision: number): number {
    if (typeof precision === 'undefined' || precision === 0) {
      return Math.round(value)
    }

    value = +value
    precision = +precision

    if (
      !this.isNumber(value) ||
      !(typeof precision === 'number' && precision % 1 === 0)
    ) {
      return value
    }

    // Shift
    const parts1 = value.toString().split('e')
    value = Math.round(+(parts1[0] + 'e' + (parts1[1] ? (+parts1[1] + precision) : precision)))

    // Shift back
    const parts2 = value.toString().split('e')
    return +(parts2[0] + 'e' + (parts2[1] ? (+parts2[1] - precision) : -precision))
  },

  cleanNonNumericChars (text: string): string {
    const numbers = '0123456789'
    let newText = ''
    for (let i = 0; i < text.length; i += 1) {
      if (numbers.indexOf(text[i]) > -1) {
        newText += text[i]
      }
    }
    return newText
  },

  isNumber (number: any): boolean {
    if (
      number === null ||
      number === undefined ||
      number === Infinity ||
      Number.isNaN(number)
    ) {
      return false
    }
    if (typeof number === 'number') {
      return true
    }
    const n = parseFloat(number)
    return !Number.isNaN(n)
  },

  isPositiveNumber (number: any, allowZero = true): boolean {
    if (
      number === null ||
      number === undefined ||
      number === Infinity ||
      Number.isNaN(number)
    ) {
      return false
    }
    if (typeof number === 'number') {
      return ((allowZero && number >= 0) || (!allowZero && number > 0))
    }
    const n = parseFloat(number)
    return !Number.isNaN(n) &&
      ((allowZero && n >= 0) || (!allowZero && n > 0))
  },

  /*
     Finds the number in the list that is the closest to value. I.e.,
     this will return 11: findClosestListIndex(10, [1, 2, 11, 20])

     @param {number} value any number.
     @param {array}  list  a sorted list of numbers.
     @return {number} the item in the list closest to value
   */
  findClosestListIndex (value: number, list: number[]): number {
    if (!Array.isArray(list) || list.length < 1) {
      throw new Error(ErrorCode.INVALID_INPUT)
    }
    if (value <= list[0] || list.length === 1) {
      return 0
    }
    if (value >= list[list.length - 1]) {
      return list.length - 1
    }
    let closestIndex = 0
    let minDiff = Math.abs(value - list[0])
    for (let i = 1; i < list.length; i += 1) {
      const diff = Math.abs(value - list[i])
      if (diff > minDiff) {
        return closestIndex
      }
      minDiff = diff
      closestIndex = i
    }
    return list.length - 1
  },
}
