import React, { useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { close } from 'ionicons/icons'

import './styles.css'
import type { UserIdentInfo } from '../../../lib/core/definitions'
import { ContextHelpId } from '../../../contexts/AppHelpersContext/enums'
import FindUserForm from '../../../components/FindUserForm/FindUserForm'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import UserInfoCard from '../../../components/UserInfoCard/UserInfoCard'

type Props = {
  onCancel: () => void
  onOpenInvitePage: () => void
  onSubmit: (userId: string) => void
}

const AddContactForm: React.FC<Props> = ({
  onSubmit,
  onOpenInvitePage,
  onCancel,
}): JSX.Element | null => {
  const [userIdentInfo, setUserIdentInfo] = useState<UserIdentInfo | undefined>()

  const onSelectUser = (userIdentInfo: UserIdentInfo | undefined): void => {
    setUserIdentInfo(userIdentInfo)
  }

  const handleCancel = () => {
    onCancel()
    setUserIdentInfo(undefined)
  }

  const handleSubmit = () => {
    if (!userIdentInfo) {
      return
    }
    onSubmit(userIdentInfo.id as string)
    setUserIdentInfo(undefined)
  }

  let content: JSX.Element
  if (userIdentInfo) {
    content = (
      <>
        <div className='withDoubleBottomMargin'>
          Do you want to add this member to your contacts?
        </div>
        <UserInfoCard
          userIdentInfo={userIdentInfo}
          className='withStandardBottomMargin'
        />
        <div className='formButtonWrapper'>
          <IonButton
            color='light'
            className='withStandardRightMargin'
            onClick={handleCancel}
          >
            Cancel
          </IonButton>
          <SubmitButton
            onClick={handleSubmit}
            disabled={!userIdentInfo}
          >
            Add
          </SubmitButton>
        </div>
      </>
    )
  } else {
    content = (
      <FindUserForm
        selectButtonLabel='Add'
        contextHelpId={ContextHelpId.ADD_CONTACT}
        showContacts={false}
        onSelectUser={onSelectUser}
        onSelectNonUser={onOpenInvitePage}
      />
    )
  }

  return (
    <div className='add-contact-form'>
      <div className='above-form-row'>
        <IonButton fill='clear' onClick={onCancel} className='close-button' size='small' slot='icon'>
          <IonIcon icon={close} />
        </IonButton>
      </div>
      {content}
    </div>
  )
}

export default AddContactForm
