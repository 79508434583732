import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'

const parseDate = (d: Moment | string | number | null | undefined, timezone?: string | null): Moment | null | undefined => {
  if (d === null) {
    return null
  }
  if (d === undefined) {
    return undefined
  }
  if (!d) {
    return undefined
  }
  if (d.constructor.name === 'Moment') {
    return d as Moment
  }
  if (d.toString().match(/^\d*$/)) {
    if (timezone) {
      return moment.tz(Number(d.toString()), timezone)
    }
    return moment(Number(d.toString()))
  }
  if (timezone) {
    return moment.tz(d, timezone)
  }
  return moment(d)
}

export default parseDate
