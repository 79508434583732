import type { Purchase, PurchaseTransfer } from '../../../definitions'

const getPurchaseTransferFromPurchase = (
  purchase: Purchase | null | undefined,
  direction: 'sent' | 'received' = 'sent',
): PurchaseTransfer | undefined => {
  if (!purchase) {
    return undefined
  }
  const transfers = direction === 'sent' ? purchase.sentPurchaseTransfers : purchase.receivedPurchaseTransfers
  if (
    !Array.isArray(transfers) ||
    transfers.length < 1
  ) {
    return undefined
  }
  return transfers[transfers.length - 1]
}

export default getPurchaseTransferFromPurchase
