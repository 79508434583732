import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { AsyncTask } from '../../lib/core/definitions'
import type { NotificationMethod } from '../../lib/core/enums'
import { ErrorCode } from '../../lib/core/enums'
import type {
  SendAsyncTaskNotificationData,
  SendAsyncTaskNotificationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import loadAsyncTask from './loadAsyncTask'

const sendMutation = async (
  taskId: string,
  notificationMethod: NotificationMethod | undefined,
  email: string | undefined,
  phoneNumber: string | undefined,
  apolloClient: ApolloClient<any>,
): Promise<AsyncTask> => (
  new Promise((resolve, reject) => {
    const queryParams: MutationOptions<SendAsyncTaskNotificationData, SendAsyncTaskNotificationVariables> = {
      mutation: apollo.mutations.sendAsyncTaskNotification,
      variables: { taskId, notificationMethod, email, phoneNumber },
    }
    if (!globalCacheData.getActiveUser()) {
      queryParams.context = { skipAuthentication: true }
    }
    apolloClient.mutate<SendAsyncTaskNotificationData, SendAsyncTaskNotificationVariables>(queryParams).then((resp) => {
      if (!resp || !resp.data || !resp.data.sendAsyncTaskNotification) {
        console.log('auth.sendAsyncTaskNotification: mutation did not return any data.')
        reject(new Error(ErrorCode.SYSTEM_ERROR))
        return
      }

      const returnedTask = resp.data.sendAsyncTaskNotification
      if (!returnedTask) {
        console.log('auth.sendAsyncTaskNotification: sendAsyncTaskNotification did not return a task.')
        reject(new Error(ErrorCode.SYSTEM_ERROR))
        return
      }

      resolve(returnedTask)
    }, (error) => {
      console.error(error)
    })
  })
)

export default async (
  taskId: string,
  notificationMethod: NotificationMethod | undefined,
  email: string | undefined,
  phoneNumber: string | undefined,
  timeout = 30000,
  apolloClient: ApolloClient<any>,
): Promise<AsyncTask | undefined> => {
  console.log('auth.sendAsyncTaskNotification called.')

  if (!apolloClient) {
    console.log('auth.sendAsyncTaskNotification: no Apollo client available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  const task = await sendMutation(taskId, notificationMethod, email, phoneNumber, apolloClient)
  const lastNotificationSentOutAt = task.notificationSentAt

  const isTaskInTargetStateFunc = (task: AsyncTask): boolean => {
    if (!task || !task.notificationSentAt) {
      return false
    }
    return !lastNotificationSentOutAt || task.notificationSentAt !== lastNotificationSentOutAt
  }

  return loadAsyncTask(taskId, apolloClient, isTaskInTargetStateFunc, timeout)
}
