import type { AppFeature } from '../../../enums'

const getAppFeaturesAsString = (appFeatures: AppFeature | AppFeature[] | string | null | undefined): string | null => {
  if (!appFeatures) {
    return null
  }
  if (Array.isArray(appFeatures)) {
    return appFeatures.join(',')
  }
  return appFeatures
}

export default getAppFeaturesAsString
