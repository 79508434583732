import React, { useState } from 'react'

import './styles.css'
import type { Contact, User } from '../../../lib/core/definitions'
import { ContactPageIdeasTabSectionId } from './enums'
import NavSection from './NavSection/NavSection'
import FavoritesSection from './FavoritesSection/FavoritesSection'
import ThoughtsSection from './ThoughtsSection/ThoughtsSection'
import WishesSection from './WishesSection/WishesSection'

type Props = {
  contact: Contact | null | undefined
  user: User | null | undefined
  showUiMessage: (message: string) => void
  onOpenFavoriteMerchant: (merchantId: string, merchantInMarketplace: boolean) => void
  onOpenWishExternalUrl: (wishId: string, url: string) => void
}

const ContactPageIdeasTab: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contact,
    user,
    showUiMessage,
    onOpenFavoriteMerchant,
    onOpenWishExternalUrl,
  } = props

  // ===================================================================================================================
  // State:
  const [sectionId, setSectionId] = useState(ContactPageIdeasTabSectionId.WISH_LIST)

  // ===================================================================================================================
  // Event Handlers:
  const onOpenSection = (newSectionId: ContactPageIdeasTabSectionId): void => {
    setSectionId(newSectionId)
  }

  // ===================================================================================================================
  // Rendering:
  if (!user) {
    return null
  }

  let content: JSX.Element | undefined
  if (sectionId === ContactPageIdeasTabSectionId.FAVORITES) {
    content = (
      <FavoritesSection
        contact={contact}
        onOpenFavoriteMerchant={onOpenFavoriteMerchant}
        showUiMessage={showUiMessage}
      />
    )
  }
  if (sectionId === ContactPageIdeasTabSectionId.THOUGHTS) {
    content = (
    <ThoughtsSection
      contact={contact}
      onOpenWishExternalUrl={onOpenWishExternalUrl}
      showUiMessage={showUiMessage}
    />
    )
  }
  if (sectionId === ContactPageIdeasTabSectionId.WISH_LIST) {
    content = (
      <WishesSection
        contact={contact}
        onOpenWishExternalUrl={onOpenWishExternalUrl}
        showUiMessage={showUiMessage}
      />
    )
  }

  return (
    <div className='contact-page-ideas-tab'>
      <NavSection
        sectionId={sectionId}
        onOpenSection={onOpenSection}
      />
      {content}
    </div>
  )
}

export default ContactPageIdeasTab
