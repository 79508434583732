import type { Currency, StoredProduct, StoredProductListItem } from '../../../definitions'
import type { StoredProductStatus, FundType } from '../../../enums'
import { RecordStatus } from '../../../enums'

const updateStoredProductListItemFromStoredProduct = (
  storedProductListItem: StoredProductListItem | undefined,
  storedProduct: StoredProduct,
): StoredProductListItem => {
  if (!storedProductListItem) {
    return {
      id: storedProduct.id || '',
      productId: storedProduct.productId || '',
      productOptionId: storedProduct.productOptionId || '',
      purchaseId: storedProduct.purchaseId || null,
      merchantId: '',
      merchantName: '',
      status: storedProduct.status as StoredProductStatus,
      code: storedProduct.code || '',
      amount: storedProduct.amount || 0,
      fundType: storedProduct.fundType as FundType,
      currency: storedProduct.currency as Currency,
      discount: storedProduct.discount || 0,
      receivedAt: storedProduct.receivedAt || null,
      paidAt: storedProduct.paidAt || '',
      poNumber: storedProduct.poNumber || '',
      recordStatus: storedProduct.recordStatus || RecordStatus.ACTIVE,
      createdAt: storedProduct.createdAt || '',
      updatedAt: storedProduct.updatedAt || '',
      updatedBy: storedProduct.updatedBy || '',

      // todo: deprecated
      vendorId: '',
      vendorName: '',
    }
  }

  const updatedItem: StoredProductListItem = { ...storedProductListItem }

  if (storedProduct.productId && storedProduct.productId !== storedProductListItem.productId) {
    updatedItem.productId = storedProduct.productId
  }
  if (storedProduct.productOptionId && storedProduct.productOptionId !== storedProductListItem.productOptionId) {
    updatedItem.productOptionId = storedProduct.productOptionId
  }
  if (storedProduct.purchaseId && storedProduct.purchaseId !== storedProductListItem.purchaseId) {
    updatedItem.purchaseId = storedProduct.purchaseId
  }
  if (storedProduct.status && storedProduct.status !== storedProductListItem.status) {
    updatedItem.status = storedProduct.status
  }
  if (storedProduct.code && storedProduct.code !== storedProductListItem.code) {
    updatedItem.code = storedProduct.code
  }
  if (Number.isInteger(storedProduct.amount) && storedProduct.amount !== storedProductListItem.amount) {
    updatedItem.amount = storedProduct.amount as number
  }
  if (storedProduct.fundType && storedProduct.fundType !== storedProductListItem.fundType) {
    updatedItem.fundType = storedProduct.fundType
  }
  if (storedProduct.currency && storedProduct.currency !== storedProductListItem.currency) {
    updatedItem.currency = storedProduct.currency
  }
  if (Number.isInteger(storedProduct.discount) && storedProduct.discount !== storedProductListItem.discount) {
    updatedItem.discount = storedProduct.discount as number
  }
  if (storedProduct.receivedAt && storedProduct.receivedAt !== storedProductListItem.receivedAt) {
    updatedItem.receivedAt = storedProduct.receivedAt
  }
  if (storedProduct.paidAt && storedProduct.paidAt !== storedProductListItem.paidAt) {
    updatedItem.paidAt = storedProduct.paidAt
  }
  if (storedProduct.poNumber && storedProduct.poNumber !== storedProductListItem.poNumber) {
    updatedItem.poNumber = storedProduct.poNumber
  }
  if (storedProduct.recordStatus && storedProduct.recordStatus !== storedProductListItem.recordStatus) {
    updatedItem.recordStatus = storedProduct.recordStatus
  }
  if (storedProduct.createdAt && storedProduct.createdAt !== storedProductListItem.createdAt) {
    updatedItem.createdAt = storedProduct.createdAt
  }
  if (storedProduct.updatedAt && storedProduct.updatedAt !== storedProductListItem.updatedAt) {
    updatedItem.updatedAt = storedProduct.updatedAt
  }

  return updatedItem
}

export default updateStoredProductListItemFromStoredProduct
