import type { ShoppingCartsQueryVariables } from '../definitions'
import type { ShoppingCartListFilter } from '../../../lib/core/definitions'
import getShoppingCartListFilter from './getShoppingCartListFilter'

const getShoppingCartsQueryVariables = (
  listFilter: ShoppingCartListFilter | undefined,
  activeUserId: string,
): ShoppingCartsQueryVariables => ({
  filter: listFilter || getShoppingCartListFilter(activeUserId),
})

export default getShoppingCartsQueryVariables
