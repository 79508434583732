import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import { ContextMenuId, ContextMenuItemId } from '../../../components/ContextMenu/contextMenuDefs'
import type { AppRoute } from '../../../enums'
import { AppPage, PageMessageType } from '../../../enums'
import type { ProductListFilter } from '../../../lib/core/definitions'
import type { ProductsQueryData, ProductsQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { ProductListScope, UiMessage } from '../../../lib/core/enums'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import coreHelpers from '../../../lib/core/helpers'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import ProductGrid from './ProductGrid'
import ProductListHeader from './ProductListHeader'

const appPageId = AppPage.AdminProductsPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const ProductsPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [filter, setFilter] = useState({ showOnlyMainProducts: true } as ProductListFilter | undefined)

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading products:
  const {
    data: productsData,
    loading: isLoadingProducts,
    refetch: reloadProducts,
  } = useQuery<ProductsQueryData, ProductsQueryVariables>(
    apollo.queries.products, {
      variables: { filter: { scope: ProductListScope.ADMIN } },
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const products = productsData ? productsData.products : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingProducts

  // ===================================================================================================================
  // Event Handlers:
  const onChangeFilter = (newFilter: ProductListFilter): void => {
    setFilter(newFilter)
    if (!coreHelpers.models.product.compareProductListFilters(newFilter, filter, true)) {
      reloadProducts({ filter: newFilter })
    }
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    // todo: have sendGetProductsRequest return a promise, then cancel the refreshEvent
    reloadProducts()
    // sendGetProductsRequest().then(() => {
    //   if (refreshEvent) {
    //     refreshEvent.detail.complete()
    //     refreshEvent = undefined
    //   }
    // }, (error) => {
    //   console.error(error)
    // })
  }

  const onContextMenu = (id: ContextMenuItemId): void => {
    // console.log('Context menu clicked:', id);
    switch (id) {
      case ContextMenuItemId.REFRESH:
        reloadProducts()
        break
      default:
        console.error('ProductsPage.onContextMenu: Unexpected context menu item received.', id)
    }
  }

  const onOpenProduct = (productId: string): void => {
    api.loadProduct(
      productId,
      undefined,
      apollo.getProductListFilter(),
      apolloClient,
      {
        includeMerchant: true,
        includeProductOptions: true,
        includeStoredProducts: true,
        includeTags: true,
      },
      undefined,
    ).then((product) => {
      if (product) {
        navigate(`/admin/product/${productId}`)
      }
    }, (error) => {
      console.error(error)
      pageHelpers.checkForUnauthorized(error, navigate)
      // pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      setToastMessage('Error connecting.')
      setShowToast(true)
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  return (
    <IonPage className='app-page-admin products-page'>
      <NavBar
        title='Manage Products'
        contextMenuId={ContextMenuId.PRODUCTS_PAGE}
        onContextMenu={onContextMenu}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding' scrollY={false}>
        <div className='g-non-scroll-content-wrapper'>
          <PageMessages />
          <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <ProductListHeader
            onChangeFilter={onChangeFilter}
          />
          <ProductGrid
            products={products}
            filter={filter}
            onOpenProduct={onOpenProduct}
          />
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default ProductsPage
