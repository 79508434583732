import type { Currency, ShoppingCart } from '../../../definitions'
import type { FundType } from '../../../enums'
import getAmountsInfo from '../shoppingCartItem/getAmountsInfo'
import logger from '../../../logger'

type Args = {
  shoppingCart: ShoppingCart | null | undefined
  paymentFundType: FundType
  paymentCurrency: Currency
  paymentExchangeRate?: number
}

const getTotalProductAmount = (args: Args): number | undefined => {
  // logger.trace('lib.core.helpers.models.shoppingCart.getTotalProductAmount called.', { args })
  const {
    shoppingCart,
    paymentFundType,
    paymentCurrency,
    paymentExchangeRate,
  } = args

  if (!shoppingCart) {
    return 0
  }

  let productAmount = 0

  if (!shoppingCart) {
    logger.error('lib.core.helpers.models.shoppingCart.getTotalProductAmount: no shopping cart given.')
    return
  }

  if (!Array.isArray(shoppingCart.items) || shoppingCart.items.length < 1) {
    // logger.info('lib.core.helpers.models.shoppingCart.getTotalProductAmount: no items.',
    //   { shoppingCart })
    return 0
  }

  shoppingCart.items.forEach((item) => {
    const amountInfo = getAmountsInfo(
      item,
      paymentFundType,
      paymentCurrency,
      paymentExchangeRate,
    )
    if (amountInfo && amountInfo.productAmount) {
      // logger.trace('lib.core.helpers.models.shoppingCart.getTotalProductAmount: setting item amount.',
      //   { item, amountInfo })
      productAmount += amountInfo.productAmount.amount
    }
  })

  return productAmount
}

export default getTotalProductAmount
