import type { WishesQueryVariables } from '../definitions'
import type { WishListFilter } from '../../../lib/core/definitions'
import getWishListFilter from './getWishListFilter'

const getWishesQueryVariables = (
  listFilter: WishListFilter | undefined,
  userId: string,
): WishesQueryVariables => ({
  filter: listFilter || getWishListFilter(userId),
})

export default getWishesQueryVariables
