import { gql } from '@apollo/client'

export default gql`
  query Q($filter: ChatMessageListFilter!) {
    chatMessages(filter: $filter) {
      id
      chatId
      fromUserId
      toUserId
      messageType
      systemMessageType
      messageText
      imageUrl
      imageType
      animation
      receivedAt
      metadata {
        attachments {
          id
          chatAttachmentId
          attachmentType
          ... on ChatAttachmentPurchaseTransferInfo {
            fromPurchaseId
            toPurchaseId
            purchaseTransferId
            productId
            productOptionId
            purchaseType
            productImageSourceFrontSide
            status
            amount
            balance
            fundType
            currency
            discount
            merchantId
            merchantName
            merchantUrl
            merchantSmallLogoImageSource
            merchantLargeLogoImageSource
          }
          ... on ChatAttachmentRewardInfo {
            rewardType
            amount
            fundType
            currency
          }
          ... on ChatAttachmentTransactionInfo {
            transactionId
            transactionType
            amount
            fundType
            currency
          }
        }
        purchaseTransferId
        purchaseTransferStatus
        fromPurchaseId
        toPurchaseId
      }
      recordStatus
      createdAt
      updatedAt
    }
  }`
