import { gql } from '@apollo/client'

export default gql`
  query Q($taskId: String, $userId: String, $taskType: String, $options: QueryOptions) {
    asyncTask(taskId: $taskId, userId: $userId, taskType: $taskType, options: $options) {
      id
      userId
      username
      email
      phoneNumber
      taskType
      taskStatus
      result
      attemptCount
      notificationMethod
      notificationSentAt
      notificationResult
      notificationId
      textData
      report
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
