import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { Transaction, TransactionListItem } from '../../../lib/core/definitions'
import type {
  TransactionQueryData,
  TransactionQueryVariables,
  TransactionsQueryData,
  TransactionsQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  transaction: Transaction,
  queryListVariables: TransactionsQueryVariables,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateTransaction.updateInList called.', { transaction })
  if (!transaction) {
    logger.error('api.cache.updateTransaction.updateInList: no transaction given.')
    return
  }

  try {
    const cachedTransactionsData = apolloClient.readQuery<TransactionsQueryData, TransactionsQueryVariables>({
      query: apollo.queries.transactions,
      variables: queryListVariables,
    })

    if (
      !cachedTransactionsData ||
      !Array.isArray(cachedTransactionsData.transactions) ||
      cachedTransactionsData.transactions.length < 1
    ) {
      const listItem = coreHelpers.models.transaction.updateTransactionListItemFromTransaction(undefined, transaction)
      apolloClient.writeQuery<TransactionsQueryData, TransactionsQueryVariables>({
        query: apollo.queries.transactions,
        variables: queryListVariables,
        data: { transactions: [listItem] },
      })
      return
    }

    const updatedTransactions = coreHelpers.models.updateModelInList<TransactionListItem, Transaction>(
      cachedTransactionsData.transactions,
      transaction,
      undefined,
      true,
      coreHelpers.models.transaction.updateTransactionListItemFromTransaction,
    )

    apolloClient.writeQuery<TransactionsQueryData, TransactionsQueryVariables>({
      query: apollo.queries.transactions,
      variables: queryListVariables,
      data: { transactions: updatedTransactions },
    })
  } catch (error) {
    logger.error('api.cache.updateTransaction.updateInList: error.', { transaction, error })
  }
}

const updateTransaction = (
  transaction: Transaction,
  queryListVariables: TransactionsQueryVariables | undefined,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateTransaction called.', { transaction })
  if (!apolloClient) {
    logger.error('api.cache.updateTransaction: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!transaction || !transaction.id) {
    logger.error('api.cache.updateTransaction: no transaction given.', { transaction })
    return
  }

  try {
    apolloClient.writeQuery<TransactionQueryData, TransactionQueryVariables>({
      query: apollo.queries.transaction,
      variables: { transactionId: transaction.id },
      data: { transaction },
    })

    if (queryListVariables) {
      updateInList(
        transaction,
        queryListVariables,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateTransaction: error.', { transaction, error })
  }
}

export default updateTransaction
