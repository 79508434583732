import type { ContactUserListItem } from '../../../definitions'

const compareListItemsByChat = (contact1: ContactUserListItem, contact2: ContactUserListItem): number => {
  if (contact1.latestChatMessageCreatedAt && !contact2.latestChatMessageCreatedAt) {
    return -1
  }

  if (!contact1.latestChatMessageCreatedAt && contact2.latestChatMessageCreatedAt) {
    return 1
  }

  if (contact1.latestChatMessageCreatedAt && contact2.latestChatMessageCreatedAt) {
    return Number(contact2.latestChatMessageCreatedAt) - Number(contact1.latestChatMessageCreatedAt)
  }

  return (contact1.fullName || contact1.username || '')
    .localeCompare(contact2.fullName || contact2.username || '') || 0
}

export default compareListItemsByChat
