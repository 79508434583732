import type { ApolloClient, MutationOptions } from '@apollo/client'

import type {
  ApiQueryOptions,
  ContactEvent,
  ContactEventInput,
  ContactEventListFilter,
} from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertContactEventMutationData,
  UpsertContactEventMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import loadContactEvent from './loadContactEvent'
import logger from '../logger'

const upsertContactEvent = (
  contactEventInput: ContactEventInput,
  isInTargetStateFunc: ((contactEvent: ContactEvent) => boolean) | 'watch-updated-at' | undefined,
  listFilter: ContactEventListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ContactEvent | undefined> => (
  // console.log('api.upsertContactEvent called.', { contactEventInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertContactEvent: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertContactEventMutationData, UpsertContactEventMutationVariables> = {
      mutation: apollo.mutations.upsertContactEvent,
      variables: { contactEvent: contactEventInput },
    }

    apolloClient.mutate<UpsertContactEventMutationData, UpsertContactEventMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertContactEvent: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertContactEvent ||
          !resp.data.upsertContactEvent.id
        ) {
          logger.error('api.upsertContactEvent: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((contactEvent: ContactEvent) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!contactEventInput.id) {
            logger.error('api.upsertContactEvent: watch-updated-at should not be used on a new insert.', { resp })
          } else {
            const oldUpdatedAt = resp.data.upsertContactEvent.updatedAt
            activeIsInTargetStateFunc = (obj: ContactEvent) => obj.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertContactEvent)
        ) {
          // cache.updateContactEvent(
          //   resp.data.upsertContactEvent,
          //   apollo.getContactEventsQueryVariables(listFilter),
          //   apolloClient,
          // )
          // // console.log('api.upsertContactEvent: in target state, resolving.')
          resolve(resp.data.upsertContactEvent)
          return
        }

        const contactEventId = resp.data.upsertContactEvent.id

        setTimeout(() => {
          // console.log('api.upsertContactEvent: calling loadContactEvent.', { contactEventId })
          loadContactEvent(
            contactEventId,
            activeIsInTargetStateFunc,
            listFilter,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((contactEvent) => {
              // console.log('api.upsertContactEvent: resolving with contactEvent', contactEvent)
              resolve(contactEvent)
            }, (error) => {
              logger.error('api.upsertContactEvent: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertContactEvent: error', { error })
        reject(error)
      })
  })
)

export default upsertContactEvent
