import { TfaStatus } from '../../../../../lib/core/enums'
import { InputVerificationStatus } from '../../../../../enums'

export default (tfaStatus: TfaStatus | undefined): InputVerificationStatus | undefined => {
  if (tfaStatus === TfaStatus.CODE_MISMATCH) {
    return InputVerificationStatus.INVALID
  }
  if (tfaStatus === TfaStatus.PASSED) {
    return InputVerificationStatus.VALID
  }
}
