import type { CSSProperties } from 'react'
import React from 'react'
import { IonIcon } from '@ionic/react'
import { chevronDown, chevronUp } from 'ionicons/icons'

import './styles.css'

export enum ChipButtonType {
  SHOW_MORE = 'show-more',
}

export type Props = {
  type?: ChipButtonType
  text?: string
  contentId?: string
  show?: boolean
  block?: boolean
  rightAligned?: boolean
  selected?: boolean
  color?: string
  className?: string
  onClick: (contentId?: string) => void
}

const ChipButton: React.FC<Props> = (props): JSX.Element | null => {
  const {
    type,
    contentId,
    show,
    block,
    rightAligned,
    color,
    selected,
    className,
    onClick,
  } = props
  let { text } = props
  if (!text && type === ChipButtonType.SHOW_MORE) {
    text = selected ? 'See Less' : 'See More'
  }

  const handleClick = () => {
    onClick(contentId)
  }

  // ===================================================================================================================
  // Rendering:
  if (!show) {
    return null
  }

  let chevron: JSX.Element | undefined
  if (type === ChipButtonType.SHOW_MORE) {
    const icon = selected ? chevronUp : chevronDown
    chevron = <IonIcon icon={icon} class='chevron' />
  }

  const buttonStyle: CSSProperties = {}
  if (color) {
    buttonStyle.borderColor = color
  } else if (type === ChipButtonType.SHOW_MORE) {
    buttonStyle.borderColor = 'var(--border-color-light)'
  }

  const labelStyle: CSSProperties = {}
  if (color) {
    labelStyle.color = color
  } else if (type === ChipButtonType.SHOW_MORE) {
    labelStyle.color = 'var(--border-color-light)'
  }

  const buttonClass = block
    ? `chip-button ${className || ''}`
    : `chip-button inline ${className || ''}`
  const button = (
    <div
      className={buttonClass}
      style={buttonStyle}
      onClick={handleClick}
    >
      <div className='label' style={labelStyle}>
        {text}
      </div>
      {chevron}
    </div>
  )

  if (block) {
    const rowClassName = rightAligned
      ? 'chip-button-row-right-aligned'
      : 'chip-button-row'
    return (
      <div className={`${rowClassName} ${className || ''}`}>
        {button}
      </div>
    )
  }

  return button
}

ChipButton.defaultProps = {
  show: true,
  block: true,
}

export default ChipButton
