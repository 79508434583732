import type { Inbox, InboxItem } from '../../../definitions'
import { InboxItemType } from '../../../enums'

const composeInboxFromItems = (userId: string, items: InboxItem[]): Inbox => {
  const inbox: Inbox = {
    userId,
    appBadge: 0,
    chatMessageCount: 0,
    systemMessageCount: 0,
    giftCount: 0,
    items,
  }

  if (!Array.isArray(items) || items.length < 1) {
    return inbox
  }

  items.forEach((item) => {
    switch (item.inboxItemType) {
      case InboxItemType.PURCHASE:
        // @ts-ignore
        inbox.giftCount += 1
        // @ts-ignore
        inbox.appBadge += 1
        break
      case InboxItemType.PURCHASE_TRANSFER:
        // @ts-ignore
        inbox.giftCount += 1
        // @ts-ignore
        inbox.appBadge += 1
        break
      case InboxItemType.CHAT:
        if (item.metadata && item.metadata.chat) {
          if (item.metadata.chat.unreadMessageCount) {
            // @ts-ignore
            inbox.chatMessageCount += item.metadata.chat.unreadMessageCount
            // @ts-ignore
            inbox.appBadge += item.metadata.chat.unreadMessageCount
          }
          if (item.metadata.chat.unreadSystemMessageCount) {
            // @ts-ignore
            inbox.systemMessageCount += item.metadata.chat.unreadSystemMessageCount
          }
        }
        break
      case InboxItemType.SYSTEM_NOTIFICATION:
        // @ts-ignore
        inbox.systemMessageCount += 1
        break
    }
  })

  return inbox
}

export default composeInboxFromItems
