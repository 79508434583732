import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { IonButton } from '@ionic/react'

import './styles.css'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import type { UserPreferencesField } from '../../../lib/core/definitions'
import FormCheckbox from '../../../components/FormCheckbox/FormCheckbox'
import pageHelpers from '../../../helpers/pageHelpers'
import PasswordForm from './PasswordForm/PasswordForm'
import ProcessingOverlay from '../../../components/ProcessingOverlay/ProcessingOverlay'
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm'

type Props = {
  showUiMessage: (message: string) => void
}

const SettingsTab: React.FC<Props> = ({
  showUiMessage,
}): JSX.Element => {
  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser, reloadActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [contrastPennies, setContrastPennies] = useState(undefined as boolean | undefined)
  const [isEditingPassword, setIsEditingPassword] = useState(false)
  const [isResettingPassword, setIsResettingPassword] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  // ===================================================================================================================
  // Helpers:
  let contrastPenniesDisplay = contrastPennies

  if (
    (contrastPennies !== true && contrastPennies !== false) &&
    activeUser &&
    activeUser.prefs &&
    (activeUser.prefs.contrastPennies === true || activeUser.prefs.contrastPennies === false)
  ) {
    contrastPenniesDisplay = activeUser.prefs.contrastPennies
  }

  const resetForm = (): void => {
    setContrastPennies(undefined)
  }

  const updatePrefFlag = (field: UserPreferencesField, defaultVal?: boolean) => {
    if (activeUser && activeUserId) {
      const oldVal = activeUser.prefs && activeUser.prefs[field]
      const newVal = ((defaultVal === true || defaultVal === false) && (oldVal === null || oldVal === undefined))
        ? !defaultVal
        : !oldVal
      setIsSaving(true)
      pageHelpers.updateUserPref(
        { [field]: newVal },
        activeUser,
        apolloClient,
        reloadActiveUser,
        showUiMessage,
      ).then(() => {
        setIsSaving(false)
      }, (error) => {
        console.error(error)
        setIsSaving(false)
      })
    }
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeContrastPennies = () => {
    updatePrefFlag('contrastPennies', false)
  }

  const onChangeShareEmail = () => {
    updatePrefFlag('shareEmail', true)
  }

  const onChangeSharePhoneNumber = () => {
    updatePrefFlag('sharePhoneNumber', true)
  }

  const onChangeShareBirthday = () => {
    updatePrefFlag('shareBirthday', true)
  }

  const onChangeShareBirthdayYear = () => {
    updatePrefFlag('shareBirthdayYear', false)
  }

  const onChangeShareWishList = () => {
    updatePrefFlag('shareWishList', true)
  }

  const onClosePasswordForm = () => {
    setIsEditingPassword(false)
  }

  const onOpenResetPasswordForm = () => {
    setIsEditingPassword(false)
    setIsResettingPassword(true)
  }

  const onCloseResetPasswordForm = () => {
    setIsEditingPassword(false)
    setIsResettingPassword(false)
  }

  // ===================================================================================================================
  // Rendering:
  const penniesClass = contrastPenniesDisplay ? 'purchaseListItemPennies' : 'purchaseListItemAmount'
  const contrastPenniesLabel = (
    <div>
      Reduce text size for pennies, i.e.:&nbsp;
      <span className='purchaseListItemAmount'>$12</span>
      <span className={penniesClass}>.34</span>
    </div>
  )

  let passwordSection: JSX.Element | undefined
  if (isEditingPassword || isResettingPassword) {
    if (isEditingPassword) {
      passwordSection = (
        <PasswordForm
          onClose={onClosePasswordForm}
          onOpenResetPasswordForm={onOpenResetPasswordForm}
          showUiMessage={showUiMessage}
        />
      )
    } else {
      passwordSection = (
        <ResetPasswordForm
          onClose={onCloseResetPasswordForm}
          showUiMessage={showUiMessage}
        />
      )
    }
  } else {
    passwordSection = (
      <div
        className='rowWithCenterJustifiedItems withDoubleTopMargin'
        onClick={() => setIsEditingPassword(true) }>
        <IonButton fill='outline'>Change password</IonButton>
      </div>
    )
  }

  return (
    <div className='settings-tab'>
      <form>
        <div className='withTripleBottomMargin'>
          <div className='sectionCaption'>App Settings</div>
          <FormCheckbox
            isChecked={contrastPenniesDisplay}
            label={contrastPenniesLabel}
            onChange={onChangeContrastPennies}
          />
        </div>
        <div className='withTripleBottomMargin'>
          <div className='sectionCaption'>Privacy Settings</div>
          <div className='withStandardBottomMargin'>
            Specify which information you would like to share with your contacts:
          </div>
          <FormCheckbox
            isChecked={activeUser && !(activeUser.prefs && activeUser.prefs.shareBirthday === false)}
            label='Birthday'
            onChange={onChangeShareBirthday}
          />
          <FormCheckbox
            isChecked={activeUser && activeUser.prefs && activeUser.prefs.shareBirthdayYear}
            label='Birthday Year'
            onChange={onChangeShareBirthdayYear}
          />
          <FormCheckbox
            isChecked={activeUser && !(activeUser.prefs && activeUser.prefs.shareEmail === false)}
            label='Email address'
            onChange={onChangeShareEmail}
          />
          <FormCheckbox
            isChecked={activeUser && !(activeUser.prefs && activeUser.prefs.sharePhoneNumber === false)}
            label='Phone number'
            onChange={onChangeSharePhoneNumber}
          />
          <FormCheckbox
            isChecked={activeUser && !(activeUser.prefs && activeUser.prefs.shareWishList === false)}
            label='Wish list'
            onChange={onChangeShareWishList}
          />
        </div>
      </form>
      {passwordSection}
      <ProcessingOverlay text='Saving changes...' isProcessing={isSaving} />
    </div>
  )
}

export default SettingsTab
