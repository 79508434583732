import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpdateUserMutationData,
  UpdateUserMutationVariables,
} from '../apollo/definitions'
import type {
  ApiQueryOptions,
  User,
  UserInput,
  UserListFilter,
} from '../../lib/core/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadUser from './loadUser'
import logger from '../logger'

const updateUser = (
  userInput: UserInput,
  user: User,
  isInTargetStateFunc: ((user: User) => boolean) | 'watch-updated-at' | undefined,
  listFilter: UserListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<User | undefined> => (
  // console.log('api.updateUser called.', { userInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.updateUser: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpdateUserMutationData, UpdateUserMutationVariables> = {
      mutation: apollo.mutations.updateUser,
      variables: { user: userInput },
    }

    apolloClient.mutate<UpdateUserMutationData, UpdateUserMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.updateUser: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.updateUser ||
          !resp.data.updateUser.id
        ) {
          logger.error('api.updateUser: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((user: User) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!userInput.id) {
            logger.error('api.updateUser: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = user.updatedAt
            activeIsInTargetStateFunc = (user: User) => user.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.updateUser)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getUsersQueryVariables(listFilter)
            : undefined
          cache.updateUser(
            resp.data.updateUser,
            queryListVariables,
            apolloClient,
          )
          resolve(resp.data.updateUser)
          return
        }

        const userId = resp.data.updateUser.id

        setTimeout(() => {
          // console.log('api.updateUser: calling loadUser')
          loadUser(
            userId,
            activeIsInTargetStateFunc,
            listFilter,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((user) => {
              // console.log('api.updateUser: resolving with user', user)
              resolve(user)
            }, (error) => {
              logger.error('api.updateUser: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.updateUser: error', { error })
        reject(error)
      })
  })
)

export default updateUser
