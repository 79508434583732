import React from 'react'
import { calendarOutline, giftOutline, personCircleOutline } from 'ionicons/icons'

import './styles.css'
import { ContactPageProfileTabSectionId } from '../enums'
import { IonButton, IonIcon } from '@ionic/react'

type Props = {
  sectionId: ContactPageProfileTabSectionId
  onOpenSection: (sectionId: ContactPageProfileTabSectionId) => void
}

const NavSection: React.FC<Props> = (props): JSX.Element | null => {
  const { sectionId, onOpenSection } = props

  const buttonDefs = [
    {
      label: 'Info',
      sectionId: ContactPageProfileTabSectionId.PROFILE_INFO,
      icon: personCircleOutline,
    },
    {
      label: 'Events',
      sectionId: ContactPageProfileTabSectionId.EVENTS,
      icon: calendarOutline,
    },
    {
      label: 'Gifts',
      sectionId: ContactPageProfileTabSectionId.PAST_GIFTS,
      icon: giftOutline,
    },
  ]
  const buttons = buttonDefs.map((buttonDef, index): JSX.Element => {
    return (
      <IonButton
        key={buttonDef.sectionId}
        fill={buttonDef.sectionId === sectionId ? 'solid' : 'outline'}
        size='small'
        className={index === buttonDefs.length - 1 ? '' : 'withSmallRightMargin'}
        onClick={() => onOpenSection(buttonDef.sectionId)}
      >
        <IonIcon icon={buttonDef.icon} className='withSmallRightMargin' />
        {buttonDef.label}
      </IonButton>
    )
  })
  return (
    <div className='nav-section'>
      {buttons}
    </div>
  )
}

export default NavSection
