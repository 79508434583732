import type { ShoppingCart } from '../../../definitions'
import logger from '../../../logger'
import transactionRemoteStatusHelpers from '../../type/transactionRemoteStatusHelpers'

const isPaymentSettled = (shoppingCart: ShoppingCart): boolean => {
  logger.debug('lib.core.helpers.models.shoppingCart.isPaymentSettled called.', { shoppingCart })
  if (!shoppingCart) {
    logger.warn('lib.core.helpers.models.shoppingCart.isPaymentSettled: missing shoppingCart.')
    return false
  }
  return (
    !!shoppingCart.paymentTransactionId &&
    transactionRemoteStatusHelpers.isSettled(shoppingCart.paymentStatus)
  )
}

export default isPaymentSettled
