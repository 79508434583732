import type { Chat } from '../../lib/core/definitions'
import type { ChatContactUserInfo } from '../../definitions'
import coreHelpers from '../../lib/core/helpers'

const getChatListInfo = (
  chat: Chat | null | undefined,
  activeUserId: string | null | undefined,
  isAdminOrgChat: boolean | undefined,
): { contactInfo: ChatContactUserInfo, unreadMessageCount: number, unreadSystemMessageCount: number } => {
  if (!chat) {
    return { contactInfo: { id: '' }, unreadMessageCount: 0, unreadSystemMessageCount: 0 }
    // return { contactInfo: { contactId: '' }, unreadMessageCount: 0 }
  }

  const activeUserIsFromUser = isAdminOrgChat || coreHelpers.models.compareId(chat.fromUserId, activeUserId)
  const id = (activeUserIsFromUser ? chat.toUserId : chat.fromUserId) || ''
  const u = activeUserIsFromUser ? chat.toUser : chat.fromUser

  let unreadMessageCount = 0
  let unreadSystemMessageCount = 0
  if (chat.metadata) {
    const userInfo = activeUserIsFromUser
      ? chat.metadata.fromUser
      : chat.metadata.toUser
    if (userInfo) {
      if (userInfo.unreadMessageCount) {
        unreadMessageCount = userInfo.unreadMessageCount
      }
      if (userInfo.unreadSystemMessageCount) {
        unreadSystemMessageCount = userInfo.unreadSystemMessageCount
      }
    }
  }

  return {
    contactInfo: {
      id,
      username: u ? u.username : undefined,
      fullName: u ? u.fullName : undefined,
      imageUrl: u ? u.imageUrl : undefined,
      role: u ? u.roles : undefined,
      ident: u ? u.fullName || u.username || u.email || u.phoneNumber || id : id,
    },
    unreadMessageCount,
    unreadSystemMessageCount,
  }
}

export default getChatListInfo
