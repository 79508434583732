import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { ApiQueryOptions, ContactEventReminderListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  DeleteContactEventReminderMutationData,
  DeleteContactEventReminderMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import loadContactEventReminder from './loadContactEventReminder'
import logger from '../logger'

const deleteContactEventReminder = (
  contactEventReminderId: string,
  apolloClient: ApolloClient<any>,
  listFilter?: ContactEventReminderListFilter,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<void> => (
  new Promise((resolve, reject) => {
    // console.log('api.deleteContactEventReminder called.', { contactEventReminderInput })

    if (!apolloClient) {
      logger.error('api.deleteContactEventReminder: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<DeleteContactEventReminderMutationData, DeleteContactEventReminderMutationVariables> = {
      mutation: apollo.mutations.deleteContactEventReminder,
      variables: { contactEventReminderId },
    }

    apolloClient.mutate<DeleteContactEventReminderMutationData, DeleteContactEventReminderMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.deleteContactEventReminder: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.deleteContactEventReminder ||
          !resp.data.deleteContactEventReminder.id
        ) {
          logger.error('api.deleteContactEventReminder: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        const contactEventReminderId = resp.data.deleteContactEventReminder.id

        setTimeout(() => {
          // console.log('api.deleteContactEventReminder: calling loadContactEventReminder')
          loadContactEventReminder(
            contactEventReminderId,
            'expect-null',
            undefined,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then(() => {
              // console.log('api.deleteContactEventReminder: resolving with contactEventReminder', contactEventReminder)
              resolve()
            }, (error) => {
              logger.error('api.deleteContactEventReminder: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.deleteContactEventReminder: error', { error })
        reject(error)
      })
  })
)

export default deleteContactEventReminder
