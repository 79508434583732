import React from 'react'
import { IonImg } from '@ionic/react'
import Lottie from 'react-lottie-player'

import './styles.css'
import type { AnimationName, ChatImageType } from '../../../lib/core/enums'
import { FundType, TokenName } from '../../../lib/core/enums'
import { useAppHelpers } from '../../../contexts/AppHelpersContext/AppHelpersContext'
import animations from '../../../animations'
import ChatAttachmentPreview from '../../../components/ChatAttachmentPreview/ChatAttachmentPreview'
import TokenAttachment from '../../../components/TokenAttachment/TokenAttachment'

type Props = {
  tokenTransferAmount?: number | undefined
  imageUrl?: string | null
  imageType?: ChatImageType | null
  animation?: AnimationName | null
  imageClassName?: string;
  onDeleteAttachment: (id: string) => void
}

const ChatAttachmentPreviewSection: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('ChatAttachmentPreviewSection: render called.', props)
  const {
    tokenTransferAmount,
    imageUrl,
    imageType,
    animation,
    imageClassName,
    onDeleteAttachment,
  } = props

  const { showFullPageAnimation } = useAppHelpers()

  // ===================================================================================================================
  // Rendering:
  if (
    !tokenTransferAmount &&
    !imageUrl &&
    !animation
  ) {
    return null
  }

  const previewList: JSX.Element[] = []
  if (animation) {
    previewList.push(
      <ChatAttachmentPreview
        key='animation'
        attachmentId='animation'
        onDelete={onDeleteAttachment}
        className='withSmallLeftMargin'
      >
        <Lottie
          play={true}
          animationData={animations.fullPage[animation]}
          className='animation'
          onClick={() => showFullPageAnimation(animation)}
        />
      </ChatAttachmentPreview>,
    )
  }

  if (imageUrl) {
    previewList.push(
      <ChatAttachmentPreview
        key='image'
        attachmentId='image'
        onDelete={onDeleteAttachment}
        className='withSmallLeftMargin'
      >
        <IonImg
          src={imageUrl}
          className={`attachment-image ${imageClassName || ''}`}
        />
      </ChatAttachmentPreview>,
    )
  }

  if (tokenTransferAmount) {
    previewList.push(
      <ChatAttachmentPreview
        key='token-transfer'
        attachmentId='token-transfer'
        onDelete={onDeleteAttachment}
      >
        <TokenAttachment
          amount={tokenTransferAmount}
          fundType={FundType.TOKEN}
          currency={TokenName.MIMBLE_TOKEN}
          className='withStandardLeftMargin'
        />
      </ChatAttachmentPreview>,
    )
  }

  return (
    <div className='chat-attachment-preview-section'>
      {previewList}
    </div>
  )
}

export default ChatAttachmentPreviewSection
