import { CryptoCurrency, FiatCurrency, FundType, TokenName, TransactionProcessor } from '../../enums'
import type { Currency } from '../../definitions'

const labelsEn = Object.freeze({
  [TransactionProcessor.BITCOIN_BLOCKCHAIN]: 'Bitcoin',
  [TransactionProcessor.COINPAYMENTS]: 'Crypto',
  [TransactionProcessor.STRIPE]: 'Credit Card',
  [TransactionProcessor.INTERNAL]: 'Mimble Tokens',
})

const transactionProcessorHelpers = {
  getLabel: (status?: TransactionProcessor | null, language?: string): string => {
    if (!status) {
      return ''
    }
    if (!language || language === 'US') {
      return labelsEn[status]
    }
    return ''
  },

  isCrypto: (processor: TransactionProcessor | null | undefined): boolean => (
    !!processor &&
    (
      processor === TransactionProcessor.BITCOIN_BLOCKCHAIN ||
      processor === TransactionProcessor.COINPAYMENTS
    )
  ),

  isUsingCryptoReceivingAddress: (processor: TransactionProcessor | null | undefined): boolean => (
    !!processor &&
    (
      processor === TransactionProcessor.BITCOIN_BLOCKCHAIN ||
      processor === TransactionProcessor.COINPAYMENTS
    )
  ),

  isValid: (processor: TransactionProcessor): boolean => Object.values(TransactionProcessor).includes(processor),

  getFundTypeAndCurrency: (processor: TransactionProcessor | null | undefined): {
    fundType: FundType | undefined,
    currency: Currency | undefined,
  } => {
    if (!processor) {
      return { fundType: FundType.FIAT, currency: FiatCurrency.USD }
    }

    switch (processor) {
      case TransactionProcessor.BITCOIN_BLOCKCHAIN:
      case TransactionProcessor.COINPAYMENTS:
        return {
          fundType: FundType.CRYPTO,
          currency: CryptoCurrency.BITCOIN,
        }
      case TransactionProcessor.STRIPE:
        return {
          fundType: FundType.FIAT,
          currency: FiatCurrency.USD,
        }
      case TransactionProcessor.INTERNAL:
        return {
          fundType: FundType.TOKEN,
          currency: TokenName.MIMBLE_TOKEN,
        }
    }
  },
}

export default transactionProcessorHelpers
