import React from 'react'
import { IonButton } from '@ionic/react'

import { FundType, TokenName } from '../../lib/core/enums'
import coreHelpers from '../../lib/core/helpers'

type Props = {
  purchaseAmount: number;
  walletBalance: number | undefined;
  onOpenMarketplace: () => void;
};

const ForTokens: React.FC<Props> = (props): JSX.Element | null => {
  const {
    purchaseAmount,
    walletBalance,
    onOpenMarketplace,
  } = props

  const formattedPurchaseAmount = coreHelpers.ui.formatAmount(
    purchaseAmount, FundType.TOKEN, TokenName.MIMBLE_TOKEN, true)

  let newBalanceText: JSX.Element | undefined
  if (walletBalance) {
    const formattedWalletBalance = coreHelpers.ui.formatAmount(
      walletBalance, FundType.TOKEN, TokenName.MIMBLE_TOKEN, true)
    newBalanceText = (
      <span>The new balance is now <strong>{formattedWalletBalance}</strong>.</span>
    )
  } else {
    // newBalanceText = (
    //   <span>(calculating the new token balance...)</span>
    // )
  }
  // console.log('PaymentReceivedForTokenPurchase.render called.', { props });
  return (
    <div className='g-with-safe-padding'>
      <h2>Thank you!</h2>
      Your payment has been received.
      We have added <strong>{formattedPurchaseAmount}</strong> to your Mimble wallet.
      {newBalanceText}
      <br />
      <br />
      You can now use your Mimble tokens to purchase new gift cards in the marketplace.
      <div className='withStandardTopMargin'>
        <div key='activateFormButtons' className='formButtonWrapper'>
          <IonButton onClick={onOpenMarketplace}>Open Marketplace</IonButton>
        </div>
      </div>
    </div>
  )
}

export default ForTokens
