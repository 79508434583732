import { AssetType } from '../../enums'

const labelsEn = Object.freeze({
  [AssetType.PURCHASE_FRONT]: 'purchase frontside',
  [AssetType.PURCHASE_BACK]: 'purchase backside',
  [AssetType.MERCHANT_LOGO]: 'merchant logo',
  [AssetType.USER_AVATAR]: 'avatar',
})

export default {
  getLabel: (type: AssetType | null | undefined, language = 'en'): string => {
    if (!type) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[type]
    }
    return ''
  },
  isValid: (type: AssetType | string | null | undefined): boolean => Object.values(AssetType).includes(type as AssetType),
  typeNeedsUserRelation: (type: AssetType | string | null | undefined): boolean => type !== AssetType.MERCHANT_LOGO,
}
