import Moment from 'moment-timezone'
import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'
import type {
  GridColumns,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import {
  DataGridPro,
} from '@mui/x-data-grid-pro'

import type { CampaignListFilter, CampaignListItem } from '../../../lib/core/definitions'
// import coreHelpers from '../../../lib/core/helpers'
// import pageHelpers from '../../../helpers/pageHelpers'

type Props = {
  campaigns: CampaignListItem[] | null | undefined
  filter: CampaignListFilter | undefined
  isProcessing?: boolean
  onOpenCampaign: (campaignId: string) => void
}

const CampaignGrid: React.FC<Props> = (props): JSX.Element | null => {
  const { campaigns, filter, isProcessing, onOpenCampaign } = props

  const filterList = (template: CampaignListItem): boolean => {
    if (filter && filter.searchText) {
      const reg = new RegExp(filter.searchText, 'i')
      if (
        (!template.name || !template.name.match(reg)) // &&
        // (!template.description || !template.description.match(reg)) &&
        // (!template.titleEn || !template.titleEn.match(reg))
      ) {
        return false
      }
    }
    return true
  }

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (rowData: GridRowParams): void => {
    onOpenCampaign(rowData.row.id as string)
  }

  const onExport = (): void => {
    if (!Array.isArray(campaigns) || campaigns.length < 1) {
      return
    }
    console.error('not implemented')
    // const data = coreHelpers.models.campaign.exportData(campaigns, filter)
    // const link = document.createElement('a')
    // link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
    // link.target = '_blank'
    // link.download = 'members.csv'
    // link.click()
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(campaigns) || campaigns.length < 1) {
    return null
  }
  // see https://material-ui.com/components/data-grid/rendering/#components

  const columns: GridColumns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      flex: 0.5,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? Moment(Number(params.value)).format('YYYY-MM-DD') : '-'
      ),
      sortComparator: (v1, v2) => (
        Number(v2) - Number(v1)
      ),
    },
  ]
  const rows: GridRowsProp = campaigns
    .filter(filterList)
    .map((campaign): GridRowModel => {
      return {
        id: campaign.id as string,
        name: campaign.name || '-',
        updatedAt: campaign.updatedAt as string,
      }
    })

  return (
    <div className='x-grid-data-view'>
      <div className='actions-row'>
        <IonButton
          size='small'
          fill='clear'
          color='medium'
          disabled={!Array.isArray(campaigns) || campaigns.length < 1 || isProcessing}
          onClick={onExport}
        >
          <IonIcon icon={cloudDownloadOutline} />
        </IonButton>
      </div>
      <div className='x-grid-wrapper'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  )
}

export default CampaignGrid
