import moment from 'moment'
import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { IonIcon } from '@ionic/react'
import { callOutline, mailOutline } from 'ionicons/icons'
import { CakeOutlined as CakeOutlinedIcon } from '@mui/icons-material'

import './styles.css'
import type { Contact, ContactInput, User } from '../../../../lib/core/definitions'
import { useMimbleData } from '../../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../../services/api'
import coreHelpers from '../../../../lib/core/helpers'
import InfoPaneEntry from '../../../../components/InfoPaneEntry/InfoPaneEntry'
import InPlaceInput from '../../../../components/InPlaceInput/InPlaceInput'

type Props = {
  contact: Contact | null | undefined
  user: User | null | undefined
  showUiMessage: (message: string) => void
}

const InfoSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contact,
    user,
    // user: userFromProps,
    showUiMessage,
  } = props
  // const user = {
  //   ...userFromProps,
  //   email: 'someone@gmail.com',
  //   phoneNumber: '+15031234567',
  //   // birthday: '0000-02-23',
  //   birthday: '1983-02-23',
  // }

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser, contacts, reloadContacts } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [isSavingAttribute, setIsSavingAttribute] = useState(false)
  const [validationError, setValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Event Handlers:
  const onChangeAttribute = (id: string, value: string | null | undefined): void => {
    setValidationError(undefined)
  }

  const onSaveAttribute = (id: string, value: string | null | undefined): void => {
    // console.log('onSaveAttribute:', value)
    if (!contact || !activeUserId) {
      return
    }
    const contactInput: ContactInput = {
      id: contact.id,
      fromUserId: activeUserId,
      toUserId: contact.toUserId,
    }
    if (id === 'nickname') {
      if (
        value &&
        Array.isArray(contacts) &&
        contacts.length > 0
      ) {
        if (contacts.find((contact) => contact.nickname === value)) {
          setValidationError(`You already used ${value} for a different contact`)
          return
        }
      }
      contactInput.nickname = value
    }
    setIsSavingAttribute(true)
    api.upsertContact(
      contactInput,
      'watch-updated-at',
      { fromUserId: activeUserId, orderBy: 'fullName' },
      activeUserId as string,
      apolloClient,
    )
      .then(() => {
        setIsSavingAttribute(false)
        showUiMessage && showUiMessage('Saved successfully')
        reloadContacts().then(undefined, (error) => {
          console.error(error)
        })
      }, (error) => {
        console.error(error)
        setIsSavingAttribute(false)
        // showUiMessage && showUiMessage('Failed to save')
      })
  }

  // ===================================================================================================================
  // Rendering:
  if (!user) {
    return null
  }

  const contactInfo: JSX.Element[] = []
  if (user.email) {
    contactInfo.push(
      <div key='email' className='contact-info-line'>
        <IonIcon className='withStandardRightMargin' icon={mailOutline}/>
        <span className='contact-info-line-content'>{user.email}</span>
      </div>,
    )
  }
  if (user.phoneNumber) {
    const formattedPhoneNumber = coreHelpers.ui.formatPhoneNumber(user.phoneNumber)
    contactInfo.push(
      <div key='phone' className='contact-info-line'>
        <IonIcon className='withStandardRightMargin' icon={callOutline} />
        <span className='contact-info-line-content'>{formattedPhoneNumber}</span>
      </div>,
    )
  }
  if (user.birthday) {
    const hasYear = !user.birthday.startsWith('0000')
    const date = hasYear ? user.birthday : user.birthday.substr(5)
    let displayVal = hasYear
      ? moment(date, 'YYYY-MM-DD').format('MMMM Do, YYYY')
      : moment(date, 'MM-DD').format('MMMM Do')
    if (hasYear) {
      displayVal = `${displayVal} - age ${moment().diff(date, 'years')}`
    }
    contactInfo.push(
      <div key='birthday' className='contact-info-line'>
        <CakeOutlinedIcon
          fontSize='small'
          className='withStandardRightMargin'
          classes={{
            fontSizeSmall: 'birthday-icon-fontsize-small',
          }} />
        <span className='contact-info-line-content'>{displayVal}</span>
      </div>,
    )
  }

  if (contact && contact.invitation) {
    let value: string
    if (contact.invitation.toUserId === contact.toUserId) {
      value = `You invited ${user.fullName} on ${coreHelpers.ui.formatDate(contact.invitation.createdAt)}`
    } else {
      value = `${user.fullName} invited you on ${coreHelpers.ui.formatDate(contact.invitation.createdAt)}`
    }
    contactInfo.push(
      <div key='invitation' className='invitation-info'>
        {value}
      </div>
    )
  }

  return (
    <div className='info-section'>
      <InPlaceInput
        id='nickname'
        value={contact && contact.nickname}
        label='Nickname'
        placeholder='New Nickname'
        validationError={validationError}
        isProcessing={isSavingAttribute}
        onChange={onChangeAttribute}
        onSave={onSaveAttribute}
      >
        <InfoPaneEntry
          label='Nickname'
          value={(contact && contact.nickname)}
          isInPlaceEditable
        />
      </InPlaceInput>
      {contactInfo}
    </div>
  )
}

export default InfoSection
