import { parseFullName } from 'parse-full-name'

export interface ParsedName {
  title?: string;
  first?: string;
  middle?: string;
  last?: string;
  nick?: string;
  suffix?: string;
  error?: [];
}

const parseName = (fullName: string | null | undefined): ParsedName | undefined => {
  if (!fullName) {
    return
  }
  return parseFullName(fullName)
}

export default parseName
