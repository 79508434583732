import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { User, UserListItem } from '../../../lib/core/definitions'
import type {
  UserQueryData,
  UserQueryVariables,
  UsersQueryData,
  UsersQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  user: User,
  queryListVariables: UsersQueryVariables,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateUser.updateInList called.', { user })
  if (!user) {
    logger.error('api.cache.updateUser.updateInList: no user given.')
    return
  }

  try {
    const cachedUsersData = apolloClient.readQuery<UsersQueryData, UsersQueryVariables>({
      query: apollo.admin.queries.users,
      variables: queryListVariables,
    })

    if (
      !cachedUsersData ||
      !Array.isArray(cachedUsersData.users) ||
      cachedUsersData.users.length < 1
    ) {
      const listItem = coreHelpers.models.user.updateUserListItemFromUser(undefined, user)
      apolloClient.writeQuery<UsersQueryData, UsersQueryVariables>({
        query: apollo.admin.queries.users,
        variables: queryListVariables,
        data: { users: [listItem] },
      })
      return
    }

    const updatedUsers = coreHelpers.models.updateModelInList<UserListItem, User>(
      cachedUsersData.users,
      user,
      undefined,
      true,
      coreHelpers.models.user.updateUserListItemFromUser,
    )

    apolloClient.writeQuery<UsersQueryData, UsersQueryVariables>({
      query: apollo.admin.queries.users,
      variables: queryListVariables,
      data: { users: updatedUsers },
    })
  } catch (error) {
    logger.error('api.cache.updateUser.updateInList: error.', { user, error })
  }
}

const updateUser = (
  user: User,
  queryListVariables: UsersQueryVariables | undefined,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateUser called.', { user, queryListVariables })
  if (!apolloClient) {
    logger.error('api.cache.updateUser: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!user || !user.id) {
    logger.error('api.cache.updateUser: no user given.', { user })
    return
  }

  try {
    // console.log('api.cache.updateUser: updating single object', { user })
    apolloClient.writeQuery<UserQueryData, UserQueryVariables>({
      query: apollo.queries.user,
      variables: { userId: user.id },
      data: { user },
    })

    if (queryListVariables) {
      // console.log('api.cache.updateUser: updating object in list.', { user, queryListVariables })
      updateInList(
        user,
        queryListVariables,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateUser: error.', { user, error })
  }
}

export default updateUser
