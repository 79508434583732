import React, { useEffect, useState } from 'react'
import { IonButton } from '@ionic/react'

import { BarcodeType } from '../../../lib/core/enums'
import type { Currency, Purchase } from '../../../lib/core/definitions'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import BalanceForm from './BalanceForm'
import Barcode from '../../../components/Barcode/Barcode'
import coreHelpers from '../../../lib/core/helpers/'
import pageHelpers from '../../../helpers/pageHelpers'

type Props = {
  purchase: Purchase;
  isProcessing?: boolean;
  onUpdateBalance: (balance: number, notes?: string) => void;
  onShowingInputForm: (formIsVisible: boolean) => void;
  onShowZoomedBarcode: () => void;
  showUiMessage: (message: string) => void;
};

const PurchaseUsageTab: React.FC<Props> = ({
  purchase,
  isProcessing,
  onUpdateBalance,
  onShowingInputForm,
  onShowZoomedBarcode,
  showUiMessage,
}: Props): JSX.Element | null => {
  // ===================================================================================================================
  // State:
  const { getActiveUserPrefs } = useGlobalCache()
  const userPrefs = getActiveUserPrefs()

  const [editedBalance, setEditedBalance] = useState<number | undefined>()
  const [showEditBalance, setShowEditBalance] = useState(false)

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    setEditedBalance(undefined)
    setShowEditBalance(false)
  }, [purchase])

  // ===================================================================================================================
  // Helpers:
  if (!purchase) {
    return null
  }

  const currency = purchase.currency as Currency
  const contrastPennies = userPrefs ? userPrefs.contrastPennies : false

  // ===================================================================================================================
  // Event Handlers:
  const onEditBalance = (): void => {
    setShowEditBalance(true)
    onShowingInputForm(true)
  }

  const onChangeBalance = (newEditedBalance: number | undefined): void => {
    setEditedBalance(newEditedBalance)
  }

  const onSaveNewBalance = (balance: number, notes?: string): void => {
    onUpdateBalance(balance, notes)
    setShowEditBalance(false)
    onShowingInputForm(false)
  }

  const onCancelEditBalance = (): void => {
    setShowEditBalance(false)
    onShowingInputForm(false)
    setEditedBalance(undefined)
  }

  const onCopyCodeToClipboard = (): void => {
    if (purchase.code) {
      pageHelpers.copyInputTextToClipboard(purchase.code, 'Code', showUiMessage)
    }
  }

  const onCopyPinToClipboard = (): void => {
    if (purchase.pin) {
      pageHelpers.copyInputTextToClipboard(purchase.pin, 'PIN', showUiMessage)
    }
  }

  const onClickLookUpBalance = (): void => {
    if (!purchase || !purchase.product) {
      return
    }
    const { merchant } = purchase.product
    if (!merchant) {
      return
    }
    const { balanceLookupUri } = merchant
    if (!balanceLookupUri) {
      return
    }
    window.open(balanceLookupUri, '_system')
  }

  // ===================================================================================================================
  // Rendering:
  const sections: JSX.Element[] = []

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Card Balance:
  let editBalanceButton
  if (!showEditBalance) {
    editBalanceButton = (
      <IonButton onClick={onEditBalance} className='smallButton'>
        Edit Balance
      </IonButton>
    )
  }

  let lookUpBalanceButton
  if (
    purchase.product &&
      purchase.product.merchant &&
      purchase.product.merchant.balanceLookupUri
  ) {
    lookUpBalanceButton = (
      <div className='smallText linkText withStandardBottomMargin' onClick={onClickLookUpBalance}>Look up balance</div>
    )
  }

  const newBalance = editedBalance || editedBalance === 0 ? editedBalance : purchase.balance
  const formattedBalance = coreHelpers.ui.formatAmount(newBalance, purchase.fundType, purchase.currency, false, 2)
  const formattedBalanceParts = formattedBalance.split('.') // todo: use locale, might not be a period

  const formattedBalanceUpdateDate = coreHelpers.ui.formatDate(purchase.balanceUpdatedAt || purchase.updatedAt)
  const penniesClass = contrastPennies ? 'purchasePageAmountPennies' : 'purchasePageAmount'

  let balanceSection
  if (showEditBalance) {
    balanceSection = (
      <BalanceForm
        key='bal-form'
        purchase={purchase}
        isProcessing={isProcessing}
        onChangeBalance={onChangeBalance}
        onSave={onSaveNewBalance}
        onCancel={onCancelEditBalance}
      />
    )
  } else {
    balanceSection = (
      <div className='smallText lightText withStandardBottomMargin'>
        Balance as of {formattedBalanceUpdateDate}
      </div>
    )
  }
  const penniesPart = formattedBalanceParts[1]
    ? <span className={penniesClass}>.{formattedBalanceParts[1]}</span>
    : null
  const amountWrapperClass = formattedBalanceParts[1]
    ? '' // 'purchasePageAmountWrapper'
    : ''
  sections.push(
    <div key='balance' className='section withCenteredColumnContent'>
      <div className={amountWrapperClass} onClick={onEditBalance}>
        <span className='purchasePageCurrency'>{currency}</span>
        <span className='purchasePageAmount'>{formattedBalanceParts[0]}</span>
        {penniesPart}
      </div>
      {balanceSection}
      {lookUpBalanceButton}
      {editBalanceButton}
    </div>,
  )
  // }
  // END

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Gift Card Code:
  if (purchase.code) {
    const cardCodeSections: JSX.Element[] = []

    if (purchase.hasBarcode) {
      const scale = purchase.barcodeFormat === BarcodeType.QR_CODE ? 3 : 1
      const height = purchase.barcodeFormat === BarcodeType.QR_CODE ? undefined : 15
      cardCodeSections.push(
        <div key='bcs' onClick={onShowZoomedBarcode} className='code-wrapper'>
          <Barcode
            value={purchase.code}
            format={purchase.barcodeFormat}
            displayZoomButton
            scale={scale}
            height={height}
          />
        </div>,
      )
    }

    const codeClass = purchase.hasBarcode ? 'purchasePageCode purchasePageCodeHasBarcode' : 'purchasePageCode'
    cardCodeSections.push(
      <div key='code' className={codeClass}>
        {purchase.code.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '')}
      </div>,
    )

    if (purchase.hasBarcode) {
      cardCodeSections.push(
        <div key='code-buttons' className='row'>
          <IonButton onClick={onShowZoomedBarcode} className='smallButton withStandardRightMargin'>
            Zoom
          </IonButton>
          <IonButton onClick={onCopyCodeToClipboard} className='smallButton'>
            Copy
          </IonButton>
        </div>,
      )
    } else {
      cardCodeSections.push(
        <div key='br' className='row'>
          <IonButton onClick={onCopyCodeToClipboard} className='smallButton'>
            Copy Code
          </IonButton>
        </div>,
      )
    }

    sections.push(
      <div key='code' className='purchasePageCardCodeSection'>
        {cardCodeSections}
      </div>,
    )
  }

  if (purchase.pin) {
    sections.push(
      <div key='pin' className='purchasePagePinSection'>
        <div className='purchasePagePin'>
          {purchase.pin || ''}
        </div>
        <div className='smallText lightText withStandardBottomMargin'>Card PIN</div>
        <IonButton onClick={onCopyPinToClipboard} className='smallButton'>
          Copy PIN
        </IonButton>
      </div>,
    )
  }

  return (
    <div className='g-with-safe-padding'>
      <a id='overview-top' />
      {sections}
    </div>
  )
}

export default PurchaseUsageTab
