import React, { useState } from 'react'
import { IonList, IonItem } from '@ionic/react'

import './styles.css'
import type { Tag } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import TagListRow from './TagListRow'

const filterList = (list: Tag[], searchText: string | null | undefined): Tag[] => {
  console.log('TagList.filterList called.', { searchText })
  if (!searchText) {
    return list
  }
  return list.filter((tag: Tag): boolean => {
    if (!searchText) {
      return true
    }
    try {
      const reg = new RegExp(searchText, 'i')
      if (tag.name && tag.name.match(reg)) {
        return true
      }
    } catch (error) {
      return false
    }
    return false
  })
}

interface Props {
  tags: Tag[] | undefined,
  searchText: string | null | undefined,
  onDeleteTag: (tagId: string) => void,
  onEditTag: (tagId: string) => void,
}

const TagList: React.FC<Props> = (props): JSX.Element | null => {
  const {
    tags,
    searchText,
    onDeleteTag,
    onEditTag,
  } = props
  // ===================================================================================================================
  // State:
  // const [toastErrorMessage, setToastErrorMessage] = useState<string | undefined>()
  // const [showToast, setShowToast] = useState(false)
  const [expandedTagId, setExpandedTagId] = useState<string | undefined>()
  // const [showModal, setShowModal] = useState(false)
  // const [currentEditTag, setCurrentEditTag] = useState({} as Tag)
  // const [tagFormName, setTagFormName] = useState<string | undefined>()

  // const [addTagToDb] = useMutation(apollo.admin.mutations.addTagToDb)
  // const [deleteTag] = useMutation(apollo.admin.mutations.deleteTag)

  // ===================================================================================================================
  // Event Handler:
  // async function onDeleteTag (tagId: string) {
  //   try {
  //     await deleteTag({
  //       variables: {
  //         tagId,
  //       },
  //     })
  //     refreshPage()
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const onSelectTag = (tagId: string) => {
  //   setShowModal(true)
  //   setCurrentEditTag(tag)
  //   setTagFormName(tag.name as string)
  // }

  const onSelectTag = (tagId: string) => {
    if (tagId === expandedTagId) {
      setExpandedTagId(undefined)
      return
    }
    setExpandedTagId(tagId)
  }

  // const onEditTag = (tagId: string) => {
  //   setShowModal(true)
  //   setCurrentEditTag(tag)
  //   setTagFormName(tag.name as string)
  // }

  // async function onFormSubmit () {
  //   try {
  //     if (tagFormName.length >= 3) {
  //       await addTagToDb({
  //         variables: {
  //           tag: {
  //             id: currentEditTag.id,
  //             name: tagFormName,
  //           } as Tag,
  //         },
  //       })
  //       refreshPage()
  //     }
  //   } catch (err) {
  //     console.error(err)
  //     setToastErrorMessage(helpers.getErrorEditTag(tagFormName || ''))
  //     setShowToast(true)
  //   }
  //   setShowModal(false)
  // }

  // ===================================================================================================================
  // Rendering:
  /*
  const tagRows = tags.map((tag) => {
    return (
      <div className='tagListRowItemContent tag-row'>
        <div className='tagListRow0'>
          <div className='tagListRowCol tagListRowCol0'>
            {tag.name}
          </div>
          <div className='tagListRowCol tagListRow0Col2'>
            <IonButton
              onClick={() => onEditTag(tag as Tag)}
              className='tag-row-button'
              fill='clear'
              size='small'
              color='warning'
            >
              Edit
            </IonButton>
          </div>
          <div className='tagListRowCol tagListRow0Col3'>
            <IonButton
              onClick={() => onDeleteTag(tag.id as string)}
              className='tag-row-button'
              fill='clear'
              size='small'
              color='danger'
            >
              Delete
            </IonButton>
          </div>
        </div>
      </div>
    )
  })

  return (
    <IonContent>
      <IonList>
        <div className='tagListRowItemContent tagListHeaderRowItemContent'>
          <div className='tagListRow0 tagListHeaderRow'>
            <div className='tagListRowCol tagListRowCol0'>
              Name
            </div>
            <div className='tagListRowCol tagListRow0Col2'>
              Edit
            </div>
            <div className='tagListRowCol tagListRow0Col3'>
              Delete
            </div>
          </div>
        </div>
        {tagRows}
      </IonList>
      <div className='edit-tag-form'>
        <IonModal
          isOpen={showModal}
          onDidDismiss={() => setShowModal(false)}
        >
          <div className='ion-modal'>
            <IonLabel>Enter new tag name:</IonLabel>
            <FormItem
              label='Tag Name'
            >
              <IonInput
                value={tagFormName}
                onIonChange={(e) => setTagFormName(e.detail.value as string)}
              />
            </FormItem>
            <div className='modal-buttons'>
              <IonButton expand='block' className='button' onClick={onFormSubmit}>Submit</IonButton>
              <IonButton expand='block' color='danger' className='button' onClick={() => setShowModal(false)}>Close Modal</IonButton>
            </div>
          </div>
        </IonModal>
      </div>
      <IonToast
        color='danger'
        duration={5000}
        isOpen={!!toastErrorMessage}
        onDidDismiss={() => setToastErrorMessage('')}
        message={toastErrorMessage}
        position='bottom'
        buttons={[{ text: 'OK', role: 'cancel' }]}
      />
    </IonContent>
  ) */

  if (!Array.isArray(tags) || tags.length < 1) {
    return null
  }
  const items = filterList(tags, searchText).map((tag: Tag): any => (
    <TagListRow
      key={tag.id as string}
      tag={tag}
      showDetails={coreHelpers.models.compareId(tag.id, expandedTagId)}
      onDeleteTag={onDeleteTag}
      onEditTag={onEditTag}
      onSelectTag={onSelectTag}
    />
  ))
  return (
    <IonList>
      <IonItem lines='none' className='tagListRow tagListHeaderRow'>
        <div className='tagListRowItemContent tagListHeaderRowItemContent'>
          <div className='tagListItemRow0'>
            <div className='tagListItemCol0'>
              Name
            </div>
            <div className='tagListItemCol1'>
              Caption
            </div>
            <div className='tagListItemCol2'>
              Type
            </div>
          </div>
        </div>
      </IonItem>
      {items}
    </IonList>
  )
}

export default TagList
