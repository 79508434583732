import { ErrorCode } from '../../lib/core/enums'
import { AppRoute } from '../../enums'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

const checkForUnauthorized = (
  errors: any | any[],
  navigate: (route: AppRoute | string, replace?: boolean) => void,
): boolean => {
  const checkError = (
    error: any,
    navigate: (route: AppRoute | string, replace?: boolean) => void,
  ): boolean => {
    if (!error) {
      return false
    }
    console.log('Page error received.', { error })
    if (error === ErrorCode.UNAUTHORIZED || error.message === ErrorCode.UNAUTHORIZED) {
      console.log('unauthorized, forwarding to sign-in.')
      globalCacheData.clearSessionData()
      navigate(AppRoute.SIGN_IN, true)
      return true
    }
    return false
  }

  if (Array.isArray(errors)) {
    for (let i = 0; i < errors.length; i += 1) {
      if (checkError(errors[i], navigate)) {
        return true
      }
    }
    return false
  }
  return checkError(errors, navigate)
}

export default checkForUnauthorized
