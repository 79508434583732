import type { PurchaseTransfer, PurchaseTransferListItem } from '../../../definitions'
import { PurchaseTransferStatus, RecordStatus } from '../../../enums'

const updatePurchaseTransferListItemFromPurchaseTransfer = (
  purchaseTransferListItem: PurchaseTransferListItem | undefined,
  purchaseTransfer: PurchaseTransfer,
): PurchaseTransferListItem => {
  if (!purchaseTransferListItem) {
    return {
      id: purchaseTransfer.id as string,
      fromUserId: null,
      fromUserUsername: null,
      fromUserEmail: null,
      fromUserPhoneNumber: null,
      fromPurchaseId: purchaseTransfer.fromPurchaseId || null,
      toPurchaseId: purchaseTransfer.toPurchaseId || null,
      toUserId: purchaseTransfer.toUserId || null,
      toUserUsername: purchaseTransfer.toUserUsername || null,
      toUserFullName: purchaseTransfer.toUserFullName || null,
      toUserEmail: purchaseTransfer.toUserEmail || null,
      toUserPhoneNumber: purchaseTransfer.toUserPhoneNumber || null,
      notificationMethod: purchaseTransfer.notificationMethod || null,
      messageScheduledAt: purchaseTransfer.messageScheduledAt || null,
      messageSentAt: purchaseTransfer.messageSentAt || null,
      transferStatus: purchaseTransfer.transferStatus || PurchaseTransferStatus.CREATING,
      adminNotes: purchaseTransfer.adminNotes || null,
      metadata: purchaseTransfer.metadata,
      recordStatus: purchaseTransfer.recordStatus || RecordStatus.ACTIVE,
      createdAt: purchaseTransfer.createdAt || '',
      updatedAt: purchaseTransfer.updatedAt || '',
      updatedBy: purchaseTransfer.updatedBy || '',
    }
  }

  const updatedItem: PurchaseTransferListItem = { ...purchaseTransferListItem }

  if (purchaseTransfer.fromPurchaseId && purchaseTransfer.fromPurchaseId !== purchaseTransferListItem.fromPurchaseId) {
    updatedItem.fromPurchaseId = purchaseTransfer.fromPurchaseId
  }
  if (purchaseTransfer.toPurchaseId && purchaseTransfer.toPurchaseId !== purchaseTransferListItem.toPurchaseId) {
    updatedItem.toPurchaseId = purchaseTransfer.toPurchaseId
  }
  if (purchaseTransfer.toUserId && purchaseTransfer.toUserId !== purchaseTransferListItem.toUserId) {
    updatedItem.toUserId = purchaseTransfer.toUserId
  }
  if (purchaseTransfer.toUserUsername && purchaseTransfer.toUserUsername !== purchaseTransferListItem.toUserUsername) {
    updatedItem.toUserUsername = purchaseTransfer.toUserUsername
  }
  if (purchaseTransfer.toUserFullName && purchaseTransfer.toUserFullName !== purchaseTransferListItem.toUserFullName) {
    updatedItem.toUserFullName = purchaseTransfer.toUserFullName
  }
  if (purchaseTransfer.toUserEmail && purchaseTransfer.toUserEmail !== purchaseTransferListItem.toUserEmail) {
    updatedItem.toUserEmail = purchaseTransfer.toUserEmail
  }
  if (purchaseTransfer.toUserPhoneNumber && purchaseTransfer.toUserPhoneNumber !== purchaseTransferListItem.toUserPhoneNumber) {
    updatedItem.toUserPhoneNumber = purchaseTransfer.toUserPhoneNumber
  }
  if (purchaseTransfer.notificationMethod && purchaseTransfer.notificationMethod !== purchaseTransferListItem.notificationMethod) {
    updatedItem.notificationMethod = purchaseTransfer.notificationMethod
  }
  if (purchaseTransfer.messageScheduledAt && purchaseTransfer.messageScheduledAt !== purchaseTransferListItem.messageScheduledAt) {
    updatedItem.messageScheduledAt = purchaseTransfer.messageScheduledAt
  }
  if (purchaseTransfer.messageSentAt && purchaseTransfer.messageSentAt !== purchaseTransferListItem.messageSentAt) {
    updatedItem.messageSentAt = purchaseTransfer.messageSentAt
  }
  if (purchaseTransfer.transferStatus && purchaseTransfer.transferStatus !== purchaseTransferListItem.transferStatus) {
    updatedItem.transferStatus = purchaseTransfer.transferStatus
  }
  if (purchaseTransfer.adminNotes && purchaseTransfer.adminNotes !== purchaseTransferListItem.adminNotes) {
    updatedItem.adminNotes = purchaseTransfer.adminNotes
  }
  if (purchaseTransfer.metadata) {
    updatedItem.metadata = purchaseTransfer.metadata
  }
  if (purchaseTransfer.recordStatus && purchaseTransfer.recordStatus !== purchaseTransferListItem.recordStatus) {
    updatedItem.recordStatus = purchaseTransfer.recordStatus
  }
  if (purchaseTransfer.createdAt && purchaseTransfer.createdAt !== purchaseTransferListItem.createdAt) {
    updatedItem.createdAt = purchaseTransfer.createdAt
  }
  if (purchaseTransfer.updatedAt && purchaseTransfer.updatedAt !== purchaseTransferListItem.updatedAt) {
    updatedItem.updatedAt = purchaseTransfer.updatedAt
  }

  return updatedItem
}

export default updatePurchaseTransferListItemFromPurchaseTransfer
