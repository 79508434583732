import type { Currency } from '../../../definitions'
import { ErrorCode, FundType, TokenName } from '../../../enums'
import cryptoCurrencyHelpers from '../../cryptoCurrency'
import getDisplayMoneyAmount from '../../ui/getDisplayMoneyAmount'
import logger from '../../../logger'

const getDisplayFiatAmount = (
  amount: number,
  fundType: FundType,
  currency: Currency,
  exchangeRate: number,
): number => {
  const displayAmount = getDisplayMoneyAmount(amount, fundType, currency)

  if (fundType === FundType.FIAT) {
    return displayAmount
  }

  if (fundType === FundType.TOKEN) {
    if (currency === TokenName.MIMBLE_TOKEN) {
      return cryptoCurrencyHelpers.convertMimbleTokenToFiat(displayAmount)
    } else {
      logger.error('lib.helpers.transaction.getDisplayFiatAmount: unsupported token.',
        { amount, fundType, currency, exchangeRate })
      throw new Error(ErrorCode.NOT_SUPPORTED)
    }
  }

  if (fundType === FundType.CRYPTO) {
    return displayAmount * exchangeRate
  }

  logger.error('lib.helpers.transaction.getDisplayFiatAmount: unsupported fundType.',
    { amount, fundType, currency, exchangeRate })
  throw new Error(ErrorCode.NOT_SUPPORTED)
}

export default getDisplayFiatAmount
