import { IonGrid, IonRow, IonCol } from '@ionic/react'
import React, { useState } from 'react'

import './styles.css'
import type { Currency, PurchaseHistoryEvent } from '../../../lib/core/definitions'
import type { FundType } from '../../../lib/core/enums'
import coreHelpers from '../../../lib/core/helpers'
import HistoryEventListRow from './HistoryEventListRow'

type Props = {
  fundType: FundType | null | undefined;
  currency: Currency | null | undefined;
  events?: PurchaseHistoryEvent[];
};

const HistoryEventList: React.FC<Props> = (props): JSX.Element | null => {
  const { events, fundType, currency } = props
  // console.log('HistoryEventList.render called.', props);

  // ===================================================================================================================
  // State:
  const [expandedEventId, setExpandedEventId] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Event Handlers:
  const onOpenEvent = (eventId: string): void => {
    if (!eventId) {
      return
    }
    if (coreHelpers.models.compareId(eventId, expandedEventId)) {
      setExpandedEventId(undefined)
    } else {
      setExpandedEventId(eventId)
    }
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(events) || events.length < 1) {
    return null
  }
  const rows = events.map((ev: PurchaseHistoryEvent, index): any => (
    <HistoryEventListRow
      key={ev.id as string}
      event={ev}
      index={index}
      fundType={fundType}
      currency={currency}
      showDetails={coreHelpers.models.compareId(ev.id, expandedEventId)}
      onOpenEvent={onOpenEvent}
    />
  ))
  return (
    <IonGrid className='purchase-history'>
      <IonRow className='row header-row'>
        <IonCol className='col' size='2'>
          Date
        </IonCol>
        <IonCol className='col' size='7'>
          Notes
        </IonCol>
        <IonCol className='col' size='3'>
          Balance
        </IonCol>
      </IonRow>
      {rows}
    </IonGrid>
  )
}

export default HistoryEventList
