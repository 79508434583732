import React, { useContext, useState } from 'react'
import { add, createOutline, openOutline } from 'ionicons/icons'
import {
  IonContent,
  IonIcon,
  IonImg,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import { UiMessage } from '../../../lib/core/enums'
import { CdnAssetType } from '../../../services/cdn/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type {
  ProductQueryData,
  ProductQueryVariables,
} from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import cdn from '../../../services/cdn'
import coreHelpers from '../../../lib/core/helpers'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import ProductOptionList from '../../../admin/components/ProductOptionList/ProductOptionList'
import StoredProductList from '../../../admin/components/StoredProductList/StoredProductList'
import TagsSection from './TagsSection'

const appPageId = AppPage.AdminProductPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  productId: string
}

const ProductPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { productId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading product:
  const {
    data: productLoadedData,
    loading: isLoadingProduct,
    refetch: reloadProduct,
  } = useQuery<ProductQueryData, ProductQueryVariables>(
    apollo.queries.product({
      includeMerchant: true,
      includeProductOptions: true,
      includeStoredProducts: true,
      includeTags: true,
    }), {
      variables: { productId: productId as string },
      skip: !productId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: ProductQueryData) => {
        const returnedProduct = data ? data.product : undefined
        if (returnedProduct === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const product = productLoadedData ? productLoadedData.product : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingProduct
  const merchantId = product && product.merchant ? product.merchant.id : ''
  const genericProductId = product ? product.genericProductId : ''
  const merchantName = product && product.merchant ? product.merchant.name : ''
  const merchantLargeLogoImageSource = product && product.merchant ? product.merchant.largeLogoImageSource : ''
  const merchantListed = product && product.merchant ? product.merchant.listed : false
  const merchantUrl = product && product.merchant ? product.merchant.url : ''

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  // })

  // ===================================================================================================================
  // Event Handlers:
  const onEditProduct = (): void => {
    if (!productId) {
      return
    }
    navigate(`/admin/edit-product/${productId}`)
  }

  const onEditMerchant = (): void => {
    if (!merchantId) {
      return
    }
    navigate(`/admin/edit-merchant/${merchantId}`)
  }

  const onSelectStoredProduct = (): void => {
    if (!merchantId) {
      return
    }
    navigate(`/admin/edit-merchant/${merchantId}`)
  }

  const onEditStoredProduct = (productId: string, productOptionId: string, storedProductId: string): void => {
    navigate(`/admin/edit-stored-product/${productId}/${productOptionId}/${storedProductId}`)
  }

  const onAddStoredProduct = (productOptionId: string): void => {
    if (!productId || !productOptionId) {
      console.error('ProductPage.onAddStoredProduct: missing args.')
      return
    }
    navigate(`/admin/add-stored-product/${productId}/${productOptionId}`)
  }

  const onOpenProductOption = (productOptionId: string): void => {
    if (!productId) {
      return
    }
    navigate(`/admin/edit-product-option/${productId}/${productOptionId}`)
  }

  const onEditProductOption = (productOptionId: string): void => {
    if (!productId) {
      return
    }
    navigate(`/admin/edit-product-option/${productId}/${productOptionId}`)
  }

  const onOpenGenericProduct = (): void => {
    if (!product || !product.genericProductId) {
      return
    }
    navigate(`/admin/product/${product.genericProductId}`)
  }

  const onAddProductOption = (): void => {
    if (!productId) {
      return
    }
    navigate(`/admin/add-product-option/${productId}`)
  }

  const onCreateProductOption = (): void => {
    if (!merchantId) {
      return
    }
    navigate(`/admin/edit-merchant/${merchantId}`)
  }

  const onCancel = (): void => {
    navigate(-1)
  }

  const onImportStoredProducts = (): void => {
    if (!productId) {
      return
    }
    navigate(`/admin/import-stored-products/${productId}`)
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !productId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadProduct({ productId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let merchantLogo
  if (merchantLargeLogoImageSource) {
    const merchantLogoImageUri = cdn.getUri(CdnAssetType.MERCHANT_LOGO, merchantLargeLogoImageSource) || ''
    merchantLogo = (
      <div key='logo' className='merchantPageLogoWrapper'>
        <IonImg src={merchantLogoImageUri} className='with50PercentWidth' />
      </div>
    )
  }

  let productOptionsSection
  if (product) {
    let caption
    let body
    if (product.genericProductId) {
      caption = (
        <div className='row withStandardBottomMargin'>
          <div className='sectionCaption'>Product Options</div>
        </div>
      )
      body = (
        <div className='lightText withStandardBottomMargin'>
          Note: Product options are controlled by the generic product.
        </div>
      )
    } else {
      caption = (
        <div className='row withStandardBottomMargin withPointerCursor' onClick={onAddProductOption}>
          <div className='sectionCaption'>Product Options</div>
          <IonIcon className='inlineEditIcon' icon={add} />
        </div>
      )
      body = (
        <ProductOptionList
          productOptions={product && product.productOptions}
          onOpenProductOption={onOpenProductOption}
          onEditProductOption={onEditProductOption}
          onAddStoredProduct={onAddStoredProduct}
        />
      )
    }
    productOptionsSection = (
      <div key='po' className='section'>
        {caption}
        {body}
      </div>
    )
  }

  let inventorySection
  if (product) {
    let body
    let caption
    if (product.genericProductId) {
      caption = (
        <div className='row withStandardBottomMargin'>
          <div className='sectionCaption'>Inventory</div>
        </div>
      )
      body = (
        <div className='lightText withStandardBottomMargin'>
          Note: Inventory is listed for the generic product.
        </div>
      )
    } else {
      caption = (
        <div className='row withStandardBottomMargin withPointerCursor' onClick={onImportStoredProducts}>
          <div className='sectionCaption'>Inventory</div>
          <IonIcon className='inlineEditIcon' icon={add} />
        </div>
      )
      body = (
        <StoredProductList
          storedProducts={product && product.storedProducts}
          onEditStoredProduct={onEditStoredProduct}
        />
      )
    }
    inventorySection = (
      <div key='inv' className='section'>
        {caption}
        {body}
      </div>
    )
  }

  let openGenericProductButton
  if (product && product.genericProductId) {
    openGenericProductButton = (
      <IonIcon className='inlineEditIcon' icon={openOutline} />
    )
  }

  return (
    <IonPage className='app-page-admin  product-page'>
      <NavBar
        title='Manage Product'
        goBackUri={AppRoute.ADMIN_PRODUCTS}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <div className='rowWithSpaceBetween'>
          <div className='section'>
            <div className='row withStandardBottomMargin withPointerCursor' onClick={onEditMerchant}>
              <div className='sectionCaption'>Merchant</div>
              <IonIcon className='inlineEditIcon' icon={createOutline} />
            </div>
            <div className='attributeList'>
              <div className='attributeListRow'>
                <span className='attributeListLabel'>ID:</span>
                <span className='attributeListValue'>{coreHelpers.ui.formatModelId(merchantId || '')}</span>
              </div>
              <div className='attributeListRow'>
                <span className='attributeListLabel'>Name:</span>
                <span className='attributeListValue'>{merchantName}</span>
              </div>
              <div className='attributeListRow'>
                <span className='attributeListLabel'>Listed:</span>
                <span className='attributeListValue'>{merchantListed ? 'yes' : 'no'}</span>
              </div>
              <div className='attributeListRow'>
                <span className='attributeListLabel'>Website:</span>
                <span className='attributeListValue'>{merchantUrl}</span>
              </div>
            </div>
          </div>
          <div>
            {merchantLogo}
          </div>
        </div>
        <div className='section'>
          <div className='row withStandardBottomMargin withPointerCursor' onClick={onEditProduct}>
            <div className='sectionCaption'>Product</div>
            <IonIcon className='inlineEditIcon' icon={createOutline} />
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>ID:</span>
            <span className='attributeListValue'>{coreHelpers.ui.formatModelId(productId || '')}</span>
          </div>
          <div className='attributeListRow withPointerCursor' onClick={onOpenGenericProduct}>
            <span className='attributeListLabel'>Generic Product ID:</span>
            <span className='attributeListValue'>{coreHelpers.ui.formatModelId(genericProductId || '')}</span>
            {openGenericProductButton}
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>Listed?</span>
            <span className='attributeListValue'>{product && product.listed ? 'yes' : 'no'}</span>
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>Search Terms:</span>
            <span className='attributeListValue'>{product && product.searchTerms}</span>
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>Is Merchant&apos;s Main Product:</span>
            <span className='attributeListValue'>{product && product.isMainProduct ? 'yes' : 'no'}</span>
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>Has PIN:</span>
            <span className='attributeListValue'>{product && product.hasPin ? 'yes' : 'no'}</span>
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>Has barcode:</span>
            <span className='attributeListValue'>{product && product.hasBarcode ? 'yes' : 'no'}</span>
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>Barcode type:</span>
            <span className='attributeListValue'>{product && product.barcodeFormat}</span>
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>Terms:</span>
            <span className='attributeListValue'>{product && product.termsEn ? product.termsEn : '-'}</span>
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>Terms URL:</span>
            <span className='attributeListValue'>{product && product.termsUrl ? product.termsUrl : '-'}</span>
          </div>
          <div className='attributeListRow'>
            <span className='attributeListLabel'>Gift Card Image:</span>
            <span className='attributeListValue'>{product && product.imageSourceFrontSide ? cdn.getUri(CdnAssetType.GIFT_CARD, product.imageSourceFrontSide) || '-' : '-'}</span>
          </div>
        </div>
        {productOptionsSection}
        {inventorySection}
        <TagsSection product={product} />

      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default ProductPage
