import React, { useState } from 'react'
import { IonSelect, IonSelectOption } from '@ionic/react'

import type { ReportFilter } from '../../../lib/core/definitions'
import { ReportDataFormat, ReportType } from '../../../lib/core/enums'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import coreHelpers from '../../../lib/core/helpers'
import FormItem from '../../../components/FormItem/FormItem'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'

const showFormatInput = false

type Props = {
  isProcessing: boolean;
  onSubmit: (filter: ReportFilter) => void;
};

const ReportForm: React.FC<Props> = (props): JSX.Element => {
  const { isProcessing, onSubmit } = props

  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [reportType, setReportType] = useState<ReportType | undefined>()
  const [format, setFormat] = useState<ReportDataFormat | undefined>(ReportDataFormat.JSON)

  // ===================================================================================================================
  // Effect Handlers:

  // ===================================================================================================================
  // Helpers:
  const isDirty = !!reportType
  const isValid = !!reportType

  const resetForm = (): void => {
    setReportType(undefined)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeReportType = (event: any): void => {
    setReportType(event.detail.value)
  }

  const onChangeFormat = (event: any): void => {
    setFormat(event.detail.value)
  }

  const handleSubmit = (): void => {
    if (!reportType) {
      return
    }
    const filter: ReportFilter = {
      reportType,
      userId: activeUserId as string,
      format,
    }
    onSubmit(filter)
  }

  // ===================================================================================================================
  // Rendering:
  // let spinnerSection
  // if (isProcessing) {
  //   spinnerSection = (
  //     <div key='spinner' className='withCenteredColumnContent withStandardBottomMargin'>
  //       <IonSpinner />
  //     </div>
  //   )
  // }

  const typeOptions = Object.values(ReportType)
    // .filter(type => type === ReportType.TRANSACTIONS)
    .map((rt): JSX.Element => (
      <IonSelectOption
        key={rt}
        value={rt}
      >
        {coreHelpers.type.reportType.getLabel(rt)}
      </IonSelectOption>
    ))

  const formatOptions = Object.values(ReportDataFormat)
    .map((f): JSX.Element => (
      <IonSelectOption
        key={f}
        value={f}
      >
        {coreHelpers.type.reportDataFormat.getLabel(f)}
      </IonSelectOption>
    ))

  let formatInput: JSX.Element | undefined
  if (showFormatInput) {
    formatInput = (
      <FormItem
        label='Format'
        withBottomMargin
      >
        <IonSelect
          value={format}
          interface='popover'
          // okText='OK'
          // cancelText='Cancel'
          onIonChange={onChangeFormat}
        >
          {formatOptions}
        </IonSelect>
      </FormItem>
    )
  }

  return (
    <div className='report-form'>
      <form onSubmit={handleSubmit}>
        <FormItem
          label='Report'
          withBottomMargin
        >
          <IonSelect
            value={reportType}
            interface='popover'
            // okText='OK'
            // cancelText='Cancel'
            onIonChange={onChangeReportType}
          >
            {typeOptions}
          </IonSelect>
        </FormItem>
        {formatInput}
        <div className='withCenteredColumnContent'>
          <SubmitButton
            onClick={handleSubmit}
            isProcessing={isProcessing}
            disabled={!isDirty || !isValid || isProcessing}
          >
            Load
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

export default ReportForm
