import { gql } from '@apollo/client'

export default gql`
  query Q($productOptionId: String!) {
    productOption (productOptionId: $productOptionId) {
      id
      productId
      labelEn
      orderIndex
      amount
      fundType
      currency
      discount
      reward
      inventory
      listed
      source
#      metadata
      adminNotes
      recordStatus
      createdAt
      updatedAt
    }
  }
`
