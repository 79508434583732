import type { ChatsQueryVariables } from '../definitions'
import type { ChatListFilter } from '../../../lib/core/definitions'
import getChatListFilter from './getChatListFilter'

const getChatsQueryVariables = (
  listFilter: ChatListFilter | undefined,
  activeUserId: string,
): ChatsQueryVariables => ({
  filter: listFilter || getChatListFilter(activeUserId),
})

export default getChatsQueryVariables
