import type { EnvironmentVal } from '../../lib/core/definitions'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import serviceData from './serviceData'

const resetLocalCache = (environment?: EnvironmentVal): void => {
  const client = serviceData.getClient(environment || globalCacheData.getEnvironment())
  if (client) {
    client.cache.reset()
  }
}

export default resetLocalCache
