import addProductToShoppingCart from './addProductToShoppingCart'
import appPageDefs from './appPageDefs'
import checkForUnauthorized from './checkForUnauthorized'
import comparePurchasesByScopeCounts from './comparePurchasesByScopeCounts'
import copyInputTextToClipboard from './copyInputTextToClipboard'
import filterPurchaseList from './filterPurchaseList'
import getChatListInfo from './getChatListInfo'
import getFormInputValue from './getFormInputValue'
import getPurchaseCdnUrisFromChatAttachment from './getPurchaseCdnUrisFromChatAttachment'
import getPurchaseCdnUrisFromMerchant from './getPurchaseCdnUrisFromMerchant'
import getPurchaseCdnUrisFromProduct from './getPurchaseCdnUrisFromProduct'
import getPurchaseCdnUrisFromProductListItem from './getPurchaseCdnUrisFromProductListItem'
import getPurchaseCdnUrisFromPurchase from './getPurchaseCdnUrisFromPurchase'
import getPurchaseCdnUrisFromPurchaseListItem from './getPurchaseCdnUrisFromPurchaseListItem'
import getPurchaseCountByScope from './getPurchaseCountByScope'
import getUserMimbleWalletBalance from './getUserMimbleWalletBalance'
import getWindowHeight from './getWindowHeight'
import getWindowWidth from './getWindowWidth'
import handleNewOrUpdatedChatMessage from './handleNewOrUpdatedChatMessage'
import handleSignIn from './handleSignIn'
import isPageActive from './isPageActive'
import isPurchaseInScope from './isPurchaseInScope'
import navigate from './navigate'
import openContact from './openContact'
import setAutoFillStatus from './setAutoFillStatus'
import timezones from './timezones'
import updateUserPref from './updateUserPref'
import userHasSufficientMimbleCredit from './userHasSufficientMimbleCredit'

export const DEFAULT_PURCHASES_BY_SCOPE_COUNTS = {
  activeCnt: 0,
  pendingCnt: 0,
  receivedCnt: 0,
  sentCnt: 0,
  transferredCnt: 0,
  archivedCnt: 0,
}

export interface AutoFillInputConfig {
  name: string;
  setter: (flag: boolean) => void;
}

const pageHelpers = {
  addProductToShoppingCart,
  appPageDefs,
  checkForUnauthorized,
  comparePurchasesByScopeCounts,
  copyInputTextToClipboard,
  filterPurchaseList,
  getChatListInfo,
  getFormInputValue,
  getPurchaseCdnUrisFromChatAttachment,
  getPurchaseCdnUrisFromMerchant,
  getPurchaseCdnUrisFromProduct,
  getPurchaseCdnUrisFromProductListItem,
  getPurchaseCdnUrisFromPurchase,
  getPurchaseCdnUrisFromPurchaseListItem,
  getPurchaseCountByScope,
  getUserMimbleWalletBalance,
  getWindowHeight,
  getWindowWidth,
  handleNewOrUpdatedChatMessage,
  handleSignIn,
  isPageActive,
  isPurchaseInScope,
  navigate,
  openContact,
  setAutoFillStatus,
  timezones,
  updateUserPref,
  userHasSufficientMimbleCredit,
}

export default pageHelpers
