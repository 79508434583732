import React, { useEffect, useState } from 'react'

import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import type { AnimationName, ChatImageType } from '../../lib/core/enums'
import coreHelpers from '../../lib/core/helpers'
import helpers from './helpers'
import NewChatMessage from '../../components/NewChatMessage/NewChatMessage'
import StepButtons from './StepButtons'
import validationHelpers from '../../helpers/validationHelpers'

type Props = {
  onPrevStep: () => void;
  onNextStep: () => void;
}

const MessageStep: React.FC<Props> = (props): JSX.Element | null => {
  const { onPrevStep, onNextStep } = props

  // ===================================================================================================================
  // State:
  const { activeUser, contacts } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const {
    flowId,
    toUserId,
    giftChanges,
    setGiftChanges,
    fromPurchase,
    isProcessing,
    getCurToUserInfo,
    getCurMessageText,
    getCurChatImageUrl,
    getCurChatImageType,
    getCurChatAnimation,
  } = useGiftFlow()

  const [messageText, setMessageText] = useState<string | null | undefined>()
  const [messageTextValidationError, setMessageTextValidationError] = useState<string | undefined>()
  const [chatImageUrl, setChatImageUrl] = useState<string | null | undefined>()
  const [chatImageType, setChatImageType] = useState<ChatImageType | null | undefined>()
  const [animation, setAnimation] = useState<AnimationName | null | undefined>()

  const prevMessageText = getCurMessageText()
  const prevChatImageUrl = getCurChatImageUrl()
  const prevChatImageType = getCurChatImageType()
  const prevChatAnimation = getCurChatAnimation()

  const curMessageText = (messageText || messageText === null) ? messageText : prevMessageText
  const curImageUrl = (chatImageUrl || chatImageUrl === null ? chatImageUrl : prevChatImageUrl)
  const curImageType = (chatImageType || chatImageType === null ? chatImageType : prevChatImageType)
  const curAnimation = (animation || animation === null ? animation : prevChatAnimation)

  const toUserInfo = getCurToUserInfo()
  const recipientName = helpers.getRecipientNameForMessageText(toUserId, toUserInfo, contacts)
  const senderName = helpers.getSenderNameForMessageText(activeUser)
  const merchant = fromPurchase && fromPurchase.product
    ? fromPurchase.product.merchant
    : undefined
  const merchantPart = merchant ? ` for ${merchant.name}` : undefined
  const balancePart = fromPurchase
    ? `${coreHelpers.ui.formatAmount(fromPurchase.balance, fromPurchase.fundType, fromPurchase.currency, true)} `
    : undefined

  const defaultMessageText = `Hi${recipientName ? ` ${recipientName}` : ''},

Here is a ${balancePart}gift card${merchantPart}!

Best,

${senderName || ''}`

  // ===================================================================================================================
  // Helpers:
  const resetState = (): void => {
    setMessageText(undefined)
    setChatImageUrl(undefined)
    setChatImageType(undefined)
    setAnimation(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    resetState()
  }, [flowId])

  // ===================================================================================================================
  // Helpers:
  const save = (goToNextStep: boolean) => {
    if (
      (
        ((!messageText && prevMessageText) || (messageText && messageText === prevMessageText)) &&
        (chatImageUrl === undefined || (chatImageUrl === prevChatImageUrl)) &&
        (chatImageType === undefined || (chatImageType === prevChatImageType)) &&
        (animation === undefined || (animation === prevChatAnimation))
      )
    ) {
      console.log('SendGiftPage.MessageStep.save: no changes or no change handler - not saving.')
      if (goToNextStep) {
        onNextStep()
      }
      return
    }
    setGiftChanges({
      ...giftChanges,
      messageText: messageText || defaultMessageText,
      chatImageUrl,
      chatImageType,
      animation,
    }, goToNextStep)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeMessageText = (newMessageText: string | null | undefined): void => {
    if (newMessageText) {
      setMessageText(newMessageText)
      setMessageTextValidationError(validationHelpers.validatePurchaseTransferMessageText(newMessageText))
    } else {
      setMessageText(newMessageText)
      setMessageTextValidationError(undefined)
    }
  }

  const onChangeImage = (newImageUrl: string | null | undefined, newImageType: ChatImageType | null | undefined): void => {
    if (newImageUrl && newImageType) {
      setChatImageUrl(newImageUrl)
      setChatImageType(newImageType)
    } else {
      setChatImageUrl(newImageUrl)
      setChatImageType(newImageType)
    }
  }

  const onChangeAnimation = (newAnimation: AnimationName | null | undefined): void => {
    if (newAnimation) {
      setAnimation(newAnimation)
    } else {
      setAnimation(newAnimation)
    }
  }

  const onSaveAndNext = () => {
    save(true)
  }

  const onSaveAndPrev = () => {
    save(false)
    onPrevStep()
  }

  // ===================================================================================================================
  // Rendering:

  return (
    <div className='message-step'>
      <div className='withDoubleBottomMargin'>
        <NewChatMessage
          activeUserId={activeUserId}
          messageText={curMessageText}
          imageUrl={curImageUrl}
          imageType={curImageType}
          animation={curAnimation}
          defaultMessageText={defaultMessageText}
          onChangeMessageText={onChangeMessageText}
          onChangeImage={onChangeImage}
          onChangeAnimation={onChangeAnimation}
          numberOfInputRows={8}
          charCount
        />
      </div>
      <StepButtons
        isProcessing={isProcessing}
        onPrevStep={onSaveAndPrev}
        onNextStep={onSaveAndNext}
        disableNextButton={messageText === null}
        nextLabel='Next'
      />
    </div>
  )
}

export default MessageStep
