import React, { useContext, useState } from 'react'
import type { DialogButtonId, DialogId } from './enums'
import type { DialogConfig } from './definitions'
import { useGlobalCache } from '../GlobalCacheContext/GlobalCacheContext'
import dialogConfigs from './dialogConfigs'

export interface DialogContextValue {
  dialogId: DialogId | undefined
  isDialogOpen: boolean
  openDialog: (
    dialogId: DialogId,
    buttonHandler?: (buttonId: DialogButtonId) => void,
    checkDontShowAgainSetting?: boolean
  ) => void
  closeDialog: () => void;
  onButtonClick: (buttonId: DialogButtonId) => void
  getDialogConfig: () => DialogConfig | undefined,
}

export const defaultValue = {
  dialogId: undefined,
  isDialogOpen: false,
  openDialog: (
    dialogId: DialogId,
    buttonHandler?: (buttonId: DialogButtonId) => void,
    checkDontShowAgainSetting?: boolean,
  ): void => {
    console.log(`DialogContext.defaultValue.open(${dialogId}, ${checkDontShowAgainSetting}) called.`)
  },
  closeDialog: (): void => { console.log('DialogContext.defaultValue.close called.') },
  onButtonClick: (buttonId: string): void => {
    console.log(`DialogContext.defaultValue.onButtonClick(${buttonId}) called`)
  },
  getDialogConfig: (): DialogConfig | undefined => undefined,
}

export const DialogContext = React.createContext<DialogContextValue>(defaultValue)

export function useDialog (): DialogContextValue {
  return useContext(DialogContext)
}

let buttonHandler: ((buttonId: DialogButtonId) => void) | undefined

const DialogProvider: React.FC = (props) => {
  const { getPreference } = useGlobalCache()
  const [dialogId, setDialogId] = useState<DialogId | undefined>()

  const openDialog = (
    newDialogId: DialogId,
    buttonClickCallback?: (buttonId: DialogButtonId) => void,
    checkDontShowAgainSetting?: boolean,
  ): void => {
    if (newDialogId && checkDontShowAgainSetting) {
      const config = dialogConfigs[newDialogId]
      if (
        config &&
        config.showAgainLocalPreference &&
        getPreference(config.showAgainLocalPreference) === 'false'
      ) {
        return
      }
    }
    buttonHandler = buttonClickCallback
    setDialogId(newDialogId)
  }

  const closeDialog = (): void => {
    buttonHandler = undefined
    setDialogId(undefined)
  }

  const getDialogConfig = (): DialogConfig | undefined => dialogId && dialogConfigs[dialogId]

  const onButtonClick = (buttonId: DialogButtonId): void => {
    console.log(`DialogContext.onButtonClick(${buttonId}) called`)
    if (buttonHandler) {
      buttonHandler(buttonId)
    }
    buttonHandler = undefined
    setDialogId(undefined)
  }

  return (
    <DialogContext.Provider
      value={{
        dialogId,
        isDialogOpen: !!dialogId,
        openDialog,
        closeDialog,
        onButtonClick,
        getDialogConfig,
      }}
    >
      {props.children}
    </DialogContext.Provider>
  )
}

export default DialogProvider
