import type { Wish } from '../../../definitions'
import { RecordStatus } from '../../../enums'

const updateWishListItemFromWish = (
  wishListItem: Wish | undefined,
  wish: Wish,
): Wish => {
  if (!wishListItem) {
    return {
      id: wish.id || '',
      userId: wish.userId,
      contactId: wish.contactId,
      merchantId: wish.merchantId,
      title: wish.title,
      notes: wish.notes,
      productUrl: wish.productUrl,
      budgetAmount: wish.budgetAmount,
      sortIndex: wish.sortIndex,
      recordStatus: wish.recordStatus || RecordStatus.ACTIVE,
      createdAt: wish.createdAt || '',
      updatedAt: wish.updatedAt || '',

      // todo: deprecated
      vendorId: wish.vendorId,
    }
  }

  const updatedItem: Wish = { ...wishListItem }

  if (wish.userId && wish.userId !== wishListItem.userId) {
    updatedItem.userId = wish.userId
  }
  if (wish.contactId && wish.contactId !== wishListItem.contactId) {
    updatedItem.contactId = wish.contactId
  }
  if (wish.merchantId && wish.merchantId !== wishListItem.merchantId) {
    updatedItem.merchantId = wish.merchantId
  }
  if (wish.title && wish.title !== wishListItem.title) {
    updatedItem.title = wish.title
  }
  if (wish.notes && wish.notes !== wishListItem.notes) {
    updatedItem.notes = wish.notes
  }
  if (wish.productUrl && wish.productUrl !== wishListItem.productUrl) {
    updatedItem.productUrl = wish.productUrl
  }
  if (wish.budgetAmount !== undefined && wish.budgetAmount !== wishListItem.budgetAmount) {
    updatedItem.budgetAmount = wish.budgetAmount
  }
  if (wish.sortIndex !== undefined && wish.sortIndex !== wishListItem.sortIndex) {
    updatedItem.sortIndex = wish.sortIndex
  }
  if (wish.recordStatus && wish.recordStatus !== wishListItem.recordStatus) {
    updatedItem.recordStatus = wish.recordStatus
  }
  if (wish.createdAt && wish.createdAt !== wishListItem.createdAt) {
    updatedItem.createdAt = wish.createdAt
  }
  if (wish.updatedAt && wish.updatedAt !== wishListItem.updatedAt) {
    updatedItem.updatedAt = wish.updatedAt
  }

  // todo: deprecated
  if (wish.vendorId && wish.vendorId !== wishListItem.vendorId) {
    updatedItem.vendorId = wish.vendorId
  }

  return updatedItem
}

export default updateWishListItemFromWish
