import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { AsyncTask } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type { VerifyAsyncTaskTokenData, VerifyAsyncTaskTokenVariables } from '../apollo/definitions'
import apollo from '../apollo'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import loadAsyncTask from './loadAsyncTask'

const verifyAsyncTaskToken = (
  taskId: string,
  token: string,
  confirmToken: string | undefined,
  timeout = 30000,
  apolloClient: ApolloClient<any>,
): Promise<AsyncTask | undefined> => (
  new Promise((resolve, reject) => {
    console.log('auth.verifyAsyncTaskToken called.', { taskId, token, confirmToken, timeout })

    if (!apolloClient) {
      console.error('auth.verifyAsyncTaskToken: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<VerifyAsyncTaskTokenData, VerifyAsyncTaskTokenVariables> = {
      mutation: apollo.mutations.verifyAsyncTaskToken,
      variables: { taskId, token, confirmToken },
    }
    if (!globalCacheData.getActiveUser()) {
      queryParams.context = { skipAuthentication: true }
    }

    apolloClient.mutate<VerifyAsyncTaskTokenData, VerifyAsyncTaskTokenVariables>(queryParams)
      .then((result) => {
        console.log('auth.verifyAsyncTaskToken: mutation succeeded.', result)
        if (
          !result ||
          !result.data ||
          !result.data.verifyAsyncTaskToken ||
          !result.data.verifyAsyncTaskToken.id
        ) {
          console.log('auth.verifyAsyncTaskToken: mutation did not return expected data.')
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        const taskId = result.data.verifyAsyncTaskToken.id
        const previousAttemptCount = result.data.verifyAsyncTaskToken.attemptCount || 0
        // console.log('auth.verifyAsyncTaskToken: previous attempt count=', previousAttemptCount)

        const isTaskInTargetStateFunc = (task: AsyncTask): boolean => (
          task &&
          !!task.attemptCount &&
          task.attemptCount > previousAttemptCount
        )

        setTimeout(() => {
          console.log('auth.verifyAsyncTaskToken: calling loadAsyncTask')
          loadAsyncTask(taskId, apolloClient, isTaskInTargetStateFunc, timeout)
            .then((task) => {
              console.log('auth.verifyAsyncTaskToken: returning task', task)
              resolve(task)
            }, (error) => {
              console.error('auth.verifyAsyncTaskToken: error', error)
              reject(error)
            })
        }, 1000)
      }, (error) => {
        console.error('auth.verifyAsyncTaskToken: error', error)
        reject(error)
      })
  })
)

export default verifyAsyncTaskToken
