import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { Invitation } from '../../../lib/core/definitions'
import type {
  InvitationQueryData,
  InvitationQueryVariables,
  InvitationsQueryData,
  InvitationsQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  invitation: Invitation,
  queryListVariables: InvitationsQueryVariables,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateInvitation.updateInList called.', { invitation })
  if (!invitation) {
    logger.error('api.cache.updateInvitation.updateInList: no invitation given.')
    return
  }

  try {
    const cachedInvitationsData = apolloClient.readQuery<InvitationsQueryData, InvitationsQueryVariables>({
      query: apollo.queries.invitations,
      variables: queryListVariables,
    })

    if (
      !cachedInvitationsData ||
      !Array.isArray(cachedInvitationsData.invitations) ||
      cachedInvitationsData.invitations.length < 1
    ) {
      return
    }

    const updatedInvitations = coreHelpers.models.updateModelInList<Invitation>(
      cachedInvitationsData.invitations,
      invitation,
      undefined,
      true,
      coreHelpers.models.invitation.updateInvitationListItemFromInvitation,
    )

    apolloClient.writeQuery<InvitationsQueryData, InvitationsQueryVariables>({
      query: apollo.queries.invitations,
      variables: queryListVariables,
      data: { invitations: updatedInvitations },
    })
  } catch (error) {
    logger.error('api.cache.updateInvitation.updateInList: error.', { error })
  }
}

const updateInvitation = (
  invitation: Invitation,
  queryListVariables: InvitationsQueryVariables | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateInvitation called.', { invitation })
  if (!apolloClient) {
    logger.error('api.cache.updateInvitation: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!invitation || !invitation.id) {
    logger.error('api.cache.updateInvitation: no invitation given.', { invitation })
    return
  }

  try {
    apolloClient.writeQuery<InvitationQueryData, InvitationQueryVariables>({
      query: apollo.queries.invitation,
      variables: { invitationId: invitation.id },
      data: { invitation },
    })

    if (queryListVariables) {
      updateInList(
        invitation,
        queryListVariables,
        activeUserId,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateInvitation: error.', { invitation, error })
  }
}

export default updateInvitation
