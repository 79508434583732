import moment from 'moment'

import type { ImportStoredProductItem, Merchant, MerchantListItem } from '../../../../definitions'
import { FiatCurrency, FundType, StoredProductStatus } from '../../../../enums'
import getInternalMoneyAmount from '../../../ui/getInternalMoneyAmount'
import isUuid from '../../../string/isUuid'
import logger from '../../../../logger'
import storedProductStatusHelpers from '../../../type/storedProductStatusHelpers'
import uiHelpers from '../../../ui'

enum LinePartIndex {
  importId,
  productId,
  productOptionId,
  amount,
  discount,
  code,
  pin,
  status,
  receivedAt,
  paidAt,
  refUrl,
}
const PARTS_COUNT = Object.keys(LinePartIndex).length / 2

const generic = (
  line: string,
  lineIndex: number,
  productId: string,
  importIdPrefix: string | undefined,
  poNumber: string | undefined,
  source: string | undefined,
  merchants: Partial<Merchant | MerchantListItem>[] | undefined,
): ImportStoredProductItem => {
  logger.info('lib.core.helpers.models.asyncTask.parseImportStoredProductsLine.generic called.',
    { line, lineIndex })

  const parts = line.split(',')
  const partsCount = Array.isArray(parts) ? parts.length : 0
  const importItem: ImportStoredProductItem = {
    ok: false,
    lineIndex,
    productId,
    status: StoredProductStatus.AVAILABLE,
    poNumber,
    source,
  }

  if (partsCount !== PARTS_COUNT) {
    return {
      ...importItem,
      error: `Parsing returned incorrect number of line components (expected: ${PARTS_COUNT}, found: ${partsCount}).`,
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #0: Import ID:
  // e75270aaf94311e98abbfbe302848afc-22
  importItem.importId = parts[LinePartIndex.importId].trim() || `${importIdPrefix}-${lineIndex}`

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #1: Product ID:
  if (parts[LinePartIndex.productId].trim()) {
    // e75270aaf94311e98abbfbe302848afc
    importItem.productId = uiHelpers.formatModelId(parts[LinePartIndex.productId].trim())
    if (!isUuid(importItem.productId)) {
      return {
        ...importItem,
        error: `Invalid product ID: '${importItem.productId}'.`,
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #2: Product Option ID:
  const productOptionId = parts[LinePartIndex.productOptionId].trim()
  if (productOptionId) {
    importItem.productOptionId = uiHelpers.formatModelId(productOptionId)
    if (!isUuid(importItem.productOptionId)) {
      return {
        ...importItem,
        error: `Invalid product option ID: '${productOptionId}'.`,
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #3: Amount:
  const amountString = parts[LinePartIndex.amount].trim()
  if (amountString) {
    const amount = parseInt(amountString, 10)
    if (
      amount === undefined ||
      amount === null ||
      Number.isNaN(amount) ||
      amount < 0
    ) {
      return {
        ...importItem,
        error: `Invalid amount: '${amount}'.`,
      }
    }
    importItem.amount = getInternalMoneyAmount(
      amount,
      FundType.FIAT,
      FiatCurrency.USD,
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #4: Discount:
  const discountString = parts[LinePartIndex.discount].trim()
  if (discountString) {
    importItem.discount = Math.ceil(parseFloat(discountString) * 1000)
    // if (
    //   importItem.discount === undefined
    //   || importItem.discount === null
    //   || Number.isNaN(importItem.discount)
    //   || importItem.discount < 0
    // ) {
    //   return {
    //     ...importItem,
    //     error: `Invalid discount: '${importItem.discount}'.`,
    //   }
    // }
  }

  if (!importItem.productOptionId && !importItem.amount) {
    return {
      ...importItem,
      error: 'Neither productOptionId nor amount given.',
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #5: Code:
  importItem.code = parts[LinePartIndex.code].trim()
  if (!importItem.code) {
    return {
      ...importItem,
      error: 'Missing or invalid code.',
    }
  } else {
    importItem.code = importItem.code.replace(/ /g, '')
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #6: PIN:
  importItem.pin = parts[LinePartIndex.pin].trim().replace(/ /g, '')

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #7: Status:
  const statusString = parts[LinePartIndex.status].trim()
  if (statusString) {
    if (!storedProductStatusHelpers.isValid(statusString)) {
      return {
        ...importItem,
        error: `Invalid status '${statusString}'.`,
      }
    }
    importItem.status = statusString as StoredProductStatus
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #8: Date we received this item:
  const receivedAtString = parts[LinePartIndex.receivedAt].trim()
  if (receivedAtString) {
    const date = moment(receivedAtString)
    if (!date.isValid()) {
      return {
        ...importItem,
        error: `Invalid receivedAt date '${receivedAtString}'.`,
      }
    }
    importItem.receivedAt = date.toISOString()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #9: Date we paid for this item:
  const paidAtString = parts[LinePartIndex.paidAt].trim()
  if (paidAtString) {
    const date = moment(paidAtString)
    if (!date.isValid()) {
      return {
        ...importItem,
        error: `Invalid paidAt date '${paidAtString}'.`,
      }
    }
    importItem.paidAt = date.toISOString()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // #10: Reference URL:
  importItem.refUrl = parts[LinePartIndex.refUrl].trim().replace(/ /g, '')

  logger.info(`lib.core.helpers.models.asyncTask.parseImportStoredProductsLine.generic: parsed line #${lineIndex}.`,
    { importItem })

  return {
    ...importItem,
    ok: true,
  }
}

export default generic
