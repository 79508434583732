import React from 'react'
import { IonButton } from '@ionic/react'

import type { Order } from '../../lib/core/definitions'

type Props = {
  order: Order
  onNavigateToGiftFlow: () => void
  onNavigateToWallet: () => void
  onOpenPurchase: () => void
  onSendGift: () => void
};

const ForMultipleProducts: React.FC<Props> = (props): JSX.Element | null => {
  const {
    onNavigateToWallet,
  } = props

  // ===================================================================================================================
  // State:

  // ===================================================================================================================
  // Event Handlers:

  // ===================================================================================================================
  // Rendering:
  // console.log('PaymentReceivedForMultipleProducts.render called.', { props });
  return (
    <div className='g-with-safe-padding'>
      <h2>Thank you for your order!</h2>
      Your payment has been received and the products have been placed into
      your digital wallet.
       <div className='withStandardTopMargin'>
        <div key='activateFormButtons' className='formButtonWrapper'>
          <IonButton
            className='withStandardRightMargin'
            onClick={onNavigateToWallet}
          >
            Open Wallet
          </IonButton>
        </div>
       </div>
    </div>
  )
}

export default ForMultipleProducts
