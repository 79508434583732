export enum AdminTaskType {
  ADD_INVITE_CODE_TO_USER = 'add-invite-code-to-user',
  CANCEL_TRANSACTION = 'cancel-transaction',
  CLEAR_TASKS = 'clear-tasks',
  CREATE_CHAT_MESSAGE = 'create-chat-message',
  CREATE_CONTACT = 'create-contact',
  CREATE_CORE_MESSAGE_TEMPLATES = 'create-core-message-templates',
  CREATE_MISSING_CONTACTS = 'create-missing-contacts',
  CREATE_MISSING_REWARDS = 'create-missing-rewards',
  CREATE_MISSING_TOKEN_DELIVERY_TRANSACTIONS = 'create-missing-token-delivery-transactions',
  CREATE_ORDER = 'create-order',
  CREATE_PURCHASE = 'create-purchase',
  CREATE_TRANSACTION = 'create-transaction',
  CREATE_TRANSFER_TARGET_PURCHASES_FOR_USER = 'create-transfer-target-purchases-for-user',
  CREATE_USER_INVITATION = 'create-user-invitation',
  DECRYPT_STRING = 'decrypt-string',
  DELETE_REMOTE_BANKING_USER = 'delete-remote-banking-user',
  DELETE_USER = 'delete-user',
  DELIVER_AVAILABLE_PRODUCTS = 'deliver-available-products',
  DO_DAILY_FRAUD_CONTROL_MAINTENANCE = 'do-daily-fraud-control-maintenance',
  DO_DATA_MAINTENANCE = 'do-data-maintenance',
  EXECUTE_CODE = 'execute-code',
  LOAD_DB_CACHE = 'load-db-cache',
  MERGE_USERS = 'merge-users',
  PROCESS_TRANSACTION = 'process-transaction',
  QUERY_DB_VERSION = 'query-db-version',
  REBUILD_WALLET_EVENT_HISTORY = 'rebuild-wallet-event-history',
  REMAP_INVENTORY = 'remap-inventory',
  REMOVE_APP_FEATURE = 'remove-app-feature',
  REMOVE_TASK = 'remove-task',
  RESET_DAILY_CC_PURCHASE_LIMIT = 'reset-daily-cc-purchase-limit',
  RESET_USER_PASSWORD = 'reset-user-password',
  RUN_DB_TASK = 'run-db-task',
  RUN_MIGRATION = 'run-migration',
  SEND_CAMPAIGN_MESSAGES = 'send-campaign-messages',
  SEND_GIFTING_EVENT_REMINDERS = 'send-gifting-event-reminders',
  SEND_PUSH_NOTIFICATION = 'send-push-notification',
  SEND_PURCHASE_TRANSFER_NOTIFICATION = 'send-purchase-transfer-notification',
  SET_USER_PASSWORD = 'set-user-password',
  START_SHUTDOWN = 'start-shutdown',
  UPDATE_CHAT_METADATA = 'update-chat-metadata',
  UPDATE_REWARD_CONFIGS = 'update-reward-configs',
  UPDATE_INBOX = 'update-inbox',
  UPDATE_MARKETPLACE_METADATA = 'update-marketplace-metadata',
  VERIFY_USER_PASSWORD = 'verify-user-password',
}
