import { gql } from '@apollo/client'

export default gql`
  mutation M($merchant: MerchantInput!) {
    upsertMerchant (merchant: $merchant) {
      id
      userId
      name
      alias1
      alias2
      alias3
      description
      url
      smallLogoImageSource
      largeLogoImageSource
      balanceLookupUri
      paymentTypes
      source
      listed
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
