import { gql } from '@apollo/client'

export default gql`
  query Q {
    newPurchase {
      id
      userId
      productId
      productOptionId
      purchaseType
      amount
      initialBalance
      balance
      currency
      fundType
      code
      hasBarcode
      barcodeFormat
      pin
      balanceUpdatedAt
      referenceUrl
      messageEn
      issuedAt
      expiresAt
      adminNotes
      status
      source
      recordStatus
      createdAt
      updatedAt
      product {
        id
        merchantId
        productType
        code
        hasBarcode
        barcodeFormat
        referenceUrl
        messageEn
        termsEn
        termsUrl
        instructionsEn
        instructionsUrl
        imageSourceFrontSide
        imageSourceBackSide
        merchant {
          id
          name
          url
          smallLogoImageSource
          largeLogoImageSource
          balanceLookupUri
        }
      }
    }
  }
`
