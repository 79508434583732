import { NotificationMethod } from '../../enums'

const labelsEn = Object.freeze({
  [NotificationMethod.AUTO]: 'auto',
  [NotificationMethod.EMAIL]: 'email',
  [NotificationMethod.IN_APP_NOTIFICATION]: 'in-app-notification',
  [NotificationMethod.OFF]: 'off',
  [NotificationMethod.PUSH_NOTIFICATION]: 'push-notification',
  [NotificationMethod.SMS]: 'sms',
})

export default {
  getLabel: (method: NotificationMethod | null | undefined, language = 'en'): string => {
    if (!method) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[method]
    }
    return ''
  },
  isValid: (method: NotificationMethod): boolean => Object.values(NotificationMethod).includes(method),
}
