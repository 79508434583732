import { TagType } from '../../enums'

const labelsEn = Object.freeze({
  [TagType.CATEGORY]: 'category',
  [TagType.LOCATION]: 'location',
})

export default {
  getLabel: (type: TagType | null | undefined, language = 'en'): string => {
    if (!type) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[type]
    }
    return ''
  },
  isValid: (type: TagType | string | null | undefined): boolean => Object.values(TagType).includes(type as TagType),
}
