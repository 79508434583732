import React from 'react'
import { InputLabel, MenuItem, Select } from '@mui/material'

import './styles.css'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'

export type Props = {
  className?: string
}

const EnvironmentControl: React.FC<Props> = (props): JSX.Element | null => {
  const { className } = props

  const { getEnvironment, setEnvironment } = useGlobalCache()

  const onChangeDonationTargetUserId = (event: any): void => {
    setEnvironment(event.target.value)
  }

  // ===================================================================================================================
  // Rendering:
  // if (!environment || environment === 'production') {
  //   return null
  // }

  return (
    <div className={`environment-control ${className || ''}`}>
      <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
        <InputLabel id='select-environment-label' className='withStandardRightMargin'>
          Environment:
        </InputLabel>
        <Select
          labelId='select-environment-label'
          id='select-environment'
          value={getEnvironment() || 'production'}
          onChange={onChangeDonationTargetUserId}
        >
          <MenuItem key='development' value='development'>development</MenuItem>
          <MenuItem key='sandbox' value='sandbox'>sandbox</MenuItem>
          <MenuItem key='production' value='production'>production</MenuItem>
        </Select>
      </div>
      <div className='smallText'>
        After changing the environment, you will have to relaunch the app.
      </div>
    </div>
  )
  // return (
  //   <div className={`environment-control ${className || ''}`}>
  //     <div className='withDoubleBottomMargin'>
  //       Environment: {environment}
  //     </div>
  //     <div className='show-more-link' onClick={handleClick}>
  //       <div className='label'>
  //
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default EnvironmentControl
