import { gql } from '@apollo/client'

export default gql`
  query Q($filter: PurchaseTransferListFilter!) {
    purchaseTransfers (filter: $filter) {
      id
      fromPurchaseId
      fromUserId
      fromUserUsername
      fromUserEmail
      fromUserPhoneNumber
      toPurchaseId
      toUserId
      toUserUsername
      toUserFullName
      toUserEmail
      toUserPhoneNumber
      notificationMethod
      transferStatus
      messageText
      messageScheduledAt
      messageSentAt
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
