import React from 'react'
import { IonButton, IonIcon, IonSpinner } from '@ionic/react'
import { alert, checkmark } from 'ionicons/icons'
import { InputVerificationStatus } from '../../enums'
import { TfaStatus } from '../../lib/core/enums'

export const getInputVerificationStatusFromTfaStatus = (
  status: TfaStatus | undefined,
): InputVerificationStatus | undefined => {
  if (
    status === TfaStatus.MESSAGE_REQUESTED ||
    status === TfaStatus.VERIFYING_CODE
  ) {
    return InputVerificationStatus.PROCESSING
  }
  if (
    status === TfaStatus.MESSAGE_SENT ||
    status === TfaStatus.CODE_MISMATCH
  ) {
    return InputVerificationStatus.VERIFY
  }
  if (status === TfaStatus.PASSED) {
    return InputVerificationStatus.VALID
  }
  // if (status === TfaStatus.CODE_MISMATCH) {
  //   return InputVerificationStatus.INVALID
  // }
  // return InputVerificationStatus.DEFAULT
}

type Props = {
  status: InputVerificationStatus | undefined;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}
const VerifyInputIndicator: React.FC<Props> = (props): JSX.Element | null => {
  const {
    status,
    disabled,
    className,
    onClick,
  } = props

  if (!status) {
    return null
  }

  if (status === InputVerificationStatus.PROCESSING) {
    return (
      <IonSpinner
        color='medium'
        className='withDoubleRightMargin'
      />
    )
  }

  if (status === InputVerificationStatus.VERIFY) {
    return (
      <IonButton
        className={`verify-input-indicator ${status} ${className || ''}`}
        size='small'
        fill='clear'
        color='medium'
        disabled={disabled}
        onClick={() => onClick && onClick() }
      >
        Verify
      </IonButton>
    )
  }

  if (status === InputVerificationStatus.VALID) {
    return (
      <IonIcon
        slot='end'
        icon={checkmark}
        color='success'
        className='withDoubleRightMargin'
      />
    )
  }

  return (
    <IonIcon
      slot='end'
      icon={alert}
      color='danger'
      className='withDoubleRightMargin'
    />
  )
}

export default VerifyInputIndicator
