import type { Currency } from '../../definitions'
import type { FundType } from '../../enums'
import getAmountDisplayFactor from './getAmountDisplayFactor'

const getInternalMoneyAmount = (
  displayAmount: number | null | undefined,
  fundType: FundType | null | undefined,
  currency: Currency | null | undefined,
): number => {
  if (!displayAmount) {
    return 0
  }
  return Math.ceil(displayAmount * getAmountDisplayFactor(fundType, currency))
}

export default getInternalMoneyAmount
