import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, ShoppingCartListFilter, ShoppingCart } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  ShoppingCartQueryData,
  ShoppingCartQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'

const loadShoppingCart = (
  shoppingCartId: string | undefined,
  isActiveShoppingCart: boolean,
  addOrder: boolean,
  isInTargetStateFunc: ((shoppingCart: ShoppingCart) => boolean) | 'watch-updated-at' | undefined,
  listFilter: ShoppingCartListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ShoppingCart | undefined> => {
  // console.log('api.loadShoppingCart called.',
  //   { shoppingCartId, userId, isActiveShoppingCart, isInTargetStateFunc, options })

  return new Promise((resolve, reject) => {
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadShoppingCart: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const variables: ShoppingCartQueryVariables = { userId: activeUserId }
    if (shoppingCartId) {
      variables.shoppingCartId = shoppingCartId
    }

    const queryOptions: QueryOptions<ShoppingCartQueryVariables> = {
      query: apollo.queries.shoppingCart(addOrder),
      variables,
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      // console.log('api.loadShoppingCart.load called.')
      apolloClient.query<ShoppingCartQueryData, ShoppingCartQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.shoppingCart ||
            !resp.data.shoppingCart.id
          ) {
            logger.error('api.loadShoppingCart.load: query did not return expected data.', { resp })
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((shoppingCart: ShoppingCart) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.shoppingCart.updatedAt
            activeIsInTargetStateFunc = (shoppingCart: ShoppingCart) => shoppingCart.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.shoppingCart)
          ) {
            const queryListVariables = !isActiveShoppingCart && clientOptions && clientOptions.updateList
              ? apollo.getShoppingCartsQueryVariables(listFilter, activeUserId)
              : undefined
            cache.updateShoppingCart(
              resp.data.shoppingCart,
              isActiveShoppingCart,
              queryListVariables,
              apolloClient,
            )
            // console.log('api.loadShoppingCart.load: resolving with info.', resp.data.shoppingCart)
            resolve(resp.data.shoppingCart)
            return
          }

          // console.log('api.loadShoppingCart.load: shoppingCarts not in target state.',
          //   { shoppingCart: resp.data.shoppingCart })
          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadShoppingCart.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadShoppingCart.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
}

export default loadShoppingCart
