import querystring from 'querystring'
import { CryptoCurrency, ErrorCode, FundType } from '../enums'
import type { Currency } from '../definitions'

export const BITCOIN_TO_SATOSHI_FACTOR = 100000000

const getPaymentRequestString = (
  address: string,
  amount: number,
  currency: CryptoCurrency,
  message: string | undefined,
): string => (
  `${currency}:${address}?${querystring.stringify({
    amount: amount.toString(),
    message,
  })}`
)

const cryptoCurrencyHelpers = {
  BITCOIN_TO_SATOSHI_FACTOR,

  bitcoinToSatoshiFactor: BITCOIN_TO_SATOSHI_FACTOR,

  bitcoinToSatoshi: (value: number | string): number => {
    const floatValue = typeof value === 'string' ? parseFloat(value) : value

    return Math.floor(floatValue * BITCOIN_TO_SATOSHI_FACTOR)
  },

  satoshiToBitcoin: (microValue: number): number => {
    return microValue / BITCOIN_TO_SATOSHI_FACTOR
  },

  getPaymentRequestString,

  convertFiatToMimbleToken: (dollarAmount: number): number => dollarAmount * 100,
  convertMimbleTokenToFiat: (mimbleTokenAmount: number): number => mimbleTokenAmount / 100,

  isPaidAmountCoveringPurchaseAmount: (
    paidAmount: number,
    purchaseAmount: number,
    fundType: FundType,
    currency: Currency | null,
  ): boolean => {
    if (fundType === FundType.CRYPTO && currency === CryptoCurrency.BITCOIN) {
      return paidAmount >= purchaseAmount - 50
    }
    throw new Error(ErrorCode.NOT_SUPPORTED)
  },
}

export default cryptoCurrencyHelpers
