import React, { useState } from 'react'
import {
  IonList,
  IonAlert,
  IonButton,
} from '@ionic/react'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import type { Wish, WishInput } from '../../lib/core/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../services/api'
import logger from '../../services/logger'
import WishForm from './WishForm'
import WishItem from './WishItem'
import WishListHeader from './WishListHeader'

type Props = {
  wishes: Wish[] | null | undefined
  owner: 'active-user' | 'contact'
  userId?: string | null
  contactId?: string | null
  icon: string
  readOnly?: boolean
  helpText: string
  emptyListText: string
  className?: string
  onOpenExternalUrl?: (wishId: string, url: string) => void
  showUiMessage: (message: string) => void
}

const WishList: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('WishList.render called.', { props })
  const {
    wishes,
    owner,
    userId,
    icon,
    readOnly,
    helpText,
    contactId,
    emptyListText,
    className,
    onOpenExternalUrl,
    showUiMessage,
  } = props
  const objectLabel = owner === 'active-user' ? 'wish' : 'thought'

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [wishIdToBeDeleted, setWishIdToBeDeleted] = useState<string | undefined>()
  const [wishIdToBeEdited, setWishIdToBeEdited] = useState<string | undefined>()
  const [showNewWishForm, setShowNewWishForm] = useState(false)
  const [isDeletingWish, setIsDeletingWish] = useState(false)
  const [isSavingWish, setIsSavingWish] = useState(false)

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isDeletingWish || isSavingWish

  const deleteWish = (wishId: string): void => {
    setIsDeletingWish(true)
    api.deleteWish(
      wishId,
      activeUserId as string,
      apolloClient,
      undefined,
      { updateList: true },
    ).then(() => {
      setIsDeletingWish(false)
      setWishIdToBeEdited(undefined)
      showUiMessage('The item was removed successfully.')
    }, (error) => {
      logger.error('WishList.deleteItem: error received.', { error })
      setIsDeletingWish(false)
      setWishIdToBeEdited(undefined)
      showUiMessage('Error removing this item.')
    })
  }

  const upsertWish = (wishInput: WishInput): void => {
    if (!wishInput.id && !wishInput.userId && !wishInput.contactId) {
      if (userId) {
        wishInput.userId = userId
      }
      if (contactId) {
        wishInput.contactId = contactId
      }
    }
    setIsSavingWish(true)
    const existingWish = wishInput.id && wishes
      ? wishes.find(w => w.id === wishInput.id)
      : undefined
    const isWishInTargetStateFunc = wishInput.id && existingWish
      ? (wish: Wish) => wish.updatedAt !== existingWish.updatedAt
      : undefined
    api.upsertWish(
      wishInput,
      isWishInTargetStateFunc,
      undefined,
      activeUserId as string,
      apolloClient,
      undefined,
      { updateList: true },
    ).then(() => {
      setIsSavingWish(false)
      showUiMessage(`Successfully saved the ${objectLabel}.`)
      // reloadContact().then(() => {
      //   setToastMessage(`Successfully saved the ${objectLabel}.`)
      //   setShowToast(true)
      // })
    }, (error) => {
      logger.error('WishList.editItem: error received.', { error })
      setIsSavingWish(false)
      showUiMessage('Error saving this item.')
    })
  }

  // ===================================================================================================================
  // Event Handlers:
  const onAddItem = (): void => {
    setShowNewWishForm(true)
  }

  const onClickItem = (wishId: string): void => {
    if (readOnly) {
      return
    }
    setWishIdToBeEdited(wishId)
  }

  const onDeleteWish = (wishId: string): void => {
    setWishIdToBeDeleted(wishId)
  }

  const onDeleteWishConfirmed = () => {
    if (!wishIdToBeDeleted) {
      return
    }
    deleteWish(wishIdToBeDeleted)
    setWishIdToBeDeleted(undefined)
  }

  const onEditItem = (wishId: string): void => {
    setWishIdToBeEdited(wishId)
  }

  const onCloseEditForm = () => {
    setWishIdToBeEdited(undefined)
  }

  const onSubmitEditForm = (wishInput: WishInput) => {
    setWishIdToBeEdited(undefined)
    upsertWish(wishInput)
  }

  const onCloseNewWishForm = (): void => {
    setShowNewWishForm(false)
  }

  const onSubmitNewWishForm = (wishInput: WishInput): void => {
    upsertWish(wishInput)
    setShowNewWishForm(false)
  }

  // ===================================================================================================================
  // Rendering:
  let content: JSX.Element | undefined

  let newWishForm: JSX.Element | undefined
  if (showNewWishForm) {
    newWishForm = (
      <WishForm
        owner={owner}
        key='new-wish-form'
        isProcessing={isProcessing}
        onCancel={onCloseNewWishForm}
        onSubmit={onSubmitNewWishForm}
      />
    )
  }

  let editWishForm: JSX.Element | undefined
  if (
    wishIdToBeEdited &&
    !showNewWishForm &&
    wishes
  ) {
    const wish = wishes.find(w => w.id === wishIdToBeEdited)
    editWishForm = (
      <div key='edit-wish-form' className='in-list-edit-form-wrapper'>
        <WishForm
          key={wishIdToBeEdited}
          wish={wish}
          owner={owner}
          onCancel={onCloseEditForm}
          onDelete={onDeleteWish}
          onSubmit={onSubmitEditForm}
        />
      </div>
    )
  }

  if (Array.isArray(wishes) && wishes.length > 0) {
    let items: JSX.Element[] | undefined
    if (
      !wishIdToBeEdited &&
      !newWishForm &&
      Array.isArray(wishes) &&
      wishes.length > 0
    ) {
      items = wishes.map((wish: Wish) => {
        return (
          <WishItem
            icon={icon}
            key={wish.id}
            wish={wish}
            readOnly={readOnly}
            onClick={onClickItem}
            onDelete={onDeleteWish}
            onEdit={onEditItem}
            onOpenExternalUrl={onOpenExternalUrl}
          />
        )
      })
    }

    let listHeader: JSX.Element | undefined
    if (!showNewWishForm) {
      listHeader = (
        <WishListHeader
          helpText={helpText}
          onAddItem={readOnly ? undefined : onAddItem}
        />
      )
    }

    content = (
      <>
        {listHeader}
        {newWishForm}
        {editWishForm}
        <IonList className='wish-list'>
          <div className='list-wrapper'>
            {items}
          </div>
        </IonList>
      </>
    )
  } else {
    if (newWishForm) {
      content = newWishForm
    } else if (editWishForm) {
      content = editWishForm
    } else {
      let ctaSection: JSX.Element | undefined
      const label = owner === 'active-user' ? 'Create Wish' : 'Add Thought'
      if (!readOnly) {
        ctaSection = (
          <div className='withCenteredColumnContent withDoubleTopMargin'>
            <IonButton
              onClick={onAddItem}
            >
              {label}
            </IonButton>
          </div>
        )
      }
      content = (
        <div className='lightText withDoubleTopMargin'>
          {emptyListText}
          {ctaSection}
        </div>
      )
    }
  }

  return (
    <div className={`wish-list ${className || ''}`}>
      {content}
      <IonAlert
        isOpen={!!wishIdToBeDeleted}
        onDidDismiss={(): void => { setWishIdToBeDeleted(undefined) }}
        header='Delete Wish'
        subHeader=''
        message={`Are you sure you want to delete this ${objectLabel}?`}
        buttons={[
          { text: 'Yes, delete', handler: (): void => { onDeleteWishConfirmed() } },
          { text: 'Cancel', cssClass: 'secondary', handler: (): void => { setWishIdToBeDeleted(undefined) } },
        ]}
      />
    </div>
  )
}

export default WishList
