import { OrderStatus } from '../../enums'

const labelsEn = Object.freeze({
  [OrderStatus.PROCESSING]: 'processing',
  [OrderStatus.PENDING_DELIVERY]: 'pending delivery',
  [OrderStatus.DELIVERED]: 'delivered',
  [OrderStatus.INVALID]: 'invalid',
})

export default {
  getLabel: (status?: OrderStatus | null, language?: string): string => {
    if (!status) {
      return ''
    }
    if (!language || language === 'US') {
      return labelsEn[status]
    }
    return ''
  },
  isValid: (status: OrderStatus): boolean => Object.values(OrderStatus).includes(status),
  isComplete: (status: OrderStatus): boolean => status === OrderStatus.DELIVERED,
}
