export enum AgentType {
  ADMIN = 'admin',
}

export enum AgentStatus {
  ACTIVE = 'active',
}

export enum AnimationName {
  BALLOONS = 'balloons',
  CONFETTI_CANNON = 'confetti-cannon',
  CONFETTI_CENTER = 'confetti-center',
  CONFETTI_FALLING = 'confetti-falling',
  CONFETTI_SMALL = 'confetti-small',
  GREEN_FIREWORKS = 'green-fireworks',
  MIMBLE_FIREWORKS = 'mimble-fireworks',
  PASTEL_FIREWORKS = 'pastel-fireworks',
  RED_FIREWORKS = 'red-fireworks',
  SNOW = 'snow',
}

export enum AppAction {
  INVITE_FRIEND = 'invite-friend',
  EDIT_PROFILE = 'edit-profile',
  OPEN_CONTACTS = 'open-contacts',
  PURCHASE = 'purchase',
  UPLOAD_GIFT_CARD = 'upload-gift-card',
  UPDATE_APP = 'update-app',
}

export enum AppFeature {
  BIRTHDAY_REMINDERS = 'bdremind',
  BUY_WITH_CREDIT_CARD = 'cc',
  DONATE = 'donate',
  SEND_MIT = 'sendmit',
}

export enum AssetType {
  MERCHANT_LOGO = 'merchant-logo',
  PURCHASE_BACK = 'purchase-back',
  PURCHASE_FRONT = 'purchase-front',
  USER_AVATAR = 'user-avatar',
}

export enum AsyncTaskSendNotificationResult {
  OK = 'ok',
  FAILED = 'failed',
  INVALID_PHONE_NUMBER = 'invalid-phone',
}

export enum AsyncTaskType {
  IMPORT_STORED_PRODUCTS = 'import-stored-prod',
  RESET_PASSWORD = 'reset-password',
  TOKEN_SIGN_IN = 'token-sign-in',
  UPDATE_EMAIL = 'update-email',
  UPDATE_PASSWORD = 'update-password',
  UPDATE_PHONE_NUMBER = 'update-phone-number',
  VERIFY_EMAIL = 'verify-email',
  VERIFY_PHONE_NUMBER = 'verify-phone-number',
  VERIFY_PHONE_NUMBER_ON_SIGN_UP = 'verify-phone-signup',
  WELCOME = 'welcome',
}

export enum AsyncTaskCommandType {
  SEND_NOTIFICATION = 'send-notification',
  VERIFY_TOKEN = 'verify-token',
  CANCEL = 'cancel',
}

export enum AsyncTaskStatus {
  STARTED = 'started',
  NOTIFICATION_SENT = 'msg-sent',
  NOTIFICATION_FAILED = 'msg-failed',
  FINISHED = 'finished',
  EXPIRED = 'expired',
}

export enum AsyncTaskResult {
  OK = 'OK',
  CONFIRM_TOKEN_MISMATCH = 'token-mismatch',
  DATA_VALIDATION_FAILED = 'data-validation-failed',
  DEVICE_NOT_FOUND = 'TFA:DEVICE_NOT_FOUND',
  ERROR = 'error',
  EMAIL_MISMATCH = 'TFA:EMAIL_MISMATCH',
  EMAIL_NOT_VERIFIED = 'TFA:EMAIL_NOT_VERIFIED',
  INVALID_EMAIL = 'TFA:INVALID_EMAIL',
  INVALID_PHONE_NUMBER = 'TFA:INVALID_PHONE_NUMBER',
  MISSING_EMAIL = 'TFA:MISSING_EMAIL',
  MISSING_PHONE_NUMBER = 'TFA:MISSING_PHONE_NUMBER',
  NOT_FOUND = 'TFA:NOT_FOUND',
  PHONE_NUMBER_MISMATCH = 'TFA:PHONE_NUMBER_MISMATCH',
  PHONE_NUMBER_NOT_VERIFIED = 'TFA:PHONE_NUMBER_NOT_VERIFIED',
  PASSED = 'TFA:PASSED',
  SYSTEM_ERROR = 'TFA:SYSTEM_ERROR',
  USER_NOT_FOUND = 'TFA:USER_NOT_FOUND',
  USER_FAILED_VALIDATION = 'TFA:USER_FAILED_VALIDATION',
  USER_NOT_SIGNED_IN = 'TFA:USER_NOT_SIGNED_IN',
  PASSWORD_UPDATED = 'TFA:PASSWORD_UPDATED',
  PASSWORD_MISMATCH = 'TFA:PASSWORD_MISMATCH',
}

// see: https://www.plascards.com/help-center/barcodes.php
export enum BarcodeType {
  TYPE_39 = 'TYPE_39',
  TYPE_128 = 'TYPE_128',
  CODE_25 = 'CODE_25',
  ITF = 'ITF', // Interleaved 2 OF 5
  I125 = 'I125',
  UPC_A = 'UPC_A',
  UPC_E = 'UPC_E',
  EAN_13 = 'EAN_13',
  EAN_8 = 'EAN_8',
  QR_CODE = 'QR_CODE',
  PDF417 = 'PDF417',
  DATA_MATRIX = 'DATA_MATRIX',
}

export enum BlockchainAddressStatus {
  CREATED = 'created',
  USED = 'used',
}

export enum CampaignStatus {
  PLANNED = 'planned',
  ACTIVE = 'active',
  FINISHED = 'finished',
}

export enum CampaignType {
  ENGAGEMENT = 'engagement',
}

export enum ContactType {
  INVITATION = 'invitation',
  USER_CREATED = 'user-created',
  SYSTEM_CREATED = 'system-created',
}

export enum ContactStatus {
  ACTIVE = 'active',
}

export enum CodeSignInStage {
  UNSET = 'unset',
  STARTED = 'started',
  NOTIFICATION_REQUESTED = 'notification-requested',
  NOTIFICATION_SENT = 'notification-sent',
  CODE_SUBMITTED = 'code-submitted',
  FAILED = 'failed',
  FINISHED = 'finished',
}

export enum ChatImageType {
  GIPHY = 'giphy',
}

export enum ChatMessageType {
  STANDARD = '',
  GIFT = 'gift',
  INVITATION = 'invitation',
  SYSTEM = 'system',
}

export enum ChatStatus {
  ACTIVE = 'active',
}

export enum ChatAttachmentType {
  PURCHASE = 'purchase',
  REWARD = 'reward',
  TRANSACTION = 'transaction',
  GIPHY = 'giphy',
  ANIMATION = 'animation',
}

export enum ContactEventReminderTiming {
  DAYS_0 = 0,
  DAY_1 = 1,
  DAYS_2 = 2,
  DAYS_3 = 3,
  DAYS_4 = 4,
  DAYS_5 = 5,
  DAYS_6 = 6,
  WEEK_1 = 7,
  WEEKS_2 = 14,
  WEEKS_3 = 21,
  MONTH_1 = 31,
  MONTHS_2 = 62,
  MONTHS_3 = 93,
}

export enum CryptoCurrency {
  BITCOIN = 'BTC',
  BITCOINCASH = 'BCH',
  CARDANO = 'ADA.BEP2',
  CHAINLINK = 'LINK.BEP20',
  DOGE = 'DOGE',
  ETHEREUM = 'ETH',
  ETHEREUMCLASSIC = 'ETC',
  LITECOIN = 'LTC',
  LITECOIN_TEST = 'LTCT',
  POLKADOT = 'DOT.BEP20',
  TETHER_B ='USDT.BEP20',
  TETHER_E = 'USDT.ERC20',
  USDCOIN_B = 'USDC.BEP20',
  USDCOIN_E = 'USDC.ERC20',
  XRP = 'XRP.BEP2',
}

export enum DeeplinkType {
  INVITE = 'invite',
  GIFT = 'gift',
}

export enum EmailTransportType {
  SES = 'ses',
  // INTERCOM = 'intercom',
}

export enum ErrorCode {
  AUTH_TOKEN_NO_MATCH = 'auth-token-no-match',
  BLOCKCHAIN_ADDRESS_IN_USE = 'blockchain-address-in-use',
  CURRENT_PASSWORD_INCORRECT = 'current-password-incorrect',
  CURRENT_PASSWORD_MISSING = 'current-password-missing',
  DATA_VALIDATION_FAILED = 'data-validation-failed',
  EXCEEDS_LIMIT = 'exceeds-limit',
  INVALID_INPUT = 'invalid-input',
  INVALID_PUSH_NOTIFICATION_TOKEN = 'invalid-push-notification-token',
  INVENTORY_NOT_ACTIVE = 'inventory-not-active',
  NO_AVAILABLE_STORED_PRODUCT = 'no-available-stored-product',
  NO_LIVE_WEBSOCKET_CONNECTION_AVAILABLE = 'no-live-websocket-connection-available',
  NO_NOTIFICATION_METHOD_AVAILABLE = 'no-notification-method-available',
  NOT_FOUND = 'not-found',
  NOT_IMPLEMENTED = 'not-implemented',
  NOT_SUPPORTED = 'not-supported',
  ORDER_NOT_FOUND = 'order-not-found',
  PASSWORD_NO_MATCH = 'password-no-match',
  PHONE_NUMBER_NOT_SUPPORTED = 'phone-number-not-supported',
  PRODUCT_NOT_AVAILABLE_FOR_PURCHASE = 'product-not-available-for-purchase',
  PURCHASE_CANNOT_BE_DELETED = 'purchase-cannot-be-deleted',
  PURCHASE_HAS_NO_PRODUCT = 'purchase-has-no-product',
  PURCHASE_HAS_NO_PRODUCT_OPTION = 'purchase-has-no-product-option',
  PURCHASE_HAS_NO_USER_ID = 'purchase-has-no-user-id',
  PURCHASE_HAS_TRANSFERS = 'purchase-has-transfers',
  PURCHASE_NOT_AVAILABLE_FOR_TRANSFER = 'purchase-not-available-for-transfer',
  PURCHASE_NOT_FOUND = 'purchase-not-found',
  PURCHASE_TRANSFER_CANNOT_BE_CANCELED = 'purchase-cannot-be-canceled',
  PURCHASING_NOT_ACTIVE = 'purchasing-not-active',
  RECIPIENT_NOT_AVAILABLE_FOR_TRANSFER = 'recipient-not-available-for-transfer',
  SERVICE_NOT_ACTIVE = 'service-not-active',
  SYSTEM_ERROR = 'system-error',
  TIMEOUT = 'timeout',
  TOO_MANY_REQUESTS = 'too-many-requests',
  UNAUTHORIZED = 'unauthorized',
  USER_ALREADY_EXISTS = 'user-already-exists',
  ALREADY_EXISTS = 'already-exists',
  USER_CANNOT_RECEIVE_PRODUCT = 'user-cannot-receive-product',
}

export enum FundType {
  UNKNOWN = '',
  CRYPTO = 'crypto',
  FIAT = 'fiat',
  TOKEN = 'token',
}

export enum GiftingEventType {
  ANNIVERSARY = 'anniversary',
  AWARD = 'award',
  BIRTHDAY = 'birthday',
  EASTER = 'easter',
  EARTH = 'earth',
  GRADUATION = 'graduation',
  NAME_DAY = 'name-day',
  PAW = 'paw',
  PARTY = 'party',
  TRAVEL = 'travel',
  TROPHY = 'trophy',
  WEDDING_ANNIVERSARY = 'wedding-anniversary',
}

export enum InventoryImportFormat {
  BUY_A_TAB = 'buy-a-tab',
  CASH_STAR = 'cashstar',
  GENERIC = 'generic',
  INCOMM = 'incomm',
}

export enum InvitationType {
  MEMBER = 'member',
  GIFT = 'gift',
}

export enum InvitationStatus {
  PENDING = 'pending',
  FINISHED = 'finished',
}

export enum IdentType {
  UNKNOWN = 'unknown',
  ID = 'id',
  USERNAME = 'username',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone-number',
}

export enum InquiryType {
  TRANSACTION = 'transaction',
  SYSTEM_EVENT = 'system-event',
  CLOSE_ACCOUNT = 'close-account',
}

export enum InboxItemType {
  PURCHASE = 'purchase',
  PURCHASE_TRANSFER = 'purchase-transfer',
  CHAT = 'chat',
  SYSTEM_NOTIFICATION = 'system-notification',
}

export enum InviteCodeType {
  CAMPAIGN = 'campaign',
}

export enum MarketplaceView {
  DEFAULT = 'default',
}

export enum ModelAttributeType {
  BOOLEAN = 'boolean',
  DATE = 'date',
  SHORT_DATE = 'short-date', // YYYY-MM-DD
  FLOAT = 'float',
  INTEGER = 'integer',
  MONEY_AMOUNT = 'money-amount',
  JSON = 'json',
  STRING = 'string',
  UUID = 'uuid',
  PHONE_NUMBER = 'phone-number',
}

/// ///////////////////////////////////////////////////////////////////////////////////////
// Note: The values here must match the email templates used.
//       The email templates can be found under src/services/messaging/email/emails.
/// ///////////////////////////////////////////////////////////////////////////////////////
export enum CoreMemberMessageTemplateName {
  CHAT_MESSAGE_RECEIVED = 'chat-message-received',
  CONTACT_EVENT_REMINDER = 'contact-event-reminder',
  FREEBIE_REWARD_RECEIVED = 'freebie-reward-received',
  MEMBER_RECEIVED_GIFT = 'member-received-gift',
  NON_MEMBER_RECEIVED_GIFT = 'non-member-received-gift',
  INVITATION_ACCEPTED = 'invitation-accepted',
  INVITATION_RECEIVED = 'invitation-received', // i.e. someone invited a non-member
  MARKETPLACE_PURCHASE_DELIVERED = 'marketplace-purchase-delivered',
  MIT_PURCHASE_DELIVERED = 'mit-purchase-delivered',
  GIFT_FAILED_TO_SEND = 'gift-failed-to-send',
  RECIPIENT_ACCEPTED_GIFT = 'recipient-accepted-gift',
  RECIPIENT_DECLINED_GIFT = 'contact-declined-gift',
  TFA = 'tfa',
  TOKEN_REWARD_RECEIVED = 'token-reward-received',
  WELCOME = 'welcome',
}

export enum ModelChangedMessageType {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
}

export enum ModelChangedCode {
  PURCHASE_DELIVERED = 'purchase-delivered',
  ORDER_CREATED = 'order-created',
  PAYMENT_FAILED = 'payment-failed',
  PAYMENT_SUCCEEDED = 'payment-succeeded',
}

export enum ModelType {
  AsyncTask = 'AsyncTask',
  BankAccount = 'BankAccount',
  BlockchainAddress = 'BlockchainAddress',
  BlockchainTransaction = 'BlockchainTransaction',
  Chat = 'Chat',
  ChatAttachment = 'ChatAttachment',
  ChatMessage = 'ChatMessage',
  Contact = 'Contact',
  ContactEvent = 'ContactEvent',
  ContactEventReminder = 'ContactEventReminder',
  Inbox = 'Inbox',
  Inquiry = 'Inquiry',
  Invitation = 'Invitation',
  Merchant = 'Merchant',
  Offer = 'Offer',
  OfferTag = 'OfferTag',
  Order = 'Order',
  Organization = 'Organization',
  Product = 'Product',
  ProductOption = 'ProductOption',
  Purchase = 'Purchase',
  PurchaseTransfer = 'PurchaseTransfer',
  ShoppingCart = 'ShoppingCart',
  ShoppingCartItem = 'ShoppingCartItem',
  StoredProduct = 'StoredProduct',
  Tag = 'Tag',
  Transaction = 'Transaction',
  User = 'User',
  UserDevice = 'UserDevice',
  UserIdentInfo = 'UserIdentInfo',
  Wallet = 'Wallet',
}

export enum NotificationSettingType {
  OFF = '',
  EMAIL = 'e',
  PUSH_NOTIFICATION = 'p',
  IN_APP_NOTIFICATION = 'n',
}

export enum NotificationMethod {
  OFF = 'off',
  AUTO = 'auto',
  CHAT_MESSAGE = 'chat-message',
  EMAIL = 'email',
  SMS = 'sms',
  PUSH_NOTIFICATION = 'push-notification',
  IN_APP_NOTIFICATION = 'in-app-notification',
}

export enum OAuthUserProvider {
  APPLE = 'apple',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  TWITTER = 'twitter',
}

export enum OrganizationType {
  MERCHANT = 'merchant',
  NONPROFIT = 'nonprofit',
}

export enum OrganizationStatus {
  ACTIVE = 'active',
}

export enum OrderStatus {
  PROCESSING = 'processing',
  PENDING_DELIVERY = 'pending-delivery',
  DELIVERED = 'delivered',
  INVALID = 'invalid',
}

export enum OrgProductType {
  GIFT_CARD = 'gift-card',
  DISCOUNT_CODE = 'discount-code',
  PROMO_CODE = 'promo-code',
  MIMBLE_TOKEN = 'mimble-token',
}

export enum PaymentErrorCode {
  CANCELED = 'canceled',
  CARD_EXPIRED = 'card-expired',
  CARD_NOT_SUPPORTED = 'card-not-supported',
  CREDIT_CARD_REPORTED_LOST = 'credit-card-reported-lost',
  CREDIT_CARD_REPORTED_STOLEN = 'credit-card-reported-stolen',
  CURRENCY_NOT_SUPPORTED = 'currency-not-supported',
  DUPLICATED_TRANSACTION = 'duplicated-transaction',
  FRAUD_SUSPECTED = 'fraud-suspected',
  IN_ESCROW = 'in-escrow',
  INCORRECT_ADDRESS = 'incorrect-address',
  INCORRECT_CREDIT_CARD_NUMBER = 'incorrect-credit-card-number',
  INCORRECT_CVC = 'incorrect-cvc',
  INCORRECT_EXPIRATION_MONTH = 'incorrect-expiration-month',
  INCORRECT_EXPIRATION_YEAR = 'incorrect-expiration-year',
  INCORRECT_INPUT = 'incorrect-input',
  INCORRECT_NAME = 'incorrect-name',
  INCORRECT_PIN = 'incorrect-pin',
  INCORRECT_POSTAL_CODE = 'incorrect-postal-code',
  INSUFFICIENT_FUNDS = 'insufficient-funds',
  INVALID_AMOUNT = 'invalid-amount',
  PIN_TRY_EXCEEDED = 'pin-try-exceeded',
  PROCESSOR_REJECTED = 'processor-rejected',
  REFUNDING = 'refunding',
  TRY_AGAIN_LATER = 'try-again-later',
  UNKNOWN_REASON = 'unknown-reason',
  UNSUPPORTED_CREDIT_CARD = 'unsupported-credit-card',
  USER_BLOCKED = 'user-blocked',
  VALIDATION_FAILED = 'validation-failed',
}

export enum PaymentType {
  CREDIT_CARD = 'cc',
  CRYPTO = 'crypto',
  MIT = 'mit',
}

export enum PurchaseAmountState {
  UNKNOWN= 'UNKNOWN',
  EXACT = 'EXACT',
  OVERPAID = 'OVERPAID',
  UNDERPAID = 'UNDERPAID',
}

export enum PurchaseSource {
  USER_ADDED = 'user-added',
  PURCHASED = 'purchased',
  GIFTED = 'gifted',
  FREEBIE = 'freebie',
}

export enum PurchaseType {
  MARKETPLACE_PRODUCT = 'marketplace-product',
  ORG_PRODUCT = 'org-product',
  TOKEN = 'token',
}

export enum PurchaseUpdateType {
  STATUS_CHANGE = 'status-change',
  RECORD_STATUS_CHANGE = 'record-status-change',
  BALANCE_CHANGE = 'balance-change',
  MULTIPLE_CHANGES = 'multiple-changes',
}

export enum ProductListScope {
  MARKETPLACE = 'marketplace',
  ADMIN = 'admin',
}

export enum PurchasesUpdateType {
  BALANCE_CHANGE = 'balance-change',
}

export enum PurchaseStatus {
  CREATED = 'created',
  PAYMENT_REQUEST = 'payment-request',
  PENDING_DELIVERY = 'pending-delivery',
  ACTIVE = 'active',
  RECEIVED = 'received',
  REDEEMED = 'redeemed',
  CANCELED = 'canceled',
  TRANSFERRING = 'transferring',
  TRANSFERRED = 'transferred',
  INVALID = 'invalid',
}

export enum PurchaseTransferStatus {
  CREATING = 'creating',
  SCHEDULED = 'scheduled',
  AVAILABLE = 'available',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  INVALID = 'invalid',
  CANCELED = 'canceled',
}

export enum RewardType {
  ADD_CONTACT = 'add-contact',
  ADDED_AS_A_CONTACT = 'added-as-a-contact',
  ADD_FAVORITE_MERCHANT = 'add-favorite-merchant',
  ADD_GIFT_IDEA = 'add-gift-idea',
  ADD_WISH = 'add-wish',
  CARD_BALANCE_UPDATE = 'card-balance-update',
  CONTACT_ADDED_GIFT_IDEA = 'contact-added-gift-idea',
  GIFT_CARD_UPLOAD = 'gift-card-upload',
  INVITED_BY_USER = 'invited-by-user',
  MARKETPLACE_PURCHASE = 'marketplace-purchase',
  NEW_USER_INVITATION = 'new-user-invitation',
  PURCHASE_BY_INVITED_USER = 'purchase-by-invitation',
  PURCHASE_TRANSFER = 'purchase-transfer',
  SESSION_MILESTONE = 'session-milestone',
  SHARE_BIRTHDAY = 'share-birthday',
  SHARE_WISH_LIST = 'share-wish-list',
  SIGN_UP = 'sign-up',
}

export enum RecordStatus {
  UNSET = '',
  ACTIVE = 'A',
  PENDING = 'P',
  INACTIVE = 'I',
  DELETED = 'D',
}

export enum ReportStatus {
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum ReportType {
  USERS = 'all-users',
  INVENTORY = 'inventory',
  MEMBER_MESSAGES = 'member-messages',
  TRANSACTIONS = 'transactions',
}

export enum ReportDataFormat {
  CSV = 'csv',
  TSV = 'tsv',
  JSON = 'json',
}

export enum ShoppingCartCommand {
  ADD_ITEM = 'add-item',
  CANCEL_PAYMENT = 'cancel-payment',
  CREATE_CRYPTO_PAYMENT_REQUEST = 'create-crypto-payment-request',
  RECREATE_CRYPTO_PAYMENT_REQUEST = 'recreate-crypto-payment-request',
  CLEAR = 'clear',
  PLACE_ORDER = 'place-order',
  REBUILD_ITEMS = 'rebuild-items',
  REMOVE_ITEM = 'remove-item',
  REMOVE_OUT_OF_STOCK_ITEMS = 'remove-out-of-stock-items',
  UPDATE_PAYMENT_METHOD = 'update-payment-method',
  UPDATE_EXCHANGE_RATE = 'update-exchange-rate',
  UPDATE_ITEM = 'update-item',
}

export enum SignInMethod {
  PASSWORD = 'password',
  CODE = 'code',
}

export enum SystemMessageType {
  GIFT = 'gift',
  MARKETING = 'marketing',
  OPERATIONAL = 'operational',
  PURCHASE_TRANSFER_RESULT = 'gift-result',
  REWARD_ISSUED = 'reward-issued',
  WELCOME = 'welcome',
}

export enum StoredProductStatus {
  CREATED = 'created',
  AVAILABLE = 'available',
  RESERVED = 'reserved',
  DELIVERED = 'delivered',
}

export enum ShareStatus {
  UNSET = '',
  ALL = 'A',
  PRIVATE = 'P',
}

export enum SystemEventType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum SystemEventName {
  CREATE_TRANSACTION_FAILED = 'create-transaction-failed',
  CANCEL_TRANSACTION_FAILED = 'cancel-transaction-failed',
  TRANSACTION_REJECTED = 'transaction-rejected',
  PHOTO_ID_UPLOAD_FAILED = 'photo-id-upload-failed',
  AUTH_VIDEO_UPLOAD_FAILED = 'auth-video-upload-failed',
  PHOTO_ID_REJECTED = 'photo-id-rejected',
  AUTH_VIDEO_REJECTED = 'auth-video-rejected',
  UPDATE_BANKING_USER_FAILED = 'update-banking-user-failed',
  LINK_BANK_ACCOUNT_FAILED = 'link-bank-account-failed',
  UNLINK_BANK_ACCOUNT_FAILED = 'unlink-bank-account-failed',
}

export enum SystemEventDisplayName {
  CREATE_TRANSACTION_FAILED = 'Transaction failed',
  CANCEL_TRANSACTION_FAILED = 'Canceling transaction failed',
  PHOTO_ID_UPLOAD_FAILED = 'Photo ID failed to upload',
  AUTH_VIDEO_UPLOAD_FAILED = 'Video failed to upload',
  PHOTO_ID_REJECTED = 'Photo ID was rejected',
  AUTH_VIDEO_REJECTED = 'Video was rejected',
  UPDATE_BANKING_USER_FAILED = 'Sending personal info failed',
  LINK_BANK_ACCOUNT_FAILED = 'Authorizing bank account failed',
  UNLINK_BANK_ACCOUNT_FAILED = 'Un-authorizing bank account failed',
  TRANSACTION_REJECTED = 'Transaction rejected',
}

export enum SystemMessageStatus {
  PLANNED = 'planned',
  ACTIVE = 'active',
  PAUSED = 'paused',
  FINISHED = 'finished',
}

export enum TagType {
  LOCATION = 'loc',
  CATEGORY = 'cat',
}

export enum TransactionUpdateAction {
  UPDATE_EXCHANGE_RATE = 'upd-exchng-rate',
}

export enum TransactionRemoteStatus {
  // Coinpayments:
  PAYPAL_REFUND_OR_REVERSAL = 'paypal-refund-or-reversal',
  CANCELED_OR_TIMED_OUT = 'canceled-or-timed-out',
  WAITING_FOR_FUNDS = 'waiting-for-funds',
  CONFIRMED = 'confirmed',
  QUEUED_FOR_NIGHTLY_PAYOUT = 'queued-for-nightly-payout',
  PAYPAL_PENDING = 'paypal-pending',
  IN_ESCROW = 'in-escrow',
  SETTLED = 'settled',
  // blockchain.info:
  UNSET = '',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  CANCELED = 'CANCELED',
  INVALID = 'INVALID',
  LOCKED = 'LOCKED',
  OK = 'OK',
}

export enum TransactionStatus {
  NOT_STARTED = 'not-started',
  REQUEST_CREATED = 'request-created',
  PENDING = 'pending',
  CONFIRMING = 'confirming',
  PROCESSING = 'processing',
  SETTLED = 'settled',
  REFUNDED = 'refunded',
  FAILED = 'failed',
}

export enum TfaCommand {
  RESET_PASSWORD = 'reset-password',
  UPDATE_PASSWORD = 'update-password',
  VERIFY_EMAIL = 'verify-email',
  VERIFY_PHONE_NUMBER = 'verify-phone-number',
  WELCOME = 'welcome',
}

export enum TfaStatus {
  CODE_MISMATCH = 'TFA:CODE_MISMATCH',
  EMAIL_MISMATCH = 'TFA:EMAIL_MISMATCH',
  EMAIL_NOT_VERIFIED = 'TFA:EMAIL_NOT_VERIFIED',
  EXPIRED = 'TFA:EXPIRED',
  INVALID_EMAIL = 'TFA:INVALID_EMAIL',
  INVALID_PHONE_NUMBER = 'TFA:INVALID_PHONE_NUMBER',
  MESSAGE_FAILED_TO_SEND = 'TFA:MESSAGE_FAILED_TO_SEND',
  MESSAGE_REQUESTED = 'TFA:MESSAGE_REQUESTED',
  MESSAGE_SENT = 'TFA:MESSAGE_SENT',
  MISSING_EMAIL = 'TFA:MISSING_EMAIL',
  MISSING_PHONE_NUMBER = 'TFA:MISSING_PHONE_NUMBER',
  NOT_FOUND = 'TFA:NOT_FOUND',
  PASSED = 'TFA:PASSED',
  PHONE_NUMBER_NOT_VERIFIED = 'TFA:PHONE_NUMBER_NOT_VERIFIED',
  STARTED = 'TFA:STARTED',
  SYSTEM_ERROR = 'TFA:SYSTEM_ERROR',
  USER_NOT_FOUND = 'TFA:USER_NOT_FOUND',
  VERIFYING_CODE = 'verifying-code',
}

export enum ShoppingCartStatus {
  CREATED = 'created',
  ORDER_PLACED = 'order-placed',
  WAITING_FOR_PAYMENT = 'waiting-for-payment',
  CREATING_ORDER = 'creating-order',
  // This is only used for an already deleted shopping cart to deliver as GraphQL parent for a requested order:
  REPLACED_BY_ORDER = 'replaced-by-order',
  CANCELED = 'canceled',
  INVALID = 'invalid',
}

export enum TransactionType {
  CASHBACK = 'cashback',
  CONTRIBUTION = 'contribution',
  GOAL_BOOST = 'goal-boost',
  GOAL_TO_SAVINGS = 'goal-to-savings',
  GOAL_WITHDRAWAL = 'goal-withdrawal',
  INITIAL = 'initial',
  PURCHASE = 'purchase',
  RECURRING = 'recurring',
  REFUND = 'refund',
  CASHBACK_REFUND = 'cashback-refund',
  REFUND_TO_LINKED = 'refund-to-linked',
  REWARD = 'reward',
  SAVINGS_BOOST = 'savings-boost',
  SAVINGS_TO_GOAL = 'savings-to-goal',
  SAVINGS_WITHDRAWAL = 'savings-withdrawal',
  TOKEN_TRANSFER = 'token-transfer',
  TOKEN_DELIVERY = 'token-delivery',
  UNKNOWN = 'unknown',
  USER_FEE = 'user-fee',
  USER_PROMOTION = 'user-promotion',
}

export enum TransactionProcessor {
  BITCOIN_BLOCKCHAIN = 'bitcoin-blockchain',
  COINPAYMENTS = 'coinpayments',
  STRIPE = 'stripe',
  INTERNAL = 'internal',
}

export enum WalletType {
  MIMBLE = 'MIW',
  ORG_MERCHANT = 'OME',
}

export enum WalletUpdateType {
  STATUS_CHANGE = 'status-change',
  BALANCE_CHANGE = 'balance-change',
  MULTIPLE_CHANGES = 'multiple-changes',
}

export enum TokenName {
  MIMBLE_TOKEN = 'MIT',
  // SATOSHI = 'satoshi',
}

export enum FiatCurrencyNames {
  ADP = 'Andorran Peseta',
  AED = 'UAE Dirham',
  AFA = 'Afghani',
  AFN = 'Afghani',
  ALK = 'Old Lek',
  ALL = 'Lek',
  AMD = 'Armenian Dram',
  ANG = 'Netherlands Antillean Guilder',
  AOA = 'Kwanza',
  AOK = 'Kwanza',
  AON = 'New Kwanza',
  AOR = 'Kwanza Reajustado',
  ARA = 'Austral',
  ARP = 'Peso Argentino',
  ARS = 'Argentine Peso',
  ARY = 'Peso',
  ATS = 'Schilling',
  AUD = 'Australian Dollar',
  AWG = 'Aruban Florin',
  AYM = 'Azerbaijan Manat',
  AZM = 'Azerbaijanian Manat',
  AZN = 'Azerbaijan Manat',
  BAD = 'Dinar',
  BAM = 'Convertible Mark',
  BBD = 'Barbados Dollar',
  BDT = 'Taka',
  BEC = 'Convertible Franc',
  BEF = 'Belgian Franc',
  BEL = 'Financial Franc',
  BGJ = 'Lev A/52',
  BGK = 'Lev A/62',
  BGL = 'Lev',
  BGN = 'Bulgarian Lev',
  BHD = 'Bahraini Dinar',
  BIF = 'Burundi Franc',
  BMD = 'Bermudian Dollar',
  BND = 'Brunei Dollar',
  BOB = 'Boliviano',
  BOP = 'Peso boliviano',
  BOV = 'Mvdol',
  BRB = 'Cruzeiro',
  BRC = 'Cruzado',
  BRE = 'Cruzeiro',
  BRL = 'Brazilian Real',
  BRN = 'New Cruzado',
  BRR = 'Cruzeiro Real',
  BSD = 'Bahamian Dollar',
  BTN = 'Ngultrum',
  BUK = 'Kyat',
  BWP = 'Pula',
  BYB = 'Belarusian Ruble',
  BYN = 'Belarusian Ruble',
  BYR = 'Belarusian Ruble',
  BZD = 'Belize Dollar',
  CAD = 'Canadian Dollar',
  CDF = 'Congolese Franc',
  CHC = 'WIR Franc (for electronic)',
  CHE = 'WIR Euro',
  CHF = 'Swiss Franc',
  CHW = 'WIR Franc',
  CLF = 'Unidad de Fomento',
  CLP = 'Chilean Peso',
  CNY = 'Yuan Renminbi',
  COP = 'Colombian Peso',
  COU = 'Unidad de Valor Real',
  CRC = 'Costa Rican Colon',
  CSD = 'Serbian Dinar',
  CSJ = 'Krona A/53',
  CSK = 'Koruna',
  CUC = 'Peso Convertible',
  CUP = 'Cuban Peso',
  CVE = 'Cabo Verde Escudo',
  CYP = 'Cyprus Pound',
  CZK = 'Czech Koruna',
  DDM = 'Mark der DDR',
  DEM = 'Deutsche Mark',
  DJF = 'Djibouti Franc',
  DKK = 'Danish Krone',
  DOP = 'Dominican Peso',
  DZD = 'Algerian Dinar',
  ECS = 'Sucre',
  ECV = 'Unidad de Valor Constante (UVC)',
  EEK = 'Kroon',
  EGP = 'Egyptian Pound',
  ERN = 'Nakfa',
  ESA = 'Spanish Peseta',
  ESP = 'Spanish Peseta',
  ETB = 'Ethiopian Birr',
  EUR = 'Euro',
  FIM = 'Markka',
  FJD = 'Fiji Dollar',
  FKP = 'Falkland Islands Pound',
  FRF = 'French Franc',
  GBP = 'Pound Sterling',
  GEK = 'Georgian Coupon',
  GEL = 'Lari',
  GHC = 'Cedi',
  GHP = 'Ghana Cedi',
  GHS = 'Ghana Cedi',
  GIP = 'Gibraltar Pound',
  GMD = 'Dalasi',
  GNE = 'Syli',
  GNF = 'Guinean Franc',
  GNS = 'Syli',
  GQE = 'Ekwele',
  GRD = 'Drachma',
  GTQ = 'Quetzal',
  GWE = 'Guinea Escudo',
  GWP = 'Guinea-Bissau Peso',
  GYD = 'Guyana Dollar',
  HKD = 'Hong Kong Dollar',
  HNL = 'Lempira',
  HRD = 'Croatian Dinar',
  HRK = 'Croatian Kuna',
  HTG = 'Gourde',
  HUF = 'Forint',
  IDR = 'Rupiah',
  IEP = 'Irish Pound',
  ILP = 'Pound',
  ILR = 'Old Shekel',
  ILS = 'New Israeli Sheqel',
  INR = 'Indian Rupee',
  IQD = 'Iraqi Dinar',
  IRR = 'Iranian Rial',
  ISJ = 'Old Krona',
  ISK = 'Iceland Krona',
  ITL = 'Italian Lira',
  JMD = 'Jamaican Dollar',
  JOD = 'Jordanian Dinar',
  JPY = 'Yen',
  KES = 'Kenyan Shilling',
  KGS = 'Som',
  KHR = 'Riel',
  KMF = 'Comorian Franc',
  KPW = 'North Korean Won',
  KRW = 'Won',
  KWD = 'Kuwaiti Dinar',
  KYD = 'Cayman Islands Dollar',
  KZT = 'Tenge',
  LAJ = 'Pathet Lao Kip',
  LAK = 'Lao Kip',
  LBP = 'Lebanese Pound',
  LKR = 'Sri Lanka Rupee',
  LRD = 'Liberian Dollar',
  LSL = 'Loti',
  LSM = 'Loti',
  LTL = 'Lithuanian Litas',
  LTT = 'Talonas',
  LUC = 'Luxembourg Convertible Franc',
  LUF = 'Luxembourg Franc',
  LUL = 'Luxembourg Financial Franc',
  LVL = 'Latvian Lats',
  LVR = 'Latvian Ruble',
  LYD = 'Libyan Dinar',
  MAD = 'Moroccan Dirham',
  MDL = 'Moldovan Leu',
  MGA = 'Malagasy Ariary',
  MGF = 'Malagasy Franc',
  MKD = 'Denar',
  MLF = 'Mali Franc',
  MMK = 'Kyat',
  MNT = 'Tugrik',
  MOP = 'Pataca',
  MRO = 'Ouguiya',
  MTL = 'Maltese Lira',
  MTP = 'Maltese Pound',
  MUR = 'Mauritius Rupee',
  MVQ = 'Maldive Rupee',
  MVR = 'Rufiyaa',
  MWK = 'Kwacha',
  MXN = 'Mexican Peso',
  MXP = 'Mexican Peso',
  MXV = 'Mexican Unidad de Inversion (UDI)',
  MYR = 'Malaysian Ringgit',
  MZE = 'Mozambique Escudo',
  MZM = 'Mozambique Metical',
  MZN = 'Mozambique Metical',
  NAD = 'Namibia Dollar',
  NGN = 'Naira',
  NIC = 'Cordoba',
  NIO = 'Cordoba Oro',
  NLG = 'Netherlands Guilder',
  NOK = 'Norwegian Krone',
  NPR = 'Nepalese Rupee',
  NZD = 'New Zealand Dollar',
  OMR = 'Rial Omani',
  PAB = 'Balboa',
  PEH = 'Sol',
  PEI = 'Inti',
  PEN = 'Nuevo Sol',
  PES = 'Sol',
  PGK = 'Kina',
  PHP = 'Philippine Piso',
  PKR = 'Pakistan Rupee',
  PLN = 'Zloty',
  PLZ = 'Zloty',
  PTE = 'Portuguese Escudo',
  PYG = 'Guarani',
  QAR = 'Qatari Rial',
  RHD = 'Rhodesian Dollar',
  ROK = 'Leu A/52',
  ROL = 'Old Leu',
  RON = 'Romanian Leu',
  RSD = 'Serbian Dinar',
  RUB = 'Russian Ruble',
  RWF = 'Rwanda Franc',
  SAR = 'Saudi Riyal',
  SBD = 'Solomon Islands Dollar',
  SCR = 'Seychelles Rupee',
  SDD = 'Sudanese Dinar',
  SDG = 'Sudanese Pound',
  SEK = 'Swedish Krona',
  SGD = 'Singapore Dollar',
  SHP = 'Saint Helena Pound',
  SIT = 'Tolar',
  SKK = 'Slovak Koruna',
  SLL = 'Leone',
  SOS = 'Somali Shilling',
  SRD = 'Surinam Dollar',
  SRG = 'Surinam Guilder',
  SSP = 'South Sudanese Pound',
  STD = 'Dobra',
  SUR = 'Rouble',
  SVC = 'El Salvador Colon',
  SYP = 'Syrian Pound',
  SZL = 'Lilangeni',
  THB = 'Baht',
  TJR = 'Tajik Ruble',
  TJS = 'Somoni',
  TMM = 'Turkmenistan Manat',
  TMT = 'Turkmenistan New Manat',
  TND = 'Tunisian Dinar',
  TOP = 'Pa’anga',
  TPE = 'Timor Escudo',
  TRL = 'Old Turkish Lira',
  TRY = 'Turkish Lira',
  TTD = 'Trinidad and Tobago Dollar',
  TWD = 'New Taiwan Dollar',
  TZS = 'Tanzanian Shilling',
  UAH = 'Hryvnia',
  UAK = 'Karbovanet',
  UGS = 'Uganda Shilling',
  UGW = 'Old Shilling',
  UGX = 'Uganda Shilling',
  USD = 'US Dollar',
  UYI = 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
  UYN = 'Old Uruguay Peso',
  UYP = 'Uruguayan Peso',
  UYU = 'Peso Uruguayo',
  UZS = 'Uzbekistan Sum',
  VEB = 'Bolivar',
  VEF = 'Bolivar Fuerte',
  VNC = 'Old Dong',
  VND = 'Dong',
  VUV = 'Vatu',
  WST = 'Tala',
  XAF = 'CFA Franc BEAC',
  XAG = 'Silver',
  XAU = 'Gold',
  XBA = 'Bond Markets Unit European Composite Unit (EURCO)',
  XBB = 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
  XBC = 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
  XBD = 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
  XCD = 'East Caribbean Dollar',
  XDR = 'SDR (Special Drawing Right)',
  XEU = 'European Currency Unit (E.C.U)',
  XFO = 'Gold-Franc',
  XFU = 'UIC-Franc',
  XOF = 'CFA Franc BCEAO',
  XPD = 'Palladium',
  XPF = 'CFP Franc',
  XPT = 'Platinum',
  XRE = 'RINET Funds Code',
  XSU = 'Sucre',
  XTS = 'Codes specifically reserved for testing purposes',
  XUA = 'ADB Unit of Account',
  XXX = 'The codes assigned for transactions where no currency is involved',
  YDD = 'Yemeni Dinar',
  YER = 'Yemeni Rial',
  YUD = 'New Yugoslavian Dinar',
  YUM = 'New Dinar',
  YUN = 'Yugoslavian Dinar',
  ZAL = 'Financial Rand',
  ZAR = 'Rand',
  ZMK = 'Zambian Kwacha',
  ZMW = 'Zambian Kwacha',
  ZRN = 'New Zaire',
  ZRZ = 'Zaire',
  ZWC = 'Rhodesian Dollar',
  ZWD = 'Zimbabwe Dollar',
  ZWL = 'Zimbabwe Dollar',
  ZWN = 'Zimbabwe Dollar (new)',
  ZWR = 'Zimbabwe Dollar',
}

export enum FiatCurrency {
  ADP = 'ADP',
  AED = 'AED',
  AFA = 'AFA',
  AFN = 'AFN',
  ALK = 'ALK',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  AOK = 'AOK',
  AON = 'AON',
  AOR = 'AOR',
  ARA = 'ARA',
  ARP = 'ARP',
  ARS = 'ARS',
  ARY = 'ARY',
  ATS = 'ATS',
  AUD = 'AUD',
  AWG = 'AWG',
  AYM = 'AYM',
  AZM = 'AZM',
  AZN = 'AZN',
  BAD = 'BAD',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BEC = 'BEC',
  BEF = 'BEF',
  BEL = 'BEL',
  BGJ = 'BGJ',
  BGK = 'BGK',
  BGL = 'BGL',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOP = 'BOP',
  BOV = 'BOV',
  BRB = 'BRB',
  BRC = 'BRC',
  BRE = 'BRE',
  BRL = 'BRL',
  BRN = 'BRN',
  BRR = 'BRR',
  BSD = 'BSD',
  BTN = 'BTN',
  BUK = 'BUK',
  BWP = 'BWP',
  BYB = 'BYB',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHC = 'CHC',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CSD = 'CSD',
  CSJ = 'CSJ',
  CSK = 'CSK',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CYP = 'CYP',
  CZK = 'CZK',
  DDM = 'DDM',
  DEM = 'DEM',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  ECS = 'ECS',
  ECV = 'ECV',
  EEK = 'EEK',
  EGP = 'EGP',
  ERN = 'ERN',
  ESA = 'ESA',
  ESP = 'ESP',
  ETB = 'ETB',
  EUR = 'EUR',
  FIM = 'FIM',
  FJD = 'FJD',
  FKP = 'FKP',
  FRF = 'FRF',
  GBP = 'GBP',
  GEK = 'GEK',
  GEL = 'GEL',
  GHC = 'GHC',
  GHP = 'GHP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNE = 'GNE',
  GNF = 'GNF',
  GNS = 'GNS',
  GQE = 'GQE',
  GRD = 'GRD',
  GTQ = 'GTQ',
  GWE = 'GWE',
  GWP = 'GWP',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRD = 'HRD',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  IEP = 'IEP',
  ILP = 'ILP',
  ILR = 'ILR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISJ = 'ISJ',
  ISK = 'ISK',
  ITL = 'ITL',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAJ = 'LAJ',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LSM = 'LSM',
  LTL = 'LTL',
  LTT = 'LTT',
  LUC = 'LUC',
  LUF = 'LUF',
  LUL = 'LUL',
  LVL = 'LVL',
  LVR = 'LVR',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MGF = 'MGF',
  MKD = 'MKD',
  MLF = 'MLF',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MTL = 'MTL',
  MTP = 'MTP',
  MUR = 'MUR',
  MVQ = 'MVQ',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXP = 'MXP',
  MXV = 'MXV',
  MYR = 'MYR',
  MZE = 'MZE',
  MZM = 'MZM',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIC = 'NIC',
  NIO = 'NIO',
  NLG = 'NLG',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEH = 'PEH',
  PEI = 'PEI',
  PEN = 'PEN',
  PES = 'PES',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PLZ = 'PLZ',
  PTE = 'PTE',
  PYG = 'PYG',
  QAR = 'QAR',
  RHD = 'RHD',
  ROK = 'ROK',
  ROL = 'ROL',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDD = 'SDD',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SIT = 'SIT',
  SKK = 'SKK',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SRG = 'SRG',
  SSP = 'SSP',
  STD = 'STD',
  SUR = 'SUR',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJR = 'TJR',
  TJS = 'TJS',
  TMM = 'TMM',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TPE = 'TPE',
  TRL = 'TRL',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UAK = 'UAK',
  UGS = 'UGS',
  UGW = 'UGW',
  UGX = 'UGX',
  USD = 'USD',
  UYI = 'UYI',
  UYN = 'UYN',
  UYP = 'UYP',
  UYU = 'UYU',
  UZS = 'UZS',
  VEB = 'VEB',
  VEF = 'VEF',
  VNC = 'VNC',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XBA = 'XBA',
  XBB = 'XBB',
  XBC = 'XBC',
  XBD = 'XBD',
  XCD = 'XCD',
  XDR = 'XDR',
  XEU = 'XEU',
  XFO = 'XFO',
  XFU = 'XFU',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  XRE = 'XRE',
  XSU = 'XSU',
  XTS = 'XTS',
  XUA = 'XUA',
  XXX = 'XXX',
  YDD = 'YDD',
  YER = 'YER',
  YUD = 'YUD',
  YUM = 'YUM',
  YUN = 'YUN',
  ZAL = 'ZAL',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZRN = 'ZRN',
  ZRZ = 'ZRZ',
  ZWC = 'ZWC',
  ZWD = 'ZWD',
  ZWL = 'ZWL',
  ZWN = 'ZWN',
  ZWR = 'ZWR',
}

export enum ModelEventType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum MerchantListScope {
  LISTED = 'listed',
  UNLISTED = 'unlisted',
}

export enum MeteredRequestType {
  CHECK_EMAIL_AVAILABILITY = 'checkEmailAvailability',
  CHECK_PHONE_NUMBER_AVAILABILITY = 'checkPhoneNumberAvailability',
  LOOKUP_POSTAL_CODE = 'lookupPostalCode',
  RESET_PASSWORD = 'resetPassword',
  RESET_USER_PIN = 'resetUserPin',
  SIGN_IN = 'signIn',
  SIGN_IN_WITH_SMS = 'signInWithSms',
  SIGN_UP = 'signUp',
  SIGN_UP_VERIFY_PHONE_NUMBER = 'signUpVerifyPhone',
  UPDATE_PASSWORD = 'updatePassword',
  UPDATE_PHONE_NUMBER = 'updatePhoneNumber',
  UPDATE_USER_PIN = 'updateUserPin',
  VALIDATE_INVITE_CODE = 'validateInviteCode',
  VERIFY_USER_PIN = 'verifyUserPin',
  VERIFY_EMAIL = 'verifyEmail',
  VERIFY_PHONE_NUMBER = 'verifyPhoneNumber',
}

export enum ModelUpdateEventStatus {
  IN_PROGRESS = 'p',
  SUCCEEDED = 's',
  FAILED = 'f',
}

export enum PurchaseListScope {
  PENDING = 'pending',
  ACTIVE = 'active',
  TRANSFERRED = 'transferred',
  ARCHIVED = 'archived',
  FAVORITES = 'favorites',
  SENT = 'sent',
  RECEIVED = 'received',
  TRANSFERRABLE = 'transferrable',
}

export enum PurchaseTagType {
  RECORD_STATUS = 'record-status',
  PURCHASE_STATUS = 'valid-status',
}

// This supports the "sign-in-as" feature.
// With both "sign-in-as" both active and inactive, the activeUser is the user reflected in the app
// "sign-in-as" is active:
//   activeUser = proxyUser
//   authUser = the admin that initiated sign-in-as
// "sign-in-as" is inactive:
//   activeUser = authUser
//   authUser = the one and only user
//   proxyUser = undefined
export enum ClientUserType {
  ACTIVE = 'active',
  AUTHENTICATED = 'authenticated',
  PROXY = 'proxy',
}

export enum StoredProductListScope {
  CREATED = 'created',
  AVAILABLE = 'available',
  RESERVED = 'reserved',
  DELIVERED = 'delivered',
}

export enum UiMessage {
  CANCEL_REQUEST_RECEIVED = 'cancel-request-received',
  CHANGES_SAVED_SUCCESSFULLY = 'changes-saved-successfully',
  CURRENT_PASSWORD_INCORRECT = 'current-password-incorrect',
  DATA_DELETED_SUCCESSFULLY = 'data-deleted-successfully',
  DATA_UPDATED_SUCCESSFULLY = 'data-saved-successfully',
  EMAIL_UPDATED_SUCCESSFULLY = 'email-updated-successfully',
  ERROR_ACCEPTING_GIFT = 'error-accepting-gift',
  ERROR_CONNECTING = 'error-connecting',
  ERROR_DATA_NOT_FOUND = 'error-data-not-found',
  ERROR_LOCATING_REQUEST = 'error-locating-request',
  ERROR_PROCESSING_PAYMENT = 'error-processing-payment',
  ERROR_PROCESSING_PURCHASE = 'error-processing-purchase',
  ERROR_PROCESSING_REQUEST = 'error-processing-request',
  ERROR_RECEIVED_GIFT_NOT_AVAILABLE = 'received-gift-not-available',
  ERROR_SAVING_CHANGES = 'error-saving-changes',
  ERROR_SENDING_GIFT_CARD = 'error-sending-gift-card',
  ERROR_SENDING_NOTIFICATION = 'error-sending-notification',
  ERROR_SIGNIN_TOKEN = 'error-signin-token',
  ERROR_SIGNING_IN = 'error-signing-in',
  ERROR_SIGNING_OUT = 'error-signing-out',
  ERROR_UPDATING_PRODUCT = 'error-updating-product',
  ERROR_UPLOADING_GIFT_CARD = 'error-uploading-gift-card',
  FAILED_TO_SIGN_IN_THROUGH_TRANSFER = 'failed-to-sign-in-through-transfer',
  GIFT_CARD_NOT_FOUND = 'gift-card-not-found',
  NEW_APP_VERSION_AVAILABLE = 'new-app-version-available',
  PASSWORD_UPDATED_SUCCESSFULLY = 'password-updated-successfully',
  PHONE_NUMBER_UPDATED_SUCCESSFULLY = 'phone-number-updated-successfully',
  TRANSACTION_SAVED_SUCCESSFULLY = 'transaction-updated-successfully',
  TRANSFER_NOT_FOUND = 'transfer-not-found',
  TRANSFER_NOT_VALID = 'transfer-not-valid',
  UNKNOWN = 'unknown',
  USERNAME_UPDATED_SUCCESSFULLY = 'username-updated-successfully',
}

export enum UserRole {
  ADMIN = 'admin',
  MARKETPLACE_EDITOR = 'marketplace',
  MERCHANT = 'merchant',
  MIMBLE_ORG = 'mimble-org',
  STAFF = 'staff',
  QA = 'qa', // person is a staff member working in QA
  TEST = 'test', // a test account
}

export enum WebSocketClientCommand {
  OPEN_CONNECTION = 'open-connection',
  AUTHENTICATE = 'authenticate',
}

export enum WebSocketServerCommand {
  ASYNC_TASK_UPDATED = 'async-task-updated',
  MODEL_UPDATED = 'model-updated',
}
