import React, { useState } from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import { alertCircleOutline, closeOutline } from 'ionicons/icons'

import './styles.css'
import { FiatCurrency, FundType, TokenName } from '../../../../lib/core/enums'
import { useMimbleData } from '../../../../contexts/MimbleDataContext/MimbleDataContext'
import coreHelpers from '../../../../lib/core/helpers'
import FormItem from '../../../FormItem/FormItem'
import IconButton from '../../../IconButton/IconButton'
import SubmitButton from '../../../SubmitButton/SubmitButton'
import validationHelpers from '../../../../helpers/validationHelpers'

type Props = {
  onClose: () => void;
  onSubmit: (amount: number) => void;
}

const TokenTransferForm: React.FC<Props> = (props): JSX.Element | null => {
  const { onClose, onSubmit } = props
  // ===================================================================================================================
  // State:
  const { getActiveUserMimbleWallet } = useMimbleData()
  const mimbleWallet = getActiveUserMimbleWallet && getActiveUserMimbleWallet()
  const tokenBalance = mimbleWallet ? mimbleWallet.balanceToken : 0
  const tokenBalanceInFiat = coreHelpers.cryptoCurrency.convertMimbleTokenToFiat(tokenBalance || 0)
  const formattedTokenBalance = coreHelpers.ui.formatAmount(
    tokenBalance,
    FundType.TOKEN,
    TokenName.MIMBLE_TOKEN,
    true,
  )
  const formattedTokenBalanceInFiat = coreHelpers.ui.formatAmount(
    tokenBalanceInFiat,
    FundType.FIAT,
    FiatCurrency.USD,
    true,
  )

  const [amount, setAmount] = useState<number | undefined>()
  const [amountValidationError, setAmountValidationError] = useState<string | undefined>()
  const amountInFiat = coreHelpers.cryptoCurrency.convertMimbleTokenToFiat(amount || 0)
  const formattedAmount = coreHelpers.ui.getDisplayMoneyAmount(
    amount, FundType.TOKEN, TokenName.MIMBLE_TOKEN,
  )
  const formattedAmountInFiat = coreHelpers.ui.formatAmount(
    amountInFiat, FundType.FIAT, FiatCurrency.USD, true, 99,
  )

  // ===================================================================================================================
  // Event Handlers:
  const onChangeAmount = (event: any): void => {
    const internalValue = coreHelpers.ui.getInternalMoneyAmount(
      parseFloat(event.detail.value), FundType.TOKEN, TokenName.MIMBLE_TOKEN)
    setAmount(internalValue)
    if (internalValue) {
      let validationError = validationHelpers.validateTransactionAmount(internalValue)
      if (
        !validationError &&
        tokenBalance &&
        internalValue > tokenBalance
      ) {
        validationError = 'This exceeds the MIT balance of your wallet'
      }
      setAmountValidationError(validationError)
    } else {
      setAmountValidationError(undefined)
    }
  }

  const handleSubmit = (event: any): void => {
    event.preventDefault()
    if (!amount) {
      return
    }
    onSubmit(amount)
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='tokens-transfer-form'>
      <div className='caption-row'>
        <div className='withStandardPadding lightText'>
          Attach Mimble Tokens (MITs)
        </div>
        <IconButton
          icon={closeOutline}
          layout='vertical'
          className='close-button'
          iconSize={20}
          onClick={onClose}
        />
      </div>
      <div className='withStandardPadding'>
        <div className='withStandardBottomMargin smallText lightText'>
          You have <span className='withBoldText'>{formattedTokenBalance}</span>&nbsp;
          (<span className='withBoldText'>{formattedTokenBalanceInFiat}</span>) in your wallet.
        </div>
        <div className='rowWithCenterAlignedItems'>
          <div style={{ flex: 1 }}>
            <FormItem
              label='Amount in MIT'
              validationError={amountValidationError}
            >
              <IonInput
                onIonChange={onChangeAmount}
                type='number'
                inputmode='decimal'
                // minVal={0}
                placeholder='Amount'
                value={formattedAmount ? formattedAmount.toString() : ''}
              />
            </FormItem>
            <div className='smallText lightText'>
              Equals <span className='withBoldText'>{formattedAmountInFiat}</span>
            </div>
          </div>
          <SubmitButton
            size='small'
            disabled={!amount || !!amountValidationError}
            className='withStandardLeftMargin'
            onClick={handleSubmit}
          >
            Add
          </SubmitButton>
        </div>
        <div className='smallText lightText withStandardTopMargin'>
          <IonIcon icon={alertCircleOutline} className='withSmallRightMargin' />
          Note: Once you send the message with the MITs attached the funds
          are transferred from your wallet to the recipient&apos;s wallet.
        </div>
      </div>
    </div>
  )
}

export default TokenTransferForm
