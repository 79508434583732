import React, { useEffect, useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'

import './styles.css'
import { AppRoute } from '../../../enums'
import type { FilterRewardType, RewardListItem } from '../../../lib/core/definitions'
import type { RewardsQueryData, RewardsQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import FilterSelect from './FilterSelect'
import RewardGrid from './RewardGrid'

type Props = {
  refreshIndex: number
  onNavigate: (route: AppRoute | string) => void
  showUiMessage: (message: string) => void
  onBuyTokens: () => void
  onFinishedLoading: () => void
}

const RewardsTab: React.FC<Props> = (props): JSX.Element | null => {
  const {
    refreshIndex,
    onBuyTokens,
    onNavigate,
    showUiMessage,
    onFinishedLoading,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser, rewardConfigs, isLoadingRewardConfigs, getRewardConfigs } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [filterRewardType, setFilterRewardType] = useState<FilterRewardType | 'all'>()

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: userRewardsData,
    refetch: reloadRewards,
  } = useQuery<RewardsQueryData, RewardsQueryVariables>(
    apollo.queries.rewards, {
      variables: {
        filter: {
          userId: activeUserId as string,
          orderBy: 'createdAt',
          orderByDirection: 'desc',
        },
      },
      skip: !activeUserId,
      notifyOnNetworkStatusChange: true,
      onCompleted: onFinishedLoading,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
    },
  )
  let filteredRewards: RewardListItem[] | undefined
  if (userRewardsData && Array.isArray(userRewardsData.rewards) && userRewardsData.rewards.length > 0) {
    filteredRewards = userRewardsData.rewards.filter((r) => {
      if (!filterRewardType || filterRewardType === 'all') {
        return true
      }
      if (r.rewardType !== filterRewardType.rewardType) {
        return false
      }
      if (
        filterRewardType.occurrence !== null &&
        filterRewardType.occurrence !== undefined &&
        filterRewardType.occurrence > 0 &&
        filterRewardType.occurrence !== r.occurrence
      ) {
        return false
      }
      return (
        filterRewardType.invitationLevel === null ||
        filterRewardType.invitationLevel === undefined ||
        filterRewardType.invitationLevel === r.invitationLevel
      )
    })
  }

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (refreshIndex > 0 && reloadRewards) {
      reloadRewards()
    }
  }, [refreshIndex])

  useEffect(() => {
    if (!rewardConfigs) {
      getRewardConfigs()
    }
  }, [rewardConfigs])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeFilterRewardType = (filter: FilterRewardType | 'all'): void => {
    setFilterRewardType(filter)
  }

  const onOpenReward = (rewardId: string): void => {
    api.loadReward(
      rewardId,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then((reward) => {
      if (reward) {
        onNavigate(`${AppRoute.REWARD}/${rewardId}`)
      }
    }, (error) => {
      console.error(error)
    })
  }

  // ===================================================================================================================
  // Rendering:
  if (!activeUser) {
    return null
  }

  return (
    <div className='rewards-tab'>
      <FilterSelect
        rewards={userRewardsData && userRewardsData.rewards}
        rewardConfigs={rewardConfigs}
        isLoadingRewardConfigs={isLoadingRewardConfigs}
        onChange={onChangeFilterRewardType}
      />
      <RewardGrid
        rewards={filteredRewards}
        onOpenReward={onOpenReward}
      />
    </div>
  )
}

export default RewardsTab
