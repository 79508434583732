import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import './styles.css'
import { cart, cloudUpload, server } from 'ionicons/icons'

type Props = {
  showWelcomeMessage: boolean;
  onAddUploadedPurchase: () => void;
  onCreatePurchase?: () => void;
  onOpenEarnRewards?: () => void;
};

const EmptyWalletContent: React.FC<Props> = (props): JSX.Element => {
  const {
    showWelcomeMessage,
    onAddUploadedPurchase,
    onCreatePurchase,
    onOpenEarnRewards,
  } = props

  let introSection: JSX.Element | undefined
  if (showWelcomeMessage) {
    introSection = (
      <div className='withTripleBottomMargin'>
        <div className='withCenteredColumnContent'>
          <h1>Welcome to Mimble!</h1>
        </div>
        Mimble is a social gifting platform that makes gifting fun, fast, easy,
        and allows you to keep all your gifts organized in one convenient place.
      </div>
    )
  } else {
    introSection = (
      <div className='withDoubleTopMargin withTripleBottomMargin'>
        You currently have no active gifts in your wallet.
      </div>
    )
  }

  let marketplaceSection
  if (onCreatePurchase) {
    marketplaceSection = (
      <div className='withTripleBottomMargin'>
        <div className='lightText withStandardBottomMargin'>
          Need a gift?
        </div>
        <div className='rowWithCenterAlignedItems'>
          <IonButton
            className='withStandardRightMargin'
            onClick={onCreatePurchase}
          >
            <IonIcon icon={cart} />
          </IonButton>
          <div onClick={onCreatePurchase}>
            Head over to Mimble&apos;s marketplace, buy a gift and get rewarded!
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='withStandardMargin withCenterAlignedItems'>
      {introSection}
      <div className='withStandardMargin'>
        {marketplaceSection}
        <div className='withTripleBottomMargin'>
          <div className='lightText withStandardBottomMargin'>
            Have gift cards lying around?
          </div>
          <div className='rowWithCenterAlignedItems'>
            <IonButton
              className='withStandardRightMargin'
              onClick={onAddUploadedPurchase}
            >
              <IonIcon icon={cloudUpload} />
            </IonButton>
            <div onClick={onAddUploadedPurchase}>
              Upload them into your wallet and always
              have them easily available when you need them most - when you are shopping!
            </div>
          </div>
        </div>
        <div>
          <div className='lightText withStandardBottomMargin'>
            Earn Rewards!
          </div>
          <div className='rowWithCenterAlignedItems'>
            <IonButton
              className='withStandardRightMargin'
              onClick={onOpenEarnRewards}
            >
              <IonIcon icon={server} />
            </IonButton>
            <div onClick={onOpenEarnRewards}>
              When you invite friends that join Mimble, or you buy or send gift cards
              you will earn rewards (MIT) that you can then redeem for more gifts!
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyWalletContent
