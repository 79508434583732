import React, { useState } from 'react'
import { alertCircleOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

import './styles.css'
import ChipButton, { ChipButtonType } from '../ChipButton/ChipButton'

type Props = {
  messageElement: JSX.Element
  showMoreElement?: JSX.Element
  className?: string
  show?: boolean
  onChangeShowMore?: (showMore: boolean) => void
}

const WarningBlock: React.FC<Props> = (props): JSX.Element | null => {
  const {
    messageElement,
    showMoreElement,
    className,
    show,
    onChangeShowMore,
  } = props

  const [isShowingMore, setIsShowingMore] = useState(false)

  const toggleIsShowingMore = () => {
    setIsShowingMore(!isShowingMore)
    if (onChangeShowMore) {
      onChangeShowMore(!isShowingMore)
    }
  }

  if (show === false) {
    return null
  }

  let expandedSection: JSX.Element | undefined
  if (isShowingMore) {
    expandedSection = (
      <>
        <ChipButton
          type={ChipButtonType.SHOW_MORE}
          rightAligned
          selected
          className='withSmallTopMargin withStandardBottomMargin'
          onClick={toggleIsShowingMore}
        />
        {showMoreElement}
      </>
    )
  } else if (showMoreElement) {
    expandedSection = (
      <ChipButton
        type={ChipButtonType.SHOW_MORE}
        rightAligned
        className='withSmallTopMargin'
        onClick={toggleIsShowingMore}
      />
    )
  }

  return (
    <div className={`warning-block ${className || ''}`}>
      <div className='rowWithTopAlignedItems'>
        <IonIcon
          icon={alertCircleOutline}
          className='warn-icon'
        />
        {messageElement}
      </div>
      {expandedSection}
    </div>
  )
}

export default WarningBlock
