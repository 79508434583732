import React, { useEffect, useRef, useState } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { IonInput, useIonViewDidEnter } from '@ionic/react'
import type { CreateTokenCardData, StripeCardNumberElement } from '@stripe/stripe-js'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import type { ShoppingCart, UserSensitiveInfo, UserSensitiveInfoInput } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import FormCheckbox from '../../../components/FormCheckbox/FormCheckbox'
import FormItem from '../../../components/FormItem/FormItem'
import pageHelpers from '../../../helpers/pageHelpers'
import SubmitSection from '../SubmitSection'
import validationHelpers from '../../../helpers/validationHelpers'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

type Props = {
  shoppingCart: ShoppingCart | undefined
  paymentErrorMessage: string | undefined
  isActiveShoppingCart: boolean | undefined
  isSavingShoppingCart: boolean;
  isOrderPlaced: boolean;
  onClearShoppingCart: () => void
  onDeletePendingOrder: () => void
  onPlaceOrder: (paymentMethodToken: string) => void;
}

const CreditCardForm: React.FC<Props> = (props): JSX.Element | null => {
  const {
    shoppingCart,
    paymentErrorMessage,
    isActiveShoppingCart,
    isSavingShoppingCart,
    isOrderPlaced,
    onClearShoppingCart,
    onDeletePendingOrder,
    onPlaceOrder,
  } = props

  // ===================================================================================================================
  // State
  const apolloClient = useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const stripe = useStripe()
  const elements = useElements()

  const [userSensitiveInfo, setUserSensitiveInfo] = useState<UserSensitiveInfo | undefined>()

  const [fullName, setFullName] = useState<string | undefined>()
  const [fullNameValidationError, setFullNameValidationError] = useState<string | undefined>()
  const [enableValidationForFullName, setEnableValidationForFullName] = useState(false)
  const fullNameRef = useRef<HTMLIonInputElement | null>(null)

  const [addressLine1, setAddressLine1] = useState<string | undefined>()
  const [addressLine1ValidationError, setAddressLine1ValidationError] = useState<string | undefined>()
  const [enableValidationForAddressLine1, setEnableValidationForAddressLine1] = useState(false)
  const addressLine1Ref = useRef<HTMLIonInputElement | null>(null)

  const [addressLine2, setAddressLine2] = useState<string | null | undefined>()
  const [addressLine2ValidationError, setAddressLine2ValidationError] = useState<string | undefined>()
  const [enableValidationForAddressLine2, setEnableValidationForAddressLine2] = useState(false)
  const addressLine2Ref = useRef<HTMLIonInputElement | null>(null)

  const [city, setCity] = useState<string | undefined>()
  const [cityValidationError, setCityValidationError] = useState<string | undefined>()
  const [enableValidationForCity, setEnableValidationForCity] = useState(false)
  const cityRef = useRef<HTMLIonInputElement | null>(null)

  const [regionCode, setRegionCode] = useState<string | undefined>()
  const [regionCodeValidationError, setRegionCodeValidationError] = useState<string | undefined>()
  const [enableValidationForRegionCode, setEnableValidationForRegionCode] = useState(false)
  const regionCodeRef = useRef<HTMLIonInputElement | null>(null)

  const [postalCode, setPostalCode] = useState<string | undefined>()
  const [postalCodeValidationError, setPostalCodeValidationError] = useState<string | undefined>()
  const [enableValidationForPostalCode, setEnableValidationForPostalCode] = useState(false)
  const postalCodeRef = useRef<HTMLIonInputElement | null>(null)

  const [countryCode, setCountryCode] = useState('US')
  const [countryCodeValidationError, setCountryCodeValidationError] = useState<string | undefined>()
  const [enableValidationForCountryCode, setEnableValidationForCountryCode] = useState(false)
  const countryCodeRef = useRef<HTMLIonInputElement | null>(null)

  const [saveSensitiveInfo, setSaveSensitiveInfo] = useState(true)
  const [showPaymentErrorMessage, setShowPaymentErrorMessage] = useState(false)

  const [ccNumberIsComplete, setCcNumberIsComplete] = useState(false)
  const [ccNumberValidationError, setCcNumberValidationError] = useState<string | undefined>()

  const [ccExpiresAtIsComplete, setCcExpiresAtIsComplete] = useState<string | undefined>()
  const [ccExpiresAtValidationError, setCcExpiresAtValidationError] = useState<string | undefined>()

  const [ccCvcIsComplete, setCcCvcIsComplete] = useState<string | undefined>()
  const [ccCvcValidationError, setCcCvcValidationError] = useState<string | undefined>()

  // const creditCardNumberRef = useRef<HTMLIonInputElement | null>(null)
  const [autoFillInputs, setAutoFillInputs] = useState<string[]>([])

  const fullNameFormValue = fullName || pageHelpers.getFormInputValue(fullNameRef)
  const isFullNameDirty = (
    !!fullNameFormValue &&
    (!userSensitiveInfo || userSensitiveInfo.ccNameOnCard !== fullNameFormValue)
  )
  const addressLine1FormValue = addressLine1 || pageHelpers.getFormInputValue(addressLine1Ref)
  const isAddressLine1Dirty = (
    !!addressLine1FormValue &&
    (!userSensitiveInfo || userSensitiveInfo.ccAddressLine1 !== addressLine1FormValue)
  )
  const addressLine2FormValue = addressLine2 || addressLine2 === null
    ? addressLine2
    : pageHelpers.getFormInputValue(addressLine2Ref)
  const isAddressLine2Dirty = (
    !!addressLine2FormValue &&
    (!userSensitiveInfo || userSensitiveInfo.ccAddressLine2 !== addressLine2FormValue)
  )
  const cityFormValue = city || pageHelpers.getFormInputValue(cityRef)
  const isCityDirty = (
    !!cityFormValue &&
    (!userSensitiveInfo || userSensitiveInfo.ccCity !== cityFormValue)
  )
  const regionCodeFormValue = regionCode || pageHelpers.getFormInputValue(regionCodeRef)
  const isRegionCodeDirty = (
    !!regionCodeFormValue &&
    (!userSensitiveInfo || userSensitiveInfo.ccRegionCode !== regionCodeFormValue)
  )
  const postalCodeFormValue = postalCode || pageHelpers.getFormInputValue(postalCodeRef)
  const isPostalCodeDirty = (
    !!postalCodeFormValue &&
    (!userSensitiveInfo || userSensitiveInfo.ccPostalCode !== postalCodeFormValue)
  )
  const countryCodeFormValue = countryCode || pageHelpers.getFormInputValue(countryCodeRef)

  const isCustomerInfoFormDirty = (
    isFullNameDirty ||
    isAddressLine1Dirty ||
    isAddressLine2Dirty ||
    isCityDirty ||
    isRegionCodeDirty ||
    isPostalCodeDirty
  )

  const isValid = (
    (!!fullNameFormValue || autoFillInputs.includes('fullName')) &&
    (!!addressLine1FormValue || autoFillInputs.includes('addressLine1')) &&
    (!!cityFormValue || autoFillInputs.includes('city')) &&
    (!!regionCodeFormValue || autoFillInputs.includes('regionCode')) &&
    (!!postalCodeFormValue || autoFillInputs.includes('postalCode')) &&
    (!!countryCodeFormValue || autoFillInputs.includes('countryCode')) &&
    ccNumberIsComplete &&
    ccExpiresAtIsComplete &&
    ccCvcIsComplete &&
    !fullNameValidationError &&
    !addressLine1ValidationError &&
    !addressLine2ValidationError &&
    !cityValidationError &&
    !regionCodeValidationError &&
    !postalCodeValidationError &&
    !countryCodeValidationError &&
    !ccNumberValidationError &&
    !ccExpiresAtValidationError &&
    !ccCvcValidationError
  )
  // console.log('isValid=', {
  //   isValid,
  //   fullNameFormValue,
  //   addressLine1FormValue,
  //   addressLine2FormValue,
  //   cityFormValue,
  //   regionCodeFormValue,
  //   postalCodeFormValue,
  //   ccNumberIsComplete,
  //   ccExpiresAtIsComplete,
  //   ccCvcIsComplete,
  //   fullNameValidationError,
  //   addressLine1ValidationError,
  //   addressLine2ValidationError,
  //   cityValidationError,
  //   regionCodeValidationError,
  //   postalCodeValidationError,
  //   countryCodeValidationError,
  //   ccNumberValidationError,
  //   ccExpiresAtValidationError,
  //   ccCvcValidationError,
  // })

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewDidEnter(() => {
    pageHelpers.setAutoFillStatus([
      'fullName',
      'addressLine1',
      'addressLine2',
      'city',
      'regionCode',
      'postalCode',
      'countryCode',
    ], setAutoFillInputs)
  })

  useEffect(() => {
    if (activeUser && activeUser.id) {
      api.loadUserSensitiveInfo(
        activeUser.id,
        undefined,
        apolloClient,
      ).then((userSensitiveInfo) => {
        if (!userSensitiveInfo) {
          return
        }
        setUserSensitiveInfo(userSensitiveInfo)
      })
    }
  }, [activeUser])

  useEffect(() => {
    if (paymentErrorMessage && !showPaymentErrorMessage) {
      setShowPaymentErrorMessage(true)
    }
  }, [paymentErrorMessage])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeFullName = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(fullNameRef)
    if (val) {
      setFullName(val)
      if (enableValidationForFullName) {
        setFullNameValidationError(
          val
            ? validationHelpers.validateUserFullName(val)
            : 'required',
        )
      } else {
        setTimeout(() => {
          setEnableValidationForFullName(true)
        }, 4000)
      }
    }
    setShowPaymentErrorMessage(false)
  }

  const onBlurFullName = () => {
    const val = fullName || pageHelpers.getFormInputValue(fullNameRef)
    if (val !== undefined && !enableValidationForFullName) {
      setEnableValidationForFullName(true)
      setFullNameValidationError(
        val
          ? validationHelpers.validateUserFullName(val)
          : 'required',
      )
    }
  }

  const onChangeAddressLine1 = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(addressLine1Ref)
    if (val) {
      setAddressLine1(val)
      if (enableValidationForAddressLine1) {
        setAddressLine1ValidationError(
          val
            ? validationHelpers.validateStreetAddress(val)
            : 'required',
        )
      } else {
        setTimeout(() => {
          setEnableValidationForAddressLine1(true)
        }, 4000)
      }
    }
    setShowPaymentErrorMessage(false)
  }

  const onBlurAddressLine1 = () => {
    const val = addressLine1 || pageHelpers.getFormInputValue(addressLine1Ref)
    if (val !== undefined && !enableValidationForAddressLine1) {
      setEnableValidationForAddressLine1(true)
      setAddressLine1ValidationError(
        val
          ? validationHelpers.validateStreetAddress(val)
          : 'required',
      )
    }
  }

  const onChangeAddressLine2 = (event: any): void => {
    let val = event.detail.value || null
    const formInputVal = pageHelpers.getFormInputValue(addressLine2Ref)
    if (!val && formInputVal) {
      val = formInputVal
    }
    setAddressLine2(val)
    if (val) {
      if (enableValidationForAddressLine2) {
        setAddressLine2ValidationError(
          val
            ? validationHelpers.validateStreetAddress(val)
            : 'required',
        )
      } else {
        setTimeout(() => {
          setEnableValidationForAddressLine2(true)
        }, 4000)
      }
    } else {
      setAddressLine2ValidationError(undefined)
    }
    setShowPaymentErrorMessage(false)
  }

  const onBlurAddressLine2 = () => {
    const val = addressLine2 || pageHelpers.getFormInputValue(addressLine2Ref)
    if (val !== undefined && !enableValidationForAddressLine2) {
      setEnableValidationForAddressLine2(true)
      setAddressLine2ValidationError(
        val
          ? validationHelpers.validateStreetAddress(val)
          : 'required',
      )
    }
  }

  const onChangeCity = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(cityRef)
    if (val) {
      setCity(val)
      if (enableValidationForCity) {
        setCityValidationError(
          val
            ? validationHelpers.validateCity(val)
            : 'required',
        )
      } else {
        setTimeout(() => {
          setEnableValidationForCity(true)
        }, 4000)
      }
    }
    setShowPaymentErrorMessage(false)
  }

  const onBlurCity = () => {
    const val = city || pageHelpers.getFormInputValue(cityRef)
    if (val !== undefined && !enableValidationForCity) {
      setEnableValidationForCity(true)
      setCityValidationError(
        val
          ? validationHelpers.validateCity(val)
          : 'required',
      )
    }
  }

  const onChangeRegionCode = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(regionCodeRef)
    if (val) {
      setRegionCode(val.toUpperCase())
      if (enableValidationForRegionCode) {
        setRegionCodeValidationError(
          val
            ? validationHelpers.validateRegionCode(val, countryCode)
            : 'required',
        )
      } else {
        setTimeout(() => {
          setEnableValidationForRegionCode(true)
        }, 4000)
      }
    }
    setShowPaymentErrorMessage(false)
  }

  const onBlurRegionCode = () => {
    const val = regionCode || pageHelpers.getFormInputValue(regionCodeRef)
    if (val !== undefined && !enableValidationForRegionCode) {
      setEnableValidationForRegionCode(true)
      setRegionCodeValidationError(
        val
          ? validationHelpers.validateRegionCode(val, countryCode)
          : 'required',
      )
    }
  }

  const onChangePostalCode = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(postalCodeRef)
    if (val) {
      setPostalCode(val.toUpperCase())
      if (enableValidationForPostalCode) {
        setPostalCodeValidationError(
          val
            ? validationHelpers.validatePostalCode(val)
            : 'required',
        )
      } else {
        setTimeout(() => {
          setEnableValidationForPostalCode(true)
        }, 4000)
      }
    }
    setShowPaymentErrorMessage(false)
  }

  const onBlurPostalCode = () => {
    const val = postalCode || pageHelpers.getFormInputValue(postalCodeRef)
    if (val !== undefined && !enableValidationForPostalCode) {
      setEnableValidationForPostalCode(true)
      setPostalCodeValidationError(
        val
          ? validationHelpers.validatePostalCode(val)
          : 'required',
      )
    }
  }

  const onChangeCountryCode = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(countryCodeRef)
    if (val) {
      setCountryCode(val.toUpperCase())
      if (enableValidationForCountryCode) {
        setCountryCodeValidationError(
          val
            ? validationHelpers.validateCountryCode(val)
            : 'required',
        )
      } else {
        setTimeout(() => {
          setEnableValidationForCountryCode(true)
        }, 4000)
      }
    }
    setShowPaymentErrorMessage(false)
  }

  const onBlurCountryCode = () => {
    const val = countryCode || pageHelpers.getFormInputValue(countryCodeRef)
    if (val !== undefined && !enableValidationForCountryCode) {
      setEnableValidationForCountryCode(true)
      setCountryCodeValidationError(
        val
          ? validationHelpers.validateCountryCode(val)
          : 'required',
      )
    }
  }

  const onChangeCcNumber = (event: any): void => {
    // console.log('onChangeCcNumber called', event)
    setCcNumberIsComplete(event.complete)
    setCcNumberValidationError(
      event.error
        ? event.error.message || undefined
        : undefined,
    )
    setShowPaymentErrorMessage(false)
  }

  const onBlurCcNumber = () => {
    if (ccNumberValidationError) {
      setCcNumberValidationError(ccNumberValidationError)
    } else if (!ccNumberIsComplete) {
      setCcNumberValidationError('required/incomplete')
    }
  }

  const onChangeCcExpiresAt = (event: any): void => {
    console.log('onChangeCcExpiresAt called', event)
    setCcExpiresAtIsComplete(event.complete)
    setCcExpiresAtValidationError(
      event.error
        ? event.error.message || undefined
        : undefined,
    )
    setShowPaymentErrorMessage(false)
  }

  const onBlurCcExpiresAt = () => {
    if (ccExpiresAtValidationError) {
      setCcExpiresAtValidationError(ccExpiresAtValidationError)
    } else if (!ccExpiresAtIsComplete) {
      setCcExpiresAtValidationError('required/incomplete')
    }
  }

  const onChangeCcCvc = (event: any): void => {
    console.log('onChangeCcCvc called', event)
    setCcCvcIsComplete(event.complete)
    setCcCvcValidationError(
      event.error
        ? event.error.message || undefined
        : undefined,
    )
    setShowPaymentErrorMessage(false)
  }

  const onBlurCcCvc = () => {
    if (ccCvcValidationError) {
      setCcCvcValidationError(ccCvcValidationError)
    } else if (!ccCvcIsComplete) {
      setCcCvcValidationError('required/incomplete')
    }
  }

  const onChangeSaveSensitiveInfo = (checked: boolean) => {
    setSaveSensitiveInfo(checked)
  }

  const handlePlaceOrder = (): void => {
    console.log('CreditCardForm.handlePlaceOrder called.')
    let debounceTimer: NodeJS.Timeout | undefined

    if (debounceTimer) {
      console.log('CreditCardForm.handlePlaceOrder: ignore multiple calls.')
      return
    }

    if (!activeUser || !stripe || !elements) {
      console.error('CreditCardForm.handlePlaceOrder: stripe object not available.')
      return
    }

    setShowPaymentErrorMessage(false)
    debounceTimer = setTimeout(() => {
      debounceTimer = undefined
    }, 2000)

    const fullNameToUse = (
      fullNameFormValue ||
      (userSensitiveInfo ? userSensitiveInfo.ccNameOnCard : undefined) ||
      (activeUser ? activeUser.fullName : '')
    )

    if (!fullNameToUse) {
      console.error('CreditCardForm.handlePlaceOrder: fullName not given.')
      return
    }

    const data: CreateTokenCardData = {
      name: fullNameToUse,
      address_line1: addressLine1FormValue || (userSensitiveInfo ? userSensitiveInfo.ccAddressLine1 || '' : ''),
      address_city: cityFormValue || (userSensitiveInfo ? userSensitiveInfo.ccCity || '' : ''),
      address_state: regionCodeFormValue || (userSensitiveInfo ? userSensitiveInfo.ccRegionCode || '' : ''),
      address_zip: postalCodeFormValue || (userSensitiveInfo ? userSensitiveInfo.ccPostalCode || '' : ''),
      address_country: countryCodeFormValue || (userSensitiveInfo ? userSensitiveInfo.ccCountryCode || '' : ''),
    }

    if (addressLine2FormValue || addressLine2FormValue === null) {
      data.address_line2 = addressLine2FormValue || ''
    } else {
      data.address_line2 = userSensitiveInfo ? userSensitiveInfo.ccAddressLine2 || '' : ''
    }
    if (addressLine2FormValue || (userSensitiveInfo && userSensitiveInfo.ccAddressLine2)) {
      data.address_line2 = addressLine2FormValue || (userSensitiveInfo ? userSensitiveInfo.ccAddressLine2 || '' : '')
    }

    console.log('CreditCardForm.handlePlaceOrder: calling stripe.createToken().')
    // see https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
    stripe
      .createToken(
        elements.getElement(CardNumberElement) as StripeCardNumberElement,
        data,
      )
      .then(({ token, error }: { token?: any; error?: any }) => {
        console.log('CreditCardForm.handlePlaceOrder: stripe.createToken() returned.', { token, error })
        if (error) {
          console.error('Error received Stripe.createToken:', error)
          return
        }
        if (
          isCustomerInfoFormDirty &&
          saveSensitiveInfo &&
          (
            fullNameFormValue ||
            addressLine1FormValue ||
            addressLine2FormValue ||
            addressLine2FormValue === null ||
            cityFormValue ||
            regionCodeFormValue ||
            postalCodeFormValue ||
            countryCodeFormValue
          )
        ) {
          console.log('CreditCardForm.handlePlaceOrder: will send user info first.')
          const info: UserSensitiveInfoInput = {
            userId: activeUser.id,
            ccNameOnCard: fullNameFormValue || (userSensitiveInfo ? userSensitiveInfo.ccNameOnCard || '' : ''),
            ccAddressLine1: addressLine1FormValue || (userSensitiveInfo ? userSensitiveInfo.ccAddressLine1 || '' : ''),
            ccAddressLine2: addressLine2FormValue || addressLine2FormValue === null
              ? addressLine2FormValue
              : (userSensitiveInfo ? userSensitiveInfo.ccAddressLine2 || '' : ''),
            ccCity: cityFormValue || (userSensitiveInfo ? userSensitiveInfo.ccCity || '' : ''),
            ccRegionCode: regionCodeFormValue || (userSensitiveInfo ? userSensitiveInfo.ccRegionCode || '' : ''),
            ccPostalCode: postalCodeFormValue || (userSensitiveInfo ? userSensitiveInfo.ccPostalCode || '' : ''),
            ccCountryCode: countryCodeFormValue || (userSensitiveInfo ? userSensitiveInfo.ccCountryCode || '' : ''),
          }
          api.upsertUserSensitiveInfo(
            info,
            'watch-updated-at',
            undefined,
            activeUserId as string,
            apolloClient,
          ).then(() => {
            console.log('CreditCardForm.handlePlaceOrder: calling onSubmit.')
            onPlaceOrder(token.id)
          }, (error) => {
            console.error(error)
          })
        } else {
          console.log('CreditCardForm.handlePlaceOrder: calling onSubmit.')
          onPlaceOrder(token.id)
        }
      })
  }

  const logEvent = (name: string): (event: any) => void => (event: any): void => {
    // console.log(`[${name}]`, event)
  }

  let saveMemberInfoSection: JSX.Element | undefined
  if (isCustomerInfoFormDirty) {
    saveMemberInfoSection = (
      <div className='withDoubleBottomMargin'>
        <FormCheckbox
          label='Save my name and address to my member account'
          isChecked={saveSensitiveInfo}
          onChange={onChangeSaveSensitiveInfo}
        />
      </div>
    )
  }

  let paymentErrorMessageSection: JSX.Element | undefined
  if (paymentErrorMessage && showPaymentErrorMessage) {
    paymentErrorMessageSection = (
      <div className='payment-error-message'>
        {paymentErrorMessage}
      </div>
    )
  }

  const enableSubmitButton = (
    !!stripe &&
    !!elements &&
    !!isValid &&
    !isSavingShoppingCart &&
    !isOrderPlaced &&
    (!paymentErrorMessage || !showPaymentErrorMessage)
  )

  const addressLine2DisplayValue = addressLine2FormValue || addressLine2FormValue === null
    ? addressLine2FormValue || ''
    : (userSensitiveInfo ? userSensitiveInfo.ccAddressLine2 || '' : '')

  return (
    <form onSubmit={handlePlaceOrder} className='credit-card-form' autoComplete='off'>
      <FormItem
        label='Name on card'
        validationError={fullNameValidationError}
        withBottomMargin
      >
        <IonInput
          ref={fullNameRef}
          name='fullName'
          placeholder='Name on card'
          value={fullName || (userSensitiveInfo ? userSensitiveInfo.ccNameOnCard || '' : '') || (activeUser ? activeUser.fullName : undefined)}
          autocomplete='cc-name'
          onIonChange={onChangeFullName}
          onIonBlur={onBlurFullName}
        />
      </FormItem>
      <FormItem
        label='Address line 1'
        validationError={addressLine1ValidationError}
        withBottomMargin
      >
        <IonInput
          ref={addressLine1Ref}
          name='addressLine1'
          placeholder='Address line 1'
          value={addressLine1 || (userSensitiveInfo ? userSensitiveInfo.ccAddressLine1 || '' : '')}
          autocomplete='address-line1'
          onIonChange={onChangeAddressLine1}
          onIonBlur={onBlurAddressLine1}
        />
      </FormItem>
      <FormItem
        label='Address line 2'
        validationError={addressLine2ValidationError}
        withBottomMargin
      >
        <IonInput
          ref={addressLine2Ref}
          name='addressLine2'
          placeholder='Address line 2'
          value={addressLine2DisplayValue}
          autocomplete='address-line2'
          onIonChange={onChangeAddressLine2}
          onIonBlur={onBlurAddressLine2}
        />
      </FormItem>
      <div className='row'>
        <FormItem
          label='City'
          validationError={cityValidationError}
          className='city-input'
          withBottomMargin
        >
          <IonInput
            ref={cityRef}
            name='city'
            placeholder='City'
            value={city || (userSensitiveInfo ? userSensitiveInfo.ccCity || '' : '')}
            autocomplete='address-level2'
            onIonChange={onChangeCity}
            onIonBlur={onBlurCity}
          />
        </FormItem>
        <FormItem
          label='State'
          validationError={regionCodeValidationError}
          withBottomMargin
          className='region-code-input'
        >
          <div className='row'>
            <IonInput
              ref={regionCodeRef}
              name='regionCode'
              minlength={2}
              maxlength={2}
              placeholder='XX'
              value={regionCode || (userSensitiveInfo ? userSensitiveInfo.ccRegionCode || '' : '')}
              autocomplete='address-level1'
              onIonChange={onChangeRegionCode}
              onIonBlur={onBlurRegionCode}
            />
            {/* <RegionCodeSelect value={regionCode} onChange={onChangeRegionCode} /> */}
          </div>
        </FormItem>
        <FormItem
          label='Postal code'
          validationError={postalCodeValidationError}
          withBottomMargin
          className='postal-code-input'
        >
          <IonInput
            ref={postalCodeRef}
            name='postalCode'
            type='number'
            inputmode='numeric'
            minlength={5}
            maxlength={5}
            placeholder='00000'
            value={postalCode || (userSensitiveInfo ? userSensitiveInfo.ccPostalCode || '' : '')}
            autocomplete='postal-code'
            onIonChange={onChangePostalCode}
            onIonBlur={onBlurPostalCode}
          />
        </FormItem>
      </div>
      {saveMemberInfoSection}
      <FormItem
        label='Credit card number'
        validationError={ccNumberValidationError}
        className='cc-number-input'
        withBottomMargin
      >
        <div className='stripe-input-wrapper'>
          <CardNumberElement
            // ref={creditCardNumberRef}
            id='cardNumber'
            onBlur={onBlurCcNumber}
            onChange={onChangeCcNumber}
            // onFocus={logEvent('focus')}
            onReady={logEvent('ready')}
            options={ELEMENT_OPTIONS}
          />
        </div>
      </FormItem>
      <div className='row'>
        <FormItem
          label='Expiration date'
          validationError={ccExpiresAtValidationError}
          className='cc-exp-input'
          withBottomMargin
        >
          <div className='stripe-input-wrapper'>
            <CardExpiryElement
              id='expiry'
              onBlur={onBlurCcExpiresAt}
              onChange={onChangeCcExpiresAt}
              // onFocus={logEvent('focus')}
              onReady={logEvent('ready')}
              options={ELEMENT_OPTIONS}
            />
          </div>
        </FormItem>
        <FormItem
          label='Security code'
          validationError={ccCvcValidationError}
          className='cc-cvc-input'
          withBottomMargin
        >
          <div className='stripe-input-wrapper'>
            <CardCvcElement
              id='cvc'
              onBlur={onBlurCcCvc}
              onChange={onChangeCcCvc}
              // onFocus={logEvent('focus')}
              onReady={logEvent('ready')}
              options={ELEMENT_OPTIONS}
            />
          </div>
        </FormItem>
      </div>
      {paymentErrorMessageSection}
      <SubmitSection
        shoppingCart={shoppingCart}
        isActiveShoppingCart={isActiveShoppingCart}
        isSavingShoppingCart={isSavingShoppingCart}
        enableSubmitButton={enableSubmitButton}
        onClearShoppingCart={onClearShoppingCart}
        onDeletePendingOrder={onDeletePendingOrder}
        onPlaceOrder={handlePlaceOrder}
      />
    </form>
  )
}

export default CreditCardForm
