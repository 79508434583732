import React from 'react'
import { IonList } from '@ionic/react'

import './styles.css'
import type { PurchaseListItem } from '../../../../../lib/core/definitions'
import ContactPurchaseListItem from './ContactPurchaseListItem'

type Props = {
  purchases?: PurchaseListItem[] | null
  onOpenPurchase?: (purchaseId: string) => void;
}

const ContactPurchaseList: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('ContactPurchaseList.render called.', { props })
  const {
    purchases,
    onOpenPurchase,
  } = props

  // ===================================================================================================================
  // Helpers:
  if (!Array.isArray(purchases) || purchases.length < 1) {
    return null
  }

  // ===================================================================================================================
  // Rendering:
  const purchaseListItems = purchases.map((purchase: PurchaseListItem) => (
    <ContactPurchaseListItem
      key={purchase.id}
      purchase={purchase}
      onOpenPurchase={onOpenPurchase}
    />
  ))

  return (
    <div className='contact-purchase-list'>
      <IonList className='autoOverflowContainer'>
        {purchaseListItems}
      </IonList>
    </div>
  )
}

export default ContactPurchaseList
