import React from 'react'
import { IonToggle } from '@ionic/react'

import './styles.css'

interface Props {
  values: [string, string]
  labels: [string, string]
  curValue: string
  className?: string
  onChange: (value?: string) => void
}

const ToggleWithLabels: React.FC<Props> = (props): JSX.Element | null => {
  const {
    values,
    labels,
    curValue,
    className,
    onChange,
  } = props

  // ===================================================================================================================
  // Event Handlers:
  const handleToggleChange = (event: any) => {
    onChange(event.detail.checked ? values[1] : values[0])
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className={`toggle-with-labels ${className || ''}`}>
      <span
        className={curValue === values[0] ? 'selected' : 'unselected'}
        onClick={() => onChange(values[0])}
      >
        {labels[0]}
      </span>
      <IonToggle
        onIonChange={handleToggleChange}
        checked={curValue === values[1]}
      />
      <span
        className={curValue === values[1] ? 'selected' : 'unselected'}
        onClick={() => onChange(values[1])}
      >
        {labels[1]}
      </span>
    </div>
  )
}

export default ToggleWithLabels
