export enum ChatLayout {
  FULL_PAGE = 'full-page',
  EMBEDDED = 'embedded',
}

export enum DisplayMode {
  SELECT_ATTACHMENT_TYPE = 'select-attachment-type',
  GIPHY = 'giphy',
  TOKEN_TRANSFER = 'send-tokens',
}
