import React, { useContext, useState } from 'react'

import type { AppPage } from '../enums'
import type { User } from '../lib/core/definitions'
import coreHelpers from '../lib/core/helpers'
import logger from '../services/logger'

export interface PageData {
  contactUser?: Partial<User> | null
  purchaseId?: string | null
  purchaseTransferId?: string | null
}

const comparePageData = (d1: PageData | undefined, d2: PageData | undefined): boolean => {
  if (!d1 && !d2) {
    return true
  }

  if ((d1 && !d2) || (!d1 && d2)) {
    return false
  }

  if (d1 && d2) {
    if (
      (d1.contactUser && !d2.contactUser) ||
      (!d1.contactUser && d2.contactUser)
    ) {
      return false
    }
    if (d1.contactUser && d2.contactUser) {
      if (!coreHelpers.models.compareId(d1.contactUser.id, d2.contactUser.id)) {
        return false
      }
    }
  }

  return (
    coreHelpers.models.compareId(d1 && d1.purchaseId, d2 && d2.purchaseId) &&
    coreHelpers.models.compareId(d1 && d1.purchaseTransferId, d2 && d2.purchaseTransferId)
  )
}

const isPageDataEmpty = (data: PageData | undefined): boolean => {
  if (!data) {
    return true
  }
  return (
    !data.contactUser &&
    !data.purchaseId &&
    !data.purchaseTransferId
  )
}

let pendingPageData: { page: AppPage, data: PageData } | undefined

export interface ActivePageDataContextValue {
  page?: AppPage,
  activePageData?: PageData,
  clear: () => void
  pageWillEnter: (page: AppPage, pageData?: PageData) => void
  pageWillLeave: (page: AppPage) => void
  setActivePageData: (page: AppPage, pageData: PageData | undefined) => void
}

export const defaultContextValue: ActivePageDataContextValue = {
  page: undefined,
  activePageData: undefined,
  clear: () => {
    console.log('ActivePageDataContext.defaultContextValue.clear called.')
  },
  pageWillEnter: (page: AppPage, pageData?: PageData) => {
    console.log('ActivePageDataContext.defaultContextValue.pageWillEnter called.', { page, pageData })
  },
  pageWillLeave: (page: AppPage) => {
    console.log('ActivePageDataContext.defaultContextValue.pageWillLeave called.', { page })
  },
  setActivePageData: (page: AppPage, pageData: PageData | undefined) => {
    console.log('ActivePageDataContext.defaultContextValue.setActivePageData called.', { page, pageData })
  },
}

export const ActivePageDataContext = React.createContext<ActivePageDataContextValue>(defaultContextValue)

export function useActivePageData (): ActivePageDataContextValue {
  return useContext(ActivePageDataContext)
}

const ActivePageDataProvider: React.FC = (props) => {
  const [page, setPage] = useState<AppPage | undefined>()
  const [pageData, setPageData] = useState<PageData | undefined>()

  const clear = (): void => {
    // console.log('ActivePageContext.clear called.', { page, pageData })
    setPage(undefined)
    setPageData(undefined)
    pendingPageData = undefined
  }

  const pageWillEnter = (pg: AppPage, d?: PageData): void => {
    // console.log('ActivePageDataContext.pageWillEnter called.',
    //   { page, pageData, pendingPageData, pg, d })
    if (pg !== page) {
      setPage(pg)
      if (
        d &&
        !isPageDataEmpty(d) &&
        !comparePageData(d, pageData)
      ) {
        setPageData(d)
      } else if (
        pendingPageData &&
        pendingPageData.page === pg &&
        pendingPageData.data
      ) {
        // console.log('ActivePageDataContext.pageWillEnter: using pending data.',
        //   { page: pg, data: pendingPageData.data })
        setPageData(pendingPageData.data)
      }
    }
  }

  const pageWillLeave = (pg: AppPage): void => {
    console.log('ActivePageDataContext.pageWillLeave called.',
      { page, pageData, pendingPageData, pg })
    clear()
  }

  const setActivePageData = (pg: AppPage, d: PageData | undefined): void => {
    // console.log('ActivePageDataContext.setActivePageData called.',
    //   { page, pageData, pendingPageData, pg, d })
    if (
      !d ||
      isPageDataEmpty(d) ||
      comparePageData(d, pageData)
    ) {
      return
    }
    if (pg === page) {
      // console.log('ActivePageDataContext.setActivePageData called.', { page: pg, data: d })
      setPageData(d)
      pendingPageData = undefined
      return
    }
    if (d) {
      pendingPageData = { page: pg, data: d }
    }
  }

  return (
    <ActivePageDataContext.Provider
      value={{
        page,
        activePageData: pageData,
        clear,
        pageWillEnter,
        pageWillLeave,
        setActivePageData,
      }}
    >
      {props.children}
    </ActivePageDataContext.Provider>
  )
}

export default ActivePageDataProvider
