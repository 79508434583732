import React, { useContext, useState } from 'react'
import { create } from 'ionicons/icons'
import {
  IonContent,
  IonIcon,
  IonImg,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import type { AppRoute } from '../../../enums'
import { AppPage, PageMessageType } from '../../../enums'
import { CdnAssetType } from '../../../services/cdn/enums'
import type { ProductOption, StoredProductInput } from '../../../lib/core/definitions'
import { UiMessage } from '../../../lib/core/enums'
import type {
  ProductQueryData,
  ProductQueryVariables,
  StoredProductQueryData,
  StoredProductQueryVariables,
} from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import cdn from '../../../services/cdn'
import coreHelpers from '../../../lib/core/helpers'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import StoredProductForm from './StoredProductForm'

const appPageId = AppPage.AdminEditStoredProductPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  productId: string
  productOptionId: string
  storedProductId: string
}

const EditStoredProductPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { productId, productOptionId, storedProductId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [isUpsertingStoredProduct, setIsUpsertingStoredProduct] = useState(false)

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading product:
  const {
    data: productLoadedData,
    loading: isLoadingProduct,
    // refetch: reloadProduct,
    client: apolloClient,
  } = useQuery<ProductQueryData, ProductQueryVariables>(
    apollo.queries.product({
      includeMerchant: true,
      includeProductOptions: true,
      includeStoredProducts: true,
      includeTags: true,
    }), {
      variables: { productId: productId as string },
      skip: !productId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: ProductQueryData) => {
        const returnedProduct = data ? data.product : undefined
        if (returnedProduct === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        // if (refreshEvent) {
        //   refreshEvent.detail.complete()
        //   refreshEvent = undefined
        // }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const product = productLoadedData && productLoadedData.product
  const merchant = product ? product.merchant : undefined
  const merchantName = merchant ? merchant.name : undefined
  const merchantLargeLogoImageSource = merchant ? merchant.largeLogoImageSource : ''
  const productOption = (
    productOptionId &&
    product &&
    Array.isArray(product.productOptions) &&
    product.productOptions.length > 0
      ? product.productOptions.find((option: ProductOption): boolean => coreHelpers.models.compareId(option.id, productOptionId))
      : undefined
  )

  // -------------------------------------------------------------------------------------------------------------------
  // Loading stored product:
  const {
    data: storedProductLoadedData,
    loading: isLoadingStoredProduct,
    refetch: reloadStoredProduct,
  } = useQuery<StoredProductQueryData, StoredProductQueryVariables>(
    apollo.admin.queries.storedProduct, {
      variables: { storedProductId: storedProductId as string },
      skip: !storedProductId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: StoredProductQueryData) => {
        const returnedStoredProduct = data ? data.storedProduct : undefined
        if (returnedStoredProduct === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const storedProduct = storedProductLoadedData && storedProductLoadedData.storedProduct

  // ===================================================================================================================
  // Helpers:
  const isProcessing = (
    isLoadingActiveUser ||
    isLoadingProduct ||
    isLoadingStoredProduct ||
    isUpsertingStoredProduct
  )
  // const product = storedProduct ? storedProduct.product : undefined;

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onUpsertStoredProduct = (storedProductInput: StoredProductInput): void => {
    setIsUpsertingStoredProduct(true)
    api.upsertStoredProduct(
      storedProductInput,
      'watch-updated-at',
      apollo.getStoredProductListFilter(),
      apolloClient,
      undefined,
    ).then(() => {
      setIsUpsertingStoredProduct(false)
      showUiMessage('Successfully updated this inventory item.')
    }, (error) => {
      setIsUpsertingStoredProduct(false)
      console.error(error)
      showUiMessage('Error saving this item.')
    })
  }

  // ===================================================================================================================
  // Event Handlers:
  const onEditMerchant = (): void => {
    if (!storedProduct || !storedProduct.product || !storedProduct.product.merchantId) {
      return
    }
    navigate(`/admin/edit-merchant/${storedProduct.product.merchantId}`)
  }

  const onEditProduct = (): void => {
    if (!storedProduct) {
      return
    }
    navigate(`/admin/edit-product/${storedProduct.productId}`)
  }

  const onGoBack = (): void => {
    navigate(-1)
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !storedProductId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadStoredProduct().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const pageTitle = storedProduct ? 'Edit Inventory Item' : 'Add Inventory Item'

  let merchantLogo
  if (merchantLargeLogoImageSource) {
    const merchantLogoImageUri = cdn.getUri(CdnAssetType.MERCHANT_LOGO, merchantLargeLogoImageSource) || ''
    merchantLogo = (
      <IonImg
        src={merchantLogoImageUri}
        className='with4RemWidth withDoubleRightMargin'
      />
    )
  }

  let productOptionSection
  if (productOption) {
    const formattedAmount = coreHelpers.ui.formatAmount(
      productOption.amount,
      productOption.fundType,
      productOption.currency,
      false,
      2,
    )

    productOptionSection = (
      <div className='attributeList withDoubleBottomMargin'>
        <div className='attributeListRow'>
          <span className='attributeListLabel'>Fund Type: </span>
          <span className='attributeListValue'>{productOption.fundType}</span>
        </div>
        <div className='attributeListRow'>
          <span className='attributeListLabel'>Amount: </span>
          <span className='attributeListValue'>{formattedAmount}</span>
        </div>
        <div className='attributeListRow'>
          <span className='attributeListLabel'>Currency: </span>
          <span className='attributeListValue'>{productOption.currency}</span>
        </div>
      </div>
    )
  }

  return (
    <IonPage className='app-page-admin edit-stored-product-page'>
      <NavBar
        title={pageTitle}
        goBackUri={`/admin/product/${productId}`}
        isProcessing={isProcessing}
      />
      <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <div className='rowWithCenterAlignedItems withDoubleBottomMargin' onClick={onEditMerchant}>
          {merchantLogo}
          <div className='sectionCaption'>Inventory Item For Brand &quot;{merchantName}&quot;</div>
          <IonIcon className='inlineEditIcon' icon={create} />
        </div>
        {/* <div> */}
        {/*  <div className="linkText withStandardBottomMargin" onClick={onEditMerchant}> */}
        {/*    Edit Merchant {merchantName} */}
        {/*  </div> */}
        {/*  <div className="linkText withStandardBottomMargin" onClick={onEditProduct}> */}
        {/*    Edit Product */}
        {/*  </div> */}
        {/* </div> */}
        {productOptionSection}
        <StoredProductForm
          storedProduct={storedProductId ? storedProduct : undefined}
          productOption={productOption}
          onGoBack={onGoBack}
          onSave={onUpsertStoredProduct}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default EditStoredProductPage
