// import serviceData from './serviceData'

export default (): boolean => {
  // const workbox = serviceData.getWorkbox()
  // const serviceWorkerRegistration = serviceData.getServiceWorkerRegistration()
  //
  // return (
  //   !!workbox &&
  //   !!serviceWorkerRegistration &&
  //   !!serviceWorkerRegistration.waiting &&
  //   serviceWorkerRegistration.waiting.state === 'installed'
  // )
  return false
}
