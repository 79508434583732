import React, { useEffect, useState } from 'react'
import { IonButton, IonInput } from '@ionic/react'

import type { Tag, TagInput } from '../../../lib/core/definitions'
import type { TagType } from '../../../lib/core/enums'
import './styles.css'
import coreHelpers from '../../../lib/core/helpers'
import FormItem from '../../../components/FormItem/FormItem'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../helpers/validationHelpers'

type Props = {
  tag?: Tag | null;
  isProcessing: boolean;
  onGoBack: () => void;
  onSave: (tag: TagInput) => void;
};

const TagForm: React.FC<Props> = ({
  tag = null,
  isProcessing,
  onSave,
  onGoBack,
}): JSX.Element => {
  const [name, setName] = useState<string | undefined>()
  const [nameValidationError, setNameValidationError] = useState<string | undefined>()
  const [captionEn, setCaptionEn] = useState<string | undefined>()
  const [captionEnValidationError, setCaptionEnValidationError] = useState<string | undefined>()
  const [lat, setLat] = useState<string | undefined>()
  const [latValidationError, setLatValidationError] = useState<string | undefined>()
  const [lon, setLon] = useState<string | undefined>()
  const [lonValidationError, setLonValidationError] = useState<string | undefined>()
  const [tagType, setTagType] = useState(undefined as TagType | undefined)
  const [tagTypeValidationError, setTagTypeValidationError] = useState<string | undefined>()
  const [priority, setPriority] = useState<number | undefined>()
  const [priorityValidationError, setPriorityValidationError] = useState<string | undefined>()
  const [sortIndex, setSortIndex] = useState<number | undefined>()
  const [sortIndexValidationError, setSortIndexValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const nameChanged = name !== undefined && !(tag && name === tag.name)
  const captionEnChanged = captionEn !== undefined && !(tag && captionEn === tag.captionEn)
  const latChanged = lat !== undefined && !(tag && lat === tag.lat)
  const lonChanged = lon !== undefined && !(tag && lon === tag.lon)
  const tagTypeChanged = tagType !== undefined && !(tag && tagType === tag.tagType)
  const priorityChanged = priority !== undefined && !(tag && priority === tag.priority)
  const sortIndexChanged = sortIndex !== undefined && !(tag && sortIndex === tag.sortIndex)

  const isDirty = (
    nameChanged ||
    tagTypeChanged ||
    captionEnChanged ||
    latChanged ||
    lonChanged ||
    priorityChanged ||
    sortIndexChanged
  )
  const isValid = (
    !nameValidationError &&
    !tagTypeValidationError &&
    !captionEnValidationError &&
    !latValidationError &&
    !lonValidationError &&
    !priorityValidationError &&
    !sortIndexValidationError
  )
  // console.log('TagForm.render called.', {
  //   nameValidationError,
  //   smallLogoImageTagTypeValidationError,
  //   largeLogoImageTagTypeValidationError,
  //   tagTypeValidationError,
  //   isDirty,
  //   isValid,
  // });

  const resetForm = (): void => {
    setName(undefined)
    setNameValidationError(undefined)
    setCaptionEn(undefined)
    setCaptionEnValidationError(undefined)
    setLat(undefined)
    setLatValidationError(undefined)
    setLon(undefined)
    setLonValidationError(undefined)
    setTagType(undefined)
    setTagTypeValidationError(undefined)
    setPriority(undefined)
    setPriorityValidationError(undefined)
    setSortIndex(undefined)
    setSortIndexValidationError(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('TagForm: new tag received - resetting form.')
    resetForm()
  }, [tag])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeName = (event: any): void => {
    if (
      (tag && (event.detail.value === tag.name || (!event.detail.value && !tag.name))) ||
      (!tag && !event.detail.value)
    ) {
      setNameValidationError(undefined)
      setName(undefined)
      return
    }
    setName(event.detail.value)
    if (event.detail.value) {
      setNameValidationError(validationHelpers.validateTagName(event.detail.value))
    } else {
      setNameValidationError(undefined)
    }
  }

  const onChangeCaptionEn = (event: any): void => {
    if (
      (tag && (event.detail.value === tag.captionEn || (!event.detail.value && !tag.captionEn))) ||
      (!tag && !event.detail.value)
    ) {
      setCaptionEnValidationError(undefined)
      setCaptionEn(undefined)
      return
    }
    setCaptionEn(event.detail.value)
    if (event.detail.value) {
      setCaptionEnValidationError(validationHelpers.validateTagCaption(event.detail.value))
    } else {
      setCaptionEnValidationError(undefined)
    }
  }

  const onChangeLat = (event: any): void => {
    if (
      (tag && (event.detail.value === tag.lat || (!event.detail.value && !tag.lat))) ||
      (!tag && !event.detail.value)
    ) {
      setLatValidationError(undefined)
      setLat(undefined)
      return
    }
    setLat(event.detail.value)
    if (event.detail.value) {
      setLatValidationError(validationHelpers.validateGeoLatitude(event.detail.value))
    } else {
      setLatValidationError(undefined)
    }
  }

  const onChangeLon = (event: any): void => {
    if (
      (tag && (event.detail.value === tag.lon || (!event.detail.value && !tag.lon))) ||
      (!tag && !event.detail.value)
    ) {
      setLonValidationError(undefined)
      setLon(undefined)
      return
    }
    setLon(event.detail.value)
    if (event.detail.value) {
      setLonValidationError(validationHelpers.validateGeoLongitude(event.detail.value))
    } else {
      setLonValidationError(undefined)
    }
  }

  const onChangePriority = (event: any): void => {
    if (
      (tag && (event.detail.value === tag.priority || (!event.detail.value && !tag.priority))) ||
      (!tag && !event.detail.value)
    ) {
      setPriorityValidationError(undefined)
      setPriority(undefined)
      return
    }
    setPriority(parseInt(event.detail.value))
    if (event.detail.value) {
      setPriorityValidationError(validationHelpers.validateTagPriority(event.detail.value))
    } else {
      setPriorityValidationError(undefined)
    }
  }

  const onChangeSortIndex = (event: any): void => {
    if (
      (tag && (event.detail.value === tag.sortIndex || (!event.detail.value && !tag.sortIndex))) ||
      (!tag && !event.detail.value)
    ) {
      setSortIndexValidationError(undefined)
      setSortIndex(undefined)
      return
    }
    setSortIndex(parseInt(event.detail.value))
    if (event.detail.value) {
      setSortIndexValidationError(validationHelpers.validateTagSortIndex(event.detail.value))
    } else {
      setSortIndexValidationError(undefined)
    }
  }

  const onChangeTagType = (event: any): void => {
    if (
      (tag && (event.detail.value === tag.tagType || (!event.detail.value && !tag.tagType))) ||
      (!tag && !event.detail.value)
    ) {
      setTagTypeValidationError(undefined)
      setTagType(undefined)
      return
    }
    setTagType(event.detail.value)
    if (event.detail.value) {
      setTagTypeValidationError(coreHelpers.type.tagType.isValid(event.detail.value) ? undefined : 'not a valid type')
    } else {
      setTagTypeValidationError(undefined)
    }
  }

  const onClickSaveButton = (event: any): void => {
    event.preventDefault()
    const updatedTag: TagInput = {}
    if (tag && tag.id) {
      updatedTag.id = tag.id
    }
    if (name !== undefined && (!tag || name !== tag.name)) {
      updatedTag.name = name || null
    }
    if (tagType !== undefined && (!tag || tagType !== tag.tagType)) {
      updatedTag.tagType = tagType || null
    }
    if (captionEn !== undefined && (!tag || captionEn !== tag.captionEn)) {
      updatedTag.captionEn = captionEn || null
    }
    if (lat !== undefined && (!tag || lat !== tag.lat)) {
      updatedTag.lat = lat || null
    }
    if (lon !== undefined && (!tag || lon !== tag.lon)) {
      updatedTag.lon = lon || null
    }
    if (priority !== undefined && (!tag || priority !== tag.priority)) {
      updatedTag.priority = priority || 0
    }
    if (sortIndex !== undefined && (!tag || sortIndex !== tag.sortIndex)) {
      updatedTag.sortIndex = sortIndex || 0
    }
    onSave(updatedTag)
  }

  return (
    <form onSubmit={onClickSaveButton}>
      <FormItem label='Name (must be unique)' validationError={nameValidationError} withBottomMargin>
        <IonInput
          autofocus
          onIonChange={onChangeName}
          placeholder='name'
          value={name !== undefined ? name : (tag ? tag.name : undefined)}
        />
      </FormItem>
      <FormItem label='Caption' validationError={captionEnValidationError} withBottomMargin>
        <IonInput
          autocapitalize='sentences'
          placeholder='caption'
          onIonChange={onChangeCaptionEn}
          value={captionEn !== undefined ? captionEn : (tag ? tag.captionEn : undefined)}
        />
      </FormItem>
      <FormItem label='Type' validationError={tagTypeValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeTagType}
          placeholder='cat | loc'
          value={tagType !== undefined ? tagType : (tag ? tag.tagType : undefined)}
        />
      </FormItem>
      <FormItem label='Latitude (geo)' validationError={latValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeLat}
          placeholder='geo: latitude'
          value={lat !== undefined ? lat : (tag ? tag.lat : undefined)}
        />
      </FormItem>
      <FormItem label='Longitude (geo)' validationError={lonValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeLon}
          placeholder='geo: longitude'
          value={lon !== undefined ? lon : (tag ? tag.lon : undefined)}
        />
      </FormItem>
      <FormItem label='Priority' validationError={priorityValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangePriority}
          placeholder=''
          value={priority !== undefined ? priority : (tag ? tag.priority : undefined)}
        />
      </FormItem>
      <FormItem label='Sort Index' validationError={sortIndexValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeSortIndex}
          placeholder=''
          value={sortIndex !== undefined ? sortIndex : (tag ? tag.sortIndex : undefined)}
        />
      </FormItem>
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onGoBack}
        >
          Back
        </IonButton>
        <SubmitButton
          onClick={onClickSaveButton}
          disabled={!isDirty || !isValid}
          isProcessing={isProcessing}
        >
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default TagForm
