import React, { useState, FC } from 'react'
import { IonButton, IonChip, IonLabel, IonSearchbar } from '@ionic/react'
import { useMutation, useQuery } from '@apollo/client'

import './styles.css'
import type { Product, Tag, TagInput } from '../../../lib/core/definitions'
import type {
  UpsertTagMutationData,
  UpsertTagMutationVariables,
  AddOrRemoveTagFromMutationData,
  AddOrRemoveTagFromProductMutationVariables,
  ProductQueryData,
  ProductQueryVariables,
  TagsQueryData,
  TagsQueryVariables,
} from '../../../services/apollo/definitions'
import apollo from '../../../services/apollo'

interface Props {
  product: Product | null | undefined,
}

const AddProductTag: React.FC<Props> = (props): JSX.Element | null => {
  const { product } = props

  // ===================================================================================================================
  // State:
  const [searchText, setSearchText] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: tagsData,
    refetch: reloadTags,
  } = useQuery<TagsQueryData, TagsQueryVariables>(
    apollo.queries.tags,
    {
      skip: !searchText,
      variables: {
        filter: {
          searchText,
          limit: 100,
        },
      },
    },
  )
  let foundTags: Tag[] | undefined
  if (tagsData && Array.isArray(tagsData.tags)) {
    foundTags = tagsData.tags // .sort(helpers.alphabeticalSorter) || []
  }

  const [
    addTagToProduct,
  ] = useMutation<AddOrRemoveTagFromMutationData, AddOrRemoveTagFromProductMutationVariables>(apollo.admin.mutations.addTagToProduct)

  const [
    createTag,
  ] = useMutation<UpsertTagMutationData, UpsertTagMutationVariables>(apollo.admin.mutations.createTag)

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSearchText = (event: any) => {
    setSearchText(event.detail.value)
  }

  const onClickTag = (tag: Tag) => {
    if (!product) {
      return
    }

    addTagToProduct({
      variables: {
        productId: product.id as string,
        tagId: tag.id as string,
      },
      update: (cache) => {
        try {
          const cachedProductData = cache.readQuery<ProductQueryData, ProductQueryVariables>({
            query: apollo.queries.product({
              includeMerchant: true,
              includeProductOptions: true,
              includeStoredProducts: true,
              includeTags: true,
            }),
            variables: { productId: product.id as string },
          })
          if (cachedProductData && cachedProductData.product) {
            const updatedProduct: Product = { ...cachedProductData.product }
            if (Array.isArray(updatedProduct.tags) && updatedProduct.tags.length > 0) {
              updatedProduct.tags = updatedProduct.tags.slice(0)
              updatedProduct.tags.push(tag)
            } else {
              updatedProduct.tags = [tag]
            }
            cache.writeQuery({
              query: apollo.queries.product({
                includeMerchant: true,
                includeProductOptions: true,
                includeStoredProducts: true,
                includeTags: true,
              }),
              variables: { productId: product.id as string },
              data: { product: updatedProduct },
            })
          }
        } catch (error) {
          console.warn(error)
        }
      },
    })
  }

  const onCreateTag = () => {
    const tagInput: TagInput = {
      name: searchText,
    }
    createTag({
      variables: { tag: tagInput },
      update: (cache, upsertTagData) => {
        if (
          upsertTagData &&
          upsertTagData.data &&
          upsertTagData.data.upsertTag &&
          upsertTagData.data.upsertTag.id
        ) {
          const createdTag = upsertTagData.data.upsertTag
          reloadTags().then(() => {
            onClickTag(createdTag)
          }, (error) => {
            console.error(error)
          })
        }
      },
    })
  }

  // ===================================================================================================================
  // Helpers:
  const isTagAssigned = (tagId: string | null | undefined): boolean => {
    if (
      !product ||
      !Array.isArray(product.tags) ||
      product.tags.length < 1 ||
      !tagId
    ) {
      return false
    }
    return !!product.tags.find(t => t.id === tagId)
  }

  // ===================================================================================================================
  // Rendering:
  let renderedTags: JSX.Element[] | undefined
  if (Array.isArray(foundTags) && foundTags.length > 0) {
    renderedTags = foundTags
      .map(tag => {
        const isAssigned = isTagAssigned(tag.id)
        const className = isAssigned ? 'assigned-tag' : ''
        return (
          <IonChip
            className={className}
            onClick={() => !isAssigned ? onClickTag(tag) : undefined}
            key={tag.id}
          >
            <IonLabel>
              <span>{tag.name}</span>
            </IonLabel>
          </IonChip>
        )
      })
  }

  return (
    <div className='add-product-tag'>
      <div className='rowWithCenterAlignedItems'>
        <IonSearchbar
          value={searchText}
          placeholder='Search tags...'
          onIonChange={onChangeSearchText}
        />
        <IonButton
          size='small'
          onClick={onCreateTag}
          disabled={!searchText || searchText.length < 3 || (Array.isArray(foundTags) && foundTags.length > 0)}
        >
          Create Tag
        </IonButton>
      </div>
      {renderedTags}
    </div>
  )
}

export default AddProductTag
