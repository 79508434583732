import React, { useContext, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import { UiMessage } from '../../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type { TagInput } from '../../../lib/core/definitions'
import { useMutation, useQuery } from '@apollo/client'
import type {
  DeleteTagData,
  DeleteTagVariables,
  TagQueryData,
  TagQueryVariables,
  UpsertTagData,
  UpsertTagVariables,
} from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import coreHelpers from '../../../lib/core/helpers'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import TagForm from './TagForm'

const appPageId = AppPage.AdminEditTagPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  tagId: string
}

const EditTagPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { tagId } = useParams<keyof Params>() as unknown as Params
  const goBackUri = AppRoute.ADMIN_TAGS

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [isPollingTag, setIsPollingTag] = useState(false)
  const [isWaitingToReloadTag, setIsWaitingToReloadTag] = useState(false)
  const [updatedAtBeforeSaving, setUpdatedAtBeforeSaving] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading tag:
  const {
    data: tagLoadedData,
    loading: isLoadingTag,
    refetch: reloadTag,
    startPolling: startPollingTag,
    stopPolling: stopPollingTag,
  } = useQuery<TagQueryData, TagQueryVariables>(
    apollo.queries.tag, {
      variables: { tagId: tagId as string },
      skip: !tagId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: TagQueryData) => {
        const returnedTag = data ? data.tag : undefined
        if (!returnedTag) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
          return
        }
        if (isWaitingToReloadTag) {
          if (
            !tagId ||
            !updatedAtBeforeSaving ||
            returnedTag.updatedAt !== updatedAtBeforeSaving
          ) {
            setIsWaitingToReloadTag(false)
            if (isPollingTag) {
              stopPollingTag()
              setIsPollingTag(false)
            }
            setToastMessage(coreHelpers.type.uiMessage.getLabel(UiMessage.DATA_UPDATED_SUCCESSFULLY))
            setShowToast(true)
            navigate(-1)
            return
          }
          startPollingTag(1000)
          setIsPollingTag(true)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const tag = tagLoadedData ? tagLoadedData.tag : undefined

  const [
    createTag, {
      loading: isCreatingTag,
    },
  ] = useMutation<UpsertTagData, UpsertTagVariables>(apollo.admin.mutations.createTag, {
    notifyOnNetworkStatusChange: true,
    onError (error) {
      console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
    },
    // refetchQueries: [{
    //   query: apollo.queries.tag,
    //   variables: { tagId: 'apollographql/apollo-client' },
    // }],

    // update: (store, upsertTagData) => {
    //   try {
    //     if (upsertTagData && upsertTagData.data) {
    //       const newTag = upsertTagData.data.upsertTag
    //       const tagsData = store.readQuery<TagsQueryData, TagsQueryVariables>({ query: apollo.queries.tags })
    //       if (tagsData && tagsData.tags) {
    //         tagsData.tags = [...tagsData.tags, newTag]
    //         store.writeQuery({ query: apollo.queries.tags, data: tagsData })
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   } finally {
    //     setToastMessage(coreHelpers.type.uiMessage.getLabel(UiMessage.DATA_UPDATED_SUCCESSFULLY))
    //     setShowToast(true)
    //     navigate(-1)
    //   }
    // },

    onCompleted: (data) => {
      if (data && data.upsertTag && data.upsertTag.id) {
        setToastMessage(coreHelpers.type.uiMessage.getLabel(UiMessage.DATA_UPDATED_SUCCESSFULLY))
        setShowToast(true)
        navigate(-1)
      }
    },
  })

  const [
    deleteTag, {
      loading: isDeletingTag,
    },
  ] = useMutation<DeleteTagData, DeleteTagVariables>(apollo.admin.mutations.deleteTag, {
    notifyOnNetworkStatusChange: true,
    onError (error) {
      console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
    },
    // refetchQueries: [{
    //   query: apollo.queries.tag,
    //   variables: { tagId: 'apollographql/apollo-client' },
    // }],

    // update: (store, upsertTagData) => {
    //   try {
    //     if (upsertTagData && upsertTagData.data) {
    //       const newTag = upsertTagData.data.upsertTag
    //       const tagsData = store.readQuery<TagsQueryData, TagsQueryVariables>({ query: apollo.queries.tags })
    //       if (tagsData && tagsData.tags) {
    //         tagsData.tags = [...tagsData.tags, newTag]
    //         store.writeQuery({ query: apollo.queries.tags, data: tagsData })
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   } finally {
    //     setToastMessage(coreHelpers.type.uiMessage.getLabel(UiMessage.DATA_UPDATED_SUCCESSFULLY))
    //     setShowToast(true)
    //     navigate(-1)
    //   }
    // },

    onCompleted: (data) => {
      if (data && data.deleteTag && data.deleteTag.id) {
        setToastMessage(coreHelpers.type.uiMessage.getLabel(UiMessage.DATA_DELETED_SUCCESSFULLY))
        setShowToast(true)
        navigate(-1)
      }
    },
  })

  const [
    updateTag, {
      loading: isUpdatingTag,
    },
  ] = useMutation<UpsertTagData, UpsertTagVariables>(apollo.admin.mutations.updateTag, {
    notifyOnNetworkStatusChange: true,
    onError (error) {
      console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
    },
    // refetchQueries: ['reloadTag'],
    onCompleted: (data) => {
      console.log('onCompleted called.', {
        reloadTag,
        data,
        'data.updateTag': data.upsertTag,
        'data.updateTag.id': data.upsertTag.id,
      })
      if (data && data.upsertTag && data.upsertTag.id) {
        console.log('onCompleted: will reload in a bit.')
        setIsWaitingToReloadTag(true)
        // setTimeout(() => {
        console.log('onCompleted: reloading now.', { variables: { tagId: data.upsertTag.id } })
        // reloadTagFixed()
        reloadTag({ tagId: data.upsertTag.id })
        // }, 500)
      }
    },
  })

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingTag || isCreatingTag || isUpdatingTag || isWaitingToReloadTag

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  // })

  // ===================================================================================================================
  // Event Handlers:
  const onUpsertTag = (tagInput: TagInput): void => {
    if (tag && tag.updatedAt) {
      setUpdatedAtBeforeSaving(tag.updatedAt)
    }
    if (tagInput.id) {
      updateTag({ variables: { tag: tagInput } })
    } else {
      createTag({ variables: { tag: tagInput } })
    }
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !reloadTag) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadTag().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onGoBack = (): void => {
    navigate(-1)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const pageTitle = tag ? 'Edit Tag' : 'Add Tag'

  return (
    <IonPage className='app-page-admin edit-tag-page'>
      <NavBar
        title={pageTitle}
        goBackUri={goBackUri}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <TagForm
          tag={tagId ? tag : null}
          isProcessing={isProcessing}
          onGoBack={onGoBack}
          onSave={onUpsertTag}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default EditTagPage
