// import handleDeletedInboxItems from './handleDeletedInboxItem'
// import handleNewOrUpdatedInboxItems from './handleNewOrUpdatedInboxItem'
import reloadChat from './reloadChat'
import reloadContact from './reloadContact'
import reloadUserPurchasesIfNeeded from './reloadUserPurchasesIfNeeded'
import shouldReloadContacts from './shouldReloadContacts'
import updateCachedInbox from './updateCachedInbox'

export default {
  // handleNewOrUpdatedInboxItems,
  // handleDeletedInboxItems,
  reloadChat,
  reloadContact,
  reloadUserPurchasesIfNeeded,
  shouldReloadContacts,
  updateCachedInbox,
}
