import React from 'react'
import './styles.css'
import { addCircleOutline, createOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

type Props = {
  label: string
  value?: string | null | undefined
  isInPlaceEditable?: boolean
  noBottomMargin?: boolean
  className?: string
  navigateToRoute?: (route: string) => void
  onClick?: () => void
}
const InfoPaneEntry: React.FC<Props> = (props): JSX.Element | null => {
  const {
    label,
    value,
    isInPlaceEditable,
    noBottomMargin,
    className,
    navigateToRoute,
    onClick,
    children,
  } = props

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  let renderedValue: JSX.Element | undefined
  if (value) {
    renderedValue = (
      <div className='info-pane-value'>{value}</div>
    )
  } else if (isInPlaceEditable && !value && !children) {
    renderedValue = (
      <div className='lightText rowWithCenterAlignedItems withSmallTopMargin'>
        <IonIcon className='withSmallRightMargin' icon={addCircleOutline} />
        Add&nbsp;{label}
      </div>
    )
  }

  let labelSection: JSX.Element | undefined
  if (isInPlaceEditable) {
    labelSection = (
      <div className='rowWithCenterAlignedItems'>
        <div className='info-pane-label'>
          {label}
        </div>
        {(value || children) && <IonIcon icon={createOutline} className='info-pane-edit-icon' />}
      </div>
    )
  } else {
    labelSection = (
      <div className='info-pane-label'>
        {label}
      </div>
    )
  }

  return (
    <div
      className={`info-pane-entry ${className || ''}`}
      style={{ marginBottom: noBottomMargin ? 0 : 'var(--standard-margin)' }}
      onClick={handleClick}
    >
      {labelSection}
      {children}
      {renderedValue}
    </div>
  )
}

export default InfoPaneEntry
