import React from 'react'
import { heartOutline } from 'ionicons/icons'

import type { Contact } from '../../../../lib/core/definitions'
import WishList from '../../../../components/WishList/WishList'

type Props = {
  contact: Contact | null | undefined
  onOpenWishExternalUrl: (wishId: string, url: string) => void
  showUiMessage: (message: string) => void
}

const WishesSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contact,
    onOpenWishExternalUrl,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // Rendering:
  if (!contact) {
    return null
  }

  let content: JSX.Element | undefined
  if (contact && contact.toUser) {
    const emptyListText = `${contact.toUser.fullName || 'This contact'} hasn't added any wishes yet, or is not sharing their list.`
    if (contact.toUser.wishes && contact.toUser.wishes.length >= 0) {
      content = (
        <div className='withStandardLeftMargin withStandardRightMargin'>
          <WishList
            wishes={contact.toUser.wishes}
            owner='contact'
            readOnly
            helpText='Wishes can be anything and are meant to create gifting inspiration!'
            userId={contact.toUser.id}
            icon={heartOutline}
            emptyListText={emptyListText}
            className='withStandardLeftMargin withStandardRightMargin'
            onOpenExternalUrl={onOpenWishExternalUrl}
            showUiMessage={showUiMessage}
          />
        </div>
      )
    }
  }

  return (
    <div className='autoOverflowContainer withStandardMargin'>
      {content}
    </div>
  )
}

export default WishesSection
