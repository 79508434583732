import React from 'react'
import { IonItem } from '@ionic/react'

import './styles.css'
import type { Chat } from '../../../lib/core/definitions'
import Avatar from '../../../components/Avatar/Avatar'

type Props = {
  chat: Chat;
  onClick: (chatId: string) => void;
}

const OrgChatListItem: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('OrgChatListItem.render called.', { props })
  const { chat, onClick } = props

  // ===================================================================================================================
  // Event Handlers:
  const handleClick = (): void => {
    if (onClick) {
      onClick(chat.id as string)
    }
  }

  // ===================================================================================================================
  // Rendering:
  let contactFullName = ''
  let contactUsername = ''
  let contactImageUrl = ''

  if (chat.toUser) {
    contactFullName = chat.toUser.fullName || ''
    contactUsername = chat.toUser.username || ''
    contactImageUrl = chat.toUser.imageUrl || ''
  }

  const unreadMessageCount = chat.metadata && chat.metadata.toUser
    ? chat.metadata.toUser.unreadMessageCount
    : 0

  const avatarUser = {
    fullName: contactFullName,
    imageUrl: contactImageUrl,
  }
  return (
    <IonItem
      key={chat.id as string}
      detail
      onClick={handleClick}
    >
      <Avatar
        user={avatarUser}
        badge={unreadMessageCount}
        padForBadge
        className='withStandardRightMargin'
      />
      <div>
        <div key='line1' className='contact-name'>
          {contactFullName}
        </div>
        <div key='line2'>
          <span className='lightText'><span className='handleGlyph'>@</span>{contactUsername}</span>
        </div>
      </div>
    </IonItem>
  )
}

export default OrgChatListItem
