import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { Chat, Inbox, ChatListFilter, ApiQueryOptions } from '../../../lib/core/definitions'
import { ErrorCode } from '../../../lib/core/enums'
import type {
  ChatQueryData,
  ChatQueryVariables,
  ApiClientOptions,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import cache from '../cache'
import helpers from './helpers'
import logger from '../../logger'

const index = (
  chatId: string,
  isInTargetStateFunc: ((chat: Chat) => boolean) | 'watch-updated-at' | undefined,
  listFilter: ChatListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
  handleUnreadMessages?: true,
  inbox?: Inbox,
): Promise<Chat | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadChat called.',
    //   { chatId, activeUserId, options, handleUnreadMessages })
    const queryListVariables = clientOptions && clientOptions.updateList
      ? apollo.getChatsQueryVariables(listFilter, activeUserId)
      : undefined
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadChat: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<ChatQueryVariables> = {
      query: apollo.queries.chat,
      variables: { chatId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<ChatQueryData, ChatQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.chat ||
            !resp.data.chat.id
          ) {
            logger.error('api.loadChat.load: query did not return expected data.', { resp })
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((chat: Chat) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.chat.updatedAt
            activeIsInTargetStateFunc = (chat: Chat) => chat.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.chat)
          ) {
            cache.updateChat(
              resp.data.chat,
              queryListVariables,
              activeUserId,
              apolloClient,
            )
            if (handleUnreadMessages) {
              // const activeUserId = globalCacheData.getActiveUserId()
              if (activeUserId) {
                setTimeout(() => {
                  if (resp && resp.data && resp.data.chat && activeUserId) {
                    helpers.handleUnreadMessages(resp.data.chat, activeUserId, inbox, apolloClient)
                  }
                }, 200)
              }
            }
            // console.log('api.loadChat.load: resolving with info.', resp.data.chat)
            resolve(resp.data.chat)
            return
          }

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadChat.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadChat.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default index
