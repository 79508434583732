import React, { useRef } from 'react'
import {
  IonItem,
  IonIcon,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonButton,
} from '@ionic/react'
import {
  createOutline,
  linkOutline,
  trashOutline,
} from 'ionicons/icons'

import type { Wish } from '../../lib/core/definitions'
import IconButton from '../../components/IconButton/IconButton'

type Props = {
  wish: Wish
  icon: string
  readOnly?: boolean
  onClick?: (wishId: string) => void
  onEdit?: (wishId: string) => void
  onDelete?: (wishId: string) => void
  onOpenExternalUrl?: (wishId: string, url: string) => void
}

const WishItem: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('WishItem.render called.', { props })
  const {
    wish,
    icon,
    readOnly,
    onClick,
    onDelete,
    onEdit,
    onOpenExternalUrl,
  } = props

  // ===================================================================================================================
  // State:
  const slidingRef = useRef<HTMLIonItemSlidingElement | null>(null)

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Event Handlers:
  const onClickItem = (): void => {
    if (onClick && wish && wish.id) {
      onClick(wish.id)
    }
  }

  const onDeleteItem = (): void => {
    if (onDelete && wish && wish.id) {
      onDelete(wish.id)
    }
  }

  const onEditItem = (): void => {
    if (onEdit && wish && wish.id) {
      onEdit(wish.id)
      if (slidingRef.current) {
        slidingRef.current.close()
      }
    }
  }

  const onClickProductUrl = (): void => {
    if (wish && wish.id && wish.productUrl && onOpenExternalUrl) {
      onOpenExternalUrl(wish.id, wish.productUrl)
    }
  }

  // ===================================================================================================================
  // Rendering:
  let editButton: JSX.Element | undefined
  if (!readOnly) {
    editButton = (
      <IconButton
        icon={createOutline}
        text='Edit'
        layout='vertical'
        className='button'
        iconSize={20}
        onClick={onEditItem}
      />
    )
  }
  const lines: JSX.Element[] = [
    <div key='title' className='title-row' onClick={onClickItem}>
      <IonIcon
        icon={icon}
        color='medium'
        className='row-icon'
      />
      <div className='wish-title'>
        {wish.title}
      </div>
      {editButton}
    </div>,
  ]
  if (wish.notes) {
    lines.push(
      <div key='notes' className='notes-row'>
        {wish.notes.split('\n').map((item, key) => {
          return <span key={key}>{item}<br /></span>
        })}
      </div>,
    )
  }
  if (wish.productUrl) {
    lines.push(
      <div key='url' className='product-url-row'>
        <IonIcon
          icon={linkOutline}
          color='medium'
          className='row-icon'
        />
        <div className='product-link'>
          {wish.productUrl}
        </div>
        <IonButton
          size='small'
          fill='outline'
          color='medium'
          className='withSmallLeftMargin'
          onClick={onClickProductUrl}
        >
          Visit
        </IonButton>
      </div>,
    )
  }
  let actionOptions
  if (!readOnly) {
    actionOptions = (
      <IonItemSliding key={wish.id as string} ref={slidingRef} className='wish-list-item'>
        <IonItem lines='none' className='wish-item'>
          <div className='item-wrapper'>
            {lines}
          </div>
        </IonItem>
        <IonItemOptions onIonSwipe={onDeleteItem}>
          <IonItemOption color='medium' expandable onClick={onEditItem}>
            <IonIcon icon={createOutline} color='light' slot='top' />
            edit
          </IonItemOption>
          <IonItemOption color='danger' expandable onClick={onDeleteItem}>
            <IonIcon icon={trashOutline} color='light' slot='top' />
            remove
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    )
  } else {
    actionOptions = (
      <div className='wish-list-item'>
        <IonItem onClick={onClickItem} lines='none' className='wish-item'>
          <div className='item-wrapper'>
            {lines}
          </div>
        </IonItem>
      </div>
    )
  }

  return actionOptions
}

export default WishItem
