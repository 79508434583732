import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import type { TopNavTabDef } from '../../components/TopNavBar/definitions'
import { TopNavTabId } from '../../components/TopNavBar/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import EarnRewardsTab from './EarnRewardsTab/EarnRewardsTab'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import RewardsTab from './RewardsTab/RewardsTab'
import TokensHeader from '../../components/TokensHeader/TokensHeader'
import TopNavBar from '../../components/TopNavBar/TopNavBar'
import TransactionsTab from './TransactionsTab/TransactionsTab'

const appPageId = AppPage.TokensPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

export const topNavTabDefs: TopNavTabDef[] = [
  { id: TopNavTabId.EARN_REWARDS, label: 'Earn MIT', slug: 'earn' },
  { id: TopNavTabId.USER_REWARDS, label: 'My Rewards', slug: 'rewards' },
  { id: TopNavTabId.USER_TOKEN_TRANSACTIONS, label: 'MIT Transactions', slug: 'transactions' },
]

type Params = {
  tab: string
}

const TokensPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { tab } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // Helpers:
  let topNavTabId = TopNavTabId.EARN_REWARDS
  if (tab) {
    const def = topNavTabDefs.find(d => d.slug === tab)
    if (def) {
      topNavTabId = def.id
    }
  }

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const {
    activeUser,
    isLoadingActiveUserWallets,
    getActiveUserMimbleWallet,
    reloadActiveUserWallets,
  } = useMimbleData()
  const mimbleWallet = getActiveUserMimbleWallet && getActiveUserMimbleWallet()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [refreshIndex, setRefreshIndex] = useState(0)

  const isProcessing = isLoadingActiveUserWallets

  // ===================================================================================================================
  // Event Handlers:
  const onRefresh = (event?: CustomEvent<RefresherEventDetail>): void => {
    pageMessages && pageMessages.clear()
    if (event && event.type === 'ionRefresh') {
      refreshEvent = event
    }

    reloadActiveUserWallets().then(undefined, (error) => {
      console.error(error)
    })

    // Reloading the content tabs:
    setRefreshIndex(refreshIndex + 1)
  }

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const onNavigateTo = (route: AppRoute | string): void => {
    navigate(route)
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onBuyTokens = (): void => {
    navigate(AppRoute.BUY_MIMBLE_TOKENS)
  }

  const onFinishedLoading = (): void => {
    if (refreshEvent) {
      refreshEvent.detail.complete()
      refreshEvent = undefined
    }
  }

  const onChangeTopTabId = (
    tabId: TopNavTabId,
  ): void => {
    const def = topNavTabDefs.find(d => d.id === tabId)
    if (def) {
      navigate(`${AppRoute.TOKENS}/${def.slug}`)
    }
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let tabContent: JSX.Element | undefined
  if (activeUser) {
    switch (topNavTabId) {
      case TopNavTabId.USER_REWARDS:
        tabContent = (
          <RewardsTab
            refreshIndex={refreshIndex}
            onNavigate={onNavigateTo}
            showUiMessage={showUiMessage}
            onBuyTokens={onBuyTokens}
            onFinishedLoading={onFinishedLoading}
          />
        )
        break
      case TopNavTabId.EARN_REWARDS:
        tabContent = (
          <EarnRewardsTab
            refreshIndex={refreshIndex}
            onBuyTokens={onBuyTokens}
            onFinishedLoading={onFinishedLoading}
            onNavigate={onNavigateTo}
            showUiMessage={showUiMessage}
          />
        )
        break
      case TopNavTabId.USER_TOKEN_TRANSACTIONS:
        tabContent = (
          <TransactionsTab
            refreshIndex={refreshIndex}
            onNavigate={onNavigateTo}
            showUiMessage={showUiMessage}
            onBuyTokens={onBuyTokens}
            onFinishedLoading={onFinishedLoading}
          />
        )
        break
    }
  }

  const contentWrapperClass = topNavTabId === TopNavTabId.USER_TOKEN_TRANSACTIONS || TopNavTabId.USER_REWARDS ? 'g-non-scroll-content-wrapper' : ''

  return (
    <IonPage className='app-page-public tokens-page'>
      <NavBar
        title='Mimble Tokens (MIT)'
        goBackUri={AppRoute.WALLET}
        onRefresh={onRefresh}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <TokensHeader
        wallet={mimbleWallet}
        onBuyTokens={onBuyTokens}
      />
      <TopNavBar
        tabs={topNavTabDefs}
        onClickTab={onChangeTopTabId}
        activeTabId={topNavTabId || TopNavTabId.EARN_REWARDS}
      />
      <IonContent className='g-content-with-padding'>
        <div className={contentWrapperClass}>
          <PageMessages />
          <IonRefresher slot='fixed' onIonRefresh={onRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {tabContent}
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default TokensPage
