import React from 'react'
import { IonIcon, IonImg } from '@ionic/react'
import { alertCircleOutline, lockClosedOutline } from 'ionicons/icons'

import './styles.css'
import { TransactionProcessor } from '../../../lib/core/enums'

type Props = {
  transactionProcessor: TransactionProcessor | null | undefined
}

const LegalSection: React.FC<Props> = (props): JSX.Element | null => {
  const { transactionProcessor } = props

  let processorNotice: JSX.Element | undefined
  if (transactionProcessor === TransactionProcessor.STRIPE) {
    processorNotice = (
      <div className='powered-by'>
        <div className='powered-by-text'>
          <IonIcon icon={lockClosedOutline} style={{ marginRight: '2px' }} />
          Secure Payments
        </div>
        <IonImg src='assets/images/powered-by-stripe.png' />
      </div>
    )
  }

  return (
    <div className='legal-section'>
      <div className='lightText smallText'>
        By purchasing this gift card you agree to the terms and conditions of the gift card.
      </div>
      <div className='badges'>
        <div className='non-refundable-notice'>
          <IonIcon icon={alertCircleOutline} className='warn-icon' />
          <div className='text'>
            All gift card purchases are final.
          </div>
        </div>
        {processorNotice}
      </div>
    </div>
  )
}

export default LegalSection
