import { AppRoute } from '../../../enums'
import { AppTabId } from '../enums'

const isTabSelected = (
  pathname: string | null | undefined,
  tabId: AppTabId,
): boolean => {
  if (!pathname) {
    return false
  }
  switch (tabId) {
    case AppTabId.WALLET:
      return (
        !!pathname.match(/^\/wallet\/?/) ||
        !!pathname.match(/^\/card\//) ||
        !!pathname.match(/^\/barcode\/?/) ||
        !!pathname.match(/^\/gift\/?/) ||
        !!pathname.match(/^\/select-brand\//) ||
        !!pathname.match(/^\/upload-card\//)
      )
    case AppTabId.MARKETPLACE:
      return (
        pathname === AppRoute.MARKETPLACE ||
        !!pathname.match(/^\/marketplace\//) ||
        !!pathname.match(/^\/checkout-cc\/?/) ||
        !!pathname.match(/^\/checkout-token\/?/) ||
        !!pathname.match(/^\/buy-mit\/?/) ||
        !!pathname.match(/^\/checkout-method\/?/) ||
        !!pathname.match(/^\/payment-request\/?/)
      )
    case AppTabId.GIFT:
      return (
        pathname === AppRoute.SEND_GIFT
      )
    case AppTabId.USER_ACCOUNT:
      return (
        pathname === AppRoute.USER_ACCOUNT ||
        !!pathname.match(/^\/account\/?/)
      )
    // case AppTabId.UPLOAD:
    //   return (
    //     pathname === '/select-brand/upload-card' ||
    //     !!pathname.match(/^\/upload-card\//)
    //   )
    case AppTabId.CONTACTS:
      return (
        pathname === AppRoute.CONTACTS ||
        pathname === AppRoute.MESSAGES ||
        pathname === AppRoute.CONTACTS ||
        !!pathname.match(/^\/contact\/?/) ||
        !!pathname.match(/^\/messages\/?/)
      )
  }
  return false
}

export default isTabSelected
