import type { FormEvent } from 'react'
import React from 'react'
import { IonSpinner, IonButton } from '@ionic/react'

import './styles.css'

type Props = {
  // Objects
  color?: string;
  className?: string;
  disabled?: boolean;
  // Flags:
  isProcessing?: boolean;
  /** If set, this will execute event.preventDefault() and insert a hidden submit input tag to
   * enable the form to be submitted with the return key.
  **/
  noPreventDefault?: boolean;
  [x: string]: any;

  // Functions:
  onClick: (event?: FormEvent) => void;
}

const SubmitButton: React.FC<Props> = (props): JSX.Element => {
  const {
    color,
    className,
    disabled,
    isProcessing,
    noPreventDefault,
    onClick,
    children,
    ...other
  } = props

  const onButtonClick = (event: FormEvent): void => {
    if (!noPreventDefault) {
      event.preventDefault()
    }
    onClick(event)
  }

  let hiddenSubmitInput
  if (!noPreventDefault) {
    hiddenSubmitInput = <input type='submit' style={{ display: 'none' }} />
  }

  const spinner = isProcessing ? <IonSpinner className='spinner' /> : undefined

  return (
    <>
      <IonButton
        color={color}
        className={className ? `submit-button ${className || ''}` : 'submit-button'}
        disabled={disabled}
        onClick={onButtonClick}
        {...other}
      >
        {children}
        {spinner}
      </IonButton>
      {hiddenSubmitInput}
    </>
  )
}

export default SubmitButton
