import { gql } from '@apollo/client'

export default gql`
  query Q($filter: FindUserFilter!) {
    findUser (filter: $filter) { 
      id
      fullName
      nickname
      username
      email
      phoneNumber
      inviteCode
      imageUrl
      isOrganization
    }
  }
`
