import CryptoJS from 'crypto-js'

export default {
  encrypt: (text: string, key: string): string => {
    if (!text || !key) {
      return text
    }
    return CryptoJS.AES.encrypt(text, key).toString()
  },

  decrypt: (text: string, key: string): string => {
    if (!text || !key) {
      return text
    }
    try {
      const clearData = CryptoJS.AES.decrypt(text, key)
      if (!clearData) {
        return ''
      }
      return clearData.toString(CryptoJS.enc.Utf8)
    } catch (error) {
      console.warn('crypto.decrypt: error', { text, key, error })
      return ''
    }
  },
}
