import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { Merchant, MerchantListItem } from '../../../lib/core/definitions'
import type {
  MerchantQueryData,
  MerchantQueryVariables,
  MerchantsQueryData,
  MerchantsQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  merchant: Merchant,
  queryListVariables: MerchantsQueryVariables,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateMerchant.updateInList called.', { merchant })
  if (!merchant) {
    logger.error('api.cache.updateMerchant.updateInList: no merchant given.')
    return
  }

  try {
    const cachedMerchantsData = apolloClient.readQuery<MerchantsQueryData, MerchantsQueryVariables>({
      query: apollo.queries.merchants(),
      variables: queryListVariables,
    })

    if (
      !cachedMerchantsData ||
      !Array.isArray(cachedMerchantsData.merchants) ||
      cachedMerchantsData.merchants.length < 1
    ) {
      const listItem = coreHelpers.models.merchant.updateMerchantListItemFromMerchant(undefined, merchant)
      apolloClient.writeQuery<MerchantsQueryData, MerchantsQueryVariables>({
        query: apollo.queries.merchants(),
        variables: queryListVariables,
        data: { merchants: [listItem] },
      })
      return
    }

    const updatedMerchants = coreHelpers.models.updateModelInList<MerchantListItem, Merchant>(
      cachedMerchantsData.merchants,
      merchant,
      undefined,
      true,
      coreHelpers.models.merchant.updateMerchantListItemFromMerchant,
    )

    apolloClient.writeQuery<MerchantsQueryData, MerchantsQueryVariables>({
      query: apollo.queries.merchants(),
      variables: queryListVariables,
      data: { merchants: updatedMerchants },
    })
  } catch (error) {
    logger.error('api.cache.updateMerchant.updateInList: error.', { merchant, error })
  }
}

const updateMerchant = (
  merchant: Merchant,
  queryListVariables: MerchantsQueryVariables | undefined,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateMerchant called.', { merchant })
  if (!apolloClient) {
    logger.error('api.cache.updateMerchant: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!merchant || !merchant.id) {
    logger.error('api.cache.updateMerchant: no merchant given.', { merchant })
    return
  }

  try {
    apolloClient.writeQuery<MerchantQueryData, MerchantQueryVariables>({
      query: apollo.queries.merchant,
      variables: { merchantId: merchant.id },
      data: { merchant },
    })

    if (queryListVariables) {
      updateInList(
        merchant,
        queryListVariables,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateMerchant: error.', { merchant, error })
  }
}

export default updateMerchant
