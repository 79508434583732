import type { GridColumns, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro'
import { ReportType } from '../../../../lib/core/enums'
import type { Report } from '../../../../lib/core/definitions'

// see https://material-ui.com/components/data-grid/columns/
const getDataGridConfig = (report: Report): { rows: GridRowsProp, columns: GridColumns } | undefined => {
  if (report.reportType === ReportType.INVENTORY) {
    const columns: GridColumns = [
      { field: 'id', headerName: 'ID', flex: 1 },
      { field: 'productOptionId', headerName: 'Product Option ID', flex: 2 },
      { field: 'productId', headerName: 'Product ID', flex: 2 },
      { field: 'merchantId', headerName: 'Merchant ID', flex: 2 },
      { field: 'amount', headerName: 'Amount', flex: 2 },
      { field: 'status', headerName: 'Status', flex: 2 },
      { field: 'source', headerName: 'Source', flex: 2 },
      { field: 'merchantName', headerName: 'Merchant Name', flex: 2 },
    ]
    const rows: GridRowsProp = report.data.map((entry: any): GridRowModel => ({
      id: entry.id,
      productOptionId: entry.productOptionId,
      productId: entry.productId,
      merchantId: entry.merchantId,
      amount: entry.amount,
      status: entry.status,
      source: entry.source,
      merchantName: entry.merchantName,
    }))
    return { rows, columns }
  }

  if (report.reportType === ReportType.MEMBER_MESSAGES) {
    const columns: GridColumns = [
      { field: 'id', headerName: 'ID', flex: 1 },
      { field: 'userId', headerName: 'User ID', flex: 1 },
      { field: 'fullName', headerName: 'User Name', flex: 2 },
      { field: 'templateName', headerName: 'Template', flex: 2 },
      { field: 'sentMessagesCount', headerName: 'Messages Sent', flex: 1 },
      { field: 'createdAt', headerName: 'Created At', flex: 1 },
    ]
    const rows: GridRowsProp = report.data.map((entry: any): GridRowModel => ({
      id: entry.id,
      userId: entry.userId,
      fullName: entry.fullName,
      templateName: entry.templateName,
      sentMessagesCount: entry.sentMessagesCount,
      createdAt: entry.createdAt,
    }))
    return { rows, columns }
  }

  if (report.reportType === ReportType.TRANSACTIONS) {
    const columns: GridColumns = [
      { field: 'id', headerName: 'ID', flex: 1 },
      { field: 'from_user_name', headerName: 'Sender', flex: 2 },
      { field: 'to_user_name', headerName: 'Recipient', flex: 2 },
      { field: 'transaction_type', headerName: 'Transaction Type', flex: 1 },
      { field: 'status', headerName: 'Status', flex: 1 },
      { field: 'amount', headerName: 'Amount', flex: 1 },
      { field: 'fund_type', headerName: 'Fund Type', flex: 1 },
      { field: 'processor', headerName: 'Processor', flex: 1 },
      { field: 'merchant_name', headerName: 'Merchant Name', flex: 1 },
      { field: 'purchase_type', headerName: 'Purchase Type', flex: 1 },
      { field: 'created_at', headerName: 'Created At', flex: 1 },
    ]
    const rows: GridRowsProp = report.data.map((entry: any): GridRowModel => ({
      id: entry.id,
      from_user_name: entry.from_user_name,
      to_user_name: entry.to_user_name,
      transaction_type: entry.transaction_type,
      status: entry.status,
      amount: entry.amount,
      fund_type: entry.fund_type,
      processor: entry.processor,
      merchant_name: entry.merchant_name,
      purchase_type: entry.purchase_type,
      created_at: entry.created_at,
    }))
    return { rows, columns }
  }

  if (report.reportType === ReportType.USERS) {
    const columns: GridColumns = [
      { field: 'id', headerName: 'ID', flex: 1 },
      { field: 'fullName', headerName: 'Name', flex: 2 },
      { field: 'username', headerName: 'Username', flex: 2 },
      { field: 'createdAt', headerName: 'Joined', flex: 2 },
      { field: 'sessionEndedAt', headerName: 'Last Seen', flex: 2 },
      { field: 'appVersion', headerName: 'App Vers.', flex: 2 },
      { field: 'os', headerName: 'OS', flex: 2 },
      { field: 'inviterId', headerName: 'Invited By', flex: 2 },
      { field: 'inviterFullName', headerName: 'Invited By ID', flex: 2 },
    ]
    const rows: GridRowsProp = report.data.map((entry: any): GridRowModel => ({
      id: entry.id,
      fullName: entry.fullName,
      username: entry.username,
      createdAt: entry.createdAt,
      sessionEndedAt: entry.sessionEndedAt,
      appVersion: entry.appVersion,
      os: entry.os,
      inviterId: entry.inviterId,
      inviterFullName: entry.inviterFullName,
    }))
    return { rows, columns }
  }
}

export default getDataGridConfig
