import moment from 'moment'

function setEventYearToZero<T> (eventDate: T): T | string {
  if (!eventDate) {
    return eventDate
  }

  if (typeof eventDate === 'string') {
    return `0000${eventDate.slice(4)}`
  }

  return `0000-${moment(eventDate).format('MM-DD')}`
}

export default setEventYearToZero
