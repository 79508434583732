import { isPlatform } from '@ionic/react'
import { Clipboard } from '@capacitor/clipboard'

export default (
  value: string,
  valueName: string,
  showToast?: (message: string) => void,
  toastMessage?: string,
): void => {
  if (isPlatform('capacitor') && Clipboard) {
    Clipboard.write({ string: value })
  } else {
    const tempInput = document.createElement('input')
    tempInput.style.position = 'absolute'
    tempInput.style.left = '-1000px'
    tempInput.style.top = '-1000px'
    tempInput.value = value
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
  }
  if (showToast) {
    showToast(toastMessage || `${valueName || value} copied to clipboard`)
  }
}
