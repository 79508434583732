import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type {
  InboxQueryData,
  InboxQueryVariables,
} from '../../apollo/definitions'
import type { Inbox } from '../../../lib/core/definitions'
import apollo from '../../apollo'
import inboxHelpers from '../../../lib/core/helpers/models/inbox'
import logger from '../../logger'

const deleteInboxItem = (
  inboxItemId: string | undefined,
  modelId: string | undefined,
  userId: string | undefined,
  apolloClient: ApolloClient<any>,
): Inbox | undefined => {
  // console.log('api.cache.deleteInboxItem called.')

  if (!apolloClient) {
    logger.error('api.cache.deleteInboxItem: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  const cachedInboxData = apolloClient.readQuery<InboxQueryData, InboxQueryVariables>({
    query: apollo.queries.inbox,
    variables: { userId },
  })
  // console.log('api.cache.deleteInboxItem: loaded cached inbox data.', { cachedInboxData })

  if (cachedInboxData && cachedInboxData.inbox) {
    let items = cachedInboxData.inbox.items
    if (Array.isArray(items) && items.length > 0) {
      items = items.filter(i => i.id !== inboxItemId && i.modelId !== modelId)
    }
    const newInbox = inboxHelpers.composeInboxFromItems(
      (userId || cachedInboxData.inbox.userId) as string,
      items || [],
    )
    newInbox.id = cachedInboxData.inbox.id
    newInbox.recordStatus = cachedInboxData.inbox.recordStatus
    newInbox.createdAt = cachedInboxData.inbox.createdAt
    newInbox.updatedAt = cachedInboxData.inbox.updatedAt
    // console.log('api.cache.deleteInboxItem: updated inbox=', { newInbox })

    apolloClient.writeQuery<InboxQueryData>({
      query: apollo.queries.inbox,
      variables: { userId },
      data: { inbox: newInbox },
    })

    return newInbox
  }
}

export default deleteInboxItem
