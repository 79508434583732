import React from 'react'
import { IonButton } from '@ionic/react'

import DeletePendingOrderSection from './DeletePendingOrderSection'

interface Props {
  isActiveShoppingCart: boolean | undefined
  onDeletePendingOrder: () => void;
  onRefreshExchangeRate: () => void;
}

const RequestExpiredSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    isActiveShoppingCart,
    onDeletePendingOrder,
    onRefreshExchangeRate,
  } = props

  return (
    <div className='withCenteredColumnContent'>
      <div>
        Note: This payment request has expired. You can create a new payment
        request for this order anytime.
      </div>
      <DeletePendingOrderSection
        isActiveShoppingCart={isActiveShoppingCart}
        onDeletePendingOrder={onDeletePendingOrder}
      />
      <div className='withCenteredTextAlign'>
        <IonButton onClick={onRefreshExchangeRate}>Renew</IonButton>
      </div>
    </div>
  )
}

export default RequestExpiredSection
