import React from 'react'
import { cardOutline } from 'ionicons/icons'

import { CryptoCurrency, FiatCurrency, FundType, TokenName, TransactionProcessor } from '../../../lib/core/enums'
import type { PaymentChoicesItem } from '../definitions'
import type { ShoppingCart, User, WalletListItem } from '../../../lib/core/definitions'
import getPaymentMethodPermissions from './getPaymentMethodPermissions'
import MimbleTokenImage from '../../../components/MimbleTokenImage/MimbleTokenImage'

// eslint-disable-next-line react/display-name
const getPaymentMethodChoices = (
  shoppingCart: ShoppingCart | null | undefined,
  activeUser: User | null | undefined,
  activeUserWallets: WalletListItem[] | null | undefined,
): PaymentChoicesItem[] => {
  const paymentMethodChoices = getPaymentMethodPermissions(
    shoppingCart,
    activeUser,
    activeUserWallets,
  )

  return [
    {
      key: 'internal',
      value: {
        transactionProcessor: TransactionProcessor.INTERNAL,
        fundType: FundType.TOKEN,
        currency: TokenName.MIMBLE_TOKEN,
      },
      label: 'MIT',
      imageComponent: <MimbleTokenImage size={30} className='token-image' />,
      show: paymentMethodChoices.showMimbleTokens,
      enable: paymentMethodChoices.enableMimbleTokens,
    },
    {
      key: 'cc',
      value: {
        transactionProcessor: TransactionProcessor.STRIPE,
        fundType: FundType.FIAT,
        currency: FiatCurrency.USD,
      },
      label: 'Credit Card',
      icon: cardOutline,
      show: paymentMethodChoices.showCreditCard,
      enable: paymentMethodChoices.enableCreditCard,
    },
    {
      key: 'btc',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.BITCOIN,
      },
      label: 'Bitcoin',
      imageSource: process.env.PUBLIC_URL + '/assets/images/bitcoin-logo.png',
      show: paymentMethodChoices.showBitcoin,
      enable: paymentMethodChoices.enableBitcoin,
    },
    {
      key: 'bch',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.BITCOINCASH,
      },
      label: 'Bitcoin Cash',
      imageSource: process.env.PUBLIC_URL + '/assets/images/bitcoincash--logo.png',
      show: paymentMethodChoices.showBitcoinCash,
      enable: paymentMethodChoices.enableBitcoinCash,
    },
    {
      key: 'ada',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.CARDANO,
      },
      label: 'Cardano',
      imageSource: process.env.PUBLIC_URL + '/assets/images/cardano-logo.png',
      show: paymentMethodChoices.showCardano,
      enable: paymentMethodChoices.enableCardano,
    },
    {
      key: 'doge',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.DOGE,
      },
      label: 'DOGE',
      imageSource: process.env.PUBLIC_URL + '/assets/images/doge-logo.png',
      show: paymentMethodChoices.showDoge,
      enable: paymentMethodChoices.enableDoge,
    },
    {
      key: 'eth',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.ETHEREUM,
      },
      label: 'Ethereum',
      imageSource: process.env.PUBLIC_URL + '/assets/images/ethereum-logo.png',
      show: paymentMethodChoices.showEthereum,
      enable: paymentMethodChoices.enableEthereum,
    },
    {
      key: 'etc',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.ETHEREUMCLASSIC,
      },
      label: 'Ethereum Classic',
      imageSource: process.env.PUBLIC_URL + '/assets/images/ethereumclassic-logo.png',
      show: paymentMethodChoices.showEthereumClassic,
      enable: paymentMethodChoices.enableEthereumClassic,
    },
    {
      key: 'ltc',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.LITECOIN,
      },
      label: 'Litecoin',
      imageSource: process.env.PUBLIC_URL + '/assets/images/litecoin-logo.png',
      show: paymentMethodChoices.showLitecoin,
      enable: paymentMethodChoices.enableLitecoin,
    },
    {
      key: 'ltct',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.LITECOIN_TEST,
      },
      label: 'Litecoin Test',
      imageSource: process.env.PUBLIC_URL + '/assets/images/litecoin-logo.png',
      show: paymentMethodChoices.showLitecoinTest,
      enable: paymentMethodChoices.enableLitecoinTest,
    },
    {
      key: 'dot',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.POLKADOT,
      },
      label: 'Polkadot',
      imageSource: process.env.PUBLIC_URL + '/assets/images/polkadot-logo.png',
      show: paymentMethodChoices.showPolkadot,
      enable: paymentMethodChoices.enablePolkadot,
    },
    {
      key: 'usdtb',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.TETHER_B,
      },
      label: 'Tether BSC',
      imageSource: process.env.PUBLIC_URL + '/assets/images/tether-logo.png',
      show: paymentMethodChoices.showTetherB,
      enable: paymentMethodChoices.enableTetherB,
    },
    {
      key: 'usdte',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.TETHER_E,
      },
      label: 'Tether ERC',
      imageSource: process.env.PUBLIC_URL + '/assets/images/tether-logo.png',
      show: paymentMethodChoices.showTetherE,
      enable: paymentMethodChoices.enableTetherE,
    },
    {
      key: 'usdcb',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.USDCOIN_B,
      },
      label: 'USDCoin BSC',
      imageSource: process.env.PUBLIC_URL + '/assets/images/usdcoin-logo.png',
      show: paymentMethodChoices.showUsdCoinB,
      enable: paymentMethodChoices.enableUsdCoinB,
    },
    {
      key: 'usdcb',
      value: {
        transactionProcessor: TransactionProcessor.COINPAYMENTS,
        fundType: FundType.CRYPTO,
        currency: CryptoCurrency.USDCOIN_E,
      },
      label: 'USDCoin ERC',
      imageSource: process.env.PUBLIC_URL + '/assets/images/usdcoin-logo.png',
      show: paymentMethodChoices.showUsdCoinE,
      enable: paymentMethodChoices.enableUsdCoinE,
    },
  ]
}

export default getPaymentMethodChoices
