import React, { useContext, useEffect, useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'

import {
  ErrorCode,
  NotificationMethod,
  PurchaseStatus,
  PurchaseTransferStatus,
} from '../lib/core/enums'
import type {
  AnimationName,
  ChatImageType,
} from '../lib/core/enums'
import { GiftFlowDeliveryTimeChoice, GiftFlowStep } from '../enums'
import type { GiftFlowData, GiftMessageDeliveryInfo } from '../definitions'
import type {
  FindUserQueryData,
  FindUserQueryVariables,
  PurchaseQueryData,
  PurchaseQueryVariables,
  PurchaseTransferQueryData,
  PurchaseTransferQueryVariables,
} from '../services/apollo/definitions'
import { GlobalCacheDataKey } from './GlobalCacheContext/enum'
import type {
  Purchase,
  PurchaseTransfer,
  PurchaseTransferInput,
  User,
  UserIdentInfo,
} from '../lib/core/definitions'
import { simpleTimes } from '../pages/SendGiftPage/helpers'
import { useActivePageData } from './ActivePageDataContext'
import { useMimbleData } from './MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from './GlobalCacheContext/GlobalCacheContext'
import api from '../services/api'
import apollo from '../services/apollo'
import coreHelpers from '../lib/core/helpers'
import logger from '../services/logger'

export type StartArgs = {
  purchaseTransferId?: string | null,
  fromPurchaseId?: string | null,
  toUserId?: string | null,
}

const isPurchaseTransferInTargetState = (purchaseTransfer: PurchaseTransfer): boolean => (
  purchaseTransfer.transferStatus === PurchaseTransferStatus.AVAILABLE ||
  purchaseTransfer.transferStatus === PurchaseTransferStatus.SCHEDULED ||
  purchaseTransfer.transferStatus === PurchaseTransferStatus.INVALID
)

const isSourcePurchaseMarkedForTransfer = (purchase: Purchase): boolean => (
  purchase.status === PurchaseStatus.TRANSFERRING ||
  purchase.status === PurchaseStatus.TRANSFERRED
)

const getHourFromTimeChoice = (choice: GiftFlowDeliveryTimeChoice, hour: number): number => (
  choice === GiftFlowDeliveryTimeChoice.ADVANCED_INPUT
    ? hour
    : simpleTimes[choice]
)

export interface GiftFlowContextValue {
  flowId: number | undefined
  purchaseTransferId: string | null | undefined
  fromPurchaseId: string | null | undefined
  toUserId: string | null | undefined
  giftFlowStep: GiftFlowStep | undefined
  setGiftFlowStep: (step: GiftFlowStep | undefined) => void
  giftChanges: PurchaseTransferInput | undefined
  setGiftChanges: (changes: PurchaseTransferInput, goToNextStep: boolean) => void
  recipientUserIdentInfo: UserIdentInfo | undefined
  fromPurchase: Purchase | undefined
  fromPurchaseLoadingError: Error | undefined
  // messageText: string | undefined
  // chatImageUrl: string | undefined
  // chatImageType: ChatImageType | undefined
  // setMessageText: (text: string | undefined) => void
  // setChatImageUrl: (url: string | null | undefined) => void
  // setChatImageType: (imageType: ChatImageType | null | undefined) => void
  purchaseTransfer: PurchaseTransfer | null | undefined
  updatedPurchaseTransfer: PurchaseTransfer | null | undefined
  isProcessing: boolean
  setIsProcessing: (on: boolean) => void

  getCurToUserInfo: () => Partial<User> | undefined
  getCurMessageText: () => string | undefined
  getCurChatImageUrl: () => string | null | undefined
  getCurChatImageType: () => ChatImageType | null | undefined
  getCurChatAnimation: () => AnimationName | null | undefined
  getCurMessageDeliveryInfo: () => GiftMessageDeliveryInfo | undefined
  getGiftFlowStepLabels: () => any
  getGiftFlowSteps: (curNotificationMethod?: NotificationMethod) => GiftFlowStep[]
  getPrevGiftFlowStep: (steps: GiftFlowStep[], step: GiftFlowStep | undefined) => GiftFlowStep | null
  getNextGiftFlowStep: (steps: GiftFlowStep[], step: GiftFlowStep | undefined) => GiftFlowStep | null
  goToNextStep: () => boolean
  goToPrevStep: () => boolean
  getHourFromTimeChoice: (choice: GiftFlowDeliveryTimeChoice, hour: number) => number

  start: (args?: StartArgs) => void
  clear: () => void
  save: () => Promise<void>
}

export const defaultGiftFlowContextValue: GiftFlowContextValue = {
  flowId: undefined,
  purchaseTransferId: undefined,
  fromPurchaseId: undefined,
  toUserId: undefined,
  giftFlowStep: undefined,
  setGiftFlowStep: (step: GiftFlowStep | undefined): void => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.setGiftFlowStep called.', { step })
  },
  giftChanges: undefined,
  setGiftChanges: (changes: PurchaseTransferInput, goToNextStep: boolean): void => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.setGiftChanges called.', { changes, goToNextStep })
  },
  recipientUserIdentInfo: undefined,
  fromPurchase: undefined,
  fromPurchaseLoadingError: undefined,
  // messageText: undefined,
  // chatImageUrl: undefined,
  // chatImageType: undefined,
  // setMessageText: (text: string | undefined): void => {
  //   console.log('GiftFlowContext.defaultGiftFlowContextValue.setMessageText called.', { text })
  // },
  // setChatImageUrl: (url: string | null | undefined): void => {
  //   console.log('GiftFlowContext.defaultGiftFlowContextValue.setChatImageUrl called.', { url })
  // },
  // setChatImageType: (imageType: ChatImageType | null | undefined): void => {
  //   console.log('GiftFlowContext.defaultGiftFlowContextValue.setChatImageType called.', { imageType })
  // },
  purchaseTransfer: undefined,
  updatedPurchaseTransfer: undefined,
  isProcessing: false,
  setIsProcessing: (on: boolean): void => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.setIsProcessing called.', { on })
  },

  getCurToUserInfo: (): Partial<User> | undefined => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getCurToUserInfo called.')
    return undefined
  },
  getCurMessageText: (): string | undefined => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getCurMessageText called.')
    return undefined
  },
  getCurChatImageUrl: (): string | null | undefined => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getCurChatImageUrl called.')
    return undefined
  },
  getCurChatImageType: (): ChatImageType | null | undefined => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getCurChatImageType called.')
    return undefined
  },
  getCurChatAnimation: (): AnimationName | null | undefined => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getCurChatAnimation called.')
    return undefined
  },
  getCurMessageDeliveryInfo: (): GiftMessageDeliveryInfo | undefined => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getCurMessageDeliveryInfo called.')
    return undefined
  },
  getGiftFlowStepLabels: (): any => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getGiftFlowStepLabels called.')
  },
  getGiftFlowSteps: (curNotificationMethod?: NotificationMethod): GiftFlowStep[] => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getGiftFlowSteps called.', { curNotificationMethod })
    return []
  },
  getPrevGiftFlowStep: (steps: GiftFlowStep[], step: GiftFlowStep | undefined): GiftFlowStep | null => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getPrevGiftFlowStep called.', { steps, step })
    return null
  },
  getNextGiftFlowStep: (steps: GiftFlowStep[], step: GiftFlowStep | undefined): GiftFlowStep | null => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getNextGiftFlowStep called.', { steps, step })
    return null
  },
  goToNextStep: (): boolean => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.goToNextStep called.')
    return false
  },
  goToPrevStep: (): boolean => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.goToPrevStep called.')
    return false
  },
  getHourFromTimeChoice: (choice: GiftFlowDeliveryTimeChoice, hour: number): number => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.getHourFromTimeChoice called.', choice, hour)
    return 0
  },

  start: (args?: StartArgs): void => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.start called.', { args })
  },
  clear: (): void => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.clear called.')
  },
  save: (): Promise<void> => {
    console.log('GiftFlowContext.defaultGiftFlowContextValue.save called.')
    return new Promise<void>((resolve) => {
      resolve()
    })
  },
}

export const GiftFlowContext = React.createContext<GiftFlowContextValue>(defaultGiftFlowContextValue)

export function useGiftFlow (): GiftFlowContextValue {
  return useContext(GiftFlowContext)
}

const GiftFlowProvider: React.FC = (props) => {
  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activePageData } = useActivePageData()
  const {
    getIsSignedIn,
    getObj: getGlobalCacheObj,
    setObj: setGlobalCacheObj,
  } = useGlobalCache()
  const { activeUser, reloadPurchases } = useMimbleData()
  const activeUserId = activeUser ? activeUser.id : undefined

  const [flowId, setFlowId] = useState<number | undefined>(0)
  const [initialPurchaseTransferId, setInitialPurchaseTransferId] = useState<string | null | undefined>()
  const [initialFromPurchaseId, setInitialFromPurchaseId] = useState<string | null | undefined>()
  const [initialToUserId, setInitialToUserId] = useState<string | null | undefined>()
  const [giftFlowStep, setGiftFlowStep] = useState<GiftFlowStep | undefined>()
  const [giftChanges, setGiftChanges] = useState<PurchaseTransferInput | undefined>()
  const [updatedPurchaseTransfer, setUpdatedPurchaseTransfer] = useState<PurchaseTransfer | null | undefined>()
  const [isProcessing, setIsProcessing] = useState(false)

  let purchaseTransferId = initialPurchaseTransferId
  if (!purchaseTransferId && updatedPurchaseTransfer) {
    purchaseTransferId = updatedPurchaseTransfer.id || undefined
  }

  let fromPurchaseId = initialFromPurchaseId
  if (giftChanges && giftChanges.fromPurchaseId) {
    fromPurchaseId = giftChanges.fromPurchaseId
  }

  let toUserId: string | null | undefined = initialToUserId
  if (giftChanges && (giftChanges.toUserId || giftChanges.toUserId === null)) {
    toUserId = giftChanges.toUserId
  }
  if (
    !toUserId &&
    toUserId !== null &&
    updatedPurchaseTransfer &&
    updatedPurchaseTransfer.toUserId
  ) {
    toUserId = updatedPurchaseTransfer.toUserId
  }

  // useEffect(() => {
  //   console.log('GiftFlowContext.useEffect[activePageData called.', activePageData)
  // }, [activePageData])

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading existing purchase transfer:
  const {
    data: purchaseTransferLoadedData,
    error: purchaseTransferLoadingError,
  } = useQuery<PurchaseTransferQueryData, PurchaseTransferQueryVariables>(
    apollo.queries.purchaseTransfer, {
      variables: { purchaseTransferId: purchaseTransferId as string },
      skip: !purchaseTransferId || !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
    },
  )
  const purchaseTransfer = purchaseTransferLoadedData && purchaseTransferLoadedData.purchaseTransfer
  const targets = [giftChanges, updatedPurchaseTransfer, purchaseTransfer]
  if (
    !toUserId &&
    toUserId !== null &&
    purchaseTransfer &&
    purchaseTransfer.toUserId
  ) {
    toUserId = purchaseTransfer.toUserId
  }
  if (!fromPurchaseId && purchaseTransfer && purchaseTransfer.fromPurchaseId) {
    fromPurchaseId = purchaseTransfer.fromPurchaseId
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Loading source purchase:
  const {
    data: fromPurchaseLoadedData,
    error: fromPurchaseLoadingError,
  } = useQuery<PurchaseQueryData, PurchaseQueryVariables>(
    apollo.queries.purchase, {
      variables: { purchaseId: fromPurchaseId as string },
      skip: !fromPurchaseId || !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (
          data &&
          data.purchase &&
          !purchaseTransfer
        ) {
          const sentPurchaseTransfer = coreHelpers.models.purchase.getPurchaseTransferFromPurchase(data.purchase)
          if (sentPurchaseTransfer) {
            if (
              sentPurchaseTransfer.transferStatus !== PurchaseTransferStatus.SCHEDULED &&
              sentPurchaseTransfer.transferStatus !== PurchaseTransferStatus.AVAILABLE &&
              sentPurchaseTransfer.transferStatus !== PurchaseTransferStatus.INVALID
            ) {
              // This purchase transfer can't be edited.
              setInitialFromPurchaseId(null)
            } else {
              setInitialPurchaseTransferId(sentPurchaseTransfer.id)
            }
          }
        }
      },
    },
  )
  const fromPurchase = fromPurchaseLoadedData && fromPurchaseLoadedData.purchase

  // -------------------------------------------------------------------------------------------------------------------
  // Loading recipient user info:
  const {
    data: recipientUserIdentInfoLoadedData,
  } = useQuery<FindUserQueryData, FindUserQueryVariables>(
    apollo.queries.findUser, {
      variables: { filter: { id: toUserId, includeMimbleOrg: false } },
      skip: !toUserId || !activeUserId || !getIsSignedIn(),
      // notifyOnNetworkStatusChange: true,
    },
  )
  let recipientUserIdentInfo: UserIdentInfo | undefined
  if (
    recipientUserIdentInfoLoadedData &&
    Array.isArray(recipientUserIdentInfoLoadedData.findUser) &&
    recipientUserIdentInfoLoadedData.findUser.length === 1
  ) {
    recipientUserIdentInfo = recipientUserIdentInfoLoadedData.findUser[0]
  }

  // Loads up this context from local storage during app launch:
  useEffect(() => {
    if (flowId === 0) {
      const data = getGlobalCacheObj<GiftFlowData>(GlobalCacheDataKey.GIFT_FLOW_DATA, true)
      if (data) {
        const {
          flowId,
          initialPurchaseTransferId,
          initialFromPurchaseId,
          initialToUserId,
          giftChanges,
        } = data
        if (!flowId && !initialFromPurchaseId && !initialToUserId && !giftChanges) {
          setFlowId(undefined)
          return
        }
        if (data.flowStep) {
          setGiftFlowStep(data.flowStep)
        }
        if (initialFromPurchaseId) {
          setInitialFromPurchaseId(initialFromPurchaseId)
        }
        if (initialPurchaseTransferId) {
          setInitialPurchaseTransferId(initialPurchaseTransferId)
        }
        if (initialToUserId) {
          setInitialToUserId(initialToUserId)
        }
        if (giftChanges) {
          setGiftChanges(giftChanges)
        }
        // Call setFlowId last, since it will trigger a UI re-render
        if (flowId) {
          setFlowId(flowId || new Date().getTime())
        }
      }
    }
  }, [flowId])

  // ===================================================================================================================
  // Event Handlers:
  const clear = (assignNewFlowId = false, saveToGlobalCache = true): void => {
    console.log('GiftFlowContext.clear called.')
    const newFlowId = assignNewFlowId ? new Date().getTime() : undefined
    setGiftFlowStep(undefined)
    setInitialFromPurchaseId(undefined)
    setInitialToUserId(undefined)
    setGiftChanges(undefined)
    setUpdatedPurchaseTransfer(undefined)
    setIsProcessing(false)
    if (saveToGlobalCache) {
      setGlobalCacheObj<GiftFlowData>(
        GlobalCacheDataKey.GIFT_FLOW_DATA,
        {
          flowId: newFlowId,
          flowStep: giftFlowStep,
          initialPurchaseTransferId,
          initialFromPurchaseId,
          initialToUserId,
          giftChanges,
        },
        true,
      )
    }
    // Call this last, as it will trigger a UI re-render
    setFlowId(newFlowId)
  }

  const start = (args?: StartArgs): void => {
    console.log('GiftFlowContext.start called.', { args, activePageData })
    const newFlowId = new Date().getTime()
    const initialPurchaseTransferId = (
      (args && args.purchaseTransferId) ||
      (activePageData && activePageData.purchaseTransferId)
    )
    const initialFromPurchaseId = (
      (args && args.fromPurchaseId) ||
      (activePageData && activePageData.purchaseId)
    )
    let initialToUserId = args && args.toUserId
    if (
      !initialToUserId &&
      activePageData &&
      activePageData.contactUser &&
      activePageData.contactUser.username &&
      activePageData.contactUser.username !== 'mimble'
    ) {
      initialToUserId = activePageData.contactUser.id
    }

    // console.log('GiftFlowContext.start: initial data collected.',
    //   { initialFromPurchaseId, initialPurchaseTransferId, initialToUserId, activePageData })

    const newFlowStep = (
      initialPurchaseTransferId ||
      initialFromPurchaseId ||
      initialToUserId
    )
      ? GiftFlowStep.RECIPIENT
      : GiftFlowStep.RECIPIENT

    setInitialPurchaseTransferId(initialPurchaseTransferId)
    setInitialFromPurchaseId(initialFromPurchaseId)
    setInitialToUserId(initialToUserId)
    setGiftChanges(undefined)
    setUpdatedPurchaseTransfer(undefined)
    setIsProcessing(false)

    setGlobalCacheObj<GiftFlowData>(
      GlobalCacheDataKey.GIFT_FLOW_DATA,
      {
        flowId: newFlowId,
        flowStep: newFlowStep,
        initialPurchaseTransferId,
        initialFromPurchaseId,
        initialToUserId,
        giftChanges: undefined,
      },
      true,
    )

    // Call this last, as it will trigger a UI re-render
    setFlowId(newFlowId)
    setGiftFlowStep(newFlowStep)
  }

  const getCurToUserInfo = (): Partial<User> | undefined => {
    for (let i = 0; i < 3; i++) {
      const target = targets[i]
      if (target) {
        if (target.toUserId) {
          if (recipientUserIdentInfo && recipientUserIdentInfo.id === target.toUserId) {
            return {
              id: recipientUserIdentInfo.id,
              username: recipientUserIdentInfo.username,
              fullName: recipientUserIdentInfo.fullName,
              email: recipientUserIdentInfo.email,
              phoneNumber: recipientUserIdentInfo.phoneNumber,
              imageUrl: recipientUserIdentInfo.imageUrl,
            }
          }
          return {
            id: target.toUserId,
          }
        }
        if (target.toUserFullName) {
          return {
            fullName: target.toUserFullName,
            email: target.toUserEmail,
            phoneNumber: target.toUserPhoneNumber,
          }
        }
      }
    }
    if (toUserId) {
      if (recipientUserIdentInfo && recipientUserIdentInfo.id === toUserId) {
        return {
          id: toUserId,
          username: recipientUserIdentInfo.username,
          fullName: recipientUserIdentInfo.fullName,
          email: recipientUserIdentInfo.email,
          phoneNumber: recipientUserIdentInfo.phoneNumber,
          imageUrl: recipientUserIdentInfo.imageUrl,
        }
      }
      return { id: toUserId }
    }
  }

  const getCurMessageText = (): string | undefined => {
    for (let i = 0; i < 3; i++) {
      const target = targets[i]
      if (target && target.messageText) {
        return target.messageText
      }
    }
  }

  const getCurChatImageUrl = (): string | null | undefined => {
    for (let i = 0; i < 3; i++) {
      const target = targets[i]
      if (target && (target.chatImageUrl || target.chatImageUrl === null)) {
        return target.chatImageUrl
      }
    }
  }

  const getCurChatImageType = (): ChatImageType | null | undefined => {
    for (let i = 0; i < 3; i++) {
      const target = targets[i]
      if (target && (target.chatImageType || target.chatImageType === null)) {
        return target.chatImageType
      }
    }
  }

  const getCurChatAnimation = (): AnimationName | null | undefined => {
    for (let i = 0; i < 3; i++) {
      const target = targets[i]
      if (target && (target.animation || target.animation === null)) {
        return target.animation
      }
    }
  }

  const getCurMessageDeliveryInfo = (): GiftMessageDeliveryInfo | undefined => {
    for (let i = 0; i < 3; i++) {
      const target = targets[i]
      if (target && target.notificationMethod) {
        return {
          notificationMethod: target.notificationMethod,
          messageScheduledAt: target.messageScheduledAt,
          messageScheduledTimezone: target.messageScheduledTimezone,
        }
      }
    }
  }

  const getGiftFlowStepLabels = (): any => ({
    [GiftFlowStep.RECIPIENT]: 'Recipient',
    [GiftFlowStep.GIFT]: 'Gift',
    [GiftFlowStep.SCHEDULE]: 'Schedule',
    [GiftFlowStep.MESSAGE]: 'Message',
    [GiftFlowStep.CONFIRM]: purchaseTransferId ? 'Send' : 'Save',
    [GiftFlowStep.FINISHED]: 'Done',
  })

  const getGiftFlowSteps = (curNotificationMethod?: NotificationMethod): GiftFlowStep[] => {
    if (!curNotificationMethod) {
      const deliveryInfo = getCurMessageDeliveryInfo()
      curNotificationMethod = (deliveryInfo && deliveryInfo.notificationMethod) || undefined
    }
    return Object.values(GiftFlowStep).filter((step) => {
      if (
        [
          GiftFlowStep.CONFIRM,
          GiftFlowStep.FINISHED,
        ].includes(step)
      ) {
        return false
      }
      if (step === GiftFlowStep.GIFT && purchaseTransferId) {
        return false
      }
      if (
        step === GiftFlowStep.MESSAGE &&
        curNotificationMethod &&
        curNotificationMethod === NotificationMethod.OFF
      ) {
        return false
      }
      return true
    })
  }

  const getPrevGiftFlowStep = (steps: GiftFlowStep[], step: GiftFlowStep | undefined): GiftFlowStep | null => {
    if (!step) {
      return null
    }
    const idx = steps.indexOf(step)
    if (idx > 0) {
      return steps[idx - 1]
    }
    if (step === GiftFlowStep.CONFIRM) {
      return steps[steps.length - 1]
    }
    return null
  }

  const getNextGiftFlowStep = (steps: GiftFlowStep[], step: GiftFlowStep | undefined): GiftFlowStep | null => {
    if (!step) {
      return steps[0]
    }
    const idx = steps.indexOf(step)
    if ((idx || idx === 0) && idx < steps.length - 1) {
      return steps[idx + 1]
    }
    if (idx === steps.length - 1) {
      return GiftFlowStep.CONFIRM
    }
    return null
  }

  const goToNextStep = (): boolean => {
    const steps = getGiftFlowSteps()
    const nextStep = getNextGiftFlowStep(steps, giftFlowStep)
    if (nextStep) {
      setGiftFlowStep(nextStep)
      setGlobalCacheObj<GiftFlowData>(
        GlobalCacheDataKey.GIFT_FLOW_DATA,
        {
          flowId,
          flowStep: nextStep,
          initialPurchaseTransferId,
          initialFromPurchaseId,
          initialToUserId,
          giftChanges,
        },
        true,
      )
      return true
    }
    return false
  }

  const goToPrevStep = (): boolean => {
    const steps = getGiftFlowSteps()
    const prevStep = getPrevGiftFlowStep(steps, giftFlowStep)
    if (prevStep) {
      setGiftFlowStep(prevStep)
      setGlobalCacheObj<GiftFlowData>(
        GlobalCacheDataKey.GIFT_FLOW_DATA,
        {
          flowId,
          flowStep: prevStep,
          initialPurchaseTransferId,
          initialFromPurchaseId,
          initialToUserId,
          giftChanges,
        },
        true,
      )
      return true
    }
    return false
  }

  const setGiftChangesFromOutside = (changes: PurchaseTransferInput | undefined, goToNextStep: boolean) => {
    // console.log('GiftFlowContext.setGiftChangesFromOutside called.', { changes, giftFlowStep })
    setGiftChanges(changes)

    let nextStep: GiftFlowStep | null = null
    if (goToNextStep) {
      let curNotificationMethod: NotificationMethod | undefined
      if (changes && changes.notificationMethod) {
        curNotificationMethod = changes.notificationMethod
      } else {
        const deliveryInfo = getCurMessageDeliveryInfo()
        curNotificationMethod = (deliveryInfo && deliveryInfo.notificationMethod) || undefined
      }
      const steps = getGiftFlowSteps(curNotificationMethod)
      nextStep = getNextGiftFlowStep(steps, giftFlowStep)
      if (nextStep && setGiftFlowStep) {
        setGiftFlowStep(nextStep)
      }
    }

    if (flowId) {
      setGlobalCacheObj<GiftFlowData>(
        GlobalCacheDataKey.GIFT_FLOW_DATA,
        {
          flowId,
          flowStep: nextStep || giftFlowStep,
          initialPurchaseTransferId,
          initialFromPurchaseId,
          initialToUserId,
          giftChanges: changes,
        },
        true,
      )
    }
  }

  const save = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!apolloClient) {
        logger.error('GiftFlowContext.save: no apollo client available.')
        reject(new Error(ErrorCode.SYSTEM_ERROR))
        return
      }

      if (!giftChanges) {
        logger.error('GiftFlowContext.save: not saving because of wrong state or data.')
        reject(new Error(ErrorCode.INVALID_INPUT))
        return
      }

      if (!fromPurchaseId) {
        logger.error('GiftFlowContext.save: not saving because of wrong state or data.')
        reject(new Error(ErrorCode.INVALID_INPUT))
        return
      }

      const purchaseTransferInput: PurchaseTransferInput = { ...giftChanges }

      if (purchaseTransfer) {
        purchaseTransferInput.id = purchaseTransfer.id
      }

      if (!purchaseTransferInput.id && !purchaseTransferInput.fromPurchaseId && fromPurchaseId) {
        purchaseTransferInput.fromPurchaseId = fromPurchaseId
      }

      if (!purchaseTransferInput.id && !purchaseTransferInput.toUserId && toUserId) {
        purchaseTransferInput.toUserId = toUserId
      }

      setIsProcessing(true)
      api.upsertPurchaseTransfer(
        purchaseTransferInput,
        isPurchaseTransferInTargetState,
        undefined,
        activeUserId as string,
        apolloClient,
      ).then((purchaseTransfer) => {
        if (!purchaseTransfer) {
          logger.error('GiftFlowContext.save: upsertPurchaseTransfer did not return data.')
          reject(new Error('Failed to save your changes. Please try again.'))
          setIsProcessing(false)
          return
        }
        // onChange(purchaseTransfer)
        setUpdatedPurchaseTransfer(purchaseTransfer)
        api.loadPurchase(
          purchaseTransfer.fromPurchaseId as string,
          isSourcePurchaseMarkedForTransfer,
          undefined,
          activeUserId as string,
          apolloClient,
        ).then((updatedPurchase) => {
          if (
            updatedPurchase &&
            Array.isArray(updatedPurchase.sentPurchaseTransfers) &&
            updatedPurchase.sentPurchaseTransfers.length > 0
          ) {
            purchaseTransfer = updatedPurchase.sentPurchaseTransfers[updatedPurchase.sentPurchaseTransfers.length - 1]
            setUpdatedPurchaseTransfer(purchaseTransfer)
            // onChange(purchaseTransfer)
          }

          // Reloading all purchases:
          reloadPurchases().then(() => {
            if (setGiftFlowStep) {
              setGiftFlowStep(GiftFlowStep.FINISHED)
            }
            setIsProcessing(false)
          }, (error) => {
            logger.error('GiftFlowContext.save: loadPurchases returned error.',
              { error })
            setIsProcessing(false)
            reject(new Error('Failed to save your changes. Please try again.'))
          })
        }, (error) => {
          logger.error('GiftFlowContext.save: loadPurchase returned error.',
            { error })
          setIsProcessing(false)
          reject(new Error('Failed to save your changes. Please try again.'))
        })
      }, (error) => {
        logger.error('GiftFlowContext.save: upsertPurchaseTransfer returned error.',
          { error })
        setIsProcessing(false)
        reject(new Error('Failed to save your changes. Please try again.'))
      })
    })
  }

  return (
    <GiftFlowContext.Provider
      value={{
        flowId,
        purchaseTransferId,
        fromPurchaseId,
        toUserId,

        giftFlowStep,
        setGiftFlowStep,

        giftChanges,
        setGiftChanges: setGiftChangesFromOutside,

        recipientUserIdentInfo,

        fromPurchase,
        fromPurchaseLoadingError,

        purchaseTransfer,
        updatedPurchaseTransfer,

        isProcessing,
        setIsProcessing,

        getCurToUserInfo,
        getCurMessageText,
        getCurChatImageUrl,
        getCurChatImageType,
        getCurChatAnimation,
        getCurMessageDeliveryInfo,
        getGiftFlowStepLabels,
        getGiftFlowSteps,
        getPrevGiftFlowStep,
        getNextGiftFlowStep,
        goToNextStep,
        goToPrevStep,
        getHourFromTimeChoice,

        start,
        clear,
        save,
      }}
    >
      {props.children}
    </GiftFlowContext.Provider>
  )
}

export default GiftFlowProvider
