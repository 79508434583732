import { CampaignType } from '../../enums'

const labelsEn = Object.freeze({
  [CampaignType.ENGAGEMENT]: 'Engagement',
})

export default {
  getLabel: (messageType: CampaignType | null | undefined, language = 'en'): string => {
    if (!messageType) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[messageType]
    }
    return ''
  },
  isValid: (messageType: CampaignType | string): boolean => (
    Object.values(CampaignType).includes(messageType as CampaignType)
  ),
}
