import numberHelpers from '../../../helpers/number'

type Args = {
  offerPercentage: number;
  offerLevels: number;
  purchaseAmount: number;
};

const getOfferPercentageForAmount = (args: Args): number => {
  const {
    offerLevels,
    purchaseAmount,
  } = args
  let { offerPercentage } = args
  let amount = 0

  if (numberHelpers.isPositiveNumber(offerPercentage, false)) {
    offerPercentage = parseFloat(offerPercentage.toString())
  }
  if (numberHelpers.isPositiveNumber(purchaseAmount, false)) {
    amount = parseFloat(purchaseAmount.toString())
  }

  if (!Array.isArray(offerLevels) || offerLevels.length < 1) {
    return offerPercentage
  }

  if (amount < 0.01) {
    if (offerPercentage) {
      return parseFloat(offerPercentage.toString())
    }
    // Returning the first percentage found in the levels:
    for (let i = 0; i < offerLevels.length; i += 1) {
      if (numberHelpers.isPositiveNumber(offerLevels[i].percentage)) {
        return parseFloat(offerLevels[i].percentage)
      }
    }
    return 0
  }
  for (let i = 0; i < offerLevels.length; i += 1) {
    if (Math.abs(parseFloat(offerLevels[i].amount) - amount) < 0.01) {
      if (offerLevels[i].percentage && numberHelpers.isPositiveNumber(offerLevels[i].percentage, false)) {
        return parseFloat(offerLevels[i].percentage)
      }
      return offerPercentage
    }
  }
  return offerPercentage
}

export default getOfferPercentageForAmount
