import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { ApiQueryOptions, ChatMessage, ChatMessageInput } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertChatMessageMutationData,
  UpsertChatMessageMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'

const upsertChatMessage = (
  chatMessageInput: ChatMessageInput,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ChatMessage | undefined> => (
  // console.log('api.upsertChatMessage called.', { chatMessageInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertChatMessage: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertChatMessageMutationData, UpsertChatMessageMutationVariables> = {
      mutation: apollo.mutations.upsertChatMessage,
      variables: { chatMessage: chatMessageInput },
    }

    apolloClient.mutate<UpsertChatMessageMutationData, UpsertChatMessageMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertChatMessage: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertChatMessage ||
          !resp.data.upsertChatMessage.id
        ) {
          logger.error('api.upsertChatMessage: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }
        // console.log('api.upsertChatMessage: mutation succeeded.', resp)
        resolve(resp.data.upsertChatMessage)
      }, (error) => {
        logger.error('api.upsertChatMessage: error', { error })
        reject(error)
      })
  })
)

export default upsertChatMessage
