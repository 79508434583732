const formatDate = (date: any, includeTime = true): string => {
  if (typeof date === 'string') {
    return date
  }
  if (includeTime) {
    // @ts-ignore
    return new Moment(date).toISOString()
  }
  // @ts-ignore
  return new Moment(date).format('YYYY-MM-DD')
}

export default formatDate
