import React, { useContext, useState } from 'react'
import { IonContent, IonInput, IonPage, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useApolloClient } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import {
  FiatCurrency,
  FundType,
  TokenName,
} from '../../lib/core/enums'
import { AppPage, AppRoute } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useShoppingCart } from '../../contexts/ShoppingCartContext/ShoppingCartContext'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import coreHelpers from '../../lib/core/helpers/'
import FormItem from '../../components/FormItem/FormItem'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import TokensHeader from '../../components/TokensHeader/TokensHeader'
import validationHelpers from '../../helpers/validationHelpers'

const appPageId = AppPage.BuyTokenPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const BuyTokenPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const {
    activeUser,
    getActiveUserMimbleWallet,
    isLoadingActiveUser,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const mimbleWallet = getActiveUserMimbleWallet && getActiveUserMimbleWallet()

  const { setShoppingCart, isSavingShoppingCart } = useShoppingCart()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [amountFiat, setAmountFiat] = useState<number | undefined>()
  const [amountValidationError, setAmountValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const isDirty = !!amountFiat
  const isValid = !!amountFiat

  const resetPage = (): void => {
    setAmountFiat(undefined)
    setAmountValidationError(undefined)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeAmountFiat = (event: any): void => {
    if (!event.detail.value || Number(event.detail.value) < 1) {
      setAmountFiat(undefined)
      setAmountValidationError(undefined)
      return
    }

    const amount = coreHelpers.ui.getInternalMoneyAmount(
      parseFloat(event.detail.value),
      FundType.FIAT,
      FiatCurrency.USD,
    )
    setAmountFiat(amount)
    setAmountValidationError(validationHelpers.validateTokenPurchaseAmount(amount))
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenEarnRewardsPage = (): void => {
    navigate(AppRoute.TOKENS)
  }

  const onAddShoppingCartItem = (event: any): void => {
    event.preventDefault()

    if (!amountFiat) {
      setToastMessage('Missing input.')
      setShowToast(true)
      return
    }

    pageHelpers.addProductToShoppingCart({
      activeUserId,
      amountFiat,
      navigate,
      setShoppingCart,
      apolloClient,
    })
    resetPage()
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let amountFiatDisplay = ''
  if (amountFiat !== null && amountFiat !== undefined) {
    amountFiatDisplay = coreHelpers.ui.getDisplayMoneyAmount(
      amountFiat, FundType.FIAT, FiatCurrency.USD).toString()
  }
  const amountToken = coreHelpers.cryptoCurrency.convertFiatToMimbleToken(amountFiat || 0)
  const amountTokenDisplay = coreHelpers.ui.formatAmount(
    amountToken, FundType.TOKEN, TokenName.MIMBLE_TOKEN)

  const content = (
    <div className='viewWrapper'>
      <form onSubmit={onAddShoppingCartItem}>
        <div className='section'>
          Add Mimble Tokens (MIT) to your Mimble wallet. MIT never expire and you can use them on
          marketplace purchases, or send them to another Mimble member.
        </div>
        <div className='section'>
          Mimble Tokens (MIT) are equivalent to $0.01.
        </div>
        <div className='section'>
          <div className='token-amount'>
            {amountTokenDisplay}
          </div>
          <div className='withCenteredTextAlign lightText largeText'>
            MIT
          </div>
        </div>
        <FormItem
          label='Amount (USD)'
          validationError={amountValidationError}
          withBottomMargin
        >
          <div className='rowWithCenterAlignedItems'>
            <span>$</span>
            <IonInput
              type='number'
              inputmode='numeric'
              onIonChange={onChangeAmountFiat}
              placeholder='amount'
              value={amountFiatDisplay}
            />
          </div>
        </FormItem>
      </form>
      <div className='formButtonWrapper'>
        <SubmitButton
          expand='block'
          disabled={!isDirty || !isValid || isLoadingActiveUser || isSavingShoppingCart}
          onClick={onAddShoppingCartItem}
          isProcessing={isSavingShoppingCart}
        >
          Next
        </SubmitButton>
      </div>
    </div>
  )

  return (
    <IonPage>
      <NavBar
        title='Buy Mimble Token'
        userInfo={activeUser}
        isProcessing={isLoadingActiveUser}
        onOpenUserAccount={onOpenUserAccount}
      />
      <TokensHeader
        wallet={mimbleWallet}
        onOpenEarnRewardsPage={onOpenEarnRewardsPage}
      />
      <IonContent className='buy-token-page g-content-with-padding' scrollY={false}>
        <PageMessages />
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default BuyTokenPage
