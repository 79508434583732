import type { ApolloClient } from '@apollo/client'

import { AppRoute } from '../../enums'
import { TopNavTabId } from '../../components/TopNavBar/enums'
import api from '../../services/api'

type Args = {
  activeUserId: string | null | undefined
  contactId?: string | null | undefined
  contactUserId?: string | null | undefined
  chatId?: string | null | undefined
  tabId?: TopNavTabId
  apolloClient: ApolloClient<any>
  navigate: (route: AppRoute | string, replace?: boolean, state?: any) => void
}

const loadContactAndGo = (args: Args) => {
  const {
    activeUserId,
    contactId,
    contactUserId,
    tabId,
    apolloClient,
    navigate,
  } = args

  api.loadContact(
    contactId,
    activeUserId,
    contactUserId,
    undefined,
    undefined,
    activeUserId as string,
    apolloClient,
  ).then((contact) => {
    if (contactId) {
      navigate(`${AppRoute.CONTACT}/${contactId}/-`, false, { tab: tabId })
      return
    }

    if (contactUserId) {
      navigate(`${AppRoute.CONTACT}/-/${contactUserId}`, false, { tab: tabId })
      return
    }
  })
}

const openContact = (args: Args): void => {
  // console.log('pageHelpers.openContact called.', args)

  const {
    activeUserId,
    contactId,
    contactUserId,
    chatId,
    tabId: tabIdFromArgs,
    apolloClient,
    navigate,
  } = args
  const tabId = tabIdFromArgs || TopNavTabId.CONTACT_MESSAGES

  if (contactId || contactUserId) {
    loadContactAndGo({
      activeUserId,
      contactId,
      contactUserId,
      tabId,
      apolloClient,
      navigate,
    })
    return
  }

  if (!chatId) {
    console.error('pageHelpers.openContact: invalid input', args)
    return
  }

  api.loadChat(
    chatId,
    undefined,
    undefined,
    activeUserId as string,
    apolloClient,
  ).then((chat) => {
    if (!chat || !chat.toUserId) {
      console.error('Failed to create or load the chat.')
      return
    }
    loadContactAndGo({
      activeUserId,
      contactUserId: chat.toUserId,
      tabId,
      apolloClient,
      navigate,
    })
  }, (error) => {
    console.error(error)
  })
}

export default openContact
