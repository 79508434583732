import { IonList } from '@ionic/react'
import React, { useState } from 'react'

import './styles.css'
import type { StoredProductListItem } from '../../../lib/core/definitions'
import {
  RecordStatus,
  StoredProductListScope,
  StoredProductStatus,
} from '../../../lib/core/enums'
import coreHelpers from '../../../lib/core/helpers'
import StoredProductListRow from './StoredProductListRow'

const isStoredProductInScope = (
  scope: StoredProductListScope | undefined,
  status: StoredProductStatus | null | undefined,
  recordStatus: RecordStatus | null | undefined,
): boolean => {
  if (scope === StoredProductListScope.CREATED) {
    return (
      status === StoredProductStatus.CREATED &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  if (!scope || scope === StoredProductListScope.AVAILABLE) {
    return (
      status === StoredProductStatus.AVAILABLE &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  if (scope === StoredProductListScope.RESERVED) {
    return (
      status === StoredProductStatus.RESERVED &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  if (scope === StoredProductListScope.DELIVERED) {
    return (
      status === StoredProductStatus.DELIVERED &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  return false
}

const filterList = (
  list: StoredProductListItem[],
  searchText?: string,
  scope?: StoredProductListScope,
): StoredProductListItem[] => {
  // console.log('StoredProductList.filterList called.', { searchText, scope })
  if (!searchText && !scope) {
    return list
  }
  return list.filter((storedProductListItem: StoredProductListItem): boolean => {
    if (!searchText && !scope) {
      return true
    }
    if (!isStoredProductInScope(scope, storedProductListItem.status, storedProductListItem.recordStatus)) {
      return false
    }
    if (!searchText) {
      return true
    }
    try {
      const reg = new RegExp(searchText, 'i')
      if (storedProductListItem.merchantName && storedProductListItem.merchantName.match(reg)) {
        return true
      }
    } catch (error) {
      return false
    }
    return false
  })
}

type Props = {
  searchText?: string
  filterVersion?: number
  scope?: StoredProductListScope
  storedProducts: StoredProductListItem[] | null | undefined
  onEditStoredProduct?: (productId: string, productOptionId: string, storedProductId: string) => void
}

const StoredProductList: React.FC<Props> = (props): JSX.Element | null => {
  const {
    searchText,
    scope,
    storedProducts,
    onEditStoredProduct,
  } = props
  // const isProcessing = isGettingStoredProducts || isGettingStoredProduct

  // ===================================================================================================================
  // State:
  const [expandedStoredProductId, setExpandedStoredProductId] = useState<string | undefined>()

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(storedProducts) || storedProducts.length < 1) {
    return null
  }
  const items = filterList(storedProducts, searchText, scope).map((stPr: StoredProductListItem): any => (
    <StoredProductListRow
      key={stPr.id as string}
      storedProductListItem={stPr}
      showDetails={coreHelpers.models.compareId(stPr.id, expandedStoredProductId)}
      onEditStoredProduct={onEditStoredProduct}
    />
  ))
  return (
    <IonList>
      <div className='storedProductListRowItemContent storedProductListHeaderRowItemContent'>
        <div className='storedProductListRow0 storedProductListHeaderRow'>
          <div className='storedProductListRowCol storedProductListRowCol0'>
            Brand
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col1'>
            Amount
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col2'>
            Discount
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col3'>
            Code
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col4'>
            Created
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col5'>
            Status
          </div>
        </div>
      </div>
      {items}
    </IonList>
  )
}

export default StoredProductList
