import { parsePhoneNumber } from 'libphonenumber-js/max'

import type { TfaAttribute, ValidationResult } from '../definitions'
import validationHelpers from '../../../../../helpers/validationHelpers'

export default (
  value: string | null | undefined,
  attribute: TfaAttribute,
): ValidationResult => {
  // console.log('PhoneNumberForm.validateInput called.', { value, handleError })
  if (!value) {
    return { valid: false, cleanValue: '' }
  }

  if (attribute === 'email') {
    const validationError = validationHelpers.validateEmail(value)

    return {
      valid: !validationError,
      error: validationError,
      cleanValue: value,
    }
  }

  if (attribute === 'phoneNumber') {
    try {
      const phoneNumberInfo = parsePhoneNumber(value, 'US')
      if (!phoneNumberInfo || !phoneNumberInfo.isValid()) {
        return { valid: false, cleanValue: '', error: 'not a valid phone number' }
      }
      console.log('PhoneNumberForm.validateInput: number is valid.', { cleanValue: phoneNumberInfo.number as string })
      return { valid: true, cleanValue: phoneNumberInfo.number as string }
    } catch (error) {
      console.warn((error as Error).message)
      return { valid: false, cleanValue: value }
    }
  }

  if (attribute === 'password') {
    const validationError = validationHelpers.validatePassword(value)

    return {
      valid: !validationError,
      error: validationError,
      cleanValue: value,
    }
  }

  return {
    valid: false,
    error: 'System error',
    cleanValue: value,
  }
}
