import React from 'react'

import './styles.css'
import type { ChatAttachmentPurchaseTransferInfo } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import GiftCard from '../../GiftCard/GiftCard'
import pageHelpers from '../../../helpers/pageHelpers'

type Props = {
  attachmentInfo: ChatAttachmentPurchaseTransferInfo
  className?: string
  onClick?: () => void
}

const GiftAttachment: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('GiftAttachment.render called.', props)
  const {
    attachmentInfo,
    onClick,
    className,
  } = props

  const formattedAmount = coreHelpers.ui.formatAmount(
    attachmentInfo.amount || attachmentInfo.balance,
    attachmentInfo.fundType,
    attachmentInfo.currency,
    true,
  )
  const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromChatAttachment(attachmentInfo)

  return (
    <div className={`chat-attachment gift-card ${className || ''}`} onClick={onClick}>
      <div className='image'>
        <GiftCard
          purchaseId={attachmentInfo.toPurchaseId as string}
          backgroundImageUri={giftCardBackgroundUri}
          merchantLogoUri={merchantLogoUri}
          small
        />
      </div>
      <div className='smallText lightText'>
        {formattedAmount} to {attachmentInfo.merchantName}
      </div>
    </div>
  )
}

export default GiftAttachment
