import { OAuthUserProvider } from '../../enums'

const labelsEn = Object.freeze({
  [OAuthUserProvider.APPLE]: 'Apple',
  [OAuthUserProvider.FACEBOOK]: 'Facebook',
  [OAuthUserProvider.GOOGLE]: 'Google',
  [OAuthUserProvider.MICROSOFT]: 'Microsoft',
  [OAuthUserProvider.TWITTER]: 'Twitter',
})

const FirebaseProviderId = Object.freeze({
  'apple.com': OAuthUserProvider.APPLE,
  'facebook.com': OAuthUserProvider.FACEBOOK,
  'google.com': OAuthUserProvider.GOOGLE,
  'microsoft.com': OAuthUserProvider.MICROSOFT,
  'twitter.com': OAuthUserProvider.TWITTER,
})

export default {
  getLabel: (type: OAuthUserProvider | null | undefined, language = 'en'): string => {
    if (!type) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[type]
    }
    return ''
  },
  isValid: (type: OAuthUserProvider | string | null | undefined): boolean => Object.values(OAuthUserProvider).includes(type as OAuthUserProvider),
  getProviderByFirebaseProviderId: (firebaseProviderId: string): OAuthUserProvider | undefined => {
    // @ts-ignore
    const id = FirebaseProviderId[firebaseProviderId] as OAuthUserProvider | undefined
    return id
  },
}
