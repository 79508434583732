import { TransactionRemoteStatus } from '../../enums'

const labelsEn = Object.freeze({
  // Coinpayments:
  [TransactionRemoteStatus.PAYPAL_REFUND_OR_REVERSAL]: 'paypal-refund-or-reversal',
  [TransactionRemoteStatus.CANCELED_OR_TIMED_OUT]: 'canceled-or-timed-out',
  [TransactionRemoteStatus.WAITING_FOR_FUNDS]: 'waiting-for-funds',
  [TransactionRemoteStatus.CONFIRMED]: 'confirmed',
  [TransactionRemoteStatus.QUEUED_FOR_NIGHTLY_PAYOUT]: 'queued-for-nightly-payout',
  [TransactionRemoteStatus.PAYPAL_PENDING]: 'paypal-pending',
  [TransactionRemoteStatus.IN_ESCROW]: 'in-escrow',
  [TransactionRemoteStatus.SETTLED]: 'settled',
  // blockchain.info:
  [TransactionRemoteStatus.UNSET]: '--',
  [TransactionRemoteStatus.PENDING]: 'pending',
  [TransactionRemoteStatus.PROCESSING]: 'processing',
  [TransactionRemoteStatus.CANCELED]: 'canceled/rejected',
  [TransactionRemoteStatus.INVALID]: 'invalid/unverified',
  [TransactionRemoteStatus.LOCKED]: 'locked',
  [TransactionRemoteStatus.OK]: 'ok',
})

export default {
  getLabel: (status: TransactionRemoteStatus | null | undefined, language = 'en'): string => {
    if (!status) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[status]
    }
    return ''
  },

  isValid: (status: TransactionRemoteStatus): boolean => Object.values(TransactionRemoteStatus).includes(status),

  isSettled: (status: TransactionRemoteStatus | null | undefined): boolean => (
    !!status &&
    [
      TransactionRemoteStatus.OK,
      TransactionRemoteStatus.QUEUED_FOR_NIGHTLY_PAYOUT,
      TransactionRemoteStatus.SETTLED,
    ].includes(status)
  ),

  isTimedOutOrCanceled: (status: TransactionRemoteStatus | null | undefined): boolean => (
    !!status &&
    [
      TransactionRemoteStatus.CANCELED,
      TransactionRemoteStatus.CANCELED_OR_TIMED_OUT,
      TransactionRemoteStatus.INVALID,
    ].includes(status)
  ),
}
