import type { ApolloClient } from '@apollo/client'

import type { ApiQueryOptions, Wallet, WalletListItem } from '../../lib/core/definitions'
import type { RecordStatus } from '../../lib/core/enums'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  WalletsQueryData,
  WalletsQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import coreHelpers from '../../lib/core/helpers'
import loadWallet from './loadWallet'
import logger from '../logger'

const loadWalletWithNewBalance = (
  walletId: string,
  oldWalletBalance: number | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Wallet | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadWalletWithNewBalance called.', { walletInput })

    let index = 0

    if (!apolloClient) {
      logger.error('api.loadWalletWithNewBalance: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const isInTargetStateFunc = (wallet: Wallet): boolean => {
      if (index > 2) {
        return true
      }
      index += 1
      return coreHelpers.models.wallet.isTokenBalanceUpdated(wallet, oldWalletBalance)
    }

    // console.log('api.loadWalletWithNewBalance: calling loadWallet')
    loadWallet(
      walletId,
      isInTargetStateFunc,
      apollo.getWalletListFilter(activeUserId),
      activeUserId,
      apolloClient,
      queryOptions,
      clientOptions,
    )
      .then((wallet) => {
        // console.log('api.loadWalletWithNewBalance: received updated object.', { wallet })
        if (wallet) {
          try {
            const variables = apollo.getWalletsQueryVariables(undefined, activeUserId)
            const cachedWalletsData = apolloClient.readQuery<WalletsQueryData, WalletsQueryVariables>({
              query: apollo.queries.wallets,
              variables,
            })
            // console.log('api.loadWalletWithNewBalance.update: cachedWalletsData=', cachedWalletsData)
            if (cachedWalletsData && cachedWalletsData.wallets) {
              const updatedWalletList = cachedWalletsData.wallets.map((p) => {
                if (p.id === wallet.id) {
                  const walletListItem: WalletListItem = {
                    id: p.id as string,
                    userId: p.userId as string,
                    walletType: p.walletType,
                    balanceCrypto: p.balanceCrypto,
                    cryptoCurrency: p.cryptoCurrency,
                    balanceFiat: p.balanceFiat,
                    fiatCurrency: p.fiatCurrency,
                    balanceToken: p.balanceToken,
                    tokenName: p.tokenName,
                    recordStatus: p.recordStatus as RecordStatus,
                    createdAt: p.createdAt as string,
                    updatedAt: p.updatedAt as string,
                    updatedBy: p.updatedBy as string,
                  }
                  return coreHelpers.models.wallet.updateWalletListItemFromWallet(walletListItem, wallet)
                }
                return p as WalletListItem
              })
              apolloClient.writeQuery<WalletsQueryData>({
                query: apollo.queries.wallets,
                variables,
                data: { wallets: updatedWalletList },
              })
            }
          } catch (error) {
            logger.error('api.loadWalletWithNewBalance: error', { error })
          }
        }
        // console.log('api.loadWalletWithNewBalance: resolving with wallet', wallet)
        resolve(wallet)
      }, (error) => {
        logger.error('api.loadWalletWithNewBalance: error', { error })
        reject(error)
      })
  })
)

export default loadWalletWithNewBalance
