import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type {
  WishesQueryData,
  WishesQueryVariables,
} from '../../apollo/definitions'
import type { WishListFilter } from '../../../lib/core/definitions'
import apollo from '../../apollo'
import logger from '../../logger'

const deleteActiveUserWish = (
  wishId: string | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.deleteActiveUserWish called.')

  if (!apolloClient) {
    logger.error('api.cache.deleteActiveUserWish: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  const filter: WishListFilter = {
    userId: activeUserId,
    orderBy: 'title',
  }
  const wishesVariables = { filter }

  const cachedWishesData = apolloClient.readQuery<WishesQueryData, WishesQueryVariables>({
    query: apollo.queries.wishes,
    variables: wishesVariables,
  })
  // console.log('api.cache.deleteActiveUserWish: loaded cached wishes data.', { cachedWishesData })

  if (cachedWishesData && cachedWishesData.wishes) {
    const updatedWishes = cachedWishesData.wishes.filter(w => w.id !== wishId)
    // console.log('api.cache.deleteActiveUserWish: updated wishes=', { updatedWishes })

    apolloClient.writeQuery<WishesQueryData>({
      query: apollo.queries.wishes,
      variables: wishesVariables,
      data: { wishes: updatedWishes },
    })
  }
}

export default deleteActiveUserWish
