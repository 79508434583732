import { FirebaseApp } from 'firebase/app'
import {
  Auth,
  getAuth,
  // onAuthStateChanged,
  // FacebookAuthProvider,
  // GoogleAuthProvider,
  // TwitterAuthProvider,
} from 'firebase/auth'

import type { EnvironmentVal, OAuthUser } from '../../lib/core/definitions'
import type { FirebaseConfig } from '../../definitions'
import env from '../../lib/env'

// Auth:
type ListenerCallback = (firebaseUser: OAuthUser) => void
const authStateChangeListeners = new Map<string, ListenerCallback>()
/*
let googleAuthProvider: GoogleAuthProvider | undefined
let twitterAuthProvider: TwitterAuthProvider | undefined
let facebookAuthProvider: FacebookAuthProvider | undefined
*/

const firebaseApps = new Map<EnvironmentVal, FirebaseApp>()

const firebaseServiceData = {
  authStateChangeListeners,

  config: (environment: EnvironmentVal): FirebaseConfig => ({
    apiKey: env('FIREBASE_API_KEY', environment),
    authDomain: env('FIREBASE_AUTH_DOMAIN', environment),
    databaseURL: env('FIREBASE_DATABASE_URL', environment),
    projectId: env('FIREBASE_PROJECT_ID', environment),
    storageBucket: env('FIREBASE_STORAGE_BUCKET', environment),
    messagingSenderId: env('FIREBASE_MESSAGING_SENDER_ID', environment),
    appId: env('FIREBASE_APP_ID', environment),
    measurementId: env('FIREBASE_MEASUREMENT_ID', environment),
  }),

  setFirebaseApp: (environment: EnvironmentVal, app: FirebaseApp | null): void => {
    if (app) {
      firebaseApps.set(environment, app)
    } else {
      firebaseApps.delete(environment)
    }
  },

  getAuth: (app: FirebaseApp): Auth => getAuth(app),

  // getGoogleAuthProvider: (): GoogleAuthProvider => {
  //   if (!googleAuthProvider) {
  //     googleAuthProvider = new GoogleAuthProvider()
  //   }
  //   return googleAuthProvider
  // },

  // getTwitterAuthProvider: (): TwitterAuthProvider => {
  //   if (!twitterAuthProvider) {
  //     twitterAuthProvider = new TwitterAuthProvider()
  //   }
  //   return twitterAuthProvider
  // },

  // getFacebookAuthProvider: (): FacebookAuthProvider => {
  //   if (!facebookAuthProvider) {
  //     facebookAuthProvider = new FacebookAuthProvider()
  //   }
  //   return facebookAuthProvider
  // },

  getFirebaseApp: (environment: EnvironmentVal): FirebaseApp | undefined => {
    return firebaseApps.get(environment)
  },

  isServiceActive: (environment: EnvironmentVal): boolean => {
    return !!firebaseApps.get(environment)
  },
}

export default firebaseServiceData
