import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, Wallet, WalletListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  WalletQueryData,
  WalletQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'

const loadWallet = (
  walletId: string,
  isInTargetStateFunc: ((wallet: Wallet) => boolean) | 'watch-updated-at' | undefined,
  listFilter: WalletListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Wallet | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadWallet called.')
    const queryListVariables = clientOptions && clientOptions.updateList
      ? apollo.getWalletsQueryVariables(listFilter, activeUserId)
      : undefined
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadWallet: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<WalletQueryVariables> = {
      query: apollo.queries.wallet,
      variables: { walletId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<WalletQueryData, WalletQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.wallet ||
            !resp.data.wallet.id
          ) {
            console.log('api.loadWallet.load: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((wallet: Wallet) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.wallet.updatedAt
            activeIsInTargetStateFunc = (wallet: Wallet) => wallet.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.wallet)
          ) {
            // console.log('api.loadWallet.load: resolving with info.', resp.data.wallet)
            cache.updateWallet(
              resp.data.wallet,
              queryListVariables,
              activeUserId,
              apolloClient,
            )
            resolve(resp.data.wallet)
            return
          }

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadWallet.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadWallet.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadWallet
