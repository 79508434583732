import type { Currency } from '../../definitions'
import { FiatCurrency } from '../../enums'
import type { FundType } from '../../enums'
import getAmountDisplayFactor from './getAmountDisplayFactor'
import getAmountPrecision from './getAmountPrecision'

const formatAmount = (
  amount: number | string | null | undefined,
  fundType: FundType | null | undefined,
  currency: Currency | null | undefined,
  showCurrency = false,
  precision = 99,
  locale = 'en-US',
): string => {
  if (amount === null || amount === undefined || amount === '') {
    return ''
  }
  const factor = getAmountDisplayFactor(fundType, currency)
  const fundTypeAndCurrencyPrecision = getAmountPrecision(fundType, currency)
  const val = parseFloat(amount as string) / factor
  let precision2 = precision === null || precision === undefined || precision === 99
    ? fundTypeAndCurrencyPrecision
    : precision
  if (
    precision === 99 &&
    // 1.001 is accounting for JavaScript floating point errors
    1.0001 * Math.ceil(val) - val < (1 / (Math.pow(10, fundTypeAndCurrencyPrecision)))
  ) {
    precision2 = 0
  }
  let renderedCurrency = ''
  if (showCurrency) {
    renderedCurrency = (currency === FiatCurrency.USD) ? '$' : `${currency} `
  }
  const formatted = val.toLocaleString(
    locale,
    { minimumFractionDigits: precision2 },
  )
  return `${renderedCurrency}${formatted}`
}

export default formatAmount
