import { gql } from '@apollo/client'

export default gql`
  mutation M($chat: ChatInput!) {
    upsertChat(chat: $chat) {
        id
        fromUserId
        toUserId
        status
        metadata {
            fromUser {
                username
                fullName
                email
                phoneNumber
                imageUrl
                unreadMessageCount
            }
            toUser {
                username
                fullName
                email
                phoneNumber
                imageUrl
                unreadMessageCount
            }
            updatedAt
        }
        recordStatus
        createdAt
        updatedAt
        fromUser { id username fullName email phoneNumber imageUrl roles }
        toUser { id username fullName email phoneNumber imageUrl roles }
        messages {
            id
            chatId
            fromUserId
            toUserId
            messageType
            systemMessageType
            messageText
            imageUrl
            imageType
            animation
            receivedAt
            metadata {
                attachments {
                    id
                    chatAttachmentId
                    attachmentType
                    ... on ChatAttachmentPurchaseTransferInfo {
                        fromPurchaseId
                        toPurchaseId
                        purchaseTransferId
                        productId
                        productOptionId
                        purchaseType
                        productImageSourceFrontSide
                        status
                        amount
                        balance
                        fundType
                        currency
                        discount
                        merchantId
                        merchantName
                        merchantUrl
                        merchantSmallLogoImageSource
                        merchantLargeLogoImageSource
                    }
                    ... on ChatAttachmentRewardInfo {
                        rewardType
                        amount
                        fundType
                        currency
                    }
                    ... on ChatAttachmentTransactionInfo {
                        transactionId
                        transactionType
                        amount
                        fundType
                        currency
                    }
                }
                purchaseTransferId
                purchaseTransferStatus
                fromPurchaseId
                toPurchaseId
            }
            recordStatus
            createdAt
            updatedAt
        }
    }
  }
`
