import { PurchaseUpdateType } from '../../enums'

const labelsEn = Object.freeze({
  [PurchaseUpdateType.STATUS_CHANGE]: 'status changed',
  [PurchaseUpdateType.RECORD_STATUS_CHANGE]: 'archived/unarchived',
  [PurchaseUpdateType.BALANCE_CHANGE]: 'balance changed',
  [PurchaseUpdateType.MULTIPLE_CHANGES]: 'multiple changes',
})

export default {
  getLabel: (status?: PurchaseUpdateType | null, language?: string): string => {
    if (!status) {
      return ''
    }
    if (!language || language === 'US') {
      return labelsEn[status]
    }
    return ''
  },
  isValid: (status: PurchaseUpdateType): boolean => Object.values(PurchaseUpdateType).includes(status),
}
