import React from 'react'

import './styles.css'

type Props = {
  senderName: string;
  formattedBalanceWithCurrency?: string;
  merchantName: string;
}

const IntroSection: React.FC<Props> = ({
  senderName,
  formattedBalanceWithCurrency,
  merchantName,
}): JSX.Element => {
  const parts = [<div key='caption' className='caption'>Congratulations!</div>]
  if (senderName) {
    parts.push(<span key='sender'>{senderName} sent you a </span>)
  } else {
    parts.push(<span key='no-sender'>You&apos;ve received a </span>)
  }
  if (formattedBalanceWithCurrency) {
    parts.push(<span key='amount' className='withBoldText'>{formattedBalanceWithCurrency}</span>)
  }
  parts.push(<span key='gift-card'> gift card </span>)
  if (merchantName) {
    parts.push(<span key='to-spend'>to spend at </span>)
    parts.push(<span key='merchant' className='withBoldText'>{merchantName}</span>)
  }
  parts.push(<span key='excl'>!</span>)

  return (
    <div className='purchase-received-intro'>
      {parts}
    </div>
  )
}

export default IntroSection
