import { gql } from '@apollo/client'

export default gql`
  query Q($filter: TagListFilter) {
    tags(filter: $filter) {
      id
      parentTagId
      name
      captionEn
      tagType
      aliases
      priority
      lat
      lon
      place
      region
      countryCode
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }
`
