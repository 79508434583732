import { gql } from '@apollo/client'

export default gql`
  query Q($filter: ProductListFilter!) {
    products (filter: $filter) {
      id
      merchantId
      offerId
      productType
      messageEn
      imageSourceFrontSide
      isMainProduct
      stock
      listed
      metadata
      merchantName
      merchantUrl
      merchantSmallLogoImageSource
      merchantLargeLogoImageSource
      merchantListed
      merchantRecordStatus
      recordStatus
      createdAt
      updatedAt
    }
  }`
