import type { FormEvent } from 'react'
import React, { useContext, useEffect, useState } from 'react'
import { IonButton, IonInput, IonSpinner } from '@ionic/react'
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'

import './styles.css'
import type { AsyncTask, AsyncTaskInput, User } from '../../lib/core/definitions'
import {
  AsyncTaskCommandType,
  AsyncTaskResult,
  AsyncTaskStatus,
  AsyncTaskType,
  IdentType,
  NotificationMethod, UiMessage,
} from '../../lib/core/enums'
import type { ChoicesItem } from '../../definitions'
import type {
  AsyncTaskQueryData,
  AsyncTaskQueryVariables,
  UpsertAsyncTaskMutationData,
  UpsertAsyncTaskMutationVariables,
} from '../../services/apollo/definitions'
import { PageMessageType } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import api from '../../services/api'
import apollo from '../../services/apollo'
import auth from '../../services/auth'
import Choices from '../Choices/Choices'
import coreHelpers from '../../lib/core/helpers'
import FormItem from '../../components/FormItem/FormItem'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../helpers/validationHelpers'

enum FormState {
  INITIAL = 'initial',
  NOTIFICATION_REQUESTED = 'notification-requested',
  NOTIFICATION_SENT = 'notification-sent',
  TOKEN_SENT = 'token-sent',
  TOKEN_CONFIRMED = 'token-confirmed',
  UPDATE_PASSWORD = 'update-password',
  WAITING_FOR_PASSWORD_UPDATING = 'waiting-for-password-updating',
  FINISHED = 'finished',
}

type Props = {
  userIdent?: string;
  taskIdFromRequestParams?: string;
  confirmTokenFromRequestParams?: string;
  onCancel: () => void;
  onNext: () => void;
}

const ResetPasswordForm: React.FC<Props> = (props): JSX.Element => {
  // console.log('ResetPasswordForm.render called.', { props })
  const {
    userIdent,
    taskIdFromRequestParams,
    confirmTokenFromRequestParams,
    onCancel,
    onNext,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { getIsSignedIn, setAuthUser } = useGlobalCache()
  const { activeUser, reloadActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [formState, setFormState] = useState(FormState.INITIAL)

  const [identType, setIdentType] = useState(IdentType.EMAIL)
  const [email, setEmail] = useState<string | undefined>()
  const [emailValidationError, setEmailValidationError] = useState<string | undefined>()
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>()
  const [phoneNumberValidationError, setPhoneNumberValidationError] = useState<string | undefined>()
  const [enteredConfirmToken, setEnteredConfirmToken] = useState<string | undefined>()
  const [enteredConfirmTokenValidationError, setEnteredConfirmTokenValidationError] = useState<string | undefined>()
  const [newPassword, setNewPassword] = useState<string | undefined>()
  const [newPasswordValidationError, setNewPasswordValidationError] = useState<string | undefined>()
  const [isPollingAsyncTask, setIsPollingAsyncTask] = useState(false)

  // ===================================================================================================================
  // Helpers:
  const resetForm = (): void => {
    setFormState(FormState.INITIAL)
    setEmail('')
    setEmailValidationError('')
    setPhoneNumber('')
    setPhoneNumberValidationError('')
    setEnteredConfirmToken(undefined)
    setEnteredConfirmTokenValidationError('')
    setNewPasswordValidationError('')
    pageMessages && pageMessages.clear()
  }

  // ===================================================================================================================
  // Apollo Hooks:
  const [
    loadAsyncTask,
    {
      data: asyncTaskLoadedData,
      loading: isLoadingAsyncTask,
      // error: asyncTaskLoadingError,
      startPolling: startPollingAsyncTask,
      stopPolling: stopPollingAsyncTask,
    },
  ] = useLazyQuery<AsyncTaskQueryData, AsyncTaskQueryVariables>(
    apollo.queries.asyncTask, {
      context: { skipAuthentication: !getIsSignedIn },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // console.log('ResetPasswordForm: loadAsyncTask.onCompleted called.', { data, formState })
        const asyncTask = data ? data.asyncTask : undefined
        if (!asyncTask) {
          console.error('ResetPasswordForm: loadAsyncTask.onCompleted: asyncTask not found in data.')
          // pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.GIFT_CARD_NOT_FOUND)
          return
        }

        if (
          formState === FormState.TOKEN_SENT &&
          (
            asyncTask.taskStatus !== AsyncTaskStatus.FINISHED ||
            asyncTask.result !== AsyncTaskResult.OK ||
            !asyncTask.metadata ||
            !asyncTask.metadata.authToken
          )
        ) {
          if (!isPollingAsyncTask) {
            setIsPollingAsyncTask(true)
            if (startPollingAsyncTask) {
              startPollingAsyncTask(1000)
            }
          }
          return
        }

        if (
          formState === FormState.TOKEN_SENT &&
          asyncTask.taskStatus === AsyncTaskStatus.FINISHED &&
          asyncTask.result === AsyncTaskResult.OK &&
          asyncTask.metadata &&
          asyncTask.metadata.authToken
        ) {
          console.log('The token was confirmed.')
          if (!getIsSignedIn()) {
            const user: User = {
              id: asyncTask.userId || activeUserId,
              authToken: asyncTask.metadata.authToken,
            }
            let username = asyncTask.username
            if (!username && asyncTask.user) {
              ({ username } = asyncTask.user)
            }
            let email = asyncTask.email
            if (!email && asyncTask.user) {
              ({ email } = asyncTask.user)
            }
            let phoneNumber = asyncTask.phoneNumber
            if (!phoneNumber && asyncTask.user) {
              ({ phoneNumber } = asyncTask.user)
            }
            if (username) {
              user.username = username
            }
            if (email) {
              user.email = email
            }
            if (phoneNumber) {
              user.phoneNumber = phoneNumber
            }
            console.log('ResetPasswordForm: calling globalCache.setAuthUser', { user })
            setAuthUser(user)
          }

          setFormState(FormState.TOKEN_CONFIRMED)
          setToastMessage('The token was confirmed.')
          setShowToast(true)
          if (isPollingAsyncTask) {
            setIsPollingAsyncTask(false)
            if (stopPollingAsyncTask) {
              stopPollingAsyncTask()
            }
          }
          reloadActiveUser().then(() => {
            resetForm()
            console.log('ResetPasswordForm: loading of active user completed. Calling onNext.')
            onNext()
          })
          return
        }

        if (formState === FormState.NOTIFICATION_REQUESTED) {
          console.log('ResetPasswordForm: loadAsyncTask.onCompleted: formState=NOTIFICATION_REQUESTED.')
          if (asyncTask.taskStatus === AsyncTaskStatus.NOTIFICATION_SENT) {
            setFormState(FormState.NOTIFICATION_SENT)
            if (isPollingAsyncTask) {
              setIsPollingAsyncTask(false)
              if (stopPollingAsyncTask) {
                stopPollingAsyncTask()
              }
            }
            return
          }
          if (!isPollingAsyncTask) {
            setIsPollingAsyncTask(true)
            if (startPollingAsyncTask) {
              startPollingAsyncTask(1000)
            }
          }
        }
      },
      onError: (error) => {
        setToastMessage(error.message)
        setShowToast(true)
      },
    },
  )
  const asyncTask = asyncTaskLoadedData ? asyncTaskLoadedData.asyncTask : undefined

  // useEffect((): void => {
  //   if (asyncTaskLoadingError) {
  //     setToastMessage(asyncTaskLoadingError.message)
  //     setShowToast(true)
  //   }
  // }, [asyncTaskLoadingError])

  const [
    upsertAsyncTask,
    { loading: isUpsertingAsyncTask },
  ] = useMutation<UpsertAsyncTaskMutationData, UpsertAsyncTaskMutationVariables>(apollo.mutations.upsertAsyncTask, {
    context: { skipAuthentication: !getIsSignedIn() },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      console.log('SignInPage: mutation succeeded.', data)
      if (data && data.upsertAsyncTask) {
        const asyncTask = data.upsertAsyncTask
        if (asyncTask) {
          if (formState === FormState.NOTIFICATION_REQUESTED || formState === FormState.TOKEN_SENT) {
            if (asyncTask.taskStatus === AsyncTaskStatus.NOTIFICATION_SENT) {
              setFormState(FormState.NOTIFICATION_SENT)
              return
            }
            loadAsyncTask({ variables: { taskId: asyncTask.id as string } })
          }
        }
      }
    },
    onError: (error) => {
      console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
    },
  })

  // ===================================================================================================================
  // Helpers:
  const taskId = taskIdFromRequestParams || (asyncTask ? asyncTask.id : undefined)
  const confirmToken = (enteredConfirmToken || confirmTokenFromRequestParams || '').replace(/\D/g, '')
  const canSendOutNotification = !!activeUserId || !(!email && !phoneNumber)
  const isProcessing = isLoadingAsyncTask || isUpsertingAsyncTask
  const isDirty = confirmTokenFromRequestParams || !!confirmToken || !!newPassword
  const isValid = (
    !enteredConfirmTokenValidationError &&
    !newPasswordValidationError &&
    !!confirmToken &&
    !!newPassword
  )

  // ===================================================================================================================
  // Effect Handlers:
  useEffect(() => {
    if (
      taskIdFromRequestParams &&
      confirmTokenFromRequestParams &&
      formState !== FormState.UPDATE_PASSWORD
    ) {
      setFormState(FormState.UPDATE_PASSWORD)
    }
  }, [taskIdFromRequestParams, confirmTokenFromRequestParams])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeEmail = (event: any): void => {
    setEmail(event.detail.value)
    if (event.detail.value) {
      setEmailValidationError(validationHelpers.validateEmail(event.detail.value))
    } else {
      setEmailValidationError('')
    }
  }

  const onChangePhoneNumber = (event: any): void => {
    setPhoneNumber(event.detail.value)
    if (event.detail.value) {
      setPhoneNumberValidationError(
        validationHelpers.validatePhoneNumber(event.detail.value),
      )
    } else {
      setPhoneNumberValidationError('')
    }
  }

  const onChangeEnteredConfirmToken = (event: any): void => {
    setEnteredConfirmToken(event.detail.value)
    if (event.detail.value) {
      setEnteredConfirmTokenValidationError(validationHelpers.validateConfirmationToken(event.detail.value))
    } else {
      setEnteredConfirmTokenValidationError('')
    }
  }

  const onChangeNewPassword = (event: any): void => {
    setNewPassword(event.detail.value)
    if (event.detail.value) {
      setNewPasswordValidationError(validationHelpers.validatePassword(event.detail.value))
    } else {
      setNewPasswordValidationError('')
    }
  }

  const onChangeIdentType = (newIdentType: IdentType): void => {
    // console.log('ResetPasswordForm.onChangeIdentType called.', newIdentType)
    setIdentType(newIdentType)
  }

  const onBackFromNotificationRequested = (): void => {
    setFormState(FormState.INITIAL)
  }

  const onBackFromNotificationSent = (): void => {
    setFormState(FormState.INITIAL)
  }

  const onBackFromTokenSent = (): void => {
    setFormState(FormState.INITIAL)
  }

  const onSendNotification = (): void => {
    console.log('ResetPasswordForm.onSendNotification called.')

    const processErrorResponse = (error?: Error): void => {
      console.error('ResetPasswordForm.onSendNotification.processReturnedAsyncTask: error recorded.', error)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SENDING_NOTIFICATION)
      // setToastMessage('Failed to send notification.')
      // setShowToast(true)
    }

    const processReturnedAsyncTask = (task: AsyncTask | undefined): void => {
      if (!task) {
        console.error('ResetPasswordForm.onSendNotification.processReturnedAsyncTask: no task returned.')
        return
      }
      if (formState !== FormState.NOTIFICATION_REQUESTED) {
        console.warn('ResetPasswordForm.onSendNotification.processReturnedAsyncTask: form in unexpected state.')
        return
      }
      console.log('ResetPasswordForm.onSendNotification.processReturnedAsyncTask: task returned.', task)

      if (task.taskStatus === AsyncTaskStatus.NOTIFICATION_FAILED) {
        processErrorResponse()
        return
      }

      if (task.taskStatus === AsyncTaskStatus.NOTIFICATION_SENT) {
        setFormState(FormState.NOTIFICATION_SENT)
        return
      }

      console.error('ResetPasswordForm.onSendNotification.processReturnedAsyncTask: task in unexpected state.', task)
    }

    pageMessages && pageMessages.clear()
    setFormState(FormState.NOTIFICATION_REQUESTED)

    if (asyncTask && asyncTask.id) {
      auth.sendAsyncTaskNotification(
        asyncTask.id as string,
        undefined,
        undefined,
        undefined,
        undefined,
        apolloClient,
      )
        .then(processReturnedAsyncTask, processErrorResponse)
      return
    }
    const taskInput: AsyncTaskInput = { taskType: AsyncTaskType.RESET_PASSWORD }
    if (activeUserId) {
      taskInput.userId = activeUserId
    } else if (userIdent) {
      taskInput.userIdent = userIdent
    } else if (identType === IdentType.EMAIL && email) {
      taskInput.email = email
      taskInput.notificationMethod = NotificationMethod.EMAIL
    } else if (identType === IdentType.PHONE_NUMBER && phoneNumber) {
      taskInput.phoneNumber = phoneNumber
      taskInput.notificationMethod = NotificationMethod.SMS
    }
    api.upsertAsyncTask(
      taskInput,
      getIsSignedIn(),
      coreHelpers.models.asyncTask.isNewTaskSendingNotificationCompleted,
      apolloClient,
    ).then(processReturnedAsyncTask, processErrorResponse)
  }

  const onUpdatePassword = () => {
    setFormState(FormState.WAITING_FOR_PASSWORD_UPDATING)
    const taskInput: AsyncTaskInput = {
      taskType: AsyncTaskType.RESET_PASSWORD,
      confirmToken,
      command: AsyncTaskCommandType.VERIFY_TOKEN,
      metadata: {
        password: newPassword,
      },
    }
    if (taskId) {
      taskInput.id = taskId
    }
    upsertAsyncTask({ variables: { task: taskInput } })
    pageMessages && pageMessages.clear()
    setFormState(FormState.TOKEN_SENT)
  }

  const onSubmitToken = (event?: FormEvent): void => {
    if (event) {
      event.preventDefault()
    }
    const taskInput: AsyncTaskInput = {
      confirmToken,
      command: AsyncTaskCommandType.VERIFY_TOKEN,
      metadata: {
        password: newPassword,
      },
    }
    if (taskId) {
      taskInput.id = taskId
    }
    upsertAsyncTask({ variables: { task: taskInput } })
    pageMessages && pageMessages.clear()
    setFormState(FormState.TOKEN_SENT)
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('ResetPasswordForm: rending...', {
  //   confirmToken, enteredConfirmToken, confirmTokenFromRequestParams, formState, asyncTask, userIdent,
  // })
  if (formState === FormState.FINISHED) {
    return (
      <div className='g-with-safe-padding'>
        <div className='withStandardBottomMargin'>
          You&apos;ve successfully updated your password.
        </div>
        <div className='formButtonWrapper withStandardBottomMargin'>
          <IonButton onClick={onNext}>
            Next
          </IonButton>
        </div>
      </div>
    )
  }
  const sections: JSX.Element[] = []

  if (formState === FormState.INITIAL) {
    const choices: ChoicesItem[] = [
      {
        value: IdentType.EMAIL,
        line1: 'Email',
        enable: true,
      },
      {
        value: IdentType.PHONE_NUMBER,
        line1: 'Phone Number',
        enable: true,
      },
    ]
    sections.push(
      <div key='intro' className='withStandardBottomMargin'>
        <div key='intro' className='withStandardBottomMargin'>
          To reset your password, we will send you a confirmation token.
          <br />
          <br />
          How should we send you this token?
        </div>
        <Choices
          items={choices}
          currentValue={identType}
          onChange={onChangeIdentType}
        />
      </div>,
    )
    if (!userIdent) {
      if (identType === IdentType.EMAIL) {
        sections.push(
          <FormItem
            key='email'
            label='Email Address'
            validationError={emailValidationError}
          >
            <IonInput
              name={IdentType.EMAIL}
              type='email'
              inputmode='email'
              onIonChange={onChangeEmail}
              value={email}
              placeholder='email@mimble.co'
              clearInput
            />
          </FormItem>,
        )
      } else if (identType === IdentType.PHONE_NUMBER) {
        sections.push(
          <FormItem
            key='phone'
            label='Phone number'
            validationError={phoneNumberValidationError}
          >
            <IonInput
              type='tel'
              inputmode='tel'
              onIonChange={onChangePhoneNumber}
              placeholder='i.e. 1234567890'
              value={phoneNumber}
              clearInput
              autocomplete='off'
            />
          </FormItem>,
        )
      }
    }
    sections.push(
      <div key='buttons' className='formButtonWrapper'>
        <IonButton
          color='medium'
          className='withStandardRightMargin'
          onClick={onCancel}
        >
          Back
        </IonButton>
        <SubmitButton
          onClick={onSendNotification}
          disabled={!canSendOutNotification}
          isProcessing={isProcessing}
        >
          Send Token To Me
        </SubmitButton>
      </div>,
    )
  } else if (formState === FormState.NOTIFICATION_REQUESTED) {
    sections.push(
      <div key='not-sent' className='withStandardBottomMargin'>
        <div className='withDoubleBottomMargin'>
          Sending out a notification to you...
        </div>
        <div key='spinner' className='withCenteredColumnContent withStandardBottomMargin'>
          <IonSpinner />
        </div>
        <div key='buttons' className='formButtonWrapper'>
          <IonButton
            color='medium'
            onClick={onBackFromNotificationRequested}
          >
            Back
          </IonButton>
        </div>
      </div>,
    )
  } else if (formState === FormState.UPDATE_PASSWORD) {
    sections.push(
      <form onSubmit={onUpdatePassword} key='upd-pwd-form'>
        <FormItem
          key='pwd-input'
          label='New Password'
          validationError={newPasswordValidationError}
          withBottomMargin
        >
          <IonInput
            autofocus
            type='password'
            onIonChange={onChangeNewPassword}
            placeholder='password'
            value={newPassword}
            required
            minlength={8}
            maxlength={30}
            clearInput
          />
        </FormItem>
        <div key='buttons' className='formButtonWrapper'>
          <IonButton
            color='medium'
            className='withStandardRightMargin'
            onClick={onCancel}
          >
            Back
          </IonButton>
          <SubmitButton
            onClick={onUpdatePassword}
            disabled={!newPassword || !!newPasswordValidationError}
            isProcessing={isProcessing}
          >
            Save New Password
          </SubmitButton>
        </div>
      </form>,
    )
  } else if (formState === FormState.NOTIFICATION_SENT) {
    sections.push(
      <div key='not-sent' className='withStandardBottomMargin'>
        <div>
          A notification has been sent to you. Please either click on the link it contains, or
          enter the token here.
        </div>
      </div>,
    )
    if (!confirmTokenFromRequestParams) {
      sections.push(
        <FormItem
          key='token-input'
          label='Unlock Code'
          validationError={enteredConfirmTokenValidationError}
          withBottomMargin
        >
          <IonInput
            autofocus
            type='number'
            inputmode='numeric'
            pattern='[0-9]{6}'
            onIonChange={onChangeEnteredConfirmToken}
            placeholder='6-digit token'
            value={enteredConfirmToken}
            autocomplete='one-time-code'
            minlength={6}
            maxlength={6}
            required
            clearInput
          />
        </FormItem>,
      )
    }
    sections.push(
      <FormItem
        key='pwd-input'
        label='New Password'
        validationError={newPasswordValidationError}
        withBottomMargin
      >
        <IonInput
          autofocus
          type='password'
          onIonChange={onChangeNewPassword}
          placeholder='password'
          value={newPassword}
          required
          minlength={8}
          maxlength={30}
          clearInput
        />
      </FormItem>,
    )
    sections.push(
      <div key='buttons' className='formButtonWrapper'>
        <IonButton
          color='medium'
          className='withStandardRightMargin'
          onClick={onBackFromNotificationSent}
        >
          Back
        </IonButton>
        <SubmitButton
          disabled={!isDirty || !isValid || isProcessing}
          onClick={onSubmitToken}
          isProcessing={isProcessing}
        >
          Submit
        </SubmitButton>
      </div>,
    )
  } else if (formState === FormState.TOKEN_SENT) {
    sections.push(
      <div key='not-sent' className='withStandardBottomMargin'>
        <div className='withDoubleBottomMargin'>
          Verifying token...
        </div>
        <div key='spinner' className='withCenteredColumnContent withStandardBottomMargin'>
          <IonSpinner />
        </div>
        <div key='buttons' className='formButtonWrapper'>
          <IonButton
            color='medium'
            onClick={onBackFromTokenSent}
          >
            Back
          </IonButton>
        </div>
      </div>,
    )
  } else if (formState === FormState.TOKEN_CONFIRMED) {
    sections.push(
      <div key='not-sent' className='withStandardBottomMargin'>
        <div className='withDoubleBottomMargin'>
          The token has been verified. Signing you in now...
        </div>
        <div key='spinner' className='withCenteredColumnContent withStandardBottomMargin'>
          <IonSpinner />
        </div>
        <div key='buttons' className='formButtonWrapper'>
          <IonButton
            color='medium'
            onClick={onCancel}
          >
            Back
          </IonButton>
        </div>
      </div>,
    )
  }

  return (
    <form onSubmit={onSubmitToken} className='g-with-safe-padding'>
      {sections}
    </form>
  )
}

export default ResetPasswordForm
