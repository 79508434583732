import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { IonButton, IonInput } from '@ionic/react'

import './styles.css'
import { InputVerificationStatus } from '../../../../enums'
import type { UserInput } from '../../../../lib/core/definitions'
import { useMimbleData } from '../../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../../services/api'
import FormItem from '../../../../components/FormItem/FormItem'
import logger from '../../../../services/logger'
import SubmitButton from '../../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../../helpers/validationHelpers'
import VerifyInputIndicator from '../../../../components/VerifyInputIndicator/VerifyInputIndicator'

type Props = {
  onClose: () => void
  showUiMessage: (message: string) => void
}

const UsernameForm: React.FC<Props> = ({
  onClose,
  showUiMessage,
}): JSX.Element => {
  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const {
    activeUser,
    isUpdatingActiveUser,
    updateActiveUser,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [value, setValue] = useState<string | null | undefined>()
  const [validationError, setValidationError] = useState<string | undefined>()
  const [enableValidationForInput, setEnableValidationForInput] = useState(false)
  const [isVerifyingInput, setIsVerifyingInput] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState<InputVerificationStatus | undefined>()

  const oldValue = activeUser && activeUser.username

  // ===================================================================================================================
  // Helpers:
  const isDirty = (!!value && value !== oldValue) || value === null
  const isValid = !!value && !validationError

  const resetForm = (): void => {
    setValue(undefined)
  }

  // ===================================================================================================================
  // Event Handlers:
  // const onChangeValue = (event: any): void => {
  //   setVerificationStatus(undefined)
  //   if (event.detail.value && event.detail.value !== oldValue) {
  //     setValue(event.detail.value)
  //     setValidationError(validationHelpers.validateUsername(event.detail.value))
  //   } else {
  //     setValue(event.detail.value === oldValue ? undefined : null)
  //     setValidationError(validationHelpers.validateUsername(event.detail.value))
  //   }
  // }

  const onBlur = (): void => {
    if (!enableValidationForInput) {
      setEnableValidationForInput(true)
    }
  }

  const onVerifyInput = (): void => {
    setIsVerifyingInput(true)
    setVerificationStatus(undefined)
    api.findUser({ username: value }, true, apolloClient)
      .then((userIdentInfos) => {
        setIsVerifyingInput(false)
        if (Array.isArray(userIdentInfos) && userIdentInfos.length > 0) {
          setVerificationStatus(InputVerificationStatus.INVALID)
          setValidationError('Handle already taken')
        } else {
          setVerificationStatus(InputVerificationStatus.VALID)
          setValidationError(undefined)
        }
      }, (error) => {
        setIsVerifyingInput(false)
        setVerificationStatus(InputVerificationStatus.INVALID)
        logger.error('SignUpForm: loadUserIdentInfo returned error.', { error })
      })
  }

  const onChangeValue = (event: any): void => {
    event.preventDefault()
    setVerificationStatus(undefined)
    if (event.detail.value && event.detail.value !== oldValue) {
      setValue(event.detail.value)
      setValidationError(validationHelpers.validateUsername(event.detail.value))
    } else {
      setValue(event.detail.value === oldValue ? undefined : null)
    }
  }

  const onSave = (event: any): void => {
    event.preventDefault()

    const userInput: UserInput = { id: activeUserId, username: value }

    updateActiveUser(userInput).then(() => {
      showUiMessage('Successfully updated your handle.')
      onClose()
      resetForm()
    }, (error) => {
      console.error(error)
      showUiMessage('Failed to update your handle. Please try again.')
    })
  }

  const onCancel = () => {
    onClose()
    resetForm()
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='user-account-username-form'>
      <form onSubmit={onSave}>
        <FormItem
          label='Your Mimble Handle'
          withBottomMargin
          successMessage={verificationStatus === InputVerificationStatus.VALID ? 'Available' : undefined}
          validationError={validationError}
        >
          <div className='rowWithCenterAlignedItems'>
            <span className='handleGlyph'>@</span>
            <IonInput
              name='username'
              value={value === null ? '' : (value || oldValue)}
              placeholder='i.e. @JoeTheSailor'
              onIonChange={onChangeValue}
              onIonBlur={onBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  onVerifyInput()
                }
              }
                }
              required
            />
            <VerifyInputIndicator
              status={verificationStatus}
            />
          </div>
        </FormItem>
        <SubmitButton
          size='small'
          fill='outline'
          onClick={onVerifyInput}
          disabled={!isDirty || !isValid || verificationStatus === InputVerificationStatus.VALID}
          isProcessing={isVerifyingInput}
        >
          Is it available?
        </SubmitButton>
        <div className='formButtonWrapper'>
          <IonButton
            size='small'
            color='light'
            className='withStandardRightMargin'
            onClick={onCancel}
          >
            Cancel
          </IonButton>
          <SubmitButton
            size='small'
            disabled={!isDirty || !isValid || verificationStatus !== InputVerificationStatus.VALID}
            isProcessing={isUpdatingActiveUser}
            onClick={onSave}
          >
            Save
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

export default UsernameForm
