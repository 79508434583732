import React from 'react'
import { IonIcon } from '@ionic/react'

import './styles.css'
import type { TopNavTabId } from './enums'
import type { TopNavTabDefDetails } from './definitions'
import numberHelpers from '../../lib/core/helpers/number'

interface Props {
  id: TopNavTabId
  label: string
  icon?: string
  details?: TopNavTabDefDetails
  isActive?: boolean
  onClick: (id: TopNavTabId) => void
}

const TopNavTab: React.FC<Props> = (props): JSX.Element => {
  const {
    id,
    label,
    icon,
    details,
    isActive = false,
    onClick,
  } = props
  const wrapperClass = isActive ? 'tab active-tab' : 'tab'

  let renderedIcon
  if (icon) {
    renderedIcon = <IonIcon icon={icon} className='withSmallRightMargin' />
  }

  let countText
  if (details && numberHelpers.isNumber(details.count)) {
    countText = <span className='count-text'>&nbsp;({details.count})</span>
  }

  // todo: style the button and use instead of the div
  // return (
  //   <IonButton
  //     color='clear'
  //     size='small'
  //     className={wrapperClass}
  //     onClick={(): void => onClick(id)}
  //   >
  //     {renderedIcon}{label}{countText}
  //   </IonButton>
  // )
  return (
    <div className={wrapperClass} onClick={(): void => onClick(id)}>
      {renderedIcon}{label}{countText}
    </div>
  )
}

export default TopNavTab
