import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, Wish, WishListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  WishQueryData,
  WishQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'

const loadWish = (
  wishId: string,
  isInTargetStateFunc: ((wish: Wish) => boolean) | 'watch-updated-at' | undefined,
  listFilter: WishListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Wish | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadWish called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadWish: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<WishQueryVariables> = {
      query: apollo.queries.wish,
      variables: { wishId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<WishQueryData, WishQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.wish ||
            !resp.data.wish.id
          ) {
            logger.error('api.loadWish.load: query did not return expected data.', { resp })
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((wish: Wish) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.wish.updatedAt
            activeIsInTargetStateFunc = (wish: Wish) => wish.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.wish)
          ) {
            // console.log('api.loadWish.load: resolving with info.', resp.data.wish)
            cache.updateWish(
              resp.data.wish,
              activeUserId,
              apolloClient,
            )
            resolve(resp.data.wish)
            return
          }

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadWish.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadWish.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadWish
