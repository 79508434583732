import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { ApiQueryOptions, AsyncTask, AsyncTaskInput } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type { ApiClientOptions, UpsertAsyncTaskMutationData, UpsertAsyncTaskMutationVariables } from '../apollo/definitions'
import apollo from '../apollo'
import loadAsyncTask from './loadAsyncTask'
import logger from '../logger'

const upsertAsyncTask = (
  asyncTaskInput: AsyncTaskInput,
  isSignedIn: boolean,
  isInTargetStateFunc: ((asyncTask: AsyncTask) => boolean) | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<AsyncTask | undefined> => (
  // console.log('api.upsertAsyncTask called.', { asyncTaskInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertAsyncTask: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertAsyncTaskMutationData, UpsertAsyncTaskMutationVariables> = {
      mutation: apollo.mutations.upsertAsyncTask,
      variables: { task: asyncTaskInput },
    }

    if (!isSignedIn) {
      queryParams.context = { skipAuthentication: true }
    }

    apolloClient.mutate<UpsertAsyncTaskMutationData, UpsertAsyncTaskMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertAsyncTask: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertAsyncTask ||
          !resp.data.upsertAsyncTask.id
        ) {
          logger.error('api.upsertAsyncTask: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        if (!isInTargetStateFunc) {
          resolve(resp.data.upsertAsyncTask)
          return
        }

        const asyncTaskId = resp.data.upsertAsyncTask.id

        setTimeout(() => {
          // console.log('api.upsertAsyncTask: calling loadAsyncTask')
          loadAsyncTask(
            asyncTaskId,
            isSignedIn,
            apolloClient,
            isInTargetStateFunc,
            queryOptions,
            clientOptions,
          )
            .then((asyncTask) => {
              // console.log('api.upsertAsyncTask: resolving with asyncTask', asyncTask)
              resolve(asyncTask)
            }, (error) => {
              logger.error('api.upsertAsyncTask: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertAsyncTask: error', { error })
        reject(error)
      })
  })
)

export default upsertAsyncTask
