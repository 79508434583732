import React from 'react'
import { IonChip, IonLabel } from '@ionic/react'
import { useMutation } from '@apollo/client'

import './styles.css'
import type { Product, Tag } from '../../../lib/core/definitions'
import type {
  ProductQueryData,
  ProductQueryVariables,
  AddOrRemoveTagFromMutationData,
  AddOrRemoveTagFromProductMutationVariables,
} from '../../../services/apollo/definitions'
import apollo from '../../../services/apollo'
import AddProductTag from './AddProductTag'

interface Props {
  product: Product | null | undefined,
}

const TagsSection: React.FC<Props> = (props): JSX.Element | null => {
  const { product } = props

  // ===================================================================================================================
  // State:

  // ===================================================================================================================
  // Apollo Hooks:
  const [
    deleteTagFromProduct,
  ] = useMutation<AddOrRemoveTagFromMutationData, AddOrRemoveTagFromProductMutationVariables>(apollo.admin.mutations.deleteTagFromProduct)

  // ===================================================================================================================
  // Event Handlers:
  const onClickTag = (tagId: string) => {
    if (!product || !Array.isArray(product.tags) || product.tags.length < 1) {
      return
    }
    // const tag: Tag | undefined = product.tags.find(t => t.id === tagId)
    // if (!tag) {
    //   return
    // }

    deleteTagFromProduct({
      variables: {
        productId: product.id as string,
        tagId,
      },
      update: (cache) => {
        try {
          const cachedProductData = cache.readQuery<ProductQueryData, ProductQueryVariables>({
            query: apollo.queries.product({
              includeMerchant: true,
              includeProductOptions: true,
              includeStoredProducts: true,
              includeTags: true,
            }),
            variables: { productId: product.id as string },
          })
          if (cachedProductData && cachedProductData.product) {
            const updatedProduct: Product = { ...cachedProductData.product }
            if (Array.isArray(updatedProduct.tags) && updatedProduct.tags.length > 0) {
              updatedProduct.tags = updatedProduct.tags.filter(t => t.id !== tagId)
              cache.writeQuery({
                query: apollo.queries.product({
                  includeMerchant: true,
                  includeProductOptions: true,
                  includeStoredProducts: true,
                  includeTags: true,
                }),
                variables: { productId: product.id },
                data: { product: updatedProduct },
              })
            }
          }
        } catch (error) {
          console.warn(error)
        }
      },
    })
  }

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Rendering:
  if (!product) {
    return null
  }

  let assignedTags: JSX.Element | undefined
  if (Array.isArray(product.tags) && product.tags.length > 0) {
    const tagList = product.tags
      .map(tag => (
        <IonChip
          className='assigned-tag'
          onClick={() => onClickTag(tag.id as string)}
          key={tag.id}
        >
          <IonLabel>
            <span>{tag.name}</span>
          </IonLabel>
        </IonChip>
      ))
    assignedTags = (
      <div className='withDoubleBottomMargin'>
        {tagList}
      </div>
    )
  }

  return (
    <div className='section'>
      <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
        <div className='sectionCaption'>Tags</div>
      </div>
      {assignedTags}
      <AddProductTag
        product={product}
      />
    </div>
  )
}

export default TagsSection
