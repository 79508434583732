import React, { useEffect, useState } from 'react'
import { IonButton, IonInput } from '@ionic/react'

import './styles.css'
import type { CryptoCurrency, FiatCurrency, FundType } from '../../../lib/core/enums'
import { StoredProductStatus } from '../../../lib/core/enums'
import type { ProductOption, StoredProduct, StoredProductInput } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import FormItem from '../../../components/FormItem/FormItem'
import validationHelpers from '../../../helpers/validationHelpers'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'

type Props = {
  storedProduct?: StoredProduct;
  productOption?: ProductOption;
  onGoBack: () => void;
  onSave: (storedProduct: StoredProductInput) => void;
};

const StoredProductForm: React.FC<Props> = (props): JSX.Element => {
  const {
    storedProduct,
    productOption,
    onSave,
    onGoBack,
  } = props

  const [status, setStatus] = useState(undefined as StoredProductStatus | undefined)
  const [statusValidationError, setStatusValidationError] = useState<string | undefined>()
  const [code, setCode] = useState<string | undefined>()
  const [codeValidationError, setCodeValidationError] = useState<string | undefined>()
  const [pin, setPin] = useState<string | undefined>()
  const [pinValidationError, setPinValidationError] = useState<string | undefined>()
  const [amount, setAmount] = useState(undefined as number | null | undefined)
  const [amountValidationError, setAmountValidationError] = useState<string | undefined>()
  const [discount, setDiscount] = useState(undefined as number | null | undefined)
  const [discountValidationError, setDiscountValidationError] = useState<string | undefined>()
  const [fundType, setFundType] = useState(undefined as FundType | undefined)
  const [fundTypeValidationError, setFundTypeValidationError] = useState<string | undefined>()
  const [currency, setCurrency] = useState(undefined as CryptoCurrency | FiatCurrency | string | undefined)
  const [currencyValidationError, setCurrencyValidationError] = useState<string | undefined>()
  const [adminNotes, setAdminNotes] = useState<string | undefined>()
  const [adminNotesValidationError, setAdminNotesValidationError] = useState<string | undefined>()
  const [source, setSource] = useState<string | undefined>()
  const [sourceValidationError, setSourceValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const statusChanged = status !== undefined && !(storedProduct && status === storedProduct.status)
  const codeChanged = code !== undefined && !(storedProduct && code === storedProduct.code)
  const pinChanged = pin !== undefined && !(storedProduct && pin === storedProduct.pin)
  const amountChanged = amount !== undefined && !(storedProduct && amount === storedProduct.amount)
  const discountChanged = discount !== undefined && !(storedProduct && discount === storedProduct.discount)
  const fundTypeChanged = fundType !== undefined && !(storedProduct && fundType === storedProduct.fundType)
  const currencyChanged = currency !== undefined && !(storedProduct && currency === storedProduct.currency)
  const adminNotesChanged = adminNotes !== undefined && !(storedProduct && adminNotes === storedProduct.adminNotes)
  const sourceChanged = source !== undefined && !(storedProduct && source === storedProduct.source)

  const isDirty = (
    statusChanged ||
    codeChanged ||
    pinChanged ||
    amountChanged ||
    discountChanged ||
    fundTypeChanged ||
    currencyChanged ||
    adminNotesChanged ||
    sourceChanged
  )
  const isValid = (
    !statusValidationError &&
    !codeValidationError &&
    !pinValidationError &&
    !amountValidationError &&
    !discountValidationError &&
    !fundTypeValidationError &&
    !currencyValidationError &&
    !adminNotesValidationError &&
    !sourceValidationError
  )

  const resetForm = (): void => {
    setStatus(undefined)
    setStatusValidationError('')
    setCode(undefined)
    setCodeValidationError('')
    setPin(undefined)
    setPinValidationError('')
    setAmount(undefined)
    setAmountValidationError('')
    setDiscount(undefined)
    setDiscountValidationError('')
    setFundType(undefined)
    setFundTypeValidationError('')
    setCurrency(undefined)
    setCurrencyValidationError('')
    setAdminNotes(undefined)
    setAdminNotesValidationError('')
    setSource(undefined)
    setSourceValidationError('')
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('StoredProductForm: new storedProduct received - resetting form.')
    resetForm()
    if (!storedProduct) {
      setStatus(StoredProductStatus.AVAILABLE)
    }
  }, [storedProduct])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeStatus = (event: any): void => {
    if (
      (storedProduct && (event.detail.value === storedProduct.status || (!event.detail.value && !storedProduct.status))) ||
      (!storedProduct && !event.detail.value)
    ) {
      setStatusValidationError('')
      setStatus(undefined)
      return
    }
    setStatus(event.detail.value)
    if (event.detail.value) {
      setStatusValidationError(validationHelpers.validateStoredProductStatus(event.detail.value))
    } else {
      setStatusValidationError('')
    }
  }

  const onChangeCode = (event: any): void => {
    if (
      (storedProduct && (event.detail.value === storedProduct.code || (!event.detail.value && !storedProduct.code))) ||
      (!storedProduct && !event.detail.value)
    ) {
      setCodeValidationError('')
      setCode(undefined)
      return
    }
    setCode(event.detail.value)
    if (event.detail.value) {
      setCodeValidationError(validationHelpers.validatePurchaseCode(event.detail.value))
    } else {
      setCodeValidationError('')
    }
  }

  const onChangePin = (event: any): void => {
    if (
      (storedProduct && (event.detail.value === storedProduct.pin || (!event.detail.value && !storedProduct.pin))) ||
      (!storedProduct && !event.detail.value)
    ) {
      setPinValidationError('')
      setPin(undefined)
      return
    }
    setPin(event.detail.value)
    if (event.detail.value) {
      setPinValidationError(validationHelpers.validatePurchasePin(event.detail.value))
    } else {
      setPinValidationError('')
    }
  }

  const onChangeAmount = (event: any): void => {
    const activeFundType = fundType || (productOption ? productOption.fundType : undefined)
    const activeCurrency = currency || (productOption ? productOption.currency : undefined)
    const internalAmount = coreHelpers.ui.getInternalMoneyAmount(
      parseFloat(event.detail.value), activeFundType, activeCurrency)

    if (storedProduct && internalAmount === storedProduct.amount) {
      setAmountValidationError('')
      setAmount(undefined)
      return
    }
    setAmount(event.detail.value === '' ? null : internalAmount)
    setAmountValidationError(validationHelpers.validateProductOptionAmount(internalAmount))
  }

  const onChangeDiscount = (event: any): void => {
    const newDiscount = Math.ceil(parseFloat(event.detail.value) * 1000)
    if (storedProduct && newDiscount === storedProduct.discount) {
      setDiscountValidationError('')
      setDiscount(undefined)
      return
    }
    setDiscount(newDiscount)
    setDiscountValidationError(validationHelpers.validateStoredProductDiscount(newDiscount))
  }

  const onChangeFundType = (event: any): void => {
    if (
      (storedProduct && (event.detail.value === storedProduct.fundType || (!event.detail.value && !storedProduct.fundType))) ||
      (!storedProduct && !event.detail.value)
    ) {
      setFundTypeValidationError('')
      setFundType(undefined)
      return
    }
    setFundType(event.detail.value)
    if (event.detail.value) {
      setFundTypeValidationError(validationHelpers.validateFundType(event.detail.value))
    } else {
      setFundTypeValidationError('')
    }
  }

  const onChangeCurrency = (event: any): void => {
    if (
      (storedProduct && (event.detail.value === storedProduct.currency || (!event.detail.value && !storedProduct.currency))) ||
      (!storedProduct && !event.detail.value)
    ) {
      setCurrencyValidationError('')
      setCurrency(undefined)
      return
    }
    setCurrency(event.detail.value)
    if (event.detail.value) {
      setCurrencyValidationError(validationHelpers.validateCurrency(event.detail.value))
    } else {
      setCurrencyValidationError('')
    }
  }

  const onChangeAdminNotes = (event: any): void => {
    if (
      (storedProduct && (event.detail.value === storedProduct.adminNotes || (!event.detail.value && !storedProduct.adminNotes))) ||
      (!storedProduct && !event.detail.value)
    ) {
      setAdminNotesValidationError('')
      setAdminNotes(undefined)
      return
    }
    setAdminNotes(event.detail.value)
    if (event.detail.value) {
      setAdminNotesValidationError(validationHelpers.validateAdminNotes(event.detail.value))
    } else {
      setAdminNotesValidationError('')
    }
  }

  const onChangeSource = (event: any): void => {
    if (
      (storedProduct && (event.detail.value === storedProduct.source || (!event.detail.value && !storedProduct.source))) ||
      (!storedProduct && !event.detail.value)
    ) {
      setSourceValidationError('')
      setSource(undefined)
      return
    }
    setSource(event.detail.value)
    if (event.detail.value) {
      setSourceValidationError(validationHelpers.validateImageSource(event.detail.value))
    } else {
      setSourceValidationError('')
    }
  }

  const onClickSaveButton = (event: any): void => {
    event.preventDefault()
    if (!productOption) {
      console.error('StoredProductForm.onClickSaveButton: Missing productOption.')
      return
    }
    const updatedStoredProduct: StoredProductInput = storedProduct && storedProduct.id
      ? { id: storedProduct.id }
      : {
          productId: productOption.productId,
          productOptionId: productOption.id,
          fundType: productOption.fundType,
          currency: productOption.currency,
        }

    if (statusChanged) {
      updatedStoredProduct.status = status || null
    }
    if (codeChanged) {
      updatedStoredProduct.code = code || null
    }
    if (pinChanged) {
      updatedStoredProduct.pin = pin || null
    }
    if (amountChanged && coreHelpers.number.isPositiveNumber(amount)) {
      updatedStoredProduct.amount = amount as number
    }
    if (discountChanged && coreHelpers.number.isPositiveNumber(discount)) {
      updatedStoredProduct.discount = discount as number
    }
    if (storedProduct && storedProduct.id) {
      if (fundType && fundTypeChanged) {
        updatedStoredProduct.fundType = fundType
      }
      if (currencyChanged) {
        updatedStoredProduct.currency = currency
      }
    }
    if (sourceChanged) {
      updatedStoredProduct.source = source || null
    }
    if (adminNotesChanged) {
      updatedStoredProduct.adminNotes = adminNotes || null
    }
    onSave(updatedStoredProduct)
  }

  // ===================================================================================================================
  // Rendering:
  const activeFundType = fundType || (productOption ? productOption.fundType : undefined)
  const activeCurrency = currency || (productOption ? productOption.currency : undefined)
  let amountDisplayNumeric: number | null | undefined = storedProduct ? storedProduct.amount : undefined
  let amountDisplay = ''
  if (amount !== undefined) {
    amountDisplayNumeric = amount
  }
  if (amountDisplayNumeric !== null && amountDisplayNumeric !== undefined) {
    amountDisplay = coreHelpers.ui.getDisplayMoneyAmount(
      amountDisplayNumeric,
      activeFundType,
      activeCurrency,
    ).toString()
  }

  let discountDisplayNumeric: number | null | undefined = storedProduct ? (storedProduct.discount || 0) : undefined
  if (discount !== undefined) {
    discountDisplayNumeric = discount
  }
  let discountDisplay = ''
  if (discountDisplayNumeric !== null && discountDisplayNumeric !== undefined && !Number.isNaN(discountDisplayNumeric)) {
    discountDisplay = coreHelpers.ui.formatPercentAmount(discountDisplayNumeric || 0, 2, false)
  }

  let updateObjectFormItems: JSX.Element | undefined
  if (storedProduct && storedProduct.id) {
    updateObjectFormItems = (
      <>
        <FormItem
          label='Amount'
          validationError={amountValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeAmount}
            placeholder='i.e. 50'
            value={amountDisplay}
          />
        </FormItem>
        <FormItem
          label='Discount'
          validationError={discountValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeDiscount}
            placeholder='Our discount at the supplier: 0-99'
            value={discountDisplay}
          />
        </FormItem>
        <FormItem
          label='Fund Type (fiat/crypto/token)'
          validationError={fundTypeValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeFundType}
            placeholder='fiat/crypto/token'
            value={fundType !== undefined ? fundType : (storedProduct ? storedProduct.fundType : '')}
          />
        </FormItem>
        <FormItem
          label='Currency (use USD)'
          validationError={currencyValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeCurrency}
            placeholder='USD'
            value={currency !== undefined ? currency : (storedProduct ? storedProduct.currency : '')}
          />
        </FormItem>
      </>
    )
  }

  return (
    <form onSubmit={onClickSaveButton}>
      <FormItem
        label='Status (created/available/reserved/delivered)'
        validationError={statusValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeStatus}
          placeholder='created/available/reserved/delivered'
          value={status !== undefined ? status : (storedProduct ? storedProduct.status : '')}
        />
      </FormItem>
      <FormItem
        label='Code'
        validationError={codeValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeCode}
          placeholder='code'
          value={code !== undefined ? code : (storedProduct ? storedProduct.code : '')}
        />
      </FormItem>
      <FormItem
        label='PIN'
        validationError={pinValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangePin}
          placeholder='PIN'
          value={pin !== undefined ? pin : (storedProduct ? storedProduct.pin : '')}
        />
      </FormItem>
      {updateObjectFormItems}
      <FormItem
        label='Admin notes (optional)'
        validationError={currencyValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeAdminNotes}
          placeholder='admin notes'
          value={adminNotes !== undefined ? adminNotes : (storedProduct ? storedProduct.adminNotes : '')}
        />
      </FormItem>
      <FormItem
        label='Source (optional)'
        validationError={sourceValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeSource}
          placeholder='source'
          value={source !== undefined ? source : (storedProduct ? storedProduct.source : '')}
        />
      </FormItem>
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onGoBack}
        >
          Back
        </IonButton>
        <SubmitButton
          onClick={onClickSaveButton}
          disabled={!isDirty || !isValid}
        >
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default StoredProductForm
