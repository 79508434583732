import { gql } from '@apollo/client'

export default gql`
  mutation M($input: SignUpInput!) {
    signUp (input: $input) {
      id
      username
      email
      phoneNumber
      authToken
      roles
      prefs {
        contrastPennies
      }
    }
  }`
