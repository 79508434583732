import React, { useState } from 'react'
import { closeOutline } from 'ionicons/icons'

import './styles.css'
import type { ContactInput, UserIdentInfo } from '../../lib/core/definitions'
import { ContactType, RecordStatus } from '../../lib/core/enums'
import { ContextHelpId } from '../../contexts/AppHelpersContext/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import FindUserForm from '../../components/FindUserForm/FindUserForm'
import IconButton from '../../components/IconButton/IconButton'

type Props = {
  onCancel: () => void;
  onOpenInvitePage: () => void;
  onSubmit: (contactInput: ContactInput) => void;
};

const AddContactForm: React.FC<Props> = ({
  onSubmit,
  onOpenInvitePage,
  onCancel,
}): JSX.Element => {
  const { activeUser } = useMimbleData()
  const [newContactUserIdentInfo, setNewContactUserIdentInfo] = useState<UserIdentInfo | null | undefined>()

  const onClearNewContactUser = (): void => {
    setNewContactUserIdentInfo(undefined)
  }

  const onSelectUser = (userIdentInfo: UserIdentInfo | undefined): void => {
    setNewContactUserIdentInfo(userIdentInfo)

    if (!activeUser || !userIdentInfo) {
      return
    }

    const contactInput: ContactInput = {
      fromUserId: activeUser.id,
      toUserId: userIdentInfo.id,
      contactType: ContactType.USER_CREATED,
      recordStatus: RecordStatus.ACTIVE,
    }
    onSubmit(contactInput)
  }

  return (
    <div className='add-contact-form'>
      <div className='rowWithSpaceBetween'>
        <div className='sectionCaption'>
          Add a Contact
        </div>
        <IconButton
          icon={closeOutline}
          layout='vertical'
          className='close-button'
          iconSize={20}
          onClick={onCancel}
        />
      </div>
      <FindUserForm
        selectedUser={newContactUserIdentInfo || undefined}
        selectButtonLabel='Add'
        contextHelpId={ContextHelpId.ADD_CONTACT}
        className='withStandardBottomMargin'
        onSelectUser={onSelectUser}
        onSelectNonUser={onOpenInvitePage}
      />
      <div className='smallText lightText withPointerCursor' onClick={onOpenInvitePage}>
        Missing someone? &nbsp;
        <span className='linkText'>Invite a friend!</span>
      </div>
    </div>
  )
}

export default AddContactForm
