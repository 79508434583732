import React from 'react'
import { Avatar as MaterialUiAvatar } from '@mui/material'

import './styles.css'
import type { User } from '../../lib/core/definitions'
import { IonBadge } from '@ionic/react'

type Props = {
  user?: User
  className?: string
  size?: number
  badge?: number
  padForBadge?: boolean
  onClick?: () => void
};

const Avatar: React.FC<Props> = (props): JSX.Element => {
  const {
    user,
    className,
    size,
    badge,
    padForBadge,
    onClick,
  } = props

  const compProps: any = {}
  if (className && !badge) {
    compProps.className = className
  }
  if (onClick) {
    compProps.onClick = onClick
  }
  if (size) {
    compProps.style = { width: size, height: size }
  }

  let initials

  if (user && user.fullName) {
    if (user.fullName) {
      initials = user.fullName.split(' ').map(l => l.charAt(0) && l.charAt(0).toUpperCase())
      compProps.alt = user.fullName
    }
    if (user.imageUrl) {
      compProps.src = user.imageUrl
    }
  }

  const mAvatar = (
    <MaterialUiAvatar {...compProps}>
      {initials}
    </MaterialUiAvatar>
  )

  if (!badge && !padForBadge) {
    return mAvatar
  }

  if (badge) {
    return (
      <div className={`avatar-with-badge ${className || ''}`}>
        {mAvatar}
        <IonBadge color='success' className='badge'>{badge}</IonBadge>
      </div>
    )
  }

  return (
    <div className='avatar-without-badge'>
      {mAvatar}
    </div>
  )
}

export default Avatar
