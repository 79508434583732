import React from 'react'
import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react'
import { giftOutline, trashOutline, archiveOutline, analyticsOutline, cartOutline } from 'ionicons/icons'

import { PurchaseStatus, RecordStatus } from '../../lib/core/enums'
import RoundButton from './RoundButton'

export type Props = {
  purchaseId?: string;
  show?: boolean;
  purchaseStatus?: PurchaseStatus | null;
  recordStatus?: RecordStatus | null;
  onEditPurchase?: (purchaseId: string) => void;
  onAcceptGift?: (purchaseId: string) => void;
  onArchivePurchase?: (purchaseId: string) => void;
  onUnArchivePurchase?: (purchaseId: string) => void;
  onDeletePurchase?: (purchaseId: string) => void;
  onTransferPurchase?: (purchaseId: string) => void;
  onVisitMerchant?: () => void;
  onClose?: () => void;
}

const Overlay: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('Overlay.render called.', props)
  const {
    purchaseId,
    show,
    purchaseStatus,
    recordStatus,
    onArchivePurchase,
    onAcceptGift,
    onUnArchivePurchase,
    onDeletePurchase,
    onTransferPurchase,
    onEditPurchase,
    onVisitMerchant,
  } = props

  const handleEditPurchase = () => {
    if (onEditPurchase && purchaseId) {
      onEditPurchase(purchaseId)
    }
  }

  const handleArchivePurchase = () => {
    if (onArchivePurchase && purchaseId) {
      onArchivePurchase(purchaseId)
    }
  }

  const handleActivatePurchase = () => {
    if (onAcceptGift) {
      // event.preventDefault()
      // onAcceptGift(purchaseId)
    }
  }

  const handleUnArchivePurchase = () => {
    if (onUnArchivePurchase && purchaseId) {
      onUnArchivePurchase(purchaseId)
    }
  }

  const handleDeletePurchase = () => {
    if (onDeletePurchase && purchaseId) {
      onDeletePurchase(purchaseId)
    }
  }

  const handleTransferPurchase = () => {
    if (onTransferPurchase && purchaseId) {
      onTransferPurchase(purchaseId)
    }
  }

  const handleVisitMerchant = () => {
    if (onVisitMerchant) {
      onVisitMerchant()
    }
  }

  // const handleOpenPurchase = () => {
  //   if (onOpenPurchase) {
  //     onOpenPurchase()
  //   }
  // }

  if (!show) {
    return null
  }

  if (onAcceptGift && purchaseStatus === PurchaseStatus.RECEIVED) {
    return (
      <div className='overlay'>
        <div className='activate'>
          <div className=''>
            <IonButton
              size='small'
              fill='solid'
              color='success'
              onClick={handleActivatePurchase}
            >
              Accept
            </IonButton>
          </div>
        </div>
      </div>
    )
  }

  if (
    !onEditPurchase &&
    !onDeletePurchase &&
    !onArchivePurchase &&
    !onUnArchivePurchase &&
    !onTransferPurchase &&
    !onVisitMerchant
  ) {
    return null
  }

  type ColDef = {
    id: string;
    label: string;
    icon: string;
    clickHandler: () => void;
  }

  const gridDefs: (ColDef | undefined)[] = []

  if (onVisitMerchant) {
    gridDefs.push(
      {
        id: 'visit-b',
        label: 'Brand',
        icon: cartOutline,
        clickHandler: handleVisitMerchant,
      },
    )
  }

  if (
    onTransferPurchase &&
    recordStatus === RecordStatus.ACTIVE &&
    purchaseStatus === PurchaseStatus.ACTIVE
  ) {
    gridDefs.push(
      {
        id: 'transfer-p',
        label: 'Gift',
        icon: giftOutline,
        clickHandler: handleTransferPurchase,
      },
    )
  }

  if (
    onTransferPurchase &&
    recordStatus === RecordStatus.ACTIVE &&
    purchaseStatus === PurchaseStatus.ACTIVE
  ) {
    gridDefs.push({
      id: 'edit-p',
      label: 'Update',
      icon: analyticsOutline,
      clickHandler: handleEditPurchase,
    })
  }

  if (recordStatus === RecordStatus.ACTIVE && onArchivePurchase) {
    gridDefs.push({
      id: 'archive-p',
      label: 'Archive',
      icon: archiveOutline,
      clickHandler: handleArchivePurchase,
    })
  } else if (recordStatus === RecordStatus.INACTIVE && onUnArchivePurchase) {
    gridDefs.push({
      id: 'unarchive-p',
      label: 'Unarchive',
      icon: archiveOutline,
      clickHandler: handleUnArchivePurchase,
    })
    if (onDeletePurchase) {
      gridDefs.push({
        id: 'del-p',
        label: 'Delete',
        icon: trashOutline,
        clickHandler: handleDeletePurchase,
      })
    }
  }

  const rows: JSX.Element[] = []
  for (let i = 0; i < gridDefs.length; i += 2) {
    let content0: JSX.Element | undefined
    let content1: JSX.Element | undefined
    const def = gridDefs[i]
    if (def && def.label) {
      content0 = (
        <RoundButton
          icon={def.icon}
          label={def.label}
          onClick={def.clickHandler}
        />
      )
    }
    if (i < gridDefs.length - 1) {
      const def = gridDefs[i + 1]
      if (def && def.label) {
        content1 = (
          <RoundButton
            icon={def.icon}
            label={def.label}
            onClick={def.clickHandler}
          />
        )
      }
    }

    rows.push(
      <IonRow key={`row-${i}`}>
        <IonCol size='6' className='col'>
          {content0}
        </IonCol>
        <IonCol size='6' className='col'>
          {content1}
        </IonCol>
      </IonRow>,
    )
  }

  return (
    <div className='overlay'>
      <div className='form'>
        <div className='button-wrapper'>
          <div className='buttons'>
            <IonGrid className='grid'>
              {rows}
            </IonGrid>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overlay
