import React, { useEffect, useState } from 'react'
import { IonButton, IonInput } from '@ionic/react'

import './styles.css'
import type { User, UserInput } from '../../../lib/core/definitions'
import type { UserRole } from '../../../lib/core/enums'
import FormItem from '../../../components/FormItem/FormItem'
import validationHelpers from '../../../helpers/validationHelpers'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'

type Props = {
  user?: User | null;
  onGoBack: () => void;
  onSave: (user: UserInput) => void;
};

const UserForm: React.FC<Props> = ({
  user = null,
  onSave,
  onGoBack,
}): JSX.Element => {
  const [username, setUsername] = useState<string | undefined>()
  const [usernameValidationError, setUsernameValidationError] = useState<string | undefined>()
  const [fullName, setFullName] = useState<string | undefined>()
  const [fullNameValidationError, setFullNameValidationError] = useState<string | undefined>()
  const [roles, setRoles] = useState<string | undefined>()
  const [rolesValidationError, setRolesValidationError] = useState<string | undefined>()
  const [appFeatures, setAppFeatures] = useState<string | undefined>()
  const [appFeaturesValidationError, setAppFeaturesValidationError] = useState<string | undefined>()
  const [trustLevel, setTrustLevel] = useState<string | undefined>()
  const [trustLevelValidationError, setTrustLevelValidationError] = useState<string | undefined>()
  const [imageUrl, setImageUrl] = useState<string | undefined>()
  const [imageUrlValidationError, setImageUrlValidationError] = useState<string | undefined>()
  const [source, setSource] = useState<string | undefined>()
  const [sourceValidationError, setSourceValidationError] = useState<string | undefined>()
  const [adminNotes, setAdminNotes] = useState<string | undefined>()
  const [adminNotesValidationError, setAdminNotesValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const usernameChanged = username !== undefined && !(user && username === user.username)
  const fullNameChanged = fullName !== undefined && !(user && fullName === user.fullName)
  const rolesChanged = roles !== undefined && !(user && roles === user.roles)
  const appFeaturesChanged = appFeatures !== undefined && !(user && appFeatures === user.appFeatures)
  const trustLevelChanged = trustLevel !== undefined && !(user && parseInt(trustLevel) === user.trustLevel)
  const imageUrlChanged = imageUrl !== undefined && !(user && imageUrl === user.imageUrl)
  const sourceChanged = source !== undefined && !(user && source === user.source)
  const adminNotesChanged = adminNotes !== undefined && !(user && adminNotes === user.adminNotes)

  const isDirty = (
    usernameChanged ||
    fullNameChanged ||
    rolesChanged ||
    appFeaturesChanged ||
    trustLevelChanged ||
    imageUrlChanged ||
    sourceChanged ||
    adminNotesChanged
  )
  const isValid = (
    !usernameValidationError &&
    !fullNameValidationError &&
    !rolesValidationError &&
    !appFeaturesValidationError &&
    !imageUrlValidationError &&
    !sourceValidationError &&
    !adminNotesValidationError
  )

  const resetForm = (): void => {
    setUsername(undefined)
    setUsernameValidationError(undefined)
    setFullName(undefined)
    setFullNameValidationError(undefined)
    setRoles(undefined)
    setRolesValidationError(undefined)
    setAppFeatures(undefined)
    setAppFeaturesValidationError(undefined)
    setImageUrl(undefined)
    setImageUrlValidationError(undefined)
    setSource(undefined)
    setSourceValidationError(undefined)
    setAdminNotes(undefined)
    setAdminNotesValidationError(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('UserForm: new user received - resetting form.')
    resetForm()
  }, [user])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeUsername = (event: any): void => {
    if (
      (user && (event.detail.value === user.username || (!event.detail.value && !user.username))) ||
      (!user && !event.detail.value)
    ) {
      setUsernameValidationError('')
      setUsername(undefined)
      return
    }
    setUsername(event.detail.value)
    if (event.detail.value) {
      setUsernameValidationError(validationHelpers.validateUsername(event.detail.value))
    } else {
      setUsernameValidationError('')
    }
  }

  const onChangeFullName = (event: any): void => {
    if (
      (user && (event.detail.value === user.fullName || (!event.detail.value && !user.fullName))) ||
      (!user && !event.detail.value)
    ) {
      setFullNameValidationError('')
      setFullName(undefined)
      return
    }
    setFullName(event.detail.value)
    if (event.detail.value) {
      setFullNameValidationError(validationHelpers.validateUserFullName(event.detail.value))
    } else {
      setFullNameValidationError('')
    }
  }

  const onChangeRoles = (event: any): void => {
    if (
      (user && (event.detail.value === user.roles || (!event.detail.value && !user.roles))) ||
      (!user && !event.detail.value)
    ) {
      setRolesValidationError('')
      setRoles(undefined)
      return
    }
    setRoles(event.detail.value)
    if (event.detail.value) {
      setRolesValidationError(validationHelpers.validateUserRoles(event.detail.value))
    } else {
      setRolesValidationError('')
    }
  }

  const onChangeAppFeatures = (event: any): void => {
    if (
      (user && (event.detail.value === user.appFeatures || (!event.detail.value && !user.appFeatures))) ||
      (!user && !event.detail.value)
    ) {
      setAppFeaturesValidationError('')
      setAppFeatures(undefined)
      return
    }
    setAppFeatures(event.detail.value)
    if (event.detail.value) {
      // setAppFeaturesValidationError(validationHelpers.validateUserAppFeatures(event.detail.value))
    } else {
      setAppFeaturesValidationError('')
    }
  }

  const onChangeTrustLevel = (event: any): void => {
    if (
      (user && (event.detail.value === user.trustLevel || (!event.detail.value && !user.trustLevel))) ||
      (!user && !event.detail.value)
    ) {
      setTrustLevelValidationError('')
      setTrustLevel(undefined)
      return
    }
    setTrustLevel(event.detail.value)
    if (event.detail.value) {
      setTrustLevelValidationError(validationHelpers.validateTrustLevel(event.detail.value))
    } else {
      setTrustLevelValidationError('')
    }
  }

  const onChangeImageUrl = (event: any): void => {
    if (
      (user && (event.detail.value === user.imageUrl || (!event.detail.value && !user.imageUrl))) ||
      (!user && !event.detail.value)
    ) {
      setImageUrlValidationError('')
      setImageUrl(undefined)
      return
    }
    setImageUrl(event.detail.value)
    if (event.detail.value) {
      setImageUrlValidationError(validationHelpers.validateUserImageUrl(event.detail.value))
    } else {
      setImageUrlValidationError('')
    }
  }

  const onChangeSource = (event: any): void => {
    if (
      (user && (event.detail.value === user.source || (!event.detail.value && !user.source))) ||
      (!user && !event.detail.value)
    ) {
      setSourceValidationError('')
      setSource(undefined)
      return
    }
    setSource(event.detail.value)
    if (event.detail.value) {
      // setSourceValidationError(validationHelpers.validateUserSource(event.detail.value))
    } else {
      setSourceValidationError('')
    }
  }

  const onChangeAdminNotes = (event: any): void => {
    if (
      (user && (event.detail.value === user.adminNotes || (!event.detail.value && !user.adminNotes))) ||
      (!user && !event.detail.value)
    ) {
      setAdminNotesValidationError('')
      setAdminNotes(undefined)
      return
    }
    setAdminNotes(event.detail.value)
    if (event.detail.value) {
      // setAdminNotesValidationError(validationHelpers.validateUserAdminNotes(event.detail.value))
    } else {
      setAdminNotesValidationError('')
    }
  }

  const onFormSubmit = (event?: any): void => {
    if (event) {
      event.preventDefault()
    }
    const updatedUser: UserInput = {}
    if (user && user.id) {
      updatedUser.id = user.id
    }
    if (username !== undefined && (!user || username !== user.username)) {
      updatedUser.username = username || null
    }
    if (fullName !== undefined && (!user || fullName !== user.fullName)) {
      updatedUser.fullName = fullName || null
    }
    if (roles !== undefined && (!user || roles !== user.roles)) {
      updatedUser.roles = roles as UserRole || null
    }
    if (appFeatures !== undefined && (!user || appFeatures !== user.appFeatures)) {
      updatedUser.appFeatures = appFeatures || null
    }
    if (trustLevel !== undefined && (!user || parseInt(trustLevel) !== user.trustLevel)) {
      updatedUser.trustLevel = parseInt(trustLevel)
    }
    if (imageUrl !== undefined && (!user || imageUrl !== user.imageUrl)) {
      updatedUser.imageUrl = imageUrl || null
    }
    if (source !== undefined && (!user || source !== user.source)) {
      updatedUser.source = source || null
    }
    if (adminNotes !== undefined && (!user || adminNotes !== user.adminNotes)) {
      updatedUser.adminNotes = adminNotes || null
    }

    onSave(updatedUser)
  }

  return (
    <form onSubmit={onFormSubmit}>
      <FormItem label='Handle' validationError={usernameValidationError} withBottomMargin>
        <IonInput
          autofocus
          onIonChange={onChangeUsername}
          placeholder='a unique handle'
          value={username !== undefined ? username : (user ? user.username : '')}
        />
      </FormItem>
      <FormItem label='Name' validationError={fullNameValidationError} withBottomMargin>
        <IonInput
          autofocus
          autocapitalize='on'
          onIonChange={onChangeFullName}
          placeholder='first and last name'
          value={fullName !== undefined ? fullName : (user ? user.fullName : '')}
        />
      </FormItem>
      <FormItem label='Roles' validationError={rolesValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeRoles}
          placeholder='roles'
          value={roles !== undefined ? roles : (user ? user.roles as string : '')}
        />
      </FormItem>
      <FormItem label='App features' validationError={appFeaturesValidationError}>
        <IonInput
          onIonChange={onChangeAppFeatures}
          value={appFeatures !== undefined ? appFeatures : (user ? user.appFeatures as string : '')}
        />
      </FormItem>
      <div className='smallText lightText withStandardBottomMargin'>
        <strong>cc</strong> - pay with credit card <br />
        <strong>bdremind</strong> - receive birthday reminders <br />
        <strong>donate</strong> - donate rewards <br />
      </div>
      <FormItem label='TrustLevel' validationError={trustLevelValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeTrustLevel}
          placeholder='0000'
          value={trustLevel !== undefined ? trustLevel : (user ? user.trustLevel : '')}
        />
      </FormItem>
      <FormItem label='Avatar URL' validationError={imageUrlValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeImageUrl}
          placeholder='avatar url'
          value={imageUrl !== undefined ? imageUrl : (user ? user.imageUrl : '')}
        />
      </FormItem>
      <FormItem
        label='Source'
        validationError={sourceValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeSource}
          placeholder='source'
          value={source !== undefined ? source : (user ? user.source : '')}
        />
      </FormItem>
      <FormItem
        label='Admin notes'
        validationError={adminNotesValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeAdminNotes}
          placeholder='any internal notes about this member'
          value={adminNotes !== undefined ? adminNotes : (user ? user.adminNotes : '')}
        />
      </FormItem>
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onGoBack}
        >
          Back
        </IonButton>
        <SubmitButton
          onClick={onFormSubmit}
          disabled={!isDirty || !isValid}
        >
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default UserForm
