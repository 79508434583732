import type { Reward, User } from '../../../definitions'
import { RewardType } from '../../../enums'

const getContactUser = (reward: Reward): Partial<User> | undefined => {
  if (!reward || !reward.rewardType) {
    return
  }

  if (
    [
      RewardType.CARD_BALANCE_UPDATE,
      RewardType.GIFT_CARD_UPLOAD,
      RewardType.MARKETPLACE_PURCHASE,
    ].includes(reward.rewardType)
  ) {
    return
  }

  if (
    reward.rewardType === RewardType.ADD_CONTACT ||
    reward.rewardType === RewardType.ADDED_AS_A_CONTACT ||
    reward.rewardType === RewardType.ADD_GIFT_IDEA
  ) {
    if (!reward.forContact) {
      return
    }
    if (reward.rewardType === RewardType.ADDED_AS_A_CONTACT) {
      return reward.forContact.fromUser || { id: reward.forContact.fromUserId }
    } else {
      return reward.forContact.toUser || { id: reward.forContact.toUserId }
    }
  }

  if (
    reward.rewardType === RewardType.INVITED_BY_USER ||
    reward.rewardType === RewardType.NEW_USER_INVITATION
  ) {
    if (!reward.forInvitation) {
      return
    }
    return reward.forInvitation.toUser || { id: reward.forInvitation.toUserId }
  }

  if (reward.rewardType === RewardType.PURCHASE_TRANSFER) {
    if (!reward.forPurchaseTransfer) {
      return
    }
    return reward.forPurchaseTransfer.toUser || { id: reward.forPurchaseTransfer.toUserId }
  }
}

export default getContactUser
