import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import './styles.css'
import { logoFacebook, logoGoogle, logoTwitter } from 'ionicons/icons'

type Props = {
  onSignInWithFacebook: () => void;
  onSignInWithGoogle: () => void;
  onSignInWithTwitter: () => void;
};

const OAuthButtons: React.FC<Props> = (props): JSX.Element => {
  const { onSignInWithFacebook, onSignInWithGoogle, onSignInWithTwitter } = props
  return (
    <div className='o-auth-buttons'>
      <div className='inner'>
        <IonButton size='small' fill='outline' onClick={() => onSignInWithFacebook()}>
          <IonIcon icon={logoFacebook} slot='start' className='button' />
          Facebook
        </IonButton>
        <IonButton size='small' fill='outline' onClick={() => onSignInWithGoogle()}>
          <IonIcon icon={logoGoogle} slot='start' className='button' />
          Google
        </IonButton>
        <IonButton size='small' fill='outline' onClick={() => onSignInWithTwitter()}>
          <IonIcon icon={logoTwitter} slot='start' className='button' />
          Twitter
        </IonButton>
      </div>
    </div>
  )
}

export default OAuthButtons
