import React from 'react'

import './styles.css'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { FiatCurrency, FundType, TokenName } from '../../../lib/core/enums'
import type { ShoppingCart } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import SubmitSection from '../SubmitSection'
import WarningBlock from '../../../components/WarningBlock/WarningBlock'

type Props = {
  shoppingCart: ShoppingCart
  isActiveShoppingCart: boolean
  isSavingShoppingCart: boolean
  isShoppingCartValid: boolean
  isTokenPaymentEnabled: boolean
  onClearShoppingCart: () => void
  onDeletePendingOrder: () => void
  onPlaceOrder: () => void
}

const MimbleTokenForm: React.FC<Props> = (props): JSX.Element | null => {
  const {
    shoppingCart,
    isActiveShoppingCart,
    isSavingShoppingCart,
    isShoppingCartValid,
    isTokenPaymentEnabled,
    onClearShoppingCart,
    onDeletePendingOrder,
    onPlaceOrder,
  } = props

  const { getActiveUserMimbleWallet } = useMimbleData()
  const mimbleWallet = getActiveUserMimbleWallet && getActiveUserMimbleWallet()
  const mitBalance = (mimbleWallet && mimbleWallet.balanceToken) || 0
  let isValid = true

  const hasMimbleTokensItem = shoppingCart && coreHelpers.models.shoppingCart.hasMimbleTokensItem(shoppingCart)
  const mitBalanceInFiat = coreHelpers.cryptoCurrency.convertMimbleTokenToFiat(mitBalance)
  const formattedMitBalance = coreHelpers.ui.formatAmount(
    mitBalance,
    FundType.TOKEN,
    TokenName.MIMBLE_TOKEN,
    true,
  )
  const formattedMitBalanceInFiat = coreHelpers.ui.formatAmount(
    mitBalanceInFiat,
    FundType.FIAT,
    FiatCurrency.USD,
    true,
  )

  // ===================================================================================================================
  // Rendering:
  let topSection: JSX.Element | undefined
  if (shoppingCart) {
    if (hasMimbleTokensItem) {
      const messageElement = (
        <div>
          Please switch to another payment method since you are buying Mimble Tokens (MIT) with
          this order. Or delete the MIT row and order the tokens in a separate order.
        </div>
      )
      topSection = (
        <WarningBlock
          messageElement={messageElement}
          className='withDoubleBottomMargin'
        />
      )
      isValid = false
    } else if (!mitBalance || mitBalance < 1) {
      const messageElement = (
        <div>
          You don&apos;t have any Mimble Tokens in your wallet. Please switch to another
          payment method.
        </div>
      )
      topSection = (
        <WarningBlock
          messageElement={messageElement}
          className='withDoubleBottomMargin'
        />
      )
      isValid = false
    } else if ((shoppingCart.paymentAmount || 0) > mitBalance) {
      const messageElement = (
        <div>
          The payment amount of this order exceeds the balance of your Mimble Wallet.
          <br />
          <br />
          <span className='lightText'>
            You are paying for this order with your Mimble Wallet using Mimble Tokens (MITs).
            Your current wallet balance is: {formattedMitBalance} ({formattedMitBalanceInFiat}).
            Please either switch to another payment method, or remove items from your shopping cart
            until the order can be paid with your Mimble Token balance.
          </span>
        </div>
      )
      topSection = (
        <WarningBlock
          messageElement={messageElement}
          className='withDoubleBottomMargin'
        />
      )
      isValid = false
    } else {
      topSection = (
        <div className='section'>
          You are paying for this order with your Mimble Wallet using Mimble Tokens (MIT).
          <br />
          <br />
          <span className='lightText'>Your current wallet balance is: {formattedMitBalance} ({formattedMitBalanceInFiat}).</span>
        </div>
      )
    }
  }

  return (
    <div className='mimble-token-form'>
      {topSection}
      <SubmitSection
        shoppingCart={shoppingCart}
        isActiveShoppingCart={isActiveShoppingCart}
        isSavingShoppingCart={isSavingShoppingCart}
        enableSubmitButton={
          isTokenPaymentEnabled &&
          isShoppingCartValid &&
          !isSavingShoppingCart &&
          isValid
        }
        onClearShoppingCart={onClearShoppingCart}
        onDeletePendingOrder={onDeletePendingOrder}
        onPlaceOrder={onPlaceOrder}
      />
    </div>
  )
}

export default MimbleTokenForm
