import { gql } from '@apollo/client'

export default gql`
  query Q($chatId: String $userIds: [String]) {
    chat(chatId: $chatId, userIds: $userIds) {
      id
      fromUserId
      toUserId
      status
      metadata {
        fromUser {
          username
          fullName
          email
          phoneNumber
          unreadMessageCount
        }
        toUser {
          username
          fullName
          email
          phoneNumber
          unreadMessageCount
        }
        updatedAt
      }
      recordStatus
      createdAt
      updatedAt
      fromUser { id username fullName email phoneNumber roles }
      toUser { id username fullName email phoneNumber roles }
    }
  }
`
