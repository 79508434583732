import React from 'react'
import { animated, useSpring } from 'react-spring'

import './styles.css'
import type { Currency } from '../../lib/core/definitions'
import type {
  PurchaseStatus,
  PurchaseTagType,
  RecordStatus,
} from '../../lib/core/enums'
import { useExportToPdf } from '../../contexts/ExportToPdfContext'
import ActionPane from './ActionPane'
import type { GiftCardProps } from '../GiftCard/GiftCard'
import GiftCard from '../GiftCard/GiftCard'
import PurchaseStatusTags from '../PurchaseStatusTags/PurchaseStatusTags'

export enum GiftCardHeaderLayout {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

type AnimationProps = {
  maxWidth: string
}

const animationProps = {
  normal: {
    maxWidth: '100vw',
  },
  minimized: {
    maxWidth: '0vw',
  },
}

type Props = {
  purchaseId?: string | null
  purchaseStatus?: PurchaseStatus
  recordStatus?: RecordStatus
  backgroundImageUri?: string
  merchantLogoUri?: string
  currency?: Currency | null
  formattedBalance?: string
  layout?: GiftCardHeaderLayout
  minimized?: boolean
  onClickTag?: (tagType: PurchaseTagType, value: RecordStatus | PurchaseStatus | null | undefined) => void
  onDeletePurchase?: (confirmed?: boolean) => void
  onEditPurchase?: () => void
  onArchivePurchase?: () => void
  onUnArchivePurchase?: () => void
  onTransferPurchase?: () => void
  onVisitMerchant?: () => void
};

const GiftCardHeader: React.FC<Props> = (props): JSX.Element | null => {
  const {
    purchaseId,
    purchaseStatus,
    recordStatus,
    backgroundImageUri,
    merchantLogoUri,
    currency,
    formattedBalance,
    layout,
    minimized,
    onClickTag,
    onDeletePurchase,
    onEditPurchase,
    onArchivePurchase,
    onUnArchivePurchase,
    onTransferPurchase,
    onVisitMerchant,
  } = props

  // ===================================================================================================================
  // State:
  const { exportToPdf } = useExportToPdf()
  const [animationStyle] = useSpring<AnimationProps>(minimized
    ? animationProps.minimized
    : animationProps.normal,
  [minimized],
  )

  // ===================================================================================================================
  // Event Handlers:
  const onExportToPdf = (): void => {
    exportToPdf()
  }

  if (!backgroundImageUri && !merchantLogoUri) {
    return null
  }

  let balanceSection
  if (formattedBalance && currency) {
    let balanceSectionClass
    switch (layout) {
      case GiftCardHeaderLayout.LARGE:
        balanceSectionClass = 'balance-section-large'
        break
      case GiftCardHeaderLayout.MEDIUM:
        balanceSectionClass = 'balance-section-medium'
        break
      case GiftCardHeaderLayout.SMALL:
        balanceSectionClass = 'balance-section-small'
        break
    }
    balanceSection = (
      <div key='balance' className={balanceSectionClass}>
        <div>
          <span className='currency'>{currency}</span>
          <span className='balance'>{formattedBalance}</span>
        </div>
        {/* <div className='smallText lightText'>Gift Card Amount</div> */}
      </div>
    )
  }

  let tagSection
  if (recordStatus && purchaseStatus) {
    tagSection = (
      <div className='action-and-tag-section'>
        <ActionPane
          purchaseId={purchaseId || ''}
          purchaseStatus={purchaseStatus}
          recordStatus={recordStatus}
          className='action-pane'
          onEditPurchase={onEditPurchase}
          onArchivePurchase={onArchivePurchase}
          onUnArchivePurchase={onUnArchivePurchase}
          onDeletePurchase={() => onDeletePurchase && onDeletePurchase()}
          onDownloadPurchase={onExportToPdf}
          onTransferPurchase={onTransferPurchase}
          onVisitMerchant={onVisitMerchant}
        />
        <div className='tags-wrapper'>
          <PurchaseStatusTags
            recordStatus={recordStatus}
            status={purchaseStatus}
            showActiveStatus
            onClickTag={onClickTag}
          />
        </div>
      </div>
    )
  }

  let cardWrapperClass
  switch (layout) {
    case GiftCardHeaderLayout.LARGE:
      cardWrapperClass = 'giftCardHeaderCardWrapper with75PercentWidth'
      break
    case GiftCardHeaderLayout.MEDIUM:
      cardWrapperClass = 'giftCardHeaderCardWrapper with50PercentWidth'
      break
    case GiftCardHeaderLayout.SMALL:
      cardWrapperClass = 'giftCardHeaderCardWrapper with30PercentWidth'
      break
  }

  const giftCardProps: GiftCardProps = {
    purchaseId: purchaseId as string,
    purchaseStatus: purchaseStatus,
    recordStatus: recordStatus,
    backgroundImageUri: backgroundImageUri,
    merchantLogoUri: merchantLogoUri,
    onEditPurchase,
    onArchivePurchase,
    onUnArchivePurchase,
    onDeletePurchase: () => { onDeletePurchase && onDeletePurchase() },
    onTransferPurchase,
    onVisitMerchant,
  }
  return (
    <animated.div className='gift-card-header' style={animationStyle}>
      <div className={cardWrapperClass}>
        <GiftCard {...giftCardProps} />
      </div>
      {balanceSection}
      {tagSection}
    </animated.div>
  )
}

export default GiftCardHeader
