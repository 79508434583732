import { gql } from '@apollo/client'

export default gql`
  query Q($contactEventReminderId: String) {
    contactEventReminder (contactEventReminderId: $contactEventReminderId) {
      id
      eventId
      timing
      notificationMethod
      messageText
      sentAt
      sendReport
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
