import type { Contact, ContactUserListItem, User, UserIdentInfo } from '../../../definitions'

export interface GetContactNameResult {
  fullName: string | undefined
  nickname: string | undefined
  composite: string | undefined
}

const getContactNames = (
  contact: Contact | ContactUserListItem | null | undefined,
  contactUser: User | UserIdentInfo | null | undefined,
): GetContactNameResult => {
  if (!contact && !contactUser) {
    return {
      fullName: undefined,
      nickname: undefined,
      composite: undefined,
    }
  }

  let fullName: string | undefined

  if (contactUser && contactUser.fullName) {
    fullName = contactUser.fullName
  }

  if (!fullName && contact && (contact as ContactUserListItem).fullName) {
    fullName = (contact as ContactUserListItem).fullName
  }

  if (!fullName && contact && (contact as Contact).toUser) {
    const toUser = (contact as Contact).toUser
    if (toUser && toUser.fullName) {
      fullName = toUser.fullName
    }
  }

  const nickname = (
    (contact && contact.nickname) ||
    (contactUser && (contactUser as UserIdentInfo).nickname) ||
    undefined
  )

  let composite: string | undefined
  if (fullName) {
    composite = nickname
      ? `${nickname} (${fullName})`
      : fullName
  } else {
    composite = nickname
  }

  return {
    fullName,
    nickname,
    composite,
  }
}

export default getContactNames
