import React, { useEffect, useState } from 'react'
import { IonIcon } from '@ionic/react'
import { informationCircleOutline } from 'ionicons/icons'
import { useQuery } from '@apollo/client'

import './styles.css'
import type { AppRoute } from '../../../enums'
import type { RewardType } from '../../../lib/core/enums'
import { RecordStatus } from '../../../lib/core/enums'
import type { RewardConfigsQueryData } from '../../../services/apollo/definitions'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import apollo from '../../../services/apollo'
import coreHelpers from '../../../lib/core/helpers'
import EarnRewardsItem from './EarnRewardsItem'

type Props = {
  refreshIndex: number
  onNavigate: (route: AppRoute | string) => void
  showUiMessage: (message: string) => void
  onBuyTokens: () => void
  onFinishedLoading: () => void
}

const EarnRewardsTab: React.FC<Props> = (props): JSX.Element => {
  const {
    refreshIndex,
    onNavigate,
    showUiMessage,
    onFinishedLoading,
  } = props

  // ===================================================================================================================
  // State:
  const { getActiveUser } = useGlobalCache()
  const localUser = getActiveUser()
  const userCanPurchase = coreHelpers.models.user.canPurchase(localUser)

  const [selectedRewardType, setSelectedRewardType] = useState<RewardType | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: rewardConfigsData,
    refetch: reloadRewardConfigs,
  } = useQuery<RewardConfigsQueryData>(
    apollo.queries.rewardConfigs, {
      notifyOnNetworkStatusChange: true,
      onCompleted: onFinishedLoading,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
    },
  )
  const rewardConfigs = rewardConfigsData ? rewardConfigsData.rewardConfigs : undefined

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (refreshIndex > 0 && reloadRewardConfigs) {
      reloadRewardConfigs()
    }
  }, [refreshIndex])

  // ===================================================================================================================
  // Event Handlers:
  const onNavigateTo = (route: string): void => {
    onNavigate(route)
  }

  const onSelectItem = (rewardType: RewardType): void => {
    setSelectedRewardType(rewardType)
  }

  // ===================================================================================================================
  // Rendering:
  let intro: JSX.Element | undefined
  if (userCanPurchase) {
    intro = (
      <div className='withDoubleBottomMargin'>
        <h2>How Do I Earn Rewards?</h2>
        <div>
          As you use and explore Mimble, you&apos;re bound to earn rewards!
          Rewards are issued as MIT tokens that can be used in the marketplace, and can be earned various ways.
          When you are rewarded, the Mimble Tokens are immediately available to you in your Mimble Wallet.
          <br />
          <br />
          <div className='conversion-tip'>
            <IonIcon icon={informationCircleOutline} className='icon' />
            100 MIT : $1.00
          </div>
        </div>
      </div>
    )
  }

  // Rows:
  let rows: JSX.Element[] | undefined
  if (rewardConfigs) {
    rows = rewardConfigs.filter((r) => (r.recordStatus === RecordStatus.ACTIVE))
      .map((rewardConfig) => (
        <EarnRewardsItem
          config={rewardConfig}
          key={`${rewardConfig.rewardType}-${rewardConfig.invitationLevel}`}
          isSelected={rewardConfig.rewardType === selectedRewardType}
          onNavigateTo={onNavigateTo}
          onSelect={onSelectItem}
        />
      ))
  }

  return (
    <div className='withStandardMargin earn-rewards-tab'>
      {intro}
      <div className='earn-rewards-list'>
        <br />
        {rows}
      </div>
    </div>
  )
}

export default EarnRewardsTab
