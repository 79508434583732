import type { ApolloClient } from '@apollo/client'

import type { TfaAttribute } from '../definitions'
import api from '../../../../../services/api'

const previousVerificationResults = new Map<string, string>()

const checkIfValueIsAvailable = (
  value: string,
  attribute: TfaAttribute,
  apolloClient: ApolloClient<any>,
): Promise<true | string> => {
  return new Promise((resolve) => {
    const previousResult = previousVerificationResults.get(value)
    if (previousResult) {
      console.log('TfaFieldForm.helpers.checkIfValueIsAvailable: already checked this value.')
      resolve(previousResult)
      return
    }

    const filter = attribute === 'phoneNumber'
      ? { phoneNumber: value, exactMatch: true }
      : { email: value, exactMatch: true }
    api.findUser(filter, false, apolloClient)
      .then((userIdentInfos) => {
        if (Array.isArray(userIdentInfos) && userIdentInfos.length === 1) {
          const message = attribute === 'phoneNumber'
            ? 'This number is associated with another member account.'
            : 'This email is associated with another member account.'
          previousVerificationResults.set(value, message)
          resolve(message)
          return
        }
        resolve(true)
      })
  })
}

export default checkIfValueIsAvailable
