import React from 'react'
import { IonButton } from '@ionic/react'

import './styles.css'
import type { Chat, PurchaseListItem, UserPrefs, WalletListItem } from '../../../lib/core/definitions'
import type { ChatAttachmentType } from '../../../lib/core/enums'
import { PurchaseListScope } from '../../../lib/core/enums'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import ChatDashboardWidget from './widgets/ChatDashboardWidget'
import coreHelpers from '../../../lib/core/helpers'
import GiftCardsDashboardWidget from './widgets/GiftCardsDashboardWidget'
import MimbleWalletDashboardWidget from './widgets/MimbleWalletDashboardWidget/MimbleWalletDashboardWidget'
// import NewUserSendDashboardWidget from './widgets/NewUserSendDashboardWidget'
// import NewUserUploadDashboardWidget from './widgets/NewUserUploadDashboardWidget'
import pageHelpers from '../../../helpers/pageHelpers'
import QuickActionsDashboardWidget from './widgets/QuickActionsDashboardWidget'
// import WelcomeMessageDashboardWidget from './widgets/WelcomeMessageDashboardWidget'

type Props = {
  userChats: Chat[] | null | undefined;
  userPurchases: PurchaseListItem[] | null | undefined;
  userWallets: WalletListItem[] | null | undefined;
  onAddUploadedPurchase: () => void;
  onCreatePurchase: () => void;
  onAddFundsToWallet: () => void;
  onOpenChat: (chatId: string) => void;
  onOpenSupportChat: () => void;
  onOpenChatAttachment?: (id: string, type: ChatAttachmentType) => void;
  onOpenContactsPage: () => void;
  onOpenPurchase: (id: string) => void;
  onOpenDigitalWallet: (scope: PurchaseListScope) => void;
  onOpenUserAccount: () => void;
  onUpdateUserPrefs: (prefs: UserPrefs) => void;
};

const DashboardWidgets: React.FC<Props> = (props): JSX.Element | null => {
  const {
    userChats,
    userPurchases,
    userWallets,
    onAddUploadedPurchase,
    onCreatePurchase,
    onAddFundsToWallet,
    onOpenChat,
    onOpenSupportChat,
    onOpenChatAttachment,
    onOpenContactsPage,
    onOpenPurchase,
    onOpenDigitalWallet,
    onOpenUserAccount,
    onUpdateUserPrefs,
  } = props

  // ===================================================================================================================
  // State:
  const { getActiveUser } = useGlobalCache()
  const localUser = getActiveUser()
  const { activeUser, getActiveUserMimbleWallet } = useMimbleData()
  const mimbleWallet = getActiveUserMimbleWallet && getActiveUserMimbleWallet()
  const userMetadata = activeUser ? activeUser.metadata : undefined
  const userCanPurchase = coreHelpers.models.user.canPurchase(localUser)
  const userCanBuyTokens = coreHelpers.models.user.canBuyMimbleTokens(localUser)

  // ===================================================================================================================
  // Event Handlers:
  const onHideWelcomeMessage = () => {
    if (!activeUser) {
      return
    }
    onUpdateUserPrefs({ showWelcomeMessage: false })
  }

  const onHideTipUploadCard = () => {
    if (!activeUser) {
      return
    }
    onUpdateUserPrefs({ showTipUploadCard: false })
  }

  const onHideTipSendCard = () => {
    if (!activeUser) {
      return
    }
    onUpdateUserPrefs({ showTipSendCard: false })
  }

  // ===================================================================================================================
  // Rendering:
  const cards: JSX.Element[] = []
  // const showWelcomeMessage = activeUser && !(activeUser.prefs && activeUser.prefs.showWelcomeMessage === false)
  // const showTipUploadCard = !showWelcomeMessage && activeUser && !(activeUser.prefs && activeUser.prefs.showTipUploadCard === false)
  // const showTipSendCard = !showWelcomeMessage && !showTipUploadCard && activeUser && !(activeUser.prefs && activeUser.prefs.showTipSendCard === false)
  //
  // if (showWelcomeMessage) {
  //   cards.push(
  //     <WelcomeMessageDashboardWidget onHide={onHideWelcomeMessage} />,
  //   )
  // }
  //
  // if (showTipUploadCard) {
  //   cards.push(
  //     <NewUserUploadDashboardWidget onHide={onHideTipUploadCard} />,
  //   )
  // }
  //
  // if (showTipSendCard) {
  //   cards.push(
  //     <NewUserSendDashboardWidget onHide={onHideTipSendCard} />,
  //   )
  // }

  cards.push(
    <MimbleWalletDashboardWidget
      key='mi-wallet'
      wallet={mimbleWallet}
      userMetadata={userMetadata}
      onAddFundsToWallet={onAddFundsToWallet}
      userCanBuyTokens={userCanBuyTokens}
    />,
  )

  cards.push(
    <QuickActionsDashboardWidget
      key='actions'
      userCanPurchase={userCanPurchase}
      userCanBuyTokens={userCanBuyTokens}
      onAddUploadedPurchase={onAddUploadedPurchase}
      onCreatePurchase={onCreatePurchase}
      onAddFundsToWallet={onAddFundsToWallet}
      onOpenUserAccount={onOpenUserAccount}
    />,
  )

  if (Array.isArray(userChats) && userChats.length > 0) {
    cards.push(
      <ChatDashboardWidget
        key='chats'
        chats={userChats}
        onOpenContactsPage={onOpenContactsPage}
        onOpenSupportChat={onOpenSupportChat}
        onOpenChat={onOpenChat}
      />,
    )
  }

  if (userPurchases) {
    const favoritePurchases = pageHelpers.filterPurchaseList(userPurchases, undefined, PurchaseListScope.FAVORITES)
    const receivedPurchases = pageHelpers.filterPurchaseList(userPurchases, undefined, PurchaseListScope.RECEIVED)
    const sentPurchases = pageHelpers.filterPurchaseList(userPurchases, undefined, PurchaseListScope.SENT)

    if (Array.isArray(favoritePurchases) && favoritePurchases.length > 0) {
      let buyButton
      if (userCanPurchase) {
        buyButton = (
          <IonButton onClick={onCreatePurchase} size='small'>Buy</IonButton>
        )
      }
      const actions = (
        <div className='bottom-buttons'>
          <IonButton onClick={onAddUploadedPurchase} size='small'>Upload</IonButton>
          {buyButton}
        </div>
      )
      cards.push(
        <GiftCardsDashboardWidget
          purchases={favoritePurchases}
          listScope={PurchaseListScope.FAVORITES}
          onOpenPurchase={onOpenPurchase}
          onOpenDigitalWallet={onOpenDigitalWallet}
          caption='My Active Gift Cards'
          actionSection={actions}
          key='favs'
        />,
      )
    }
    if (Array.isArray(receivedPurchases) && receivedPurchases.length > 0) {
      cards.push(
        <GiftCardsDashboardWidget
          purchases={receivedPurchases}
          listScope={PurchaseListScope.RECEIVED}
          onOpenDigitalWallet={onOpenDigitalWallet}
          onOpenPurchase={onOpenPurchase}
          caption='Gift Cards I received'
          key='received'
        />,
      )
    }
    if (Array.isArray(sentPurchases) && sentPurchases.length > 0) {
      cards.push(
        <GiftCardsDashboardWidget
          purchases={sentPurchases}
          listScope={PurchaseListScope.SENT}
          onOpenDigitalWallet={onOpenDigitalWallet}
          onOpenPurchase={onOpenPurchase}
          caption='Gift Cards I Sent'
          key='sent'
        />,
      )
    }
  }

  return (
    <div className='list-content'>
      {cards}
    </div>
  )
}

export default DashboardWidgets
