import {
  AsyncTaskCommandType,
  AsyncTaskResult,
  AsyncTaskStatus,
  AsyncTaskType,
  NotificationMethod,
} from '../../../../../lib/core/enums'
import type { AsyncTaskInput } from '../../../../../lib/core/definitions'
import type { SaveAttributeArgs, SaveAttributeResult } from '../definitions'
import api from '../../../../../services/api'
import coreHelpers from '../../../../../lib/core/helpers'
import validateInput from './validateInput'

const saveAttribute = (args: SaveAttributeArgs): Promise<SaveAttributeResult> => {
  const {
    value,
    attribute,
    activeUserId,
    tfaTaskId,
    confirmToken,
    notificationMethod,
    reloadActiveUser,
    apolloClient,
  } = args

  return new Promise((resolve) => {
    const { valid, cleanValue, error } = validateInput(
      value,
      attribute,
    )
    if (error || !valid) {
      resolve({
        errorMessage: error || 'The entered value is not valid. Please correct and try again.',
      })
      return
    }

    if (cleanValue !== value) {
      args.value = cleanValue
    }

    const taskInput: AsyncTaskInput = {
      id: tfaTaskId,
      userId: activeUserId,
      confirmToken,
      command: AsyncTaskCommandType.VERIFY_TOKEN,
    }
    if (attribute === 'email') {
      taskInput.email = value || null
      taskInput.notificationMethod = NotificationMethod.EMAIL
      taskInput.taskType = AsyncTaskType.UPDATE_EMAIL
    } else if (attribute === 'phoneNumber') {
      taskInput.phoneNumber = value || null
      taskInput.notificationMethod = NotificationMethod.SMS
      taskInput.taskType = AsyncTaskType.UPDATE_PHONE_NUMBER
    } else if (attribute === 'password') {
      taskInput.notificationMethod = notificationMethod || NotificationMethod.SMS
      taskInput.taskType = AsyncTaskType.RESET_PASSWORD
      taskInput.metadata = {
        password: value as string,
      }
    }

    api.upsertAsyncTask(
      taskInput,
      true,
      coreHelpers.models.asyncTask.isNewTaskSendingNotificationCompleted,
      apolloClient,
    )
      .then((asyncTask) => {
        if (
          asyncTask &&
          asyncTask.taskStatus === AsyncTaskStatus.FINISHED &&
          asyncTask.result === AsyncTaskResult.OK
        ) {
          reloadActiveUser().then(() => {
            resolve({
              ok: true,
              uiMessage: 'Successfully saved changes.',
            })
          }, (error) => {
            console.error(error)
            resolve({
              errorMessage: 'Failed to save changes.',
            })
          })
          return
        }

        if (
          asyncTask &&
          asyncTask.result === AsyncTaskResult.CONFIRM_TOKEN_MISMATCH
        ) {
          return { error: 'The code did not match.' }
        }

        console.error('TfaFieldForm.helpers.saveValueWithTfaTask: unexpected TFA result', { asyncTask })
        resolve({ errorMessage: 'Failed to save changes.' })
      }, (error) => {
        console.log(error)
        console.error('TfaFieldForm.helpers.saveValueWithTfaTask: unexpected TFA result')
        resolve({ errorMessage: 'Failed to save changes.' })
      })
  })
}

export default saveAttribute
