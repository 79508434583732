import React from 'react'
import { IonSpinner } from '@ionic/react'

interface Props {
  onCancelPaymentRequest: () => void;
}

const PreparingPaymentRequestSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    onCancelPaymentRequest,
  } = props

  return (
    <div className='withCenteredColumnContent'>
      Preparing payment request...
      <div className='withStandardTopMargin'>
        <IonSpinner />
      </div>
    </div>
  )
}

export default PreparingPaymentRequestSection
