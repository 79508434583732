import moment from 'moment'
import React, { useContext, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { pencilOutline } from 'ionicons/icons'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import { UiMessage } from '../../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type {
  CampaignQueryData,
  CampaignQueryVariables,
  MemberMessageTemplatesQueryData, MemberMessageTemplatesQueryVariables,
} from '../../../services/apollo/definitions'
import type { CampaignUserInfoListFilter } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import IconButton from '../../../components/IconButton/IconButton'
import type { InfoPaneEntryDef } from '../../../components/InfoPane/InfoPane'
import InfoPane from '../../../components/InfoPane/InfoPane'
import ListHeader from '../../../components/ListHeader/ListHeader'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import RecipientGrid from './RecipientGrid'

const appPageId = AppPage.AdminCampaignPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  campaignId: string
}

const CampaignPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { campaignId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [recipientListFilter, setRecipientListFilter] = useState<CampaignUserInfoListFilter | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading campaign:
  const {
    data: campaignLoadedData,
    loading: isLoadingCampaign,
    refetch: reloadCampaign,
  } = useQuery<CampaignQueryData, CampaignQueryVariables>(
    apollo.queries.campaign, {
      variables: { campaignId: campaignId || '' },
      skip: !campaignId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: CampaignQueryData) => {
        const returnedCampaign = data ? data.campaign : undefined
        if (returnedCampaign === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const campaign = campaignLoadedData ? campaignLoadedData.campaign : undefined

  // -------------------------------------------------------------------------------------------------------------------
  // Loading member message templates:
  const {
    data: memberMessageTemplatesData,
    loading: isLoadingMemberMessageTemplates,
    refetch: reloadMemberMessageTemplates,
  } = useQuery<MemberMessageTemplatesQueryData, MemberMessageTemplatesQueryVariables>(
    apollo.queries.memberMessageTemplates, {
      variables: { filter: {} },
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
      onCompleted: () => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
    },
  )
  const memberMessageTemplates = memberMessageTemplatesData ? memberMessageTemplatesData.memberMessageTemplates : undefined
  const template = campaign && Array.isArray(memberMessageTemplates)
    ? memberMessageTemplates.find(t => t.id === campaign.memberMessageTemplateId)
    : undefined
  const templateName = template && template.name

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingCampaign

  // ===================================================================================================================
  // Event Handlers:
  const onEditCampaign = (): void => {
    if (!campaignId) {
      return
    }
    navigate(`${AppRoute.ADMIN_EDIT_CAMPAIGN}/${campaignId}`)
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !campaignId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadCampaign({ campaignId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenUser = (userId: string): void => {
    navigate(`${AppRoute.ADMIN_USER}/${userId}`)
  }

  const onApplyRecipientListFilter = (searchText: string): void => {
    setRecipientListFilter({ searchText })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content: JSX.Element | undefined
  if (campaign) {
    const infoPaneEntries: InfoPaneEntryDef[] = [
      {
        key: 'id',
        label: 'ID',
        value: (campaign.id as string).replace(/-/g, ''),
      },
      {
        key: 'name',
        label: 'Name',
        value: campaign.name,
      },
      {
        key: 'template',
        label: 'Message Template',
        value: templateName,
      },
      {
        key: 'created-at',
        label: 'Created',
        value: moment(campaign.createdAt).toISOString(),
      },
    ]

    content = (
      <>
        <div className='row withStandardBottomMargin'>
          <div className='caption'>
            {campaign.name}
          </div>
          <IconButton
            key='edit'
            icon={pencilOutline}
            text='Edit'
            layout='vertical'
            className='button'
            iconSize={20}
            onClick={onEditCampaign}
          />
        </div>
        <div className='withRoundedBorder withStandardBottomMargin'>
          <InfoPane
            entries={infoPaneEntries}
          />
        </div>
        <div className='sectionCaption withDoubleTopMargin'>Recipients</div>
        <ListHeader
          searchText={recipientListFilter && recipientListFilter.searchText}
          className='withStandardLeftMargin'
          onApply={onApplyRecipientListFilter}
          showSearch
        />
        <RecipientGrid
          userInfos={campaign.users}
          filter={recipientListFilter}
          onOpenUser={onOpenUser}
        />
      </>
    )
  }

  return (
    <IonPage className='app-page-admin  campaign-page'>
      <NavBar
        title='Manage Campaign'
        goBackUri={AppRoute.ADMIN_CAMPAIGNS}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
          {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default CampaignPage
