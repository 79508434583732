import type { PurchaseTransfer } from '../../../lib/core/definitions'
import { IdentType } from '../../../lib/core/enums'

const getRecipientIdentType = (purchaseTransfer?: PurchaseTransfer): IdentType | undefined => {
  if (!purchaseTransfer) {
    return
  }
  if (purchaseTransfer.toUserId) {
    return IdentType.ID
  }
  if (purchaseTransfer.toUserUsername) {
    return IdentType.USERNAME
  }
  if (purchaseTransfer.toUserEmail) {
    return IdentType.EMAIL
  }
  if (purchaseTransfer.toUserPhoneNumber) {
    return IdentType.PHONE_NUMBER
  }
}

export default getRecipientIdentType
