import { MemberMessage } from '../../lib/core/definitions'
import { AppAction } from '../../lib/core/enums'
import { ApolloClient } from '@apollo/client'
import api from '../../services/api'
import logger from '../../services/logger'

const reportedMessageIds = new Set<string>()

const reportToServer = (
  memberMessage: MemberMessage,
  actionTaken: AppAction | undefined,
  activeUserId: string | null | undefined,
  reloadUnreadInAppMessages: () => Promise<MemberMessage[] | undefined>,
  apolloClient: ApolloClient<any>,
): void => {
  if (reportedMessageIds.has(memberMessage.id as string)) {
    console.log('InAppMessageModal.reportToServer: message already reported.')
    return
  }

  logger.info('InAppMessageModal.reportToServer called.',
    { memberMessage, activeUserId, actionTaken })

  if (memberMessage && memberMessage.id && activeUserId) {
    reportedMessageIds.add(memberMessage.id as string)
    api.markInAppMessageReceived(
      activeUserId,
      memberMessage.id,
      actionTaken,
      apolloClient,
    ).then(() => {
      logger.info('InAppMessageModal.reportToServer: successfully reported to server.')
      setTimeout(() => {
        reloadUnreadInAppMessages().then(undefined, (error) => {
          console.error(error)
        })
      }, 2000)
    }, (error) => {
      logger.error('InAppMessageModal.reportToServer: error received.', { error })
    })
  }
}

export default reportToServer
