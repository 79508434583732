import { gql } from '@apollo/client'

export default gql`
  query Q($purchaseTransferId: String, $toPurchaseId: String) {
    purchaseTransfer (purchaseTransferId: $purchaseTransferId, toPurchaseId: $toPurchaseId) {
      id
      fromPurchaseId
      toPurchaseId
      toUserId
      toUserFullName
      toUserEmail
      toUserPhoneNumber
      notificationMethod
      transferStatus
      messageText
      messageScheduledAt
      messageScheduledTimezone
      messageSentAt
      options
      metadata
      recordStatus
      createdAt
      updatedAt
      fromUser {
        id
        username
        fullName
        email
        phoneNumber
        imageUrl
        recordStatus
      }
      toUser {
        id
        username
        fullName
        email
        phoneNumber
        recordStatus
      }
      fromPurchase {
        id
        userId
        offerId
        productId
        productOptionId
        purchaseType
        amount
        initialBalance
        balance
        currency
        fundType
        code
        hasBarcode
        barcodeFormat
        pin
        balanceUpdatedAt
        referenceUrl
        messageEn
        issuedAt
        expiresAt
        adminNotes
        status
        source
        recordStatus
        createdAt
        updatedAt
        product {
          id
          merchantId
          productType
          code
          hasBarcode
          barcodeFormat
          referenceUrl
          messageEn
          termsEn
          termsUrl
          instructionsEn
          instructionsUrl
          imageSourceFrontSide
          imageSourceBackSide
          merchant {
            id
            name
            url
            smallLogoImageSource
            largeLogoImageSource
            balanceLookupUri
          }
          #        tags {}
        }
      }
      toPurchase {
        id
        userId
        offerId
        productId
        productOptionId
        purchaseType
        amount
        initialBalance
        balance
        currency
        fundType
        code
        hasBarcode
        barcodeFormat
        pin
        balanceUpdatedAt
        referenceUrl
        messageEn
        issuedAt
        expiresAt
        adminNotes
        status
        source
        metadata {
          amountPaid
          receivedTransfer {
            id
            fromUserId
            fromPurchaseId
            transferStatus
            recordStatus
          }
          sentTransfer {
            id
            toUserId
            toPurchaseId
            transferStatus
            recordStatus
          }
          latestError
          events {
            time
            type
            message
          }
        }
        recordStatus
        createdAt
        updatedAt
      }
    }
  }
`
