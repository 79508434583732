import type { ShoppingCartListItem } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'

// Call this after having deleted a shopping cart. It will reload all shopping carts until
// the deleted one is no longer found in the list.
const reloadPendingShoppingCartsUntilDeleted = (
  deletedShoppingCartId: string,
  reloadPendingShoppingCarts: () => Promise<ShoppingCartListItem[] | undefined>,
  timeout = 20000,
): Promise<void> => {
  const startTime = new Date().getTime()
  return new Promise<void>((resolve, reject) => {
    const reload = () => {
      reloadPendingShoppingCarts().then((shoppingCarts) => {
        if (
          !Array.isArray(shoppingCarts) ||
          shoppingCarts.length < 1 ||
          !shoppingCarts.find(cart => coreHelpers.models.compareId(cart.id, deletedShoppingCartId))
        ) {
          resolve()
          return
        }
        if (new Date().getTime() - startTime > timeout) {
          console.warn('ShoppingCartPage.helpers.reloadPendingShoppingCartsUntilDeleted: timed out.')
          resolve()
          return
        }
        setTimeout(reload, 1000)
      }, reject)
    }

    // We'll wait for a second before the first reloading - to give the backend time to delete the pending order.
    setTimeout(reload, 1000)
  })
}

export default reloadPendingShoppingCartsUntilDeleted
