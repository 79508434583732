import React from 'react'
import { IonIcon } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'

import './styles.css'
import { GiftFlowStep } from '../../enums'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import Stepper from '../Stepper/Stepper'

type Props = {
  size: 'small' | 'large'
  parent: 'send-gift-page' | 'marketplace' | 'upload'
  className?: string
  onClickStep?: (step: GiftFlowStep) => void;
}

const GiftFlowStepper: React.FC<Props> = (props): JSX.Element | null => {
  const {
    size,
    parent,
    className,
    onClickStep,
  } = props

  // ===================================================================================================================
  // State:
  const {
    giftFlowStep,
    fromPurchase,
    getGiftFlowSteps,
    getGiftFlowStepLabels,
  } = useGiftFlow()
  const flowSteps = getGiftFlowSteps()

  // ===================================================================================================================
  // Rendering:
  if (
    !flowSteps ||
    !giftFlowStep ||
    [GiftFlowStep.CONFIRM, GiftFlowStep.FINISHED].includes(giftFlowStep) ||
    ((parent === 'marketplace' || parent === 'upload') && giftFlowStep !== GiftFlowStep.GIFT)
  ) {
    return null
  }

  if (!size || size === 'large') {
    return (
      <div className={className}>
        <Stepper
          step={giftFlowStep}
          steps={flowSteps}
          getLabel={(step: string) => getGiftFlowStepLabels()[step as GiftFlowStep]}
        />
      </div>
    )
  }

  const steps: JSX.Element[] = flowSteps.reduce<JSX.Element[]>((result, step, index) => {
    const onHandleClickStep = (): void => {
      if (onClickStep) {
        onClickStep(step)
      }
    }

    const label = getGiftFlowStepLabels()[step as GiftFlowStep]
    const isActiveStep = step === giftFlowStep
    let activeIcon: JSX.Element | undefined
    // if (isActiveStep) {
    //   activeIcon = (
    //     <IonIcon
    //       icon={ellipse}
    //       className='icon'
    //     />
    //   )
    // }
    const stepClass = isActiveStep ? 'step active' : 'step'
    result.push(
      <div key={step} className={stepClass} onClick={onHandleClickStep}>
        {activeIcon}<span className='label'>{label}</span>
      </div>,
    )
    if (index < flowSteps.length - 1) {
      result.push(
        <IonIcon
          key={`${step}-arrow`}
          icon={chevronForwardOutline}
          className='arrowIcon'
        />,
      )
    }
    return result
  }, [])

  return (
    <div className={`gift-flow-stepper small ${className || ''}`}>
      {steps}
    </div>
  )
}

export default GiftFlowStepper
