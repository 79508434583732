import React, { useState } from 'react'
import { IonSpinner } from '@ionic/react'
import { useQuery } from '@apollo/client'

import type {
  FavoriteOrganizationInput,
  FavoriteOrganizationListItem
} from '../../../lib/core/definitions'
import type {
  MerchantsQueryData,
  MerchantsQueryVariables
} from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../../services/apollo'
import ActionModal from '../../../components/ActionModal/ActionModal'
import ListHeader from '../../../components/ListHeader/ListHeader'
import MerchantGrid from '../../../components/MerchantGrid/MerchantGrid'

type Props = {
  favoriteOrganizations: FavoriteOrganizationListItem[] | undefined
  show: boolean
  onCancel: () => void
  onSubmit: (favoriteOrganization: FavoriteOrganizationInput) => void
}

const FavoriteMerchantsForm: React.FC<Props> = ({
  show,
  favoriteOrganizations,
  onCancel,
  onSubmit,
}): JSX.Element => {
  // ===================================================================================================================
  // State:
  const { activeUser } = useMimbleData()
  const [searchText, setSearchText] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: merchantsData,
    loading: isLoadingMerchants,
  } = useQuery<MerchantsQueryData, MerchantsQueryVariables>(
    apollo.queries.merchants(true), {
      variables: { filter: { listed: true } },
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
      },
    },
  )
  const merchants = merchantsData ? merchantsData.merchants : undefined

  // ===================================================================================================================
  // Event Handlers:
  const onApplyListFilter = (newSearchText: string): void => {
    if (newSearchText !== searchText) {
      setSearchText(newSearchText)
    }
  }

  const onSelectMerchant = (merchantId: string): void => {
    // TODO 🚨 Implement adding notes =>
    // 1. On call, hide the grid
    // 2. Show the logo, an input field and a submit/cancel set
    const favoriteOrganizationInput: FavoriteOrganizationInput = {
      userId: activeUser && activeUser.id,
      organizationId: merchantId,
      // notes: noteInput,
    }
    onSubmit(favoriteOrganizationInput)
  }

  // ===================================================================================================================
  // Rendering:
  let content: JSX.Element | undefined
  if (isLoadingMerchants) {
    content = (
      <IonSpinner name='dots' className='spinner' />
    )
  } else if (Array.isArray(merchants) && merchants.length > 0) {
    const favoriteIds: string[] | undefined = Array.isArray(favoriteOrganizations) && favoriteOrganizations.length > 0
      ? favoriteOrganizations.reduce<string[]>((list, organization) => {
        if (organization.organizationIsMerchant && organization.organizationId) {
          list.push(organization.organizationId as string)
        }
        return list
      }, [])
      : undefined
    content = (
      <MerchantGrid
        searchText={searchText}
        merchants={merchants}
        favoriteIds={favoriteIds}
        onSelectMerchant={onSelectMerchant}
      />
    )
  }

  return (
    <ActionModal
      show={show}
      title='Favorite Brands'
      onCancel={onCancel}
      className='favorite-merchants-form'
    >
      <ListHeader
        searchText={searchText}
        showSearch
        onApply={onApplyListFilter}
        className='withStandardMargin'
      />
      <div className='grid-wrapper'>
        {content}
      </div>
    </ActionModal>
  )
}

export default FavoriteMerchantsForm
