import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { Reward, RewardListItem } from '../../../lib/core/definitions'
import type {
  RewardQueryData,
  RewardQueryVariables,
  RewardsQueryData,
  RewardsQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  reward: Reward,
  queryListVariables: RewardsQueryVariables,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateReward.updateInList called.', { reward })
  if (!reward) {
    logger.error('api.cache.updateReward.updateInList: no reward given.')
    return
  }

  try {
    const cachedRewardsData = apolloClient.readQuery<RewardsQueryData, RewardsQueryVariables>({
      query: apollo.queries.rewards,
      variables: queryListVariables,
    })

    if (
      !cachedRewardsData ||
      !Array.isArray(cachedRewardsData.rewards) ||
      cachedRewardsData.rewards.length < 1
    ) {
      const listItem = coreHelpers.models.reward.updateRewardListItemFromReward(undefined, reward)
      apolloClient.writeQuery<RewardsQueryData, RewardsQueryVariables>({
        query: apollo.queries.rewards,
        variables: queryListVariables,
        data: { rewards: [listItem] },
      })
      return
    }

    const updatedContactUsers = coreHelpers.models.updateModelInList<RewardListItem, Reward>(
      cachedRewardsData.rewards,
      reward,
      undefined,
      true,
      coreHelpers.models.reward.updateRewardListItemFromReward,
    )

    apolloClient.writeQuery<RewardsQueryData>({
      query: apollo.queries.rewards,
      variables: queryListVariables,
      data: { rewards: updatedContactUsers },
    })
  } catch (error) {
    logger.error('api.cache.updateReward.updateInList: error.', { reward, error })
  }
}

const updateReward = (
  reward: Reward,
  queryListVariables: RewardsQueryVariables | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateReward called.', { reward })
  if (!apolloClient) {
    logger.error('api.cache.updateReward: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!reward || !reward.id) {
    logger.error('api.cache.updateReward: no reward given.', { reward })
    return
  }

  try {
    apolloClient.writeQuery<RewardQueryData, RewardQueryVariables>({
      query: apollo.queries.reward,
      variables: { rewardId: reward.id },
      data: { reward },
    })

    if (queryListVariables) {
      updateInList(
        reward,
        queryListVariables,
        activeUserId,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateReward: error.', { reward, error })
  }
}

export default updateReward
