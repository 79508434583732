import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonToast,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from '@ionic/react'
// import { Update } from 'history'
import { useApolloClient } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'

import './styles.css'
import type { AsyncTask, User } from '../../lib/core/definitions'
import { AsyncTaskResult, AsyncTaskStatus, AsyncTaskType, UiMessage } from '../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm'

const appPageId = AppPage.ConfirmAsyncTaskTokenPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

type Params = {
  taskId: string
  taskType: AsyncTaskType
  token: string
}

const ConfirmAsyncTaskTokenPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  // const locationUpdate: Update = useLocation()
  // const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const {
    taskId: taskIdFromRequestParams,
    taskType: taskTypeFromRequestParams,
    token: confirmTokenFromRequestParam,
  } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { isLoadingActiveUser, reloadActiveUser } = useMimbleData()
  const pageMessages = useContext(PageMessagesContext)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [isProcessingTask, setIsProcessingTask] = useState(false)
  const [asyncTask, setAsyncTask] = useState<AsyncTask | undefined>()

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isProcessingTask

  const processConfirmedSignInWithTokenTask = (task: AsyncTask): void => {
    if (!task || !task.metadata) {
      return
    }

    const user: User = {
      id: task.userId,
      authToken: task.metadata.authToken,
    }
    if (task.username) {
      user.username = task.username
    }
    if (task.email) {
      user.email = task.email
    }
    if (task.phoneNumber) {
      user.phoneNumber = task.phoneNumber
    }

    pageHelpers.handleSignIn({
      user,
      reloadActiveUser,
      apolloClient,
      navigate,
    }).then(() => {
      setIsProcessingTask(false)
      navigate(AppRoute.HOME, true)
    }, (error) => {
      console.warn(error)
      setIsProcessingTask(false)
      setToastMessage('Failed to sign you in. Please check your input and try again.')
      setShowToast(true)
    })
  }

  const confirmToken = () => {
    if (!taskIdFromRequestParams || !confirmTokenFromRequestParam) {
      console.error('ConfirmAsyncTaskTokenPage.useIonViewWillEnter: missing task id or token', taskTypeFromRequestParams)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_PROCESSING_REQUEST)
      setToastMessage('Failed to confirm the token.')
      setShowToast(true)
      return
    }

    setIsProcessingTask(true)
    auth.verifyAsyncTaskToken(
      taskIdFromRequestParams,
      confirmTokenFromRequestParam,
      undefined,
      undefined,
      apolloClient,
    ).then((task) => {
      setAsyncTask(task)
      if (
        !task ||
        task.taskStatus !== AsyncTaskStatus.FINISHED ||
        task.result !== AsyncTaskResult.OK ||
        !task.userId ||
        !task.metadata ||
        !task.metadata.authToken
      ) {
        console.error('ConfirmAsyncTaskTokenPage: task failed.', { task })
        setToastMessage('Failed to confirm the token.')
        setShowToast(true)
        setIsProcessingTask(false)
        return
      }
      if (task.taskType === AsyncTaskType.TOKEN_SIGN_IN) {
        processConfirmedSignInWithTokenTask(task)
        // return
      }

      // if (taskTypeFromRequestParams === AsyncTaskType.RESET_PASSWORD) {
      //   return
      // }
    }, (error) => {
      console.error(error)
      console.error('ConfirmAsyncTaskTokenPage: error received.', error)
      setToastMessage('Failed to confirm the token.')
      setShowToast(true)
      setIsProcessingTask(false)
    })
  }

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  useIonViewWillEnter(() => {
    // console.log('ConfirmAsyncTaskTokenPage.useIonViewWillEnter: called.', {
    //   taskIdFromRequestParams,
    //   taskTypeFromRequestParams,
    //   confirmTokenFromRequestParam,
    // })
    // isActivePage = true

    if (
      taskTypeFromRequestParams !== AsyncTaskType.TOKEN_SIGN_IN &&
      taskTypeFromRequestParams !== AsyncTaskType.RESET_PASSWORD
    ) {
      console.error('ConfirmAsyncTaskTokenPage.useIonViewWillEnter: unknown task type', taskTypeFromRequestParams)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_PROCESSING_REQUEST)
      return
    }

    confirmToken()
  })

  useIonViewDidLeave(() => {
    // isActivePage = false
    pageMessages && pageMessages.clear()
    setToastMessage('')
    // setShowResetPasswordForm(false);
    // setActiveTopNavTabId(TopNavTabId.EDIT_USER_ACCOUNT_PROFILE);
  })

  // ===================================================================================================================
  // Event Handlers:
  const onCancelResetPasswordForm = (): void => {
    // todo
    console.error('Not implemented')
  }

  const onNext = (): void => {
    navigate(AppRoute.HOME)
  }

  // ===================================================================================================================
  // Rendering:
  let content
  if (isProcessing) {
    content = (
      <div className='g-with-safe-padding withCenteredColumnContent'>
        processing...
      </div>
    )
  } else if (!asyncTask) {
    content = (
      <div className='g-with-safe-padding withCenteredColumnContent' />
    )
  } else {
    if (asyncTask.taskType === AsyncTaskType.RESET_PASSWORD) {
      content = (
        <div className='g-with-safe-padding'>
          <ResetPasswordForm
            taskIdFromRequestParams={taskIdFromRequestParams}
            confirmTokenFromRequestParams={confirmTokenFromRequestParam}
            onCancel={onCancelResetPasswordForm}
            onNext={onNext}
          />
        </div>
      )
    // } else if (asyncTask.taskType === AsyncTaskType.TOKEN_SIGN_IN) {
    }
  }

  return (
    <IonPage className='app-page-public confirm-async-task-page'>
      <NavBar
        title='Confirm Token'
        isProcessing={isProcessing}
      />
      <IonContent>
        <PageMessages />
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default ConfirmAsyncTaskTokenPage
