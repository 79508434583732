import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { Order, OrderListItem } from '../../../lib/core/definitions'
import type {
  OrderQueryData,
  OrdersQueryData,
  OrdersQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  order: Order,
  queryListVariables: OrdersQueryVariables,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateOrder.updateInList called.', { order })
  if (!order) {
    logger.error('api.cache.updateOrder.updateInList: no order given.')
    return
  }

  try {
    const cachedOrderUsersData = apolloClient.readQuery<OrdersQueryData, OrdersQueryVariables>({
      query: apollo.queries.orders,
      variables: queryListVariables,
    })

    if (
      !cachedOrderUsersData ||
      !Array.isArray(cachedOrderUsersData.orders) ||
      cachedOrderUsersData.orders.length < 1
    ) {
      const listItem = coreHelpers.models.order.updateOrderListItemFromOrder(undefined, order)
      apolloClient.writeQuery<OrdersQueryData, OrdersQueryVariables>({
        query: apollo.queries.orders,
        variables: queryListVariables,
        data: { orders: [listItem] },
      })
      return
    }

    const updatedOrderUsers = coreHelpers.models.updateModelInList<OrderListItem, Order>(
      cachedOrderUsersData.orders,
      order,
      undefined,
      true,
      coreHelpers.models.order.updateOrderListItemFromOrder,
    )

    apolloClient.writeQuery<OrdersQueryData, OrdersQueryVariables>({
      query: apollo.queries.orders,
      variables: queryListVariables,
      data: { orders: updatedOrderUsers },
    })
  } catch (error) {
    logger.error('api.cache.updateOrder.updateInList: error.', { error })
  }
}

const updateOrder = (
  order: Order,
  queryListVariables: OrdersQueryVariables | undefined,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateOrder called.', { order })
  if (!apolloClient) {
    logger.error('api.cache.updateOrder: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!order || !order.id) {
    logger.error('api.cache.updateOrder: no order given.', { order })
    return
  }

  try {
    apolloClient.writeQuery<OrderQueryData>({
      query: apollo.queries.order,
      variables: { orderId: order.id },
      data: { order },
    })

    if (queryListVariables) {
      updateInList(
        order,
        queryListVariables,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateOrder: error.', { order, error })
  }
}

export default updateOrder
