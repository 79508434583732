import React, { useState } from 'react'
import { IonButton, IonInput } from '@ionic/react'

import './styles.css'
import { useGlobalCache } from '../../../../contexts/GlobalCacheContext/GlobalCacheContext'
import { useMimbleData } from '../../../../contexts/MimbleDataContext/MimbleDataContext'
import type { UserInput } from '../../../../lib/core/definitions'
import { UserRole } from '../../../../lib/core/enums'
import FormItem from '../../../../components/FormItem/FormItem'
import SubmitButton from '../../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../../helpers/validationHelpers'

type Props = {
  onClose: () => void
  onOpenResetPasswordForm: () => void
  showUiMessage: (message: string) => void
};

const PasswordForm: React.FC<Props> = ({
  onClose,
  onOpenResetPasswordForm,
  showUiMessage,
}): JSX.Element => {
  // ===================================================================================================================
  // State:
  const { activeUser, isUpdatingActiveUser, updateActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const { getActiveUserHasRole } = useGlobalCache()
  const isAuthUserAdmin = getActiveUserHasRole(UserRole.ADMIN)

  const [currentPassword, setCurrentPassword] = useState<string | undefined>()
  const [newPassword, setNewPassword] = useState<string | undefined>()
  const [currentPasswordValidationError, setCurrentPasswordValidationError] = useState<string | undefined>()
  const [newPasswordValidationError, setNewPasswordValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const isDirty = (!!newPassword)
  const isValid = !currentPasswordValidationError && !newPasswordValidationError

  const resetForm = (): void => {
    setCurrentPassword(undefined)
    setNewPassword(undefined)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeCurrentPassword = (event: any): void => {
    setCurrentPassword(event.detail.value)
    setCurrentPasswordValidationError(validationHelpers.validatePassword(event.detail.value))
  }

  const onBlurCurrentPassword = (event: any): void => {
    if (!currentPassword) {
      setCurrentPasswordValidationError('required')
    }
  }

  const onChangeNewPassword = (event: any): void => {
    setNewPassword(event.detail.value)
    setNewPasswordValidationError(validationHelpers.validatePassword(event.detail.value))
  }

  const onBlurNewPassword = (event: any): void => {
    if (!newPassword) {
      setNewPasswordValidationError('required')
    }
  }

  const onSave = (event: any): void => {
    event.preventDefault()
    if (
      (!isAuthUserAdmin && !currentPassword) ||
      !newPassword
    ) {
      return
    }
    const userInput: UserInput = {
      id: activeUserId,
      newPassword,
    }
    if (!isAuthUserAdmin) {
      userInput.currentPassword = currentPassword
    }

    updateActiveUser(userInput).then((updatedUser) => {
      // console.log('TfaFieldForm: updateActiveUser succeeded.', { updatedUser })
      showUiMessage('Successfully updated your password.')
      onClose()
      resetForm()
    }, (error) => {
      console.error(error)
      showUiMessage('Failed to update your password. Please try again.')
    })
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='user-account-password-form'>
      <form onSubmit={onSave}>
        <FormItem label='Old Password' validationError={currentPasswordValidationError} withBottomMargin>
          <IonInput
            autofocus
            type='password'
            onIonChange={onChangeCurrentPassword}
            onIonBlur={onBlurCurrentPassword}
            placeholder='old password'
            value={currentPassword || ''}
            minlength={8}
            maxlength={30}
            required
          />
        </FormItem>
        <FormItem label='New Password' validationError={newPasswordValidationError} withBottomMargin>
          <IonInput
            type='password'
            onIonChange={onChangeNewPassword}
            onIonBlur={onBlurNewPassword}
            placeholder='Enter a new password'
            value={newPassword || ''}
            minlength={8}
            maxlength={30}
            required
          />
        </FormItem>
        <div className='linkText' onClick={onOpenResetPasswordForm}>Forgot Password?</div>
        <div className='formButtonWrapper'>
          <IonButton
            size='small'
            color='light'
            className='withStandardRightMargin'
            onClick={onClose}
          >
            Cancel
          </IonButton>
          <SubmitButton
            size='small'
            onClick={onSave}
            disabled={!isDirty || !isValid}
            isProcessing={isUpdatingActiveUser}
          >
            Save
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

export default PasswordForm
