import type { ContactUserListItem, User, UserIdentInfo } from '../../../definitions'
import { RecordStatus, UserRole } from '../../../enums'

const filterList = (
  list: (User | ContactUserListItem | UserIdentInfo)[],
  searchText: string | null | undefined,
  showOrg?: boolean,
  showOnlyActive?: boolean,
): (User | ContactUserListItem | UserIdentInfo)[] => {
  // logger.debug('modelHelpers.user.filterList called.', { searchText, showOrg })
  if (!searchText && showOrg && showOnlyActive) {
    return list
  }
  return list.filter((user): boolean => {
    if (
      showOnlyActive &&
      (user as ContactUserListItem).contactRecordStatus &&
      (user as ContactUserListItem).contactRecordStatus !== RecordStatus.ACTIVE
    ) {
      return false
    }
    if (!showOrg && user.roles === UserRole.MIMBLE_ORG) {
      return false
    }
    if (!searchText) {
      return true
    }
    try {
      const reg = new RegExp(searchText, 'i')
      const nickname = (user as ContactUserListItem | UserIdentInfo).nickname
      if (
        (user.fullName && user.fullName.match(reg)) ||
        (user.username && user.username.match(reg)) ||
        (user.email && user.email.match(reg)) ||
        (user.phoneNumber && user.phoneNumber.match(reg)) ||
        (nickname && nickname.match(reg))
      ) {
        return true
      }
    } catch (error) {
      return false
    }
    return false
  })
}

export default filterList
