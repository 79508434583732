import { IonIcon, IonInput } from '@ionic/react'
import React, { useRef, useState } from 'react'
import { search } from 'ionicons/icons'

import './styles.css'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import type { UserListFilter } from '../../../lib/core/definitions'

type Props = {
  isProcessing?: boolean;
  onApply: (filter: UserListFilter) => void;
};

const UserListHeader: React.FC<Props> = (props): JSX.Element => {
  const { isProcessing, onApply } = props

  // ===================================================================================================================
  // State:
  const [searchText, setSearchText] = useState<string | undefined>()
  const searchTextRef = useRef<HTMLIonInputElement>(null)

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSearchText = (event: any): void => {
    setSearchText(event.detail.value || undefined)
  }

  const onFormSubmit = (event: any): void => {
    event.preventDefault()
    if (searchText) {
      onApply({ searchText })
    }
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <form className='userHeader' onSubmit={onFormSubmit}>
      <div className='searchBarWrapper'>
        <IonIcon icon={search} class='searchBarSearchIcon' />
        <IonInput
          ref={searchTextRef}
          value={searchText}
          placeholder='search'
          onIonChange={onChangeSearchText}
          className='searchBar'
          clearInput
        />
      </div>
      <SubmitButton
        size='small'
        className='withStandardLeftMargin'
        onClick={onFormSubmit}
        disabled={isProcessing}
      >
        Go
      </SubmitButton>
    </form>
  )
}

export default UserListHeader
