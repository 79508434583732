import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type {
  ContactQueryData,
  ContactQueryVariables,
  // ContactsQueryData,
  // ContactsQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import logger from '../../logger'

/* const deleteWishInContactsList = (
  wishId: string | undefined,
  contactId: string | undefined,
  apolloClient: ApolloClient<any>,
): void => {
   console.log('api.cache.deleteContactWish called.')

  const localUserId = globalCache.getActiveUserId()
  if (!localUserId) {
    console.log('api.cache.deleteContactWish: no localUserId found.')
    return
  }

  if (!apolloClient) {
    logger.error('api.cache.deleteContactWish: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  const contactsVariables = {
    filter: {
      fromUserId: globalCache.getActiveUserId() as string,
      orderBy: 'fullName',
    },
  }
  const cachedContactsData = apolloClient.readQuery<ContactsQueryData, ContactsQueryVariables>({
    query: apollo.queries.contacts,
    variables: contactsVariables,
  })
   console.log('api.cache.deleteContactWish: loaded cached contacts data.', { cachedContactsData })

  if (cachedContactsData && cachedContactsData.contacts) {
    const updatedContacts = cachedContactsData.contacts.map((contact) => {
      if (contact.id !== contactId) {
        return contact
      }
      const updatedContact = { ...contact }
      if (Array.isArray(contact.wishes) && contact.wishes.length > 0) {
        updatedContact.wishes = contact.wishes.filter(w => w.id !== wishId)
      }
      return updatedContact
    })
    // console.log('api.cache.deleteContactWish: updated contacts=', { updatedContacts })

    apolloClient.writeQuery<WishesQueryData>({
      query: apollo.queries.contacts,
      variables: contactsVariables,
      data: { contacts: updatedContacts },
    })
  }
} */

const deleteWishInContact = (
  wishId: string | undefined,
  contactId: string | undefined,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.deleteWishInContact called.')

  if (!apolloClient) {
    logger.error('api.cache.deleteWishInContact: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  const contactVariables = { contactId }
  const cachedContactData = apolloClient.readQuery<ContactQueryData, ContactQueryVariables>({
    query: apollo.queries.contact,
    variables: contactVariables,
  })
  // console.log('api.cache.deleteWishInContact: loaded cached contacts data.', { cachedContactData })

  if (cachedContactData && cachedContactData.contact) {
    const updatedContact = { ...cachedContactData.contact }
    if (Array.isArray(cachedContactData.contact.wishes) && cachedContactData.contact.wishes.length > 0) {
      updatedContact.wishes = cachedContactData.contact.wishes.filter(w => w.id !== wishId)
    }
    // console.log('api.cache.deleteWishInContact: updated contact=', { updatedContact })

    apolloClient.writeQuery<ContactQueryData>({
      query: apollo.queries.contact,
      variables: contactVariables,
      data: { contact: updatedContact },
    })
  }
}

const deleteContactWish = (
  wishId: string | undefined,
  contactId: string | undefined,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.deleteContactWish called.')
  deleteWishInContact(wishId, contactId, apolloClient)
}

export default deleteContactWish
