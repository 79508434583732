import { FiatCurrency, FundType, OrgProductType, TokenName } from '../../../enums'
import type {
  Currency,
  MoneyAmount,
  ProductOption,
  ShoppingCartItem,
  ShoppingCartItemAmountsInfo,
} from '../../../definitions'
import compareId from '../compareId'
import cryptoCurrency from '../../cryptoCurrency'
import getAmountPrecision from '../../ui/getAmountPrecision'
import getDisplayMoneyAmount from '../../ui/getDisplayMoneyAmount'
import getInternalMoneyAmount from '../../ui/getInternalMoneyAmount'
import logger from '../../../logger'

const getPaymentMoneyAmount = (
  item: ShoppingCartItem,
  fundType: FundType | null | undefined,
  currency: Currency | null | undefined,
): MoneyAmount => {
  const precision = getAmountPrecision(fundType, currency)
  return {
    amount: Number(item.paymentAmount) || 0,
    fundType: fundType || FundType.FIAT,
    currency: currency || FiatCurrency.USD,
    precision,
  }
}

const getDefaultResult = (
  item: ShoppingCartItem,
  paymentFundType: FundType | null | undefined,
  paymentCurrency: Currency | null | undefined,
): ShoppingCartItemAmountsInfo => {
  const productFundType = item.productType === OrgProductType.MIMBLE_TOKEN ? FundType.TOKEN : FundType.FIAT
  const productCurrency = item.productType === OrgProductType.MIMBLE_TOKEN ? TokenName.MIMBLE_TOKEN : FiatCurrency.USD
  return {
    productAmount: {
      amount: 0,
      fundType: productFundType,
      currency: productCurrency,
      precision: getAmountPrecision(productFundType, productCurrency),
    },
    paymentAmount: getPaymentMoneyAmount(
      item,
      paymentFundType,
      paymentCurrency,
    ),
    rewardAmount: {
      amount: 0,
      fundType: FundType.TOKEN,
      currency: TokenName.MIMBLE_TOKEN,
      precision: 0,
    },
  }
}

const forMarketplaceProduct = (
  item: ShoppingCartItem,
  paymentFundType: FundType | null | undefined,
  paymentCurrency: Currency | null | undefined,
  exchangeRate: number | null | undefined,
): ShoppingCartItemAmountsInfo => {
  // logger.trace('lib.core.helpers.models.shoppingCartItem.getAmountsInfo.forMarketplaceProduct called.',
  //   { item, paymentFundType, paymentCurrency, exchangeRate })
  const result = getDefaultResult(
    item,
    paymentFundType,
    paymentCurrency,
  )
  let productOption = (item && item.productOption) as ProductOption | null | undefined

  // Did the user already select a product option?
  if (
    !productOption &&
    (!item || !item.productOptionId)
  ) {
    // No product option selected yet - returning default result (amounts = zero)
    return result
  }

  if (!productOption) {
    const productOptionId = item && item.productOptionId

    const product = item && item.product
    productOption = (
      productOptionId &&
      product &&
      Array.isArray(product.productOptions) &&
      product.productOptions.find(po => compareId(po.id, productOptionId))
    ) as ProductOption | null | undefined
  }

  if (!productOption) {
    logger.warn('lib.core.helpers.shoppingCartItem.getAmountsInfo.forMarketplaceProduct: productOption not found.',
      { item })
    return result
  }

  result.productAmount.amount = Number(productOption.amount) || 0
  result.productAmount.fundType = productOption.fundType as FundType
  result.productAmount.currency = productOption.currency as Currency

  if (productOption.reward) {
    result.rewardAmount.amount = productOption.reward || 0
  }

  if (paymentFundType === FundType.TOKEN && paymentCurrency === TokenName.MIMBLE_TOKEN) {
    result.paymentAmount.amount = cryptoCurrency.convertFiatToMimbleToken(
      (item.productCount || 1) * (Number(productOption.amount) || 0),
    )
  } else if (paymentFundType === FundType.FIAT) {
    result.paymentAmount.amount = (item.productCount || 1) * (Number(productOption.amount) || 0)
  } else if (paymentFundType === FundType.CRYPTO) {
    const externalFiatAmount = getDisplayMoneyAmount(
      (item.productCount || 1) * (Number(productOption.amount) || 0),
      FundType.FIAT,
      FiatCurrency.USD,
    )
    if (exchangeRate && exchangeRate > 0) {
      result.paymentAmount.amount = getInternalMoneyAmount(
        (exchangeRate || 1) * externalFiatAmount,
        paymentFundType,
        paymentCurrency,
      )
    } else {
      result.paymentAmount.amount = 0
    }
  }

  return result
}

const forMimbleTokenPurchase = (
  item: ShoppingCartItem,
  paymentFundType: FundType | null | undefined,
  paymentCurrency: Currency | null | undefined,
  exchangeRate: number | null | undefined,
): ShoppingCartItemAmountsInfo => {
  // logger.trace('lib.core.helpers.shoppingCartItem.getAmountsInfo.forMimbleTokenPurchase called.',
  //   { item, paymentFundType, paymentCurrency, exchangeRate })
  const result = getDefaultResult(
    item,
    paymentFundType,
    paymentCurrency,
  )

  const fiatAmount = cryptoCurrency.convertMimbleTokenToFiat(item.productCount || 0)

  result.productAmount.amount = fiatAmount
  result.productAmount.fundType = FundType.FIAT
  result.productAmount.currency = FiatCurrency.USD

  if (paymentFundType === FundType.TOKEN) {
    result.paymentAmount.amount = item.productCount || 0
  } else if (paymentFundType === FundType.FIAT) {
    result.paymentAmount.amount = fiatAmount
  } else if (paymentFundType === FundType.CRYPTO) {
    if (exchangeRate && exchangeRate > 0) {
      const externalFiatAmount = getDisplayMoneyAmount(fiatAmount, FundType.FIAT, FiatCurrency.USD)
      result.paymentAmount.amount = getInternalMoneyAmount(
        (exchangeRate || 1) * externalFiatAmount,
        paymentFundType,
        paymentCurrency,
      )
    } else {
      result.paymentAmount.amount = 0
    }
  }

  return result
}

const getAmountsInfo = (
  item: ShoppingCartItem,
  paymentFundType: FundType | null | undefined,
  paymentCurrency: Currency | null | undefined,
  exchangeRate: number | null | undefined,
): ShoppingCartItemAmountsInfo => {
  // logger.trace('lib.core.helpers.shoppingCartItem.getAmountsInfo called.',
  //   { item, paymentFundType, paymentCurrency, exchangeRate })
  if (item.productType === OrgProductType.GIFT_CARD) {
    return forMarketplaceProduct(
      item,
      paymentFundType,
      paymentCurrency,
      exchangeRate,
    )
  }

  if (item.productType === OrgProductType.MIMBLE_TOKEN) {
    return forMimbleTokenPurchase(
      item,
      paymentFundType,
      paymentCurrency,
      exchangeRate,
    )
  }

  return getDefaultResult(
    item,
    paymentFundType,
    paymentCurrency,
  )
}

export default getAmountsInfo
