import type { Invitation } from '../../../definitions'
// import { RecordStatus } from '../../../enums'

const updateInvitationListItemFromInvitation = (
  invitationListItem: Invitation | undefined,
  invitation: Invitation,
): Invitation => {
  // if (!invitationListItem) {
  //   return {
  //     id: invitation.id || '',
  //     fromUserId: invitation.fromUserId || null,
  //     toUserId: invitation.toUserId || null,
  //     toUserEmail: invitation.toUserEmail || null,
  //     toUserPhoneNumber: invitation.toUserPhoneNumber || null,
  //     toUserFullName: invitation.toUserFullName || null,
  //     invitationType: invitation.invitationType || null,
  //     message: invitation.message || null,
  //     messageSentAt: invitation.messageSentAt,
  //     messageSendReport: invitation.messageSendReport || null,
  //     campaign: invitation.campaign || '',
  //     adminNotes: invitation.adminNotes || '',
  //     metadata: invitation.metadata || '',
  //     recordStatus: invitation.recordStatus || RecordStatus.ACTIVE,
  //     createdAt: invitation.createdAt || '',
  //     updatedAt: invitation.updatedAt || '',
  //   }
  // }
  return invitation
}

export default updateInvitationListItemFromInvitation
