import React from 'react'

import './styles.css'
import { IonText } from '@ionic/react'
import type { PurchaseTransfer, User } from '../../lib/core/definitions'
import { PurchaseTransferStatus } from '../../lib/core/enums'

type Props = {
  purchaseTransfer: PurchaseTransfer
  fromUserId?: string | null
  senderName?: string
  user?: User
}

const GiftNotAvailableContent: React.FC<Props> = ({
  purchaseTransfer,
  fromUserId,
  senderName,
  user,
}): JSX.Element => {
  if (purchaseTransfer.transferStatus === PurchaseTransferStatus.ACCEPTED) {
    return (
      <IonText>
        This gift card has already been accepted.
      </IonText>
    )
  }
  return (
    <IonText>
      This gift is not available, or not ready.
    </IonText>
  )
}

export default GiftNotAvailableContent
