import { isPlatform } from '@ionic/react'
import { FCM } from '@capacitor-community/fcm'

import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

const getPushNotificationToken = (): Promise<string | undefined> => new Promise((resolve) => {
  if (!isPlatform('capacitor')) {
    // Not in a native app
    resolve(undefined)
    return
  }

  FCM.getToken()
    .then((r) => {
      const { token } = r
      globalCacheData.setValue(GlobalCacheDataKey.FCM_TOKEN, token)
      resolve(token)
    })
    .catch((err) => {
      console.log(err)
      resolve(globalCacheData.getValue(GlobalCacheDataKey.FCM_TOKEN))
    })
})

export default getPushNotificationToken
