import type { ApolloClient } from '@apollo/client'
import type { Chat as IChat, ChatMessage } from '../../lib/core/definitions'
import type { ChatQueryData, ChatQueryVariables } from '../../services/apollo/definitions'
// import { ModelChangedMessageType } from '../../lib/core/enums'
import api from '../../services/api'
import apollo from '../../services/apollo'
import coreHelpers from '../../lib/core/helpers'
import logger from '../../services/logger'

const handleNewOrUpdatedChatMessage = (
  // messageType: ModelChangedMessageType,
  chatMessage: ChatMessage,
  chatQueryVariables: ChatQueryVariables,
  activeUserId: string,
  isSignedIn: boolean,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('pageHelpers.handleNewOrUpdatedChatMessage called.', { chatMessage })

  if (!apolloClient || !isSignedIn) {
    console.warn('pageHelpers.handleNewOrUpdatedChatMessage: no apollo client, or not signed in.')
    return
  }

  // Updating cached chat and message:
  let foundChatInCache = false
  try {
    const cachedChatData = apolloClient.readQuery<ChatQueryData, ChatQueryVariables>({
      query: apollo.queries.chat,
      variables: chatQueryVariables,
    })
    if (cachedChatData && cachedChatData.chat) {
      foundChatInCache = true
      const updatedChat: IChat = { ...cachedChatData.chat }
      if (Array.isArray(updatedChat.messages) && updatedChat.messages.length > 0) {
        updatedChat.messages = coreHelpers.models.updateModelInList<ChatMessage>(
          updatedChat.messages,
          chatMessage,
          undefined,
          true,
        )
        apolloClient.writeQuery<ChatQueryData>({
          query: apollo.queries.chat,
          variables: { chatId: chatMessage.chatId },
          data: { chat: updatedChat },
        })
      }
    }
  } catch (error) {
    if ((error as Error).message !== 'Can\'t find field \'chat\' on ROOT_QUERY object') {
      logger.warn('pageHelpers.handleNewOrUpdatedChatMessage: apollo.readQuery failed.', { error })
    }
  }

  if (foundChatInCache) {
    // Reloading chat:
    api.loadChat(
      chatMessage.chatId as string,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then(undefined, (error) => {
      logger.error(error)
    })
  } else {
    api.loadChats(
      apollo.getChatsQueryVariables(undefined, activeUserId as string),
      apolloClient,
      undefined,
      { skipCache: true },
    ).then(undefined, (error) => {
      console.error(error)
    })
  }
}

export default handleNewOrUpdatedChatMessage
