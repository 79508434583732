import canBuyMimbleTokens from './canBuyMimbleTokens'
import canPurchase from './canPurchase'
import exportData from './exportData'
import filterList from './filterList'
import getAppFeaturesAsArray from './getAppFeaturesAsArray'
import getAppFeaturesAsString from './getAppFeaturesAsString'
import getOnboardingProgress from './getOnboardingProgress'
import getRolesAsArray from './getRolesAsArray'
import hasAppFeature from './hasAppFeature'
import hasRole from './hasRole'
import isSearchTextMatchForUsernameOrFullName from './isSearchTextMatchForUsernameOrFullName'
import isTfaMethodAvailable from './isTfaMethodAvailable'
import isTrusted from './isTrusted'
import isUserWithLiveBanking from './isUserWithLiveBanking'
import parseName from './parseName'
import updateUserListItemFromUser from './updateUserListItemFromUser'

const userHelpers = {
  canBuyMimbleTokens,
  canPurchase,
  exportData,
  filterList,
  getAppFeaturesAsArray,
  getAppFeaturesAsString,
  getOnboardingProgress,
  getRolesAsArray,
  hasAppFeature,
  hasRole,
  isSearchTextMatchForUsernameOrFullName,
  isTfaMethodAvailable,
  isTrusted,
  isUserWithLiveBanking,
  parseName,
  updateUserListItemFromUser,
}

export default userHelpers
