import type { MarketplaceListFilter } from '../../../definitions'
import compareId from '../compareId'

const compareMarketplaceListFilters = (
  filter1?: MarketplaceListFilter,
  filter2?: MarketplaceListFilter,
  ignoreTextFilter = false,
): boolean => {
  if (!filter1 && !filter2) {
    return true
  }
  if ((filter1 && !filter2) || (!filter1 && filter2)) {
    return false
  }
  // todo compare: tagIds, productTypes, vendorRecordStates
  return (
    !!filter1 &&
    !!filter2 &&
    filter1.view === filter2.view &&
    filter1.categoryTagId === filter2.categoryTagId &&
    (filter1.searchText === filter2.searchText || ignoreTextFilter) &&
    compareId(filter1.merchantId, filter2.merchantId) &&
    filter1.offset === filter2.offset &&
    filter1.limit === filter2.limit &&

    // todo: deprecated
    compareId(filter1.vendorId, filter2.vendorId)
  )
}

export default compareMarketplaceListFilters
