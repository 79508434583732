import type { ProductMetadata } from '../../../definitions'

export interface GetMetadataInventoryResult {
  inventory5: '-' | number;
  inventory10: '-' | number;
  inventory25: '-' | number;
  inventory30: '-' | number;
  inventory50: '-' | number;
  inventory100: '-' | number;
  inventory250: '-' | number;
}

const getInventory = (metadata: ProductMetadata, amount: number): '-' | number => {
  if (!metadata.productOptions) {
    return '-'
  }
  const info = metadata.productOptions.find(i => i.amount === amount)
  return info ? info.inventory : '-'
}

const getMetadataInventory = (metadata: ProductMetadata | null | undefined): GetMetadataInventoryResult => {
  if (
    !metadata ||
    !Array.isArray(metadata.productOptions) ||
    metadata.productOptions.length < 1
  ) {
    return {
      inventory5: '-',
      inventory10: '-',
      inventory25: '-',
      inventory30: '-',
      inventory50: '-',
      inventory100: '-',
      inventory250: '-',
    }
  }

  return {
    inventory5: getInventory(metadata, 5000),
    inventory10: getInventory(metadata, 10000),
    inventory25: getInventory(metadata, 25000),
    inventory30: getInventory(metadata, 30000),
    inventory50: getInventory(metadata, 50000),
    inventory100: getInventory(metadata, 100000),
    inventory250: getInventory(metadata, 250000),
  }
}

export default getMetadataInventory
