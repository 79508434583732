import { Location } from 'history'
import { DataImportPageMode } from './enums'

const helpers = {
  getPageModeFromLocation: (location: Location): DataImportPageMode | undefined => {
    if (!location || !location.pathname) {
      return
    }
    if (location.pathname.match(/^\/admin\/data-import\//)) {
      return DataImportPageMode.DATA_IMPORT
    } else if (location.pathname.match(/^\/admin\/import-stored-products[/]?/)) {
      return DataImportPageMode.NEW_STORED_PRODUCT_IMPORT
    }
  },

  getPageTitle: (pageMode?: DataImportPageMode): string => {
    if (pageMode === DataImportPageMode.NEW_STORED_PRODUCT_IMPORT) {
      return 'Import Inventory'
    }
    if (pageMode === DataImportPageMode.DATA_IMPORT) {
      return 'Data Import Report'
    }
    return 'Data Import'
  },
}

export default helpers
