import { gql } from '@apollo/client'

export default gql`
  query Q($favoriteOrganizationId: String!) {
    favoriteOrganization(favoriteOrganizationId: $favoriteOrganizationId) {
      id
      userId
      organizationId
      # notes
      recordStatus
      createdAt
      updatedAt
    }
  }`
