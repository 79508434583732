import React, { useEffect, useState } from 'react'
import {
  IonButton,
  IonIcon,
  IonInput,
  IonTextarea,
} from '@ionic/react'
import { close } from 'ionicons/icons'

import type { Wish, WishInput } from '../../lib/core/definitions'
import FormItem from '../FormItem/FormItem'
import SubmitButton from '../SubmitButton/SubmitButton'
import validationHelpers from '../../helpers/validationHelpers'

type Props = {
  wish?: Wish | null;
  owner: 'active-user' | 'contact'
  isProcessing?: boolean;
  onCancel: () => void;
  onDelete?: (wishId: string) => void;
  onSubmit: (wish: WishInput) => void;
};

const WishForm: React.FC<Props> = ({
  wish = null,
  owner,
  isProcessing,
  onCancel,
  onDelete,
  onSubmit,
}): JSX.Element => {
  const [title, setTitle] = useState<string | undefined>()
  const [titleValidationError, setTitleValidationError] = useState<string | undefined>()
  const [notes, setNotes] = useState<string | undefined>()
  const [notesValidationError, setNotesValidationError] = useState<string | undefined>()
  const [productUrl, setProductUrl] = useState<string | undefined>()
  const [productUrlValidationError, setProductUrlValidationError] = useState<string | undefined>()
  const [merchantId, setMerchantId] = useState<string | undefined>()
  const [merchantIdValidationError, setMerchantIdValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const titleChanged = title !== undefined && !(wish && title === wish.title)
  const notesChanged = notes !== undefined && !(wish && notes === wish.notes)
  const productUrlChanged = productUrl !== undefined && !(wish && productUrl === wish.productUrl)
  const merchantIdChanged = merchantId !== undefined && !(wish && merchantId === wish.merchantId)
  const formLabel = owner === 'active-user' ? 'My Wish' : 'Thought'

  const isDirty = (
    titleChanged ||
    notesChanged ||
    productUrlChanged ||
    merchantIdChanged
  )

  const isValid = (
    (title || (wish && wish.title)) &&
    !titleValidationError &&
    !notesValidationError &&
    !productUrlValidationError &&
    !merchantIdValidationError
  )

  const resetForm = (): void => {
    setTitle(undefined)
    setTitleValidationError(undefined)
    setNotes(undefined)
    setNotesValidationError(undefined)
    setProductUrl(undefined)
    setProductUrlValidationError(undefined)
    setMerchantId(undefined)
    setMerchantIdValidationError(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('WishForm: new wish received - resetting form.')
    resetForm()
  }, [wish])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeTitle = (event: any): void => {
    if (
      (wish && (event.detail.value === wish.title || (!event.detail.value && !wish.title))) ||
      (!wish && !event.detail.value)
    ) {
      setTitleValidationError(undefined)
      setTitle(undefined)
      return
    }
    setTitle(event.detail.value)
    setTitleValidationError(validationHelpers.validateWishTitle(event.detail.value))
  }

  const onChangeNotes = (event: any): void => {
    if (
      (wish && (event.detail.value === wish.notes || (!event.detail.value && !wish.notes))) ||
      (!wish && !event.detail.value)
    ) {
      setNotesValidationError(undefined)
      setNotes(undefined)
      return
    }
    setNotes(event.detail.value)
    if (event.detail.value) {
      setNotesValidationError(validationHelpers.validateWishNotes(event.detail.value))
    } else {
      setNotesValidationError(undefined)
    }
  }

  const onChangeProductUrl = (event: any): void => {
    if (
      (wish && (event.detail.value === wish.productUrl || (!event.detail.value && !wish.productUrl))) ||
      (!wish && !event.detail.value)
    ) {
      setProductUrlValidationError(undefined)
      setProductUrl(undefined)
      return
    }
    setProductUrl(event.detail.value)
    if (event.detail.value) {
      setProductUrlValidationError(validationHelpers.validateUrl(event.detail.value))
    } else {
      setProductUrlValidationError(undefined)
    }
  }

  // const onChangeMerchantId = (event: any): void => {
  //   if (
  //     (wish && (event.detail.value === wish.merchantId || (!event.detail.value && !wish.merchantId))) ||
  //     (!wish && !event.detail.value)
  //   ) {
  //     setMerchantIdValidationError('')
  //     setMerchantId(undefined)
  //     return
  //   }
  //   setMerchantId(event.detail.value)
  //   if (event.detail.value) {
  //     setMerchantIdValidationError(validationHelpers.validateProductTerms(event.detail.value))
  //   } else {
  //     setMerchantIdValidationError('')
  //   }
  // }

  const onClickSaveButton = (event: any): void => {
    event.preventDefault()
    const wishInput: WishInput = {}
    if (wish && wish.id) {
      wishInput.id = wish.id
    }
    if (title !== undefined && (!wish || title !== wish.title)) {
      wishInput.title = title || null
    }
    if (notes !== undefined && (!wish || notes !== wish.notes)) {
      wishInput.notes = notes || null
    }
    if (productUrl !== undefined && (!wish || productUrl !== wish.productUrl)) {
      wishInput.productUrl = productUrl || null
      if (wishInput.productUrl && !wishInput.productUrl.startsWith('https://')) {
        wishInput.productUrl = `https://${wishInput.productUrl}`
      }
    }
    if (merchantId !== undefined && (!wish || merchantId !== wish.merchantId)) {
      wishInput.merchantId = merchantId || null
    }
    onSubmit(wishInput)
  }

  const handleDeleteButtonClick = () => {
    if (onDelete && wish && wish.id) {
      onDelete(wish.id)
    }
  }

  // ===================================================================================================================
  // Rendering:
  let deleteButton: JSX.Element | undefined
  if (onDelete) {
    deleteButton = (
      <IonButton
        fill='outline'
        color='danger'
        className='withStandardRightMargin'
        onClick={handleDeleteButtonClick}
      >
        Delete
      </IonButton>
    )
  }

  return (
    <form className='wish-form' onSubmit={onClickSaveButton}>
      <div className='close-button-row'>
        <IonButton
          size='small'
          fill='clear'
          color='medium'
          onClick={onCancel}
          className='close-button'
        >
          <IonIcon icon={close} />
        </IonButton>
      </div>
      <FormItem
        label={formLabel}
        validationError={titleValidationError}
        withBottomMargin
      >
        <IonInput
          autofocus
          type='text'
          inputmode='text'
          onIonChange={onChangeTitle}
          value={title !== undefined ? title : (wish ? wish.title : '')}
        />
      </FormItem>
      <FormItem
        label='Notes'
        validationError={notesValidationError}
        withBottomMargin
      >
        <IonTextarea
          rows={4}
          autocapitalize='sentences'
          onIonChange={onChangeNotes}
          value={notes !== undefined ? notes : (wish ? wish.notes : '')}
        />
      </FormItem>
      <FormItem
        label='Link'
        validationError={productUrlValidationError}
        withBottomMargin
      >
        <IonInput
          type='url'
          inputmode='url'
          onIonChange={onChangeProductUrl}
          // placeholder='Reference Url'
          value={productUrl !== undefined ? productUrl : (wish ? wish.productUrl : '')}
        />
      </FormItem>
      {/* <FormItem */}
      {/*   label='Merchant' */}
      {/*   validationError={merchantIdValidationError} */}
      {/*   withBottomMargin */}
      {/* > */}
      {/*   <IonInput */}
      {/*     onIonChange={onChangeMerchantId} */}
      {/*     placeholder='Terms of use' */}
      {/*     value={merchantId !== undefined ? merchantId : (wish ? wish.merchantId : '')} */}
      {/*   /> */}
      {/* </FormItem> */}
      <div className='button-wrapper'>
        {deleteButton}
        <SubmitButton
          onClick={onClickSaveButton}
          isProcessing={isProcessing}
          disabled={!isDirty || !isValid}
        >
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default WishForm
