import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import type { FilterRewardType, RewardConfig, RewardListItem } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'

type Props = {
  rewards: RewardListItem[] | undefined
  rewardConfigs: RewardConfig[] | undefined
  isLoadingRewardConfigs?: boolean
  onChange: (filterRewardType: FilterRewardType | 'all') => void
}

const FilterSelect: React.FC<Props> = (props): JSX.Element | null => {
  const {
    rewards,
    rewardConfigs,
    isLoadingRewardConfigs,
    onChange,
  } = props

  // ===================================================================================================================
  // State:
  const [filterRewardType, setFilterRewardType] = useState<FilterRewardType | 'all'>()

  // ===================================================================================================================
  // Event Handlers:
  const onChangeFilterRewardType = (event: any): void => {
    if (
      !event.target.value ||
      event.target.value === 'all' ||
      event.target.value === 'loading'
    ) {
      setFilterRewardType('all')
      return
    }
    const parts = event.target.value.split(':')

    const filterRewardType: FilterRewardType = {
      rewardType: parts[0],
      occurrence: Number(parts[1]),
      invitationLevel: Number(parts[2]),
      label: coreHelpers.type.rewardType.getLabel(
        parts[0],
        parts[1] ? Number(parts[1]) : undefined,
        parts[2] ? Number(parts[2]) : undefined,
        rewardConfigs,
      ),
    }
    setFilterRewardType(filterRewardType)
    onChange(filterRewardType)
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(rewardConfigs) || rewardConfigs.length < 1) {
    return null
  }

  let filterRewardTypeAsString = 'all'
  if (filterRewardType && filterRewardType !== 'all') {
    filterRewardTypeAsString = `${filterRewardType.rewardType}:${filterRewardType.occurrence || ''}:${filterRewardType.invitationLevel || ''}`
  }

  let filterOptions: JSX.Element[]
  if (isLoadingRewardConfigs) {
    filterOptions = [(
      <MenuItem key='loading' value='loading'>
        loading...
      </MenuItem>
    )]
  } else {
    filterOptions = coreHelpers.type.rewardType.getFilterRewardTypes(rewardConfigs).map(frt => {
      const val = `${frt.rewardType}:${frt.occurrence || ''}:${frt.invitationLevel || ''}`
      return (
        <MenuItem key={val} value={val}>
          {frt.label}
        </MenuItem>
      )
    })
  }

  return (
    <FormControl
      variant='outlined'
      fullWidth
      className='withStandardTopMargin withStandardBottomMargin'
    >
      <InputLabel id='select-time-label'>Reward Type</InputLabel>
      <Select
        labelId='select-time-label'
        id='select-time'
        value={filterRewardTypeAsString}
        onChange={onChangeFilterRewardType}
        label='Reward Type'
      >
        <MenuItem key='all' value='all'>All Rewards</MenuItem>
        {filterOptions}
      </Select>
    </FormControl>
  )
}

export default FilterSelect
