import { gql } from '@apollo/client'

export default gql`
  mutation M($ident: String!, $password: String!) {
    signIn (ident: $ident, password: $password) {
      id
      username
      email
      phoneNumber
      authToken
      roles
      prefs {
        contrastPennies
      }
    }
  }`
