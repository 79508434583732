import type { ApolloClient } from '@apollo/client'
import apollo from '../../apollo'
import type { ContactUsersQueryData, ContactUsersQueryVariables } from '../../apollo/definitions'
import logger from '../../logger'

const setMessagesInfoInContactUser = (
  userId: string,
  activeUserId: string,
  unreadMessagesCount: number,
  latestChatMessage: string,
  latestChatMessageCreatedAt: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.setMessagesInfoInContactUser called.', { userId })
  try {
    let contactId: string | undefined
    const contactsVariables = {
      // todo: rename back to filter, once we can delete the deprecated filter
      filter2: {
        fromUserId: activeUserId,
        orderBy: 'fullName',
      },
    }
    const cachedContactsData = apolloClient.readQuery<ContactUsersQueryData, ContactUsersQueryVariables>({
      query: apollo.queries.contactUsers,
      variables: contactsVariables,
    })
    // console.log('api.cache.setMessagesInfoInContactUser: cachedContactsData=', cachedContactsData)
    if (cachedContactsData && cachedContactsData.contactUsers) {
      const updatedContactUserList = cachedContactsData.contactUsers.map((c) => {
        if (c.userId === userId) {
          // console.log('api.cache.setMessagesInfoInContactUser: found item.', { item: c })
          const updatedContactUser = { ...c }
          updatedContactUser.contactId = c.id
          updatedContactUser.unreadChatMessageCount = unreadMessagesCount
          updatedContactUser.latestChatMessage = ''
          updatedContactUser.latestChatMessageFromUserId = ''
          updatedContactUser.latestChatMessageCreatedAt = ''
          return updatedContactUser
        }
        return c
      })
      apolloClient.writeQuery<ContactUsersQueryData>({
        query: apollo.queries.contactUsers,
        variables: contactsVariables,
        data: { contactUsers: updatedContactUserList },
      })
    }

    // if (contactId) {
    //   const cachedContactData = apolloClient.readQuery<ContactQueryData, ContactQueryVariables>({
    //     query: apollo.queries.contact,
    //     variables: { contactId },
    //   })
    //   if (cachedContactData && cachedContactData.contact) {
    //     const updatedContact: Contact = { ...cachedContactData.contact }
    //     updatedContact.unreadChatMessageCount = 0
    //     delete updatedContact.latestChatMessage
    //     delete updatedContact.latestChatMessageCreatedAt
    //     cache.writeQuery({
    //       query: apollo.queries.contact,
    //       variables: { contactId: contact.id as string },
    //       data: { contact: updatedContact },
    //     })
    //   }
    // }
  } catch (error) {
    logger.error('api.cache.setMessagesInfoInContactUser: error received.', { error })
  }
}

export default setMessagesInfoInContactUser
