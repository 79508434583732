import { parsePhoneNumber } from 'libphonenumber-js/mobile'
import validator from 'validator'
import type { StoredProductStatus, TransactionType, RewardType, PaymentType } from '../lib/core/enums'
import coreHelpers from '../lib/core/helpers'

const validationHelpers = {
  validateAdminNotes: (value: string): string => {
    if (value.length > 255) {
      return 'must not be longer than 255 characters'
    }
    return ''
  },
  validateCardAmount: (value: number): string => {
    if (value < 0) {
      return 'not a valid amount'
    }
    return ''
  },
  validateCreditCardNumber: (value: string): string => {
    if (!value || !value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return 'not a valid credit card number'
    }
    return ''
  },
  validateCreditCardExpiresAt: (value: string): string => {
    if (!value || !value.match(/^\d{2}\/?\d{2}$/)) {
      return 'not a valid expiration date'
    }
    return ''
  },
  validateCreditCardCvC: (value: string): string => {
    if (!value || !value.match(/^\d{3}$/)) {
      return 'not a security code'
    }
    return ''
  },
  validateInviteCode: (value: string | null | undefined): string => {
    // todo: validate the invite code which could also be a username
    // if (!value || value <= 0) {
    //   return 'not a valid amount'
    // }
    return ''
  },
  validateProductOptionAmount: (value: number): string => {
    if (!value || value <= 0) {
      return 'not a valid amount'
    }
    return ''
  },
  validateTokenPurchaseAmount: (value: number): string => {
    if (!value || value <= 0 || value > 5000000) {
      return 'not a valid amount'
    }
    return ''
  },
  validateStoredProductDiscount: (value: number): string => {
    if (value === undefined || value === null || Number.isNaN(value) || value < 0 || value >= 1) {
      return 'not a valid discount'
    }
    return ''
  },
  validateConfirmationToken: (value: string): string => {
    if (!value || !value.match(/^[0-9]{6}$/)) {
      return 'not a valid token'
    }
    return ''
  },
  validateCurrency: (value: string): string => {
    if (
      !value ||
      (
        !coreHelpers.type.fiatCurrencyHelpers.isValid(value) &&
      !coreHelpers.type.cryptoCurrencyHelpers.isValid(value)
      )
    ) {
      return 'not a valid currency'
    }
    return ''
  },
  validateEmail: (value: string | null | undefined): string => {
    if (!value) {
      return ''
    }
    if (!validator.isEmail(value)) {
      return 'not a valid email address'
    }
    return ''
  },
  validateEventReminderOffset: (
    value: number | null | undefined,
    precedingValue: number | null | undefined,
    index: number,
  ): string => {
    if (index > 0 && precedingValue === 0) {
      return 'no second reminder available'
    }
    if (value === null || value === undefined) {
      return ''
    }
    if (
      index > 0 &&
      (precedingValue === null || precedingValue === undefined)
    ) {
      return 'please enter the first reminder'
    }
    if (index > 0 && value === precedingValue) {
      return 'should be different than first reminder'
    }
    if (index > 0 && (precedingValue || precedingValue === 0) && value >= precedingValue) {
      return 'should be fewer days than first reminder'
    }
    if (value < 0) {
      return 'not a valid number of days'
    }
    if (value > 200) {
      return 'too many days'
    }
    return ''
  },
  validateFundType: (value: string): string => {
    if (!coreHelpers.type.fundType.isValid(value)) {
      return 'not a valid fund type'
    }
    return ''
  },
  validateImageSource: (value: string): string => {
    if (value.length < 3 || value.length > 50) {
      return 'must be between 3 and 50 characters'
    }
    return ''
  },
  validateObjectIdList: (value: string): string => {
    if (!value) {
      return ''
    }
    if (!value.includes(',')) {
      if (validationHelpers.validateUuid(value) !== '') {
        return `invalid id '${value}'`
      }
      return ''
    }
    const ids = value.split(',')
    if (!Array.isArray(ids) || ids.length < 1) {
      return 'no valid ids recognized'
    }
    for (let i = 0; i < ids.length; i++) {
      if (validationHelpers.validateUuid(ids[i]) !== '') {
        return `invalid id '${ids[i]}'`
      }
    }
    return ''
  },
  validateMfaCode: (value: string | undefined): string => {
    if (!value) {
      return ''
    }
    const cleaned = (value).replace(/\D/g, '')
    if (!cleaned.match(/^[0-9]{6}$/)) {
      return 'must be a 6 digit code'
    }
    return ''
  },
  validateStoredProductStatus: (value: StoredProductStatus): string => {
    if (!coreHelpers.type.storedProductStatus.isValid(value)) {
      return 'not a valid stored product status'
    }
    return ''
  },
  validatePassword: (value: string | null | undefined): string => {
    if (value && (value.length < 8 || value.length > 30)) {
      return 'must be between 8 and 30 characters'
    }
    return ''
  },
  validatePasswordConfirmation: (pass: string, conf: string): string => {
    return pass !== conf ? 'must be identical to password' : ''
  },
  validatePhoneNumber: (value: string): string => {
    if (!value) {
      return ''
    }
    try {
      const phoneNumberInfo = parsePhoneNumber(value, 'US')
      return phoneNumberInfo && phoneNumberInfo.isValid()
        ? ''
        : 'not a valid phone number'
    } catch (error) {
      console.warn('parsePhoneNumber threw error', { value, error })
      return 'not a valid phone number'
    }
  },
  validateProductInstructions: (value: string): string => {
    if (value.length < 10 || value.length > 5000) {
      return 'must be between 10 and 5000 characters'
    }
    return ''
  },
  validateProductTerms: (value: string): string => {
    if (value.length < 10 || value.length > 5000) {
      return 'must be between 10 and 5000 characters'
    }
    return ''
  },
  validateProductOptionLabel: (value: string): string => {
    if (value.length < 2 || value.length > 20) {
      return 'must be between 3 and 20 characters'
    }
    return ''
  },
  validateOrderIndex: (value: string): string => {
    const numVal = parseInt(value, 10)
    if (
      Number.isNaN(numVal) ||
      value === null ||
      numVal < 0 ||
      numVal > 999
    ) {
      return 'must be between 0 and 999'
    }
    return ''
  },
  validateProductOptionReward: (value: number): string => {
    return coreHelpers.number.isPositiveNumber(value, true)
      ? ''
      : 'not a valid transaction amount'
  },
  validatePurchaseBalance: (value: number | string): string => {
    if (value === null) {
      return 'not a valid balance'
    }
    const fval = parseFloat(value.toString())
    if (Number.isNaN(fval) || fval < 0 || fval > 1000000) {
      return 'not a valid balance'
    }
    return ''
  },
  validatePurchaseBalanceChange: (
    oldBalance: number | string,
    amountRemovedOrAdded: number | string,
    isAdding: boolean,
  ): string => {
    if (
      oldBalance === '' ||
      oldBalance === null ||
      oldBalance === undefined ||
      amountRemovedOrAdded === '' ||
      amountRemovedOrAdded === null ||
      amountRemovedOrAdded === undefined
    ) {
      return ''
    }

    const fOldBalance = parseFloat(oldBalance.toString())
    if (Number.isNaN(fOldBalance)) {
      return ''
    }

    const fval = parseFloat(amountRemovedOrAdded.toString())
    if (Number.isNaN(fval)) {
      return 'not a valid amount to remove or add'
    }

    const newBalance = isAdding ? fOldBalance + fval : fOldBalance - fval

    if (newBalance >= 0) {
      return ''
    }
    return isAdding
      ? 'not a valid amount to add'
      : 'not a valid amount to remove'
  },
  validatePurchaseCode: (value: string): string => {
    // if (!value || !value.match(/^[0-9]{10,12}$/)) {
    //   return 'not a valid phone number';
    // }
    return ''
  },
  validatePurchasePin: (value: string): string => {
    if (value && value.length > 20) {
      return 'too long (max is 20)'
    }
    return ''
  },
  validatePurchaseTransferMessageText: (value: string): string => {
    if (value.length < 10 || value.length > 1000) {
      return 'must be between 10 and 1000 characters'
    }
    return ''
  },
  validateRewardType: (value: RewardType | string): string => {
    if (!coreHelpers.type.rewardType.isValid(value)) {
      return 'not a valid reward type'
    }
    return ''
  },
  validateCity: (value: string | null | undefined): string => {
    if (!value) {
      return ''
    }
    if (value.length < 3 || value.length > 255) {
      return 'not a valid city'
    }
    return ''
  },
  validateRegionCode: (value: string | null | undefined, countryCode: string | null | undefined): string => {
    // todo
    if (!value) {
      return ''
    }
    if (countryCode && countryCode.toUpperCase() === 'US') {
      if (!value.match(/[a-zA-Z]{2}/)) {
        return 'not a valid state'
      }
      if (!coreHelpers.lists.usStates.find(s => s.code === value.toUpperCase())) {
        return 'not a valid state'
      }
    }
    return ''
  },
  validatePostalCode: (value: string | null | undefined): string => {
    // todo
    if (!value) {
      return ''
    }
    // see https://regexlib.com/Search.aspx?k=us+zip+code&c=-1&m=-1&ps=20&AspxAutoDetectCookieSupport=1
    if (!value.match(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)) {
      return 'not a valid postal code'
    }
    return ''
  },
  validateStreetAddress: (value: string | null | undefined): string => {
    if (!value) {
      return ''
    }
    if (value.length < 5 || value.length > 255) {
      return 'not a valid street address'
    }
    return ''
  },
  validateGeoLocation: (value: string): string => {
    if (value && !value.match(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/)) {
      return 'not a valid geo location (lat/lon)'
    }
    return ''
  },
  validateGeoLatitude: (value: string): string => {
    if (value && !value.match(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/)) {
      return 'not a valid geo latitude'
    }
    return ''
  },
  validateGeoLongitude: (value: string): string => {
    if (value && !value.match(/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/)) {
      return 'not a valid geo longitude'
    }
    return ''
  },
  validateTagCaption: (value: string): string => {
    if (value.length > 255) {
      return 'must be less than 255 characters'
    }
    return ''
  },
  validateTagName: (value: string): string => {
    if (value.length < 2 || value.length > 50) {
      return 'must be between 2 and 50 characters'
    }
    return ''
  },
  validateTagPriority: (value: string): string => {
    if (value && Number.isNaN(value)) {
      return 'must be a positive number'
    }
    const nVal = parseInt(value, 10)
    if (Number.isNaN(nVal) || nVal < 0 || nVal > 999999) {
      return 'must be a positive number'
    }
    return ''
  },
  validateTagSortIndex: (value: string): string => {
    if (value && Number.isNaN(value)) {
      return 'must be a positive number'
    }
    const nVal = parseInt(value, 10)
    if (Number.isNaN(nVal) || nVal < 0 || nVal > 999999) {
      return 'must be a positive number'
    }
    return ''
  },
  validateTransactionType: (value: TransactionType | string): string => {
    if (!coreHelpers.type.transactionType.isValid(value)) {
      return 'not a valid transaction type'
    }
    return ''
  },
  validateCountryCode: (value: string | null | undefined): string => {
    if (!value) {
      return ''
    }
    if (!coreHelpers.type.transactionType.isValid(value)) {
      return 'not a valid transaction type'
    }
    return ''
  },
  validateTransactionAmount: (value: number): string => {
    return (!Number.isNaN(value) && value !== null && !!value && value > 0)
      ? ''
      : 'not a valid transaction amount'
  },
  validateTransactionMessage: (value: string): string => {
    if (!value) {
      return ''
    }
    if (value.length > 255) {
      return 'too long (max=255 characters)'
    }
    return ''
  },
  validateTrustLevel: (value: number): string => {
    return (!Number.isNaN(value) && value !== null && value >= -99999 && value < 99999)
      ? ''
      : 'not a valid trust level'
  },
  validateUrl: (value: string): string => {
    if (!validator.isURL(value)) {
      return 'not a valid URL'
    }
    return ''
  },
  validateUserIdent: (value: string): string => {
    if (value.length < 3 || value.length > 40) {
      return 'must be between 3 and 30 characters'
    }
    return ''
  },
  validateUsername: (value: string): string => {
    if (value.length < 3 || value.length > 30) {
      return 'must be between 3 and 30 characters'
    }
    return ''
  },
  validateUserBirthday: (value: string | null | undefined): string => {
    if (!value) {
      return ''
    }
    // MM/DD
    // todo use regex
    if (value.length === 5) {
      return ''
    }
    // MM/DD/YYYY
    if (value.length === 10) {
      return ''
    }
    return 'not a valid date'
  },
  validateUserFullName: (value: string | null | undefined, requireFirstAndLastName?: boolean): string => {
    if (!value) {
      return ''
    }
    if (value.length < 3) {
      return 'too short'
    }
    if (value.length > 50) {
      return 'too long'
    }
    if (requireFirstAndLastName && value.indexOf(' ') < 0) {
      return 'not a complete name'
    }
    return value.length > 50 ? 'too long' : ''
  },
  validateUserRoles: (value: string | null | undefined): string => {
    if (!value) {
      return ''
    }
    if (value !== 'admin') {
      return 'not a valid role'
    }
    return ''
  },
  validateUserImageUrl: (value: string): string => {
    const t = /\.(jpeg|jpg|gif|png)$/
    if (!t.test(value)) {
      return 'Not a valid image URL'
    }
    return ''
  },
  validateUuid: (value: string): string => {
    if (!coreHelpers.string.isUuid(value)) {
      return 'not a valid record ID'
    }
    return ''
  },
  validateMerchantName: (value: string): string => {
    if (value.length < 3 || value.length > 50) {
      return 'must be between 3 and 50 characters'
    }
    return ''
  },
  validateMerchantDescription: (value: string): string => {
    if (value.length > 2000) {
      return 'must be between less than 2000 characters'
    }
    return ''
  },
  validatePaymentTypes: (value: string): string => {
    if (!value) {
      return ''
    }
    const types = value.split(',')
    for (let i = 0; i < types.length; i++) {
      if (!coreHelpers.type.paymentType.isValid(types[i] as PaymentType)) {
        return `invalid type '${types[i]}'`
      }
    }
    return ''
  },
  validateMerchantSource: (value: string): string => {
    if (value.length < 3 || value.length > 20) {
      return 'must be between 3 and 20 characters'
    }
    return ''
  },
  validateMerchantBalanceLookupUri: (value: string): string => {
    if (!validator.isURL(value)) {
      return 'not a valid URL'
    }
    if (value.length < 3 || value.length > 1024) {
      return 'must be between 3 and 1024 characters'
    }
    return ''
  },
  validateWishTitle: (value: string): string | undefined => {
    if (!value) {
      return 'required'
    }
    if (value.length > 255) {
      return 'too long'
    }
  },
  validateWishNotes: (value: string): string | undefined => {
    if (value.length > 1024) {
      return 'too long'
    }
  },
}

export default validationHelpers
