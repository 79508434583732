import { CdnAssetType } from '../../services/cdn/enums'
import type { ChatAttachmentPurchaseTransferInfo } from '../../lib/core/definitions'
import type { PurchaseCdnUris } from '../../definitions'
import cdn from '../../services/cdn'

const getPurchaseCdnUrisFromChatAttachment = (
  attachment?: ChatAttachmentPurchaseTransferInfo | null,
  useCloudfront = true,
): PurchaseCdnUris => {
  if (!attachment) {
    return {
      giftCardBackgroundUri: '',
      merchantLogoUri: '',
    }
  }
  return {
    giftCardBackgroundUri: cdn.getUri(
      CdnAssetType.GIFT_CARD,
      attachment.productImageSourceFrontSide,
      useCloudfront,
    ) || '',
    merchantLogoUri: cdn.getUri(
      CdnAssetType.MERCHANT_LOGO,
      attachment.merchantLargeLogoImageSource,
      useCloudfront,
    ) || '',
  }
}

export default getPurchaseCdnUrisFromChatAttachment
