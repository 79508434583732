import React, { useContext, useEffect, useState } from 'react'
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { person, ribbon } from 'ionicons/icons'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import type { RewardType } from '../../lib/core/enums'
import { UiMessage } from '../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import type { TransactionQueryData, TransactionQueryVariables } from '../../services/apollo/definitions'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import coreHelpers from '../../lib/core/helpers'
import type { InfoPaneEntryDef } from '../../components/InfoPane/InfoPane'
import InfoPane from '../../components/InfoPane/InfoPane'
import NavBar from '../../components/NavBar/NavBar'
import OrderObjectInfo from '../../components/objectInfo/OrderObjectInfo/OrderObjectInfo'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import TransactionObjectInfo from '../../components/objectInfo/TransactionObjectInfo/TransactionObjectInfo'
import UserInfoCard from '../../components/UserInfoCard/UserInfoCard'

const appPageId = AppPage.TransactionPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  transactionId: string
}

const TransactionPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { transactionId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const {
    activeUser,
    isLoadingActiveUser,
    rewardConfigs,
    getRewardConfigs,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  useEffect(() => {
    if (!rewardConfigs) {
      getRewardConfigs()
    }
  }, [rewardConfigs])

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading transaction:
  const {
    data: transactionLoadedData,
    loading: isLoadingTransaction,
    refetch: reloadTransaction,
  } = useQuery<TransactionQueryData, TransactionQueryVariables>(
    apollo.queries.transaction, {
      variables: { transactionId: transactionId as string },
      skip: !transactionId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: TransactionQueryData) => {
        const returnedTransaction = data ? data.transaction : undefined
        if (returnedTransaction === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const transaction = transactionLoadedData ? transactionLoadedData.transaction : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingTransaction

  // ===================================================================================================================
  // Event Handlers:
  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !transactionId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadTransaction({ transactionId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenReward = (): void => {
    if (transaction && transaction.reward) {
      navigate(`${AppRoute.REWARD}/${transaction.reward.id}`)
    }
  }

  const onOpenOrder = (orderId: string): void => {
    if (orderId) {
      navigate(`${AppRoute.ORDER}/${orderId}`)
    }
  }

  const onOpenContact = (): void => {
    if (!transaction) {
      return
    }
    const contactUserId = coreHelpers.models.compareId(transaction.fromUserId, activeUserId)
      ? transaction.toUserId
      : transaction.fromUserId
    if (contactUserId) {
      navigate(`${AppRoute.CONTACT}/-/${contactUserId}`)
    }
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const sections: JSX.Element[] = []
  if (transaction) {
    if (transaction.reward) {
      const entries: InfoPaneEntryDef[] = [
        {
          key: 'type',
          label: 'Type',
          value: coreHelpers.type.rewardType.getLabel(
            transaction.reward.rewardType as RewardType,
            transaction.reward.occurrence || 0,
            transaction.reward.invitationLevel || 0,
            rewardConfigs,
          ),
        },
        {
          key: 'id',
          label: 'ID',
          value: (transaction.reward.id as string).replace(/-/g, ''),
        },
      ]
      sections.push(
        <div key='reward'>
          <div className='withStandardBottomMargin section-header'>
            <IonIcon icon={ribbon} className='withStandardRightMargin' /> Reward
          </div>
          <div className='section-content'>
            <InfoPane
              entries={entries}
            />
            <IonButton
              size='small'
              onClick={onOpenReward}
            >
              Open
            </IonButton>
          </div>
        </div>,
      )
    }
    const contactUserInfo = coreHelpers.models.compareId(transaction.fromUserId, activeUserId)
      ? transaction.toUser
      : transaction.fromUser
    if (contactUserInfo) {
      sections.push(
        <div key='contact'>
          <div className='withStandardBottomMargin section-header'>
            <IonIcon icon={person} className='withStandardRightMargin' /> Contact
          </div>
          <UserInfoCard
            userIdentInfo={contactUserInfo}
            className='section-content withStandardTopMargin info-pane-value'
            showChat
            onOpenContact={onOpenContact}
          />
        </div>,
      )
    }
  }

  if (transaction && transaction.order) {
    sections.push(
      <OrderObjectInfo
        key='order'
        order={transaction.order}
        onOpenOrder={onOpenOrder}
      />,
    )
  }

  const content = (
    <div>
      <TransactionObjectInfo transaction={transaction} />
      {sections}
    </div>
  )

  return (
    <IonPage className='app-page-public transaction-page'>
      <NavBar
        title='Transaction'
        goBackUri={`${AppRoute.USER_ACCOUNT}/transactions`}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default TransactionPage
