import type { ImportStoredProductItem, Merchant, MerchantListItem } from '../../../../definitions'
import { InventoryImportFormat } from '../../../../enums'
import buyatab from './buyatab'
import cashstar from './cashstar'
import generic from './generic'
import incomm from './incomm'
import logger from '../../../../logger'

const parseImportStoredProductsLine = (
  line: string,
  lineIndex: number,
  productId: string,
  importIdPrefix: string | undefined,
  poNumber: string | undefined,
  source: string | undefined,
  format: InventoryImportFormat,
  merchants: Partial<Merchant | MerchantListItem>[] | undefined,
): ImportStoredProductItem => {
  logger.info('lib.core.helpers.models.asyncTask.parseImportStoredProductsLine called.',
    { line, lineIndex, format })
  if (format === InventoryImportFormat.BUY_A_TAB) {
    return buyatab(
      line,
      lineIndex,
      productId,
      importIdPrefix,
      poNumber,
      source,
      merchants,
    )
  }
  if (format === InventoryImportFormat.CASH_STAR) {
    return cashstar(
      line,
      lineIndex,
      productId,
      importIdPrefix,
      poNumber,
      source,
      merchants,
    )
  }
  if (format === InventoryImportFormat.INCOMM) {
    return incomm(
      line,
      lineIndex,
      productId,
      importIdPrefix,
      poNumber,
      source,
      merchants,
    )
  }
  return generic(
    line,
    lineIndex,
    productId,
    importIdPrefix,
    poNumber,
    source,
    merchants,
  )
}

export default parseImportStoredProductsLine
