import type { ApolloClient } from '@apollo/client'
import type { Chat, ChatMetadata, ChatMetadataUserInfo, Inbox } from '../../../lib/core/definitions'
import type { ChatQueryData } from '../../apollo/definitions'
import apollo from '../../apollo'
import deleteInboxItemInCache from '../cache/deleteInboxItem'
import markChatMessagesOfChatAsReceived from '../markChatMessagesOfChatAsReceived'

const helpers = {
  handleUnreadMessages: (
    chat: Chat,
    activeUserId: string,
    inbox: Inbox | undefined,
    apolloClient: ApolloClient<any>,
  ): void => {
    // console.log('services.api.handleUnreadMessages called.')
    let updatedChat = chat
    if (Array.isArray(chat.messages) && chat.messages.length > 0) {
      const originalMessages = chat.messages
      let foundUnreadMessages = false
      const updatedMessages = chat.messages.map((msg) => {
        if (msg.receivedAt) {
          return msg
        }
        foundUnreadMessages = true
        return { ...msg, receivedAt: new Date().getTime().toString() }
      })
      if (foundUnreadMessages) {
        // console.log(`api.loadChat.handleUnreadMessages: found ${updatedMessages.length} unread messages.`)
        updatedChat = { ...chat, messages: updatedMessages }
        if (updatedChat.metadata) {
          const newMetadata: ChatMetadata = { ...updatedChat.metadata }
          if (activeUserId === updatedChat.fromUserId) {
            newMetadata.fromUser = { ...newMetadata.fromUser, unreadMessageCount: 0 } as ChatMetadataUserInfo
          } else {
            newMetadata.toUser = { ...newMetadata.toUser, unreadMessageCount: 0 } as ChatMetadataUserInfo
          }
          updatedChat.metadata = newMetadata
        }
        // console.log('api.loadChat: calling markChatMessagesOfChatAsReceived.', { chatId: chat.id })
        markChatMessagesOfChatAsReceived(originalMessages, activeUserId, apolloClient)
        deleteInboxItemInCache(
          undefined,
          chat.id as string,
          activeUserId,
          apolloClient,
        )
      } else {
        if (
          inbox &&
          Array.isArray(inbox.items) &&
          inbox.items.length > 0
        ) {
          const inboxItemOfThisChat = inbox.items.find(i => i.modelId === chat.id)
          if (
            inboxItemOfThisChat &&
            inboxItemOfThisChat.metadata &&
            inboxItemOfThisChat.metadata.chat &&
            inboxItemOfThisChat.metadata.chat.unreadMessageCount
          ) {
            deleteInboxItemInCache(
              inboxItemOfThisChat.id as string,
              undefined,
              activeUserId,
              apolloClient,
            )
          }
        }
      }
    }
    apolloClient.writeQuery<ChatQueryData>({
      query: apollo.queries.chat,
      variables: { chatId: chat.id as string },
      data: { chat: updatedChat },
    })
  },
}

export default helpers
