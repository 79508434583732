import React, { useState } from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import { search, addOutline, searchOutline } from 'ionicons/icons'
import IconButton from '../IconButton/IconButton'

type Props = {
  searchText: string | undefined;
  showSearch?: boolean;
  className?: string;
  onApply: (searchText: string) => void;
  onAddItem?: () => void;
};

const ListHeader: React.FC<Props> = ({
  searchText,
  showSearch,
  className,
  onApply,
  onAddItem,
}): JSX.Element => {
  const [showSearchInput, setShowSearchInput] = useState<boolean | undefined>()

  const onChangeSearchText = (event: any): void => {
    onApply(event.detail.value)
  }

  const onToggleShowSearchInput = (): void => {
    setShowSearchInput(!showSearchInput)
  }

  const handleAddItem = (): void => {
    if (onAddItem) {
      onAddItem()
    }
    if (showSearchInput) {
      setShowSearchInput(false)
    }
  }

  let showSearchButton: JSX.Element | undefined
  let searchInput: JSX.Element | undefined
  const searchInputWrapperClass = (showSearch || showSearchInput) ? 'searchBarWrapper withSmallTopMargin' : ''
  const buttonSize = (showSearch || showSearchInput) ? 36 : 24

  if (showSearch || showSearchInput) {
    searchInput = (
      <>
        <IonIcon icon={search} class='searchBarSearchIcon' />
        <IonInput
          value={searchText}
          placeholder='search'
          onIonChange={onChangeSearchText}
          clearInput
        />
      </>
    )
  } else {
    showSearchButton = (
      <IconButton
        icon={searchOutline}
        text='Search'
        layout='vertical'
        className='button'
        iconSize={buttonSize}
        onClick={onToggleShowSearchInput}
      />
    )
  }

  let addButton: JSX.Element | undefined
  if (onAddItem) {
    addButton = (
      <IconButton
        icon={addOutline}
        text='Add'
        layout='vertical'
        className='button'
        iconSize={buttonSize}
        onClick={handleAddItem}
      />
    )
  }

  return (
    <div className={`withStandardBottomMargin ${className || ''}`}>
      <div className='row0'>
        <div className='rowWithCenterAlignedItems'>
          <div className={searchInputWrapperClass} style={{ flex: 1 }}>
            {searchInput}
          </div>
          {showSearchButton}
          {addButton}
        </div>
      </div>
    </div>
  )
}

export default ListHeader
