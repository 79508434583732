import React, { useState } from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import { cloudUploadOutline, arrowDown, arrowUp, cartOutline, search } from 'ionicons/icons'

import './styles.css'
import { PurchaseListScope } from '../../lib/core/enums'
import type { FormRadioItem } from '../../components/FormRadioGroup/FormRadioGroup'
import FormRadioGroup from '../../components/FormRadioGroup/FormRadioGroup'
import IconButton from '../../components/IconButton/IconButton'

const showExpandControl = false

type Props = {
  searchText?: string;
  scope: PurchaseListScope;
  showSearch?: boolean;
  onApply: (searchText: string, scope: PurchaseListScope) => void;
  onAddUploadedPurchase: () => void;
  onCreatePurchase?: () => void;
};

const PurchasesWalletHeader: React.FC<Props> = ({
  searchText,
  scope,
  onApply,
  showSearch,
  onAddUploadedPurchase,
  onCreatePurchase,
}): JSX.Element => {
  const [showExpanded, setShowExpanded] = useState(false)

  const onChangeSearchText = (event: any): void => {
    // console.log('onChangeShowActive called', event)
    onApply(event.detail.value, scope)
  }

  const onClearFilter = (): void => {
    onApply('', scope)
  }

  const onChangeScope = (newScope: PurchaseListScope): void => {
    onApply(searchText || '', newScope)
  }

  const onClickExpandIcon = (): void => {
    // console.log('onChangeShowActive called', event)
    setShowExpanded(!showExpanded)
  }

  const scopeChoices: FormRadioItem[] = [
    {
      value: PurchaseListScope.ACTIVE,
      label: 'Active',
      isChecked: scope === PurchaseListScope.ACTIVE,
    },
    {
      value: PurchaseListScope.PENDING,
      label: 'Pending',
      isChecked: scope === PurchaseListScope.PENDING,
    },
    {
      value: PurchaseListScope.TRANSFERRED,
      label: 'Sent',
      isChecked: scope === PurchaseListScope.TRANSFERRED,
    },
    {
      value: PurchaseListScope.ARCHIVED,
      label: 'Archived',
      isChecked: scope === PurchaseListScope.ARCHIVED,
    },
  ]

  let expandedSection
  if (showExpanded) {
    expandedSection = (
      <div className='row1'>
        <FormRadioGroup
          items={scopeChoices}
          smallLabels
          onChange={onChangeScope}
        />
      </div>
    )
  }

  const expandIcon = showExpanded ? arrowUp : arrowDown
  let buyButton
  if (onCreatePurchase) {
    buyButton = (
      <IconButton
        icon={cartOutline}
        text='Shop'
        layout='vertical'
        className='button'
        iconSize={30}
        onClick={onCreatePurchase}
      />
    )
  }

  let expandControl
  if (showExpandControl) {
    expandControl = (
      <IonIcon
        icon={expandIcon}
        class='expandFilterIcon'
        onClick={onClickExpandIcon}
      />
    )
  }

  let searchInput: JSX.Element | undefined
  if (showSearch) {
    searchInput = (
      <div className='searchBarWrapper' style={{ flex: 1 }}>
        <IonIcon icon={search} class='searchBarSearchIcon' />
        <IonInput
          value={searchText}
          placeholder='search'
          onIonChange={onChangeSearchText}
          autocapitalize='on'
          className='searchBar'
          clearInput
        />
      </div>
    )
  }

  return (
    <div className='purchases-wallet-header'>
      <div className='row0'>
        {searchInput}
        <div>
          {buyButton}
          <IconButton
            icon={cloudUploadOutline}
            text='Upload'
            layout='vertical'
            className='button'
            iconSize={30}
            onClick={onAddUploadedPurchase}
          />
        </div>
      </div>
      {expandedSection}
    </div>
  )
}

export default PurchasesWalletHeader
