import { FundType, WalletType } from '../../lib/core/enums'
import type { User, Wallet } from '../../lib/core/definitions'

const getUserMimbleWalletBalance = (
  user: User | null | undefined,
  wallets: Wallet[] | null | undefined,
  fundType: FundType,
): number => {
  if (!user || !Array.isArray(wallets) || wallets.length < 1) {
    return 0
  }
  const mimbleWallet = wallets.find(w => w.walletType === WalletType.MIMBLE)
  if (!mimbleWallet) {
    return -1
  }
  switch (fundType) {
    case FundType.CRYPTO:
      return mimbleWallet.balanceCrypto || 0
    case FundType.FIAT:
      return mimbleWallet.balanceFiat || 0
    case FundType.TOKEN:
      return mimbleWallet.balanceToken || 0
  }
  return -1
}

export default getUserMimbleWalletBalance
