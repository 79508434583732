import React, { useContext } from 'react'
import { isPlatform } from '@ionic/react'
import { Filesystem, FilesystemDirectory } from '@capacitor/filesystem'
import { Share } from '@capacitor/share'
import { pdf } from '@react-pdf/renderer'

import type { Purchase } from '../lib/core/definitions'
import GiftCardPdf from '../components/pdf/GiftCardPdf/GiftCardPdf'

export interface ExportToPdfContextValue {
  exportToPdf: () => void
}

export const defaultExportToPdfContextValue: ExportToPdfContextValue = {
  exportToPdf: () => {
    console.log('ExportToPdfContextValue.defaultExportToPdfContextValue.exportToPdf called.')
  },
}

interface Props {
  purchase: Purchase | null | undefined
  showUiMessage: (message: string) => void
}

export const ExportToPdfContext = React.createContext<ExportToPdfContextValue>(defaultExportToPdfContextValue)

export function useExportToPdf (): ExportToPdfContextValue {
  return useContext(ExportToPdfContext)
}

const ExportToPdfProvider: React.FC<Props> = (props) => {
  const { purchase, showUiMessage } = props

  const exportToPdf = (): void => {
    if (!purchase) {
      console.log('ExportToPdfContext.exportToPdf: no purchase available.')
      return
    }
    const merchant = purchase && purchase.product ? purchase.product.merchant : undefined

    const a = document.createElement('a')
    document.body.appendChild(a)

    const name = `Gift Card${merchant && ` For ${merchant.name}`}.pdf`

    pdf(<GiftCardPdf purchase={purchase} />)
      .toBlob()
      .then(blob => {
        if (isPlatform('capacitor')) {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = function () {
            const base64data = reader.result as string
            Filesystem.writeFile({
              data: base64data,
              path: name,
              directory: FilesystemDirectory.Documents,
              recursive: true,
            }).then((result) => {
              Share.share({
                url: result.uri,
                title: name,
                dialogTitle: name,
              }).then((result) => {
                console.log('ExportPurchaseToPdf.onDownloadPdf: download was successful.', { result })
              }, (error) => {
                console.log(error)
                showUiMessage('Failed to download the purchase. Please copy and share it manually.')
              })
            })
          }
        } else {
          const blobUrl = URL.createObjectURL(blob)
          a.href = blobUrl
          a.download = name
          a.click()
          setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl)
            document.body.removeChild(a)
          }, 0)
        }
      })
  }

  return (
    <ExportToPdfContext.Provider
      value={{
        exportToPdf,
      }}
    >
      {props.children}
    </ExportToPdfContext.Provider>
  )
}

export default ExportToPdfProvider
