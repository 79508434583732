import { gql } from '@apollo/client'

export default gql`
  query Q($invitationId: String $toUserId: String) {
    invitation (invitationId: $invitationId toUserId: $toUserId) {
      id
      fromUserId
      toUserId
      toUserEmail
      toUserPhoneNumber
      toUserFullName
      invitationType
      message
      messageSentAt
      messageSendReport
      campaign
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
      fromUser { username fullName imageUrl isOrganization createdAt }
    }
  }`
