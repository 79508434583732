import { OrgProductType } from '../../../enums'
import type { ShoppingCartItem } from '../../../definitions'

const showInShoppingCart = (item: ShoppingCartItem): boolean => (
  !!item &&
  (
    item.productType === OrgProductType.MIMBLE_TOKEN ||
    !!item.productOptionId
  )
)

export default showInShoppingCart
