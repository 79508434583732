import getDisplayValue from './getDisplayValue'
import getLabel from './getLabel'
import setEventYearToZero from './setEventYearToZeros'

const contactEventHelpers = {
  getDisplayValue,
  getLabel,
  setEventYearToZero,
}

export default contactEventHelpers
