import React from 'react'

import './styles.css'
type Props = {
  name?: string
  label?: string
  className?: string
  onClick?: (name: string | undefined) => void
}

const FormGroup: React.FC<Props> = (props): JSX.Element => {
  const { name, label, className, children, onClick } = props

  const handleClick = (): void => {
    if (onClick) {
      onClick(name)
    }
  }

  let renderedLabel: JSX.Element | undefined
  if (label) {
    renderedLabel = (
      <div className='label-wrapper'>
        <div className='label'>{label}</div>
      </div>
    )
  }
  return (
    <div className={`form-group ${className || ''}`} onClick={handleClick}>
      {renderedLabel}
      {children}
    </div>
  )
}

export default FormGroup
