import type { Chat, ChatMetadataUserInfo } from '../../../definitions'

const sortChatList = (chats: Chat[], activeUserId: string): Chat[] => {
  const compare = (chat1: Chat, chat2: Chat): number => {
    let chat1MeMetadata: ChatMetadataUserInfo | undefined
    let chat2MeMetadata: ChatMetadataUserInfo | undefined
    if (chat1.metadata) {
      chat1MeMetadata = chat1.fromUserId === activeUserId ? chat1.metadata.fromUser : chat1.metadata.toUser
    }
    if (chat2.metadata) {
      chat2MeMetadata = chat2.fromUserId === activeUserId ? chat2.metadata.fromUser : chat2.metadata.toUser
    }
    const chat1Contact = chat1.fromUserId === activeUserId ? chat1.toUser : chat1.fromUser
    const chat2Contact = chat2.fromUserId === activeUserId ? chat2.toUser : chat2.fromUser

    if (!chat1Contact || !chat2Contact) {
      return 0
    }

    // Chats with unread messages first:
    if (chat1MeMetadata && chat2MeMetadata) {
      const chat1UnreadMessageCount = (
        (chat1MeMetadata.unreadMessageCount || 0) +
        (chat1MeMetadata.unreadSystemMessageCount || 0)
      )
      const chat2UnreadMessageCount = (
        (chat2MeMetadata.unreadMessageCount || 0) +
        (chat2MeMetadata.unreadSystemMessageCount || 0)
      )
      if (chat1UnreadMessageCount > 0 && chat2UnreadMessageCount < 1) {
        return -1
      }
      if (chat1UnreadMessageCount < 1 && chat2UnreadMessageCount > 0) {
        return 1
      }
      if (chat1UnreadMessageCount > 0 && chat2UnreadMessageCount > 0) {
        return parseInt(chat2.updatedAt || '', 10) - parseInt(chat1.updatedAt || '', 10)
      }
    }
    return (chat1Contact.fullName || chat1Contact.username || '')
      .localeCompare(chat2Contact.fullName || chat2Contact.username || '') || 0
  }
  return chats.sort(compare)
}

export default sortChatList
