import { PurchaseListScope } from '../../lib/core/enums'
import type {
  PurchaseListItem,
  PurchasesByScopeCounts,
} from '../../lib/core/definitions'
import isPurchaseInScope from './isPurchaseInScope'

export const DEFAULT_PURCHASES_BY_SCOPE_COUNTS = {
  activeCnt: 0,
  pendingCnt: 0,
  receivedCnt: 0,
  sentCnt: 0,
  transferredCnt: 0,
  archivedCnt: 0,
}

const getPurchaseCountByScope = (list: PurchaseListItem[]): PurchasesByScopeCounts => {
  let activeCnt = 0
  let pendingCnt = 0
  let receivedCnt = 0
  let sentCnt = 0
  let transferredCnt = 0
  let archivedCnt = 0

  list.forEach((purchase): void => {
    if (isPurchaseInScope(
      PurchaseListScope.ACTIVE,
      purchase.balance,
      purchase.metadata,
      purchase.status,
      purchase.recordStatus,
    )) {
      activeCnt++
    }
    if (isPurchaseInScope(
      PurchaseListScope.PENDING,
      purchase.balance,
      purchase.metadata,
      purchase.status,
      purchase.recordStatus,
    )) {
      pendingCnt++
    }
    if (isPurchaseInScope(
      PurchaseListScope.TRANSFERRED,
      purchase.balance,
      purchase.metadata,
      purchase.status,
      purchase.recordStatus,
    )) {
      transferredCnt++
    }
    if (isPurchaseInScope(
      PurchaseListScope.RECEIVED,
      purchase.balance,
      purchase.metadata,
      purchase.status,
      purchase.recordStatus,
    )) {
      receivedCnt++
    }
    if (isPurchaseInScope(
      PurchaseListScope.SENT,
      purchase.balance,
      purchase.metadata,
      purchase.status,
      purchase.recordStatus,
    )) {
      sentCnt++
    }
    if (isPurchaseInScope(
      PurchaseListScope.ARCHIVED,
      purchase.balance,
      purchase.metadata,
      purchase.status,
      purchase.recordStatus,
    )) {
      archivedCnt++
    }
  })
  return {
    activeCnt,
    pendingCnt,
    receivedCnt,
    sentCnt,
    transferredCnt,
    archivedCnt,
  }
}

export default getPurchaseCountByScope
