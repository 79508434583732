import React, { useContext, useEffect, useState } from 'react'
import {
  IonAlert,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import { Share } from '@capacitor/share'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import {
  NotificationMethod,
  PurchaseStatus,
  PurchaseTagType,
  PurchaseTransferStatus,
  RecordStatus,
  UiMessage,
} from '../../lib/core/enums'

import './styles.css'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import { barcode, informationCircle, logoIonic } from 'ionicons/icons'

import type { Purchase, PurchaseInput } from '../../lib/core/definitions'
import type {
  CancelPurchaseTransferData,
  CancelPurchaseTransferVariables,
  DeletePurchaseData,
  DeletePurchaseVariables,
  PurchaseQueryData,
  PurchaseQueryVariables,
  SendPurchaseTransferNotificationData,
  SendPurchaseTransferNotificationVariables,
} from '../../services/apollo/definitions'
import type { TopNavTabDef } from '../../components/TopNavBar/definitions'
import { TopNavTabId } from '../../components/TopNavBar/enums'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useActivePageData } from '../../contexts/ActivePageDataContext'
import api from '../../services/api'
import apollo from '../../services/apollo'
import appLinks from '../../lib/core/appLinks'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import coreHelpers from '../../lib/core/helpers'
import EditPurchaseTab from './EditPurchaseTab/EditPurchaseTab'
import ExportToPdfContext from '../../contexts/ExportToPdfContext'
import GiftCardHeader, { GiftCardHeaderLayout } from '../../components/GiftCardHeader/GiftCardHeader'
import logger from '../../services/logger'
import MerchantInfo from '../../components/MerchantInfo/MerchantInfo'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import PurchaseInfoTab from './PurchaseInfoTab/PurchaseInfoTab'
import PurchaseUsageTab from './PurchaseUsageTab/PurchaseUsageTab'
import TopNavBar from '../../components/TopNavBar/TopNavBar'

const appPageId = AppPage.PurchasePage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined
let previousTopNavTabId: TopNavTabId | undefined
let isPageActive = false

const getTopNavBarTabs = (purchase?: Purchase, purchaseIsTransferred?: boolean): TopNavTabDef[] => {
  const tabs: TopNavTabDef[] = []

  // let transferLabel = 'Send'
  if (!purchaseIsTransferred) {
  //   transferLabel = 'Sent'
  //   if (purchase && purchase.sentPurchaseTransfers && purchase.sentPurchaseTransfers.length > 0) {
  //     const transfer = purchase.sentPurchaseTransfers[purchase.sentPurchaseTransfers.length - 1]
  //     const date = transfer.messageSentAt || transfer.createdAt
  //     if (date) {
  //       transferLabel = `Sent On ${moment(parseInt(date, 10)).format('M/D/YY')}`
  //     }
  //   }
  //   tabs.push({ id: TopNavTabId.TRANSFER, label: transferLabel })
  // } else {
    tabs.push({ id: TopNavTabId.USE_CARD, label: 'Use', icon: barcode })
  }
  tabs.push({ id: TopNavTabId.INFO, label: 'Info', icon: informationCircle })
  tabs.push({ id: TopNavTabId.MERCHANT_INFO, label: 'Brand', icon: logoIonic })
  // if (
  //   purchase &&
  //   !purchaseIsTransferred &&
  //   typeHelpers.purchaseStatus.isAvailableForTransfer(purchase.status)
  // ) {
  //   tabs.push({ id: TopNavTabId.TRANSFER, label: 'Send', icon: send })
  // }
  return tabs
}

type Params = {
  purchaseId: string
}

const PurchasePage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { purchaseId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser, reloadActiveUser, isLoadingActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const pageMessages = useContext(PageMessagesContext)
  const { start: startGiftFlow } = useGiftFlow()
  const { pageWillEnter, pageWillLeave, setActivePageData } = useActivePageData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [showConfirmCancelPurchaseTransferAlert, setShowConfirmCancelPurchaseTransferAlert] = useState(false)
  const [showConfirmDeletePurchaseAlert, setShowConfirmDeletePurchaseAlert] = useState(false)
  const [isShowingInputForm, setIsShowingInputForm] = useState(false)
  const [isUpsertingPurchase, setIsUpsertingPurchase] = useState(false)

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading purchase:
  const {
    data: purchaseLoadedData,
    loading: isLoadingPurchase,
    refetch: reloadPurchase,
    error: purchaseLoadingError,
  } = useQuery<PurchaseQueryData, PurchaseQueryVariables>(
    apollo.queries.purchase, {
      variables: { purchaseId: purchaseId as string },
      skip: !purchaseId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const returnedPurchase = data ? data.purchase : undefined
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
        if (returnedPurchase === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.GIFT_CARD_NOT_FOUND)
          return
        }
        if (
          isPageActive &&
          returnedPurchase &&
          returnedPurchase.id === purchaseId &&
          returnedPurchase.status === PurchaseStatus.RECEIVED
        ) {
          navigate(`${AppRoute.GIFT_RECEIVED}/tp:${purchaseId}`)
        }
      },
    },
  )
  const purchase = purchaseLoadedData ? purchaseLoadedData.purchase : undefined

  useEffect((): void => {
    if (purchaseLoadingError) {
      pageHelpers.checkForUnauthorized(purchaseLoadingError, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
    }
  }, [purchaseLoadingError])

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Deleting purchase:
  const [
    deletePurchase, {
      loading: isDeletingPurchase,
      error: deletePurchaseError,
    },
  ] = useMutation<DeletePurchaseData, DeletePurchaseVariables>(apollo.mutations.deletePurchase, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      reloadActiveUser().then(() => {
        navigate(AppRoute.WALLET)
      }, (error) => {
        console.error(error)
      })
    },
  })

  useEffect((): void => {
    if (deletePurchaseError) {
      setToastMessage('Error deleting gift card.')
      setShowToast(true)
    }
  }, [deletePurchaseError])

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Canceling purchase transfer:
  const [
    cancelPurchaseTransfer, {
      loading: isCancellingPurchaseTransfer,
      error: cancelPurchaseTransferError,
    },
  ] = useMutation<CancelPurchaseTransferData, CancelPurchaseTransferVariables>(apollo.mutations.cancelPurchaseTransfer, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      reloadPurchase({ purchaseId }).then(() => {
        console.log('Canceling purchase transfer done.')
        // pageMessages && pageMessages.add(PageMessageType.NOTICE, UiMessage.CANCEL_REQUEST_RECEIVED)
        setToastMessage('The sending of this gift has been canceled.')
        setShowToast(true)
        api.loadPurchase(
          purchaseId as string,
          (p: Purchase): boolean => p.status === PurchaseStatus.ACTIVE,
          undefined,
          activeUserId as string,
          apolloClient,
        )
      })
    },
  })

  useEffect((): void => {
    if (cancelPurchaseTransferError) {
      setToastMessage('Error canceling the transfer.')
      setShowToast(true)
    }
  }, [cancelPurchaseTransferError])

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Re-sending purchase transfer notification:
  const [
    sendPurchaseTransferNotification, {
      loading: isSendingPurchaseTransferNotification,
      error: sendPurchaseTransferNotificationError,
    },
  ] = useMutation<SendPurchaseTransferNotificationData, SendPurchaseTransferNotificationVariables>(apollo.mutations.sendPurchaseTransferNotification, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      reloadPurchase({ purchaseId }).then(() => {
        console.log('Sending purchase transfer notification done.')
        setToastMessage('The message will be send out shortly.')
        setShowToast(true)
      })
    },
  })

  useEffect((): void => {
    if (sendPurchaseTransferNotificationError) {
      pageHelpers.checkForUnauthorized(sendPurchaseTransferNotificationError, navigate)
      // setToastMessage('Failed to send your gift card.');
      // setShowToast(true);
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SENDING_GIFT_CARD)
    }
  }, [sendPurchaseTransferNotificationError])

  // ===================================================================================================================
  // Helpers:
  const isProcessing = (
    isLoadingActiveUser ||
    isLoadingPurchase ||
    isUpsertingPurchase ||
    isDeletingPurchase ||
    isCancellingPurchaseTransfer ||
    isSendingPurchaseTransferNotification
  )
  const purchaseIsTransferred = (
    !!purchase &&
    (
      purchase.status === PurchaseStatus.TRANSFERRED ||
      purchase.status === PurchaseStatus.TRANSFERRING
    )
  )

  // ===================================================================================================================
  // State:
  const [activeTopNavTabId, setActiveTopNavTabId] = useState(purchaseIsTransferred
    ? TopNavTabId.INFO
    : TopNavTabId.USE_CARD)

  // ===================================================================================================================
  // Helpers:
  const merchant = purchase && purchase.product ? purchase.product.merchant : undefined
  const topNavTabDefs = getTopNavBarTabs(purchase, purchaseIsTransferred)
  const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromPurchase(purchase)
  const sentPurchaseTransfer = coreHelpers.models.purchase.getPurchaseTransferFromPurchase(purchase)
  const canPurchaseTransferBeCancelled = (
    sentPurchaseTransfer &&
    (
      sentPurchaseTransfer.transferStatus === PurchaseTransferStatus.SCHEDULED ||
      sentPurchaseTransfer.transferStatus === PurchaseTransferStatus.AVAILABLE ||
      sentPurchaseTransfer.transferStatus === PurchaseTransferStatus.INVALID
    )
  )
  const canPurchaseTransferBeEdited = (
    sentPurchaseTransfer &&
    (
      sentPurchaseTransfer.transferStatus === PurchaseTransferStatus.SCHEDULED ||
      sentPurchaseTransfer.transferStatus === PurchaseTransferStatus.AVAILABLE ||
      sentPurchaseTransfer.transferStatus === PurchaseTransferStatus.INVALID
    )
  )
  const canPurchaseTransferBeSent = (
    sentPurchaseTransfer &&
    (
      sentPurchaseTransfer.transferStatus === PurchaseTransferStatus.SCHEDULED ||
      sentPurchaseTransfer.transferStatus === PurchaseTransferStatus.AVAILABLE
    ) &&
    (
      (
        sentPurchaseTransfer.notificationMethod !== NotificationMethod.OFF ||
        isPlatform('capacitor')
      ) &&
      !!sentPurchaseTransfer.messageText
    )
  )

  if (
    purchaseIsTransferred &&
    activeTopNavTabId !== TopNavTabId.INFO &&
    activeTopNavTabId !== TopNavTabId.MERCHANT_INFO
  ) {
    setActiveTopNavTabId(TopNavTabId.INFO)
  }

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  useIonViewWillEnter(() => {
    isPageActive = true
    if (pageWillEnter) {
      pageWillEnter(appPageId, {
        purchaseId,
        purchaseTransferId: sentPurchaseTransfer && canPurchaseTransferBeEdited
          ? sentPurchaseTransfer.id
          : undefined,
      })
    }
  })

  useIonViewWillLeave(() => {
    isPageActive = false
    if (pageWillLeave) {
      pageWillLeave(appPageId)
    }
  })

  useEffect(() => {
    if (isPageActive && purchase && purchase.id === purchaseId) {
      if (purchase && purchase.status === PurchaseStatus.RECEIVED) {
        navigate(`${AppRoute.GIFT_RECEIVED}/tp:${purchaseId}`)
        return
      }
      if (setActivePageData && purchaseId) {
        if (sentPurchaseTransfer && canPurchaseTransferBeEdited) {
          setActivePageData(appPageId, {
            purchaseId,
            purchaseTransferId: sentPurchaseTransfer.id,
          })
        } else {
          setActivePageData(appPageId, {
            purchaseId,
          })
        }
      }
    }
  }, [purchaseId, purchase])

  // ===================================================================================================================
  // Event Handlers:
  const onDeletePurchase = (confirmed?: any): void => {
    if (confirmed !== true) {
      setShowConfirmDeletePurchaseAlert(true)
      return
    }
    if (purchaseId) {
      deletePurchase({ variables: { purchaseId: purchaseId as string } })
    }
  }

  const onSaveNewBalance = (newBalance: number, notes?: string): void => {
    // console.log(`onSaveBalance(${newBalance}) called.`)
    if (
      !purchase ||
      (!newBalance && newBalance !== 0) ||
      newBalance === purchase.balance
    ) {
      return
    }
    const purchaseInput: PurchaseInput = {
      id: purchase.id,
      balance: newBalance,
      historyEventNotes: notes,
    }
    setIsUpsertingPurchase(true)
    const purchaseBalanceHasBeenUpdated = (purchase: Purchase): boolean => purchase.balance === newBalance
    api.upsertPurchase(
      purchaseInput,
      purchaseBalanceHasBeenUpdated,
      apollo.getPurchaseListFilter(activeUserId as string),
      activeUserId as string,
      apolloClient,
      undefined,
      { updateList: true },
    ).then(() => {
      setIsUpsertingPurchase(false)
      setToastMessage('The changes were saved successfully.')
      setShowToast(true)
    }, (error) => {
      pageHelpers.checkForUnauthorized(error, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SAVING_CHANGES)
      setIsUpsertingPurchase(false)
    })
  }

  const onShowingInputForm = (isShowingForm: boolean): void => {
    setIsShowingInputForm(isShowingForm)
  }

  const onShowZoomedBarcode = (): void => {
    navigate(`${AppRoute.BARCODE}/${purchaseId}`)
  }

  const onTransferPurchase = (): void => {
    if (!purchase) {
      logger.error('PurchasePage.onTransferPurchase: no purchase available.')
      return
    }
    const transfer = coreHelpers.models.purchase.getPurchaseTransferFromPurchase(purchase)
    const purchaseTransferId = (
      transfer &&
      transfer.id &&
      transfer.transferStatus !== PurchaseTransferStatus.CANCELED &&
      transfer.transferStatus !== PurchaseTransferStatus.DECLINED
    )
      ? transfer.id
      : undefined
    startGiftFlow({
      fromPurchaseId: purchase.id as string,
      purchaseTransferId,
    })
    navigate(AppRoute.SEND_GIFT)
  }

  const onOpenContact = (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined,
  ): void => {
    pageHelpers.openContact({
      activeUserId,
      contactId,
      contactUserId,
      chatId,
      apolloClient,
      navigate,
    })
  }

  const onOpenOrder = (orderId: string): void => {
    navigate(`${AppRoute.ORDER}/${orderId}`)
  }

  const onOpenTransaction = (transactionId: string): void => {
    navigate(`${AppRoute.TRANSACTION}/${transactionId}`)
  }

  const onSendTransferNotification = (): void => {
    const transfer = coreHelpers.models.purchase.getPurchaseTransferFromPurchase(purchase)
    if (
      !transfer ||
      !(
        transfer.transferStatus === PurchaseTransferStatus.AVAILABLE ||
        transfer.transferStatus === PurchaseTransferStatus.SCHEDULED
      )
    ) {
      setToastMessage('Failed to resend the gift.')
      setShowToast(true)
      return
    }
    if (
      isPlatform('capacitor') &&
      !transfer.toUserId
    ) {
      Share.share({
        url: appLinks.gift(transfer.id, undefined),
        dialogTitle: 'Sending you a gift!',
      }).then((result) => {
        console.log('FinishedStep.handleShareButtonClick: share was successful.', { result })
      }, (error) => {
        console.log(error)
        setToastMessage('Failed to share the gift link. Please copy and share it manually.')
        setShowToast(true)
      })
    } else {
      sendPurchaseTransferNotification({
        variables: { purchaseTransferId: transfer.id as string },
      })
    }
  }

  const onCancelPurchaseTransfer = (confirmed?: any): void => {
    const transfer = coreHelpers.models.purchase.getPurchaseTransferFromPurchase(purchase)
    if (
      !transfer ||
      transfer.transferStatus === PurchaseTransferStatus.ACCEPTED
    ) {
      setToastMessage('This transfer cannot be deleted.')
      setShowToast(true)
      return
    }
    if (confirmed !== true) {
      setShowConfirmCancelPurchaseTransferAlert(true)
      return
    }
    if (transfer.id) {
      cancelPurchaseTransfer({
        variables: { purchaseTransferId: transfer.id },
      })
    }
  }

  const setPurchaseRecordStatus = (recordStatus: RecordStatus): void => {
    console.log(`setPurchaseRecordStatus(${recordStatus}) called.`)
    if (purchase) {
      const purchaseInput: PurchaseInput = {
        id: purchase.id,
        recordStatus,
      }
      setIsUpsertingPurchase(true)
      api.upsertPurchase(
        purchaseInput,
        (purchase: Purchase): boolean => purchase.recordStatus === recordStatus,
        apollo.getPurchaseListFilter(activeUserId as string),
        activeUserId as string,
        apolloClient,
        undefined,
        { updateList: true },
      ).then(() => {
        setIsUpsertingPurchase(false)
        setToastMessage('The changes were saved successfully.')
        setShowToast(true)
      }, (error) => {
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SAVING_CHANGES)
        setIsUpsertingPurchase(false)
      })
    }
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !purchaseId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadPurchase({ purchaseId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const onUpdatePurchase = (purchaseInput: PurchaseInput): void => {
    console.log('PurchasePage.onUpdatePurchase called.', { purchaseInput })
    setIsUpsertingPurchase(true)
    const oldUpdatedAt = purchase ? purchase.updatedAt : ''
    api.upsertPurchase(
      purchaseInput,
      (purchase: Purchase): boolean => purchase.updatedAt !== oldUpdatedAt,
      apollo.getPurchaseListFilter(activeUserId as string),
      activeUserId as string,
      apolloClient,
      undefined,
      { updateList: true },
    ).then(() => {
      setIsUpsertingPurchase(false)
      setToastMessage('The changes were saved successfully.')
      setShowToast(true)
      reloadPurchase()
      setActiveTopNavTabId(previousTopNavTabId || TopNavTabId.USE_CARD)
      previousTopNavTabId = undefined
    }, (error) => {
      pageHelpers.checkForUnauthorized(error, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SAVING_CHANGES)
      setIsUpsertingPurchase(false)
    })
  }

  const onArchivePurchase = (): void => {
    setPurchaseRecordStatus(RecordStatus.INACTIVE)
  }

  const onUnarchivePurchase = (): void => {
    setPurchaseRecordStatus(RecordStatus.ACTIVE)
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onClickTag = (
    tagType: PurchaseTagType,
    value: RecordStatus | PurchaseStatus | null | undefined,
  ): void => {
    if (tagType === PurchaseTagType.RECORD_STATUS) {
      if (value === RecordStatus.ACTIVE) {
        onArchivePurchase()
      } else if (value === RecordStatus.INACTIVE) {
        onUnarchivePurchase()
      }
    }
  }

  const onEditPurchase = (): void => {
    previousTopNavTabId = activeTopNavTabId
    setActiveTopNavTabId(TopNavTabId.EDIT)
  }

  const onVisitMerchant = (): void => {
    if (!merchant || !merchant.url) {
      return
    }
    window.open(merchant.url as string, '_system')
  }

  const onCancelEditPurchase = (): void => {
    setActiveTopNavTabId(previousTopNavTabId || TopNavTabId.USE_CARD)
    previousTopNavTabId = undefined
  }

  const onClickTopNavTab = (navKey: TopNavTabId): void => {
    setActiveTopNavTabId(navKey)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content: JSX.Element | undefined
  if (purchase) {
    let tabContent
    let topNavBar

    if (activeTopNavTabId === TopNavTabId.EDIT) {
      tabContent = (
        <EditPurchaseTab
          purchase={purchase}
          isProcessing={isProcessing}
          onSubmit={onUpdatePurchase}
          onCancel={onCancelEditPurchase}
        />
      )
    } else {
      if (
        purchase.status !== PurchaseStatus.TRANSFERRING &&
        purchase.status !== PurchaseStatus.TRANSFERRED
      ) {
        topNavBar = (
          <TopNavBar
            tabs={topNavTabDefs}
            onClickTab={onClickTopNavTab}
            activeTabId={activeTopNavTabId || TopNavTabId.USE_CARD}
          />
        )
      }
      switch (activeTopNavTabId) {
        case TopNavTabId.USE_CARD:
          tabContent = (
            <PurchaseUsageTab
              purchase={purchase}
              isProcessing={isProcessing}
              onUpdateBalance={onSaveNewBalance}
              onShowingInputForm={onShowingInputForm}
              onShowZoomedBarcode={onShowZoomedBarcode}
              showUiMessage={showUiMessage}
            />
          )
          break
        case TopNavTabId.INFO:
          tabContent = (
            <PurchaseInfoTab
              purchase={purchase}
              onCancelTransfer={canPurchaseTransferBeCancelled ? onCancelPurchaseTransfer : undefined}
              onEditTransfer={canPurchaseTransferBeEdited ? onTransferPurchase : undefined}
              onOpenContact={onOpenContact}
              onOpenOrder={onOpenOrder}
              onOpenTransaction={onOpenTransaction}
              onSendTransferNotification={canPurchaseTransferBeSent ? onSendTransferNotification : undefined}
              showUiMessage={showUiMessage}
            />
          )
          break
        case TopNavTabId.MERCHANT_INFO:
          tabContent = (
            <MerchantInfo
              merchant={merchant}
            />
          )
          break
      }
    }
    content = (
      <div className='viewWrapper'>
        <ExportToPdfContext
          purchase={purchase}
          showUiMessage={showUiMessage}
        >
          <GiftCardHeader
            purchaseId={purchase.id}
            recordStatus={purchase.recordStatus || RecordStatus.UNSET}
            purchaseStatus={purchase.status as PurchaseStatus}
            backgroundImageUri={giftCardBackgroundUri}
            merchantLogoUri={merchantLogoUri}
            layout={isShowingInputForm ? GiftCardHeaderLayout.SMALL : GiftCardHeaderLayout.LARGE}
            onClickTag={onClickTag}
            onEditPurchase={onEditPurchase}
            onArchivePurchase={onArchivePurchase}
            onUnArchivePurchase={onUnarchivePurchase}
            onTransferPurchase={onTransferPurchase}
            onDeletePurchase={onDeletePurchase}
            onVisitMerchant={onVisitMerchant}
          />
        </ExportToPdfContext>
        {topNavBar}
        {tabContent}
      </div>
    )
  }

  return (
    <IonPage className='app-page-public purchase-page'>
      <NavBar
        title='Gift Card'
        goBackUri={AppRoute.WALLET}
        // contextMenuId={ContextMenuId.PURCHASE_PAGE}
        // contextMenuOptions={contextMenuOptions}
        // onContextMenu={onContextMenu}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      <IonAlert
        isOpen={showConfirmDeletePurchaseAlert}
        onDidDismiss={(): void => { setShowConfirmDeletePurchaseAlert(false) }}
        header='Delete Gift Card'
        subHeader=''
        message='Are you sure you want to delete this gift card?'
        buttons={[
          { text: 'Yes, delete', handler: (): void => { onDeletePurchase(true) } },
          { text: 'Cancel', cssClass: 'secondary', handler: (): void => { setShowConfirmDeletePurchaseAlert(false) } },
        ]}
      />
      <IonAlert
        isOpen={showConfirmCancelPurchaseTransferAlert}
        onDidDismiss={(): void => { setShowConfirmCancelPurchaseTransferAlert(false) }}
        header='Cancel Gift Card Transfer'
        subHeader=''
        message='Are you sure you want to cancel this gift card transfer?'
        buttons={[
          { text: 'Cancel Gift', handler: (): void => { onCancelPurchaseTransfer(true) } },
          { text: 'Back', cssClass: 'secondary', handler: (): void => { setShowConfirmCancelPurchaseTransferAlert(false) } },
        ]}
      />
    </IonPage>
  )
}

export default PurchasePage
