import type { DocumentNode } from '@apollo/client'
import { gql } from '@apollo/client'

const donationTargets = (addAliases = false): DocumentNode => {
  let aliases: string | undefined
  if (addAliases) {
    aliases = 'alias1 alias2 alias3'
  }
  return gql`
    query Q($filter: DonationTargetListFilter) {
      donationTargets (filter: $filter) {
        id
        userId
        name
          ${aliases}
        organizationType
        status
        description
        listed
        url
        smallLogoImageSource
        largeLogoImageSource
        recordStatus
        createdAt
        updatedAt
      }
    }`
}

export default donationTargets
