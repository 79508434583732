import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  IonAlert,
  IonButton,
  IonIcon,
  IonInput,
  IonToast,
  isPlatform,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from '@ionic/react'
import { eyeOffOutline, eyeOutline } from 'ionicons/icons'
import { parsePhoneNumber } from 'libphonenumber-js/mobile'
import { Clipboard } from '@capacitor/clipboard'
import { useApolloClient, useMutation } from '@apollo/client'

import './styles.css'
import {
  AsyncTaskResult,
  AsyncTaskSendNotificationResult,
  AsyncTaskStatus,
  AsyncTaskType,
  ErrorCode,
  IdentType,
  NotificationMethod,
  OAuthUserProvider,
  TfaStatus,
} from '../../lib/core/enums'
import type {
  SignUpData,
  SignUpOAuthUserData,
  SignUpOAuthUserVariables,
  SignUpVariables,
} from '../../services/apollo/definitions'
import type { AsyncTask, AsyncTaskInput, OAuthUser, OAuthUserInput, User, UserIdentInfo } from '../../lib/core/definitions'
import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import type { AppRoute } from '../../enums'
import { InputVerificationStatus } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useAppState } from '../../contexts/AppStateContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import api from '../../services/api'
import apollo from '../../services/apollo'
import appConfig from '../../appConfig'
import auth from '../../services/auth'
import Avatar from '../Avatar/Avatar'
import coreHelpers from '../../lib/core/helpers'
import firebase from '../../services/firebase'
import FormItem from '../../components/FormItem/FormItem'
import logger from '../../services/logger'
import OAuthButtons from '../OAuthButtons.ts/OAuthButtons'
import pageHelpers from '../../helpers/pageHelpers'
import ProcessingOverlay from '../ProcessingOverlay/ProcessingOverlay'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../helpers/validationHelpers'
import VerifyInputIndicator, { getInputVerificationStatusFromTfaStatus } from '../VerifyInputIndicator/VerifyInputIndicator'

const getPhoneNumberVerificationStatus = (phoneNumberStatus: TfaStatus | undefined): InputVerificationStatus | undefined => {
  if (phoneNumberStatus === TfaStatus.CODE_MISMATCH) {
    return InputVerificationStatus.INVALID
  }
  if (phoneNumberStatus === TfaStatus.PASSED) {
    return InputVerificationStatus.VALID
  }
}

const phoneNumbersChecks = new Map<string, string>()

export interface SignUpFormData {
  fullName: string | undefined
  showFullNameHelpText: boolean
  username: string | undefined
  availableUsername: string | undefined
  showUsernameInput: boolean
  email: string | undefined
  showEmailInput: boolean
  phoneNumber: string | undefined
  verifyPhoneNumberTaskId: string | undefined
  phoneNumberStatus: TfaStatus | undefined
  showPhoneNumberHelpText: boolean
  inviteCode: string | null | undefined
  inviteCodeStatus: InputVerificationStatus | undefined
  purchaseTransferId: string | null | undefined
  receivedPurchaseId: string | null | undefined
  showInviteCodeInput: boolean
}

interface Props {
  inviteCodeFromParams?: string
  fullName?: string | null
  phoneNumber?: string | null
  email?: string | null
  purchaseTransferId?: string | null
  receivedPurchaseId?: string | null
  onGoToSignIn: () => void
  onNext: () => void
}

const SignUpForm: React.FC<Props> = (props): JSX.Element => {
  // const navigate = useNavigate()

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  const {
    fullName: fullNameFromProps,
    phoneNumber: phoneNumberFromProps,
    email: emailFromProps,
    inviteCodeFromParams,
    onGoToSignIn,
    onNext,
    purchaseTransferId,
    receivedPurchaseId,
  } = props

  const enableFirebase = firebase.isAuthActive()

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { isActive: isAppActive } = useAppState()
  const { reloadActiveUser } = useMimbleData()

  const {
    getObj,
    setObj,
    getValue: getGlobalCacheValue,
  } = useGlobalCache()
  const receivedInviteCode = inviteCodeFromParams || getGlobalCacheValue(GlobalCacheDataKey.INVITE_CODE)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // oAuth (firebase:
  const [oAuthProvider, setOAuthProvider] = useState(undefined as OAuthUserProvider | undefined)
  const [isWaitingForOAuthUser, setIsWaitingForOAuthUser] = useState(false)
  const [oAuthUser, setOAuthUser] = useState<OAuthUser | undefined>()

  // Name:
  const [fullName, setFullName] = useState<string | undefined>()
  const [fullNameValidationError, setFullNameValidationError] = useState<string | undefined>()
  const [enableValidationForFullName, setEnableValidationForFullName] = useState(false)
  const [showFullNameHelpText, setShowFullNameHelpText] = useState(false)
  const fullNameRef = useRef<HTMLIonInputElement | null>(null)

  // Username/handle:
  const [username, setUsername] = useState<string | undefined>()
  const [usernameValidationError, setUsernameValidationError] = useState<string | undefined>()
  const [enableValidationForUsername, setEnableValidationForUsername] = useState(false)
  const [availableUsername, setAvailableUsername] = useState<string | undefined>()
  const [showUsernameInput, setShowUsernameInput] = useState(false)
  const usernameRef = useRef<HTMLIonInputElement | null>(null)

  // Email:
  const [email, setEmail] = useState<string | undefined>()
  const [emailValidationError, setEmailValidationError] = useState<string | undefined>()
  const [enableValidationForEmail, setEnableValidationForEmail] = useState(false)
  const [showEmailInput, setShowEmailInput] = useState(false)
  const emailRef = useRef<HTMLIonInputElement | null>(null)

  // Phone number:
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>()
  const [phoneNumberValidationError, setPhoneNumberValidationError] = useState<string | undefined>()
  const [enableValidationForPhoneNumber, setEnableValidationForPhoneNumber] = useState(false)
  const [showPhoneNumberHelpText, setShowPhoneNumberHelpText] = useState(false)
  const [verifyPhoneNumberTaskId, setVerifyPhoneNumberTaskId] = useState<string | undefined>()
  const [phoneNumberStatus, setPhoneNumberStatus] = useState<TfaStatus | undefined>()
  const [isShowingFailedToSendMfaNotificationAlert, setIsShowingFailedToSendMfaNotificationAlert] = useState(false)
  const [tfaCode, setTfaCode] = useState<string | undefined>()
  const [tfaCodeValidationError, setTfaCodeValidationError] = useState<string | undefined>()
  const phoneNumberRef = useRef<HTMLIonInputElement | null>(null)
  const tfaCodeRef = useRef<HTMLIonInputElement | null>(null)
  const verifyPhoneNumberStatus = getPhoneNumberVerificationStatus(phoneNumberStatus)
  const showTfaCodeInput = (
    phoneNumberStatus === TfaStatus.MESSAGE_SENT ||
    phoneNumberStatus === TfaStatus.VERIFYING_CODE ||
    phoneNumberStatus === TfaStatus.CODE_MISMATCH
  )

  // Password:
  const [password, setPassword] = useState<string | undefined>()
  const [passwordValidationError, setPasswordValidationError] = useState<string | undefined>()
  const [enableValidationForPassword, setEnableValidationForPassword] = useState(false)
  const [showClearPassword, setShowClearPassword] = useState(false)
  const passwordRef = useRef<HTMLIonInputElement>(null)

  // Invite code:
  const [inviteCode, setInviteCode] = useState<string | null | undefined>()
  const [invitedBy, setInvitedBy] = useState<UserIdentInfo | null | undefined>()
  const [inviteCodeValidationError, setInviteCodeValidationError] = useState<string | undefined>()
  const [enableValidationForInviteCode, setEnableValidationForInviteCode] = useState(false)
  const [inviteCodeStatus, setInviteCodeStatus] = useState<InputVerificationStatus | undefined>()
  const [showInviteCodeInput, setShowInviteCodeInput] = useState(false)
  const inviteCodeRef = useRef<HTMLIonInputElement | null>(null)
  const enableValidateInviteCode = (
    !!inviteCode &&
    !inviteCodeValidationError &&
    (
      inviteCodeStatus === InputVerificationStatus.INVALID ||
      inviteCodeStatus === InputVerificationStatus.VERIFY
    )
  )

  // Others:
  const [isProcessingSignUp, setIsProcessingSignUp] = useState(false)
  const [isShowingProcessingPopover, setIsShowingProcessingPopover] = useState(false)
  const [isShowingExistingAccountAlert, setIsShowingExistingAccountAlert] = useState(false)
  const [isShowingCreateAccountFailedAlert, setIsShowingCreateAccountFailedAlert] = useState(false)

  const fullNameFormValue = fullName || pageHelpers.getFormInputValue(fullNameRef)
  const usernameFormValue = username || pageHelpers.getFormInputValue(usernameRef)
  const emailFormValue = email || pageHelpers.getFormInputValue(emailRef)
  const phoneNumberFormValue = phoneNumber || pageHelpers.getFormInputValue(phoneNumberRef)
  const passwordFormValue = password || pageHelpers.getFormInputValue(passwordRef)
  const inviteCodeFormValue = inviteCode || pageHelpers.getFormInputValue(inviteCodeRef)
  const [autoFillInputs, setAutoFillInputs] = useState<string[]>([])

  const isValid = (
    (!!fullNameFormValue || !!fullNameFromProps || autoFillInputs.includes('fullName')) &&
    (!!phoneNumberFormValue || !!phoneNumberFromProps || autoFillInputs.includes('phoneNumber')) &&
    (!!passwordFormValue || autoFillInputs.includes('password')) &&
    !usernameValidationError &&
    !emailValidationError &&
    !phoneNumberValidationError &&
    !passwordValidationError &&
    !inviteCodeValidationError
  )
  const enableSubmitButton = (
    isValid &&
    (phoneNumberStatus === TfaStatus.PASSED || purchaseTransferId || receivedPurchaseId) &&
    !validationHelpers.validatePassword(password)
  )

  // ===================================================================================================================
  // Helpers:
  const getFormData = (): SignUpFormData => ({
    fullName,
    showFullNameHelpText,
    username,
    availableUsername,
    showUsernameInput,
    email,
    showEmailInput,
    phoneNumber,
    verifyPhoneNumberTaskId,
    phoneNumberStatus,
    showPhoneNumberHelpText,
    inviteCode: inviteCode || receivedInviteCode,
    inviteCodeStatus,
    showInviteCodeInput,
    purchaseTransferId,
    receivedPurchaseId,
  })

  const onFirebaseAuthStateChange = (oAuthUser: OAuthUser) => {
    console.log('SignUpForm.onFirebaseAuthStateChange: called.', { oAuthUser })
    setOAuthUser(oAuthUser)
  }

  const saveFormData = (data ? : SignUpFormData | null): void => {
    const d = (data || data === null) ? data : getFormData()
    // console.log(`SignUpForm.saveFormData called: data=${JSON.stringify(d)}`)
    setObj<SignUpFormData>(
      GlobalCacheDataKey.SIGN_UP_FORM_DATA,
      d || undefined,
      true,
    )
  }

  const handleSignUp = (user: User) => {
    auth.signIn(user, apolloClient, navigate)

    setTimeout(() => {
      reloadActiveUser().then(() => {
        setIsProcessingSignUp(false)
        setIsShowingProcessingPopover(false)

        if (isPlatform('capacitor')) {
          setTimeout(() => {
            firebase.reportPushNotificationTokenToServer(undefined, apolloClient)
          }, 4000)
        }

        onNext()
        saveFormData(null)
      }, (error) => {
        setIsProcessingSignUp(false)
        setIsShowingProcessingPopover(false)
        logger.error('SignUpForm: reloadActiveUser returned error.', { error })
      })
    }, 2000)
  }

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewWillEnter(() => {
    // console.log('SignUpForm.useIonViewWillEnter called.')
    if (enableFirebase) {
      firebase.addAuthStateChangeListener('SignUpForm', onFirebaseAuthStateChange)
    }
  })

  useIonViewDidEnter(() => {
    // console.log('SignUpForm.useIonViewDidEnter called.', { phoneNumber })
    pageHelpers.setAutoFillStatus([
      'fullName',
      'username',
      'email',
      'phoneNumber',
      'password',
      'inviteCode',
    ], setAutoFillInputs)
  })

  useIonViewDidLeave(() => {
    // console.log('SignUpForm.useIonViewDidLeave called.')
    if (enableFirebase) {
      firebase.removeAuthStateChangeListener('SignUpForm')
    }
  })

  useEffect(() => {
    const data = getObj<SignUpFormData>(
      GlobalCacheDataKey.SIGN_UP_FORM_DATA,
      true,
    )
    // console.log(`SignUpForm.useEffect[] called data=${JSON.stringify(data)}.`)
    if (data) {
      setFullName(data.fullName)
      setShowFullNameHelpText(data.showFullNameHelpText)
      setUsername(data.username)
      setAvailableUsername(data.availableUsername)
      setShowUsernameInput(data.showUsernameInput)
      setEmail(data.email)
      setShowEmailInput(data.showEmailInput)
      setPhoneNumber(data.phoneNumber)
      setPhoneNumberStatus(data.phoneNumberStatus)
      setShowPhoneNumberHelpText(data.showPhoneNumberHelpText)
      setInviteCode(data.inviteCode)
      setInviteCodeStatus(data.inviteCodeStatus)
      setShowInviteCodeInput(data.showInviteCodeInput)

      if (data.inviteCode) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onVerifyInviteCode(data.inviteCode)
      }
    }
  }, [])

  useEffect(() => {
    // console.log(`SignUpForm.useEffect[isAppActive] called; isAppActive=${isAppActive}.`,
    //   { phoneNumber, phoneNumberStatus, verifyPhoneNumberTaskId })
    if (
      isAppActive &&
      phoneNumber &&
      verifyPhoneNumberTaskId &&
      (
        phoneNumberStatus === TfaStatus.MESSAGE_SENT ||
        phoneNumberStatus === TfaStatus.CODE_MISMATCH
      )
    ) {
      Clipboard.read().then((result): void => {
        if (result) {
          // console.log('SignUpForm.useEffect[isAppActive]: Found value', result)
          if (
            result.type === 'text/plain' &&
            result.value &&
            result.value.length === 6 &&
            result.value.match(/^\d{6}$/) &&
            verifyPhoneNumberTaskId
          ) {
            // console.log('SignUpForm.useEffect[isAppActive]: Value might be our code')
            setTfaCode(result.value)
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onVerifyTfaCode(result.value)
          }
        }
      })
    }
  }, [isAppActive])

  useEffect(() => {
    if (
      receivedInviteCode &&
      !invitedBy &&
      invitedBy !== null &&
      !inviteCodeValidationError
    ) {
      setInviteCodeStatus(InputVerificationStatus.PROCESSING)
      saveFormData()
      api.findUser({ inviteCode: receivedInviteCode }, false, apolloClient)
        .then((userIdentInfos) => {
          if (Array.isArray(userIdentInfos) && userIdentInfos.length === 1) {
            setInviteCodeStatus(InputVerificationStatus.VALID)
            setInvitedBy(userIdentInfos[0])
          } else {
            setShowInviteCodeInput(true)
            setInviteCodeStatus(InputVerificationStatus.INVALID)
            setInviteCodeValidationError('Invite code not found')
          }
          saveFormData()
        }, (error) => {
          setInviteCodeStatus(InputVerificationStatus.INVALID)
          logger.error('SignUpForm: loadUserIdentInfo returned error.', { error })
        })
    }
  }, [receivedInviteCode])

  // ===================================================================================================================
  // Apollo Hooks:
  const [
    signUp, { loading: isSigningUp },
  ] = useMutation<SignUpData, SignUpVariables>(apollo.mutations.signUp, {
    context: { skipAuthentication: true },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      logger.debug('JoinPage: mutation succeeded.', data)
      if (data && data.signUp) {
        const user = data.signUp
        if (!user) {
          logger.error('JoinPage: signUp did not return a valid user.', data)
          setIsShowingProcessingPopover(false)
          setIsProcessingSignUp(false)
          setIsShowingCreateAccountFailedAlert(true)
          return
        }
        handleSignUp(user)
      }
    },
    onError: (error) => {
      logger.error('SignUpForm.useMutation[signUp]: failed.', { error })
      setIsShowingProcessingPopover(false)
      setIsProcessingSignUp(false)
      if (error.message === ErrorCode.USER_ALREADY_EXISTS) {
        setIsShowingExistingAccountAlert(true)
      } else {
        setIsShowingCreateAccountFailedAlert(true)
      }
    },
  })

  const [
    signUpOAuthUser, { loading: isSigningUpOAuthUser },
  ] = useMutation<SignUpOAuthUserData, SignUpOAuthUserVariables>(apollo.mutations.signUpOAuthUser, {
    context: { skipAuthentication: true },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      logger.info('JoinPage: mutation signUpOAuthUser succeeded.', data)
      if (data && data.signUpOAuthUser) {
        const user = data.signUpOAuthUser
        if (!user.oAuthProvider && oAuthUser) {
          user.oAuthProvider = oAuthUser.provider
        }
        if (!user) {
          logger.error('JoinPage: signUpOAuthUser did not return a valid user.', data)
          setIsShowingProcessingPopover(false)
          setIsProcessingSignUp(false)
          setIsShowingCreateAccountFailedAlert(true)
          return
        }
        handleSignUp(user)
      }
    },
    onError: (error): void => {
      logger.error('SignUpForm.useMutation[signUpOauthUser]: failed.', { error })
      setIsShowingProcessingPopover(false)
      setIsProcessingSignUp(false)
      firebase.signOut()
      if (error.message === ErrorCode.USER_ALREADY_EXISTS) {
        // Should we sign the user in at this point? If so,
        // we probably have to make sure the same oAuth provider
        // was used, to prevent hijacking an account with a different
        // provider using the email/phone number of an existing member.
        setIsShowingExistingAccountAlert(true)
      } else {
        setIsShowingCreateAccountFailedAlert(true)
      }
    },
  })

  useEffect(() => {
    // console.log('SignUpForm.useEffect([oAuthUser)] called.', { oAuthUser, isWaitingForOAuthUser, oAuthProvider })

    if (!oAuthUser || !isWaitingForOAuthUser || !oAuthProvider) {
      console.log('SignUpForm.onFirebaseAuthStateChange: not waiting.')
      return
    }

    console.log('SignUpForm.onFirebaseAuthStateChange called.', { oAuthUser })
    setIsWaitingForOAuthUser(false)

    setIsShowingProcessingPopover(true)

    const oAuthUserInput = { ...oAuthUser } as OAuthUserInput
    if (!oAuthUserInput.provider && oAuthProvider) {
      oAuthUserInput.provider = oAuthProvider
    }
    if (inviteCode !== null) {
      const inviteCodeFormValue = inviteCode || pageHelpers.getFormInputValue(inviteCodeRef)
      if (inviteCodeFormValue || receivedInviteCode) {
        oAuthUserInput.inviteCode = inviteCodeFormValue || receivedInviteCode
      }
    }

    setIsProcessingSignUp(true)
    const user: OAuthUserInput = {
      ...oAuthUserInput,
    }
    if (purchaseTransferId) {
      user.purchaseTransferId = purchaseTransferId
    }
    if (receivedPurchaseId) {
      user.receivedPurchaseId = receivedPurchaseId
    }
    signUpOAuthUser({ variables: { user } })
  }, [oAuthUser])

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isSigningUp || isSigningUpOAuthUser || isProcessingSignUp

  const validatePhoneNumber = (
    val: string | null | undefined,
    handleError: boolean,
  ): { valid: boolean; cleanPhoneNumber: string | undefined } => {
    // console.log('SignUpForm.validatePhoneNumber called.', { val, handleError })
    if (!val) {
      return { valid: false, cleanPhoneNumber: undefined }
    }

    try {
      const phoneNumberInfo = parsePhoneNumber(val, 'US')
      if (!phoneNumberInfo || !phoneNumberInfo.isValid()) {
        if (handleError) {
          setPhoneNumberValidationError('not a valid phone number')
        }
        return { valid: false, cleanPhoneNumber: undefined }
      }
      // console.log('SignUpForm.validatePhoneNumber: number is valid.', { cleanPhoneNumber: phoneNumberInfo.number as string })
      return { valid: true, cleanPhoneNumber: phoneNumberInfo.number as string }
    } catch (error) {
      console.warn((error as Error).message)
    }
    return { valid: false, cleanPhoneNumber: undefined }
  }

  const onVerifyPhoneNumber = (): void => {
    const { valid, cleanPhoneNumber } = validatePhoneNumber(
      phoneNumber || pageHelpers.getFormInputValue(phoneNumberRef) || phoneNumberFromProps,
      true,
    )

    if (!valid || !cleanPhoneNumber) {
      return
    }

    setPhoneNumberValidationError(undefined)

    if (phoneNumbersChecks.has(cleanPhoneNumber)) {
      console.log('SignUpForm.onBlurPhoneNumber: already checked this phone number.')
      return
    }

    api.findUser({ phoneNumber: cleanPhoneNumber, exactMatch: true }, false, apolloClient)
      .then((userIdentInfos) => {
        const takenErrorMessage = 'Already taken by another member'
        const failedToSendErrorMessage = 'Failed to send verification text message'

        if (Array.isArray(userIdentInfos) && userIdentInfos.length === 1) {
          setPhoneNumberValidationError(takenErrorMessage)
          phoneNumbersChecks.set(cleanPhoneNumber, takenErrorMessage)
          return
        }

        if (phoneNumberValidationError === takenErrorMessage) {
          setPhoneNumberValidationError(undefined)
        }

        const isTaskInTargetStateFunc = (task: AsyncTask): boolean => !!task && !!task.notificationResult
        let taskInput: AsyncTaskInput = {}
        const emailFormValue = email || pageHelpers.getFormInputValue(emailRef)
        if (emailFormValue) {
          taskInput = {
            email: emailFormValue,
            notificationMethod: NotificationMethod.EMAIL,
            taskType: AsyncTaskType.VERIFY_EMAIL,
          }
        }
        if (cleanPhoneNumber) {
          taskInput = {
            phoneNumber: cleanPhoneNumber,
            notificationMethod: NotificationMethod.SMS,
            taskType: AsyncTaskType.VERIFY_PHONE_NUMBER_ON_SIGN_UP,
          }
        }
        if (phoneNumberStatus !== TfaStatus.MESSAGE_REQUESTED) {
          setPhoneNumberStatus(TfaStatus.MESSAGE_REQUESTED)
        }
        auth.createAsyncTask(taskInput, apolloClient, isTaskInTargetStateFunc)
          .then((asyncTask) => {
            console.log('SignUpForm.onCreateAsyncTask: response asyncTask=', { asyncTask })
            if (asyncTask && asyncTask.id) {
              setVerifyPhoneNumberTaskId(asyncTask.id)
              if (asyncTask && asyncTask.notificationResult === AsyncTaskSendNotificationResult.OK) {
                if (phoneNumberStatus !== TfaStatus.MESSAGE_SENT) {
                  setPhoneNumberStatus(TfaStatus.MESSAGE_SENT)
                  if (tfaCodeRef.current) {
                    tfaCodeRef.current.setFocus()
                  }
                }
              } else {
                setIsShowingFailedToSendMfaNotificationAlert(true)
                setPhoneNumberValidationError(failedToSendErrorMessage)
                phoneNumbersChecks.set(cleanPhoneNumber, failedToSendErrorMessage)
                if (phoneNumberStatus !== TfaStatus.MESSAGE_FAILED_TO_SEND) {
                  setPhoneNumberStatus(TfaStatus.MESSAGE_FAILED_TO_SEND)
                }
              }
            }
          }, (error) => {
            console.log(error)
            if (phoneNumberStatus !== TfaStatus.MESSAGE_FAILED_TO_SEND) {
              setPhoneNumberStatus(TfaStatus.MESSAGE_FAILED_TO_SEND)
            }
          })
      }, (error) => {
        logger.error('SignUpForm: createAsyncTask returned error.', { error })
      })
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeFullName = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(fullNameRef)
    setFullName(val)
    if (enableValidationForFullName) {
      setFullNameValidationError(
        val
          ? validationHelpers.validateUserFullName(val)
          : 'required',
      )
    } else if (val) {
      setTimeout(() => {
        setEnableValidationForFullName(true)
        // setFullNameValidationError(getFullNameValidationError(fullName))
      }, 4000)
    }
    saveFormData({ ...getFormData(), fullName: val })
  }

  const onBlurFullName = (): void => {
    const val = fullName || pageHelpers.getFormInputValue(fullNameRef)
    if (val !== undefined && !enableValidationForFullName) {
      setEnableValidationForFullName(true)
      setFullNameValidationError(
        val
          ? validationHelpers.validateUserFullName(val)
          : 'required',
      )
    }
  }

  const onChangeUsername = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(usernameRef)
    setUsername(val)
    if (enableValidationForUsername) {
      setUsernameValidationError(validationHelpers.validateUsername(val))
    } else if (val) {
      setTimeout(() => {
        setEnableValidationForUsername(true)
      }, 4000)
    }
    saveFormData({ ...getFormData(), username: val })
  }

  const onBlurUsername = (): void => {
    const val = username || pageHelpers.getFormInputValue(usernameRef)
    if (val) {
      // console.log('onBlurUsername called.', { val })
      const err = (
        val
          ? validationHelpers.validateUsername(val)
          : 'required'
      )
      if (!enableValidationForUsername) {
        setEnableValidationForUsername(true)
        setUsernameValidationError(err)
      }
      if (!err) {
        api.findUser({ username: val, exactMatch: true }, false, apolloClient)
          .then((userIdentInfos) => {
            if (Array.isArray(userIdentInfos) && userIdentInfos.length === 1) {
              setUsernameValidationError('Already taken by another member')
            } else if (usernameValidationError === 'Already taken by another member') {
              setUsernameValidationError(undefined)
            }
          }, (error) => {
            logger.error('SignUpForm: loadUserIdentInfo returned error.', { error })
          })
      }
    }
  }

  const onChangeEmail = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(emailRef)
    setEmail(val)
    if (enableValidationForEmail) {
      setEmailValidationError(validationHelpers.validateEmail(val))
    } else if (val) {
      setTimeout(() => {
        setEnableValidationForEmail(true)
      }, 4000)
    }
    saveFormData({ ...getFormData(), email: val })
  }

  const onBlurEmail = (): void => {
    const val = email || pageHelpers.getFormInputValue(emailRef)
    if (val) {
      // console.log('onBlurEmail called.', { val })
      const err = (
        val
          ? validationHelpers.validateEmail(val)
          : 'required'
      )
      if (!enableValidationForEmail) {
        setEnableValidationForEmail(true)
        setEmailValidationError(err)
      }
      if (!err) {
        api.findUser({ email }, false, apolloClient).then((userIdentInfos) => {
          if (Array.isArray(userIdentInfos) && userIdentInfos.length > 0) {
            setEmailValidationError('Already taken by another member')
          } else if (emailValidationError === 'Already taken by another member') {
            setEmailValidationError(undefined)
          }
        }, (error) => {
          logger.error('SignUpForm: loadUserIdentInfo returned error.', { error })
        })
      }
    }
  }

  const onChangePhoneNumber = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(phoneNumberRef)
    // console.log('onChangePhoneNumber called.', { val })
    setPhoneNumber(val)
    if (phoneNumberStatus) {
      setPhoneNumberStatus(undefined)
    }
    if (enableValidationForPhoneNumber) {
      const result = validatePhoneNumber(val, true)
      setPhoneNumberValidationError(result.valid ? undefined : 'not a valid number')
    } else {
      if (phoneNumberValidationError) {
        setPhoneNumberValidationError(undefined)
      }
      if (val) {
        setTimeout(() => {
          if (!enableValidationForPhoneNumber) {
            setEnableValidationForPhoneNumber(true)
          }
        }, 4000)
      }
    }

    saveFormData({ ...getFormData(), phoneNumber: val })
  }

  const onBlurPhoneNumber = (): void => {
    // console.log('onBlurPhoneNumber', { phoneNumber })
    if (!enableValidationForPhoneNumber) {
      setEnableValidationForPhoneNumber(true)
      validatePhoneNumber(
        phoneNumber || pageHelpers.getFormInputValue(phoneNumberRef) || phoneNumberFromProps,
        true,
      )
    }
  }

  const onMfaCodeConfirmed = (): void => {
    const phoneNumberFormValue = phoneNumber || pageHelpers.getFormInputValue(phoneNumberRef)
    if (!phoneNumberFormValue) {
      return
    }

    if (phoneNumberValidationError) {
      setPhoneNumberValidationError(undefined)
    }

    try {
      const phoneNumberInfo = parsePhoneNumber(phoneNumberFormValue, 'US')
      if (phoneNumberInfo && phoneNumberInfo.isValid()) {
        phoneNumbersChecks.set(phoneNumberInfo.number as string, '')
      }
    } catch (error) {
      console.warn((error as Error).message)
    }

    const passwordFormValue = password || pageHelpers.getFormInputValue(passwordRef)
    if (!passwordFormValue && passwordRef.current) {
      passwordRef.current.focus()
    }
  }

  const onVerifyTfaCode = (enteredCode?: string): void => {
    const savedFormData = getFormData()
    const curCode = enteredCode || tfaCode
    const curTaskId = verifyPhoneNumberTaskId || (savedFormData && !savedFormData.verifyPhoneNumberTaskId)
    const curTfaStatus = phoneNumberStatus || (savedFormData && !savedFormData.phoneNumberStatus)
    console.log('SignUpForm.onVerifyTfaCode called.', { enteredCode, curCode, curTaskId, savedFormData })

    if (
      !curTaskId ||
      !curCode ||
      tfaCodeValidationError
    ) {
      console.log('SignUpForm.onVerifyTfaCode: not ready.',
        { curTaskId, curCode, tfaCodeValidationError })
      return
    }

    if (phoneNumberStatus !== TfaStatus.VERIFYING_CODE) {
      setPhoneNumberStatus(TfaStatus.VERIFYING_CODE)
      saveFormData({ ...getFormData(), phoneNumberStatus: TfaStatus.VERIFYING_CODE })
    }
    auth.verifyAsyncTaskToken(
      curTaskId as string,
      curCode,
      undefined,
      undefined,
      apolloClient,
    )
      .then((asyncTask) => {
        let newPhoneStatus: TfaStatus | undefined
        if (
          asyncTask &&
          asyncTask.taskStatus === AsyncTaskStatus.FINISHED &&
          asyncTask.result === AsyncTaskResult.OK
        ) {
          console.log('The code has been confirmed.')
          setToastMessage('Your phone number has been verified.')
          setShowToast(true)
          newPhoneStatus = TfaStatus.PASSED
          onMfaCodeConfirmed()
        } else if (
          asyncTask &&
          asyncTask.result === AsyncTaskResult.CONFIRM_TOKEN_MISMATCH
        ) {
          const errorMessage = 'The code did not match.'
          newPhoneStatus = TfaStatus.CODE_MISMATCH
          setToastMessage(errorMessage)
          setShowToast(true)
          setTfaCodeValidationError(errorMessage)
        }

        setPhoneNumberStatus(newPhoneStatus)
        saveFormData({ ...getFormData(), phoneNumberStatus: newPhoneStatus })
      }, (error) => {
        console.log(error)
        if (phoneNumberStatus !== TfaStatus.CODE_MISMATCH) {
          setPhoneNumberStatus(TfaStatus.CODE_MISMATCH)
          saveFormData({ ...getFormData(), phoneNumberStatus: TfaStatus.CODE_MISMATCH })
        }
      })
  }

  const onChangeTfaCode = (event: any): void => {
    if (event && event.detail && event.detail.value) {
      const cleaned = (event.detail.value).replace(/\D/g, '').substr(0, 6)
      setTfaCode(cleaned)
      if (tfaCodeValidationError) {
        setTfaCodeValidationError(undefined)
      }
      if (
        cleaned &&
        cleaned.length === 6 &&
        !validationHelpers.validateMfaCode(cleaned) &&
        verifyPhoneNumberTaskId
      ) {
        onVerifyTfaCode(cleaned)
      }
    } else {
      setTfaCode(undefined)
    }
  }

  const onBlurTfaCode = (): void => {
    if (tfaCode && tfaCode.length === 6) {
      const err = validationHelpers.validateMfaCode(tfaCode)
      setTfaCodeValidationError(err)
      // if (!err) {
      //   setIsCheckingCode(true)
      //   loadUserIdentInfo({ variables: { ident: code } })
      // }
    }
  }

  const onChangePassword = (event: any): void => {
    const val = event.detail.value || pageHelpers.getFormInputValue(passwordRef)
    setPassword(val)
    if (enableValidationForPassword) {
      setPasswordValidationError(
        val
          ? validationHelpers.validatePassword(val)
          : 'required',
      )
    } else if (val) {
      setTimeout(() => {
        setEnableValidationForPassword(true)
      }, 4000)
    }
    if (
      val &&
      !phoneNumberValidationError &&
      phoneNumberStatus !== TfaStatus.PASSED &&
      !isShowingFailedToSendMfaNotificationAlert
    ) {
      if (phoneNumber || phoneNumberFromProps) {
        setPhoneNumberValidationError('Please verify the phone number with the button below.')
      } else {
        setPhoneNumberValidationError('Please enter your phone number.')
      }
      setEnableValidationForPhoneNumber(true)
    }
  }

  const onBlurPassword = (): void => {
    const val = password || pageHelpers.getFormInputValue(passwordRef)
    if (val !== undefined && !enableValidationForPassword) {
      setEnableValidationForPassword(true)
      setPasswordValidationError(
        val
          ? validationHelpers.validatePassword(val)
          : 'required',
      )
    }
  }

  const onChangeInviteCode = (event: any): void => {
    let newStatus: InputVerificationStatus | undefined
    const val = event.detail.value || pageHelpers.getFormInputValue(inviteCodeRef)
    setInviteCode(val || null)
    if (invitedBy) {
      setInvitedBy(undefined)
    }
    const validationErrors = validationHelpers.validateInviteCode(val)
    if (enableValidationForInviteCode) {
      setInviteCodeValidationError(validationErrors)
    } else if (val) {
      setTimeout(() => {
        setEnableValidationForInviteCode(true)
      }, 4000)
    }
    if (!val || validationErrors) {
      newStatus = undefined
    } else {
      newStatus = InputVerificationStatus.VERIFY
    }
    setInviteCodeStatus(newStatus)
    if (inviteCodeValidationError) {
      setInviteCodeValidationError(undefined)
    }
    saveFormData({ ...getFormData(), inviteCode: val })
  }

  const onVerifyInviteCode = (newInviteCode?: string): void => {
    const val = newInviteCode || inviteCode || pageHelpers.getFormInputValue(inviteCodeRef)
    console.log('SignUpForm.onVerifyInviteCode called.', { newInviteCode, inviteCode, val })
    if (!val) {
      return
    }
    if (!enableValidationForInviteCode) {
      setEnableValidationForInviteCode(true)
    }
    const validationErrors = validationHelpers.validateInviteCode(val)
    setInviteCodeValidationError(validationErrors)
    if (validationErrors) {
      setInviteCodeStatus(InputVerificationStatus.INVALID)
      saveFormData({ ...getFormData(), inviteCodeStatus: InputVerificationStatus.INVALID })
    } else {
      setInviteCodeStatus(InputVerificationStatus.PROCESSING)
      api.findUser({ inviteCode: val }, false, apolloClient).then((userIdentInfos) => {
        if (Array.isArray(userIdentInfos) && userIdentInfos.length === 1) {
          setInvitedBy(userIdentInfos[0])
          setInviteCodeStatus(InputVerificationStatus.VALID)
          saveFormData({ ...getFormData(), inviteCodeStatus: InputVerificationStatus.VALID })
        } else {
          if (invitedBy) {
            setInvitedBy(null)
          }
          setInviteCodeValidationError('Invite code not found')
          setInviteCodeStatus(InputVerificationStatus.INVALID)
        }
      }, (error) => {
        logger.error('SignUpForm: loadUserIdentInfo returned error.', { error })
        setInviteCodeStatus(InputVerificationStatus.INVALID)
        saveFormData({ ...getFormData(), inviteCodeStatus: InputVerificationStatus.INVALID })
      })
    }
  }

  const onSubmit = (event?: any): void => {
    event && event.preventDefault()

    firebase.getPushNotificationToken().then((pushNotificationToken) => {
      if (
        (!fullNameFormValue && !fullNameFromProps) ||
        (!phoneNumberFormValue && !phoneNumberFromProps) ||
        !password
      ) {
        setToastMessage('Missing input.')
        setShowToast(true)
        return
      }

      const savedFormData = getObj<SignUpFormData>(
        GlobalCacheDataKey.SIGN_UP_FORM_DATA,
        true,
      )
      setIsProcessingSignUp(true)
      setIsShowingProcessingPopover(true)
      const variables: SignUpVariables = {
        input: {
          fullName: ((fullNameFormValue || fullNameFromProps) as string).trim(),
          phoneNumber: ((phoneNumberFormValue || phoneNumberFromProps) as string).trim(),
          password: (passwordFormValue as string).trim(),
          pushNotificationToken,
        },
      }
      if (usernameFormValue) {
        variables.input.username = usernameFormValue.trim()
      }
      if (emailFormValue || emailFromProps) {
        variables.input.email = ((emailFormValue || emailFromProps) as string).trim()
      }
      if (
        inviteCodeFormValue ||
        receivedInviteCode ||
        (savedFormData && savedFormData.inviteCode)
      ) {
        variables.input.inviteCode = ((
          inviteCodeFormValue ||
          receivedInviteCode ||
          (savedFormData && savedFormData.inviteCode)
        ) as string).trim()
      }

      if (purchaseTransferId || (savedFormData && savedFormData.purchaseTransferId)) {
        variables.input.purchaseTransferId = (
          purchaseTransferId ||
          (savedFormData && savedFormData.purchaseTransferId) ||
          undefined
        )
      }
      if (receivedPurchaseId || (savedFormData && savedFormData.receivedPurchaseId)) {
        variables.input.receivedPurchaseId = (
          receivedPurchaseId ||
          (savedFormData && savedFormData.receivedPurchaseId) ||
          undefined
        )
      }
      signUp({ variables }).then(undefined, (error) => {
        console.error(error)
      })
      saveFormData(null)
    }, (error) => {
      console.error(error)
    })
  }

  const onSignInWithFacebook = () => {
    console.log('SignUpForm.onSignInWithFacebook: called.')
    setOAuthProvider(OAuthUserProvider.FACEBOOK)
    setIsWaitingForOAuthUser(true)
    firebase.onSignInWithFacebook()
  }

  const onSignInWithGoogle = () => {
    console.log('SignUpForm.onSignInWithGoogle: called.')
    setOAuthProvider(OAuthUserProvider.GOOGLE)
    setIsWaitingForOAuthUser(true)
    firebase.onSignInWithGoogle()
  }

  const onSignInWithTwitter = () => {
    console.log('SignUpForm.onSignInWithTwitter: called.')
    setOAuthProvider(OAuthUserProvider.TWITTER)
    setIsWaitingForOAuthUser(true)
    firebase.onSignInWithTwitter()
  }

  const onOkFailedToSendMfaNotificationAlert = () => {
    setIsShowingFailedToSendMfaNotificationAlert(false)
    if (phoneNumberRef.current) {
      phoneNumberRef.current.setFocus()
    }
  }

  const onToggleShowClearPassword = () => {
    setShowClearPassword(!showClearPassword)
  }

  const onShowInviteCodeInput = (): void => {
    console.log('onShowInviteCodeInput called.')
    setShowInviteCodeInput(true)
    saveFormData({ ...getFormData(), showInviteCodeInput: true })
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('SignUpForm.render called.', {
  //   isAppActive,
  //   phoneNumberStatus,
  //   showTfaCodeInput,
  // })

  let phoneNumberReadyToVerify = !!(phoneNumberFormValue || phoneNumberFromProps)
  if (phoneNumberReadyToVerify) {
    const { valid } = validatePhoneNumber(
      phoneNumber || pageHelpers.getFormInputValue(phoneNumberRef) || phoneNumberFromProps,
      false,
    )
    phoneNumberReadyToVerify = valid
  }

  let oAuthButtons: JSX.Element | undefined
  if (enableFirebase) {
    oAuthButtons = (
      <>
        <div className='section'>
          <div className='sectionCaption'>Use an existing account</div>
          <OAuthButtons
            onSignInWithFacebook={onSignInWithFacebook}
            onSignInWithGoogle={onSignInWithGoogle}
            onSignInWithTwitter={onSignInWithTwitter}
          />
        </div>
        <div className='sectionCaption'>Or join with</div>
      </>
    )
  }

  let usernameSection
  if (showUsernameInput || username || availableUsername) {
    if (showUsernameInput) {
      usernameSection = (
        <FormItem
          key='username'
          label='Handle'
          validationError={usernameValidationError}
          withBottomMargin
        >
          <div className='rowWithCenterAlignedItems'>
            <span className='handleGlyph'>@</span>
            <IonInput
              ref={usernameRef}
              name={IdentType.USERNAME}
              type='text'
              placeholder='JoeTheSailor'
              value={username}
              autocomplete='username'
              onIonChange={onChangeUsername}
              onIonInput={onChangeUsername}
              onIonBlur={onBlurUsername}
            />
          </div>
        </FormItem>
      )
    }
  }

  let emailSection
  if (showEmailInput) {
    emailSection = (
      <>
        <FormItem
          label='Email Address (optional)'
          validationError={emailValidationError}
        >
          <IonInput
            ref={emailRef}
            name={IdentType.EMAIL}
            type='email'
            inputmode='email'
            value={email || emailFromProps}
            placeholder='email@example.com'
            autocomplete='email'
            onIonChange={onChangeEmail}
            onIonInput={onChangeEmail}
            onIonBlur={onBlurEmail}
          />
        </FormItem>
        <div className='smallText lightText withDoubleBottomMargin'>
          Your email address is kept confidential and we never share it
          with any third party organizations. Enter it to have another
          way to recover a lost password.
        </div>
      </>
    )
  }

  let inviteCodeInputSection
  if (showInviteCodeInput) {
    inviteCodeInputSection = (
      <FormItem
        key='inviteCode'
        label='Invite code (if you have one)'
        validationError={inviteCodeValidationError}
        withBottomMargin
      >
        <div className='rowWithCenterAlignedItems'>
          <IonInput
            ref={inviteCodeRef}
            name='inviteCode'
            value={inviteCode === null ? '' : inviteCode || receivedInviteCode}
            placeholder='invite code'
            minlength={3}
            maxlength={30}
            onIonChange={onChangeInviteCode}
            // onIonBlur={onBlurInviteCode}
            onSubmit={(): void => {
              if (enableSubmitButton) {
                onSubmit()
              }
            }}
          />
          <VerifyInputIndicator
            status={inviteCodeStatus}
            disabled={!enableValidateInviteCode}
            onClick={onVerifyInviteCode}
          />
        </div>
      </FormItem>
    )
  } else if (
    !inviteCode &&
    !receivedInviteCode &&
    !receivedPurchaseId &&
    !purchaseTransferId
  ) {
    inviteCodeInputSection = (
      <IonButton
        fill='clear'
        className='g-text-paragraph-button'
        onClick={onShowInviteCodeInput}
      >
        I have an invite code!
      </IonButton>
    )
  }

  let tfaInputSection
  if (showTfaCodeInput) {
    tfaInputSection = (
      <div className='tfa-input-section'>
        <div className='withStandardBottomMargin'>
          We sent a text message with a 6-digit confirmation code to&nbsp;
          <span className='withBoldText'>{coreHelpers.ui.formatPhoneNumber(phoneNumber)}</span>.
          Please enter it here.
        </div>
        <FormItem
          key='phoneVerificationCode'
          label='6-digit code'
          validationError={tfaCodeValidationError}
        >
          <div className='rowWithCenterAlignedItems'>
            <IonInput
              ref={tfaCodeRef}
              value={tfaCode}
              type='number'
              inputmode='numeric'
              pattern='[0-9]{6}'
              placeholder='000000'
              autocomplete='one-time-code'
              minlength={6}
              maxlength={6}
              required
              className='input'
              onIonChange={onChangeTfaCode}
              onIonBlur={onBlurTfaCode}
            />
            <VerifyInputIndicator
              status={getInputVerificationStatusFromTfaStatus(phoneNumberStatus)}
              // disabled={!enableValidatephoneVerificationCode}
              onClick={() => onVerifyTfaCode()}
            />
          </div>
        </FormItem>
        <div className='smallText'>
          Did not receive a text message? <span className='linkText' onClick={onVerifyPhoneNumber}>Send again</span>.
        </div>
      </div>
    )
  }

  let invitedBySection
  if (invitedBy) {
    if (invitedBy.imageUrl) {
      invitedBySection = (
        <div className='withStandardBottomMargin'>
          <div className='smallText lightText withSmallBottomMargin'>
            You&apos;ve been invited by:
          </div>
          <div className='rowWithCenterAlignedItems'>
            <Avatar
              user={invitedBy as User}
              size={30}
              className='withStandardRightMargin'
            />
            <div>
              {invitedBy.fullName}
            </div>
          </div>
        </div>
      )
    } else {
      invitedBySection = (
        <div className='smallText withStandardBottomMargin'>
          You&apos;ve been invited by: <span className='withBoldText'>{invitedBy.fullName}</span>.
        </div>
      )
    }
  }

  let fullNameHelpText: JSX.Element | undefined
  if (showFullNameHelpText) {
    fullNameHelpText = (
      <div className='smallText lightText withDoubleBottomMargin'>
        Your name is used when you send a gift. Also, loved ones can
        find you when they search for your name, and send you a gift, too!
        You don&apos;t have to enter your legal name here, just what you
        use with friends and family. We don&apos;t share your name with
        anyone, unless you asked us to when sending a gift.
      </div>
    )
  } else {
    fullNameHelpText = (
      <div
        className='smallText lightText withDoubleBottomMargin withPointerCursor'
        onClick={() => { setShowFullNameHelpText(true) }}
      >
        Enter a name your friends and loved ones know you by. <span className='linkText'>see why</span>
      </div>
    )
  }

  let phoneNumberHelpText: JSX.Element | undefined
  if (showPhoneNumberHelpText) {
    phoneNumberHelpText = (
      <div className='smallText lightText withDoubleBottomMargin'>
        Your phone number is kept confidential and we never share it
        with any third party. We will also not call you.
      </div>
    )
  } else {
    phoneNumberHelpText = (
      <div
        className='smallText lightText withDoubleBottomMargin withPointerCursor'
        onClick={() => { setShowPhoneNumberHelpText(true) }}
      >
        Your phone number is safe with us. <span className='linkText'>see why</span>
      </div>
    )
  }

  let verifyPhoneNumberButton: JSX.Element | undefined
  if (
    (phoneNumberFormValue || phoneNumberFromProps) &&
    phoneNumberStatus !== TfaStatus.PASSED &&
    !showTfaCodeInput
  ) {
    verifyPhoneNumberButton = (
        <IonButton
          size='small'
          onClick={onVerifyPhoneNumber}
          disabled={!phoneNumberReadyToVerify}
        >
          Verify Phone Number
        </IonButton>
    )
  }

  const formClassName = showTfaCodeInput ? 'sign-up-form tfa-input-mode' : 'sign-up-form'

  return (
    <form onSubmit={onSubmit} className={formClassName}>
      {oAuthButtons}
      <FormItem label='Your Name' validationError={fullNameValidationError}>
        <IonInput
          ref={fullNameRef}
          name='fullName'
          placeholder='i.e. Joe Smith'
          value={fullName || fullNameFromProps}
          autofocus
          autocapitalize='words'
          autocomplete='name'
          disabled={showTfaCodeInput}
          onIonBlur={onBlurFullName}
          onIonChange={onChangeFullName}
        />
      </FormItem>
      {fullNameHelpText}

      <FormItem
        label='Phone Number'
        successMessage={phoneNumberStatus === TfaStatus.PASSED ? 'Successfully verified' : undefined}
        // successMessage={'Successfully verified'}
        validationError={phoneNumberValidationError}
      >
        <div className='rowWithCenterAlignedItems'>
          <IonInput
            ref={phoneNumberRef}
            name={IdentType.PHONE_NUMBER}
            value={phoneNumber || phoneNumberFromProps}
            placeholder='1234567890'
            type='tel'
            inputmode='tel'
            autocomplete='tel'
            onIonChange={onChangePhoneNumber}
            onIonBlur={onBlurPhoneNumber}
          />
          <VerifyInputIndicator
            status={verifyPhoneNumberStatus}
          />
        </div>
      </FormItem>

      {phoneNumberHelpText}
      {verifyPhoneNumberButton}
      {tfaInputSection}
      {emailSection}
      {usernameSection}

      <FormItem
        key='pwd'
        label='Password'
        className='withDoubleTopMargin'
        validationError={passwordValidationError}
      >
        <div className='rowWithCenterAlignedItems'>
          <IonInput
            ref={passwordRef}
            name='password'
            type={showClearPassword ? 'text' : 'password'}
            value={password}
            placeholder='password'
            minlength={8}
            maxlength={30}
            required
            autocomplete='new-password'
            disabled={showTfaCodeInput}
            onIonChange={onChangePassword}
            onIonBlur={onBlurPassword}
            onSubmit={(): void => {
              if (enableSubmitButton) {
                onSubmit()
              }
            }}
          />
          <IonButton
            size='small'
            fill='clear'
            onClick={onToggleShowClearPassword}
          >
            <IonIcon
              slot='start'
              color='medium'
              icon={showClearPassword ? eyeOffOutline : eyeOutline}
            />
          </IonButton>
        </div>
      </FormItem>
      <div className='smallText lightText withDoubleBottomMargin'>
        Anything goes, but it must be at least 8 characters long.
      </div>

      {inviteCodeInputSection}
      {invitedBySection}

      <div className='lightText smallText withCenteredTextAlign withTripleTopMargin withSmallBottomMargin'>
        By continuing you agree to Mimble&apos;s&nbsp;
        <a href={appConfig.mimblemod.externalLinks.mimbleTermsOfUse} target='_system'>terms of use</a>{' '}
        and&nbsp;
        <a href={appConfig.mimblemod.externalLinks.mimblePrivacyStatementUri} target='_system'>privacy statement</a>.
      </div>

      <div key='buttons' className='withStandardTopMargin withTripleBottomMargin'>
        <SubmitButton
          expand='block'
          isProcessing={isProcessing}
          disabled={!enableSubmitButton}
          onClick={onSubmit}
        >
          Create My Account
        </SubmitButton>
      </div>

      <div className='smallText withCenteredTextAlign withStandardBottomMargin' onClick={onGoToSignIn}>
        Or, if you are already a member
      </div>

      <SubmitButton
        expand='block'
        fill='outline'
        onClick={onGoToSignIn}
      >
        Sign In
      </SubmitButton>

      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      <IonAlert
        isOpen={isShowingExistingAccountAlert}
        onDidDismiss={() => { setIsShowingExistingAccountAlert(false) }}
        header='Membership Account Already Exists'
        // subHeader=''
        message='An account with that information already exists. Please sign in instead.'
        // cssClass='confirm-sign-out-alert'
        buttons={[
          { text: 'Sign In', handler: onGoToSignIn },
          { text: 'Try again', cssClass: 'secondary', handler: () => { setIsShowingExistingAccountAlert(false) } },
        ]}
      />
      <IonAlert
        isOpen={isShowingCreateAccountFailedAlert}
        onDidDismiss={() => { setIsShowingCreateAccountFailedAlert(false) }}
        header='Error'
        message='We failed to create your membership account. Please check your input and try again.'
        buttons={[
          { text: 'Try again', handler: () => { setIsShowingCreateAccountFailedAlert(false) } },
        ]}
      />
      <IonAlert
        isOpen={isShowingFailedToSendMfaNotificationAlert}
        onDidDismiss={() => { setIsShowingFailedToSendMfaNotificationAlert(false) }}
        header='Please Check Phone Number'
        message={`We could not send a text message to ${phoneNumberFormValue} to confirm this number.`}
        buttons={[
          { text: 'OK', handler: onOkFailedToSendMfaNotificationAlert },
        ]}
      />
      <ProcessingOverlay
        caption='Creating Your Account'
        text='Hang on, this should only take a moment...'
        isProcessing={isShowingProcessingPopover}
      />
    </form>
  )
}

export default SignUpForm
