import Moment from 'moment-timezone'

const formatDate = (date: Date | string | number | null | undefined, format = 'M/D/Y'): string => {
  if (!date) {
    return ''
  }
  if (typeof date === 'string' && date.match(/[0-9]{10,16}/)) {
    return Moment(parseInt(date, 10)).format(format)
  }
  return Moment(date).format(format)
}

export default formatDate
