import type { UserRole } from '../../../enums'

const hasRole = (
  userRoles: UserRole | UserRole[] | null | undefined,
  roles: UserRole | UserRole[] | null | undefined,
): boolean => {
  // console.log('lib.core.helpers.models.user.hasRole called', { userRoles, roles })
  if (!roles) {
    return true
  }
  if (!userRoles) {
    return false
  }
  if (Array.isArray(roles)) {
    if (roles.length < 1) {
      return true
    }
    for (let i = 0; i < roles.length; i += 1) {
      if (Array.isArray(userRoles)) {
        if (userRoles.includes(roles[i])) {
          return true
        }
      } else {
        const reg = new RegExp(`\\b${roles[i]}\\b`)
        if (userRoles.match(reg)) {
          return true
        }
      }
    }
    return false
  }
  if (Array.isArray(userRoles)) {
    return userRoles.includes(roles)
  }
  const reg = new RegExp(`\\b${roles}\\b`)
  return !!userRoles.match(reg)
}

export default hasRole
