import { IonList } from '@ionic/react'
import React from 'react'

import './styles.css'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import type { Purchase, PurchaseListItem } from '../../lib/core/definitions'
import type { PurchaseListScope } from '../../lib/core/enums'
import PurchaseListRow from './PurchaseListRow'
import pageHelpers from '../../helpers/pageHelpers'

interface Props {
  purchase?: Purchase;
  purchases?: PurchaseListItem[] | null;
  searchText?: string;
  scope: PurchaseListScope;
  isProcessing?: boolean;
  onOpenPurchase?: (purchaseId: string) => void;
}

const PurchaseList: React.FC<Props> = (props): JSX.Element | null => {
  const { getActiveUserPrefs } = useGlobalCache()
  const {
    purchases,
    scope,
    searchText,
    isProcessing,
    onOpenPurchase,
  } = props

  // console.log('PurchaseList.render called.', purchases, scope, searchText)
  if (!Array.isArray(purchases) || purchases.length < 1) {
    return null
  }
  const userPrefs = getActiveUserPrefs()
  const contrastPennies = userPrefs ? userPrefs.contrastPennies : false

  const items = pageHelpers
    .filterPurchaseList(purchases, searchText, scope)
    .map((p): any => (
      <PurchaseListRow
        key={p.id as string}
        purchase={p}
        contrastPennies={contrastPennies}
        onOpenPurchase={onOpenPurchase}
      />
    ))

  return (
    <IonList>
      {items}
    </IonList>
  )
}

export default PurchaseList
