import getOfferPercentageForAmount from './getOfferPercentageForAmount'
import type { RewardType } from '../../../enums'

type Args = {
  offerRewardType: RewardType;
  offerPercentage: number;
  offerLevels: number;
  purchaseAmount: number;
};

const getMerchantRewardAmountForPurchaseAmount = (args: Args): number => {
  const {
    offerRewardType,
    offerLevels,
    purchaseAmount,
  } = args
  let { offerPercentage } = args
  offerPercentage = getOfferPercentageForAmount({
    offerPercentage,
    offerLevels,
    purchaseAmount,
  })
  const useFixedAmounts = Array.isArray(offerLevels) && offerLevels.length > 0

  // let rewardAmount
  // if (offerRewardType === RewardType.ADDED_TO_GOAL) {
  //   if (useFixedAmounts) {
  //     // TODO: how do fixed amounts affect the reward?
  //     rewardAmount = purchaseAmount - (purchaseAmount / (1 + offerPercentage / 100))
  //   } else {
  //     rewardAmount = purchaseAmount - (purchaseAmount / (1 + offerPercentage / 100))
  //   }
  // } else if (offerRewardType === RewardType.CASHBACK) {
  //   rewardAmount = purchaseAmount * (offerPercentage / 100)
  // }
  // if (rewardAmount && rewardAmount > 0) {
  //   return Math.floor(rewardAmount * 100) / 100
  // }
  return 0
}

export default getMerchantRewardAmountForPurchaseAmount
