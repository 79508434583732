import { GlobalCacheDataKey } from './enum'
import type { LocalPreference } from '../../enums'
import type { EnvironmentVal, User, UserPrefs } from '../../lib/core/definitions'
import type { AppFeature, UserRole } from '../../lib/core/enums'
import coreHelpers from '../../lib/core/helpers'
import env from '../../lib/env'

let environment: EnvironmentVal | undefined
const data = new Map<GlobalCacheDataKey, any>()
let userId: string | null | undefined
let proxyUserId: string | null | undefined
let authToken: string | null | undefined
let prefs: Map<LocalPreference, string> | undefined
let unauthorizedRequestReportedByMimbleApi = false

export const nonSessionDataKeys = [
  GlobalCacheDataKey.DEVICE_ID,
  GlobalCacheDataKey.ENVIRONMENT,
  GlobalCacheDataKey.FCM_TOKEN,
  GlobalCacheDataKey.SIGNED_OUT_USER_ID,
  GlobalCacheDataKey.USER_PREFERENCES,
]

export const unencryptedDataKeys = [
  GlobalCacheDataKey.ENVIRONMENT,
]

const getEnvironment = (): EnvironmentVal => {
  if (!environment) {
    environment = (localStorage.getItem(GlobalCacheDataKey.ENVIRONMENT) || undefined) as EnvironmentVal | undefined
    if (!environment) {
      environment = process.env.REACT_APP_ENVIRONMENT as EnvironmentVal | undefined
      if (!environment) {
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
          environment = process.env.NODE_ENV as EnvironmentVal
        } else {
          environment = 'production'
        }
      }
    }
  }
  // console.log('>>>>>>getEnvironment: returning', environment)
  return environment
}

const setEnvironment = (newEnvironment: EnvironmentVal): void => {
  environment = newEnvironment || 'production'
  if (newEnvironment === process.env.NODE_ENV) {
    localStorage.removeItem(GlobalCacheDataKey.ENVIRONMENT)
  } else {
    localStorage.setItem(GlobalCacheDataKey.ENVIRONMENT, newEnvironment)
  }
}

const getEncryptionKey = (): string | undefined => {
  let encryptionKey: string | undefined
  if (!encryptionKey) {
    encryptionKey = env('ENCRYPTION_KEY', getEnvironment())
  }
  return encryptionKey
}

const clearValue = (key: GlobalCacheDataKey, persist = true): void => {
  data.delete(key)

  if (persist) {
    localStorage.removeItem(key)
  }
}

const clearSessionData = (): void => {
  console.log('GlobalCacheContext.clearSessionData called.')
  Object
    .values(GlobalCacheDataKey)
    .filter(key => !nonSessionDataKeys.includes(key))
    .forEach(key => clearValue(key, true))
  userId = undefined
  proxyUserId = undefined
  authToken = undefined
  unauthorizedRequestReportedByMimbleApi = false
}

const clear = (): void => {
  console.log('GlobalCacheContext.clear called.')
  data.clear()
  clearSessionData()
}

function setObj<T> (key: GlobalCacheDataKey, obj: T | undefined, persist = true): T | undefined {
  if (!obj) {
    clearValue(key, persist)
    return obj
  }

  data.set(key, obj)

  if (persist) {
    const encryptionKey = getEncryptionKey()
    if (unencryptedDataKeys.includes(key) || !encryptionKey) {
      localStorage.setItem(key, JSON.stringify(obj))
    } else {
      localStorage.setItem(key, coreHelpers.crypto.encrypt(JSON.stringify(obj), encryptionKey))
    }
  }

  return obj
}

function getObj<T> (key: GlobalCacheDataKey, persist = true): T | undefined {
  const obj: T | undefined = data.get(key)
  if (obj) {
    return obj
  }

  if (!persist) {
    return
  }

  const encryptionKey = getEncryptionKey()
  const json = localStorage.getItem(key)
  if (json) {
    try {
      let obj: any
      if (unencryptedDataKeys.includes(key) || !encryptionKey) {
        obj = JSON.parse(json)
      } else {
        const clearJson = coreHelpers.crypto.decrypt(json, encryptionKey)
        if (!clearJson) {
          console.log(`globalCache.getObj: failed to decrypt key='${key}'.`, { encryptionKey })
          return
        }
        obj = JSON.parse(clearJson)
      }
      if (obj) {
        data.set(key, obj)
        return obj
      }
    } catch (error) {
      console.error(error)
    }
  }
}

const setValue = (
  key: GlobalCacheDataKey,
  val: string | number | null | undefined,
  persist = false,
): string | number | null | undefined => {
  if (!val) {
    clearValue(key, persist)
    return val
  }

  data.set(key, val)

  if (persist) {
    const encryptionKey = getEncryptionKey()
    if (unencryptedDataKeys.includes(key) || !encryptionKey) {
      localStorage.setItem(key, typeof val === 'string' ? val : val.toString())
    } else {
      localStorage.setItem(
        key,
        coreHelpers.crypto.encrypt(typeof val === 'string' ? val : val.toString(), encryptionKey),
      )
    }
  }

  return val
}

const getValue = (key: GlobalCacheDataKey, persist = false): string | undefined => {
  const val: string | undefined = data.get(key)
  if (val) {
    return val
  }

  if (!persist) {
    return
  }

  const sval = localStorage.getItem(key)
  if (!sval) {
    return
  }
  const encryptionKey = getEncryptionKey()
  if (!encryptionKey || unencryptedDataKeys.includes(key)) {
    data.set(key, sval)
    return sval
  }
  const clearVal = coreHelpers.crypto.decrypt(sval, encryptionKey)

  if (!clearVal) {
    console.error(`globalCache.getValue: failed to decrypt key='${key}'.`, { encryptionKey })
    return
  }

  data.set(key, clearVal)
  return clearVal
}

const getIntValue = (key: GlobalCacheDataKey, persist = false): number | undefined => {
  const val: number | undefined = data.get(key)
  if (val) {
    return val
  }

  if (!persist) {
    return
  }

  const sval = localStorage.getItem(key)
  if (sval) {
    const nval = parseInt(sval)
    if (nval) {
      data.set(key, nval)
      return nval
    }
  }
}

const getDeviceId = (): string => {
  const deviceId = getValue(GlobalCacheDataKey.DEVICE_ID, true)

  if (deviceId) {
    return deviceId
  }

  const newDeviceId = coreHelpers.string.generateUuidV4(false)
  return setValue(GlobalCacheDataKey.DEVICE_ID, newDeviceId, true) as string
}

const clearAuthUser = (): void => {
  clearValue(GlobalCacheDataKey.USER, true)
  authToken = undefined
  userId = undefined
}

const clearOAuthProvider = (): void => {
  clearValue(GlobalCacheDataKey.O_AUTH_PROVIDER, true)
}

const clearProxyUser = (): void => {
  clearValue(GlobalCacheDataKey.SIGN_IN_AS_USER, true)
  proxyUserId = undefined
}

const setAuthUser = (user: Partial<User>): void => {
  // console.log('globalCache.setAuthUser called.', { user })
  const prevUser = getObj<Partial<User>>(GlobalCacheDataKey.USER)
  authToken = user.authToken || (prevUser && prevUser.authToken)
  setObj(GlobalCacheDataKey.USER, {
    id: user.id,
    username: user.username || '',
    fullName: user.fullName || '',
    email: user.email || '',
    phoneNumber: user.phoneNumber || '',
    roles: coreHelpers.models.user.getRolesAsArray(user.roles),
    authToken,
    appFeatures: coreHelpers.models.user.getAppFeaturesAsArray(user.appFeatures),
    prefs: user.prefs,
  })
  userId = user.id
  clearValue(GlobalCacheDataKey.SIGNED_OUT_USER_ID)
  unauthorizedRequestReportedByMimbleApi = false
}

const setProxyUser = (user: Partial<User>): void => {
  setObj(GlobalCacheDataKey.SIGN_IN_AS_USER, {
    id: user.id,
    username: user.username || '',
    fullName: user.fullName || '',
    email: user.email || '',
    phoneNumber: user.phoneNumber || '',
    roles: coreHelpers.models.user.getRolesAsArray(user.roles),
    appFeatures: coreHelpers.models.user.getAppFeaturesAsArray(user.appFeatures),
    prefs: user.prefs,
  })
  proxyUserId = user.id
  unauthorizedRequestReportedByMimbleApi = false
}

const getActiveUser = (): Partial<User> | undefined => (
  getObj<Partial<User>>(GlobalCacheDataKey.SIGN_IN_AS_USER) ||
  getObj<Partial<User>>(GlobalCacheDataKey.USER)
)

const getPrefs = (): Map<LocalPreference, string> => {
  if (!prefs) {
    prefs = getObj<Map<LocalPreference, string>>(GlobalCacheDataKey.USER_PREFERENCES) || new Map<LocalPreference, string>()
  }
  return prefs
}

const getAuthUserId = (): string | null | undefined => {
  if (!userId) {
    const user = getObj<Partial<User>>(GlobalCacheDataKey.USER)
    if (user && user.id) {
      userId = user.id
    }
  }
  return userId
}

const getProxyUserId = (): string | null | undefined => {
  if (!proxyUserId) {
    const proxyUser = getObj<Partial<User>>(GlobalCacheDataKey.SIGN_IN_AS_USER)
    if (proxyUser && proxyUser.id) {
      proxyUserId = proxyUser.id
    }
  }
  return proxyUserId
}

const getAuthToken = (): string | null | undefined => {
  if (!authToken) {
    const user = getObj<Partial<User>>(GlobalCacheDataKey.USER)
    if (user && user.authToken) {
      ({ authToken } = user)
    }
  }
  // console.log('globalCache.getAuthToken: returning', authToken)
  return authToken
}

const init = (): void => {
  getEnvironment()
  getAuthUserId()
  getProxyUserId()
  getActiveUser()
  getAuthToken()
  getDeviceId()
  getPrefs()
  // getObj(GlobalCacheDataKey.SIGN_IN_AS_USER, true)
}

const contextData = {
  init,
  getEnvironment,
  setEnvironment,

  clearValue,
  clearSessionData,
  clear,
  getObj,
  setObj,
  setValue,
  getValue,
  getIntValue,
  getDeviceId,
  clearAuthUser,
  clearOAuthProvider,
  clearProxyUser,
  setAuthUser,
  setProxyUser,
  getActiveUser,
  getPrefs,

  getActiveUserId: (): string | null | undefined => {
    if (userId || proxyUserId) {
      return proxyUserId || userId
    }
    const user = getActiveUser()
    return user && user.id
  },

  getActiveUserIdent: (): string | undefined => {
    const user = getActiveUser()
    return user && (user.username || user.email || user.phoneNumber)
      ? (user.username || user.email || user.phoneNumber) as string
      : undefined
  },

  getActiveUserUsername: (): string | undefined => {
    const activeUser = getActiveUser()
    return activeUser && activeUser.username ? activeUser.username : undefined
  },

  getActiveUserFullName: (): string | undefined => {
    const user = getActiveUser()
    return user && user.fullName ? user.fullName : undefined
  },

  getActiveUserEmail: (): string | undefined => {
    const user = getActiveUser()
    return user && user.email ? user.email : undefined
  },

  getActiveUserPhoneNumber: (): string | undefined => {
    const user = getActiveUser()
    return user && user.phoneNumber ? user.phoneNumber : undefined
  },

  getAuthToken,

  getOAuthProvider: (): string | undefined => {
    return getValue(GlobalCacheDataKey.O_AUTH_PROVIDER)
  },

  getActiveUserRoles: (): UserRole[] | undefined => {
    if (!authToken) {
      return
    }
    const user = getActiveUser()
    return user && user.roles ? user.roles as UserRole[] : undefined
  },

  getActiveUserHasRole: (role: UserRole): boolean => {
    if (!authToken) {
      return false
    }
    const activeUser = getActiveUser()
    if (!activeUser || !Array.isArray(activeUser.roles) || activeUser.roles.length < 1) {
      return false
    }
    return activeUser.roles.includes(role)
  },

  getActiveUserAppFeatures: (): AppFeature[] | undefined => {
    const user = getActiveUser()
    return user && user.appFeatures ? user.appFeatures as AppFeature[] : undefined
  },

  getActiveUserPrefs: (): UserPrefs | undefined => {
    const user = getActiveUser()
    return user && user.prefs ? user.prefs : undefined
  },

  getAuthUser: (): Partial<User> | undefined => getObj<Partial<User>>(GlobalCacheDataKey.USER),

  getAuthUserId,

  getAuthUserUsername: (): string | null | undefined => {
    const user = getObj<Partial<User>>(GlobalCacheDataKey.USER)
    return user && user.username
  },

  getAuthUserFullName: (): string | null | undefined => {
    const user = getObj<Partial<User>>(GlobalCacheDataKey.USER)
    return user && user.fullName
  },

  getAuthUserRoles: (): UserRole[] | null | undefined => {
    const user = getObj<Partial<User>>(GlobalCacheDataKey.USER)
    return user && user.roles ? user.roles as UserRole[] : undefined
  },

  getProxyUser: (): Partial<User> | undefined => getObj<Partial<User>>(GlobalCacheDataKey.SIGN_IN_AS_USER),

  getProxyUserId,

  getIsSignedIn: (): boolean => !!authToken && !unauthorizedRequestReportedByMimbleApi,

  setPreference: (key: LocalPreference, value: string): void => {
    // console.log('globalCache.setPreference called.', { key, value })
    const p = getPrefs()
    if (p && p.set) {
      p.set(key, value)
      setObj(GlobalCacheDataKey.USER_PREFERENCES, p)
    }
  },

  getPreference: (key: LocalPreference): string | undefined => {
    const p = getPrefs()
    return p && p.get && p.get(key)
  },

  getPreferenceFlag: (key: LocalPreference, defaultValue: boolean): boolean => {
    const p = getPrefs()
    if (!p || !p.get) {
      return defaultValue
    }
    const val = p.get(key)
    if (!val) {
      return defaultValue
    }
    if (val === 'true') {
      return true
    }
    if (val === 'false') {
      return false
    }
    return defaultValue
  },

  setUnauthorizedRequestReportedByMimbleApi: (active: boolean): void => {
    // console.log('GlobalCacheContext.contextData.setUnauthorizedRequestReportedByMimbleApi called.', active)
    unauthorizedRequestReportedByMimbleApi = active
  },

  getUnauthorizedRequestReportedByMimbleApi: () => unauthorizedRequestReportedByMimbleApi,
}

export default contextData
