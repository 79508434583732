import type {
  PurchasesByScopeCounts,
} from '../../lib/core/definitions'

export const DEFAULT_PURCHASES_BY_SCOPE_COUNTS = {
  activeCnt: 0,
  pendingCnt: 0,
  receivedCnt: 0,
  sentCnt: 0,
  transferredCnt: 0,
  archivedCnt: 0,
}

const comparePurchasesByScopeCount = (
  counts1: PurchasesByScopeCounts,
  counts2: PurchasesByScopeCounts,
): boolean => (
  ((!!counts1 && !!counts2) || (!!counts1 && !!counts2)) &&
  counts1.activeCnt === counts2.activeCnt &&
  counts1.pendingCnt === counts2.pendingCnt &&
  counts1.receivedCnt === counts2.receivedCnt &&
  counts1.sentCnt === counts2.sentCnt &&
  counts1.transferredCnt === counts2.transferredCnt &&
  counts1.archivedCnt === counts2.archivedCnt
)

export default comparePurchasesByScopeCount
