import type { ApolloClient } from '@apollo/client'
import { InMemoryCache } from '@apollo/client'
// import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
// import type { ApolloClient } from 'apollo-client'
// import { persistCache } from 'apollo-cache-persist'

// Generated by Fragment Matcher plugin
// see https://graphql-code-generator.com/docs/plugins/fragment-matcher
import introspectionResult from './types.gen'

import type { EnvironmentVal } from '../../lib/core/definitions'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import init from './init'
import resetLocalCache from './resetLocalCache'
import serviceData from './serviceData'

// Queries:
import activeUserQuery from './gql/queries/activeUser'
import adminUserQuery from './gql/admin/queries/user'
import asyncTaskQuery from './gql/queries/asyncTask'
import availableUsernameQuery from './gql/queries/availableUsername'
import campaignQuery from './gql/queries/campaign'
import campaignsQuery from './gql/queries/campaigns'
import chatMessageQuery from './gql/queries/chatMessage'
import chatMessagesQuery from './gql/queries/chatMessages'
import chatQuery from './gql/queries/chat'
import chatsQuery from './gql/queries/chats'
import chatWithoutMessagesQuery from './gql/queries/chatWithoutMessages'
import contactEventQuery from './gql/queries/contactEvent'
import contactEventReminderQuery from './gql/queries/contactEventReminder'
import contactEventRemindersQuery from './gql/queries/contactEventReminders'
import contactEventsQuery from './gql/queries/contactEvents'
import contactQuery from './gql/queries/contact'
import contactsQuery from './gql/queries/contacts'
import contactUsersQuery from './gql/queries/contactUsers'
import donationTargetsQuery from './gql/queries/donationTargets'
import favoriteOrganizationQuery from './gql/queries/favoriteOrganization'
import favoriteOrganizationsQuery from './gql/queries/favoriteOrganizations'
import findUserQuery from './gql/queries/findUser'
import inboxItemsQuery from './gql/queries/inboxItems'
import inboxQuery from './gql/queries/inbox'
import invitationQuery from './gql/queries/invitation'
import invitationsQuery from './gql/queries/invitations'
import marketplaceProductsQuery from './gql/queries/marketplaceProducts'
import merchantQuery from './gql/queries/merchant'
import merchantsQuery from './gql/queries/merchants'
import newPurchaseQuery from './gql/queries/newPurchase'
import orderQuery from './gql/queries/order'
import ordersQuery from './gql/queries/orders'
import orgChatsQuery from './gql/queries/orgChats'
import productOptionQuery from './gql/queries/productOption'
import productQuery from './gql/queries/product'
import productsQuery from './gql/queries/products'
import purchaseQuery from './gql/queries/purchase'
import purchasesQuery from './gql/queries/purchases'
import purchaseTransferQuery from './gql/queries/purchaseTransfer'
import purchaseTransfersQuery from './gql/queries/purchaseTransfers'
import purchaseTransferUnauthorizedQuery from './gql/queries/purchaseTransferUnauthorized'
import reportQuery from './gql/queries/report'
import rewardConfigsQuery from './gql/queries/rewardConfigs'
import rewardQuery from './gql/queries/reward'
import rewardsQuery from './gql/queries/rewards'
import shoppingCartQuery from './gql/queries/shoppingCart'
import shoppingCartsQuery from './gql/queries/shoppingCarts'
import storedProductsQuery from './gql/admin/queries/storedProducts'
import storedProductQuery from './gql/admin/queries/storedProduct'
import memberMessageTemplateQuery from './gql/queries/memberMessageTemplate'
import memberMessageTemplatesQuery from './gql/queries/memberMessageTemplates'
import tagQuery from './gql/queries/tag'
import tagsQuery from './gql/queries/tags'
import transactionQuery from './gql/queries/transaction'
import transactionsQuery from './gql/queries/transactions'
import unreadInAppMessagesQuery from './gql/queries/unreadInAppMessages'
import userDeviceQuery from './gql/queries/userDevice'
import userDevicesQuery from './gql/queries/userDevices'
import userQuery from './gql/queries/user'
import userSensitiveInfoQuery from './gql/queries/userSensitiveInfo'
import usersQuery from './gql/queries/users'
import walletQuery from './gql/queries/wallet'
import walletsQuery from './gql/queries/wallets'
import wishesQuery from './gql/queries/wishes'
import wishQuery from './gql/queries/wish'
// Mutations:
import acceptReceivedPurchaseMutation from './gql/mutations/acceptReceivedPurchase'
import addTagToProductMutation from './gql/mutations/addTagToProduct'
import addUploadedPurchaseMutation from './gql/mutations/addUploadedPurchase'
import cancelPurchaseTransferMutation from './gql/mutations/cancelPurchaseTransfer'
import createTagMutation from './gql/mutations/createTag'
import declinePurchaseTransferMutation from './gql/mutations/declinePurchaseTransfer'
import deleteContactEventMutation from './gql/mutations/deleteContactEvent'
import deleteContactEventReminderMutation from './gql/mutations/deleteContactEventReminder'
import deleteFavoriteOrganizationMutation from './gql/mutations/deleteFavoriteOrganization'
import deleteInboxItemMutation from './gql/mutations/deleteInboxItem'
import deletePurchaseMutation from './gql/mutations/deletePurchase'
import deleteTagFromProductMutation from './gql/mutations/deleteTagFromProduct'
import deleteTagMutation from './gql/mutations/deleteTag'
import deleteWishMutation from './gql/mutations/deleteWish'
import executeAdminTaskMutation from './gql/mutations/executeAdminTask'
import markChatMessagesReceivedMutation from './gql/mutations/markChatMessagesReceived'
import markInAppMessageReceivedMutation from './gql/mutations/markInAppMessageReceived'
import sendAsyncTaskNotificationMutation from './gql/mutations/sendAsyncTaskNotification'
import sendPurchaseTransferNotificationMutation from './gql/mutations/sendPurchaseTransferNotification'
import signInMutation from './gql/mutations/signIn'
import signInOAuthUserMutation from './gql/mutations/signInOAuthUser'
import signOutMutation from './gql/mutations/signOut'
import signUpMutation from './gql/mutations/signUp'
import signUpOAuthUserMutation from './gql/mutations/signUpOAuthUser'
import updateTagMutation from './gql/mutations/updateTag'
import updateUserMutation from './gql/mutations/updateUser'
import upsertAsyncTaskMutation from './gql/mutations/upsertAsyncTask'
import upsertCampaignMutation from './gql/mutations/upsertCampaign'
import upsertChatMessageMutation from './gql/mutations/upsertChatMessage'
import upsertChatMutation from './gql/mutations/upsertChat'
import upsertContactMutation from './gql/mutations/upsertContact'
import upsertContactEventMutation from './gql/mutations/upsertContactEvent'
import upsertContactEventReminderMutation from './gql/mutations/upsertContactEventReminder'
import upsertFavoriteOrganizationMutation from './gql/mutations/upsertFavoriteOrganization'
import upsertInvitationMutation from './gql/mutations/upsertInvitation'
import upsertProductMutation from './gql/mutations/upsertProduct'
import upsertProductOptionMutation from './gql/admin/mutations/upsertProductOption'
import upsertOrderMutation from './gql/mutations/upsertOrder'
import upsertPurchaseMutation from './gql/mutations/upsertPurchase'
import upsertPurchaseTransferMutation from './gql/mutations/upsertPurchaseTransfer'
import upsertShoppingCartMutation from './gql/mutations/upsertShoppingCart'
import upsertStoredProductMutation from './gql/admin/mutations/upsertStoredProduct'
import upsertRewardMutation from './gql/mutations/upsertReward'
import upsertMemberMessageTemplateMutation from './gql/mutations/upsertMemberMessageTemplate'
import upsertTransactionMutation from './gql/mutations/upsertTransaction'
import upsertMerchantMutation from './gql/mutations/upsertMerchant'
import upsertWishMutation from './gql/mutations/upsertWish'
import upsertUserDeviceMutation from './gql/mutations/upsertUserDevice'
import upsertUserSensitiveInfoMutation from './gql/mutations/upsertUserSensitiveInfo'
import verifyAsyncTaskTokenMutation from './gql/mutations/verifyAsyncTaskToken'

// Subscriptions:
import chatMessageCreatedSubscription from './gql/subscriptions/chatMessageCreated'
import chatMessageUpdatedSubscription from './gql/subscriptions/chatMessageUpdated'
import inboxUpdatedSubscription from './gql/subscriptions/inboxUpdated'
import modelChangedSubscription from './gql/subscriptions/modelChanged'

// Filters:
import getCampaignListFilter from './filters/getCampaignListFilter'
import getCampaignsQueryVariables from './filters/getCampaignsQueryVariables'
import getChatListFilter from './filters/getChatListFilter'
import getChatsQueryVariables from './filters/getChatsQueryVariables'
import getContactUserListFilter from './filters/getContactUserListFilter'
import getContactUsersQueryVariables from './filters/getContactUsersQueryVariables'
import getFavoriteOrganizationsQueryVariables from './filters/getFavoriteOrganizationsQueryVariables'
import getFavoriteOrganizationListFilter from './filters/getFavoriteOrganizationListFilter'
import getInvitationListFilter from './filters/getInvitationListFilter'
import getInvitationsQueryVariables from './filters/getInvitationsQueryVariables'
import getMemberMessageTemplateListFilter from './filters/getMemberMessageTemplateListFilter'
import getMemberMessageTemplatesQueryVariables from './filters/getMemberMessageTemplatesQueryVariables'
import getMerchantListFilter from './filters/getMerchantListFilter'
import getMerchantsQueryVariables from './filters/getMerchantsQueryVariables'
import getOrderListFilter from './filters/getOrderListFilter'
import getOrdersQueryVariables from './filters/getOrdersQueryVariables'
import getProductListFilter from './filters/getProductListFilter'
import getProductsQueryVariables from './filters/getProductsQueryVariables'
import getPurchaseListFilter from './filters/getPurchaseListFilter'
import getPurchaseTransferListFilter from './filters/getPurchaseTransferListFilter'
import getPurchaseTransfersQueryVariables from './filters/getPurchaseTransfersQueryVariables'
import getPurchasesQueryVariables from './filters/getPurchasesQueryVariables'
import getRewardListFilter from './filters/getRewardListFilter'
import getRewardsQueryVariables from './filters/getRewardsQueryVariables'
import getShoppingCartListFilter from './filters/getShoppingCartListFilter'
import getShoppingCartsQueryVariables from './filters/getShoppingCartsQueryVariables'
import getStoredProductListFilter from './filters/getStoredProductListFilter'
import getStoredProductsQueryVariables from './filters/getStoredProductsQueryVariables'
import getTransactionListFilter from './filters/getTransactionListFilter'
import getTransactionsQueryVariables from './filters/getTransactionsQueryVariables'
import getUnreadInAppMessagesQueryVariables from './filters/getUnreadInAppMessagesQueryVariables'
import getUserDeviceListFilter from './filters/getUserDeviceListFilter'
import getUserDevicesQueryVariables from './filters/getUserDevicesQueryVariables'
import getUserListFilter from './filters/getUserListFilter'
import getUsersQueryVariables from './filters/getUsersQueryVariables'
import getWalletListFilter from './filters/getWalletListFilter'
import getWalletsQueryVariables from './filters/getWalletsQueryVariables'
import getWishesOfActiveUserListFilter from './filters/getWishesOfActiveUserListFilter'
import getWishesOfActiveUserQueryVariables from './filters/getWishesOfActiveUserQueryVariables'
import getWishesQueryVariables from './filters/getWishesQueryVariables'
import getWishListFilter from './filters/getWishListFilter'

const index = {
  getApolloClient: (environment?: EnvironmentVal): ApolloClient<any> | undefined => {
    const selectedEnvironment = environment || globalCacheData.getEnvironment()
    // console.log('services.apollo.getApolloClient called with environment=', selectedEnvironment)
    return serviceData.getClient(environment || 'production')
  },

  init,
  resetLocalCache,

  queries: {
    activeUser: activeUserQuery,
    asyncTask: asyncTaskQuery,
    availableUsername: availableUsernameQuery,
    campaign: campaignQuery,
    campaigns: campaignsQuery,
    chat: chatQuery,
    chatMessage: chatMessageQuery,
    chatMessages: chatMessagesQuery,
    chats: chatsQuery,
    chatWithoutMessages: chatWithoutMessagesQuery,
    contact: contactQuery,
    contactEvent: contactEventQuery,
    contactEventReminder: contactEventReminderQuery,
    contactEventReminders: contactEventRemindersQuery,
    contactEvents: contactEventsQuery,
    contacts: contactsQuery,
    contactUsers: contactUsersQuery,
    donationTargets: donationTargetsQuery,
    favoriteOrganization: favoriteOrganizationQuery,
    favoriteOrganizations: favoriteOrganizationsQuery,
    findUser: findUserQuery,
    inbox: inboxQuery,
    inboxItems: inboxItemsQuery,
    invitation: invitationQuery,
    invitations: invitationsQuery,
    marketplaceProducts: marketplaceProductsQuery,
    newPurchase: newPurchaseQuery,
    order: orderQuery,
    orders: ordersQuery,
    product: productQuery,
    productOption: productOptionQuery,
    products: productsQuery,
    purchase: purchaseQuery,
    purchases: purchasesQuery,
    purchaseTransfer: purchaseTransferQuery,
    purchaseTransfers: purchaseTransfersQuery,
    purchaseTransferUnauthorized: purchaseTransferUnauthorizedQuery,
    reward: rewardQuery,
    rewardConfigs: rewardConfigsQuery,
    rewards: rewardsQuery,
    shoppingCart: shoppingCartQuery,
    shoppingCarts: shoppingCartsQuery,
    memberMessageTemplate: memberMessageTemplateQuery,
    memberMessageTemplates: memberMessageTemplatesQuery,
    tag: tagQuery,
    tags: tagsQuery,
    transaction: transactionQuery,
    transactions: transactionsQuery,
    unreadInAppMessages: unreadInAppMessagesQuery,
    userDevice: userDeviceQuery,
    userDevices: userDevicesQuery,
    user: userQuery,
    userSensitiveInfo: userSensitiveInfoQuery,
    merchant: merchantQuery,
    merchants: merchantsQuery,
    wallet: walletQuery,
    wallets: walletsQuery,
    wish: wishQuery,
    wishes: wishesQuery,
  },

  mutations: {
    acceptReceivedPurchase: acceptReceivedPurchaseMutation,
    addUploadedPurchase: addUploadedPurchaseMutation,
    cancelPurchaseTransfer: cancelPurchaseTransferMutation,
    declinePurchaseTransfer: declinePurchaseTransferMutation,
    deleteContactEvent: deleteContactEventMutation,
    deleteContactEventReminder: deleteContactEventReminderMutation,
    deleteFavoriteOrganization: deleteFavoriteOrganizationMutation,
    deleteInboxItem: deleteInboxItemMutation,
    deletePurchase: deletePurchaseMutation,
    deleteWish: deleteWishMutation,
    markChatMessageReceived: markChatMessagesReceivedMutation,
    markInAppMessageReceived: markInAppMessageReceivedMutation,
    sendAsyncTaskNotification: sendAsyncTaskNotificationMutation,
    sendPurchaseTransferNotification: sendPurchaseTransferNotificationMutation,
    signIn: signInMutation,
    signInOAuthUser: signInOAuthUserMutation,
    signOut: signOutMutation,
    signUp: signUpMutation,
    signUpOAuthUser: signUpOAuthUserMutation,
    updateUser: updateUserMutation,
    upsertAsyncTask: upsertAsyncTaskMutation,
    upsertCampaign: upsertCampaignMutation,
    upsertChat: upsertChatMutation,
    upsertChatMessage: upsertChatMessageMutation,
    upsertContact: upsertContactMutation,
    upsertContactEvent: upsertContactEventMutation,
    upsertContactEventReminder: upsertContactEventReminderMutation,
    upsertFavoriteOrganization: upsertFavoriteOrganizationMutation,
    upsertInvitation: upsertInvitationMutation,
    upsertOrder: upsertOrderMutation,
    upsertPurchase: upsertPurchaseMutation,
    upsertPurchaseTransfer: upsertPurchaseTransferMutation,
    upsertShoppingCart: upsertShoppingCartMutation,
    upsertTransaction: upsertTransactionMutation,
    upsertUserDevice: upsertUserDeviceMutation,
    upsertUserSensitiveInfo: upsertUserSensitiveInfoMutation,
    upsertWish: upsertWishMutation,
    verifyAsyncTaskToken: verifyAsyncTaskTokenMutation,
  },

  subscriptions: {
    chatMessageCreated: chatMessageCreatedSubscription,
    chatMessageUpdated: chatMessageUpdatedSubscription,
    inboxUpdated: inboxUpdatedSubscription,
    modelChanged: modelChangedSubscription,
  },

  admin: {
    queries: {
      orgChats: orgChatsQuery,
      report: reportQuery,
      storedProduct: storedProductQuery,
      storedProducts: storedProductsQuery,
      user: adminUserQuery,
      users: usersQuery,
    },
    mutations: {
      addTagToProduct: addTagToProductMutation,
      createTag: createTagMutation,
      deleteTag: deleteTagMutation,
      deleteTagFromProduct: deleteTagFromProductMutation,
      executeAdminTask: executeAdminTaskMutation,
      updateTag: updateTagMutation,
      upsertMerchant: upsertMerchantMutation,
      upsertProduct: upsertProductMutation,
      upsertProductOption: upsertProductOptionMutation,
      upsertReward: upsertRewardMutation,
      upsertStoredProduct: upsertStoredProductMutation,
      upsertMemberMessageTemplate: upsertMemberMessageTemplateMutation,
    },
  },

  getCampaignListFilter,
  getCampaignsQueryVariables,
  getChatListFilter,
  getChatsQueryVariables,
  getContactUserListFilter,
  getContactUsersQueryVariables,
  getFavoriteOrganizationsQueryVariables,
  getFavoriteOrganizationListFilter,
  getInvitationListFilter,
  getInvitationsQueryVariables,
  getMemberMessageTemplateListFilter,
  getMemberMessageTemplatesQueryVariables,
  getMerchantListFilter,
  getMerchantsQueryVariables,
  getOrderListFilter,
  getOrdersQueryVariables,
  getProductListFilter,
  getProductsQueryVariables,
  getPurchaseListFilter,
  getPurchasesQueryVariables,
  getPurchaseTransferListFilter,
  getPurchaseTransfersQueryVariables,
  getRewardListFilter,
  getRewardsQueryVariables,
  getShoppingCartListFilter,
  getShoppingCartsQueryVariables,
  getStoredProductListFilter,
  getStoredProductsQueryVariables,
  getTransactionListFilter,
  getTransactionsQueryVariables,
  getUnreadInAppMessagesQueryVariables,
  getUserDeviceListFilter,
  getUserDevicesQueryVariables,
  getUserListFilter,
  getUsersQueryVariables,
  getWalletListFilter,
  getWalletsQueryVariables,
  getWishesOfActiveUserListFilter,
  getWishesOfActiveUserQueryVariables,
  getWishesQueryVariables,
  getWishListFilter,
}

export default index
