import { SystemMessageStatus } from '../../enums'

const labelsEn = Object.freeze({
  [SystemMessageStatus.PLANNED]: 'planned',
  [SystemMessageStatus.ACTIVE]: 'active',
  [SystemMessageStatus.PAUSED]: 'paused',
  [SystemMessageStatus.FINISHED]: 'finished',
})

export default {
  getLabel: (messageType: SystemMessageStatus | null | undefined, language = 'en'): string => {
    if (!messageType) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[messageType]
    }
    return ''
  },
  isValid: (messageType: SystemMessageStatus | string): boolean => (
    Object.values(SystemMessageStatus).includes(messageType as SystemMessageStatus)
  ),
}
