import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import './styles.css'

export type Props = {
  icon: string;
  label: string;
  onClick: () => void;
}

const RoundButton: React.FC<Props> = (props): JSX.Element | null => {
  const { icon, label, onClick } = props

  return (
    <div className='circle-wrapper'>
      <IonButton
        size='small'
        shape='round'
        fill='clear'
        className='action-button'
        onClick={onClick}
      >
        <div className='action-button-inner'>
          <IonIcon icon={icon} className='button-icon' />
          <div className='button-label'>
            {label}
          </div>
        </div>
      </IonButton>
    </div>
  )
}

export default RoundButton
