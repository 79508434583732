import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, Purchase, PurchaseListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  PurchaseQueryData,
  PurchaseQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'

const loadPurchase = (
  purchaseId: string,
  isInTargetStateFunc: ((purchase: Purchase) => boolean) | 'watch-updated-at' | undefined,
  listFilter: PurchaseListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Purchase | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadPurchase called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadPurchase: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<PurchaseQueryVariables> = {
      query: apollo.queries.purchase,
      variables: { purchaseId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<PurchaseQueryData, PurchaseQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.purchase ||
            !resp.data.purchase.id
          ) {
            logger.error('api.loadPurchase.load: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((purchase: Purchase) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.purchase.updatedAt
            activeIsInTargetStateFunc = (purchase: Purchase) => purchase.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.purchase)
          ) {
            const queryListVariables = clientOptions && clientOptions.updateList
              ? apollo.getPurchasesQueryVariables(listFilter, activeUserId)
              : undefined
            // console.log('api.loadPurchase.load: resolving with info.', resp.data.purchase)
            cache.updatePurchase(
              resp.data.purchase,
              queryListVariables,
              activeUserId,
              apolloClient,
            )
            // console.log('api.loadPurchase.load: resolving with info.', resp.data.purchase)
            resolve(resp.data.purchase)
            return
          }

          // console.log('api.loadPurchase.load: purchases not in target state.',
          //   { purchase: resp.data.purchase })
          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadPurchase.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadPurchase.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadPurchase
