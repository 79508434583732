import { gql } from '@apollo/client'

export default gql`
  query Q($filter2: ContactUserListFilter!) {
    contactUsers (filter2: $filter2) {
      id
      userId
      chatId
      contactId
      contactType
      contactStatus
      favorite
      contactMetadata
      username
      fullName
      nickname
      imageUrl
      birthday
      isOrganization
      roles
      latestChatMessage
      latestChatMessageFromUserId
      latestChatMessageCreatedAt
      unreadChatMessageCount
      contactCreatedAt
      contactRecordStatus
      contactUpdatedAt
      userCreatedAt
      userUpdatedAt
      userRecordStatus
#      events {
#        id
#        contactId
#        eventType
#        eventDate
#        name
#        notes
#        adminNotes
#        metadata
#        recordStatus
#        createdAt
#        updatedAt
#      }
    }
  }`
