import React, { useContext, useEffect, useState } from 'react'
import type { ApolloClient, ApolloError } from '@apollo/client'
import { useApolloClient, useQuery, useSubscription } from '@apollo/client'
import { isPlatform } from '@ionic/react'

import type {
  ChatsQueryData,
  ChatsQueryVariables,
  ContactUsersQueryData,
  ContactUsersQueryVariables,
  InboxQueryData,
  InboxQueryVariables,
  ModelChangedSubscriptionData,
  ModelChangedSubscriptionVariables,
  PurchasesQueryData,
  PurchasesQueryVariables,
  UnreadInAppMessagesQueryData,
  UnreadInAppMessagesQueryVariables,
  WalletsQueryData,
  WalletsQueryVariables,
} from '../../services/apollo/definitions'
import type {
  Chat,
  ContactUserListItem,
  Inbox,
  MemberMessage,
  ModelChangedMessage,
  PurchaseListItem,
  RewardConfig,
  User,
  UserInput,
  WalletListItem,
} from '../../lib/core/definitions'
import type { MimbleDataContextValue, ReloadActiveUserOptions } from './definitions'
import { UpdateActiveUserOptions } from './definitions'
import { useGlobalCache } from '../GlobalCacheContext/GlobalCacheContext'
import { ModelChangedCode, ModelChangedMessageType, ModelType, WalletType } from '../../lib/core/enums'
import api from '../../services/api'
import apollo from '../../services/apollo'
import auth from '../../services/auth'
import defaultMimbleDataContextValue from './defaultMimbleDataContextValue'
import logger from '../../services/logger'
import helpers from './helpers'
import capacitor from '../../services/capacitor'

let rewardConfigs: RewardConfig[] | undefined

export const MimbleDataContext = React.createContext<MimbleDataContextValue>(defaultMimbleDataContextValue)

export function useMimbleData (): MimbleDataContextValue {
  return useContext(MimbleDataContext)
}

type Props = {
  onChangeActiveUser?: (activeUser: User | undefined) => void;
}

const MimbleDataContextProvider: React.FC<Props> = (props) => {
  const { children } = props
  // -------------------------------------------------------------------------------------------------------------------
  // State:
  const apolloClient = useApolloClient()
  const { getActiveUserId, getIsSignedIn } = useGlobalCache()
  const [activeUser, setActiveUser] = useState<User | undefined>()
  const activeUserId = getActiveUserId()
  const loadedActiveUserId = activeUser && activeUser.id
  const [isUpdatingActiveUser, setIsUpdatingActiveUser] = useState(false)
  const [isLoadingRewardConfigs, setIsLoadingRewardConfigs] = useState(false)
  const [activeUserUpdatingError, setActiveUserUpdatingError] = useState<ApolloError | undefined>()

  const [activeUserLoadingError, setActiveUserLoadingError] = useState<any | undefined>()
  const [isLoadingActiveUser, setIsLoadingActiveUser] = useState(false)

  const [modelChangedMessage, setModelChangedMessage] = useState<ModelChangedMessage | undefined>()

  const [inbox, setInbox] = useState<Inbox | undefined>()
  // const [listeners, setListeners] = useState(new Map<string, ListenerCallback>())

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // Active User Wallets
  const {
    data: userWalletsData,
    refetch: reloadUserWallets,
    loading: isLoadingActiveUserWallets,
  } = useQuery<WalletsQueryData, WalletsQueryVariables>(
    apollo.queries.wallets, {
      variables: { filter: { userId: activeUserId } },
      skip: !apolloClient || !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
      },
    },
  )
  const activeUserWallets = userWalletsData ? userWalletsData.wallets : undefined

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // Active User
  const reloadActiveUser = async (options?: ReloadActiveUserOptions): Promise<User | undefined> => {
    const activeUserId = getActiveUserId()
    console.log('ActiveUserContext.reloadActiveUser called.', { activeUserId, options })

    if (!activeUserId || !getIsSignedIn()) {
      console.error('ActiveUserContext.reloadActiveUser: activeUserId missing or not signed in.')
      return
    }

    const doItNow = async (): Promise<User | undefined> => {
      if (options && options.delay) {
        console.log('ActiveUserContext.reloadActiveUser: reloading now.', { activeUserId, options })
      }

      let loadedActiveUser: User | null |undefined
      setIsLoadingActiveUser(true)
      setActiveUserLoadingError(undefined)

      try {
        loadedActiveUser = await api.loadActiveUser(
          activeUserId,
          undefined,
          apolloClient,
          undefined,
          undefined,
        )
        setIsLoadingActiveUser(false)
      } catch (error) {
        setIsLoadingActiveUser(false)
        setActiveUserLoadingError(error)
      }

      if (loadedActiveUser) {
        setActiveUser(loadedActiveUser)
        if (props.onChangeActiveUser) {
          props.onChangeActiveUser(loadedActiveUser)
        }
        apolloClient && apolloClient.cache.writeQuery({
          query: apollo.queries.activeUser,
          data: { activeUser: loadedActiveUser },
        })
        auth.setActiveUser(loadedActiveUser)
      } else {
        setActiveUser(undefined)
        if (props.onChangeActiveUser) {
          props.onChangeActiveUser(undefined)
        }
      }

      if (options && (options.reloadContacts || options.reloadAll)) {
        await reloadContacts()
      }

      if (options && (options.reloadChats || options.reloadAll)) {
        await reloadChats()
      }

      if (options && (options.reloadInbox || options.reloadAll)) {
        await reloadInbox()
      }

      if (options && (options.reloadPurchases || options.reloadAll)) {
        await reloadPurchases()
      }

      if (options && (options.reloadUnreadInAppMessages || options.reloadAll)) {
        await reloadUnreadInAppMessages()
      }

      if (options && (options.reloadWallets || options.reloadAll)) {
        await reloadUserWallets()
      }

      return loadedActiveUser || undefined
    }

    if (!options || !options.delay) {
      return doItNow()
    }

    setTimeout(doItNow, options.delay * 1000)
  }

  const reloadActiveUserWallets = async (): Promise<WalletListItem[] | undefined> => {
    // console.log('ActiveUserContext.reloadActiveUserWallets called.')
    const result = await reloadUserWallets()
    if (!result || !result.data) {
      return
    }
    return result.data.wallets
  }

  const getActiveUserMimbleWallet = (): WalletListItem | null => {
    if (!Array.isArray(activeUserWallets) || activeUserWallets.length < 1) {
      return null
    }
    return (activeUserWallets.find(w => w.walletType === WalletType.MIMBLE)) || null
  }

  const clearActiveUser = (): void => {
    // console.log('ActiveUserContext.clearActiveUser called.')
    setActiveUser(undefined)
    if (props.onChangeActiveUser) {
      props.onChangeActiveUser(undefined)
    }
  }

  const updateActiveUser = async (
    userInput: UserInput,
    options?: UpdateActiveUserOptions,
  ): Promise<User | undefined> => {
    console.log('MimbleDataContext.updateActiveUser called.', { userInput, options })
    if (!activeUser) {
      console.error('MimbleDataContext.updateActiveUser: missing activeUser')
      return
    }
    if (!userInput.id) {
      userInput.id = activeUser.id
    }
    try {
      setIsUpdatingActiveUser(true)
      setActiveUserUpdatingError(undefined)
      const oldUpdatedAt = activeUser && activeUser.updatedAt
      await api.updateUser(
        userInput,
        activeUser,
        (u: User) => u.updatedAt !== oldUpdatedAt,
        undefined,
        apolloClient,
      )
      if (!options || options.reloadActiveUser) {
        const reloadedActiveUser = await reloadActiveUser(options && options.reloadOptions)
        console.log('MimbleDataContext.updateActiveUser: reloaded user', { reloadedActiveUser })
        setIsUpdatingActiveUser(false)
        return reloadedActiveUser
      }
    } catch (error) {
      console.error(error)
      setActiveUserUpdatingError(error as ApolloError)
      setIsUpdatingActiveUser(false)
      throw error
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // Chats
  const chatsQueryResult = useQuery<ChatsQueryData, ChatsQueryVariables>(
    apollo.queries.chats, {
      variables: apollo.getChatsQueryVariables(undefined, activeUserId as string),
      skip: !apolloClient || !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      // onCompleted: () => {
      // },
      onError: (error) => {
        logger.info('Error fetching active user', error)
      },
    },
  )
  const {
    data: chatsData,
    error: chatsLoadingError,
    loading: isLoadingChats,
    networkStatus: chatsLoadingNetworkStatus,
    refetch: reloadChatsWithArgs,
  } = chatsQueryResult
  const chats = chatsData ? chatsData.chats : []

  const reloadChats = async (): Promise<Chat[] | undefined> => {
    // console.log('MimbleDataContext.reloadChats called.', { activeUserId })
    if (!activeUserId) {
      return
    }
    const result = await reloadChatsWithArgs(apollo.getChatsQueryVariables(undefined, activeUserId as string))
    // console.log('MimbleDataContext.reloadChats finished.', { result })
    if (!result || !result.data) {
      return
    }
    return result.data.chats
  }

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // Contacts
  const {
    data: contactsData,
    error: contactsLoadingError,
    loading: isLoadingContacts,
    networkStatus: contactsLoadingNetworkStatus,
    refetch: refetchContacts,
  } = useQuery<ContactUsersQueryData, ContactUsersQueryVariables>(
    apollo.queries.contactUsers, {
      variables: apollo.getContactUsersQueryVariables(undefined, activeUserId as string),
      skip: !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      // onCompleted: () => {
      //   if (listeners && listeners.size > 0) {
      //     console.log('ContactsContext.processReceivedActiveUser: calling listeners.')
      //     listeners.forEach(listener => listener(ContactsContextEvent.CONTACTS_LOADED))
      //   }
      // },
      onError: (error) => {
        logger.info('Error fetching active user', error)
      },
    },
  )
  const contacts = contactsData ? contactsData.contactUsers : []

  const reloadContacts = async (): Promise<ContactUserListItem[] | undefined> => {
    // console.log('ContactsContext.reloadContacts called.', { activeUserId })
    if (!activeUserId) {
      return
    }
    // todo: rename back to filter, once we can delete the deprecated filter
    const result = await refetchContacts({
      filter2: {
        fromUserId: activeUserId,
      },
    })
    // console.log('ContactsContext.reloadContacts finished.', { result })
    if (!result || !result.data) {
      return
    }
    // if (listeners && listeners.size > 0) {
    //   console.log('ContactsContext.processReceivedActiveUser: calling listeners.')
    //   listeners.forEach(listener => listener(ContactsContextEvent.CONTACTS_LOADED))
    // }
    return result.data.contactUsers
  }

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // Unread In-App Messages:
  const {
    data: unreadInAppMessagesData,
    error: unreadInAppMessagesLoadingError,
    loading: isLoadingUnreadInAppMessages,
    networkStatus: unreadInAppMessagesLoadingNetworkStatus,
    refetch: refetchUnreadInAppMessages,
  } = useQuery<UnreadInAppMessagesQueryData, UnreadInAppMessagesQueryVariables>(
    apollo.queries.unreadInAppMessages, {
      variables: apollo.getUnreadInAppMessagesQueryVariables(activeUserId as string),
      skip: !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      // onCompleted: () => {
      //   if (listeners && listeners.size > 0) {
      //     console.log('UnreadInAppMessagesContext.processReceivedActiveUser: calling listeners.')
      //     listeners.forEach(listener => listener(UnreadInAppMessagesContextEvent.UnreadInAppMessages_LOADED))
      //   }
      // },
      onError: (error) => {
        logger.info('Error fetching active user', error)
      },
    },
  )
  const unreadInAppMessages = unreadInAppMessagesData ? unreadInAppMessagesData.unreadInAppMessages : []

  const reloadUnreadInAppMessages = async (): Promise<MemberMessage[] | undefined> => {
    // console.log('UnreadInAppMessagesContext.reloadUnreadInAppMessages called.', { activeUserId })
    if (!activeUserId) {
      return
    }
    const result = await refetchUnreadInAppMessages({ userId: activeUserId })
    // console.log('UnreadInAppMessagesContext.reloadUnreadInAppMessages finished.', { result })
    if (!result || !result.data) {
      return
    }
    return result.data.unreadInAppMessages
  }

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // Inbox:
  const receivedNewInbox = (newInbox: Inbox, apolloClient: ApolloClient<any>) => {
    // console.log('MimbleDataContext.receivedNewInbox called.', newInbox)
    setInbox(newInbox)
    helpers.updateCachedInbox(newInbox, apolloClient, activeUserId as string)

    if (isPlatform('capacitor')) {
      if (newInbox.appBadge) {
        // console.log('MimbleDataContext.receivedNewInbox: calling setAppIconBadgeValue.',
        //   newInbox.appBadge)
        capacitor.setAppIconBadgeValue(newInbox.appBadge)
      } else {
        // console.log('MimbleDataContext.receivedNewInbox: calling clearAppIconBadge.')
        capacitor.clearAppIconBadge()
      }
    }

    helpers.reloadUserPurchasesIfNeeded(
      inbox,
      newInbox,
      activeUserId as string,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      reloadPurchases,
      apolloClient,
    )

    if (helpers.shouldReloadContacts(inbox, newInbox)) {
      // console.log('MimbleDataContext.reloading contact list')
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      reloadContacts()
        .then(undefined, (error) => {
          console.error(error)
        })
    }

    // Notifying listeners:
    // if (listeners && listeners.size > 0) {
    //   console.log('MimbleDataContext.receivedNewInbox: calling listeners.')
    //   listeners.forEach(listener => listener(MimbleDataContextEvent.INBOX_UPDATED, {
    //     inbox: newInbox,
    //   }))
    // }
  }

  const deleteInboxItem = (
    itemId: string,
    modelId: string,
    userId: string,
    apolloClient: ApolloClient<any>,
  ): Inbox | undefined => {
    // console.log('MimbleDataContext.deleteInboxItem called.', { itemId, modelId, userId, inbox })

    const updatedInbox = api.cache.deleteInboxItem(
      itemId,
      modelId,
      userId,
      apolloClient,
    )

    if (!updatedInbox) {
      logger.warn('MimbleDataContext.deleteInboxItem: api.cache.deleteInboxItem did not return an inbox.')
      return
    }
    setInbox(updatedInbox)

    return updatedInbox
  }

  const {
    error: inboxLoadingError,
    loading: isLoadingInbox,
    networkStatus: inboxLoadingNetworkStatus,
    refetch: reloadInboxWithArgs,
  } = useQuery<InboxQueryData, InboxQueryVariables>(
    apollo.queries.inbox, {
      client: apolloClient,
      variables: {
        userId: activeUserId as string,
      },
      skip: !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      onCompleted: (data) => {
        receivedNewInbox(data.inbox, apolloClient)
      },
      onError: (error) => {
        console.log('Error fetching active user', error)
      },
    },
  )

  // -------------------------------------------------------------------------------------------------------------------
  // Helpers:
  // const addModelChangeListener = (id: string, fnc: (event: MimbleDataContextEvent, data?: MimbleDataContextListenerData) => void): void => {
  //   console.log('MimbleDataContext.addListener called.', { id })
  //   const newMap = new Map(listeners)
  //   newMap.set(id, fnc)
  //   setListeners(newMap)
  // }
  //
  // const removeModelChangeListener = (id: string): void => {
  //   console.log('MimbleDataContext.removeListener called.', { id })
  //   const newMap = new Map(listeners)
  //   newMap.delete(id)
  //   setListeners(newMap)
  // }

  const reloadInbox = async (): Promise<Inbox | undefined> => {
    console.log('MimbleDataContext.reloadInbox called.', { activeUserId })
    if (!activeUserId) {
      return
    }
    const result = await reloadInboxWithArgs({ userId: activeUserId })
    console.log('MimbleDataContext.reloadInbox finished.', { result })
    if (!result || !result.data) {
      return
    }
    receivedNewInbox(result.data.inbox, apolloClient)
    return result.data.inbox
  }

  const clearInbox = (): void => {
    logger.info('MimbleDataContext.clearInbox called.')
    setInbox(undefined)
  }

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // Purchases
  const {
    data: purchasesData,
    error: purchasesLoadingError,
    loading: isLoadingPurchases,
    networkStatus: purchasesLoadingNetworkStatus,
    refetch: refetchPurchases,
  } = useQuery<PurchasesQueryData, PurchasesQueryVariables>(
    apollo.queries.purchases, {
      variables: apollo.getPurchasesQueryVariables(undefined, activeUserId as string),
      skip: !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      // onCompleted: () => {
      //   if (listeners && listeners.size > 0) {
      //     console.log('PurchasesContext.processReceivedActiveUser: calling listeners.')
      //     listeners.forEach(listener => listener(PurchasesContextEvent.PURCHASES_LOADED))
      //   }
      // },
      onError: (error) => {
        logger.info('Error fetching active user', error)
      },
    },
  )
  const purchases = purchasesData ? purchasesData.purchases : []

  const reloadPurchases = async (): Promise<PurchaseListItem[] | undefined> => {
    // console.log('PurchasesContext.reloadPurchases called.', { activeUserId })
    if (!activeUserId) {
      return
    }
    const result = await refetchPurchases()
    // console.log('PurchasesContext.reloadPurchases finished.', { result })
    if (!result || !result.data) {
      return
    }
    // if (listeners && listeners.size > 0) {
    //   console.log('PurchasesContext.processReceivedActiveUser: calling listeners.')
    //   listeners.forEach(listener => listener(PurchasesContextEvent.PURCHASES_LOADED))
    // }
    return result.data.purchases
  }

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // Model Change Messages
  const processModelChangedMessage = (message: ModelChangedMessage) => {
    // console.log('MimbleDataContext.processModelChangedMessage called.', { message })

    setModelChangedMessage(message)
    if (message.modelType === ModelType.Contact) {
      reloadContacts()
        .then(undefined, (error) => {
          console.error(error)
        })
    } else if (message.modelType === ModelType.Inbox) {
      reloadInbox().then(undefined, (error) => {
          console.error(error)
        })
    } else if (message.modelType === ModelType.ShoppingCart) {
      if (message.code === ModelChangedCode.PURCHASE_DELIVERED) {
        reloadPurchases()
          .then(undefined, (error) => {
            console.error(error)
          })
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      reloadContacts()
        .then(undefined, (error) => {
          console.error(error)
        })
    } else if (message.modelType === ModelType.Order) {
      if (message.messageType === ModelChangedMessageType.CREATED) {
        reloadPurchases()
          .then(undefined, (error) => {
            console.error(error)
          })
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      reloadContacts()
        .then(undefined, (error) => {
          console.error(error)
        })
    }
    // Notifying listeners:
    // if (listeners && listeners.size > 0) {
    //   console.log('MimbleDataContext.receivedNewInbox: calling listeners.')
    //   listeners.forEach(listener => listener(MimbleDataContextEvent.INBOX_UPDATED, {
    //     inbox: newInbox,
    //   }))
    // }
  }

  const {
    error: modelChangedError,
  } = useSubscription<ModelChangedSubscriptionData, ModelChangedSubscriptionVariables>(
    apollo.subscriptions.modelChanged,
    {
      variables: { userId: activeUserId as string },
      skip: !activeUserId || !getIsSignedIn(),
      onSubscriptionData: (data) => {
        // console.log('MimbleDataContext.useSubscription[modelChanged].onSubscriptionData called', data)
        if (
          !data ||
          !data.subscriptionData ||
          !data.subscriptionData.data ||
          !data.subscriptionData.data.modelChanged
        ) {
          logger.error('MimbleDataContext.useSubscription[modelChanged]: no valid data received.',
            data.subscriptionData)
          return
        }
        processModelChangedMessage(data.subscriptionData.data.modelChanged)
      },
    },
  )

  useEffect(() => {
    if (
      apolloClient &&
      activeUserId &&
      !loadedActiveUserId &&
      !isLoadingActiveUser
    ) {
      reloadActiveUser().then(undefined, (error) => {
        console.error(error)
      })
    }
  }, [activeUserId, loadedActiveUserId, isLoadingActiveUser])

  useEffect(() => {
    if (modelChangedError) {
      console.error(modelChangedError)
    }
  }, [modelChangedError])

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // Reward Configs:
  const getRewardConfigs = async (): Promise<RewardConfig[] | undefined> => {
    if (!rewardConfigs) {
      setIsLoadingRewardConfigs(true)
      try {
        rewardConfigs = await api.loadRewardConfigs(apolloClient)
        setIsLoadingRewardConfigs(false)
      } catch (error) {
        console.error(error)
        setIsLoadingRewardConfigs(false)
      }
      // console.log('MimbleDataContext.getRewardConfigs: loaded configs', { rewardConfigs })
    }
    return rewardConfigs
  }

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // "Rendering"
  return (
    <MimbleDataContext.Provider
      value={{
        // Active User:
        activeUser: activeUserId ? activeUser : undefined,
        activeUserWallets,
        activeUserUpdatingError,
        isLoadingActiveUser,
        isLoadingActiveUserWallets,
        isUpdatingActiveUser,
        isLoadingRewardConfigs,
        clearActiveUser,
        getActiveUserMimbleWallet,
        reloadActiveUser,
        reloadActiveUserWallets,
        updateActiveUser,

        // Chats:
        chats: activeUserId ? chats : undefined,
        chatsLoadingError,
        isLoadingChats,
        chatsLoadingNetworkStatus,
        reloadChats,

        // Contacts:
        contacts: activeUserId ? contacts : undefined,
        contactsLoadingError,
        isLoadingContacts,
        contactsLoadingNetworkStatus,
        reloadContacts,

        // Inbox:
        inbox: activeUserId ? inbox : undefined,
        inboxLoadingError,
        isLoadingInbox,
        inboxLoadingNetworkStatus,
        deleteInboxItem,
        reloadInbox,
        clearInbox,
        // addModelChangeListener,
        // removeModelChangeListener,

        // Purchases:
        purchases: activeUserId ? purchases : undefined,
        purchasesLoadingError,
        isLoadingPurchases,
        purchasesLoadingNetworkStatus,
        reloadPurchases,

        // Model Changes:
        modelChangedMessage,

        // Rewards Configuration:
        getRewardConfigs,
        rewardConfigs,

        // Unread In-App Messages:
        unreadInAppMessages,
        unreadInAppMessagesLoadingError,
        isLoadingUnreadInAppMessages,
        unreadInAppMessagesLoadingNetworkStatus,
        reloadUnreadInAppMessages,
      }}
    >
      {children}
    </MimbleDataContext.Provider>
  )
}

export default MimbleDataContextProvider
