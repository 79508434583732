import React, { useContext, useEffect, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useApolloClient } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import { ChatLayout } from '../../components/Chat/enums'
import type { ContactInput } from '../../lib/core/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { UiMessage } from '../../lib/core/enums'
import AddContactForm from './AddContactForm'
import api from '../../services/api'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import ChatList from '../../components/ChatList/ChatList'
import ListHeader from '../../components/ListHeader/ListHeader'
import logger from '../../services/logger'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'

const appPageId = AppPage.MessagesPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const MessagesPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const {
    activeUser,
    isLoadingChats,
    isLoadingActiveUser,
    reloadChats,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [isUpsertingContact, setIsUpsertingContact] = useState(false)
  const [searchText, setSearchText] = useState<string | undefined>()
  const [showAddContactForm, setShowAddContactForm] = useState(false)

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingChats || isUpsertingContact

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (!isLoadingChats) {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }
  }, [isLoadingChats])

  // ===================================================================================================================
  // Event Handlers:
  const onApplyListFilter = (newSearchText: string): void => {
    if (newSearchText !== searchText) {
      setSearchText(newSearchText)
    }
  }

  const onRefreshPage = (event?: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadChats().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenChat = (chatId: string): void => {
    if (!activeUserId) {
      return
    }

    pageMessages && pageMessages.clear()
    api.loadChat(
      chatId,
      undefined,
      undefined,
      activeUserId,
      apolloClient,
    ).then(() => {
      navigate(`${AppRoute.CONTACT_MESSAGES}/${chatId}`)
    })
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenInvitePage = (): void => {
    navigate(AppRoute.NEW_INVITATION)
  }

  const onOpenSupportChat = (): void => {
    pageMessages && pageMessages.clear()
    navigate(`${AppRoute.CONTACT_MESSAGES}/-/mimble-team`)
  }

  const onShowMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const onShowAddContactForm = (): void => {
    setShowAddContactForm(true)
  }

  const onHideAddContactForm = (): void => {
    setShowAddContactForm(false)
  }

  const onAddContact = (contactInput: ContactInput | undefined): void => {
    if (!activeUser || !contactInput) {
      return
    }

    setIsUpsertingContact(true)
    pageMessages && pageMessages.clear()

    api.upsertContact(
      contactInput,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then(() => {
      reloadChats().then(() => {
        setIsUpsertingContact(false)
        setShowAddContactForm(false)
        // pageMessages && pageMessages.add(PageMessageType.NOTICE, UiMessage.CHANGES_SAVED_SUCCESSFULLY)
        setToastMessage('The contact was added successfully.')
        setShowToast(true)
      }, (error) => {
        logger.error('ContactsPage: error reloading.', { error })
        setIsUpsertingContact(false)
        setShowAddContactForm(false)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      })
    }, (error) => {
      setIsUpsertingContact(false)
      pageHelpers.checkForUnauthorized(error, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SAVING_CHANGES)
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let header: JSX.Element | undefined
  if (showAddContactForm) {
    header = (
      <div className='contact-form'>
        <AddContactForm
          onCancel={onHideAddContactForm}
          onSubmit={onAddContact}
          onOpenInvitePage={onOpenInvitePage}
        />
      </div>
    )
  } else {
    header = (
      <ListHeader
        searchText={searchText}
        className='withStandardLeftMargin'
        onAddItem={onShowAddContactForm}
        onApply={onApplyListFilter}
        showSearch
      />
    )
  }

  return (
    <IonPage className='app-page-public messages-page'>
      <NavBar
        title='Contacts'
        userInfo={activeUser}
        isProcessing={isProcessing}
        onRefresh={onRefreshPage}
        onOpenUserAccount={onOpenUserAccount}
      />
      {header}
      <IonContent className='g-content-with-safe-padding contacts-page-content'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={onRefreshPage}>
          <IonRefresherContent />
        </IonRefresher>
        <ChatList
          searchText={searchText}
          layout={ChatLayout.FULL_PAGE}
          onOpenChat={onOpenChat}
          onOpenSupportChat={onOpenSupportChat}
          onShowMessage={onShowMessage}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default MessagesPage
