import type { WishListFilter } from '../../../lib/core/definitions'

const getWishesOfActiveUserListFilter = (
  activeUserId: string,
): WishListFilter => ({
  userId: activeUserId,
  orderBy: 'title',
})

export default getWishesOfActiveUserListFilter
