import type { FavoriteOrganizationsQueryVariables } from '../definitions'
import type { FavoriteOrganizationListFilter } from '../../../lib/core/definitions'
import getFavoriteOrganizationListFilter from './getFavoriteOrganizationListFilter'

const getFavoriteOrganizationsQueryVariables = (
  listFilter: FavoriteOrganizationListFilter | undefined,
  userId: string,
): FavoriteOrganizationsQueryVariables => ({
  filter: listFilter || getFavoriteOrganizationListFilter(userId),
})

export default getFavoriteOrganizationsQueryVariables
