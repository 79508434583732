import { AsyncTaskType, ErrorCode, UserRole } from '../../enums'
import hasRole from '../models/user/hasRole'

const taskDefinitions = Object.freeze({
  [AsyncTaskType.IMPORT_STORED_PRODUCTS]: { expires: 1440 }, // 24 hours
  [AsyncTaskType.RESET_PASSWORD]: { expires: 120 },
  [AsyncTaskType.TOKEN_SIGN_IN]: { expires: 10 },
  [AsyncTaskType.UPDATE_EMAIL]: { expires: 120 },
  [AsyncTaskType.UPDATE_PASSWORD]: { expires: 120 },
  [AsyncTaskType.UPDATE_PHONE_NUMBER]: { expires: 120 },
  [AsyncTaskType.VERIFY_EMAIL]: { expires: 120 },
  [AsyncTaskType.VERIFY_PHONE_NUMBER]: { expires: 120 },
  [AsyncTaskType.VERIFY_PHONE_NUMBER_ON_SIGN_UP]: { expires: 120 },
  [AsyncTaskType.WELCOME]: { expires: 120 },
})

const labelsEn = Object.freeze({
  [AsyncTaskType.IMPORT_STORED_PRODUCTS]: 'import-stored-prod',
  [AsyncTaskType.RESET_PASSWORD]: 'reset-password',
  [AsyncTaskType.TOKEN_SIGN_IN]: 'sign-in-with-token',
  [AsyncTaskType.UPDATE_EMAIL]: 'update-email',
  [AsyncTaskType.UPDATE_PASSWORD]: 'update-password',
  [AsyncTaskType.UPDATE_PHONE_NUMBER]: 'update-phone-number',
  [AsyncTaskType.VERIFY_EMAIL]: 'verify-email',
  [AsyncTaskType.VERIFY_PHONE_NUMBER]: 'verify-phone-number',
  [AsyncTaskType.VERIFY_PHONE_NUMBER_ON_SIGN_UP]: 'verify-phone-number-on-sign-up',
  [AsyncTaskType.WELCOME]: 'welcome',
})

export default {
  getExpirationInMinutes: (type: AsyncTaskType): number => {
    const def = taskDefinitions[type]
    if (!def) {
      throw new Error(ErrorCode.SYSTEM_ERROR)
    }
    return def.expires
  },

  getLabel: (type: AsyncTaskType | null | undefined, language = 'en'): string => {
    if (!type) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[type]
    }
    return ''
  },

  isValid: (type: AsyncTaskType): boolean => Object.values(AsyncTaskType).includes(type),

  requiresAuthentication: (type: AsyncTaskType): boolean => (
    type === AsyncTaskType.UPDATE_EMAIL ||
    type === AsyncTaskType.UPDATE_PHONE_NUMBER ||
    type === AsyncTaskType.UPDATE_PASSWORD ||
    type === AsyncTaskType.VERIFY_EMAIL ||
    type === AsyncTaskType.VERIFY_PHONE_NUMBER
  ),

  requiresUser: (type: AsyncTaskType): boolean => (
    type !== AsyncTaskType.VERIFY_PHONE_NUMBER_ON_SIGN_UP &&
    type !== AsyncTaskType.TOKEN_SIGN_IN
  ),

  allowedToUpsertTask: (type: AsyncTaskType, roles: UserRole | UserRole[] | null | undefined): boolean => {
    if (type !== AsyncTaskType.IMPORT_STORED_PRODUCTS) {
      return true
    }
    return hasRole(roles, UserRole.ADMIN)
  },

  allowOnlyOneOfTypePerUser: (taskType: AsyncTaskType): boolean => true,

  taskDefinitions,
}
