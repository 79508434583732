import React, { useState } from 'react'
import {
  IonContent,
  IonInput,
  IonPage,
  IonToast,
} from '@ionic/react'
import { Update } from 'history'
import { useHistory, useLocation } from 'react-router-dom'

import './styles.css'
import type { LogLevel } from '../../enums'
import { AppPage, AppRoute } from '../../enums'
import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import FormItem from '../../components/FormItem/FormItem'
import LogHistory from './LogHistory/LogHistory'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import SubmitButton from '../../components/SubmitButton/SubmitButton'

const appPageId = AppPage.LoggingPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const LoggingPage: React.FC = (props): JSX.Element => {
  // const navigate = useNavigate()
  // const locationUpdate: Update = useLocation()
  // const location = locationUpdate.location || window.location

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const { getValue, setValue } = useGlobalCache()
  const { activeUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [logLevel, setLogLevel] = useState<LogLevel | undefined>(
    getValue(GlobalCacheDataKey.LOG_LEVEL, true) as LogLevel,
  )

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Loading Latest App Version:

  // ===================================================================================================================
  // Event Handlers:
  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onChangeLogLevel = (event: any): void => {
    event.preventDefault()
    setLogLevel(event.detail.value)
  }

  const onSaveLogLevel = (): void => {
    setValue(GlobalCacheDataKey.LOG_LEVEL, logLevel || 'error', true)
  }

  // ===================================================================================================================
  // Rendering:
  const logLevelForm = (
    <form onSubmit={onSaveLogLevel} className='with100PercentWidth withStandardTopMargin withMaxWidth400px'>
      <FormItem
        key='logLevel'
        label='Log Level'
        withBottomMargin
      >
        <div className='row'>
          <IonInput
            onIonChange={onChangeLogLevel}
            type='text'
            placeholder='debug | info | warn | error'
            value={logLevel}
          />
          <SubmitButton
            onClick={onSaveLogLevel}
          >
            Save
          </SubmitButton>
        </div>
      </FormItem>
    </form>
  )

  return (
    <IonPage>
      <NavBar
        title='Log History'
        userInfo={activeUser}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <LogHistory />
        {logLevelForm}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default LoggingPage
