import { gql } from '@apollo/client'

export default gql`
  mutation M($favoriteOrganization: FavoriteOrganizationInput!) {
    upsertFavoriteOrganization (favoriteOrganization: $favoriteOrganization) {
      id
      userId
      organizationId
      notes
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
