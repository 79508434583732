import React from 'react'
import { closeCircleOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import './styles.css'

type Props = {
  attachmentId: string
  className?: string
  onClick?: () => void
  onDelete: (attachmentId: string) => void
}

const ChatAttachmentPreview: React.FC<Props> = (props): JSX.Element | null => {
  const {
    attachmentId,
    onDelete,
    onClick,
    className,
    children,
  } = props

  if (!children) {
    return null
  }

  const handleClick = (): void => {
    onDelete(attachmentId)
  }

  return (
    <div className={`chat-attachment-preview ${className || ''}`} onClick={onClick}>
      <IonIcon icon={closeCircleOutline} className='delete-icon' onClick={handleClick} />
      {children}
    </div>
  )
}

export default ChatAttachmentPreview
