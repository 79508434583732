import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { StoredProduct, StoredProductListItem } from '../../../lib/core/definitions'
import type {
  StoredProductQueryData,
  StoredProductQueryVariables,
  StoredProductsQueryData,
  StoredProductsQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  storedProduct: StoredProduct,
  queryListVariables: StoredProductsQueryVariables,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateStoredProduct.updateInList called.', { storedProduct })
  if (!storedProduct) {
    logger.error('api.cache.updateStoredProduct.updateInList: no storedProduct given.')
    return
  }

  try {
    const cachedStoredProductsData = apolloClient.readQuery<StoredProductsQueryData, StoredProductsQueryVariables>({
      query: apollo.admin.queries.storedProducts,
      variables: queryListVariables,
    })

    if (
      !cachedStoredProductsData ||
      !Array.isArray(cachedStoredProductsData.storedProducts) ||
      cachedStoredProductsData.storedProducts.length < 1
    ) {
      const listItem = coreHelpers.models.storedProduct.updateStoredProductListItemFromStoredProduct(undefined, storedProduct)
      apolloClient.writeQuery<StoredProductsQueryData, StoredProductsQueryVariables>({
        query: apollo.admin.queries.storedProducts,
        variables: queryListVariables,
        data: { storedProducts: [listItem] },
      })
      return
    }

    const updatedStoredProducts = coreHelpers.models.updateModelInList<StoredProductListItem, StoredProduct>(
      cachedStoredProductsData.storedProducts,
      storedProduct,
      undefined,
      true,
      coreHelpers.models.storedProduct.updateStoredProductListItemFromStoredProduct,
    )

    apolloClient.writeQuery<StoredProductsQueryData, StoredProductsQueryVariables>({
      query: apollo.admin.queries.storedProducts,
      variables: queryListVariables,
      data: { storedProducts: updatedStoredProducts },
    })
  } catch (error) {
    logger.error('api.cache.updateStoredProduct.updateInList: error.', { error })
  }
}

const updateStoredProduct = (
  storedProduct: StoredProduct,
  queryListVariables: StoredProductsQueryVariables | undefined,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateStoredProduct called.', { storedProduct })
  if (!apolloClient) {
    logger.error('api.cache.updateStoredProduct: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!storedProduct || !storedProduct.id) {
    logger.error('api.cache.updateStoredProduct: no storedProduct given.', { storedProduct })
    return
  }

  try {
    apolloClient.writeQuery<StoredProductQueryData, StoredProductQueryVariables>({
      query: apollo.admin.queries.storedProduct,
      variables: { storedProductId: storedProduct.id },
      data: { storedProduct },
    })

    if (queryListVariables) {
      updateInList(
        storedProduct,
        queryListVariables,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateStoredProduct: error.', { storedProduct, error })
  }
}

export default updateStoredProduct
