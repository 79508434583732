import type { StoredProductListFilter } from '../../../lib/core/definitions'
import type { StoredProductsQueryVariables } from '../definitions'
import getStoredProductListFilter from './getStoredProductListFilter'

const getStoredProductsQueryVariables = (
  listFilter: StoredProductListFilter | undefined,
): StoredProductsQueryVariables => ({
  filter: listFilter || getStoredProductListFilter(),
})

export default getStoredProductsQueryVariables
