import type { Contact, ContactUserListItem } from '../../../definitions'

const updateContactUserListItemFromContact = (
  contactUserListItem: ContactUserListItem | undefined,
  contact: Contact,
  activeUserId: string,
): ContactUserListItem => {
  if (!contactUserListItem) {
    const user = contact.fromUserId === activeUserId ? contact.toUser : contact.fromUser
    const id = contact.fromUserId === activeUserId ? contact.toUserId : contact.fromUserId
    return {
      id: id as string,
      userId: id as string,
      fromUserId: contact.fromUserId as string,
      contactId: contact.id as string,
      chatId: contact.chatId || '',
      contactType: contact.contactType || null,
      contactStatus: contact.status || null,
      username: (user && user.username) || '',
      fullName: (user && user.fullName) || '',
      nickname: (contact && contact.nickname) || '',
      email: (user && user.email) || '',
      phoneNumber: (user && user.phoneNumber) || '',
      imageUrl: (user && user.imageUrl) || '',
      isOrganization: (user && user.isOrganization) || false,
      roles: (user && user.roles) || null,
      birthday: (user && user.birthday) || '',
      latestChatMessage: '',
      latestChatMessageFromUserId: '',
      latestChatMessageCreatedAt: '',
      unreadChatMessageCount: 0,
      userCreatedAt: (user && user.createdAt) || '',
      userUpdatedAt: (user && user.updatedAt) || '',
      userRecordStatus: (user && user.recordStatus) || null,
      favorite: contact.favorite,
      contactMetadata: contact.metadata || null,
      contactRecordStatus: contact.recordStatus || null,
      contactCreatedAt: contact.createdAt || null,
      contactUpdatedAt: contact.updatedAt || null,
    }
  }
  const updatedItem = { ...contactUserListItem }

  if (contact.contactType) {
    updatedItem.contactType = contact.contactType
  }
  if (contact.status) {
    updatedItem.contactStatus = contact.status
  }
  if (contact.favorite === true || contact.favorite === false) {
    updatedItem.favorite = contact.favorite
  }
  if (contact.metadata) {
    updatedItem.contactMetadata = contact.metadata
  }
  if (contact.recordStatus) {
    updatedItem.contactRecordStatus = contact.recordStatus
  }
  if (contact.createdAt) {
    updatedItem.contactCreatedAt = contact.createdAt
  }
  if (contact.updatedAt) {
    updatedItem.contactUpdatedAt = contact.updatedAt
  }

  return updatedItem
}

export default updateContactUserListItemFromContact
