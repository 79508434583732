import { FiatCurrency, FundType, ReportDataFormat, StoredProductListScope, StoredProductStatus } from '../../../enums'
import type { StoredProductListFilter, StoredProductListItem } from '../../../definitions'
import formatAmount from '../../ui/formatAmount'
import formatDate from '../../ui/formatDate'
import formatPercentAmount from '../../ui/formatPercentAmount'

const exportData = (
  storedProducts: StoredProductListItem[] | null | undefined,
  filter: StoredProductListFilter | undefined,
  scope?: StoredProductListScope,
  format = ReportDataFormat.TSV,
): string => {
  if (!Array.isArray(storedProducts) || storedProducts.length < 1) {
    return ''
  }

  let colDelimiter = '\t'
  const rowDelimiter = '\n'
  switch (format) {
    case ReportDataFormat.CSV:
      colDelimiter = ','
      break
    case ReportDataFormat.TSV:
      colDelimiter = '\t'
  }

  const lines = storedProducts
    .filter((storedProduct) => {
      if (filter && filter.searchText) {
        const reg = new RegExp(filter.searchText, 'i')
        if (!storedProduct.merchantName || !storedProduct.merchantName.match(reg)) {
          return false
        }
      }
      if (scope) {
        switch (scope) {
          case StoredProductListScope.CREATED:
            return storedProduct.status === StoredProductStatus.CREATED
          case StoredProductListScope.AVAILABLE:
            return storedProduct.status === StoredProductStatus.AVAILABLE
          case StoredProductListScope.RESERVED:
            return storedProduct.status === StoredProductStatus.RESERVED
          case StoredProductListScope.DELIVERED:
            return storedProduct.status === StoredProductStatus.DELIVERED
        }
      }
      return false
    })
    .map((storedProduct): string => {
      return [
        (storedProduct.id as string).replace(/-/g, ''),
        storedProduct.productId ? (storedProduct.productId).replace(/-/g, '') : '-',
        storedProduct.productOptionId ? (storedProduct.productOptionId).replace(/-/g, '') : '-',
        storedProduct.purchaseId ? (storedProduct.purchaseId).replace(/-/g, '') : '-',
        storedProduct.merchantName,
        formatAmount(storedProduct.amount, FundType.FIAT, FiatCurrency.USD, false, 0),
        formatPercentAmount(storedProduct.discount),
        storedProduct.code,
        storedProduct.status,
        storedProduct.poNumber,
        formatDate(storedProduct.receivedAt),
        formatDate(storedProduct.paidAt),
        formatDate(storedProduct.createdAt),
        formatDate(storedProduct.updatedAt),
        storedProduct.recordStatus,
      ].join(colDelimiter)
    })
  lines.unshift([
    'ID',
    'Product ID',
    'ProductOption ID',
    'Purchase ID',
    'Vendor',
    'Amount',
    'Discount',
    'Code',
    'Status',
    'Po Number',
    'Received At',
    'Paid At',
    'Created At',
    'Updated At',
    'Record Status',
  ].join(colDelimiter))

  return lines.join(rowDelimiter)
}

export default exportData
