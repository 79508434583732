export enum DialogId {
  TRANSFER_TOKENS_IN_CHAT = 'transfer-tokens-in-chat',
  OUT_OF_STOCK_ITEMS_IN_SHOPPING_CART = 'out-of-stock-items-in-shopping-cart',
  CONFIRM_CLEAR_SHOPPING_CART = 'confirm-clear-shopping-cart',
  CONFIRM_DELETE_PENDING_ORDER = 'confirm-delete-pending-order',
}

export enum DialogButtonId {
  CANCEL = 'cancel',
  CLEAR_SHOPPING_CART = 'clear-shopping-cart',
  DELETE_PENDING_ORDER = 'delete-pending-order',
  EDIT_SHOPPING_CART = 'edit-shopping-cart',
  PLACE_ORDER = 'place-order',
}
