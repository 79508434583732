import React, { useContext, useEffect, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import type {
  ContactQueryData,
  ContactQueryVariables,
  PurchasesQueryData,
  PurchasesQueryVariables,
} from '../../services/apollo/definitions'
import { ChatQueryData, ChatQueryVariables } from '../../services/apollo/definitions'
import { useActivePageData } from '../../contexts/ActivePageDataContext'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useShoppingCart } from '../../contexts/ShoppingCartContext/ShoppingCartContext'
import { AppFeature, ChatAttachmentType, ModelType, PurchaseStatus, UiMessage } from '../../lib/core/enums'
import { TopNavTabId } from '../../components/TopNavBar/enums'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import {
  ChatAttachmentInfo,
  ChatAttachmentPurchaseTransferInfo,
  ChatAttachmentRewardInfo,
  ChatAttachmentTransactionInfo,
  ChatMessage,
} from '../../lib/core/definitions'
import { ChatLayout } from '../../components/Chat/enums'
import api from '../../services/api'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import ChatComponent from '../../components/Chat/Chat'
import ContactHeader from '../../components/ContactHeader/ContactHeader'
import ContactPageIdeasTab from './ContactPageIdeasTab/ContactPageIdeasTab'
import ContactPageInfoTab from './ContactPageInfoTab/ContactPageInfoTab'
import coreHelpers from '../../lib/core/helpers'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'

const CHAT_MESSAGE_BATCH_SIZE = 50
const appPageId = AppPage.ContactPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type LocationState = {
  tab?: TopNavTabId
}

type Params = {
  contactId?: string
  userId?: string
}

const ContactPage: React.FC = (): JSX.Element => {
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  const isActivePage = appPageDef.routeMatches(location && location.pathname)

  const { contactId } = useParams<keyof Params>() as unknown as Params
  let { userId: contactUserId } = useParams<keyof Params>() as unknown as Params

  let topNavTabId = (
    location &&
    location.state &&
    (location.state as LocationState).tab
  ) as TopNavTabId || TopNavTabId.CONTACT_MESSAGES

  // console.log('ContactPage.render called.', { location, contactId, contactUserId, topNavTabId, isActivePage })

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { setShoppingCart } = useShoppingCart()
  const { pageWillEnter, pageWillLeave, setActivePageData } = useActivePageData()
  const pageMessages = useContext(PageMessagesContext)
  const { start: startGiftFlow } = useGiftFlow()
  const {
    activeUser,
    isLoadingActiveUser,
    modelChangedMessage,
    reloadActiveUser,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [messagesOffset, setMessagesOffset] = useState(0)
  const [markUnreadMessages, setMarkUnreadMessages] = useState(false)

  // console.log('ContactPage.render called.', { props, params: match.params, locationState: location.state, topNavTabId })

  // ===================================================================================================================
  // Apollo Hooks:
  const loadContactVariables: ContactQueryVariables = contactId && contactId !== '-'
    ? { contactId }
    : { fromUserId: activeUserId, toUserId: contactUserId }

  const {
    data: contactData,
    refetch: reloadContact,
    loading: isLoadingContact,
  } = useQuery<ContactQueryData, ContactQueryVariables>(
    apollo.queries.contact, {
      variables: loadContactVariables,
      skip: !(!!contactId || (!!activeUserId && !!contactUserId)),
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
        if (
          data &&
          data.contact &&
          data.contact.toUser
        ) {
          setActivePageData(appPageId, { contactUser: data.contact.toUser })
        }
      },
      onError: (error) => {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
    },
  )
  const contact = contactData
    ? { ...contactData.contact }
    : undefined

  if (contact && contact.metadata) {
    contact.metadata = coreHelpers.models.contact.addTransientMetadata(contact.metadata)
  }

  if (
    (!contactUserId || contactUserId === '-') &&
    contact &&
    contact.toUserId
  ) {
    contactUserId = contact.toUserId
  }
  const userIsOrganization = !!(contact && contact.toUser && contact.toUser.isOrganization)

  const {
    data: purchasesISentData,
    refetch: reloadPurchasesISent,
    loading: isLoadingPurchasesISent,
  } = useQuery<PurchasesQueryData, PurchasesQueryVariables>(
    apollo.queries.purchases, {
      variables: {
        filter: {
          userId: activeUserId as string,
          sentToUserId: (contact && contact.toUserId) as string,
        },
      },
      skip: !activeUserId || !contact || !contact.toUserId || userIsOrganization,
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
    },
  )
  const purchasesISent = purchasesISentData ? purchasesISentData.purchases : undefined

  const {
    data: purchasesIReceivedData,
    refetch: reloadPurchasesIReceived,
    loading: isLoadingPurchasesIReceived,
  } = useQuery<PurchasesQueryData, PurchasesQueryVariables>(
    apollo.queries.purchases, {
      variables: {
        filter: {
          userId: activeUserId as string,
          receivedFromUserId: (contact && contact.toUserId) as string,
        },
      },
      skip: !activeUserId || !contact || !contact.toUserId || userIsOrganization,
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
    },
  )
  const purchasesIReceived = purchasesIReceivedData ? purchasesIReceivedData.purchases : undefined

  const chatQueryVariables: ChatQueryVariables = {
    userIds: [activeUserId as string, contactUserId as string],
    viewerUserId: activeUserId || '',
    messagesOffset: messagesOffset || 0,
    messagesLimit: CHAT_MESSAGE_BATCH_SIZE,
  }

  const {
    data: chatLoadedData,
    loading: isLoadingChat,
    refetch: reloadChat,
  } = useQuery<ChatQueryData, ChatQueryVariables>(
    apollo.queries.chat, {
      variables: chatQueryVariables,
      skip: !activeUserId || !contactUserId || contactUserId === '-',
      onCompleted: (data) => {
        if (data && data.chat) {
          // if (onChatLoaded) {
          //   onChatLoaded(data.chat)
          // }
          // if (!chatId) {
          //   setChatId(data.chat.id as string)
          // }
          // setTimeout(() => {
          //   todo: This might be a redundant call, if the previous page loaded this chat earlier.
          //   console.log('ContactPage: calling handleUnreadChatMessages')
          //   api.helpers.handleUnreadChatMessages(
          //     data.chat,
          //     activeUserId as string,
          //     inbox,
          //     apolloClient,
          //   )
          // }, 200)
          // scrollToBottom()
          if (refreshEvent) {
            refreshEvent.detail.complete()
            refreshEvent = undefined
          }
          // if (data.chat) {
          //   contactUser = data.chat.fromUserId === activeUserId ? data.chat.toUser : data.chat.fromUser
          //   if (contactUser) {
          //     setActivePageData(AppPage.ChatPage, {
          //       contactUser,
          //     })
          //   }
          // }
        }
      },
    },
  )
  const { chat } = chatLoadedData || {}

  // ===================================================================================================================
  // Helpers:
  const isProcessing = (
    isLoadingActiveUser ||
    isLoadingChat ||
    isLoadingContact ||
    isLoadingPurchasesISent ||
    isLoadingPurchasesIReceived
  )
  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  useIonViewWillEnter(() => {
    if (pageWillEnter) {
      pageWillEnter(appPageId, {
        contactUser: contactUserId && contactUserId !== '-' ? { id: contactUserId } : undefined,
      })
    }
  })

  useIonViewWillLeave(() => {
    if (pageWillLeave) {
      pageWillLeave(appPageId)
    }
  })

  useEffect(() => {
    // console.log('ContactPage.useEffect[modelChangedMessage] called.', modelChangedMessage)
    if (
      !activeUserId ||
      !modelChangedMessage ||
      modelChangedMessage.modelType !== ModelType.ChatMessage ||
      !modelChangedMessage.model ||
      !(modelChangedMessage.model as ChatMessage).chatId ||
      !chat ||
      !coreHelpers.models.compareId((modelChangedMessage.model as ChatMessage).chatId, chat.id)
    ) {
      return
    }
    // console.log('ContactPage.useEffect[modelChangedMessage]: chat message received.', modelChangedMessage.model)
    pageHelpers.handleNewOrUpdatedChatMessage(
      modelChangedMessage.model as ChatMessage,
      chatQueryVariables,
      activeUserId,
      true,
      apolloClient,
    )
  }, [modelChangedMessage])

  // ===================================================================================================================
  // Event Handlers:
  const doRefresh = (event?: CustomEvent<RefresherEventDetail>): void => {
    // console.log('ContactPage.doRefresh called.')
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    if (event) {
      refreshEvent = event
    }

    const done = () => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }

    reloadActiveUser().then(() => {
      reloadContact().then(() => {
        if (topNavTabId === TopNavTabId.CONTACT_MESSAGES) {
          reloadChat().then(done, (error) => {
            console.error(error)
          })
        } else if (topNavTabId === TopNavTabId.CONTACT_IDEAS) {
          reloadPurchasesISent().then(() => {
            reloadPurchasesIReceived().then(done, (error) => {
              console.error(error)
            })
          })
        }
      }, (error) => {
        console.error(error)
      })
    }, (error) => {
      console.error(error)
    })
  }

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenWishExternalUrl = (wishId: string, url: string): void => {
    if (url) {
      window.open(url as string, '_system')
    }
  }

  const onOpenFavoriteMerchant = (merchantId: string, merchantInMarketplace: boolean): void => {
    if (merchantInMarketplace) {
      pageHelpers.addProductToShoppingCart({
        activeUserId,
        merchantId,
        setShoppingCart,
        navigate,
        apolloClient,
      })
    }
  }

  const onOpenPurchase = (purchaseId: string): void => {
    api.loadPurchase(
      purchaseId,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
      undefined,
      { updateList: false },
    ).then((purchase) => {
      if (!purchase) {
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.GIFT_CARD_NOT_FOUND)
        return
      }
      navigate(`/${AppRoute.GIFT_CARD}/${purchaseId}`)
    },
    )
  }

  const onOpenContact = (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    tabId: TopNavTabId,
  ): void => {
    // console.log('ChatPage.onOpenContact called.', { contactId, userId, tabId })
    pageHelpers.openContact({
      activeUserId,
      contactId,
      contactUserId,
      tabId,
      apolloClient,
      navigate,
    })
  }

  const onNewGift = (): void => {
    if (contact) {
      startGiftFlow({
        toUserId: contactUserId as string,
      })
    }
    navigate(AppRoute.SEND_GIFT)
  }

  const onOpenChatAttachment = (
    chatMessage: ChatMessage,
    chatAttachmentInfo: ChatAttachmentInfo,
  ): void => {
    // console.log('ChatPage.onOpenAttachment called.', { chatMessage, chatAttachmentInfo })
    if (!activeUser) {
      return
    }

    if (chatAttachmentInfo.attachmentType === ChatAttachmentType.PURCHASE) {
      const attachment = chatAttachmentInfo as ChatAttachmentPurchaseTransferInfo
      const purchaseId = activeUser.id === chatMessage.fromUserId
        ? attachment.fromPurchaseId
        : attachment.toPurchaseId
      if (purchaseId) {
        api.loadPurchase(
          purchaseId,
          undefined,
          undefined,
          activeUserId as string,
          apolloClient,
          undefined,
          { skipCache: true },
        ).then((purchase) => {
          if (purchase && purchase.status === PurchaseStatus.RECEIVED) {
            navigate(`${AppRoute.GIFT_RECEIVED}/tp:${purchaseId}`)
          } else {
            navigate(`${AppRoute.GIFT_CARD}/${purchaseId}`)
          }
        }, (error) => {
          console.error(error)
        })
        return
      }
      if (activeUser.id === chatMessage.fromUserId) {
        // The sender is opening the transfer of the card they sent:
        navigate(`${AppRoute.GIFT_CARD}/${attachment.fromPurchaseId}`)
        return
      }
      // The recipient is opening a card they received:
      navigate(`${AppRoute.GIFT_CARD}/${attachment.toPurchaseId}`)
      return
    }

    if (chatAttachmentInfo.attachmentType === ChatAttachmentType.REWARD) {
      const attachment = chatAttachmentInfo as ChatAttachmentRewardInfo
      navigate(`${AppRoute.REWARD}/${attachment.id}`)
    }

    if (chatAttachmentInfo.attachmentType === ChatAttachmentType.TRANSACTION) {
      const attachment = chatAttachmentInfo as ChatAttachmentTransactionInfo
      navigate(`${AppRoute.TRANSACTION}/${attachment.id}`)
    }
  }

  const onChangeTopTabId = (
    contactId: string | null | undefined,
    userId: string | null | undefined,
    tabId: TopNavTabId,
  ): void => {
    pageHelpers.openContact({
      activeUserId,
      contactId,
      contactUserId,
      chatId: contact && contact.chatId,
      tabId,
      apolloClient,
      navigate,
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  if (userIsOrganization && topNavTabId !== TopNavTabId.CONTACT_MESSAGES) {
    topNavTabId = TopNavTabId.CONTACT_MESSAGES
  }

  const userCanAttachTokens = coreHelpers.models.user.hasAppFeature(
    activeUser && activeUser.appFeatures,
    AppFeature.SEND_MIT,
  )

  let tabContent: JSX.Element | undefined
  if (contact && contact.toUser) {
    switch (topNavTabId) {
      case TopNavTabId.CONTACT_MESSAGES:
        tabContent = (
          <ChatComponent
            chat={chat}
            layout={ChatLayout.FULL_PAGE}
            isAdminOrgChat={false}
            isOnActivePage={isActivePage}
            isLoading={isLoadingChat}
            canAttachTokens={userCanAttachTokens}
            onNewGift={onNewGift}
            // onGetPreviousMessagesBatch={onGetPreviousMessagesBatch}
            onOpenContact={onOpenContact}
            onOpenAttachment={onOpenChatAttachment}
          />
        )
        break
      case TopNavTabId.CONTACT_PROFILE:
        tabContent = (
          <ContactPageInfoTab
            contact={contact}
            user={contact && contact.toUser}
            purchasesISent={purchasesISent}
            purchasesIReceived={purchasesIReceived}
            showUiMessage={showUiMessage}
            onOpenPurchase={onOpenPurchase}
            onNewGift={onNewGift}
          />
        )
        break
      case TopNavTabId.CONTACT_IDEAS:
        tabContent = (
          <ContactPageIdeasTab
            contact={contact}
            user={contact && contact.toUser}
            showUiMessage={showUiMessage}
            onOpenFavoriteMerchant={onOpenFavoriteMerchant}
            onOpenWishExternalUrl={onOpenWishExternalUrl}
          />
        )
        break
    }
  }

  return (
    <IonPage className='app-page-public contact-page'>
      <NavBar
        title='Contact'
        userInfo={activeUser}
        goBackUri={AppRoute.CONTACTS}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
        onRefresh={doRefresh}
      />
      <ContactHeader
        contact={contact}
        user={contact ? contact.toUser : undefined}
        topNavTabId={topNavTabId}
        onOpenContact={onChangeTopTabId}
        showUiMessage={showUiMessage}
      />
      <IonContent scrollY={false} className='g-content-with-safe-padding'>
        <div className='g-non-scroll-content-wrapper'>
          <PageMessages />
          <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {tabContent}
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default ContactPage
