import { PurchaseStatus } from '../../enums'

const labelsEn = Object.freeze({
  [PurchaseStatus.CREATED]: 'created',
  [PurchaseStatus.PAYMENT_REQUEST]: 'requesting payment',
  [PurchaseStatus.PENDING_DELIVERY]: 'pending delivery',
  [PurchaseStatus.ACTIVE]: 'active',
  [PurchaseStatus.RECEIVED]: 'received',
  [PurchaseStatus.REDEEMED]: 'redeemed',
  [PurchaseStatus.CANCELED]: 'canceled',
  [PurchaseStatus.TRANSFERRING]: 'transferring',
  [PurchaseStatus.TRANSFERRED]: 'transferred',
  [PurchaseStatus.INVALID]: 'invalid',
})

export default {
  unverifiedStatuses: [
    PurchaseStatus.CREATED,
  ],
  unprocessedStatuses: [
    PurchaseStatus.CREATED,
    PurchaseStatus.PAYMENT_REQUEST,
  ],
  pendingStatuses: [
    PurchaseStatus.PENDING_DELIVERY,
    PurchaseStatus.TRANSFERRING,
    PurchaseStatus.RECEIVED,
  ],
  getLabel: (status?: PurchaseStatus | null, language?: string): string => {
    if (!status) {
      return ''
    }
    if (!language || language === 'US') {
      return labelsEn[status]
    }
    return ''
  },
  isValid: (status: PurchaseStatus): boolean => Object.values(PurchaseStatus).includes(status),

  isAvailableForTransfer: (status?: PurchaseStatus | null): boolean => (
    status === PurchaseStatus.ACTIVE
  ),
}
