import type { ShoppingCart } from '../../../definitions'
import { OrgProductType } from '../../../enums'

const getTotalProductCount = (shoppingCart: ShoppingCart | undefined): number => {
  if (
    !shoppingCart ||
    !Array.isArray(shoppingCart.items) ||
    shoppingCart.items.length < 1
  ) {
    return 0
  }
  return shoppingCart.items.reduce((count, item) => {
    if (item.productType === OrgProductType.MIMBLE_TOKEN) {
      return count + 1
    }
    if (item.productType === OrgProductType.GIFT_CARD) {
      if (!item.productOptionId) {
        return count
      }
      return count + (item.productCount || 0)
    }
    return count + 1
  }, 0)
}

export default getTotalProductCount
