import moment from 'moment'
import { IonRow, IonCol } from '@ionic/react'
import React from 'react'

import './styles.css'
import type { Currency, PurchaseHistoryEvent } from '../../../lib/core/definitions'
import type { FundType } from '../../../lib/core/enums'
import coreHelpers from '../../../lib/core/helpers/'

interface Props {
  event: PurchaseHistoryEvent;
  index: number;
  fundType: FundType | null | undefined;
  currency: Currency | null | undefined;
  showDetails?: boolean;
  onOpenEvent: (eventId: string) => void;
}

const HistoryEventListRow: React.FC<Props> = (props): JSX.Element | null => {
  const {
    event,
    index,
    fundType,
    currency,
    onOpenEvent,
  } = props

  if (!event) {
    return null
  }

  // ===================================================================================================================
  // Helpers:
  const eventId: string = event.id as string

  // ===================================================================================================================
  // Event Handlers:

  // ===================================================================================================================
  // Rendering:
  const createdAt = moment(+event.createdAt)
  const formattedDate = createdAt.year() === moment().year()
    ? createdAt.format('MM/DD')
    : createdAt.format('YY/MM/DD')
  const formattedBalance = coreHelpers.ui.formatAmount(
    event.balance,
    fundType,
    currency,
    true,
    2,
  )

  let rowClass = ''
  if (index % 2) {
    rowClass = 'odd-row'
  }
  if (index === 0) {
    rowClass = `${rowClass} first-row`
  }

  return (
    <IonRow className={rowClass} onClick={(): void => onOpenEvent(eventId)}>
      <IonCol className='col' size='2'>
        {formattedDate}
      </IonCol>
      <IonCol className='col withItalicText' size='7'>
        {event.notes || (index === 0 ? 'Initial balance' : '(none)')}
      </IonCol>
      <IonCol className='col withRightTextAlign' size='3'>
        {formattedBalance}
      </IonCol>
    </IonRow>
  )
}

export default HistoryEventListRow
