import type { ContactUserListItem } from '../../../definitions'
import parseName from '../user/parseName'

const isItemATextSearchHit = (contactUserListItem: ContactUserListItem, searchText?: string): boolean => {
  // logger.debug('modelHelpers.contact.isItemATextSearchHit called.', { searchText, showOrg })
  if (!searchText) {
    return true
  }
  const cleanSearchText = searchText && searchText.trim().toLowerCase()
  if (!cleanSearchText) {
    return true
  }
  if (cleanSearchText.length < 3) {
    if (contactUserListItem.username && contactUserListItem.username.toLowerCase().startsWith(cleanSearchText)) {
      return true
    }
    if (
      contactUserListItem.nickname &&
      contactUserListItem.nickname.toLowerCase().startsWith(cleanSearchText)
    ) {
      return true
    }
    const parsedName = parseName(contactUserListItem.fullName)
    if (parsedName) {
      if (parsedName.first && parsedName.first.toLowerCase().startsWith(cleanSearchText)) {
        return true
      }
      if (parsedName.last && parsedName.last.toLowerCase().startsWith(cleanSearchText)) {
        return true
      }
    }
  } else {
    try {
      const reg = new RegExp(cleanSearchText, 'i')
      if (
        (contactUserListItem.fullName && contactUserListItem.fullName.match(reg)) ||
        (contactUserListItem.username && contactUserListItem.username.match(reg)) ||
        (contactUserListItem.nickname && contactUserListItem.nickname.match(reg)) // ||
        // (contactUserListItem.email && contactUserListItem.email.match(reg)) ||
        // (contactUserListItem.phoneNumber && contactUserListItem.phoneNumber.match(reg))
      ) {
        return true
      }
    } catch (error) {
      return false
    }
  }
  return false
}

export default isItemATextSearchHit
