import moment from 'moment'
import React from 'react'
import { chatboxOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

import './styles.css'
import type { ContactUserListItem } from '../../lib/core/definitions'

type Props = {
  activeUserId: string | null | undefined
  contact: ContactUserListItem
  className?: string
  onClick?: () => void
}

const LatestChatMessageInfo: React.FC<Props> = (props): JSX.Element | null => {
  const {
    activeUserId,
    contact,
    className,
    onClick,
  } = props

  if (!contact || !contact.latestChatMessageCreatedAt) {
    return (
      <div className={`latest-chat-message-info ${className || ''}`} onClick={onClick}>
        <div className='row0'>
          <IonIcon icon={chatboxOutline} className='icon' />
          <div className='text' style={{ color: 'var(--text-color-light' }}>
            No messages yet
          </div>
        </div>
      </div>
      )
  }

  let youInfo: JSX.Element | undefined
  if (contact.latestChatMessageFromUserId === activeUserId) {
    youInfo = <span>You: </span>
  }

  let sentAt: JSX.Element | undefined
  if (contact.latestChatMessageCreatedAt) {
    const sentAtText = /^\d+$/.test(contact.latestChatMessageCreatedAt)
      ? moment(parseInt(contact.latestChatMessageCreatedAt, 10)).from(moment())
      : moment(contact.latestChatMessageCreatedAt).from(moment())
    sentAt = (
      <div className='sent-at'>
        {sentAtText}
      </div>
    )
  }

  return (
    <div className={`latest-chat-message-info ${className || ''}`} onClick={onClick}>
      <div className='row0'>
        <IonIcon icon={chatboxOutline} className='icon' />
        <div className='text'>
          {youInfo}{contact.latestChatMessage || '(image or attachment)'}
        </div>
      </div>
      {sentAt}
    </div>
  )
}

export default LatestChatMessageInfo
