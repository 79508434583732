import React, { useState } from 'react'
import { IonButton, IonInput, IonSelect, IonSelectOption } from '@ionic/react'

import './styles.css'
import type { AdminTaskInput } from '../../../lib/core/admin/definitions'
import { AdminTaskType } from '../../../lib/core/admin/enum'
import coreHelpers from '../../../lib/core/helpers'
import FormItem from '../../../components/FormItem/FormItem'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'

type Props = {
  isProcessing: boolean;
  onGoBack: () => void;
  onSubmit: (input: AdminTaskInput) => void;
};

const ExecuteAdminTaskForm: React.FC<Props> = ({
  isProcessing,
  onSubmit,
  onGoBack,
}): JSX.Element => {
  const [taskType, setTaskType] = useState<AdminTaskType | undefined>()
  const [param0, setParam0] = useState<string | undefined>()
  const [param1, setParam1] = useState<string | undefined>()
  const [param2, setParam2] = useState<string | undefined>()
  const [param3, setParam3] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const isDirty = (
    !!taskType ||
    !!param3 ||
    !!param0 ||
    !!param1 ||
    !!param2
  )
  const isValid = !!taskType

  const resetForm = (): void => {
    setTaskType(undefined)
    setParam0(undefined)
    setParam1(undefined)
    setParam2(undefined)
    setParam3(undefined)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeTaskType = (event: any): void => {
    setTaskType(event.detail.value)
  }

  const onChangeParam0 = (event: any): void => {
    setParam0(event.detail.value)
  }

  const onChangeParam1 = (event: any): void => {
    setParam1(event.detail.value)
  }

  const onChangeParam2 = (event: any): void => {
    setParam2(event.detail.value)
  }

  const onChangeParam3 = (event: any): void => {
    setParam3(event.detail.value)
  }

  const onClickSubmitButton = (event: any): void => {
    event.preventDefault()
    if (!taskType) {
      return
    }
    const updatedTag: AdminTaskInput = {
      taskType,
      param0,
      param1,
      param2,
      param3,
    }
    onSubmit(updatedTag)
  }

  // ===================================================================================================================
  // Rendering:
  const taskTypeOptions = Object.values(AdminTaskType)
    .filter(taskType => !!coreHelpers.type.adminTaskType.getLabel(taskType))
    .sort((taskType1, taskType2) => (
      coreHelpers.type.adminTaskType.getLabel(taskType1)
        .localeCompare(coreHelpers.type.adminTaskType.getLabel(taskType2))
    ))
    .map(taskType => (
      <IonSelectOption key={taskType} value={taskType}>
        {coreHelpers.type.adminTaskType.getLabel(taskType)}
      </IonSelectOption>
    ))

  const paramVals = [
    param0,
    param1,
    param2,
    param3,
  ]

  const paramChangeHandlers = [
    onChangeParam0,
    onChangeParam1,
    onChangeParam2,
    onChangeParam3,
  ]

  let paramInputs: JSX.Element[] | undefined
  if (taskType) {
    const paramDefs = coreHelpers.type.adminTaskType.getParamDefs(taskType)
    if (paramDefs) {
      paramInputs = paramDefs.map((paramDef, index) => {
        let description: JSX.Element | undefined
        if (paramDef.description) {
          description = (
            <div className='withDoubleBottomMargin smallText lightText'>
              {paramDef.description}
            </div>
          )
        }
        return (
          <div key={paramDef.name}>
            <FormItem
              label={paramDef.label || paramDef.name}
              withBottomMargin={!paramDef.description}
            >
              <IonInput
                onIonChange={paramChangeHandlers[index]}
                value={paramVals[index]}
              />
            </FormItem>
            {description}
          </div>
        )
      })
    }
  }

  return (
    <form onSubmit={onClickSubmitButton}>
      <FormItem label='Task' withBottomMargin>
        <IonSelect
          value={taskType}
          interface='action-sheet'
          onIonChange={onChangeTaskType}
        >
          {taskTypeOptions}
        </IonSelect>
      </FormItem>
      {paramInputs}
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onGoBack}
        >
          Back
        </IonButton>
        <SubmitButton
          onClick={onClickSubmitButton}
          disabled={!isDirty || !isValid}
          isProcessing={isProcessing}
        >
          Execute!
        </SubmitButton>
      </div>
    </form>
  )
}

export default ExecuteAdminTaskForm
