import React, { useState } from 'react'
import { IonButton } from '@ionic/react'

import './styles.css'
import type { PurchaseListItem } from '../../../../lib/core/definitions'
import ListScopeHeader from '../../ListScopeHeader/ListScopeHeader'
import ContactPurchaseList from './ContactPurchaseList/ContactPurchaseList'

enum ListScope {
  SENT = 'sent',
  RECEIVED = 'received',
}

type Props = {
  purchasesISent: PurchaseListItem[] | null | undefined
  purchasesIReceived: PurchaseListItem[] | null | undefined
  contactName: string | null | undefined
  onOpenPurchase: (purchaseId: string) => void
  onNewGift: (() => void) | undefined
}

const GiftsSection: React.FC<Props> = (props): JSX.Element => {
  const {
    purchasesISent,
    purchasesIReceived,
    contactName,
    onOpenPurchase,
    onNewGift,
  } = props

  // ===================================================================================================================
  // State:
  const [scope, setScope] = useState(ListScope.SENT)

  // ===================================================================================================================
  // Event Handlers:
  const onChangeScope = (newScope?: string) => {
    setScope(newScope as ListScope ||
      (scope === ListScope.SENT ? ListScope.RECEIVED : ListScope.SENT))
  }

  // ===================================================================================================================
  // Rendering:
  let content: JSX.Element
  if (
    (
      scope === ListScope.SENT &&
      (!Array.isArray(purchasesISent) || purchasesISent.length < 1)
    ) ||
    (
      scope === ListScope.RECEIVED &&
      (!Array.isArray(purchasesIReceived) || purchasesIReceived.length < 1)
    )
  ) {
    const text = scope === ListScope.SENT
      ? (
          <div className='withStandardPadding'>
            You haven&apos;t sent {contactName || 'this contact'} a gift yet.
          </div>
        )
      : (
        <div className='withStandardPadding'>
          {contactName || 'this contact'} has not sent you a gift yet.
          You could send them one first!
        </div>
        )
    content = (
      <div className='withDoubleTopMargin'>
        {text}
        <div className='withCenteredColumnContent'>
          <IonButton
            className='withDoubleTopMargin'
            onClick={() => onNewGift ? onNewGift() : undefined}
          >
            Send a Gift
          </IonButton>
        </div>
      </div>
    )
  } else {
    const purchaseList = scope === ListScope.SENT ? purchasesISent : purchasesIReceived
    content = (
      <ContactPurchaseList
        purchases={purchaseList}
        onOpenPurchase={onOpenPurchase}
      />
    )
  }

  return (
    <div className='gifts-section'>
      <ListScopeHeader
        values={[ListScope.SENT, ListScope.RECEIVED]}
        labels={['Sent', 'Received']}
        curValue={scope}
        className='withStandardTopMargin'
        onChange={onChangeScope}
      />
      {content}
    </div>
  )
}

export default GiftsSection
