import type { ReactElement } from 'react'
import React from 'react'
import Text from './Text'

type Props = {
  htmlElement: ReactElement | null
}

const Strong: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('###########################Strong.render called.', { props })
  const { htmlElement } = props
  if (!htmlElement) {
    return null
  }
  return (
    // <Text style={style.strong} htmlElement={htmlElement} />
    <Text style={{ fontWeight: 'bold' }} htmlElement={htmlElement} />
  )
}
export default Strong
