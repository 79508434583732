import { ApolloClient, InMemoryCache } from '@apollo/client'
import type { ApolloConfig } from './definitions'
import type { EnvironmentVal } from '../../lib/core/definitions'
import configStore from './config'
import getLink from './getLink'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import introspectionResult from './types.gen'
import serviceData from './serviceData'

const initApollo = (config: ApolloConfig, environment?: EnvironmentVal): void => {
  configStore.setConfig(config)

  const selectedEnvironment = environment || globalCacheData.getEnvironment()

  if (serviceData.getClient(selectedEnvironment)) {
    // Once created, the client can't be re-created, apparently.
    return
  }

  // see https://graphql-code-generator.com/docs/plugins/fragment-matcher#usage-with-apollo-client-3
  const cache = new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
  })

  // see: https://github.com/apollographql/apollo-cache-persist
  // await persistCache({
  //   cache,
  //   storage: window.localStorage,
  // })

  const link = getLink()
  console.log('Initializing Apollo', { config, link, environment: selectedEnvironment })

  const client = new ApolloClient({ link, cache })
  // @ts-ignore
  client.endpoint = config.graphQlEndpoint
  serviceData.setClient(client, selectedEnvironment)

  // console.log('services.apollo.init: done.')
}

export default initApollo
