import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, StoredProduct, StoredProductListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  StoredProductQueryData,
  StoredProductQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'

const loadStoredProduct = (
  storedProductId: string,
  isInTargetStateFunc: ((storedProduct: StoredProduct) => boolean) | 'watch-updated-at' | undefined,
  listFilter: StoredProductListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<StoredProduct | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadStoredProduct called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadStoredProduct: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<StoredProductQueryVariables> = {
      query: apollo.admin.queries.storedProduct,
      variables: { storedProductId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<StoredProductQueryData, StoredProductQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.storedProduct ||
            !resp.data.storedProduct.id
          ) {
            logger.error('api.loadStoredProduct.load: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((storedProduct: StoredProduct) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.storedProduct.updatedAt
            activeIsInTargetStateFunc = (storedProduct: StoredProduct) => storedProduct.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.storedProduct)
          ) {
            const queryListVariables = clientOptions && clientOptions.updateList
              ? apollo.getStoredProductsQueryVariables(listFilter)
              : undefined
            cache.updateStoredProduct(
              resp.data.storedProduct,
              queryListVariables,
              apolloClient,
            )
            // console.log('api.loadStoredProduct.load: resolving with info.', resp.data.storedProduct)
            resolve(resp.data.storedProduct)
            return
          }

          // console.log('api.loadPstoredProduct.load: pstoredProducts not in target state.',
          //   { pstoredProduct: resp.data.pstoredProduct })
          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadStoredProduct.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadStoredProduct.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadStoredProduct
