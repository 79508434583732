import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type {
  FavoriteOrganizationsQueryData,
  FavoriteOrganizationsQueryVariables,
} from '../../apollo/definitions'
import type { FavoriteOrganizationListFilter } from '../../../lib/core/definitions'
import apollo from '../../apollo'
import logger from '../../logger'

const deleteFavoriteOrganization = (
  favoriteOrganizationId: string | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.deleteFavoriteOrganization called.')

  if (!apolloClient) {
    logger.error('api.cache.deleteFavoriteOrganization: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  const filter: FavoriteOrganizationListFilter = {
    userId: activeUserId,
    orderBy: 'organizationId',
  }
  const favoriteOrganizationsVariables = { filter }

  const cachedFavoriteOrganizationsData = apolloClient.readQuery<FavoriteOrganizationsQueryData, FavoriteOrganizationsQueryVariables>({
    query: apollo.queries.favoriteOrganizations,
    variables: favoriteOrganizationsVariables,
  })
  // console.log('api.cache.deleteFavoriteOrganization: loaded cached favoriteOrganizations data.', { cachedFavoriteOrganizationsData })

  if (cachedFavoriteOrganizationsData && cachedFavoriteOrganizationsData.favoriteOrganizations) {
    const updatedFavoriteOrganizations = cachedFavoriteOrganizationsData.favoriteOrganizations.filter(o => o.id !== favoriteOrganizationId)
    // console.log('api.cache.deleteFavoriteOrganization: updated favoriteOrganizations=', { updatedFavoriteOrganizations })

    apolloClient.writeQuery<FavoriteOrganizationsQueryData>({
      query: apollo.queries.favoriteOrganizations,
      variables: favoriteOrganizationsVariables,
      data: { favoriteOrganizations: updatedFavoriteOrganizations },
    })
  }
}

export default deleteFavoriteOrganization
