import type { ApolloClient, MutationOptions } from '@apollo/client'

import type {
  ApiQueryOptions,
  ProductListFilter,
  Product,
  ProductInput,
} from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertProductMutationData,
  UpsertProductMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadProduct from './loadProduct'
import logger from '../logger'

const upsertProduct = (
  productInput: ProductInput,
  isInTargetStateFunc: ((product: Product) => boolean) | 'watch-updated-at' | undefined,
  listFilter: ProductListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Product | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.upsertProduct called.', { productInput })
    if (!apolloClient) {
      logger.error('api.upsertProduct: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertProductMutationData, UpsertProductMutationVariables> = {
      mutation: apollo.admin.mutations.upsertProduct,
      variables: { product: productInput },
    }

    apolloClient.mutate<UpsertProductMutationData, UpsertProductMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertProduct: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertProduct ||
          !resp.data.upsertProduct.id
        ) {
          logger.error('api.upsertProduct: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((product: Product) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!productInput.id) {
            logger.error('api.upsertProduct: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertProduct.updatedAt
            activeIsInTargetStateFunc = (product: Product) => product.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertProduct)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getProductsQueryVariables(listFilter)
            : undefined
          cache.updateProduct(
            resp.data.upsertProduct,
            queryListVariables,
            apolloClient,
          )
          resolve(resp.data.upsertProduct)
          return
        }

        const productId = resp.data.upsertProduct.id

        setTimeout(() => {
          // console.log('api.upsertProduct: calling loadProduct')
          loadProduct(
            productId,
            activeIsInTargetStateFunc,
            listFilter,
            apolloClient,
            undefined,
            queryOptions,
            clientOptions,
          )
            .then((product) => {
              // console.log('api.upsertProduct: resolving with product', product)
              resolve(product)
            }, (error) => {
              logger.error('api.upsertProduct: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertProduct: error', { error })
        reject(error)
      })
  })
)

export default upsertProduct
