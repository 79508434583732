import React, { useEffect, useState } from 'react'
import { IonButton, IonIcon, IonInput, IonSpinner, IonTextarea, isPlatform } from '@ionic/react'
import { bookmarkOutline, checkmark, copyOutline, linkOutline, personOutline } from 'ionicons/icons'
import { parsePhoneNumber } from 'libphonenumber-js/mobile'
import { useQuery } from '@apollo/client'

import './styles.css'
import { BarcodeType, FundType, RecordStatus, RewardType, TokenName } from '../../lib/core/enums'
import type { Invitation, InvitationInput, UserIdentInfo } from '../../lib/core/definitions'
import type { RewardConfigsQueryData } from '../../services/apollo/definitions'
import { SelectPersonMode } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import apollo from '../../services/apollo'
import appLinks from '../../lib/core/appLinks'
import Barcode from '../../components/Barcode/Barcode'
import coreHelpers from '../../lib/core/helpers'
import FormItem from '../../components/FormItem/FormItem'
import helpers from './helpers'
import logger from '../../services/logger'
import pageHelpers from '../../helpers/pageHelpers'
import validationHelpers from '../../helpers/validationHelpers'

type Props = {
  invitation?: Invitation | null
  isProcessing: boolean
  isUpsertingInvitation: boolean
  goBack: () => void
  onUpsertInvitation: (invitation: InvitationInput) => void
  showUiMessage: (message: string) => void
}

const requireSenderEmailWhenSendingViaEmail = false
const requireSenderPhoneNumberWhenSendingViaSms = false

const SendByMemberContent: React.FC<Props> = (props): JSX.Element | null => {
  const {
    invitation,
    isProcessing,
    isUpsertingInvitation,
    goBack,
    onUpsertInvitation,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const { getActiveUser } = useGlobalCache()
  const localUser = getActiveUser()
  const userCanPurchase = coreHelpers.models.user.canPurchase(localUser)
  const { activeUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [selectPersonMode, setSelectPersonMode] = useState<SelectPersonMode>(SelectPersonMode.NOT_SET)

  const [fromUserFullName, setFromUserFullName] = useState<string | undefined>()
  const [fromUserFullNameValidationError, setFromUserFullNameValidationError] = useState<string | undefined>()
  const [fromUserEmail, setFromUserEmail] = useState<string | undefined>()
  const [fromUserEmailValidationError, setFromUserEmailValidationError] = useState<string | undefined>()
  const [fromUserPhoneNumber, setFromUserPhoneNumber] = useState<string | undefined>()
  const [fromUserPhoneNumberValidationError, setFromUserPhoneNumberValidationError] = useState<string | undefined>()

  const [selectedUser, setSelectedUser] = useState(undefined as UserIdentInfo | undefined)
  const [toUserEmail, setToUserEmail] = useState<string | undefined>()
  const [toUserPhoneNumber, setToUserPhoneNumber] = useState<string | undefined>()
  const [toUserFullName, setToUserFullName] = useState<string | undefined>()
  const [selectUserInputIsValid, setSelectUserInputIsValid] = useState(true)

  const [toUserFullNameValidationError, setToUserFullNameValidationError] = useState<string | undefined>()

  const [messageText, setMessageText] = useState<string | undefined>()
  const [messageTextValidationError, setMessageTextValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading reward configs:
  const { data: rewardConfigsData } = useQuery<RewardConfigsQueryData>(
    apollo.queries.rewardConfigs, {
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
    },
  )
  const rewardConfigs = rewardConfigsData ? rewardConfigsData.rewardConfigs : undefined
  const rewardConfig = rewardConfigs && rewardConfigs
    .find(r => (
      r.rewardType ===
      RewardType.NEW_USER_INVITATION &&
      r.invitationLevel === 0 &&
      r.recordStatus === RecordStatus.ACTIVE
    ))

  // ===================================================================================================================
  // Helpers:
  const inviteCode = activeUser ? activeUser.inviteCode : ''
  const inviteLink = appLinks.invite(inviteCode || '')

  const resetState = (): void => {
    setSelectPersonMode(SelectPersonMode.NOT_SET)
    setFromUserFullName('')
    setFromUserFullNameValidationError('')
    setFromUserEmail('')
    setFromUserEmailValidationError('')
    setToUserFullName('')
    setToUserFullNameValidationError('')
    setSelectedUser(undefined)
    setMessageText(undefined)
    setMessageTextValidationError('')
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    if (!activeUser && selectPersonMode !== SelectPersonMode.NOT_SET) {
      resetState()
    }
  }, [activeUser])

  // ===================================================================================================================
  // Helpers:
  const isValid = (
    !fromUserFullNameValidationError &&
    !fromUserEmailValidationError &&
    !toUserFullNameValidationError &&
    !messageTextValidationError &&
    (selectedUser || !!toUserFullName) &&
    ((activeUser && !!activeUser.fullName) || !!fromUserFullName)
  )
  const haveValidRecipientInfo = (
    (selectPersonMode === SelectPersonMode.EMAIL && toUserEmail) ||
    (selectPersonMode === SelectPersonMode.PHONE_NUMBER && toUserPhoneNumber)
  )
  const toUserFullNameComposed = toUserFullName || (selectedUser ? selectedUser.fullName : '')
  const toUserFirstNameComposed = toUserFullNameComposed ? ` ${toUserFullNameComposed.split(' ')[0]}` : ''
  let senderName = fromUserFullName
  if (!senderName && activeUser) {
    senderName = activeUser.fullName || ''
  }
  if (senderName) {
    senderName = senderName.split(' ')[0]
  }
  const defaultMessageText = `Hi${toUserFirstNameComposed},

I want to invite you to become a Mimble member!

Best,
${senderName}`

  const readyToSubmit = (
    isValid &&
    selectUserInputIsValid &&
    !isProcessing &&
    toUserFullNameComposed &&
    (toUserEmail || toUserPhoneNumber) &&
    (messageText || messageText === undefined)
  )
  // console.log('SendGiftPage.render called.', {
  //   isValid,
  //   selectUserInputIsValid,
  //   toUserFullNameComposed,
  //   selectedUser,
  //   toUserEmail,
  //   toUserPhoneNumber,
  // })

  // ===================================================================================================================
  // Event Handlers:
  const onCopyInviteCodeToClipboard = (): void => {
    if (activeUser && activeUser.inviteCode) {
      pageHelpers.copyInputTextToClipboard(
        activeUser.inviteCode,
        'Invite Code',
        showUiMessage,
      )
    }
  }

  const onCopyInviteLinkToClipboard = (): void => {
    if (activeUser && activeUser.inviteCode) {
      pageHelpers.copyInputTextToClipboard(
        inviteLink,
        'Invite Link',
        showUiMessage,
      )
    }
  }

  const onChangeSelectPersonMode = (value: SelectPersonMode): void => {
    setSelectPersonMode(value)
    if (selectedUser) {
      setSelectedUser(undefined)
    }
  }

  const onChangeFromUserFullName = (event: any): void => {
    setFromUserFullName(event.detail.value)
    if (event.detail.value) {
      setFromUserFullNameValidationError(validationHelpers.validateUserFullName(event.detail.value))
    } else {
      setFromUserFullNameValidationError('required')
    }
  }

  const onChangeFromUserEmail = (event: any): void => {
    setFromUserEmail(event.detail.value)
    if (event.detail.value) {
      setFromUserEmailValidationError(validationHelpers.validateEmail(event.detail.value))
    } else {
      setFromUserEmailValidationError('')
    }
  }

  const onChangeFromUserPhoneNumber = (event: any): void => {
    setFromUserPhoneNumber(event.detail.value)
    if (event.detail.value) {
      setFromUserPhoneNumberValidationError(validationHelpers.validatePhoneNumber(event.detail.value))
    } else {
      setFromUserPhoneNumberValidationError('')
    }
  }

  const onChangeToUserEmailOrPhoneNumber = (value: string): void => {
    if (selectPersonMode === SelectPersonMode.EMAIL) {
      setToUserEmail(value)
    } else if (selectPersonMode === SelectPersonMode.PHONE_NUMBER) {
      setToUserPhoneNumber(value)
    }
  }

  const onChangeToUserFullName = (event: any): void => {
    setToUserFullName(event.detail.value)
    if (event.detail.value) {
      setToUserFullNameValidationError(validationHelpers.validateUserFullName(event.detail.value))
    } else {
      setToUserFullNameValidationError('required')
    }
  }

  const onChangeSelectUserInputIsValid = (isValid: boolean): void => {
    setSelectUserInputIsValid(isValid)
  }

  const onChangeMessageText = (event: any): void => {
    if (event.detail.value !== defaultMessageText) {
      setMessageText(event.detail.value)
      if (event.detail.value) {
        setMessageTextValidationError(validationHelpers.validatePurchaseTransferMessageText(event.detail.value))
      } else {
        setMessageTextValidationError('')
      }
    }
  }

  const onFormSubmit = (): void => {
    if (!isValid || !activeUser) {
      return
    }
    let senderEmail = fromUserEmail
    if (!senderEmail && activeUser) {
      senderEmail = activeUser.email || ''
    }
    let senderPhoneNumber = fromUserPhoneNumber
    if (!senderPhoneNumber && activeUser) {
      senderPhoneNumber = activeUser.phoneNumber || ''
    }
    const invitationInput: InvitationInput = {
      fromUserId: activeUser.id,
      fromUserFullName,
      fromUserEmail: senderEmail,
      fromUserPhoneNumber: senderPhoneNumber,
      toUserFullName,
      message: messageText || defaultMessageText,
    }
    if (toUserEmail) {
      invitationInput.toUserEmail = toUserEmail
    }
    if (toUserPhoneNumber) {
      try {
        const phoneNumberInfo = parsePhoneNumber(toUserPhoneNumber, 'US')
        if (phoneNumberInfo && phoneNumberInfo.isValid()) {
          invitationInput.toUserPhoneNumber = phoneNumberInfo.number as string
        }
      } catch (error) {
        logger.warn((error as Error).message)
      }
    }

    onUpsertInvitation(invitationInput)
  }

  const onShare = () => {
    if (!inviteCode || !inviteLink) {
      return
    }

    if (isPlatform('capacitor')) {
      helpers.shareUsingCapacitorPlugIn(inviteLink, showUiMessage)
      return
    }

    helpers.shareUsingEmail(inviteLink, inviteCode, showUiMessage)
  }

  // ===================================================================================================================
  // Rendering:
  const sections: JSX.Element[] = []

  sections.push(
    <div key='intro1' className='lightText'>
      <p>
        To invite a new Mimble member, please fill out this form.
      </p>
    </div>,
  )

  let recipientCaption
  if (
    activeUser &&
    !activeUser.fullName &&
    (activeUser.email || activeUser.phoneNumber || activeUser.username)
  ) {
    const inputItems: JSX.Element[] = [
      <FormItem
        key='sender-name'
        label='Your Name'
        validationError={fromUserFullNameValidationError}
        withBottomMargin
      >
        <IonInput
          autofocus
          onIonChange={onChangeFromUserFullName}
          type='text'
          autocapitalize='on'
          placeholder='Your name'
          value={fromUserFullName}
        />
      </FormItem>,
    ]

    if (
      requireSenderEmailWhenSendingViaEmail &&
      selectPersonMode === SelectPersonMode.EMAIL &&
      activeUser &&
      !activeUser.email
    ) {
      inputItems.push(
        <FormItem
          key='sender-email'
          label='Your Email'
          validationError={fromUserEmailValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeFromUserEmail}
            type='text'
            placeholder='i.e. joethesailor@supermail.com'
            value={fromUserEmail}
          />
        </FormItem>,
      )
    }
    if (
      requireSenderPhoneNumberWhenSendingViaSms &&
      selectPersonMode === SelectPersonMode.PHONE_NUMBER &&
      activeUser &&
      !activeUser.phoneNumber
    ) {
      inputItems.push(
        <FormItem
          key='sender-phone'
          label='Your Phone number'
          validationError={fromUserPhoneNumberValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeFromUserPhoneNumber}
            type='text'
            placeholder='i.e. 1234567890'
            value={fromUserPhoneNumber}
          />
        </FormItem>,
      )
    }
    sections.push(
      <div key='sender' className='section'>
        <h2>About You - The Sender</h2>
        {inputItems}
      </div>,
    )
    recipientCaption = <h2>About The Recipient</h2>
  }

  // sections.push(
  //   <div key='i-want-to' className='withDoubleBottomMargin'>
  //     {recipientCaption}
  //     <div className=''>
  //       For the recipient, I want to enter their:
  //     </div>
  //     <SelectPersonModeInput
  //       value={selectPersonMode}
  //       userCanBeAMember={false}
  //       enableSelectFromContacts={Array.isArray(activeUserContacts) && activeUserContacts.length > 0}
  //       onChange={onChangeSelectPersonMode}
  //     />
  //   </div>,
  // )

  if (selectPersonMode !== SelectPersonMode.NOT_SET) {
    // sections.push(
    //   <div key='select-user' className='withStandardBottomMargin'>
    //     <SelectUserInput
    //       mode={selectPersonMode}
    //       userCanBeAMember={false}
    //       activeUserId={activeUser ? activeUser.id || '' : ''}
    //       showFoundUserInfo={false}
    //       disallowSelectingActiveUser
    //       onChangeInputIsValid={onChangeSelectUserInputIsValid}
    //       onChangeInput={onChangeToUserEmailOrPhoneNumber}
    //     />
    //   </div>,
    // )
  }

  if (selectedUser) {
    sections.push(
      <div key='selected-user-info'>
        <div className='rowWithCenterAlignedItems withSmallBottomMargin'>
          <IonIcon slot='start' icon={checkmark} />
          <div className='smallText lightText'>Member Found:</div>
        </div>
        <div key='found-user' className='found-user'>
          <div className='found-user-col0'>
            <IonIcon slot='start' icon={personOutline} style={{ width: '2rem', height: '2rem' }} />
          </div>
          <div className='found-user-col1'>
            <div className='withBoldText'>
              @{selectedUser.username}
            </div>
            <div>
              <span className='lightText'>
                Name:&nbsp;
              </span>
              {selectedUser.fullName || '-'}
            </div>
            <div>
              <span className='lightText xsmallText'>
                Member ID:&nbsp;
              </span>
              <span className='xsmallText'>
                {(selectedUser.id || '').replace(/-/g, '')}
              </span>
            </div>
          </div>
        </div>
      </div>,
    )
  }

  if (
    haveValidRecipientInfo &&
    (selectPersonMode as SelectPersonMode) === SelectPersonMode.FULL_NAME &&
    !(selectedUser && selectedUser.fullName)
  ) {
    sections.push(
      <FormItem
        key='to-user-full-name'
        label='Recipient Name'
        validationError={toUserFullNameValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeToUserFullName}
          type='text'
          autocapitalize='on'
          placeholder='recipient name'
          value={toUserFullName}
        />
      </FormItem>,
    )
  }

  if (selectPersonMode !== SelectPersonMode.NOT_SET && haveValidRecipientInfo) {
    const msgText = messageText === undefined ? defaultMessageText : messageText
    sections.push(
      <FormItem
        key='message-text'
        label='Message'
        validationError={messageTextValidationError}
        withBottomMargin
      >
        <IonTextarea
          rows={6}
          autocapitalize='sentences'
          onIonChange={onChangeMessageText}
          value={msgText}
        />
      </FormItem>,
    )
  }

  let activityIndicator
  if (isProcessing) {
    activityIndicator = (
      <IonSpinner className='withStandardLeftMargin' />
    )
  }
  sections.push(
    <div key='buttons' className='section'>
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={goBack}
        >
          Back
        </IonButton>
        <IonButton
          onClick={onFormSubmit}
          disabled={!readyToSubmit || isProcessing}
        >
          Submit
          {activityIndicator}
        </IonButton>
      </div>
    </div>,
  )

  let qrImage: JSX.Element | undefined
  if (activeUser && activeUser.inviteCode) {
    const qrImageUri = appLinks.invite(activeUser.inviteCode)
    qrImage = (
      <div className='withQuadrupleBottomMargin'>
        <Barcode
          value={qrImageUri}
          format={BarcodeType.QR_CODE}
          scale={3}
        />
      </div>
    )
  }

  let ctaText: JSX.Element | undefined
  if (rewardConfig && rewardConfig.amount && rewardConfig.amount > 0) {
    const formattedRewardAmount = coreHelpers.ui.formatAmount(
      rewardConfig.amount,
      FundType.TOKEN,
      TokenName.MIMBLE_TOKEN,
      true,
    )

    ctaText = (
      <>
        <div className='section'>
          Invite your friends and family to join Mimble with a free membership!
          As a thank you, we will send you&nbsp;
          <span className='withBoldText'>{formattedRewardAmount}</span>&nbsp;
          for each person that signs up. For all of those that join with your link,
          they will receive an additional reward for being invited by you.
        </div>
        <div className='section'>
          You also receive a reward each time members you invited place a purchase in Mimble&apos;s marketplace!
        </div>
      </>
    )
  } else {
    ctaText = (
      <div className='section withDoubleBottomMargin'>
        Invite your friends and family to join Mimble! You will earn rewards for their activities
        and they will receive a special reward by being invited by you!
      </div>
    )
  }

  const shareButtonLabel = isPlatform('capacitor') ? 'Share' : 'Send Email'

  return (
    <div className='send-by-member-content withDoubleTopMargin'>
      <h2>Invite others, earn MIT!</h2>
      {ctaText}
      <div>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={linkOutline} className='g-inline-copy-to-clipboard-icon withStandardRightMargin' />
          <div className='label'>Your personal invite link:</div>
        </div>
        <div className='withCenteredColumnContent'>
          <div className='rowWithCenterAlignedItems withPointerCursor withQuadrupleBottomMargin' onClick={onCopyInviteLinkToClipboard}>
            <span className='withBoldText linkText'>{inviteLink}</span>
            <IonIcon icon={copyOutline} className='g-inline-copy-to-clipboard-icon' />
          </div>
          {qrImage}
        </div>
      </div>
      <div className='withDoubleBottomMargin'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={bookmarkOutline} className='g-inline-copy-to-clipboard-icon withStandardRightMargin' />
          <div className='label'>Your personal invite code:</div>
        </div>
        <div className='withCenteredColumnContent'>
          <div className='rowWithCenterAlignedItems withPointerCursor' onClick={onCopyInviteCodeToClipboard}>
            <span className='withBoldText linkText' onClick={onCopyInviteCodeToClipboard}>{inviteCode}</span>
            <IonIcon icon={copyOutline} className='g-inline-copy-to-clipboard-icon' />
          </div>
        </div>
      </div>

      <div className='withDoubleTopMargin'>
        <IonButton
          expand='block'
          className='withStandardBottomMargin'
          onClick={onShare}
        >
          {shareButtonLabel}
        </IonButton>
        {/* }
        <IonButton
          expand='block'
          onClick={onSendBySMS}
        >
          Send a Text Message
        </IonButton>
        { */}
      </div>
      {/* <div className='section'>
        <IonIcon icon={mailOutline} className='g-inline-copy-to-clipboard-icon withStandardRightMargin' />
        Send this link:
        <br />
        <span className='withBoldText linkText' onClick={onCopyInviteLinkToClipboard}>{inviteLink}</span>
        <IonIcon icon={copyOutline} className='g-inline-copy-to-clipboard-icon' onClick={onCopyInviteLinkToClipboard} />
      </div> */}
    </div>
  )
}

export default SendByMemberContent
