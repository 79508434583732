import type { PurchaseTransfersQueryVariables } from '../definitions'
import type { PurchaseTransferListFilter } from '../../../lib/core/definitions'
import getPurchaseTransferListFilter from './getPurchaseTransferListFilter'

const getPurchaseTransfersQueryVariables = (
  listFilter: PurchaseTransferListFilter | undefined,
  activeUserId: string,
): PurchaseTransfersQueryVariables => ({
  filter: listFilter || getPurchaseTransferListFilter(activeUserId),
})

export default getPurchaseTransfersQueryVariables
