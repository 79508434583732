import { ErrorCode } from '../../enums'

const labelsEn = Object.freeze({
  [ErrorCode.ALREADY_EXISTS]: 'Already exists',
  [ErrorCode.AUTH_TOKEN_NO_MATCH]: 'The authentication token did not match',
  [ErrorCode.BLOCKCHAIN_ADDRESS_IN_USE]: 'The blockchain address is in use',
  [ErrorCode.CURRENT_PASSWORD_INCORRECT]: 'The current password could not be verified',
  [ErrorCode.CURRENT_PASSWORD_MISSING]: 'The current password was not provided',
  [ErrorCode.DATA_VALIDATION_FAILED]: 'The data validation failed',
  [ErrorCode.EXCEEDS_LIMIT]: 'The request exceeds the limit.',
  [ErrorCode.INVALID_INPUT]: 'Invalid input',
  [ErrorCode.INVALID_PUSH_NOTIFICATION_TOKEN]: 'Invalid push notification token',
  [ErrorCode.INVENTORY_NOT_ACTIVE]: 'Inventory is not active',
  [ErrorCode.NO_AVAILABLE_STORED_PRODUCT]: 'No available stored product found',
  [ErrorCode.NO_LIVE_WEBSOCKET_CONNECTION_AVAILABLE]: 'No live websocket connection available',
  [ErrorCode.NO_NOTIFICATION_METHOD_AVAILABLE]: 'No notification method available',
  [ErrorCode.NOT_FOUND]: 'Not found',
  [ErrorCode.NOT_IMPLEMENTED]: 'Not implemented yet',
  [ErrorCode.NOT_SUPPORTED]: 'The requested operation is not supported',
  [ErrorCode.ORDER_NOT_FOUND]: 'Order not found for purchase',
  [ErrorCode.PASSWORD_NO_MATCH]: 'Passwords do not match',
  [ErrorCode.PHONE_NUMBER_NOT_SUPPORTED]: 'Phone number not supported',
  [ErrorCode.PRODUCT_NOT_AVAILABLE_FOR_PURCHASE]: 'Product not available for purchase',
  [ErrorCode.PURCHASE_CANNOT_BE_DELETED]: 'Purchase cannot be deleted',
  [ErrorCode.PURCHASE_HAS_NO_PRODUCT]: 'Purchase has no product',
  [ErrorCode.PURCHASE_HAS_NO_PRODUCT_OPTION]: 'Purchase has no product option',
  [ErrorCode.PURCHASE_HAS_NO_USER_ID]: 'Purchase has no member ID',
  [ErrorCode.PURCHASE_HAS_TRANSFERS]: 'Purchase has already been transferred',
  [ErrorCode.PURCHASE_NOT_AVAILABLE_FOR_TRANSFER]: 'Purchase not available for transfer',
  [ErrorCode.PURCHASE_NOT_FOUND]: 'Purchase not found',
  [ErrorCode.PURCHASE_TRANSFER_CANNOT_BE_CANCELED]: 'Purchase cannot be canceled',
  [ErrorCode.PURCHASING_NOT_ACTIVE]: 'Purchasing service is not active',
  [ErrorCode.RECIPIENT_NOT_AVAILABLE_FOR_TRANSFER]: 'Recipient not available for transfer',
  [ErrorCode.SERVICE_NOT_ACTIVE]: 'Service not active',
  [ErrorCode.SYSTEM_ERROR]: 'System error',
  [ErrorCode.TIMEOUT]: 'Connection has timed out',
  [ErrorCode.TOO_MANY_REQUESTS]: 'Too many requests',
  [ErrorCode.UNAUTHORIZED]: 'Unauthorized',
  [ErrorCode.USER_ALREADY_EXISTS]: 'Member account already exists',
  [ErrorCode.USER_CANNOT_RECEIVE_PRODUCT]: 'Recipient member cannot receive a purchase transfer',
})

const ERROR_CODES_SUPPORTED_BY_UI = [
  // ErrorCode.AUTH_TOKEN_NO_MATCH,
  // ErrorCode.BLOCKCHAIN_ADDRESS_IN_USE,
  ErrorCode.CURRENT_PASSWORD_INCORRECT,
  ErrorCode.CURRENT_PASSWORD_MISSING,
  // ErrorCode.DATA_VALIDATION_FAILED,
  ErrorCode.EXCEEDS_LIMIT,
  // ErrorCode.INVALID_INPUT,
  // ErrorCode.INVENTORY_NOT_ACTIVE,
  // ErrorCode.NO_AVAILABLE_STORED_PRODUCT,
  // ErrorCode.NO_LIVE_WEBSOCKET_CONNECTION_AVAILABLE,
  // ErrorCode.NOT_FOUND,
  // ErrorCode.NOT_IMPLEMENTED,
  // ErrorCode.ORDER_NOT_FOUND
  ErrorCode.PASSWORD_NO_MATCH,
  ErrorCode.PHONE_NUMBER_NOT_SUPPORTED,
  // ErrorCode.PRODUCT_NOT_AVAILABLE_FOR_PURCHASE,
  // ErrorCode.PURCHASE_CANNOT_BE_DELETED,
  // ErrorCode.PURCHASE_HAS_NO_PRODUCT,
  // ErrorCode.PURCHASE_HAS_NO_PRODUCT_OPTION,
  // ErrorCode.PURCHASE_HAS_NO_USER_ID,
  // ErrorCode.PURCHASE_HAS_TRANSFERS,
  // ErrorCode.PURCHASE_NOT_AVAILABLE_FOR_TRANSFER,
  // ErrorCode.PURCHASE_NOT_FOUND,
  ErrorCode.PURCHASE_TRANSFER_CANNOT_BE_CANCELED,
  // ErrorCode.PURCHASING_NOT_ACTIVE,
  // ErrorCode.RECIPIENT_NOT_AVAILABLE_FOR_TRANSFER,
  // ErrorCode.SERVICE_NOT_ACTIVE,
  // ErrorCode.SYSTEM_ERROR,
  ErrorCode.TIMEOUT,
  // ErrorCode.TOO_MANY_REQUESTS,
  // ErrorCode.UNAUTHORIZED,
  // ErrorCode.USER_ALREADY_EXISTS,
  // ErrorCode.USER_CANNOT_RECEIVE_PRODUCT,
  ErrorCode.NOT_SUPPORTED,
]

export default {
  getLabel: (code: ErrorCode | null | undefined, language = 'en'): string => {
    if (!code) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[code]
    }
    return ''
  },
  isValid: (method: ErrorCode): boolean => Object.values(ErrorCode).includes(method),
  isSupportedByUi: (code: ErrorCode): boolean => ERROR_CODES_SUPPORTED_BY_UI.includes(code),
}
