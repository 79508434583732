import React from 'react'
import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/react'

import './styles.css'
import { CdnAssetType } from '../../../services/cdn/enums'
import type { MarketplaceItem } from '../../../lib/core/definitions'
import type { MerchantListScope } from '../../../lib/core/enums'
import cdn from '../../../services/cdn'
import GiftCard from '../../../components/GiftCard/GiftCard'

type Props = {
  searchText?: string;
  scope?: MerchantListScope;
  products?: MarketplaceItem[] | null

  onSelectProduct: (merchantId: string) => void;
};

const filterList = (
  list: MarketplaceItem[],
  searchText?: string,
): MarketplaceItem[] => {
  if (!searchText) {
    return list
  }
  return list.filter((marketplaceItem: MarketplaceItem): boolean => {
    if (!marketplaceItem) {
      return false
    }
    if (!searchText) {
      return true
    }
    try {
      const reg = new RegExp(searchText, 'i')
      if (marketplaceItem.merchantName && marketplaceItem.merchantName.match(reg)) {
        return true
      }
    } catch (error) {
      return false
    }
    return false
  })
}

const ProductGrid: React.FC<Props> = (props): JSX.Element | null => {
  const {
    searchText,
    products,
    onSelectProduct,
  } = props

  // ===================================================================================================================
  // State:

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(products) || products.length < 1) {
    return null
  }
  const cols = filterList(products, searchText)
    .map((marketplaceItem) => {
      const productImageUri = cdn.getUri(CdnAssetType.GIFT_CARD, marketplaceItem.imageSourceFrontSide) || ''
      const merchantLogoImageUri = cdn.getUri(CdnAssetType.MERCHANT_LOGO, marketplaceItem.merchantLargeLogoImageSource) || ''

      return (
        <IonCol
          key={marketplaceItem.id as string}
          className='col'
          size='1'
          onClick={() => onSelectProduct(marketplaceItem.id as string)}
        >
          <GiftCard
            className='product'
            backgroundImageUri={productImageUri}
            merchantLogoUri={productImageUri}
            small
          />
          <div className='info'>
            <IonImg
              src={merchantLogoImageUri}
              className='merchant-logo withSmallRightMargin'
            />
            <div>
              {marketplaceItem.merchantName}
            </div>
          </div>
        </IonCol>
      )
    })

  return (
    <IonGrid className='product-grid'>
      <IonRow className='row'>
        {cols}
      </IonRow>
    </IonGrid>
  )
}

export default ProductGrid
