import React, { useContext, useEffect, useState } from 'react'
import {
  IonAlert,
  IonContent,
  IonPage,
  IonToast,
} from '@ionic/react'
import { Update } from 'history'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import './styles.css'
import type {
  PurchaseTransfer,
  User,
  UserIdentInfo,
  FindUserFilter,
} from '../../lib/core/definitions'
import { PurchaseStatus, UiMessage } from '../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import type {
  ApiClientOptions,
  DeclinePurchaseTransferData,
  DeclinePurchaseTransferVariables,
  PurchaseTransferQueryData,
  PurchaseTransferQueryVariables,
} from '../../services/apollo/definitions'
import { GiftReceivedPageStep } from './enums'
import { useAppHelpers } from '../../contexts/AppHelpersContext/AppHelpersContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import AcceptGiftAndSignInContent from './AcceptGiftAndSignInContent'
import AcceptGiftContent from './AcceptGiftContent'
import api from '../../services/api'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import coreHelpers from '../../lib/core/helpers'
import GiftCardHeader, { GiftCardHeaderLayout } from '../../components/GiftCardHeader/GiftCardHeader'
import GiftDeclinedContent from './GiftDeclinedContent'
import GiftNotAvailableContent from './GiftNotAvailableContent'
import helpers from './helpers'
import InitialPageContent from './InitialPageContent'
import logger from '../../services/logger'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import SenderIsActiveUserContent from './SenderIsActiveUserContent'
import SignUpForm from '../../components/SignUpForm/SignUpForm'
import WrongRecipientContent from './WrongRecipientContent'

const appPageId = AppPage.GiftReceivedPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

type Params = {
  purchaseTransferId: string
  step: string
}

/*
  This component handles these situations:
  1. The user is in, and is the correct recipient for this transfer
  2. The user is not signed in, and is the correct recipient for this transfer
  3. The user is not signed in, and is not the correct recipient for this transfer
  4. The recipient does not yet have a Mimble member account
  5. The user is the sender of the transfer
 */
const GiftReceivedPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  const isActivePage = appPageDef.routeMatches(location && location.pathname)
  let {
    purchaseTransferId: purchaseTransferIdFromParams,
    step: pageStepFromParams,
  } = useParams<keyof Params>() as unknown as Params
  const pageStep = pageStepFromParams || GiftReceivedPageStep.INITIAL
  let purchaseTransferId: string | undefined
  let toPurchaseId: string | undefined

  if (purchaseTransferIdFromParams) {
    if (purchaseTransferIdFromParams.startsWith('tp:')) {
      toPurchaseId = purchaseTransferIdFromParams.slice(3)
    } else {
      purchaseTransferId = purchaseTransferIdFromParams
    }
  }

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { showFullPageAnimation } = useAppHelpers()
  const {
    activeUser,
    isLoadingActiveUser,
    reloadActiveUser,
    reloadPurchases,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const { clearSessionData, getIsSignedIn, getActiveUser } = useGlobalCache()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [recipientIdentInfo, setRecipientIdentInfo] = useState<UserIdentInfo | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isActivatingPurchase, setIsActivatingPurchase] = useState(false)
  const [isTransferChanged, setIsTransferChanged] = useState(false)
  const [showConfirmDeclineTransferAlert, setShowConfirmDeclineTransferAlert] = useState(false)

  // ===================================================================================================================
  // Helpers:
  const showToastMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading purchase transfer:
  const {
    data: purchaseTransferLoadedData,
    refetch: reloadPurchaseTransfer,
    loading: isLoadingPurchaseTransfer,
  } = useQuery<PurchaseTransferQueryData, PurchaseTransferQueryVariables>(
    getIsSignedIn()
      ? apollo.queries.purchaseTransfer
      : apollo.queries.purchaseTransferUnauthorized,
    {
      variables: {
        purchaseTransferId,
        toPurchaseId,
      },
      context: { skipAuthentication: !getIsSignedIn() },
      skip: !purchaseTransferId && !toPurchaseId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        console.log('GiftReceivedPage: purchase transfer loaded:', data)
        const purchaseTransfer = data ? data.purchaseTransfer : undefined

        if (!purchaseTransfer) {
          console.log('GiftReceivedPage: no purchase transfer returned from loading.')
          return
        }
        const toPurchaseId = purchaseTransfer.toPurchaseId

        const dataEvaluation = helpers.evaluateData(
          purchaseTransfer,
          purchaseTransferId,
          toPurchaseId,
          recipientIdentInfo,
          activeUserId,
          getIsSignedIn(),
        )
        // Is the transfer in the right state?
        if (
          dataEvaluation.transferHasCorrectStatus === false &&
          !isActivatingPurchase &&
          !isTransferChanged &&
          // !isDecliningPurchaseTransfer &&
          !isLoading &&
          pageStep !== GiftReceivedPageStep.DECLINED &&
          isActivePage
        ) {
          console.log('GiftReceivedPage: purchase transfer not in right state.')
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_RECEIVED_GIFT_NOT_AVAILABLE)
        }

        // Is this the correct transfer?
        if (dataEvaluation.transferMatchesRequest === false) {
          console.log('GiftReceivedPage: loaded wrong purchase transfer!',
            { purchaseTransferId, purchaseTransfer })
          dataEvaluation.transferMatchesRequest = false
          if (
            !isActivatingPurchase &&
            !isTransferChanged &&
            // !isDecliningPurchaseTransfer &&
            pageStep !== GiftReceivedPageStep.DECLINED
          ) {
            pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.TRANSFER_NOT_FOUND)
          }
        }

        // Loading user info for the recipient:
        if (
          !getIsSignedIn() &&
          (
            purchaseTransfer.toUserId ||
            purchaseTransfer.toUserUsername ||
            purchaseTransfer.toUserEmail ||
            purchaseTransfer.toUserPhoneNumber
          )
        ) {
          const filter: FindUserFilter = {}
          if (purchaseTransfer.toUserId) {
            filter.id = purchaseTransfer.toUserId
          }
          if (purchaseTransfer.toUserUsername) {
            filter.username = purchaseTransfer.toUserUsername
          }
          if (purchaseTransfer.toUserEmail) {
            filter.email = purchaseTransfer.toUserEmail
          }
          if (purchaseTransfer.toUserPhoneNumber) {
            filter.phoneNumber = purchaseTransfer.toUserPhoneNumber
          }
          setIsLoading(true)
          api.findUser(filter, getIsSignedIn(), apolloClient).then((userIdentInfos) => {
            setIsLoading(false)
            if (Array.isArray(userIdentInfos) && userIdentInfos.length === 1) {
              setRecipientIdentInfo(userIdentInfos[0])
            }
          }, (error) => {
            logger.error('GiftReceivedPage: findUser returned an error.', { error })
            pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
            setIsLoading(false)
          })
        }
      },
      onError: (error) => {
        logger.error('GiftReceivedPage: loading purchase transfer error.', { error })
        if (
          !isActivatingPurchase &&
          !isTransferChanged &&
          // !isDecliningPurchaseTransfer &&
          pageStep !== GiftReceivedPageStep.DECLINED
        ) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.TRANSFER_NOT_FOUND)
        } else {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
        }
      },
    },
  )

  const purchaseTransfer = purchaseTransferLoadedData ? purchaseTransferLoadedData.purchaseTransfer : undefined
  if (!toPurchaseId && purchaseTransfer && purchaseTransfer.toPurchaseId) {
    ({ toPurchaseId } = purchaseTransfer)
  }
  if (!purchaseTransferId && purchaseTransfer) {
    purchaseTransferId = purchaseTransfer.id as string
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Decline transfer as an authorized user:
  const [
    declinePurchaseTransfer, {
      loading: isDecliningPurchaseTransfer,
    },
  ] = useMutation<DeclinePurchaseTransferData, DeclinePurchaseTransferVariables>(
    apollo.mutations.declinePurchaseTransfer,
    {
      context: { skipAuthentication: !getIsSignedIn() },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        console.log('GiftReceivedPage: mutation declinePurchaseTransfer succeeded.', data)
        pageMessages && pageMessages.clear()
        navigate(`${AppRoute.GIFT_RECEIVED}/${purchaseTransferId}/${GiftReceivedPageStep.DECLINED}`, true)
      },
      onError: (error) => {
        logger.error('graphql.declineReceivedPurchase: error received', { error })
        if (pageStep !== GiftReceivedPageStep.DECLINED) {
          showToastMessage('Failed to decline this gift card.')
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SAVING_CHANGES)
        }
      },
    })

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    console.log('GiftReceivedPage.useEffect[toPurchaseId, purchaseTransferId] called.',
      { toPurchaseId, purchaseTransferId })
    if (
      purchaseTransfer &&
      toPurchaseId &&
      !coreHelpers.models.compareId(purchaseTransfer.toPurchaseId, toPurchaseId)
    ) {
      console.log('GiftReceivedPage: target purchase ID mismatch; reloading purchaseTransfer',
        { 'purchaseTransfer.toPurchaseId': purchaseTransfer.toPurchaseId, toPurchaseId })
      reloadPurchaseTransfer({ toPurchaseId })
    }
  }, [toPurchaseId, purchaseTransferId])

  // ===================================================================================================================
  // Helpers:
  const resetPage = () => {
    pageMessages && pageMessages.clear()
    setIsActivatingPurchase(false)
    setIsTransferChanged(false)
    setIsLoading(false)
    setRecipientIdentInfo(undefined)
    setShowConfirmDeclineTransferAlert(false)
  }

  const isProcessing = (
    isLoadingPurchaseTransfer ||
    isDecliningPurchaseTransfer ||
    isActivatingPurchase ||
    isLoading ||
    isLoadingActiveUser
  )
  const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromPurchase(
    purchaseTransfer ? purchaseTransfer.fromPurchase : undefined,
  )
  const fromPurchase = purchaseTransfer ? purchaseTransfer.fromPurchase : undefined

  // The sender:
  let fromUser: User | undefined = purchaseTransfer ? purchaseTransfer.fromUser : undefined
  if (!fromUser && fromPurchase && fromPurchase.user) {
    fromUser = fromPurchase.user
  }

  let senderName: string | undefined
  if (fromUser && fromUser.fullName) {
    const name = coreHelpers.models.user.parseName(fromUser.fullName)
    if (name && (name.first || name.last)) {
      senderName = name.first || name.last
    }
  }

  const formattedBalanceWithCurrency = coreHelpers.models.purchase.getFormattedBalance(fromPurchase, true)
  let merchantName = ''
  const signedInUserValidationResult = helpers.validateSignedInUser(purchaseTransfer, getActiveUser())

  if (fromPurchase) {
    if (fromPurchase.product && fromPurchase.product.merchant && fromPurchase.product.merchant.name) {
      merchantName = fromPurchase.product.merchant.name
    }
  }

  let fromUserId: string | undefined
  if (fromPurchase && fromPurchase.user) {
    fromUser = fromPurchase.user
    if (fromUser && fromUser.id) {
      fromUserId = fromUser.id
    }
  }

  const getPageTitle = (): string => {
    if (pageStep === GiftReceivedPageStep.ACCEPT) {
      return 'Accept Gift'
    }
    if (pageStep === GiftReceivedPageStep.DECLINED) {
      return 'Gift Declined'
    }
    return 'Gift Received'
  }

  const navigateNext = (acceptedPurchaseTransfer: PurchaseTransfer | null | undefined) => {
    console.log('GiftReceivedPage.navigateNext called.', { acceptedPurchaseTransfer })
    if (
      !acceptedPurchaseTransfer ||
      !acceptedPurchaseTransfer.toPurchaseId ||
      !coreHelpers.models.purchaseTransfer.isPurchaseTransferFinished(acceptedPurchaseTransfer)
    ) {
      showToastMessage('Failed to accept this gift.')
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_ACCEPTING_GIFT)
      return
    }
    // isPageActive = false
    resetPage()
    navigate(`${AppRoute.GIFT_CARD}/${acceptedPurchaseTransfer.toPurchaseId}`)
  }

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewDidEnter(() => {
  //   isPageActive = true
  // })
  //
  // useIonViewDidLeave(() => {
  //   isPageActive = false
  //   resetPage()
  // })

  useEffect(() => {
    if (purchaseTransfer && purchaseTransfer.animation) {
      showFullPageAnimation(purchaseTransfer.animation)
    }
  }, [purchaseTransfer && purchaseTransfer.animation])

  // ===================================================================================================================
  // Event Handlers:
  const onGoToSignIn = (): void => {
    // isPageActive = false
    resetPage()
    clearSessionData()
    navigate(AppRoute.SIGN_IN, true)
    pageMessages && pageMessages.clear()
  }

  const onGoToAcceptStep = (): void => {
    if (!purchaseTransfer || !purchaseTransfer.id) {
      logger.error('GiftReceivedPage.onGoToAcceptStep: missing purchase transfer.')
      return
    }
    navigate(`${AppRoute.GIFT_RECEIVED}/${purchaseTransfer.id}/${GiftReceivedPageStep.ACCEPT}`)
  }

  const onDeclineGift = (confirmed?: any): void => {
    if (confirmed !== true) {
      setShowConfirmDeclineTransferAlert(true)
      return
    }
    setShowConfirmDeclineTransferAlert(false)
    if (!purchaseTransferId) {
      logger.error('GiftReceivedPage.onDeclineGift: missing purchase transfer.')
      return
    }
    // todo can a not logged-in user decline the transfer?
    // if (globalCache.getIsSignedIn()) {
    //   isWaitingToAcceptReceivedPurchase = true
    //   declinePurchaseTransfer(purchaseTransfer.id)
    //   pageMessages && pageMessages.clear()
    //   return
    // }
    declinePurchaseTransfer({
      variables: { purchaseTransferId },
    })
    pageMessages && pageMessages.clear()
  }

  const onNextFromSignUp = () => {
    console.log('GiftReceivedPage.onNextFromSignUp called.')
    api.loadPurchaseTransfer(
      purchaseTransferId as string,
      coreHelpers.models.purchaseTransfer.isPurchaseTransferFinished,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then((acceptedPurchaseTransfer) => {
      if (
        !acceptedPurchaseTransfer ||
        !acceptedPurchaseTransfer.toPurchaseId
      ) {
        logger.error('GiftReceivedPage.onNextFromSignUp: invalid data from loadPurchaseTransfer.')
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.GIFT_CARD_NOT_FOUND)
        return
      }
      api.loadPurchase(
        acceptedPurchaseTransfer.toPurchaseId,
        (purchase) => purchase.status === PurchaseStatus.ACTIVE,
        apollo.getPurchaseListFilter(activeUserId as string),
        activeUserId as string,
        apolloClient,
        undefined,
        { updateList: true },
      ).then((purchase) => {
        if (!purchase) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.GIFT_CARD_NOT_FOUND)
          return
        }
        pageMessages && pageMessages.clear()
        navigateNext(acceptedPurchaseTransfer)
      })
    }, (error) => {
      logger.error('GiftReceivedPage: loadPurchaseTransfer error.', { error })
      showToastMessage('Failed to accept this card.')
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_ACCEPTING_GIFT)
    })
  }

  const onGoHome = (): void => {
    // isPageActive = false
    resetPage()
    navigate(AppRoute.HOME, true)
  }

  const onOpenContact = (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined,
  ): void => {
    // isPageActive = false
    resetPage()
    pageHelpers.openContact({
      activeUserId,
      contactId,
      contactUserId,
      chatId,
      apolloClient,
      navigate,
    })
  }

  const onOpenSignUp = (): void => {
    // isPageActive = false
    resetPage()
    navigate(AppRoute.JOIN, true)
  }

  const onOpenSignIn = (): void => {
    // isPageActive = false
    resetPage()
    clearSessionData()
    navigate(AppRoute.SIGN_IN, true)
  }

  const onAcceptGift = (): void => {
    console.log('GiftReceivedPage.onAcceptGift called.', { isSignedIn: getIsSignedIn(), purchaseTransferId })

    if (!getIsSignedIn()) {
      logger.error('GiftReceivedPage.onAcceptGift: user not signed in')
      return
    }

    if (!purchaseTransferId) {
      logger.error('GiftReceivedPage.onAcceptGift: failed to determine purchase transfer ID')
      return
    }

    setIsActivatingPurchase(true)
    setIsTransferChanged(true)
    const clientOptions: ApiClientOptions = {}
    if (activeUserId) {
      clientOptions.updateList = true
      // options.queryListVariables = apollo.getPurchasesQueryVariables(activeUserId as string)
    }
    api.acceptReceivedPurchase(
      purchaseTransferId,
      activeUserId as string,
      apolloClient,
      undefined,
      clientOptions,
    )
      .then((acceptedPurchaseTransfer) => {
        console.log('GiftReceivedPage: acceptReceivedPurchase returned:', acceptedPurchaseTransfer)
        reloadPurchases().then(() => {
          navigateNext(acceptedPurchaseTransfer)
          setIsActivatingPurchase(false)
        }, (error) => {
          logger.error('GiftReceivedPage: failed to reload purchases.', { error })
          navigateNext(acceptedPurchaseTransfer)
          setIsActivatingPurchase(false)
        })
      }, (error) => {
        logger.error('GiftReceivedPage: acceptReceivedPurchase error.', { error })
        showToastMessage('Failed to accept this gift.')
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_ACCEPTING_GIFT)
      })
  }

  const onSignInAndAcceptPurchase = (password: string): void => {
    // console.log('GiftReceivedPage.onSignInAndAcceptPurchase called.', { password })

    let userId = recipientIdentInfo ? recipientIdentInfo.id : undefined
    if (!userId && purchaseTransfer) {
      userId = purchaseTransfer.toUserId
    }

    if (!userId) {
      logger.error('GiftReceivedPage.onSignInAndAcceptPurchase: failed to determine recipient user ID.')
      return
    }

    setIsActivatingPurchase(true)
    setIsTransferChanged(true)

    pageHelpers.handleSignIn({
      ident: userId,
      password,
      apolloClient,
      reloadActiveUser,
      navigate,
    }).then(onAcceptGift, (error) => {
      setIsActivatingPurchase(false)
      logger.error('GiftReceivedPage: Failed to sign you in.', { error })
      setToastMessage('Failed to sign you in. Please check your input and try again.')
      setShowToast(true)
    })
  }

  const dataEvaluation = helpers.evaluateData(
    purchaseTransfer,
    purchaseTransferId,
    toPurchaseId,
    recipientIdentInfo,
    activeUserId,
    getIsSignedIn(),
  )

  // console.log('GiftReceivedPage.render called.', {
  //   purchaseTransferId,
  //   toPurchaseId,
  //   pageStep,
  //   dataEvaluation,
  // })

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  // ===================================================================================================================
  // Rendering:

  const getContent = (): JSX.Element | undefined => {
    if (!purchaseTransfer) {
      // todo Show loading?
      return
    }

    if (pageStep === GiftReceivedPageStep.DECLINED) {
      return (
        <GiftDeclinedContent
          senderName={senderName}
          senderUserInfo={fromUser}
          onOpenContact={onOpenContact}
          onGoHome={onGoHome}
          onOpenSignUp={onOpenSignUp}
          onOpenSignIn={onOpenSignIn}
        />
      )
    }

    if (
      !isActivatingPurchase &&
      !isTransferChanged &&
      dataEvaluation.transferHasCorrectStatus === false
    ) {
      return (
        <GiftNotAvailableContent
          purchaseTransfer={purchaseTransfer}
          fromUserId={fromUserId}
          senderName={senderName}
          user={activeUser}
        />
      )
    }

    if (dataEvaluation.signedInUserIsSender) {
      return (
        <SenderIsActiveUserContent
          purchaseTransfer={purchaseTransfer}
          fromUserId={fromUserId}
          senderName={senderName}
          user={activeUser}
        />
      )
    }

    if (signedInUserValidationResult !== true) {
      return (
        <WrongRecipientContent
          purchaseTransfer={purchaseTransfer}
          fromUserId={fromUserId}
          senderName={senderName}
          user={activeUser}
          signedInUserValidationResult={signedInUserValidationResult}
        />
      )
    }

    if (dataEvaluation.recipientIsSignedIn) {
      return (
      <div className='flex'>
        <AcceptGiftContent
          purchaseTransfer={purchaseTransfer}
          senderId={fromUserId as string}
          senderName={senderName || ''}
          merchantName={merchantName}
          formattedBalanceWithCurrency={formattedBalanceWithCurrency}
          onAcceptGift={onAcceptGift}
          onDeclineGift={onDeclineGift}
        />
      </div>
      )
    }

    if (pageStep === GiftReceivedPageStep.INITIAL) {
      return (
        <>
          <InitialPageContent
            senderName={senderName || ''}
            senderImageUrl={fromUser ? fromUser.imageUrl : undefined}
            senderMessage={purchaseTransfer.messageText}
            formattedBalanceWithCurrency={formattedBalanceWithCurrency}
            merchantName={merchantName}
            onNext={onGoToAcceptStep}
            onDeclineGift={onDeclineGift}
          />
        </>
      )
    }

    if (!dataEvaluation.recipientIsSignedIn && !dataEvaluation.recipientAlreadyExists) {
      return (
        <div key='sign-up'>
          <div className='withDoubleBottomMargin'>
            Let&apos;s create a free Mimble member account and add this gift card into your wallet.
            Then you can use your phone to pay with this gift card in the store.
          </div>
          <SignUpForm
            fullName={purchaseTransfer.toUserFullName}
            email={purchaseTransfer.toUserEmail}
            phoneNumber={purchaseTransfer.toUserPhoneNumber}
            purchaseTransferId={purchaseTransferId}
            receivedPurchaseId={toPurchaseId}
            onGoToSignIn={onGoToSignIn}
            onNext={onNextFromSignUp}
          />
        </div>
      )
    }

    if (!dataEvaluation.recipientIsSignedIn && dataEvaluation.recipientAlreadyExists) {
      return (
        <>
          <AcceptGiftAndSignInContent
            purchaseTransfer={purchaseTransfer}
            onGoToSignIn={onGoToSignIn}
            onAcceptGift={onSignInAndAcceptPurchase}
            onDeclineGift={onDeclineGift}
          />
        </>
      )
    }
  }

  let giftCardHeader
  if (pageStep !== GiftReceivedPageStep.DECLINED) {
    const currency = purchaseTransfer && purchaseTransfer.fromPurchase
      ? purchaseTransfer.fromPurchase.currency
      : undefined
    const formattedBalance = purchaseTransfer && purchaseTransfer.fromPurchase
      ? coreHelpers.ui.formatAmount(
        purchaseTransfer.fromPurchase.balance,
        purchaseTransfer.fromPurchase.fundType,
        purchaseTransfer.fromPurchase.currency,
      )
      : undefined

    giftCardHeader = (
      <GiftCardHeader
        backgroundImageUri={giftCardBackgroundUri}
        merchantLogoUri={merchantLogoUri}
        currency={currency}
        formattedBalance={formattedBalance}
        layout={GiftCardHeaderLayout.MEDIUM}
      />
    )
  }
  const content = (
    <div className='viewWrapper'>
      {giftCardHeader}
      <div className='withPadding' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {getContent()}
      </div>
    </div>
  )
  const goBackUri = ((): string | undefined => {
    if (purchaseTransferId) {
      if (pageStep === GiftReceivedPageStep.ACCEPT) {
        return `${AppRoute.GIFT_RECEIVED}/${purchaseTransferId}`
      }
    }
  })()

  return (
    <IonPage className='app-page-public purchase-received-page'>
      <NavBar
        title={getPageTitle()}
        goBackUri={goBackUri}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      <IonAlert
        isOpen={showConfirmDeclineTransferAlert}
        onDidDismiss={(): void => { setShowConfirmDeclineTransferAlert(false) }}
        header='Decline Gift Card'
        subHeader=''
        message='Are you sure you want to decline this gift card?'
        buttons={[
          { text: 'Yes, decline', handler: (): void => { onDeclineGift(true) } },
          { text: 'Cancel', cssClass: 'secondary', handler: (): void => { setShowConfirmDeclineTransferAlert(false) } },
        ]}
      />
    </IonPage>
  )
}

export default GiftReceivedPage
