import React, { useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import type { AppFeature } from '../../lib/core/enums'
import type { FrequentlyAskedQuestion } from './faq'
import { chevronDown, chevronUp } from 'ionicons/icons'

type Props = {
  item: FrequentlyAskedQuestion;
  activeUserAppFeatures: AppFeature | AppFeature[] | null | undefined;
  onNavigateTo: (targetRoute: string) => void;
};

const FaqItem: React.FC<Props> = (props): JSX.Element | null => {
  const {
    item,
    activeUserAppFeatures,
    onNavigateTo,
  } = props

  const [showAnswer, setShowAnswer] = useState(false)

  const handleNavigateTo = () => {
    if (onNavigateTo && item.targetRoute) {
      onNavigateTo(item.targetRoute)
    }
  }

  const onToggleAnswer = () => {
    setShowAnswer(!showAnswer)
  }

  if (item.appFeatures && !activeUserAppFeatures) {
    return null
  }

  let answer: JSX.Element | undefined
  if (showAnswer) {
    let button: JSX.Element | undefined
    if (item.buttonLabel && item.targetRoute && onNavigateTo) {
      button = (
        <div>
          <IonButton
            size='small'
            onClick={handleNavigateTo}
          >
            {item.buttonLabel}
          </IonButton>
        </div>
      )
    }

    answer = (
      <div>
        {item.answer}
        {button}
      </div>
    )
  }

  const expandIcon = showAnswer ? chevronUp : chevronDown

  return (
    <div className='faq-item'>
      <div className='question-row' onClick={onToggleAnswer}>
        <div className='question'>{item.question}</div>
        <IonButton
          size='small'
          fill='clear'
          color='medium'
          onClick={onToggleAnswer}
        >
          <IonIcon icon={expandIcon} />
        </IonButton>
      </div>
      {answer}
    </div>
  )
}

export default FaqItem
