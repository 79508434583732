import type { MouseEvent } from 'react'
import React, { useState } from 'react'
import { IonBadge } from '@ionic/react'
import { useLocation } from 'react-router-dom'
import { Update } from 'history'

import { AppRoute } from '../../enums'
import type { AppTabScope } from './enums'
import { AppTabId } from './enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useShoppingCart } from '../../contexts/ShoppingCartContext/ShoppingCartContext'
import AppTab from './AppTab'
import appTabDefs from './getAppTabDefs'
import helpers from './helpers'
import MarketplacePopover from './MarketplacePopover'

interface Props {
  scope: AppTabScope;
  showControlCenter: boolean;
  onClickAppTab: (tabId: AppTabId) => void;
  onNavigateTo: (route: AppRoute | string) => void;
}

export const AppTabs: React.FC<Props> = (props): JSX.Element | null => {
  const {
    scope,
    showControlCenter,
    onClickAppTab,
    onNavigateTo,
  } = props

  // ===================================================================================================================
  // State:
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  const { activeUser, inbox } = useMimbleData()

  const { shoppingCart, pendingShoppingCarts } = useShoppingCart()
  const shoppingCartItemCount = helpers.getShoppingCartBadgeCount(shoppingCart, pendingShoppingCarts)

  const [showMarketplacePopover, setShowMarketplacePopover] = useState(false)
  const [marketplacePopoverClickEvent, setMarketplacePopoverClickEvent] = useState<MouseEvent<Element, MouseEvent> | undefined>()

  const getAppTabBadge = (tabId: AppTabId): JSX.Element | undefined => {
    if (tabId === AppTabId.CONTACTS) {
      if (
        !inbox ||
        !inbox.chatMessageCount ||
        inbox.chatMessageCount < 1
      ) {
        return
      }

      return <IonBadge color='success' className='badge'>{inbox.chatMessageCount}</IonBadge>
    } else if (tabId === AppTabId.MARKETPLACE) {
      if (shoppingCartItemCount < 1) {
        return
      }

      return <IonBadge color='success' className='badge'>{shoppingCartItemCount}</IonBadge>
    } else if (tabId === AppTabId.WALLET) {
      if (
        inbox &&
        inbox.giftCount &&
        !Number.isNaN(inbox.giftCount) &&
        inbox.giftCount > 0
      ) {
        return <IonBadge color='success' className='badge'>Gift</IonBadge>
      }
    }
  }

  // ===================================================================================================================
  // Event Handlers:
  const handleClickAppTab = (appTabId: AppTabId, event: MouseEvent<Element, MouseEvent>) => {
    if (appTabId === AppTabId.MARKETPLACE) {
      if (shoppingCartItemCount > 0) {
        setMarketplacePopoverClickEvent(event)
        setShowMarketplacePopover(true)
      } else {
        onNavigateTo(AppRoute.MARKETPLACE)
      }
    }
    onClickAppTab(appTabId)
  }

  const onCloseMarketplacePopover = (): void => {
    if (showMarketplacePopover) {
      setShowMarketplacePopover(false)
    }
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('AppTabs.render called.', { activeUser, inbox })
  if (!activeUser) {
    return null
  }

  const tabs = appTabDefs
    .filter(def => def.scope === scope)
    .map((def) => (
      <AppTab
        key={def.id}
        tabId={def.id}
        icon={def.icon}
        label={def.label}
        badge={getAppTabBadge(def.id)}
        targetRoute={def.targetRoute}
        isSelected={helpers.isTabSelected(location && location.pathname, def.id)}
        onNavigateTo={onNavigateTo}
        onClick={handleClickAppTab}
      />
    ))

  return (
    <>
      <div className='app-tabs'>
        {tabs}
      </div>
      <MarketplacePopover
        mouseEvent={marketplacePopoverClickEvent as Event | undefined}
        show={showMarketplacePopover}
        onClose={onCloseMarketplacePopover}
        onNavigateTo={onNavigateTo}
      />
    </>
  )
}

export default AppTabs
