import { RecordStatus } from '../../enums'

const labelsEn = Object.freeze({
  [RecordStatus.UNSET]: 'unset',
  [RecordStatus.ACTIVE]: 'active',
  [RecordStatus.PENDING]: 'pending',
  [RecordStatus.INACTIVE]: 'inactive',
  [RecordStatus.DELETED]: 'deleted',
})

export default {
  getLabel: (status: RecordStatus | null | undefined, language = 'en'): string => {
    if (language === 'en') {
      if (!status) {
        return labelsEn[RecordStatus.UNSET]
      }
      return labelsEn[status]
    }
    return ''
  },
  isValid: (status: RecordStatus): boolean => Object.values(RecordStatus).includes(status),
}
