import type { User } from '../../../definitions'
// import { AppFeature } from '../../../enums'
// import getAppFeaturesAsArray from './getAppFeaturesAsArray'

const canPurchase = (user: User | null | undefined): boolean => {
  return true
  // if (!user || !user.appFeatures) {
  //   return false
  // }
  //
  // const featureList = Array.isArray(user.appFeatures)
  //   ? user.appFeatures
  //   : getAppFeaturesAsArray(user.appFeatures)
  //
  // return featureList.includes(AppFeature.BUY_WITH_CRYPTO) ||
  //   featureList.includes(AppFeature.BUY_WITH_CREDIT_CARD) ||
  //   featureList.includes(AppFeature.BUY_WITH_BITCOIN_LIGHTNING)
}

export default canPurchase
