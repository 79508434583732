import { gql } from '@apollo/client'

export default gql`
  mutation M($contactEventReminder: ContactEventReminderInput!) {
    upsertContactEventReminder (contactEventReminder: $contactEventReminder) {
      id
      eventId
      timing
      notificationMethod
      sentAt
      sendReport
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
