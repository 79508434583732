import type { ApolloClient } from '@apollo/client'
import type { EnvironmentVal } from '../../lib/core/definitions'

type ClientMap = {
  test: ApolloClient<any> | undefined
  development: ApolloClient<any> | undefined
  sandbox: ApolloClient<any> | undefined
  production: ApolloClient<any> | undefined
}
const clients: ClientMap = {
  test: undefined,
  development: undefined,
  sandbox: undefined,
  production: undefined,
}

const serviceData = {
  setClient: (client: ApolloClient<any>, environment: EnvironmentVal): void => {
    clients[environment] = client
  },

  getClient: (environment: EnvironmentVal): ApolloClient<any> | undefined => clients[environment],
}

export default serviceData
