import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../../enums'
import type { CampaignListFilter } from '../../../lib/core/definitions'
import type { CampaignsQueryData, CampaignsQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import CampaignGrid from './CampaignGrid'
import CampaignListHeader from './CampaignListHeader'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'

const appPageId = AppPage.AdminCampaignsPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const CampaignsPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [filter, setFilter] = useState<CampaignListFilter | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: campaignsData,
    loading: isLoadingCampaigns,
    refetch: reloadCampaigns,
  } = useQuery<CampaignsQueryData, CampaignsQueryVariables>(
    apollo.queries.campaigns, {
      variables: { filter: {} },
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
      onCompleted: () => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
    },
  )
  const campaigns = campaignsData ? campaignsData.campaigns : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingCampaigns

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  // })

  // ===================================================================================================================
  // Event Handlers:
  const onChangeFilter = (newFilter: CampaignListFilter): void => {
    setFilter(newFilter)
    // if (!coreHelpers.models.product.compareCampaignListFilters(newFilter, filter, true)) {
    //   sendGetProductsRequest(newFilter)
    // }
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadCampaigns().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenCampaign = (campaignId: string): void => {
    api.loadCampaign(
      campaignId,
      undefined,
      undefined,
      apolloClient,
    ).then((campaign) => {
      if (campaign) {
        navigate(`${AppRoute.ADMIN_CAMPAIGN}/${campaignId}`)
      }
    }, (error) => {
      console.error(error)
      pageHelpers.checkForUnauthorized(error, navigate)
      // pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      setToastMessage('Error connecting.')
      setShowToast(true)
    })
  }

  const onCreateNew = (): void => {
    navigate(AppRoute.ADMIN_EDIT_CAMPAIGN)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  return (
    <IonPage className='app-page-admin campaigns-page'>
      <NavBar
        title='Manage Member Message Templates'
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding' scrollY={false}>
        <div className='g-non-scroll-content-wrapper'>
          <PageMessages />
          <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
           <CampaignListHeader
            onChangeFilter={onChangeFilter}
            onCreateNew={onCreateNew}
           />
           <CampaignGrid
            campaigns={campaigns}
            filter={filter}
            onOpenCampaign={onOpenCampaign}
           />
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default CampaignsPage
