import React, { useEffect, useState } from 'react'
import {
  IonButton,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from '@ionic/react'

import './styles.css'
import { CampaignStatus, CampaignType } from '../../../lib/core/enums'
import type { Campaign, CampaignInput, MemberMessageTemplateListItem } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import FormItem from '../../../components/FormItem/FormItem'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../helpers/validationHelpers'

type Props = {
  campaign: Campaign | null | undefined
  memberMessageTemplates: MemberMessageTemplateListItem[] | null | undefined
  onGoBack: () => void
  onSave: (campaign: CampaignInput) => void
}

const CampaignForm: React.FC<Props> = (props): JSX.Element => {
  const {
    campaign = null,
    memberMessageTemplates,
    onSave,
    onGoBack,
  } = props

  const [name, setName] = useState<string | undefined>()
  const [nameValidationError, setNameValidationError] = useState<string | undefined>()

  const [memberMessageTemplateId, setMemberMessageTemplateId] = useState<string | undefined>()
  const [memberMessageTemplateIdValidationError, setMemberMessageTemplateIdValidationError] = useState<CampaignType | undefined>()

  const [campaignType, setCampaignType] = useState<CampaignType | undefined>()
  const [campaignTypeValidationError, setCampaignTypeValidationError] = useState<CampaignType | undefined>()

  const [status, setStatus] = useState<CampaignStatus | undefined>()
  const [statusValidationError, setStatusValidationError] = useState<CampaignStatus | undefined>()

  const [adminNotes, setAdminNotes] = useState<string | undefined>()
  const [adminNotesValidationError, setAdminNotesValidationError] = useState<string | undefined>()

  const [userIds, setUserIds] = useState<string | undefined>()
  const [userIdsValidationError, setUserIdsValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const nameChanged = name !== undefined && !(campaign && name === campaign.name)
  const campaignTypeChanged = campaignType !== undefined && !(campaign && campaignType === campaign.campaignType)
  const memberMessageTemplateIdChanged = memberMessageTemplateId !== undefined && !(campaign && memberMessageTemplateId === campaign.memberMessageTemplateId)
  const statusChanged = status !== undefined && !(campaign && status === campaign.status)
  const adminNotesChanged = adminNotes !== undefined && !(campaign && adminNotes === campaign.adminNotes)
  const userIdsChanged = !!userIds

  const isDirty = (
    nameChanged ||
    campaignTypeChanged ||
    memberMessageTemplateIdChanged ||
    statusChanged ||
    adminNotesChanged ||
    userIdsChanged
  )
  const isValid = (
    !nameValidationError &&
    !campaignTypeValidationError &&
    !memberMessageTemplateIdValidationError &&
    !statusValidationError &&
    !adminNotesValidationError &&
    !userIdsValidationError
  )
  // console.log('CampaignForm.render called.', {
  //   nameValidationError,
  //   action2ValidationError,
  //   adminNotesValidationError,
  //   sourceValidationError,
  //   isDirty,
  //   isValid,
  // });

  const resetForm = (): void => {
    setName(undefined)
    setNameValidationError(undefined)
    setCampaignType(undefined)
    setCampaignTypeValidationError(undefined)
    setMemberMessageTemplateId(undefined)
    setMemberMessageTemplateIdValidationError(undefined)
    setStatus(undefined)
    setStatusValidationError(undefined)
    setAdminNotes(undefined)
    setAdminNotesValidationError(undefined)
    setUserIds(undefined)
    setUserIdsValidationError(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('CampaignForm: new campaign received - resetting form.')
    resetForm()
  }, [campaign])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeName = (event: any): void => {
    if (
      (campaign && (event.detail.value === campaign.name || (!event.detail.value && !campaign.name))) ||
      (!campaign && !event.detail.value)
    ) {
      setNameValidationError('')
      setName(undefined)
      return
    }
    setName(event.detail.value)
    if (event.detail.value) {
      // setNameValidationError(validationHelpers.validateCampaignName(event.detail.value))
    } else {
      setNameValidationError('')
    }
  }

  const onChangeCampaignType = (event: any): void => {
    if (
      (campaign && (event.detail.value === campaign.campaignType || (!event.detail.value && !campaign.campaignType))) ||
      (!campaign && !event.detail.value)
    ) {
      setCampaignTypeValidationError(undefined)
      setCampaignType(undefined)
      return
    }
    setCampaignType(event.detail.value)
    if (event.detail.value) {
      // setStatusValidationError(validationHelpers.validateCampaignStatus(event.detail.value))
    } else {
      setCampaignTypeValidationError(undefined)
    }
  }

  const onChangeMemberMessageTemplateId = (event: any): void => {
    if (
      (campaign && (event.detail.value === campaign.memberMessageTemplateId || (!event.detail.value && !campaign.memberMessageTemplateId))) ||
      (!campaign && !event.detail.value)
    ) {
      setMemberMessageTemplateIdValidationError(undefined)
      setMemberMessageTemplateId(undefined)
      return
    }
    setMemberMessageTemplateId(event.detail.value)
    if (event.detail.value) {
      // setStatusValidationError(validationHelpers.validateCampaignStatus(event.detail.value))
    } else {
      setMemberMessageTemplateIdValidationError(undefined)
    }
  }

  const onChangeStatus = (event: any): void => {
    if (
      (campaign && (event.detail.value === campaign.status || (!event.detail.value && !campaign.status))) ||
      (!campaign && !event.detail.value)
    ) {
      setStatusValidationError(undefined)
      setStatus(undefined)
      return
    }
    setStatus(event.detail.value)
    if (event.detail.value) {
      // setStatusValidationError(validationHelpers.validateCampaignStatus(event.detail.value))
    } else {
      setStatusValidationError(undefined)
    }
  }

  const onChangeAdminNotes = (event: any): void => {
    if (
      (campaign && (event.detail.value === campaign.adminNotes || (!event.detail.value && !campaign.adminNotes))) ||
      (!campaign && !event.detail.value)
    ) {
      setAdminNotesValidationError(undefined)
      setAdminNotes(undefined)
      return
    }
    setAdminNotes(event.detail.value)
    if (event.detail.value) {
      // setAdminNotesValidationError(validationHelpers.validateImageSource(event.detail.value))
    } else {
      setAdminNotesValidationError(undefined)
    }
  }

  const onChangeUserIds = (event: any): void => {
    // if (
    //   (campaign && (event.detail.value === campaign.userIds || (!event.detail.value && !campaign.userIds))) ||
    //   (!campaign && !event.detail.value)
    // ) {
    //   setUserIdsValidationError(undefined)
    //   setUserIds(undefined)
    //   return
    // }
    setUserIds(event.detail.value)
    if (event.detail.value) {
      setUserIdsValidationError(validationHelpers.validateObjectIdList(event.detail.value))
    } else {
      setUserIdsValidationError(undefined)
    }
  }

  const compileCampaign = (): CampaignInput => {
    const updatedCampaign: CampaignInput = {}
    if (campaign && campaign.id) {
      updatedCampaign.id = campaign.id
    }
    if (name !== undefined && (!campaign || name !== campaign.name)) {
      updatedCampaign.name = name || null
    }
    if (campaignType !== undefined && (!campaign || campaignType !== campaign.campaignType)) {
      updatedCampaign.campaignType = campaignType || null
    }
    if (memberMessageTemplateId !== undefined && (!campaign || memberMessageTemplateId !== campaign.memberMessageTemplateId)) {
      updatedCampaign.memberMessageTemplateId = memberMessageTemplateId || null
    }
    if (status !== undefined && (!campaign || status !== campaign.status)) {
      updatedCampaign.status = status || null
    }
    if (userIds !== undefined) {
      updatedCampaign.userIds = userIds
    }
    if (adminNotes !== undefined && (!campaign || adminNotes !== campaign.adminNotes)) {
      updatedCampaign.adminNotes = adminNotes || null
    }
    return updatedCampaign
  }

  const onClickSaveButton = (event: any): void => {
    event.preventDefault()
    onSave(compileCampaign())
  }

  const campaignTypeOptions = Object.values(CampaignType).map((t) => (
    <IonSelectOption key={t} value={t}>
      {coreHelpers.type.campaignType.getLabel(t)}
    </IonSelectOption>
  ))

  let memberMessageTemplateIdOptions: JSX.Element[] | undefined
  if (Array.isArray(memberMessageTemplates) && memberMessageTemplates.length > 0) {
    memberMessageTemplateIdOptions = memberMessageTemplates.map((t) => (
      <IonSelectOption key={t.id} value={t.id}>
        {t.name}
      </IonSelectOption>
    ))
  }

  const statusOptions = Object.values(CampaignStatus).map((t) => (
    <IonSelectOption key={t} value={t}>
      {coreHelpers.type.campaignStatus.getLabel(t)}
    </IonSelectOption>
  ))

  return (
    <form onSubmit={onClickSaveButton}>
      <FormItem label='Name' validationError={nameValidationError} withBottomMargin>
        <IonInput
          autofocus
          onIonChange={onChangeName}
          placeholder='name'
          value={name !== undefined ? name : (campaign ? campaign.name : '')}
        />
      </FormItem>
      <FormItem label='Message Template' validationError={memberMessageTemplateIdValidationError} withBottomMargin>
        <IonSelect
          value={memberMessageTemplateId !== undefined ? memberMessageTemplateId : (campaign ? campaign.memberMessageTemplateId : '')}
          interface='popover'
          onIonChange={onChangeMemberMessageTemplateId}
        >
          {memberMessageTemplateIdOptions}
        </IonSelect>
      </FormItem>
      <FormItem label='Campaign Type' validationError={campaignTypeValidationError} withBottomMargin>
        <IonSelect
          value={campaignType !== undefined ? campaignType : (campaign ? campaign.campaignType : '')}
          interface='popover'
          onIonChange={onChangeCampaignType}
        >
          {campaignTypeOptions}
        </IonSelect>
      </FormItem>
      <FormItem label='Status' validationError={statusValidationError} withBottomMargin>
        <IonSelect
          value={status !== undefined ? status : (campaign ? campaign.status : '')}
          interface='popover'
          onIonChange={onChangeStatus}
        >
          {statusOptions}
        </IonSelect>
      </FormItem>
      <FormItem label='User IDs' validationError={userIdsValidationError} withBottomMargin>
        <IonTextarea
          rows={10}
          onIonChange={onChangeUserIds}
          value={userIds || ''}
        />
      </FormItem>
      <FormItem label='Admin Notes' validationError={adminNotesValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeAdminNotes}
          placeholder='admin notes'
          value={adminNotes !== undefined ? adminNotes : (campaign ? campaign.adminNotes : '')}
        />
      </FormItem>
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onGoBack}
        >
          Back
        </IonButton>
        <SubmitButton
          onClick={onClickSaveButton}
          disabled={!isDirty || !isValid}
        >
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default CampaignForm
