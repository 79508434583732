import moment from 'moment'
import type { MouseEvent } from 'react'
import React, { useState } from 'react'
import { useIonViewDidLeave } from '@ionic/react'

import './styles.css'
import {
  CryptoCurrency,
  TransactionProcessor,
  TransactionRemoteStatus,
} from '../../../lib/core/enums'
import { PaymentRequestPart } from './helpers'
import type { ShoppingCart } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import CreatePaymentRequestSection from './CreatePaymentRequestSection'
import ExchangeRateSection from './ExchangeRateSection'
import pageHelpers from '../../../helpers/pageHelpers'
import PaymentReceivedAndConfirmedSection from './PaymentReceivedAndConfirmedSection'
import PreparingPaymentRequestSection from './PreparingPaymentRequestSection'
import RequestExpiredSection from './RequestExpiredSection'
import RequestInfoSection from './RequestInfoSection/RequestInfoSection'

type Props = {
  shoppingCart: ShoppingCart | null | undefined
  isActiveShoppingCart: boolean
  onCancelPaymentRequest: () => void
  onOpenPurchase: () => void
  onOpenWallet: () => void
  onRefreshExchangeRate: () => void
  onPlaceOrder: () => void
  onDeletePendingOrder: () => void
  showUiMessage: (message: string) => void
}

const CryptoForm: React.FC<Props> = (props): JSX.Element | null => {
  const {
    shoppingCart,
    isActiveShoppingCart,
    onCancelPaymentRequest,
    onDeletePendingOrder,
    onOpenPurchase,
    onOpenWallet,
    onPlaceOrder,
    onRefreshExchangeRate,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const [showCopyPaymentRequestMenu, setShowCopyPaymentRequestMenu] = useState(false)
  const [processMessage, setProcessMessage] = useState<string | undefined>()
  const [copyRequestMenuClickEvent, setCopyRequestMenuClickEvent] = useState(undefined as MouseEvent | undefined)

  const timeToGoOnPaymentRequest = (
    shoppingCart &&
    shoppingCart.paymentCryptoAddress &&
    shoppingCart.paymentTimesOutAt
  )
    ? moment(Number(shoppingCart.paymentTimesOutAt)).valueOf() - new Date().getTime()
    : 0
  // const cryptoAmount = coreHelpers.cryptoCurrency.satoshiToBitcoin(
  //   (shoppingCart && shoppingCart.paymentAmount) || 0)

  // const requestIsExpired = coreHelpers.type.transactionRemoteStatus.isTimedOutOrCanceled(
  //   shoppingCart && shoppingCart.paymentStatus)

  const paymentRequestString = coreHelpers.cryptoCurrency.getPaymentRequestString(
    (shoppingCart && shoppingCart.paymentCryptoAddress) || '',
    (shoppingCart && Number(shoppingCart.paymentAmount)) || 0,
    (shoppingCart && shoppingCart.paymentCurrency as CryptoCurrency) || CryptoCurrency.BITCOIN,
    'Mimble marketplace purchase',
  )
  const enableSubmitButton = true

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewDidLeave(() => {
    setShowCopyPaymentRequestMenu(false)
    setCopyRequestMenuClickEvent(undefined)
  })

  // ===================================================================================================================
  // Event Handlers:
  const handleSubmit = (): void => {
    console.log('CryptoForm.handleSubmit called.')
  }

  const onOpenCopyRequestMenu = (event: MouseEvent): void => {
    event.persist()
    setShowCopyPaymentRequestMenu(true)
    setCopyRequestMenuClickEvent(event)
  }

  const onCloseContextMenu = (): void => {
    setShowCopyPaymentRequestMenu(false)
    setCopyRequestMenuClickEvent(undefined)
  }

  const onCopyPaymentRequestPart = (component: PaymentRequestPart): void => {
    let value: string | null | undefined
    let label: string
    switch (component) {
      case PaymentRequestPart.RECEIVING_ADDRESS:
        value = shoppingCart ? shoppingCart.paymentCryptoAddress : ''
        label = 'address'
        break
      case PaymentRequestPart.AMOUNT: {
        const cryptoAmount = coreHelpers.cryptoCurrency.satoshiToBitcoin(shoppingCart ? Number(shoppingCart.paymentAmount) || 0 : 0)
        value = cryptoAmount.toString()
        label = 'amount'
      }
        break
      case PaymentRequestPart.MESSAGE:
        // todo: should be request message:
        value = shoppingCart ? shoppingCart.paymentCryptoAddress : ''
        if (!value) {
          value = 'Gift Card Payment'
        }
        label = 'message'
        break
      case PaymentRequestPart.REQUEST_STRING:
        value = paymentRequestString
        label = 'request string'
        break
    }
    if (value) {
      pageHelpers.copyInputTextToClipboard(
        value,
        label,
        showUiMessage,
        `The ${label} was copied to clipboard`,
      )
    }
    onCloseContextMenu()
  }

  // ===================================================================================================================
  // Rendering:
  if (!shoppingCart) {
    return null
  }

  if (
    !shoppingCart.paymentCryptoAddress ||
    !timeToGoOnPaymentRequest ||
    timeToGoOnPaymentRequest < 1
  ) {
    return (
      <CreatePaymentRequestSection
        isActiveShoppingCart={isActiveShoppingCart}
        isProcessing={false}
        // isProcessing={isLoadingShoppingCart || isSavingShoppingCart}
        onCreatePaymentRequest={onPlaceOrder}
        onDeletePendingOrder={onDeletePendingOrder}
      />
    )
  }

  let paymentRequestSectionContent
  if (shoppingCart.paymentStatus === TransactionRemoteStatus.SETTLED) {
    paymentRequestSectionContent = (
      <PaymentReceivedAndConfirmedSection
        onOpenPurchase={onOpenPurchase}
      />
    )
    // } else if (shoppingCart.paymentStatus === TransactionRemoteStatus.WAITING_FOR_FUNDS) {
    //   paymentRequestSectionContent = <PaymentReceivedAndNotYetConfirmedSection onOpenWallet={onOpenWallet} />
  } else if (!timeToGoOnPaymentRequest || timeToGoOnPaymentRequest < 1) {
    paymentRequestSectionContent = (
      <RequestExpiredSection
        isActiveShoppingCart={isActiveShoppingCart}
        onDeletePendingOrder={onDeletePendingOrder}
        onRefreshExchangeRate={onRefreshExchangeRate}
      />
    )
  } else if (shoppingCart.paymentCryptoAddress || shoppingCart.paymentProcessor === TransactionProcessor.COINPAYMENTS) {
    paymentRequestSectionContent = (
      <RequestInfoSection
        shoppingCart={shoppingCart}
        copyRequestMenuClickEvent={copyRequestMenuClickEvent}
        showCopyPaymentRequestMenu={showCopyPaymentRequestMenu}
        onCloseContextMenu={onCloseContextMenu}
        onCopyPaymentRequestPart={onCopyPaymentRequestPart}
        onCancelPaymentRequest={onCancelPaymentRequest}
        onDeletePendingOrder={onDeletePendingOrder}
        onOpenCopyRequestMenu={onOpenCopyRequestMenu}
        onRefreshExchangeRate={onRefreshExchangeRate}
      />
    )
  } else {
    paymentRequestSectionContent = <PreparingPaymentRequestSection onCancelPaymentRequest={onCancelPaymentRequest} />
  }

  return (
    <div className='crypto-form'>
      <div className='sectionCaption'>Crypto Payment Request</div>
      {paymentRequestSectionContent}
      {/* <div key='payment' className='section'> */}
      {/*   <TransactionInfo transaction={purchaseTransaction} showCaption /> */}
      {/* </div> */}
      <ExchangeRateSection
        shoppingCart={shoppingCart}
        isActiveShoppingCart={isActiveShoppingCart}
      />
    </div>
  )
}

export default CryptoForm
