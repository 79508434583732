import Moment from 'moment'
import React from 'react'
import type {
  GridColumns,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import {
  DataGridPro,
} from '@mui/x-data-grid-pro'

import type { RewardListItem } from '../../../lib/core/definitions'
import { FundType, TokenName } from '../../../lib/core/enums'
import coreHelpers from '../../../lib/core/helpers'

type Props = {
  rewards: RewardListItem[] | null | undefined;
  isProcessing?: boolean;
  onOpenReward: (rewardId: string) => void;
};

const RewardGrid: React.FC<Props> = (props): JSX.Element | null => {
  const { rewards, onOpenReward } = props

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (rowData: GridRowParams): void => {
    onOpenReward((rowData.row.id as string).replace(/-/g, ''))
  }
  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(rewards) || rewards.length < 1) {
    return null
  }
  // see https://material-ui.com/components/data-grid/rendering/#components

  const columns: GridColumns = [
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1.8,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? Moment(Number(params.value)).format('YYYY-MM-DD') : '-'
      ),
      sortComparator: (v1, v2) => (
        Number(v2) - Number(v1)
      ),
    },
    {
      field: 'amount',
      headerName: 'MIT',
      align: 'right',
      flex: 1.6,
      valueFormatter: (params: GridValueFormatterParams) => (
        coreHelpers.ui.formatAmount(
          Number(params.value),
          FundType.TOKEN,
          TokenName.MIMBLE_TOKEN,
          false,
          0,
        )
      ),
    },
    {
      field: 'rewardType',
      headerName: 'Reward',
      flex: 2.2,
      valueFormatter: (params: GridValueFormatterParams) => (
        String(params.value)
      ),
    },
  ]
  const rows: GridRowsProp = rewards
    .map((reward): GridRowModel => {
      return {
        id: reward.id,
        createdAt: reward.createdAt,
        amount: reward.amount,
        rewardType: reward.rewardType,
      }
    })

  return (
    <div className='x-grid-data-view'>
      <div className='x-grid-wrapper'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          onRowClick={onRowClick}
          disableColumnResize
          disableColumnReorder
        />
      </div>
    </div>
  )
}

export default RewardGrid
