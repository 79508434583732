import moment from 'moment'
import React, { useContext, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { gift, person, receipt } from 'ionicons/icons'
import { IonButton, IonContent, IonIcon, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import { RewardType, UiMessage } from '../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import type { RewardQueryData, RewardQueryVariables } from '../../services/apollo/definitions'
import type { Purchase, User } from '../../lib/core/definitions'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import coreHelpers from '../../lib/core/helpers'
import GiftCard from '../../components/GiftCard/GiftCard'
import type { InfoPaneEntryDef } from '../../components/InfoPane/InfoPane'
import InfoPane from '../../components/InfoPane/InfoPane'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import RewardObjectInfo from '../../components/objectInfo/RewardObjectInfo/RewardObjectInfo'
import UserInfoCard from '../../components/UserInfoCard/UserInfoCard'

const appPageId = AppPage.RewardPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  rewardId: string
}

const RewardPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { rewardId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { activeUser, isLoadingActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading reward:
  const {
    data: rewardLoadedData,
    loading: isLoadingReward,
    refetch: reloadReward,
  } = useQuery<RewardQueryData, RewardQueryVariables>(
    apollo.queries.reward, {
      variables: { rewardId: (rewardId as string).replace(/-/g, '') },
      skip: !rewardId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: RewardQueryData) => {
        const returnedReward = data ? data.reward : undefined
        if (returnedReward === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const reward = rewardLoadedData ? rewardLoadedData.reward : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingReward

  // ===================================================================================================================
  // Event Handlers:
  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !rewardId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadReward({ rewardId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenPurchase = (purchaseId: string): void => {
    navigate(`${AppRoute.GIFT_CARD}/${purchaseId}`)
  }

  const onOpenContact = (): void => {
    if (!reward || !reward.rewardType) {
      return
    }
    const contactUser = coreHelpers.models.reward.getContactUser(reward)
    if (!contactUser || !contactUser.id) {
      return
    }
    pageHelpers.openContact({
      activeUserId,
      contactUserId: contactUser.id,
      apolloClient,
      navigate,
    })
  }

  const onOpenTransaction = (): void => {
    if (!reward || !reward.transactionId) {
      return
    }
    navigate(`${AppRoute.TRANSACTION}/${reward.transactionId}`)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const sections: JSX.Element[] = []
  if (reward) {
    sections.push(
      <RewardObjectInfo
        key={reward.id}
        reward={reward}
      />,
    )
  }

  if (
    reward &&
    reward.rewardType &&
    coreHelpers.type.rewardType.getRewardTypesReferencingContact().includes(reward.rewardType)
  ) {
    let userIdentInfo
    if (reward.forInvitation && reward.forInvitation.toUser) {
      if (reward.rewardType === RewardType.NEW_USER_INVITATION) {
        userIdentInfo = reward.forInvitation.toUser
      } else if (reward.rewardType === RewardType.INVITED_BY_USER) {
        userIdentInfo = reward.forInvitation.fromUser
      }
    }
    if (reward.forPurchaseTransfer && reward.forPurchaseTransfer.toUser) {
      userIdentInfo = reward.forPurchaseTransfer.toUser
    }
    if (reward.forContact && reward.forContact.toUser) {
      if (reward.rewardType === RewardType.ADDED_AS_A_CONTACT) {
        userIdentInfo = reward.forContact.fromUser
      } else {
        userIdentInfo = reward.forContact.toUser
      }
    }

    if (userIdentInfo) {
      const onOpenContactFnc = coreHelpers.models.reward.canOpenChat(reward) ? onOpenContact : undefined
      sections.push(
        <div key='contact'>
          <div className='withStandardBottomMargin section-header'>
            <IonIcon icon={person} className='withStandardRightMargin' /> Contact
          </div>
          <UserInfoCard
            userIdentInfo={userIdentInfo as User}
            className='section-content withStandardTopMargin info-pane-value'
            showChat
            onOpenContact={onOpenContactFnc}
          />
        </div>,
      )
    }
  }

  let purchase: Purchase | undefined
  let purchaseCaption = 'Purchase'
  if (
    reward &&
    reward.forPurchaseId &&
    reward.forPurchase &&
    reward.forPurchase.product &&
    reward.forPurchase.product.merchant
  ) {
    purchase = reward.forPurchase
  } else if (
    reward &&
    reward.invitationLevel === 0 && // we don't want to show the purchase of an invited member
    reward.forPurchaseTransfer &&
    reward.forPurchaseTransfer.fromPurchase
  ) {
    purchase = reward.forPurchaseTransfer.fromPurchase
    purchaseCaption = 'Gift'
  }

  if (purchase) {
    const purchaseId = (purchase.id as string).replace(/-/g, '')
    const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromPurchase(purchase)
    const merchantName = purchase.product && purchase.product.merchant ? purchase.product.merchant.name : 'n/a'

    const entries: InfoPaneEntryDef[] = [
      {
        key: 'id',
        label: 'ID',
        value: purchaseId,
      },
      {
        key: 'brand',
        label: 'Brand',
        value: merchantName,
      },
      {
        key: 'date',
        label: 'Purchase Date',
        value: moment(Number(purchase.createdAt)).format('MM/DD/YYYY hh:mm a'),
      },
      {
        key: 'amount',
        label: 'Amount',
        value: coreHelpers.ui.formatAmount(
          purchase.balance,
          purchase.fundType,
          purchase.currency,
          true,
          0,
        ),
      },
    ]
    sections.push(
      <div key='purchase'>
        <div className='withStandardBottomMargin section-header'>
          <IonIcon icon={gift} className='section-icon' /> {purchaseCaption}
        </div>
        <div className='section-content'>
          <div className='withStandardBottomMargin withMaxWidth150px'>
            <GiftCard
              purchaseId={purchase.id as string}
              backgroundImageUri={giftCardBackgroundUri}
              merchantLogoUri={merchantLogoUri}
              small
            />
          </div>
          <InfoPane
            entries={entries}
          />
          <IonButton
            size='small'
            onClick={() => onOpenPurchase(purchaseId)}
          >
            Open
          </IonButton>
        </div>
      </div>,
    )
  }

  if (
    reward &&
    reward.transactionId &&
    reward.transaction
  ) {
    const entries: InfoPaneEntryDef[] = [
      {
        key: 'id',
        label: 'ID',
        value: reward.transactionId.replace(/-/g, ''),
      },
      {
        key: 'status',
        label: 'Status',
        value: coreHelpers.type.transactionStatus.getLabel(reward.transaction.status),
      },
      {
        key: 'amount',
        label: 'Amount',
        value: coreHelpers.ui.formatAmount(
          reward.transaction.amount,
          reward.transaction.fundType,
          reward.transaction.currency,
          true,
          0,
        ),
      },
      {
        key: 'date',
        label: 'Date',
        value: moment(Number(reward.transaction.createdAt)).format('MM/DD/YYYY hh:mm a'),
      },
    ]
    sections.push(
      <div key='transaction'>
        <div className='withStandardBottomMargin section-header'>
          <IonIcon icon={receipt} className='section-icon' /> Transaction
        </div>
        <div className='section-content'>
          <InfoPane
            entries={entries}
          />
          <IonButton
            size='small'
            onClick={onOpenTransaction}
          >
            Open
          </IonButton>
        </div>
      </div>,
    )
  }

  const content = (
    <div>
      {sections}
    </div>
  )

  return (
    <IonPage className='app-page-public reward-page'>
      <NavBar
        title='Reward'
        goBackUri={`${AppRoute.TOKENS}/rewards`}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default RewardPage
