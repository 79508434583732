import type { PurchasesQueryVariables } from '../definitions'
import type { PurchaseListFilter } from '../../../lib/core/definitions'
import getPurchaseListFilter from './getPurchaseListFilter'

const getPurchasesQueryVariables = (
  listFilter: PurchaseListFilter | undefined,
  activeUserId: string,
): PurchasesQueryVariables => ({
  filter: listFilter || getPurchaseListFilter(activeUserId),
})

export default getPurchasesQueryVariables
