import React, { useEffect, useState } from 'react'
import {
  IonButton,
  IonCheckbox,
  IonInput,
} from '@ionic/react'
import { Editor } from '@tinymce/tinymce-react'

import './styles.css'
import '../../../theme/default-html-content.css'
import type { Merchant, MerchantInput } from '../../../lib/core/definitions'
import { OrganizationStatus, OrganizationType } from '../../../lib/core/enums'
import FormItem from '../../../components/FormItem/FormItem'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../helpers/validationHelpers'

type Props = {
  merchant?: Merchant | null
  onEditMainProduct: () => void
  onGoBack: () => void
  onSave: (merchant: MerchantInput) => void
}

const MerchantForm: React.FC<Props> = ({
  merchant = null,
  onEditMainProduct,
  onSave,
  onGoBack,
}): JSX.Element => {
  const [name, setName] = useState<string | undefined>()
  const [nameValidationError, setNameValidationError] = useState<string | undefined>()
  const [alias1, setAlias1] = useState<string | undefined>()
  const [alias1ValidationError, setAlias1ValidationError] = useState<string | undefined>()
  const [alias2, setAlias2] = useState<string | undefined>()
  const [alias2ValidationError, setAlias2ValidationError] = useState<string | undefined>()
  const [alias3, setAlias3] = useState<string | undefined>()
  const [alias3ValidationError, setAlias3ValidationError] = useState<string | undefined>()
  const [description, setDescription] = useState<string | undefined>()
  const [descriptionValidationError, setDescriptionValidationError] = useState<string | undefined>()
  const [url, setUrl] = useState<string | undefined>()
  const [urlValidationError, setUrlValidationError] = useState<string | undefined>()
  const [smallLogoImageSource, setSmallLogoImageSource] = useState<string | undefined>()
  const [smallLogoImageSourceValidationError, setSmallLogoImageSourceValidationError] = useState<string | undefined>()
  const [largeLogoImageSource, setLargeLogoImageSource] = useState<string | undefined>()
  const [largeLogoImageSourceValidationError, setLargeLogoImageSourceValidationError] = useState<string | undefined>()
  const [balanceLookupUri, setBalanceLookupUri] = useState<string | undefined>()
  const [balanceLookupUriValidationError, setBalanceLookupUriValidationError] = useState<string | undefined>()
  const [paymentTypes, setPaymentTypes] = useState<string | undefined>()
  const [paymentTypesValidationError, setPaymentTypesValidationError] = useState<string | undefined>()
  const [source, setSource] = useState<string | undefined>()
  const [sourceValidationError, setSourceValidationError] = useState<string | undefined>()
  const [listed, setListed] = useState(undefined as boolean | undefined)

  // ===================================================================================================================
  // Helpers:
  const nameChanged = name !== undefined && !(merchant && name === merchant.name)
  const alias1Changed = alias1 !== undefined && !(merchant && alias1 === merchant.alias1)
  const alias2Changed = alias2 !== undefined && !(merchant && alias2 === merchant.alias2)
  const alias3Changed = alias3 !== undefined && !(merchant && alias3 === merchant.alias3)
  const descriptionChanged = description !== undefined && !(merchant && description === merchant.description)
  const urlChanged = url !== undefined && !(merchant && url === merchant.url)
  const smallLogoImageSourceChanged = smallLogoImageSource !== undefined && !(merchant && smallLogoImageSource === merchant.smallLogoImageSource)
  const largeLogoImageSourceChanged = largeLogoImageSource !== undefined && !(merchant && largeLogoImageSource === merchant.largeLogoImageSource)
  const balanceLookupUriChanged = balanceLookupUri !== undefined && !(merchant && balanceLookupUri === merchant.balanceLookupUri)
  const paymentTypesChanged = paymentTypes !== undefined && !(merchant && paymentTypes === merchant.paymentTypes)
  const sourceChanged = source !== undefined && !(merchant && source === merchant.source)
  const listedChanged = listed !== undefined && !(merchant && listed === merchant.listed)

  const isDirty = (
    nameChanged ||
    alias1Changed ||
    alias2Changed ||
    alias3Changed ||
    descriptionChanged ||
    urlChanged ||
    smallLogoImageSourceChanged ||
    largeLogoImageSourceChanged ||
    balanceLookupUriChanged ||
    paymentTypesChanged ||
    sourceChanged ||
    listedChanged
  )
  const isValid = (
    !nameValidationError &&
    !alias1ValidationError &&
    !alias2ValidationError &&
    !alias3ValidationError &&
    !descriptionValidationError &&
    !smallLogoImageSourceValidationError &&
    !largeLogoImageSourceValidationError &&
    !balanceLookupUriValidationError &&
    !paymentTypesValidationError &&
    !sourceValidationError
  )
  // console.log('MerchantForm.render called.', {
  //   nameValidationError,
  //   smallLogoImageSourceValidationError,
  //   largeLogoImageSourceValidationError,
  //   sourceValidationError,
  //   isDirty,
  //   isValid,
  // })

  const resetForm = (): void => {
    setName(undefined)
    setNameValidationError(undefined)
    setAlias1(undefined)
    setAlias1ValidationError(undefined)
    setAlias2(undefined)
    setAlias2ValidationError(undefined)
    setAlias3(undefined)
    setAlias3ValidationError(undefined)
    setDescription(undefined)
    setDescriptionValidationError(undefined)
    setSmallLogoImageSource(undefined)
    setSmallLogoImageSourceValidationError(undefined)
    setLargeLogoImageSource(undefined)
    setLargeLogoImageSourceValidationError(undefined)
    setBalanceLookupUri(undefined)
    setBalanceLookupUriValidationError(undefined)
    setPaymentTypes(undefined)
    setPaymentTypesValidationError(undefined)
    setSource(undefined)
    setSourceValidationError(undefined)
    setListed(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('MerchantForm: new merchant received - resetting form.')
    resetForm()
  }, [merchant])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeName = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.name || (!event.detail.value && !merchant.name))) ||
      (!merchant && !event.detail.value)
    ) {
      setNameValidationError('')
      setName(undefined)
      return
    }
    setName(event.detail.value)
    if (event.detail.value) {
      setNameValidationError(validationHelpers.validateMerchantName(event.detail.value))
    } else {
      setNameValidationError('')
    }
  }

  const onChangeAlias1 = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.alias1 || (!event.detail.value && !merchant.alias1))) ||
      (!merchant && !event.detail.value)
    ) {
      setAlias1ValidationError('')
      setAlias1(undefined)
      return
    }
    setAlias1(event.detail.value)
    if (event.detail.value) {
      setAlias1ValidationError(validationHelpers.validateMerchantName(event.detail.value))
    } else {
      setAlias1ValidationError('')
    }
  }

  const onChangeAlias2 = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.alias2 || (!event.detail.value && !merchant.alias2))) ||
      (!merchant && !event.detail.value)
    ) {
      setAlias2ValidationError('')
      setAlias2(undefined)
      return
    }
    setAlias2(event.detail.value)
    if (event.detail.value) {
      setAlias2ValidationError(validationHelpers.validateMerchantName(event.detail.value))
    } else {
      setAlias2ValidationError('')
    }
  }

  const onChangeAlias3 = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.alias3 || (!event.detail.value && !merchant.alias3))) ||
      (!merchant && !event.detail.value)
    ) {
      setAlias3ValidationError('')
      setAlias3(undefined)
      return
    }
    setAlias3(event.detail.value)
    if (event.detail.value) {
      setAlias3ValidationError(validationHelpers.validateMerchantName(event.detail.value))
    } else {
      setAlias3ValidationError('')
    }
  }

  const onChangeDescription = (newDescription: string): void => {
    if (
      (merchant && (newDescription === merchant.description || (!newDescription && !merchant.description))) ||
      (!merchant && !newDescription)
    ) {
      setDescriptionValidationError('')
      setDescription(undefined)
      return
    }
    setDescription(newDescription)
    if (newDescription) {
      setDescriptionValidationError(validationHelpers.validateMerchantDescription(newDescription))
    } else {
      setDescriptionValidationError('')
    }
  }

  const onChangeUrl = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.url || (!event.detail.value && !merchant.url))) ||
      (!merchant && !event.detail.value)
    ) {
      setUrlValidationError('')
      setUrl(undefined)
      return
    }
    setUrl(event.detail.value)
    if (event.detail.value) {
      setUrlValidationError(validationHelpers.validateUrl(event.detail.value))
    } else {
      setUrlValidationError('')
    }
  }

  const onChangeSmallLogoImageSource = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.smallLogoImageSource || (!event.detail.value && !merchant.smallLogoImageSource))) ||
      (!merchant && !event.detail.value)
    ) {
      setSmallLogoImageSourceValidationError('')
      setSmallLogoImageSource(undefined)
      return
    }
    setSmallLogoImageSource(event.detail.value)
    if (event.detail.value) {
      setSmallLogoImageSourceValidationError(validationHelpers.validateImageSource(event.detail.value))
    } else {
      setSmallLogoImageSourceValidationError('')
    }
  }

  const onChangeLargeLogoImageSource = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.largeLogoImageSource || (!event.detail.value && !merchant.largeLogoImageSource))) ||
      (!merchant && !event.detail.value)
    ) {
      setLargeLogoImageSourceValidationError('')
      setLargeLogoImageSource(undefined)
      return
    }
    setLargeLogoImageSource(event.detail.value)
    if (event.detail.value) {
      setLargeLogoImageSourceValidationError(validationHelpers.validateImageSource(event.detail.value))
    } else {
      setLargeLogoImageSourceValidationError('')
    }
  }

  const onChangeBalanceLookupUri = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.balanceLookupUri || (!event.detail.value && !merchant.balanceLookupUri))) ||
      (!merchant && !event.detail.value)
    ) {
      setBalanceLookupUriValidationError('')
      setBalanceLookupUri(undefined)
      return
    }
    setBalanceLookupUri(event.detail.value)
    if (event.detail.value) {
      setBalanceLookupUriValidationError(validationHelpers.validateMerchantBalanceLookupUri(event.detail.value))
    } else {
      setBalanceLookupUriValidationError('')
    }
  }

  const onChangePaymentTypes = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.paymentTypes || (!event.detail.value && !merchant.paymentTypes))) ||
      (!merchant && !event.detail.value)
    ) {
      setPaymentTypesValidationError('')
      setPaymentTypes(undefined)
      return
    }
    setPaymentTypes(event.detail.value)
    if (event.detail.value) {
      setPaymentTypesValidationError(validationHelpers.validatePaymentTypes(event.detail.value))
    } else {
      setPaymentTypesValidationError('')
    }
  }

  const onChangeSource = (event: any): void => {
    if (
      (merchant && (event.detail.value === merchant.source || (!event.detail.value && !merchant.source))) ||
      (!merchant && !event.detail.value)
    ) {
      setSourceValidationError('')
      setSource(undefined)
      return
    }
    setSource(event.detail.value)
    if (event.detail.value) {
      setSourceValidationError(validationHelpers.validateMerchantSource(event.detail.value))
    } else {
      setSourceValidationError('')
    }
  }

  const onChangeListed = (event: any): void => {
    if (
      (merchant && event.detail.checked === merchant.listed) ||
      (!merchant && event.detail.checked)
    ) {
      setListed(undefined)
      return
    }
    setListed(event.detail.checked)
  }

  const onClickSaveButton = (event: any): void => {
    event.preventDefault()
    const merchantInput: MerchantInput = {}
    if (merchant && merchant.id) {
      merchantInput.id = merchant.id
    }
    if (name !== undefined && (!merchant || name !== merchant.name)) {
      merchantInput.name = name || null
    }
    if (alias1 !== undefined && (!merchant || alias1 !== merchant.alias1)) {
      merchantInput.alias1 = alias1 || null
    }
    if (alias2 !== undefined && (!merchant || alias2 !== merchant.alias2)) {
      merchantInput.alias2 = alias2 || null
    }
    if (alias3 !== undefined && (!merchant || alias3 !== merchant.alias3)) {
      merchantInput.alias3 = alias3 || null
    }
    if (description !== undefined && (!merchant || description !== merchant.description)) {
      merchantInput.description = description || null
    }
    if (url !== undefined && (!merchant || url !== merchant.url)) {
      merchantInput.url = url || null
    }
    if (smallLogoImageSource !== undefined && (!merchant || smallLogoImageSource !== merchant.smallLogoImageSource)) {
      merchantInput.smallLogoImageSource = smallLogoImageSource || null
    }
    if (largeLogoImageSource !== undefined && (!merchant || largeLogoImageSource !== merchant.largeLogoImageSource)) {
      merchantInput.largeLogoImageSource = largeLogoImageSource || null
    }
    if (balanceLookupUri !== undefined && (!merchant || balanceLookupUri !== merchant.balanceLookupUri)) {
      merchantInput.balanceLookupUri = balanceLookupUri || null
    }
    if (paymentTypes !== undefined && (!merchant || paymentTypes !== merchant.paymentTypes)) {
      merchantInput.paymentTypes = paymentTypes || null
    }
    if (source !== undefined && (!merchant || source !== merchant.source)) {
      merchantInput.source = source || null
    }
    if (listed !== undefined && (!merchant || listed !== merchant.listed)) {
      merchantInput.listed = listed
    }
    if (!merchantInput.id && !merchantInput.organizationType) {
      merchantInput.organizationType = OrganizationType.MERCHANT
    }
    if (!merchantInput.id && !merchantInput.status) {
      merchantInput.status = OrganizationStatus.ACTIVE
    }

    onSave(merchantInput)
  }

  let listedDisplay = merchant ? merchant.listed === true : true
  let listedMainProductNote
  if (listed !== undefined) {
    listedDisplay = listed
  }
  if (merchant && merchant.mainProduct && listed !== merchant.mainProduct.listed) {
    if (merchant && merchant.mainProduct && merchant.mainProduct.listed) {
      listedMainProductNote = 'Note that the main product of this merchant is marked as "listed".'
    } else {
      listedMainProductNote = 'Note that the main product of this merchant is marked as "unlisted", so the member will not see this merchant even if you mark them as listed here.'
    }
  }

  return (
    <form onSubmit={onClickSaveButton}>
      <FormItem label='Brand Name' validationError={nameValidationError} withBottomMargin>
        <IonInput
          autofocus
          onIonChange={onChangeName}
          placeholder='name'
          value={name !== undefined ? name : (merchant ? merchant.name : '')}
        />
      </FormItem>
      <FormItem label='Alias 1' validationError={alias1ValidationError} withBottomMargin>
        <IonInput
          autofocus
          onIonChange={onChangeAlias1}
          placeholder='alias 1'
          value={alias1 !== undefined ? alias1 : (merchant ? merchant.alias1 : '')}
        />
      </FormItem>
      <FormItem label='Alias 2' validationError={alias2ValidationError} withBottomMargin>
        <IonInput
          autofocus
          onIonChange={onChangeAlias2}
          placeholder='alias 2'
          value={alias2 !== undefined ? alias2 : (merchant ? merchant.alias2 : '')}
        />
      </FormItem>
      <FormItem label='Alias 3' validationError={alias3ValidationError} withBottomMargin>
        <IonInput
          autofocus
          onIonChange={onChangeAlias3}
          placeholder='alias 3'
          value={alias3 !== undefined ? alias3 : (merchant ? merchant.alias3 : '')}
        />
      </FormItem>
      <FormItem label='Brand Description' validationError={descriptionValidationError} withBottomMargin>
      <Editor
          apiKey='dv7dwodu7u7ujzaik6cr3g42xsq8jdtmbcovnwmsuniiindk'
          onEditorChange={onChangeDescription}
          // init={{ min_height: 400, max_height: 600, browser_spellcheck: true, contextmenu: false, autosave_interval: '15s' }}
          init={
            {
              content_css: './styles.css',
              min_height: 400,
              max_width: 600,
              browser_spellcheck: true,
              contextmenu: false,
              autosave_interval: '15s',
            }
          }
          value={(description !== undefined) ? description : (merchant && merchant.description ? merchant.description : '')}
          plugins='print preview paste importcss searchreplace autolink autosave save directionality code visualblocks
           visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc
            insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons'
          toolbar='undo redo | bold italic underline strikethrough forecolor | fontselect fontsizeselect formatselect |
           alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |
            pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl'
        />
      </FormItem>
      <FormItem label='Corporate website URL' validationError={urlValidationError} withBottomMargin>
        <IonInput
          type='url'
          inputmode='url'
          onIonChange={onChangeUrl}
          placeholder='corporate website URL'
          value={url !== undefined ? url : (merchant ? merchant.url : '')}
        />
      </FormItem>
      {/* <FormItem */}
      {/*  label="Small logo" */}
      {/*  validationError={smallLogoImageSourceValidationError} */}
      {/*  withBottomMargin */}
      {/* > */}
      {/*  <IonInput */}
      {/*    onIonChange={onChangeSmallLogoImageSource} */}
      {/*    placeholder="small logo" */}
      {/*    value={smallLogoImageSource !== undefined ? smallLogoImageSource : (merchant ? merchant.smallLogoImageSource : '')} */}
      {/*  /> */}
      {/* </FormItem> */}
      <FormItem label='Logo image' validationError={largeLogoImageSourceValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeLargeLogoImageSource}
          placeholder='logo image'
          value={largeLogoImageSource !== undefined ? largeLogoImageSource : (merchant ? merchant.largeLogoImageSource : '')}
        />
      </FormItem>
      <FormItem label='Balance Lookup URL' validationError={balanceLookupUriValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeBalanceLookupUri}
          placeholder='balance lookup URL'
          value={balanceLookupUri !== undefined ? balanceLookupUri : (merchant ? merchant.balanceLookupUri : '')}
        />
      </FormItem>
      <FormItem label='Payment Types' validationError={paymentTypesValidationError}>
        <IonInput
          onIonChange={onChangePaymentTypes}
          placeholder='paymentType'
          value={paymentTypes !== undefined ? paymentTypes : (merchant ? merchant.paymentTypes : '')}
        />
      </FormItem>
      <div className='smallText lightText withStandardBottomMargin'>
        cc, crypto, mit, or leave empty to allow all
      </div>
      <FormItem label='Source (who/what added this brand to our database?)' validationError={sourceValidationError} withBottomMargin>
        <IonInput
          onIonChange={onChangeSource}
          placeholder='source'
          value={source !== undefined ? source : (merchant ? merchant.source : '')}
        />
      </FormItem>
      <FormItem label='Listed?'>
        <IonCheckbox
          onIonChange={onChangeListed}
          checked={listedDisplay}
        />
      </FormItem>
      <div className='smallText lightText withStandardBottomMargin'>
        Uncheck to hide this brand from the users. <strong>{listedMainProductNote}</strong>
      </div>
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onGoBack}
        >
          Back
        </IonButton>
        <SubmitButton
          onClick={onClickSaveButton}
          disabled={!isDirty || !isValid}
        >
          Save
        </SubmitButton>
      </div>
      <div className='linkText withCenteredColumnContent' onClick={onEditMainProduct}>
        Edit Main Product
      </div>
    </form>
  )
}

export default MerchantForm
