import moment from 'moment'
import React from 'react'
import { IonButton, IonIcon, isPlatform } from '@ionic/react'
import { calendarOutline, copyOutline, shareSocial, warningOutline } from 'ionicons/icons'
import { Share } from '@capacitor/share'

import { NotificationMethod } from '../../lib/core/enums'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import appLinks from '../../lib/core/appLinks'
import coreHelpers from '../../lib/core/helpers'
import pageHelpers from '../../helpers/pageHelpers'
import SubmitButton from '../../components/SubmitButton/SubmitButton'

type Props = {
  onOpenChat?: () => void;
  onOpenEvents: () => void;
  onOpenPurchase: () => void;
  onOpenWallet: () => void;
  showUiMessage: (message: string) => void;
}

const FinishedStep: React.FC<Props> = ({
  onOpenChat,
  onOpenEvents,
  onOpenPurchase,
  onOpenWallet,
  showUiMessage,
}): JSX.Element | null => {
  const {
    purchaseTransfer,
  } = useGiftFlow()

  const purchaseTransferId = purchaseTransfer && purchaseTransfer.id
  const giftLink = appLinks.gift(purchaseTransferId, undefined)
  const recipientName = purchaseTransfer && purchaseTransfer.toUserFullName

  // ===================================================================================================================
  // Event Handlers:
  const onCopyGiftLinkToClipboard = (): void => {
    if (giftLink) {
      pageHelpers.copyInputTextToClipboard(
        giftLink,
        'Gift Card Link',
        showUiMessage,
      )
    }
  }

  const onShare = () => {
    if (!giftLink) {
      console.log('FinishedStep.onShare: no giftLink available.')
      return
    }
    // console.log('FinishedStep.onShare called.', giftLink)
    if (isPlatform('capacitor')) {
      Share.share({
        url: giftLink,
        dialogTitle: 'Sending you a gift!',
      }).then((result) => {
        console.log('FinishedStep.handleShareButtonClick: share was successful.', { result })
      }, (error) => {
        console.log(error)
        showUiMessage('Failed to share the gift link. Please copy and share it manually.')
      })
    }
  }

  // ===================================================================================================================
  // Rendering:
  if (!purchaseTransfer) {
    return null
  }

  const parts: JSX.Element[] = []

  if (purchaseTransfer && purchaseTransfer.notificationMethod === NotificationMethod.OFF) {
    parts.push(
      <div key='s1' className='withDoubleBottomMargin'>
        <div className='withDoubleBottomMargin'>
          The gift is now ready.
          Send {recipientName || 'them'} the Gift Link with a text message,
          an email, on Signal or any other way you like.
          <br /><br />
          Just make sure only {recipientName || 'they'} will receive the Gift Link
          (so don&apos;t post it to a social media network)!
        </div>
        <div className='warning'>
          <IonIcon icon={warningOutline} className='inlineWarnIcon' />
          If the recipient is not already a Mimble member, anyone can accept
          your gift and use it with this link. You are essentially sending money.
        </div>
      </div>,
    )
  } else {
    if (purchaseTransfer.messageScheduledAt) {
      const timeScheduled = coreHelpers.string.parseDate(
        purchaseTransfer.messageScheduledAt,
        purchaseTransfer.messageScheduledTimezone,
      ) || moment(purchaseTransfer.messageScheduledAt)
      let timezoneLabel = purchaseTransfer.messageScheduledTimezone
        ? pageHelpers.timezones.getSimpleTimezoneLabel(
          purchaseTransfer.messageScheduledTimezone,
        ) || purchaseTransfer.messageScheduledTimezone
        : ''
      if (timezoneLabel) {
        timezoneLabel = ` (${timezoneLabel})`
      }
      parts.push(
        <div key='s1' className='withDoubleBottomMargin'>
          Your gift is now scheduled to be sent to {recipientName || 'the recipient'} on&nbsp;
          {timeScheduled.format('dddd, MMMM Do, YYYY')} at&nbsp;
          {timeScheduled.format('hh:mm a')}{timezoneLabel}.
        </div>,
      )
    } else {
      parts.push(
        <div key='s1' className='withDoubleBottomMargin'>
          Your gift will be sent immediately!
        </div>,
      )
    }

    if (purchaseTransfer && purchaseTransfer.toUserId) {
      parts.push(
        <div key='s2' className='withStandardBottomMargin'>
          They will be notified and your gift will show up in their Mimble wallet.&nbsp;
          <span className='lightText'>
            You can check on the status of your gift anytime by viewing it in your wallet.
          </span>
        </div>,
      )
    } else {
      parts.push(
        <div key='s2' className='withStandardBottomMargin'>
          They will receive the Gift Link with your message and some instructions
          to set up their Mimble account.&nbsp;
          <span className='lightText'>
            You can check on the status of your gift anytime by viewing it in your wallet.
          </span>
        </div>,
      )
    }
  }

  if (purchaseTransfer && purchaseTransfer.notificationMethod === NotificationMethod.OFF) {
    let actionButton: JSX.Element | undefined
    if (isPlatform('capacitor')) {
      actionButton = (
        <IonButton
          onClick={onShare}
          className='withDoubleBottomMargin'
        >
        <IonIcon icon={shareSocial} className='withStandardRightMargin' />
        Send
        </IonButton>
      )
    } else {
      actionButton = (
        <IonButton
        onClick={onCopyGiftLinkToClipboard}
        className='withDoubleBottomMargin'
      >
        <IonIcon icon={copyOutline} className='withStandardRightMargin' /> Copy
      </IonButton>
      )
    }

    parts.push(
      <div key='s4' className='withDoubleBottomMargin'>
        <div className='gift-link-label'>Gift Link</div>
        <div className='gift-link'>
          {giftLink}
        </div>
        <div className='withCenteredTextAlign'>
          {actionButton}
        </div>
        <div className='lightText withStandardBottomMargin'>
          You can check on the status of your gift anytime by viewing it in your wallet.
        </div>
      </div>,
    )
  }

  return (
    <div className='purchase-transfer-finished-step'>
      <div className='sectionCaption'>Success!</div>
      {parts}
      <div className='formButtonWrapper'>
        <div key='discard' className='withCenteredColumnContent withStandardBottomMargin'>
          <IonButton
            fill='clear'
            size='small'
            onClick={onOpenPurchase}
          >
            <div className='g-gray-text-button-label'>Review this gift</div>
          </IonButton>
        </div>
        <SubmitButton
          className='withStandardLeftMargin'
          onClick={onOpenEvents}
        >
          <IonIcon icon={calendarOutline} className='withStandardRightMargin' />
          View Upcoming Events
        </SubmitButton>
      </div>
    </div>
  )
}

export default FinishedStep
