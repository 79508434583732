import { gql } from '@apollo/client'

export default gql`
  query Q($filter: PurchaseListFilter!) {
    purchases (filter: $filter) {
      id
      userId
      offerId
      productId
      productOptionId
      merchantId
      merchantName
      merchantSmallLogoImageSource
      merchantLargeLogoImageSource
      productImageSourceFrontSide
      purchaseType
      productType
      amount
      fundType
      currency
      balance
      balanceUpdatedAt
      discount
      code
      source
      metadata {
        amountPaid
        receivedTransfer {
          id
          fromUserId
          fromPurchaseId
          transferStatus
          recordStatus
        }
        sentTransfer {
          id
          toUserId
          toPurchaseId
          transferStatus
          recordStatus
        }
        latestError
        events {
          time
          type
          message
        }
      }
      status
      recordStatus
      createdAt
      updatedAt
    }
  }`
