import type { RewardConfigMetadata } from '../../../definitions'

const isMetadataIdentical = (
  RewardConfigMetadata1: RewardConfigMetadata | null | undefined,
  RewardConfigMetadata2: RewardConfigMetadata | null | undefined,
): boolean => {
  if (!RewardConfigMetadata1 && !RewardConfigMetadata2) {
    return true
  }

  if (
    (RewardConfigMetadata1 && !RewardConfigMetadata2) ||
    (!RewardConfigMetadata1 && RewardConfigMetadata2)
  ) {
    return false
  }

  if (!RewardConfigMetadata1 || !RewardConfigMetadata2) {
    return false
  }

  if (RewardConfigMetadata1.issuedCount !== RewardConfigMetadata2.issuedCount) {
    return false
  }

  return true
}

export default isMetadataIdentical
