import formatAmount from './formatAmount'
import formatDate from './formatDate'
import formatDuration from './formatDuration'
import formatModelId from './formatModelId'
import formatPercentAmount from './formatPercentAmount'
import formatPhoneNumber from './formatPhoneNumber'
import formatWeekday from './formatWeekday'
import formatUuid from './formatUuid'
import getAmountDisplayFactor from './getAmountDisplayFactor'
import getAmountPrecision from './getAmountPrecision'
import getDisplayMoneyAmount from './getDisplayMoneyAmount'
import getInternalMoneyAmount from './getInternalMoneyAmount'
import isPhoneNumber from './isPhoneNumber'

const uiHelpers = {
  formatAmount,
  formatBirthday: (value: string | null | undefined): string => value || '',
  formatDate,
  formatDuration,
  formatModelId,
  formatPercentAmount,
  formatPhoneNumber,
  formatWeekday,
  formatUuid,
  getAmountDisplayFactor,
  getAmountPrecision,
  getDisplayMoneyAmount,
  getInternalMoneyAmount,
  isPhoneNumber,
}

export default uiHelpers
