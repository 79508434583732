import {
  PurchaseListScope,
  PurchaseStatus,
  PurchaseTransferStatus,
  RecordStatus,
} from '../../lib/core/enums'
import type { PurchaseMetadata } from '../../lib/core/definitions'

const isPurchaseInScope = (
  scope: PurchaseListScope | undefined,
  balance: number,
  metadata: PurchaseMetadata | null,
  status: PurchaseStatus | null | undefined,
  recordStatus: RecordStatus | null | undefined,
): boolean => {
  if (!scope || scope === PurchaseListScope.ACTIVE) {
    return (
      (status === PurchaseStatus.ACTIVE || status === PurchaseStatus.RECEIVED) &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  if (!scope || scope === PurchaseListScope.TRANSFERRABLE) {
    return (
      balance > 0 &&
      status === PurchaseStatus.ACTIVE &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  // todo: handle favorites, after that attributes becomes available
  if (!scope || scope === PurchaseListScope.FAVORITES) {
    return (
      balance > 0 &&
      status === PurchaseStatus.ACTIVE &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  if (scope === PurchaseListScope.PENDING) {
    return (
      (
        status === PurchaseStatus.PENDING_DELIVERY ||
        status === PurchaseStatus.TRANSFERRING ||
        // status === PurchaseStatus.RECEIVED ||
        status === PurchaseStatus.PAYMENT_REQUEST
      ) &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  if (scope === PurchaseListScope.TRANSFERRED) {
    return (
      status === PurchaseStatus.TRANSFERRED &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  if (scope === PurchaseListScope.SENT) {
    return (
      !!metadata &&
      !!metadata.sentTransfer &&
      !!metadata.sentTransfer.id &&
      metadata.sentTransfer.transferStatus !== PurchaseTransferStatus.CANCELED &&
      metadata.sentTransfer.transferStatus !== PurchaseTransferStatus.DECLINED &&
      metadata.sentTransfer.transferStatus !== PurchaseTransferStatus.INVALID &&
      recordStatus === RecordStatus.ACTIVE
    )
  }
  if (scope === PurchaseListScope.RECEIVED) {
    return (
      status === PurchaseStatus.RECEIVED &&
      recordStatus === RecordStatus.ACTIVE
    )
    // Once we mark incoming gift cards with as "not yet activated"
    // we can show a "Received" tab with all cards the user
    // has received, activated or not. Until then, we'll call the
    // tab "Inbox" and show only those that have not yet been activated.
    // return (
    //   !!receivedFromUserId &&
    //   recordStatus === RecordStatus.ACTIVE
    // )
  }
  if (scope === PurchaseListScope.ARCHIVED) {
    return recordStatus === RecordStatus.INACTIVE
  }
  return false
}

export default isPurchaseInScope
