import React, { useContext, useState } from 'react'
import { IonContent, IonPage, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import './styles.css'
import type { AdminTaskInput } from '../../../lib/core/admin/definitions'
import { AppPage, AppRoute } from '../../../enums'
import type { ExecuteAdminTaskMutationData, ExecuteAdminTaskMutationVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import logger from '../../../services/logger'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import ExecuteAdminTaskForm from './ExecuteAdminTaskForm'

const appPageId = AppPage.AdminExecuteAdminTaskPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const ExecuteAdminTaskPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const goBackUri = AppRoute.ADMIN_TAGS

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading admin task:
  const [
    executeAdminTask, {
      loading: isExecutingAdminTask,
      data: executeAdminTaskData,
    },
  ] = useMutation<ExecuteAdminTaskMutationData, ExecuteAdminTaskMutationVariables>(apollo.admin.mutations.executeAdminTask, {
    notifyOnNetworkStatusChange: true,
    onError (error) {
      console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
    },
    // refetchQueries: ['reloadTag'],
    onCompleted: (data) => {
      console.log('onCompleted called.', { data })
    },
  })
  const result = executeAdminTaskData ? executeAdminTaskData.executeAdminTask : undefined
  const data = result ? result.data : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isExecutingAdminTask

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  // })

  // ===================================================================================================================
  // Event Handlers:
  const onExecuteAdminTask = (input: AdminTaskInput): void => {
    executeAdminTask({
      variables: { input },
    }).then((data) => {
      logger.info('ExecuteAdminTaskPage.onExecuteAdminTask: succeeded.', { data })
    }, (error) => {
      logger.info('ExecuteAdminTaskPage.onExecuteAdminTask: failed.', { error })
    })
  }

  const onGoBack = (): void => {
    navigate(-1)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let report: JSX.Element | undefined
  if (executeAdminTaskData && executeAdminTaskData.executeAdminTask) {
    report = (
      report = (
        <div className='result-data'>
          <pre>
            Task: {executeAdminTaskData.executeAdminTask.task}
          </pre>
          <pre>
            OK: {executeAdminTaskData.executeAdminTask.ok ? 'yes' : 'no'}
          </pre>
          <hr />
          <pre>
            {data ? JSON.stringify(data, undefined, 2) : '(no data returned)'}
          </pre>
        </div>
      )
    )
  }

  return (
    <IonPage className='app-page-admin execute-admin-task-page'>
      <NavBar
        title='Admin Task'
        goBackUri={goBackUri}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <ExecuteAdminTaskForm
          isProcessing={isProcessing}
          onGoBack={onGoBack}
          onSubmit={onExecuteAdminTask}
        />
        {report}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default ExecuteAdminTaskPage
