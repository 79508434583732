import { gql } from '@apollo/client'

export default gql`
  query Q($filter: MarketplaceListFilter) {
      marketplaceProducts (filter: $filter) {
      id
      merchantId
      messageEn
      imageSourceFrontSide
      metadata
      merchantName
      merchantUrl
      merchantSmallLogoImageSource
      merchantLargeLogoImageSource
      featured
    }
  }`
