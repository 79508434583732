import React, { FC } from 'react'
import {
  CakeOutlined as CakeOutlinedIcon,
  SchoolOutlined as SchoolOutlinedIcon,
} from '@mui/icons-material'
import { IonIcon } from '@ionic/react'
import {
  airplaneOutline,
  balloonOutline,
  calendarNumberOutline,
  earthOutline,
  eggOutline,
  heartOutline,
  notificationsOutline,
  pawOutline,
  ribbonOutline,
  trophyOutline,
} from 'ionicons/icons'

import { GiftingEventType } from '../../lib/core/enums'


const iconDefs = {
  [GiftingEventType.ANNIVERSARY]: { icon: calendarNumberOutline, source: 'ionicon' },
  [GiftingEventType.AWARD]: { icon: ribbonOutline, source: 'ionicon' },
  [GiftingEventType.BIRTHDAY]: { icon: CakeOutlinedIcon, source: 'mui' },
  [GiftingEventType.EARTH]: { icon: earthOutline, source: 'ionicon' },
  [GiftingEventType.EASTER]: { icon: eggOutline, source: 'ionicon' },
  [GiftingEventType.GRADUATION]: { icon: SchoolOutlinedIcon, source: 'mui' },
  [GiftingEventType.NAME_DAY]: { icon: CakeOutlinedIcon, source: 'mui' },
  [GiftingEventType.PARTY]: { icon: balloonOutline, source: 'ionicon' },
  [GiftingEventType.PAW]: { icon: pawOutline, source: 'ionicon' },
  [GiftingEventType.TRAVEL]: { icon: airplaneOutline, source: 'ionicon' },
  [GiftingEventType.TROPHY]: { icon: trophyOutline, source: 'ionicon' },
  [GiftingEventType.WEDDING_ANNIVERSARY]: { icon: heartOutline, source: 'ionicon' },
  'default': { icon: notificationsOutline, source: 'ionicon' },
}


type Props = {
  eventType: GiftingEventType | 'default' | null | undefined
  size?: number
  className?: string
  onClick?: () => void
}

const ContactEventIcon: FC<Props> = (props): JSX.Element | null => {
  // console.log('ContactEventIcon.render called.', { props })
  const {
    eventType,
    size,
    className,
    onClick,
  } = props

  let iconDef = iconDefs[eventType || 'default']
  if (!iconDef) {
    console.error('ContactEventIcon: invalid eventType given')
    iconDef = iconDefs['default']
  }

  // ===================================================================================================================
  // Rendering:
  let eventIcon: JSX.Element | undefined
  if (iconDef.source === 'ionicon') {
    eventIcon = (
      <IonIcon
        className='lightText'
        style={{ fontSize: `${size || 24}px` }}
        icon={iconDef.icon as string}
      />
    )
  } else {
    eventIcon = (
      <iconDef.icon
        className='lightText'
        style={{ fontSize: `${size || 24}px` }}
      />
    )
  }

  return (
    <div
      onClick={onClick}
      className={`contact-event-icon ${className || ''}`}>
      {eventIcon}
    </div>
  )
}

ContactEventIcon.defaultProps = {
  eventType: 'default',
  size: 24,
}

export default ContactEventIcon
