import React from 'react'
import type {
  GridColumns,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import {
  DataGridPro,
} from '@mui/x-data-grid-pro'
import Moment from 'moment'

import coreHelpers from '../../../lib/core/helpers'
import type { TransactionListItem, WalletListItem } from '../../../lib/core/definitions'
import { FundType, TokenName } from '../../../lib/core/enums'

type Props = {
  activeUserId: string | null | undefined
  activeUserWallet: WalletListItem | null | undefined
  transactions: TransactionListItem[] | null | undefined
  isProcessing?: boolean
  onOpenTransaction: (transactionId: string) => void
};

const TransactionGrid: React.FC<Props> = (props): JSX.Element | null => {
  const {
    activeUserId,
    activeUserWallet,
    transactions,
    onOpenTransaction,
  } = props

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (rowData: GridRowParams): void => {
    onOpenTransaction((rowData.row.id as string).replace(/-/g, ''))
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(transactions) || transactions.length < 1) {
    return null
  }
  // see https://material-ui.com/components/data-grid/rendering/#components

  const columns: GridColumns = [
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? Moment(Number(params.value)).format('YYYY-MM-DD') : '-'
      ),
      sortComparator: (v1, v2) => (
        Number(v2) - Number(v1)
      ),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'right',
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams) => (
        coreHelpers.ui.formatAmount(
          Number(params.value),
          FundType.TOKEN,
          TokenName.MIMBLE_TOKEN,
          true,
          0,
        )
      ),
    },
  ]
  const rows: GridRowsProp = transactions
    .map((transaction): GridRowModel => {
      const factor = activeUserId && transaction.fromUserId === activeUserId ? -1 : 1
      return {
        id: transaction.id,
        createdAt: transaction.createdAt,
        amount: factor * Number(transaction.amount || 0),
      }
    })

  return (
    <div className='x-grid-data-view'>
      <div className='x-grid-wrapper'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          disableColumnResize
          disableColumnReorder
          onRowClick={onRowClick}
        />
      </div>
    </div>
  )
}

export default TransactionGrid
