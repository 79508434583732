import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { MemberMessageTemplate } from '../../../lib/core/definitions'
import type {
  MemberMessageTemplateQueryData,
  MemberMessageTemplateQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import logger from '../../logger'

const updateMemberMessageTemplate = (
  memberMessageTemplate: MemberMessageTemplate,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateMemberMessageTemplate called.', { memberMessageTemplate })
  if (!apolloClient) {
    logger.error('api.cache.updateMemberMessageTemplate: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!memberMessageTemplate || !memberMessageTemplate.id) {
    logger.error('api.cache.updateMemberMessageTemplate: no memberMessageTemplate given.', { memberMessageTemplate })
    return
  }

  try {
    apolloClient.writeQuery<MemberMessageTemplateQueryData, MemberMessageTemplateQueryVariables>({
      query: apollo.queries.memberMessageTemplate,
      variables: { memberMessageTemplateId: memberMessageTemplate.id },
      data: { memberMessageTemplate },
    })
  } catch (error) {
    logger.error('api.cache.updateSystemMessage: error.', { memberMessageTemplate, error })
  }
}

export default updateMemberMessageTemplate
