import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { ChatMessage } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  MarkChatMessagesReceivedMutationData,
  MarkChatMessagesReceivedMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const markChatMessagesOfChatAsReceived = (
  chatMessages: ChatMessage[],
  activeUserId: string | null | undefined,
  apolloClient: ApolloClient<any>,
): Promise<void> => (
  new Promise((resolve, reject) => {
    // console.info('api.markChatMessagesOfChatAsReceived called.', { chatMessages })
    if (
      !activeUserId ||
      !Array.isArray(chatMessages) ||
      chatMessages.length < 1
    ) {
      logger.error('api.markChatMessagesOfChatAsReceived: invalid input.', { chatMessages })
      resolve()
      return
    }

    const chatMessageIds = chatMessages.reduce((list: string[], message: ChatMessage): string[] => {
      if (
        message.id &&
        !message.receivedAt &&
        message.toUserId === activeUserId
      ) {
        list.push(message.id)
      }
      return list
    }, [])

    if (!Array.isArray(chatMessageIds) || chatMessageIds.length < 1) {
      // console.info('api.markChatMessagesOfChatAsReceived: no unread messages.')
      resolve()
      return
    }

    if (!apolloClient) {
      logger.error('api.markChatMessagesOfChatAsReceived: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const options: MutationOptions<MarkChatMessagesReceivedMutationData, MarkChatMessagesReceivedMutationVariables> = {
      mutation: apollo.mutations.markChatMessageReceived,
      variables: { chatMessageIds },
    }

    apolloClient.mutate<MarkChatMessagesReceivedMutationData, MarkChatMessagesReceivedMutationVariables>(options)
      .then((resp) => {
        if (
          !resp ||
          !resp.data ||
          !resp.data.markChatMessagesReceived
        ) {
          logger.error('api.markChatMessagesOfChatAsReceived: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }
        // console.log('api.markChatMessagesOfChatAsReceived: mutation succeeded.', resp)
        resolve()
      }, (error) => {
        logger.error('api.markChatMessagesOfChatAsReceived: error', { error })
        reject(error)
      })
  })
)

export default markChatMessagesOfChatAsReceived
