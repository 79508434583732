import React, { useContext, useState } from 'react'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import { UiMessage } from '../../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type { CampaignInput } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import type {
  CampaignQueryData,
  CampaignQueryVariables,
  MemberMessageTemplatesQueryData, MemberMessageTemplatesQueryVariables,
} from '../../../services/apollo/definitions'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import CampaignForm from './CampaignForm'
import coreHelpers from '../../../lib/core/helpers'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'

const appPageId = AppPage.AdminEditCampaignPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  campaignId: string
}

const EditCampaignPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { campaignId } = useParams<keyof Params>() as unknown as Params
  const goBackUri = AppRoute.ADMIN_CAMPAIGN

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading campaign:
  const {
    data: campaignLoadedData,
    loading: isLoadingCampaign,
    refetch: reloadCampaign,
  } = useQuery<CampaignQueryData, CampaignQueryVariables>(
    apollo.queries.campaign, {
      variables: { campaignId: campaignId || '' },
      skip: !campaignId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: CampaignQueryData) => {
        const returnedCampaign = data ? data.campaign : undefined
        if (!returnedCampaign) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
          return
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const campaign = campaignLoadedData ? campaignLoadedData.campaign : undefined

  // -------------------------------------------------------------------------------------------------------------------
  // Loading member message templates:
  const {
    data: memberMessageTemplatesData,
    loading: isLoadingMemberMessageTemplates,
    refetch: reloadMemberMessageTemplates,
  } = useQuery<MemberMessageTemplatesQueryData, MemberMessageTemplatesQueryVariables>(
    apollo.queries.memberMessageTemplates, {
      variables: { filter: {} },
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
      onCompleted: () => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
    },
  )
  const memberMessageTemplates = memberMessageTemplatesData ? memberMessageTemplatesData.memberMessageTemplates : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = false // isGettingCampaign || isUpsertingCampaign

  // ===================================================================================================================
  // Event Handlers:
  const onUpsertCampaign = (campaignInput: CampaignInput): void => {
    console.log('EditCampaignPage.onUpsertCampaign called.', campaignInput)
    api.upsertCampaign(
      campaignInput,
      'watch-updated-at',
      undefined,
      activeUserId as string,
      apolloClient,
    ).then(() => {
      setToastMessage(coreHelpers.type.uiMessage.getLabel(UiMessage.DATA_UPDATED_SUCCESSFULLY))
      setShowToast(true)
      navigate(-1)
    }, (error) => {
      console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
    })
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !reloadCampaign || !campaignId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadCampaign({ campaignId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onGoBack = (): void => {
    navigate(-1)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const pageTitle = campaign ? 'Edit Campaign' : 'Add Campaign'

  return (
    <IonPage className='app-page-admin edit-campaign-page'>
      <NavBar
        title={pageTitle}
        goBackUri={goBackUri}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <CampaignForm
          campaign={campaignId ? campaign : null}
          memberMessageTemplates={memberMessageTemplates}
          onGoBack={onGoBack}
          onSave={onUpsertCampaign}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default EditCampaignPage
