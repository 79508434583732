import { Location } from 'history'
import { AppRoute } from '../../enums'
import type { PageDef } from '../../definitions'
import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import hasRole from '../../lib/core/helpers/models/user/hasRole'

const redirectIfUnauthorized = (
  pageDef: PageDef,
  location: Location,
  navigate: (route: AppRoute | string, replace?: boolean) => void,
): void => {
  if (!location || !location.pathname) {
    return
  }

  if (pageDef.routeMatches && !pageDef.routeMatches(location.pathname)) {
    // console.log('auth.redirectIfUnauthorized: not the active page', page)
    return
  }

  // console.log('auth.redirectIfUnauthorized called.', {
  //   pathname: location.pathname,
  //   page,
  // })
  if (
    location.pathname !== AppRoute.SIGN_IN &&
    location.pathname !== AppRoute.JOIN &&
    pageDef.requireAuth &&
    !globalCacheData.getIsSignedIn()
  ) {
    // console.log('auth.redirectIfUnauthorized: Unauthorized', { pathname: location.pathname })
    globalCacheData.clearSessionData()
    if (!globalCacheData.getValue(GlobalCacheDataKey.SIGNED_OUT_USER_ID, true)) {
      if (document.location.pathname === '/') {
        console.log('auth.redirectIfUnauthorized: forwarding to /welcome')
        navigate(AppRoute.WELCOME, true)
      } else {
        console.log('auth.redirectIfUnauthorized: forwarding to /join')
        navigate(AppRoute.JOIN, true)
      }
    } else {
      console.log('auth.redirectIfUnauthorized: forwarding to /sign-in')
      navigate(AppRoute.SIGN_IN, true)
    }
    return
  }

  if (!pageDef.roles) {
    return
  }

  const activeUserRoles = globalCacheData.getActiveUserRoles()
  if (hasRole(activeUserRoles, pageDef.roles)) {
    return
  }

  // @ts-ignore
  navigate(-1)
  return
}

export default redirectIfUnauthorized
