import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { ApiQueryOptions, Chat, ChatInput, ChatListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertChatMutationData,
  UpsertChatMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadChat from './loadChat'
import logger from '../logger'

const upsertChat = (
  chatInput: ChatInput,
  isInTargetStateFunc: ((chat: Chat) => boolean) | 'watch-updated-at' | undefined,
  listFilter: ChatListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Chat | undefined> => (
  // console.log('api.upsertChat called.', { chatInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertChat: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertChatMutationData, UpsertChatMutationVariables> = {
      mutation: apollo.mutations.upsertChat,
      variables: { chat: chatInput },
    }

    apolloClient.mutate<UpsertChatMutationData, UpsertChatMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertChat: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertChat ||
          !resp.data.upsertChat.id
        ) {
          logger.error('api.upsertChat: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((chat: Chat) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!chatInput.id) {
            logger.error('api.upsertChat: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertChat.updatedAt
            activeIsInTargetStateFunc = (chat: Chat) => chat.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertChat)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getChatsQueryVariables(listFilter, activeUserId)
            : undefined
          cache.updateChat(
            resp.data.upsertChat,
            queryListVariables,
            activeUserId,
            apolloClient,
          )
          resolve(resp.data.upsertChat)
          return
        }

        const chatId = resp.data.upsertChat.id

        setTimeout(() => {
          // console.log('api.upsertChat: calling loadChat')
          loadChat(
            chatId,
            activeIsInTargetStateFunc,
            listFilter,
            activeUserId,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((chat) => {
              // console.log('api.upsertChat: resolving with chat', chat)
              resolve(chat)
            }, (error) => {
              logger.error('api.upsertChat: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertChat: error', { error })
        reject(error)
      })
  })
)

export default upsertChat
