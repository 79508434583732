import moment from 'moment'
import React from 'react'
import type { ModelEvent } from '../../lib/core/definitions'
import { ModelEventType } from '../../lib/core/enums'
import './styles.css'

type Props = {
  events?: ModelEvent[];
}

const typeToCssClassMapping = {
  [ModelEventType.INFO]: 'modelEventItemMessage',
  [ModelEventType.WARNING]: 'modelEventItemMessage modelEventItemMessageWarning',
  [ModelEventType.ERROR]: 'modelEventItemMessage modelEventItemMessageError',
}

const ModelEventList: React.FC<Props> = (props): JSX.Element | null => {
  const { events } = props

  if (!Array.isArray(events) || events.length < 1) {
    return null
  }
  const renderedEvents = events.map((event: ModelEvent): JSX.Element => {
    const formattedTime = moment(event.time).format('M/D/YY hh:mm A')

    return (
      <div key={event.time} className='modelEventItem'>
        <div className='modelEventItemTime'>{formattedTime}</div>
        <div className={typeToCssClassMapping[event.type]}>{event.message}</div>
      </div>
    )
  })

  return (
    <div className='modelEvents'>
      {renderedEvents}
    </div>
  )
}

export default ModelEventList
