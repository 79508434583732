import { gql } from '@apollo/client'

export default gql`
  mutation M($input: AdminTaskInput!) {
      executeAdminTask (input: $input) {
        task
        ok
        data
    }
  }`
