import type { ShoppingCart } from '../../../definitions'
import { OrgProductType } from '../../../enums'

const hasMimbleTokensItem = (
  shoppingCart: ShoppingCart,
): boolean => {
  if (
    !shoppingCart ||
    !Array.isArray(shoppingCart.items) ||
    shoppingCart.items.length < 1
  ) {
    return false
  }
  for (let i = 0; i < shoppingCart.items.length; i++) {
    const item = shoppingCart.items[i]
    if (item.productType === OrgProductType.MIMBLE_TOKEN) {
      return true
    }
  }
  return false
}

export default hasMimbleTokensItem
