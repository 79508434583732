import type { Moment } from 'moment'
import moment from 'moment'

import type { ContactUserListItem, User } from '../../lib/core/definitions'
import { GiftFlowDeliveryTimeChoice } from '../../enums'
import coreHelpers from '../../lib/core/helpers'

export const simpleTimes = {
  [GiftFlowDeliveryTimeChoice.MORNING]: 8,
  [GiftFlowDeliveryTimeChoice.MIDDAY]: 12,
  [GiftFlowDeliveryTimeChoice.AFTERNOON]: 16,
  [GiftFlowDeliveryTimeChoice.NIGHT]: 24,
  [GiftFlowDeliveryTimeChoice.ADVANCED_INPUT]: 99,
}

const getRecipientContact = (
  toUserId: string | null | undefined,
  toUserInfo: Partial<User> | null | undefined,
  contacts: ContactUserListItem[] | null | undefined,
): ContactUserListItem | undefined => {
  if (!toUserId && !toUserInfo) {
    return
  }

  if (!Array.isArray(contacts) || contacts.length < 1) {
    return
  }

  return contacts.find(c => coreHelpers.models.compareId(c.id, (toUserId || (toUserInfo && toUserInfo.id))))
}

const getRecipientName = (
  toUserId: string | null | undefined,
  toUserInfo: Partial<User> | null | undefined,
  contacts: ContactUserListItem[] | null | undefined,
): string => {
  if (!toUserId && !toUserInfo) {
    return ''
  }

  const contact = getRecipientContact(toUserId, toUserInfo, contacts)

  if (!toUserInfo) {
    if (!contact) {
      return ''
    }
    return coreHelpers.models.contact.getContactNames(contact, undefined).composite || ''
  }
  const nickname = contact && contact.nickname
  const recipientFullName = (
    (toUserInfo && toUserInfo.fullName) ||
    (contact && contact.fullName)
  )
  return nickname ? `${nickname} (${recipientFullName})` : (recipientFullName || '')
}

const getRecipientNameForMessageText = (
  toUserId: string | null | undefined,
  toUserInfo: Partial<User> | null | undefined,
  contacts: ContactUserListItem[] | null | undefined,
): string => {
  if (!toUserId && !toUserInfo) {
    return ''
  }

  const contact = getRecipientContact(toUserId, toUserInfo, contacts)

  if (contact && contact.nickname) {
    return contact.nickname
  }

  const fullName = (
    (toUserInfo && toUserInfo.fullName) ||
    (contact && contact.fullName)
  )

  if (!fullName) {
    return ''
  }

  const parsedName = coreHelpers.models.user.parseName(fullName)
  if (!parsedName) {
    return fullName
  }
  return parsedName.first || ''
}

const getSenderNameForMessageText = (activeUser: User | undefined): string => {
  if (!activeUser || !activeUser.fullName) {
    return ''
  }
  const parsedName = coreHelpers.models.user.parseName(activeUser.fullName)
  if (!parsedName) {
    return activeUser.fullName
  }
  return parsedName.first || parsedName.last || activeUser.fullName || ''
}

const helpers = {
  getHourFromTimeChoice: (choice: GiftFlowDeliveryTimeChoice, hour: number): number => (
    choice === GiftFlowDeliveryTimeChoice.ADVANCED_INPUT
      ? hour
      : simpleTimes[choice]
  ),

  getAvailableSimpleTimeChoices: (deliveryTime: Moment | null | undefined): GiftFlowDeliveryTimeChoice[] => {
    if (!deliveryTime || deliveryTime.isAfter(moment().endOf('date'))) {
      return Object.values(GiftFlowDeliveryTimeChoice)
    }
    return Object.values(GiftFlowDeliveryTimeChoice).filter(tc => simpleTimes[tc] > moment().get('hour'))
  },

  getRecipientContact,
  getRecipientName,
  getRecipientNameForMessageText,
  getSenderNameForMessageText,
}

export default helpers
