import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  isPlatform,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import { SignOutVariables } from '../../services/apollo/definitions'
import { TopNavTabId } from '../../components/TopNavBar/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import capacitor from '../../services/capacitor'
import ConfirmSignOutAlert from '../../components/ConfirmSignOutAlert'
import FavoritesTab from './FavoritesTab/FavoritesTab'
import NavBar from '../../components/NavBar/NavBar'
import OrdersTab from './OrdersTab/OrdersTab'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import ProfileTab from './ProfileTab/ProfileTab'
import SettingsTab from './SettingsTab/SettingsTab'
import UserAccountDashboard from './UserAccountDashboard/UserAccountDashboard'
import WishListTab from './WishListTab/WishListTab'

const appPageId = AppPage.UserAccountPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  tab: TopNavTabId
}

const UserAccountPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const topNavTabId = (useParams<keyof Params>() as unknown as Params).tab || TopNavTabId.USER_DASHBOARD

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const {
    activeUser,
    clearActiveUser,
    clearInbox,
    isLoadingActiveUser,
    reloadActiveUser
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [refreshId, setRefreshId] = useState(0)
  const [showConfirmSignOutAlert, setShowConfirmSignOutAlert] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser

  // -------------------------------------------------------------------------------------------------------------------
  // Signing out:
  const [
    signOut,
    { client: apolloClient },
  ] = useMutation<undefined, SignOutVariables>(apollo.mutations.signOut, {
    variables: { userId: activeUserId as string },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (apolloClient) {
        apolloClient.clearStore()
      }
      auth.signOut(false, true, apolloClient)
      if (clearActiveUser) {
        clearActiveUser()
      }
      clearInbox()
      if (isPlatform('capacitor')) {
        capacitor.clearAppIconBadge()
      }
      setToastMessage('Successfully signed out of Mimble')
      setShowToast(true)
      navigate(AppRoute.SIGN_IN, true)
    },
    onError: (error) => {
      console.error(error)
      setToastMessage('Failed to sign out. Please check your input and try again.')
      setShowToast(true)
    },
  })

  const onSignOut = (confirmed?: any): void => {
    if (confirmed !== true) {
      setShowConfirmSignOutAlert(true)
      return
    }
    setShowConfirmSignOutAlert(false)
    signOut().then(undefined, (error) => {
      console.error(error)
    })
  }
  // ===================================================================================================================
  // Event Handlers:
  const onGetActiveUser = (): void => {
    reloadActiveUser().then(undefined, (error) => {
      console.error(error)
    })
  }

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const onRefresh = (): void => {
    pageMessages && pageMessages.clear()
    setRefreshId(refreshId + 1)
    onGetActiveUser()
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    setRefreshId(refreshId + 1)
    reloadActiveUser().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onClickDashboardItem = (tab: TopNavTabId): void => {
    navigate(`${AppRoute.USER_ACCOUNT}/${tab}`)
  }

  const onNavigate = (route: AppRoute | string): void => {
    navigate(route)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content: JSX.Element | undefined
  let currentTabTitle: string | undefined
  if (activeUser) {
    switch (topNavTabId) {
      case TopNavTabId.USER_ORDERS:
        currentTabTitle = 'Order History'
        content = (
          <OrdersTab
            refreshId={refreshId}
            onNavigate={onNavigate}
            showUiMessage={showUiMessage}
          />
        )
        break
      case TopNavTabId.USER_WISH_LIST:
        currentTabTitle = 'My Wish List'
        content = (
          <WishListTab
            refreshId={refreshId}
            onNavigate={onNavigate}
            showUiMessage={showUiMessage}
          />
        )
        break
      case TopNavTabId.USER_SETTINGS:
        currentTabTitle = 'Settings'
        content = (
          <SettingsTab
            showUiMessage={showUiMessage}
          />
        )
        break
      case TopNavTabId.USER_PROFILE:
        currentTabTitle = 'Profile'
        content = (
          <ProfileTab
            showUiMessage={showUiMessage}
          />
        )
        break
      case TopNavTabId.USER_FAVORITES:
        currentTabTitle = 'My Favorites'
        content = (
          <FavoritesTab
            onNavigate={onNavigate}
            showUiMessage={showUiMessage}
          />
        )
        break
      default:
        content = (
          <UserAccountDashboard
            onOpenFavorites={() => onClickDashboardItem(TopNavTabId.USER_FAVORITES)}
            onOpenUserOrderHistory={() => onClickDashboardItem(TopNavTabId.USER_ORDERS)}
            onOpenUserProfile={() => onClickDashboardItem(TopNavTabId.USER_PROFILE)}
            onOpenUserSettings={() => onClickDashboardItem(TopNavTabId.USER_SETTINGS)}
            onOpenUserWishList={() => onClickDashboardItem(TopNavTabId.USER_WISH_LIST)}
            onNavigate={onNavigate}
            onSignOut={onSignOut}
          />
        )
    }
  }

  const contentWrapperClass = topNavTabId === TopNavTabId.USER_ORDERS ? 'g-non-scroll-content-wrapper' : ''

  return (
    <IonPage className='app-page-public user-account-page'>
      <NavBar
        title={currentTabTitle || 'Account'}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onRefresh={onRefresh}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent className='g-content-with-safe-padding'>
        <div className={contentWrapperClass}>
          <PageMessages />
          <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {content}
        </div>
      </IonContent>
      <ConfirmSignOutAlert
        isOpen={showConfirmSignOutAlert}
        showNoPasswordRecoveryWarning={false}
        onDidDismiss={(): void => { setShowConfirmSignOutAlert(false) }}
        onCancel={(): void => { setShowConfirmSignOutAlert(false) }}
        onSignOut={(): void => { onSignOut(true) }}
      />
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default UserAccountPage
