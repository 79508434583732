import { AppFeature, FiatCurrency, FundType, OrgProductType, PaymentType, WalletType } from '../../../lib/core/enums'
import type { PaymentMethodChoices } from '../../../definitions'
import type { ShoppingCart, User, WalletListItem } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import globalCacheData from '../../../contexts/GlobalCacheContext/contextData'

const getPaymentMethodPermissions = (
  shoppingCart: ShoppingCart | null | undefined,
  activeUser: User | null | undefined,
  userWallets: WalletListItem[] | null | undefined,
): PaymentMethodChoices => {
  const enableTesting = globalCacheData.getEnvironment() !== 'production'
  let showMimbleTokens = true
  let showCreditCard = false
  let showBitcoin = true
  let showBitcoinCash = false
  let showCardano = false
  let showDoge = true
  let showEthereum = false
  let showEthereumClassic = false
  let showLitecoin = true
  const showLitecoinTest = enableTesting
  let showPolkadot = false
  let showTetherB = false
  let showTetherE = false
  let showUsdCoinB = false
  let showUsdCoinE = false
  let showXrp = false

  let enableMimbleTokens = false
  let enableCreditCard = false
  let enableBitcoin = true
  let enableBitcoinCash = false
  let enableCardano = false
  let enableDoge = true
  let enableEthereum = false
  let enableEthereumClassic = false
  let enableLitecoin = true
  const enableLitecoinTest = enableTesting
  let enablePolkadot = false
  let enableTetherB = false
  let enableTetherE = false
  let enableUsdCoinB = false
  let enableUsdCoinE = false
  let enableXrp = false

  const paymentAmountFiat = coreHelpers.models.shoppingCart.getTotalProductAmount({
    shoppingCart,
    paymentFundType: FundType.FIAT,
    paymentCurrency: FiatCurrency.USD,
  })

  if (activeUser) {
    const appFeatures = coreHelpers.models.user.getAppFeaturesAsArray(activeUser.appFeatures)
    if (Array.isArray(appFeatures)) {
      showCreditCard = appFeatures.includes(AppFeature.BUY_WITH_CREDIT_CARD)

      enableCreditCard = showCreditCard
      if (
        Array.isArray(userWallets) &&
        userWallets.length > 0
      ) {
        const mimbleWallet = userWallets.find(w => w.walletType === WalletType.MIMBLE)
        if (
          mimbleWallet &&
          mimbleWallet.balanceToken &&
          mimbleWallet.balanceToken > 0
        ) {
          if (paymentAmountFiat) {
            const transactionAmount = coreHelpers.cryptoCurrency.convertFiatToMimbleToken(paymentAmountFiat)
            enableMimbleTokens = transactionAmount <= mimbleWallet.balanceToken
          } else {
            enableMimbleTokens = true
          }
        }
      }
    }
    if (
      showCreditCard &&
      enableCreditCard &&
      activeUser &&
      paymentAmountFiat &&
      (activeUser.maxPurchaseAmountCc || 0) < paymentAmountFiat
    ) {
      console.log('getPaymentMethodPermissions: disabling CC',
        { paymentAmountFiat, maxPurchaseAmountCc: activeUser.maxPurchaseAmountCc })
      enableCreditCard = false
    }
  }

  if (
    shoppingCart &&
    Array.isArray(shoppingCart.items) &&
    shoppingCart.items.length > 0
  ) {
    shoppingCart.items.forEach((item) => {
      if (item.productType === OrgProductType.MIMBLE_TOKEN) {
        // showMimbleTokens = false
        enableMimbleTokens = false
      } else if (item.productType === OrgProductType.GIFT_CARD) {
        const { product } = item
        if (product && product.merchant && product.merchant.paymentTypes) {
          const paymentTypes = product.merchant.paymentTypes.split(',') as PaymentType[]
          if (!paymentTypes.includes(PaymentType.MIT)) {
            showMimbleTokens = false
            enableMimbleTokens = false
          }
          if (!paymentTypes.includes(PaymentType.CREDIT_CARD)) {
            showCreditCard = false
            enableCreditCard = false
          }
          if (!paymentTypes.includes(PaymentType.CRYPTO)) {
            showBitcoin = false
            enableBitcoin = false
            showBitcoinCash = false
            enableBitcoinCash = false
            showCardano = false
            enableCardano = false
            showDoge = false
            enableDoge = false
            showEthereum = false
            enableEthereum = false
            showEthereumClassic = false
            enableEthereumClassic = false
            showLitecoin = false
            enableLitecoin = false
            showPolkadot = false
            enablePolkadot = false
            showTetherB = false
            enableTetherB = false
            showTetherE = false
            enableTetherE = false
            showUsdCoinB = false
            enableUsdCoinB = false
            showUsdCoinE = false
            enableUsdCoinE = false
            showXrp = false
            enableXrp = false
          }
        }
      }
    })
  }

  return {
    showMimbleTokens,
    showCreditCard,
    showBitcoin,
    showBitcoinCash,
    showCardano,
    showDoge,
    showEthereum,
    showEthereumClassic,
    showLitecoin,
    showLitecoinTest,
    showPolkadot,
    showTetherB,
    showTetherE,
    showUsdCoinB,
    showUsdCoinE,
    showXrp,
    enableMimbleTokens,
    enableCreditCard,
    enableBitcoin,
    enableBitcoinCash,
    enableCardano,
    enableDoge,
    enableEthereum,
    enableEthereumClassic,
    enableLitecoin,
    enableLitecoinTest,
    enablePolkadot,
    enableTetherB,
    enableTetherE,
    enableUsdCoinB,
    enableUsdCoinE,
    enableXrp,
  }
}

export default getPaymentMethodPermissions
