import type { Product } from '../../lib/core/definitions'
import type { PurchaseCdnUris } from '../../definitions'
import { CdnAssetType } from '../../services/cdn/enums'
import cdn from '../../services/cdn'

const getPurchaseCdnUrisFromProduct = (
  product?: Product | null,
  useCloudfront = true,
): PurchaseCdnUris => {
  if (!product) {
    return {
      giftCardBackgroundUri: '',
      merchantLogoUri: '',
    }
  }
  return {
    giftCardBackgroundUri: cdn.getUri(
      CdnAssetType.GIFT_CARD,
      product.imageSourceFrontSide,
      useCloudfront,
    ) || '',
    merchantLogoUri: product.merchant
      ? cdn.getUri(
        CdnAssetType.MERCHANT_LOGO,
        product.merchant.largeLogoImageSource,
        useCloudfront,
      ) || ''
      : '',
  }
}

export default getPurchaseCdnUrisFromProduct
