import type { ApolloClient } from '@apollo/client'
import type { ShoppingCart, ShoppingCartInput, ShoppingCartListItem } from '../../lib/core/definitions'
import type { ShoppingCartStateFunc } from '../../definitions'
import { ErrorCode, ShoppingCartCommand } from '../../lib/core/enums'
import api from '../../services/api'
import logger from '../../services/logger'

const setShoppingCart = (
  shoppingCartInput: ShoppingCartInput,
  activeShoppingCart: ShoppingCart | undefined,
  pendingShoppingCarts: ShoppingCartListItem[] | undefined,
  isActiveShoppingCart: boolean,
  isInTargetStateFunc: ShoppingCartStateFunc | undefined,
  activeUserId: string | null | undefined,
  setIsSaving: (value: boolean) => void,
  setSavingError: (error: string) => void,
  apolloClient: ApolloClient<any>,
): Promise<ShoppingCart | undefined> => {
  // console.log('ShoppingCartContext.setShoppingCart called.',
  //   { shoppingCartInput, activeShoppingCart, isActiveShoppingCart })

  return new Promise((resolve, reject) => {
    if (!shoppingCartInput) {
      console.error('ShoppingCartContext.setShoppingCart invalid input.', shoppingCartInput)
      reject(new Error(ErrorCode.INVALID_INPUT))
      return
    }

    if (isActiveShoppingCart && shoppingCartInput.id) {
      console.error('Do not specify the shopping cart ID when calling setShoppingCart for the active cart!')
      delete shoppingCartInput.id
    }

    if (!shoppingCartInput.userId) {
      if (!activeUserId) {
        console.error('ShoppingCartContext.setShoppingCart: no userId given, and no activeUserId')
        return
      }
      shoppingCartInput.userId = activeUserId
    }

    const expectNoShoppingCartToBeReturned = (
      !isActiveShoppingCart &&
      shoppingCartInput.command === ShoppingCartCommand.CLEAR
    )

    if (!isInTargetStateFunc) {
      isInTargetStateFunc = expectNoShoppingCartToBeReturned ? 'expect-null' : 'watch-updated-at'
    }

    if (isInTargetStateFunc === 'watch-updated-at') {
      isInTargetStateFunc = (shoppingCart: ShoppingCart): boolean => (
        shoppingCart.updatedAt !== (activeShoppingCart && activeShoppingCart.updatedAt)
      )
    } else if (isInTargetStateFunc === 'watch-metadata-revision') {
      isInTargetStateFunc = (shoppingCart: ShoppingCart): boolean => (
        (shoppingCart.metadata && shoppingCart.metadata.revision) !== (activeShoppingCart && activeShoppingCart.metadata && activeShoppingCart.metadata.revision)
      )
    }

    setIsSaving(true)
    api.upsertShoppingCart(
      shoppingCartInput,
      isActiveShoppingCart,
      false,
      isInTargetStateFunc,
      activeUserId as string,
      apolloClient,
    ).then((updatedShoppingCart) => {
      console.log('ShoppingCartContext.setShoppingCart: upsertShoppingCart returned.', { updatedShoppingCart })
      if (!updatedShoppingCart && !expectNoShoppingCartToBeReturned) {
        logger.error('ShoppingCartContext.setShoppingCart: upsertShoppingCart did not return data.')
        setIsSaving(false)
        setSavingError('Failed to save your changes. Please try again.')
        reject(new Error('Failed to save your changes. Please try again.'))
        return updatedShoppingCart
      }
      setIsSaving(false)
      console.log('ShoppingCartContext.setShoppingCart: resolving.', { updatedShoppingCart })
      resolve(updatedShoppingCart)
    }, (error) => {
      logger.error('ShoppingCartContext.setShoppingCart: upsertShoppingCart returned error.',
        { error })
      setIsSaving(false)
      setSavingError('Failed to save your changes. Please try again.')
      reject(new Error('Failed to save your changes. Please try again.'))
    })
  })
}

export default setShoppingCart
