import type { ApolloClient } from '@apollo/client'
import type { Chat } from '../../../lib/core/definitions'
import type {
  ChatQueryData,
  ChatQueryVariables,
  ChatsQueryData,
  ChatsQueryVariables,
} from '../../apollo/definitions'
import { ErrorCode } from '../../../lib/core/enums'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  chat: Chat,
  queryListVariables: ChatsQueryVariables,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateChat.updateInList called.', { chat })
  if (!chat) {
    logger.error('api.cache.updateChat.updateInList: no chat given.')
    return
  }

  try {
    const cachedChatUsersData = apolloClient.readQuery<ChatsQueryData, ChatsQueryVariables>({
      query: apollo.queries.chats,
      variables: queryListVariables,
    })

    if (
      !cachedChatUsersData ||
      !Array.isArray(cachedChatUsersData.chats) ||
      cachedChatUsersData.chats.length < 1
    ) {
      return
    }

    const updatedChats = coreHelpers.models.updateModelInList<Chat>(
      cachedChatUsersData.chats,
      chat,
      undefined,
      true,
      undefined,
    )

    apolloClient.writeQuery<ChatsQueryData, ChatsQueryVariables>({
      query: apollo.queries.chats,
      variables: queryListVariables,
      data: { chats: updatedChats },
    })
  } catch (error) {
    logger.error('api.cache.updateChat.updateInList: error.', { error })
  }
}

const updateChat = (
  chat: Chat,
  queryListVariables: ChatsQueryVariables | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateChat called.', { chat })
  if (!apolloClient) {
    logger.error('api.cache.updateChat: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!chat || !chat.id) {
    logger.error('api.cache.updateChat: no chat given.', { chat })
    return
  }

  try {
    apolloClient.writeQuery<ChatQueryData, ChatQueryVariables>({
      query: apollo.queries.chat,
      variables: { chatId: chat.id },
      data: { chat },
    })

    if (queryListVariables) {
      updateInList(
        chat,
        queryListVariables,
        activeUserId,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateChat: error.', { error })
  }
}

export default updateChat
