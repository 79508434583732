import { ClientUserType } from '../../lib/core/enums'
import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import type { User } from '../../lib/core/definitions'
import coreHelpers from '../../lib/core/helpers'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

// This supports the "sign-in-as" feature.
// With both "sign-in-as" both active and inactive, the activeUser is the user reflected in the app
// "sign-in-as" is active:
//   activeUser = proxyUser
//   authUser = the admin that initiated sign-in-as
// "sign-in-as" is inactive:
//   activeUser = authUser
//   authUser = the one and only user
//   proxyUser = undefined
const setActiveUser = (user: User | null | undefined, clientUserType?: ClientUserType): void => {
  // console.log('auth.setUser called.', { user, clientUserType, proxyUserId })

  const proxyUserId = globalCacheData.getProxyUserId()
  if (user) {
    const userCacheInfo = {
      id: user.id,
      username: user.username,
      fullName: user.fullName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      authToken: user.authToken,
      roles: user.roles,
      appFeatures: user.appFeatures,
      prefs: user.prefs,
    }

    if (!clientUserType || clientUserType === ClientUserType.ACTIVE) {
      if (proxyUserId && coreHelpers.models.compareId(user.id, proxyUserId)) {
        globalCacheData.setProxyUser(userCacheInfo)
      } else {
        globalCacheData.setAuthUser(userCacheInfo)
      }
    } else if (clientUserType === ClientUserType.AUTHENTICATED) {
      globalCacheData.setAuthUser(userCacheInfo)
    } else if (proxyUserId && clientUserType === ClientUserType.PROXY) {
      globalCacheData.setProxyUser(userCacheInfo)
    }
  }

  // The user is null:
  if (clientUserType === ClientUserType.ACTIVE || clientUserType === ClientUserType.AUTHENTICATED) {
    const userId = globalCacheData.getActiveUserId()
    globalCacheData.clearAuthUser()
    globalCacheData.clearProxyUser()
    globalCacheData.setValue(GlobalCacheDataKey.SIGNED_OUT_USER_ID, userId, true)
  } else if (clientUserType === ClientUserType.PROXY) {
    globalCacheData.clearProxyUser()
  }
}

export default setActiveUser
