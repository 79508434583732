import React from 'react'
import { animated, useSpring } from 'react-spring'

import GiftCard from '../../components/GiftCard/GiftCard'

type AnimationProps = {
  maxWidth: string
  transform: string,
  opacity: number
}

const animationProps = {
  normal: {
    width: '75vw',
    opacity: 1,
  },
  minimized: {
    width: '0vw',
    opacity: 0,
  },
}

type Props = {
  fromPurchaseId: string
  giftCardBackgroundUri: string
  merchantLogoUri: string
  minimized: boolean;
}

const GiftCardImage: React.FC<Props> = (props): JSX.Element | null => {
  const {
    fromPurchaseId,
    giftCardBackgroundUri,
    merchantLogoUri,
    minimized,
  } = props

  // ===================================================================================================================
  // State:
  const [animationStyle] = useSpring<AnimationProps>(minimized
    ? animationProps.minimized
    : animationProps.normal,
  [minimized],
  )

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='withCenteredColumnContent'>
      <div className='withCenteredColumnContent'>
        <animated.div
          className='giftCardOuterWrapper withMaxWidth400px withDoubleBottomMargin'
          style={animationStyle}
        >
          <GiftCard
            purchaseId={fromPurchaseId as string}
            backgroundImageUri={giftCardBackgroundUri}
            merchantLogoUri={merchantLogoUri}
          />
        </animated.div>
      </div>
    </div>
  )
}

export default GiftCardImage
