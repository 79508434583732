import type { ShoppingCart, ShoppingCartMetadata } from '../../../definitions'
import getTotalProductCount from './getTotalProductCount'

const compileMetadata = (shoppingCart: ShoppingCart | null | undefined): ShoppingCartMetadata | undefined => {
  if (!shoppingCart) {
    return
  }

  const metadata: ShoppingCartMetadata = shoppingCart.metadata || { revision: 0 }
  metadata.revision = metadata.revision + 1

  if (Array.isArray(shoppingCart.items) && shoppingCart.items.length > 0) {
    metadata.productCount = getTotalProductCount(shoppingCart) || 0
  }

  return metadata
}

export default compileMetadata
