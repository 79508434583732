import { IdentType } from '../../enums'

const labelsEn = Object.freeze({
  [IdentType.UNKNOWN]: 'unknown',
  [IdentType.ID]: 'id',
  [IdentType.USERNAME]: 'handle',
  [IdentType.EMAIL]: 'email',
  [IdentType.PHONE_NUMBER]: 'phone number',
})

export default {
  getLabel: (type: IdentType | null | undefined, language = 'en'): string => {
    if (!type) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[type]
    }
    return ''
  },
  isValid: (type: IdentType | string | null | undefined): boolean => Object.values(IdentType).includes(type as IdentType),
}
