import { OrgProductType } from '../../enums'

const labelsEn = Object.freeze({
  [OrgProductType.GIFT_CARD]: 'gift card',
  [OrgProductType.DISCOUNT_CODE]: 'discount code',
  [OrgProductType.PROMO_CODE]: 'promo code',
  [OrgProductType.MIMBLE_TOKEN]: 'Mimble token',
})

export default {
  getLabel: (productType: OrgProductType | null | undefined, language = 'en'): string => {
    if (!productType) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[productType]
    }
    return ''
  },
  isValid: (type: OrgProductType): boolean => Object.values(OrgProductType).includes(type),
}
