import React from 'react'
import { calendarOutline, chatboxOutline, starOutline } from 'ionicons/icons'

import './styles.css'
import { ContactsPageListScopeId } from './enums'
import { IonButton, IonIcon } from '@ionic/react'

type Props = {
  sectionId: ContactsPageListScopeId
  containsFavorites: boolean
  onOpenSection: (newSectionId: ContactsPageListScopeId) => void
}

const NavSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    sectionId,
    containsFavorites,
    onOpenSection
  } = props

  const buttonDefs = [
    {
      label: 'Messages',
      sectionId: ContactsPageListScopeId.MESSAGES,
      icon: chatboxOutline,
    },
    {
      label: 'Events',
      sectionId: ContactsPageListScopeId.EVENTS,
      icon: calendarOutline,
    },
  ]

  if (containsFavorites) {
    buttonDefs.push(
      {
        label: 'Favorites',
        sectionId: ContactsPageListScopeId.ALPHABETICAL,
        icon: starOutline,
      },
    )
  }

  const buttons = buttonDefs.map((buttonDef, index): JSX.Element => {
    return (
      <IonButton
        key={buttonDef.sectionId}
        fill={buttonDef.sectionId === sectionId ? 'solid' : 'outline'}
        size='small'
        className={index === buttonDefs.length - 1 ? '' : 'withSmallRightMargin'}
        onClick={() => onOpenSection(buttonDef.sectionId)}
      >
        <IonIcon icon={buttonDef.icon} className='withSmallRightMargin' />
        {buttonDef.label}
      </IonButton>
    )
  })
  return (
    <div className='nav-section'>
      {buttons}
    </div>
  )
}

export default NavSection
