import type { UserRole } from '../../../enums'

const getRolesAsArray = (roles: UserRole[] | string | null | undefined): UserRole[] => {
  if (!roles) {
    return []
  }
  if (Array.isArray(roles)) {
    return roles
  }
  return roles.split(',') as UserRole[]
}

export default getRolesAsArray
