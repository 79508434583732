import type { Purchase, PurchaseListItem } from '../../../definitions'
import type { PurchaseStatus } from '../../../enums'
import { RecordStatus } from '../../../enums'

const updatePurchaseListItemFromPurchase = (purchaseListItem: PurchaseListItem | undefined, purchase: Purchase): PurchaseListItem => {
  let merchantSmallLogoImageSource = ''
  let merchantLargeLogoImageSource = ''

  // todo: deprecated
  if (purchase.product && purchase.product.vendor) {
    merchantSmallLogoImageSource = purchase.product.vendor.smallLogoImageSource || ''
    merchantLargeLogoImageSource = purchase.product.vendor.largeLogoImageSource || ''
  }

  if (purchase.product && purchase.product.merchant) {
    merchantSmallLogoImageSource = purchase.product.merchant.smallLogoImageSource || ''
    merchantLargeLogoImageSource = purchase.product.merchant.largeLogoImageSource || ''
  }

  if (!purchaseListItem) {
    return {
      id: purchase.id || '',
      userId: purchase.userId || null,
      offerId: purchase.offerId || null,
      orderId: purchase.orderId || null,
      poNumber: null,
      productId: purchase.productId || null,
      productOptionId: purchase.productOptionId || null,
      merchantId: purchase.product && purchase.product.merchantId ? purchase.product.merchantId : null,
      merchantName: purchase.product && purchase.product.merchant ? purchase.product.merchant.name || null : null,
      merchantSmallLogoImageSource,
      merchantLargeLogoImageSource,
      purchaseType: purchase.purchaseType,
      status: purchase.status as PurchaseStatus,
      code: purchase.code || '',
      amount: purchase.amount || 0,
      balance: purchase.balance || 0,
      discount: purchase.discount || 0,
      balanceUpdatedAt: purchase.balanceUpdatedAt || '',
      fundType: purchase.fundType || null,
      currency: purchase.currency || null,
      productImageSourceFrontSide: purchase.product ? purchase.product.imageSourceFrontSide || null : null,
      metadata: purchase.metadata,
      source: purchase.source || null,
      recordStatus: purchase.recordStatus || RecordStatus.ACTIVE,
      createdAt: purchase.createdAt || '',
      updatedAt: purchase.updatedAt || '',
      updatedBy: purchase.updatedBy || '',

      // todo: deprecated
      vendorId: purchase.product && purchase.product.merchantId ? purchase.product.merchantId : null,
      vendorName: purchase.product && purchase.product.vendor ? purchase.product.vendor.name || null : null,
      vendorSmallLogoImageSource: merchantSmallLogoImageSource,
      vendorLargeLogoImageSource: merchantLargeLogoImageSource,
    }
  }

  const updatedItem: PurchaseListItem = { ...purchaseListItem }

  if (purchase.purchaseType && purchase.purchaseType !== updatedItem.purchaseType) {
    updatedItem.purchaseType = purchase.purchaseType
  }
  if (purchase.status && purchase.status !== updatedItem.status) {
    updatedItem.status = purchase.status
  }
  if (purchase.amount !== undefined && purchase.amount !== updatedItem.amount) {
    updatedItem.amount = purchase.amount
  }
  if (purchase.balance !== undefined && purchase.balance !== updatedItem.balance) {
    updatedItem.balance = purchase.balance
  }
  if (purchase.discount !== undefined && purchase.discount !== updatedItem.discount) {
    updatedItem.discount = purchase.discount
  }
  if (purchase.balanceUpdatedAt && purchase.balanceUpdatedAt !== updatedItem.balanceUpdatedAt) {
    updatedItem.balanceUpdatedAt = purchase.balanceUpdatedAt
  }
  if (purchase.fundType && purchase.fundType !== updatedItem.fundType) {
    updatedItem.fundType = purchase.fundType
  }
  if (purchase.currency && purchase.currency !== updatedItem.currency) {
    updatedItem.currency = purchase.currency
  }
  if (purchase.metadata) {
    updatedItem.metadata = purchase.metadata
  }
  if (purchase.source && purchase.source !== updatedItem.source) {
    updatedItem.source = purchase.source
  }
  if (purchase.recordStatus && purchase.recordStatus !== updatedItem.recordStatus) {
    updatedItem.recordStatus = purchase.recordStatus
  }
  if (purchase.createdAt && purchase.createdAt !== updatedItem.createdAt) {
    updatedItem.createdAt = purchase.createdAt
  }
  if (purchase.updatedAt && purchase.updatedAt !== updatedItem.updatedAt) {
    updatedItem.updatedAt = purchase.updatedAt
  }
  if (merchantSmallLogoImageSource) {
    updatedItem.merchantSmallLogoImageSource = merchantSmallLogoImageSource
  }
  if (merchantLargeLogoImageSource) {
    updatedItem.merchantLargeLogoImageSource = merchantLargeLogoImageSource
  }
  // todo: deprecated
  if (merchantSmallLogoImageSource) {
    updatedItem.vendorSmallLogoImageSource = merchantSmallLogoImageSource
  }
  if (merchantLargeLogoImageSource) {
    updatedItem.vendorLargeLogoImageSource = merchantLargeLogoImageSource
  }

  return updatedItem
}

export default updatePurchaseListItemFromPurchase
