export default (inputs: string[], setStateFnc: (names: string[]) => void): void => {
  const startTime = new Date().getTime()
  const interval = setInterval(() => {
    try {
      const autoFilledInputs = document.querySelectorAll('input:-webkit-autofill')
      if (!autoFilledInputs || autoFilledInputs.length < 1) {
        if (new Date().getTime() - startTime > 5000) {
          clearInterval(interval)
        }
        return
      }
      setStateFnc(Array.from(autoFilledInputs).map(ele => (ele as HTMLInputElement).name))
    } catch (error) {
      console.error(error)
    } finally {
      clearInterval(interval)
    }
  }, 200)
}
