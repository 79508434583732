import React from 'react'

import './styles.css'
import { IonButton } from '@ionic/react'
import type { User } from '../../lib/core/definitions'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import UserInfoCard from '../../components/UserInfoCard/UserInfoCard'

type Props = {
  senderUserInfo: User | null | undefined
  senderName: string | undefined
  onOpenContact: (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined
  ) => void
  onGoHome: () => void
  onOpenSignUp: () => void
  onOpenSignIn: () => void
}

const GiftDeclinedContent: React.FC<Props> = ({
  senderUserInfo,
  senderName,
  onOpenContact,
  onGoHome,
  onOpenSignUp,
  onOpenSignIn,
}): JSX.Element => {
  // ===================================================================================================================
  // State:
  const { getIsSignedIn } = useGlobalCache()

  // ===================================================================================================================
  // Rendering:
  const sections: JSX.Element[] = [
    (
      <div key='intro' className='withDoubleBottomMargin'>
        We will let {senderName}&apos know that you declined their gift.
        <br /><br />
        Would you like to tell {senderName} why you didn&apos;t it? You can open the chat
        and text them about it.
      </div>
    ),
  ]

  if (getIsSignedIn() && senderUserInfo) {
    sections.push(
      <UserInfoCard
        key='sender'
        userIdentInfo={senderUserInfo}
        showChat
        onOpenContact={onOpenContact}
      />,
    )
  }

  if (getIsSignedIn()) {
    // sections.push(
    //   <div key='activateFormButtons' className='formButtonWrapper'>
    //     <IonButton onClick={onGoHome}>Open Wallet</IonButton>
    //   </div>,
    // )
  } else {
    sections.push(
      <div key='next'>
        <div className='withStandardBottomMargin'>
          If you like, you can now create a free Mimble account. If you
          already have one, sign in!
        </div>
        <div key='activateFormButtons' className='formButtonWrapper'>
          <IonButton
            className='withStandardRightMargin'
            onClick={onOpenSignUp}
          >
            Create Account
          </IonButton>
          <IonButton onClick={onOpenSignIn}>Sign In</IonButton>
        </div>
      </div>,
    )
  }

  return (
    <div key='declinedContent'>
      {sections}
    </div>
  )
}

export default GiftDeclinedContent
