import { gql } from '@apollo/client'

export default gql`
  query Q($filter: ReportFilter!) {
    report(filter: $filter) {
      id
      userId
      reportType
      filter
      data
      status
      lines
      recordStatus
      createdAt
      updatedAt
    }
  }`
