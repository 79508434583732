import React from 'react'
import { IonButton } from '@ionic/react'

import './styles.css'
import IntroSection from './IntroSection'
import GiftTransferMessage from '../../components/GiftTransferMessage/GiftTransferMessage'

type Props = {
  senderName: string;
  senderImageUrl: string | null | undefined;
  senderMessage: string | null | undefined;
  formattedBalanceWithCurrency: string;
  merchantName: string;
  onNext: () => void;
  onDeclineGift: () => void;
}

const InitialPageContent: React.FC<Props> = ({
  senderName,
  senderImageUrl,
  senderMessage,
  formattedBalanceWithCurrency,
  merchantName,
  onNext,
  onDeclineGift,
}): JSX.Element => {
  const parts: JSX.Element[] = [
    <IntroSection
      key='intro'
      senderName={senderName}
      formattedBalanceWithCurrency={formattedBalanceWithCurrency}
      merchantName={merchantName}
    />,
  ]

  if (senderMessage) {
    parts.push(
      <GiftTransferMessage
        key='msg'
        senderImageUrl={senderImageUrl}
        senderName={senderName}
        messageText={senderMessage}
      />,
    )
  }

  return (
    <div key='initialContent'>
      {parts}
      <div key='activateFormButtons' className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onDeclineGift}
        >
          Decline
        </IonButton>
        <IonButton onClick={onNext}>Open Gift</IonButton>
      </div>
    </div>
  )
}

export default InitialPageContent
