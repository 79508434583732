import type { MemberMessageContext, MemberMessageInput, MemberMessageTemplate } from '../../../definitions'
import logger from '../../../logger'

type CoreMessageContext = {
  appLink: string | null | undefined
}

function replaceNextInlineValue<ContextT extends MemberMessageContext> (
  text: string,
  context: ContextT,
  coreMessageContext: CoreMessageContext,
  index: number,
): string {
  let newText: string
  const endIndex = text.indexOf('}}', index + 2)
  if (endIndex < 0) {
    logger.error('lib.core.helpers.models.memberMessage.setContextValues: Error parsing variables.')
    newText = text.substr(0, index) + text.substr(index + 2)
  } else {
    const variable = text.substr(index + 2, endIndex - index - 2)
    newText = text.replace(
      `{{${variable}}}`,
      context[variable] || coreMessageContext[variable] || '',
    )
  }

  const nextIndex = newText.indexOf('{{')

  if (nextIndex < 0) {
    return newText
  }

  return replaceNextInlineValue(newText, context, coreMessageContext, nextIndex)
}

function setContextValues<ContextT extends MemberMessageContext> (
  memberMessageInput: MemberMessageInput,
  memberMessageTemplate: MemberMessageTemplate,
  context: ContextT,
  language: 'en' = 'en',
): MemberMessageInput {
  const coreMessageContext: CoreMessageContext = {
    appLink: memberMessageInput.appLink,
  }
  if (language === 'en') {
    const title = memberMessageInput.title || memberMessageTemplate.titleEn
    if (title) {
      const firstVariableIndex = title.indexOf('{{')
      if (firstVariableIndex > -1) {
        memberMessageInput.title = replaceNextInlineValue(
          title,
          context,
          coreMessageContext,
          firstVariableIndex,
        )
      }
    }

    const messageText = memberMessageInput.messageText || memberMessageTemplate.messageTextEn
    if (messageText) {
      const firstVariableIndex = messageText.indexOf('{{')
      if (firstVariableIndex > -1) {
        memberMessageInput.messageText = replaceNextInlineValue(
          messageText,
          context,
          coreMessageContext,
          firstVariableIndex,
        )
      }
    }

    const shortMessageText = memberMessageInput.shortMessageText || memberMessageTemplate.shortMessageTextEn
    if (shortMessageText) {
      const firstVariableIndex = shortMessageText.indexOf('{{')
      if (firstVariableIndex > -1) {
        memberMessageInput.shortMessageText = replaceNextInlineValue(
          shortMessageText,
          context,
          coreMessageContext,
          firstVariableIndex,
        )
      }
    }

    const htmlMessage = memberMessageInput.htmlMessage || memberMessageTemplate.htmlMessageEn
    if (htmlMessage) {
      const firstVariableIndex = htmlMessage.indexOf('{{')
      if (firstVariableIndex > -1) {
        memberMessageInput.htmlMessage = replaceNextInlineValue(
          htmlMessage,
          context,
          coreMessageContext,
          firstVariableIndex,
        )
      }
    }
  }

  return memberMessageInput
}

export default setContextValues
