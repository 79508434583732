import React from 'react'
import { IonButton } from '@ionic/react'

interface Props {
  onOpenPurchase: () => void;
}

const PaymentReceivedAndConfirmedSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    onOpenPurchase,
  } = props

  return (
    <div className='withCenteredColumnContent'>
      <p>
        Thank you! Your payment has been received and confirmed.
      </p>
      <div key='activateFormButtons' className='formButtonWrapper'>
        <IonButton onClick={onOpenPurchase}>Open Gift Card</IonButton>
      </div>
    </div>
  )
}

export default PaymentReceivedAndConfirmedSection
