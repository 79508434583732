import React from 'react'
import { IonIcon, IonImg } from '@ionic/react'

import './styles.css'
import type { PaymentChoicesItem } from '../../definitions'

type Props = {
  item: PaymentChoicesItem;
  readonly: boolean | undefined
  isSelected: boolean;
  onClick: (item: PaymentChoicesItem) => void
}

const PaymentMethodItem: React.FC<Props> = (props): JSX.Element | null => {
  const { item, readonly, isSelected, onClick } = props

  if (!item || !item.show) {
    return null
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Event Handlers:
  const handleClick = (): void => {
    // if (!item.enable) {
    //   return
    // }
    onClick(item)
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Rendering:
  let itemClass = isSelected
    ? 'payment-method-item payment-method-item-selected'
    : 'payment-method-item'
  if (item.enable === false || readonly) {
    itemClass += ' payment-method-item-disabled'
  }
  const labelClass = ((item.enable === false) || readonly)
    ? 'label-disabled'
    : 'label'

  let image: JSX.Element | undefined
  if (item.icon) {
    image = <IonIcon icon={item.icon} className='icon' />
  } else if (item.imageSource) {
    image = (
      <IonImg
        className='image'
        src={item.imageSource}
      />
    )
  } else if (item.imageComponent) {
    image = item.imageComponent
  }

  return (
    <div key={item.key} className={itemClass} onClick={handleClick}>
      {image}
      <div className={labelClass}>{item.label}</div>
    </div>
  )
}

export default PaymentMethodItem
