import type { ApolloClient } from '@apollo/client'
import type { RewardConfig } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type { RewardConfigsQueryData } from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadRewardConfigs = (
  apolloClient: ApolloClient<any>,
): Promise<RewardConfig[] | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadRewardConfigs called.')

    if (!apolloClient) {
      logger.error('api.loadRewardConfigs: no Apollo client available.')
      throw (new Error(ErrorCode.SYSTEM_ERROR))
    }

    apolloClient.query<RewardConfigsQueryData>({ query: apollo.queries.rewardConfigs })
      .then((resp) => {
        // console.log('api.loadRewardConfigs: resp=', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.rewardConfigs
        ) {
          logger.error('api.loadRewardConfigs.load: query did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        resolve(resp.data.rewardConfigs)
      }, (error) => {
        logger.error('api.loadRewardConfigs: error.', { error })
        reject(error)
      })
  })
)

export default loadRewardConfigs
