import React, { useState } from 'react'
import { IonAlert, IonList } from '@ionic/react'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import type { Chat } from '../../lib/core/definitions'
import { ChatLayout } from '../Chat/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { RecordStatus } from '../../lib/core/enums'
import api from '../../services/api'
import ChatListItem from './ChatListItem'
import logger from '../../services/logger'
import modelHelpers from '../../lib/core/helpers/models'

type Props = {
  searchText?: string,
  layout?: ChatLayout;
  onOpenChat?: (chatId: string) => void;
  onShowMessage?: (message: string) => void;
  onOpenSupportChat: () => void;
}

const ChatList: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('ChatList.render called.', { props })
  const {
    layout,
    searchText,
    onOpenChat,
    onOpenSupportChat,
    onShowMessage,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser, chats } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [
    contactIdToBeArchived,
    setContactIdToBeArchived,
  ] = useState<{ contactId: string | undefined; fromUserId: string | undefined; toUserId: string | undefined } | undefined>()

  // ===================================================================================================================
  // Helpers:
  if (!Array.isArray(chats) || chats.length < 1) {
    return null
  }

  const wrapperCssClass = layout === ChatLayout.FULL_PAGE
    ? 'chat-contact-list full-page'
    : 'chat-contact-list embedded'

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSelectedChatId = (chatId: string): void => {
    if (onOpenChat) {
      onOpenChat(chatId)
    }
  }

  const onArchiveContact = (
    contactId: string | undefined,
    fromUserId: string | undefined,
    toUserId: string | undefined,
  ) => {
    setContactIdToBeArchived({ contactId, fromUserId, toUserId })
  }

  const onArchiveContactConfirmed = () => {
    if (!contactIdToBeArchived || !activeUserId) {
      return
    }
    api.upsertContact(
      {
        id: contactIdToBeArchived.contactId,
        recordStatus: RecordStatus.INACTIVE,
      },
      'watch-updated-at',
      undefined,
      activeUserId as string,
      apolloClient,
    ).then(() => {
      if (onShowMessage) {
        onShowMessage('The contact was archived successfully.')
      }
      setContactIdToBeArchived(undefined)
    }, (error) => {
      logger.error('ContactList.onArchiveContactConfirmed: error received.', { error })
      setContactIdToBeArchived(undefined)
    })
  }

  // ===================================================================================================================
  // Rendering:
  let sortedAndFilteredChatList = modelHelpers.chat.sortChatList(
    // chats.filter(chat => !chat.fromUser || chat.fromUser.username !== 'mimble'),
    chats.slice(0),
    activeUserId as string,
  )

  if (searchText) {
    sortedAndFilteredChatList = sortedAndFilteredChatList.filter((chat) => {
      // If the contact isn't present, the other member added the active member as
      // a contact. This creates a chat, but we don't want to show this chat here, since
      // the active member has not yet added the other member as a contact. Once
      // the other member sends a chat message, that contact is created.
      // if (!chat.contact) {
      //   return false
      // }

      let userInfo = chat.fromUserId === activeUserId ? chat.toUser : chat.fromUser
      if (!userInfo && chat.metadata) {
        userInfo = chat.fromUserId === activeUserId ? chat.metadata.toUser : chat.metadata.fromUser
      }
      if (!userInfo) {
        return false
      }
      return modelHelpers.user.isSearchTextMatchForUsernameOrFullName(
        searchText,
        userInfo.username,
        userInfo.fullName,
      )
    })
  }

  const chatListItems = sortedAndFilteredChatList.map((chat: Chat) => (
    <ChatListItem
      key={chat.id}
      chat={chat}
      activeUserId={activeUserId as string}
      onArchiveContact={onArchiveContact}
      onClick={onChangeSelectedChatId}
    />
  ))

  return (
    <>
      <IonList className={wrapperCssClass}>
        {/* <NotificationsItem */}
        {/*   key='noti' */}
        {/*   onOpenSupportChat={onOpenSupportChat} */}
        {/* /> */}
        {chatListItems}
      </IonList>
      <IonAlert
        isOpen={!!contactIdToBeArchived}
        onDidDismiss={(): void => { setContactIdToBeArchived(undefined) }}
        header='Delete Contact'
        subHeader=''
        message='Are you sure you want to archive this contact?'
        buttons={[
          { text: 'Yes, archive', handler: (): void => { onArchiveContactConfirmed() } },
          { text: 'Cancel', cssClass: 'secondary', handler: (): void => { setContactIdToBeArchived(undefined) } },
        ]}
      />
    </>
  )
}

export default ChatList
