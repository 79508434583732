import { gql } from '@apollo/client'

export default gql`
  query Q($purchaseTransferId: String, $toPurchaseId: String) {
    purchaseTransfer (purchaseTransferId: $purchaseTransferId, toPurchaseId: $toPurchaseId) {
      id
      fromPurchaseId
      toPurchaseId
      toUserId
      toUserFullName
      toUserEmail
      toUserPhoneNumber
      notificationMethod
      transferStatus
      messageText
      chatImageUrl
      chatImageType
      animation
      messageScheduledAt
      messageScheduledTimezone
      messageSentAt
      options
      metadata
      recordStatus
      createdAt
      updatedAt
      fromPurchase {
        id
        userId
        offerId
        productId
        productOptionId
        purchaseType
        amount
        initialBalance
        balance
        currency
        fundType
        code
        hasBarcode
        barcodeFormat
        pin
        balanceUpdatedAt
        referenceUrl
        messageEn
        issuedAt
        expiresAt
        adminNotes
        status
        source
        metadata {
          amountPaid
          receivedTransfer {
            id
            fromUserId
            fromPurchaseId
            transferStatus
            recordStatus
          }
          sentTransfer {
            id
            toUserId
            toPurchaseId
            transferStatus
            recordStatus
          }
          latestError
          events {
            time
            type
            message
          }
        }
        recordStatus
        createdAt
        updatedAt
        purchaseTransfers {
          id
          fromPurchaseId
          toPurchaseId
          toUserId
          toUserFullName
          toUserUsername
          toUserEmail
          toUserPhoneNumber
          notificationMethod
          transferStatus
          messageText
          chatImageUrl
          chatImageType
          animation
          messageScheduledAt
          messageScheduledTimezone
          messageSentAt
          options
          adminNotes
          metadata
          recordStatus
          createdAt
          updatedAt
        }
        receivedPurchaseTransfers {
          id
          fromPurchaseId
          toPurchaseId
          toUserId
          toUserFullName
          toUserUsername
          toUserEmail
          toUserPhoneNumber
          notificationMethod
          transferStatus
          messageText
          chatImageUrl
          chatImageType
          animation
          messageScheduledAt
          messageScheduledTimezone
          messageSentAt
          options
          adminNotes
          metadata
          recordStatus
          createdAt
          updatedAt
        }
        sentPurchaseTransfers {
          id
          fromPurchaseId
          toPurchaseId
          toUserId
          toUserFullName
          toUserUsername
          toUserEmail
          toUserPhoneNumber
          notificationMethod
          transferStatus
          messageText
          chatImageUrl
          chatImageType
          animation
          messageScheduledAt
          messageScheduledTimezone
          messageSentAt
          options
          adminNotes
          metadata
          recordStatus
          createdAt
          updatedAt
        }
        purchaseTransferListItems {
          id
          fromPurchaseId
          fromUserId
          fromUserUsername
          fromUserEmail
          fromUserPhoneNumber
          toPurchaseId
          toUserId
          toUserUsername
          toUserFullName
          toUserEmail
          toUserPhoneNumber
          transferStatus
          notificationMethod
          messageScheduledAt
          messageSentAt
          adminNotes
          metadata
          status
          recordStatus
          createdAt
          updatedAt
        }
        product {
          id
          merchantId
          productType
          code
          hasBarcode
          barcodeFormat
          referenceUrl
          messageEn
          termsEn
          termsUrl
          instructionsEn
          instructionsUrl
          imageSourceFrontSide
          imageSourceBackSide
          merchant {
            id
            name
            url
            smallLogoImageSource
            largeLogoImageSource
            balanceLookupUri
          }
          #        tags {}
        }
        transactions {
          id
          remoteId
          purchaseId
          fromUserId
          toUserId
          fromWalletId
          toWalletId
          transactionType
          rewardType
          status
          amount
          price
          paid
          currency
          fundType
          exchangeRate
          exchangeRateUpdatedAt
          processor
          paymentRequest
          source
          blockchainAddress
          message
          requestMessage
          #        metadata
          recordStatus
          createdAt
          updatedAt
          #        fromWallet
          #        toWallet
          blockchainTransactions {
            id
            hash
            transactionId
            fromUserId
            toUserId
            fromWalletId
            toWalletId
            fromBlockchainAddress
            toBlockchainAddress
            transactionType
            amount
            currency
            confirmations
            approved
            message
            #          metadata
            adminNotes
            recordStatus
            createdAt
            updatedAt
            #          fromWallet
            #          toWallet
            #          transaction
          }
        }
        updateHistory {
          id
          purchaseId
          updateType
          balance
          status
          recordStatus
          notes
          createdAt
        }
        user {
          id
          username
          fullName
          email
          phoneNumber
          recordStatus
        }
      }
      toPurchase {
        id
        userId
        offerId
        productId
        productOptionId
        purchaseType
        amount
        initialBalance
        balance
        currency
        fundType
        code
        hasBarcode
        barcodeFormat
        pin
        balanceUpdatedAt
        referenceUrl
        messageEn
        issuedAt
        expiresAt
        adminNotes
        status
        source
        metadata {
          amountPaid
          receivedTransfer {
            id
            fromUserId
            fromPurchaseId
            transferStatus
            recordStatus
          }
          sentTransfer {
            id
            toUserId
            toPurchaseId
            transferStatus
            recordStatus
          }
          latestError
          events {
            time
            type
            message
          }
        }
        recordStatus
        createdAt
        updatedAt
        purchaseTransfers {
          id
          fromPurchaseId
          toPurchaseId
          toUserId
          toUserFullName
          toUserUsername
          toUserEmail
          toUserPhoneNumber
          notificationMethod
          transferStatus
          messageText
          chatImageUrl
          chatImageType
          animation
          messageScheduledAt
          messageScheduledTimezone            
          messageSentAt
          options
          adminNotes
          metadata
          recordStatus
          createdAt
          updatedAt
        }
        receivedPurchaseTransfers {
          id
          fromPurchaseId
          toPurchaseId
          toUserId
          toUserFullName
          toUserUsername
          toUserEmail
          toUserPhoneNumber
          notificationMethod
          transferStatus
          messageText
          chatImageUrl
          chatImageType
          animation
          messageScheduledAt
          messageScheduledTimezone
          messageSentAt
          options
          adminNotes
          metadata
          recordStatus
          createdAt
          updatedAt
        }
        sentPurchaseTransfers {
          id
          fromPurchaseId
          toPurchaseId
          toUserId
          toUserFullName
          toUserUsername
          toUserEmail
          toUserPhoneNumber
          notificationMethod
          transferStatus
          messageText
          chatImageUrl
          chatImageType
          animation
          messageScheduledAt
          messageScheduledTimezone
          messageSentAt
          options
          adminNotes
          metadata
          recordStatus
          createdAt
          updatedAt
        }
        purchaseTransferListItems {
          id
          fromPurchaseId
          fromUserId
          fromUserUsername
          fromUserEmail
          fromUserPhoneNumber
          toPurchaseId
          toUserId
          toUserUsername
          toUserFullName
          toUserEmail
          toUserPhoneNumber
          transferStatus
          notificationMethod
          messageScheduledAt
          messageSentAt
          adminNotes
          metadata
          status
          recordStatus
          createdAt
          updatedAt
        }
        product {
          id
          merchantId
          productType
          code
          hasBarcode
          barcodeFormat
          referenceUrl
          messageEn
          termsEn
          termsUrl
          instructionsEn
          instructionsUrl
          imageSourceFrontSide
          imageSourceBackSide
          merchant {
            id
            name
            url
            smallLogoImageSource
            largeLogoImageSource
            balanceLookupUri
          }
          #        tags {}
        }
        transactions {
          id
          remoteId
          purchaseId
          fromUserId
          toUserId
          fromWalletId
          toWalletId
          transactionType
          rewardType
          status
          amount
          price
          paid
          currency
          fundType
          exchangeRate
          exchangeRateUpdatedAt
          processor
          paymentRequest
          source
          blockchainAddress
          message
          requestMessage
          #        metadata
          recordStatus
          createdAt
          updatedAt
          #        fromWallet
          #        toWallet
          blockchainTransactions {
            id
            hash
            transactionId
            fromUserId
            toUserId
            fromWalletId
            toWalletId
            fromBlockchainAddress
            toBlockchainAddress
            transactionType
            amount
            currency
            confirmations
            approved
            message
            #          metadata
            adminNotes
            recordStatus
            createdAt
            updatedAt
            #          fromWallet
            #          toWallet
            #          transaction
          }
        }
        updateHistory {
          id
          purchaseId
          updateType
          balance
          status
          recordStatus
          notes
          createdAt
        }
      }
    }
  }
`
