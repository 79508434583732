import type { ApolloClient } from '@apollo/client'
import api from '../../../services/api'
import apollo from '../../../services/apollo'

export default (
  chatId: string | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  if (chatId) {
    api.loadChat(
      chatId,
      undefined,
      undefined,
      activeUserId,
      apolloClient,
    ).then(() => {
      // console.log('Reloaded chats.')
    }, (error) => {
      console.error(error)
    })
  } else {
    api.loadChats(
      apollo.getChatsQueryVariables(undefined, activeUserId as string),
      apolloClient,
      undefined,
      { skipCache: true },
    ).then(() => {
      console.log('Reloaded chats.')
    }, (error) => {
      console.error(error)
    })
  }
}
