import React from 'react'
import { IonList } from '@ionic/react'

import './styles.css'
import type { ContactUserListItem } from '../../../lib/core/definitions'
import { ContactEventListEntry } from '../../../lib/core/definitions'
import { ContactItemList } from '../definitions'
import { ContactListEntryType, ContactListScope } from '../enums'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import ContactAllListItem from './ContactAllListItem/ContactAllListItem'
import ContactEventListItem from './ContactEventListItem/ContactEventListItem'
import NotificationsItem from './NotificationsItem/NotificationsItem'

type Props = {
  itemLists: ContactItemList[]
  scope: ContactListScope
  showSystemNotificationItem?: boolean
  onOpenContact: (contactId: string, contactUserId: string, chatId: string) => void
  onOpenSupportChat: () => void
  onArchiveContact: (contactId: string) => void
  onUnArchiveContact: (contactId: string) => void
  onSendGift: (userId: string) => void
  showUiMessage: (message: string) => void
}

const ContactList: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('ContactList.render called.', { props })
  const {
    itemLists,
    scope,
    onOpenContact,
    showSystemNotificationItem,
    onArchiveContact,
    // onUnArchiveContact,
    onOpenSupportChat,
    onSendGift,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  // ===================================================================================================================
  // Rendering:
  let sysNotificationItem: JSX.Element | undefined

  if (showSystemNotificationItem) {
    sysNotificationItem = (
      <NotificationsItem
        key='noti'
        onOpenSupportChat={() => onOpenSupportChat && onOpenSupportChat()}
      />
    )
  }
  
  const contactListItems: JSX.Element[] = []
  itemLists.forEach((list) => {
    if (list.entryType === ContactListEntryType.CONTACT_LIST_ITEM) {
      list.items.forEach((item) =>
          contactListItems.push(
            <ContactAllListItem
              key={item.id}
              contact={item as ContactUserListItem}
              activeUserId={activeUserId}
              scope={scope}
              onArchiveContact={onArchiveContact}
              onOpenContact={onOpenContact}
              onSendGift={onSendGift}
              showUiMessage={showUiMessage}
            />
          )
        )
      } else if (list.entryType === ContactListEntryType.CONTACT_EVENT_ITEM) {
        list.items.forEach((item) =>
          contactListItems.push(
            <ContactEventListItem
              key={item.id}
              entry={item as ContactEventListEntry}
              activeUserId={activeUserId}
              onArchiveContact={onArchiveContact}
              onOpenContact={onOpenContact}
              onSendGift={onSendGift}
              showUiMessage={showUiMessage}
            />
          )
        )
      }
    }
  )

  if (contactListItems.length < 1) {
    return null
  }

  return (
    <IonList className='contact-list withSmallTopMargin'>
      {sysNotificationItem}
      {contactListItems}
    </IonList>
  )
}

export default ContactList
