import React from 'react'

import './styles.css'
import type { FavoriteOrganizationListItem } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import FavoriteMerchantItem from './FavoriteMerchantItem'

type Props = {
  userId: string
  favoriteOrganizations: FavoriteOrganizationListItem[] | undefined
  className?: string
  onAddFavoriteMerchant?: () => void
  onDeleteFavoriteMerchant?: (merchantId: string) => void
  onOpenFavoriteMerchant: (merchantId: string, merchantInMarketplace: boolean) => void
  showUiMessage: (message: string) => void
}

const FavoriteMerchantsSection: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('FavoriteMerchantsSection.render called.', { props })
  const {
    userId,
    className,
    favoriteOrganizations,
    onAddFavoriteMerchant,
    onDeleteFavoriteMerchant,
    onOpenFavoriteMerchant,
  } = props

  // ===================================================================================================================
  // State:
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(favoriteOrganizations) || favoriteOrganizations.length < 1) {
    const emptyListText = userId === activeUserId
      ? 'Add your favorite brands'
      : 'No favorite brands selected yet'

    return (
      <div
        className='lightText withStandardLeftMargin withPointerCursor'
        onClick={onAddFavoriteMerchant}
      >
        {emptyListText}
      </div>
    )
  }

  const list = favoriteOrganizations.map((favoriteMerchant) => (
    <FavoriteMerchantItem
      key={favoriteMerchant.id}
      favoriteMerchant={favoriteMerchant}
      onClick={onOpenFavoriteMerchant}
      onDelete={onDeleteFavoriteMerchant}
    />
  ))

  return (
    <div className={`favorite-merchants-section ${className || ''}`}>
      <div className='favorite-merchants-row'>
        {list}
      </div>
    </div>
  )
}

export default FavoriteMerchantsSection
