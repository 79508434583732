import React from 'react'

import './styles.css'
import type {
  ChatAttachmentInfo,
  ChatAttachmentPurchaseTransferInfo,
  ChatAttachmentRewardInfo,
  ChatAttachmentTransactionInfo, Currency,
} from '../../../lib/core/definitions'
import { ChatAttachmentType } from '../../../lib/core/enums'
import GiftAttachment from './GiftAttachment'
import TokenAttachment from '../../../components/TokenAttachment/TokenAttachment'

type Props = {
  chatMessageId: string;
  attachment?: ChatAttachmentInfo | null;
  isFromLocalUser?: boolean;
  onOpenAttachment?: (
    chatMessageId: string,
    chatAttachmentInfo: ChatAttachmentInfo,
  ) => void;
}

const ChatAttachment: React.FC<Props> = ({
  chatMessageId,
  attachment,
  isFromLocalUser,
  onOpenAttachment,
}): JSX.Element | null => {
  if (!attachment) {
    return null
  }

  const handleClick = (): void => {
    if (onOpenAttachment) {
      onOpenAttachment(chatMessageId, attachment)
    }
  }

  if (attachment.attachmentType === ChatAttachmentType.PURCHASE) {
    return (
      <GiftAttachment
        attachmentInfo={attachment as ChatAttachmentPurchaseTransferInfo}
        onClick={handleClick}
      />
    )
  } else if (attachment.attachmentType === ChatAttachmentType.REWARD) {
    const rewardInfo = attachment as ChatAttachmentRewardInfo
    return (
      <TokenAttachment
        amount={rewardInfo.amount}
        fundType={rewardInfo.fundType}
        currency={rewardInfo.currency as Currency}
        onClick={handleClick}
      />
    )
  } else if (attachment.attachmentType === ChatAttachmentType.TRANSACTION) {
    const transactionInfo = attachment as ChatAttachmentTransactionInfo
    return (
      <TokenAttachment
        amount={transactionInfo.amount}
        fundType={transactionInfo.fundType}
        currency={transactionInfo.currency as Currency}
        onClick={handleClick}
      />
    )
  }
  return null
}

export default ChatAttachment
