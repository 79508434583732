import type { PaymentMethod } from '../definitions'
import getPaymentMethodChoices from './getPaymentMethodChoices'
import getPaymentMethodPermissions from './getPaymentMethodPermissions'
import reloadPendingShoppingCartsUntilDeleted from './reloadPendingShoppingCartsUntilDeleted'

const index = {
  comparePaymentMethod: (method1: PaymentMethod, method2: PaymentMethod): boolean => (
    !!method1 &&
    !!method2 &&
    method1.transactionProcessor === method2.transactionProcessor &&
    method1.fundType === method2.fundType &&
    method1.currency === method2.currency
  ),

  getPaymentMethodChoices,
  getPaymentMethodPermissions,
  reloadPendingShoppingCartsUntilDeleted,
}

export default index
