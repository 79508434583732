import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonIcon,
  IonPage,
  IonToast,
} from '@ionic/react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  barChart,
  basket,
  bookmark,
  cash,
  chatbubbles,
  cube,
  fileTrayStacked,
  key,
  layers,
  megaphone,
  people,
  reader,
  rocket,
} from 'ionicons/icons'
import { Update } from 'history'

import { AppPage, AppRoute } from '../../../enums'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'

const appPageId = AppPage.AdminDashboardPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const AdminDashboardPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (route: AppRoute | string, replace = false) => pageHelpers.navigate(route, history, replace)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const { getProxyUser } = useGlobalCache()
  const { isLoadingActiveUser } = useMimbleData()

  const pageMessages = useContext(PageMessagesContext)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  //   // console.log('DashboardPage: ionViewDidLeave event fired.')
  // })

  // ===================================================================================================================
  // Event Handlers:
  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let signInAsSection: JSX.Element
  if (getProxyUser()) {
    signInAsSection = (
      <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
        <IonIcon icon={key} className='g_bulleted-list-icon' />
        <a href={AppRoute.ADMIN_SIGN_IN_AS}>Cancel Sign In as</a>
      </div>
    )
  } else {
    signInAsSection = (
      <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
        <IonIcon icon={key} className='g_bulleted-list-icon' />
        <a href={AppRoute.ADMIN_SIGN_IN_AS}>Sign In As</a>
      </div>
    )
  }

  return (
    <IonPage className='app-page-admin admin-home-page'>
      <NavBar
        title='Admin Console'
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <div>
          <div className='withDoubleBottomMargin'>
            <div className='sectionCaption'>
              Members
            </div>
            <div className='withStandardLeftMargin withStandardTopMargin'>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={people} className='g_bulleted-list-icon' />
                <a href={AppRoute.ADMIN_USERS}>Members</a>
              </div>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={cash} className='g_bulleted-list-icon' />
                <a href='/admin/send-funds'>Send funds to a member</a>
              </div>
              {signInAsSection}
            </div>
          </div>
          <div className='withDoubleBottomMargin'>
            <div className='sectionCaption'>
              Marketplace
            </div>
            <div className='withStandardLeftMargin withStandardTopMargin'>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={basket} className='g_bulleted-list-icon' />
                <a href='/admin/merchants'>Merchants</a>
              </div>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={cube} className='g_bulleted-list-icon' />
                <a href='/admin/products'>Products</a>
              </div>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={layers} className='g_bulleted-list-icon' />
                <a href='/admin/inventory'>Inventory list</a>
              </div>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={fileTrayStacked} className='g_bulleted-list-icon' />
                <a href='/admin/import-stored-products'>Import inventory</a>
              </div>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={bookmark} className='g_bulleted-list-icon' />
                <a href='/admin/tags'>Tags</a>
              </div>
            </div>
          </div>
          <div className='withDoubleBottomMargin'>
            <div className='sectionCaption'>
              Messaging
            </div>
            <div className='withStandardTopMargin'>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={chatbubbles} className='g_bulleted-list-icon' />
                <a href='/admin/mimble-team-messages'>Messages from members</a>
              </div>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={megaphone} className='g_bulleted-list-icon' />
                <a href={AppRoute.ADMIN_CAMPAIGNS}>Campaigns</a>
              </div>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={reader} className='g_bulleted-list-icon' />
                <a href={AppRoute.ADMIN_MEMBER_MESSAGE_TEMPLATES}>Member Message Templates</a>
              </div>
            </div>
          </div>
          <div className='withDoubleBottomMargin'>
            <div className='sectionCaption'>
              System
            </div>
            <div className='withStandardTopMargin'>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={rocket} className='g_bulleted-list-icon' />
                <a href={AppRoute.ADMIN_EXECUTE_ADMIN_TASK}>Execute Admin Task</a>
              </div>
              <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
                <IonIcon icon={barChart} className='g_bulleted-list-icon' />
                <a href={AppRoute.ADMIN_REPORTS}>Reports</a>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default AdminDashboardPage
