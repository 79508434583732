import React, { useContext, useState } from 'react'
import {
  IonButton,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  useIonViewDidLeave,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../../enums'
import type { UserIdentInfo } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import FindUserForm from '../../../components/FindUserForm/FindUserForm'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import UserInfoCard from '../../../components/UserInfoCard/UserInfoCard'

const appPageId = AppPage.AdminSignInAsAnotherUserPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const SignInAsAnotherUserPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { getProxyUser, setProxyUser } = useGlobalCache()
  const { activeUser, isLoadingActiveUser, reloadActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [otherUserIdentInfo, setOtherUserIdentInfo] = useState(undefined as UserIdentInfo | undefined | null)

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser

  const isDirty = !!otherUserIdentInfo
  const isValid = true

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  // })

  useIonViewDidLeave(() => {
    pageMessages && pageMessages.clear()
    setToastMessage('')
    setOtherUserIdentInfo(undefined)
  })

  // ===================================================================================================================
  // Event Handlers:
  const onSelectOtherUser = (newUserIdentInfo: UserIdentInfo | undefined): void => {
    setOtherUserIdentInfo(newUserIdentInfo)
  }

  const onClearOtherUser = (): void => {
    setOtherUserIdentInfo(undefined)
  }

  const onClearSignInAs = (): void => {
    pageMessages && pageMessages.clear()
    auth.clearSignInAsUser(apolloClient)
    setTimeout(() => {
      reloadActiveUser().then(() => {
        navigate(AppRoute.HOME)
      }, (error) => {
        console.error(error)
      })
    }, 500)
  }

  const onGetActiveUser = (): void => {
    pageMessages && pageMessages.clear()
    reloadActiveUser().then(undefined, (error) => {
      console.error(error)
    })
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    // clearUserIdentInfo()
    refreshEvent = event
    // todo: have onGetActiveUser return a promise, then cancel the refreshEvent
    onGetActiveUser()
    // onGetActiveUser().then(() => {
    //   if (refreshEvent) {
    //     refreshEvent.detail.complete()
    //     refreshEvent = undefined
    //   }
    // }, (error) => {
    //   console.error(error)
    // })
  }

  const onSubmit = (event: any): void => {
    event.preventDefault()
    if (!otherUserIdentInfo) {
      return
    }
    setProxyUser(otherUserIdentInfo)
    setTimeout(() => {
      reloadActiveUser().then(() => {
        if (
          location &&
          location.pathname &&
          location.pathname !== AppRoute.HOME
        ) {
          navigate(AppRoute.HOME)
        }
      }, (error) => {
        console.error(error)
      })
    }, 500)
  }

  const onOpenContact = (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined,
  ): void => {
    pageHelpers.openContact({
      activeUserId,
      contactId,
      contactUserId,
      chatId,
      apolloClient,
      navigate,
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content
  const proxyUser = getProxyUser()
  if (proxyUser && proxyUser.id) {
    content = (
      <div className='g-with-safe-padding'>
        <h2>Sign-In-As Active</h2>
        <div className='section'>
          You are signed in as&nbsp;
          <strong>{proxyUser.username || proxyUser.email || proxyUser.phoneNumber || proxyUser.id || 'N/A'}</strong>
          .
          Please respect the privacy of the other other user! Certain features can&apos;t be used while signed in as
          another user.
        </div>
        <div className='bottomButtonWrapper'>
          <IonButton
            onClick={onClearSignInAs}
          >
            Clear Sign In As
          </IonButton>
        </div>
      </div>
    )
  } else {
    let selectUserSection: JSX.Element | undefined
    if (otherUserIdentInfo) {
      selectUserSection = (
        <UserInfoCard
          userIdentInfo={otherUserIdentInfo}
          className='withStandardBottomMargin'
          onClose={onClearOtherUser}
          onOpenContact={onOpenContact}
        />
      )
    } else {
      selectUserSection = (
        <FindUserForm
          selectedUser={otherUserIdentInfo || undefined}
          className='find-user-form'
          onSelectUser={onSelectOtherUser}
        />
      )
    }

    const submitButtonLabel = otherUserIdentInfo
      ? `Sign In As ${otherUserIdentInfo.fullName}`
      : 'Sign In As'

    content = (
      <div className='g-with-safe-padding'>
        <h2>Sign-In-As (Sign In As Another User)</h2>
        <div className='section'>
          This allows you to drive the app as another user. This feature is only available to admins. Please
          respect the privacy of the other other user! Certain features can&apos;t be used while signed in as
          another user.
        </div>
        {selectUserSection}
        <form onSubmit={onSubmit}>
          <div className='formButtonWrapper'>
            <SubmitButton
              disabled={!isDirty || !isValid}
              onClick={onSubmit}
            >
              {submitButtonLabel}
            </SubmitButton>
          </div>
        </form>
      </div>
    )
  }

  return (
    <IonPage className='app-page-admin admin-sign-in-as-page'>
      <NavBar
        title='Sign In As'
        isProcessing={isProcessing}
      />
      <IonContent>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default SignInAsAnotherUserPage
