import React from 'react'
import { IonButton, IonIcon, IonModal } from '@ionic/react'
import { close } from 'ionicons/icons'

import './styles.css'
import type { ContextHelpId } from '../../contexts/AppHelpersContext/enums'
import ContextHelpText from '../../contexts/AppHelpersContext/ContextHelpText'

type Props = {
  ContextHelpId: ContextHelpId | undefined
  onClose: () => void
}

const ContextHelpModal: React.FC<Props> = (props): JSX.Element | null => {
  const {
    ContextHelpId,
    onClose,
  } = props

  // ===================================================================================================================
  // Rendering:
  // console.log('SelectContact.render called.', { props, contactUsers })
  if (!ContextHelpId) {
    return null
  }

  const data = ContextHelpText[ContextHelpId]

  if (!data || !data.text) {
    return null
  }

  return (
    <IonModal
      isOpen={!!ContextHelpId}
      cssClass='context-help-modal'
      backdropDismiss
      swipeToClose
      onWillDismiss={onClose}
    >
      <div className='modal-content'>
        <div className='header'>
          <div>
            <div className='header-caption'>{data.title || 'Info'}</div>
          </div>
          <div>
            <IonButton
              fill='clear'
              onClick={onClose}
            >
              <IonIcon icon={close} />
            </IonButton>
          </div>
        </div>
        <div className='withStandardPadding autoOverflowContainer'>
          <div
            dangerouslySetInnerHTML={{ __html: data.text }}
          />
          {/* {data.text.split('\n').map((item, key) => { */}
          {/*  return <span key={key}>{item}<br /></span> */}
          {/* })} */}
        </div>
        <div className='withCenteredColumnContent withStandardPadding'>
          <IonButton
            color='light'
            onClick={onClose}
          >
            Got it!
          </IonButton>
        </div>
      </div>
    </IonModal>
  )
}

export default ContextHelpModal
