import { gql } from '@apollo/client'

export default gql`
  query Q($filter: OrderListFilter!) {
    orders (filter: $filter) {
      id
      userId
      donationTargetId
      metadata
      status
      recordStatus
      createdAt
      updatedAt
      userFullName
      paymentAmount
      paymentFundType
      paymentCurrency
      paymentProcessor
      paymentStatus
    }
  }`
