import React from 'react'
import { add, calendarOutline } from 'ionicons/icons'

import IconButton from '../../../components/IconButton/IconButton'
import { ContactListScope } from '../enums'

type Props = {
  scope?: ContactListScope
  onActionButton?: () => void;
}

const EmptyContactListContent: React.FC<Props> = (props): JSX.Element | null => {
  const { scope, onActionButton } = props

  const handleActionButton = () => {
    if (onActionButton) {
      onActionButton()
    }
  }
  let title: string | undefined
  let subText: string | undefined
  let actionText: string | undefined
  let icon = add

  if (scope === ContactListScope.CONTACTS) {
    title = 'Your contact list is currently empty'
    subText = 'Anytime you send or receive a gift, Mimble will add that person to your contact list.'
    actionText = 'You can add anyone to your contacts with the Add button'
  } else if (scope === ContactListScope.MESSAGES) {
    title = 'No active chats'
    actionText = 'Add someone as a contact, or send someone a gift to initiate a chat!'
  } else if (scope === ContactListScope.EVENTS) {
    title = 'No upcoming events'
    subText = 'Contacts with shared upcoming birthdays and other events will be listed here.'
    actionText = 'Add gifting events for your contacts to never forget a special moment.'
    icon = calendarOutline
  }

  let actionSection: JSX.Element | undefined
  if (actionText) {
    actionSection = (
      <div className='empty-list-action'>
        <IconButton
          key='contacts'
          icon={icon}
          text={icon === add ? 'Add' : undefined}
          layout='vertical'
          className='button'
          iconSize={30}
          disabled // True until there is handling for adding a contact and navigating to activeUser events
          onClick={handleActionButton}
        />
        {actionText}
      </div>
    )
  }

  return (
    <div className='empty-contact-list withCenteredColumnContent withDoublePadding'>
      <div className='withCenteredColumnContent withDoubleTopMargin withDoubleBottomMargin'>
        <div className='sectionCaption'>{title}</div>
        {subText &&
          <div className='withStandardPadding withStandardTopMargin'>
            {subText}
          </div>
        }
      </div>
      {actionSection}
    </div>
  )
}

export default EmptyContactListContent
