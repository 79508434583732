import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, Product, ProductListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  ProductQueryData,
  ProductQueryVariables,
} from '../apollo/definitions'
import type { ProductQueryOptions } from '../apollo/gql/queries/product'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'

const loadProduct = (
  productId: string,
  isInTargetStateFunc: ((product: Product) => boolean) | 'watch-updated-at' | undefined,
  listFilter: ProductListFilter | undefined,
  apolloClient: ApolloClient<any>,
  productQueryOptions?: ProductQueryOptions,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Product | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadProduct called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadProduct: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<ProductQueryVariables> = {
      query: apollo.queries.product(productQueryOptions),
      variables: { productId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<ProductQueryData, ProductQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.product ||
            !resp.data.product.id
          ) {
            logger.error('api.loadProduct.load: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((product: Product) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.product.updatedAt
            activeIsInTargetStateFunc = (product: Product) => product.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.product)
          ) {
            const queryListVariables = clientOptions && clientOptions.updateList
              ? apollo.getProductsQueryVariables(listFilter)
              : undefined
            cache.updateProduct(
              resp.data.product,
              queryListVariables,
              apolloClient,
            )
            // console.log('api.loadProduct.load: resolving with info.', resp.data.product)
            resolve(resp.data.product)
            return
          }

          // console.log('api.loadPproduct.load: pproducts not in target state.',
          //   { pproduct: resp.data.pproduct })
          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadProduct.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadProduct.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadProduct
