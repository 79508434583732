import clearAppCacheAndReload from './clearAppCacheAndReload'
import compareVersions from './compareVersions'
import currentVersion from '../../version'
import fetchLatestVersion from './fetchLatestVersion'
import getLatestVersion from './getLatestVersion'
import isNewAppWaiting from './isNewAppWaiting'
import onServiceWorkerSuccess from './onServiceWorkerSuccess'
import onServiceWorkerUpdate from './onServiceWorkerUpdate'
import serviceData from './serviceData'
import upgradeApp from './upgradeApp'

export default {
  addVersionListener: serviceData.addVersionListener,
  clearAppCacheAndReload,
  compareVersions,
  currentVersion,
  fetchLatestVersion,
  getLatestVersion,
  getServiceWorkerRegistration: serviceData.getServiceWorkerRegistration,
  // getWorkbox: serviceData.getWorkbox,
  isAppVersionCurrent: (): boolean => !compareVersions(serviceData.getLatestVersion(), currentVersion),
  isNewAppWaiting,
  latestVersion: serviceData.getLatestVersion,
  onServiceWorkerSuccess,
  onServiceWorkerUpdate,
  removeVersionListener: serviceData.removeVersionListener,
  setServiceWorkerRegistration: serviceData.setServiceWorkerRegistration,
  // setWorkbox: serviceData.setWorkbox,
  upgradeApp,
}
