import React, { useState } from 'react'

import './styles.css'
import InitialScreen from './InitialScreen'
import UpdatePasswordScreen from './UpdatePasswordScreen'

enum FormState {
  INITIAL = 'initial',
  UPDATE_PASSWORD = 'update-password',
}

type Props = {
  onClose: () => void
  showUiMessage: (message: string) => void
}

const ResetPasswordForm: React.FC<Props> = (props): JSX.Element => {
  // console.log('ResetPasswordForm.render called.', { props })
  const {
    onClose,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const [formState, setFormState] = useState(FormState.INITIAL)
  const [asyncTaskId, setAsyncTaskId] = useState<string | undefined>()

  // ===================================================================================================================
  // Event Handlers:
  const onNotificationSent = (newAsyncTaskId: string): void => {
    setAsyncTaskId(newAsyncTaskId)
    setFormState(FormState.UPDATE_PASSWORD)
  }

  // ===================================================================================================================
  // Rendering:
  if (formState === FormState.INITIAL) {
    return (
      <InitialScreen
        key='initial'
        asyncTaskId={asyncTaskId}
        onNotificationSent={onNotificationSent}
        onClose={onClose}
        showUiMessage={showUiMessage}
      />
    )
  }

  return (
    <UpdatePasswordScreen
      key='update'
      asyncTaskId={asyncTaskId as string}
      onClose={onClose}
      showUiMessage={showUiMessage}
    />
  )
}

export default ResetPasswordForm
