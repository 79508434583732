import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type {
  ContactQueryData,
  ContactQueryVariables,
  WishQueryData,
  WishesQueryData,
  WishesQueryVariables,
  WishQueryVariables,
} from '../../apollo/definitions'
import type { Wish } from '../../../lib/core/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  wish: Wish,
  queryListVariables: WishesQueryVariables,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateWish.updateInList called.', { wish )
  if (!wish) {
    logger.error('api.cache.updateWish.updateInList: no wish given.')
    return
  }

  try {
    const cachedWishesData = apolloClient.readQuery<WishesQueryData, WishesQueryVariables>({
      query: apollo.queries.wishes,
      variables: queryListVariables,
    })

    if (
      !cachedWishesData ||
      !Array.isArray(cachedWishesData.wishes) ||
      cachedWishesData.wishes.length < 1
    ) {
      apolloClient.writeQuery<WishesQueryData, WishesQueryVariables>({
        query: apollo.queries.wishes,
        variables: queryListVariables,
        data: { wishes: [wish] },
      })
      return
    }

    const updatedWishes = coreHelpers.models.updateModelInList<Wish, Wish>(
      cachedWishesData.wishes,
      wish,
      undefined,
      true,
      undefined,
    )
    // console.log('api.cache.updateInList: updated wishes=', { updatedWishes })

    apolloClient.writeQuery<WishesQueryData, WishesQueryVariables>({
      query: apollo.queries.wishes,
      variables: queryListVariables,
      data: { wishes: updatedWishes },
    })
  } catch (error) {
    logger.error('api.cache.updateWish.updateInList: error.', { wish, error })
  }
}

/* const updateWishInContactList = (
  wish: Wish,
  contactId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateWishInContactList called.')

  const localUserId = globalCache.getActiveUserId()
  if (!localUserId) {
    console.log('api.cache.updateWishInContactList: no localUserId found.')
    return
  }

  const contactsVariables = {
    filter: {
      fromUserId: globalCache.getActiveUserId() as string,
      orderBy: 'fullName',
    },
  }
  const cachedContactsData = apolloClient.readQuery<ContactsQueryData, ContactsQueryVariables>({
    query: apollo.queries.contacts,
    variables: contactsVariables,
  })
  // console.log('api.cache.updateWishInContactList: loaded cached contacts data.', { cachedContactsData })

  if (cachedContactsData && cachedContactsData.contacts) {
    const updatedContacts = cachedContactsData.contacts.map((contact) => {
      if (contact.id !== contactId) {
        return contact
      }
      const updatedContact = { ...contact }
      if (Array.isArray(contact.wishes) && contact.wishes.length > 0) {
        updatedContact.wishes = coreHelpers.models.updateModelInList<Wish, Wish>(
          contact.wishes,
          wish,
          undefined,
          true,
        )
      }
      return updatedContact
    })
    // console.log('api.cache.updateWishInContactList: updated contacts=', { updatedContacts })

    apolloClient.writeQuery({
      query: apollo.queries.contacts,
      variables: contactsVariables,
      data: { contacts: updatedContacts },
    })
  }
} */

const updateWishInContact = (
  wish: Wish,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateWish.updateWishInContact called.')
  const contactVariables = { contactId: wish.contactId as string }
  const cachedContactData = apolloClient.readQuery<ContactQueryData, ContactQueryVariables>({
    query: apollo.queries.contact,
    variables: contactVariables,
  })
  // console.log('api.cache.updateWishInContact: loaded cached contacts data.', { cachedContactData })

  if (cachedContactData && cachedContactData.contact) {
    const updatedContact = { ...cachedContactData.contact }
    updatedContact.wishes = coreHelpers.models.updateModelInList<Wish, Wish>(
      cachedContactData.contact.wishes,
      wish,
      undefined,
      true,
    )
    // console.log('api.cache.updateWishInContact: updated contacts=', { updatedContacts })

    apolloClient.writeQuery<ContactQueryData, ContactQueryVariables>({
      query: apollo.queries.contact,
      variables: contactVariables,
      data: { contact: updatedContact },
    })
  }
}

const updateWish = (
  wish: Wish,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateWish called.', { wish })
  if (!apolloClient) {
    logger.error('api.cache.updateWish: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!wish || !wish.id) {
    logger.error('api.cache.updateWish: no wish given.', { wish })
    return
  }

  try {
    apolloClient.writeQuery<WishQueryData, WishQueryVariables>({
      query: apollo.queries.wish,
      variables: { wishId: wish.id },
      data: { wish },
    })

    if (activeUserId && wish.userId === activeUserId) {
      updateInList(
        wish,
        apollo.getWishesOfActiveUserQueryVariables(undefined, activeUserId),
        apolloClient,
      )
      return
    }

    if (wish.contactId) {
      updateWishInContact(wish, apolloClient)
      return
    }

    if (wish.userId) {
      updateInList(
        wish,
        apollo.getWishesQueryVariables(undefined, wish.userId),
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateWish: error received.', { wish, error })
  }
}

export default updateWish
