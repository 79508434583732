import { gql } from '@apollo/client'

export default gql`
  mutation M($memberMessageTemplate: MemberMessageTemplateInput!) {
    upsertMemberMessageTemplate (memberMessageTemplate: $memberMessageTemplate) {
      id
      name
      description
      titleEn
      messageTextEn
      shortMessageTextEn
      htmlMessageEn
      action0
      action1
      action2
      sendChatMessage
      sendEmail
      sendInAppMessage
      sendPushNotification
      sendSms
      isCore
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
      updatedBy
    }
  }`
