import { OrgProductType } from '../../../enums'
import type { ShoppingCartItem } from '../../../definitions'
import getInventory from './getInventory'

const isInStock = (item: ShoppingCartItem): boolean => {
  if (
    !item ||
    item.productType === OrgProductType.MIMBLE_TOKEN ||
    !item.productOptionId
  ) {
    return true
  }
  return (item.productCount || 1) <= getInventory(item)
}

export default isInStock
