import type { FormEvent } from 'react'
import React, { useContext, useEffect, useState } from 'react'
import { IonContent, IonInput, IonPage, IonToast, useIonViewDidLeave } from '@ionic/react'
import { Update } from 'history'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import { FiatCurrency, FundType, OrgProductType, PurchaseStatus, UiMessage } from '../../lib/core/enums'
import { AppPage, AppRoute, GiftFlowStep, PageMessageType } from '../../enums'
import type { PurchaseInput } from '../../lib/core/definitions'
import type {
  AddUploadedPurchaseData,
  AddUploadedPurchaseVariables,
  MerchantQueryData,
  MerchantQueryVariables,
} from '../../services/apollo/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import api from '../../services/api'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import coreHelpers from '../../lib/core/helpers'
import FormItem from '../../components/FormItem/FormItem'
import GiftCard from '../../components/GiftCard/GiftCard'
import GiftFlowStepper from '../../components/GiftFlowStepper/GiftFlowStepper'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../helpers/validationHelpers'

const appPageId = AppPage.NewUploadedPurchasePage
const appPageDef = pageHelpers.appPageDefs[appPageId]

type Params = {
  merchantId: string
}

const NewUploadedPurchasePage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { merchantId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const {
    activeUser,
    isLoadingActiveUser,
    reloadPurchases,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const {
    flowId: giftFlowId,
    giftFlowStep,
    giftChanges,
    setGiftChanges,
  } = useGiftFlow()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [balance, setBalance] = useState(0)
  const [balanceValidationError, setBalanceValidationError] = useState<string | undefined>()
  const [fundType, setFundType] = useState(FundType.FIAT)
  const [fundTypeValidationError, setFundTypeValidationError] = useState<string | undefined>()
  const [currency, setCurrency] = useState(FiatCurrency.USD)
  const [currencyValidationError, setCurrencyValidationError] = useState<string | undefined>()
  const [code, setCode] = useState<string | undefined>()
  const [codeValidationError, setCodeValidationError] = useState<string | undefined>()
  const [pin, setPin] = useState<string | undefined>()
  const [pinValidationError, setPinValidationError] = useState<string | undefined>()
  const [hasBarcode, setHasBarcode] = useState(true)
  const [hasBarcodeValidationError, setHasBarcodeValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading merchant:
  const {
    data: merchantLoadedData,
    loading: isLoadingMerchant,
    error: merchantLoadingError,
  } = useQuery<MerchantQueryData, MerchantQueryVariables>(
    apollo.queries.merchant, {
      variables: { merchantId: merchantId as string },
      skip: !merchantId,
      notifyOnNetworkStatusChange: true,
    },
  )
  const merchant = merchantLoadedData ? merchantLoadedData.merchant : undefined

  useEffect((): void => {
    if (merchantLoadingError) {
      pageHelpers.checkForUnauthorized(merchantLoadingError, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
    }
  }, [merchantLoadingError])

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Upsert purchase:
  const [
    addUploadedPurchase, {
      loading: isAddingUploadedPurchase,
      error: addUploadedPurchaseError,
    },
  ] = useMutation<AddUploadedPurchaseData, AddUploadedPurchaseVariables>(apollo.mutations.addUploadedPurchase, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.addUploadedPurchase && data.addUploadedPurchase.id) {
        api.loadPurchase(
          data.addUploadedPurchase.id as string,
          (p) => p.status === PurchaseStatus.ACTIVE,
          undefined,
          activeUserId as string,
          apolloClient,
          undefined,
          { skipCache: true },
        ).then((reloadedPurchase) => {
          if (!reloadedPurchase) {
            console.error('Did not receive a purchase')
            return
          }
          reloadPurchases().then(() => {
            // console.log('Reloaded purchases.')
            if (
              giftFlowId &&
              giftFlowStep === GiftFlowStep.GIFT &&
              reloadedPurchase.id
            ) {
              setGiftChanges({
                ...giftChanges,
                fromPurchaseId: reloadedPurchase.id,
              }, true)
              navigate(AppRoute.SEND_GIFT, true)
              return
            }
            navigate(`${AppRoute.GIFT_CARD}/${data.addUploadedPurchase.id}`, true)
          }, (error) => {
            console.error(error)
          })
        }, (error) => {
          console.error(error)
        })
      }
    },
  })

  useEffect((): void => {
    if (addUploadedPurchaseError) {
      pageHelpers.checkForUnauthorized(addUploadedPurchaseError, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_UPLOADING_GIFT_CARD)
    }
  }, [addUploadedPurchaseError])

  // ===================================================================================================================
  // Helpers:
  const product = merchant ? merchant.mainProduct : undefined
  const balanceChanged = !!balance
  const fundTypeChanged = fundType !== FundType.FIAT
  const currencyChanged = !!currency
  const codeChanged = !!code
  const pinChanged = !!pin
  const hasBarcodeChanged = hasBarcode !== true
  const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromMerchant(merchant)

  const isDirty = (
    balanceChanged ||
    fundTypeChanged ||
    currencyChanged ||
    codeChanged ||
    pinChanged ||
    hasBarcodeChanged
  )
  const isValid = (
    !balanceValidationError &&
    !fundTypeValidationError &&
    !currencyValidationError &&
    !codeValidationError &&
    !pinValidationError &&
    !hasBarcodeValidationError
  )

  const isProcessing = (
    isAddingUploadedPurchase ||
    isLoadingMerchant ||
    isLoadingActiveUser
  )

  const resetForm = (): void => {
    setBalance(0)
    setFundType(FundType.FIAT)
    setCurrency(FiatCurrency.USD)
    setCode('')
    setPin('')
    setHasBarcode(true)
  }

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  // })

  useIonViewDidLeave(() => {
    pageMessages && pageMessages.clear()
    setToastMessage('')
    resetForm()
  })

  // ===================================================================================================================
  // Event Handlers:
  const onChangeBalance = (event: any): void => {
    const numericValue = parseFloat(event.detail.value)
    setBalance(numericValue)
    if (numericValue) {
      setBalanceValidationError(validationHelpers.validatePurchaseBalance(numericValue))
    } else {
      setBalanceValidationError('')
    }
  }

  const onChangeFundType = (event: any): void => {
    setFundType(event.detail.value)
    if (event.detail.value) {
      setFundTypeValidationError(validationHelpers.validateFundType(event.detail.value))
    } else {
      setFundTypeValidationError('')
    }
  }

  const onChangeCurrency = (event: any): void => {
    setCurrency(event.detail.value)
    if (event.detail.value) {
      setCurrencyValidationError(validationHelpers.validateCurrency(event.detail.value))
    } else {
      setCurrencyValidationError('')
    }
  }

  const onChangeCode = (event: any): void => {
    setCode(event.detail.value)
    if (event.detail.value) {
      setCodeValidationError(validationHelpers.validatePurchaseCode(event.detail.value))
    } else {
      setCodeValidationError('')
    }
  }

  const onChangePin = (event: any): void => {
    setPin(event.detail.value)
    if (event.detail.value) {
      setPinValidationError(validationHelpers.validatePurchasePin(event.detail.value))
    } else {
      setPinValidationError('')
    }
  }

  const onAddUploadedPurchase = (event?: FormEvent): void => {
    if (event) {
      event.preventDefault()
    }
    // console.log('onAddUploadedPurchase called.', { balanceCrypto, balance, code, pin })
    if (!product) {
      return
    }
    if (!balance || !code) {
      setToastMessage('Missing input.')
      setShowToast(true)
      return
    }
    const purchase: PurchaseInput = {
      userId: activeUserId as string,
      productId: (product.id as string),
      productType: OrgProductType.GIFT_CARD,
      balance: coreHelpers.ui.getInternalMoneyAmount(balance || 0, fundType, currency),
      fundType,
      currency,
      code,
      hasBarcode: product.hasBarcode,
      barcodeFormat: product.barcodeFormat,
    }
    if (pin) {
      purchase.pin = pin
    }
    addUploadedPurchase({ variables: { purchase } })
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const showFundType = false
  const showCurrency = false
  const sections: JSX.Element[] = []

  if (merchant) {
    sections.push(
      <div key='merchant'>
        <div className='withCenteredColumnContent'>
          <div className='gift-card-image'>
            <GiftCard
              purchaseId=''
              backgroundImageUri={giftCardBackgroundUri}
              merchantLogoUri={merchantLogoUri}
            />
          </div>
        </div>
        <div className='withDoubleTopMargin withDoubleBottomMargin'>
          <div className='formLabel'>
            Brand
          </div>
          {merchant.name}
        </div>
      </div>,
    )
  }

  sections.push(
    <FormItem
      key='balance'
      label='Balance'
      validationError={balanceValidationError}
      withBottomMargin
    >
      <IonInput
        onIonChange={onChangeBalance}
        type='number'
        inputmode='decimal'
        placeholder='Balance'
        step='any'
        value={balance ? balance.toString() : ''}
      />
    </FormItem>,
  )

  if (showFundType) {
    sections.push(
      <FormItem
        key='fund-type'
        label='Fund Type'
        validationError={fundTypeValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeFundType}
          type='text'
          placeholder='fundType'
          value={fundType}
        />
      </FormItem>,
    )
  }

  if (showCurrency) {
    sections.push(
      <FormItem
        key='currency'
        label='Currency'
        validationError={currencyValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeCurrency}
          type='text'
          placeholder='currency'
          value={currency}
        />
      </FormItem>,
    )
  }

  sections.push(
    <FormItem
      key='code'
      label='Code'
      validationError={codeValidationError}
      withBottomMargin
    >
      <IonInput
        onIonChange={onChangeCode}
        type='text'
        placeholder='Code'
        value={code}
      />
    </FormItem>,
  )

  if (product && product.hasPin) {
    sections.push(
      <FormItem
        key='pin'
        label='PIN'
        validationError={pinValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangePin}
          type='text'
          placeholder='PIN'
          value={pin}
        />
      </FormItem>,
    )
  }

  const content = (
    <form onSubmit={onAddUploadedPurchase}>
      {sections}
      <div className='formButtonWrapper'>
        <SubmitButton
          expand='block'
          onClick={onAddUploadedPurchase}
          disabled={!isDirty || !isValid}
          isProcessing={isProcessing}
        >
          Submit
        </SubmitButton>
      </div>
    </form>
  )

  return (
    <IonPage className='app-page-public new-uploaded-purchase-page'>
      <NavBar
        title='Upload Gift Card'
        goBackUri='/select-brand/upload-card'
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <GiftFlowStepper
        size='large'
        className='withStandardTopMargin'
        parent='marketplace'
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default NewUploadedPurchasePage
