import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, UserSensitiveInfo } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type { ApiClientOptions, UserSensitiveInfoQueryData, UserSensitiveInfoQueryVariables } from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadUserSensitiveInfo = (
  userId: string,
  isInTargetStateFunc: ((userSensitiveInfo: UserSensitiveInfo) => boolean) | 'watch-updated-at' | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<UserSensitiveInfo | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadUserSensitiveInfo called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadUserSensitiveInfo: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<UserSensitiveInfoQueryVariables> = {
      query: apollo.queries.userSensitiveInfo,
      variables: { userId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<UserSensitiveInfoQueryData, UserSensitiveInfoQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.userSensitiveInfo ||
            !resp.data.userSensitiveInfo.id
          ) {
            console.log('api.loadUserSensitiveInfo.load: no sensitive info returned for this user.')
            resolve(undefined)
            return
          }

          let activeIsInTargetStateFunc: ((userSensitiveInfo: UserSensitiveInfo) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.userSensitiveInfo.updatedAt
            activeIsInTargetStateFunc = (userSensitiveInfo: UserSensitiveInfo) => userSensitiveInfo.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.userSensitiveInfo)
          ) {
            // console.log('api.loadUserSensitiveInfo.load: resolving with info.', resp.data.userSensitiveInfo)
            resolve(resp.data.userSensitiveInfo)
            return
          }

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadUserSensitiveInfo.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadUserSensitiveInfo.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadUserSensitiveInfo
