import { gql } from '@apollo/client'

export default gql`
  subscription S($userId: String!) {
    modelChanged(userId: $userId) {
      modelId
      modelType
      userId
      messageType
      code
      model
    }
  }
`
