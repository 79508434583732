import type { ApolloClient } from '@apollo/client'
import type { UserDeviceInput } from '../../lib/core/definitions'
import logger from '../logger'
import upsertUserDevice from './upsertUserDevice'

const reportSessionState = (
  isSessionStart: boolean,
  activeUserId: string | null | undefined,
  deviceUuid: string,
  isSignInAsSession: boolean,
  apolloClient: ApolloClient<any>,
): void => {
  console.log('api.reportSessionState called.')
  if (!activeUserId || !deviceUuid) {
    // console.log('api.reportSessionState: user not signed in.')
    return
  }

  if (isSignInAsSession) {
    console.log('api.reportSessionState: this is a sign-in-as session; not reporting.')
    return
  }

  const userDeviceInput: UserDeviceInput = {
    deviceUuid,
    userId: activeUserId,
  }

  if (isSessionStart) {
    userDeviceInput.sessionStartedAt = 'now'
  } else {
    userDeviceInput.sessionEndedAt = 'now'
  }

  // console.log('api.reportSessionState: calling upsertUserDevice.', { userDeviceInput, activeUserId, deviceUuid })
  upsertUserDevice(
    userDeviceInput,
    undefined,
    undefined,
    activeUserId,
    apolloClient,
    undefined,
    { updateList: false },
  ).then(undefined, (error) => {
    logger.error('services.firebase.reportSessionState: upsertUserDevice returned error.', { error })
  })
}

export default reportSessionState
