import React, { useEffect, useState } from 'react'
import { IonToast, isPlatform } from '@ionic/react'
import { Keyboard } from '@capacitor/keyboard'
import { Update } from 'history'
import { useHistory, useLocation } from 'react-router-dom'

import './styles.css'
import { UserRole } from '../../lib/core/enums'
import { AppRoute } from '../../enums'
import { AppTabId, AppTabScope } from './enums'
import type { SignOutVariables } from '../../services/apollo/definitions'
import { useMutation } from '@apollo/client'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../services/apollo'
import AppTabs from './AppTabs'
import auth from '../../services/auth'
import capacitor from '../../services/capacitor'
import ConfirmSignOutAlert from '../ConfirmSignOutAlert'
import ControlCenter from './ControlCenter'
import coreHelpers from '../../lib/core/helpers'
import pageHelpers from '../../helpers/pageHelpers'

interface Props {
  scope: AppTabScope
  onClickAppTab?: (tabId: AppTabId) => void
  onNavigateTo?: (route: string, replace?: boolean, state?: any) => void
}

const AppPageFooter: React.FC<Props> = (props): JSX.Element | null => {
  const { onClickAppTab, onNavigateTo } = props
  let scope = props.scope || AppTabScope.UNAUTHENTICATED
  // const navigate = useNavigate()

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  const {
    activeUser,
    clearActiveUser,
    clearInbox,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const { start: startGiftFlow } = useGiftFlow()

  const [showControlCenter, setShowControlCenter] = useState(false)
  const [showConfirmSignOutAlert, setShowConfirmSignOutAlert] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [isKeyboardShown, setIsKeyboardShown] = useState(false)

  if (!scope) {
    if (
      location &&
      location.pathname &&
      location.pathname.startsWith('/admin')
    ) {
      scope = AppTabScope.ADMIN
    } else if (activeUser) {
      scope = AppTabScope.MAIN
    } else {
      scope = AppTabScope.UNAUTHENTICATED
    }
  }

  const userIsAdmin = (
    !!activeUser &&
    !!activeUser.roles &&
    coreHelpers.models.user.hasRole(activeUser.roles, UserRole.ADMIN)
  )

  useEffect((): void => {
    if (isPlatform('capacitor')) {
      Keyboard.addListener('keyboardWillShow', () => {
      // console.log('SendGiftPage: keyboard will show with height', info.keyboardHeight)
        setIsKeyboardShown(true)
      })

      Keyboard.addListener('keyboardDidHide', () => {
      // console.log('SendGiftPage: keyboard did hide')
        setIsKeyboardShown(false)
      })
    }
  }, [])

  // -------------------------------------------------------------------------------------------------------------------
  // Signing out:
  const [
    signOut,
    { client: apolloClient },
  ] = useMutation<undefined, SignOutVariables>(apollo.mutations.signOut, {
    variables: { userId: activeUserId as string },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (apolloClient) {
        apolloClient.clearStore()
      }
      auth.signOut(false, true, apolloClient)
      if (clearActiveUser) {
        clearActiveUser()
      }
      clearInbox()
      if (isPlatform('capacitor')) {
        capacitor.clearAppIconBadge()
      }
      setToastMessage('Successfully signed out of Mimble')
      setShowToast(true)
      if (onNavigateTo) {
        onNavigateTo(AppRoute.SIGN_IN, true)
      } else {
        navigate(AppRoute.SIGN_IN, true)
      }
    },
    onError: (error) => {
      console.error(error)
      setToastMessage('Failed to sign out. Please check your input and try again.')
      setShowToast(true)
    },
  })

  const onSignOut = (confirmed?: any): void => {
    if (confirmed !== true) {
      setShowConfirmSignOutAlert(true)
      return
    }
    setShowConfirmSignOutAlert(false)
    signOut().then(undefined, (error) => {
      console.error(error)
    })
  }

  // ===================================================================================================================
  // Event Handlers:
  const handleOnClickAppTab = (appTabId: AppTabId) => {
    if (appTabId === AppTabId.CONTROL_CENTER) {
      setShowControlCenter(!showControlCenter)
      return
    }
    if (onClickAppTab) {
      onClickAppTab(appTabId)
    }
  }

  const handleOnNavigateTo = (route: string, replace?: boolean, state?: any) => {
    if (showControlCenter) {
      setShowControlCenter(false)
    }
    if (onNavigateTo) {
      onNavigateTo(route, replace, state)
      return
    }
    if (route === AppRoute.SEND_GIFT) {
      startGiftFlow()
    }
    navigate(route)
  }

  const onCloseExpandedContent = () => {
    setShowControlCenter(false)
  }

  // ===================================================================================================================
  // Rendering:
  let appTabs
  if (
    scope &&
    scope !== AppTabScope.UNAUTHENTICATED &&
    activeUser
  ) {
    appTabs = (
      <div className={`toolbar ${isKeyboardShown ? 'hidden' : ''}`}>
        <AppTabs
          scope={scope}
          showControlCenter={showControlCenter}
          onClickAppTab={handleOnClickAppTab}
          onNavigateTo={handleOnNavigateTo}
        />
      </div>
    )
  }

  return (
    <>
      <ControlCenter
        show={showControlCenter}
        userIsAdmin={userIsAdmin}
        onNavigateTo={handleOnNavigateTo}
        onSignOut={onSignOut}
        onClose={onCloseExpandedContent}
      />
      <div className='app-page-footer'>
        {appTabs}
      </div>
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      <ConfirmSignOutAlert
        isOpen={showConfirmSignOutAlert}
        showNoPasswordRecoveryWarning={false}
        onDidDismiss={(): void => { setShowConfirmSignOutAlert(false) }}
        onCancel={(): void => { setShowConfirmSignOutAlert(false) }}
        onSignOut={(): void => { onSignOut(true) }}
      />
    </>
  )
}

export default AppPageFooter
