import type { WishesQueryVariables } from '../definitions'
import type { WishListFilter } from '../../../lib/core/definitions'
import getWishesOfActiveUserListFilter from './getWishesOfActiveUserListFilter'

const getWishesOfActiveUserQueryVariables = (
  listFilter: WishListFilter | undefined,
  activeUserId: string,
): WishesQueryVariables => ({
  filter: listFilter || getWishesOfActiveUserListFilter(activeUserId),
})

export default getWishesOfActiveUserQueryVariables
