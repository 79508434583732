import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { AppAction } from '../../lib/core/enums'
import { ErrorCode } from '../../lib/core/enums'
import type { MarkInAppMessageReceivedMutationData, MarkInAppMessageReceivedMutationVariables } from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const markInAppMessageReceived = (
  userId: string,
  memberMessageId: string,
  actionTaken: AppAction | undefined,
  apolloClient: ApolloClient<any>,
): Promise<void> => (
  new Promise((resolve, reject) => {
    // console.info('api.markInAppMessageReceived called.', { chatMessages })
    if (!userId || !memberMessageId
    ) {
      logger.error('api.markInAppMessageReceived: invalid input.', { userId, memberMessageId })
      resolve()
      return
    }

    if (!apolloClient) {
      logger.error('api.markInAppMessageReceived: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const options: MutationOptions<MarkInAppMessageReceivedMutationData, MarkInAppMessageReceivedMutationVariables> = {
      mutation: apollo.mutations.markInAppMessageReceived,
      variables: { memberMessageId, actionTaken },
    }

    apolloClient.mutate<MarkInAppMessageReceivedMutationData, MarkInAppMessageReceivedMutationVariables>(options)
      .then((resp) => {
        // console.log('api.markInAppMessageReceived: mutation succeeded.', resp)
        resolve()
      }, (error) => {
        logger.error('api.markInAppMessageReceived: error', { userId, memberMessageId, error })
        reject(error)
      })
  })
)

export default markInAppMessageReceived
