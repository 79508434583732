import { gql } from '@apollo/client'

export default gql`
  mutation M($userDevice: UserDeviceInput!) {
    upsertUserDevice (userDevice: $userDevice) {
      id
      userId
      deviceUuid
      phoneNumber
      phoneNumberUpdatedAt
      phoneNumberVerified
      brand
      model
      isTablet
      screenWidth
      screenHeight
      os
      osVersion
      timezone
      ipAddress
      locale
      countryCode
      appVersion
      signedInAt
      signedOutAt
      pushNotificationToken
      trusted
      trustedAt
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }
`
