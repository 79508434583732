import { UiMessage } from '../../enums'

const labelsEn = Object.freeze({
  [UiMessage.CANCEL_REQUEST_RECEIVED]: 'The sending of this gift was canceled.',
  [UiMessage.CHANGES_SAVED_SUCCESSFULLY]: 'The changes were saved successfully.',
  [UiMessage.CURRENT_PASSWORD_INCORRECT]: 'The current password is incorrect.',
  [UiMessage.DATA_DELETED_SUCCESSFULLY]: 'Deleted successfully.',
  [UiMessage.DATA_UPDATED_SUCCESSFULLY]: 'Saved successfully.',
  [UiMessage.EMAIL_UPDATED_SUCCESSFULLY]: 'Your email was updated successfully.',
  [UiMessage.ERROR_ACCEPTING_GIFT]: 'Failed to activate this gift card. Please contact support.',
  [UiMessage.ERROR_CONNECTING]: 'Error connecting',
  [UiMessage.ERROR_DATA_NOT_FOUND]: 'The requested information was not found.',
  [UiMessage.ERROR_LOCATING_REQUEST]: 'Failed to locate the request. Please start again.',
  [UiMessage.ERROR_PROCESSING_PAYMENT]: 'Error processing payment',
  [UiMessage.ERROR_PROCESSING_PURCHASE]: 'Error processing purchase. Please check your input and try again.',
  [UiMessage.ERROR_PROCESSING_REQUEST]: 'Error processing the request.',
  [UiMessage.ERROR_RECEIVED_GIFT_NOT_AVAILABLE]: 'This gift is not available.',
  [UiMessage.ERROR_SAVING_CHANGES]: 'Failed to save your changes. Please try again.',
  [UiMessage.ERROR_SENDING_GIFT_CARD]: 'Failed to send your gift card.',
  [UiMessage.ERROR_SENDING_NOTIFICATION]: 'Failed to send out the notification.',
  [UiMessage.ERROR_SIGNIN_TOKEN]: 'Wrong token entered. Please resend request and try again.',
  [UiMessage.ERROR_SIGNING_IN]: 'Failed to sign you in. Please check your input and try again.',
  [UiMessage.ERROR_SIGNING_OUT]: 'Error signing you out. Please try again.',
  [UiMessage.ERROR_UPDATING_PRODUCT]: 'Error updating product',
  [UiMessage.ERROR_UPLOADING_GIFT_CARD]: 'Failed to upload your gift card.',
  [UiMessage.FAILED_TO_SIGN_IN_THROUGH_TRANSFER]: 'Failed to sign you in.',
  [UiMessage.GIFT_CARD_NOT_FOUND]: 'Gift card not found.',
  [UiMessage.NEW_APP_VERSION_AVAILABLE]: 'A new app version is available. Reload the app to get the latest features.',
  [UiMessage.PASSWORD_UPDATED_SUCCESSFULLY]: 'Your password was updated successfully.',
  [UiMessage.PHONE_NUMBER_UPDATED_SUCCESSFULLY]: 'Your phone number was updated successfully.',
  [UiMessage.TRANSACTION_SAVED_SUCCESSFULLY]: 'Successfully created this transaction.',
  [UiMessage.TRANSFER_NOT_FOUND]: 'Failed to load this gift card transfer. Please verify you are using the correct link from the email.',
  [UiMessage.TRANSFER_NOT_VALID]: 'This gift card transfer is either invalid, or has expired. Please verify you are using the link from the email.',
  [UiMessage.UNKNOWN]: '',
  [UiMessage.USERNAME_UPDATED_SUCCESSFULLY]: 'Your handle was updated successfully.',
})

const uiMessageHelpers = {
  getLabel: (code: UiMessage | null | undefined, language = 'en'): string => {
    if (!code) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[code]
    }
    return ''
  },
  isValid: (method: UiMessage): boolean => Object.values(UiMessage).includes(method),
}

export default uiMessageHelpers
