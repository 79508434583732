import React, { useState } from 'react'
import { IonIcon, IonInput, IonSpinner, useIonViewWillLeave } from '@ionic/react'
import { useApolloClient } from '@apollo/client'
import { addCircleOutline, alertCircleOutline, informationCircleOutline, personOutline } from 'ionicons/icons'

import './styles.css'
import type { ContactUserListItem, FindUserFilter, UserIdentInfo } from '../../lib/core/definitions'
import type { ContextHelpId } from '../../contexts/AppHelpersContext/enums'
import { useAppHelpers } from '../../contexts/AppHelpersContext/AppHelpersContext'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../services/api'
import FormItem from '../FormItem/FormItem'
import modelHelpers from '../../lib/core/helpers/models'
import UserList from './UserList'

type Props = {
  selectedUser?: UserIdentInfo | ContactUserListItem | undefined
  searchInputPlaceholder?: string
  selectButtonLabel?: string
  findByIdentOnly?: boolean
  includeOrganizations?: boolean
  showContacts?: boolean
  contextHelpId?: ContextHelpId
  className?: string
  onSelectUser: (user: ContactUserListItem | UserIdentInfo | undefined) => void
  onSelectNonUser?: (inputName?: string) => void
}

const FindUserForm: React.FC<Props> = (props): JSX.Element | null => {
  const {
    selectedUser,
    className,
    findByIdentOnly,
    showContacts,
    includeOrganizations,
    selectButtonLabel,
    searchInputPlaceholder,
    contextHelpId,
    onSelectUser,
    onSelectNonUser,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { contacts } = useMimbleData()
  const { openContextHelpModal } = useAppHelpers()

  const [searchText, setSearchText] = useState<string | undefined>()
  const [isLoadingUserIdentInfo, setIsLoadingUserIdentInfo] = useState(false)
  const [foundUsers, setFoundUsers] = useState<UserIdentInfo[] | undefined>()

  // ===================================================================================================================
  // Helpers:
  const filteredContacts = Array.isArray(contacts) && contacts.length > 0
    ? modelHelpers.user.filterList(contacts, searchText, false, true)
    : contacts

  const findUser = (searchText: string): void => {
    if (!searchText || searchText.length < 1) {
      return
    }

    const filter: FindUserFilter = {
      excludeContacts: true,
    }
    if (findByIdentOnly) {
      filter.ident = searchText
    } else {
      filter.textSearch = searchText
    }

    if (!includeOrganizations) {
      filter.isOrganization = false
    }

    setIsLoadingUserIdentInfo(true)
    api.findUser(filter, true, apolloClient).then((newUserIdentInfos) => {
      setIsLoadingUserIdentInfo(false)
      setFoundUsers(newUserIdentInfos)
    }, (error) => {
      console.error(error)
      setIsLoadingUserIdentInfo(false)
    })
  }

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewWillLeave((): void => {
    setIsLoadingUserIdentInfo(false)
    setSearchText(undefined)
  })

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSearchText = (event: any): void => {
    setSearchText(event.detail.value)
    if (!event.detail.value) {
      setFoundUsers(undefined)
      return
    }
    if (event.detail.value.length > 1) {
      findUser(event.detail.value)
    }
  }

  const onInviteNewMember = (): void => {
    if (onSelectNonUser) {
      onSelectNonUser(searchText)
    }
  }

  const onShowContextHelp = () => {
    if (contextHelpId) {
      openContextHelpModal(contextHelpId)
    }
  }

  const onSubmit = () => {
    if (searchText && searchText.length > 0) {
      findUser(searchText)
    }
  }

  // ===================================================================================================================
  // Rendering:
  let results: JSX.Element | undefined
  const contactList = Array.isArray(filteredContacts) && filteredContacts.length > 0
    ? filteredContacts
    : undefined
  let list: (ContactUserListItem | UserIdentInfo)[] = []
  if (contactList && showContacts !== false) {
    list = contactList
  }
  if (Array.isArray(foundUsers) && foundUsers.length > 0) {
    list = list.concat(foundUsers)
  }

  if (isLoadingUserIdentInfo) {
    results = <IonSpinner />
  } else {
    if (!Array.isArray(list) || list.length < 1) {
      if (searchText) {
        results = (
          <div key='no-results' className='rowWithCenterAlignedItems' style={{ color: 'var(--ion-color-medium)' }}>
            <IonIcon
              icon={alertCircleOutline}
              className='withStandardRightMargin'
              style={{ height: '20px', width: '20px' }}
            />
            No members found
          </div>
        )
      }
    } else {
      results = (
        <UserList
          users={list}
          selectedUserId={selectedUser && selectedUser.id as string}
          onSelectUser={onSelectUser}
          selectButtonLabel={selectButtonLabel || 'Select'}
          isLoadingUsers={isLoadingUserIdentInfo}
        />
      )
    }
  }

  let belowResultSection: JSX.Element | undefined
  if (onSelectNonUser || contextHelpId) {
    let inviteNonMemberSection: JSX.Element | undefined
    if (onSelectNonUser) {
      inviteNonMemberSection = (
        <div onClick={onInviteNewMember} className='rowWithCenterAlignedItems withPointerCursor'>
          <IonIcon icon={addCircleOutline} color='primary' className='invite-icon' />
          <div className='invite-new-text withSmallLeftMargin'>
            {`Invite ${`${searchText || 'someone new'}`}\nto Mimble`}
          </div>
        </div>
      )
    }

    let helpSection: JSX.Element | undefined
    if (contextHelpId) {
      helpSection = (
        <div className='help-button' onClick={onShowContextHelp}>
          Help
          <IonIcon icon={informationCircleOutline} size='small' className='withSmallLeftMargin' />
        </div>
      )
    }

    belowResultSection = (
      <div className='below-list-row'>
        {inviteNonMemberSection}
        {helpSection}
      </div>
    )
  }

  const content = (
      <>
        <FormItem className='g-with-flex-1 withSmallBottomMargin'>
          <div className='rowWithCenterAlignedItems'>
            <IonIcon
              size='small'
              // slot='start' -- For some reason, this icon as a child of the input breaks the input value.
              color='medium'
              className='withStandardLeftMargin withStandardRightMargin'
              icon={personOutline}
            />
            <IonInput
              id='searchText'
              name='searchText'
              value={searchText}
              debounce={300}
              placeholder={searchInputPlaceholder}
              required
              onIonChange={onChangeSearchText}
              clearInput
              onSubmit={onSubmit}
             />
          </div>
        </FormItem>
        <div className='results' style={{ height: '60vh' }}>
          {results}
          {belowResultSection}
        </div>
      </>
  )

  return (
    <form className={`find-user-form ${className || ''}`} onSubmit={onSubmit}>
      {content}
    </form>
  )
}

FindUserForm.defaultProps = {
  searchInputPlaceholder: 'Search by name, phone, email',
  selectButtonLabel: 'Select',
}

export default FindUserForm
