import { gql } from '@apollo/client'

export default gql`
  mutation M($campaign: CampaignInput!) {
    upsertCampaign(campaign: $campaign) {
      id
      memberMessageTemplateId
      name
      campaignType
      status
      metadata
      recordStatus
      createdAt
      updatedAt
      updatedBy
    }
  }
`
