import type { ApolloClient, MutationOptions } from '@apollo/client'

import type {
  ApiQueryOptions,
  ProductOption,
  ProductOptionInput,
} from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertProductOptionMutationData,
  UpsertProductOptionMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import loadProductOption from './loadProductOption'
import logger from '../logger'

const upsertProductOption = (
  productOptionInput: ProductOptionInput,
  isInTargetStateFunc: ((productOption: ProductOption) => boolean) | 'watch-updated-at' | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ProductOption | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.upsertProductOption called.', { productOptionInput })
    if (!apolloClient) {
      logger.error('api.upsertProductOption: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertProductOptionMutationData, UpsertProductOptionMutationVariables> = {
      mutation: apollo.admin.mutations.upsertProductOption,
      variables: { productOption: productOptionInput },
    }

    apolloClient.mutate<UpsertProductOptionMutationData, UpsertProductOptionMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertProductOption: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertProductOption ||
          !resp.data.upsertProductOption.id
        ) {
          logger.error('api.upsertProductOption: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((productOption: ProductOption) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!productOptionInput.id) {
            logger.error('api.upsertProductOption: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertProductOption.updatedAt
            activeIsInTargetStateFunc = (productOption: ProductOption) => productOption.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertProductOption)
        ) {
          // const queryListVariables = clientOptions && clientOptions.updateList
          //   ? apollo.getProductOptionsQueryVariables(listFilter)
          //   : undefined
          // cache.updateProductOption(
          //   resp.data.upsertProductOption,
          //   queryListVariables,
          //   apolloClient,
          // )
          resolve(resp.data.upsertProductOption)
          return
        }

        const productOptionId = resp.data.upsertProductOption.id

        setTimeout(() => {
          // console.log('api.upsertProductOption: calling loadProductOption')
          loadProductOption(
            productOptionId,
            activeIsInTargetStateFunc,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((productOption) => {
              // console.log('api.upsertProductOption: resolving with productOption', productOption)
              resolve(productOption)
            }, (error) => {
              logger.error('api.upsertProductOption: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertProductOption: error', { error })
        reject(error)
      })
  })
)

export default upsertProductOption
