import type { ContactEvent } from '../../../definitions'
import { GiftingEventType } from '../../../enums'

const getLabel = (event: ContactEvent): string => {
  if (event.eventType === GiftingEventType.BIRTHDAY) {
    return 'Birthday'
  }
  return event.name || ''
}

export default getLabel
