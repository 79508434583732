import React, { useState } from 'react'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../../enums'
import type { UserListFilter } from '../../../lib/core/definitions'
import type { UsersQueryData, UsersQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import UserGrid from './UserGrid'
import UserListHeader from './UserListHeader'

const appPageId = AppPage.AdminUsersPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined
const DEFAULT_USERS_FILTER: UserListFilter = {
  limit: 2000,
}

const UsersPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [filter, setFilter] = useState<UserListFilter | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  const [
    loadUsers,
    {
      data: usersLoadedData,
      loading: isLoadingUsers,
    },
  ] = useLazyQuery<UsersQueryData, UsersQueryVariables>(
    apollo.admin.queries.users, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: { filter: filter || DEFAULT_USERS_FILTER },
      onCompleted: () => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.error(error)
        setToastMessage(error.message)
        setShowToast(true)
      },
    },
  )
  const users = usersLoadedData ? usersLoadedData.users : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingUsers

  // ===================================================================================================================
  // Event Handlers:
  const onApplyListFilter = (newFilter: UserListFilter): void => {
    // console.log('UsersPage.onApplyListFilter called.', newFilter)
    setFilter(newFilter)
    loadUsers({ variables: { filter: newFilter } })
  }

  const onGetUsers = (): void => {
    // sendGetUsersRequest()
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    refreshEvent = event
    // todo: have onGetUsers return a promise, then canel the refreshEvent
    onGetUsers()
    // onGetUsers().then(() => {
    //   if (refreshEvent) {
    //     refreshEvent.detail.complete()
    //     refreshEvent = undefined
    //   }
    // }, (error) => {
    //   console.error(error)
    // })
  }

  const onOpenUser = (userId: string): void => {
    api.loadUser(
      userId,
      undefined,
      undefined,
      apolloClient,
    ).then(() => {
      navigate(`${AppRoute.ADMIN_USER}/${userId}`)
    }, (error) => {
      console.error(error)
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  return (
    <IonPage className='app-page-admin users-page'>
      <NavBar
        title='Manage Members'
        goBackUri={AppRoute.ADMIN_HOME}
      />
      <IonContent className='g-content-with-padding' scrollY={false}>
        <div className='g-non-scroll-content-wrapper'>
          <PageMessages />
          <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <UserListHeader
            isProcessing={isLoadingUsers}
            onApply={onApplyListFilter}
          />
          <UserGrid
            users={users}
            isProcessing={isProcessing}
            onOpenUser={onOpenUser}
          />
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default UsersPage
