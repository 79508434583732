import type { AppFeature } from '../../../enums'

const hasAppFeature = (
  userAppFeatures: AppFeature | AppFeature[] | null | undefined,
  features: AppFeature | AppFeature[] | null | undefined,
): boolean => {
  if (!features) {
    return true
  }
  if (!userAppFeatures) {
    return false
  }
  if (Array.isArray(features)) {
    if (features.length < 1) {
      return true
    }
    for (let i = 0; i < features.length; i += 1) {
      if (Array.isArray(userAppFeatures)) {
        if (userAppFeatures.includes(features[i])) {
          return true
        }
      } else {
        const reg = new RegExp(`\\b${features[i]}\\b`)
        if (userAppFeatures.match(reg)) {
          return true
        }
      }
    }
    return false
  }
  if (Array.isArray(userAppFeatures)) {
    return userAppFeatures.includes(features)
  }
  const reg = new RegExp(`\\b${features}\\b`)
  return !!userAppFeatures.match(reg)
}

export default hasAppFeature
