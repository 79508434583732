import type { ApolloClient } from '@apollo/client'
import type {
  ApiQueryOptions,
  Contact,
  ContactInput,
  ContactUserListFilter,
} from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertContactMutationData,
  UpsertContactMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadContact from './loadContact'
import logger from '../logger'

const upsertContact = (
  contactInput: ContactInput,
  isInTargetStateFunc: ((contact: Contact) => boolean) | 'watch-updated-at' | undefined,
  listFilter: ContactUserListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Contact | undefined> => (
  // console.log('api.upsertContact called.', { contactInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertContact: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    apolloClient.mutate<UpsertContactMutationData, UpsertContactMutationVariables>({
      mutation: apollo.mutations.upsertContact,
      variables: { contact: contactInput },
    })
      .then((resp) => {
        // console.log('api.upsertContact: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertContact ||
          !resp.data.upsertContact.id
        ) {
          logger.error('api.upsertContact: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((contact: Contact) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!contactInput.id) {
            logger.error('api.upsertContact: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertContact.updatedAt
            activeIsInTargetStateFunc = (contact: Contact) => contact.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertContact)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getContactUsersQueryVariables(listFilter, activeUserId)
            : undefined
          cache.updateContact(
            resp.data.upsertContact,
            queryListVariables,
            activeUserId,
            apolloClient,
          )
          resolve(resp.data.upsertContact)
          return
        }

        const contactId = resp.data.upsertContact.id

        setTimeout(() => {
          // console.log('api.upsertContact: calling loadContact')
          loadContact(
            contactId,
            undefined,
            undefined,
            activeIsInTargetStateFunc,
            listFilter,
            activeUserId,
            apolloClient,
            true,
            queryOptions,
            clientOptions,
          )
            .then((contact) => {
              // console.log('api.upsertContact: resolving with contact', contact)
              resolve(contact)
            }, (error) => {
              logger.error('api.upsertContact: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertContact: error', { error })
        reject(error)
      })
  })
)

export default upsertContact
