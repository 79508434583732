import type { ProductListFilter } from '../../../definitions'

const isEmptyProductListFilter = (filter: ProductListFilter): boolean => {
  if (!filter) {
    return true
  }

  // todo compare: tagIds, productTypes, vendorRecordStates
  return (
    !filter.searchText &&
    !filter.merchantId &&
    !filter.createdAtFrom &&
    !filter.createdAtUntil &&
    !filter.updatedAtFrom &&
    !filter.updatedAtUntil &&
    (filter.showOnlyMainProducts !== true && filter.showOnlyMainProducts !== false) &&
    (filter.showOnlyInStock !== true && filter.showOnlyInStock !== false) &&
    (filter.showOnlyListed !== true && filter.showOnlyListed !== false) &&
    (filter.showOnlyVendorListed !== true && filter.showOnlyVendorListed !== false) &&
    (!Array.isArray(filter.tagIds) || filter.tagIds.length < 1) &&
    (!Array.isArray(filter.tagNames) || filter.tagNames.length < 1) &&
    (!Array.isArray(filter.excludeTagIds) || filter.excludeTagIds.length < 1) &&
    (!Array.isArray(filter.excludeTagNames) || filter.excludeTagNames.length < 1) &&
    (!Array.isArray(filter.productTypes) || filter.productTypes.length < 1) &&
    (!Array.isArray(filter.recordStatuses) || filter.recordStatuses.length < 1) &&
    (!Array.isArray(filter.vendorRecordStates) || filter.vendorRecordStates.length < 1) &&

    // todo legacy
    !filter.vendorId
  )
}

export default isEmptyProductListFilter
