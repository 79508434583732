import { isPlatform } from '@ionic/react'
import { Share } from '@capacitor/share'
import logger from '../../../services/logger'

export default {
  shareUsingCapacitorPlugIn: (inviteLink: string, showUiMessage: (message: string) => void): void => {
    if (!isPlatform('capacitor') || !inviteLink) {
      if (showUiMessage) {
        showUiMessage('Failed to launch social share. Please copy your link and share manually.')
      }
      return
    }
    Share.share({
      title: 'Invitation to Mimble',
      text: 'Please accept my invitation to join me on Mimble - where gifting is fun, fast and easy!',
      url: `${inviteLink}`,
      dialogTitle: 'Share with friends',
    }).then(() => {
      console.log('SendByMemberContent.handleShareButtonClick: share was successful.')
    }, (error: Error) => {
      logger.error('SendByMemberContent.handleShareButtonClick: share failed with error.', { error })
      showUiMessage('Failed to launch social share. Please copy your link and share manually.')
    })
  },

  shareUsingEmail: (inviteLink: string, inviteCode: string, showUiMessage: (message: string) => void): void => {
    // <a href="mailto:email@mycompany.com?subject=Subscribe&body=Lastame%20%3A%0D%0AFirstname%20%3A"><img alt="Subscribe" class="center" height="50" src="subscribe.png" style="width: 137px; height: 50px; color: #4da6f7; font-size: 20px; display: block;" width="137"></a>
    const body = `I like to find and send gifts on Mimble. It's free, so you can try it out, too!
With my invitation you earn double the sign on reward, and I get a reward as well.

Click on this link:

       ${inviteLink}

Or enter this code when you join: ${inviteCode}.

See you on Mimble!`
      .replace(/ /g, '%20')
      .replace(/\n/g, '%0D%0A')
    window.open(`mailto:?subject=Join%20Mimble!&body=${body}`, '_system')
    // window.location.href = `mailto:?subject=Join&nbsp;Mimble!`
  },

  shareUsingSms: (inviteLink: string, inviteCode: string, showUiMessage: (message: string) => void): void => {
    const body =
      `Join me on Mimble, a powerful social gifting platform! Get double the sign up reward when you use my code!
Follow this link:
${inviteLink}
Or enter my code: ${inviteCode}`
        .replace(/ /g, '%20')
        .replace(/\n/g, '%0D%0A')
    window.open(`sms:&body=${body}`)
  },
}
