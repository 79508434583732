import React from 'react'

import type { Purchase } from '../../../lib/core/definitions'
import TransactionInfo from '../../../components/TransactionInfo/TransactionInfo'
import OrderObjectInfo from '../../../components/objectInfo/OrderObjectInfo/OrderObjectInfo'
import TransactionObjectInfo from '../../../components/objectInfo/TransactionObjectInfo/TransactionObjectInfo'
import { PurchaseType } from '../../../lib/core/enums'
import MimbleTokenPurchaseObjectInfo
  from '../../../components/objectInfo/MimbleTokenPurchaseObjectInfo/MimbleTokenPurchaseObjectInfo'
import GiftCardObjectInfo from '../../../components/objectInfo/GiftCardObjectInfo/GiftCardObjectInfo'

type Props = {
  purchase: Purchase
  onOpenOrder: (orderId: string) => void
  onOpenTransaction: (transactionId: string) => void
};

const OrderInfoSection: React.FC<Props> = ({
  purchase,
  onOpenOrder,
  onOpenTransaction,
}: Props): JSX.Element | null => {
  if (!purchase || !purchase.order) {
    return null
  }

  const sections: JSX.Element[] = []

  sections.push(
    <OrderObjectInfo
      key='order'
      order={purchase.order}
      onOpenOrder={onOpenOrder}
    />,
  )

  // if (purchase.order.paymentTransaction) {
  //   sections.push(
  //     <TransactionObjectInfo
  //       key='trans'
  //       transaction={purchase.order.paymentTransaction}
  //       onOpenTransaction={onOpenTransaction}
  //     />,
  //   )
  // }
  //
  // const paymentTransaction = purchase && purchase.order && purchase.order.paymentTransaction
  // if (!paymentTransaction) {
  //   return null
  // }

  return (
    <div className='withStandardPadding'>
      {sections}
    </div>
  )
}

export default OrderInfoSection
