import React, { useState } from 'react'

import './styles.css'
import type { RecordStatus } from '../../lib/core/enums'
import type { Props as OverlayProps } from './Overlay'
import { PurchaseStatus } from '../../lib/core/enums'
import FullBleedBackground from './FullBleedBackground'
import MerchantLogoBackground from './MerchantLogoBackground'
import Overlay from './Overlay'

export type GiftCardProps = {
  purchaseId?: string;
  purchaseStatus?: PurchaseStatus;
  recordStatus?: RecordStatus;
  backgroundImageUri?: string;
  merchantLogoUri?: string;
  small?: boolean;
  className?: string;
  onEditPurchase?: (purchaseId: string) => void;
  onArchivePurchase?: (purchaseId: string) => void;
  onAcceptGift?: (purchaseId: string) => void;
  onUnArchivePurchase?: (purchaseId: string) => void;
  onDeletePurchase?: (purchaseId: string) => void;
  onDownloadPurchase?: (purchaseId: string) => void;
  onTransferPurchase?: (purchaseId: string) => void;
  onVisitMerchant?: () => void;
}

const GiftCard: React.FC<GiftCardProps> = (props): JSX.Element => {
  // console.log('GiftCard.render called.', props)
  const {
    purchaseId,
    purchaseStatus,
    recordStatus,
    backgroundImageUri,
    merchantLogoUri,
    small,
    className,
    onEditPurchase,
    onDeletePurchase,
    onDownloadPurchase,
    onAcceptGift,
    onArchivePurchase,
    onUnArchivePurchase,
    onTransferPurchase,
    onVisitMerchant,
  } = props

  const [showOverlay, setShowOverlay] = useState(purchaseStatus === PurchaseStatus.RECEIVED)

  const onCloseOverlay = () => {
    setShowOverlay(false)
  }

  const onToggleOverlay = () => {
    if (!showOverlay || purchaseStatus !== PurchaseStatus.RECEIVED) {
      setShowOverlay(!showOverlay)
    }
  }

  if (
    !onEditPurchase &&
    !onDeletePurchase &&
    !onDownloadPurchase &&
    !onAcceptGift &&
    !onArchivePurchase &&
    !onUnArchivePurchase &&
    !onTransferPurchase &&
    !onVisitMerchant
  ) {
    if (backgroundImageUri || !merchantLogoUri) {
      return <FullBleedBackground imageUri={backgroundImageUri} small={small} />
    }
    return (
      <MerchantLogoBackground
        logoUri={merchantLogoUri}
        small={small}
      />
    )
  }

  const overlayProps: OverlayProps = {
    purchaseId,
    purchaseStatus,
    recordStatus,
    show: showOverlay,
    // onClose: onCloseOverlay,
    onAcceptGift,
    // onEditPurchase,
    // onArchivePurchase,
    // onUnArchivePurchase,
    // onTransferPurchase,
    // onVisitMerchant,
  }

  if (backgroundImageUri || !merchantLogoUri) {
    return (
      <div className={`gift-card ${className || ''}`}>
        <FullBleedBackground imageUri={backgroundImageUri} small={small} />
        <Overlay {...overlayProps} />
      </div>
    )
  }

  return (
    <div className={`gift-card ${className || ''}`}>
      <MerchantLogoBackground
        logoUri={merchantLogoUri}
        small={small}
      />
      <Overlay {...overlayProps} />
    </div>
  )
}

export default GiftCard
