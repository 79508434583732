import React, { useContext, useState } from 'react'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonPage,
  IonToast,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'
// import { Update } from 'history'

import './styles.css'
import type { AppFeature } from '../../lib/core/enums'
// import { UiMessage } from '../../lib/core/enums'
import { AppPage, AppRoute } from '../../enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import faq from './faq'
import FaqItem from './FaqItem'
import getAppFeaturesAsArray from '../../lib/core/helpers/models/user/getAppFeaturesAsArray'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'

const appPageId = AppPage.HelpPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const HelpPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { activeUser, isLoadingActiveUser } = useMimbleData()

  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [showToast, setShowToast] = useState(false)

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser

  // ===================================================================================================================
  // Event Handlers:
  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenContactsPage = (): void => {
    navigate(AppRoute.CONTACTS)
  }

  const onNavigateTo = (targetRoute: string): void => {
    navigate(targetRoute)
  }

  let userAppFeatures: AppFeature[] | undefined
  if (activeUser && activeUser.appFeatures) {
    userAppFeatures = Array.isArray(activeUser.appFeatures)
      ? activeUser.appFeatures
      : getAppFeaturesAsArray(activeUser.appFeatures)
  }

  // ===================================================================================================================
  // Rendering:
  const sections: JSX.Element[] = []

  const renderedFaqList = faq.map((item, index) => (
    <FaqItem
      key={`faq-${index}`}
      item={item}
      activeUserAppFeatures={userAppFeatures}
      onNavigateTo={onNavigateTo}
    />
  ))
  sections.push(
    <IonCard key='faq'>
      <IonCardHeader>
        <IonCardTitle>Frequently Asked Questions</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className='g-ion-card-content'>
        {renderedFaqList}
      </IonCardContent>
    </IonCard>,
  )

  sections.push(
    <IonCard key='contact'>
      <IonCardHeader>
        <IonCardTitle>Contact Support</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className='g-ion-card-content'>
        To contact support, <span className='linkText' onClick={onOpenContactsPage}>open the Contacts Page</span>, and
        find the &quot;Mimble Team&quot; chat. Here you can contact our team directly.
      </IonCardContent>
    </IonCard>,
  )

  return (
    <IonPage>
      <NavBar
        title='Mimble Help'
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent className='g-content-with-safe-padding'>
        <PageMessages />
        {sections}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default HelpPage
