import React, { useRef } from 'react'
import {
  IonItem,
  IonButton,
  IonIcon,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonBadge,
} from '@ionic/react'
import { chevronForwardOutline, trashOutline } from 'ionicons/icons'

import './styles.css'
import { useMimbleData } from '../../../../contexts/MimbleDataContext/MimbleDataContext'

type Props = {
  onOpenSupportChat: () => void;
}

const NotificationsItem: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('SystemMessagesItem.render called.', { props })
  const { onOpenSupportChat } = props

  // ===================================================================================================================
  // State:
  const { chats } = useMimbleData()
  const slidingRef = useRef<HTMLIonItemSlidingElement | null>(null)

  // ===================================================================================================================
  // Helpers:
  if (!Array.isArray(chats) || chats.length < 1) {
    return null
  }
  const supportChat = chats.find(chat => chat.fromUser && chat.fromUser.username === 'mimble')
  if (
    !supportChat ||
    !supportChat.metadata ||
    !supportChat.metadata.fromUser ||
    !supportChat.metadata.fromUser.imageUrl ||
    !supportChat.metadata.toUser ||
    (supportChat.metadata.toUser.unreadMessageCount < 1 && supportChat.metadata.toUser.unreadSystemMessageCount < 1)) {
    return null
  }
  const badge = (supportChat.metadata.toUser.unreadMessageCount || 0) +
    (supportChat.metadata.toUser.unreadSystemMessageCount || 0)

  if (!badge || badge < 1) {
    return null
  }

  // ===================================================================================================================
  // Event Handlers:
  const onHideItem = (event: any) => {
    event.preventDefault()
    if (slidingRef.current) {
      slidingRef.current.close()
    }
  }

  // ===================================================================================================================
  // Rendering:
  const avatarUser = {
    fullName: 'Mimble',
    imageUrl: supportChat.metadata.fromUser.imageUrl,
  }

  return (
    <IonItemSliding ref={slidingRef}>
      <IonItem className='notifications-item' onClick={onOpenSupportChat}>
        {/* <Avatar */}
        {/*   user={avatarUser} */}
        {/*   size={32} */}
        {/*   badge={badge} */}
        {/*   padForBadge */}
        {/*   className='withSmallRightMargin' */}
        {/*   onClick={onOpenSupportChat} */}
        {/* /> */}
        <div className='notification-text'>
          New notifications
          <IonBadge color='success' className='withSmallLeftMargin'>{badge}</IonBadge>
        </div>
        <IonButton
          fill='clear'
          color='medium'
          onClick={onOpenSupportChat}
        >
          <IonIcon icon={chevronForwardOutline} slot='start' />
        </IonButton>
      </IonItem>
      <IonItemOptions onIonSwipe={onHideItem}>
        <IonItemOption type='button' color='danger' expandable onClick={onHideItem}>
          <IonIcon icon={trashOutline} color='light' slot='top' />
          Hide
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  )
}

export default NotificationsItem
