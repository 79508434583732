import { gql } from '@apollo/client'

export default gql`
  query Q($filter: MemberMessageTemplateListFilter!) {
    memberMessageTemplates (filter: $filter) {
      id
      name
      isCore
      metadata
      recordStatus
    }
  }`
