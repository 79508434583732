import { gql } from '@apollo/client'

export default gql`
  query Q($memberMessageTemplateId: String!) {
    memberMessageTemplate (memberMessageTemplateId: $memberMessageTemplateId) {
      id
      name
      description
      titleEn
      messageTextEn
      shortMessageTextEn
      htmlMessageEn
      action0
      action1
      action2

      sendChatMessage
      sendEmail
      sendInAppMessage
      sendPushNotification
      sendSms

      isCore
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
