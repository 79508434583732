import {
  AsyncTaskCommandType,
  AsyncTaskSendNotificationResult,
  AsyncTaskType,
  NotificationMethod,
  TfaStatus,
} from '../../../../../lib/core/enums'
import type { AsyncTaskInput } from '../../../../../lib/core/definitions'
import type { OnSendTfaNotificationResult, SendTfaNotificationArgs } from '../definitions'
import api from '../../../../../services/api'
import coreHelpers from '../../../../../lib/core/helpers'

const sendTfaNotification = (args: SendTfaNotificationArgs): Promise<OnSendTfaNotificationResult> => {
  const {
    value,
    attribute,
    activeUserId,
    notificationMethod,
    apolloClient,
  } = args

  let tfaTaskId: string | undefined
  return new Promise((resolve) => {
    const taskInput: AsyncTaskInput = {
      userId: activeUserId,
      command: AsyncTaskCommandType.SEND_NOTIFICATION,
    }
    if (attribute === 'email') {
      taskInput.taskType = AsyncTaskType.UPDATE_EMAIL
      taskInput.email = value
      taskInput.notificationMethod = NotificationMethod.EMAIL
    } else if (attribute === 'phoneNumber') {
      taskInput.taskType = AsyncTaskType.UPDATE_PHONE_NUMBER
      taskInput.phoneNumber = value
      taskInput.notificationMethod = NotificationMethod.SMS
    } else if (attribute === 'password') {
      taskInput.taskType = AsyncTaskType.RESET_PASSWORD
      taskInput.notificationMethod = notificationMethod || NotificationMethod.SMS
      taskInput.metadata = {
        password: value,
      }
    }
    api.upsertAsyncTask(
      taskInput,
      true,
      coreHelpers.models.asyncTask.isNewTaskSendingNotificationCompleted,
      apolloClient,
    )
      .then((asyncTask) => {
        console.log('TfaFieldForm.onCreateAsyncTask: response asyncTask=', { asyncTask })
        if (asyncTask && asyncTask.id) {
          tfaTaskId = asyncTask.id
          const response: OnSendTfaNotificationResult = {
            ok: true,
            tfaTaskId,
            tfaStatus: TfaStatus.MESSAGE_SENT,
          }
          if (asyncTask && asyncTask.notificationResult === AsyncTaskSendNotificationResult.OK) {
            response.tfaStatus = TfaStatus.MESSAGE_SENT
          } else {
            response.tfaStatus = TfaStatus.MESSAGE_FAILED_TO_SEND
            if (attribute === 'email' || notificationMethod === NotificationMethod.EMAIL) {
              response.errorMessage = 'Failed to send verification email'
            } else if (attribute === 'phoneNumber' || notificationMethod === NotificationMethod.SMS) {
              response.errorMessage = 'Failed to send verification text message'
            }
          }
          resolve(response)
        }
      }, (error) => {
        console.error(error)
        resolve({
          ok: false,
          tfaTaskId,
          tfaStatus: TfaStatus.MESSAGE_FAILED_TO_SEND,
        })
      })
  })
}

export default sendTfaNotification
