import type { InvitationsQueryVariables } from '../definitions'
import type { InvitationListFilter } from '../../../lib/core/definitions'
import getInvitationListFilter from './getInvitationListFilter'

const getInvitationsQueryFilter = (
  listFilter: InvitationListFilter | undefined,
  activeUserId: string,
): InvitationsQueryVariables => ({
  filter: listFilter || getInvitationListFilter(activeUserId),
})

export default getInvitationsQueryFilter
