import type { EnvironmentVal } from '../../lib/core/definitions'
import api from '../../services/api'
import apollo from '../../services/apollo'
import appLinks from '../../lib/core/appLinks'
import appVersion from '../../version'
import cdn from '../../services/cdn'
import env from '../../lib/env'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import logger from '../../services/logger'

const initServices = (environment: EnvironmentVal): void => {
  // console.log('App.initServices called.')
  appLinks.setEnvironment(environment)

  logger.init()

  const graphQlEndpoint = env('MIMBLE_GRAPHQL_ENDPOINT', environment) as string
  const apolloGraphQlEndpoint = env('MIMBLE_APOLLO_ENDPOINT', environment) as string
  const apolloSubscriptionEndpoint = env('MIMBLE_APOLLO_WS_ENDPOINT', environment) as string
  const hmacKey = env('HMAC_KEY', environment) as string
  const hmacSecret = env('HMAC_SECRET', environment) as string

  // console.log('Back-end config loaded', {
  //   environment: globalCache.getEnvironment(),
  //   graphQlEndpoint,
  //   openGraphQlEndpoint,
  //   webSocketEndpoint,
  //   apolloGraphQlEndpoint,
  //   apolloSubscriptionEndpoint,
  //   hmacKey,
  //   hmacSecret,
  // })

  if (
    !apolloGraphQlEndpoint ||
    !graphQlEndpoint ||
    !hmacKey ||
    !hmacSecret
  ) {
    console.error('Missing back-end config', {
      environment,
      // env: process.env,
    })
  }

  apollo.init(
    {
      deviceId: globalCacheData.getDeviceId(),
      graphQlEndpoint: apolloGraphQlEndpoint,
      subscriptionEndpoint: apolloSubscriptionEndpoint,
      hmacKey,
      hmacSecret,
      appVersion,
    },
    environment,
  )

  api.init()

  // CDN:
  const cdnRoot = env('CDN_ROOT_URI', environment) as string
  const cdnS3Root = env('CDN_S3_ROOT_URI', environment) as string
  cdn.init({ cdnRoot, cdnS3Root })

  // console.log('App.initServices: done.')
}

export default initServices
