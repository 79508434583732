import moment from 'moment'
import type { ContactEventListEntry } from '../../../definitions'
import parseEventDate from './parseEventDate'

// todo: parseEventDate should take the timezone to calculate the correct diff
const compareListItemsByEvent = (entry1: ContactEventListEntry, entry2: ContactEventListEntry): number => {
  if (!entry1.event && !entry2.event) {
    return 0
  }

  if (!entry1.event) {
    return -1
  }

  if (!entry2.event) {
    return 1
  }

  const parsedDate1 = entry1.event.parsedDate || parseEventDate(entry1.event.eventDate)
  const parsedDate2 = entry2.event.parsedDate || parseEventDate(entry2.event.eventDate)

  if (!parsedDate1 && !parsedDate2) {
    return 0
  }

  if (!parsedDate1) {
    return -1
  }

  if (!parsedDate2) {
    return 1
  }

  if (!parsedDate1.nextDate && !parsedDate2.nextDate) {
    return 0
  }

  if (!parsedDate1.nextDate) {
    return -1
  }

  if (!parsedDate2.nextDate) {
    return 1
  }

  return moment(parsedDate1.nextDate).isBefore(parsedDate2.nextDate)
    ? -1
    : 1
}

export default compareListItemsByEvent
