import Moment from 'moment-timezone'
import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'
import type {
  GridColumns,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import {
  DataGridPro,
} from '@mui/x-data-grid-pro'

import type { StoredProductListFilter, StoredProductListItem } from '../../../lib/core/definitions'
import { FiatCurrency, FundType, StoredProductListScope, StoredProductStatus } from '../../../lib/core/enums'
import coreHelpers from '../../../lib/core/helpers'

type Props = {
  storedProducts: StoredProductListItem[] | null | undefined
  filter: StoredProductListFilter | undefined
  scope: StoredProductListScope
  isProcessing?: boolean
  onOpenStoredProduct: (storedProductId: string) => void
}

const StoredProductGrid: React.FC<Props> = (props): JSX.Element | null => {
  const {
    storedProducts,
    filter,
    scope,
    isProcessing,
    onOpenStoredProduct,
  } = props

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (rowData: GridRowParams): void => {
    onOpenStoredProduct(rowData.row.id as string)
  }

  const onExport = (): void => {
    if (!Array.isArray(storedProducts) || storedProducts.length < 1) {
      return
    }
    const data = coreHelpers.models.storedProduct.exportData(storedProducts, filter)
    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
    link.target = '_blank'
    link.download = 'members.csv'
    link.click()
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(storedProducts) || storedProducts.length < 1) {
    return null
  }
  // see https://material-ui.com/components/data-grid/rendering/#components

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', flex: 1.8 },
    { field: 'merchantName', headerName: 'Brand', flex: 1.5 },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'right',
      flex: 0.6,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value || params.value === 0) {
          return coreHelpers.ui.formatAmount(
            params.value as string,
            FundType.FIAT,
            FiatCurrency.USD,
            false,
            0,
          )
        }
        return '-'
      },
    },
    { field: 'discount', headerName: 'Discount', align: 'right', flex: 0.6 },
    { field: 'code', headerName: 'Code', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 0.6 },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 0.6,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? Moment(Number(params.value)).format('YYYY-MM-DD') : '-'
      ),
      sortComparator: (v1, v2) => (
        Number(v2) - Number(v1)
      ),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      flex: 0.6,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? Moment(Number(params.value)).format('YYYY-MM-DD') : '-'
      ),
      sortComparator: (v1, v2) => (
        Number(v2) - Number(v1)
      ),
    },
  ]
  const rows: GridRowsProp = storedProducts
    .filter((storedProduct) => {
      if (filter && filter.searchText) {
        const reg = new RegExp(filter.searchText, 'i')
        if (!storedProduct.merchantName || !storedProduct.merchantName.match(reg)) {
          return false
        }
      }
      switch (scope) {
        case StoredProductListScope.CREATED:
          return storedProduct.status === StoredProductStatus.CREATED
        case StoredProductListScope.AVAILABLE:
          return storedProduct.status === StoredProductStatus.AVAILABLE
        case StoredProductListScope.RESERVED:
          return storedProduct.status === StoredProductStatus.RESERVED
        case StoredProductListScope.DELIVERED:
          return storedProduct.status === StoredProductStatus.DELIVERED
      }
      return false
    })
    .map((storedProduct): GridRowModel => {
      return {
        id: storedProduct.id,
        merchantName: storedProduct.merchantName,
        amount: storedProduct.amount,
        discount: coreHelpers.ui.formatPercentAmount(storedProduct.discount),
        code: storedProduct.code,
        status: storedProduct.status,
        createdAt: storedProduct.createdAt,
        updatedAt: storedProduct.updatedAt,
      }
    })

  return (
    <div className='x-grid-data-view'>
      <div className='actions-row'>
        <IonButton
          size='small'
          fill='clear'
          color='medium'
          disabled={!Array.isArray(storedProducts) || storedProducts.length < 1 || isProcessing}
          onClick={onExport}
        >
          <IonIcon icon={cloudDownloadOutline} />
        </IonButton>
      </div>
      <div className='x-grid-wrapper'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  )
}

export default StoredProductGrid
