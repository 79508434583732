import type { ShoppingCart } from '../../../definitions'
import logger from '../../../logger'

const isPaymentRequestCreated = (shoppingCart: ShoppingCart): boolean => {
  // logger.debug('lib.core.helpers.models.shoppingCart.isPaymentRequestCreated called.', { shoppingCart })
  if (!shoppingCart) {
    logger.warn('lib.core.helpers.models.shoppingCart.isPaymentRequestCreated: missing shoppingCart.')
    return false
  }
  return !!shoppingCart.paymentRemoteId
}

export default isPaymentRequestCreated
