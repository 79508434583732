import type { ShoppingCartItem } from '../../../definitions'
import compareId from '../compareId'

const getAvailableInventory = (item: ShoppingCartItem): number | undefined => {
  if (!item || !item.productOptionId) {
    return
  }
  if (
    !item.product ||
    !Array.isArray(item.product.productOptions) ||
    item.product.productOptions.length < 1
  ) {
    // The product or the product option have been removed since adding to the shopping cart.
    return 0
  }
  const productOption = item.product.productOptions.find(po => compareId(po.id, item.productOptionId))
  if (!productOption) {
    // The product option is no longer available.
    return 0
  }
  return productOption.inventory
}

export default getAvailableInventory
