const matchesSearchText = (
  text: string,
  searchText: string,
  maxNumberOfCharsToMatchStartOfText = 2,
  searchStartOfWords = true,
  sensitivity = 'accent', // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator
): boolean => {
  if (!searchText || !text) {
    return true
  }
  if (
    maxNumberOfCharsToMatchStartOfText &&
    searchText.length <= maxNumberOfCharsToMatchStartOfText
  ) {
    if (searchStartOfWords && text.includes(' ')) {
      const parts = text.split(' ')
      return !!parts.find(namePart => (
        namePart
          .substr(0, searchText.length)
          .localeCompare(
            searchText,
            undefined,
            { sensitivity },
          ) === 0
      ))
    }
    return text
      .substr(0, searchText.length)
      .localeCompare(
        searchText,
        undefined,
        { sensitivity },
      ) === 0
  }
  try {
    // todo escape characters that mess up regex
    return text.search(
      new RegExp(searchText, sensitivity === 'accent' ? 'i' : ''),
    ) !== -1
  } catch (error) {
    return false
  }
}

export default matchesSearchText
