import React from 'react'
import './styles.css'

type Props = {
  label?: string;
  validationError?: string;
  successMessage?: string;
  children: JSX.Element;
  withBottomMargin?: boolean;
  className?: string;
}
const FormItem: React.FC<Props> = (props): JSX.Element => {
  const {
    children,
    label,
    successMessage,
    withBottomMargin,
    className,
    validationError,
  } = props
  // console.log('FormItem.render called.', { validationError });

  let successMessageElement
  if (successMessage) {
    successMessageElement = (
      <div className='inputWrapperSuccessMessage'>{successMessage}</div>
    )
  }

  let errorElement
  if (validationError) {
    errorElement = (
      <div className='inputWrapperErrorMessage'>{validationError}</div>
    )
  }

  let itemClass = withBottomMargin ? 'formItem formItemWithBottomMargin' : 'formItem'
  if (className) {
    itemClass = `${itemClass} ${className || ''}`
  }
  return (
    <div className={itemClass}>
      <div className='formLabel'>{label}</div>
      <div className={validationError ? 'inputWrapperWithError' : 'inputWrapperWithNoError'}>
        {children}
      </div>
      {successMessageElement}
      {errorElement}
    </div>
  )
}

export default FormItem
