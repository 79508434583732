import React, { useState } from 'react'
import { IonButton, IonImg } from '@ionic/react'
import { checkmark, close } from 'ionicons/icons'

import './styles.css'
import { AnimationName } from '../../../../lib/core/enums'
import { useAppHelpers } from '../../../../contexts/AppHelpersContext/AppHelpersContext'
import FormItem from '../../../FormItem/FormItem'
import IconButton from '../../../IconButton/IconButton'

type Props = {
  onClose: () => void
  onSubmit: (animation: AnimationName) => void
}

const Animations: React.FC<Props> = (props): JSX.Element | null => {
  const { onClose, onSubmit } = props

  const { showFullPageAnimation } = useAppHelpers()
  const [selectedAnimation, setSelectedAnimation] = useState<AnimationName | undefined>()

  const onPreview = (): void => {
    if (!selectedAnimation) {
      return
    }
    showFullPageAnimation(selectedAnimation)
  }

  const handleSelect = (): void => {
    if (!selectedAnimation) {
      return
    }
    onSubmit(selectedAnimation)
  }

  const animations = Object.values(AnimationName).map<JSX.Element>((animationName) => {
    const onSelectAnimation = (): void => {
      setSelectedAnimation(animationName)
    }

    const thumbnailClassName = animationName === selectedAnimation ? 'lottie selected' : 'lottie'

    return (
      <div key={animationName} className='lottie-container' onClick={onSelectAnimation}>
        <IonImg
          className={`withSmallLeftMargin ${thumbnailClassName}`}
          src={`${process.env.PUBLIC_URL}/assets/images/animation-thumbnails/${animationName}.png`}
        />
      </div>
    )
  })

  return (
    <FormItem className='withSmallPadding'>
      <>
        <div className='lightText rowWithSpaceBetween withSmallPadding'>
          Select Animation
          <div>
            <IonButton
              className='withSmallRightMargin'
              size='small'
              fill='clear'
              onClick={onPreview} disabled={!selectedAnimation}
              >
              Preview
            </IonButton>
            <IconButton
              key='select'
              icon={checkmark}
              layout='vertical'
              iconColor={selectedAnimation ? 'primary' : ''}
              className='button'
              iconSize={20}
              onClick={handleSelect}
            />
            <IconButton
              key='close'
              icon={close}
              layout='vertical'
              className='button'
              iconSize={20}
              onClick={onClose}
            />
          </div>
        </div>
        <div className='animations'>
          {animations}
        </div>
      </>
    </FormItem>
  )
}

export default Animations
