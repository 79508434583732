import { TfaCommand } from '../../enums'

const labelsEn = Object.freeze({
  [TfaCommand.RESET_PASSWORD]: 'reset password',
  [TfaCommand.UPDATE_PASSWORD]: 'update password',
  [TfaCommand.VERIFY_EMAIL]: 'verify email',
  [TfaCommand.VERIFY_PHONE_NUMBER]: 'verify phone number',
  [TfaCommand.WELCOME]: 'welcome',
})

export default {
  getLabel: (command: TfaCommand | null | undefined, language = 'en'): string => {
    if (!command) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[command]
    }
    return ''
  },
  isValid: (command: TfaCommand): boolean => Object.values(TfaCommand).includes(command),
}
