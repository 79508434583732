import React, { useContext, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { pencilOutline, searchOutline } from 'ionicons/icons'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import type { AppAction } from '../../../lib/core/enums'
import { UiMessage } from '../../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type { MemberMessageTemplateQueryData, MemberMessageTemplateQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import type { InfoPaneEntryDef } from '../../../components/InfoPane/InfoPane'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import coreHelpers from '../../../lib/core/helpers'
import IconButton from '../../../components/IconButton/IconButton'
import InAppMessageModal from '../../../components/InAppMessageModal/InAppMessageModal'
import InfoPane from '../../../components/InfoPane/InfoPane'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'

const appPageId = AppPage.AdminMemberMessageTemplatePage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  memberMessageTemplateId: string
}

const MemberMessageTemplatePage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { memberMessageTemplateId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [showInAppMessageModal, setShowInAppMessageModal] = useState(false)

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading memberMessageTemplate:
  const {
    data: memberMessageTemplateLoadedData,
    loading: isLoadingMemberMessageTemplate,
    refetch: reloadMemberMessageTemplate,
  } = useQuery<MemberMessageTemplateQueryData, MemberMessageTemplateQueryVariables>(
    apollo.queries.memberMessageTemplate, {
      variables: { memberMessageTemplateId: memberMessageTemplateId || '' },
      skip: !memberMessageTemplateId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: MemberMessageTemplateQueryData) => {
        const returnedMemberMessageTemplate = data ? data.memberMessageTemplate : undefined
        if (returnedMemberMessageTemplate === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const memberMessageTemplate = memberMessageTemplateLoadedData
    ? memberMessageTemplateLoadedData.memberMessageTemplate
    : undefined
  const mergedMemberMessage = memberMessageTemplate
    ? coreHelpers.models.memberMessage.mergeMemberMessageWithTemplate(
      {},
      memberMessageTemplate,
      {},
    )
    : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingMemberMessageTemplate

  // ===================================================================================================================
  // Event Handlers:
  const onEditMemberMessageTemplate = (): void => {
    if (!memberMessageTemplateId) {
      return
    }
    navigate(`${AppRoute.ADMIN_EDIT_MEMBER_MESSAGE_TEMPLATE}/${memberMessageTemplateId}`)
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !memberMessageTemplateId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadMemberMessageTemplate({ memberMessageTemplateId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onPreview = (): void => {
    setShowInAppMessageModal(true)
  }

  const onAppAction = (action: AppAction): void => {
    alert(`You clicked on an action '${action}'`)
  }

  const onCloseMemberMessageTemplateModal = (): void => {
    setShowInAppMessageModal(false)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content: JSX.Element | undefined
  if (memberMessageTemplate) {
    const infoPaneEntries: InfoPaneEntryDef[] = [
      {
        key: 'id',
        label: 'ID',
        value: (memberMessageTemplate.id as string).replace(/-/g, ''),
      },
      {
        key: 'name',
        label: 'Name',
        value: memberMessageTemplate.name,
      },
      {
        key: 'description',
        label: 'Description',
        value: memberMessageTemplate.description,
      },
      {
        key: 'titleEn',
        label: 'Title',
        value: memberMessageTemplate.titleEn,
      },
      {
        key: 'messageTextEn',
        label: 'Message Text (Long)',
        value: memberMessageTemplate.messageTextEn,
      },
      {
        key: 'shortMessageTextEn',
        label: 'Message Text (Short)',
        value: memberMessageTemplate.shortMessageTextEn,
      },
      {
        key: 'htmlMessageEn',
        label: 'Message Text (HTML)',
        value: memberMessageTemplate.htmlMessageEn,
      },
      {
        key: 'action0',
        label: 'Action #1',
        value: memberMessageTemplate.action0,
      },
      {
        key: 'action1',
        label: 'Action #2',
        value: memberMessageTemplate.action1,
      },
      {
        key: 'action2',
        label: 'Action #3',
        value: memberMessageTemplate.action2,
      },
      {
        key: 'send-chat-message',
        label: 'Send Chat Message?',
        value: memberMessageTemplate.sendChatMessage ? 'yes' : 'no',
      },
      {
        key: 'send-email',
        label: 'Send Email?',
        value: memberMessageTemplate.sendEmail ? 'yes' : 'no',
      },
      {
        key: 'send-in-app-message',
        label: 'Send In-App Message?',
        value: memberMessageTemplate.sendInAppMessage ? 'yes' : 'no',
      },
      {
        key: 'send-push-notification',
        label: 'Send Push Notification?',
        value: memberMessageTemplate.sendPushNotification ? 'yes' : 'no',
      },
      {
        key: 'send-sms',
        label: 'Send SMS?',
        value: memberMessageTemplate.sendSms ? 'yes' : 'no',
      },
      {
        key: 'is-core',
        label: 'Is Core Template?',
        value: memberMessageTemplate.isCore ? 'yes' : 'no',
      },
      {
        key: 'created-at',
        label: 'Created',
        value: coreHelpers.ui.formatDate(memberMessageTemplate.createdAt),
      },
      {
        key: 'updated-at',
        label: 'Updated',
        value: coreHelpers.ui.formatDate(memberMessageTemplate.updatedAt),
      },
    ]

    let previewButton: JSX.Element | undefined
    if (
      memberMessageTemplate &&
      memberMessageTemplate.sendInAppMessage &&
      memberMessageTemplate.htmlMessageEn
    ) {
      previewButton = (
        <IconButton
          key='preview'
          icon={searchOutline}
          text='Preview'
          layout='vertical'
          className='button'
          iconSize={20}
          onClick={onPreview}
        />
      )
    }

    let editButton: JSX.Element | undefined
    if (memberMessageTemplate && !memberMessageTemplate.isCore) {
      editButton = (
        <IconButton
          key='edit'
          icon={pencilOutline}
          text='Edit'
          layout='vertical'
          className='button'
          iconSize={20}
          onClick={onEditMemberMessageTemplate}
        />
      )
    }

    content = (
      <>
        <div className='row withStandardBottomMargin'>
          <div className='caption'>
            {memberMessageTemplate.name}
          </div>
          {editButton}
          {previewButton}
        </div>
        <div className='withRoundedBorder withStandardBottomMargin'>
          <InfoPane
            entries={infoPaneEntries}
          />
        </div>
      </>
    )
  }

  let inAppMessageModal: JSX.Element | undefined
  if (
    showInAppMessageModal &&
    memberMessageTemplate &&
    memberMessageTemplate.sendInAppMessage &&
    memberMessageTemplate.htmlMessageEn &&
    mergedMemberMessage
  ) {
    inAppMessageModal = (
      <InAppMessageModal
        memberMessage={mergedMemberMessage}
        onAction={onAppAction}
        onClose={onCloseMemberMessageTemplateModal}
      />
    )
  }

  return (
    <IonPage className='app-page-admin  member-message-template-page'>
      <NavBar
        title='Manage Member Message Template'
        goBackUri={AppRoute.ADMIN_MEMBER_MESSAGE_TEMPLATES}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
          {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      {inAppMessageModal}
    </IonPage>
  )
}

export default MemberMessageTemplatePage
