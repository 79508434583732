import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { AsyncTask, AsyncTaskInput } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type { UpsertAsyncTaskMutationData, UpsertAsyncTaskMutationVariables } from '../apollo/definitions'
import apollo from '../apollo'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import loadAsyncTask from './loadAsyncTask'

const createAsyncTask = (
  taskInput: AsyncTaskInput,
  apolloClient: ApolloClient<any>,
  isTaskInTargetStateFunc: (task: AsyncTask) => boolean,
  timeout = 30000,
): Promise<AsyncTask | undefined> => (
  new Promise((resolve, reject) => {
    console.log('auth.createAsyncTask called.', { taskInput })

    if (!apolloClient) {
      console.error('auth.createAsyncTask: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertAsyncTaskMutationData, UpsertAsyncTaskMutationVariables> = {
      mutation: apollo.mutations.upsertAsyncTask,
      variables: { task: taskInput },
    }
    if (!globalCacheData.getActiveUser()) {
      queryParams.context = { skipAuthentication: true }
    }

    apolloClient.mutate<UpsertAsyncTaskMutationData, UpsertAsyncTaskMutationVariables>(queryParams)
      .then((result) => {
        console.log('auth.createAsyncTask: mutation succeeded.', result)
        if (
          !result ||
          !result.data ||
          !result.data.upsertAsyncTask ||
          !result.data.upsertAsyncTask.id
        ) {
          console.error('auth.createAsyncTask: mutation did not return expected data.', result)
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        const taskId = result.data.upsertAsyncTask.id

        setTimeout(() => {
          console.log('auth.createAsyncTask: calling loadAsyncTask')
          loadAsyncTask(taskId, apolloClient, isTaskInTargetStateFunc, timeout)
            .then((task) => {
              console.log('auth.createAsyncTask: resolving with task', task)
              resolve(task)
            }, (error) => {
              console.error('auth.createAsyncTask: error', error)
              reject(error)
            })
        }, 1000)
      }, (error) => {
        console.error('auth.createAsyncTask: error', error)
        reject(error)
      })
  })
)

export default createAsyncTask
