import React, { useEffect, useState } from 'react'

import type { PurchaseListItem, PurchaseTransferInput } from '../../../lib/core/definitions'
import { PurchaseStatus, RecordStatus } from '../../../lib/core/enums'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { useGiftFlow } from '../../../contexts/GiftFlowContext'
import coreHelpers from '../../../lib/core/helpers'
import GiftList from './GiftList/GiftList'
import StepButtons from '../StepButtons'
import ToggleWithLabels from '../../../components/ToggleWithLabels/ToggleWithLabels'

type Props = {
  onOpenMarketplace: () => void;
  onOpenUploadPurchase: () => void;
  onPrevStep: () => void;
  onNextStep: () => void;
}

const GiftStep: React.FC<Props> = (props): JSX.Element | null => {
  const {
    onOpenMarketplace,
    onOpenUploadPurchase,
    onPrevStep,
    onNextStep,
  } = props

  // ===================================================================================================================
  // State:
  const {
    activeUser,
    purchases,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  let userPurchases: PurchaseListItem[] | undefined
  if (Array.isArray(purchases) && purchases.length > 0) {
    userPurchases = purchases.filter(p => (
      p.status === PurchaseStatus.ACTIVE &&
      p.recordStatus === RecordStatus.ACTIVE),
    )
  }

  const {
    flowId,
    fromPurchaseId,
    giftChanges,
    isProcessing,
    purchaseTransfer,
    setGiftChanges,
    getCurToUserInfo,
    goToNextStep,
  } = useGiftFlow()
  const userCanPurchase = activeUser && coreHelpers.models.user.canPurchase(activeUser)
  const newGiftToggleLabel = userCanPurchase ? 'Marketplace' : 'Upload'
  const toUserInfo = getCurToUserInfo()
  let toUserFirstName: string | undefined
  if (toUserInfo && toUserInfo.fullName) {
    toUserFirstName = toUserInfo.fullName.split(' ')[0]
  }

  const [showSendGiftHelpText, setSendGiftHelpText] = useState(false)
  const [source, setSource] = useState<'new-gift' | 'wallet'>('new-gift')

  const emptyActiveList = source === 'wallet' && userPurchases && userPurchases.length < 1

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    setSendGiftHelpText(false)
    setSource(fromPurchaseId ? 'wallet' : 'new-gift')
  }, [flowId])

  useEffect(() => {
    setSource(fromPurchaseId ? 'wallet' : 'new-gift')
  }, [fromPurchaseId])

  // ===================================================================================================================
  // Event Handler:
  const onSelectPurchase = (purchaseId: string): void => {
    if (purchaseId) {
      const changes: Partial<PurchaseTransferInput> = { ...giftChanges, fromPurchaseId: purchaseId }
      if (purchaseTransfer && purchaseId === purchaseTransfer.fromPurchaseId) {
        delete changes.fromPurchaseId
      }
      setGiftChanges(changes, false)
    }
  }

  const onChangeSource = (newSource?: string) => {
    setSource((newSource as 'new-gift' | 'wallet') ||
      (source === 'new-gift' ? 'wallet' : 'new-gift'))
  }

  const onNext = () => {
    if (source === 'new-gift') {
      if (userCanPurchase) {
        onOpenMarketplace()
      } else {
        onOpenUploadPurchase()
      }
      return
    }

    if (emptyActiveList) {
      onChangeSource('new-gift')
      onOpenUploadPurchase()
      return
    }

    goToNextStep()
  }

  // ===================================================================================================================
  // Rendering:
  let showMoreInstructionsLink: JSX.Element | undefined
  let detailedInstructions: JSX.Element | undefined

  // if (showSendGiftHelpText) {
  //   detailedInstructions = (
  //     <div className='withStandardTopMargin'>
  //       Now choose a gift from your digital wallet, or buy a new gift.
  //     </div>
  //   )
  // } else {
  //   showMoreInstructionsLink = (
  //     <span className='lightText'>Learn more</span>
  //   )
  // }

  let giftList: JSX.Element | undefined
  if (Array.isArray(userPurchases) && userPurchases.length > 0) {
    const curSearchText = ''
    giftList = (
      <div className='autoOverflowContainer'>
        <div className='lightText smallText'>Select a Gift</div>
        <GiftList
          searchText={curSearchText}
          purchases={userPurchases}
          selectedPurchaseId={fromPurchaseId}
          onSelectPurchase={onSelectPurchase}
        />
      </div>
    )
  } else {
    giftList = (
      <div className='lightText withStandardTopMargin withStandardBottomMargin'>
        You currently have no active gifts in your wallet.
      </div>
    )
  }

  let tabContent: JSX.Element | undefined
  if (source === 'new-gift') {
    const direction = userCanPurchase ? 'purchase your gift' : 'upload the gift to your wallet'
    tabContent = (
      <div className=' lightText withStandardTopMargin withStandardBottomMargin'>
        After you {direction}, you will be directed back here to send it.
      </div>
    )
  } else {
    tabContent = giftList
  }

  let nextButtonText
  if (source === 'new-gift' && userCanPurchase) {
    nextButtonText = 'Marketplace'
  } else if (source === 'new-gift' && !userCanPurchase) {
    nextButtonText = 'Upload Gift'
  } else if (source === 'wallet' && (userPurchases && userPurchases.length < 1)) {
    nextButtonText = 'Upload Gift'
  } else {
    nextButtonText = 'Next'
  }

  return (
    <div className='gift-step'>
      <div className='withDoubleBottomMargin'>
        Now {userCanPurchase ? 'buy' : 'upload'} or choose a gift for {toUserFirstName}:
      </div>
      <div className='autoOverflowContainer' style={{ position: 'relative' }}>
        {/* <div className='withDoubleBottomMargin lightText smallText'> */}
        {/*  {showMoreInstructionsLink} */}
        {/*  {detailedInstructions} */}
        {/* </div> */}
        <ToggleWithLabels
          values={['new-gift', 'wallet']}
          labels={[newGiftToggleLabel, 'My Wallet']}
          curValue={source}
          className='withStandardBottomMargin'
          onChange={onChangeSource}
        />
        {tabContent}
        <StepButtons
          isProcessing={isProcessing}
          disableNextButton={!emptyActiveList && source === 'wallet' && !fromPurchaseId}
          onPrevStep={onPrevStep}
          onNextStep={onNext}
          nextLabel={nextButtonText}
        />
      </div>
    </div>
  )
}

export default GiftStep
