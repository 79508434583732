import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { ShoppingCart, ShoppingCartListItem } from '../../../lib/core/definitions'
import type {
  ShoppingCartQueryData,
  ShoppingCartQueryVariables,
  ShoppingCartsQueryData,
  ShoppingCartsQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  shoppingCart: ShoppingCart,
  queryListVariables: ShoppingCartsQueryVariables,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateShoppingCart.updateInList called.', { shoppingCart })
  if (!shoppingCart) {
    logger.error('api.cache.updateShoppingCart.updateInList: no shoppingCart given.')
    return
  }

  try {
    const cachedShoppingCartsData = apolloClient.readQuery<ShoppingCartsQueryData, ShoppingCartsQueryVariables>({
      query: apollo.queries.shoppingCarts,
      variables: queryListVariables,
    })

    if (
      !cachedShoppingCartsData ||
      !Array.isArray(cachedShoppingCartsData.shoppingCarts) ||
      cachedShoppingCartsData.shoppingCarts.length < 1
    ) {
      const listItem = coreHelpers.models.shoppingCart.updateShoppingCartListItemFromShoppingCart(undefined, shoppingCart)
      apolloClient.writeQuery<ShoppingCartsQueryData, ShoppingCartsQueryVariables>({
        query: apollo.queries.shoppingCarts,
        variables: queryListVariables,
        data: { shoppingCarts: [listItem] },
      })
      return
    }

    const updatedShoppingCart = coreHelpers.models.updateModelInList<ShoppingCartListItem, ShoppingCart>(
      cachedShoppingCartsData.shoppingCarts,
      shoppingCart,
      undefined,
      true,
      coreHelpers.models.shoppingCart.updateShoppingCartListItemFromShoppingCart,
    )

    apolloClient.writeQuery<ShoppingCartsQueryData>({
      query: apollo.queries.shoppingCarts,
      variables: queryListVariables,
      data: { shoppingCarts: updatedShoppingCart },
    })
  } catch (error) {
    logger.error('api.cache.updateShoppingCart.updateInList: error.', { shoppingCart, error })
  }
}

const updateShoppingCart = (
  shoppingCart: ShoppingCart,
  isActiveShoppingCart: boolean,
  queryListVariables: ShoppingCartsQueryVariables | undefined,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateShoppingCart called.', { shoppingCart })
  if (!apolloClient) {
    logger.error('api.cache.updateShoppingCart: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!shoppingCart || !shoppingCart.id) {
    logger.error('api.cache.updateShoppingCart: no shoppingCart given.', { shoppingCart })
    return
  }

  try {
    const variables: ShoppingCartQueryVariables = isActiveShoppingCart
      ? { userId: shoppingCart.userId as string }
      : { shoppingCartId: shoppingCart.id }

    apolloClient.writeQuery<ShoppingCartQueryData, ShoppingCartQueryVariables>({
      query: apollo.queries.shoppingCart(false),
      variables,
      data: { shoppingCart },
    })

    if (!isActiveShoppingCart && queryListVariables) {
      updateInList(
        shoppingCart,
        queryListVariables,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateShoppingCart: error.', { shoppingCart, error })
  }
}

export default updateShoppingCart
