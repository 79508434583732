import moment from 'moment'
import React from 'react'
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from '@ionic/react'
import { callOutline, close, mailOutline, person } from 'ionicons/icons'
import { CakeOutlined as CakeOutlinedIcon } from '@mui/icons-material'

import './styles.css'
import type { Contact, ContactUserListItem, User, UserIdentInfo } from '../../lib/core/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import Avatar from '../Avatar/Avatar'
import coreHelpers from '../../lib/core/helpers'
import FavoriteContactButton from '../FavoriteContactButton/FavoriteContactButton'
import LatestChatMessageInfo from '../LatestChatMessageInfo/LatestChatMessageInfo'

type Props = {
  userIdentInfo: User | UserIdentInfo
  contact?: Contact | ContactUserListItem | null | undefined
  className?: string
  showChat?: boolean
  onClick?: (contactId: string) => void
  onClose?: () => void
  onOpenContact?: (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined
  ) => void
}

const UserInfoCard: React.FC<Props> = (props): JSX.Element | null => {
  const {
    userIdentInfo,
    contact,
    className,
    showChat,
    onClick,
    onOpenContact,
    onClose,
  } = props

  // ===================================================================================================================
  // State:
  const { activeUser, contacts } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  // ===================================================================================================================
  // Helpers:
  let curContact: ContactUserListItem | undefined
  if (Array.isArray(contacts) && contacts.length > 0) {
    curContact = contacts.find(contact => contact.userId === userIdentInfo.id)
  }

  const {
    fullName: contactFullName,
    nickname: contactNickname,
  } = coreHelpers.models.contact.getContactNames(contact, userIdentInfo)
  const contactUsername = userIdentInfo.id && userIdentInfo.username

  // ===================================================================================================================
  // Event Handlers:
  const handleClick = (event: any) => {
    event.preventDefault()
    if (onClick) {
      onClick(userIdentInfo.id as string)
    }
  }

  const handleOpenContactClick = (event?: any): void => {
    if (event) {
      event.preventDefault()
    }
    onOpenContact && onOpenContact(
      curContact && curContact.contactId,
      userIdentInfo && userIdentInfo.id,
      curContact && curContact.chatId,
    )
  }

  // ===================================================================================================================
  // Rendering:

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Header Content:
  let usernameLine: JSX.Element | undefined
  if (contactUsername) {
    usernameLine = (
      <IonCardSubtitle className='username'>
        <span className='handleGlyph'>@</span>{contactUsername}
      </IonCardSubtitle>
    )
  }

  // If there's a nickname, we show the person's full name below it
  let fullNameLine: JSX.Element | undefined
  if (contactNickname) {
    fullNameLine = (
      <IonCardSubtitle className='lightText'>
        {contactFullName}
      </IonCardSubtitle>
    )
  }

  let closeButton: JSX.Element | undefined
  if (onClose) {
    closeButton = (
      <IonIcon icon={close} onClick={onClose} className='close-button' />
    )
  }

  let favoriteButton: JSX.Element | undefined
  if (curContact) {
    favoriteButton = (
      <FavoriteContactButton
        isFavorite={curContact.favorite}
        className='withStandardRightMargin'
      />
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Card Content
  let birthdaySection: JSX.Element | undefined
  if (curContact && curContact.birthday) {
    const d = moment(curContact.birthday)
    const formattedBirthday = d.format('MMMM Do')
    birthdaySection = (
      <div key='birthday' className='birthday-section'>
        <CakeOutlinedIcon
          fontSize='small'
          classes={{
            fontSizeSmall: 'birthday-icon-fontsize-small',
          }}
        />
        <span className='withSmallLeftMargin'>
            {(`${formattedBirthday}` || '')}
          </span>
      </div>
    )
  }

  let chatSection: JSX.Element | undefined
  if (curContact && curContact.latestChatMessage && showChat) {
    chatSection = (
      <div key='message' className='message-section'>
        <LatestChatMessageInfo
          activeUserId={activeUserId}
          contact={curContact}
        />
      </div>
    )
  }

  const newUserInfo: JSX.Element[] = []
  if (!userIdentInfo.id) {
    if (userIdentInfo.email) {
      newUserInfo.push(
        <div key='email' className='contact-info-line'>
          <IonIcon className='withStandardRightMargin' icon={mailOutline}/>
          <span className='contact-info-line-content'>{userIdentInfo.email}</span>
        </div>,
      )
    }
    if (userIdentInfo.phoneNumber) {
      const formattedPhoneNumber = coreHelpers.ui.formatPhoneNumber(userIdentInfo.phoneNumber)
      newUserInfo.push(
        <div key='phone' className='contact-info-line'>
          <IonIcon className='withStandardRightMargin' icon={callOutline} />
          <span className='contact-info-line-content'>{formattedPhoneNumber}</span>
        </div>,
      )
    }
  }

  let viewContactSection: JSX.Element | undefined
  if (curContact && onOpenContact) {
    viewContactSection = (
      <div key='btn-row' className='open-button-row'>
        <IonButton
          fill='outline'
          size='small'
          onClick={handleOpenContactClick}
        >
          <IonIcon icon={person} slot='start' className='button' />
          View
        </IonButton>
      </div>
    )
  }

  const hasContent = !!(
    newUserInfo.length > 0 ||
    birthdaySection ||
    chatSection ||
    viewContactSection
  )

  return (
    <IonCard className={`user-info-card ${className || ''}`} onClick={handleClick}>
      <IonCardHeader className='card-header'>
        <div className='header-container'>
          <div className='info-container'>
            <Avatar
              user={userIdentInfo}
              className='avatar'
            />
            <div className='withLeftAlignedColumnContent'>
              <IonCardTitle style={{ fontSize: '1.2rem' }}>
                {contactNickname || contactFullName}
              </IonCardTitle>
              {usernameLine}
              {fullNameLine}
            </div>
          </div>
          <div className={`button-container ${onClose ? 'withColumnDirection' : ''}`}>
          {closeButton}
            <div className='rowWithCenterAlignedItems'>
              {favoriteButton}
            </div>
          </div>
        </div>
      </IonCardHeader>
      <IonCardContent className={hasContent ? '' : 'hidden'}>
        <div className='g-with-flex-1 withSmallTopMargin'>
          {birthdaySection}
          {chatSection}
          {newUserInfo}
        </div>
        {viewContactSection}
      </IonCardContent>
    </IonCard>
  )
}

export default UserInfoCard
