import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertTransactionMutationData,
  UpsertTransactionMutationVariables,
} from '../apollo/definitions'
import type {
  ApiQueryOptions,
  Transaction,
  TransactionInput,
  TransactionListFilter,
} from '../../lib/core/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadTransaction from './loadTransaction'
import logger from '../logger'

const upsertTransaction = (
  transactionInput: TransactionInput,
  isInTargetStateFunc: ((transaction: Transaction) => boolean) | 'watch-updated-at' | undefined,
  listFilter: TransactionListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Transaction | undefined> => (
  // console.log('api.upsertTransaction called.', { transactionInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertTransaction: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertTransactionMutationData, UpsertTransactionMutationVariables> = {
      mutation: apollo.mutations.upsertTransaction,
      variables: { transaction: transactionInput },
    }

    apolloClient.mutate<UpsertTransactionMutationData, UpsertTransactionMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertTransaction: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertTransaction ||
          !resp.data.upsertTransaction.id
        ) {
          logger.error('api.upsertTransaction: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((transaction: Transaction) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!transactionInput.id) {
            logger.error('api.upsertTransaction: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertTransaction.updatedAt
            activeIsInTargetStateFunc = (transaction: Transaction) => transaction.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertTransaction)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getTransactionsQueryVariables(listFilter, activeUserId)
            : undefined
          cache.updateTransaction(
            resp.data.upsertTransaction,
            queryListVariables,
            apolloClient,
          )
          resolve(resp.data.upsertTransaction)
          return
        }

        const transactionId = resp.data.upsertTransaction.id

        setTimeout(() => {
          // console.log('api.upsertTransaction: calling loadTransaction')
          loadTransaction(
            transactionId,
            activeIsInTargetStateFunc,
            listFilter,
            activeUserId,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((transaction) => {
              // console.log('api.upsertTransaction: resolving with transaction', transaction)
              resolve(transaction)
            }, (error) => {
              logger.error('api.upsertTransaction: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertTransaction: error', { error })
        reject(error)
      })
  })
)

export default upsertTransaction
