import type { Moment } from 'moment-timezone'
import { GiftingEventType } from '../../../enums'
import getDiffText from './getDiffText'

type Args = {
  contactName: string
  eventName: string
  eventDate: Moment
  diffInDays: number
}

const getMessageText = (args: Args): { title: string, messageText: string } => {
  // logger.info('services.users.sendGiftingEventReminders.doSingleUser.getMessageText called.', { args })
  const {
    contactName,
    eventDate,
    eventName,
    diffInDays,
  } = args

  const formattedEventDate = eventDate.format('MMMM Do')
  const contactNameWithApos = contactName.endsWith('s')
    ? `${contactName}'`
    : `${contactName}'s`

  // -------------------------------------------------------------------------------------------------------------------
  // The event is today:
  if (diffInDays === 0) {
    const messageText = `Need a last minute gift for ${contactName}? Get it at Mimble's marketplace!`
    if (eventName === GiftingEventType.BIRTHDAY) {
      return {
        title: `Today is ${contactNameWithApos} birthday!`,
        messageText,
      }
    }
    return {
      title: `${contactName} is celebrating an event (${eventName}) today!`,
      messageText,
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // The event is tomorrow:
  if (diffInDays === 1) {
    const messageText = `Do you want to get ${contactName} a gift? Check out Mimble's marketplace to send one with a few clicks!`
    if (eventName === GiftingEventType.BIRTHDAY) {
      return {
        title: `Tomorrow is ${contactNameWithApos} birthday!`,
        messageText,
      }
    }
    return {
      title: `Tomorrow ${contactName} is celebrating ${eventName}!`,
      messageText,
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // The event is more than 1 day away:
  const marketplaceText = 'Check out Mimble\'s marketplace to send a gift!'
  const diffText = getDiffText(diffInDays)

  if (eventName === GiftingEventType.BIRTHDAY) {
    return {
      title: `${contactNameWithApos} birthday is coming up!`,
      messageText: `${contactNameWithApos} birthday is on ${formattedEventDate}${diffText}. ${marketplaceText}`,
    }
  }

  return {
    title: `${contactName} has the event ${eventName} coming up!`,
    messageText: `${contactName} is going to celebrate the event ${eventName} on ${formattedEventDate}${diffText}. ${marketplaceText}`,
  }
}

export default getMessageText
