import React, { useRef, useState } from 'react'
import { IonButton, IonInput } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import { TfaStatus } from '../../../../lib/core/enums'
import type { TfaAttribute } from './definitions'
import { useMimbleData } from '../../../../contexts/MimbleDataContext/MimbleDataContext'
import coreHelpers from '../../../../lib/core/helpers'
import FormCheckbox from '../../../../components/FormCheckbox/FormCheckbox'
import FormItem from '../../../../components/FormItem/FormItem'
import helpers from './helpers'
import InlineMessage from '../../../../components/InlineMessage/InlineMessage'
import pageHelpers from '../../../../helpers/pageHelpers'
import SubmitButton from '../../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../../helpers/validationHelpers'
import VerifyInputIndicator, { getInputVerificationStatusFromTfaStatus } from '../../../../components/VerifyInputIndicator/VerifyInputIndicator'
import ProcessingOverlay from '../../../../components/ProcessingOverlay/ProcessingOverlay'

const previousVerificationResults = new Map<string, string>()

const shareFieldMapping = {
  email: 'shareEmail',
  phoneNumber: 'sharePhoneNumber',
}

type Props = {
  attribute: TfaAttribute
  onClose: () => void
  showUiMessage: (message: string) => void
}

const TfaFieldForm: React.FC<Props> = ({
  attribute,
  onClose,
  showUiMessage,
}): JSX.Element => {
  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()

  const {
    activeUser,
    reloadActiveUser,
    updateActiveUser,
    isUpdatingActiveUser,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [value, setValue] = useState<string | null | undefined>()
  const [validationError, setValidationError] = useState<string | undefined>()
  const [enableValidationForInput, setEnableValidationForInput] = useState(false)
  const [isSharing, setIsSharing] = useState<boolean | undefined>()
  const [isProcessing, setIsProcessing] = useState(false)
  const [showDetailedHelpText, setShowDetailedHelpText] = useState(false)
  const [tfaTaskId, setTfaTaskId] = useState<string | undefined>()
  const [tfaStatus, setTfaStatus] = useState<TfaStatus | undefined>()
  const [tfaCode, setTfaCode] = useState<string | undefined>()
  const [tfaCodeValidationError, setTfaCodeValidationError] = useState<string | undefined>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const inputRef = useRef<HTMLIonInputElement | null>(null)

  const { oldValue, oldIsSharing } = helpers.getOldValues(activeUser, attribute)
  const showTfaCodeInput = (
    tfaStatus === TfaStatus.MESSAGE_SENT ||
    tfaStatus === TfaStatus.VERIFYING_CODE ||
    tfaStatus === TfaStatus.CODE_MISMATCH
  )
  const formValue = value || pageHelpers.getFormInputValue(inputRef)

  // ===================================================================================================================
  // Helpers:
  const isDirty = ((!!value || value === null) && value !== oldValue) || isSharing !== undefined
  const enableSaveButton = (
    (value === null && oldValue !== null) ||
    (!!tfaCode && validationHelpers.validateMfaCode(tfaCode.replace(/\D/g, ''))) ||
    isSharing !== undefined
  )

  const resetForm = (): void => {
    setValue(undefined)
    setValidationError(undefined)
    setEnableValidationForInput(false)
    setIsSharing(undefined)
    setShowDetailedHelpText(false)
    setTfaTaskId(undefined)
    setTfaStatus(undefined)
    setTfaCode(undefined)
    setTfaCodeValidationError(undefined)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onSendTfaNotification = async () => {
    const { valid, cleanValue, error } = helpers.validateInput(
      value || pageHelpers.getFormInputValue(inputRef),
      attribute,
    )
    if (error) {
      setValidationError(error)
    }
    if (!valid) {
      showUiMessage('The entered value is not valid. Please correct and try again.')
      return
    }

    setValidationError(undefined)
    setIsProcessing(true)

    if (helpers.requiresUniqueCheck(attribute)) {
      const previousResult = previousVerificationResults.get(cleanValue)
      if (previousResult) {
        setIsProcessing(false)
        setValidationError(previousResult)
        showUiMessage(previousResult)
        return
      }

      const result = await helpers.checkIfValueIsAvailable(
        cleanValue,
        attribute,
        apolloClient,
      )
      setIsProcessing(false)
      if (result !== true) {
        setIsProcessing(false)
        setValidationError(result)
        showUiMessage(result)
        previousVerificationResults.set(cleanValue, result)
        return
      }
    }

    setTfaStatus(TfaStatus.MESSAGE_REQUESTED)
    const {
      ok,
      tfaTaskId: newTfaTaskId,
      tfaStatus: newTfaStatus,
      errorMessage: newErrorMessage,
      uiMessage,
    } = await helpers.sendTfaNotification({
      value: cleanValue,
      attribute,
      activeUserId: activeUserId as string,
      notificationMethod: undefined,
      apolloClient,
    })
    setIsProcessing(false)
    if (newTfaTaskId) {
      setTfaTaskId(newTfaTaskId)
    }
    if (newTfaStatus) {
      setTfaStatus(newTfaStatus)
    }
    if (newErrorMessage) {
      setErrorMessage(newErrorMessage)
    }
    if (uiMessage) {
      showUiMessage(uiMessage)
    }
  }

  const onChangeValue = (event: any): void => {
    const newValue = event.detail.value || pageHelpers.getFormInputValue(inputRef)
    // console.log('onChangeValue called.', { val })
    setValue(newValue || null)

    if (tfaStatus) {
      setTfaStatus(undefined)
    }
    if (enableValidationForInput) {
      const result = helpers.validateInput(newValue, attribute)
      setValidationError(result.valid ? undefined : result.error)
    } else {
      if (validationError) {
        setValidationError(undefined)
      }
      if (newValue) {
        setTimeout(() => {
          if (!enableValidationForInput) {
            setEnableValidationForInput(true)
          }
        }, 2000)
      }
    }
  }

  const onSave = (enteredCode?: string): void => {
    console.log('TfaFieldForm.onSave called.', { value, enteredCode, tfaCode, tfaTaskId })

    if (value === undefined && isSharing !== undefined) {
      if (attribute === 'password') {
        console.error('TfaFieldForm.onSave: no sharing setting for password.')
        return
      }
      if (!activeUser) {
        console.error('TfaFieldForm.onSave: activeUser missing.')
        return
      }
      const field = shareFieldMapping[attribute]
      setIsProcessing(true)
      pageHelpers.updateUserPref(
        { [field]: isSharing },
        activeUser,
        apolloClient,
        reloadActiveUser,
        showUiMessage,
      ).then(() => {
        setIsProcessing(false)
        onClose()
        resetForm()
      }, (error) => {
        console.error(error)
        setIsProcessing(false)
      })
      return
    }

    if (value === null) {
      setIsProcessing(true)
      helpers.clearAttribute(
        attribute,
        activeUserId as string,
        updateActiveUser,
      )
        .then((result) => {
          setIsProcessing(false)
          const {
            ok,
            uiMessage: newUiMessage,
            errorMessage: newErrorMessage,
          } = result
          if (errorMessage) {
            setErrorMessage(newErrorMessage)
          }
          if (newUiMessage) {
            showUiMessage(newUiMessage)
          }
          if (ok) {
            onClose()
            resetForm()
          }
        }, (error) => {
          setIsProcessing(false)
          console.error(error)
          showUiMessage('Failed to save changes. Please try again.')
        })
      return
    }

    const confirmToken = (enteredCode || tfaCode || '').replace(/\D/g, '')

    if (
      !activeUserId ||
      !tfaTaskId ||
      !confirmToken ||
      tfaCodeValidationError
    ) {
      console.log('TfaFieldForm.onSave: not ready.', { tfaTaskId, confirmToken, tfaCodeValidationError })
      return
    }

    setIsProcessing(true)
    setTfaStatus(TfaStatus.VERIFYING_CODE)
    setErrorMessage(undefined)

    helpers.saveAttribute({
      value: value || null,
      attribute,
      activeUserId,
      tfaTaskId,
      confirmToken,
      notificationMethod: undefined,
      reloadActiveUser,
      apolloClient,
    }).then((response) => {
      setIsProcessing(false)
      const {
        ok,
        errorMessage: newErrorMessage,
        uiMessage,
      } = response
      if (ok) {
        if (uiMessage) {
          showUiMessage(uiMessage)
        }
        onClose()
        resetForm()
        return
      }

      // Something went wrong:
      setTfaStatus(undefined)
      if (newErrorMessage) {
        setErrorMessage(newErrorMessage)
      }
    }, (error) => {
      console.error(error)
      setIsProcessing(false)
      setTfaStatus(undefined)
      setErrorMessage(error.message)
    })
  }

  const onChangeTfaCode = (event: any): void => {
    if (event && event.detail && event.detail.value) {
      const cleaned = (event.detail.value).replace(/\D/g, '').substr(0, 6)
      setTfaCode(cleaned)
      if (tfaCodeValidationError) {
        setTfaCodeValidationError(undefined)
      }
      if (
        cleaned &&
        cleaned.length === 6 &&
        !validationHelpers.validateMfaCode(cleaned) &&
        tfaTaskId
      ) {
        onSave(cleaned)
      }
    } else {
      setTfaCode(undefined)
    }
  }

  const onCancel = () => {
    onClose()
    resetForm()
  }

  const onChangeIsSharing = (checked: boolean) => {
    setIsSharing(checked)
  }

  // ===================================================================================================================
  // Rendering:
  let valueReadyToVerify = !!(formValue || oldValue) && isDirty && value !== null
  if (valueReadyToVerify) {
    const { valid } = helpers.validateInput(
      value || pageHelpers.getFormInputValue(inputRef) || oldValue,
      attribute,
    )
    valueReadyToVerify = valid
  }

  let detailedHelpText: JSX.Element | undefined
  if (showDetailedHelpText) {
    if (attribute === 'phoneNumber') {
      detailedHelpText = (
        <div className='smallText lightText withDoubleBottomMargin'>
          Your phone number is kept confidential and we never share it
          with any third party. We will also not call you.
        </div>
      )
    } else {
      detailedHelpText = (
        <div className='smallText lightText withDoubleBottomMargin'>
          Your email is kept confidential and we never share it
          with any third party. We will also not email you unless it is about your account.
        </div>
      )
    }
  } else {
    if (attribute === 'phoneNumber') {
      detailedHelpText = (
        <div
          className='smallText lightText withStandardBottomMargin withPointerCursor'
          onClick={() => { setShowDetailedHelpText(true) }}
        >
          Your phone number is safe with us. <span className='linkText'>see why</span>
        </div>
      )
    } else {
      detailedHelpText = (
        <div
          className='smallText lightText withStandardBottomMargin withPointerCursor'
          onClick={() => { setShowDetailedHelpText(true) }}
        >
          Your email is safe with us. <span className='linkText'>see why</span>
        </div>
      )
    }
  }

  let verifyButton: JSX.Element | undefined
  if (
    (formValue || oldValue) &&
    tfaStatus !== TfaStatus.PASSED &&
    !showTfaCodeInput
  ) {
    const label = attribute === 'phoneNumber' ? 'Send SMS Code' : 'Send Email Code'
    verifyButton = (
      <SubmitButton
        size='small'
        fill='outline'
        onClick={onSendTfaNotification}
        disabled={!valueReadyToVerify}
        isProcessing={isProcessing}
      >
        {label}
      </SubmitButton>
    )
  }

  let tfaInputSection
  if (showTfaCodeInput) {
    const headerParagraph = attribute === 'phoneNumber'
      ? (
        <div className='withStandardBottomMargin'>
          We sent a text message with a 6-digit confirmation code to&nbsp;
          <span className='withBoldText'>{coreHelpers.ui.formatPhoneNumber(value)}</span>.
          Please enter it here.
        </div>
        )
      : (
        <div className='withStandardBottomMargin'>
          We sent an email with a 6-digit confirmation code to&nbsp;
          <span className='withBoldText'>{value}</span>.
          Please enter it here.
        </div>
        )
    tfaInputSection = (
      <div className='tfa-input-section'>
        {headerParagraph}
        <FormItem
          key='phoneVerificationCode'
          label='6-digit code'
          validationError={tfaCodeValidationError}
        >
          <div className='rowWithCenterAlignedItems'>
            <IonInput
              value={tfaCode}
              type='number'
              inputmode='numeric'
              pattern='[0-9]{6}'
              placeholder='000000'
              autocomplete='one-time-code'
              minlength={6}
              maxlength={6}
              required
              className='input'
              onIonChange={onChangeTfaCode}
            />
            <VerifyInputIndicator
              status={getInputVerificationStatusFromTfaStatus(tfaStatus)}
              // disabled={!enableValidatephoneVerificationCode}
              onClick={() => onSave()}
            />
          </div>
        </FormItem>
        <div className='smallText'>
          Did not receive our message? <span className='linkText' onClick={onSendTfaNotification}>Send again</span>.
        </div>
      </div>
    )
  }

  const sharingIsChecked = isSharing === undefined
    ? oldIsSharing
    : isSharing

  const inputFormItem = attribute === 'phoneNumber'
    ? (
      <FormItem
        label='Phone Number'
        successMessage={tfaStatus === TfaStatus.PASSED ? 'Successfully verified' : undefined}
        validationError={validationError}
      >
        <div className='rowWithCenterAlignedItems'>
          <IonInput
            ref={inputRef}
            name='phone-number'
            value={value || oldValue}
            placeholder='1234567890'
            type='tel'
            inputmode='tel'
            autocomplete='tel'
            onIonChange={onChangeValue}
          />
          <VerifyInputIndicator
            status={helpers.getVerificationStatus(tfaStatus)}
          />
        </div>
      </FormItem>
      )
    : (
      <FormItem
        label='Email'
        successMessage={tfaStatus === TfaStatus.PASSED ? 'Successfully verified' : undefined}
        validationError={validationError}
      >
        <div className='rowWithCenterAlignedItems'>
          <IonInput
            ref={inputRef}
            name='email'
            value={value || value === null ? value || '' : oldValue}
            placeholder='you@somemail.com'
            type='email'
            inputmode='email'
            autocomplete='email'
            onIonChange={onChangeValue}
          />
          <VerifyInputIndicator
            status={helpers.getVerificationStatus(tfaStatus)}
          />
        </div>
      </FormItem>
      )

  return (
    <div className='tfa-field-form'>
      <form onSubmit={() => onSave()}>
        {inputFormItem}
        {detailedHelpText}
        {verifyButton}
        {tfaInputSection}
        <div className='withStandardTopMargin'>
          <FormCheckbox
            isChecked={sharingIsChecked}
            label='Share with my contacts'
            onChange={onChangeIsSharing}
          />
        </div>
        <InlineMessage
          text={errorMessage}
          icon={alertCircleOutline}
          color='var(--ion-color-warning)'
        />
        <div className='formButtonWrapper'>
          <IonButton
            size='small'
            color='light'
            className='withStandardRightMargin'
            onClick={onCancel}
          >
            Cancel
          </IonButton>
          <SubmitButton
            size='small'
            disabled={!enableSaveButton}
            isProcessing={(isProcessing && tfaStatus === TfaStatus.VERIFYING_CODE) || isUpdatingActiveUser}
            onClick={() => onSave()}
          >
            Save
          </SubmitButton>
        </div>
      </form>
      <ProcessingOverlay text='Saving changes...' isProcessing={isProcessing} />
    </div>
  )
}

export default TfaFieldForm
