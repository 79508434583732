import type { ApolloClient } from '@apollo/client'
import type { Contact, ContactUserListItem } from '../../../lib/core/definitions'
import type {
  ContactQueryData,
  ContactQueryVariables,
  ContactUsersQueryData,
  ContactUsersQueryVariables,
} from '../../apollo/definitions'
import { ErrorCode } from '../../../lib/core/enums'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  contact: Contact,
  queryListVariables: ContactUsersQueryVariables,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateContact.updateInList called.', { contact })
  if (!contact) {
    logger.error('api.cache.updateContact.updateInList: no contact given.')
    return
  }

  try {
    const cachedContactUsersData = apolloClient.readQuery<ContactUsersQueryData, ContactUsersQueryVariables>({
      query: apollo.queries.contactUsers,
      variables: queryListVariables,
    })

    if (
      !cachedContactUsersData ||
      !Array.isArray(cachedContactUsersData.contactUsers) ||
      cachedContactUsersData.contactUsers.length < 1
    ) {
      const listItem = coreHelpers.models.contact.updateContactUserListItemFromContact(undefined, contact, activeUserId)
      apolloClient.writeQuery<ContactUsersQueryData, ContactUsersQueryVariables>({
        query: apollo.queries.contactUsers,
        variables: queryListVariables,
        data: { contactUsers: [listItem] },
      })
      return
    }

    const updatedContactUsers = coreHelpers.models.updateModelInList<ContactUserListItem, Contact>(
      cachedContactUsersData.contactUsers,
      contact,
      undefined,
      true,
      (contactUserListItem, contact) =>
        coreHelpers.models.contact.updateContactUserListItemFromContact(contactUserListItem, contact, activeUserId),
    )

    apolloClient.writeQuery<ContactUsersQueryData, ContactUsersQueryVariables>({
      query: apollo.queries.contactUsers,
      variables: queryListVariables,
      data: { contactUsers: updatedContactUsers },
    })
  } catch (error) {
    logger.error('api.cache.updateChatMessage.updateInList: error.', { error })
  }
}

const updateContact = (
  contact: Contact,
  queryListVariables: ContactUsersQueryVariables | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateContact called.', { contact })
  if (!apolloClient) {
    logger.error('api.cache.updateContact: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!contact || !contact.id) {
    logger.error('api.cache.updateContact: no contact given.', { contact })
    return
  }

  try {
    apolloClient.writeQuery<ContactQueryData, ContactQueryVariables>({
      query: apollo.queries.contact,
      variables: { contactId: contact.id },
      data: { contact },
    })

    if (queryListVariables) {
      updateInList(
        contact,
        queryListVariables,
        activeUserId,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateContact: error.', { contact, error })
  }
}

export default updateContact
