import type { AppPage } from '../../enums'
import appPageDefs from './appPageDefs'

const isPageActive = (appPage: AppPage, location: Location): boolean => (
  !!location &&
  !!location.pathname &&
  !!appPage &&
  !!appPageDefs[appPage] &&
  appPageDefs[appPage].routeMatches &&
  appPageDefs[appPage].routeMatches(location.pathname)
)

export default isPageActive
