import React, { useState } from 'react'
import './styles.css'
import { FundType, TokenName } from '../../../lib/core/enums'
import type { ProductOption } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'

type Props = {
  productOption: ProductOption;
  isSelected: boolean;
  disabled?: boolean;
  onClick: (productOptionId: string) => void;
}
const ProductOptionItem: React.FC<Props> = (props): JSX.Element | null => {
  const {
    productOption,
    isSelected,
    disabled,
    onClick,
  } = props

  const [highlighted, setHighlighted] = useState(false)

  const formattedAmount = coreHelpers.ui.formatAmount(
    productOption.amount,
    productOption.fundType,
    productOption.currency,
    false,
    0,
  )
  const inStock = !!productOption.inventory && productOption.inventory > 0

  let itemClass = highlighted
    ? 'product-option-item selected'
    : 'product-option-item'
  if (!inStock) {
    itemClass += ' hidden'
  }
  if (disabled) {
    itemClass += ' disabled'
  }

  const line1Class = inStock
    ? 'line1'
    : 'line1 line1-disabled'

  const rewardInfoClass = inStock
    ? 'reward-info'
    : 'reward-info reward-info-disabled'

  let rewardInfo
  if (productOption.reward) {
    const rewardDisplayAmount = coreHelpers.ui.formatAmount(
      productOption.reward,
      FundType.TOKEN,
      TokenName.MIMBLE_TOKEN,
    )
    rewardInfo = (
      <div className={rewardInfoClass}>
        Reward: MIT {rewardDisplayAmount}
      </div>
    )
  }

  const handleClick = (): void => {
    if (disabled || !inStock || !productOption.id) {
      return
    }
    setHighlighted(true)
    onClick(productOption.id)
    setTimeout(() => {
      setHighlighted(false)
    }, 1000)
  }

  return (
    <div className={itemClass} onClick={handleClick}>
      <div className={line1Class}>
        <span className='line1-currency'>USD</span>
        {formattedAmount}
      </div>
      {rewardInfo}
    </div>
  )
}

export default ProductOptionItem
