import React, { useContext, useEffect, useState } from 'react'
import { IonButton, IonInput } from '@ionic/react'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import type { AsyncTask, AsyncTaskInput } from '../../../../lib/core/definitions'
import {
  AsyncTaskCommandType,
  AsyncTaskResult,
  AsyncTaskStatus,
  AsyncTaskType,
  UiMessage,
} from '../../../../lib/core/enums'
import { PageMessageType } from '../../../../enums'
import api from '../../../../services/api'
import coreHelpers from '../../../../lib/core/helpers'
import FormItem from '../../../../components/FormItem/FormItem'
import PageMessagesContext from '../../../../contexts/pageMessagesContext'
import SubmitButton from '../../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../../helpers/validationHelpers'

type Props = {
  asyncTaskId: string
  onClose: () => void
  showUiMessage: (message: string) => void
}

const UpdatePasswordScreen: React.FC<Props> = (props): JSX.Element => {
  // console.log('EnterPasswordScreen.render called.', { props })
  const {
    asyncTaskId,
    onClose,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)

  const [confirmToken, setConfirmToken] = useState<string | undefined>()
  const [confirmTokenValidationError, setConfirmTokenValidationError] = useState<string | undefined>()
  const [newPassword, setNewPassword] = useState<string | undefined>()
  const [newPasswordValidationError, setNewPasswordValidationError] = useState<string | undefined>()
  const [isProcessing, setIsProcessing] = useState(false)

  // ===================================================================================================================
  // Helpers:
  const resetForm = (): void => {
    setConfirmToken(undefined)
    setConfirmTokenValidationError(undefined)
    setNewPasswordValidationError(undefined)
    pageMessages && pageMessages.clear()
  }

  useEffect(() => {
    resetForm()
  }, [asyncTaskId])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeConfirmToken = (event: any): void => {
    setConfirmToken(event.detail.value)
    if (event.detail.value) {
      setConfirmTokenValidationError(validationHelpers.validateConfirmationToken(event.detail.value))
    } else {
      setConfirmTokenValidationError('')
    }
  }

  const onChangeNewPassword = (event: any): void => {
    setNewPassword(event.detail.value)
    if (event.detail.value) {
      setNewPasswordValidationError(validationHelpers.validatePassword(event.detail.value))
    } else {
      setNewPasswordValidationError(undefined)
    }
  }

  const onUpdatePassword = () => {
    const processErrorResponse = (error?: Error): void => {
      console.error('UpdatePasswordScreen.onSendNotification.processReturnedAsyncTask: error recorded.', error)
      setIsProcessing(false)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SAVING_CHANGES)
      showUiMessage('Failed to save changes.')
    }

    const processReturnedAsyncTask = (task: AsyncTask | undefined): void => {
      setIsProcessing(false)
      if (!task) {
        console.error('UpdatePasswordScreen.onSendNotification.processReturnedAsyncTask: no task returned.')
        return
      }

      console.log('UpdatePasswordScreen.onSendNotification.processReturnedAsyncTask: task returned.', task)

      if (task.taskStatus === AsyncTaskStatus.NOTIFICATION_FAILED) {
        processErrorResponse()
        return
      }

      if (
        task.taskStatus === AsyncTaskStatus.FINISHED &&
        task.result === AsyncTaskResult.OK
      ) {
        showUiMessage('Successfully saved your new password.')
        onClose()
        resetForm()
        return
      }

      console.error('UpdatePasswordScreen.onSendNotification.processReturnedAsyncTask: task in unexpected state.', task)
    }

    setIsProcessing(true)
    pageMessages && pageMessages.clear()

    const taskInput: AsyncTaskInput = {
      id: asyncTaskId,
      taskType: AsyncTaskType.RESET_PASSWORD,
      confirmToken: (confirmToken || '').replace(/\D/g, ''),
      command: AsyncTaskCommandType.VERIFY_TOKEN,
      metadata: {
        password: newPassword,
      },
    }

    api.upsertAsyncTask(
      taskInput,
      true,
      coreHelpers.models.asyncTask.isNewTaskSendingNotificationCompleted,
      apolloClient,
    ).then(processReturnedAsyncTask, processErrorResponse)
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <form onSubmit={onUpdatePassword} className='user-account-reset-password-form'>
      <FormItem
        key='token-input'
        label='Unlock Code'
        validationError={confirmTokenValidationError}
        withBottomMargin
      >
        <IonInput
          autofocus
          type='number'
          inputmode='numeric'
          pattern='[0-9]{6}'
          onIonChange={onChangeConfirmToken}
          placeholder='6-digit token'
          value={confirmToken}
          autocomplete='one-time-code'
          minlength={6}
          maxlength={6}
          required
          clearInput
        />
      </FormItem>
      <FormItem
        label='New Password'
        validationError={newPasswordValidationError}
        withBottomMargin
      >
        <IonInput
          autofocus
          type='password'
          onIonChange={onChangeNewPassword}
          placeholder='password'
          value={newPassword}
          required
          minlength={8}
          maxlength={30}
          clearInput
        />
      </FormItem>
      <div key='buttons' className='formButtonWrapper'>
        <IonButton
          color='medium'
          className='withStandardRightMargin'
          onClick={onClose}
        >
          Cancel
        </IonButton>
        <SubmitButton
          onClick={onUpdatePassword}
          disabled={!newPassword || !!newPasswordValidationError}
          isProcessing={isProcessing}
        >
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default UpdatePasswordScreen
