import Moment from 'moment-timezone'

const formatDuration = (startDate: any, endDate: any): string => {
  const durationInMonths = Moment(endDate).diff(startDate, 'months')
  if (durationInMonths > 12) {
    const durationInYears = Moment(endDate).diff(startDate, 'years')
    const yearUnit = durationInYears > 1 ? 'years' : 'year'
    const remainingDurationInMonths = Moment(endDate).diff(Moment(startDate).add(durationInYears, 'years'), 'months')
    const remainingMonthUnit = remainingDurationInMonths > 1 ? 'months' : 'month'
    return `${durationInYears} ${yearUnit}, ${remainingDurationInMonths} ${remainingMonthUnit}`
  }
  if (durationInMonths > 0) {
    const monthUnit = durationInMonths > 1 ? 'months' : 'month'
    const remainingDurationInDays = Moment(endDate).diff(Moment(startDate).add(durationInMonths, 'months'), 'months')
    if (remainingDurationInDays < 1) {
      return `${durationInMonths} ${monthUnit}`
    }
    const remainingDayUnit = remainingDurationInDays > 1 ? 'days' : 'day'
    return `${durationInMonths} ${monthUnit}, ${remainingDurationInDays} ${remainingDayUnit}`
  }
  const durationInDays = Moment(endDate).diff(startDate, 'days')
  if (durationInDays === 0) {
    const durationInHours = Moment(endDate).diff(startDate, 'hours')
    const hourUnit = durationInHours > 1 ? 'hours' : 'hour'
    return `${durationInHours} ${hourUnit}`
  }
  const dayUnit = durationInDays > 1 ? 'days' : 'day'
  return `${durationInDays} ${dayUnit}`
}

export default formatDuration
