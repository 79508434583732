const getFileVersionFromUrl = (url: string | null | undefined): number => {
  if (!url) {
    return 0
  }

  // https://d27wpajtnol6ce.cloudfront.net/members/c03b025c376d11eba1244f999d46d0d6/avatar.png?t=1633707513247
  // https://s3-us-west-2.amazonaws.com/co-mimble-cdn/vendors/skates.jpg
  const matches1 = url.match(/.+\/(.+)\..+$/)

  if (!Array.isArray(matches1) || matches1.length < 2) {
    return 0
  }

  const filename = matches1[1]
  if (
    !filename ||
    filename.length < 1 ||
    !filename.includes('-')
  ) {
    return 0
  }

  const matches2 = filename.match(/.+-([0-9]+)$/)

  if (!Array.isArray(matches2) || matches2.length < 2) {
    return 0
  }

  return Number(matches2[1])
}

export default getFileVersionFromUrl
