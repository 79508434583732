import React, { useContext } from 'react'
import type { AnimationName } from '../../lib/core/enums'
import type { ContextHelpId } from './enums'

export interface AppHelpersContextValue {
  showFullPageAnimation: (animation: AnimationName) => void
  openContextHelpModal: (id: ContextHelpId) => void
  closeContextHelpModal: () => void
}

export const defaultAppHelpersContextValue = {
  // Full page animations
  showFullPageAnimation: (animation: AnimationName): void => {
    console.log('ContextHelpContext.defaultContextHelpContextValue.showFullPageAnimation called.', { animation })
  },
  // Context help modal
  openContextHelpModal: (id: ContextHelpId): void => {
    console.log('ContextHelpContext.defaultContextHelpContextValue.openContextHelpModal called.', { id })
  },
  closeContextHelpModal: (): void => {
    console.log('ContextHelpContext.defaultContextHelpContextValue.closeContextHelpModal called.')
  },
}

export const AppHelpersContext = React.createContext<AppHelpersContextValue>(defaultAppHelpersContextValue)

export function useAppHelpers (): AppHelpersContextValue {
  return useContext(AppHelpersContext)
}

interface Props {
  showModal: (id: ContextHelpId) => void
  hideModal: () => void
  showFullPageAnimation: (animation: AnimationName) => void
}

const AppHelpersProvider: React.FC<Props> = (props) => {
  const { showModal, hideModal, showFullPageAnimation } = props

  return (
    <AppHelpersContext.Provider
      value={{
        openContextHelpModal: showModal,
        closeContextHelpModal: hideModal,
        showFullPageAnimation: showFullPageAnimation,
      }}
    >
      {props.children}
    </AppHelpersContext.Provider>
  )
}

export default AppHelpersProvider
