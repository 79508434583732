import type { History } from 'history'
import type { AppRoute } from '../../enums'

const navigate = (
  route: AppRoute | string | number,
  history: History,
  replace?: boolean,
  state?: any,
): void => {
  // console.log('pageHelpers.navigate called.', { route, replace, state })

  if (!route || !history) {
    return
  }

  if (route === -1) {
    history.back()
    return
  }

  if (replace) {
    history.replace(route as string, state)
    return
  }

  history.push(route as string, state)
}

export default navigate
