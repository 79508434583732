import { gql } from '@apollo/client'

export default gql`
  query Q($walletId: String!) {
    wallet (walletId: $walletId) {
      id
      userId
      walletType
      balanceCrypto
      balanceFiat
      balanceToken
      cryptoCurrency
      fiatCurrency
      tokenName
      metadata
      recordStatus
      createdAt
      updatedAt
      updateHistory { 
        id
        walletId
        transactionId
        updateType
        balanceCrypto
        balanceFiat
        balanceToken
        createdAt
      }
    }
  }`
