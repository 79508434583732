// see https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
export default (versionA: string | undefined, versionB: string | undefined): boolean => {
  if (!versionA || !versionB) {
    return false
  }

  const versionsA = versionA.split(/\./g)
  const versionsB = versionB.split(/\./g)

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())
    const b = Number(versionsB.shift())
    if (
      a !== b &&
      (a > b || !b || Number.isNaN(b))
    ) {
      return true
    }
  }
  return false
}
