import React from 'react'
import { IonButton } from '@ionic/react'

import SubmitButton from '../../components/SubmitButton/SubmitButton'

type Props = {
  isProcessing?: boolean | undefined
  disableNextButton?: boolean | undefined
  nextLabel?: string | undefined
  prevLabel?: string | undefined
  onPrevStep?: (() => void)
  onNextStep: (() => void)
};

const StepButtons: React.FC<Props> = (props): JSX.Element | null => {
  const {
    isProcessing,
    disableNextButton,
    nextLabel,
    prevLabel,
    onPrevStep,
    onNextStep,
  } = props

  let backButton: JSX.Element | undefined
  if (onPrevStep) {
    backButton = (
      <IonButton
        fill='outline'
        color='medium'
        className='withStandardRightMargin'
        onClick={onPrevStep}
      >
        {prevLabel || 'Back'}
      </IonButton>
    )
  }

  return (
    <div key='buttons' className='section'>
      <div className='formButtonWrapper'>
        {backButton}
        <SubmitButton
          onClick={() => onNextStep()}
          isProcessing={isProcessing}
          disabled={disableNextButton}
          label
        >
          {nextLabel || 'Next'}
        </SubmitButton>
      </div>
    </div>
  )
}

export default StepButtons
