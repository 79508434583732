import React from 'react'
import { bulbOutline, heartOutline, starOutline } from 'ionicons/icons'
import { IonButton, IonIcon } from '@ionic/react'

import './styles.css'
import { ContactPageIdeasTabSectionId } from '../enums'

type Props = {
  sectionId: ContactPageIdeasTabSectionId
  onOpenSection: (sectionId: ContactPageIdeasTabSectionId) => void
}

const NavSection: React.FC<Props> = (props): JSX.Element | null => {
  const { sectionId, onOpenSection } = props

  const buttonDefs = [
    {
      label: 'Wishes',
      sectionId: ContactPageIdeasTabSectionId.WISH_LIST,
      icon: heartOutline,
    },
    {
      label: 'Thoughts',
      sectionId: ContactPageIdeasTabSectionId.THOUGHTS,
      icon: bulbOutline,
    },
    {
      label: 'Favorites',
      sectionId: ContactPageIdeasTabSectionId.FAVORITES,
      icon: starOutline,
    },
  ]
  const buttons = buttonDefs.map((buttonDef, index): JSX.Element => {
    return (
      <IonButton
        key={buttonDef.sectionId}
        fill={buttonDef.sectionId === sectionId ? 'solid' : 'outline'}
        size='small'
        className={index === buttonDefs.length - 1 ? '' : 'withSmallRightMargin'}
        onClick={() => onOpenSection(buttonDef.sectionId)}
      >
        <IonIcon icon={buttonDef.icon} className='withSmallRightMargin' />
        {buttonDef.label}
      </IonButton>
    )
  })
  return (
    <div className='nav-section'>
      {buttons}
    </div>
  )
}

export default NavSection
