const getOfferLevelForAmount = (offer, amount) => {
  if (
    !offer ||
    !Array.isArray(offer.levels) ||
    offer.levels.length < 1
  ) {
    return null
  }
  const amountAsFloat = parseFloat(amount)
  for (let i = 0; i < offer.levels.length; i += 1) {
    if (Math.abs(parseFloat(offer.levels[i].amount) - amountAsFloat) < 0.01) {
      return offer.levels[i]
    }
  }
  return null
}

export default getOfferLevelForAmount
