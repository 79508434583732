import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  IonAlert,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  useIonViewWillLeave,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { useApolloClient } from '@apollo/client'
import { Update } from 'history'

import './styles.css'
import { AppPage, AppRoute, PageMessageType } from '../../enums'
import type { ContactInput } from '../../lib/core/definitions'
import { ContactItemList } from './definitions'
import { ContactsPageListScopeId } from './NavSection/enums'
import { ContactType, RecordStatus, UiMessage } from '../../lib/core/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import AddContactForm from './AddContactForm/AddContactForm'
import api from '../../services/api'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import ContactList from './ContactList/ContactList'
import ContactsPageHeader from './ContactsPageHeader'
import EmptyContactListContent from './ContactList/EmptyContactListContent'
import helpers from './helpers'
import logger from '../../services/logger'
import NavBar from '../../components/NavBar/NavBar'
import NavSection from './NavSection/NavSection'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import ProcessingOverlay from '../../components/ProcessingOverlay/ProcessingOverlay'

const appPageId = AppPage.ContactsPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const ContactsPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = pageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const {
    activeUser,
    contacts,
    isLoadingActiveUser,
    isLoadingContacts,
    reloadContacts,
  } = useMimbleData()

  const activeUserId = activeUser ? activeUser.id : undefined
  const { start: startGiftFlow } = useGiftFlow()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [isUpsertingContact, setIsUpsertingContact] = useState(false)
  const [searchText, setSearchText] = useState<string | undefined>()
  const [showAddContactForm, setShowAddContactForm] = useState(false)
  const [contactIdToBeArchived, setContactIdToBeArchived] = useState<string | undefined>()
  const [navSectionId, setNavSectionId] = useState(ContactsPageListScopeId.MESSAGES)

  const scopedContacts = useMemo<ContactItemList[] | undefined>(() => {
    if (!Array.isArray(contacts) || contacts.length < 1) {
      return
    }
    return helpers.getContactListForScope(
      contacts,
      helpers.navSectionIdToScopeMapping[navSectionId],
      searchText,
    )
  }, [navSectionId, contacts, searchText])

  const containsFavorites = (
    Array.isArray(scopedContacts) &&
    scopedContacts.length > 0
    && scopedContacts.some(l => l.hasFavorites)
  )

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingContacts || isUpsertingContact

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const upsertContact = (contactInput: ContactInput, done: (error?: string) => void): void => {
    api.upsertContact(
      contactInput,
      'watch-updated-at',
      undefined,
      activeUserId as string,
      apolloClient,
    ).then(() => {
      reloadContacts().then(() => {
        console.log('Reloaded contacts')
        done()
      }, (error) => {
        console.error(error)
        done(error.message)
      })
    }, (error) => {
      logger.error('ContactList.onArchiveContactConfirmed: error received.', { error })
      done(error.message)
    })
  }

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (!isLoadingContacts) {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }
  }, [isLoadingContacts])


  useIonViewWillLeave((): void => {
    setShowAddContactForm(false)
  })

  // ===================================================================================================================
  // Event Handlers:
  const onApplyListFilter = (newSearchText: string): void => {
    if (newSearchText !== searchText) {
      setSearchText(newSearchText)
    }
  }

  const onRefreshPage = (event?: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadContacts().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenContact = (contactId: string, contactUserId: string, chatId: string | undefined): void => {
    pageHelpers.openContact({
      activeUserId,
      contactId,
      contactUserId,
      chatId,
      tabId: helpers.localNavSectionIdToContactPageNavSectionMapping[navSectionId],
      apolloClient,
      navigate,
    })
  }

  const onOpenSupportChat = (): void => {
    pageMessages && pageMessages.clear()
    navigate(`${AppRoute.CONTACT_MESSAGES}/-/mimble-team`)
  }

  const onSendGift = (userId: string): void => {
    pageMessages && pageMessages.clear()
    if (!userId) {
      return
    }
    startGiftFlow({
      toUserId: userId,
    })
    navigate(AppRoute.SEND_GIFT)
  }

  const onOpenSection = (newSectionId: ContactsPageListScopeId): void => {
    if (Array.isArray(scopedContacts) &&
      scopedContacts.length > 0 &&
      navSectionId === newSectionId
    ) {
      // if (!('event' in scopedContacts[0])) {
      //   scopedContacts.sort(coreHelpers.models.contact.compareListItems) // TODO confirm we're not receiving events
      // }
    }
    setNavSectionId(newSectionId)
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onHideAddContactForm = (): void => {
    setShowAddContactForm(false)
  }

  const onAddContact = (userId: string): void => {
    if (!activeUser || !userId) {
      return
    }

    setIsUpsertingContact(true)
    pageMessages && pageMessages.clear()

    const contactInput: ContactInput = {
      fromUserId: activeUser.id,
      toUserId: userId,
      contactType: ContactType.USER_CREATED,
    }

    api.upsertContact(
      contactInput,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then(() => {
      reloadContacts().then(() => {
        setIsUpsertingContact(false)
        setShowAddContactForm(false)
        // pageMessages && pageMessages.add(PageMessageType.NOTICE, UiMessage.CHANGES_SAVED_SUCCESSFULLY)
        showUiMessage('The contact was added successfully.')
      }, (error) => {
        logger.error('ContactsPage: error reloading.', { error })
        setIsUpsertingContact(false)
        setShowAddContactForm(false)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      })
    }, (error) => {
      setIsUpsertingContact(false)
      pageHelpers.checkForUnauthorized(error, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SAVING_CHANGES)
    })
  }

  const onArchiveContact = (contactId: string) => {
    setContactIdToBeArchived(contactId)
  }

  const onArchiveContactConfirmed = () => {
    if (!contactIdToBeArchived) {
      return
    }
    setContactIdToBeArchived(undefined)
    upsertContact({
      id: contactIdToBeArchived,
      recordStatus: RecordStatus.INACTIVE,
    }, (error?: string) => {
      if (error) {
        showUiMessage('Failed to archive this contact. Please try again.')
        return
      }
      showUiMessage('The contact was archived successfully.')
    })
  }

  const onUnArchiveContact = (contactId: string) => {
    upsertContact({
      id: contactId,
      recordStatus: RecordStatus.ACTIVE,
    }, (error?: string) => {
      if (error) {
        showUiMessage('Failed to unarchive this contact. Please try again.')
        return
      }
      showUiMessage('The contact was unarchived successfully.')
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let searchSection: JSX.Element | undefined
  if (!showAddContactForm) {
    searchSection = (
      <ContactsPageHeader
        searchText={searchText}
        onApply={onApplyListFilter}
        onAddContact={() => setShowAddContactForm(true)}
      />
    )
  }

  let topNavSection: JSX.Element | undefined
  if (!showAddContactForm) {
    topNavSection = (
      <NavSection
        sectionId={navSectionId}
        containsFavorites={containsFavorites}
        onOpenSection={onOpenSection}
      />
    )
  }

  let content: JSX.Element | undefined
  if (showAddContactForm) {
    content = (
      <AddContactForm
        onCancel={onHideAddContactForm}
        onSubmit={onAddContact}
        onOpenInvitePage={() => navigate(AppRoute.NEW_INVITATION)}
      />
    )
  } else {
    if (Array.isArray(scopedContacts) && scopedContacts.length > 0) {
      content = (
        <ContactList
          scope={helpers.navSectionIdToScopeMapping[navSectionId]}
          itemLists={scopedContacts}
          onArchiveContact={onArchiveContact}
          onUnArchiveContact={onUnArchiveContact}
          onOpenContact={onOpenContact}
          onOpenSupportChat={onOpenSupportChat}
          onSendGift={onSendGift}
          showUiMessage={showUiMessage}
        />
      )
    } else if (
      !isLoadingContacts &&
      !isLoadingActiveUser &&
      !searchText
    ) {
      content = (
        <EmptyContactListContent scope={helpers.navSectionIdToScopeMapping[navSectionId]} />
      )
    }
  }

  const pageTitle = showAddContactForm ? 'Add Contact' : 'Contacts'

  return (
    <IonPage className='app-page-public contacts-page'>
      <NavBar
        title={pageTitle}
        userInfo={activeUser}
        isProcessing={isProcessing}
        onRefresh={onRefreshPage}
        onOpenUserAccount={onOpenUserAccount}
      />
      <PageMessages />
      {searchSection}
      {topNavSection}
      <IonContent className='g-content-with-safe-padding'>
        <IonRefresher slot='fixed' onIonRefresh={onRefreshPage}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      <IonAlert
        isOpen={!!contactIdToBeArchived}
        onDidDismiss={(): void => { setContactIdToBeArchived(undefined) }}
        header='Archive Contact'
        subHeader=''
        message='Are you sure you want to archive this contact? You can add them back in at any time later.'
        buttons={[
          { text: 'Yes, archive', handler: (): void => { onArchiveContactConfirmed() } },
          { text: 'Cancel', cssClass: 'secondary', handler: (): void => { setContactIdToBeArchived(undefined) } },
        ]}
      />
      <ProcessingOverlay isProcessing={isLoadingActiveUser || isLoadingContacts} />
    </IonPage>
  )
}

export default ContactsPage
