import getLevelsFromOffers from './getLevelsFromOffers'
import getOfferLevelForAmount from './getOfferLevelForAmount'
import getOfferPercentageForAmount from './getOfferPercentageForAmount'
import getMerchantRewardAmountForPurchaseAmount from './getMerchantRewardAmountForPurchaseAmount'

export default {
  getLevelsFromOffers,
  getOfferLevelForAmount,
  getOfferPercentageForAmount,
  getMerchantRewardAmountForPurchaseAmount,
}
