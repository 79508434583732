import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  DeleteFavoriteOrganizationMutationData,
  DeleteFavoriteOrganizationMutationVariables,
} from '../apollo/definitions'
import type { ApiQueryOptions, FavoriteOrganization } from '../../lib/core/definitions'
import apollo from '../apollo'
import cache from './cache'
import coreHelpers from '../../lib/core/helpers'
import logger from '../logger'

const deleteFavoriteOrganization = (
  favoriteOrganizationId: string,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<FavoriteOrganization | undefined> => (
  new Promise((resolve, reject) => {
    console.log('api.deleteFavoriteOrganization called.', { favoriteOrganizationId, clientOptions })

    if (!apolloClient) {
      logger.error('api.deleteFavoriteOrganization: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<DeleteFavoriteOrganizationMutationData, DeleteFavoriteOrganizationMutationVariables> = {
      mutation: apollo.mutations.deleteFavoriteOrganization,
      variables: { favoriteOrganizationId },
    }

    apolloClient.mutate<DeleteFavoriteOrganizationMutationData, DeleteFavoriteOrganizationMutationVariables>(queryParams)
      .then((resp) => {
        console.log('api.deleteFavoriteOrganization: apollo responded.', { resp })
        if (
          !resp ||
          !resp.data ||
          !resp.data.deleteFavoriteOrganization ||
          !resp.data.deleteFavoriteOrganization.id
        ) {
          logger.error('api.deleteFavoriteOrganization: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }
        if (coreHelpers.models.compareId(resp.data.deleteFavoriteOrganization.userId, activeUserId)) {
          console.log('api.deleteFavoriteOrganization: deleting active users favoriteOrganization in cache.', { resp })
          cache.deleteFavoriteOrganization(favoriteOrganizationId, activeUserId, apolloClient)
        }
        console.log('api.deleteFavoriteOrganization: mutation succeeded.', resp)
        resolve(resp.data.deleteFavoriteOrganization)
      }, (error) => {
        logger.error('api.deleteFavoriteOrganization: error', { error })
        reject(error)
      })
  })
)

export default deleteFavoriteOrganization
