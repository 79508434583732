import type { ShoppingCart } from '../../../definitions'
import showInShoppingCart from '../shoppingCartItem/showInShoppingCart'

const isEmpty = (shoppingCart: ShoppingCart | null | undefined): boolean => {
  if (
    !shoppingCart ||
    !Array.isArray(shoppingCart.items) ||
    shoppingCart.items.length < 1
  ) {
    return true
  }
  return shoppingCart.items.filter(i => showInShoppingCart(i)).length < 1
}

export default isEmpty
