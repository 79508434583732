import type { RefObject } from 'react'

const getFormInputValue = (inputRef: RefObject<HTMLIonInputElement>): string | undefined => {
  if (!inputRef || !inputRef.current || !inputRef.current.children) {
    return
  }
  const ele: HTMLInputElement = inputRef.current.firstChild as HTMLInputElement
  if (ele && ele.value) {
    return ele.value
  }
}

export default getFormInputValue
