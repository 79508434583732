import moment from 'moment'
import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { gift } from 'ionicons/icons'

import '../styles.css'
import type { Purchase } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import GiftCard from '../../GiftCard/GiftCard'
import type { InfoPaneEntryDef } from '../../InfoPane/InfoPane'
import InfoPane from '../../InfoPane/InfoPane'
import pageHelpers from '../../../helpers/pageHelpers'

type Props = {
  purchase: Purchase
  className?: string
  onOpenPurchase?: (purchaseId: string) => void
}

const GiftCardObjectInfo: React.FC<Props> = (props): JSX.Element | null => {
  const { purchase, className, onOpenPurchase } = props

  const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromPurchase(purchase)
  const merchantName = purchase.product && purchase.product.merchant ? purchase.product.merchant.name : 'n/a'

  const entries: InfoPaneEntryDef[] = [
    {
      key: 'id',
      label: 'ID',
      value: coreHelpers.ui.formatModelId(purchase.id),
    },
    {
      key: 'brand',
      label: 'Brand',
      value: merchantName,
    },
    {
      key: 'date',
      label: 'Purchase Date',
      value: moment(Number(purchase.createdAt)).format('MM/DD/YYYY hh:mm a'),
    },
    {
      key: 'amount',
      label: 'Amount',
      value: coreHelpers.ui.formatAmount(
        purchase.balance,
        purchase.fundType,
        purchase.currency,
        true,
        0,
      ),
    },
  ]

  let openButton: JSX.Element | undefined
  if (onOpenPurchase) {
    openButton = (
      <IonButton
        size='small'
        fill='outline'
        onClick={() => onOpenPurchase(purchase.id as string)}
      >
        Open
      </IonButton>
    )
  }
  return (
    <div className='object-info'>
      <div className={`withStandardBottomMargin section-header ${className || ''}`}>
        <IonIcon icon={gift} className='section-icon' /> Purchase
      </div>
      <div className='section-content'>
        <div className='withStandardBottomMargin withMaxWidth150px'>
          <GiftCard
            purchaseId={purchase.id as string}
            backgroundImageUri={giftCardBackgroundUri}
            merchantLogoUri={merchantLogoUri}
            small
          />
        </div>
        <InfoPane
          entries={entries}
        />
        {openButton}
      </div>
    </div>
  )
}

export default GiftCardObjectInfo
