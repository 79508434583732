import { gql } from '@apollo/client'

export default gql`
  query Q($campaignId: String) {
    campaign(campaignId: $campaignId) {
      id
      memberMessageTemplateId
      name
      campaignType
      status
      metadata
      recordStatus
      createdAt
      updatedAt
      updatedBy
    }
  }
`
