import React from 'react'
import { IonIcon } from '@ionic/react'
import { addOutline, informationCircleOutline} from 'ionicons/icons'
import IconButton from '../IconButton/IconButton'

type Props = {
  helpText: string
  onAddItem?: () => void
}

const WishListHeader: React.FC<Props> = ({
  helpText,
  onAddItem,
}): JSX.Element => {

  const handleAddItem = (): void => {
    if (onAddItem) {
      onAddItem()
    }
  }

  let addButton: JSX.Element | undefined
  if (onAddItem) {
    addButton = (
      <IconButton
        icon={addOutline}
        text='Add'
        layout='vertical'
        className='button'
        iconSize={36}
        onClick={handleAddItem}
      />
    )
  }

  return (
    <div className='withStandardBottomMargin'>
      <div className='row0'>
        <div className='rowWithCenterAlignedItems'>
          <div className='help-text' style={{ flex: 1 }}>
            <IonIcon icon={informationCircleOutline} className='info-icon' />
            {helpText}
          </div>
          {addButton}
        </div>
      </div>
    </div>
  )
}

export default WishListHeader
