import React from 'react'

import './styles.css'
import type { PurchaseListItem } from '../../../../../lib/core/definitions'
import type { PurchaseStatus, RecordStatus } from '../../../../../lib/core/enums'
import coreHelpers from '../../../../../lib/core/helpers'
import pageHelpers from '../../../../../helpers/pageHelpers'
import GiftCard from '../../../../../components/GiftCard/GiftCard'
import PurchaseStatusTags from '../../../../../components/PurchaseStatusTags/PurchaseStatusTags'

interface Props {
  purchase?: PurchaseListItem;
  contrastPennies?: boolean;
  onOpenPurchase?: (purchaseId: string) => void;
}

const ContactPurchaseListItem: React.FC<Props> = (props): JSX.Element | null => {
  const {
    purchase = null,
    contrastPennies,
    onOpenPurchase,
  } = props

  if (!purchase) {
    return null
  }

  // ===================================================================================================================
  // Helpers:
  const purchaseId = purchase.id as string
  const formattedBalance = coreHelpers.ui.formatAmount(purchase.balance, purchase.fundType, purchase.currency, false, 2)
  const formattedBalanceParts = formattedBalance.split('.') // todo: use locale, might not be a period
  const formattedUpdatedAt = coreHelpers.ui.formatDate(purchase.balanceUpdatedAt || purchase.updatedAt)
  const penniesClass = contrastPennies ? 'digitalWalletListItemPennies' : 'digitalWalletListItemAmount'
  const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromPurchaseListItem(purchase)

  // ===================================================================================================================
  // Event Handlers:
  const onClickPurchase = (purchaseId: string): void => {
    if (onOpenPurchase) {
      onOpenPurchase(purchaseId)
    }
  }

  // ===================================================================================================================
  // Rendering:
  if (!purchase) {
    return null
  }

  const penniesPart = formattedBalanceParts[1]
    ? <span className={penniesClass}>.{formattedBalanceParts[1]}</span>
    : null
  return (
    <div className='digitalWalletListItemWrapper' onClick={(): void => onClickPurchase(purchaseId)}>
      <div className='digitalWalletListItemCol0'>
        <GiftCard
          purchaseId={purchaseId}
          purchaseStatus={purchase.status as PurchaseStatus}
          backgroundImageUri={giftCardBackgroundUri}
          merchantLogoUri={merchantLogoUri}
          small
        />
      </div>
      <div className='digitalWalletListItemCol1'>
        <div className='digitalWalletListItemCol1Row0'>
          <div>
            <div className='digitalWalletListItemBalance'>
              <span className='digitalWalletListItemAmount'>{formattedBalanceParts[0]}</span>
              {penniesPart}
            </div>
            <div className='digitalWalletListItemDate'>
              {purchase.currency}
              <br />
              {formattedUpdatedAt}
            </div>
          </div>
          <div className='digitalWalletListItemmerchantNameWrapper'>
            <div className='digitalWalletListItemmerchantName'>{purchase.merchantName}</div>
          </div>
        </div>
        <PurchaseStatusTags
          recordStatus={purchase.recordStatus as RecordStatus}
          status={purchase.status as PurchaseStatus}
          showSmallLayout
        />
      </div>
    </div>
  )
}

export default ContactPurchaseListItem
