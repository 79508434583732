import type { ImportStoredProductItem, Vendor, VendorListItem } from '../../../../definitions'
import { FiatCurrency, FundType, StoredProductStatus } from '../../../../enums'
import getInternalMoneyAmount from '../../../ui/getInternalMoneyAmount'
import logger from '../../../../logger'

// Denomination,Reference ID,Card Number,Card PIN,URL
enum LinePartIndex {
  amount, // Denomination
  refId, // Reference ID
  code, // Card Number
  pin, // Card PIN
  refUrl, // URL
}
const PARTS_COUNT = Object.keys(LinePartIndex).length / 2

const bayatab = (
  line: string,
  lineIndex: number,
  productId: string,
  importIdPrefix: string | undefined,
  poNumber: string | undefined,
  source: string | undefined,
  vendors: Partial<Vendor | VendorListItem>[] | undefined,
): ImportStoredProductItem => {
  logger.info('lib.core.helpers.models.asyncTask.parseImportStoredProductsLine.buyatab called.',
    { line, lineIndex })

  if (line.startsWith('De') || line.length < 5) {
    return {
      lineIndex,
      ok: true,
      ignore: true,
    }
  }

  const parts = line.split(',')
    .map(p => p.replace('=', ''))
    .map(p => p.replace('"', ''))
    .map(p => p.replace('"', ''))
    .map(p => p.replace(')', ''))
  const partsCount = Array.isArray(parts) ? parts.length : 0
  const importItem: ImportStoredProductItem = {
    ok: false,
    importId: parts[LinePartIndex.refId],
    lineIndex,
    productId,
    status: StoredProductStatus.AVAILABLE,
    poNumber,
    source: source || 'buy-a-tab',
    receivedAt: new Date().toISOString(),
  }

  if (partsCount !== PARTS_COUNT) {
    return {
      ...importItem,
      error: `Parsing returned incorrect number of line components (expected: ${PARTS_COUNT}, found: ${partsCount}).`,
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Amount:
  const amountString = parts[LinePartIndex.amount].trim()
  if (amountString) {
    const amount = parseFloat(amountString)
    if (
      amount === undefined ||
      amount === null ||
      Number.isNaN(amount) ||
      amount < 0
    ) {
      return {
        ...importItem,
        error: `Invalid amount: '${amount}'.`,
      }
    }
    importItem.amount = getInternalMoneyAmount(
      amount,
      FundType.FIAT,
      FiatCurrency.USD,
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Code:
  importItem.code = parts[LinePartIndex.code].trim()
  if (!importItem.code) {
    return {
      ...importItem,
      error: 'Missing or invalid code.',
    }
  } else {
    importItem.code = importItem.code.replace(/ /g, '')
  }

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // PIN:
  importItem.pin = parts[LinePartIndex.pin].trim().replace(/ /g, '')

  // - - - - - - - - - - - - - - - - - - - - - - - -
  // Reference URL:
  importItem.refUrl = parts[LinePartIndex.refUrl].trim().replace(/ /g, '')

  logger.info(`lib.core.helpers.models.asyncTask.parseImportStoredProductsLine.buyatab: parsed line #${lineIndex}.`,
    { importItem })

  return {
    ...importItem,
    ok: true,
  }
}

export default bayatab
