import type { ProductListFilter } from '../../../definitions'
import compareId from '../compareId'

const compareProductListFilters = (filter1?: ProductListFilter, filter2?: ProductListFilter, ignoreTextFilter = false): boolean => {
  if (!filter1 && !filter2) {
    return true
  }
  if ((filter1 && !filter2) || (!filter1 && filter2)) {
    return false
  }
  // todo compare: tagIds, productTypes, merchantRecordStates
  return (
    !!filter1 &&
    !!filter2 &&
    (filter1.searchText === filter2.searchText || ignoreTextFilter) &&
    compareId(filter1.merchantId, filter2.merchantId) &&
    filter1.showOnlyMainProducts === filter2.showOnlyMainProducts &&
    filter1.showOnlyInStock === filter2.showOnlyInStock &&
    filter1.showOnlyListed === filter2.showOnlyListed &&
    filter1.offset === filter2.offset &&
    filter1.limit === filter2.limit &&

    // todo: deprecated
    compareId(filter1.vendorId, filter2.vendorId)
  )
}

export default compareProductListFilters
