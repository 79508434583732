import React, { useContext, useState } from 'react'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonPage,
  IonToast,
} from '@ionic/react'
import { useApolloClient } from '@apollo/client'
import { Update } from 'history'
import { useHistory, useLocation } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import { UserRole } from '../../lib/core/enums'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import coreHelpers from '../../lib/core/helpers'
import EnvironmentControl from '../../components/EnvironmentControl/EnvironmentControl'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import versionService from '../../services/version'

const appPageId = AppPage.AboutPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const AboutPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  // const locationUpdate: Update = useLocation()
  // const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { getEnvironment } = useGlobalCache()
  const { activeUser, isLoadingActiveUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const userCanTestApp = coreHelpers.models.user.hasRole(
    activeUser && activeUser.roles,
    UserRole.QA,
  )

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser

  // ===================================================================================================================
  // Event Handlers:
  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenLogHistory = (): void => {
    navigate(AppRoute.LOGGING)
  }

  const onOpenSupportChat = (): void => {
    pageHelpers.openContact({
      activeUserId,
      // contactId,
      contactUserId: 'mimble-team',
      // chatId,
      apolloClient,
      navigate,
    })
  }

  // ===================================================================================================================
  // Rendering:
  const sections: JSX.Element[] = []

  let versionInfo = versionService.currentVersion
  // For now, we don't cache the PWA. Once we do again, enable the below logic.
  const environment = getEnvironment()
  if (environment && environment !== 'production') {
    versionInfo += ` (${environment})`
  }
  // For now, we don't cache the PWA. Once we do again, enable the below logic.
  //  const refreshButton = isGettingLatestAppVersion
  //    ? <IonSpinner class='loadLatestAppVersionIcon' />
  //    : <IonIcon icon={refresh} class='loadLatestAppVersionIcon' />
  // const refreshButton = undefined
  // sections.push(
  //   <div key='ver' className='appVersionInfoSection'>
  //     <div className='appVersionInfoSectionRow0' onClick={onLoadLatestAppVersion}>
  //       <div>
  //         App version:
  //       </div>
  //       <div className='value'>
  //         {versionInfo}
  //       </div>
  //       {refreshButton}
  //     </div>
  //     {upgradeButton}
  //   </div>,
  // )

  sections.push(
    <IonCard key='version'>
      <IonCardHeader>
        <IonCardTitle>Version</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className='g-ion-card-content'>
        {versionInfo}
      </IonCardContent>
    </IonCard>,
  )

  sections.push(
    <IonCard key='intro'>
      <IonCardHeader>
        <IonCardTitle>What Is Mimble?</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className='g-ion-card-content'>
        Mimble is a social gifting platform. With Mimble, you can find gifts to send
        to friends and loved ones in a secure way.
        <br />
        <br />
        You can also use Mimble to manage
        your own gift cards and pay with your mobile phone using one of your gift card&apos;s
        barcode, QR code, or numerical code (depending on the card).
      </IonCardContent>
    </IonCard>,
  )

  sections.push(
    <IonCard key='who'>
      <IonCardHeader>
        <IonCardTitle>Who Makes Mimble?</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className='g-ion-card-content'>
        This app is created by the team at Mimble, Inc. in the Pacific Northwest. We&apos;re
        headquartered near Seattle, Washington.
        <br />
        <br />
        To reach Mimble&apos;s team, either text us in
        the <span className='linkText' onClick={onOpenSupportChat}>support chat</span>, or send an
        email to <a href='mailto:help@mimble.co'>help@mimble.co</a>.
      </IonCardContent>
    </IonCard>,
  )

  if (userCanTestApp) {
    sections.push(
      <IonCard key='qa'>
        <IonCardHeader>
          <IonCardTitle>QA</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className='g-ion-card-content'>
          <EnvironmentControl className='withDoubleBottomMargin' />
          <div className='rowWithCenterAlignedItems'>
            Set log level, view log history:
            <IonButton
              size='small'
              className='withStandardLeftMargin'
              onClick={onOpenLogHistory}
            >
              Logging
            </IonButton>
          </div>
        </IonCardContent>
      </IonCard>,
    )
  }

  return (
    <IonPage>
      <NavBar
        title='About Mimble'
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent className='g-content-with-safe-padding'>
        <PageMessages />
        {sections}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default AboutPage
