import type { Currency } from '../../definitions'
import type { FundType } from '../../enums'
import getAmountDisplayFactor from './getAmountDisplayFactor'

const getDisplayMoneyAmount = (
  internalAmount: number | null | undefined,
  fundType: FundType | null | undefined,
  currency: Currency | null | undefined,
): number => {
  if (!internalAmount) {
    return 0
  }
  return internalAmount / getAmountDisplayFactor(fundType, currency)
}

export default getDisplayMoneyAmount
