import { gql } from '@apollo/client'

export default gql`
  query Q($filter: InvitationListFilter!) {
    invitations (filter: $filter) {
      id
      fromUserId
      toUserId
      toUserEmail
      toUserPhoneNumber
      toUserFullName
      invitationType
      message
      messageSentAt
      messageSendReport
      campaign
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
