import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { chatboxOutline } from 'ionicons/icons'

import type { PurchaseTransfer } from '../../lib/core/definitions'
import GiftMessageText from '../../components/GiftMessageText/GiftMessageText'
import IntroSection from './IntroSection'

type Props = {
  purchaseTransfer: PurchaseTransfer;
  senderId: string;
  senderName: string;
  merchantName: string;
  formattedBalanceWithCurrency: string;
  onAcceptGift: (
    username: string | undefined,
    email: string | undefined,
    phoneNumber: string | undefined,
    password: string | undefined,
  ) => void;
  onDeclineGift: () => void;
}

const AcceptGiftContent: React.FC<Props> = ({
  purchaseTransfer,
  senderId,
  senderName,
  merchantName,
  formattedBalanceWithCurrency,
  onAcceptGift,
  onDeclineGift,
}): JSX.Element => {
  // ===================================================================================================================
  // Event Handlers:
  const onSubmit = (): void => {
    onAcceptGift(undefined, undefined, undefined, undefined)
  }

  // ===================================================================================================================
  // Rendering:
  let transferMessageSection: JSX.Element | undefined
  if (purchaseTransfer.messageText) {
    transferMessageSection = (
      <div className='section'>
        <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
          <IonIcon icon={chatboxOutline} className='withSmallRightMargin' color='medium' />
          <div className='lightText'>Message</div>
        </div>
        <div className='row withDoubleLeftMargin' style={{ justifyContent: 'flex-start' }}>
          <GiftMessageText
            value={purchaseTransfer.messageText}
            chatImageUrl={purchaseTransfer.chatImageUrl}
            chatImageType={purchaseTransfer.chatImageType}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <IntroSection
        senderName={senderName}
        merchantName={merchantName}
        formattedBalanceWithCurrency={formattedBalanceWithCurrency}
      />
      {transferMessageSection}
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onDeclineGift}
        >
          Decline
        </IonButton>
        <IonButton
          onClick={onSubmit}
        >
          Accept Gift
        </IonButton>
      </div>
    </>
  )
}

export default AcceptGiftContent
