import { IonList } from '@ionic/react'
import React, { useState } from 'react'

import './styles.css'
import type { ProductOption } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import ProductOptionRow from './ProductOptionRow'

type Props = {
  productOptions: ProductOption[] | null | undefined
  onOpenProductOption: (productId: string) => void
  onEditProductOption: (productOptionId: string) => void
  onAddStoredProduct: (productOptionId: string) => void
}

const ProductOptionList: React.FC<Props> = (props): JSX.Element | null => {
  const {
    productOptions,
    // filterVersion,
    onOpenProductOption,
    onEditProductOption,
    onAddStoredProduct,
  } = props
  // const isProcessing = isGettingProductOptions || isGettingProductOption

  // ===================================================================================================================
  // State:
  const [expandedProductOptionId, setExpandedProductOptionId] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Single Product:

  // ===================================================================================================================
  // Event Handlers:
  const onClickProductOption = (productOptionId: string): void => {
    if (!productOptionId) {
      return
    }
    if (coreHelpers.models.compareId(productOptionId, expandedProductOptionId)) {
      setExpandedProductOptionId(undefined)
    } else {
      setExpandedProductOptionId(productOptionId)
    }
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(productOptions) || productOptions.length < 1) {
    return null
  }
  const items = productOptions.map((productOption: ProductOption): JSX.Element => (
    <ProductOptionRow
      key={productOption.id as string}
      productOption={productOption}
      showDetails={coreHelpers.models.compareId(productOption.id, expandedProductOptionId)}
      onSelectProductOption={onClickProductOption}
      onOpenProductOption={onOpenProductOption}
      onEditProductOption={onEditProductOption}
      onAddStoredProduct={onAddStoredProduct}
    />
  ))
  return (
    <IonList>
      <div className='productOptionListRowItemContent productOptionListHeaderRowItemContent'>
        <div className='productOptionListRow0 productOptionListHeaderRow'>
          <div className='productOptionListRowCol productOptionListRowCol0'>
            #
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col1'>
            Label
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col2'>
            Amount
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col3'>
            Fund Type
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col4'>
            Currency
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col5'>
            Listed?
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col6'>
            Inventory
          </div>
          <div className='productOptionListRowCol productOptionListRow0Col7' />
        </div>
      </div>
      {items}
    </IonList>
  )
}

export default ProductOptionList
