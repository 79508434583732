import { CampaignStatus } from '../../enums'

const labelsEn = Object.freeze({
  [CampaignStatus.PLANNED]: 'planned',
  [CampaignStatus.ACTIVE]: 'active',
  [CampaignStatus.FINISHED]: 'finished',
})

export default {
  getLabel: (messageType: CampaignStatus | null | undefined, language = 'en'): string => {
    if (!messageType) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[messageType]
    }
    return ''
  },
  isValid: (messageType: CampaignStatus | string): boolean => (
    Object.values(CampaignStatus).includes(messageType as CampaignStatus)
  ),
}
