import type { Purchase } from '../../../lib/core/definitions'

const styles = (purchase: Purchase | null | undefined): any => {
  const code = purchase && purchase.code
  const codeLength = code ? code.length : 30
  const codeTextSize = codeLength < 20 ? 12 : 10

  return {
    body: {
      padding: 25,
    },

    mainColumn: {
      flexDirection: 'column',
      width: 250,
      border: 1,
      borderStyle: 'solid',
      borderColor: 'lightgrey',
    },

    sectionCaption: {
      fontSize: 7,
      color: 'darkgrey',
      marginBottom: 2.5,
    },

    redeemInstructionsText: {
      fontSize: 7,
      textAlign: 'justify',
      color: 'grey',
    },

    termsText: {
      fontSize: 5,
      textAlign: 'justify',
      color: 'grey',
    },

    /* -------------------------- Card Section -------------------------- */
    cardSection: {
      flexDirection: 'column',
      // width: 230,
      paddingTop: 10,
      paddingBottom: 6,
      marginBottom: 6,
      borderBottom: 0.5,
      borderStyle: 'dashed',
      borderColor: 'lightgrey',
    },

    cardImage: {
      maxWidth: 230,
      maxHeight: 144,
      border: 0.5,
      borderColor: 'lightgrey',
      borderRadius: 6,
      alignSelf: 'center',
      marginBottom: 10,
    },

    balance: {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 10,
    },

    cardBarcode: {
      maxWidth: 100,
      maxHeight: 60,
      alignSelf: 'center',
      marginBottom: 8,
    },

    cardQrCode: {
      maxWidth: 70,
      maxHeight: 70,
      alignSelf: 'center',
      marginBottom: 8,
    },

    code: {
      fontSize: codeTextSize,
      textAlign: 'center',
      marginBottom: 4,
    },

    pin: {
      fontSize: 12,
      color: 'black',
      marginBottom: 10,
      textAlign: 'center',
    },

    cardText: {
      fontSize: 8,
      textAlign: 'justify',
      color: 'grey',
      paddingHorizontal: 4,
    },

    /* -------------------------- Promo Section -------------------------- */
    promoSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: 0.5,
      borderStyle: 'solid',
      borderColor: 'lightgrey',
      borderRadius: 40,
      padding: 4,
      margin: 6,
    },

    inviteQR: {
      maxWidth: 36,
      maxHeight: 36,
      margin: 6,
    },

    promoCol1Line0: {
      fontSize: 8,
    },

    promoCol1Line1: {
      fontSize: 8,
    },

    /* -------------------------- Powered By Section -------------------------- */
    poweredBySection: {
      // borderTop: 0.5,
      // borderStyle: 'solid',
      // borderColor: 'lightgrey',
    },

    mimbleLogoRow: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 2,
    },

    mimbleLogo: {
      maxWidth: 16,
      maxHeight: 16,
    },

    mimbleTextLine0: {
      fontSize: 7,
      color: 'gray',
      textAlign: 'center',
    },

    mimbleTextLine1: {
      fontSize: 5,
      color: 'gray',
      textAlign: 'center',
    },

    /* -------------------------- Info Section -------------------------- */
    infoSection: {
      paddingHorizontal: 8,
    },
  }
}

export default styles
