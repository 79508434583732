import { gql } from '@apollo/client'

export default gql`
  query Q($wishId: String!) {
    wish(wishId: $wishId) {
      id
      contactId
      userId
      merchantId
      title
      notes
      productUrl
      budgetAmount
      sortIndex
      recordStatus
      createdAt
      updatedAt
    }
  }`
