import { IonIcon, IonInput } from '@ionic/react'
import { addOutline, search } from 'ionicons/icons'
import React, { useState } from 'react'

import './styles.css'
import type { MerchantListFilter } from '../../../lib/core/definitions'
import IconButton from '../../../components/IconButton/IconButton'

type Props = {
  isProcessing?: boolean
  onAddMerchant: () => void
  onChangeFilter: (filter: MerchantListFilter) => void
}

const MerchantListHeader: React.FC<Props> = (props): JSX.Element => {
  const {
    isProcessing,
    onAddMerchant,
    onChangeFilter,
  } = props
  // console.log('MerchantListHeader.render called.', props)
  // const isProcessing = isGettingMerchants || isGettingMerchant

  // ===================================================================================================================
  // State:
  const [searchText, setSearchText] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Merchant List:

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSearchTextLocal = (event: any): void => {
    setSearchText(event.detail.value)
    const filter: MerchantListFilter = {
      searchText,
    }
    onChangeFilter(filter)
  }

  const onClearFilter = (): void => {
    setSearchText('')
    onChangeFilter({})
  }

  // const onChangeScope = (newScope: MerchantListScope): void => {
  //   onApply(searchText || '', newScope)
  // }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='merchant-header'>
      <div className='searchBarWrapper'>
        <IonIcon icon={search} class='searchBarSearchIcon' />
        <IonInput
          value={searchText}
          placeholder='search'
          onIonChange={onChangeSearchTextLocal}
          className='searchBar'
          clearInput
        />
      </div>
      <div>
        <IconButton
          icon={addOutline}
          text='Add'
          layout='vertical'
          className='button'
          iconSize={30}
          onClick={onAddMerchant}
        />
      </div>
    </div>
  )
}

export default MerchantListHeader
