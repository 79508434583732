import type { Wallet, WalletListItem } from '../../../definitions'
import { RecordStatus } from '../../../enums'

const updateWalletListItemFromWallet = (walletListItem: WalletListItem | undefined, wallet: Wallet): WalletListItem => {
  if (!walletListItem) {
    return {
      id: wallet.id || '',
      userId: wallet.userId || null,
      walletType: wallet.walletType || null,
      balanceCrypto: wallet.balanceCrypto || 0,
      cryptoCurrency: wallet.cryptoCurrency || null,
      balanceFiat: wallet.balanceFiat || 0,
      fiatCurrency: wallet.fiatCurrency || null,
      balanceToken: wallet.balanceToken || 0,
      tokenName: wallet.tokenName,
      recordStatus: wallet.recordStatus || RecordStatus.ACTIVE,
      createdAt: wallet.createdAt || '',
      updatedAt: wallet.updatedAt || '',
      updatedBy: wallet.updatedBy || '',
    }
  }

  const updatedItem: WalletListItem = { ...walletListItem }

  if (wallet.userId && wallet.userId !== walletListItem.userId) {
    updatedItem.userId = wallet.userId
  }
  if (wallet.walletType && wallet.walletType !== walletListItem.walletType) {
    updatedItem.walletType = wallet.walletType
  }
  if (Number.isInteger(wallet.balanceCrypto) && wallet.balanceCrypto !== walletListItem.balanceCrypto) {
    updatedItem.balanceCrypto = wallet.balanceCrypto
  }
  if (wallet.cryptoCurrency && wallet.cryptoCurrency !== walletListItem.cryptoCurrency) {
    updatedItem.cryptoCurrency = wallet.cryptoCurrency
  }
  if (Number.isInteger(wallet.balanceFiat) && wallet.balanceFiat !== walletListItem.balanceFiat) {
    updatedItem.balanceFiat = wallet.balanceFiat
  }
  if (wallet.fiatCurrency && wallet.fiatCurrency !== walletListItem.fiatCurrency) {
    updatedItem.fiatCurrency = wallet.fiatCurrency
  }
  if (Number.isInteger(wallet.balanceToken) && wallet.balanceToken !== walletListItem.balanceToken) {
    updatedItem.balanceToken = wallet.balanceToken
  }
  if (wallet.tokenName && wallet.tokenName !== walletListItem.tokenName) {
    updatedItem.tokenName = wallet.tokenName
  }
  if (wallet.recordStatus && wallet.recordStatus !== walletListItem.recordStatus) {
    updatedItem.recordStatus = wallet.recordStatus
  }
  if (wallet.createdAt && wallet.createdAt !== walletListItem.createdAt) {
    updatedItem.createdAt = wallet.createdAt
  }
  if (wallet.updatedAt && wallet.updatedAt !== walletListItem.updatedAt) {
    updatedItem.updatedAt = wallet.updatedAt
  }

  return updatedItem
}

export default updateWalletListItemFromWallet
