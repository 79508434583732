// import { FirebaseApp } from 'firebase/app'
// import { signInWithPopup } from 'firebase/auth'

import type { OAuthUser } from '../../lib/core/definitions'
import getPushNotificationToken from './getPushNotificationToken'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import init from './init'
import parseDeeplink from './parseDeeplink'
import reportPushNotificationTokenToServer from './reportPushNotificationTokenToServer'
import serviceData from './serviceData'
import signOut from './signOut'

/*
const getAuth = (app?: FirebaseApp) => {
  if (!app) {
    app = serviceData.getFirebaseApp(globalCacheData.getEnvironment())
  }
  if (!app) {
    return null
  }
  return serviceData.getAuth(app)
}
*/

const firebaseService = {
  init,
  isActive: serviceData.isServiceActive,
  // isAuthActive: (): boolean => !!getAuth(),
  isAuthActive: (): boolean => false,
  getPushNotificationToken,
  parseDeeplink,
  reportPushNotificationTokenToServer,
  signOut,

  onSignInWithGoogle: (): void => {
    if (!serviceData.isServiceActive(globalCacheData.getEnvironment())) {
      console.log('firebase.onSignInWithGoogle: service not active')
      // return
    }
    /*
  const auth = getAuth()
  if (auth) {
    // see https://firebase.google.com/docs/auth/web/google-signin
    signInWithPopup(auth, serviceData.getGoogleAuthProvider())
    auth.signInWithRedirect(serviceData.getGoogleAuthProvider())
    firebase.auth().getRedirectResult().then((result) => {
      if (result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const token = result.credential.accessToken
        // ...
      }
      // The signed-in user info.
      const user = result.user
      console.log('firebase.onSignInWithGoogle: getRedirectResult: user=', user)
    }).catch(function(error) {
      // Handle Errors here.
      const errorCode = error.code
      const errorMessage = error.message
      // The email of the user's account used.
      const email = error.email
      // The firebase.auth.AuthCredential type that was used.
      const credential = error.credential
      // ...
    })
    }
     */
  },

  onSignInWithTwitter: (): void => {
    if (!serviceData.isServiceActive(globalCacheData.getEnvironment())) {
      console.log('firebase.onSignInWithTwitter: service not active')
      // return
    }
    // const auth = getAuth()
    // if (auth) {
    //   signInWithPopup(auth, serviceData.getTwitterAuthProvider())
    // }
  },

  onSignInWithFacebook: (): void => {
    if (!serviceData.isServiceActive(globalCacheData.getEnvironment())) {
      console.log('firebase.onSignInWithFacebook: service not active')
      // return
    }
    // const auth = getAuth()
    // if (auth) {
    //   signInWithPopup(auth, serviceData.getFacebookAuthProvider())
    // }
  },

  addAuthStateChangeListener: (name: string, listener: (firebaseUser: OAuthUser) => void): void => {
    serviceData.authStateChangeListeners.set(name, listener)
  },

  removeAuthStateChangeListener: (name: string): void => {
    serviceData.authStateChangeListeners.delete(name)
  },
}

export default firebaseService
