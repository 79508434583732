import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { Purchase, PurchaseListItem } from '../../../lib/core/definitions'
import type {
  PurchaseQueryData,
  PurchaseQueryVariables,
  PurchasesQueryData,
  PurchasesQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  purchase: Purchase,
  queryListVariables: PurchasesQueryVariables,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updatePurchase.updateInList called.',
  //   { purchase, queryListVariables, activeUserId })
  if (!purchase) {
    logger.error('api.cache.updatePurchase.updateInList: no purchase given.')
    return
  }

  try {
    const cachedPurchasesData = apolloClient.readQuery<PurchasesQueryData, PurchasesQueryVariables>({
      query: apollo.queries.purchases,
      variables: queryListVariables,
    })

    if (
      !cachedPurchasesData ||
      !Array.isArray(cachedPurchasesData.purchases) ||
      cachedPurchasesData.purchases.length < 1
    ) {
      const listItem = coreHelpers.models.purchase.updatePurchaseListItemFromPurchase(undefined, purchase)
      apolloClient.writeQuery<PurchasesQueryData, PurchasesQueryVariables>({
        query: apollo.queries.purchases,
        variables: queryListVariables,
        data: { purchases: [listItem] },
      })
      return
    }

    const updatedPurchases = coreHelpers.models.updateModelInList<PurchaseListItem, Purchase>(
      cachedPurchasesData.purchases,
      purchase,
      undefined,
      true,
      coreHelpers.models.purchase.updatePurchaseListItemFromPurchase,
    )

    apolloClient.writeQuery<PurchasesQueryData, PurchasesQueryVariables>({
      query: apollo.queries.purchases,
      variables: queryListVariables,
      data: { purchases: updatedPurchases },
    })
  } catch (error) {
    logger.error('api.cache.updatePurchase.updateInList: error.', { purchase, error })
  }
}

const updatePurchase = (
  purchase: Purchase,
  queryListVariables: PurchasesQueryVariables | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updatePurchase called.', { purchase })
  if (!apolloClient) {
    logger.error('api.cache.updatePurchase: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!purchase || !purchase.id) {
    logger.error('api.cache.updatePurchase: no purchase given.', { purchase })
    return
  }

  try {
    apolloClient.writeQuery<PurchaseQueryData, PurchaseQueryVariables>({
      query: apollo.queries.purchase,
      variables: { purchaseId: purchase.id },
      data: { purchase },
    })

    if (queryListVariables) {
      updateInList(
        purchase,
        queryListVariables,
        activeUserId,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updatePurchase: error.', { purchase, error })
  }
}

export default updatePurchase
