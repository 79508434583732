import type { Offer } from '../../../definitions'

const getLevelsFromOffers = (offers: Offer[]): any => {
  if (!Array.isArray(offers) || offers.length < 1) {
    return null
  }
  if (offers.length === 1) {
    return offers[0].levels
  }
  return offers.reduce((mergedLevels: any, offer): any => {
    if (!Array.isArray(offer.levels) || offer.levels.length < 1) {
      return mergedLevels
    }
    offer.levels.forEach((level: any): any => {
      const isAlreadyInList = mergedLevels.find((l: any): any => (
        Math.abs(parseFloat(l.amount.toString()) - parseFloat(level.amount.toString())) < 0.01
      ))
      if (!isAlreadyInList) {
        mergedLevels.push(level)
      }
    })
    return mergedLevels
  }, [])
}

export default getLevelsFromOffers
