import asyncTask from './asyncTask'
import buildUpdatedObjectList from './buildUpdatedObjectList'
import campaign from './campaign'
import chat from './chat'
import chatMessage from './chatMessage'
import compareId from './compareId'
import contact from './contact'
import contactEvent from './contactEvent'
import contactEventReminder from './contactEventReminder'
import invitation from './invitation'
import memberMessage from './memberMessage'
import memberMessageTemplate from './memberMessageTemplate'
import merchant from './merchant'
import offer from './offer'
import order from './order'
import pickColumns from './pickColumns'
import product from './product'
import purchase from './purchase'
import purchaseTransfer from './purchaseTransfer'
import reward from './reward'
import rewardConfig from './rewardConfig'
import shoppingCart from './shoppingCart'
import shoppingCartItem from './shoppingCartItem'
import storedProduct from './storedProduct'
import transaction from './transaction'
import updateModelInList from './updateModelInList'
import user from './user'
import wallet from './wallet'
import wish from './wish'

const modelHelpers = {
  asyncTask,
  campaign,
  chat,
  chatMessage,
  contact,
  contactEvent,
  contactEventReminder,
  invitation,
  memberMessage,
  memberMessageTemplate,
  merchant,
  offer,
  order,
  product,
  purchase,
  purchaseTransfer,
  reward,
  rewardConfig,
  shoppingCart,
  shoppingCartItem,
  storedProduct,
  transaction,
  user,
  wallet,
  wish,

  // General helpers:
  buildUpdatedObjectList,
  compareId,
  pickColumns,
  updateModelInList,
}

export default modelHelpers
