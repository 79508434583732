import React, { useEffect, useState, useContext } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonPage, IonContent, IonRefresher, IonRefresherContent, IonAlert } from '@ionic/react'
import { Update } from 'history'
import { useMutation, useQuery } from '@apollo/client'

import './styles.css'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type {
  DeleteTagData,
  DeleteTagVariables,
  TagsQueryData,
  TagsQueryVariables,
} from '../../../services/apollo/definitions'
import { useLocation, useHistory } from 'react-router-dom'
import type { TagListFilter } from '../../../lib/core/definitions'
import { UiMessage } from '../../../lib/core/enums'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import coreHelpers from '../../../lib/core/helpers'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import TagList from '../../components/TagList/TagList'
import TagListHeader from './TagListHeader'

const appPageId = AppPage.AdminTagsPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const TagsPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [filter, setFilter] = useState({} as TagListFilter | undefined)
  const [deleteTagId, setDeleteTagId] = useState<string | undefined>()
  const [searchText, setSearchText] = useState<string | undefined>()
  const [showConfirmDeleteTagAlert, setShowConfirmDeleteTagAlert] = useState(false)

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: tagsData,
    loading: isLoadingTags,
    refetch: reloadTags,
    error: tagsLoadingError,
  } = useQuery<TagsQueryData, TagsQueryVariables>(
    apollo.queries.tags, {
      variables: { filter },
      notifyOnNetworkStatusChange: true,
      onCompleted: (tagsQueryData) => {
        // try {
        //   if (tagsQueryData && tagsQueryData.tags) {
        //     const loadedTags = tagsQueryData.tags
        //     store.writeQuery({ query: apollo.queries.tags, data: tagsQueryData })
        //   }
        // } catch (error) {
        //   console.log(error)
        // }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
    },
  )
  const { tags } = tagsData || {}

  const [
    deleteTag, {
      loading: isDeletingTag,
    },
  ] = useMutation<DeleteTagData, DeleteTagVariables>(apollo.admin.mutations.deleteTag, {
    notifyOnNetworkStatusChange: true,
    onError (error) {
      console.error(error)
      setToastMessage(error.message)
      setShowToast(true)
    },

    update: (store, deleteTagData) => {
      try {
        if (
          deleteTagData &&
          deleteTagData.data &&
          deleteTagData.data.deleteTag &&
          deleteTagData.data.deleteTag.id
        ) {
          const deletedTagId = deleteTagData.data.deleteTag.id
          const tagsData = store.readQuery<TagsQueryData, TagsQueryVariables>({
            query: apollo.queries.tags,
            variables: { filter },
          })
          if (tagsData && tagsData.tags) {
            const filteredList = tagsData.tags.filter(t => t.id !== deletedTagId)
            store.writeQuery({
              query: apollo.queries.tags,
              variables: { filter },
              data: { tags: filteredList },
            })
          }
        }
      } catch (error) {
        console.log(error)
      }
    },

    onCompleted: (data) => {
      if (data && data.deleteTag && data.deleteTag.id) {
        setToastMessage(coreHelpers.type.uiMessage.getLabel(UiMessage.DATA_DELETED_SUCCESSFULLY))
        setShowToast(true)
      }
    },
  })

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  // })

  useEffect((): void => {
    if (tagsLoadingError) {
      pageHelpers.checkForUnauthorized(tagsLoadingError, navigate)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
    }
  }, [tagsLoadingError])

  // ===================================================================================================================
  // Event Handlers:
  const onApplyListFilter = (newSearchText: string): void => {
    console.log('TagsPage.onApplyListFilter called.', { newSearchText })
    if (newSearchText !== searchText) {
      setSearchText(newSearchText)
    }
  }

  const onDeleteTag = (tagId: string, confirmed = false): void => {
    if (!confirmed) {
      setDeleteTagId(tagId)
      setShowConfirmDeleteTagAlert(true)
      return
    }
    if (deleteTagId) {
      deleteTag({ variables: { tagId: deleteTagId } })
    }
  }

  const onEditTag = (tagId: string): void => {
    navigate(`${AppRoute.EDIT_TAG}/${tagId}`)
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadTags().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onAddTag = (): void => {
    navigate('/admin/add-tag')
  }

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingTags || isDeletingTag

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  return (
    <IonPage className='app-page-admin tags-page'>
      <NavBar
        title='Edit Tags'
        goBackUri={AppRoute.ADMIN_HOME}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <TagListHeader
          searchText={searchText}
          onAddTag={onAddTag}
          onApply={onApplyListFilter}
        />
        <TagList
          tags={tags}
          searchText={searchText}
          onDeleteTag={onDeleteTag}
          onEditTag={onEditTag}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonAlert
        isOpen={showConfirmDeleteTagAlert}
        onDidDismiss={(): void => { setShowConfirmDeleteTagAlert(false) }}
        header='Delete Tag'
        subHeader=''
        message='Are you sure you want to delete this tag?'
        buttons={[
          { text: 'Yes, delete', handler: (): void => { onDeleteTag('', true) } },
          { text: 'Cancel', cssClass: 'secondary', handler: (): void => { setShowConfirmDeleteTagAlert(false) } },
        ]}
      />
    </IonPage>
  )
}

export default TagsPage
