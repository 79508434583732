import React, { useEffect, useRef, useState } from 'react'
import { arrowBackOutline, informationCircleOutline } from 'ionicons/icons'
import { IonButton, IonIcon, IonSpinner, isPlatform } from '@ionic/react'
import { Keyboard } from '@capacitor/keyboard'

import './styles.css'
import type {
  Chat as IChat, ChatAttachmentInfo,
  ChatMessage,
  ChatMessageInput,
  User,
} from '../../lib/core/definitions'
import { ChatAttachmentType, ChatMessageType } from '../../lib/core/enums'
import { ChatLayout } from './enums'
import { ChatQueryData, ChatQueryVariables } from '../../services/apollo/definitions'
import { TopNavTabId } from '../TopNavBar/enums'
import { ApolloQueryResult, useApolloClient } from '@apollo/client'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../services/api'
import Avatar from '../Avatar/Avatar'
import coreHelpers from '../../lib/core/helpers'
import logger from '../../services/logger'
import Message from './ChatMessage/ChatMessage'
import NewChatMessage from '../NewChatMessage/NewChatMessage'
import pageHelpers from '../../helpers/pageHelpers'

type Props = {
  chat: IChat | null | undefined
  layout?: ChatLayout
  className?: string
  canAttachTokens?: boolean
  isAdminOrgChat?: boolean
  isOnActivePage: boolean
  isLoading: boolean
  onNewGift?: () => void
  onBack?: () => void
  onOpenAttachment?: (chatMessage: ChatMessage, chatAttachmentInfo: ChatAttachmentInfo) => void
  onOpenContact?: (contactId: string | null | undefined, userId: string | null | undefined, tabId: TopNavTabId) => void
  onGetPreviousMessagesBatch?: () => void
  reloadChat?: (variables?: Partial<ChatQueryVariables>) => Promise<ApolloQueryResult<ChatQueryData>>
}

const Chat: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('Chat.render called.', { props })
  const {
    chat,
    layout,
    className,
    canAttachTokens,
    isAdminOrgChat,
    isLoading,
    isOnActivePage,
    onNewGift,
    onBack,
    onOpenAttachment,
    onOpenContact,
    onGetPreviousMessagesBatch,
    reloadChat,
  } = props
  const chatId = chat && chat.id
  const messageCount = chat && Array.isArray(chat.messages) ? chat.messages.length : 0

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const messagesRef = useRef<HTMLDivElement>(null)
  const [autoScroll, setAutoScroll] = useState(true)
  const [loadMoreMessageId, setLoadMoreMessageId] = useState<string | undefined>()
  // const [isPreparing, setIsPreparing] = useState(true)

  // ===================================================================================================================
  // Helpers:
  const { contactInfo } = pageHelpers.getChatListInfo(chat, activeUserId, isAdminOrgChat)
  const {
    id: contactUserId,
    fullName: contactFullName,
    imageUrl: contactImageUrl,
    username: contactUsername,
  } = contactInfo

  // todo check out https://github.com/dizco/react-scrollable-feed/
  const scrollToBottom = (): void => {
    if (!autoScroll || !messagesRef.current) {
      console.log('Chat.scrollToBottom: turned off, or ref not available.', { autoScroll, ref: messagesRef.current })
      return
    }
    // console.log('Chat.scrollToBottom: scrolling to bottom.')
    // We're scrolling to 46 to account for the NewMessageSection height
    messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight)
  }

  // ===================================================================================================================
  // Effect Hooks:
  // useIonViewWillLeave(() => {
  //   setIsPreparing(true)
  // })

  useEffect((): void => {
    if (isPlatform('capacitor')) {
      Keyboard.addListener('keyboardWillShow', () => {
      // console.log('SendGiftPage: keyboard will show with height', info.keyboardHeight)
        scrollToBottom()
      })
    }
  }, [])

  useEffect(() => {
    // console.log('>>>>>>>>>>>>>messageCount=', messageCount)
    setTimeout(scrollToBottom, 500)
  }, [messageCount])

  // ===================================================================================================================
  // Helpers:
  let contactId: string | null | undefined

  // ===================================================================================================================
  // Event Handlers:
  const onCreateMessage = (chatMessageInput: ChatMessageInput): void => {
    // console.log('Chat.onCreateMessage called.', chatMessageInput)

    if (!chat) {
      console.error('Chat.onCreateMessage: no message given.')
      return
    }

    chatMessageInput.chatId = chatId

    if (isAdminOrgChat) {
      chatMessageInput.fromUserId = chat.fromUserId as string
      chatMessageInput.toUserId = chat.toUserId as string
      // chatMessageInput.messageType = ChatMessageType.SYSTEM
    }
    api.upsertChatMessage(
      chatMessageInput,
      apolloClient,
      undefined,
      { updateList: true },
    ).then((chatMessage) => {
      if (isAdminOrgChat && reloadChat) {
        reloadChat().then(() => {

        }, (error) => {
          console.error(error)
        })
      } else if (
        chatMessage &&
        chatMessageInput.tokenTransferAmount
      ) {
        // It takes the back-end a little while to create
        // the transaction and attach it to the chat message.
        api.loadChatMessage(
          chatMessage.id as string,
          (cm: ChatMessage): boolean => {
            if (
              !cm ||
              !cm.metadata ||
              !Array.isArray(cm.metadata.attachments) ||
              cm.metadata.attachments.length < 1
            ) {
              return false
            }
            return cm.metadata.attachments[0].attachmentType === ChatAttachmentType.TRANSACTION
          },
          apolloClient,
        ).then(scrollToBottom, (error) => {
          console.error(error)
        })
        return
      }
      scrollToBottom()
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenAttachmentLocal = (
    chatMessageId: string,
    chatAttachmentInfo: ChatAttachmentInfo,
  ): void => {
    // console.log('PurchasePage.onOpenAttachmentLocal called.', chatMessageId, chatAttachmentInfo)
    if (onOpenAttachment && chat && chat.messages) {
      const chatMessage = chat.messages.find((m: ChatMessage) => m.id === chatMessageId)
      if (chatMessage) {
        onOpenAttachment(chatMessage, chatAttachmentInfo)
      }
    }
  }

  const onMessageMovedIntoViewport = (message: ChatMessage): void => {
    // console.log('>>>>>>>>>>>onMessageMovedIntoViewport called.', { message })
    if (!isOnActivePage) {
      return
    }

    if (
      // !isPreparing &&
      onGetPreviousMessagesBatch &&
      chat &&
      Array.isArray(chat.messages) &&
      chat.messages.length > 9 &&
      message.id === loadMoreMessageId
    ) {
      // console.log('>>>>>>>>>onMessageMovedIntoViewport: will load more', message.id)
      onGetPreviousMessagesBatch()
    }

    if (
      !message.receivedAt &&
      coreHelpers.models.compareId(message.toUserId, activeUserId)
    ) {
      // console.log('>>>>>>>>>onMessageMovedIntoViewport: calling markChatMessagesAsReceived',
      //   { id: message.id, text: message.messageText })
      api.markChatMessagesOfChatAsReceived(
        [message],
        activeUserId,
        apolloClient,
      ).then(undefined, (error) => {
        logger.error('Chat.onMessageMovedIntoViewport: error received.', { message, error })
      })
    }
  }

  const onClickContactAvatar = () => {
    if (onOpenContact && (contactId || contactUserId)) {
      if (!contactId && !contactUserId) {
        logger.error('Chat.onClickContactAvatar: no contactId and contactUserId', { props })
        return
      }
      onOpenContact(contactId, contactUserId, TopNavTabId.CONTACT_PROFILE)
    }
  }

  // const onMessagesScroll = (event: any) => {
  //   // console.log('onMessagesScroll called.', {
  //   //   scrollHeight: event.nativeEvent.target.scrollHeight,
  //   //   scrollTop: event.nativeEvent.target.scrollTop,
  //   //   clientHeight: messagesRef.current.clientHeight,
  //   //   gap: event.nativeEvent.target.scrollHeight - event.nativeEvent.target.scrollTop - messagesRef.current.clientHeight,
  //   //   ref: messagesRef.current,
  //   // })
  //   if (
  //     isPreparing &&
  //     messagesRef.current &&
  //     event.nativeEvent &&
  //     event.nativeEvent.target &&
  //     event.nativeEvent.target.scrollHeight - event.nativeEvent.target.scrollTop - messagesRef.current.clientHeight < 10
  //   ) {
  //     console.log('onMessagesScroll: turning off isPreparing.')
  //     setIsPreparing(false)
  //   }
  // }

  // ===================================================================================================================
  // Rendering:
  // console.log('Chat.render called.', { chat })
  if (!chat) {
    // console.log('Chat: no data', { chat, composedChat, chat, chatLoadedData })
    return null
  }

  const avatarUser: User = {
    fullName: contactFullName,
    imageUrl: contactImageUrl,
  }

  let renderedMessages
  const messages = chat.messages
  if (Array.isArray(messages) && messages.length > 0) {
    let latestMessageWithAnimationId: string | null | undefined
    const unreadMessagesWithAnimation = messages.filter(m => m.animation && !m.receivedAt)
    if (Array.isArray(unreadMessagesWithAnimation) && unreadMessagesWithAnimation.length > 0) {
      latestMessageWithAnimationId = (unreadMessagesWithAnimation[unreadMessagesWithAnimation.length - 1]).id
    }
    renderedMessages = messages.slice(0).reverse().map((msg: ChatMessage): any => {
      const isFromLocalUser = isAdminOrgChat
        ? coreHelpers.models.compareId(msg.fromUserId, chat.fromUserId)
        : coreHelpers.models.compareId(msg.fromUserId, activeUserId)
      const isLatestUnreadMessageWithAnimation = msg.id === latestMessageWithAnimationId

      if (msg.messageType === ChatMessageType.SYSTEM && contactUsername !== 'mimble') {
        return (
          <div key={msg.id} className='system-message-row withCenteredTextAlign'>
            <Message
              chatMessage={msg}
              attachments={msg.metadata ? msg.metadata.attachments : undefined}
              position='center'
              isFromLocalUser={isFromLocalUser}
              isLatestUnreadMessageWithAnimation={isLatestUnreadMessageWithAnimation}
              isOnActivePage={isOnActivePage}
              onMovedIntoViewport={onMessageMovedIntoViewport}
              onOpenAttachment={onOpenAttachmentLocal}
            />
          </div>
        )
      }

      if (isFromLocalUser) {
        return (
          <div key={msg.id} className='sent-message-row'>
            <Message
              chatMessage={msg}
              attachments={msg.metadata ? msg.metadata.attachments : undefined}
              position='right'
              isFromLocalUser
              isLatestUnreadMessageWithAnimation={isLatestUnreadMessageWithAnimation}
              isOnActivePage={isOnActivePage}
              onOpenAttachment={onOpenAttachmentLocal}
              onMovedIntoViewport={onMessageMovedIntoViewport}
            />
          </div>
        )
      }

      return (
        <div key={msg.id} className='received-message-row'>
          <Avatar
            user={avatarUser}
            className='from-user-avatar'
            onClick={onClickContactAvatar}
          />
          <Message
            chatMessage={msg}
            attachments={msg.metadata ? msg.metadata.attachments : undefined}
            position='left'
            isFromLocalUser={isFromLocalUser}
            isLatestUnreadMessageWithAnimation={isLatestUnreadMessageWithAnimation}
            isOnActivePage={isOnActivePage}
            onOpenAttachment={onOpenAttachmentLocal}
            onMovedIntoViewport={onMessageMovedIntoViewport}
          />
        </div>
      )
    })
  } else {
    renderedMessages = (
      <div className='rowWithCenterJustifiedItems lightText withDoubleTopMargin'>
        <IonIcon icon={informationCircleOutline} className='withSmallRightMargin' />
        This chat has no messages yet.
      </div>
    )
  }

  if (layout === ChatLayout.FULL_PAGE) {
    return (
      <div className={`chat full-page ${className || ''}`}>
        <div
          ref={messagesRef}
          className='messages-content'
          // onScroll={onMessagesScroll}
        >
           {/* <ProcessingOverlay text='loading messages' isProcessing={isPreparing} /> */}
          {renderedMessages}
        </div>
        <NewChatMessage
          activeUserId={activeUserId}
          contactUserId={contactUserId}
          canAttachTokens={canAttachTokens}
          canAttachGift
          onNewGift={onNewGift}
          onCreateMessage={onCreateMessage}
          onClickInput={scrollToBottom}
        />
      </div>
    )
  }

  let backButton
  if (onBack) {
    backButton = (
      <IonButton
        size='small'
        fill='clear'
        color='medium'
        onClick={onBack}
      >
        <IonIcon
          slot='start'
          icon={arrowBackOutline}
        />
      </IonButton>
    )
  }

  let busyIndicator: JSX.Element | undefined
  if (isLoading) {
    busyIndicator = (
      <div className='withCenteredColumnContent'>
        <IonSpinner className='spinner' />
      </div>
    )
  }

  return (
    <div key='chat-content' className={`chat embedded ${className || ''}`}>
      <div className='caption'>
        {backButton}
        <Avatar
          user={avatarUser}
          className='withStandardRightMargin'
        />
        {contactFullName}
      </div>
      <div ref={messagesRef} className='messages-content'>
        {busyIndicator}
        {renderedMessages}
      </div>
      <NewChatMessage
        activeUserId={activeUserId}
        contactUserId={contactUserId}
        onCreateMessage={onCreateMessage}
      />
    </div>
  )
}

export default Chat
