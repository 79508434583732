import React from 'react'

import { PurchaseStatus, RecordStatus } from '../../lib/core/enums'
import {
  archiveOutline,
  gift,
  openOutline,
  optionsOutline,
  receiptOutline,
  trash,
} from 'ionicons/icons'
import IconButton from '../IconButton/IconButton'

type ActionDef = {
  key: string;
  icon: string;
  text: string;
  iconColor?: string;
  textColor?: string;
  handler: () => void;
}

export type Props = {
  purchaseId?: string;
  purchaseStatus?: PurchaseStatus;
  recordStatus?: RecordStatus;
  onEditPurchase?: (purchaseId: string) => void;
  onArchivePurchase?: (purchaseId: string) => void;
  onAcceptGift?: (purchaseId: string) => void;
  onUnArchivePurchase?: (purchaseId: string) => void;
  onDeletePurchase?: (purchaseId: string) => void;
  onTransferPurchase?: (purchaseId: string) => void;
  onDownloadPurchase?: () => void;
  onVisitMerchant?: () => void;
  className?: string;
}

const ActionPane: React.FC<Props> = (props): JSX.Element => {
  // console.log('GiftCardHeader.ActionPane.render called.', props)
  const {
    purchaseId,
    purchaseStatus,
    recordStatus,
    onEditPurchase,
    onDeletePurchase,
    onDownloadPurchase,
    onAcceptGift,
    onArchivePurchase,
    onUnArchivePurchase,
    onTransferPurchase,
    onVisitMerchant,
  } = props

  const actionDefs: ActionDef[] = []
  if (
    purchaseId &&
    purchaseStatus === PurchaseStatus.ACTIVE &&
    recordStatus === RecordStatus.ACTIVE &&
    onTransferPurchase
  ) {
    actionDefs.push({
      key: 'send',
      icon: gift,
      text: 'Gift',
      // iconColor: '#f1a22b',
      // textColor: '#f1a22b',
      // iconColor: '#4354ae',
      // textColor: '#4354ae',
      // iconColor: '#900020',
      // textColor: '#900020',
      iconColor: '#f6d556',
      textColor: '#b79710',
      handler: () => onTransferPurchase(purchaseId),
    })
  }

  if (onVisitMerchant) {
    actionDefs.push({
      key: 'brand',
      icon: openOutline,
      text: 'Brand',
      handler: onVisitMerchant,
    })
  }

  if (
    purchaseId &&
    onEditPurchase &&
    recordStatus === RecordStatus.ACTIVE &&
    purchaseStatus === PurchaseStatus.ACTIVE
  ) {
    actionDefs.push({
      key: 'update',
      icon: optionsOutline,
      text: 'Update',
      handler: () => onEditPurchase(purchaseId),
    })
  }

  if (
    onDownloadPurchase &&
    recordStatus === RecordStatus.ACTIVE &&
    purchaseStatus === PurchaseStatus.ACTIVE
  ) {
    actionDefs.push({
      key: 'print',
      icon: receiptOutline,
      text: 'Print',
      handler: onDownloadPurchase,
    })
  }

  if (
    purchaseId &&
    onArchivePurchase &&
    recordStatus === RecordStatus.ACTIVE
  ) {
    actionDefs.push({
      key: 'archive',
      icon: archiveOutline,
      text: 'Archive',
      handler: () => onArchivePurchase(purchaseId),
    })
  } else if (
    purchaseId &&
    onUnArchivePurchase &&
    recordStatus === RecordStatus.INACTIVE
  ) {
    actionDefs.push({
      key: 'unarchive',
      icon: archiveOutline,
      text: 'Unarchive',
      handler: () => onUnArchivePurchase(purchaseId),
    })
  }

  if (
    purchaseId &&
    onDeletePurchase &&
    recordStatus === RecordStatus.INACTIVE
  ) {
    actionDefs.push({
      key: 'del',
      icon: trash,
      text: 'Delete',
      handler: () => onDeletePurchase(purchaseId),
    })
  }

  const buttons = actionDefs.map((def) => (
    <IconButton
      key={def.key}
      icon={def.icon}
      text={def.text}
      layout='vertical'
      iconSize={30}
      iconColor={def.iconColor}
      textColor={def.textColor}
      onClick={def.handler}
    />
  ))

  return (
    <div className='action-pane'>
      {buttons}
    </div>
  )
}

export default ActionPane
