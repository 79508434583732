import moment from 'moment'
import React, { useContext, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { chatboxOutline, keyOutline, pencilOutline } from 'ionicons/icons'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import { FiatCurrency, FundType, TokenName, UiMessage } from '../../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type { UserQueryData, UserQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import Avatar from '../../../components/Avatar/Avatar'
import coreHelpers from '../../../lib/core/helpers'
import IconButton from '../../../components/IconButton/IconButton'
import type { InfoPaneEntryDef } from '../../../components/InfoPane/InfoPane'
import InfoPane from '../../../components/InfoPane/InfoPane'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'

const appPageId = AppPage.AdminUserPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  userId: string
}

const UserPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { userId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { setProxyUser } = useGlobalCache()
  const { reloadActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading user:
  const {
    data: userLoadedData,
    loading: isLoadingUser,
    refetch: reloadUser,
  } = useQuery<UserQueryData, UserQueryVariables>(
    apollo.admin.queries.user, {
      // client: apollo.getApolloClient(),
      variables: { userId: userId as string },
      skip: !userId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: UserQueryData) => {
        const returnedUser = data ? data.user : undefined
        if (returnedUser === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const user = userLoadedData ? userLoadedData.user : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingUser

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:
  // useIonViewWillEnter(() => {
  // })

  // ===================================================================================================================
  // Event Handlers:
  const onEditUser = (): void => {
    if (!userId) {
      return
    }
    navigate(`/admin/edit-user/${userId}`)
  }

  const onSignInAs = (): void => {
    if (!user) {
      return
    }
    if (!user) {
      return
    }
    setProxyUser(user)
    setTimeout(() => {
      reloadActiveUser().then(() => {
        if (
          location &&
          location.pathname &&
          location.pathname !== AppRoute.HOME
        ) {
          navigate(AppRoute.HOME)
        }
      }, (error) => {
        console.error(error)
      })
    }, 500)
  }

  const onOpenOrgChat = (): void => {
    pageMessages && pageMessages.clear()
    navigate(`${AppRoute.ADMIN_ORG_CHAT}/-/${userId}`)
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !userId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadUser({ userId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content: JSX.Element | undefined
  if (user) {
    let infoPaneEntries: InfoPaneEntryDef[] = [
      {
        key: 'id',
        label: 'ID',
        value: (user.id as string).replace(/-/g, ''),
      },
      {
        key: 'username',
        label: 'Handle',
        value: `@${user.username}`,
      },
      {
        key: 'name',
        label: 'Name',
        value: user.fullName,
      },
      {
        key: 'email',
        label: 'Email',
        value: user.email,
      },
      {
        key: 'phone',
        label: 'Phone number',
        value: user.phoneNumber,
      },
      {
        key: 'bd',
        label: 'Birthday',
        value: user.birthday,
      },
      {
        key: 'invitecode',
        label: 'Invite Code',
        value: user.inviteCode,
      },
      {
        key: 'source',
        label: 'Source',
        value: user.source,
      },
      {
        key: 'roles',
        label: 'Roles',
        value: user.roles as string,
      },
      {
        key: 'appfeatures',
        label: 'App Features',
        value: user.appFeatures as string,
      },
      {
        key: 'maxPurchaseAmountCc',
        label: 'Daily CC Limit',
        value: coreHelpers.ui.formatAmount(user.maxPurchaseAmountCc, FundType.FIAT, FiatCurrency.USD, true),
      },
      {
        key: 'joined',
        label: 'Joined',
        value: coreHelpers.ui.formatDate(user.createdAt, 'M/D/Y hh:mm a'),
      },
    ]

    if (user.metadata) {
      const stats = [
        ['purchased', user.metadata.purchasedGiftCardCount],
        ['uploaded', user.metadata.uploadedGiftCardCount],
        ['pending', user.metadata.pendingGiftCardCount],
        ['received', user.metadata.receivedGiftCardCount],
        ['received-active', user.metadata.receivedActiveGiftCardCount],
        ['received-declined', user.metadata.receivedDeclinedGiftCardCount],
        ['received-failed', user.metadata.receivedFailedGiftCardCount],
        ['received-pending', user.metadata.receivedPendingGiftCardCount],
        ['sent', user.metadata.sentGiftCardCount],
        ['sent-active', user.metadata.sentActiveGiftCardCount],
        ['sent-declined', user.metadata.sentDeclinedGiftCardCount],
        ['sent-failed', user.metadata.sentFailedGiftCardCount],
        ['sent-pending', user.metadata.sentPendingGiftCardCount],
      ]
      infoPaneEntries.push(
        {
          key: 'gcs',
          label: 'Gift cards',
          value: stats.map(l => `${l[0]}: ${l[1]}`).join(', '),
        },
      )
      if (user.metadata.mimbleBalanceToken || user.metadata.mimbleBalanceToken === 0) {
        infoPaneEntries.push(
          {
            key: 'mit-bal',
            label: 'Token wallet balance',
            value: coreHelpers.ui.formatAmount(
              user.metadata.mimbleBalanceToken,
              FundType.TOKEN,
              TokenName.MIMBLE_TOKEN,
              true),
          },
        )
      }
    }

    if (user.latestUserDevice) {
      infoPaneEntries = infoPaneEntries.concat([
        {
          key: 'appvers',
          label: 'App Version',
          value: user.latestUserDevice.appVersion,
        },
        {
          key: 'os',
          label: 'Platform',
          value: user.latestUserDevice.os,
        },
        {
          key: 'timezone',
          label: 'Timezone',
          value: user.latestUserDevice.timezone,
        },
        {
          key: 'ip-address',
          label: 'IP Address',
          value: user.latestUserDevice.ipAddress,
        },
      ])
      if (user.latestUserDevice.signedInAt) {
        infoPaneEntries.push({
          key: 'signed-in',
          label: 'Signed In',
          value: moment(user.latestUserDevice.signedInAt).toISOString(),
        })
      }
      if (user.latestUserDevice.signedOutAt) {
        infoPaneEntries.push({
          key: 'signed-out',
          label: 'Signed Out',
          value: moment(user.latestUserDevice.signedOutAt).toISOString(),
        })
      }
      if (user.latestUserDevice.sessionStartedAt) {
        infoPaneEntries.push({
          key: 'session-start',
          label: 'Session Started',
          value: moment(user.latestUserDevice.sessionStartedAt).toISOString(),
        })
      }
      if (user.latestUserDevice.sessionEndedAt) {
        infoPaneEntries.push({
          key: 'session-end',
          label: 'Session Ended',
          value: moment(user.latestUserDevice.sessionEndedAt).toISOString(),
        })
      }
    }

    content = (
      <>
        <div className='row withStandardBottomMargin'>
          <Avatar
            user={user}
            className='avatar'
          />
          <div className='caption'>
            {user.fullName}
          </div>
          <IconButton
            key='edit'
            icon={pencilOutline}
            text='Edit'
            layout='vertical'
            className='button'
            iconSize={20}
            onClick={onEditUser}
          />
          <IconButton
            key='signinas'
            icon={keyOutline}
            text='Sign In As'
            layout='vertical'
            className='button'
            iconSize={20}
            onClick={onSignInAs}
          />
          <IconButton
            key='chat'
            icon={chatboxOutline}
            text='Support'
            layout='vertical'
            className='button'
            iconSize={20}
            onClick={onOpenOrgChat}
          />
        </div>
        <div>
          <div className='withRoundedBorder withStandardBottomMargin'>
            <InfoPane
              entries={infoPaneEntries}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <IonPage className='app-page-admin  user-page'>
      <NavBar
        title='Manage User'
        goBackUri={AppRoute.ADMIN_USERS}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
          {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default UserPage
