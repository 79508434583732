import React from 'react'
import { IonIcon } from '@ionic/react'
import { ribbon } from 'ionicons/icons'

import type { Currency } from '../../../lib/core/definitions'
import type { FundType } from '../../../lib/core/enums'
import coreHelpers from '../../../lib/core/helpers/'

type Props = {
  amount: number
  label?: string
  fundType: FundType
  currency: Currency
  precision: number
  className?: string
  withBorder?: boolean
  showIcon?: boolean
}

const ItemReward: React.FC<Props> = (props): JSX.Element | null => {
  const {
    amount,
    label,
    fundType,
    currency,
    precision,
    className,
    withBorder,
    showIcon,
  } = props

  if (!amount) {
    return null
  }

  const formattedAmount = coreHelpers.ui.formatAmount(
    amount,
    fundType,
    currency,
    true,
    precision,
  )

  let mergedClassName = withBorder ? 'item-reward with-border' : 'item-reward'
  if (className) {
    mergedClassName += ` ${className || ''}`
  }

  let icon: JSX.Element | undefined
  if (showIcon) {
    icon = <IonIcon icon={ribbon} className='icon' />
  }
  return (
    <div className={mergedClassName}>
      {icon} <span className='label'>{label}</span> {formattedAmount}
    </div>
  )
}

ItemReward.defaultProps = {
  label: 'Reward:',
}

export default ItemReward
