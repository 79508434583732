import type { ApolloClient } from '@apollo/client'
import firebase from '../firebase'
import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import type { SignOutVariables } from '../apollo/definitions'
import apollo from '../apollo'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

const signOut = (
  signOutOfMimbleServer: boolean,
  signOutOfFirebase: boolean,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('auth.signOut called.', { signOutOfMimbleServer, signOutOfFirebase })
  const activeUser = globalCacheData.getActiveUser()

  if (!activeUser || !activeUser.id) {
    console.error('auth.signOut: no activeUser available.')
    return
  }

  if (signOutOfMimbleServer) {
    console.log('auth.signOut: signing out of the mimble server.')
    if (!apolloClient) {
      console.error('firebase.callbacks.authStateChanged: no Apollo client available.')
      return
    }

    apolloClient.mutate<undefined, SignOutVariables>({
      mutation: apollo.mutations.signOut,
      variables: { userId: activeUser.id },
    }).then(() => {
      if (signOutOfFirebase && activeUser && activeUser.oAuthProvider) {
        console.log('auth.signOut: signing out of firebase.')
        firebase.signOut()
      }

      console.log('auth.signOut: clearing global cache.')
      globalCacheData.clearSessionData()
      if (activeUser && activeUser.id) {
        globalCacheData.setValue(GlobalCacheDataKey.SIGNED_OUT_USER_ID, activeUser.id, true)
      }

      console.log('auth.signOut: clearing Apollo cache.')
      apolloClient.clearStore().then(undefined, (error) => {
        console.error(error)
      })
    }, (error) => {
      console.error(error)
    })

    console.log('auth.signOut: done.')
    return
  }

  if (signOutOfFirebase && activeUser && activeUser.oAuthProvider) {
    console.log('auth.signOut: signing out of firebase.')
    firebase.signOut()
  }

  console.log('auth.signOut: clearing global cache.')
  globalCacheData.clearSessionData()
  if (activeUser && activeUser.id) {
    globalCacheData.setValue(GlobalCacheDataKey.SIGNED_OUT_USER_ID, activeUser.id, true)
  }

  if (apolloClient) {
    console.log('auth.signOut: clearing Apollo cache.')
    apolloClient.clearStore().then(undefined, (error) => {
      console.error(error)
    })
  }

  console.log('auth.signOut: done.')
}

export default signOut
