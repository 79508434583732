const buildUpdatedObjectList = ({
  oldList,
  newList,
  deleteFromOldList = false,
  keyField = 'id',
}: {
  oldList: any;
  newList: any;
  deleteFromOldList?: boolean;
  keyField?: string;
}): any => {
  if (!Array.isArray(newList) || newList.length < 1) {
    return oldList
  }
  if (!Array.isArray(oldList) || oldList.length < 1 || deleteFromOldList) {
    return newList
  }
  return oldList.map(oldItem => (
    newList.find(newItem => newItem[keyField] === oldItem[keyField]) || oldItem
  )).concat(newList.filter(newItem => !oldList.find(oldItem => oldItem[keyField] === newItem[keyField])))
}

export default buildUpdatedObjectList
