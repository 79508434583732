import moment from 'moment'
import React, { useEffect } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { ribbon } from 'ionicons/icons'

import '../styles.css'
import type { Reward } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import type { RewardType } from '../../../lib/core/enums'
import type { InfoPaneEntryDef } from '../../InfoPane/InfoPane'
import coreHelpers from '../../../lib/core/helpers'
import InfoPane from '../../InfoPane/InfoPane'

type Props = {
  reward: Reward
  className?: string
  onOpenReward?: (rewardId: string) => void
}

const RewardObjectInfo: React.FC<Props> = (props): JSX.Element | null => {
  const { reward, className, onOpenReward } = props
  const { rewardConfigs, getRewardConfigs } = useMimbleData()

  useEffect(() => {
    if (!rewardConfigs) {
      getRewardConfigs()
    }
  }, [rewardConfigs])

  const entries: InfoPaneEntryDef[] = [
    {
      key: 'type',
      label: 'Type',
      value: coreHelpers.type.rewardType.getLabel(
        reward.rewardType as RewardType,
        reward.occurrence,
        reward.invitationLevel,
        rewardConfigs,
      ),
    },
    {
      key: 'date',
      label: 'Date',
      value: moment(Number(reward.createdAt)).format('MM/DD/YYYY hh:mm a'),
    },
    {
      key: 'amount',
      label: 'Amount',
      value: coreHelpers.ui.formatAmount(
        reward.amount,
        reward.fundType,
        reward.currency,
        true,
        0,
      ),
    },
    {
      key: 'description',
      label: 'Description',
      value: coreHelpers.type.rewardType.getDescription(
        reward.rewardType as RewardType,
        reward.occurrence,
        reward.invitationLevel,
        rewardConfigs,
      ),
    },
  ]

  let openButton: JSX.Element | undefined
  if (onOpenReward && reward.id) {
    openButton = (
      <IonButton
        size='small'
        fill='outline'
        onClick={() => onOpenReward(reward.id as string)}
      >
        Open
      </IonButton>
    )
  }
  return (
    <div className='object-info'>
      <div className={`withStandardBottomMargin section-header ${className || ''}`}>
        <IonIcon icon={ribbon} className='section-icon' /> Reward
      </div>
      <div className='section-content'>
        <InfoPane
          entries={entries}
        />
        {openButton}
      </div>
    </div>
  )
}

export default RewardObjectInfo
