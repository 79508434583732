import { gql } from '@apollo/client'

export default gql`
  mutation M($tag: TagInput!) {
    upsertTag (tag: $tag) {
      id
      parentTagId
      name
      captionEn
      tagType
      aliases
      priority
      lat
      lon
      place
      region
      countryCode
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }
`
