import { isPlatform } from '@ionic/react'
import { PushNotifications } from '@capacitor/push-notifications'
import { FCM } from '@capacitor-community/fcm'
import type { ApolloClient } from '@apollo/client'
import { PermissionStatus } from '@capacitor/push-notifications/dist/esm/definitions'

import { GlobalCacheDataKey } from '../../../contexts/GlobalCacheContext/enum'
import globalCacheData from '../../../contexts/GlobalCacheContext/contextData'
import pushNotificationActionPerformed from '../callbacks/pushNotificationActionPerformed'
import pushNotificationReceived from '../callbacks/pushNotificationReceived'
import reportPushNotificationTokenToServer from '../reportPushNotificationTokenToServer'

// https://capacitorjs.com/docs/guides/push-notifications-firebase
// https://capacitorjs.com/docs/apis/push-notifications
// https://enappd.com/blog/firebase-push-notification-in-ionic-react-capacitor/111/
// https://githubplus.com/capacitor-community/fcm
// token issues: https://github.com/ionic-team/capacitor-plugins/issues?q=is%3Aissue+token
// https://stackoverflow.com/questions/70570848/capacitor-push-notification-and-fcm-generate-different-tokens-crashes-android
const initFcm = async (
  reportToServerEvenIfTokenDidNotChange: boolean,
  apolloClient: ApolloClient<any>,
): Promise<void> => {
  console.log('services.firebase.initFcm called.')

  // Are we running in a browser on the desktop?
  if (!isPlatform('capacitor')) {
    // skipping on web
    return
  }

  let permStatus: PermissionStatus | undefined
  try {
    permStatus = await PushNotifications.checkPermissions()
  } catch (error) {
    console.error('services.firebase.initFcm: PushNotifications.register failed.', error)
    globalCacheData.clearValue(GlobalCacheDataKey.FCM_TOKEN)
  }

  if (permStatus && permStatus.receive === 'prompt') {
    try {
      permStatus = await PushNotifications.requestPermissions()
    } catch (error) {
      console.error('services.firebase.initFcm: PushNotifications.requestPermissions failed.', error)
    }
  }


  if (permStatus && permStatus.receive !== 'granted') {
    console.log('services.firebase.initFcm: User denied permissions to receive push notifications.')
    globalCacheData.clearValue(GlobalCacheDataKey.FCM_TOKEN)
    return
  }

  try {
    await PushNotifications.register()
  } catch (error) {
    console.error('services.firebase.initFcm: PushNotifications.register failed.', error)
    globalCacheData.clearValue(GlobalCacheDataKey.FCM_TOKEN)
  }

  await PushNotifications.addListener('registration', (tokenObj) => {
    console.log('services.firebase.initFcm: registration token: ', tokenObj.value)

    const handleReceivedToken = (token: string): void => {
      console.log('services.firebase.initFcm.handleReceivedToken called.', token)
      // Has the token changed since it was last saved into local storage?
      const savedToken = globalCacheData.getValue(GlobalCacheDataKey.FCM_TOKEN)
      if (
        savedToken &&
        token === savedToken &&
        !reportToServerEvenIfTokenDidNotChange
      ) {
        // The token has not changed since last
        console.log('services.firebase.initFcm: the token has not changed.', { token })
        return
      }

      console.log('services.firebase.initFcm: New FCM token received; saving into global cache.', token)
      globalCacheData.setValue(GlobalCacheDataKey.FCM_TOKEN, token)

      reportPushNotificationTokenToServer(token, apolloClient)
    }

    if (isPlatform('android')) {
      handleReceivedToken(tokenObj.value)
      return
    }

    if (isPlatform('ios')) {
      // On iOS we receive the APN token, not the FCM token.
      FCM.getToken()
        .then((r) => {
          handleReceivedToken(r.token)
        })
        .catch((error) => console.log('FCM.getToken failed with error.', error))
    }
  })

  await PushNotifications.addListener('registrationError', err => {
    console.error('services.firebase.initFcm: Registration error: ', err.error)
    globalCacheData.clearValue(GlobalCacheDataKey.FCM_TOKEN)
    reportPushNotificationTokenToServer(undefined, apolloClient)
  })

  await PushNotifications.addListener('pushNotificationReceived', pushNotificationReceived)
  await PushNotifications.addListener('pushNotificationActionPerformed', pushNotificationActionPerformed)
}

export default initFcm
