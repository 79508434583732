import { gql } from '@apollo/client'

export default gql`
  query Q($merchantId: String!) {
    merchant(merchantId: $merchantId) {
      id
      userId
      name
      alias1
      alias2
      alias3
      organizationType
      description
      status
      isDonationTarget
      listed
      url
      imageSource
      smallLogoImageSource
      largeLogoImageSource
      balanceLookupUri
      paymentTypes
      sortIndex
      metadata
      source
      adminNotes
      recordStatus
      createdAt
      updatedAt
      mainProduct {
        id
        merchantId
        offerId
        productType
        code
        hasBarcode
        barcodeFormat
        referenceUrl
        messageEn
        contentFrontSide
        contentBackSide
        termsEn
        termsUrl
        instructionsEn
        instructionsUrl
        imageSourceFrontSide
        imageSourceBackSide
        isMainProduct
        hasPin
        stock
        listed
        # metadata {}
        recordStatus
        createdAt
        updatedAt
        productOptions {
          id
          productId
          orderIndex
          labelEn
          amount
          fundType
          currency
          reward
          discount
          inventory
          listed
          # metadata {}
          source
          recordStatus
          createdAt
          updatedAt
        }
      }
    }
  }`
