import { IonButton, IonIcon, IonInput } from '@ionic/react'
import { close } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'

import './styles.css'
import FormItem from '../FormItem/FormItem'
import InfoPaneEntry from '../InfoPaneEntry/InfoPaneEntry'
import SubmitButton from '../../components/SubmitButton/SubmitButton'

type Props = {
  id: string
  value: string | null | undefined
  label: string
  placeholder?: string
  validationError?: string | undefined
  className?: string
  isProcessing?: boolean
  onChange: (id: string, value: string | null | undefined) => void
  onSave: (id: string, value: string | null | undefined) => void
  onClick?: (id: string) => void
}

const InPlaceInput: React.FC<Props> = (props): JSX.Element | null => {
  const {
    id,
    value,
    label,
    placeholder,
    validationError,
    className,
    isProcessing,
    onClick,
    onChange,
    onSave,
    children,
  } = props

  // ===================================================================================================================
  // State:
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState<string | null | undefined>()

  // ===================================================================================================================
  // Helpers:
  const enableSubmitButton = (
    editedValue !== undefined &&
    editedValue !== value &&
    !(editedValue === null && value === undefined) &&
    !validationError
  )

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  useEffect(() => {
    if (!isProcessing) {
      setIsEditing(false)
    }
  }, [isProcessing])

  // ===================================================================================================================
  // Event Handlers:
  const handleClick = (): void => {
    if (!isEditing) {
      setIsEditing(true)
    }
    if (onClick) {
      onClick(id)
    }
  }

  const handleSave = (): void => {
    if (enableSubmitButton) {
      onSave(id, editedValue)
    }
  }

  const handleCancel = (): void => {
    setIsEditing(false)
    setEditedValue(undefined)
  }

  const onChangeValue = (event: any): void => {
    setEditedValue(event.detail.value || null)
    onChange(id, event.detail.value || null)
  }

  // ===================================================================================================================
  // Rendering:
  if (isEditing) {
    return (
      <div className={`in-place-input ${className || ''}`} onClick={handleClick}>
        <FormItem label={label} validationError={validationError} className='g-with-flex-1'>
          <div className='row'>
            <IonInput
              // type='tel'
              // inputmode='tel'
              onSubmit={handleSave}
              onIonChange={onChangeValue}
              placeholder={placeholder}
              value={editedValue === null ? '' : (editedValue || value)}
              autocomplete='off'
            />
            <SubmitButton
              size='small'
              isProcessing={isProcessing}
              onClick={handleSave}
              disabled={!enableSubmitButton}
            >
              Save
            </SubmitButton>
            <IonButton
              size='small'
              color='medium'
              fill='clear'
              onClick={handleCancel}
              className='withSmallLeftMargin'
            >
              <IonIcon icon={close} />
            </IonButton>
          </div>
        </FormItem>
      </div>
    )
  }

  if (children) {
    return (
      <div className={`in-place-input ${className || ''}`} onClick={handleClick}>
        {children}
      </div>
    )
  }

  return (
    <div className={`in-place-input ${className || ''}`} onClick={handleClick}>
      <InfoPaneEntry
        label={label}
        value={value}
        isInPlaceEditable
      />
    </div>
  )
}

InPlaceInput.defaultProps = {
  placeholder: '--',
}

export default InPlaceInput
