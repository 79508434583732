import type { ReactElement } from 'react'
import React from 'react'
import Text from './Text'

type Props = {
  htmlElement: ReactElement | null
}

const Span: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('###########################Span.render called.', { props })
  const { htmlElement } = props
  if (!htmlElement) {
    return null
  }

  return (
    <Text style={htmlElement.props.style} htmlElement={htmlElement} />
  )
}
export default Span
