import serviceData from './serviceData'
import fetchLatestVersion from './fetchLatestVersion'

let isFetchingLatestVersion = false

export default (refresh = false): Promise<string | undefined> => {
  // console.log('versionUtils.getLatestVersion called.', { refresh });

  return new Promise((resolve, reject) => {
    const latestVersion = serviceData.getLatestVersion()
    if (latestVersion && !refresh) {
      resolve(latestVersion)
      return
    }

    if (isFetchingLatestVersion) {
      const listenerName = new Date().getTime().toString()
      const listener = (version: string | undefined): void => {
        resolve(version)
        serviceData.removeVersionListener(listenerName)
        isFetchingLatestVersion = false
      }
      serviceData.addVersionListener(listenerName, listener)
      return
    }

    isFetchingLatestVersion = true
    fetchLatestVersion().then((version) => {
      resolve(version)
      isFetchingLatestVersion = false
    }, reject)
  })
}
