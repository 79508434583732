import React, { useState } from 'react'
import { IonButton, IonInput } from '@ionic/react'

import './styles.css'
import type { UserInput } from '../../../../lib/core/definitions'
import { useMimbleData } from '../../../../contexts/MimbleDataContext/MimbleDataContext'
import FormItem from '../../../../components/FormItem/FormItem'
import SubmitButton from '../../../../components/SubmitButton/SubmitButton'
import validationHelpers from '../../../../helpers/validationHelpers'

type Props = {
  onClose: () => void
  showUiMessage: (message: string) => void
}

const NameForm: React.FC<Props> = ({
  onClose,
  showUiMessage,
}): JSX.Element => {
  // ===================================================================================================================
  // State:
  const {
    activeUser,
    isUpdatingActiveUser,
    updateActiveUser,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [value, setValue] = useState<string | null | undefined>()
  const [validationError, setValidationError] = useState<string | undefined>()

  const oldValue = activeUser && activeUser.fullName

  // ===================================================================================================================
  // Helpers:
  const isDirty = (!!value && value !== oldValue) || value === null
  const isValid = !!value && !validationError

  const resetForm = (): void => {
    setValue(undefined)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeValue = (event: any): void => {
    if (event.detail.value && event.detail.value !== oldValue) {
      setValue(event.detail.value)
      setValidationError(validationHelpers.validateUserFullName(event.detail.value))
    } else {
      setValue(event.detail.value === oldValue ? undefined : null)
      setValidationError(undefined)
    }
  }

  const onSave = (event: any): void => {
    event.preventDefault()

    const userInput: UserInput = { id: activeUserId, fullName: value }

    updateActiveUser(userInput).then(() => {
      showUiMessage('Successfully updated your name.')
      onClose()
      resetForm()
    }, (error) => {
      console.error(error)
      showUiMessage('Failed to update your name. Please try again.')
    })
  }

  const onCancel = () => {
    onClose()
    resetForm()
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='user-account-name-form'>
      <form onSubmit={onSave}>
      <FormItem
          key='nameForm'
          label='Your Name'
          validationError={validationError}
        >
          <div className='rowWithCenterAlignedItems'>
            <IonInput
              value={value === null ? '' : (value || oldValue)}
              placeholder='Enter your name'
              autocomplete='name'
              minlength={2}
              clearInput
              required
              onIonChange={onChangeValue}
            />
          </div>
        </FormItem>
        <div className='formButtonWrapper'>
          <IonButton
            size='small'
            color='light'
            className='withStandardRightMargin'
            onClick={onCancel}
          >
            Cancel
          </IonButton>
          <SubmitButton
            size='small'
            disabled={!isDirty || !isValid}
            isProcessing={isUpdatingActiveUser}
            onClick={onSave}
          >
            Save
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

export default NameForm
