import type { ProductListFilter } from '../../../lib/core/definitions'
import type { ProductsQueryVariables } from '../definitions'
import getProductListFilter from './getProductListFilter'

const getProductsQueryVariables = (
  listFilter: ProductListFilter | undefined,
): ProductsQueryVariables => ({
  filter: listFilter || getProductListFilter(),
})

export default getProductsQueryVariables
