import { gql } from '@apollo/client'

export default gql`
  mutation M($purchase: PurchaseInput!) {
    upsertPurchase (purchase: $purchase) {
      id
      userId
#      offerId
#      productId
#      productOptionId
#      purchaseType
#      amount
#      initialBalance
#      balance
#      currency
#      fundType
#      code
#      hasBarcode
#      barcodeFormat
#      pin
#      balanceUpdatedAt
#      referenceUrl
#      messageEn
#      issuedAt
#      expiresAt
#      adminNotes
#      status
#      source
      metadata {
        amountPaid
        receivedTransfer {
          id
          fromUserId
          fromPurchaseId
          transferStatus
          recordStatus
        }
        sentTransfer {
          id
          toUserId
          toPurchaseId
          transferStatus
          recordStatus
        }
        latestError
        events {
          time
          type
          message
        }
      }
#      recordStatus
#      createdAt
#      updatedAt
#      purchaseTransfers {
#        id
#        fromPurchaseId
#        toPurchaseId
#        toUserId
#        toUserFullName
#        toUserUsername
#        toUserEmail
#        toUserPhoneNumber
#        notificationMethod
#        transferStatus
#        messageText
#        messageScheduledAt
#        messageScheduledTimezone
#        messageSentAt
#        options
#        adminNotes
#        metadata
#        recordStatus
#        createdAt
#        updatedAt
#      }
#      receivedPurchaseTransfers {
#        id
#        fromPurchaseId
#        toPurchaseId
#        toUserId
#        toUserFullName
#        toUserUsername
#        toUserEmail
#        toUserPhoneNumber
#        notificationMethod
#        transferStatus
#        messageText
#        messageScheduledAt
#        messageScheduledTimezone
#        messageSentAt
#        options
#        adminNotes
#        metadata
#        recordStatus
#        createdAt
#        updatedAt
#      }
#      sentPurchaseTransfers {
#        id
#        fromPurchaseId
#        toPurchaseId
#        toUserId
#        toUserFullName
#        toUserUsername
#        toUserEmail
#        toUserPhoneNumber
#        notificationMethod
#        transferStatus
#        messageText
#        messageScheduledAt
#        messageScheduledTimezone
#        messageSentAt
#        options
#        adminNotes
#        metadata
#        recordStatus
#        createdAt
#        updatedAt
#      }
#      purchaseTransferListItems {
#        id
#        fromPurchaseId
#        fromUserId
#        fromUserUsername
#        fromUserEmail
#        fromUserPhoneNumber
#        toPurchaseId
#        toUserId
#        toUserUsername
#        toUserFullName
#        toUserEmail
#        toUserPhoneNumber
#        transferStatus
#        notificationMethod
#        messageScheduledAt
#        messageScheduledTimezone
#        messageSentAt
#        adminNotes
#        metadata
#        status
#        recordStatus
#        createdAt
#        updatedAt
#      }
#      product {
#        id
#        merchantId
#        productType
#        code
#        hasBarcode
#        barcodeFormat
#        referenceUrl
#        messageEn
#        termsEn
#        termsUrl
#        instructionsEn
#        instructionsUrl
#        imageSourceFrontSide
#        imageSourceBackSide
#        merchant {
#          id
#          name
#          url
#          smallLogoImageSource
#          largeLogoImageSource
#          balanceLookupUri
#        }
#        #        tags {}
#      }
#      transactions {
#        id
#        remoteId
#        purchaseId
#        fromUserId
#        toUserId
#        fromWalletId
#        toWalletId
#        transactionType
#        rewardType
#        status
#        amount
#        price
#        paid
#        currency
#        fundType
#        exchangeRate
#        exchangeRateUpdatedAt
#        processor
#        paymentRequest
#        source
#        blockchainAddress
#        requestMessage
#        #        metadata
#        recordStatus
#        createdAt
#        updatedAt
#        #        fromWallet
#        #        toWallet
#        blockchainTransactions {
#          id
#          hash
#          transactionId
#          fromUserId
#          toUserId
#          fromWalletId
#          toWalletId
#          fromBlockchainAddress
#          toBlockchainAddress
#          transactionType
#          amount
#          currency
#          confirmations
#          approved
#          message
#          #          metadata
#          adminNotes
#          recordStatus
#          createdAt
#          updatedAt
#          #          fromWallet
#          #          toWallet
#          #          transaction
#        }
#      }
#      updateHistory {
#        id
#        purchaseId
#        updateType
#        balance
#        status
#        recordStatus
#        notes
#        createdAt
#      }
    }
  }
`
