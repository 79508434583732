import React from 'react'
import { IonSpinner } from '@ionic/react'

import type { ShoppingCart } from '../../lib/core/definitions'

type Props = {
  shoppingCart: ShoppingCart | undefined
  message: string | undefined
  isLoadingShoppingCart: boolean
}

const ProcessingSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    shoppingCart,
    message,
    isLoadingShoppingCart,
  } = props

  if (
    !message &&
    !(!shoppingCart && isLoadingShoppingCart)
  ) {
    return null
  }

  return (
    <div
      key='processing'
      className='withCenteredColumnContent withStandardTopMargin withDoubleBottomMargin'
    >
      <div className='withCenteredColumnContent'>
        <IonSpinner/>
        <div className='withCenteredTextAlign withStandardTopMargin'>
          {message}
        </div>
      </div>
    </div>
  )
}

ProcessingSection.defaultProps = {
  message: 'Loading...',
}

export default ProcessingSection
