import { gql } from '@apollo/client'

export default gql`
  mutation M($contact: ContactInput!) {
    upsertContact (contact: $contact) {
      id
      fromUserId
      toUserId
      nickname
      contactType
      status
      source
      metadata
      # adminNotes
      createdAt
      updatedAt
      recordStatus
      toUser {
        id
        username
        fullName
        email
        phoneNumber
        imageUrl
        roles
        createdAt
        updatedAt
        recordStatus
      }
    }
  }
`
