import React, { useState } from 'react'
import { IonModal, IonButton, IonInput, IonIcon } from '@ionic/react'
import { close, informationCircleOutline } from 'ionicons/icons'

import './style.css'
import { AssetType } from '../../lib/core/enums'
import type { User } from '../../lib/core/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import appLinks from '../../lib/core/appLinks'
import Avatar from '../../components/Avatar/Avatar'
import FormItem from '../FormItem/FormItem'
import type { FormRadioItem } from '../FormRadioGroup/FormRadioGroup'
import FormRadioGroup, { FormRadioLayout } from '../FormRadioGroup/FormRadioGroup'
import SubmitButton from '../SubmitButton/SubmitButton'
import validationHelpers from '../../helpers/validationHelpers'

enum DisplayMode {
  INITIAL = 'initial',
  SELECT_FILE = 'select-file',
  PREVIEW = 'preview',
}

enum EditMode {
  URL = 'url',
  UPLOAD = 'upload',
  REMOVE = 'remove',
  LINKED_ACCOUNT = 'linked-account',
}

type Props = {
  user: User | null | undefined
  show: boolean
  onClose: () => void
  onSuccess: () => void
}

const EditAvatarModal: React.FC<Props> = (props): JSX.Element => {
  const { user, show, onSuccess, onClose } = props

  // ===================================================================================================================
  // State:
  const { activeUser, reloadActiveUser, updateActiveUser } = useMimbleData()
  const [displayMode, setDisplayMode] = useState(DisplayMode.INITIAL)
  const [file, setFile] = useState<File | undefined>()
  const [editMode, setEditMode] = useState(EditMode.UPLOAD)
  const [imageUrl, setImageUrl] = useState<string | null | undefined>()
  const [imageUrlValidationError, setImageUrlValidationError] = useState('')

  // ===================================================================================================================
  // Helpers:
  const imageSrc = file ? URL.createObjectURL(file) : ''
  const isDirty = (
    !!user &&
    ((user.imageUrl !== undefined && imageUrl !== user.imageUrl) ||
    user.imageUrl === null)
  )
  const isValid = !imageUrlValidationError

  const resetModal = () => {
    setDisplayMode(DisplayMode.INITIAL)
    setFile(undefined)
    setImageUrl(undefined)
    setImageUrlValidationError('')
  }

  const updateAvatarUrlInActiveUser = (url: string | null): void => {
    if (!user || !user.id || !url) {
      return
    }

    updateActiveUser({
      id: user.id,
      imageUrl: url,
    },
      { reloadActiveUser: true, reloadOptions: { delay: 40 } },
      )
      .then(() => {
        onClose()
      }, (error) => {
        console.error(error)
        onClose()
      })
  }

  // ===================================================================================================================
  // Event Handlers:
  const onFileUpload = (e: any) => {
    if (e.target.files) {
      setFile(e.target.files[0])
      setDisplayMode(DisplayMode.PREVIEW)
    }
  }

  const handleFileSend = () => {
    if (!file) {
      return
    }

    const url = appLinks.fileUpload()

    console.log('UploadFileModal: Sending file now')
    const formData = new FormData()
    formData.append('assetType', AssetType.USER_AVATAR)
    if (user && user.id) {
      formData.append('userId', user.id)
    }
    formData.append('file', file)

    const otherParams: RequestInit = { method: 'POST', body: formData }

    fetch(url, otherParams)
      .then((data) => {
        console.log(data)
        reloadActiveUser({ delay: 40 }).then(() => {
          resetModal()
          onSuccess()
        }, (error) => {
          console.error(error)
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onChangeEditMode = (newEditMode: EditMode): void => {
    // console.log('EditAvatarModal.onChangeEditMode called.', { imageUrl, 'user.imageUrl': user.imageUrl })
    setEditMode(newEditMode)
  }

  const onChangeImageUrl = (event: any): void => {
    if (
      (user && (event.detail.value === user.imageUrl || (!event.detail.value && !user.imageUrl))) ||
      (!user && !event.detail.value)
    ) {
      setImageUrlValidationError('')
      setImageUrl(undefined)
      return
    }
    setImageUrl(event.detail.value)
    if (event.detail.value) {
      setImageUrlValidationError(validationHelpers.validateUserImageUrl(event.detail.value))
    } else {
      setImageUrlValidationError('')
    }
  }

  const onClickSaveButton = (event: any): void => {
    event.preventDefault()
    if (!user || !isDirty || !imageUrl) {
      return
    }
    updateAvatarUrlInActiveUser(imageUrl)
  }

  const onClickRemoveButton = (event: any): void => {
    event.preventDefault()
    updateAvatarUrlInActiveUser(null)
  }

  const onBackFromPreview = () => {
    resetModal()
  }

  // ===================================================================================================================
  // Rendering:
  let content: JSX.Element | undefined

  const modeOptions: FormRadioItem[] = [
    {
      value: EditMode.UPLOAD,
      label: 'Upload an image file',
      isChecked: editMode === EditMode.UPLOAD,
    },
    {
      value: EditMode.URL,
      label: 'Use an image link',
      isChecked: editMode === EditMode.URL,
    },
  ]
  if (user && user.oAuthProvider) {
    modeOptions.push({
      value: EditMode.LINKED_ACCOUNT,
      label: `Use the avatar of my linked ${user ? user.oAuthProvider : ''} account`,
      isChecked: editMode === EditMode.LINKED_ACCOUNT,
    })
  }

  // if (user && user.imageUrl) {
  //   modeOptions.push({
  //     value: EditMode.REMOVE,
  //     label: 'Remove your avatar image',
  //     isChecked: editMode === EditMode.REMOVE,
  //   })
  // }

  let disclaimer: JSX.Element | undefined
  if (editMode !== EditMode.REMOVE) {
    disclaimer = (
      <div className='rowWithCenterAlignedItems smallText lightText'>
        <IonIcon icon={informationCircleOutline} className='info-icon' />
        It may take some time before your avatar presents in your profile.
      </div>
    )
  }

  if (displayMode === DisplayMode.INITIAL) {
    let editSection: JSX.Element | undefined
    if (editMode === EditMode.UPLOAD) {
      // see https://stackoverflow.com/questions/54844616/why-does-upload-image-via-camera-work-on-mobile-safari-but-not-as-ios-pwa
      editSection = (
        <>
          {disclaimer}
          <div className='form-button-wrapper'>
            <IonButton
              color='light'
              className='withStandardRightMargin'
              onClick={onClose}
            >
              Cancel
            </IonButton>
            <IonButton fill='solid'>
              <input
                accept='image/*'
                className='fileUpload'
                id='image-upload'
                name='image-upload'
                onChange={onFileUpload}
                type='file'
              />
              <label htmlFor='file'>Select image</label>
            </IonButton>
          </div>
        </>
      )
    } else if (editMode === EditMode.URL) {
      editSection = (
        <>
          <FormItem
            label='Valid links end with .gif, .jpeg, .jpg or .png'
            validationError={imageUrlValidationError}
            withBottomMargin
          >
            <IonInput
              onIonChange={onChangeImageUrl}
              placeholder='https://example.com/avatar-image.png'
              value={imageUrl !== undefined ? imageUrl || '' : (user ? user.imageUrl : '')}
              clearInput
            />
          </FormItem>
          {disclaimer}
          <div className='form-button-wrapper'>
            <IonButton
              color='light'
              className='withStandardRightMargin'
              onClick={onClose}
            >
              Cancel
            </IonButton>
            <SubmitButton
              disabled={!isValid}
              onClick={onClickSaveButton}
            >
              Submit
            </SubmitButton>
          </div>
        </>
      )
    } else {
      if (editMode === EditMode.REMOVE) {
        editSection = (
          <>
            {disclaimer}
            <div className='form-button-wrapper'>
              <IonButton
                color='light'
                className='withStandardRightMargin'
                onClick={onClose}
              >
                Cancel
              </IonButton>
              <SubmitButton
                onClick={onClickRemoveButton}
                color='danger'
              >
                Remove
              </SubmitButton>
            </div>
          </>
        )
      }
    }
    content = (
      <form>
        <div className='withCenteredColumnContent'>
          <Avatar
            user={activeUser}
            className='withStandardMargin'
            size={100}
          />
          <span className='withDoubleBottomMargin'>
            You can upload your own image, or link to one that is available online.
          </span>
        </div>
        <FormRadioGroup
          items={modeOptions}
          layout={FormRadioLayout.vertical}
          className='withStandardBottomMargin'
          onChange={onChangeEditMode}
        />
        {editSection}
      </form>
    )
  } else if (displayMode === DisplayMode.SELECT_FILE) {
    content = (
      <form>
        <label
          htmlFor='image-upload'
          style={{ display: 'block', width: '100%', height: '100%' }}
          className='image-upload-button withStandardPadding'
        >
          Upload Image
          <input
            onChange={onFileUpload}
            style={{ display: 'none' }}
            type='file'
            accept='image/*'
            capture='environment'
            name='file-upload'
            id='image-upload'
          />
        </label>
      </form>
    )
  } else if (displayMode === DisplayMode.PREVIEW) {
    content = (
      <form>
        <div className='withCenteredColumnContent withStandardBottomMargin'>
          <img src={imageSrc} alt='Avatar' style={{ maxHeight: '400px' }} />
        </div>
        {disclaimer}
        <div className='formButtonWrapper'>
          <IonButton
            color='light'
            className='withStandardRightMargin'
            onClick={onBackFromPreview}
          >
            Back
          </IonButton>
          <SubmitButton
            onClick={handleFileSend}
          >
            Upload
          </SubmitButton>
        </div>
      </form>
    )
  }
  return (
    <IonModal
      isOpen={show}
      cssClass='edit-avatar-modal'
      backdropDismiss
      swipeToClose
      onWillDismiss={onClose}
    >
      <div>
        <div className='header'>
          <div>
            <h2>Upload Avatar</h2>
          </div>
          <div>
            <IonButton
              fill='clear'
              onClick={onClose}
            >
              <IonIcon icon={close} />
            </IonButton>
          </div>
        </div>
        <div className='withStandardPadding'>
          {content}
        </div>
      </div>
    </IonModal>
  )
}

export default EditAvatarModal
