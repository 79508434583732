import type { ApolloClient } from '@apollo/client'
import type { InboxItem } from '../../../lib/core/definitions'
import type { InboxQueryData, InboxQueryVariables } from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInboxListItem = (
  inboxItem: InboxItem,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): InboxItem[] | undefined => {
  // console.log('api.cache.updateInboxItemListItem called.', { inboxItem })
  try {
    if (!inboxItem || !activeUserId) {
      logger.error('api.cache.updateInboxItemListItem: no inboxItem given, or missing localUserId.')
      return
    }
    const queryListVariables: InboxQueryVariables = { userId: activeUserId }
    const cachedInboxData = apolloClient.readQuery<InboxQueryData, InboxQueryVariables>({
      query: apollo.queries.inboxItems,
      variables: queryListVariables,
    })
    // console.log('api.upsertInboxItem.update: cachedInboxData=', cachedInboxData)
    if (
      cachedInboxData &&
      cachedInboxData.inbox &&
      cachedInboxData.inbox.items
    ) {
      const updatedInboxItems = coreHelpers.models.updateModelInList<InboxItem, InboxItem>(
        cachedInboxData.inbox.items,
        inboxItem,
        'id',
        true,
      )
      const inbox = { ...cachedInboxData.inbox, items: updatedInboxItems }

      apolloClient.writeQuery<InboxQueryData>({
        query: apollo.queries.inbox,
        variables: queryListVariables,
        data: { inbox },
      })

      return updatedInboxItems
    }
  } catch (error) {
    logger.error('api.cache.updateInboxListItem: error.', { error })
  }
}

export default updateInboxListItem
