import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import {
  IonButton,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient } from '@apollo/client'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../../enums'
import type { AsyncTask, AsyncTaskInput, ImportStoredProductItem } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { DataImportPageMode } from './enums'
import api from '../../../services/api'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import coreHelpers from '../../../lib/core/helpers'
import helpers from './helpers'
import ImportStoredProductsForm from './ImportStoredProductsForm'
import ModelEventList from '../../../components/ModelEventList/ModelEventList'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'

const appPageId = AppPage.AdminImportDataPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  asyncTaskId: string
  productId: string
}

const ImportDataPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { asyncTaskId, productId } = useParams<keyof Params>() as unknown as Params
  const pageMode = helpers.getPageModeFromLocation(location)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [asyncTask, setAsyncTask] = useState<AsyncTask | undefined>()
  const [isLoadingAsyncTask, setIsLoadingAsyncTask] = useState(false)
  const [isUpsertingAsyncTask, setIsUpsertingAsyncTask] = useState(false)

  // ===================================================================================================================
  // Helpers:
  const isProcessing = (
    isLoadingActiveUser ||
    isLoadingAsyncTask ||
    isUpsertingAsyncTask
  )

  // ===================================================================================================================
  // Helpers:
  const loadAsyncTask = () => {
    if (!asyncTaskId) {
      return
    }
    setIsLoadingAsyncTask(true)
    api.loadAsyncTask(
      asyncTaskId,
      true,
      apolloClient,
      undefined,
      undefined,
      undefined,
    ).then((loadedAsyncTask) => {
      setAsyncTask(loadedAsyncTask)
      setIsLoadingAsyncTask(false)
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
      setIsLoadingAsyncTask(false)
    })
  }

  const upsertAsyncTask = (asyncTaskInput: AsyncTaskInput) => {
    if (!asyncTaskInput) {
      console.error('ImportDataPage.upsertAsyncTask: task not given.')
      return
    }
    setIsUpsertingAsyncTask(true)
    api.upsertAsyncTask(
      asyncTaskInput,
      true,
      (task: AsyncTask) => (
        task.result !== null &&
        task.result !== undefined
      ),
      apolloClient,
      undefined,
      undefined,
    ).then((loadedAsyncTask) => {
      console.log('ImportDataPage.upsertAsyncTask: api.upsertingAsyncTask returned.', { loadedAsyncTask })
      setIsUpsertingAsyncTask(false)
      if (
        loadedAsyncTask &&
        loadedAsyncTask.id &&
        !asyncTaskId) {
        navigate(`/admin/data-import/${loadedAsyncTask.id}`)
      }
      setAsyncTask(loadedAsyncTask)
    }, (error) => {
      console.error(error)
      setIsUpsertingAsyncTask(false)
    })
  }

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (
      pageMode === DataImportPageMode.DATA_IMPORT &&
      asyncTaskId &&
      (!asyncTask || !coreHelpers.models.compareId(asyncTask.id, asyncTaskId)) &&
      !isLoadingAsyncTask &&
      !isUpsertingAsyncTask
    ) {
      loadAsyncTask()
    }
  }, [asyncTaskId])

  // ===================================================================================================================
  // Event Handlers:
  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const onSubmit = (asyncTaskInput: AsyncTaskInput): void => {
    console.log('ImportDataPage.onSubmit called', { asyncTaskInput })
    if (isProcessing || !asyncTaskInput) {
      showUiMessage('Invalid input')
      return
    }
    upsertAsyncTask(asyncTaskInput)
  }

  const onBack = (): void => {
    navigate(-1)
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !asyncTaskId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    loadAsyncTask()
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content
  if (pageMode === DataImportPageMode.NEW_STORED_PRODUCT_IMPORT) {
    content = (
      <>
        <ImportStoredProductsForm
          productId={productId}
          isProcessing={isProcessing}
          showUiMessage={showUiMessage}
          onSubmit={onSubmit}
          onBack={onBack}
        />
      </>
    )
  } else if (pageMode === DataImportPageMode.DATA_IMPORT) {
    let taskInfo
    if (asyncTask) {
      let modelEvents
      if (asyncTask.metadata && Array.isArray(asyncTask.metadata.events) && asyncTask.metadata.events.length > 0) {
        modelEvents = (
          <ModelEventList events={asyncTask.metadata.events} />
        )
      }
      let itemsInfo
      if (asyncTask.metadata && Array.isArray(asyncTask.metadata.items) && asyncTask.metadata.items.length > 0) {
        itemsInfo = asyncTask.metadata.items.map((item: ImportStoredProductItem, index: number): JSX.Element => {
          const formattedReceivedAt = item.receivedAt ? moment(item.receivedAt).toISOString() : '-'
          const formattedPaidAt = item.paidAt ? moment(item.paidAt).toISOString() : '-'
          return (
            <div key={item.importId}>
              <pre className='smallText'>
                #{index + 1}:<br />
                Succeeded={item.ok ? 'yes' : 'no'}<br />
                {item.error ? `Error=${item.error}` : ''}<br />
                Import ID={item.importId}<br />
                Stored Product ID={item.storedProductId}<br />
                Product ID={item.productId}<br />
                Product Option ID={item.productOptionId || '-'}<br />
                Amount={item.amount || '-'}<br />
                Discount={item.discount || '-'}<br />
                Code={item.code}<br />
                PIN={item.pin || '-'}<br />
                Status={item.status}<br />
                Received At={formattedReceivedAt}<br />
                Paid At={formattedPaidAt}<br />
                PO Number={item.poNumber}<br />
                Source={item.source || '-'}<br />
                URL={item.refUrl || '-'}
              </pre>
            </div>
          )
        })
      }
      taskInfo = (
        <div>
          <h2>Import Status</h2>
          <div>Import ID: {(asyncTaskId || '').replace(/-/g, '')}</div>
          <div>Status: {asyncTask.taskStatus || '-'}</div>
          <div>Result: {asyncTask.result || '-'}</div>
          <h2>Report</h2>
          <pre className='smallText'>{asyncTask.report || ''}</pre>
          <h2>Items</h2>
          {itemsInfo}
          <h2>Events</h2>
          {modelEvents}
          <div className='bottomButtonWrapperHorizontal'>
            <IonButton onClick={loadAsyncTask}>Refresh</IonButton>
          </div>
        </div>
      )
    }
    content = (
      <>
        <div className='section'>
          {taskInfo}
        </div>
      </>
    )
  }

  return (
    <IonPage className='app-page-admin import-data-page'>
      <NavBar
        title={helpers.getPageTitle(pageMode)}
        goBackUri={AppRoute.ADMIN_HOME}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default ImportDataPage
