import { ReportDataFormat } from '../../../enums'
import type { CampaignUserInfoListFilter, CampaignUserInfo } from '../../../definitions'
import formatDate from '../../ui/formatDate'

const exportUserInfoList = (
  userInfos: CampaignUserInfo[] | null | undefined,
  filter: CampaignUserInfoListFilter | undefined,
  format = ReportDataFormat.TSV,
): string => {
  if (!Array.isArray(userInfos) || userInfos.length < 1) {
    return ''
  }

  let colDelimiter = '\t'
  const rowDelimiter = '\n'
  switch (format) {
    case ReportDataFormat.CSV:
      colDelimiter = ','
      break
    case ReportDataFormat.TSV:
      colDelimiter = '\t'
  }

  const lines = userInfos
    .filter((userInfo) => {
      if (filter && filter.searchText) {
        const reg = new RegExp(filter.searchText, 'i')
        if (!userInfo.fullName || !userInfo.fullName.match(reg)) {
          return false
        }
      }
      return false
    })
    .map((userInfo): string => {
      return [
        (userInfo.userId as string).replace(/-/g, ''),
        userInfo.username,
        userInfo.fullName,
        userInfo.email ? (userInfo.email) : '-',
        userInfo.phoneNumber,
        userInfo.inAppMessageReceivedAt ? (formatDate(userInfo.inAppMessageReceivedAt)) : '-',
        userInfo.actionTaken || '-',
      ].join(colDelimiter)
    })
  lines.unshift([
    'ID',
    'Handle',
    'Name',
    'Email',
    'Phone Number',
    'Received At',
    'Action Taken',
  ].join(colDelimiter))

  return lines.join(rowDelimiter)
}

export default exportUserInfoList
