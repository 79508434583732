import React, { useContext, useEffect, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  useIonViewDidLeave,
} from '@ionic/react'
// import { add, cart, cloudUpload, closeOutline } from 'ionicons/icons' // SpeedDial
import type { RefresherEventDetail } from '@ionic/core'
import { useLocation, useHistory, useParams } from 'react-router-dom'
// import SpeedDial from '@mui/lab/SpeedDial'
// import SpeedDialAction from '@mui/lab/SpeedDialAction'
import { useApolloClient } from '@apollo/client'
import { Update } from 'history'

import './styles.css'
// import { ContextMenuId, ContextMenuItemId, ContextMenuOptions } from '../../components/ContextMenu/contextMenuDefs'
import { PageMessageType, AppRoute, AppPage } from '../../enums'
import { AppAction, PurchaseListScope, PurchaseStatus, PurchaseType, UiMessage } from '../../lib/core/enums'
import type { TopNavTabDef } from '../../components/TopNavBar/definitions'
import { topNavTabDefs as tokensPageTopNavTabDefs } from '../TokensPage/TokensPage'
import { TopNavTabId } from '../../components/TopNavBar/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import api from '../../services/api'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import coreHelpers from '../../lib/core/helpers'
import EmptyWalletContent from './EmptyWalletContent'
import GiftCardList from './GiftCardList/GiftCardList'
import InAppMessageModal from '../../components/InAppMessageModal/InAppMessageModal'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers, { DEFAULT_PURCHASES_BY_SCOPE_COUNTS } from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import PurchasesWalletHeader from './PurchasesWalletHeader'
import TokensHeader from '../../components/TokensHeader/TokensHeader'
import TopNavBar, { getPurchaseListScopeFromTopNavTabId } from '../../components/TopNavBar/TopNavBar'

const DEFAULT_TOP_NAV_DEFS: TopNavTabDef[] = []

const appPageId = AppPage.WalletPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  tab: TopNavTabId
  searchText: string
}

const WalletPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { tab: activeTopNavTabId, searchText: searchTextFromParams } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // Helpers:
  const scope = getPurchaseListScopeFromTopNavTabId(activeTopNavTabId || TopNavTabId.ACTIVE_PURCHASES)

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { getActiveUser } = useGlobalCache()
  const {
    activeUser,
    purchases,
    isLoadingActiveUser,
    isLoadingPurchases,
    getActiveUserMimbleWallet,
    reloadPurchases,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const mimbleWallet = getActiveUserMimbleWallet && getActiveUserMimbleWallet()
  const giftCards = Array.isArray(purchases) && purchases.length > 0
    ? purchases.filter(p => p.purchaseType === PurchaseType.MARKETPLACE_PRODUCT)
    : purchases

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [searchText, setSearchText] = useState<string | undefined>()
  const [topNavTabDefs, setTopNavTabDefs] = useState(DEFAULT_TOP_NAV_DEFS)
  const [scopeCounts, setScopeCounts] = useState(DEFAULT_PURCHASES_BY_SCOPE_COUNTS)

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingPurchases
  const userCanPurchase = coreHelpers.models.user.canPurchase(getActiveUser())
  const curSearchText: string = searchText || searchTextFromParams || ''
  const showSearch = (
    (scope === PurchaseListScope.ACTIVE && scopeCounts.activeCnt > 5) ||
    (scope === PurchaseListScope.PENDING && scopeCounts.pendingCnt > 5) ||
    (scope === PurchaseListScope.TRANSFERRED && scopeCounts.transferredCnt > 5) ||
    (scope === PurchaseListScope.ARCHIVED && scopeCounts.archivedCnt > 5)
  )

  const updateTopNavTabDefs = (): void => {
    if (!Array.isArray(giftCards) || giftCards.length < 1) {
      if (Array.isArray(topNavTabDefs) && topNavTabDefs.length > 0) {
        setTopNavTabDefs([])
      }
    } else {
      const counts = pageHelpers.getPurchaseCountByScope(giftCards)
      if (!pageHelpers.comparePurchasesByScopeCounts(counts, scopeCounts)) {
        setScopeCounts(counts)
        setTopNavTabDefs([
          {
            id: TopNavTabId.ACTIVE_PURCHASES,
            label: 'Active',
            details: { count: counts.activeCnt },
          },
          {
            id: TopNavTabId.PENDING_PURCHASES,
            label: 'Pending',
            details: { count: counts.pendingCnt },
            show: counts.pendingCnt > 0,
          },
          {
            id: TopNavTabId.TRANSFERRED_PURCHASES,
            label: 'Transferred',
            details: { count: counts.transferredCnt },
            show: counts.transferredCnt > 0,
          },
          {
            id: TopNavTabId.ARCHIVED_PURCHASES,
            label: 'Archived',
            details: { count: counts.archivedCnt },
            show: counts.archivedCnt > 0,
          },
        ])
      }
    }
  }

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  useEffect((): void => {
    updateTopNavTabDefs()
    if (refreshEvent) {
      refreshEvent.detail.complete()
      refreshEvent = undefined
    }
  }, [giftCards])

  useIonViewDidLeave(() => {
    if (refreshEvent) {
      refreshEvent.detail.complete()
      refreshEvent = undefined
    }
  })

  // ===================================================================================================================
  // Event Handlers:
  const onApplyListFilter = (newSearchText: string, newScope: PurchaseListScope): void => {
    // console.log('WalletPage.onApplyListFilter called.', { newSearchText, newScope })
    setSearchText(newSearchText)
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || isLoadingPurchases) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadPurchases().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    })
  }

  const onOpenPurchase = (purchaseId: string): void => {
    api.loadPurchase(
      purchaseId,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then((purchase) => {
      if (!purchase) {
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.GIFT_CARD_NOT_FOUND)
        return
      }
      if (purchase.status === PurchaseStatus.RECEIVED) {
        navigate(`${AppRoute.GIFT_RECEIVED}/tp:${purchaseId}`)
      } else {
        navigate(`/card/${purchaseId}`)
      }
    })
  }

  const onAddUploadedPurchase = (): void => {
    navigate('/select-brand/upload-card')
  }

  const onCreatePurchase = (): void => {
    navigate(AppRoute.MARKETPLACE)
  }

  // const onContextMenu = (id: ContextMenuItemId): void => {
  //   // console.log('Context menu clicked:', id);
  //   switch (id) {
  //     case ContextMenuItemId.REFRESH:
  //       onGetPurchases()
  //       break
  //     case ContextMenuItemId.ADD_UPLOADED_PURCHASE:
  //       onAddUploadedPurchase()
  //       break
  //     case ContextMenuItemId.CREATE_PURCHASE:
  //       onCreatePurchase()
  //       break
  //     default:
  //       console.error('WalletPage.onContextMenu: Unexpected context menu item received.', id)
  //   }
  // }

  const onClickTopNavTab = (navTabId: TopNavTabId): void => {
    const scopePath = navTabId ? `/${navTabId}` : ''
    // todo Remove animation
    // see https://stackoverflow.com/questions/56621532/how-to-disable-page-transition-animation-in-ionic-4-angular
    navigate(`${AppRoute.WALLET}${scopePath}`)
  }

  const onBuyTokens = (): void => {
    navigate(AppRoute.BUY_MIMBLE_TOKENS)
  }

  const onOpenTokensPage = (): void => {
    navigate(AppRoute.TOKENS)
  }

  const onOpenEarnRewardsPage = (): void => {
    const def = tokensPageTopNavTabDefs.find(d => d.id === TopNavTabId.EARN_REWARDS)
    if (def) {
      navigate(`${AppRoute.TOKENS}/${def.slug}`)
    }
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onAppAction = (action: AppAction): void => {
    switch (action) {
      case AppAction.EDIT_PROFILE:
        navigate(AppRoute.USER_ACCOUNT)
        break
      case AppAction.INVITE_FRIEND:
        navigate(AppRoute.NEW_INVITATION)
        break
      case AppAction.OPEN_CONTACTS:
        navigate(AppRoute.CONTACTS)
        break
      case AppAction.PURCHASE:
        navigate(AppRoute.MARKETPLACE)
        break
      case AppAction.UPLOAD_GIFT_CARD:
        navigate(AppRoute.UPLOAD_GIFT_CARD)
        break
      case AppAction.UPDATE_APP:
        break
    }
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('WalletPage.render called.')
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content
  let purchasesWalletHeader: JSX.Element | undefined

  if (
    !activeUser ||
    isLoadingActiveUser ||
    isLoadingPurchases
  ) {
    // todo: show loading or "not connected" info
  } else if (
    !Array.isArray(giftCards) ||
    giftCards.length < 1 ||
    (scope === PurchaseListScope.ACTIVE && scopeCounts.activeCnt < 1)
  ) {
    content = (
      <EmptyWalletContent
        showWelcomeMessage={!Array.isArray(giftCards) || giftCards.length < 1}
        onAddUploadedPurchase={onAddUploadedPurchase}
        onCreatePurchase={userCanPurchase ? onCreatePurchase : undefined}
        onOpenEarnRewards={onOpenEarnRewardsPage}
      />
    )
  } else {
    purchasesWalletHeader = (
      <PurchasesWalletHeader
        searchText={curSearchText}
        scope={scope}
        showSearch={showSearch}
        onApply={onApplyListFilter}
        onAddUploadedPurchase={onAddUploadedPurchase}
        onCreatePurchase={userCanPurchase ? onCreatePurchase : undefined}
      />
    )
    content = (
      <GiftCardList
        searchText={curSearchText}
        scope={scope}
        purchases={giftCards}
        isProcessing={isProcessing}
        onOpenPurchase={onOpenPurchase}
      />
    )
  }

  return (
    <IonPage className='app-page-public wallet-page'>
      <NavBar
        title='Wallet'
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <TokensHeader
        wallet={mimbleWallet}
        onBuyTokens={onBuyTokens}
        onOpenEarnRewardsPage={onOpenEarnRewardsPage}
      />
      <TopNavBar
        tabs={topNavTabDefs}
        onClickTab={onClickTopNavTab}
        activeTabId={activeTopNavTabId || TopNavTabId.ACTIVE_PURCHASES}
      />
      <PageMessages />
      {purchasesWalletHeader}
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
      <InAppMessageModal
        onAction={onAppAction}
      />
    </IonPage>
  )
}

export default WalletPage
