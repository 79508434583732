import moment from 'moment-timezone'
import { createHmacSignature } from './createHmacSignature'
import appConfig from '../../appConfig'
import config from './config'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

const getHmacKey = (authToken: string | undefined): string => {
  const apolloConfig = config.getConfig()
  if (authToken) {
    // console.log(`api.getRequestHeaders: using authToken '${authToken}' in HMAC key: ${env('HMAC_KEY')}-${authToken}`);
    return `${apolloConfig.hmacKey}-${authToken}`
  }
  // console.log('api.getRequestHeaders: NOT using authToken in HMAC key.')
  return apolloConfig.hmacKey
}

const getRequestHeaders = (skipAuthentication?: boolean): HeadersInit => {
  const authUserId = skipAuthentication ? '' : globalCacheData.getAuthUserId()
  const proxyUserId = skipAuthentication ? '' : globalCacheData.getProxyUserId()
  const authToken = skipAuthentication ? '' : globalCacheData.getAuthToken() || ''
  const deviceId = globalCacheData.getDeviceId()
  const timestamp = new Date().getTime().toString()
  const apolloConfig = config.getConfig()

  const headers: HeadersInit = {
    [appConfig.mimbledata.hmac.headerNames.deviceId]: deviceId,
    [appConfig.mimbledata.hmac.headerNames.timezone]: moment.tz.guess(),
    [appConfig.mimbledata.hmac.headerNames.timestamp]: timestamp,
  }

  if (apolloConfig) {
    const appVersion = `-${apolloConfig.appVersion}` || ''
    const key = getHmacKey(authToken && !skipAuthentication ? authToken : undefined)
    const hmacData = skipAuthentication ? '' : proxyUserId || authUserId || ''
    headers[appConfig.mimbledata.hmac.headerNames.signature] = createHmacSignature({
      data: hmacData,
      timestamp,
      key,
      secret: apolloConfig.hmacSecret,
    })
    headers[appConfig.mimbledata.hmac.headerNames.consumer] = `mimble-app${appVersion}`
  } else {
    headers[appConfig.mimbledata.hmac.headerNames.consumer] = 'mimble-app'
  }

  if (!skipAuthentication && authUserId && authToken) {
    headers.Authorization = `Bearer ${authUserId}`
    if (proxyUserId) {
      headers[appConfig.mimbledata.hmac.headerNames.proxy] = proxyUserId
    }
  }

  if (skipAuthentication) {
    headers[appConfig.mimbledata.hmac.headerNames.authType] = 'none'
  }

  // console.log('apollo.getRequestHeaders: done.', {
  //   skipAuthentication,
  //   authUserId,
  //   proxyUserId,
  //   authToken,
  //   deviceId,
  //   timestamp,
  //   headers,
  //   apolloConfig,
  // })

  return headers
}

export default getRequestHeaders
