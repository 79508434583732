import React, { useContext, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { useGlobalCache } from './GlobalCacheContext/GlobalCacheContext'
import api from '../services/api'
import logger from '../services/logger'

let loadInAppMessagesTimer: NodeJS.Timeout | undefined

export interface AppStateContextValue {
  isActive: boolean
}

export const defaultValue = {
  isActive: false,
}

export const AppStateContext = React.createContext<AppStateContextValue>(defaultValue)

export function useAppState (): AppStateContextValue {
  return useContext(AppStateContext)
}

interface Props {
  isActive: boolean
}

const AppStateProvider: React.FC<Props> = (props) => {
  const { isActive } = props

  // ----------------------------------------------------------------------------------
  // State:
  const apolloClient = useApolloClient()
  const {
    getDeviceId,
    getActiveUserId,
    getProxyUser,
    getIsSignedIn,
  } = useGlobalCache()

  // ----------------------------------------------------------------------------------
  // Effect Hooks:
  useEffect(() => {
    // We don't want to report app sessions to the backend during Sign-In-As:
    if (getProxyUser()) {
      return
    }

    api.reportSessionState(
      isActive,
      getActiveUserId(),
      getDeviceId(),
      !!getProxyUser(),
      apolloClient,
    )

    if (isActive) {
      if (!loadInAppMessagesTimer) {
        loadInAppMessagesTimer = setTimeout(() => {
          // console.log('App.onAppStateChange: checking for system message.')
          loadInAppMessagesTimer = undefined
          const activeUserId = getActiveUserId()
          if (activeUserId && getIsSignedIn()) {
            // console.log('App.onAppStateChange: calling loadUserInAppMessagess.')
            api.loadUnreadInAppMessages(
              activeUserId,
              apolloClient,
              undefined,
              { skipCache: true },
            )
              .then(undefined, (error) => {
                logger.error(error)
              })
          }
        }, 2000)
      }
    } else {
      if (loadInAppMessagesTimer) {
        clearTimeout(loadInAppMessagesTimer)
        loadInAppMessagesTimer = undefined
      }
    }
  }, [isActive])

  return (
    <AppStateContext.Provider
      value={{
        isActive: props.isActive,
      }}
    >
      {props.children}
    </AppStateContext.Provider>
  )
}

export default AppStateProvider
