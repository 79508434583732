import { ApolloClient } from '@apollo/client'
import { AppRoute } from '../../enums'
import { OrgProductType, ShoppingCartCommand } from '../../lib/core/enums'
import { ShoppingCart, ShoppingCartInput } from '../../lib/core/definitions'
import { ShoppingCartStateFunc } from '../../definitions'
import api from '../../services/api'
import checkForUnauthorized from './checkForUnauthorized'
import coreHelpers from '../../lib/core/helpers'

type Args = {
  activeUserId: string | null | undefined
  amountFiat?: number | null | undefined
  merchantId?: string | null | undefined
  productId?: string | null | undefined
  apolloClient: ApolloClient<any>
  setShoppingCart: (
    shoppingCart: ShoppingCartInput,
    isActiveShoppingCart: boolean,
    isInTargetStateFunc?: ShoppingCartStateFunc,
  ) => Promise<ShoppingCart | undefined>
  navigate: (route: AppRoute | string, replace?: boolean, state?: any) => void
}

const addProductToShoppingCart = (args: Args): void => {
  const {
    activeUserId,
    amountFiat,
    merchantId,
    productId,
    apolloClient,
    setShoppingCart,
    navigate,
  } = args

  if (!activeUserId) {
    return
  }

  if (amountFiat) {
    const amountToken = coreHelpers.cryptoCurrency.convertFiatToMimbleToken(amountFiat)
    const shoppingCartInput: ShoppingCartInput = {
      userId: activeUserId,
      command: ShoppingCartCommand.ADD_ITEM,
      items: [{
        productType: OrgProductType.MIMBLE_TOKEN,
        productCount: amountToken,
        paymentAmount: amountFiat,
      }],
    }
    setShoppingCart(
      shoppingCartInput,
      true,
      'watch-metadata-revision',
    ).then(() => {
      navigate(AppRoute.SHOPPING_CART)
    }, (error) => {
      console.error(error)
    })
  } else if (productId) {
    api.loadProduct(
      productId as string,
      undefined,
      undefined,
      apolloClient,
      {
        includeMerchant: true,
        includeProductOptions: true,
      },
    ).then((product) => {
      if (!product) {
        console.log('Failed to load product')
        return
      }

      const shoppingCartInput: ShoppingCartInput = {
        userId: activeUserId,
        command: ShoppingCartCommand.ADD_ITEM,
        items: [{
          productId,
          productType: OrgProductType.GIFT_CARD,
          productCount: 1,
        }],
      }

      const isInTargetStateFunc = (shoppingCart: ShoppingCart): boolean => {
        if (!shoppingCart) {
          return false
        }
        if (!Array.isArray(shoppingCart.items) || shoppingCart.items.length < 1) {
          return false
        }
        return !!shoppingCart.items.find(item => (
          coreHelpers.models.compareId(item.productId, productId) &&
          !item.productOptionId),
        )
      }

      setShoppingCart(
        shoppingCartInput,
        true,
        isInTargetStateFunc,
      ).then((shoppingCart) => {
        if (shoppingCart && shoppingCart.items) {
          const item = shoppingCart.items.find(item => item.productId === productId && !item.productOptionId)
          if (item) {
            navigate(`${AppRoute.CHECKOUT_PRODUCT_OPTION}/${item.id}`)
          }
        }
      })
    }, (error) => {
      console.error(error)
      checkForUnauthorized(error, navigate)
    })
  } else if (merchantId) {
    const isInTargetStateFunc = (shoppingCart: ShoppingCart): boolean => {
      if (
        !shoppingCart ||
        !Array.isArray(shoppingCart.items) ||
        shoppingCart.items.length < 1
      ) {
        return false
      }
      return !!shoppingCart.items.find(item => (
        item.product &&
        coreHelpers.models.compareId(item.product.merchantId, merchantId) &&
        !item.productOptionId
      ))
    }
    const shoppingCartInput: ShoppingCartInput = {
      userId: activeUserId,
      command: ShoppingCartCommand.ADD_ITEM,
      items: [{
        merchantId,
        productType: OrgProductType.GIFT_CARD,
        productCount: 1,
      }],
    }
    setShoppingCart(
      shoppingCartInput,
      true,
      isInTargetStateFunc,
    ).then((shoppingCart) => {
      if (shoppingCart && shoppingCart.items) {
        const item = shoppingCart.items.find(item => item.product?.merchantId === merchantId && !item.productOptionId)
        if (item) {
          navigate(`${AppRoute.CHECKOUT_PRODUCT_OPTION}/${item.id}`)
        }
      }
    })
  }
}

export default addProductToShoppingCart
