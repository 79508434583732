import type { MarketplaceListFilter } from '../../../definitions'
import { MarketplaceView } from '../../../enums'

const isEmptyMarketplaceListFilter = (filter: MarketplaceListFilter): boolean => {
  if (!filter) {
    return true
  }

  // todo compare: tagIds, productTypes, merchantRecordStates
  return (
    (!filter.view || filter.view === MarketplaceView.DEFAULT) &&
    !filter.searchText &&
    !filter.categoryTagId &&
    !filter.merchantId &&
    (!Array.isArray(filter.tagIds) || filter.tagIds.length < 1) &&
    (!Array.isArray(filter.tagNames) || filter.tagNames.length < 1) &&
    (!Array.isArray(filter.excludeTagIds) || filter.excludeTagIds.length < 1) &&
    (!Array.isArray(filter.excludeTagNames) || filter.excludeTagNames.length < 1) &&

    // todo: deprecated
    !filter.vendorId
  )
}

export default isEmptyMarketplaceListFilter
