export interface RegionDef {
  code: string;
  name: string;
  scope: string;
}

const usStates: RegionDef[] = [
  { code: 'AL', name: 'Alabama', scope: 'continental' },
  { code: 'AK', name: 'Alaska', scope: 'continental' },
  { code: 'AZ', name: 'Arizona', scope: 'continental' },
  { code: 'AR', name: 'Arkansas', scope: 'continental' },
  { code: 'CA', name: 'California', scope: 'continental' },
  { code: 'CO', name: 'Colorado', scope: 'continental' },
  { code: 'CT', name: 'Connecticut', scope: 'continental' },
  { code: 'DE', name: 'Delaware', scope: 'continental' },
  { code: 'DC', name: 'District Of Columbia', scope: 'continental' },
  { code: 'FL', name: 'Florida', scope: 'continental' },
  { code: 'GA', name: 'Georgia', scope: 'continental' },
  { code: 'HI', name: 'Hawaii', scope: 'continental' },
  { code: 'ID', name: 'Idaho', scope: 'continental' },
  { code: 'IL', name: 'Illinois', scope: 'continental' },
  { code: 'IN', name: 'Indiana', scope: 'continental' },
  { code: 'IA', name: 'Iowa', scope: 'continental' },
  { code: 'KS', name: 'Kansas', scope: 'continental' },
  { code: 'KY', name: 'Kentucky', scope: 'continental' },
  { code: 'LA', name: 'Louisiana', scope: 'continental' },
  { code: 'ME', name: 'Maine', scope: 'continental' },
  { code: 'MD', name: 'Maryland', scope: 'continental' },
  { code: 'MA', name: 'Massachusetts', scope: 'continental' },
  { code: 'MI', name: 'Michigan', scope: 'continental' },
  { code: 'MN', name: 'Minnesota', scope: 'continental' },
  { code: 'MS', name: 'Mississippi', scope: 'continental' },
  { code: 'MO', name: 'Missouri', scope: 'continental' },
  { code: 'MT', name: 'Montana', scope: 'continental' },
  { code: 'NE', name: 'Nebraska', scope: 'continental' },
  { code: 'NV', name: 'Nevada', scope: 'continental' },
  { code: 'NH', name: 'New Hampshire', scope: 'continental' },
  { code: 'NJ', name: 'New Jersey', scope: 'continental' },
  { code: 'NM', name: 'New Mexico', scope: 'continental' },
  { code: 'NY', name: 'New York', scope: 'continental' },
  { code: 'NC', name: 'North Carolina', scope: 'continental' },
  { code: 'ND', name: 'North Dakota', scope: 'continental' },
  { code: 'OH', name: 'Ohio', scope: 'continental' },
  { code: 'OK', name: 'Oklahoma', scope: 'continental' },
  { code: 'OR', name: 'Oregon', scope: 'continental' },
  { code: 'PA', name: 'Pennsylvania', scope: 'continental' },
  { code: 'RI', name: 'Rhode Island', scope: 'continental' },
  { code: 'SC', name: 'South Carolina', scope: 'continental' },
  { code: 'SD', name: 'South Dakota', scope: 'continental' },
  { code: 'TN', name: 'Tennessee', scope: 'continental' },
  { code: 'TX', name: 'Texas', scope: 'continental' },
  { code: 'UT', name: 'Utah', scope: 'continental' },
  { code: 'VT', name: 'Vermont', scope: 'continental' },
  { code: 'VA', name: 'Virginia', scope: 'continental' },
  { code: 'WA', name: 'Washington', scope: 'continental' },
  { code: 'WV', name: 'West Virginia', scope: 'continental' },
  { code: 'WI', name: 'Wisconsin', scope: 'continental' },
  { code: 'WY', name: 'Wyoming', scope: 'continental' },

  // US Outlying Territories:
  { code: 'AS', name: 'American Samoa', scope: 'outlying-territories' },
  { code: 'GU', name: 'Guam', scope: 'continental' },
  { code: 'MP', name: 'Northern Mariana Islands', scope: 'outlying-territories' },
  { code: 'PR', name: 'Puerto Rico', scope: 'continental' },
  { code: 'UM', name: 'United States Minor Outlying Islands', scope: 'outlying-territories' },
  { code: 'VI', name: 'Virgin Islands', scope: 'outlying-territories' },

  // Armed Forces:
  { code: 'AA', name: 'Armed Forces Americas', scope: 'armed-forces' },
  { code: 'AP', name: 'Armed Forces Pacific', scope: 'armed-forces' },
  { code: 'AE', name: 'Armed Forces Others', scope: 'armed-forces' },
]

export default usStates
