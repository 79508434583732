import type { ApolloLink } from '@apollo/client'
import { from, split } from '@apollo/client'
import { HttpLink } from '@apollo/client/link/http'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

import getRequestHeaders from './getRequestHeaders'
import configStore from './config'

const getLink = (): ApolloLink => {
  const config = configStore.getConfig()
  console.log('services.apollo.getLink called.', { config })

  // https:// hasura.io/blog/moving-from-apollo-boost-to-graphql-subscriptions-with-apollo-client-cc0373e0adb0/

  // see https://www.apollographql.com/docs/react/networking/authentication/
  const setHeadersLink = setContext((req, { headers, skipAuthentication }) => {
    return {
      headers: {
        ...headers,
        ...getRequestHeaders(skipAuthentication),
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      console.log('GraphQL error response:', graphQLErrors)
      // graphQLErrors.map(({ message, locations, path }) =>
      //   console.log(
      //     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      //   ),
      // )
    }

    if (networkError) {
      console.log('GraphQL network error:', { networkError })
      if (networkError && (networkError as any).statusCode === 401) {
        globalCacheData.setUnauthorizedRequestReportedByMimbleApi(true)
      }
    }

    // if (response) {
    //   response.errors = undefined
    // }
  })

  const composedHttpLink = from([
    errorLink,
    setHeadersLink,
    new HttpLink({
      uri: config.graphQlEndpoint,
    }),
  ])

  const wsLink = new WebSocketLink({
    uri: config.subscriptionEndpoint,
    options: {
      reconnect: true,
      connectionParams: getRequestHeaders(),
    },
  })

  const composedWsLink = from([
    errorLink,
    wsLink,
  ])

  return split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    composedWsLink,
    composedHttpLink,
  )
}

export default getLink
