import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'
import type {
  GridColumns,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import {
  DataGridPro,
} from '@mui/x-data-grid-pro'

import './styles.css'
import type { UserListItem } from '../../../lib/core/definitions'
import { FiatCurrency, FundType, TokenName } from '../../../lib/core/enums'
import coreHelpers from '../../../lib/core/helpers'
import Moment from 'moment-timezone'

type Props = {
  users: UserListItem[] | null | undefined;
  isProcessing?: boolean;
  onOpenUser: (userId: string) => void;
};

const UserGrid: React.FC<Props> = (props): JSX.Element | null => {
  const { users, isProcessing, onOpenUser } = props

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (rowData: GridRowParams): void => {
    onOpenUser((rowData.row.id as string).replace(/-/g, ''))
  }

  const onExport = (): void => {
    if (!Array.isArray(users) || users.length < 1) {
      return
    }
    const data = coreHelpers.models.user.exportData(users)
    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
    link.target = '_blank'
    link.download = 'members.csv'
    link.click()
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(users) || users.length < 1) {
    return null
  }
  // see https://material-ui.com/components/data-grid/rendering/#components
  const columns: GridColumns = [
    { field: 'username', headerName: 'Handle', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 2 },
    { field: 'roles', headerName: 'Roles', flex: 1 },
    { field: 'appFeatures', headerName: 'Perms', flex: 1 },
    { field: 'trustLevel', headerName: 'Trust Level', align: 'right', flex: 1 },
    {
      field: 'maxPurchaseAmountCc',
      headerName: 'CC Limit',
      align: 'right',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value || params.value === 0
          ? coreHelpers.ui.formatAmount(params.value as number, FundType.FIAT, FiatCurrency.USD, false, 0)
          : '-'
      ),
    },
    {
      field: 'walletBalance',
      headerName: 'MIT Balance',
      align: 'right',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value || params.value === 0
          ? coreHelpers.ui.formatAmount(Number(params.value), FundType.TOKEN, TokenName.MIMBLE_TOKEN)
          : '-'
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Joined',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? Moment(Number(params.value)).format('YYYY-MM-DD') : '-'
      ),
      sortComparator: (v1, v2) => (
        Number(v2) - Number(v1)
      ),
    },
    {
      field: 'sessionEndedAt',
      headerName: 'Last Seen',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? Moment(Number(params.value)).format('YYYY-MM-DD') : '-'
      ),
      sortComparator: (v1, v2) => (
        Number(v2) - Number(v1)
      ),
    },
    { field: 'appVersion', headerName: 'App Ver.', flex: 1 },
    { field: 'os', headerName: 'OS', flex: 1 },
  ]
  const rows: GridRowsProp = users.map((user: any): GridRowModel => {
    return {
      id: user.id,
      username: `@${user.username}`,
      name: user.fullName,
      roles: user.roles,
      appFeatures: user.appFeatures,
      trustLevel: user.trustLevel,
      maxPurchaseAmountCc: user.maxPurchaseAmountCc,
      walletBalance: user.metadata && user.metadata.mimbleBalanceToken,
      createdAt: user.createdAt,
      sessionEndedAt: user.sessionEndedAt,
      appVersion: user.appVersion,
      os: user.appVersion,
    }
  })

  return (
    <div className='x-grid-data-view'>
      <div className='actions-row'>
        <IonButton
          size='small'
          fill='clear'
          color='medium'
          disabled={!Array.isArray(users) || users.length < 1 || isProcessing}
          onClick={onExport}
        >
          <IonIcon icon={cloudDownloadOutline} />
        </IonButton>
      </div>
      <div className='x-grid-wrapper'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  )
}

export default UserGrid
