import React from 'react'

import type { Chat } from '../../../../lib/core/definitions'
import { IonButton, IonIcon } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'
import ChatList from '../../../../components/ChatList/ChatList'

type Props = {
  chats?: Chat[] | null;
  onOpenChat?: (chatId: string) => void;
  onOpenSupportChat: () => void;
  onOpenContactsPage?: () => void;
}

const ChatDashboardWidget: React.FC<Props> = (props): JSX.Element | null => {
  const {
    chats,
    onOpenChat,
    onOpenSupportChat,
    onOpenContactsPage,
  } = props

  if (!Array.isArray(chats) || chats.length < 1) {
    return null
  }

  return (
    <div className='dashboard-widget chats'>
      <div className='header' onClick={onOpenContactsPage}>
        <span className='text'>Messages</span>
        <IonButton
          size='small'
          color='medium'
          fill='clear'
        >
          <IonIcon icon={chevronForward} />
        </IonButton>
      </div>
      <div className='list'>
        <ChatList
          onOpenSupportChat={onOpenSupportChat}
          onOpenChat={onOpenChat}
        />
      </div>
    </div>
  )
}

export default ChatDashboardWidget
