import React from 'react'

import type { Purchase } from '../../../lib/core/definitions'
import ModelEventList from '../../../components/ModelEventList/ModelEventList'

type Props = {
  purchase: Purchase;
};

const ModelEventsSection: React.FC<Props> = ({
  purchase,
}: Props): JSX.Element | null => {
  if (
    !purchase ||
    !purchase.metadata ||
    !Array.isArray(purchase.metadata.events) ||
    purchase.metadata.events.length < 1
  ) {
    return null
  }

  return (
    <div className='withStandardPadding'>
      <div className='sectionCaption'>Change History</div>
      <ModelEventList
        events={purchase.metadata.events}
      />
    </div>
  )
}

export default ModelEventsSection
