import type { User, UserInput } from '../../../../../lib/core/definitions'
import type { TfaAttribute, ClearAttributeResult } from '../definitions'

const clearAttribute = (
  attribute: TfaAttribute,
  activeUserId: string,
  updateActiveUser: (userInput: UserInput) => Promise<User | undefined>,
): Promise<ClearAttributeResult> => {
  return new Promise((resolve) => {
    if (attribute === 'phoneNumber' || attribute === 'password') {
      resolve({
        ok: false,
        errorMessage: 'Please enter a value',
      })
    }
    const userInput: UserInput = { id: activeUserId }
    if (attribute === 'email') {
      userInput.email = null
    }

    updateActiveUser(userInput).then(() => {
      resolve({
        ok: true,
        uiMessage: 'Successfully saved changes',
      })
    }, (error) => {
      console.error(error)
      resolve({
        ok: false,
        errorMessage: 'Failed to save changes. Please try again.',
      })
    })
  })
}

export default clearAttribute
