import React from 'react'
import './styles.css'
import { IonCheckbox, IonLabel } from '@ionic/react'

type Props = {
  isChecked?: boolean;
  isDisabled?: boolean;
  label: string | JSX.Element;
  validationError?: string;
  className?: string;
  onChange: (checked: boolean) => void;
}
const FormCheckbox: React.FC<Props> = (props): JSX.Element | null => {
  const {
    isChecked,
    isDisabled,
    label,
    validationError,
    className,
    onChange,
  } = props
  // console.log('FormCheckbox.render called.', { isChecked, isDisabled, label, validationError });

  const handleClick = (): void => {
    onChange(!isChecked)
  }

  const labelEle = typeof label === 'string'
    ? <IonLabel className='lightText'>{label}</IonLabel>
    : label
  return (
    <div className={`rowWithCenterAlignedItems ${className || ''}`}>
      <div className='formCheckboxCol0'>
        <IonCheckbox
          slot='start'
          checked={isChecked}
          disabled={isDisabled}
          onClick={handleClick}
        />
      </div>
      <div className='formCheckboxCol2' onClick={handleClick}>
        {labelEle}
      </div>
    </div>
  )
}

export default FormCheckbox
