import React from 'react'
import { IonButton, IonItem } from '@ionic/react'

import type { ContactUserListItem, UserIdentInfo } from '../../lib/core/definitions'
import Avatar from '../Avatar/Avatar'

type Props = {
  user: ContactUserListItem | UserIdentInfo
  isSelected: boolean
  buttonLabel: string | undefined
  className?: string
  onSelectUser: (user: ContactUserListItem | UserIdentInfo | undefined) => void
}

const UserListItem: React.FC<Props> = (props): JSX.Element | null => {
  const {
    user,
    isSelected,
    buttonLabel,
    className,
    onSelectUser,
  } = props

  const onClick = () => {
    if (onSelectUser) {
      onSelectUser(user)
    }
  }

  // ===================================================================================================================
  // Rendering:
  const nickname = user.nickname
  const line1 = nickname ? `${nickname} (${user.fullName})` : user.fullName
  const lines: JSX.Element[] = []
  if (user.fullName) {
    lines.push(
      <div key='line1' className='contact-name'>
        {line1}
      </div>,
    )
    lines.push(
      <div key='line2'>
        <span className='lightText smallText'><span className='handleGlyph'>@</span>{user.username}</span>
      </div>,
    )
  } else {
    lines.push(
      <div key='line1' className='contact-name'>
        <span className='handleGlyph'>@</span>{user.username}
      </div>,
    )
  }

  let button: JSX.Element | undefined
  if (buttonLabel) {
    button = (
      <IonButton
        fill='outline'
        slot='end'
      >
        {buttonLabel}
      </IonButton>
    )
  }

  return (
    <IonItem
      key={user.id as string}
      className={`item ${className || ''}`}
      lines='none'
      detail={!buttonLabel}
      onClick={onClick}
    >
      <Avatar
        user={user}
        className='avatar'
      />
      <div>
        {lines}
      </div>
      {button}
    </IonItem>
  )
}

export default UserListItem
