import { CodeSignInStage } from '../../enums'

const labelsEn = Object.freeze({
  [CodeSignInStage.UNSET]: 'unset',
  [CodeSignInStage.STARTED]: 'started',
  [CodeSignInStage.NOTIFICATION_REQUESTED]: 'notification requested',
  [CodeSignInStage.NOTIFICATION_SENT]: 'notification sent',
  [CodeSignInStage.CODE_SUBMITTED]: 'unlock code submitted',
  [CodeSignInStage.FAILED]: 'failed',
  [CodeSignInStage.FINISHED]: 'finished',
})

const infoTextUs = Object.freeze({
  [CodeSignInStage.UNSET]: '',
  [CodeSignInStage.STARTED]: 'A text message or email will be sent out shortly.',
  [CodeSignInStage.NOTIFICATION_REQUESTED]: 'A text message or email has been sent out. It contains the unlock code you can use here to sign in.',
  [CodeSignInStage.NOTIFICATION_SENT]: 'A text message or email has been sent out. It contains the unlock code you can use here to sign in.',
  [CodeSignInStage.CODE_SUBMITTED]: 'The unlock code has been submitted and is being verified.',
  [CodeSignInStage.FAILED]: 'We could not verify the sign in information you provided. Please double-check it and try again.',
  [CodeSignInStage.FINISHED]: 'Authentication succeeded.',
})

export default {
  getLabel: (stage: CodeSignInStage | null | undefined, language = 'en'): string => {
    if (!stage) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[stage]
    }
    return ''
  },
  getInfoText: (stage: CodeSignInStage | null | undefined, language = 'en'): string => {
    if (!stage) {
      return ''
    }
    if (language === 'en') {
      return infoTextUs[stage]
    }
    return ''
  },
  isValid: (stage: CodeSignInStage): boolean => Object.values(CodeSignInStage).includes(stage),
}
