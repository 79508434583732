import React from 'react'
import { IonApp, IonPage, IonSpinner } from '@ionic/react'
import Lottie from 'react-lottie-player'

import './styles.css'
import lottieElephantWithGlow from './elephantWithGlow.json'

type Props = {
  showAnimation: boolean
  onCompleteAnimation: () => void
}

const LoadingApp: React.FC<Props> = (props): JSX.Element => {
  const { showAnimation, onCompleteAnimation } = props
  // -------------------------------------------------------------------------------------------------------------------
  // Event Handlers:

  // -------------------------------------------------------------------------------------------------------------------
  // Rendering:

  let content: JSX.Element
  if (showAnimation) {
    content = (
      <Lottie
        loop={1}
        play
        animationData={lottieElephantWithGlow}
        onComplete={onCompleteAnimation}
        rendererSettings={{ preserveAspectRatio: 'xMidYMax slice' }}
      />
    )
  } else {
    content = (
      <IonSpinner name='dots' />
    )
  }
  return (
    <IonApp>
      <IonPage>
        <div className='g-centered-box-outer'>
          <div className='g-centered-content'>
            {content}
          </div>
        </div>
      </IonPage>
    </IonApp>
  )
}

export default LoadingApp
