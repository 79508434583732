import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertMemberMessageTemplateMutationData,
  UpsertMemberMessageTemplateMutationVariables,
} from '../apollo/definitions'
import type {
  ApiQueryOptions,
  MemberMessageTemplateListFilter,
  MemberMessageTemplate,
  MemberMessageTemplateInput,
} from '../../lib/core/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadMemberMessageTemplate from './loadMemberMessageTemplate'
import logger from '../logger'

const upsertMemberMessageTemplate = (
  memberMessageTemplateInput: MemberMessageTemplateInput,
  isInTargetStateFunc: ((memberMessageTemplate: MemberMessageTemplate) => boolean) | 'watch-updated-at' | undefined,
  listFilter: MemberMessageTemplateListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<MemberMessageTemplate | undefined> => (
  // console.log('api.upsertMemberMessageTemplate called.', { memberMessageTemplateInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertMemberMessageTemplate: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertMemberMessageTemplateMutationData, UpsertMemberMessageTemplateMutationVariables> = {
      mutation: apollo.admin.mutations.upsertMemberMessageTemplate,
      variables: { memberMessageTemplate: memberMessageTemplateInput },
    }

    apolloClient.mutate<UpsertMemberMessageTemplateMutationData, UpsertMemberMessageTemplateMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertMemberMessageTemplate: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertMemberMessageTemplate ||
          !resp.data.upsertMemberMessageTemplate.id
        ) {
          logger.error('api.upsertMemberMessageTemplate: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((memberMessageTemplate: MemberMessageTemplate) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!memberMessageTemplateInput.id) {
            logger.error('api.upsertMemberMessageTemplate: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertMemberMessageTemplate.updatedAt
            activeIsInTargetStateFunc = (memberMessageTemplate: MemberMessageTemplate) => memberMessageTemplate.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertMemberMessageTemplate)
        ) {
          cache.updateMemberMessageTemplate(
            resp.data.upsertMemberMessageTemplate,
            apolloClient,
          )
          resolve(resp.data.upsertMemberMessageTemplate)
          return
        }

        const memberMessageTemplateId = resp.data.upsertMemberMessageTemplate.id

        setTimeout(() => {
          // console.log('api.upsertMemberMessageTemplate: calling loadMemberMessageTemplate')
          loadMemberMessageTemplate(
            memberMessageTemplateId,
            activeIsInTargetStateFunc,
            listFilter,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((memberMessageTemplate) => {
              // console.log('api.upsertMemberMessageTemplate: resolving with memberMessageTemplate', memberMessageTemplate)
              resolve(memberMessageTemplate)
            }, (error) => {
              logger.error('api.upsertMemberMessageTemplate: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertMemberMessageTemplate: error', { error })
        reject(error)
      })
  })
)

export default upsertMemberMessageTemplate
