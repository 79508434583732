import type { User } from '../../../definitions'
import { AppFeature } from '../../../enums'
import getAppFeaturesAsArray from './getAppFeaturesAsArray'

const canBuyMimbleTokens = (user: User | null | undefined): boolean => {
  if (!user || !user.appFeatures) {
    return false
  }

  const featureList = Array.isArray(user.appFeatures)
    ? user.appFeatures
    : getAppFeaturesAsArray(user.appFeatures)

  return featureList.includes(AppFeature.BUY_WITH_CREDIT_CARD)
}

export default canBuyMimbleTokens
