import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import type { AnimationName } from '../../lib/core/enums'
import animations from '../../animations'

interface ContainerProps {
  delay?: number
  loop?: number
  width?: string
  height?: string
  name: AnimationName | undefined
  onClick?: () => void
  onComplete?: () => void
}

const FullPageAnimation: React.FC<ContainerProps> = ({
  delay,
  loop,
  name,
  width,
  onClick,
  onComplete,
}): JSX.Element | null => {
  const [play, setPlay] = useState(!delay)

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setPlay(true)
      }, delay)
    }
  }, [])

  if (!name) {
    return null
  }

  const animationData = animations.fullPage[name]
  if (!animationData) {
    return null
  }

  return (
    <Lottie
      loop={loop}
      play={play}
      animationData={animationData}
      onComplete={onComplete}
      onClick={onClick}
      rendererSettings={{ preserveAspectRatio: 'xMidYMax slice' }}
      style={{
        width: width || '100vw',
        height: '100vh',
        position: 'absolute',
        zIndex: 9,
        bottom: 0,
        left: 0,
      }}
    />
  )
}

FullPageAnimation.defaultProps = {
  delay: 0,
  loop: 1,
}

export default FullPageAnimation
