import React, { useContext, useState } from 'react'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import type { ApiQueryOptions, ProductOptionInput } from '../../../lib/core/definitions'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type { ProductOptionQueryData, ProductOptionQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { UiMessage } from '../../../lib/core/enums'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import ProductOptionForm from './ProductOptionForm'

const appPageId = AppPage.AdminEditProductOptionPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  productId: string
  productOptionId: string
}

const EditProductOptionPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { productId, productOptionId } = useParams<keyof Params>() as unknown as Params
  const goBackUri = productId ? `${AppRoute.ADMIN_PRODUCT}/productId` : AppRoute.ADMIN_PRODUCTS

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [isUpsertingProductOption, setIsUpsertingProductOption] = useState(false)

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading product option:
  const {
    data: productOptionLoadedData,
    loading: isLoadingProductOption,
    refetch: reloadProductOption,
    client: apolloClient,
  } = useQuery<ProductOptionQueryData, ProductOptionQueryVariables>(
    apollo.queries.productOption, {
      variables: { productOptionId: productOptionId as string },
      skip: !productOptionId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: ProductOptionQueryData) => {
        const returnedProductOption = data ? data.productOption : undefined
        if (returnedProductOption === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const productOption = productOptionLoadedData && productOptionLoadedData.productOption

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingProductOption || isUpsertingProductOption

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  // ===================================================================================================================
  // Event Handlers:
  const onUpsertProductOption = (productOptionInput: ProductOptionInput): void => {
    if (!productOptionInput.productId) {
      productOptionInput.productId = productId
    }
    setIsUpsertingProductOption(true)
    api.upsertProductOption(
      productOptionInput,
      productOptionInput.id ? 'watch-updated-at' : undefined,
      apolloClient,
      undefined,
    ).then((updatedProductOption) => {
      if (!updatedProductOption) {
        setIsUpsertingProductOption(false)
        console.error('api.upsertProductOption did not return an updated object.')
        showUiMessage('Error saving this item.')
        return
      }
      api.loadProduct(
        updatedProductOption.productId as string,
        undefined,
        undefined,
        apolloClient,
        {
          includeMerchant: true,
          includeProductOptions: true,
          includeStoredProducts: true,
          includeTags: true,
        }
      ).then(() => {
        setIsUpsertingProductOption(false)
        showUiMessage('Successfully updated this inventory item.')
        navigate(`${AppRoute.ADMIN_PRODUCT}/${updatedProductOption.productId}`)
      }, (error) => {
        setIsUpsertingProductOption(false)
        console.error(error)
        showUiMessage('Error saving this item.')
      })
    }, (error) => {
      setIsUpsertingProductOption(false)
      console.error(error)
      showUiMessage('Error saving this item.')
    })
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !reloadProductOption || !productOptionId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadProductOption({ productOptionId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onGoBack = (): void => {
    navigate(-1)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const pageTitle = productOption ? 'Edit Product Option' : 'Add Product Option'

  return (
    <IonPage className='app-page-admin edit-product-option-page'>
      <NavBar
        title={pageTitle}
        goBackUri={goBackUri}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <ProductOptionForm
          productOption={productOptionId ? productOption : null}
          onGoBack={onGoBack}
          onSave={onUpsertProductOption}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default EditProductOptionPage
