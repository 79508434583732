import { gql } from '@apollo/client'

export default gql`
  query Q($filter: ContactEventListFilter!) {
    contactEvents (filter: $filter) {
      id
      contactId
      eventType
      eventDate
      name
      notes
      adminNotes
      metadata
      recordStatus
      createdAt
      updatedAt
      reminders {
        id
        eventId
        timing
        notificationMethod
        messageText
        sentAt
        sendReport
        adminNotes
        metadata
        recordStatus
        createdAt
        updatedAt
      }
    }
  }`
