import type { RewardsQueryVariables } from '../definitions'
import type { RewardListFilter } from '../../../lib/core/definitions'
import getRewardListFilter from './getRewardListFilter'

const getRewardsQueryVariables = (
  listFilter: RewardListFilter | undefined,
  activeUserId: string,
): RewardsQueryVariables => ({
  filter: listFilter || getRewardListFilter(activeUserId),
})

export default getRewardsQueryVariables
