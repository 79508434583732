import { PaymentErrorCode } from '../../enums'

const labelsEn = Object.freeze({
  [PaymentErrorCode.CANCELED]: 'The payment was canceled',
  [PaymentErrorCode.CARD_EXPIRED]: 'The credit card has expired',
  [PaymentErrorCode.CARD_NOT_SUPPORTED]: 'The credit card is not supported',
  [PaymentErrorCode.CREDIT_CARD_REPORTED_LOST]: 'The credit card has been reported lost',
  [PaymentErrorCode.CREDIT_CARD_REPORTED_STOLEN]: 'The credit card has been reported stolen',
  [PaymentErrorCode.CURRENCY_NOT_SUPPORTED]: 'The currency is not supported',
  [PaymentErrorCode.DUPLICATED_TRANSACTION]: 'The payment has been identified as duplicate',
  [PaymentErrorCode.FRAUD_SUSPECTED]: 'Fraud suspected',
  [PaymentErrorCode.IN_ESCROW]: 'In escrow',
  [PaymentErrorCode.INCORRECT_ADDRESS]: 'The entered address did not match',
  [PaymentErrorCode.INCORRECT_CREDIT_CARD_NUMBER]: 'The credit card number is incorrect',
  [PaymentErrorCode.INCORRECT_CVC]: 'The CVC number did not match',
  [PaymentErrorCode.INCORRECT_EXPIRATION_MONTH]: 'The expiration month did not match',
  [PaymentErrorCode.INCORRECT_EXPIRATION_YEAR]: 'The expiration year did not match',
  [PaymentErrorCode.INCORRECT_INPUT]: 'Your input could not be verified',
  [PaymentErrorCode.INCORRECT_NAME]: 'The name entered did not match the name on the card',
  [PaymentErrorCode.INCORRECT_PIN]: 'The PIN number is incorrect',
  [PaymentErrorCode.INCORRECT_POSTAL_CODE]: 'The postal code did not match',
  [PaymentErrorCode.INSUFFICIENT_FUNDS]: 'Insufficient funds have been reported',
  [PaymentErrorCode.INVALID_AMOUNT]: 'The amount of this payment was reported invalid',
  [PaymentErrorCode.PIN_TRY_EXCEEDED]: 'The maximum number of PIN tries has been exceeded.',
  [PaymentErrorCode.PROCESSOR_REJECTED]: 'The processor rejected the payment',
  [PaymentErrorCode.REFUNDING]: 'The payment was refunded',
  [PaymentErrorCode.TRY_AGAIN_LATER]: 'Please try again later',
  [PaymentErrorCode.UNKNOWN_REASON]: 'Unknown reasons',
  [PaymentErrorCode.UNSUPPORTED_CREDIT_CARD]: 'The credit card is not supported',
  [PaymentErrorCode.USER_BLOCKED]: 'Unknown reasons',
  [PaymentErrorCode.VALIDATION_FAILED]: 'The input validation for this payment failed',
})

const paymentErrorCodeHelpers = {
  getLabel: (taskType?: PaymentErrorCode | null, language?: string): string => {
    if (!taskType) {
      return ''
    }
    if (!language || language === 'US') {
      return labelsEn[taskType]
    }
    return ''
  },
  treatAsFraud: (code: PaymentErrorCode) => [
    PaymentErrorCode.CREDIT_CARD_REPORTED_STOLEN,
    PaymentErrorCode.FRAUD_SUSPECTED,
    PaymentErrorCode.USER_BLOCKED,
  ].includes(code),
}

export default paymentErrorCodeHelpers
