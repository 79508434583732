import React from 'react'
import { IonItem, IonLabel, IonList, IonPopover } from '@ionic/react'

import { AppRoute } from '../../enums'
import { useShoppingCart } from '../../contexts/ShoppingCartContext/ShoppingCartContext'
import coreHelpers from '../../lib/core/helpers'

interface Props {
  mouseEvent: Event | undefined
  show: boolean
  onClose: () => void
  onNavigateTo: (route: AppRoute | string) => void
}

export const MarketplacePopover: React.FC<Props> = (props): JSX.Element | null => {
  const {
    mouseEvent,
    show,
    onClose,
    onNavigateTo,
  } = props

  // ===================================================================================================================
  // State:
  const { shoppingCart, pendingShoppingCarts } = useShoppingCart()

  // ===================================================================================================================
  // Event Handlers:
  const onMarketplacePopoverClick = (target: string, id?: string): void => {
    if (target === 'marketplace') {
      onNavigateTo(AppRoute.MARKETPLACE)
    }
    if (target === 'mit') {
      onNavigateTo(AppRoute.BUY_MIMBLE_TOKENS)
    }
    if (target === 'cart') {
      onNavigateTo(AppRoute.SHOPPING_CART)
    }
    if (target === 'pending') {
      onNavigateTo(`${AppRoute.SHOPPING_CART}/${id}`)
    }
    onClose()
  }

  // ===================================================================================================================
  // Rendering:
  const items: JSX.Element[] = [
    <IonItem
      key='marketplace'
      lines='none'
      onClick={(): void => { onMarketplacePopoverClick('marketplace') }}
    >
      <IonLabel>Marketplace</IonLabel>
    </IonItem>,
    <IonItem
      key='mit'
      lines='none'
      onClick={(): void => { onMarketplacePopoverClick('mit') }}
    >
      <IonLabel>Buy Mimble Tokens</IonLabel>
    </IonItem>,
  ]

  if (!coreHelpers.models.shoppingCart.isEmpty(shoppingCart)) {
    items.push(
      <IonItem
        key='cart'
        lines='none'
        onClick={(): void => { onMarketplacePopoverClick('cart') }}
      >
        <IonLabel>Shopping Cart</IonLabel>
      </IonItem>,
    )
  }

  if (Array.isArray(pendingShoppingCarts) && pendingShoppingCarts.length > 0) {
    pendingShoppingCarts.forEach((cart, index) => {
      const number = pendingShoppingCarts.length > 1 ? ` #${index + 1}` : ''
      const formattedCreatedAtDate = coreHelpers.ui.formatDate(cart.createdAt, 'MMM Do')
      items.push(
        <IonItem
          key={cart.id}
          lines='none'
          onClick={(): void => {
            onMarketplacePopoverClick('pending', cart.id as string)
          }}
        >
          <IonLabel>Pending Order{number} ({formattedCreatedAtDate})</IonLabel>
        </IonItem>,
      )
    })
  }

  return (
    <IonPopover
      isOpen={show}
      event={mouseEvent as Event | undefined}
      onDidDismiss={onClose}
    >
      <IonList class='marketplace-popover-list'>
        {items}
      </IonList>
    </IonPopover>
  )
}

export default MarketplacePopover
