import { ContactListScope } from '../enums'
import { ContactsPageListScopeId } from '../NavSection/enums'
import type { TopNavTabDef } from '../../../components/TopNavBar/definitions'
import { TopNavTabId } from '../../../components/TopNavBar/enums'
import getContactListForScope from './getContactListForScope'
import getEventList from './getEventList'

const topNavTabDefs: TopNavTabDef[] = [
  { id: TopNavTabId.MESSAGES, label: 'Messages' },
  { id: TopNavTabId.EVENTS, label: 'Events' },
  { id: TopNavTabId.CONNECTIONS, label: 'Connections' },
]

const navSectionIdToScopeMapping = {
  [ContactsPageListScopeId.ALPHABETICAL]: ContactListScope.CONTACTS,
  [ContactsPageListScopeId.EVENTS]: ContactListScope.EVENTS,
  [ContactsPageListScopeId.MESSAGES]: ContactListScope.MESSAGES,
}

const localNavSectionIdToContactPageNavSectionMapping = {
  [ContactsPageListScopeId.ALPHABETICAL]: TopNavTabId.CONTACT_MESSAGES,
  [ContactsPageListScopeId.EVENTS]: TopNavTabId.CONTACT_MESSAGES,
  [ContactsPageListScopeId.MESSAGES]: TopNavTabId.CONTACT_MESSAGES,
}

const contactsPageHelpers = {
  getContactListForScope,
  getEventList,
  topNavTabDefs,
  navSectionIdToScopeMapping,
  localNavSectionIdToContactPageNavSectionMapping
}

export default contactsPageHelpers
