import React, { useState } from 'react'
import { IonButton, IonIcon, IonSpinner } from '@ionic/react'
import { star, starOutline } from 'ionicons/icons'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import type { ContactInput } from '../../lib/core/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../services/api'
import apollo from '../../services/apollo'

type Props = {
  contactId?: string | null
  isFavorite: boolean | null | undefined
  size?: number
  className?: string
  showUiMessage?: (message: string) => void
}

const FavoriteContactButton: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contactId,
    isFavorite,
    size,
    className,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const [isProcessing, setIsProcessing] = useState(false)

  // ===================================================================================================================
  // Event Handlers:
  const onUpdateFavoriteFlag = (): void => {
    const contactInput: ContactInput = {
      id: contactId,
      favorite: !isFavorite,
    }
    setIsProcessing(true)
    api.upsertContact(
      contactInput,
      (contact) => contact.favorite === !isFavorite,
      apollo.getContactUserListFilter(activeUserId as string),
      // { fromUserId: activeUserId as string, orderBy: 'fullName' },
      activeUserId as string,
      apolloClient,
    )
      .then(() => {
        setIsProcessing(false)
        showUiMessage && showUiMessage('Contact added to favorites')
      }, (error) => {
        console.error(error)
        setIsProcessing(false)
        showUiMessage && showUiMessage('Failed to add contact to favorites')
      })
  }

  // ===================================================================================================================
  // Rendering:
  if (!contactId && !isFavorite) {
    return null
  }

  const icon = isFavorite ? star : starOutline
  const iconClass = isFavorite ? 'favorite-contact-button-icon-active' : 'favorite-contact-button-icon'

  if (contactId) {
    let buttonContent: JSX.Element
    if (isProcessing) {
      buttonContent = (
        <IonSpinner
          className='spinner'
          style={{ width: size, height: size }}
        />
      )
    } else {
      buttonContent = (
        <IonIcon
          icon={icon}
          className={iconClass}
          style={{ width: size, height: size }}
        />
      )
    }
    return (
      <IonButton
        fill='clear'
        size='small'
        className={`favorite-contact-button ${className || ''}`}
        onClick={onUpdateFavoriteFlag}
      >
        {buttonContent}
      </IonButton>
    )
  }

  return (
    <IonIcon
      icon={icon}
      className={`${iconClass} ${className || ''} withPointerCursor`}
      style={{ width: size, height: size }}
    />
  )
}

FavoriteContactButton.defaultProps = {
  size: 25,
}

export default FavoriteContactButton
