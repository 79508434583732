import React, { useState } from 'react'
import { IonAlert } from '@ionic/react'
import { addOutline } from 'ionicons/icons'

import { AppRoute } from '../../../enums'
import { useApolloClient, useQuery } from '@apollo/client'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { useShoppingCart } from '../../../contexts/ShoppingCartContext/ShoppingCartContext'
import { FavoriteOrganizationInput } from '../../../lib/core/definitions'
import { FavoriteOrganizationsQueryData, FavoriteOrganizationsQueryVariables } from '../../../services/apollo/definitions'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import FavoriteMerchantsForm from '../../../components/FavoritesSections/FavoriteMerchantsSection/FavoriteMerchantsForm'
import FavoriteMerchantsSection from '../../../components/FavoritesSections/FavoriteMerchantsSection/FavoriteMerchantsSection'
import IconButton from '../../../components/IconButton/IconButton'
import logger from '../../../services/logger'
import pageHelpers from '../../../helpers/pageHelpers'

type Props = {
  onNavigate: (route: AppRoute | string) => void;
  showUiMessage: (message: string) => void;
}

const FavoritesTab: React.FC<Props> = (props): JSX.Element | null => {
  const {
    onNavigate,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const apolloClient =useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const { setShoppingCart } = useShoppingCart()

  // FavoriteOrganizations
  const [isShowingFavoriteMerchantForm, setIsShowingFavoriteMerchantForm] = useState(false)
  const [isEditingFavoriteMerchant, setIsEditingFavoriteMerchant] = useState(false)
  const [favoriteOrganizationIdToBeDeleted, setFavoriteOrganizationIdToBeDeleted] = useState<string | undefined>()
  const [isDeletingFavoriteOrganization, setIsDeletingFavoriteOrganization] = useState(false)

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: favoriteOrganizationsData,
    refetch: reloadFavoriteOrganizations,
  } = useQuery<FavoriteOrganizationsQueryData, FavoriteOrganizationsQueryVariables>(
    apollo.queries.favoriteOrganizations, {
      variables: {
        filter: {
          userId: activeUserId || undefined,
          orderBy: 'organizationName',
        },
      },
      skip: !activeUserId,
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
      // setToastMessage(error.message)
      // setShowToast(true)
      },
    },
  )
  const favoriteOrganizations = favoriteOrganizationsData ? favoriteOrganizationsData.favoriteOrganizations : undefined

  // ===================================================================================================================
  // Event Handlers:
  const onOpenFavoriteOrganization = (favoriteOrganizationId: string): void => {
    pageHelpers.addProductToShoppingCart({
      activeUserId,
      merchantId: favoriteOrganizationId,
      apolloClient,
      setShoppingCart,
      navigate: onNavigate,
    })
  }

  const onDeleteFavoriteOrganization = (favoriteOrganizationId: string): void => {
    setFavoriteOrganizationIdToBeDeleted(favoriteOrganizationId)
  }

  const onDeleteFavoriteOrganizationConfirmed = () => {
    if (!favoriteOrganizationIdToBeDeleted) {
      return
    }
    deleteFavoriteOrganization(favoriteOrganizationIdToBeDeleted)
    setFavoriteOrganizationIdToBeDeleted(undefined)
  }

  const onCancelDeleteFavoriteOrganization = () => {
    setFavoriteOrganizationIdToBeDeleted(undefined)
  }

  const onCloseFavoriteMerchantsSection = () => {
    setIsShowingFavoriteMerchantForm(false)
  }

  const onOpenAddFavoriteForm = () => {
    setIsShowingFavoriteMerchantForm(true)
  }

  const deleteFavoriteOrganization = (favoriteOrganizationId: string): void => {
    setIsDeletingFavoriteOrganization(true)
    api.deleteFavoriteOrganization(
      favoriteOrganizationId,
      activeUserId as string,
      apolloClient,
      undefined,
      { updateList: true },
    ).then(() => {
      setIsDeletingFavoriteOrganization(false)
      reloadFavoriteOrganizations()
      showUiMessage('The item was removed successfully.')
    }, (error) => {
      logger.error('FavoriteMerchantsSection.deleteItem: error received.', { error })
      setIsDeletingFavoriteOrganization(false)
      showUiMessage('Error removing this item.')
    })
  }

  const upsertFavoriteOrganization = (favoriteOrganizationInput: FavoriteOrganizationInput): void => {
    // TODO 🚨 Implement editing notes
    setIsEditingFavoriteMerchant(true)
    const existingFavoriteOrganization = favoriteOrganizationInput.organizationId && favoriteOrganizations
      ? favoriteOrganizations.find(fo => fo.organizationId === favoriteOrganizationInput.organizationId)
      : undefined
    if (existingFavoriteOrganization) {
      console.log(existingFavoriteOrganization)
      setIsEditingFavoriteMerchant(false)
      onCloseFavoriteMerchantsSection()
      showUiMessage('This item is already a favorite.')
      return
    }
    api.upsertFavoriteOrganization(
      favoriteOrganizationInput,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
      undefined,
      { updateList: true },
    ).then(() => {
      setIsEditingFavoriteMerchant(false)
      reloadFavoriteOrganizations()
      onCloseFavoriteMerchantsSection()
      showUiMessage('Successfully saved as a favorite.')
    }, (error) => {
      logger.error('FavoriteMerchantsSection.editItem: error received.', { error })
      setIsEditingFavoriteMerchant(false)
      onCloseFavoriteMerchantsSection()
      showUiMessage('Error saving this item.')
    })
  }

  // ===================================================================================================================
  // Rendering:
  if (!activeUser) {
    return null
  }

  let addFavoriteButton: JSX.Element | undefined
  if (!Array.isArray(favoriteOrganizations) || favoriteOrganizations.length < 10) {
    addFavoriteButton = (
      <IconButton
        icon={addOutline}
        text='Add'
        layout='vertical'
        className='button'
        iconSize={24}
        onClick={onOpenAddFavoriteForm}
      />
    )
  }

  return (
    <div className='favorites-tab withStandardPadding'>
      <div className='rowWithSpaceBetween' onClick={onOpenAddFavoriteForm}>
        <div className='sectionCaption'>Brands</div>
        {addFavoriteButton}
      </div>
      <FavoriteMerchantsSection
        favoriteOrganizations={favoriteOrganizations}
        userId={activeUserId as string}
        onAddFavoriteMerchant={() => setIsShowingFavoriteMerchantForm(true)}
        onDeleteFavoriteMerchant={onDeleteFavoriteOrganization}
        onOpenFavoriteMerchant={onOpenFavoriteOrganization}
        showUiMessage={showUiMessage}
        />
      <FavoriteMerchantsForm
        favoriteOrganizations={favoriteOrganizations}
        show={isShowingFavoriteMerchantForm}
        onCancel={onCloseFavoriteMerchantsSection}
        onSubmit={upsertFavoriteOrganization}
      />
      <IonAlert
        isOpen={!!favoriteOrganizationIdToBeDeleted}
        onDidDismiss={(): void => { setFavoriteOrganizationIdToBeDeleted(undefined) }}
        header='Remove Favorite Brand'
        subHeader=''
        message='Are you sure you want to remove this brand from your favorites?'
        buttons={[
          { text: 'Remove', handler: onDeleteFavoriteOrganizationConfirmed },
          { text: 'Cancel', cssClass: 'secondary', handler: onCancelDeleteFavoriteOrganization },
        ]}
      />
    </div>
  )
}

export default FavoritesTab
