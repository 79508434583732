import type { ApolloClient, QueryOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type { UserIdentInfo, FindUserFilter, ApiQueryOptions } from '../../lib/core/definitions'
import type { ApiClientOptions, FindUserQueryData, FindUserQueryVariables } from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const findUser = async (
  filter: FindUserFilter,
  isSignedIn: boolean,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<UserIdentInfo[] | undefined> => {
  // console.log('api.findUser called.')

  return new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.findUser: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    if (!filter) {
      logger.error('api.findUser: filter not given.')
      reject(new Error(ErrorCode.INVALID_INPUT))
      return
    }

    const queryParams: QueryOptions<FindUserQueryVariables> = {
      query: apollo.queries.findUser,
      variables: { filter },
      fetchPolicy: 'network-only',
    }
    if (!isSignedIn) {
      queryParams.context = { skipAuthentication: true }
    }
    apolloClient.query<FindUserQueryData, FindUserQueryVariables>(queryParams)
      .then((resp) => {
        if (!resp || !resp.data) {
          logger.error('api.findUser: query did not return any data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }
        resolve(resp.data.findUser)
      }, (error) => {
        logger.error('api.findUser: error', { error })
        reject(error)
      })
  })
}

export default findUser
