import React from 'react'
import { IonImg } from '@ionic/react'

import './styles.css'
import { ChatImageType } from '../../lib/core/enums'

interface Props {
  value: string
  chatImageUrl: string | null | undefined
  chatImageType: ChatImageType | null | undefined
  className?: string
}

const GiftMessageText: React.FC<Props> = (props): JSX.Element | null => {
  const {
    value,
    chatImageUrl,
    chatImageType,
    className,
  } = props

  // ===================================================================================================================
  // Rendering:
  let imageSection: JSX.Element | undefined
  if (chatImageUrl && chatImageType) {
    if (chatImageType === ChatImageType.GIPHY) {
      imageSection = (
        <div className='image-wrapper'>
          <IonImg
            key={chatImageUrl}
            src={chatImageUrl}
            className='attached-image'
          />
        </div>
      )
    }
  }

  return (
    <div className={`gift-message-preview ${className || ''}`}>
      {imageSection}
      <div className='withStandardPadding'>
        {value.split('\n').map((item, key) => {
          return <span key={key}>{item}<br /></span>
        })}
      </div>
    </div>
  )
}

export default GiftMessageText
