import React from 'react'
import { IonIcon, IonItem } from '@ionic/react'
import {
  cloudUploadOutline,
  constructOutline,
  heartOutline,
  informationCircleOutline,
  logOut,
  logOutOutline,
  pricetagOutline,
  receiptOutline,
  settingsOutline,
  shareSocialOutline,
  starOutline
} from 'ionicons/icons'

import './styles.css'
import { AppRoute } from '../../../enums'
import { UserRole } from '../../../lib/core/enums'
import { useApolloClient } from '@apollo/client'
import { useGlobalCache } from '../../../contexts/GlobalCacheContext/GlobalCacheContext'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import appVersion from '../../../version'
import authService from '../../../services/auth'
import Avatar from '../../../components/Avatar/Avatar'
import coreHelpers from '../../../lib/core/helpers'

type Props = {
  onNavigate: (route: AppRoute | string) => void
  onSignOut: () => void
  onOpenFavorites: () => void
  onOpenUserOrderHistory: () => void
  onOpenUserProfile: () => void
  onOpenUserSettings: () => void
  onOpenUserWishList: () => void
}

const UserAccountDashboard: React.FC<Props> = (props): JSX.Element | null => {
  const {
    onNavigate,
    onSignOut,
    onOpenFavorites,
    onOpenUserOrderHistory,
    onOpenUserProfile,
    onOpenUserSettings,
    onOpenUserWishList,
  } = props

  // ===================================================================================================================
  // State:
  const { activeUser, reloadActiveUser } = useMimbleData()
  const { getProxyUser } = useGlobalCache()
  const apolloClient = useApolloClient()

  const userIsAdmin = (
    !!activeUser &&
    !!activeUser.roles &&
    coreHelpers.models.user.hasRole(activeUser.roles, UserRole.ADMIN)
  )

  // ===================================================================================================================
  // Event Handlers:
  const onNewInvite = (): void => {
    onNavigate(AppRoute.NEW_INVITATION)
  }

  const onOpenAbout = (): void => {
    onNavigate(AppRoute.ABOUT)
  }

  const onUploadGiftCard = (): void => {
    onNavigate(AppRoute.UPLOAD_GIFT_CARD)
  }

  const onOpenMITWallet = (): void => {
    onNavigate(AppRoute.TOKENS)
  }

  const onBuyMIT = (): void => {
    onNavigate(AppRoute.BUY_MIMBLE_TOKENS)
  }

  const onOpenAdminConsole = (): void => {
    onNavigate(AppRoute.ADMIN_HOME)
  }

  const onClearSignInAs = (): void => {
    authService.clearSignInAsUser(apolloClient)
    setTimeout(() => {
      reloadActiveUser().then(() => {
        onNavigate(AppRoute.HOME)
      }, (error) => {
        console.error(error)
      })
    }, 500)
  }

  // ===================================================================================================================
  // Rendering:
  if (!activeUser) {
    return null
  }

  let activeUserName = ''
  if (activeUser.fullName) {
    const name = coreHelpers.models.user.parseName(activeUser.fullName)
    if (name && (name.first || name.last)) {
      activeUserName = name.first || name.last || ''
    } else {
      activeUserName = activeUser.fullName
    }
  }

  const menuDefs = [
    {
      key: 'mit-wallet',
      text: 'MIT Wallet',
      icon: informationCircleOutline,
      handler: onOpenMITWallet,
      show: true,
    },
    {
      key: 'buy-mit',
      text: 'Buy Mimble Tokens',
      icon: pricetagOutline,
      handler: onBuyMIT,
      show: true,
    },
    {
      key: 'upload-card',
      text: 'Upload Gift Card',
      icon: cloudUploadOutline,
      handler: onUploadGiftCard,
      show: true,
    },
    {
      key: 'wishlist',
      text: 'My Wish List',
      icon: heartOutline,
      handler: onOpenUserWishList,
      show: true,
    },
    {
      key: 'favorites',
      text: 'My Favorites',
      icon: starOutline,
      handler: onOpenFavorites,
      show: true,
    },
    {
      key: 'order-history',
      text: 'Order History',
      icon: receiptOutline,
      handler: onOpenUserOrderHistory,
      show: true,
    },
    {
      key: 'settings',
      text: 'Settings',
      icon: settingsOutline,
      handler: onOpenUserSettings,
      show: true,
    },
    {
      key: 'new-invite',
      text: 'Invite a friend',
      icon: shareSocialOutline,
      handler: onNewInvite,
      show: true,
    },
    {
      key: 'sign-out',
      text: 'Sign Out',
      icon: logOutOutline,
      className: 'withDoubleBottomMargin',
      handler: onSignOut,
      show: true,
    },
    {
      key: 'admin-console',
      text: 'Admin Console',
      icon: constructOutline,
      handler: onOpenAdminConsole,
      show: !!userIsAdmin,
    },
    {
      key: 'admin-clear-sign-in-as',
      text: 'Clear Sign-In-As',
      icon: logOut,
      handler: onClearSignInAs,
      show: !!getProxyUser(),
    },
    {
      key: 'about',
      text: `About Mimble${appVersion ? ' v.' + appVersion : ''}`,
      icon: informationCircleOutline,
      handler: onOpenAbout,
      show: true,
    },
  ]

  const items = (
    menuDefs.filter(def => def.show === undefined || def.show === true)
    .map((def): JSX.Element => {
      const clickHandler = (): void => {
        if (def.handler) {
          def.handler()
        }
      }
      return (
        <IonItem
          key={def.key}
          detail
          lines='none'
          className={`withPointerCursor ${def.className || ''}`}
          onClick={clickHandler}
        >
          <IonIcon
            slot='start'
            icon={def.icon}
            className='dashboard-item-icon'
          />
          {def.text}
        </IonItem>
      )
    })
  )

  return (
    <div className='user-account-dashboard withDoublePadding'>
      <IonItem
        key='header'
        className='withDoubleBottomMargin withPointerCursor'
        lines='none'
        detail
        onClick={onOpenUserProfile}
      >
        <Avatar
          user={activeUser}
          className='withStandardRightMargin'
          size={60}
        />
        <div className='col'>
          <div className='largeText'>
            {activeUserName}
          </div>
          <div className='lightText smallText'>
            <span className='handleGlyph'>@</span>
            {activeUser.username}
          </div>
        </div>
      </IonItem>
      {items}
    </div>
  )
}

export default UserAccountDashboard
