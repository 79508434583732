import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { PurchaseTransfer, PurchaseTransferListItem } from '../../../lib/core/definitions'
import type {
  PurchaseTransferQueryData,
  PurchaseTransferQueryVariables,
  PurchaseTransfersQueryData,
  PurchaseTransfersQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  purchaseTransfer: PurchaseTransfer,
  queryListVariables: PurchaseTransfersQueryVariables,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updatePurchaseTransfer.updateInList called.', { purchaseTransfer })
  if (!purchaseTransfer) {
    logger.error('api.cache.updatePurchaseTransfer.updateInList: no purchaseTransfer given.')
    return
  }

  try {
    const cachedPurchaseTransfersData = apolloClient.readQuery<PurchaseTransfersQueryData, PurchaseTransfersQueryVariables>({
      query: apollo.queries.purchaseTransfers,
      variables: queryListVariables,
    })

    if (
      !cachedPurchaseTransfersData ||
      !Array.isArray(cachedPurchaseTransfersData.purchaseTransfers) ||
      cachedPurchaseTransfersData.purchaseTransfers.length < 1
    ) {
      const listItem = coreHelpers.models.purchaseTransfer.updatePurchaseTransferListItemFromPurchaseTransfer(undefined, purchaseTransfer)
      apolloClient.writeQuery<PurchaseTransfersQueryData, PurchaseTransfersQueryVariables>({
        query: apollo.queries.purchaseTransfers,
        variables: queryListVariables,
        data: { purchaseTransfers: [listItem] },
      })
      return
    }

    const updatedPurchaseTransfer = coreHelpers.models.updateModelInList<PurchaseTransferListItem, PurchaseTransfer>(
      cachedPurchaseTransfersData.purchaseTransfers,
      purchaseTransfer,
      undefined,
      true,
      coreHelpers.models.purchaseTransfer.updatePurchaseTransferListItemFromPurchaseTransfer,
    )

    apolloClient.writeQuery<PurchaseTransfersQueryData>({
      query: apollo.queries.purchaseTransfers,
      variables: queryListVariables,
      data: { purchaseTransfers: updatedPurchaseTransfer },
    })
  } catch (error) {
    logger.error('api.cache.updatePurchaseTransfer.updateInList: error.', { purchaseTransfer, error })
  }
}

const updatePurchaseTransfer = (
  purchaseTransfer: PurchaseTransfer,
  queryListVariables: PurchaseTransfersQueryVariables | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updatePurchaseTransfer called.', { purchaseTransfer })
  if (!apolloClient) {
    logger.error('api.cache.updatePurchaseTransfer: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!purchaseTransfer || !purchaseTransfer.id) {
    logger.error('api.cache.updatePurchaseTransfer: no purchaseTransfer given.', { purchaseTransfer })
    return
  }

  try {
    apolloClient.writeQuery<PurchaseTransferQueryData, PurchaseTransferQueryVariables>({
      query: apollo.queries.purchaseTransfer,
      variables: { purchaseTransferId: purchaseTransfer.id },
      data: { purchaseTransfer },
    })

    if (queryListVariables) {
      updateInList(
        purchaseTransfer,
        queryListVariables,
        activeUserId,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updatePurchaseTransfer: error.', { purchaseTransfer, error })
  }
}

export default updatePurchaseTransfer
