import React, { useState } from 'react'

import './styles.css'
import dialogConfigs from '../../../contexts/DialogContext/dialogConfigs'
import WarningBlock from '../../../components/WarningBlock/WarningBlock'

type Props = {
  isInStock: boolean
  onRemoveOutOfStockItems: () => void
}

const OutOfStockWarning: React.FC<Props> = (props): JSX.Element | null => {
  const { isInStock, onRemoveOutOfStockItems } = props

  const [isShowingMore, setIsShowingMore] = useState(false)

  const onChangeShowMore = (newIsShowingMore: boolean): void => {
    setIsShowingMore(newIsShowingMore)
  }

  if (isInStock) {
    return null
  }

  const removeItemsLink = (
    <span className='linkText' onClick={onRemoveOutOfStockItems}>
      Remove items
    </span>
  )

  const messageElement = (
    <div className=''>
      Some of the products in your shopping cart are back-ordered.&nbsp;
      {!isShowingMore && removeItemsLink}
    </div>
  )

  const text = dialogConfigs['out-of-stock-items-in-shopping-cart'].text
  const showMoreElement = (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: text as string }}
        className='withStandardBottomMargin'
      />
      {removeItemsLink}
    </>
  )

  return (
    <WarningBlock
      messageElement={messageElement}
      showMoreElement={showMoreElement}
      className='withStandardBottomMargin'
      onChangeShowMore={onChangeShowMore}
    />
  )
}

export default OutOfStockWarning
