import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, Merchant, MerchantListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  MerchantsQueryData,
  MerchantsQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadMerchants = (
  filter: MerchantListFilter,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
  addAliases = false,
): Promise<Merchant[] | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadMerchants called.')
    if (!apolloClient) {
      logger.error('api.loadMerchants: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<MerchantsQueryVariables> = {
      query: apollo.queries.merchants(addAliases),
      variables: { filter },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    apolloClient.query<MerchantsQueryData, MerchantsQueryVariables>(queryOptions)
      .then((resp) => {
        if (
          !resp ||
          !resp.data ||
          !resp.data.merchants
        ) {
          logger.error('api.loadMerchants.load: query did not return expected data.')
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        resolve(resp.data.merchants)
      }, (error) => {
        logger.error('api.loadMerchants.load: error.', { error })
        reject(error)
      })
  })
)

export default loadMerchants
