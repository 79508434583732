import getAmountsInfo from './getAmountsInfo'
import getAvailableInventory from './getAvailableInventory'
import getInventory from './getInventory'
import getProductOption from './getProductOption'
import isInStock from './isInStock'
import showInShoppingCart from './showInShoppingCart'

export default {
  getAmountsInfo,
  getAvailableInventory,
  getInventory,
  getProductOption,
  isInStock,
  showInShoppingCart,
}
