import type { ContactEventListEntry, ContactUserListItem } from '../../../lib/core/definitions'
import { RecordStatus } from '../../../lib/core/enums'
import coreHelpers from '../../../lib/core/helpers'
import parseEventDate from '../../../lib/core/helpers/models/contact/parseEventDate'

const getEventList = (
  contacts: ContactUserListItem[] | undefined,
  searchText: string | undefined,
): ContactEventListEntry[] => {
  if (!Array.isArray(contacts) || contacts.length < 1) {
    return []
  }

  return contacts
    .filter(contact => (
      contact.contactMetadata &&
      Array.isArray(contact.contactMetadata.events) &&
      contact.contactMetadata.events.length > 0 &&
      contact.contactRecordStatus === RecordStatus.ACTIVE &&
      coreHelpers.models.contact.isItemATextSearchHit(contact, searchText)
    ))
    .reduce<ContactEventListEntry[]>((events, contact): ContactEventListEntry[] => {
      if (
        !contact.contactMetadata ||
        !Array.isArray(contact.contactMetadata.events) ||
        Array.isArray(contact.contactMetadata.events.length < 1)
      ) {
        return events
      }
      return events.concat(
        contact.contactMetadata.events.map((event): ContactEventListEntry => {
          let eventWithParsedDate = event
          if (!event.parsedDate) {
            eventWithParsedDate = {
              ...event,
              parsedDate: parseEventDate(event.eventDate),
            }
          }
          return {
            id: event.id as string,
            event: eventWithParsedDate,
            contact,
          }
        })
      )
    }, [])
    .sort(coreHelpers.models.contact.compareListItemsByEvent)
}

export default getEventList
