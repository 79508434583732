import versionService from '../../services/version'

export default (delay = 1000): void => {
  try {
    if (!versionService.latestVersion) {
      setTimeout((): void => {
        versionService.getLatestVersion().then(() => {
          // console.log('Received latest app version: ', version);
        }, (error: Error): void => {
          console.error(error)
        })
      }, delay)
    }
  } catch (error) {
    console.error(error)
  }
}
