import React, { useEffect, useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import './styles.css'
import { AppRoute } from '../../../enums'
import type { OrderStatus } from '../../../lib/core/enums'
import type { OrdersQueryData, OrdersQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import coreHelpers from '../../../lib/core/helpers'
import OrderList from './OrderList'
import ProcessingOverlay from '../../../components/ProcessingOverlay/ProcessingOverlay'

let filterOrderHistoryStatusOptions: JSX.Element[]
const getFilterOrderHistoryStatusOptions = (orderStatuses: OrderStatus[] | undefined): JSX.Element[] => {
  if (!filterOrderHistoryStatusOptions && orderStatuses) {
    filterOrderHistoryStatusOptions = orderStatuses.map(os => (
      <MenuItem key={os} value={os}>
        {coreHelpers.type.orderStatus.getLabel(os)}
      </MenuItem>
    ))
  }
  return filterOrderHistoryStatusOptions
}

type Props = {
  refreshId: number
  onNavigate: (route: AppRoute | string) => void
  showUiMessage: (message: string) => void
}

const OrdersTab: React.FC<Props> = (props): JSX.Element | null => {
  const {
    refreshId,
    onNavigate,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const apolloClient = useApolloClient()
  const [scope, setScope] = useState<OrderStatus | 'all'>('all')

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: userOrdersData,
    loading: isLoadingOrders,
    refetch: reloadOrders,
  } = useQuery<OrdersQueryData, OrdersQueryVariables>(
    apollo.queries.orders, {
      variables: apollo.getOrdersQueryVariables(undefined, activeUserId as string),
      skip: !activeUserId,
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
    },
  )

  let orders = userOrdersData ? userOrdersData.orders : undefined
  let orderStatuses: OrderStatus[] | undefined
  if (Array.isArray(orders) && orders.length > 0) {
    orders = orders.filter((o) => (scope === 'all' || o.status as string === scope))
    const statusSet = new Set<OrderStatus>()
    orders.forEach((o) => {
      if (o.status && coreHelpers.type.orderStatus.isValid(o.status)) {
        statusSet.add(o.status)
      }
    })
    orderStatuses = Array.from(statusSet.values()).sort((s1, s2) => (
      coreHelpers.type.orderStatus.getLabel(s1).localeCompare(coreHelpers.type.orderStatus.getLabel(s2))
    ))
  }

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (reloadOrders) {
      reloadOrders()
    }
  }, [refreshId])

  // ===================================================================================================================
  // Event Handlers:

  const onOpenOrder = (orderId: string): void => {
    api.loadOrder(
      orderId,
      undefined,
      undefined,
      activeUserId as string,
      apolloClient,
    ).then((order) => {
      if (order) {
        onNavigate(`${AppRoute.ORDER}/${orderId}`)
      }
    }, (error: Error) => {
      console.error(error)
    })
  }

  const onChangeScope = (event: any): void => {
    setScope(event.target.value)
  }

  // ===================================================================================================================
  // Rendering:
  if (!activeUser) {
    return null
  }

  let content: JSX.Element | undefined
  if (Array.isArray(orders) && orders.length > 0) {
    content = (
      <>
        <FormControl
          variant='outlined'
          fullWidth
        >
          <InputLabel id='select-time-label'>Filter</InputLabel>
          <Select
            labelId='select-time-label'
            id='select-time'
            value={scope}
            onChange={onChangeScope}
            label='Order History'
          >
            <MenuItem key='all' value='all'>All Orders</MenuItem>
            {getFilterOrderHistoryStatusOptions(orderStatuses)}
          </Select>
        </FormControl>
        <OrderList
          orders={orders}
          onSelectOrder={onOpenOrder}
          onNavigate={onNavigate}
          className='order-list'
        />
      </>
    )
  } else if (!isLoadingOrders) {
    return (
      <>
        <div className='lightText withStandardPadding withDoubleTopMargin rowWithCenterJustifiedItems withCenteredTextAlign'>
          Once you have placed orders in Mimble&apos;s marketplace, they will be shown here.
        </div>
        <div
          className='withDoubleTopMargin linkText rowWithCenterJustifiedItems'
          onClick={() => onNavigate(AppRoute.MARKETPLACE)}
        >
          Visit the Mimble marketplace
        </div>
      </>
    )
  }

  return (
    <div className='orders-tab'>
      {content}
      <ProcessingOverlay isProcessing={isLoadingOrders} />
    </div>
  )
}

export default OrdersTab
