import React, { useState } from 'react'
import { IonButton } from '@ionic/react'

import './styles.css'
import type { TransactionProcessor } from '../../../lib/core/enums'
import { FundType, OrgProductType, TokenName } from '../../../lib/core/enums'
import type { ShoppingCart as ShoppingCartType } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers/'
import ItemReward from './ItemReward'
import ShoppingCartRowGiftCard from './ShoppingCartRowGiftCard'
import ShoppingCartRowToken from './ShoppingCartRowToken'

type Props = {
  shoppingCart: ShoppingCartType | undefined
  isActiveShoppingCart: boolean
  onAddMarketplaceProduct: () => void
  onAddMimbleTokens: () => void
  onRemoveItem: (itemId: string) => void
}

const ShoppingCart: React.FC<Props> = (props): JSX.Element | null => {
  const {
    shoppingCart,
    isActiveShoppingCart,
    onAddMarketplaceProduct,
    onAddMimbleTokens,
    onRemoveItem,
  } = props
  const [editItemId, setEditItemId] = useState<string | undefined>()

  const onStartEditItem = (itemId: string) => {
    setEditItemId(itemId)
  }

  const onStopEditItem = (itemId: string) => {
    setEditItemId(undefined)
  }

  if (!shoppingCart) {
    return null
  }

  let totalPaymentAmount = 0
  let totalRewardAmount = 0
  let renderedItems: (JSX.Element | null)[] | null = null

  const transactionAmountPrecision = coreHelpers.ui.getAmountPrecision(
    shoppingCart.paymentFundType,
    shoppingCart.paymentCurrency,
  )

  if (Array.isArray(shoppingCart.items) && shoppingCart.items.length > 0) {
    renderedItems = shoppingCart.items
      .filter(item => (
        item.productType !== OrgProductType.GIFT_CARD || item.productOptionId
      ))
      .map((item, index): JSX.Element | null => {
        const amountsInfo = coreHelpers.models.shoppingCartItem.getAmountsInfo(
          item,
          shoppingCart.paymentFundType,
          shoppingCart.paymentCurrency,
          shoppingCart.paymentExchangeRate,
        )
        totalPaymentAmount += (amountsInfo.paymentAmount.amount || 0)
        totalRewardAmount += (amountsInfo.rewardAmount.amount || 0)

        if (item.productType === OrgProductType.GIFT_CARD) {
          return (
            <ShoppingCartRowGiftCard
              key={item.id}
              shoppingCart={shoppingCart}
              index={index}
              item={item}
              transactionProcessor={shoppingCart.paymentProcessor as TransactionProcessor}
              readonly={!isActiveShoppingCart}
              canEdit={false /* !editItemId */}
              isEditing={false /* !!editItemId && editItemId === item.id */}
              onStartEdit={onStartEditItem}
              onRemoveItem={onRemoveItem}
              onStopEdit={onStopEditItem}
            />
          )
        }

        if (item.productType === OrgProductType.MIMBLE_TOKEN) {
          return (
            <ShoppingCartRowToken
              key={item.id}
              shoppingCart={shoppingCart}
              index={index}
              item={item}
              readonly={!isActiveShoppingCart}
              transactionProcessor={shoppingCart.paymentProcessor as TransactionProcessor}
              onEdit={onAddMimbleTokens}
              onRemoveItem={onRemoveItem}
            />
          )
        }
        return null
      })
  }

  let formattedTotalPaymentAmount = ''
  if (totalPaymentAmount && totalPaymentAmount > 0) {
    formattedTotalPaymentAmount = coreHelpers.ui.formatAmount(
      totalPaymentAmount,
      shoppingCart.paymentFundType,
      shoppingCart.paymentCurrency,
      true,
      transactionAmountPrecision,
    )
  }

  let buttons: JSX.Element | undefined
  if (isActiveShoppingCart) {
    let addMimbleTokensButton: JSX.Element | undefined
    if (
      !Array.isArray(shoppingCart.items) ||
      shoppingCart.items.length < 1 ||
      !shoppingCart.items.find(i => i.productType === OrgProductType.MIMBLE_TOKEN)
    ) {
      addMimbleTokensButton = (
        <IonButton
          size='small'
          fill='outline'
          className='withStandardLeftMargin'
          onClick={onAddMimbleTokens}
        >
          {/* <IonIcon icon={addCircleOutline} className='withSmallRightMargin' /> */}
          Add MIT
        </IonButton>
      )
    }

    buttons = (
      <div className='add-item-row'>
        <IonButton
          size='small'
          fill='outline'
          onClick={onAddMarketplaceProduct}
        >
          {/* <IonIcon icon={addCircleOutline} className='withSmallRightMargin' /> */}
          Add Gift
        </IonButton>
        {addMimbleTokensButton}
      </div>
    )
  }

  return (
    <div className='shopping-cart'>
      <div className='cart-row header-row'>
        <div className='cart-col cart-col0 header-col' />
        <div className='cart-col cart-col1 header-col'>
          <br />
          <span className='product-title'>Product</span>
        </div>
        <div className='cart-col cart-col2 header-col'>
          <br />
          Count
        </div>
        <div className='cart-col cart-col3 header-col'>
          Amount
          <br />
          ({shoppingCart.paymentCurrency})
        </div>
      </div>
      {renderedItems}
      <div className='cart-row'>
        <div className='cart-col cart-col0'>
        </div>
        <div className='cart-col cart-col2 withRightTextAlign'>
        </div>
        <div className='cart-col cart-col3 withRightTextAlign'>
          <span className='lightText'>total:</span> <span className='total-amount'>{formattedTotalPaymentAmount}</span>
        </div>
      </div>
      {buttons}
      <ItemReward
        amount={totalRewardAmount}
        label='Total reward:'
        fundType={FundType.TOKEN}
        currency={TokenName.MIMBLE_TOKEN}
        precision={0}
        className='withSmallTopMargin'
        withBorder
        showIcon
      />
    </div>
  )
}

export default ShoppingCart
