import React from 'react'

import './styles.css'
import { FiatCurrency, FundType } from '../../lib/core/enums'
import type { Currency } from '../../lib/core/definitions'
import coreHelpers from '../../lib/core/helpers'
import MimbleTokenImage from '../MimbleTokenImage/MimbleTokenImage'

type Props = {
  amount: number
  fundType: FundType
  currency: Currency
  label?: string
  className?: string
  onClick?: () => void
}

const TokenAttachment: React.FC<Props> = (props): JSX.Element | null => {
  const {
    amount,
    fundType,
    currency,
    label,
    onClick,
    className,
  } = props
  const formattedAmount = coreHelpers.ui.formatAmount(
    amount,
    fundType,
    currency,
    true,
  )
  const fiatAmount = coreHelpers.cryptoCurrency.convertMimbleTokenToFiat(amount)
  const formattedFiatAmount = coreHelpers.ui.formatAmount(
    fiatAmount,
    FundType.FIAT,
    FiatCurrency.USD,
    true,
    2,
  )

  return (
    <div
      className={`token-attachment withPointerCursor ${className || ''}`}
      onClick={onClick}
    >
      <MimbleTokenImage size={30} />
      <div className='amount smallText'>{formattedAmount}</div>
      <div className='smallText lightText'>{label || formattedFiatAmount}</div>
    </div>
  )
}

export default TokenAttachment
