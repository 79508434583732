import type { Reward, RewardListItem } from '../../../definitions'
import { RecordStatus } from '../../../enums'
import type { RewardType } from '../../../enums'

const updateRewardListItemFromReward = (
  rewardListItem: RewardListItem | undefined,
  reward: Reward,
): RewardListItem => {
  if (!rewardListItem) {
    return {
      id: reward.id || '',
      userId: reward.userId || '',
      donorId: reward.donorId || '',
      freebiePurchaseId: reward.freebiePurchaseId || '',
      rewardType: reward.rewardType as RewardType,
      amount: reward.amount || 0,
      invitationLevel: reward.invitationLevel || 0,
      occurrence: reward.occurrence || 0,
      recordStatus: reward.recordStatus || RecordStatus.ACTIVE,
      createdAt: reward.createdAt || '',
      updatedAt: reward.updatedAt || '',
      updatedBy: reward.updatedBy || '',
    }
  }

  const updatedItem: RewardListItem = { ...rewardListItem }

  if (reward.userId && reward.userId !== rewardListItem.userId) {
    updatedItem.userId = reward.userId
  }
  if (reward.donorId && reward.donorId !== rewardListItem.donorId) {
    updatedItem.donorId = reward.donorId
  }
  if (reward.freebiePurchaseId && reward.freebiePurchaseId !== rewardListItem.freebiePurchaseId) {
    updatedItem.freebiePurchaseId = reward.freebiePurchaseId
  }
  if (reward.rewardType && reward.rewardType !== rewardListItem.rewardType) {
    updatedItem.rewardType = reward.rewardType
  }
  if (reward.amount !== undefined && reward.amount !== rewardListItem.amount) {
    updatedItem.amount = reward.amount
  }
  if (Number.isInteger(reward.invitationLevel) && reward.invitationLevel !== rewardListItem.invitationLevel) {
    updatedItem.invitationLevel = reward.invitationLevel as number
  }
  if (Number.isInteger(reward.occurrence) && reward.occurrence !== rewardListItem.occurrence) {
    updatedItem.occurrence = reward.occurrence as number
  }
  if (reward.recordStatus && reward.recordStatus !== rewardListItem.recordStatus) {
    updatedItem.recordStatus = reward.recordStatus
  }
  if (reward.createdAt && reward.createdAt !== rewardListItem.createdAt) {
    updatedItem.createdAt = reward.createdAt
  }
  if (reward.updatedAt && reward.updatedAt !== rewardListItem.updatedAt) {
    updatedItem.updatedAt = reward.updatedAt
  }

  return updatedItem
}

export default updateRewardListItemFromReward
