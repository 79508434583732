import type { ProductOption, ShoppingCartItem } from '../../../definitions'
import compareId from '../compareId'

const getProductOption = (item: ShoppingCartItem): ProductOption | undefined => {
  if (
    !item ||
    !item.productOptionId ||
    !item.product ||
    !Array.isArray(item.product.productOptions) ||
    item.product.productOptions.length < 1
  ) {
    return
  }
  return item.product.productOptions.find(po => compareId(po.id, item.productOptionId))
}

export default getProductOption
