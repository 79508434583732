import { StoredProductStatus } from '../../enums'

const labelsEn = Object.freeze({
  [StoredProductStatus.CREATED]: 'created',
  [StoredProductStatus.AVAILABLE]: 'available',
  [StoredProductStatus.RESERVED]: 'reserved',
  [StoredProductStatus.DELIVERED]: 'delivered',
})

export default {
  getLabel: (command: StoredProductStatus | null | undefined, language = 'en'): string => {
    if (!command) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[command]
    }
    return ''
  },
  isValid: (command: StoredProductStatus | string): boolean => Object.values(StoredProductStatus).includes(command as StoredProductStatus),
}
