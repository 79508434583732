import type { ShoppingCart } from '../../../definitions'
import getAmountsInfo from '../shoppingCartItem/getAmountsInfo'
import logger from '../../../logger'

const setPaymentAmounts = (shoppingCart: ShoppingCart): ShoppingCart => {
  // logger.trace('lib.core.helpers.models.shoppingCart.setPaymentAmounts called.', { shoppingCart })
  shoppingCart.paymentAmount = 0
  let productAmount = 0

  if (!shoppingCart) {
    logger.error('lib.core.helpers.models.shoppingCart.setPaymentAmounts: no shopping cart given')
    return shoppingCart
  }

  if (!Array.isArray(shoppingCart.items) || shoppingCart.items.length < 1) {
    logger.info('lib.core.helpers.models.shoppingCart.setPaymentAmounts: items', { shoppingCart })
    return shoppingCart
  }

  shoppingCart.items.forEach((item) => {
    const amountInfo = getAmountsInfo(
      item,
      shoppingCart.paymentFundType,
      shoppingCart.paymentCurrency,
      shoppingCart.paymentExchangeRate,
    )
    if (amountInfo && amountInfo.paymentAmount) {
      // logger.trace('lib.core.helpers.models.shoppingCart.setPaymentAmounts: setting item amount.',
      //   { item, amountInfo })
      item.paymentAmount = amountInfo.paymentAmount.amount
    } else {
      item.paymentAmount = 0
    }

    if (amountInfo && amountInfo.productAmount) {
      // logger.trace('lib.core.helpers.models.shoppingCart.setPaymentAmounts: setting item amount.',
      //   { item, amountInfo })
      productAmount += amountInfo.productAmount.amount
    }

    // if (shoppingCart.paymentCurrency === TokenName.MIMBLE_TOKEN) {
    //   if (item.productType === OrgProductType.MIMBLE_TOKEN) {
    //     item.paymentAmount = item.productCount
    //   } else if (item.productType === OrgProductType.GIFT_CARD) {
    //     if (item.productOption && item.productOption.amount) {
    //       item.paymentAmount = cryptoCurrency.convertFiatToMimbleToken(item.productOption.amount)
    //     }
    //   }
    // } else if (shoppingCart.paymentFundType === FundType.FIAT) {
    //   if (item.productType === OrgProductType.MIMBLE_TOKEN) {
    //     item.paymentAmount = cryptoCurrency.convertMimbleTokenToFiat(item.productCount || 1)
    //   } else if (item.productType === OrgProductType.GIFT_CARD) {
    //     if (item.productOption && item.productOption.amount) {
    //       item.paymentAmount = (item.productCount || 1) * item.productOption.amount
    //     }
    //   }
    // } else if (shoppingCart.paymentFundType === FundType.CRYPTO) {
    //   if (item.productType === OrgProductType.MIMBLE_TOKEN && shoppingCart.paymentExchangeRate) {
    //     const amountFiat = cryptoCurrency.convertMimbleTokenToFiat(item.productCount || 1)
    //     item.paymentAmount = shoppingCart.paymentExchangeRate * amountFiat
    //   } else if (item.productType === OrgProductType.GIFT_CARD && shoppingCart.paymentExchangeRate) {
    //     if (item.productOption && item.productOption.amount) {
    //       const amountFiat = (item.productCount || 1) * item.productOption.amount
    //       item.paymentAmount = shoppingCart.paymentExchangeRate * amountFiat
    //     }
    //   }
    // }

    shoppingCart.paymentAmount = (shoppingCart.paymentAmount || 0) + (item.paymentAmount || 0)
  })

  if (shoppingCart.metadata) {
    shoppingCart.metadata.productAmount = productAmount
  } else {
    shoppingCart.metadata = { productAmount, revision: 0 }
  }

  return shoppingCart
}

export default setPaymentAmounts
