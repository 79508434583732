import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, ProductOption } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  ProductOptionQueryData,
  ProductOptionQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadProductOption = (
  productOptionId: string,
  isInTargetStateFunc: ((productOption: ProductOption) => boolean) | 'watch-updated-at' | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ProductOption | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadProductOption called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadProductOption: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<ProductOptionQueryVariables> = {
      query: apollo.queries.productOption,
      variables: { productOptionId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<ProductOptionQueryData, ProductOptionQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.productOption ||
            !resp.data.productOption.id
          ) {
            logger.error('api.loadProductOption.load: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((productOption: ProductOption) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.productOption.updatedAt
            activeIsInTargetStateFunc = (productOption: ProductOption) => productOption.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.productOption)
          ) {
            // console.log('api.loadProductOption.load: resolving with info.', resp.data.productOption)
            resolve(resp.data.productOption)
            return
          }

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadProductOption.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadProductOption.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadProductOption
