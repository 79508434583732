import React, { useContext, useEffect, useState } from 'react'
import { IonModal, IonButton, IonInput, IonIcon, IonSpinner, isPlatform } from '@ionic/react'
import { close } from 'ionicons/icons'
import { Keyboard } from '@capacitor/keyboard'
import { GiphyFetch } from '@giphy/js-fetch-api'
import type { IGif } from '@giphy/js-types'

import './styles.css'
import { ChatImageType } from '../../lib/core/enums'
import type { EnvironmentVal } from '../../lib/core/definitions'
import { Gif, Grid, SearchContext, SearchContextManager } from '@giphy/react-components'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import env from '../../lib/env'
import FormItem from '../FormItem/FormItem'

let giphyFetch: GiphyFetch
let giphyApiKey: string | undefined

const initGiphy = (
  getEnvironment: () => EnvironmentVal,
  setEmptyResultGif: (gif: IGif | undefined) => void,
): void => {
  if (giphyFetch) {
    // already initialized
    return
  }

  try {
    giphyApiKey = env('GIPHY_KEY', getEnvironment())

    if (!giphyApiKey) {
      console.warn('Giphy not available')
      return
    }

    console.log('Initializing Giphy with API key=', giphyApiKey)
    giphyFetch = new GiphyFetch(giphyApiKey)

    if (giphyFetch) {
      giphyFetch.random({ tag: 'huh', rating: 'g', limit: 1 }).then((g) => {
        setEmptyResultGif(g.data)
        return g.data
      })
    }
  } catch (error) {
    console.error(error)
  }
}

type Props = {
  show: boolean
  onClose: () => void
  onSelectImage: (newImageUrl: string, newImageType: ChatImageType) => void
}

const ChatImageModal: React.FC<Props> = (props): JSX.Element => {
  const {
    show,
    onClose,
    onSelectImage,
  } = props

  // ===================================================================================================================
  // State:
  const { isFetching } = useContext(SearchContext)
  const { getEnvironment } = useGlobalCache()

  const [emptyResultGif, setEmptyResultGif] = useState<IGif | undefined>()
  const [newSearchKey, setNewSearchKey] = useState('')

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    initGiphy(getEnvironment, setEmptyResultGif)
  }, [])

  const width = window.innerWidth * 0.9

  // ===================================================================================================================
  // Event Handlers:
  const fetchTrending = (offset: number) => giphyFetch && giphyFetch.trending({ offset, limit: 13 })
  const fetchSearch = (offset: number) => giphyFetch && giphyFetch.search(newSearchKey, { offset, limit: 13 })

  const handleClose = () => isPlatform('capacitor') ? Keyboard.hide().then(() => onClose()) : onClose()

  const handleSelectedGif = (gif: IGif, e: any): void => {
    e.preventDefault()
    onSelectImage(gif.images.fixed_height.url, ChatImageType.GIPHY)
  }

  const onChangeSearchKey = (event: any): void => {
    const nsk = event.detail.value
    setNewSearchKey(nsk)
    console.log('searchTerm:', nsk)
  }

  // ===================================================================================================================
  // Rendering:
  let activityIndicator: JSX.Element | undefined
  if (isFetching) {
    activityIndicator = (
      <div key='spinner' className='spinner-section'>
        <IonSpinner
          color='medium'
          className='gif-spinner'
          name='dots'
        />
      </div>
    )
  }

  let noResults: JSX.Element | undefined
  if (emptyResultGif) {
    noResults = (
      <div className='spinner-section lightText smallText'>
        <span className='lightText'>Bummer, there are no gifs here...</span>
        <Gif
          key='confused-gif'
          gif={emptyResultGif}
          width={200}
          className='withStandardTopMargin'
          hideAttribution
          noLink
        />
      </div>
    )
  }

  let giphyGrid: JSX.Element | undefined
  if (giphyApiKey && giphyFetch) {
    const giphySection = (
      <div>
        {activityIndicator}
        <Grid
          key={newSearchKey}
          columns={2}
          width={width}
          gutter={2}
          fetchGifs={newSearchKey ? fetchSearch : fetchTrending}
          className='gif-grid'
          noResultsMessage={noResults}
          hideAttribution
          noLink
          onGifClick={handleSelectedGif}
        />
      </div>
    )

    giphyGrid = (
      <div className='autoOverflowContainer'>
        <SearchContextManager
          apiKey={giphyApiKey}
          options={{ rating: 'pg' }}
          theme={{ condensedMode: true }}
        >
          {giphySection}
        </SearchContextManager>
      </div>
    )
  }

  return (
    <IonModal
      isOpen={show}
      cssClass='chat-image-modal'
      backdropDismiss
      onWillDismiss={handleClose}
    >
      <div className='modal-content'>
        <div className='header'>
          <IonButton
            fill='clear'
            onClick={handleClose}
          >
            <IonIcon icon={close} />
          </IonButton>
        </div>
        <div className='grid'>
          <FormItem className='withSmallBottomMargin'>
            <div className='rowWithCenterAlignedItems'>
              <IonInput
                name='searchKey'
                value={newSearchKey}
                debounce={750}
                placeholder='Search GIPHY'
                onIonChange={onChangeSearchKey}
                clearInput
              />
            </div>
          </FormItem>
          {giphyGrid}
        </div>
      </div>
    </IonModal>
  )
}

export default ChatImageModal
