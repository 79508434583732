import React from 'react'
import { IonInput, IonIcon } from '@ionic/react'
import { addCircle, search } from 'ionicons/icons'

import './styles.css'

type Props = {
  searchText?: string
  isProcessing?: boolean
  onAddTag: () => void
  onApply: (searchText: string) => void
}

const TagListHeader: React.FC<Props> = (props): JSX.Element => {
  const {
    searchText,
    onAddTag,
    onApply,
  } = props
  // console.log('TagListHeader.render called.', props)
  // const isProcessing = isGettingTags || isGettingTag

  // ===================================================================================================================
  // State:

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Tag List:

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSearchText = (event: any): void => {
    onApply(event.detail.value)
  }

  const onClearFilter = (): void => {
    onApply('')
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='header'>
      <div className='searchBarWrapper'>
        <IonIcon icon={search} class='searchBarSearchIcon' />
        <IonInput
          value={searchText}
          placeholder='search'
          onIonChange={onChangeSearchText}
          className='searchBar'
          clearInput
        />
      </div>
      <div>
        <IonIcon
          icon={addCircle}
          class='add-tag-icon'
          onClick={onAddTag}
        />
      </div>
    </div>
  )
}

export default TagListHeader
