import { ShoppingCartStatus } from '../../enums'

const labelsEn = Object.freeze({
  [ShoppingCartStatus.CREATED]: 'created',
  [ShoppingCartStatus.ORDER_PLACED]: 'order placed',
  [ShoppingCartStatus.WAITING_FOR_PAYMENT]: 'waiting for payment',
  [ShoppingCartStatus.CREATING_ORDER]: 'creating order',
  [ShoppingCartStatus.REPLACED_BY_ORDER]: 'replaced by order',
  [ShoppingCartStatus.CANCELED]: 'canceled',
  [ShoppingCartStatus.INVALID]: 'invalid',
})

export default {
  getLabel: (status: ShoppingCartStatus | null | undefined, language = 'en'): string => {
    if (!status) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[status]
    }
    return ''
  },

  isValid: (status: ShoppingCartStatus): boolean => (
    Object.values(ShoppingCartStatus).includes(status as ShoppingCartStatus)
  ),

  isOrderPlaced: (status: ShoppingCartStatus | null | undefined): boolean => (
    status === ShoppingCartStatus.ORDER_PLACED ||
    status === ShoppingCartStatus.CREATING_ORDER ||
    status === ShoppingCartStatus.REPLACED_BY_ORDER ||
    status === ShoppingCartStatus.WAITING_FOR_PAYMENT ||
    status === ShoppingCartStatus.INVALID ||
    status === ShoppingCartStatus.CANCELED
  ),

  isPaymentProcessed: (status: ShoppingCartStatus | null | undefined): boolean => (
    status === ShoppingCartStatus.CREATING_ORDER ||
    status === ShoppingCartStatus.REPLACED_BY_ORDER
  ),
}
