import React from 'react'
import { IonIcon } from '@ionic/react'
import { radioButtonOff, radioButtonOn } from 'ionicons/icons'

import './styles.css'

export type FormRadioItem = {
  value: string;
  label: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  show?: boolean;
}

export enum FormRadioLayout {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

type Props = {
  label?: string;
  items: FormRadioItem[];
  validationError?: string;
  smallLabels?: boolean;
  layout?: FormRadioLayout;
  className?: string;
  onChange: (value: any) => void;
}
const FormRadioGroup: React.FC<Props> = (props): JSX.Element | null => {
  const {
    items,
    label,
    validationError,
    smallLabels,
    className,
    onChange,
  } = props
  const layout = props.layout || FormRadioLayout.horizontal
  if (!items) {
    return null
  }

  const renderedItems = items
    .filter(item => item.show !== false)
    .map((item, index) => {
      const onClick = (): void => {
        if (item.isDisabled) {
          return
        }
        onChange(item.value)
      }
      const icon = item.isChecked ? radioButtonOn : radioButtonOff
      const itemClass = index === 0
        ? `item-${layout} first-item-${layout}`
        : `item-${layout}`
      let labelClass = item.isDisabled
        ? `label-${layout} label-disabled`
        : `label-${layout}`
      const iconClass = item.isDisabled
        ? `icon-${layout} icon-disabled`
        : `icon-${layout}`
      if (smallLabels) {
        labelClass += ' label-small'
      }
      return (
        <div
          key={item.value}
          className={itemClass}
          onClick={onClick}
        >
          <IonIcon icon={icon} class={iconClass} />
          <span className={labelClass}>{item.label}</span>
        </div>
      )
    })

  let labelSection
  if (label) {
    labelSection = (
      <div className='formLabel'>
        {label}
      </div>
    )
  }

  return (
    <div className={`form-radio-group ${className || ''}`}>
      {labelSection}
      <div className={`items-${layout}`}>
        {renderedItems}
      </div>
    </div>
  )
}

export default FormRadioGroup
