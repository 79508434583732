import React from 'react'
import Avatar from '../Avatar/Avatar'
import './styles.css'

type Props = {
  messageText: string;
  senderName?: string | null;
  senderImageUrl?: string | null;
}

const GiftTransferMessage: React.FC<Props> = (props): JSX.Element | null => {
  const {
    messageText,
    senderName,
    senderImageUrl,
  } = props

  const avatarUser = {
    fullName: senderName,
    imageUrl: senderImageUrl,
  }
  let avatar: JSX.Element | undefined
  if (senderImageUrl) {
    avatar = (
      <Avatar
        user={avatarUser}
        className='avatar'
      />
    )
  }
  return (
    <div className='gift-transfer-message'>
      <div className='rowWithTopAlignedItems withStandardBottomMargin'>
        {avatar}
        <div className='message'>
          {messageText.split('\n').map((item, key) => {
            return <span key={key}>{item}<br /></span>
          })}
        </div>
      </div>
    </div>
  )
}

export default GiftTransferMessage
