export enum ContactListScope {
  CONTACTS = 'contacts',
  EVENTS = 'events',
  MESSAGES = 'chats',
}

export enum ContactListEntryType {
  CONTACT_LIST_ITEM = 'contact-list-item',
  CONTACT_EVENT_ITEM = 'contact-event-item'
}
