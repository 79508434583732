import numberHelpers from '../number'

const formatDataSize = (size: number | string, stringForZero = ''): string => {
  const val = parseInt(size as string, 10)
  if (
    !val ||
    !numberHelpers.isPositiveNumber(val) ||
    parseInt(val.toString(), 10) < 1
  ) {
    return stringForZero
  }
  const sizes = ['Bytes', 'kBytes', 'MB', 'GB', 'TB']
  const i = parseInt((Math.floor(Math.log(val) / Math.log(1024)).toString()), 10)
  // @ts-ignore
  return `${Math.round(val / Math.pow(1024, i), 2)} ${sizes[i]}`
}

export default formatDataSize
