import { gql } from '@apollo/client'

export default gql`
  mutation M($productOption: ProductOptionInput!) {
    upsertProductOption (productOption: $productOption) {
      id
      productId
    }
  }
`
