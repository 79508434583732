import moment from 'moment'
import type { ContactEvent } from '../../../definitions'
import { GiftingEventType } from '../../../enums'

const getDisplayValue = (event: ContactEvent): string => {
  if (!event || !event.eventDate) {
    return ''
  }
  const hasYear = !event.eventDate.startsWith('0000')
  const date = hasYear ? event.eventDate : event.eventDate.substr(5)
  const displayVal = hasYear
    ? moment(date, 'YYYY-MM-DD').format('MMMM Do, YYYY')
    : moment(date, 'MM-DD').format('MMMM Do')

  if (event.eventType === GiftingEventType.BIRTHDAY && hasYear) {
    return `${displayVal} - age ${moment().diff(date, 'years')}`
  }

  return displayVal
}

export default getDisplayValue
