import type { FormEvent } from 'react'
import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonInput,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonToggle,
  useIonViewDidLeave,
} from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import type {
  CryptoCurrency,
  FiatCurrency,
} from '../../../lib/core/enums'
import {
  FundType,
  RewardType,
  TokenName,
  TransactionProcessor,
  TransactionType,
  UiMessage,
  UserRole,
} from '../../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type { RewardInput, TransactionInput, UserIdentInfo } from '../../../lib/core/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import { useApolloClient } from '@apollo/client'
import api from '../../../services/api'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import coreHelpers from '../../../lib/core/helpers'
import FindUserForm from '../../../components/FindUserForm/FindUserForm'
import FormItem from '../../../components/FormItem/FormItem'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import UserInfoCard from '../../../components/UserInfoCard/UserInfoCard'
import validationHelpers from '../../../helpers/validationHelpers'

const appPageId = AppPage.AdminNewTransactionPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const NewTransactionPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [recipientUserIdentInfo, setRecipientUserIdentInfo] = useState<UserIdentInfo | undefined>()
  const [isReward, setIsReward] = useState(false)
  const [isUpsertingTransaction, setIsUpsertingTransaction] = useState(false)
  const [isUpsertingReward, setIsUpsertingReward] = useState(false)
  const [rewardType, setRewardType] = useState(undefined as RewardType | undefined)
  const [rewardTypeValidationError, setRewardTypeValidationError] = useState<string | undefined>()
  const [rewardForObjectId, setRewardForObjectId] = useState<string | undefined>()
  const [rewardForObjectIdValidationError, setRewardForObjectIdValidationError] = useState<string | undefined>()
  const [transactionType, setTransactionType] = useState(TransactionType.USER_PROMOTION)
  const [transactionTypeValidationError, setTransactionTypeValidationError] = useState<string | undefined>()
  const [refundTransactionId, setRefundTransactionId] = useState<string | undefined>()
  const [refundTransactionIdValidationError, setRefundTransactionIdValidationError] = useState<string | undefined>()
  const [amount, setAmount] = useState(0)
  const [amountValidationError, setAmountValidationError] = useState<string | undefined>()
  const [fundType, setFundType] = useState(FundType.TOKEN)
  const [fundTypeValidationError, setFundTypeValidationError] = useState<string | undefined>()
  const [currency, setCurrency] = useState(TokenName.MIMBLE_TOKEN as CryptoCurrency | FiatCurrency | TokenName)
  const [currencyValidationError, setCurrencyValidationError] = useState<string | undefined>()
  const [message, setMessage] = useState<string | undefined>()
  const [messageValidationError, setMessageValidationError] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:
  const transactionTypeChanged = !!transactionType
  const rewardTypeChanged = !!rewardType
  const rewardForObjectIdChanged = !!rewardForObjectId
  const amountChanged = !!amount
  const fundTypeChanged = fundType !== FundType.TOKEN
  const currencyChanged = !!currency
  const messageChanged = !!message
  const displayAmount = coreHelpers.ui.getDisplayMoneyAmount(amount, fundType, currency)
  const isProcessing = isUpsertingTransaction || isUpsertingReward
  const requireRewardForObjectId = (
    isReward &&
    !!rewardType &&
    coreHelpers.type.rewardType.requireForObjectId(rewardType)
  )

  const isDirty = isReward
    ? (
        rewardTypeChanged ||
        rewardForObjectIdChanged
      )
    : (
        amountChanged ||
        fundTypeChanged ||
        currencyChanged ||
        messageChanged ||
        transactionTypeChanged
      )
  const isValid = isReward
    ? (
        !!recipientUserIdentInfo &&
        !!rewardType &&
        !rewardForObjectIdValidationError &&
        (!requireRewardForObjectId || !!rewardForObjectId) &&
        !rewardTypeValidationError
      )
    : (
        transactionType === TransactionType.REFUND
          ? (
              !!refundTransactionId
            )
          : (
              !!recipientUserIdentInfo &&
              !!transactionType &&
              !Number.isNaN(amount) && !!amount && amount > 0 &&
              !amountValidationError &&
              !fundTypeValidationError &&
              !currencyValidationError &&
              !messageValidationError &&
              !transactionTypeValidationError
            )
      )

  const resetForm = (): void => {
    setRecipientUserIdentInfo(undefined)
    setIsReward(false)
    setTransactionType(TransactionType.USER_PROMOTION)
    setRewardForObjectId(undefined)
    setRewardTypeValidationError(undefined)
    setRewardType(undefined)
    setAmount(0)
    setFundType(FundType.TOKEN)
    setCurrency(TokenName.MIMBLE_TOKEN)
    setMessage(undefined)
  }

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewDidLeave(() => {
    pageMessages && pageMessages.clear()
    setToastMessage('')
    resetForm()
  })

  // ===================================================================================================================
  // Event Handlers:
  const onChangeAmount = (event: any): void => {
    const internalValue = coreHelpers.ui.getInternalMoneyAmount(
      parseFloat(event.detail.value), fundType, currency)
    setAmount(internalValue)
    if (internalValue) {
      setAmountValidationError(validationHelpers.validateTransactionAmount(internalValue))
    } else {
      setAmountValidationError(undefined)
    }
  }

  const onChangeFundType = (event: any): void => {
    setFundType(event.detail.value)
    if (event.detail.value) {
      setFundTypeValidationError(validationHelpers.validateFundType(event.detail.value))
    } else {
      setFundTypeValidationError(undefined)
    }
  }

  const onChangeCurrency = (event: any): void => {
    setCurrency(event.detail.value)
    if (event.detail.value) {
      setCurrencyValidationError(validationHelpers.validateCurrency(event.detail.value))
    } else {
      setCurrencyValidationError(undefined)
    }
  }

  const onChangeMessage = (event: any): void => {
    setMessage(event.detail.value)
    if (event.detail.value) {
      setMessageValidationError(validationHelpers.validateTransactionMessage(event.detail.value))
    } else {
      setMessageValidationError(undefined)
    }
  }

  const onSelectRecipient = (userIdentInfo: UserIdentInfo | undefined): void => {
    setRecipientUserIdentInfo(userIdentInfo)
  }

  const onClearRecipient = (): void => {
    setRecipientUserIdentInfo(undefined)
  }

  const onChangeRewardType = (event: any): void => {
    setRewardType(event.detail.value)
    if (event.detail.value) {
      setRewardTypeValidationError(validationHelpers.validateRewardType(event.detail.value))
    } else {
      setRewardTypeValidationError(undefined)
    }
  }

  const onChangeRefundTransactionId = (event: any): void => {
    setRefundTransactionId(event.detail.value)
    if (event.detail.value) {
      setRefundTransactionIdValidationError(validationHelpers.validateUuid(event.detail.value))
    } else {
      setRefundTransactionIdValidationError(undefined)
    }
  }

  const onChangeRewardForObjectId = (event: any): void => {
    setRewardForObjectId(event.detail.value)
    if (event.detail.value) {
      setRewardForObjectIdValidationError(validationHelpers.validateUuid(event.detail.value))
    } else {
      setRewardForObjectIdValidationError(undefined)
    }
  }

  const onChangeTransactionType = (event: any): void => {
    setTransactionType(event.detail.value)
    if (event.detail.value) {
      setTransactionTypeValidationError(validationHelpers.validateTransactionType(event.detail.value))
    } else {
      setTransactionTypeValidationError(undefined)
    }
  }

  const onSubmitForm = (event: FormEvent | undefined): void => {
    if (event) {
      event.preventDefault()
    }
    if (!isValid || (transactionType !== TransactionType.REFUND && !recipientUserIdentInfo)) {
      setToastMessage('Please check the form for errors.')
      setShowToast(true)
      return
    }
    pageMessages && pageMessages.clear()
    const recipientId = recipientUserIdentInfo && recipientUserIdentInfo.id

    if (isReward) {
      let rewardInput: RewardInput = {
        userId: recipientId,
        rewardType,
      }
      if (rewardForObjectId) {
        rewardInput = coreHelpers.models.reward.setForObjectId(rewardInput, rewardForObjectId)
      }
      api.upsertReward(
        rewardInput,
        undefined,
        undefined,
        activeUserId as string,
        apolloClient,
      ).then((reward) => {
        setIsUpsertingReward(false)
        pageMessages && pageMessages.add(PageMessageType.NOTICE, UiMessage.CHANGES_SAVED_SUCCESSFULLY)
      }, (error) => {
        setIsUpsertingReward(false)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SAVING_CHANGES)
      })
    } else {
      setIsUpsertingTransaction(true)
      const transactionInput: TransactionInput = {
        transactionType,
        fromUserId: UserRole.MIMBLE_ORG,
        message,
      }

      if (transactionType === TransactionType.REFUND) {
        if (!refundTransactionId) {
          setRefundTransactionIdValidationError('required')
          return
        }
        transactionInput.refundTransactionId = refundTransactionId
      } else {
        transactionInput.toUserId = recipientId as string
        transactionInput.rewardType = rewardType
        transactionInput.processor = TransactionProcessor.INTERNAL
        transactionInput.amount = amount
        transactionInput.fundType = fundType
        transactionInput.currency = currency
      }

      api.upsertTransaction(
        transactionInput,
        undefined,
        undefined,
        activeUserId as string,
        apolloClient,
      ).then((transaction) => {
        setIsUpsertingTransaction(false)
        pageMessages && pageMessages.add(PageMessageType.NOTICE, UiMessage.TRANSACTION_SAVED_SUCCESSFULLY)
      }, (error) => {
        setIsUpsertingTransaction(false)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_SAVING_CHANGES)
      })
    }
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onOpenContact = (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined,
  ): void => {
    pageHelpers.openContact({
      activeUserId,
      contactId,
      contactUserId,
      chatId,
      apolloClient,
      navigate,
    })
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let selectUserSection: JSX.Element | undefined
  if (transactionType !== TransactionType.REFUND) {
    if (recipientUserIdentInfo) {
      selectUserSection = (
        <div className='withStandardBottomMargin'>
          <UserInfoCard
            userIdentInfo={recipientUserIdentInfo}
            showChat
            onClose={onClearRecipient}
            onOpenContact={onOpenContact}
          />
        </div>
      )
    } else {
      selectUserSection = (
        <FindUserForm
          selectedUser={recipientUserIdentInfo}
          className='sel-user-form'
          onSelectUser={onSelectRecipient}
        />
      )
    }
  }

  let inputs: JSX.Element

  if (isReward) {
    const rewardTypeOptions = Object.values(RewardType).map(rewardType => (
      <IonSelectOption key={rewardType} value={rewardType}>{rewardType}</IonSelectOption>
    ))
    let forObjectIdInput: JSX.Element | undefined
    if (requireRewardForObjectId) {
      forObjectIdInput = (
        <FormItem
          label='For Object ID'
          validationError={rewardForObjectIdValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeRewardForObjectId}
            type='text'
            placeholder='object ID'
            value={rewardForObjectId}
          />
        </FormItem>
      )
    }
    inputs = (
      <div key='reward'>
        <FormItem
          key='reward-type'
          label='Reward Type'
          validationError={rewardTypeValidationError}
          withBottomMargin
        >
          <IonSelect
            value={rewardType}
            interface='action-sheet'
            // okText='OK'
            // cancelText='Cancel'
            onIonChange={onChangeRewardType}
          >
            {rewardTypeOptions}
          </IonSelect>
        </FormItem>
        {forObjectIdInput}
      </div>
    )
  } else {
    const transactionTypeOptions = [
      TransactionType.REFUND,
      TransactionType.USER_PROMOTION,
    ].map((tt) => (
      <IonSelectOption
        key={tt}
        value={tt}
      >
        {tt}
      </IonSelectOption>
    ))

    let detailAttributesSection: JSX.Element | undefined
    let messageLabel = 'Message to member'
    if (transactionType === TransactionType.REFUND) {
      messageLabel = 'Reason'
      detailAttributesSection = (
        <FormItem
          label='Transaction ID for this refund'
          validationError={refundTransactionIdValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeRefundTransactionId}
            value={refundTransactionId || ''}
          />
        </FormItem>
      )
    } else {
      detailAttributesSection = (
        <>
          <FormItem
            label='Amount'
            validationError={amountValidationError}
            withBottomMargin
          >
            <IonInput
              onIonChange={onChangeAmount}
              type='number'
              inputmode='decimal'
              placeholder='Amount'
              value={displayAmount ? displayAmount.toString() : ''}
            />
          </FormItem>
          <FormItem
            label='Fund Type'
            validationError={fundTypeValidationError}
            withBottomMargin
          >
            <IonInput
              onIonChange={onChangeFundType}
              type='text'
              placeholder='fundType'
              value={fundType}
            />
          </FormItem>
          <FormItem
            label='Currency'
            validationError={currencyValidationError}
            withBottomMargin
          >
            <IonInput
              onIonChange={onChangeCurrency}
              type='text'
              placeholder='currency'
              value={currency}
            />
          </FormItem>
        </>
      )
    }

    inputs = (
      <div key='trans'>
        <FormItem
          label='Transaction Type'
          validationError={transactionTypeValidationError}
          withBottomMargin
        >
          <IonSelect
            value={transactionType}
            interface='popover'
            onIonChange={onChangeTransactionType}
          >
            {transactionTypeOptions}
          </IonSelect>
        </FormItem>
        {detailAttributesSection}
        <FormItem
          label={messageLabel}
          validationError={messageValidationError}
          withBottomMargin
        >
          <IonInput
            onIonChange={onChangeMessage}
            type='text'
            placeholder='message to member'
            value={message}
          />
        </FormItem>
      </div>
    )
  }

  let rewardToggleSection: JSX.Element | undefined
  if (transactionType !== TransactionType.REFUND) {
    rewardToggleSection = (
      <div className='rowWithCenterAlignedItems withStandardBottomMargin'>
        <IonToggle checked={isReward} onIonChange={(e) => setIsReward(e.detail.checked)} />
        This is a reward
      </div>
    )
  }

  const content = (
    <>
      {selectUserSection}
      <form onSubmit={onSubmitForm}>
        {rewardToggleSection}
        {inputs}
        <div className='formButtonWrapper'>
          <SubmitButton
            expand='block'
            onClick={onSubmitForm}
            disabled={!isDirty || !isValid || isProcessing}
            isProcessing={isProcessing}
          >
            Submit
          </SubmitButton>
        </div>
      </form>
    </>
  )

  return (
    <IonPage className='app-page-public new-transaction-page'>
      <NavBar
        title='Send Funds To Member'
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default NewTransactionPage
