import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'
import type {
  GridColumns,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import {
  DataGridPro,
} from '@mui/x-data-grid-pro'

import type { CampaignUserInfoListFilter, CampaignUserInfo } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'

type Props = {
  userInfos: CampaignUserInfo[] | null | undefined;
  filter: CampaignUserInfoListFilter | undefined;
  isProcessing?: boolean;
  onOpenUser: (userId: string) => void;
};

const RecipientGrid: React.FC<Props> = (props): JSX.Element | null => {
  console.log('SystemMessagePage.RecipientGrid called.', props)
  const {
    userInfos,
    filter,
    isProcessing,
    onOpenUser,
  } = props

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (rowData: GridRowParams): void => {
    const userId = (rowData.row.id as string).replace(/-/g, '')
    onOpenUser(userId)
  }

  const onExport = (): void => {
    if (!Array.isArray(userInfos) || userInfos.length < 1) {
      return
    }
    const data = coreHelpers.models.campaign.exportUserInfoList(userInfos, filter)
    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
    link.target = '_blank'
    link.download = 'members.csv'
    link.click()
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(userInfos) || userInfos.length < 1) {
    return null
  }
  // see https://material-ui.com/components/data-grid/rendering/#components

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'fullName', headerName: 'Name', flex: 1.5 },
    {
      field: 'receivedAt',
      headerName: 'Received At',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          return coreHelpers.ui.formatDate(params.value as string)
        }
        return '-'
      },
    },
    { field: 'actionTaken', headerName: 'Action Taken', flex: 1 },
  ]
  const rows: GridRowsProp = userInfos
    .filter((userInfo) => {
      if (filter && filter.searchText) {
        const reg = new RegExp(filter.searchText, 'i')
        if (!userInfo.fullName || !userInfo.fullName.match(reg)) {
          return false
        }
      }
      return true
    })
    .map((userInfo): GridRowModel => {
      return {
        id: userInfo.userId,
        fullName: userInfo.fullName,
        receivedAt: coreHelpers.ui.formatDate(userInfo.inAppMessageReceivedAt),
        actionTaken: userInfo.actionTaken || '(none)',
      }
    })

  return (
    <div className='x-grid-data-view'>
      <div className='actions-row'>
        <IonButton
          size='small'
          fill='clear'
          color='medium'
          disabled={!Array.isArray(userInfos) || userInfos.length < 1 || isProcessing}
          onClick={onExport}
        >
          <IonIcon icon={cloudDownloadOutline} />
        </IonButton>
      </div>
      <div className='x-grid-wrapper'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  )
}

export default RecipientGrid
