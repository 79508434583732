import type { MouseEvent } from 'react'
import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import type { AppTabId } from './enums'
import type { AppRoute } from '../../enums'

interface Props {
  tabId: AppTabId
  icon: string
  label: string
  targetRoute?: AppRoute | string
  isSelected?: boolean
  badge?: JSX.Element
  className?: string
  onNavigateTo?: (route: string) => void
  onClick: (tabId: AppTabId, event: MouseEvent<Element, MouseEvent>) => void
}

export const AppTab: React.FC<Props> = (props): JSX.Element | null => {
  const {
    tabId,
    icon,
    label,
    isSelected,
    badge,
    targetRoute,
    className,
    onNavigateTo,
    onClick,
  } = props

  const handleClick = (event: any) => {
    event.preventDefault()
    if (targetRoute && onNavigateTo) {
      onNavigateTo(targetRoute)
    }
    if (onClick) {
      onClick(tabId, event)
    }
  }

  const iconWrapperClass = badge ? 'icon-with-badge' : 'icon-without-badge'
  return (
    <div className={isSelected ? `app-tab app-tab-selected ${className || ''}` : `app-tab ${className || ''}`}>
      <IonButton fill='clear' className='button' onClick={handleClick}>
        <div className='button-content'>
          <div className={iconWrapperClass}>
            <IonIcon icon={icon} className='icon' />
            {badge}
          </div>
          <div className='label'>{label}</div>
        </div>
      </IonButton>
    </div>
  )
}

export default AppTab
