import React from 'react'
import { IonButton } from '@ionic/react'

interface Props {
  isActiveShoppingCart: boolean | undefined
  onDeletePendingOrder: () => void
}

const DeletePendingOrderSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    isActiveShoppingCart,
    onDeletePendingOrder,
  } = props

  if (isActiveShoppingCart || !onDeletePendingOrder) {
    return null
  }

  return (
    <div className='withCenteredTextAlign'>
      <IonButton
        onClick={onDeletePendingOrder}
        fill='clear'
        size='small'
      >
        <div className='g-gray-text-button-label'>Delete pending order</div>
      </IonButton>
    </div>
  )
}

export default DeletePendingOrderSection
