import type { FormEvent } from 'react'
import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonImg,
  IonPage,
  IonToast,
  useIonViewDidLeave,
} from '@ionic/react'
import { Update } from 'history'
import { useHistory, useLocation } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import pageHelpers from '../../helpers/pageHelpers'

const appPageId = AppPage.WelcomePage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const WelcomePage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:
  useIonViewDidLeave(() => {
    pageMessages && pageMessages.clear()
    setToastMessage(undefined)
  })

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Event Handlers:
  const onJoin = (event?: FormEvent): void => {
    if (event) {
      event.preventDefault()
    }
    navigate(AppRoute.JOIN, true)
  }

  const onSignIn = (event?: FormEvent): void => {
    if (event) {
      event.preventDefault()
    }
    navigate(AppRoute.SIGN_IN, true)
  }

  // ===================================================================================================================
  // Rendering:
  // console.log('WelcomePage.render called.')

  return (
    <IonPage className='app-page-public welcome-page'>
      <div className='g-centered-box-outer'>
        <IonContent className='g-content-with-padding g-centered-content'>
          <PageMessages />
          <div className='logo-section'>
            <div className='logo-wrapper'>
              <IonImg
                className='image'
                src={process.env.PUBLIC_URL + '/assets/images/mimble-logo-square-gray.png'}
              />
            </div>
            <div className='welcome-text'>
              Welcome to
            </div>
            <div className='app-name'>
              Mimble
            </div>
            <div className='tagline'>
              Gifting - fun, fast, easy
            </div>
          </div>
          <div className='button-section'>
            <div className='join-button-section'>
              <SubmitButton
                expand='block'
                onClick={onJoin}
              >
                I&apos;m new
              </SubmitButton>
              <div className='lightText withCenteredColumnContent'>
                Try Mimble - it&apos;s free.
                <br />
                We hope you love it!
              </div>
            </div>
            <div className='withDoubleBottomMargin'>
              <SubmitButton
                expand='block'
                onClick={onSignIn}
              >
                I&apos;m back
              </SubmitButton>
              <div className='lightText withCenteredColumnContent'>
                Sign in here, and welcome!
              </div>
            </div>
          </div>
        </IonContent>
      </div>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default WelcomePage
