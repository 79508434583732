import type { Merchant, MerchantListItem } from '../../../definitions'
import { RecordStatus } from '../../../enums'

const updateMerchantListItemFromMerchant = (
  merchantListItem: MerchantListItem | undefined,
  merchant: Merchant,
): MerchantListItem => {
  if (!merchantListItem) {
    return {
      id: merchant.id || '',
      userId: merchant.userId || '',
      name: merchant.name || '',
      url: merchant.url || '',
      smallLogoImageSource: merchant.smallLogoImageSource || '',
      largeLogoImageSource: merchant.largeLogoImageSource || '',
      isDonationTarget: merchant.isDonationTarget as boolean,
      listed: merchant.listed as boolean,
      source: merchant.source || '',
      recordStatus: merchant.recordStatus || RecordStatus.ACTIVE,
      createdAt: merchant.createdAt || '',
      updatedAt: merchant.updatedAt || '',
      updatedBy: merchant.updatedBy || '',
      mainProductId: '',
      mainProductListed: true,
      mainProductStock: false,
    }
  }

  const updatedItem: MerchantListItem = { ...merchantListItem }

  if (merchant.userId && merchant.userId !== merchantListItem.userId) {
    updatedItem.userId = merchant.userId
  }
  if (merchant.name && merchant.name !== merchantListItem.name) {
    updatedItem.name = merchant.name
  }
  if (merchant.url && merchant.url !== merchantListItem.url) {
    updatedItem.url = merchant.url
  }
  if (merchant.smallLogoImageSource && merchant.smallLogoImageSource !== merchantListItem.smallLogoImageSource) {
    updatedItem.smallLogoImageSource = merchant.smallLogoImageSource
  }
  if (merchant.largeLogoImageSource && merchant.largeLogoImageSource !== merchantListItem.largeLogoImageSource) {
    updatedItem.largeLogoImageSource = merchant.largeLogoImageSource
  }
  if ((merchant.listed === true || merchant.listed === false) && merchant.listed !== merchantListItem.listed) {
    updatedItem.listed = merchant.listed
  }
  if (merchant.source && merchant.source !== merchantListItem.source) {
    updatedItem.source = merchant.source
  }
  if (merchant.recordStatus && merchant.recordStatus !== merchantListItem.recordStatus) {
    updatedItem.recordStatus = merchant.recordStatus
  }
  if (merchant.createdAt && merchant.createdAt !== merchantListItem.createdAt) {
    updatedItem.createdAt = merchant.createdAt
  }
  if (merchant.updatedAt && merchant.updatedAt !== merchantListItem.updatedAt) {
    updatedItem.updatedAt = merchant.updatedAt
  }

  return updatedItem
}

export default updateMerchantListItemFromMerchant
