export enum TopNavTabId {
  // Contact Page:
  CONTACT_MESSAGES = 'contact-messages',
  CONTACT_IDEAS = 'contact-ideas',
  CONTACT_PROFILE = 'contact-profile',

  // Contacts Page:
  CONNECTIONS = 'connections',
  EVENTS = 'events',
  MESSAGES = 'chats',

  // Edit User Account Page:
  SELECT_PRODUCT_OPTION = 'select-product-option',
  PRODUCT_INFO = 'product-info',
  MERCHANT_INFO = 'merchant-info',

  // Edit User Account Page:
  EDIT_PASSWORD = 'edit-password',
  EDIT_USER_ACCOUNT_PREFS = 'edit-user-account-prefs',

  // Purchase Page:
  EDIT = 'edit',
  INFO = 'info',
  USE_CARD = 'use-card',
  // MERCHANT_INFO = 'merchant-info',

  // Purchase List Page:
  ACTIVE_PURCHASES = 'active',
  PENDING_PURCHASES = 'pending',
  RECEIVED_PURCHASES = 'received',
  TRANSFERRED_PURCHASES = 'transferred',
  ARCHIVED_PURCHASES = 'archived',

  // Stored Product List Page:
  CREATED_STORED_PRODUCTS = 'created-stored-products',
  AVAILABLE_STORED_PRODUCTS = 'available-stored-products',
  RESERVED_STORED_PRODUCTS = 'reserved-stored-products',
  DELIVERED_STORED_PRODUCTS = 'delivered-stored-products',

  // Tokens Page:
  USER_REWARDS = 'user-rewards',
  EARN_REWARDS = 'earn-rewards',
  USER_TOKEN_TRANSACTIONS = 'user-tokens-purchases',

  // User Account Page:
  USER_DASHBOARD = 'dashboard',
  USER_FAVORITES = 'favorites',
  USER_ORDERS = 'orders',
  USER_PROFILE = 'profile',
  USER_SETTINGS = 'settings',
  USER_WISH_LIST = 'wish-list',

  // Merchant List Page:
  LISTED_MERCHANTS = 'listed-merchants',
  UNLISTED_MERCHANTS = 'unlisted-merchants',
}
