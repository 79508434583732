import React from 'react'
import { sendOutline, settingsOutline, trashBinOutline } from 'ionicons/icons'

import type { Purchase, PurchaseTransfer } from '../../../lib/core/definitions'
import { PurchaseTransferStatus } from '../../../lib/core/enums'
import appLinks from '../../../lib/core/appLinks'
import IconButton from '../../../components/IconButton/IconButton'
import PurchaseTransferInfoPane from '../../../components/PurchaseTransferInfoPane/PurchaseTransferInfoPane'

type Props = {
  purchase: Purchase;
  onCancelTransfer?: () => void;
  onEditTransfer?: () => void;
  onOpenContact: (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined
  ) => void
  onSendTransferNotification?: () => void;
  showUiMessage: (message: string) => void;
};

const getCaption = (purchaseTransfer: Partial<PurchaseTransfer>): string => {
  if (!purchaseTransfer) {
    return ''
  }
  if (purchaseTransfer.transferStatus === PurchaseTransferStatus.SCHEDULED) {
    return 'Gift Scheduled'
  }
  if (purchaseTransfer.transferStatus === PurchaseTransferStatus.DECLINED) {
    return 'Gift Was Declined'
  }
  if (purchaseTransfer.transferStatus === PurchaseTransferStatus.ACCEPTED) {
    return 'Gift Was Accepted'
  }
  if (purchaseTransfer.transferStatus === PurchaseTransferStatus.CANCELED) {
    return 'Gift Was Canceled'
  }
  if (purchaseTransfer.transferStatus === PurchaseTransferStatus.INVALID) {
    return 'Sending Gift Failed'
  }
  if (purchaseTransfer.messageSentAt) {
    return 'Gift Sent'
  }
  return 'Gift'
}

const GiftCardSentSection: React.FC<Props> = (args: Props): JSX.Element | null => {
  const {
    purchase,
    onOpenContact,
    showUiMessage,
    onCancelTransfer,
    onEditTransfer,
    onSendTransferNotification,
  } = args
  if (
    !purchase ||
    !Array.isArray(purchase.sentPurchaseTransfers) ||
    purchase.sentPurchaseTransfers.length < 1
  ) {
    return null
  }

  const transfer = purchase.sentPurchaseTransfers[purchase.sentPurchaseTransfers.length - 1]
  const giftLink = appLinks.gift(transfer.id as string, undefined)

  let recipientName = transfer ? transfer.toUserFullName : undefined
  if (!recipientName && transfer && transfer.toUser) {
    recipientName = transfer.toUser.fullName
  }

  const buttons: JSX.Element[] = []
  if (onSendTransferNotification) {
    buttons.push(
      <IconButton
        key='send'
        icon={sendOutline}
        text='Resend'
        layout='vertical'
        className='button'
        iconSize={30}
        onClick={onSendTransferNotification}
      />,
    )
  }
  if (onEditTransfer) {
    buttons.push(
      <IconButton
        key='update'
        icon={settingsOutline}
        text='Update'
        layout='vertical'
        className='button'
        iconSize={30}
        onClick={onEditTransfer}
      />,
    )
  }
  if (onCancelTransfer) {
    buttons.push(
      <IconButton
        key='cancel'
        icon={trashBinOutline}
        text='Cancel'
        layout='vertical'
        className='button'
        iconSize={30}
        onClick={onCancelTransfer}
      />,
    )
  }

  let buttonRow: JSX.Element | undefined
  if (buttons.length > 0) {
    buttonRow = (
      <div className='rowWithEndJustifyContent'>
        {buttons}
      </div>
    )
  }

  return (
    <div className='withStandardPadding'>
      <div className='rowWithSpaceBetween'>
        <div className='sectionCaption'>{getCaption(transfer)}</div>
        {buttonRow}
      </div>
      <PurchaseTransferInfoPane
        purchaseTransferStatus={transfer.transferStatus as PurchaseTransferStatus}
        giftLink={giftLink}
        userIdentInfo={transfer.toUser}
        toUserFullName={transfer.toUserFullName}
        toUserEmail={transfer.toUserEmail}
        toUserPhoneNumber={transfer.toUserPhoneNumber}
        messageSentAt={transfer.messageSentAt}
        messageScheduledAt={transfer.messageScheduledAt}
        messageScheduledTimezone={transfer.messageScheduledTimezone}
        messageText={transfer.messageText}
        chatImageUrl={transfer.chatImageUrl}
        chatImageType={transfer.chatImageType}
        animation={transfer.animation}
        notificationMethod={transfer.notificationMethod}
        onOpenContact={onOpenContact}
        showUiMessage={showUiMessage}
      />
    </div>
  )
}

export default GiftCardSentSection
