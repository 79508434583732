import type { PurchaseInput, ShoppingCartInput, ShoppingCartItem } from '../../../definitions'
import { FundType, OrgProductType, PurchaseStatus, TokenName } from '../../../enums'
import getAmountsInfo from '../shoppingCartItem/getAmountsInfo'
import purchaseTypeHelpers from '../../type/purchaseTypeHelpers'
import setPaymentAmounts from './setPaymentAmounts'

export interface PurchaseInputWithShoppingCartItem extends PurchaseInput {
  shoppingCartItem: ShoppingCartItem
}

const getPurchaseInputsFromShoppingCart = (
  shoppingCart: ShoppingCartInput,
  purchaseStatus?: PurchaseStatus,
): PurchaseInputWithShoppingCartItem[] | undefined => {
  // console.log('lib.core.helpers.models.shoppingCart.getPurchaseInputFromShoppingCart called.', { shoppingCart })
  if (
    !shoppingCart ||
    !shoppingCart.userId ||
    !shoppingCart.paymentProcessor ||
    !Array.isArray(shoppingCart.items) ||
    shoppingCart.items.length < 1
  ) {
    console.error('lib.core.helpers.models.shoppingCart.getPurchaseInputFromShoppingCart: invalid input')
    return
  }

  // Copies the product pricing from the product options, sets the total amount:
  setPaymentAmounts(shoppingCart)

  return shoppingCart.items.reduce<PurchaseInputWithShoppingCartItem[]>((list, item) => {
    if (!item) {
      return list
    }

    const amountsInfo = getAmountsInfo(
      item,
      shoppingCart.paymentFundType,
      shoppingCart.paymentCurrency,
      shoppingCart.paymentExchangeRate,
    )

    const purchaseInput: PurchaseInputWithShoppingCartItem = {
      shoppingCartItemId: item.id as string,
      userId: shoppingCart.userId,
      purchaseType: purchaseTypeHelpers.getPurchaseTypeFromProductType(item.productType as OrgProductType),
      productType: item.productType,
      amount: amountsInfo.productAmount.amount,
      fundType: amountsInfo.productAmount.fundType,
      currency: amountsInfo.productAmount.currency,
      shoppingCartItem: item,
      status: PurchaseStatus.PENDING_DELIVERY,
    }
    if (shoppingCart.orderId) {
      purchaseInput.orderId = shoppingCart.orderId
    }
    if (purchaseStatus) {
      purchaseInput.status = purchaseStatus
    }
    if (item.productType === OrgProductType.MIMBLE_TOKEN) {
      purchaseInput.amount = item.productCount
      purchaseInput.fundType = FundType.TOKEN
      purchaseInput.currency = TokenName.MIMBLE_TOKEN
    }
    if (item.productId) {
      purchaseInput.productId = item.productId
    }
    if (item.productOptionId) {
      purchaseInput.productOptionId = item.productOptionId
    }
    if (shoppingCart.metadataChanges) {
      purchaseInput.metadataChanges = shoppingCart.metadataChanges
    }
    list.push(purchaseInput)

    return list
  }, [])
}

export default getPurchaseInputsFromShoppingCart
