import capitalize from './capitalize'
import capitalizeSentence from './capitalizeSentence'
import containsOneOf from './containsOneOf'
import formatDataSize from './formatDataSize'
import formatDate from './formatDate'
import generatePassword from './generatePassword'
import generateSlug from './generateSlug'
import generateUuidV4 from './generateUuidV4'
import getFileVersionFromUrl from './getFileVersionFromUrl'
import getNumberSuffix from './getNumberSuffix'
import isUuid from './isUuid'
import matchesSearchText from './matchesSearchText'
import parseDate from './parseDate'
import toCamelCase from './toCamelCase'
import toSnakeCase from './toSnakeCase'
import urlSafe from './urlSafe'

const stringHelpers = {
  formatDate,
  parseDate,
  formatDataSize,
  getFileVersionFromUrl,
  containsOneOf,
  urlSafe,
  toSnakeCase,
  capitalize,
  capitalizeSentence,
  toCamelCase,
  getNumberSuffix,
  isUuid,
  generatePassword,
  generateSlug,
  generateUuidV4,
  matchesSearchText,
}

export default stringHelpers
