import toCamelCase from '../string/toCamelCase'

const pickColumns = (vals: any, columns?: string[]): any => {
  if (!columns || !Array.isArray(columns) || columns.length < 1) {
    return vals
  }
  return Object.keys(vals).reduce((changes, col): any => {
    if (columns.includes(col) || columns.includes(toCamelCase(col))) {
      // @ts-ignore
      changes[col] = vals[col]
    }
    return changes
  }, {})
}

export default pickColumns
