import moment from 'moment'
import type { Moment } from 'moment'
import type { Chat, ChatMessage, ChatMetadata, Contact, User } from '../../../definitions'
import { ChatMessageType } from '../../../enums'

const compileMetadata = (
  chat: Chat,
  messages: ChatMessage[] | null | undefined,
  latestMessage: ChatMessage | null | undefined,
  users: User[],
  contacts: Contact[] | null | undefined,
): ChatMetadata => {
  const metadata: ChatMetadata = {
    fromUser: { username: '', imageUrl: '', unreadMessageCount: 0, unreadSystemMessageCount: 0 },
    toUser: { username: '', imageUrl: '', unreadMessageCount: 0, unreadSystemMessageCount: 0 },
    updatedAt: chat.updatedAt as string,
  }

  // UpdatedAt:
  const chatUpdatedAt = moment(chat.updatedAt)
  if (Array.isArray(messages) && messages.length > 0) {
    metadata.updatedAt = messages.reduce<Moment>((time, msg) => {
      if (moment(msg.updatedAt).isAfter(time)) {
        return moment(msg.updatedAt)
      }
      return time
    }, chatUpdatedAt).toISOString()
  }

  // User Info:
  const fromUser = users ? users.find(u => u.id === chat.fromUserId) : undefined
  const toUser = users ? users.find(u => u.id === chat.toUserId) : undefined
  const fromContact = contacts ? contacts.find(c => c.fromUserId === chat.fromUserId) : undefined
  const toContact = contacts ? contacts.find(c => c.toUserId === chat.toUserId) : undefined

  if (fromUser) {
    if (metadata.fromUser) {
      metadata.fromUser.username = fromUser.username as string
      metadata.fromUser.nickname = (fromContact && fromContact.nickname) || undefined
      metadata.fromUser.fullName = fromUser.fullName || undefined
      metadata.fromUser.email = fromUser.email || undefined
      metadata.fromUser.phoneNumber = fromUser.phoneNumber || undefined
      metadata.fromUser.imageUrl = fromUser.imageUrl || undefined
    } else {
      metadata.fromUser = {
        username: fromUser.username as string,
        fullName: fromUser.fullName || undefined,
        email: fromUser.email || undefined,
        phoneNumber: fromUser.phoneNumber || undefined,
        imageUrl: fromUser.imageUrl || undefined,
        unreadMessageCount: 0,
        unreadSystemMessageCount: 0,
      }
    }
  }

  if (toUser) {
    if (metadata.toUser) {
      metadata.toUser.username = toUser.username as string
      metadata.toUser.nickname = (toContact && toContact.nickname) || undefined
      metadata.toUser.fullName = toUser.fullName || undefined
      metadata.toUser.email = toUser.email || undefined
      metadata.toUser.phoneNumber = toUser.phoneNumber || undefined
      metadata.toUser.imageUrl = toUser.imageUrl || undefined
    } else {
      metadata.toUser = {
        username: toUser.username as string,
        fullName: toUser.fullName || undefined,
        email: toUser.email || undefined,
        phoneNumber: toUser.phoneNumber || undefined,
        imageUrl: toUser.imageUrl || undefined,
        unreadMessageCount: 0,
        unreadSystemMessageCount: 0,
      }
    }
  }

  if (Array.isArray(messages) && messages.length > 0) {
    const unreadMessages = messages.filter(msg => !msg.receivedAt && msg.messageType !== ChatMessageType.SYSTEM)
    const unreadSystemMessages = messages.filter(msg => !msg.receivedAt && msg.messageType === ChatMessageType.SYSTEM)

    if (Array.isArray(unreadMessages) && unreadMessages.length > 0) {
      const fromUserUnreadMessageCount = unreadMessages.filter(
        (message) => message.toUserId === chat.fromUserId).length
      const toUserUnreadMessageCount = unreadMessages.filter(
        (message) => message.toUserId === chat.toUserId).length
      if (metadata.fromUser) {
        metadata.fromUser.unreadMessageCount = fromUserUnreadMessageCount
      }
      if (metadata.toUser) {
        metadata.toUser.unreadMessageCount = toUserUnreadMessageCount
      }
    }

    if (Array.isArray(unreadSystemMessages) && unreadSystemMessages.length > 0) {
      const fromUserUnreadSystemMessageCount = unreadSystemMessages.filter(
        (message) => message.toUserId === chat.fromUserId).length
      const toUserUnreadSystemMessageCount = unreadSystemMessages.filter(
        (message) => message.toUserId === chat.toUserId).length
      if (metadata.fromUser) {
        metadata.fromUser.unreadSystemMessageCount = fromUserUnreadSystemMessageCount
      }
      if (metadata.toUser) {
        metadata.toUser.unreadSystemMessageCount = toUserUnreadSystemMessageCount
      }
    }
  }

  // Latest message:
  if (latestMessage) {
    if (latestMessage.messageText) {
      metadata.latestMessageText = latestMessage.messageText
    }
    if (latestMessage.createdAt) {
      metadata.latestMessageCreatedAt = latestMessage.createdAt
    }
  }

  return metadata
}

export default compileMetadata
