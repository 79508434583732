import { IonInput, IonIcon, IonCheckbox } from '@ionic/react'
import { search } from 'ionicons/icons'
import React, { useState } from 'react'

import './styles.css'
import type { ProductListFilter } from '../../../lib/core/definitions'

type Props = {
  onChangeFilter: (filter: ProductListFilter) => void
}

const ProductListHeader: React.FC<Props> = (props): JSX.Element => {
  const { onChangeFilter } = props
  // console.log('ProductListHeader.render called.', props)
  // const isProcessing = isGettingProducts || isGettingProduct

  // ===================================================================================================================
  // State:
  const [searchText, setSearchText] = useState<string | undefined>()
  const [showOnlyMainProducts, setShowOnlyMainProducts] = useState(true)
  const [showOnlyInStock, setShowOnlyInStock] = useState(false)
  const [showOnlyListed, setShowOnlyListed] = useState(false)

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Product List:

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSearchTextLocal = (event: any): void => {
    setSearchText(event.detail.value)
    const filter: ProductListFilter = {
      searchText,
    }
    if (showOnlyMainProducts) {
      filter.showOnlyMainProducts = true
    }
    if (showOnlyInStock) {
      filter.showOnlyInStock = true
    }
    if (showOnlyListed) {
      filter.showOnlyListed = true
    }
    onChangeFilter(filter)
  }

  const onClearFilter = (): void => {
    setSearchText('')
    setShowOnlyMainProducts(true)
    setShowOnlyInStock(false)
    setShowOnlyListed(false)
    onChangeFilter({})
  }

  const onChangeShowOnlyMainProducts = (event: any): void => {
    setShowOnlyMainProducts(event.detail.checked)
    const filter: ProductListFilter = {
      searchText,
    }
    if (event.detail.checked) {
      filter.showOnlyMainProducts = true
    }
    if (showOnlyInStock) {
      filter.showOnlyInStock = true
    }
    if (showOnlyListed) {
      filter.showOnlyListed = true
    }
    onChangeFilter(filter)
  }

  const onChangeShowOnlyInStock = (event: any): void => {
    setShowOnlyInStock(event.detail.checked)
    const filter: ProductListFilter = {
      searchText,
    }
    if (showOnlyMainProducts) {
      filter.showOnlyMainProducts = true
    }
    if (event.detail.checked) {
      filter.showOnlyInStock = true
    }
    if (showOnlyListed) {
      filter.showOnlyListed = true
    }
    onChangeFilter(filter)
  }

  const onChangeShowOnlyListed = (event: any): void => {
    setShowOnlyListed(event.detail.checked)
    const filter: ProductListFilter = {
      searchText,
    }
    if (showOnlyMainProducts) {
      filter.showOnlyMainProducts = true
    }
    if (showOnlyInStock) {
      filter.showOnlyInStock = true
    }
    if (event.detail.checked) {
      filter.showOnlyListed = true
    }
    onChangeFilter(filter)
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='productListHeader'>
      <div className='searchBarWrapper'>
        <IonIcon icon={search} class='searchBarSearchIcon' />
        <IonInput
          value={searchText}
          placeholder='search'
          onIonChange={onChangeSearchTextLocal}
          className='searchBar'
          clearInput
        />
      </div>
      <div className='productListHeaderCol1'>
        <div className='inlineCheckboxWithLabel'>
          <IonCheckbox
            checked={showOnlyMainProducts}
            onIonChange={onChangeShowOnlyMainProducts}
          />
          <div className='smallText withStandardLeftMargin'>Main Prod.</div>
        </div>
        <div className='inlineCheckboxWithLabel'>
          <IonCheckbox
            checked={showOnlyInStock}
            onIonChange={onChangeShowOnlyInStock}
          />
          <div className='smallText withStandardLeftMargin'>Stock</div>
        </div>
        <div className='inlineCheckboxWithLabel'>
          <IonCheckbox
            checked={showOnlyListed}
            onIonChange={onChangeShowOnlyListed}
          />
          <div className='smallText withStandardLeftMargin'>Listed</div>
        </div>
      </div>
    </div>
  )
}

export default ProductListHeader
