import type { Transaction, TransactionListItem } from '../../../definitions'
import { RecordStatus } from '../../../enums'

const updateTransactionListItemFromTransaction = (
  transactionListItem: TransactionListItem | undefined,
  transaction: Transaction,
): TransactionListItem => {
  if (!transactionListItem) {
    return {
      id: transaction.id || '',
      purchaseId: transaction.purchaseId,
      fromUserId: transaction.fromUserId,
      toUserId: transaction.toUserId,
      fromWalletId: transaction.fromWalletId,
      toWalletId: transaction.toWalletId,
      transactionType: transaction.transactionType,
      status: transaction.status,
      amount: transaction.amount,
      fundType: transaction.fundType,
      currency: transaction.currency,
      processor: transaction.processor,
      recordStatus: transaction.recordStatus || RecordStatus.ACTIVE,
      createdAt: transaction.createdAt || '',
      updatedAt: transaction.updatedAt || '',
      updatedBy: transaction.updatedBy || '',
    }
  }

  const updatedItem: TransactionListItem = { ...transactionListItem }

  if (transaction.purchaseId && transaction.purchaseId !== transactionListItem.purchaseId) {
    updatedItem.purchaseId = transaction.purchaseId
  }
  if (transaction.fromUserId && transaction.fromUserId !== transactionListItem.fromUserId) {
    updatedItem.fromUserId = transaction.fromUserId
  }
  if (transaction.toUserId && transaction.toUserId !== transactionListItem.toUserId) {
    updatedItem.toUserId = transaction.toUserId
  }
  if (transaction.fromWalletId && transaction.fromWalletId !== transactionListItem.fromWalletId) {
    updatedItem.fromWalletId = transaction.fromWalletId
  }
  if (transaction.toWalletId && transaction.toWalletId !== transactionListItem.toWalletId) {
    updatedItem.toWalletId = transaction.toWalletId
  }
  if (transaction.transactionType && transaction.transactionType !== transactionListItem.transactionType) {
    updatedItem.transactionType = transaction.transactionType
  }
  if (transaction.status && transaction.status !== transactionListItem.status) {
    updatedItem.status = transaction.status
  }
  if (Number.isInteger(transaction.amount) && transaction.amount !== transactionListItem.amount) {
    updatedItem.amount = transaction.amount
  }
  if (transaction.fundType && transaction.fundType !== transactionListItem.fundType) {
    updatedItem.fundType = transaction.fundType
  }
  if (transaction.currency && transaction.currency !== transactionListItem.currency) {
    updatedItem.currency = transaction.currency
  }
  if (transaction.recordStatus && transaction.recordStatus !== transactionListItem.recordStatus) {
    updatedItem.recordStatus = transaction.recordStatus
  }
  if (transaction.createdAt && transaction.createdAt !== transactionListItem.createdAt) {
    updatedItem.createdAt = transaction.createdAt
  }
  if (transaction.updatedAt && transaction.updatedAt !== transactionListItem.updatedAt) {
    updatedItem.updatedAt = transaction.updatedAt
  }

  return updatedItem
}

export default updateTransactionListItemFromTransaction
