import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { IonImg } from '@ionic/react'

import './styles.css'
import { ChatImageType, ChatMessageType } from '../../../lib/core/enums'
import type { ChatAttachmentInfo, ChatMessage as IChatMessage } from '../../../lib/core/definitions'
import { useAppHelpers } from '../../../contexts/AppHelpersContext/AppHelpersContext'
import ChatAttachment from '../ChatAttachment/ChatAttachment'
import useInViewport from '../../../hooks/inViewport'

const wrapperCssClass = {
  left: 'message-wrapper message-wrapper-left',
  center: 'message-wrapper message-wrapper-system',
  right: 'message-wrapper message-wrapper-right',
}

const messageCssClass = {
  left: 'message message-left',
  center: 'message message-system',
  right: 'message message-right',
}

const attachmentCssClass = {
  left: 'attachments attachments-left',
  center: 'attachments attachments-system',
  right: 'attachments attachments-right',
}

type Props = {
  chatMessage: IChatMessage
  attachments: ChatAttachmentInfo[] | null | undefined
  position: 'left' | 'center' | 'right'
  isOnActivePage: boolean
  isFromLocalUser: boolean
  isLatestUnreadMessageWithAnimation: boolean
  onOpenAttachment: (
    chatMessageId: string,
    chatAttachmentInfo: ChatAttachmentInfo,
  ) => void
  onMovedIntoViewport: (message: IChatMessage) => void
}

const ChatMessage: React.FC<Props> = (props): JSX.Element | null => {
  const {
    chatMessage,
    isOnActivePage,
    isFromLocalUser,
    position,
    isLatestUnreadMessageWithAnimation,
    onOpenAttachment,
    onMovedIntoViewport,
  } = props
  const {
    id: messageId,
    messageType,
    messageText,
    imageUrl,
    imageType,
    animation,
    createdAt: sentAt,
  } = chatMessage && chatMessage
  const attachments = chatMessage && chatMessage.metadata && chatMessage.metadata.attachments

  // ===================================================================================================================
  // State:
  const { showFullPageAnimation } = useAppHelpers()
  const ref = useRef<HTMLDivElement>(null)
  const isInViewport = useInViewport(ref)
  // console.log('>>>>>>>ChatMessage.isInViewport=', isInViewport, isOnActivePage)

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    if (
      chatMessage &&
      isInViewport &&
      isOnActivePage
    ) {
      // console.log('>>>>>>>>ChatMessage.useEffect[isInViewport]', { messageId, messageText })

      onMovedIntoViewport(chatMessage)

      if (
        isLatestUnreadMessageWithAnimation &&
        chatMessage.animation &&
        !chatMessage.receivedAt &&
        !isFromLocalUser
      ) {
        showFullPageAnimation(chatMessage.animation)
      }
    }
  }, [isInViewport])

  // ===================================================================================================================
  // Event Handlers:
  const onReplayAnimation = (): void => {
    if (chatMessage.animation) {
      showFullPageAnimation(chatMessage.animation)
    }
  }

  // ===================================================================================================================
  // Rendering:
  let messageTextSection: JSX.Element | undefined
  if (messageText) {
    messageTextSection = (
      <div className='withStandardPadding view-width-70'>
        {messageText.split('\n').map((item, key) => {
          return <span key={key}>{item}<br /></span>
        })}
      </div>
    )
  }

  let replayAnimationSection: JSX.Element | undefined
  if (animation) {
    replayAnimationSection = <div onClick={onReplayAnimation} className='replay-button'>{isFromLocalUser ? 'Animated' : 'Replay'}</div>
  }

  let statusText: JSX.Element | undefined
  if (sentAt) {
    const t = /^\d+$/.test(sentAt)
      ? moment(Number(sentAt))
      : moment(sentAt)
    const timeText = `${t.format('MMM D')} at ${t.format('h:mm a')} - ${t.from(moment())}`
    let senderInfo = ''
    if (messageType === ChatMessageType.SYSTEM) {
      senderInfo = 'System notification '
    }
    statusText = (
      <div className='status-text'>
        {senderInfo}sent&nbsp;{timeText}&nbsp;<span className='linkText'>{replayAnimationSection}</span>
      </div>
    )
  }

  let attachmentsSection: JSX.Element | undefined
  let renderedAttachments
  if (Array.isArray(attachments) && attachments.length > 0) {
    renderedAttachments = attachments.map((att) => {
      return (
          <ChatAttachment
            key={att.chatAttachmentId as string}
            chatMessageId={messageId as string}
            attachment={att}
            isFromLocalUser={isFromLocalUser}
            onOpenAttachment={onOpenAttachment}
          />
      )
    })
    attachmentsSection = (
      <div className={attachmentCssClass[position]}>
        <div className='attachment-wrapper'>
          {renderedAttachments}
        </div>
      </div>
    )
  }

  let imageSection: JSX.Element | undefined
  if (imageUrl && imageType) {
    const attachedImageClass = messageText && isFromLocalUser ? 'attached-image-right' : 'attached-image-left'

    let imageOnlyStyles
    if (!messageText) {
      if (isFromLocalUser) {
        imageOnlyStyles = (
          { borderTopLeftRadius: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 0 }
        )
      } else {
        imageOnlyStyles = (
          { borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }
        )
      }
    }

    if (imageType === ChatImageType.GIPHY) {
      imageSection = (
        <div className={attachmentCssClass[position]}>
          <IonImg
            key={imageUrl}
            src={imageUrl}
            className={attachedImageClass}
            style={imageOnlyStyles} />
        </div>
      )
    }
  }

  return (
    <div ref={ref} className={wrapperCssClass[position]}>
      <div className={messageCssClass[position]}>
        {imageSection}
        {messageTextSection}
        {attachmentsSection}
      </div>
      {statusText}
    </div>
  )
}

export default ChatMessage
