import type { ContactUserListItem } from '../../../definitions'
import { UserRole } from '../../../enums'
import isItemATextSearchHit from './isItemATextSearchHit'

export interface FilterContactListOptions {
  showOrg?: boolean
  onlyFavorites?: boolean
}

const filterList = (
  list: ContactUserListItem[],
  searchText?: string,
  options?: FilterContactListOptions,
): ContactUserListItem[] => {
  // logger.debug('modelHelpers.contact.filterList called.', { searchText, showOrg })
  if (!searchText && !options) {
    return list
  }
  const cleanSearchText = searchText && searchText.trim().toLowerCase()
  return list.filter((contactUserListItem): boolean => {
    if (
      (!options || !options.showOrg) &&
      contactUserListItem.roles === UserRole.MIMBLE_ORG
    ) {
      return false
    }
    if (
      options &&
      options.onlyFavorites &&
      !contactUserListItem.favorite
    ) {
      return false
    }
    return isItemATextSearchHit(contactUserListItem, cleanSearchText)
  })
}

export default filterList
