import { IonButton, IonCheckbox } from '@ionic/react'
import type { FC } from 'react'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import MuiDialog from '@mui/material/Dialog'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogTitle from '@mui/material/DialogTitle'

import { useDialog } from '../../contexts/DialogContext/DialogContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import SubmitButton from '../SubmitButton/SubmitButton'

const BootstrapDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

type BootstrapDialogTitleProps = {
  onClose: () => void
}

const BootstrapDialogTitle: React.FC<BootstrapDialogTitleProps> = (props) => {
  const { children, onClose, ...other } = props

  return (
    <MuiDialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose
        ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
}

const Dialog: FC = () => {
  const {
    closeDialog,
    getDialogConfig,
    onButtonClick,
  } = useDialog()
  const { setPreference } = useGlobalCache()
  const [showAgain, setShowAgain] = useState(false)

  const dialogConfig = getDialogConfig()
  if (!dialogConfig) {
    return null
  }

  const saveShowAgain = (): void => {
    if (dialogConfig.showAgainLocalPreference) {
      setPreference(
        dialogConfig.showAgainLocalPreference,
        showAgain ? 'true' : 'false',
      )
    }
  }

  const handleClose = (): void => {
    saveShowAgain()
    closeDialog()
  }

  const onChangeShowAgain = (): void => {
    setShowAgain(!showAgain)
    if (dialogConfig.showAgainLocalPreference) {
      setPreference(
        dialogConfig.showAgainLocalPreference,
        showAgain ? 'false' : 'true',
      )
    }
  }

  let showAgainCheckboxSection: JSX.Element | undefined
  if (dialogConfig.showAgainLocalPreference) {
    showAgainCheckboxSection = (
      <div className='rowWithCenterAlignedItems withDoubleTopMargin'>
        <IonCheckbox
          slot='start'
          checked={!showAgain}
          className='withStandardRightMargin'
          onClick={onChangeShowAgain}
        />
        <div className='smallText lightText' onClick={onChangeShowAgain}>
          Don&apos;t show again
        </div>
      </div>
    )
  }

  let buttonsSection: JSX.Element | undefined
  if (dialogConfig.buttons) {
    const buttons = dialogConfig.buttons.map((buttonConfig, index) => {
      const onClick = () => {
        onButtonClick(buttonConfig.id)
        if (dialogConfig.showAgainLocalPreference) {
          setPreference(
            dialogConfig.showAgainLocalPreference,
            showAgain ? 'true' : 'false',
          )
        }
      }

      return (
        <IonButton
          key={buttonConfig.id}
          onClick={onClick}
          fill={buttonConfig.fill || 'solid'}
          className={index ? '' : 'withStandardLeftMargin'}
        >
          {buttonConfig.label}
        </IonButton>
      )
    })
    buttonsSection = (
      <MuiDialogActions>
        {buttons}
      </MuiDialogActions>
    )
  } else {
    buttonsSection = (
      <MuiDialogActions>
        <SubmitButton onClick={handleClose}>
          Got it!
        </SubmitButton>
      </MuiDialogActions>
    )
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={true}
    >
      <BootstrapDialogTitle onClose={handleClose}>
        {dialogConfig.title}
      </BootstrapDialogTitle>
      <MuiDialogContent dividers>
        <div
          key='text'
          dangerouslySetInnerHTML={{ __html: dialogConfig.text || '' }}
          className='withDoubleBottomMargin'
        />
        {showAgainCheckboxSection}
      </MuiDialogContent>
      {buttonsSection}
    </BootstrapDialog>
  )
}

export default Dialog
