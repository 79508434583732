function replaceOrAddItemInArray<T> (
  array: T[] | null | undefined,
  item: T | null | undefined,
  itemsAreIdenticalFunc: (item1: T, item2: T) => boolean,
): T[] | null | undefined {
  if (!item) {
    return array
  }

  if (!Array.isArray(array) || array.length < 1) {
    return [item]
  }

  let foundInList = false
  const newArray = array.map((itemFromList) => {
    if (itemsAreIdenticalFunc(itemFromList, item)) {
      foundInList = true
      return item
    }
    return itemFromList
  })
  if (foundInList) {
    return newArray
  }
  newArray.push(item)
  return newArray
}

function replaceOrAddItemsInArray<T> (
  array: T[] | null | undefined,
  items: T[] | null | undefined,
  itemsAreIdenticalFunc: (item1: T, item2: T) => boolean,
): T[] | null | undefined {
  if (!Array.isArray(items) || items.length < 1) {
    return array
  }

  if (!Array.isArray(array) || array.length < 1) {
    return items
  }

  const remainingItems = items.slice(0)
  const newArray = array.map((itemFromList) => {
    if (remainingItems.length < 1) {
      return itemFromList
    }
    for (let i = 0; i < remainingItems.length; i++) {
      if (itemsAreIdenticalFunc(itemFromList, remainingItems[i])) {
        const newItem = remainingItems[i]
        remainingItems.splice(i, 1)
        return newItem
      }
    }
    return itemFromList
  })
  if (remainingItems.length > 0) {
    return newArray.concat(remainingItems)
  }
  return newArray
}

export default {
  replaceOrAddItemInArray,
  replaceOrAddItemsInArray,
}
