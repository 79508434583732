import type { Currency } from '../../definitions'
import { CryptoCurrency, FundType } from '../../enums'

const cryptoPrecision = {
  [CryptoCurrency.BITCOIN]: 8,
  [CryptoCurrency.BITCOINCASH]: 5,
  [CryptoCurrency.CARDANO]: 5,
  [CryptoCurrency.CHAINLINK]: 5,
  [CryptoCurrency.DOGE]: 5,
  [CryptoCurrency.ETHEREUM]: 5,
  [CryptoCurrency.ETHEREUMCLASSIC]: 5,
  [CryptoCurrency.LITECOIN]: 5,
  [CryptoCurrency.LITECOIN_TEST]: 5,
  [CryptoCurrency.POLKADOT]: 5,
  [CryptoCurrency.TETHER_B]: 5,
  [CryptoCurrency.TETHER_E]: 5,
  [CryptoCurrency.USDCOIN_E]: 5,
  [CryptoCurrency.XRP]: 5,
}

const getAmountPrecision = (
  fundType: FundType | null | undefined,
  currency: Currency | null | undefined,
): number => {
  if (fundType === FundType.FIAT) {
    return 2
  }
  if (fundType === FundType.CRYPTO) {
    return cryptoPrecision[currency as CryptoCurrency]
  }
  return 0
}

export default getAmountPrecision
