import { ContactEventReminderTiming } from '../../enums'

const shortlabelsEn = Object.freeze({
  [ContactEventReminderTiming.DAYS_0]: 'On the day of the event',
  [ContactEventReminderTiming.DAY_1]: '1 day earlier',
  [ContactEventReminderTiming.DAYS_2]: '2 days earlier',
  [ContactEventReminderTiming.DAYS_3]: '3 days earlier',
  [ContactEventReminderTiming.DAYS_4]: '4 days earlier',
  [ContactEventReminderTiming.DAYS_5]: '5 days earlier',
  [ContactEventReminderTiming.DAYS_6]: '6 days earlier',
  [ContactEventReminderTiming.WEEK_1]: '1 week earlier',
  [ContactEventReminderTiming.WEEKS_2]: '2 weeks earlier',
  [ContactEventReminderTiming.WEEKS_3]: '3 weeks earlier',
  [ContactEventReminderTiming.MONTH_1]: '1 month earlier',
  [ContactEventReminderTiming.MONTHS_2]: '2 months earlier',
  [ContactEventReminderTiming.MONTHS_3]: '3 months earlier',
})

const longlabelsEn = Object.freeze({
  [ContactEventReminderTiming.DAYS_0]: 'On the day of the event',
  [ContactEventReminderTiming.DAY_1]: '1 day before the event',
  [ContactEventReminderTiming.DAYS_2]: '2 days before the event',
  [ContactEventReminderTiming.DAYS_3]: '3 days before the event',
  [ContactEventReminderTiming.DAYS_4]: '4 days before the event',
  [ContactEventReminderTiming.DAYS_5]: '5 days before the event',
  [ContactEventReminderTiming.DAYS_6]: '6 days before the event',
  [ContactEventReminderTiming.WEEK_1]: '1 week before the event',
  [ContactEventReminderTiming.WEEKS_2]: '2 weeks before the event',
  [ContactEventReminderTiming.WEEKS_3]: '3 weeks before the event',
  [ContactEventReminderTiming.MONTH_1]: '1 month before the event',
  [ContactEventReminderTiming.MONTHS_2]: '2 months before the event',
  [ContactEventReminderTiming.MONTHS_3]: '3 months before the event',
})

export default {
  getShortLabel: (timing: ContactEventReminderTiming | null | undefined, language = 'en'): string => {
    if (!timing && timing !== 0) {
      return ''
    }
    if (language === 'en') {
      return shortlabelsEn[timing]
    }
    return ''
  },
  getLongLabel: (timing: ContactEventReminderTiming | null | undefined, language = 'en'): string => {
    if (!timing && timing !== 0) {
      return ''
    }
    if (language === 'en') {
      return longlabelsEn[timing]
    }
    return ''
  },
  isValid: (timing: ContactEventReminderTiming | string | null | undefined): boolean => Object.values(ContactEventReminderTiming).includes(timing as ContactEventReminderTiming),
  all: Object.values(ContactEventReminderTiming).filter(v => typeof v === 'number') as ContactEventReminderTiming[],
}
