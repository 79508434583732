import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'

import './styles.css'
import { ContextMenuId, ContextMenuItemId } from '../../../components/ContextMenu/contextMenuDefs'
import type { AppRoute } from '../../../enums'
import type { MerchantListFilter } from '../../../lib/core/definitions'
import type { MerchantsQueryData, MerchantsQueryVariables } from '../../../services/apollo/definitions'
import type { TopNavTabDef } from '../../../components/TopNavBar/definitions'
import { AppPage, PageMessageType } from '../../../enums'
import { TopNavTabId } from '../../../components/TopNavBar/enums'
import { UiMessage } from '../../../lib/core/enums'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import MerchantGrid from './MerchantGrid'
import MerchantListHeader from './MerchantListHeader'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import TopNavBar from '../../../components/TopNavBar/TopNavBar'

// const topTabIdToScopeMapping = [
//   [TopNavTabId.LISTED_MERCHANTS, MerchantListScope.LISTED],
//   [TopNavTabId.UNLISTED_MERCHANTS, MerchantListScope.UNLISTED],
// ]

const topNavTabDefs: TopNavTabDef[] = [
  { id: TopNavTabId.LISTED_MERCHANTS, label: 'Listed' },
  { id: TopNavTabId.UNLISTED_MERCHANTS, label: 'Unlisted' },
]

// const getScopeFromTopNavTabId = (tabId: TopNavTabId): MerchantListScope => {
//   const row = topTabIdToScopeMapping.find(row => row[0] === tabId)
//   if (!row) {
//     throw new Error('Invalid topNavTabId given')
//   }
//   return row[1] as MerchantListScope
// }
//
// const getTopNavTabIdFromScope = (scope: MerchantListScope): TopNavTabId => {
//   const row = topTabIdToScopeMapping.find(row => row[1] === scope)
//   if (!row) {
//     throw new Error('Invalid scope given')
//   }
//   return row[0] as TopNavTabId
// }

const appPageId = AppPage.AdminMerchantsPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

const MerchantsPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [activeTopNavTabId, setActiveTopNavTabId] = useState(TopNavTabId.LISTED_MERCHANTS)
  const [filter, setFilter] = useState<MerchantListFilter | undefined>()

  const filterWithScope: MerchantListFilter = filter ? { ...filter } : {}
  if (activeTopNavTabId === TopNavTabId.LISTED_MERCHANTS) {
    filterWithScope.listed = true
  } else if (activeTopNavTabId === TopNavTabId.UNLISTED_MERCHANTS) {
    filterWithScope.listed = false
  }

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading merchants:
  const {
    data: merchantsData,
    loading: isLoadingMerchants,
    refetch: reloadMerchants,
  } = useQuery<MerchantsQueryData, MerchantsQueryVariables>(
    apollo.queries.merchants(true), {
      variables: { filter: {} },
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const merchants = merchantsData ? merchantsData.merchants : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingMerchants

  // if (!filter) {
  //   filter = {
  //     offset: 0,
  //     limit: 1000,
  //     version: new Date().getTime(),
  //   }
  // }
  // if (filter.searchText !== searchText) {
  //   filter.searchText = searchText
  //   // For now we don't pull a new, filtered list from the server, but filter it client-side:
  //   // filter.version = new Date().getTime();
  //   // refresh = true;
  // }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeFilter = (newFilter: MerchantListFilter): void => {
    setFilter(newFilter)
  }

  const onGetMerchants = (): void => {
    reloadMerchants()
  }

  const onOpenMerchant = (merchantId: string): void => {
    api.loadMerchant(
      merchantId,
      undefined,
      apollo.getMerchantListFilter(),
      apolloClient,
      undefined,
      { updateList: true },
    ).then(() => {
      navigate(`/admin/merchant/${merchantId}`)
    }, (error) => {
      console.error(error)
    })
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadMerchants().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  // const onEditMerchant = (merchantId: string): void => {
  //   api.loadMerchant(
  //     merchantId,
  //     undefined,
  //     apollo.getMerchantListFilter(),
  //     apolloClient,
  //     undefined,
  //     { updateList: true },
  //   ).then(() => {
  //     navigate(`/admin/edit-merchant/${merchantId}`)
  //   }, (error) => {
  //     console.error(error)
  //   })
  // }

  // const onEditProduct = (productId: string): void => {
  //   api.loadProduct(
  //     productId,
  //     undefined,
  //     undefined,
  //     apolloClient,
  //     {
  //       includeMerchant: true,
  //       includeProductOptions: true,
  //       includeStoredProducts: true,
  //       includeTags: true,
  //     },
  //   ).then((product) => {
  //     if (product) {
  //       navigate(`/admin/edit-product/${productId}`)
  //     }
  //   }, (error) => {
  //     console.error(error)
  //     pageHelpers.checkForUnauthorized(error, navigate)
  //     // pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
  //     setToastMessage('Error connecting.')
  //     setShowToast(true)
  //   })
  // }

  const onOpenProduct = (productId: string): void => {
    api.loadProduct(
      productId,
      undefined,
      undefined,
      apolloClient,
      {
        includeMerchant: true,
        includeProductOptions: true,
        includeStoredProducts: true,
        includeTags: true,
      },
    ).then((product) => {
      if (product) {
        navigate(`/admin/product/${productId}`)
      }
    }, (error) => {
      console.error(error)
      pageHelpers.checkForUnauthorized(error, navigate)
      // pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      setToastMessage('Error connecting.')
      setShowToast(true)
    })
  }

  const onAddMerchant = (): void => {
    navigate('/admin/add-merchant')
  }

  const onContextMenu = (id: ContextMenuItemId): void => {
    // console.log('Context menu clicked:', id);
    switch (id) {
      case ContextMenuItemId.REFRESH:
        onGetMerchants()
        break
      case ContextMenuItemId.ADD_LIST_ITEM:
        onAddMerchant()
        break
      default:
        console.error('MerchantsPage.onContextMenu: Unexpected context menu item received.', id)
    }
  }

  const onClickTopNavTab = (navKey: TopNavTabId): void => {
    setActiveTopNavTabId(navKey)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  return (
    <IonPage className='app-page-admin merchants-page'>
      <NavBar
        title='Manage Merchants'
        contextMenuId={ContextMenuId.MERCHANTS_PAGE}
        onContextMenu={onContextMenu}
        isProcessing={isProcessing}
      />
      <TopNavBar
        tabs={topNavTabDefs}
        onClickTab={onClickTopNavTab}
        activeTabId={activeTopNavTabId || TopNavTabId.LISTED_MERCHANTS}
      />
      <IonContent className='g-content-with-padding' scrollY={false}>
        <div className='g-non-scroll-content-wrapper'>
          <PageMessages />
          <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <MerchantListHeader
            onChangeFilter={onChangeFilter}
            onAddMerchant={onAddMerchant}
          />
          <MerchantGrid
            merchants={merchants}
            filter={filterWithScope}
            onOpenMerchant={onOpenMerchant}
            onOpenProduct={onOpenProduct}
          />
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default MerchantsPage
