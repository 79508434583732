import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, ContactEvent, ContactEventListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  ContactEventQueryData,
  ContactEventQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
// import cache from './cache'
import logger from '../logger'

const loadContactEvent = (
  contactEventId: string,
  isInTargetStateFunc: ((contactEvent: ContactEvent) => boolean) | 'watch-updated-at' | 'expect-null' | undefined,
  listFilter: ContactEventListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ContactEvent | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadContactEvent called.', { contactEventId, isInTargetStateFunc, listFilter, options })
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadContactEvent: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<ContactEventQueryVariables> = {
      query: apollo.queries.contactEvent,
      variables: { contactEventId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      // console.log('api.loadContactEvent.load called.', { queryOptions, isInTargetStateFunc })
      apolloClient.query<ContactEventQueryData, ContactEventQueryVariables>(queryOptions)
        .then((resp) => {
          // console.log('api.loadContactEvent.load: resp=', resp)
          if (!resp || !resp.data) {
            logger.error('api.loadContactEvent.load: query did not return anything.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((contactEvent: ContactEvent) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.contactEvent.updatedAt
            activeIsInTargetStateFunc = (contactEvent: ContactEvent) => contactEvent.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc === 'expect-null') {
            if (!resp.data.contactEvent) {
              resolve(undefined)
              return
            }
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !resp.data.contactEvent ||
            !resp.data.contactEvent.id
          ) {
            console.log('api.loadContactEvent: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.contactEvent)
          ) {
            // const queryListVariables = clientOptions && clientOptions.updateList && listFilter
            //   ? { filter: listFilter }
            //   : undefined
            // cache.updateContactEvent(
            //   resp.data.contactEvent,
            //   options.queryListVariables as ContactEventesQueryVariables | undefined,
            //   apolloClient,
            // )
            // console.log('api.loadContactEvent: resolving with info.', resp.data.contactEvent)
            resolve(resp.data.contactEvent)
            return
          }
          // console.log('api.loadContactEvent: isInState not passed, reloading.',
          //   { contactEvent: resp.data.contactEvent, activeIsInTargetStateFunc })

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadContactEvent: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadContactEvent.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadContactEvent
