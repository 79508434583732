export default {
  getAmountFormDisplayValue: (inputValue: number | null | undefined, object: any, attributeName: string, divider?: number): string => {
    if (inputValue === undefined) {
      let objVal = object[attributeName]
      if (divider) {
        objVal = objVal / divider
      }
      return objVal || objVal === 0 ? objVal.toString() : ''
    }
    return inputValue || inputValue === 0 ? inputValue.toString() : ''
  },

  validateConfirmationToken: (value: string): string => {
    if (!value || !value.match(/^[0-9]{6}$/)) {
      return 'not a valid token'
    }
    return ''
  },
}
