import React from 'react'
import { IonIcon } from '@ionic/react'
import { shareSocialSharp } from 'ionicons/icons'

import './styles.css'

type Props = {
  isShared?: boolean
  className?: string
}

const SharedWithContactsNotice: React.FC<Props> = (props): JSX.Element | null => {
  const { isShared, className } = props

  if (!isShared) {
    return null
  }

  return (
    <div
      className={`shared-with-contacts-notice ${className || ''}`}
    >
      <IonIcon
        icon={shareSocialSharp}
        className={'icon'}
      />
      Shared with my contacts
    </div>
  )
}

export default SharedWithContactsNotice
