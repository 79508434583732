import type { ApolloClient } from '@apollo/client'

import type { OAuthUser, User } from '../../lib/core/definitions'
import env from '../../lib/env'
// import firebase from '../firebase'
import globalCacheContextData from '../../contexts/GlobalCacheContext/contextData'
import type { AppRoute } from '../../enums'

const signIn = (
  user: User,
  apolloClient: ApolloClient<any>,
  navigate: (route: AppRoute | string,
) => void): void => {
  // console.log('services.auth.signIn called.', { user })
  const environment = globalCacheContextData.getEnvironment()
  globalCacheContextData.clearSessionData()

  globalCacheContextData.setAuthUser({
    id: user.id,
    username: user.username,
    email: user.email,
    phoneNumber: user.phoneNumber,
    authToken: user.authToken,
    oAuthProvider: user.oAuthProvider,
    roles: user.roles,
    prefs: user.prefs,
  })

  // if (env('FIREBASE_API_KEY', environment)) {
  //   // console.log('Firebase API key found - initializing.')
  //   firebase.init(environment, apolloClient, navigate)
  //   if (firebase.isAuthActive()) {
  //     const onFirebaseAuthStateChange = (user: OAuthUser) => {
  //       console.log('App.onFirebaseAuthStateChange called.', { user })
  //     }
  //     firebase.addAuthStateChangeListener('App', onFirebaseAuthStateChange)
  //   }
  // } else {
  //   console.log('services.auth.signIn: No firebase API key found - not activating.', { environment, env: process.env })
  // }
}

export default signIn
