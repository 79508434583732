import { gql } from '@apollo/client'

export default gql`
  query Q($filter: UserListFilter!) {
    users (filter: $filter) {
      id
      fullName
      username
      email
      phoneNumber
      imageUrl
      metadata {
        mimbleBalanceCrypto
        mimbleBalanceFiat
        mimbleBalanceToken

        giftCardCount
        purchasedGiftCardCount
        uploadedGiftCardCount
        pendingGiftCardCount

        receivedGiftCardCount
        receivedActiveGiftCardCount
        receivedFailedGiftCardCount
        receivedPendingGiftCardCount
        receivedDeclinedGiftCardCount

        sentGiftCardCount
        sentActiveGiftCardCount
        sentFailedGiftCardCount
        sentPendingGiftCardCount
        sentDeclinedGiftCardCount
      }
      isOrganization
      roles
      appFeatures
      trustLevel
      maxPurchaseAmountCc
      source
      recordStatus
      createdAt
      updatedAt
      signedInAt
      sessionEndedAt
    }
  }`
