import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { UserDevice } from '../../../lib/core/definitions'
import type {
  UserDeviceQueryData,
  UserDeviceQueryVariables,
  UserDevicesQueryData,
  UserDevicesQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  userDevice: UserDevice,
  queryListVariables: UserDevicesQueryVariables,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateUserDevice.updateInList called.', { userDevice })
  if (!userDevice) {
    logger.error('api.cache.updateUserDevice.updateInList: no userDevice given.')
    return
  }

  try {
    const cachedUserDeviceData = apolloClient.readQuery<UserDevicesQueryData, UserDevicesQueryVariables>({
      query: apollo.queries.userDevices,
      variables: queryListVariables,
    })

    if (
      !cachedUserDeviceData ||
      !Array.isArray(cachedUserDeviceData.userDevices) ||
      cachedUserDeviceData.userDevices.length < 1
    ) {
      // const listItem = coreHelpers.models.userDevice.updateUserDeviceListItemFromUserDevice(undefined, userDevice)
      const listItem = userDevice
      apolloClient.writeQuery<UserDevicesQueryData, UserDevicesQueryVariables>({
        query: apollo.queries.userDevices,
        variables: queryListVariables,
        data: { userDevices: [listItem] },
      })
      return
    }

    const updatedUserDevices = coreHelpers.models.updateModelInList<UserDevice>(
      cachedUserDeviceData.userDevices,
      userDevice,
      undefined,
      true,
      undefined,
    )

    apolloClient.writeQuery<UserDevicesQueryData, UserDevicesQueryVariables>({
      query: apollo.queries.userDevices,
      variables: queryListVariables,
      data: { userDevices: updatedUserDevices },
    })
  } catch (error) {
    logger.error('api.cache.updateUserDevice.updateInList: error.', { userDevice, error })
  }
}

const updateUserDevice = (
  userDevice: UserDevice,
  queryListVariables: UserDevicesQueryVariables | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateUserDevice called.', { userDevice })
  if (!apolloClient) {
    logger.error('api.cache.updateUserDevice: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!userDevice || !userDevice.id) {
    logger.error('api.cache.updateUserDevice: no userDevice given.', { userDevice })
    return
  }

  try {
    apolloClient.writeQuery<UserDeviceQueryData, UserDeviceQueryVariables>({
      query: apollo.queries.userDevice,
      variables: { userDeviceId: userDevice.id },
      data: { userDevice },
    })

    if (queryListVariables) {
      updateInList(
        userDevice,
        queryListVariables,
        activeUserId,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateUserDevice: error.', { userDevice, error })
  }
}

export default updateUserDevice
