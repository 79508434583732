import type { User, UserListItem } from '../../../definitions'
import type { RecordStatus } from '../../../enums'

const updateUserListItemFromUser = (userListItem: UserListItem | undefined, user: User): UserListItem => {
  if (!userListItem) {
    return {
      id: user.id as string,
      fullName: user.fullName,
      username: user.username,
      email: user.email,
      phoneNumber: user.phoneNumber,
      imageUrl: user.imageUrl,
      roles: user.roles as string,
      appFeatures: user.appFeatures as string,
      trustLevel: user.trustLevel,
      source: user.source,
      recordStatus: user.recordStatus as RecordStatus,
      signedInAt: user.signedInAt,
      createdAt: user.createdAt as string,
      updatedAt: user.updatedAt as string,
      updatedBy: user.updatedBy as string,
    }
  }

  const updatedItem: UserListItem = { ...userListItem }

  if (user.id && user.id !== userListItem.id) {
    updatedItem.id = user.id
  }
  if (user.fullName && user.fullName !== userListItem.fullName) {
    updatedItem.fullName = user.fullName
  }
  if (user.username && user.username !== userListItem.username) {
    updatedItem.username = user.username
  }
  if (user.email && user.email !== userListItem.email) {
    updatedItem.email = user.email
  }
  if (user.phoneNumber && user.phoneNumber !== userListItem.phoneNumber) {
    updatedItem.phoneNumber = user.phoneNumber
  }
  if (user.roles && user.roles !== userListItem.roles) {
    updatedItem.roles = user.roles as string
  }
  if (user.appFeatures && user.appFeatures !== userListItem.appFeatures) {
    updatedItem.appFeatures = user.appFeatures as string
  }
  if (Number.isInteger(user.trustLevel) && user.trustLevel !== userListItem.trustLevel) {
    updatedItem.trustLevel = user.trustLevel
  }
  if (user.source && user.source !== userListItem.source) {
    updatedItem.source = user.source
  }
  if (user.recordStatus && user.recordStatus !== userListItem.recordStatus) {
    updatedItem.recordStatus = user.recordStatus
  }
  if (user.signedInAt && user.signedInAt !== userListItem.signedInAt) {
    updatedItem.signedInAt = user.signedInAt
  }
  if (user.createdAt && user.createdAt !== userListItem.createdAt) {
    updatedItem.createdAt = user.createdAt
  }
  if (user.updatedAt && user.updatedAt !== userListItem.updatedAt) {
    updatedItem.updatedAt = user.updatedAt
  }

  return updatedItem
}

export default updateUserListItemFromUser
