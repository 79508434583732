import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertShoppingCartMutationData,
  UpsertShoppingCartMutationVariables,
} from '../apollo/definitions'
import type {
  ApiQueryOptions,
  ShoppingCart,
  ShoppingCartInput,
} from '../../lib/core/definitions'
import type { ShoppingCartStateFunc } from '../../definitions'
import apollo from '../apollo'
import cache from './cache'
import loadShoppingCart from './loadShoppingCart'
import logger from '../logger'

const upsertShoppingCart = (
  shoppingCartInput: ShoppingCartInput,
  isActiveShoppingCart: boolean,
  addOrderToReturnedShoppingCart: boolean,
  isInTargetStateFunc: ShoppingCartStateFunc | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<ShoppingCart | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.upsertShoppingCart called.',
    //   { shoppingCartInput, isActiveShoppingCart, isInTargetStateFunc })

    if (!apolloClient) {
      logger.error('api.upsertShoppingCart: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const rawShoppingCartInput = shoppingCartInput.__typename ? { ...shoppingCartInput } : shoppingCartInput
    if (rawShoppingCartInput.__typename) {
      delete rawShoppingCartInput.__typename
    }
    if (Array.isArray(rawShoppingCartInput.items) && rawShoppingCartInput.items.length > 0) {
      rawShoppingCartInput.items = rawShoppingCartInput.items.map((item) => {
        if (!item.__typename && !item.product) {
          return item
        }
        const rawItem = { ...item }
        delete rawItem.__typename
        delete rawItem.product
        return rawItem
      })
    }

    const queryParams: MutationOptions<UpsertShoppingCartMutationData, UpsertShoppingCartMutationVariables> = {
      mutation: apollo.mutations.upsertShoppingCart,
      variables: { shoppingCart: rawShoppingCartInput },
    }

    apolloClient.mutate<UpsertShoppingCartMutationData, UpsertShoppingCartMutationVariables>(queryParams)
      .then((resp) => {
        if (isInTargetStateFunc === 'expect-null') {
          resolve(undefined)
          return
        }

        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertShoppingCart ||
          !resp.data.upsertShoppingCart.id
        ) {
          logger.error('api.upsertShoppingCart: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }
        // console.log('api.upsertShoppingCart: mutation resp received.', resp)

        const { id: shoppingCartId, userId } = resp.data.upsertShoppingCart

        if (!userId) {
          logger.error('api.upsertShoppingCart: userId not set.')
          reject(ErrorCode.SYSTEM_ERROR)
          return
        }

        // cache.updateShoppingCart(
        //   resp.data.upsertShoppingCart,
        //   isActiveShoppingCart,
        //   apollo.getShoppingCartsQueryVariables(undefined, activeUserId),
        //   activeUserId,
        //   apolloClient,
        // )

        let activeIsInTargetStateFunc: ((shoppingCart: ShoppingCart) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          const oldUpdatedAt = resp.data.upsertShoppingCart.updatedAt
          activeIsInTargetStateFunc = (shoppingCart: ShoppingCart) => shoppingCart.updatedAt !== oldUpdatedAt
        } else if (isInTargetStateFunc === 'watch-metadata-revision') {
          const oldMetadataRevision = (
            resp.data.upsertShoppingCart.metadata &&
            resp.data.upsertShoppingCart.metadata.revision
          )
          // console.log(`api.upsertShoppingCart: using watch-metadata-revision with old revision=${oldMetadataRevision}.`)
          activeIsInTargetStateFunc = (shoppingCart: ShoppingCart) => {
            // console.log(`api.upsertShoppingCart.activeIsInTargetStateFunc: old revision=${oldMetadataRevision}, new revision=${shoppingCart.metadata && shoppingCart.metadata.revision}.`)
            if (!shoppingCart.metadata || !shoppingCart.metadata.revision) {
              return false
            }
            return (
              oldMetadataRevision === undefined ||
              oldMetadataRevision === null ||
              !shoppingCart.metadata ||
              shoppingCart.metadata.revision !== oldMetadataRevision
            )
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertShoppingCart)
        ) {
          const queryListVariables = !isActiveShoppingCart && clientOptions && clientOptions.updateList
            ? apollo.getShoppingCartsQueryVariables(undefined, activeUserId)
            : undefined
          cache.updateShoppingCart(
            resp.data.upsertShoppingCart,
            isActiveShoppingCart,
            queryListVariables,
            apolloClient,
          )
          // logger.error('api.upsertShoppingCart: no isInTargetStateFunc - will not poll.', { resp })
          resolve(resp.data.upsertShoppingCart)
          return
        }

        const id = isActiveShoppingCart ? undefined : shoppingCartId

        setTimeout(() => {
          // console.log('api.upsertShoppingCart: calling loadShoppingCart',
          //   { id, userId, isActiveShoppingCart, activeIsInTargetStateFunc, options })
          loadShoppingCart(
            id,
            isActiveShoppingCart,
            addOrderToReturnedShoppingCart,
            activeIsInTargetStateFunc,
            undefined,
            activeUserId,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((shoppingCart) => {
              console.log('api.upsertShoppingCart: received updated object.', { shoppingCart })
              resolve(shoppingCart)
            }, (error) => {
              logger.error('api.upsertShoppingCart: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertShoppingCart: error', { error })
        reject(error)
      })
  })
)

export default upsertShoppingCart
