import React from 'react'

import type { Product } from '../../lib/core/definitions'
import RedeemInstructionsSection from './RedeemInstructionsSection'
import TermsOfUseSection from './TermsOfUseSection'

type Props = {
  product: Product | null | undefined,
}

const ProductInfo: React.FC<Props> = ({ product }): JSX.Element | null => {
  // const onVisitMerchantWebsite = (): void => {
  //   if (!product || !product.url) {
  //     return
  //   }
  //   window.open(product.url as string, '_system')
  // }

  if (!product) {
    return null
  }

  const sections: JSX.Element[] = []

  if (product && (product.instructionsEn || product.instructionsUrl)) {
    sections.push(<RedeemInstructionsSection key='instructions' product={product} />)
  }

  if (product.termsEn || product.termsUrl) {
    sections.push(<TermsOfUseSection key='terms' product={product} />)
  }

  return (
    <div className='g-with-safe-padding'>
      {sections}
    </div>
  )
}

export default ProductInfo
