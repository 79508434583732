import moment from 'moment'
import React from 'react'

import type { Purchase } from '../../../lib/core/definitions'
import UserInfoCard from '../../../components/UserInfoCard/UserInfoCard'

type Props = {
  purchase: Purchase
  onOpenContact: (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined
  ) => void
}

const GiftCardReceivedSection: React.FC<Props> = ({
  purchase,
  onOpenContact,
}: Props): JSX.Element | null => {
  if (
    !purchase ||
    !Array.isArray(purchase.receivedPurchaseTransfers) ||
    purchase.receivedPurchaseTransfers.length < 1
  ) {
    return null
  }

  const transfer = purchase.receivedPurchaseTransfers[purchase.receivedPurchaseTransfers.length - 1]
  const formattedTransferCreatedAt = moment(+(transfer.createdAt as string)).format('Y/M/D HH:mm')
  const parts: JSX.Element[] = []
  if (transfer.fromUser) {
    parts.push(
      <div key='from-user' className='withStandardBottomMargin'>
        <div className='withBoldText withStandardBottomMargin'>Sender</div>
        <UserInfoCard
          userIdentInfo={transfer.fromUser}
          showChat
          onOpenContact={onOpenContact}
        />
      </div>,
    )
  }

  return (
    <div className='withStandardPadding'>
      <div className='sectionCaption withSmallTopMargin'>Gift Card Received</div>
      <div className='withStandardBottomMargin'>
        You received this gift card from another Mimble member as a transfer.
      </div>
      <div className='withStandardBottomMargin'>
        <div className='withBoldText withSmallBottomMargin'>Transfer Date</div>
        <div>{formattedTransferCreatedAt}</div>
      </div>
      {parts}
    </div>
  )
}

export default GiftCardReceivedSection
