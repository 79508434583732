import { IonInput, IonIcon, IonButton } from '@ionic/react'
import { addCircleOutline, search } from 'ionicons/icons'
import React, { useState } from 'react'

import type { MemberMessageTemplateListFilter } from '../../../lib/core/definitions'

type Props = {
  onChangeFilter: (filter: MemberMessageTemplateListFilter) => void
  onCreateNew: () => void
}

const MemberMessageTemplateListHeader: React.FC<Props> = (props): JSX.Element => {
  const {
    onChangeFilter,
    onCreateNew,
  } = props
  // console.log('MemberMessageTemplateListHeader.render called.', props);
  // const isProcessing = isGettingSystemMessages || isGettingSystemMessage;

  // ===================================================================================================================
  // State:
  const [searchText, setSearchText] = useState<string | undefined>()

  // ===================================================================================================================
  // Helpers:

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // SystemMessage List:

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSearchTextLocal = (event: any): void => {
    setSearchText(event.detail.value)
    const filter: MemberMessageTemplateListFilter = {
      searchText,
    }
    onChangeFilter(filter)
  }

  const onClearFilter = (): void => {
    setSearchText('')
    onChangeFilter({})
  }

  // ===================================================================================================================
  // Rendering:
  return (
    <div className='rowWithCenterAlignedItems'>
      <div className='searchBarWrapper'>
        <IonIcon icon={search} class='searchBarSearchIcon' />
        <IonInput
          value={searchText}
          placeholder='search'
          onIonChange={onChangeSearchTextLocal}
          className='searchBar'
          clearInput
        />
      </div>
      <IonButton
        size='small'
        fill='clear'
        color='medium'
        onClick={onCreateNew}
      >
        <IonIcon icon={addCircleOutline} />
      </IonButton>
    </div>
  )
}

export default MemberMessageTemplateListHeader
