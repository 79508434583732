import React, { useContext, useEffect, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useLocation, useHistory, useParams } from 'react-router-dom'

// import './styles.css'
import { FundType, PurchaseType, UiMessage } from '../../lib/core/enums'
import type { Purchase } from '../../lib/core/definitions'
import { AppPage, AppRoute, GiftFlowStep, PageMessageType } from '../../enums'
import type { OrderQueryData, OrderQueryVariables } from '../../services/apollo/definitions'
import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import api from '../../services/api'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import ForMarketplaceProduct from './ForMarketplaceProduct'
import ForMultipleProducts from './ForMultipleProducts'
import ForTokens from './ForTokens'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'

const appPageId = AppPage.CheckoutPaymentReceivedPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  orderId: string
}

const CheckoutPaymentReceivedPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { orderId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { getIntValue: getGlobalCacheIntValue } = useGlobalCache()
  const {
    activeUser,
    isLoadingActiveUser,
    isLoadingActiveUserWallets,
    getActiveUserMimbleWallet,
  } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const mimbleWallet = getActiveUserMimbleWallet && getActiveUserMimbleWallet()
  const { giftFlowStep, start: startGiftFlow } = useGiftFlow()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [newTokenBalance, setNewTokenBalance] = useState<number | undefined>()
  const [isLoadingNewBalance, setIsLoadingNewBalance] = useState(false)

  // -------------------------------------------------------------------------------------------------------------------
  // Loading order:
  const {
    data: orderLoadedData,
    loading: isLoadingOrder,
    refetch: reloadOrder,
  } = useQuery<OrderQueryData, OrderQueryVariables>(
    apollo.queries.order, {
      variables: { orderId: orderId as string },
      skip: !orderId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
        const returnedOrder = data ? data.order : undefined
        if (returnedOrder === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.GIFT_CARD_NOT_FOUND)
        }
      },
      onError: (error) => {
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const order = orderLoadedData ? orderLoadedData.order : undefined
  let singlePurchase: Purchase | undefined
  if (
    order &&
    Array.isArray(order.items) &&
    order.items.length === 1
  ) {
    singlePurchase = order.items[0]
  }

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingOrder || isLoadingActiveUser || isLoadingActiveUserWallets

  // ===================================================================================================================
  // Effect Hooks:
  useEffect(() => {
    console.log('CheckoutPaymentReceivedPage.useEffect[newTokenBalance] called.',
      { newTokenBalance, mimbleWallet, isLoadingNewBalance })

    if (
      !order ||
      !order.paymentTransaction ||
      order.paymentTransaction.fundType !== FundType.TOKEN
    ) {
      return
    }

    if (newTokenBalance === undefined && mimbleWallet && !isLoadingNewBalance) {
      console.log('CheckoutPaymentReceivedPage.useEffect[newTokenBalance]: loading wallet.')
      setIsLoadingNewBalance(true)
      const oldTokenBalance = getGlobalCacheIntValue(
        GlobalCacheDataKey.CURRENT_MIMBLE_WALLET_TOKEN_BALANCE,
        false,
      )
      api.loadWalletWithNewBalance(
        mimbleWallet.id as string,
        oldTokenBalance,
        activeUserId as string,
        apolloClient,
      ).then((updatedWallet) => {
        setIsLoadingNewBalance(false)
        if (updatedWallet) {
          setNewTokenBalance(updatedWallet.balanceToken)
        }
      }, (error) => {
        setIsLoadingNewBalance(false)
        console.error(error)
      })
    }
  }, [newTokenBalance, mimbleWallet])

  // ===================================================================================================================
  // Event Handlers:
  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadOrder().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenPurchase = (): void => {
    if (singlePurchase) {
      navigate(`${AppRoute.GIFT_CARD}/${singlePurchase.id}`)
    }
  }

  const onNavigateToGiftFlow = (): void => {
    // console.log('CheckoutPaymentReceivedPage.onNavigateToGiftFlow called.', { singlePurchase, giftFlowStep })
    if (
      singlePurchase &&
      (!giftFlowStep || giftFlowStep !== GiftFlowStep.GIFT)
    ) {
      startGiftFlow({
        fromPurchaseId: singlePurchase.id as string,
      })
    }
    navigate(AppRoute.SEND_GIFT)
  }

  const onNavigateToWallet = (): void => {
    navigate(AppRoute.WALLET)
  }

  const onOpenMarketplace = (): void => {
    navigate(AppRoute.MARKETPLACE)
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content
  if (singlePurchase) {
    const purchaseAmount = singlePurchase.amount as number
    if (singlePurchase && singlePurchase.purchaseType === PurchaseType.TOKEN) {
      content = (
        <ForTokens
          purchaseAmount={purchaseAmount}
          walletBalance={newTokenBalance}
          onOpenMarketplace={onOpenMarketplace}
        />
      )
    } else if (singlePurchase && singlePurchase.purchaseType === PurchaseType.MARKETPLACE_PRODUCT) {
      content = (
        <ForMarketplaceProduct
          purchase={singlePurchase}
          onNavigateToGiftFlow={onNavigateToGiftFlow}
          onOpenPurchase={onOpenPurchase}
          onSendGift={onNavigateToGiftFlow}
        />
      )
    }
  } else if (order) {
    content = (
      <ForMultipleProducts
        order={order}
        onNavigateToGiftFlow={onNavigateToGiftFlow}
        onNavigateToWallet={onNavigateToWallet}
        onOpenPurchase={onOpenPurchase}
        onSendGift={onNavigateToGiftFlow}
      />
    )
  }

  return (
    <IonPage>
      <NavBar
        title='Checkout Complete'
        userInfo={activeUser}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <PageMessages />
      <IonContent className='g-content-with-padding'>
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {content}
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default CheckoutPaymentReceivedPage
