import logger from '../logger'

export interface ParseUrlResult {
  action: 'join' | 'gift' | undefined;
  code: string | undefined;
  route: string | undefined;
}

// We're looking for an URL like this:
// app.mimble.mimbleapp://google/link/?deep_link_id=https%3A%2F%2Fmimble%2Eapp%2Fjoin%2Fkoo%2Dboo%2Dhoo&match_type=unique&utm_medium=dynamic%5Flink&request_ip_version=IP%5FV4&utm_source=firebase&match_message=Link%20is%20uniquely%20matched%20for%20this%20device%2E
// app.mimble.mimbleapp://google/link/?deep_link_id=https://mimble.app/join/koo-boo-hoo&match_type=unique&utm_medium=dynamic_link&request_ip_version=IP_V4&utm_source=firebase&match_message=Link is uniquely matched for this device.
const parseDynamicLinkUrlVerA = (url: string): ParseUrlResult | undefined => {
  const match = url.match(/.*deep_link_id=https:\/\/mimble\.app\/(.*)&match_type=*/)
  if (!Array.isArray(match) || match.length < 2) {
    return
  }
  let path = match[1] // something like: join/koo-boo-hoo
  if (!path.includes('/')) {
    if (path === 'invite') {
      path = 'join'
    }
    if (path !== 'join' && path !== 'gift') {
      logger.error('services.firebase.parseDynamicLinkUrl: failed to parse url (1).', { url })
      return
    }
    return { action: path as 'join' | 'gift', code: undefined, route: undefined }
  }
  const parts = path.split('/')
  let action = parts[0]
  if (action === 'invite') {
    action = 'join'
  }
  return { action: action as 'join' | 'gift', code: parts[1], route: undefined }
}

// We're looking for an URL like this:
// https://link.mimble.app/?link=https://mimble.app/join/koo-boo-hoo&apn=app.mimble.mimbleapp&isi=1396908399&ibi=app.mimble.mimbleapp&st=Gifting%20-%20fun%2C%20fast%2C%20easy&sd=Mimble%20is%20a%20social%20gifting%20platform.%20Make%20sure%20the%20checkbox%20below%20is%20checked%20to%20activate%20the%20invitation.&si=https%3A%2F%2Fco-mimble-cdn.s3.us-west-2.amazonaws.com%2Fwebimgs%2Fapp-preview-img.jpg
const parseDynamicLinkUrlVerB = (url: string): ParseUrlResult | undefined => {
  const match = url.match(/.*link=https:\/\/mimble.app\/(join|gift)\/(.*)&apn.*/)
  logger.debug('services.firebase.parseDynamicLinkUrl: regex returned.', { match })
  if (!Array.isArray(match) || match.length !== 3) {
    logger.info('services.firebase.parseDynamicLinkUrl: regex failed.')
    return
  }
  return { action: match[1] as 'join' | 'gift', code: match[2], route: undefined }
}

const parseDeeplink = (url: string): ParseUrlResult | undefined => {
  const decodedUrl = decodeURIComponent(url)
  logger.info('services.firebase.parseDynamicLinkUrl called.', { url: decodedUrl })

  if (url.toLowerCase().startsWith('https://mimble.app/')) {
    logger.debug('services.firebase.parseDynamicLinkUrl: starts with https://mimble.app/.')
    return { action: undefined, code: undefined, route: url.substr(18) }
  }

  if (decodedUrl.startsWith('app.mimble.mimbleapp://')) {
    logger.debug('services.firebase.parseDynamicLinkUrl: this is a Dynamic Link (vA).')
    return parseDynamicLinkUrlVerA(decodedUrl)
  }

  if (decodedUrl.startsWith('https://link.mimble.app/')) {
    logger.debug('services.firebase.parseDynamicLinkUrl: this is a Dynamic Link (vB).')
    return parseDynamicLinkUrlVerB(decodedUrl)
  }

  if (!decodedUrl.includes('mimble.app/')) {
    logger.error('services.firebase.parseDynamicLinkUrl: failed to parse url (2).')
    return
  }

  const path = decodedUrl.split('mimble.app/').pop()
  if (!path) {
    return
  }

  let code = path.includes('/')
    ? path.split('/').pop()
    : undefined
  if (code && code.includes('&')) {
    code = code.split('&').shift()
  }

  let action: 'join' | 'gift' | undefined
  if (decodedUrl.match(/mimble\.app\/(invite|join)/)) {
    action = 'join'
  } else if (decodedUrl.match(/mimble\.app\/gift/)) {
    action = 'gift'
  } else {
    logger.error('services.firebase.parseDynamicLinkUrl: failed to parse url (3).')
    return
  }

  return { action, code, route: undefined }
}

export default parseDeeplink
