import moment from 'moment'
import React, { useState } from 'react'

import './styles.css'
import { AppRoute } from '../../../enums'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import type { User, UserInput } from '../../../lib/core/definitions'
import Avatar from '../../../components/Avatar/Avatar'
import BirthdayForm from './BirthdayForm/BirthdayForm'
import coreHelpers from '../../../lib/core/helpers'
import EditAvatarModal from '../../../components/EditAvatarModal/EditAvatarModal'
import InfoPaneEntry from '../../../components/InfoPaneEntry/InfoPaneEntry'
import NameForm from './NameForm/NameForm'
import SharedWithContactsNotice from './SharedWithContactsNotice/SharedWithContactsNotice'
import TfaFieldForm from './TfaFieldForm/TfaFieldForm'
import UsernameForm from './UsernameForm/UsernameForm'

type Props = {
  showUiMessage: (message: string) => void;
}

const ProfileTab: React.FC<Props> = (props): JSX.Element | null => {
  const {
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const { activeUser, reloadActiveUser } = useMimbleData()
  const [showUploadAvatarModal, setShowUploadAvatarModal] = useState(false)
  const [isEditingBirthday, setIsEditingBirthday] = useState(false)
  const [isEditingEmail, setIsEditingEmail] = useState(false)
  const [isEditingName, setIsEditingName] = useState(false)
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false)
  const [isEditingUsername, setIsEditingUsername] = useState(false)

  // ===================================================================================================================
  // Event Handlers:
  const onOpenUploadAvatarModal = () => {
    setShowUploadAvatarModal(true)
  }

  const onCloseUploadAvatarModal = () => {
    setShowUploadAvatarModal(false)
  }

  const onSuccessEditAvatar = () => {
    setShowUploadAvatarModal(false)
    showUiMessage('Successfully upload a new avatar.')
    reloadActiveUser().then(undefined, (error) => {
      console.error(error)
    })
  }

  const onCloseNameForm = () => {
    setIsEditingName(false)
  }

  const onCloseUsernameForm = () => {
    setIsEditingUsername(false)
  }

  const onClosePhoneNumberForm = () => {
    setIsEditingPhoneNumber(false)
  }

  const onCloseEmailForm = () => {
    setIsEditingEmail(false)
  }

  const onCloseBirthdayForm = () => {
    setIsEditingBirthday(false)
  }

  // ===================================================================================================================
  // Rendering:
  if (!activeUser) {
    return null
  }

  const signUpDate = moment(parseInt(activeUser.createdAt as string, 10))
  const formattedSignUpDate = `${signUpDate.format('M/D/Y')} - ${signUpDate.from(moment(), false)}`

  let nameSection: JSX.Element | undefined
  if (isEditingName) {
    nameSection = (
      <NameForm
        onClose={onCloseNameForm}
        showUiMessage={showUiMessage}
      />
    )
  } else {
    nameSection = (
        <InfoPaneEntry
          label='Name'
          value={activeUser.fullName}
          isInPlaceEditable
          className='withDoubleBottomMargin'
          onClick={() => setIsEditingName(true)}
        />
    )
  }

  let formattedPhoneNumber: string | undefined
  if (activeUser.phoneNumber) {
    formattedPhoneNumber = coreHelpers.ui.formatPhoneNumber(activeUser.phoneNumber)
  }

  let usernameSection: JSX.Element | undefined
  if (isEditingUsername) {
    usernameSection = (
      <UsernameForm
        onClose={onCloseUsernameForm}
        showUiMessage={showUiMessage}
      />
    )
  } else {
    usernameSection = (
      <InfoPaneEntry
        label='Your Mimble handle'
        value={`@${activeUser && activeUser.username}`}
        isInPlaceEditable
        className='withDoubleBottomMargin'
        onClick={() => setIsEditingUsername(true)}
      />
    )
  }

  let phoneNumberSection: JSX.Element | undefined
  if (isEditingPhoneNumber) {
    phoneNumberSection = (
      <TfaFieldForm
        attribute='phoneNumber'
        onClose={onClosePhoneNumberForm}
        showUiMessage={showUiMessage}
      />
    )
  } else {
    phoneNumberSection = (
      <div className='withDoubleBottomMargin'>
        <InfoPaneEntry
          label='Phone Number'
          value={formattedPhoneNumber}
          isInPlaceEditable
          noBottomMargin
          onClick={() => setIsEditingPhoneNumber(true)}
        />
        <SharedWithContactsNotice isShared={activeUser.prefs && activeUser.prefs.sharePhoneNumber} />
      </div>
    )
  }

  let emailSection: JSX.Element | undefined
  if (isEditingEmail) {
    emailSection = (
      <TfaFieldForm
        attribute='email'
        onClose={onCloseEmailForm}
        showUiMessage={showUiMessage}
      />
    )
  } else {
    emailSection = (
      <div className='withDoubleBottomMargin'>
        <InfoPaneEntry
          label='Email'
          value={(activeUser && activeUser.email)}
          isInPlaceEditable
          noBottomMargin
          onClick={() => setIsEditingEmail(true)}
        />
        <SharedWithContactsNotice isShared={activeUser.prefs && activeUser.prefs.shareEmail} />
      </div>
    )
  }

  let birthdaySection: JSX.Element | undefined
  if (isEditingBirthday) {
    birthdaySection = (
      <BirthdayForm
        onClose={onCloseBirthdayForm}
        showUiMessage={showUiMessage}
      />
    )
  } else {
    birthdaySection = (
      <div className='withDoubleBottomMargin'>
        <InfoPaneEntry
          label='Birthday'
          value={activeUser.birthday}
          isInPlaceEditable
          noBottomMargin
          onClick={() => setIsEditingBirthday(true)}
        />
        <SharedWithContactsNotice isShared={activeUser.prefs && activeUser.prefs.shareBirthday} />
      </div>
    )
  }

  return (
    <div className='user-account-profile-tab'>
      <div className='rowWithSpaceBetween'>
        <div className='col'>
          {usernameSection}
          {nameSection}
        </div>
        <div className='withCenteredColumnContent'>
          <Avatar
            user={activeUser}
            className='withPointerCursor'
            size={80}
            onClick={onOpenUploadAvatarModal}
          />
          <div className='smallText linkText withStandardTopMargin' onClick={onOpenUploadAvatarModal}>
            Edit Avatar
          </div>
        </div>
      </div>
      {phoneNumberSection}
      {emailSection}
      {birthdaySection}
      <div key='since' className='formLabel'>
        You&apos;ve been a member since: {formattedSignUpDate}
      </div>
      <EditAvatarModal
        user={activeUser}
        show={showUploadAvatarModal}
        onClose={onCloseUploadAvatarModal}
        onSuccess={onSuccessEditAvatar}
      />
    </div>
  )
}

export default ProfileTab
