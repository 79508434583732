import { TransactionStatus } from '../../../lib/core/enums'
import type { Transaction } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'

export enum PaymentRequestPart {
  RECEIVING_ADDRESS = 'receiving-address',
  AMOUNT = 'amount',
  MESSAGE = 'message',
  REQUEST_STRING = 'request-string',
}

const getRequestAgeInSeconds = (transaction: Transaction | null | undefined): number | undefined => {
  if (!transaction || !transaction.exchangeRateUpdatedAt) {
    return
  }
  return (new Date().getTime() - parseInt(transaction.exchangeRateUpdatedAt, 10)) / 1000
}

const helpers = {
  // hasRequestExpired: (transaction: Transaction | null | undefined): boolean => {
  //   if (
  //     !transaction ||
  //     !transaction.exchangeRateUpdatedAt ||
  //     !(
  //       transaction.status === TransactionStatus.REQUEST_CREATED ||
  //       transaction.status === TransactionStatus.NOT_STARTED
  //     )
  //   ) {
  //     return false
  //   }
  //   const requestAgeInSeconds = getRequestAgeInSeconds(transaction)
  //   return (
  //     !!requestAgeInSeconds &&
  //     requestAgeInSeconds >= 15 * 60
  //   )
  // },

  getRequestAgeInSeconds,
}

export default helpers
