import React from 'react'
import { IonContent, IonImg, IonPage } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import { BarcodeType } from '../../lib/core/enums'
import type { PurchaseQueryData, PurchaseQueryVariables } from '../../services/apollo/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import apollo from '../../services/apollo'
import auth from '../../services/auth'
import Barcode from '../../components/Barcode/Barcode'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'

const appPageId = AppPage.BarcodePage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const getBarcodeScalingFactor = (value: string, barcodeType: BarcodeType | null | undefined) => {
  if (barcodeType === BarcodeType.QR_CODE) {
    return 3
  }
  if (barcodeType === BarcodeType.PDF417) {
    return 3
  }
  const isAllNumbers = !value.match(/\D/)
  if (value.length <= 10) {
    return isAllNumbers ? 3 : 2
  }
  if (value.length <= 15) {
    return isAllNumbers ? 3 : 2
  }
  if (value.length <= 20) {
    return isAllNumbers ? 2 : 1
  }
  if (value.length <= 25) {
    return isAllNumbers ? 2 : 1
  }
  if (value.length <= 30) {
    return isAllNumbers ? 1 : 1
  }
  if (value.length <= 45) {
    return isAllNumbers ? 1 : 1
  }
  return 2
}

type Params = {
  purchaseId: string
}

const BarcodePage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { purchaseId } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading purchase:
  const {
    data: purchaseLoadedData,
    loading: isLoadingPurchase,
    // error: purchaseLoadingError,
  } = useQuery<PurchaseQueryData, PurchaseQueryVariables>(
    apollo.queries.purchase, {
      variables: { purchaseId: purchaseId as string },
      skip: !purchaseId,
      notifyOnNetworkStatusChange: true,
      // onCompleted: (data) => {
      //   const returnedPurchase = data ? data.purchase : undefined
      // },
    },
  )
  const purchase = purchaseLoadedData ? purchaseLoadedData.purchase : undefined
  const product = purchase ? purchase.product : undefined
  const { merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromProduct(product)

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Page:

  // ===================================================================================================================
  // Event Handlers:
  const onBack = (): void => {
    navigate(-1)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  let content: JSX.Element | undefined
  if (purchase && purchase.code) {
    let merchantLogo: JSX.Element | undefined
    if (merchantLogoUri) {
      merchantLogo = (
        <IonImg src={merchantLogoUri} className='merchant-logo' />
      )
    }
    const displayCode = purchase.code
      .replace(/(\d{4})/g, '$1 ')
      .replace(/(^\s+|\s+$)/, '')

    let img: JSX.Element | undefined
    if (purchase.hasBarcode) {
      const value = purchase.code

      // 45:
      // const value = '98798a32459b753498735498756439873469867346322'
      // const value = 'IOUWQERIUYQWETOIUWQETOIYWERTOIYWERTIUYWQEROKE'

      // 30:
      // const value = '345987345987345903030260861163'
      // const value = 'SGKJSDGFPIUIINSGSAGASHJASDUASD'

      // 25:
      // const value = '8236873409174986348128022'
      // const value = 'SGKJSDGFPIUIINSGSAGASHJAR'

      // 20:
      // const value = '98734587634598735433'
      // const value = 'SGKJSDGFPIUIINSGSAGA'

      // 15:
      // const value = '967239393472301'
      // const value = 'SGKJSDGFPIUIINS'

      // 10:
      // const value = '9798787665'
      // const value = 'SGKJSDGFPI'

      const scale = getBarcodeScalingFactor(value, purchase.barcodeFormat)
      const rotate = !purchase.barcodeFormat || purchase.barcodeFormat !== BarcodeType.QR_CODE
        ? 'L'
        : 'N'
      img = (
        <div className='code-wrapper'>
          <Barcode
            value={value}
            format={purchase.barcodeFormat || undefined}
            scale={scale}
            rotate={rotate}
          />
        </div>
      )
    }

    let pinSection: JSX.Element | undefined
    if (purchase.pin) {
      pinSection = (
        <div className='card-pin'>
          <span className='label'>PIN:</span> {purchase.pin}
        </div>
      )
    }

    content = (
      <div className='viewWrapper'>
        {merchantLogo}
        <div onClick={onBack}>
          {img}
        </div>
        <div className='number-pane'>
          <div className='code'>{displayCode}</div>
          {pinSection}
        </div>
      </div>
    )
  }

  return (
    <IonPage className='barcode-page'>
      <NavBar
        title='Gift Card Barcode'
        goBackUri={purchaseId ? `${AppRoute.GIFT_CARD}/${purchaseId}` : AppRoute.WALLET}
        isProcessing={isLoadingPurchase}
      />
      <IonContent className='g-content-with-safe-padding' scrollX={false} scrollY={false}>
        <div className='page-content'>
          {content}
        </div>
      </IonContent>
      {/* <AppPageFooter
        scope={appPageDef.appTabScope}
      /> */}
    </IonPage>
  )
}

export default BarcodePage
