import { RecordStatus, RewardType } from '../../enums'
import type { FilterRewardType, Reward, RewardConfig } from '../../definitions'

const getConfig = (
  rewardType: RewardType,
  occurrence: number | null | undefined,
  invitationLevel: number | null | undefined,
  rewardConfigs: RewardConfig[] | undefined,
): RewardConfig | undefined => rewardConfigs
  ? rewardConfigs.find((c) => {
    if (c.rewardType !== rewardType) {
      return false
    }
    if (
      occurrence !== null &&
      occurrence !== undefined &&
      occurrence > 0 &&
      occurrence !== c.occurrence
    ) {
      return false
    }
    return (
      invitationLevel === null ||
      invitationLevel === undefined ||
      invitationLevel === c.invitationLevel
    )
  })
  : undefined

const getLabel = (
  rewardType: RewardType,
  occurrence: number | null | undefined,
  invitationLevel: number | null | undefined,
  rewardConfigs: RewardConfig[] | undefined,
): string => {
  const config = getConfig(rewardType, occurrence, invitationLevel, rewardConfigs)
  if (!config) {
    return ''
  }
  return config.labelEn as string
}

const getDescription = (
  rewardType: RewardType,
  occurrence: number | null | undefined,
  invitationLevel: number | null | undefined,
  rewardConfigs: RewardConfig[] | undefined,
): string => {
  const config = getConfig(rewardType, occurrence, invitationLevel, rewardConfigs)
  if (!config) {
    return ''
  }
  return config.descriptionEn as string
}

const rewardTypeHelpers = {
  isValid: (type: RewardType | string | null | undefined): boolean =>
    !type || Object.values(RewardType).includes(type as RewardType),

  getConfig,
  getLabel,
  getDescription,

  requireForObjectId: (rewardType: RewardType): boolean => (
    rewardType === RewardType.ADD_CONTACT ||
    rewardType === RewardType.ADD_FAVORITE_MERCHANT ||
    rewardType === RewardType.ADD_GIFT_IDEA ||
    rewardType === RewardType.ADD_WISH ||
    rewardType === RewardType.ADDED_AS_A_CONTACT ||
    rewardType === RewardType.CARD_BALANCE_UPDATE ||
    rewardType === RewardType.CONTACT_ADDED_GIFT_IDEA ||
    rewardType === RewardType.GIFT_CARD_UPLOAD ||
    rewardType === RewardType.INVITED_BY_USER ||
    rewardType === RewardType.MARKETPLACE_PURCHASE ||
    rewardType === RewardType.NEW_USER_INVITATION ||
    rewardType === RewardType.PURCHASE_BY_INVITED_USER ||
    rewardType === RewardType.PURCHASE_TRANSFER
  ),

  usesOccurrence: (rewardType: RewardType): boolean => (
    rewardType === RewardType.ADD_FAVORITE_MERCHANT ||
    rewardType === RewardType.ADD_GIFT_IDEA ||
    rewardType === RewardType.ADD_WISH ||
    rewardType === RewardType.CARD_BALANCE_UPDATE ||
    rewardType === RewardType.GIFT_CARD_UPLOAD ||
    rewardType === RewardType.SESSION_MILESTONE ||
    rewardType === RewardType.SHARE_BIRTHDAY ||
    rewardType === RewardType.SHARE_WISH_LIST
  ),

  getObjectId: (reward: Reward | null | undefined): string | null | undefined => {
    if (!reward) {
      return
    }
    switch (reward.rewardType) {
      case RewardType.ADD_CONTACT:
        return reward.forContactId
      case RewardType.ADD_FAVORITE_MERCHANT:
        return reward.forFavoriteOrganizationId
      case RewardType.ADD_GIFT_IDEA:
        return reward.forWishId
      case RewardType.ADDED_AS_A_CONTACT:
        return reward.forContactId
      case RewardType.ADD_WISH:
        return reward.forWishId
      case RewardType.CARD_BALANCE_UPDATE:
        return reward.forPurchaseId
      case RewardType.CONTACT_ADDED_GIFT_IDEA:
        return reward.forWishId
      case RewardType.GIFT_CARD_UPLOAD:
        return reward.forPurchaseId
      case RewardType.MARKETPLACE_PURCHASE:
        return reward.forPurchaseId
      case RewardType.NEW_USER_INVITATION:
        return reward.forInvitationId
      case RewardType.PURCHASE_BY_INVITED_USER:
        return reward.forPurchaseId
      case RewardType.PURCHASE_TRANSFER:
        return reward.forPurchaseTransferId
      case RewardType.INVITED_BY_USER:
        return reward.forInvitationId
    }
  },

  requiresMarketplaceAccess: (rewardType: RewardType): boolean => (
    rewardType === RewardType.MARKETPLACE_PURCHASE
  ),

  getFilterRewardTypes: (rewardConfigs: RewardConfig[]): FilterRewardType[] => {
    return rewardConfigs
      .filter(config => config.recordStatus === RecordStatus.ACTIVE)
      .map((config) => ({
        rewardType: config.rewardType as RewardType,
        label: getLabel(config.rewardType as RewardType, config.occurrence, config.invitationLevel, rewardConfigs),
        occurrence: config.occurrence,
        invitationLevel: config.invitationLevel,
      }))
      .sort((a, b) => {
        const labelA = getLabel(a.rewardType, a.occurrence, a.invitationLevel, rewardConfigs)
        const labelB = getLabel(b.rewardType, b.occurrence, b.invitationLevel, rewardConfigs)
        return labelA.localeCompare(labelB)
      })
  },

  getRewardTypesReferencingContact: (): RewardType[] => [
    RewardType.ADD_CONTACT,
    RewardType.ADD_GIFT_IDEA,
    RewardType.ADDED_AS_A_CONTACT,
    RewardType.INVITED_BY_USER,
    RewardType.NEW_USER_INVITATION,
    RewardType.PURCHASE_BY_INVITED_USER,
    RewardType.PURCHASE_TRANSFER,
  ],
}

export default rewardTypeHelpers
