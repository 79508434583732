import { gql } from '@apollo/client'

export default gql`
  query Q($userId: String!) {
    user (userId: $userId) {
      id
      fullName
      email
      emailVerified
      phoneNumber
      phoneNumberVerified
      username
      imageUrl
      birthday
      inviteCode
      appFeatures
      isOrganization
      roles
      trustLevel
      maxPurchaseAmountCc
      permissions
      oAuthProvider
      source
      adminNotes
      recordStatus
      createdAt
      updatedAt
      prefs {
        contrastPennies
        shareBirthday
        shareBirthdayYear
        shareEmail
        sharePhoneNumber
        shareWishList
        showWelcomeMessage
        showTipUploadCard
        showTipSendCard
      }
      metadata {
        mimbleBalanceCrypto
        mimbleBalanceFiat
        mimbleBalanceToken

        giftCardCount
        purchasedGiftCardCount
        uploadedGiftCardCount
        pendingGiftCardCount

        receivedGiftCardCount
        receivedActiveGiftCardCount
        receivedFailedGiftCardCount
        receivedPendingGiftCardCount
        receivedDeclinedGiftCardCount

        sentGiftCardCount
        sentActiveGiftCardCount
        sentFailedGiftCardCount
        sentPendingGiftCardCount
        sentDeclinedGiftCardCount
      }
      latestUserDevice {
        id
        appVersion
        os
        timezone
        ipAddress
        signedInAt
        signedOutAt
        sessionStartedAt
        sessionEndedAt
        createdAt
      }
    }
  }
`
