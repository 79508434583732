import { gql } from '@apollo/client'

export default gql`
  query Q($filter: ShoppingCartListFilter!) {
    shoppingCarts (filter: $filter) {
      id
      userId
      orderId
      donationTargetId
      userFullName
      status
      paymentProcessor
      paymentAmount
      paymentFundType
      paymentCurrency
      paymentStatus
      errorCode
      errorMessage
      metadata
      recordStatus
      createdAt
      updatedAt
    }
  }`
