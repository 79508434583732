import { AppRoute } from '../../enums'
import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

const handleParsedLink = (
  action: 'join' | 'gift' | undefined,
  code: string | undefined,
  route: string | undefined,
  navigate: (route: AppRoute | string, replace?: boolean) => void,
): void => {
  console.log('services.firebase.handleParsedLink called.', { action, code, route })

  if (route) {
    console.log('services.firebase.handleParsedLink: simple deep link.', { route })
    navigate(route)
    return
  }

  if (action === 'join') {
    console.log('services.firebase.handleParsedLink: invite detected.')
    if (code) {
      console.log('services.firebase.handleParsedLink: invite code found.', code)
      globalCacheData.setValue(
        GlobalCacheDataKey.INVITE_CODE,
        code,
      )
      console.log('services.firebase.handleParsedLink: forwarding to.', `${AppRoute.JOIN}/${code}`)
      navigate(`${AppRoute.JOIN}/${code}`)
    } else {
      console.error('services.firebase.handleParsedLink: no code found, forwarding to:', AppRoute.JOIN)
      navigate(AppRoute.JOIN, true)
    }
    return
  }

  if (action === 'gift') {
    console.log('services.firebase.handleParsedLink: gift detected.')
    if (code) {
      console.log('services.firebase.handleParsedLink: forwarding to.', `${AppRoute.GIFT_RECEIVED}/${code}`)
      navigate(`${AppRoute.GIFT_RECEIVED}/${code}`)
    } else {
      console.error('Gift deeplink is missing purchase ID; forwarding to: ', AppRoute.JOIN)
      navigate(AppRoute.JOIN, true)
    }
    return
  }

  console.log('services.firebase.handleParsedLink: unexpected action.', { action })
}

export default handleParsedLink
