import type { ApolloClient } from '@apollo/client'
import type { Campaign, CampaignListItem } from '../../../lib/core/definitions'
import type {
  CampaignQueryData,
  CampaignQueryVariables,
  CampaignsQueryData,
  CampaignsQueryVariables,
} from '../../apollo/definitions'
import { ErrorCode } from '../../../lib/core/enums'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  campaign: Campaign,
  queryListVariables: CampaignsQueryVariables,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateCampaign.updateInList called.', { campaign })
  if (!campaign) {
    logger.error('api.cache.updateCampaign.updateInList: no campaign given.')
    return
  }

  try {
    const cachedCampaignsData = apolloClient.readQuery<CampaignsQueryData, CampaignsQueryVariables>({
      query: apollo.queries.campaigns,
      variables: queryListVariables,
    })

    if (
      !cachedCampaignsData ||
      !Array.isArray(cachedCampaignsData.campaigns) ||
      cachedCampaignsData.campaigns.length < 1
    ) {
      return
    }

    const updatedCampaigns = coreHelpers.models.updateModelInList<CampaignListItem, Campaign>(
      cachedCampaignsData.campaigns,
      campaign,
      undefined,
      true,
      undefined,
    )

    apolloClient.writeQuery<CampaignsQueryData, CampaignsQueryVariables>({
      query: apollo.queries.campaigns,
      variables: queryListVariables,
      data: { campaigns: updatedCampaigns },
    })
  } catch (error) {
    logger.error('api.cache.updateCampaign.updateInList: error.', { error })
  }
}

const updateCampaign = (
  campaign: Campaign,
  queryListVariables: CampaignsQueryVariables | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateCampaign called.', { campaign })
  if (!apolloClient) {
    logger.error('api.cache.updateCampaign: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!campaign || !campaign.id) {
    logger.error('api.cache.updateCampaign: no campaign given.', { campaign })
    return
  }

  try {
    apolloClient.writeQuery<CampaignQueryData, CampaignQueryVariables>({
      query: apollo.queries.campaign,
      variables: { campaignId: campaign.id },
      data: { campaign },
    })

    if (queryListVariables) {
      updateInList(
        campaign,
        queryListVariables,
        activeUserId,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateCampaign: error.', { error })
  }
}

export default updateCampaign
