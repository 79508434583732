import {
  cart,
  settings,
  constructOutline,
  cube,
  exit,
  gift,
  people,
  wallet,
  menu,
} from 'ionicons/icons'
import { AppRoute } from '../../enums'
import type { AppTabDef } from './definitions'
import { AppTabScope, AppTabId } from './enums'

const defs: AppTabDef[] = [
  // {
  //   id: AppTabId.DASHBOARD,
  //   targetRoute: AppRoute.DASHBOARD,
  //   icon: home,
  //   label: 'Dashboard',
  //   show: isSignedIn,
  // },
  // {
  //   id: AppTabId.UPLOAD,
  //   targetRoute: '/select-brand/upload-card',
  //   icon: cloudUpload,
  //   label: 'Upload Gift Card',
  //   show: isSignedIn && !enableMarketplace,
  // },
  {
    id: AppTabId.WALLET,
    targetRoute: AppRoute.WALLET,
    icon: wallet,
    label: 'Wallet',
    scope: AppTabScope.MAIN,
  },
  {
    id: AppTabId.MARKETPLACE,
    targetRoute: undefined,
    icon: cart,
    label: 'Shop',
    scope: AppTabScope.MAIN,
  },
  {
    id: AppTabId.GIFT,
    targetRoute: AppRoute.SEND_GIFT,
    icon: gift,
    label: 'Gift',
    scope: AppTabScope.MAIN,
  },
  {
    id: AppTabId.CONTACTS,
    targetRoute: AppRoute.CONTACTS,
    icon: people,
    label: 'Contacts',
    scope: AppTabScope.MAIN,
  },
  {
    id: AppTabId.USER_ACCOUNT,
    targetRoute: AppRoute.USER_ACCOUNT,
    icon: menu,
    label: 'More',
    scope: AppTabScope.MAIN,
  },
  {
    id: AppTabId.ADMIN_DASHBOARD,
    targetRoute: AppRoute.ADMIN_DASHBOARD,
    icon: constructOutline,
    label: 'Console',
    scope: AppTabScope.ADMIN,
  },
  {
    id: AppTabId.ADMIN_USERS,
    targetRoute: AppRoute.ADMIN_USERS,
    icon: people,
    label: 'Members',
    scope: AppTabScope.ADMIN,
  },
  {
    id: AppTabId.ADMIN_PRODUCTS,
    targetRoute: AppRoute.ADMIN_PRODUCTS,
    icon: cube,
    label: 'Products',
    scope: AppTabScope.ADMIN,
  },
  // {
  //   id: AppTabId.ADMIN_MERCHANTS,
  //   targetRoute: AppRoute.ADMIN_MERCHANTS,
  //   icon: basket,
  //   label: 'Merchants',
  //   scope: AppTabScope.ADMIN,
  // },
  {
    id: AppTabId.EXIT_ADMIN,
    targetRoute: AppRoute.HOME,
    icon: exit,
    label: 'Exit Admin',
    scope: AppTabScope.ADMIN,
  },
]

export default defs
