import { FiatCurrency, FundType, ReportDataFormat, TokenName } from '../../../enums'
import type { UserListItem } from '../../../definitions'
import formatAmount from '../../ui/formatAmount'
import formatDate from '../../ui/formatDate'

const exportData = (
  users: UserListItem[] | null | undefined,
  format = ReportDataFormat.TSV,
): string => {
  if (!Array.isArray(users) || users.length < 1) {
    return ''
  }

  let colDelimiter = '\t'
  const rowDelimiter = '\n'
  switch (format) {
    case ReportDataFormat.CSV:
      colDelimiter = ','
      break
    case ReportDataFormat.TSV:
      colDelimiter = '\t'
  }

  const lines = users.map((user): string => {
    const walletBalance = user.metadata && user.metadata.mimbleBalanceToken
      ? formatAmount(user.metadata.mimbleBalanceToken, FundType.TOKEN, TokenName.MIMBLE_TOKEN)
      : '-'
    return [
      user.id,
      `@${user.username}`,
      user.fullName,
      user.roles,
      user.appFeatures,
      user.trustLevel,
      formatAmount(user.maxPurchaseAmountCc, FundType.FIAT, FiatCurrency.USD, false, 0),
      walletBalance,
      formatDate(user.sessionEndedAt),
    ].join(colDelimiter)
  })
  lines.unshift([
    'ID',
    'Handle',
    'Name',
    'Roles',
    'features',
    'Trust Level',
    'Daily CC Limit',
    'MIT Balance',
    'Last Seen',
  ].join(colDelimiter))

  return lines.join(rowDelimiter)
}

export default exportData
