import type { MouseEvent } from 'react'
import React, { useState } from 'react'
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react'
import { chevronDown, searchOutline } from 'ionicons/icons'

import './styles.css'
import type { Tag, TagListFilter } from '../../lib/core/definitions'
import { TagType } from '../../lib/core/enums'
import { useQuery } from '@apollo/client'

import type { MarketplaceSearchFilter } from './definitions'
import type { TagsQueryData, TagsQueryVariables } from '../../services/apollo/definitions'
import apollo from '../../services/apollo'

const categoryTagsFilter: TagListFilter = {
  tagTypes: [TagType.CATEGORY],
  minPriority: 0,
  maxPriority: 0,
}

const allCategoryTag: Tag = {
  id: 'all',
  name: 'all',
  captionEn: 'All',
}

type Props = {
  onSearch: (filter: MarketplaceSearchFilter) => void;
};

const MarketplaceHeader: React.FC<Props> = (props): JSX.Element => {
  const {
    onSearch,
  } = props

  // ===================================================================================================================
  // State:
  const [filter, setFilter] = useState<MarketplaceSearchFilter>({
    categoryTag: undefined,
    searchText: undefined,
    featured: false,
    all: true,
  })
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false)
  const [
    contextMenuClickEvent,
    setContextMenuClickEvent,
  ] = useState(undefined as MouseEvent<Element, MouseEvent> | undefined)

  // ===================================================================================================================
  // Apollo Hooks:
  const { data: categoryTagsQueryData } = useQuery<TagsQueryData, TagsQueryVariables>(
    apollo.queries.tags, {
      variables: { filter: categoryTagsFilter },
    },
  )
  const { tags: categoryTags } = categoryTagsQueryData || {}

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSearchText = (event: any): void => {
    const newFilter = {
      ...filter,
      searchText: event.detail.value,
    }
    setFilter(newFilter)
    if (!event.detail.value) {
      onSearch(newFilter)
    }
  }

  const onSubmit = (event?: any): void => {
    if (event) {
      event.preventDefault()
    }
    onSearch(filter)
  }

  const handleCategoryTagClick = (tag: Tag | undefined): void => {
    setShowCategoryDropdown(false)
    let newFilter: MarketplaceSearchFilter
    if (tag && tag.name === 'all') {
      newFilter = {
        ...filter,
        categoryTag: undefined,
        featured: false,
        all: true,
      }
    } else if (tag && tag.name === 'featured') {
      newFilter = {
        ...filter,
        categoryTag: undefined,
        featured: true,
        all: false,
      }
    } else {
      newFilter = {
        ...filter,
        categoryTag: tag,
        featured: false,
        all: false,
      }
    }
    setFilter(newFilter)
    onSearch(newFilter)
  }

  const openCategoryDropdown = (event: MouseEvent<Element, MouseEvent>): void => {
    event.persist()
    setShowCategoryDropdown(true)
    setContextMenuClickEvent(event)
  }

  const onDidDismissCategoryDropdown = (): void => {
    if (showCategoryDropdown) {
      setShowCategoryDropdown(false)
    }
  }

  // ===================================================================================================================
  // Rendering:
  const categoryLabel = filter.featured
    ? 'Featured'
    : (filter.categoryTag ? filter.categoryTag.captionEn : 'All')

  let categoryDropdown: JSX.Element | undefined
  if (Array.isArray(categoryTags) && categoryTags.length > 0) {
    const categoryItems = categoryTags.map((tag: Tag): JSX.Element => {
      return (
        <IonItem
          key={tag.id}
          lines='none'
          onClick={(): void => { handleCategoryTagClick(tag) }}
        >
          <IonLabel>{tag.captionEn || tag.name}</IonLabel>
        </IonItem>
      )
    })
    categoryDropdown = (
      <>
        <IonPopover
          isOpen={showCategoryDropdown}
          event={contextMenuClickEvent as Event | undefined}
          onDidDismiss={onDidDismissCategoryDropdown}
        >
          <IonList class='contextMenuList'>
            <IonItem
              key='all'
              lines='none'
              onClick={(): void => { handleCategoryTagClick(allCategoryTag) }}
            >
              <IonLabel>All</IonLabel>
            </IonItem>
            {categoryItems}
          </IonList>
        </IonPopover>
        <div className='category-dropdown' onClick={openCategoryDropdown as unknown as any}>
          <div className='category-dropdown-text'>
            {categoryLabel}
          </div>
          <IonIcon icon={chevronDown} class='category-dropdown-icon' />
        </div>
      </>
    )
  }

  return (
    <form className='marketplace-header' onSubmit={onSubmit}>
      {categoryDropdown}
      <IonInput
        value={filter.searchText}
        placeholder='search'
        onIonChange={onChangeSearchText}
        onSubmit={onSubmit}
        className='search-input'
        clearInput
      />
      <div className={filter.searchText ? 'search-button-wrapper' : 'search-button-wrapper-disabled'}>
        <IonButton
          size='small'
          fill='clear'
          disabled={!filter.searchText}
          onClick={onSubmit}
        >
          <IonIcon
            icon={searchOutline}
            className={filter.searchText ? 'search-button-icon' : 'search-button-icon-disabled'}
          />
        </IonButton>
      </div>
    </form>
  )
}

export default MarketplaceHeader
