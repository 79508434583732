import type { ApolloClient, MutationOptions } from '@apollo/client'

import type { ApiQueryOptions, Campaign, CampaignInput, CampaignListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertCampaignMutationData,
  UpsertCampaignMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadCampaign from './loadCampaign'
import logger from '../logger'

const upsertCampaign = (
  campaignInput: CampaignInput,
  isInTargetStateFunc: ((campaign: Campaign) => boolean) | 'watch-updated-at' | undefined,
  listFilter: CampaignListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Campaign | undefined> => (
  // console.log('api.upsertCampaign called.', { campaignInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertCampaign: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertCampaignMutationData, UpsertCampaignMutationVariables> = {
      mutation: apollo.mutations.upsertCampaign,
      variables: { campaign: campaignInput },
    }

    apolloClient.mutate<UpsertCampaignMutationData, UpsertCampaignMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertCampaign: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertCampaign ||
          !resp.data.upsertCampaign.id
        ) {
          logger.error('api.upsertCampaign: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((campaign: Campaign) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!campaignInput.id) {
            logger.error('api.upsertCampaign: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertCampaign.updatedAt
            activeIsInTargetStateFunc = (campaign: Campaign) => campaign.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertCampaign)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getCampaignsQueryVariables(listFilter)
            : undefined
          cache.updateCampaign(
            resp.data.upsertCampaign,
            queryListVariables,
            activeUserId,
            apolloClient,
          )
          resolve(resp.data.upsertCampaign)
          return
        }

        const campaignId = resp.data.upsertCampaign.id

        setTimeout(() => {
          // console.log('api.upsertCampaign: calling loadCampaign')
          loadCampaign(
            campaignId,
            activeIsInTargetStateFunc,
            listFilter,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((campaign) => {
              // console.log('api.upsertCampaign: resolving with campaign', campaign)
              resolve(campaign)
            }, (error) => {
              logger.error('api.upsertCampaign: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertCampaign: error', { error })
        reject(error)
      })
  })
)

export default upsertCampaign
