import type { TransactionsQueryVariables } from '../definitions'
import type { TransactionListFilter } from '../../../lib/core/definitions'
import getTransactionListFilter from './getTransactionListFilter'

const getTransactionsQueryVariables = (
  listFilter: TransactionListFilter | undefined,
  activeUserId: string,
): TransactionsQueryVariables => ({
  filter: listFilter || getTransactionListFilter(activeUserId),
})

export default getTransactionsQueryVariables
