import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, UserDevice, UserDeviceListFilter } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UserDeviceQueryData,
  UserDeviceQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import logger from '../logger'

const loadUserDevice = (
  userDeviceId: string,
  isInTargetStateFunc: ((userDevice: UserDevice) => boolean) | 'watch-updated-at' | undefined,
  listFilter: UserDeviceListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<UserDevice | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadUserDevice called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadUserDevice: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<UserDeviceQueryVariables> = {
      query: apollo.queries.userDevice,
      variables: { userDeviceId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<UserDeviceQueryData, UserDeviceQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.userDevice ||
            !resp.data.userDevice.id
          ) {
            logger.error('api.loadUserDevice.load: query did not return expected data.', { resp })
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((userDevice: UserDevice) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.userDevice.updatedAt
            activeIsInTargetStateFunc = (userDevice: UserDevice) => userDevice.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.userDevice)
          ) {
            const queryListVariables = clientOptions && clientOptions.updateList && listFilter
              ? apollo.getUserDevicesQueryVariables(listFilter, activeUserId)
              : undefined
            // console.log('api.loadUserDevice.load: resolving with info.', resp.data.userDevice)
            cache.updateUserDevice(
              resp.data.userDevice,
              queryListVariables,
              activeUserId,
              apolloClient,
            )
            resolve(resp.data.userDevice)
            return
          }

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadUserDevice.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadUserDevice.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadUserDevice
