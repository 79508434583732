import moment from 'moment-timezone'
import type { EventDate } from '../../../definitions'

const parseEventDate = (
  dateString: string | null | undefined,
  timezone?: string,
): EventDate | undefined => {
  if (!dateString) {
    return
  }

  const isIsoDateString = typeof dateString !== 'string' || dateString.length > 10 // 'YYYY-MM-DD'
  const dateMoment = timezone ? moment.tz(dateString, timezone) : moment(dateString)
  const curMonth = moment().get('month') + 1
  const curDay = moment().get('date')
  const curYear = moment().get('year')
  const month = isIsoDateString
    ? dateMoment.get('month') + 1
    : Number(dateString.slice(5, 7))
  const day = isIsoDateString
    ? dateMoment.get('date')
    : Number(dateString.slice(8, 10))

  let isBeforeToday = true

  if (month === curMonth) {
    isBeforeToday = day < curDay
  } else if (month > curMonth) {
    isBeforeToday = false
  }
  const year = isBeforeToday ? curYear + 1 : curYear
  const nextDate = new Date(year, month - 1, day, 0, 0, 0, 0)
  let daysUntil = moment(nextDate).diff(moment(), 'days') + 1

  if (daysUntil === 1) {
    // The event could be today, or tomorrow. If it's today, it started
    // at the begin of this day, so we'll expect a negative value for the hours.
    if (moment(nextDate).diff(moment(), 'hours') < 0) {
      daysUntil -= 1
    }
  }

  return {
    eventDate: dateString,
    nextDate: nextDate.toISOString(),
    month,
    day,
    year,
    isBeforeToday,
    daysUntil,
  }
}

export default parseEventDate
