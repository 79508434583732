import React, { useContext, useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'

import type { ShoppingCart, ShoppingCartListItem } from '../../lib/core/definitions'
import { useMimbleData } from '../MimbleDataContext/MimbleDataContext'
import type { ShoppingCartContextValue } from './definitions'
import type {
  ShoppingCartQueryData,
  ShoppingCartQueryVariables,
  ShoppingCartsQueryData,
  ShoppingCartsQueryVariables,
} from '../../services/apollo/definitions'
import { ShoppingCartStatus } from '../../lib/core/enums'
import { useGlobalCache } from '../GlobalCacheContext/GlobalCacheContext'
import apollo from '../../services/apollo'
import coreHelpers from '../../lib/core/helpers'
import helpers from './helpers'
import setShoppingCart from './setShoppingCart'

export const defaultContextValue = helpers.defaultContextValue

export const ShoppingCartContext = React.createContext<ShoppingCartContextValue>(helpers.defaultContextValue)

export function useShoppingCart (): ShoppingCartContextValue {
  return useContext(ShoppingCartContext)
}

const ShoppingCartProvider: React.FC = ({ children }) => {
  const apolloClient = useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const { getIsSignedIn } = useGlobalCache()

  const [isSavingShoppingCart, setIsSavingShoppingCart] = useState(false)
  const [savingError, setSavingError] = useState<string | undefined>()

  // -------------------------------------------------------------------------------------------------------------------
  // Apollo Hooks:
  const {
    data: shoppingCartData,
    error: shoppingCartLoadingError,
    loading: isLoadingShoppingCart,
    networkStatus: shoppingCartLoadingNetworkStatus,
    refetch: refetchShoppingCart,
  } = useQuery<ShoppingCartQueryData, ShoppingCartQueryVariables>(
    apollo.queries.shoppingCart(false), {
      variables: { userId: activeUserId as string },
      skip: !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      // onCompleted: (data) => {
      //   console.log('ShoppingCartContext: new active cart received.', { data })
      // },
      onError: (error) => {
        console.log('Error fetching active shopping cart', error)
      },
    },
  )
  const shoppingCart = shoppingCartData && shoppingCartData.shoppingCart

  const {
    data: pendingShoppingCartsData,
    error: pendingShoppingCartsLoadingError,
    loading: isLoadingPendingShoppingCarts,
    // networkStatus: shoppingCartLoadingNetworkStatus,
    refetch: refetchPendingShoppingCarts,
  } = useQuery<ShoppingCartsQueryData, ShoppingCartsQueryVariables>(
    apollo.queries.shoppingCarts, {
      variables: {
        filter: {
          userId: activeUserId as string,
          statuses: [
            ShoppingCartStatus.ORDER_PLACED,
            ShoppingCartStatus.CREATING_ORDER,
            ShoppingCartStatus.WAITING_FOR_PAYMENT,
          ],
        },
      },
      skip: !activeUserId || !getIsSignedIn(),
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      // onCompleted: (data) => {
      //   // console.log('>>>>>>ShoppingCartContext: new pending shopping cart list received.', { data })
      // },
      onError: (error) => {
        console.log('Error fetching active user', error)
      },
    },
  )
  const pendingShoppingCarts = pendingShoppingCartsData && pendingShoppingCartsData.shoppingCarts

  // -------------------------------------------------------------------------------------------------------------------
  // Helpers:
  const reloadShoppingCart = async (): Promise<ShoppingCart | undefined> => {
    try {
      const response = await refetchShoppingCart()
      return response.data.shoppingCart
    } catch (error) {
      console.error(error)
    }
  }

  const reloadPendingShoppingCarts = async (): Promise<ShoppingCartListItem[] | undefined> => {
    try {
      const response = await refetchPendingShoppingCarts()
      return response.data.shoppingCarts
    } catch (error) {
      console.error(error)
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Returning Value:
  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCart,
        pendingShoppingCarts,
        shoppingCartLoadingError,
        shoppingCartLoadingNetworkStatus,
        isLoadingShoppingCart,
        isLoadingPendingShoppingCarts,
        isSavingShoppingCart,
        reloadShoppingCart,
        reloadPendingShoppingCarts,

        setShoppingCart: (
          shoppingCartInput,
          isActiveShoppingCart,
          isInTargetStateFunc,
        ) => setShoppingCart(
          shoppingCartInput,
          shoppingCart,
          pendingShoppingCarts,
          isActiveShoppingCart,
          isInTargetStateFunc,
          activeUserId,
          setIsSavingShoppingCart,
          setSavingError,
          apolloClient,
        ),
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}

export default ShoppingCartProvider
