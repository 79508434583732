import { gql } from '@apollo/client'

export default gql`
  query Q($transactionId: String!) {
    transaction(transactionId: $transactionId) {
      id
      remoteId
      orderId
      purchaseId
      fromUserId
      toUserId
      fromWalletId
      toWalletId
      refundTransactionId
      transactionType
      status
      amount
      paid
      currency
      fundType
      exchangeRate
      exchangeRateUpdatedAt
      processor
      source
      blockchainAddress
      message
      requestMessage
      paymentRequest
#      metadata
      recordStatus
      createdAt
      updatedAt
      fromUser { id username fullName imageUrl isOrganization createdAt }
      toUser { id username fullName imageUrl isOrganization createdAt }
#      blockchainTransactions {
#        id
#        hash
#        fromUserId
#        toUserId
#        fromWalletId
#        toWalletId
#        transactionId
#        transactionType
#        fromBlockchainAddress
#        toBlockchainAddress
#        amount
#        currency
#        confirmations
#        approved
#        message
#        recordStatus
#        createdAt
#        updatedAt 
#      }
      order {
        id
        userId
        donationTargetId
        status
        source
        metadata
        adminNotes
        recordStatus
        createdAt
        updatedAt
        items {
          id
          userId
          offerId
          productId
          productOptionId
          purchaseType
          amount
          initialBalance
          balance
          currency
          fundType
          code
          hasBarcode
          barcodeFormat
          pin
          balanceUpdatedAt
          referenceUrl
          messageEn
          issuedAt
          expiresAt
          adminNotes
          status
          source
          metadata {
            amountPaid
            receivedTransfer {
              id
              fromUserId
              fromPurchaseId
              transferStatus
              recordStatus
            }
            sentTransfer {
              id
              toUserId
              toPurchaseId
              transferStatus
              recordStatus
            }
            latestError
            events {
              time
              type
              message
            }
          }
          recordStatus
          createdAt
          updatedAt
        }
      }
      reward {
        id
        userId
        transactionId
        forContactId
        forInvitationId
        forPurchaseId
        forPurchaseTransferId
        forTransactionId
        forWishId
        rewardType
        amount
        fundType
        currency
        occurrence
        invitationLevel
        metadata
        adminNotes
        createdBy
        recordStatus
        createdAt
        updatedAt
      }
    }
  }`
