import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

export interface LogEntry {
  level: 'trace' | 'debug' | 'info' | 'warn' | 'error';
  message: string;
  data: any;
  timestamp: number;
}

const MAX_HISTORY_LENGTH = 1000
const history: LogEntry[] = []

const log = (level: 'trace' | 'debug' | 'info' | 'warn' | 'error', message: string, data?: any) => {
  const logLevel = globalCacheData.getValue(GlobalCacheDataKey.LOG_LEVEL, true)

  let consoleFnc: ((message?: any, ...optionalParams: any[]) => void) | undefined
  if (level === 'trace' && logLevel === 'trace') {
    consoleFnc = console.debug
  } else if (level === 'debug' && (logLevel === 'trace' || logLevel === 'debug')) {
    consoleFnc = console.debug
  } else if (level === 'info' && (logLevel === 'trace' || logLevel === 'debug' || logLevel === 'info')) {
    consoleFnc = console.info
  } else if (level === 'warn' && (logLevel === 'trace' || logLevel === 'debug' || logLevel === 'info' || logLevel === 'warn')) {
    consoleFnc = console.warn
  } else if (level === 'error') {
    consoleFnc = console.error
  }

  if (consoleFnc) {
    if (data) {
      consoleFnc(message, data)
    } else {
      consoleFnc(message)
    }
    history.push({ level, message, data, timestamp: new Date().getTime() })
    if (history.length > MAX_HISTORY_LENGTH) {
      // We reached the maximum history length, so we need to remove an older entry
      if (history[0].level !== 'error') {
        // The oldest entry isn't an error, so we remove it:
        history.shift()
        return
      }
      for (let i = 0; i < MAX_HISTORY_LENGTH; i++) {
        if (history[i].level !== 'error') {
          history.splice(i, 1)
          return
        }
      }
      // All were errors, so we remove the oldest error:
      history.shift()
    }
  }
}

export default {
  init: (): void => { /* console.log('Initializing logger...') */ },
  history,

  trace: (message: string, data?: any): void => log('trace', message, data),
  debug: (message: string, data?: any): void => log('debug', message, data),
  info: (message: string, data?: any): void => log('info', message, data),
  warn: (message: string, data?: any): void => log('warn', message, data),
  error: (message: string, data?: any): void => log('error', message, data),
}
