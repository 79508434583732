import moment from 'moment-timezone'
import type { ApolloClient, MutationOptions } from '@apollo/client'
import { isPlatform } from '@ionic/react'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertUserDeviceMutationData,
  UpsertUserDeviceMutationVariables,
} from '../apollo/definitions'
import type {
  ApiQueryOptions,
  UserDevice,
  UserDeviceInput,
  UserDeviceListFilter,
} from '../../lib/core/definitions'
import apollo from '../apollo'
import appVersion from '../../version'
// import cache from './cache'
import firebase from '../firebase'
import loadUserDevice from './loadUserDevice'
import logger from '../logger'

const upsertUserDevice = (
  userDeviceInput: UserDeviceInput,
  isInTargetStateFunc: ((userDevice: UserDevice) => boolean) | 'watch-updated-at' | undefined,
  listFilter: UserDeviceListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<UserDevice | undefined> => (
  new Promise((resolve, reject) => {
    console.log('api.upsertUserDevice called.', JSON.stringify(userDeviceInput, undefined, 2))

    if (!apolloClient) {
      logger.error('api.upsertUserDevice: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    firebase.getPushNotificationToken().then((pushNotificationToken) => {
      console.log('api.upsertUserDevice: push notification token received.', pushNotificationToken)
      if (!userDeviceInput.appVersion) {
        userDeviceInput.appVersion = appVersion
      }

      if (!userDeviceInput.timezone) {
        userDeviceInput.timezone = moment.tz.guess()
      }

      if (!userDeviceInput.os) {
        if (isPlatform('android')) {
          userDeviceInput.os = 'android'
        } else if (isPlatform('ios')) {
          userDeviceInput.os = 'ios'
        } else {
          userDeviceInput.os = 'web'
        }
      }

      if (pushNotificationToken) {
        userDeviceInput.pushNotificationToken = pushNotificationToken
      }

      const queryParams: MutationOptions<UpsertUserDeviceMutationData, UpsertUserDeviceMutationVariables> = {
        mutation: apollo.mutations.upsertUserDevice,
        variables: { userDevice: userDeviceInput },
      }

      console.log('api.upsertUserDevice calling mutate.', JSON.stringify(userDeviceInput, undefined, 2))
      apolloClient.mutate<UpsertUserDeviceMutationData, UpsertUserDeviceMutationVariables>(queryParams)
        .then((resp) => {
          // console.log('api.upsertUserDevice: mutation succeeded.', resp)
          if (
            !resp ||
            !resp.data ||
            !resp.data.upsertUserDevice ||
            !resp.data.upsertUserDevice.id
          ) {
            logger.error('api.upsertUserDevice: mutation did not return expected data.', { resp })
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((userDevice: UserDevice) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            if (!userDeviceInput.id) {
              logger.error('api.upsertUserDevice: watch-updated-at should not be used on a new insert.')
            } else {
              const oldUpdatedAt = resp.data.upsertUserDevice.updatedAt
              activeIsInTargetStateFunc = (userDevice: UserDevice) => userDevice.updatedAt !== oldUpdatedAt
            }
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.upsertUserDevice)
          ) {
            // const queryListVariables = clientOptions && clientOptions.updateList
            //   ? apollo.getUserDevicesQueryVariables(listFilter, activeUserId)
            //   : undefined
            // if (clientOptions && clientOptions.updateList) {
            //   cache.updateUserDeviceListItem(
            //     resp.data.upsertUserDevice,
            //     queryListVariables,
            //     apolloClient,
            //   )
            // }
            resolve(resp.data.upsertUserDevice)
            return
          }

          const userDeviceId = resp.data.upsertUserDevice.id

          setTimeout(() => {
            // console.log('api.upsertUserDevice: calling loadUserDevice')
            loadUserDevice(
              userDeviceId,
              activeIsInTargetStateFunc,
              undefined,
              activeUserId,
              apolloClient,
              queryOptions,
              clientOptions,
            )
              .then((userDevice) => {
                // console.log('api.upsertUserDevice: resolving with userDevice', userDevice)
                resolve(userDevice)
              }, (error) => {
                logger.error('api.upsertUserDevice: loadUserDevice threw error.', { error })
                reject(error)
              })
          }, 1000)
        }, (error) => {
          logger.error('api.upsertUserDevice: error', { error })
          reject(error)
        })
    }, (error) => {
      console.log('api.upsertUserDevice: firebase.getPushNotificationToken returned error.', { error })
      reject(error)
    })
  })
)

export default upsertUserDevice
