import type { MouseEvent } from 'react'
import React, { useState } from 'react'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { refresh, ellipsisHorizontalSharp } from 'ionicons/icons'

import './styles.css'
import type { ContextMenuId, ContextMenuItemId, ContextMenuOptions } from '../ContextMenu/contextMenuDefs'
import type { User } from '../../lib/core/definitions'
import Avatar from '../Avatar/Avatar'
import ContextMenu from '../ContextMenu/ContextMenu'

interface Props {
  title: string;
  contextMenuId?: ContextMenuId;
  contextMenuOptions?: ContextMenuOptions;
  goBackUri?: string | boolean;
  userInfo?: User;
  isProcessing?: boolean;
  onContextMenu?: (id: ContextMenuItemId) => void;
  onOpenUserAccount?: () => void;
  onRefresh?: () => void;
}

const NavBar: React.FC<Props> = (props): JSX.Element => {
  const {
    title,
    contextMenuId,
    contextMenuOptions,
    goBackUri,
    userInfo,
    isProcessing,
    onContextMenu,
    onOpenUserAccount,
    onRefresh,
  } = props
  let contextMenu

  const [showContextMenu, setShowContextMenu] = useState(false)
  const [contextMenuClickEvent, setContextMenuClickEvent] = useState(undefined as MouseEvent<Element, MouseEvent> | undefined)

  const openContextMenu = (event: MouseEvent<Element, MouseEvent>): void => {
    event.persist()
    setShowContextMenu(true)
    setContextMenuClickEvent(event)
  }

  const closeContextMenu = (): void => {
    setShowContextMenu(false)
    setContextMenuClickEvent(undefined)
  }

  const onContextMenuLocal = (id: ContextMenuItemId): void => {
    if (onContextMenu) {
      onContextMenu(id)
    }
    setShowContextMenu(false)
  }

  const handleOpenUserAccount = (): void => {
    if (onOpenUserAccount) {
      onOpenUserAccount()
    }
  }

  const startSlotContent = (
    <IonButtons slot='start' className='withSmallLeftMargin'>
      <IonBackButton
        defaultHref={goBackUri as string}
        text=''
      />
    </IonButtons>
  )

  let endSlotContent
  if (isProcessing) {
    endSlotContent = (
      <IonButtons slot='end'>
        <IonSpinner className='withStandardRightMargin' />
      </IonButtons>
    )
  }

  if (userInfo) {
    const spinner = isProcessing ? <IonSpinner className='withStandardRightMargin' /> : undefined
    endSlotContent = (
      // <div slot='end' className='withStandardRightMargin' onClick={handleOpenUserAccount}>
      <IonButtons slot='end' className='withStandardRightMargin' onClick={handleOpenUserAccount}>
        {spinner}
        <Avatar
          user={userInfo}
          className='withPointerCursor'
        />
      </IonButtons>
    )
  } else if (onRefresh) {
    endSlotContent = (
      <IonButtons slot='end'>
        <IonButton onClick={onRefresh}>
          <IonIcon icon={refresh} class='refreshIcon' />
        </IonButton>
      </IonButtons>
    )
  } else if (contextMenuId && !isProcessing) {
    endSlotContent = (
      <IonButtons slot='end'>
        <IonButton icon-only onClick={openContextMenu as unknown as any}>
          <IonIcon icon={ellipsisHorizontalSharp} class='moreIcon' />
        </IonButton>
      </IonButtons>
    )
    contextMenu = (
      <ContextMenu
        contextMenuId={contextMenuId}
        options={contextMenuOptions}
        isOpen={showContextMenu}
        openMenuEvent={contextMenuClickEvent}
        onClickItem={onContextMenuLocal}
        onClose={closeContextMenu}
      />
    )
  }

  return (
    <IonHeader>
      <IonToolbar>
        {startSlotContent}
        <IonTitle class='toolbarTitle'>
          {title}
        </IonTitle>
        {endSlotContent}
        {contextMenu}
      </IonToolbar>
    </IonHeader>
  )
}

export default NavBar
