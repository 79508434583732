import type { ShoppingCart } from '../../../definitions'
import { FundType, OrgProductType, TokenName, TransactionProcessor } from '../../../enums'

const isValid = (
  shoppingCart: ShoppingCart,
): boolean => {
  if (
    !shoppingCart ||
    !Array.isArray(shoppingCart.items) ||
    shoppingCart.items.length < 1
  ) {
    return false
  }
  for (let i = 0; i < shoppingCart.items.length; i++) {
    const item = shoppingCart.items[i]
    if (
      item.productType === OrgProductType.MIMBLE_TOKEN &&
      shoppingCart.paymentProcessor === TransactionProcessor.INTERNAL &&
      shoppingCart.paymentFundType === FundType.TOKEN &&
      shoppingCart.paymentCurrency === TokenName.MIMBLE_TOKEN
    ) {
      return false
    }
  }
  return true
}

export default isValid
