import React from 'react'

import './styles.css'
import { IonText } from '@ionic/react'
import type { PurchaseTransfer, User } from '../../lib/core/definitions'

type Props = {
  purchaseTransfer?: PurchaseTransfer;
  fromUserId?: string | null;
  senderName?: string;
  user?: User;
}

const SenderIsActiveUserContent: React.FC<Props> = ({
  purchaseTransfer,
  fromUserId,
  senderName,
  user,
}): JSX.Element => {
  return (
    <IonText>
      This page exists for the person you sent the gift card to. If they don&apos;t know how to access it,
      please direct them to the URL of this page.
    </IonText>
  )
}

export default SenderIsActiveUserContent
