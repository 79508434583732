import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import './styles.css'
import type { PurchaseListItem } from '../../../../lib/core/definitions'
import type { PurchaseStatus } from '../../../../lib/core/enums'
import coreHelpers from '../../../../lib/core/helpers'
import GiftCard from '../../../../components/GiftCard/GiftCard'
import pageHelpers from '../../../../helpers/pageHelpers'
import { checkmarkOutline } from 'ionicons/icons'

interface Props {
  purchase: PurchaseListItem;
  contrastPennies?: boolean;
  isSelected?: boolean;
  onSelectPurchase: (purchaseId: string) => void;
}

const GiftListRow: React.FC<Props> = (props): JSX.Element | null => {
  const {
    purchase,
    contrastPennies,
    isSelected,
    onSelectPurchase,
  } = props

  // ===================================================================================================================
  // Helpers:
  const purchaseId = purchase.id as string
  const formattedBalance = coreHelpers.ui.formatAmount(purchase.balance, purchase.fundType, purchase.currency, false, 2)
  const formattedBalanceParts = formattedBalance.split('.') // todo: use locale, might not be a period
  const penniesClass = contrastPennies ? 'list-itemPennies' : 'list-itemAmount'
  const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromPurchaseListItem(purchase)

  // ===================================================================================================================
  // Event Handlers:
  const onClickPurchase = (purchaseId: string): void => {
    if (onSelectPurchase) {
      onSelectPurchase(purchaseId)
    }
  }

  const handleOpenPurchase = (purchaseId: string) => {
    if (onSelectPurchase) {
      onSelectPurchase(purchaseId)
    }
  }

  if (!purchase) {
    return null
  }

  // ===================================================================================================================
  // Rendering:
  const selectSection = isSelected
    ? <IonIcon icon={checkmarkOutline} color='success' />
    : (
        <IonButton
          size='small'
          onClick={() => onSelectPurchase(purchaseId)}
        >
          Select
        </IonButton>
      )
  const penniesPart = formattedBalanceParts[1]
    ? <span className={penniesClass}>.{formattedBalanceParts[1]}</span>
    : null
  const wrapperClass = isSelected ? 'list-item-wrapper-selected' : 'list-item-wrapper'
  return (
    <div className={wrapperClass} onClick={(): void => onClickPurchase(purchaseId)}>
      <div className='list-item-col0'>
        <GiftCard
          purchaseId={purchaseId}
          purchaseStatus={purchase.status as PurchaseStatus}
          backgroundImageUri={giftCardBackgroundUri}
          merchantLogoUri={merchantLogoUri}
          onAcceptGift={handleOpenPurchase}
          small
        />
      </div>
      <div className='list-item-col1'>
        <div className='list-item-col1-row0'>
          <div>
            <div className='list-item-balance'>
              <span className='list-itemAmount'>{formattedBalanceParts[0]}</span>
              {penniesPart}
            </div>
          </div>
          <div className='list-item-merchant-name-wrapper'>
            <div className='list-item-merchant-name'>{purchase.merchantName}</div>
          </div>
        </div>
        <div className='withRightTextAlign'>
          {selectSection}
        </div>
      </div>
    </div>
  )
}

export default GiftListRow
