const compareId = (id1: string | null | undefined, id2: string | null | undefined): boolean => {
  if (!id1 && !id2) {
    return true
  }
  if ((id1 && !id2) || (!id1 && id2)) {
    return false
  }
  return (
    id1 === id2 ||
    (id1 && id1.replace(/-/g, '')) === (id2 && id2.replace(/-/g, ''))
  )
}

export default compareId
