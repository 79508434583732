import { FundType } from '../../enums'
import type { Currency } from '../../definitions'
import cryptoCurrencyHelpers from '../cryptoCurrency'

const getAmountDisplayFactor = (
  fundType: FundType | null | undefined,
  currency: Currency | null | undefined,
): number => {
  if (fundType === FundType.FIAT || (fundType === FundType.TOKEN)) {
    return 1000
  }
  if (fundType === FundType.CRYPTO /* && currency === CryptoCurrency.BITCOIN */) {
    return cryptoCurrencyHelpers.bitcoinToSatoshiFactor
  }
  return 1
}

export default getAmountDisplayFactor
