import React, { useContext, useState } from 'react'
import { create } from 'ionicons/icons'
import { IonContent, IonIcon, IonImg, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import './styles.css'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import { CdnAssetType } from '../../../services/cdn/enums'
import type { ProductInput } from '../../../lib/core/definitions'
import { UiMessage } from '../../../lib/core/enums'
import type { ProductQueryData, ProductQueryVariables } from '../../../services/apollo/definitions'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import cdn from '../../../services/cdn'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'
import ProductForm from './ProductForm'

const appPageId = AppPage.AdminEditProductPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  productId: string
}

const EditProductPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { productId } = useParams<keyof Params>() as unknown as Params
  const goBackUri = productId ? `${AppRoute.ADMIN_PRODUCT}/productId` : AppRoute.ADMIN_PRODUCTS

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const { isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [isUpsertingProduct, setIsUpsertingProduct] = useState(false)

  // ===================================================================================================================
  // Apollo Hooks:
  // -------------------------------------------------------------------------------------------------------------------
  // Loading product:
  const {
    data: productLoadedData,
    loading: isLoadingProduct,
    refetch: reloadProduct,
    client: apolloClient,
  } = useQuery<ProductQueryData, ProductQueryVariables>(
    apollo.queries.product({
      includeMerchant: true,
      includeProductOptions: true,
      includeStoredProducts: true,
      includeTags: true,
    }), {
      variables: { productId: productId as string },
      skip: !productId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: ProductQueryData) => {
        const returnedProduct = data ? data.product : undefined
        if (returnedProduct === null) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
      onError: (error) => {
        console.log(error)
        pageHelpers.checkForUnauthorized(error, navigate)
        pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_CONNECTING)
      },
    },
  )
  const product = productLoadedData ? productLoadedData.product : undefined
  const merchantId = product && product.merchant ? product.merchant.id : ''
  const merchantName = product && product.merchant ? product.merchant.name : ''
  const merchantLargeLogoImageSource = product && product.merchant ? product.merchant.largeLogoImageSource : ''
  // const merchantListed = product && product.merchant ? product.merchant.listed : false
  // const merchantUrl = product && product.merchant ? product.merchant.url : ''
  const isProcessing = isLoadingActiveUser || isLoadingProduct || isUpsertingProduct

  // ===================================================================================================================
  // Helpers:
  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const upsertProduct = (productInput: ProductInput) => {
    if (!productInput) {
      return
    }
    setIsUpsertingProduct(true)
    api.upsertProduct(
      productInput,
      undefined,
      undefined,
      apolloClient,
      undefined,
      undefined,
    ).then(() => {
      setIsUpsertingProduct(false)
      setToastMessage('Successfully updated this product.')
      setShowToast(true)
      navigate(-1)
      // if (product && product.id && !productId) {
      //   navigate(`/admin/data-import/${product.id}`)
      // }
      // setProduct(loadedProduct)
    }, (error) => {
      console.error(error)
      setIsUpsertingProduct(false)
      pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_UPDATING_PRODUCT)
    })
  }

  // ===================================================================================================================
  // Event Handlers:
  const onEditMerchant = (): void => {
    navigate(`/admin/edit-merchant/${merchantId}`)
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !productId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadProduct().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onGoBack = (): void => {
    navigate(-1)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const pageTitle = product ? 'Edit Product' : 'Add Product'

  let merchantLogo
  if (merchantLargeLogoImageSource) {
    const merchantLogoImageUri = cdn.getUri(CdnAssetType.MERCHANT_LOGO, merchantLargeLogoImageSource) || ''
    merchantLogo = (
      <IonImg
        src={merchantLogoImageUri}
        className='with4RemWidth withDoubleRightMargin'
      />
    )
  }

  return (
    <IonPage className='app-page-admin edit-product-page'>
      <NavBar
        title={pageTitle}
        goBackUri={goBackUri}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <div className='rowWithCenterAlignedItems withDoubleBottomMargin' onClick={onEditMerchant}>
          {merchantLogo}
          <div className='sectionCaption'>Product For Brand &quot;{merchantName}&quot;</div>
          <IonIcon className='inlineEditIcon' icon={create} />
        </div>
        <ProductForm
          product={productId ? product : null}
          onGoBack={onGoBack}
          onSave={upsertProduct}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default EditProductPage
