import React from 'react'

import type { Purchase } from '../../../lib/core/definitions'
import helpers from '../../../lib/core/helpers'
import BalanceHistoryChart from '../BalanceHistoryChart'
import HistoryEventList from '../HistoryEventList/HistoryEventList'

type Props = {
  purchase: Purchase;
};

const BalanceHistorySection: React.FC<Props> = ({
  purchase,
}: Props): JSX.Element | null => {
  if (
    !purchase ||
    !Array.isArray(purchase.updateHistory) ||
    purchase.updateHistory.length < 1
  ) {
    return null
  }

  const formattedInitialBalance = helpers.ui.formatAmount(
    purchase.initialBalance,
    purchase.fundType,
    purchase.currency,
    true,
  )
  const balanceChangeEvents = purchase.updateHistory.filter(e => e.balance !== null)
  if (balanceChangeEvents.length < 1) {
    return null
  }

  let chart
  // todo: We upgraded graph.js and now the chart is broken. Removed, until it is fixed again.
  // Issue #683 - https://github.com/mimble-inc/mimblemod/issues/683
  // if (balanceChangeEvents.length > 1) {
  //   chart = (
  //     <div className='withStandardBottomMargin'>
  //       <BalanceHistoryChart purchase={purchase} />
  //     </div>
  //   )
  // }

  return (
    <div className='withStandardPadding'>
      <div className='sectionCaption'>Balance History</div>
      <div>
        <span className='lightText'>Initial Balance:</span> {formattedInitialBalance}
      </div>
      {chart}
      <HistoryEventList
        events={balanceChangeEvents}
        fundType={purchase.fundType}
        currency={purchase.currency}
      />
    </div>
  )
}

export default BalanceHistorySection
