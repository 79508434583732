import React, { useContext, useEffect, useState } from 'react'
import type { RefresherEventDetail } from '@ionic/core'
import { IonPage, IonToast, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

// import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import { ChatAttachmentType, PurchaseStatus } from '../../lib/core/enums'
import { ChatLayout } from '../../components/Chat/enums'
import type { ChatQueryData, ChatQueryVariables } from '../../services/apollo/definitions'
import type {
  ChatAttachmentInfo,
  ChatAttachmentPurchaseTransferInfo,
  ChatAttachmentRewardInfo,
  ChatAttachmentTransactionInfo,
  ChatMessage,
  User,
} from '../../lib/core/definitions'
import { TopNavTabId } from '../../components/TopNavBar/enums'
import { useActivePageData } from '../../contexts/ActivePageDataContext'
import type { StartArgs as GiftFlowStartArgs } from '../../contexts/GiftFlowContext'
import { useGiftFlow } from '../../contexts/GiftFlowContext'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../services/api'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import ChatComponent from '../../components/Chat/Chat'
import ContactHeader from '../../components/ContactHeader/ContactHeader'
import logger from '../../services/logger'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'

const CHAT_MESSAGE_BATCH_SIZE = 50
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  chatId: string
  contactUserId: string
}

const ChatPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  const {
    chatId: chatIdFromParams,
    contactUserId: contactUserIdFromParams,
  } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // Helpers:
  let chatId = chatIdFromParams && chatIdFromParams !== '-'
    ? chatIdFromParams
    : undefined
  let contactUserId =
    contactUserIdFromParams && contactUserIdFromParams !== '-'
      ? contactUserIdFromParams
      : undefined
  const isAdminOrgChat = (
    !!location &&
    !!location.pathname &&
    !!location.pathname.match(/^\/admin\/mimble-team-chat\//)
  )
  const appPageId = isAdminOrgChat ? AppPage.AdminOrgChatPage : AppPage.ChatPage
  const appPageDef = pageHelpers.appPageDefs[appPageId]
  const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // ===================================================================================================================
  // State:
  const { pageWillEnter, pageWillLeave, setActivePageData } = useActivePageData()
  const pageMessages = useContext(PageMessagesContext)
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id
  const { start: startGiftFlow } = useGiftFlow()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [messagesOffset, setMessagesOffset] = useState(0)
  const [markUnreadMessages, setMarkUnreadMessages] = useState(false)

  let contactUser: Partial<User> | null | undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = false

  // ===================================================================================================================
  // Apollo Hooks:
  const chatQueryVariables: ChatQueryVariables = {
    viewerUserId: activeUserId || '',
    messagesOffset: messagesOffset || 0,
    messagesLimit: CHAT_MESSAGE_BATCH_SIZE,
  }
  if (chatId || contactUserId) {
    if (chatId) {
      chatQueryVariables.chatId = chatId
    } else if (activeUserId && contactUserId) {
      if (isAdminOrgChat) {
        chatQueryVariables.userIds = ['mimble-team', contactUserId]
      } else {
        chatQueryVariables.userIds = [activeUserId, contactUserId]
      }
    }
  }

  const {
    data: chatLoadedData,
    loading: isLoadingChat,
    refetch: reloadChat,
    client: apolloClient,
  } = useQuery<ChatQueryData, ChatQueryVariables>(
    apollo.queries.chat, {
      variables: chatQueryVariables,
      skip: !activeUserId || (!chatId && !contactUserId),
      onCompleted: (data) => {
        if (data && data.chat) {
          // if (onChatLoaded) {
          //   onChatLoaded(data.chat)
          // }
          // if (!chatId) {
          //   setChatId(data.chat.id as string)
          // }
          // setTimeout(() => {
          //   // console.log('ChatPage: calling handleUnreadChatMessages')
          //   // todo: This might be a redundant call, if the previous page loaded this chat earlier.
          //   api.helpers.handleUnreadChatMessages(
          //     data.chat,
          //     activeUserId as string,
          //     inbox,
          //     apolloClient,
          //   )
          // }, 200)
          // scrollToBottom()
          if (refreshEvent) {
            refreshEvent.detail.complete()
            refreshEvent = undefined
          }
          if (data.chat) {
            contactUser = data.chat.fromUserId === activeUserId ? data.chat.toUser : data.chat.fromUser
            if (contactUser) {
              setActivePageData(appPageId, {
                contactUser,
              })
            }
          }
        }
      },
    },
  )
  const { chat } = chatLoadedData || {}
  if (chat) {
    if (!chatId) {
      chatId = chat.id as string
    }
    contactUser = chat.fromUserId === activeUserId ? chat.toUser : chat.fromUser
    if (contactUser && !contactUserId) {
      contactUserId = contactUser.id as string
    }
  }

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  useIonViewWillEnter(() => {
    console.log('ChatPage.useIonViewWillEnter called.', { chat })
    setMarkUnreadMessages(true)
    if (pageWillEnter) {
      pageWillEnter(appPageId, {
        contactUser: contactUserId && contactUserId !== '-' ? { id: contactUserId } : undefined,
      })
    }
  })

  useIonViewWillLeave(() => {
    setMarkUnreadMessages(false)
    if (pageWillLeave) {
      pageWillLeave(appPageId)
    }
  })

  useEffect(() => {
    setMessagesOffset(0)
  }, [chatId])

  // useEffect(() => {
  //   console.log('ChatPage.useEffect[chatLoadedData]: called.', { activePage })
  //   // Marking messages as received/read:
  // }, [chatLoadedData])

  // ===================================================================================================================
  // Event Handlers:
  const onRefreshPage = (): void => {
    pageMessages && pageMessages.clear()
  }

  const onOpenAttachment = (
    chatMessage: ChatMessage,
    chatAttachmentInfo: ChatAttachmentInfo,
  ): void => {
    // console.log('ChatPage.onOpenAttachment called.', { chatMessage, chatAttachmentInfo })
    if (!activeUser) {
      return
    }

    if (chatAttachmentInfo.attachmentType === ChatAttachmentType.PURCHASE) {
      const attachment = chatAttachmentInfo as ChatAttachmentPurchaseTransferInfo
      const purchaseId = activeUser.id === chatMessage.fromUserId
        ? attachment.fromPurchaseId
        : attachment.toPurchaseId
      if (purchaseId) {
        api.loadPurchase(
          purchaseId,
          undefined,
          undefined,
          activeUserId as string,
          apolloClient,
          undefined,
          { skipCache: true },
        ).then((purchase) => {
          if (purchase && purchase.status === PurchaseStatus.RECEIVED) {
            navigate(`${AppRoute.GIFT_RECEIVED}/tp:${purchaseId}`)
          } else {
            navigate(`${AppRoute.GIFT_CARD}/${purchaseId}`)
          }
        }, (error) => {
          console.error(error)
        })
        return
      }
      if (activeUser.id === chatMessage.fromUserId) {
        // The sender is opening the transfer of the card they sent:
        navigate(`${AppRoute.GIFT_CARD}/${attachment.fromPurchaseId}`)
        return
      }
      // The recipient is opening a card they received:
      navigate(`${AppRoute.GIFT_CARD}/${attachment.toPurchaseId}`)
      return
    }

    if (chatAttachmentInfo.attachmentType === ChatAttachmentType.REWARD) {
      const attachment = chatAttachmentInfo as ChatAttachmentRewardInfo
      navigate(`${AppRoute.REWARD}/${attachment.id}`)
    }

    if (chatAttachmentInfo.attachmentType === ChatAttachmentType.TRANSACTION) {
      const attachment = chatAttachmentInfo as ChatAttachmentTransactionInfo
      navigate(`${AppRoute.TRANSACTION}/${attachment.id}`)
    }
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  const onGetPreviousMessagesBatch = (): void => {
    setMessagesOffset(messagesOffset + CHAT_MESSAGE_BATCH_SIZE - 10)
  }

  const onOpenContact = (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    tabId: TopNavTabId,
  ): void => {
    // console.log('ChatPage.onOpenContact called.', { contactId, userId, tabId })
    pageHelpers.openContact({
      activeUserId,
      contactId,
      contactUserId,
      tabId,
      apolloClient,
      navigate,
    })
  }

  const onNewGift = (): void => {
    if (!contactUserId) {
      return
    }
    const args: GiftFlowStartArgs | undefined = (
      contactUserId &&
      contactUser &&
      contactUser.username !== 'mimble'
    )
      ? { toUserId: contactUserId }
      : undefined
    startGiftFlow(args)
    navigate(AppRoute.SEND_GIFT)
  }

  const handleOpenContact = (
    contactId: string | null | undefined,
    userId: string | null | undefined,
    tabId: TopNavTabId,
  ) => {
    if (onOpenContact && (contactId || userId)) {
      onOpenContact(contactId, userId, tabId)
    }
  }

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  return (
    <IonPage className='app-page-public chat-page'>
      <NavBar
        title='Chat'
        goBackUri={AppRoute.CONTACTS}
        userInfo={activeUser}
        onRefresh={onRefreshPage}
        isProcessing={isProcessing}
        onOpenUserAccount={onOpenUserAccount}
      />
      <PageMessages />
      <ContactHeader
        contact={chat && chat.contact}
        user={contactUser}
        topNavTabId={TopNavTabId.CONTACT_MESSAGES}
        onOpenContact={handleOpenContact}
        showUiMessage={showUiMessage}
      />
      <ChatComponent
        chat={chat}
        layout={ChatLayout.FULL_PAGE}
        isAdminOrgChat={isAdminOrgChat}
        isOnActivePage={isActivePage}
        isLoading={isLoadingChat}
        onNewGift={onNewGift}
        // onGetPreviousMessagesBatch={onGetPreviousMessagesBatch}
        onOpenContact={onOpenContact}
        onOpenAttachment={onOpenAttachment}
      />
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default ChatPage
