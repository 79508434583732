import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, MemberMessageTemplateListFilter, MemberMessageTemplate } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  MemberMessageTemplateQueryData,
  MemberMessageTemplateQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadMemberMessageTemplate = (
  memberMessageTemplateId: string,
  isInTargetStateFunc: ((memberMessageTemplate: MemberMessageTemplate) => boolean) | 'watch-updated-at' | undefined,
  listFilter: MemberMessageTemplateListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<MemberMessageTemplate | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadMemberMessageTemplate called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadMemberMessageTemplate: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<MemberMessageTemplateQueryVariables> = {
      query: apollo.queries.memberMessageTemplate,
      variables: { memberMessageTemplateId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<MemberMessageTemplateQueryData, MemberMessageTemplateQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.memberMessageTemplate ||
            !resp.data.memberMessageTemplate.id
          ) {
            logger.error('api.loadMemberMessageTemplate.load: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((memberMessageTemplate: MemberMessageTemplate) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.memberMessageTemplate.updatedAt
            activeIsInTargetStateFunc = (memberMessageTemplate: MemberMessageTemplate) => memberMessageTemplate.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.memberMessageTemplate)
          ) {
            // const queryListVariables = clientOptions && clientOptions.updateList
            //   ? apollo.getMemberMessageTemplatesQueryVariables(listFilter)
            //   : undefined
            //   helpers.updateMemberMessageTemplate(
            //     resp.data.memberMessageTemplate,
            //     options.queryListVariables as MemberMessageTemplatesQueryVariables,
            //     apolloClient,
            //   )

            // console.log('api.loadMemberMessageTemplate.load: resolving with info.', resp.data.memberMessageTemplate)
            resolve(resp.data.memberMessageTemplate)
            return
          }

          // console.log('api.loadMemberMessageTemplate.load: memberMessageTemplate not in target state.',
          //   { order: resp.data.order })
          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadMemberMessageTemplate.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadMemberMessageTemplate.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadMemberMessageTemplate
