import moment from 'moment'
import React, { useState } from 'react'

import './styles.css'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import pageHelpers from '../../helpers/pageHelpers'

let guessedTimezone: string | undefined
let guessedTimezoneWithoutGroup: string | undefined
let guessedGroup: string | undefined

const setGuessedTimezone = (): void => {
  guessedTimezone = moment.tz.guess()
  if (!guessedTimezone) {
    return
  }
  const parts = guessedTimezone.split('/')
  if (parts.length > 1) {
    guessedGroup = parts[0]
    parts.shift()
    guessedTimezoneWithoutGroup = parts.join('/')
  }
}

const groupOptions = pageHelpers.timezones.groupNames
  .map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)

if (!guessedTimezone) {
  setGuessedTimezone()
}

type Props = {
  value: string | undefined;
  scope: 'selected' | 'all';
  validationError?: string;
  className?: string;
  onChange: (value: string) => void;
}
const TimezoneSelect: React.FC<Props> = (props): JSX.Element | null => {
  const {
    value,
    scope,
    className,
    onChange,
  } = props

  // ===================================================================================================================
  // State:
  const [group, setGroup] = useState(guessedGroup || '')
  const [timezone, setTimezone] = useState(guessedTimezoneWithoutGroup || '')

  // ===================================================================================================================
  // Event Handler:
  const onChangeGroup = (event: any): void => {
    setTimezone('')
    setGroup(event.target.value || '')
  }

  const onChangeSimpleTimezone = (event: any): void => {
    onChange(event.target.value || '')
  }

  const onChangeAdvancedTimezone = (event: any): void => {
    setTimezone(event.target.value || '')
    if (event.target.value) {
      onChange(`${group}/${event.target.value}`)
    }
  }

  // ===================================================================================================================
  // Rendering:
  if (scope === 'selected') {
    const options = pageHelpers.timezones.simpleTimezones.map(stz => (
      <MenuItem key={stz.value} value={stz.value}>{stz.label}</MenuItem>
    ))
    return (
      <div className={`timezone-select ${className || ''}`}>
        <FormControl
          variant='outlined'
          fullWidth
        >
          <InputLabel id='select-time-label'>Timezone</InputLabel>
          <Select
            labelId='select-time-label'
            id='select-time'
            value={value || guessedTimezone}
            onChange={onChangeSimpleTimezone}
            label='Timezone'
          >
            {options}
            {/* <MenuItem key='more' value='more'>More...</MenuItem> */}
          </Select>
        </FormControl>
      </div>
    )
  }

  let displayGroup = group
  let displayTimezone = timezone

  if (value) {
    const parts = value.split('/')
    if (parts.length > 1) {
      displayGroup = parts[0]
      parts.shift()
      displayTimezone = parts.join('/')
    }
  }
  // console.log('TimezoneSelect.render called.', {
  //   value,
  //   group,
  //   timezone,
  //   displayGroup,
  //   displayTimezone,
  //   groupOptions,
  //   // @ts-ignore
  //   timezones: displayGroup ? pageHelpers.timezones.groups[displayGroup] : [],
  // })

  const timezoneOptions = displayGroup
    // @ts-ignore
    ? pageHelpers.timezones.groups[displayGroup].map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)
    : [] // pageHelpers.timezones.all.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)

  return (
    <div className={`timezone-select ${className || ''}`}>
      <FormControl
        variant='outlined'
      >
        <InputLabel id='select-tzg-label'>Time Zone</InputLabel>
        <Select
          labelId='select-tzg-label'
          id='select-tzg'
          value={displayGroup}
          onChange={onChangeGroup}
          label='Time Zone'
        >
          {groupOptions}
        </Select>
      </FormControl>
      <div className='withStandardLeftMargin'>
        <FormControl
          variant='outlined'
        >
          {/* <InputLabel id='select-tz-label'>Time Zone 2</InputLabel> */}
          <Select
            labelId='select-tz-label'
            id='select-tz'
            value={displayTimezone}
            onChange={onChangeAdvancedTimezone}
          >
            {timezoneOptions}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default TimezoneSelect
