import React, { useRef, useState } from 'react'
import { IonButton, IonIcon, IonSlide, IonSlides } from '@ionic/react'
import {
  chevronForward,
  returnUpBack,
  returnUpForward,
} from 'ionicons/icons'

import type { PurchaseListItem } from '../../../../lib/core/definitions'
import type { PurchaseListScope } from '../../../../lib/core/enums'
import coreHelpers from '../../../../lib/core/helpers/'
import GiftCard from '../../../../components/GiftCard/GiftCard'
import pageHelpers from '../../../../helpers/pageHelpers'

type Props = {
  purchases: PurchaseListItem[];
  listScope: PurchaseListScope;
  caption: string;
  onOpenPurchase: (id: string) => void;
  onOpenDigitalWallet: (scope: PurchaseListScope) => void;
  actionSection?: JSX.Element;
};

const GiftCardsDashboardWidget: React.FC<Props> = (props): JSX.Element | null => {
  const {
    purchases,
    listScope,
    caption,
    onOpenPurchase,
    onOpenDigitalWallet,
    actionSection,
  } = props

  // ===================================================================================================================
  // State:
  const sliderRef = useRef<HTMLIonSlidesElement>(null)
  const [slideCnt, setSlideCnt] = useState(0)

  // ===================================================================================================================
  // Helpers:
  if (!Array.isArray(purchases) || purchases.length < 1) {
    return null
  }

  // It appears the Slider component has a bug that makes it to crash when it tries to render
  // fewer slides than on the previous render. This hack fixes that.
  if (slideCnt > 0 && purchases.length < slideCnt) {
    setTimeout(() => setSlideCnt(0), 100)
    return null
  } else {
    setTimeout(() => setSlideCnt(purchases.length), 100)
  }

  // ===================================================================================================================
  // Event Handlers:
  const goPrev = async (): Promise<void> => {
    if (sliderRef.current) {
      const index = await sliderRef.current.getActiveIndex()
      if (index < 1) {
        await sliderRef.current.slideTo(purchases.length - 1)
        return
      }
      await sliderRef.current.slidePrev()
    }
  }

  const goNext = async (): Promise<void> => {
    if (sliderRef.current) {
      const index = await sliderRef.current.getActiveIndex()
      if (index > purchases.length - 2) {
        await sliderRef.current.slideTo(0)
        return
      }
      await sliderRef.current.slideNext()
    }
  }

  const onClickOpenDigitalWalletButton = (): void => {
    onOpenDigitalWallet(listScope)
  }

  // ===================================================================================================================
  // Rendering:
  const cards = purchases.map((purchase) => {
    const formattedBalance = coreHelpers.ui.formatAmount(
      purchase.balance,
      purchase.fundType,
      purchase.currency,
      true,
    )
    const {
      giftCardBackgroundUri,
      merchantLogoUri,
    } = pageHelpers.getPurchaseCdnUrisFromPurchaseListItem(purchase)

    const onOpenThisPurchase = (): void => {
      onOpenPurchase(purchase.id)
    }

    return (
      <IonSlide
        className='slide'
        key={`${listScope}-${purchase.id}`}
      >
        <div className='slide-content' onClick={onOpenThisPurchase}>
          <div>
            <GiftCard
              purchaseId={purchase.id as string}
              backgroundImageUri={giftCardBackgroundUri}
              merchantLogoUri={merchantLogoUri}
              small
            />
          </div>
          <div className='balance'>
            {formattedBalance}
          </div>
        </div>
      </IonSlide>
    )
  })

  const slideOptions = {
    speed: 400,
    grabCursor: true,
  }

  return (
    <div className='dashboard-widget gift-cards'>
      <div className='header' onClick={onClickOpenDigitalWalletButton}>
        <span className='text'>{caption}</span>
        <IonButton
          size='small'
          color='medium'
          fill='clear'
        >
          <IonIcon icon={chevronForward} />
        </IonButton>
      </div>

      <div className='gift-card-row'>
        <div className='nav-col nav-col-left'>
          <IonButton
            size='large'
            fill='clear'
            onClick={goPrev}
            color='medium'
          >
            <IonIcon icon={returnUpBack} />
          </IonButton>
        </div>
        <div className='slides-col'>
          <IonSlides
            ref={sliderRef}
            pager options={slideOptions}
          >
            {cards}
          </IonSlides>
        </div>
        <div className='nav-col nav-col-right'>
          <IonButton
            size='large'
            fill='clear'
            onClick={goNext}
            color='medium'
          >
            <IonIcon icon={returnUpForward} />
          </IonButton>
        </div>
      </div>
      {actionSection}
    </div>
  )
}

export default GiftCardsDashboardWidget
