import type { ApolloClient, MutationOptions } from '@apollo/client'

import type {
  ApiQueryOptions,
  Purchase,
  PurchaseInput,
  PurchaseListFilter,
} from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertPurchaseMutationData,
  UpsertPurchaseMutationVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import cache from './cache'
import loadPurchase from './loadPurchase'
import logger from '../logger'

const upsertPurchase = (
  purchaseInput: PurchaseInput,
  isInTargetStateFunc: ((purchase: Purchase) => boolean) | 'watch-updated-at' | undefined,
  listFilter: PurchaseListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Purchase | undefined> => (
  // console.log('api.upsertPurchase called.', { purchaseInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertPurchase: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertPurchaseMutationData, UpsertPurchaseMutationVariables> = {
      mutation: apollo.mutations.upsertPurchase,
      variables: { purchase: purchaseInput },
    }

    apolloClient.mutate<UpsertPurchaseMutationData, UpsertPurchaseMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertPurchase: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertPurchase ||
          !resp.data.upsertPurchase.id
        ) {
          logger.error('api.upsertPurchase: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((purchase: Purchase) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!purchaseInput.id) {
            logger.error('api.upsertPurchase: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertPurchase.updatedAt
            activeIsInTargetStateFunc = (purchase: Purchase) => purchase.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertPurchase)
        ) {
          const queryListVariables = clientOptions && clientOptions.updateList
            ? apollo.getPurchasesQueryVariables(listFilter, activeUserId as string)
            : undefined
          cache.updatePurchase(
            resp.data.upsertPurchase,
            queryListVariables,
            activeUserId as string,
            apolloClient,
          )
          resolve(resp.data.upsertPurchase)
          return
        }

        const purchaseId = resp.data.upsertPurchase.id

        setTimeout(() => {
          // console.log('api.upsertPurchase: calling loadPurchase')
          loadPurchase(
            purchaseId,
            activeIsInTargetStateFunc,
            listFilter,
            activeUserId,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((purchase) => {
              // console.log('api.upsertPurchase: resolving with purchase', purchase)
              resolve(purchase)
            }, (error) => {
              logger.error('api.upsertPurchase: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertPurchase: error', { error })
        reject(error)
      })
  })
)

export default upsertPurchase
