import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { ApiQueryOptions, CampaignListFilter, Campaign } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  CampaignQueryData,
  CampaignQueryVariables,
} from '../apollo/definitions'
import apollo from '../apollo'
import logger from '../logger'

const loadCampaign = (
  campaignId: string,
  isInTargetStateFunc: ((campaign: Campaign) => boolean) | 'watch-updated-at' | undefined,
  listFilter: CampaignListFilter | undefined,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<Campaign | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.loadCampaign called.')
    const startTime = new Date().getTime()
    const timeout = (clientOptions && clientOptions.timeout) || 30000

    if (!apolloClient) {
      logger.error('api.loadCampaign: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryOptions: QueryOptions<CampaignQueryVariables> = {
      query: apollo.queries.campaign,
      variables: { campaignId },
    }
    if (!clientOptions || clientOptions.skipCache !== false) {
      queryOptions.fetchPolicy = 'network-only'
    }

    const load = (): void => {
      apolloClient.query<CampaignQueryData, CampaignQueryVariables>(queryOptions)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.campaign ||
            !resp.data.campaign.id
          ) {
            logger.error('api.loadCampaign.load: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          let activeIsInTargetStateFunc: ((campaign: Campaign) => boolean) | undefined
          if (isInTargetStateFunc === 'watch-updated-at') {
            const oldUpdatedAt = resp.data.campaign.updatedAt
            activeIsInTargetStateFunc = (campaign: Campaign) => campaign.updatedAt !== oldUpdatedAt
          } else if (isInTargetStateFunc) {
            activeIsInTargetStateFunc = isInTargetStateFunc
          }

          if (
            !activeIsInTargetStateFunc ||
            activeIsInTargetStateFunc(resp.data.campaign)
          ) {
            // const queryListVariables = clientOptions && clientOptions.updateList
            //   ? apollo.getCampaignsQueryVariables(listFilter)
            //   : undefined
            //   helpers.updateCampaign(
            //     resp.data.campaign,
            //     options.queryListVariables as CampaignsQueryVariables,
            //     apolloClient,
            //   )

            // console.log('api.loadCampaign.load: resolving with info.', resp.data.campaign)
            resolve(resp.data.campaign)
            return
          }

          // console.log('api.loadCampaign.load: campaign not in target state.',
          //   { order: resp.data.order })
          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('api.loadCampaign.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          logger.error('api.loadCampaign.load: error.', { error })
          reject(error)
        })
    }

    load()
  })
)

export default loadCampaign
