import { gql } from '@apollo/client'

export default gql`
  query Q($walletId: String!) {
    userDevice (userDeviceId: $walletId) {
      id
      userId
      deviceUuid
      phoneNumber
      phoneNumberUpdatedAt
      phoneNumberVerified
      brand
      model
      isTablet
      screenWidth
      screenHeight
      os
      osVersion
      timezone
      ipAddress
      locale
      countryCode
      appVersion
      signedInAt
      signedOutAt
      authTokenCreatedAt
      pushNotificationToken
      trusted
      trustedAt
      metadata
      adminNotes
      recordStatus
      sessionStartedAt
      sessionEndedAt
      createdAt
      updatedAt
    }
  }`
