const NUMBER_CHARS = '0123456789'
const UPPER_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const LOWER_CHARS = 'abcdefghijklmnopqrstuvwxyz'
const WEIRD_CHARS = '!@#$-=,.?' // only using those that are easy enough to type on a mobile device
const ALL_CHARS = NUMBER_CHARS + UPPER_CHARS + LOWER_CHARS + WEIRD_CHARS

// see https://stackoverflow.com/questions/9719570/generate-random-password-string-with-requirements-in-javascript
const generatePassword = (length: number): string => {
  const shuffleArray = (array: string[]): string[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  let randPasswordArray = Array(length)
  randPasswordArray[0] = NUMBER_CHARS
  randPasswordArray[1] = UPPER_CHARS
  randPasswordArray[2] = LOWER_CHARS
  randPasswordArray[3] = WEIRD_CHARS
  randPasswordArray = randPasswordArray.fill(ALL_CHARS, 4)

  return shuffleArray(randPasswordArray.map((x) => (x[Math.floor(Math.random() * x.length)]))).join('')
}

export default generatePassword
