import type { ShoppingCart } from '../../../definitions'
import shoppingCartStatusHelpers from '../../type/shoppingCartStatusHelpers'
import transactionProcessorHelpers from '../../type/transactionProcessorHelpers'

const isOrderPlaced = (shoppingCart: ShoppingCart | null | undefined): boolean => {
  if (!shoppingCart) {
    return false
  }
  const isUsingCryptoPayment = transactionProcessorHelpers.isCrypto(shoppingCart.paymentProcessor)
  if (isUsingCryptoPayment) {
    return !!shoppingCart.paymentRemoteId
  }
  return shoppingCartStatusHelpers.isOrderPlaced(shoppingCart.status)
}

export default isOrderPlaced
