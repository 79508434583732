export enum GlobalCacheDataKey {
  CURRENT_MIMBLE_WALLET_TOKEN_BALANCE = 'current-mimble-wallet-token-balance',
  DEVICE_ID = 'device-id',
  ENVIRONMENT = 'environment',
  FCM_TOKEN = 'fcm-token',
  GIFT_FLOW_DATA = 'gift-flow-data',
  INVITE_CODE = 'invite-code',
  LOG_LEVEL = 'log-level',
  O_AUTH_PROVIDER = 'o-auth-provider',
  SIGN_IN_AS_USER = 'sign-in-as-user',
  SIGN_UP_FORM_DATA = 'sign-in-form-data',
  SIGNED_OUT_USER_ID = 'signed-out-user-id',
  USER = 'user',
  USER_PREFERENCES = 'user-preferences',
}
