import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'
import type {
  GridColumns,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import {
  DataGridPro,
} from '@mui/x-data-grid-pro'

import type { MemberMessageTemplateListFilter, MemberMessageTemplateListItem } from '../../../lib/core/definitions'
// import coreHelpers from '../../../lib/core/helpers'
// import pageHelpers from '../../../helpers/pageHelpers'

type Props = {
  memberMessageTemplates: MemberMessageTemplateListItem[] | null | undefined
  filter: MemberMessageTemplateListFilter | undefined
  isProcessing?: boolean
  onOpenMemberMessageTemplate: (memberMessageTemplateId: string) => void
}

const MemberMessageTemplateGrid: React.FC<Props> = (props): JSX.Element | null => {
  const { memberMessageTemplates, filter, isProcessing, onOpenMemberMessageTemplate } = props

  const filterList = (template: MemberMessageTemplateListItem): boolean => {
    if (filter && filter.searchText) {
      const reg = new RegExp(filter.searchText, 'i')
      if (
        (!template.name || !template.name.match(reg)) // &&
        // (!template.description || !template.description.match(reg)) &&
        // (!template.titleEn || !template.titleEn.match(reg))
      ) {
        return false
      }
    }
    return true
  }

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (rowData: GridRowParams): void => {
    onOpenMemberMessageTemplate(rowData.row.id as string)
  }

  const onExport = (): void => {
    if (!Array.isArray(memberMessageTemplates) || memberMessageTemplates.length < 1) {
      return
    }
    console.error('not implemented')
    // const data = coreHelpers.models.memberMessageTemplate.exportData(memberMessageTemplate, filter)
    // const link = document.createElement('a')
    // link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
    // link.target = '_blank'
    // link.download = 'members.csv'
    // link.click()
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(memberMessageTemplates) || memberMessageTemplates.length < 1) {
    return null
  }
  // see https://material-ui.com/components/data-grid/rendering/#components

  const columns: GridColumns = [
    { field: 'name', headerName: 'Name', flex: 5 },
    {
      field: 'isCore',
      headerName: 'Core?',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? 'y' : 'n'
      ),
    },
  ]
  const rows: GridRowsProp = memberMessageTemplates
    .filter(filterList)
    .map((template): GridRowModel => {
      return {
        id: template.id as string,
        name: template.name || '-',
        isCore: template.isCore,
        updatedAt: template.updatedAt as string,
      }
    })

  return (
    <div className='x-grid-data-view'>
      <div className='actions-row'>
        <IonButton
          size='small'
          fill='clear'
          color='medium'
          disabled={!Array.isArray(memberMessageTemplates) || memberMessageTemplates.length < 1 || isProcessing}
          onClick={onExport}
        >
          <IonIcon icon={cloudDownloadOutline} />
        </IonButton>
      </div>
      <div className='x-grid-wrapper'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  )
}

export default MemberMessageTemplateGrid
