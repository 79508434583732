import type { ContactUserListItem } from '../../../lib/core/definitions'
import { ContactItemList } from '../definitions'
import { ContactListEntryType, ContactListScope } from '../enums'
import { RecordStatus, UserRole } from '../../../lib/core/enums'
import coreHelpers from '../../../lib/core/helpers'
import getEventList from './getEventList'

const getContactListForScope = (
  contacts: ContactUserListItem[] | undefined,
  scope: ContactListScope,
  searchText: string | undefined,
): ContactItemList[] | undefined => {
  if (!Array.isArray(contacts) || contacts.length < 1) {
    return [{
      entryType: ContactListEntryType.CONTACT_LIST_ITEM,
      items: []
    }]
  }

  const filteredContacts = contacts
    .filter(contact => (
      contact.contactRecordStatus === RecordStatus.ACTIVE &&
      coreHelpers.models.contact.isItemATextSearchHit(contact, searchText)
    ))

  if (scope === ContactListScope.CONTACTS) {
    const items = filteredContacts.sort(coreHelpers.models.contact.compareListItemsByFavorite)
    return [
      {
        entryType: ContactListEntryType.CONTACT_LIST_ITEM,
        items,
        hasFavorites: (items.length > 0 && items[0].favorite) || false,
      }
    ]
  }

  if (scope === ContactListScope.EVENTS) {
    const eventListItemList: ContactItemList[] = []
    let otherContacts = filteredContacts

    // First block: events:
    const events = getEventList(contacts, searchText)
    if (events.length) {
      eventListItemList.push(
        {
          entryType: ContactListEntryType.CONTACT_EVENT_ITEM,
          items: events,
        },
      )
      // We remove the contacts with events from otherContacts:
      const contactIdsFromEvents = events.map(e => e.contact.contactId)
      otherContacts = filteredContacts.filter(
        c => !contactIdsFromEvents.includes(c.contactId) &&
        !coreHelpers.models.user.hasRole(c.roles, UserRole.MIMBLE_ORG)
      )
    }

    // Second block: all other contacts that don't have an event:
    eventListItemList.push({
      entryType: ContactListEntryType.CONTACT_LIST_ITEM,
      items: otherContacts.sort(coreHelpers.models.contact.compareListItemsByName),
      hasFavorites: filteredContacts.length > 0 && filteredContacts.some(c => c.favorite),
    })

    return eventListItemList
  }

  if (scope === ContactListScope.MESSAGES) {
    let hasFavorites = false
    const [contactsWithMessages, contactsWithoutMessages] = contacts.reduce<[ContactUserListItem[], ContactUserListItem[]]>((result, contactListItem) => {
      if (
        contactListItem.contactRecordStatus !== RecordStatus.ACTIVE ||
        !coreHelpers.models.contact.isItemATextSearchHit(contactListItem, searchText)
      ) {
        return result
      }
      if (contactListItem.latestChatMessageCreatedAt) {
        result[0].push(contactListItem)
      } else {
        result[1].push(contactListItem)
      }
      if (!hasFavorites && contactListItem.favorite) {
        hasFavorites = true
      }
      return result
    }, [[], []])

    return [
      {
        entryType: ContactListEntryType.CONTACT_LIST_ITEM,
        items: contactsWithMessages
            .sort(coreHelpers.models.contact.compareListItemsByChat)
            .concat(contactsWithoutMessages
              .sort(coreHelpers.models.contact.compareListItemsByName)
            ),
        hasFavorites,
      },
    ]
  }
}

export default getContactListForScope
