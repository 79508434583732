import { ReportDataFormat } from '../../enums'

const labelsEn = Object.freeze({
  [ReportDataFormat.JSON]: 'JSON',
  [ReportDataFormat.TSV]: 'tsv',
  [ReportDataFormat.CSV]: 'csv',
})

const reportDataFormatHelpers = {
  getLabel: (type: ReportDataFormat | null | undefined, language = 'en'): string => {
    if (!type) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[type]
    }
    return ''
  },

  isValid: (type: ReportDataFormat | string | null | undefined): boolean =>
    !type || Object.values(ReportDataFormat).includes(type as ReportDataFormat),
}

export default reportDataFormatHelpers
