import React from 'react'
import './styles.css'
import InfoPaneEntry from '../InfoPaneEntry/InfoPaneEntry'

export interface InfoPaneEntryDef {
  key: string
  label: string
  value?: string | null | undefined
  navigateToRoute?: (route: string) => void
  show?: boolean
}

type Props = {
  entries: InfoPaneEntryDef[];
  className?: string;
}
const InfoPane: React.FC<Props> = (props): JSX.Element | null => {
  const { entries, className } = props

  const renderedEntries = entries
    .filter(e => e.value && e.show !== false)
    .map((entry) => <InfoPaneEntry key={entry.key} label={entry.label} value={entry.value} />)
  return (
    <div className={`info-pane ${className || ''}`}>
      {renderedEntries}
    </div>
  )
}

export default InfoPane
