import React from 'react'
import { IonImg } from '@ionic/react'

import { CdnAssetType } from '../../services/cdn/enums'
import cdn from '../../services/cdn'

type Props = {
  imageUri?: string;
  small?: boolean;
};

const FullBleedBackground: React.FC<Props> = (props): JSX.Element => {
  const { imageUri, small } = props

  const uri = imageUri ||
    cdn.getUri(CdnAssetType.GIFT_CARD, 'generic-front.jpg') ||
    ''
  const cssClass = small
    ? 'gift-card-full-bleed gift-card-full-bleed-small'
    : 'gift-card-full-bleed'

  return (
    <IonImg alt='Gift Card' src={uri} className={cssClass} />
  )
}

export default FullBleedBackground
