import React from 'react'

import { UiMessage } from '../lib/core/enums'
import { PageMessageType } from '../enums'

export class PageMessage {
  public id = UiMessage.UNKNOWN
  public details: string | undefined = ''

  constructor (newId: UiMessage, details?: string) {
    this.id = newId
    this.details = details
  }
}

export interface PageMessagesContextData {
  getNotices: () => Map<UiMessage, PageMessage> | undefined;
  getWarnings: () => Map<UiMessage, PageMessage> | undefined;
  getErrors: () => Map<UiMessage, PageMessage> | undefined;
  version: number;
  setVersion?: (version: number) => void | undefined;

  add: (type: PageMessageType, msg: UiMessage, details?: string) => void;
  clear: (type?: PageMessageType) => void;
  isEmpty: () => boolean;
  hasNotices: () => boolean;
  hasWarnings: () => boolean;
  hasErrors: () => boolean;
}

let notices: Map<UiMessage, PageMessage> | undefined
let warnings: Map<UiMessage, PageMessage> | undefined
let errors: Map<UiMessage, PageMessage> | undefined

export const DEFAULT_PAGE_MESSAGE_CONTEXT_DATA: PageMessagesContextData = {
  getNotices: (): Map<UiMessage, PageMessage> | undefined => notices,
  getWarnings: (): Map<UiMessage, PageMessage> | undefined => warnings,
  getErrors: (): Map<UiMessage, PageMessage> | undefined => errors,
  version: 0,

  add (type: PageMessageType, msg: UiMessage, details?: string): void {
    if (type === PageMessageType.NOTICE) {
      if (notices) {
        if (notices.has(msg)) {
          return
        }
      } else {
        notices = new Map<UiMessage, PageMessage>()
      }
      notices.set(msg, new PageMessage(msg, details))
      this.version += 1
      if (this.setVersion) {
        this.setVersion(this.version)
      }
      return
    }
    if (type === PageMessageType.WARNING) {
      if (warnings) {
        if (warnings.has(msg)) {
          return
        }
      } else {
        warnings = new Map<UiMessage, PageMessage>()
      }
      warnings.set(msg, new PageMessage(msg, details))
      this.version += 1
      if (this.setVersion) {
        this.setVersion(this.version)
      }
      return
    }
    if (type === PageMessageType.ERROR) {
      if (errors) {
        if (errors.has(msg)) {
          return
        }
      } else {
        errors = new Map<UiMessage, PageMessage>()
      }
      errors.set(msg, new PageMessage(msg, details))
      this.version += 1
      if (this.setVersion) {
        this.setVersion(this.version)
      }
      return
    }
    throw new Error(`Unknown page message type '${type}'`)
  },

  clear (type?: PageMessageType): void {
    let changed = false
    if (
      (!type || type === PageMessageType.NOTICE) &&
      notices && notices.size > 0
    ) {
      notices = undefined
      changed = true
    }
    if (
      (!type || type === PageMessageType.WARNING) &&
      warnings && warnings.size > 0
    ) {
      warnings = undefined
      changed = true
    }
    if (
      (!type || type === PageMessageType.ERROR) &&
      errors && errors.size > 0
    ) {
      errors = undefined
      changed = true
    }
    if (changed) {
      this.version += 1
      if (this.setVersion) {
        this.setVersion(this.version)
      }
    }
  },

  isEmpty (): boolean {
    return (
      (!notices || notices.size < 1) &&
      (!warnings || warnings.size < 1) &&
      (!errors || errors.size < 1)
    )
  },

  hasNotices (): boolean {
    return !!notices && notices.size > 0
  },

  hasWarnings (): boolean {
    return !!warnings && warnings.size > 0
  },

  hasErrors (): boolean {
    return !!errors && errors.size > 0
  },
}

const PageMessagesContext = React.createContext(DEFAULT_PAGE_MESSAGE_CONTEXT_DATA)

export const PageMessagesProvider = PageMessagesContext.Provider
export const PageMessagesConsumer = PageMessagesContext.Consumer

export default PageMessagesContext
