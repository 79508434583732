import type { ApolloClient, QueryOptions } from '@apollo/client'

import type { AsyncTask } from '../../lib/core/definitions'
import { ErrorCode } from '../../lib/core/enums'
import type { AsyncTaskQueryData, AsyncTaskQueryVariables } from '../apollo/definitions'
import apollo from '../apollo'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'

const loadAsyncTask = (
  taskId: string,
  apolloClient: ApolloClient<any>,
  isTaskInTargetStateFunc: (task: AsyncTask) => boolean,
  timeout = 30000,
): Promise<AsyncTask | undefined> => (
  new Promise((resolve, reject) => {
    console.log('auth.loadAsyncTask called.')

    const startTime = new Date().getTime()
    if (!apolloClient) {
      console.log('auth.loadAsyncTask: no Apollo client available.')
      throw (new Error(ErrorCode.SYSTEM_ERROR))
    }

    const queryParams: QueryOptions<AsyncTaskQueryVariables> = {
      query: apollo.queries.asyncTask,
      variables: { taskId },
      fetchPolicy: 'network-only',
    }
    if (!globalCacheData.getIsSignedIn()) {
      queryParams.context = { skipAuthentication: true }
    }

    const load = (): void => {
      apolloClient.query<AsyncTaskQueryData, AsyncTaskQueryVariables>(queryParams)
        .then((resp) => {
          if (
            !resp ||
            !resp.data ||
            !resp.data.asyncTask ||
            !resp.data.asyncTask.id
          ) {
            console.log('auth.loadAsyncTask.load: query did not return expected data.')
            reject(new Error(ErrorCode.SYSTEM_ERROR))
            return
          }

          if (isTaskInTargetStateFunc(resp.data.asyncTask)) {
            console.log('auth.loadAsyncTask.load: resolving with task.', resp.data.asyncTask)
            resolve(resp.data.asyncTask)
            return
          }

          if (
            startTime &&
            timeout &&
            new Date().getTime() > startTime + timeout
          ) {
            console.warn('auth.loadAsyncTask.load: timed out.')
            reject(ErrorCode.TIMEOUT)
            return
          }

          setTimeout(() => { load() }, 1000)
        }, (error) => {
          console.error(error)
        })
    }

    load()
  })
)

export default loadAsyncTask
