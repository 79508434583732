import type { ShoppingCart, ShoppingCartListItem } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'

const getShoppingCartBadgeCount = (
  activeShoppingCart: ShoppingCart | undefined,
  pendingShoppingCarts: ShoppingCartListItem[] | undefined,
): number => {
  let count = coreHelpers.models.shoppingCart.getTotalProductCount(activeShoppingCart)

  if (Array.isArray(pendingShoppingCarts) && pendingShoppingCarts.length > 0) {
    pendingShoppingCarts.forEach((cart) => {
      count += cart.metadata && cart.metadata.productCount ? cart.metadata.productCount : 1
    })
  }

  return count
}

export default getShoppingCartBadgeCount
