import React from 'react'

type Props = {
  label: string
  className?: string
}

const UserListGroupHeader: React.FC<Props> = (props): JSX.Element => {
  const { label, className } = props

  return (
    <div className={`group-header ${className || ''}`}>
      {label}
    </div>
  )
}

export default UserListGroupHeader
