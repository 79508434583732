import React from 'react'
import { IonButton, IonIcon, IonImg } from '@ionic/react'
import { checkmarkOutline, closeOutline, cloudDownloadOutline } from 'ionicons/icons'
import type {
  GridColumns,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import {
  DataGridPro,
} from '@mui/x-data-grid-pro'

import './styles.css'
import type { ProductListFilter, ProductListItem } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import pageHelpers from '../../../helpers/pageHelpers'
import Moment from 'moment-timezone'

type Props = {
  products: ProductListItem[] | null | undefined;
  filter: ProductListFilter | undefined;
  isProcessing?: boolean;
  onOpenProduct: (productId: string) => void;
};

const ProductGrid: React.FC<Props> = (props): JSX.Element | null => {
  const { products, filter, isProcessing, onOpenProduct } = props

  const filterList = (product: ProductListItem): boolean => {
    if (filter && filter.searchText) {
      const reg = new RegExp(filter.searchText, 'i')
      if (!product.merchantName || !product.merchantName.match(reg)) {
        return false
      }
    }
    if (filter && filter.showOnlyMainProducts && !product.isMainProduct) {
      return false
    }
    if (filter && filter.showOnlyListed && !product.listed) {
      return false
    }
    if (filter && filter.showOnlyInStock && !product.stock) {
      return false
    }
    return true
  }

  // ===================================================================================================================
  // Event Handlers:
  const onRowClick = (rowData: GridRowParams): void => {
    onOpenProduct((rowData.row.id as string).replace(/-/g, ''))
  }

  const onExport = (): void => {
    if (!Array.isArray(products) || products.length < 1) {
      return
    }
    const data = coreHelpers.models.product.exportData(products, filter)
    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
    link.target = '_blank'
    link.download = 'members.csv'
    link.click()
  }

  // ===================================================================================================================
  // Rendering:
  if (!Array.isArray(products) || products.length < 1) {
    return null
  }
  // see https://material-ui.com/components/data-grid/rendering/#components

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'merchantLogo',
      headerName: 'Logo',
      flex: 0.4,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridValueFormatterParams) => {
        if (params.value) {
          return <IonImg src={params.value as string} style={{ width: 40 }} />
        }
        return <span>-</span>
      },
    },
    {
      field: 'productImage',
      headerName: 'Image',
      flex: 0.4,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridValueFormatterParams) => {
        if (params.value) {
          return <IonImg src={params.value as string} style={{ width: 40 }} />
        }
        return <span>-</span>
      },
    },
    { field: 'merchantName', headerName: 'Brand', flex: 1 },
    {
      field: 'isMainProduct',
      headerName: 'Main Prod.?',
      flex: 0.5,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridValueFormatterParams) => (
        params.value
          ? <IonIcon icon={checkmarkOutline} className='inlineWarnIcon' color='success' />
          : <IonIcon icon={closeOutline} className='inlineWarnIcon' color='warning' />
      ),
    },
    {
      field: 'stock',
      headerName: 'Stock?',
      flex: 0.5,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridValueFormatterParams) => (
        params.value
          ? <IonIcon icon={checkmarkOutline} className='inlineWarnIcon' color='success' />
          : <IonIcon icon={closeOutline} className='inlineWarnIcon' color='warning' />
      ),
    },
    { field: 'inventory5', headerName: '$5', align: 'right', flex: 0.5 },
    { field: 'inventory10', headerName: '$10', align: 'right', flex: 0.5 },
    { field: 'inventory25', headerName: '$25', align: 'right', flex: 0.5 },
    { field: 'inventory50', headerName: '$50', align: 'right', flex: 0.5 },
    { field: 'inventory100', headerName: '$100', align: 'right', flex: 0.5 },
    {
      field: 'listed',
      headerName: 'Listed?',
      flex: 0.5,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridValueFormatterParams) => (
        params.value
          ? <IonIcon icon={checkmarkOutline} className='inlineWarnIcon' color='success' />
          : <IonIcon icon={closeOutline} className='inlineWarnIcon' color='warning' />
      ),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      flex: 0.5,
      valueFormatter: (params: GridValueFormatterParams) => (
        params.value ? Moment(Number(params.value)).format('YYYY-MM-DD') : '-'
      ),
      sortComparator: (v1, v2) => (
        Number(v2) - Number(v1)
      ),
    },
  ]
  const rows: GridRowsProp = products
    .filter(filterList)
    .map((product): GridRowModel => {
      const { giftCardBackgroundUri, merchantLogoUri } = pageHelpers.getPurchaseCdnUrisFromProductListItem(product)
      const {
        inventory5,
        inventory10,
        inventory25,
        inventory50,
        inventory100,
      } = coreHelpers.models.product.getMetadataInventory(product.metadata)
      return {
        id: product.id,
        merchantLogo: merchantLogoUri,
        productImage: giftCardBackgroundUri,
        merchantName: product.merchantName,
        isMainProduct: product.isMainProduct,
        stock: product.stock,
        inventory5,
        inventory10,
        inventory25,
        inventory50,
        inventory100,
        listed: product.listed,
        updatedAt: product.updatedAt,
      }
    })

  return (
    <div className='x-grid-data-view'>
      <div className='actions-row'>
        <IonButton
          size='small'
          fill='clear'
          color='medium'
          disabled={!Array.isArray(products) || products.length < 1 || isProcessing}
          onClick={onExport}
        >
          <IonIcon icon={cloudDownloadOutline} />
        </IonButton>
      </div>
      <div className='x-grid-wrapper'>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={36}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  )
}

export default ProductGrid
