import moment from 'moment'
import React, { useRef } from 'react'
import { IonButton, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding } from '@ionic/react'
import {
  archiveOutline,
  chevronForwardOutline,
  giftOutline,
  personOutline,
} from 'ionicons/icons'

import './styles.css'
import { ContactEventListEntry } from '../../../../lib/core/definitions'
import { GiftingEventType } from '../../../../lib/core/enums'
import Avatar from '../../../../components/Avatar/Avatar'
import ContactEventIcon from '../../../../components/ContactEventIcon/ContactEventIcon'

type Props = {
  activeUserId: string | null | undefined
  entry: ContactEventListEntry
  onArchiveContact: (contactId: string) => void
  onOpenContact: (contactId: string, contactUserId: string, chatId: string) => void
  onSendGift: (userId: string) => void
  showUiMessage: (message: string) => void
}

const ContactEventListItem: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('ContactEventListItem.render called.', { props })
  const {
    entry,
    onArchiveContact,
    onOpenContact,
    onSendGift,
  } = props
  const { event, contact } = entry
  const {
    fullName,
    nickname,
    unreadChatMessageCount,
    imageUrl,
  } = contact
  const avatarUser = { fullName, imageUrl }
  const formattedEventDate = (
    event &&
    event.parsedDate &&
    event.parsedDate.nextDate &&
    moment(event.parsedDate.nextDate).format('MMM Do')
  )

  // ===================================================================================================================
  // State:
  const slidingRef = useRef<HTMLIonItemSlidingElement | null>(null)

  // ===================================================================================================================
  // Event Handlers:
  const onArchiveThisContact = (event: any) => {
    event.preventDefault()
    if (contact && contact.contactId) {
      onArchiveContact(contact.contactId)
      if (slidingRef.current) {
        slidingRef.current.close()
      }
    }
  }

  const handleOpenContact = (): void => {
    if (contact.contactId) {
      onOpenContact(contact.contactId, contact.userId, contact.chatId)
      if (slidingRef.current) {
        slidingRef.current.close()
      }
    }
  }

  const handleSendGift = (event?: any): void => {
    if (event) {
      event.preventDefault()
    }
    if (onSendGift && contact.userId) {
      onSendGift(contact.userId)
      if (slidingRef.current) {
        slidingRef.current.close()
      }
    }
  }

  // ===================================================================================================================
  // Rendering:
  if (!event) {
    return null
  }

  let eventLabel: JSX.Element | undefined
  let contactAgeInfo: JSX.Element | undefined
  if (event.eventType === GiftingEventType.BIRTHDAY) {
    if (
      event.parsedDate &&
      event.parsedDate.year > 0 &&
      (
        !contact.contactMetadata ||
        contact.contactMetadata.toUserSharedBirthYear !== false
      )
    ) {
      contactAgeInfo = (
        <div className='withSmallLeftMargin'>
          {`- age ${moment().diff(event.eventDate, 'years')}`}
        </div>
      )
    }
  } else {
    eventLabel = (
      <div className='withSmallLeftMargin'>
        - {event.name}
      </div>
    )
  }

  let daysToEvent: JSX.Element | undefined
  if (event.parsedDate) {
    if (event.parsedDate.daysUntil > 0) {
      daysToEvent = (
        <div className='withPointerCursor' onClick={handleOpenContact}>
          <div className='event-countdown'>
            {event.parsedDate.daysUntil}
          </div>
          <div className='countdown-text'>
            {`Day${event.parsedDate.daysUntil > 1 ? 's' : ''}`}
          </div>
        </div>
      )
    } else {
      daysToEvent = (
        <div className='today' onClick={handleOpenContact}>
          <div>Today</div>
        </div>
      )
    }
  }

  return (
    <IonItemSliding key={contact.id as string} ref={slidingRef} className='contact-list-item-sliding'>
      <IonItem className='contact-event-list-item'>
        <div className='item-content'>
          <div className='g-avatar-wrapper'>
            <Avatar
              user={avatarUser}
              size={50}
              badge={unreadChatMessageCount}
              padForBadge
              className='withSmallRightMargin withPointerCursor'
              onClick={handleOpenContact}
            />
          </div>
          <div className='row0-content-col'>
            <div className='full-name' onClick={handleOpenContact}>
              {nickname || fullName}
            </div>
            <div className='rowWithCenterAlignedItems withSmallTopMargin smallText lightText'>
              <ContactEventIcon
                eventType={event.eventType}
                size={18}
                className='withSmallRightMargin'
              />
              {formattedEventDate}
              {eventLabel}
              {contactAgeInfo}
            </div>
          </div>
          {daysToEvent}
          <div className='control-col' onClick={handleOpenContact}>
            <IonButton
              fill='clear'
              color='medium'
              className='next-button'
              onClick={handleOpenContact}
            >
              <IonIcon icon={chevronForwardOutline} />
            </IonButton>
          </div>
        </div>
      </IonItem>
      <IonItemOptions>
        <IonItemOption type='button' color='medium' expandable onClick={handleOpenContact}>
          <IonIcon icon={personOutline} color='light' slot='top' className='action-icon' />
          Open
        </IonItemOption>
        <IonItemOption type='button' color='medium' expandable onClick={handleSendGift}>
          <IonIcon icon={giftOutline} color='light' slot='top' className='action-icon' />
          Gift
        </IonItemOption>
        <IonItemOption type='button' color='danger' expandable onClick={onArchiveThisContact}>
          <IonIcon icon={archiveOutline} color='light' slot='top' className='action-icon' />
          Archive
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  )
}

export default ContactEventListItem
