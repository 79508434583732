import { AppRoute } from '../../enums'
import { AppFeature } from '../../lib/core/enums'

export interface FrequentlyAskedQuestion {
  question: string;
  answer: string;
  buttonLabel?: string;
  appFeatures?: AppFeature[];
  targetRoute?: string;
  targetUrl?: string;
}

const faq: FrequentlyAskedQuestion[] = [
  {
    question: 'What is a MIT?',
    answer: 'A MIT is a "Mimble Token". It is the unit of value in your Mimble wallet. When you earn a reward, it will be a value of MIT. 1 MIT is worth exactly $0.01, or 1 cent.',
  },
  {
    question: 'Can I buy MITs to fill up my wallet?',
    answer: 'Yes. You can purchase MITs with your credit card.',
    buttonLabel: 'Buy MIT',
    targetRoute: AppRoute.BUY_MIMBLE_TOKENS,
    appFeatures: [AppFeature.BUY_WITH_CREDIT_CARD],
  },
  {
    question: 'How can I upload a gift card that I already own?',
    answer: 'Start by selecting the brand. Then enter the gift card barcode and PIN. The new gift card will now be in your wallet.',
    buttonLabel: 'Upload Gift Card',
    targetRoute: '/select-brand/upload-card',
  },
  {
    question: 'How do I upload a gift card for a brand that isn\'t listed?',
    answer: 'That is currently not possible. Not all brands allow e-gift cards, or may not handle barcodes. We therefore maintain a list of brands we know will allow you to use Mimble.',
  },
]

export default faq
