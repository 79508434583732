import React from 'react'

import '../../theme/default-html-content.css'
import type { Product } from '../../lib/core/definitions'

type Props = {
  product: Product | null | undefined,
}

const RedeemInstructionsSection: React.FC<Props> = ({ product }): JSX.Element | null => {
  // const onVisitMerchantWebsite = (): void => {
  //   if (!product || !product.url) {
  //     return
  //   }
  //   window.open(product.url as string, '_system')
  // }

  if (
    !product ||
    (
      !product.instructionsEn &&
      !product.instructionsUrl
    )
  ) {
    return null
  }

  const parts: JSX.Element[] = []
  if (product.instructionsEn) {
    if (product.instructionsEn.startsWith('<')) {
      parts.push(
        <div
          key='instructions'
          dangerouslySetInnerHTML={{ __html: product.instructionsEn }}
          className='withStandardBottomMargin'
        />,
      )
    } else {
      parts.push(
        <div key='instructions' className='withStandardBottomMargin'>
          {product.instructionsEn}
        </div>,
      )
    }
  }
  if (product.instructionsUrl) {
    parts.push(
      <a key='link' href={product.instructionsUrl} target='_blank' rel='noreferrer'>
        View instructions online
      </a>,
      // <div className='linkText' onClick={onOpenInstructionsUrl}>
      //   {product.instructionsUrl}
      // </div>
    )
  }

  return (
    <div className='withStandardPadding'>
      <div className='sectionCaption'>How To Redeem</div>
      {parts}
    </div>
  )
}

export default RedeemInstructionsSection
