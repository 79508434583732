import React from 'react'
import type { ToBufferOptions } from 'bwip-js'
import bwipjs from 'bwip-js'
import {
  Page,
  Document,
  Text,
  StyleSheet,
  Image,
  View,
} from '@react-pdf/renderer'

import { BarcodeType } from '../../../lib/core/enums'
import type { Purchase } from '../../../lib/core/definitions'
import appLinks from '../../../lib/core/appLinks'
import coreHelpers from '../../../lib/core/helpers'
import getStyles from './styles'
import Html from '../Html/Html'
import pageHelpers from '../../../helpers/pageHelpers'

const instructionsHtmlStyles = StyleSheet.create({
  h1: { fontSize: 9, marginBottom: 1, color: 'grey' },
  li: { fontSize: 7, color: 'grey' },
  p: { fontSize: 7, marginBottom: 2.5, color: 'grey' },
})

const termsHtmlStyles = StyleSheet.create({
  h1: { fontSize: 8, marginBottom: 1, color: 'grey' },
  li: { fontSize: 5, color: 'grey' },
  p: { fontSize: 5, marginBottom: 2, color: 'grey' },
})

type Props = {
  purchase: Purchase | null | undefined
}

const GiftCardPdf: React.FC<Props> = (props): JSX.Element => {
  // console.log('GiftCardPdf.render called.', { purchase: props.purchase })
  const { purchase } = props
  const localUser = purchase && purchase.userId

  const product = purchase ? purchase.product : undefined
  const amount = purchase ? coreHelpers.ui.formatAmount(purchase.balance, purchase.fundType, purchase.currency, true, 2) : undefined
  const hasBarcode = product && product.hasBarcode ? product.hasBarcode : undefined
  const pin = purchase && purchase.pin ? purchase.pin : ''
  const code = purchase && purchase.code ? purchase.code : ''
  const formattedCode = code && code.match(/.{1,4}/g)?.join(' ')
  const { giftCardBackgroundUri } = pageHelpers.getPurchaseCdnUrisFromProduct(product, false)

  const styles = StyleSheet.create(getStyles(purchase))

  // Barcode
  let barcodeSection: JSX.Element | undefined
  if (code && hasBarcode) {
    let format: string | undefined
    if (purchase && purchase.barcodeFormat === BarcodeType.QR_CODE) {
      format = 'qrcode'
    } else if (purchase && purchase.barcodeFormat === BarcodeType.PDF417) {
      format = 'pdf417'
    } else {
      format = 'code128'
    }
    const canvas = document.createElement('canvas')
    const opts: ToBufferOptions = {
      bcid: format, // Barcode type
      text: code, // Text to encode
      rotate: 'N', // N, R, L, or I
      includetext: false, // Show human-readable text
    }
    bwipjs.toCanvas(canvas, opts)
    const barcode = canvas.toDataURL()
    const barcodeStyle = format === 'qrcode' ? styles.cardQrCode : styles.cardBarcode

    barcodeSection = <Image style={barcodeStyle} src={barcode} />
  }

  // Invite QR
  let invitationLinkQR: JSX.Element | undefined
  if (localUser) {
    const format = 'qrcode'
    const qrImageUri = appLinks.inviteQrCode(localUser as string)
    const canvas = document.createElement('canvas')
    const opts: ToBufferOptions = {
      bcid: format, // Barcode type
      text: qrImageUri, // Text to encode
      rotate: 'N', // N, R, L, or I
      includetext: false, // Show human-readable text
    }
    bwipjs.toCanvas(canvas, opts)
    const invitationQr = canvas.toDataURL()

    invitationLinkQR = <Image style={styles.inviteQR} src={invitationQr} />
  }

  // Pin
  let pinSection: JSX.Element | undefined
  if (purchase && purchase.pin) {
    pinSection = <Text style={styles.pin}>Pin: {pin}</Text>
  }

  // Instructions & Terms
  let instructionSection: JSX.Element | undefined
  if (product && product.instructionsEn) {
    let textComp: JSX.Element | undefined
    if (product.instructionsEn.trim().startsWith('<')) {
      textComp = (
        <Html
          styles={instructionsHtmlStyles}
          htmlText={product.instructionsEn} />
      )
    } else {
      textComp = <Text style={styles.redeemInstructionsText}>{product.instructionsEn}</Text>
    }
    instructionSection = (
      <View style={{ marginBottom: 2.5 }}>
        <Text style={styles.sectionCaption}>How To Redeem</Text>
        {textComp}
      </View>
    )
  }

  let termsSection: JSX.Element | undefined
  if (product && product.termsEn) {
    let textComp: JSX.Element | undefined
    if (product.termsEn.trim().startsWith('<')) {
      textComp = (
        <Html
          styles={termsHtmlStyles}
          htmlText={product.termsEn} />
      )
    } else {
      textComp = <Text style={styles.termsText}>{product.termsEn}</Text>
    }
    termsSection = (
      <>
        <Text style={styles.sectionCaption}>Terms &amp; Conditions</Text>
        {textComp}
      </>
    )
  }

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.mainColumn}>
          <View style={styles.cardSection}>
            <Image style={styles.cardImage} src={giftCardBackgroundUri}/>
            <Text style={styles.balance}>{amount}</Text>
            {barcodeSection}
            <Text style={styles.code}>{formattedCode}</Text>
            {pinSection}
          </View>

          <View style={styles.infoSection}>
            {instructionSection}
            {termsSection}
          </View>

          <View style={styles.promoSection}>
            <Text style={styles.promoCol1Line0}>
              Scan for the free Mimble app
            </Text>
            {invitationLinkQR}
            <Text style={styles.promoCol1Line1}>
              Gifting - fun, fast and easy!
            </Text>
          </View>

          <View style={styles.poweredBySection}>
            <View style={styles.mimbleLogoRow}>
              <Image
                style={styles.mimbleLogo}
                src={process.env.PUBLIC_URL + '/assets/images/mimble-logo-square-gray.png'}
              />
            </View>
            <View>
              <Text style={styles.mimbleTextLine0}>Printed from the Mimble App</Text>
              <Text style={styles.mimbleTextLine1}>&copy;2021 Mimble, Inc. All rights reserved.</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default GiftCardPdf
