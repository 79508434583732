import React, { useContext, useState } from 'react'
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonToast } from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import './styles.css'
import { UiMessage } from '../../../lib/core/enums'
import { AppPage, AppRoute, PageMessageType } from '../../../enums'
import type { MerchantInput } from '../../../lib/core/definitions'
import type { MerchantQueryData, MerchantQueryVariables } from '../../../services/apollo/definitions'
import api from '../../../services/api'
import apollo from '../../../services/apollo'
import AppPageFooter from '../../../components/AppPageFooter/AppPageFooter'
import auth from '../../../services/auth'
import logger from '../../../services/logger'
import MerchantForm from './MerchantForm'
import NavBar from '../../../components/NavBar/NavBar'
import pageHelpers from '../../../helpers/pageHelpers'
import PageMessages from '../../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../../contexts/pageMessagesContext'

const appPageId = AppPage.AdminEditCampaignPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined

type Params = {
  merchantId: string
}

const EditMerchantPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { merchantId } = useParams<keyof Params>() as unknown as Params
  const goBackUri = merchantId ? `${AppRoute.ADMIN_MERCHANT}/${merchantId}` : AppRoute.ADMIN_MERCHANTS

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  // const { activeUser } = useMimbleData()
  // const activeUserId = activeUser && activeUser.id

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()
  const [isEditingMerchant, setIsEditingMerchant] = useState(false)

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: merchantLoadedData,
    loading: isLoadingMerchant,
    refetch: reloadMerchant,
  } = useQuery<MerchantQueryData, MerchantQueryVariables>(
    apollo.queries.merchant, {
      variables: { merchantId: merchantId as string },
      skip: !merchantId,
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
        setToastMessage(error.message)
        setShowToast(true)
      },
      onCompleted: (data: MerchantQueryData) => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
        const returnedMerchant = data ? data.merchant : undefined
        if (!returnedMerchant) {
          pageMessages && pageMessages.add(PageMessageType.ERROR, UiMessage.ERROR_DATA_NOT_FOUND)
        }
      },
    },
  )
  const merchant = merchantLoadedData ? merchantLoadedData.merchant : undefined

  const isProcessing = isEditingMerchant || isLoadingMerchant

  // ===================================================================================================================
  // Effect Hooks:

  // ===================================================================================================================
  // Event Handlers:
  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const onUpsertMerchant = (merchantInput: MerchantInput): void => {
    setIsEditingMerchant(true)
    api.upsertMerchant(
      merchantInput,
      'watch-updated-at',
      undefined,
      apolloClient,
      undefined,
      { updateList: true },
    ).then(() => {
      setIsEditingMerchant(false)
      showUiMessage('Successfully saved the merchant.')
      navigate(-1)
    }, (error) => {
      logger.error('EditMerchantPage.onUpsertMerchant: error received.', { error })
      setIsEditingMerchant(false)
      showUiMessage('Error saving this merchant.')
    })
  }

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent || !reloadMerchant || !merchantId) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadMerchant({ merchantId }).then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onGoBack = (): void => {
    navigate(-1)
  }

  const onEditMainProduct = (): void => {
    if (
      merchant &&
      merchant.mainProduct &&
      merchant.mainProduct.id
    ) {
      navigate(`/admin/edit-product/${merchant.mainProduct.id}`)
    }
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  const pageTitle = merchant ? 'Edit Brand' : 'Add Brand'

  // console.log('EditMerchantPage.render called.', { merchant });
  return (
    <IonPage className='app-page-admin edit-merchant-page'>
      <NavBar
        title={pageTitle}
        goBackUri={goBackUri}
        isProcessing={isProcessing}
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <MerchantForm
          merchant={merchantId ? merchant : null}
          onEditMainProduct={onEditMainProduct}
          onGoBack={onGoBack}
          onSave={onUpsertMerchant}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default EditMerchantPage
