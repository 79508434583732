import type { MouseEvent } from 'react'
import React from 'react'
import {
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from '@ionic/react'
import type {
  ContextMenuId,
  ContextMenuItem,
  ContextMenuItemId,
  ContextMenuOptions,
} from './contextMenuDefs'
import contextMenuDefs from './contextMenuDefs'
import './styles.css'

type Props = {
  contextMenuId: ContextMenuId;
  options?: ContextMenuOptions | null;
  openMenuEvent?: MouseEvent<Element, MouseEvent> | undefined;
  isOpen?: boolean;
  onClose: () => void;
  onClickItem: (item: ContextMenuItemId) => void;
};

const ContextMenu: React.FC<Props> = (props): JSX.Element => {
  const {
    contextMenuId,
    openMenuEvent,
    isOpen,
    onClose,
    onClickItem,
    options,
  } = props

  const handleItemClick = (item: ContextMenuItemId): void => {
    onClose()
    onClickItem(item)
  }

  const menuDef = contextMenuDefs[contextMenuId]
  const items = menuDef.items(options).map((item: ContextMenuItem): JSX.Element => {
    return (
      <IonItem
        key={item.id}
        lines='none'
        onClick={(): void => { handleItemClick(item.id) }}
      >
        <IonLabel>{item.label}</IonLabel>
      </IonItem>
    )
  })
  return (
    <IonPopover
      isOpen={isOpen === true}
      event={openMenuEvent as Event | undefined}
      onDidDismiss={onClose}
    >
      <IonList class='contextMenuList'>
        {items}
      </IonList>
    </IonPopover>
  )
}

ContextMenu.defaultProps = {
  options: null,
  isOpen: false,
}

export default ContextMenu
