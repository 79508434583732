import React, { useContext } from 'react'
import { alert, close, informationCircle } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

import './styles.css'
import { PageMessageType } from '../../enums'
import type { UiMessage } from '../../lib/core/enums'
import type { PageMessage } from '../../contexts/pageMessagesContext'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import typeHelpers from '../../lib/core/helpers/type'

const getRenderedMessages = (
  key: string,
  messages: Map<UiMessage, PageMessage>,
  wrapperClass: string,
  col0Icon: any,
  onClear: () => void,
): JSX.Element => {
  const renderedNotices = Array.from(messages.keys()).map((msg: UiMessage, idx: number): JSX.Element => {
    const pageMessage = messages.get(msg)
    const text = pageMessage && pageMessage.details
      ? `${typeHelpers.uiMessage.getLabel(msg)} ${pageMessage.details}`
      : typeHelpers.uiMessage.getLabel(msg)
    const key = `e-${idx}`
    return (
      <div key={key}>
        {text}
      </div>
    )
  })
  return (
    <div key={key} className={wrapperClass}>
      <div className='pageMessagesCol0'>
        <IonIcon icon={col0Icon} class='pageMessageIcon' />
      </div>
      <div className='message-inner-wrapper'>
        <div className='pageMessagesCol1'>
          {renderedNotices}
        </div>
        <div className='pageMessagesCol2' onClick={onClear}>
          <IonIcon icon={close} class='closeIcon' />
        </div>
      </div>
    </div>
  )
}

const PageMessages: React.FC = (): JSX.Element | null => {
  const messages = useContext(PageMessagesContext)
  // console.log('PageMessages.render called.', messages)

  if (!messages || messages.isEmpty()) {
    return null
  }

  const parts: JSX.Element[] = []

  const notices = messages.getNotices()
  if (messages.hasNotices() && notices) {
    parts.push(getRenderedMessages(
      'notices',
      notices,
      'pageMessages pageMessagesNotices',
      informationCircle,
      () => { messages.clear(PageMessageType.NOTICE) },
    ))
  }

  const warnings = messages.getWarnings()
  if (messages.hasWarnings() && warnings) {
    parts.push(getRenderedMessages(
      'warnings',
      warnings,
      'pageMessages pageMessagesWarnings',
      alert,
      () => { messages.clear(PageMessageType.WARNING) },
    ))
  }
  const errors = messages.getErrors()
  if (messages.hasErrors() && errors) {
    parts.push(getRenderedMessages(
      'errors',
      errors,
      'pageMessages pageMessagesErrors',
      alert,
      () => { messages.clear(PageMessageType.ERROR) },
    ))
  }

  return (
    <div className='pageMessagesWrapper'>
      {parts}
    </div>)
}

export default PageMessages
