import type { TfaAttribute } from '../definitions'
import clearAttribute from './clearAttribute'
import checkIfValueIsAvailable from './checkIfValueIsAvailable'
import getOldValues from './getOldValues'
import getVerificationStatus from './getVerificationStatus'
import saveAttribute from './saveAttribute'
import sendTfaNotification from './sendTfaNotification'
import validateInput from './validateInput'

const requiresUniqueCheck = (attribute: TfaAttribute): boolean => attribute !== 'password'

const index = {
  clearAttribute,
  checkIfValueIsAvailable,
  getOldValues,
  getVerificationStatus,
  saveAttribute,
  requiresUniqueCheck,
  sendTfaNotification,
  validateInput,
}

export default index
