import React from 'react'
import { IonList } from '@ionic/react'

import type { ContactUserListItem, UserIdentInfo } from '../../lib/core/definitions'
import UserListGroupHeader from './UserListGroupHeader'
import UserListItem from './UserListItem'

type Props = {
  users: (ContactUserListItem | UserIdentInfo)[] | null
  selectedUserId: string | undefined
  selectButtonLabel: string
  isLoadingUsers?: boolean
  className?: string
  onSelectUser: (user: ContactUserListItem | UserIdentInfo | undefined) => void
}

const UserList: React.FC<Props> = (props): JSX.Element | null => {
  const {
    users,
    selectedUserId,
    selectButtonLabel,
    isLoadingUsers,
    className,
    onSelectUser,
  } = props

  if (!Array.isArray(users) || users.length < 1) {
    return null
  }

  let items: JSX.Element[] | undefined
  const contactUsers = users.filter(u => u.contactId)
  const otherUsers = users.filter(u => !u.contactId)

  const getItems = (users: (ContactUserListItem | UserIdentInfo)[]): JSX.Element[] => {
    return (
      users.map((user): JSX.Element => (
        <UserListItem
          key={user.id}
          user={user as ContactUserListItem | UserIdentInfo}
          isSelected={selectedUserId === user.id}
          buttonLabel={selectButtonLabel}
          onSelectUser={onSelectUser}
        />
      ))
    )
  }

  if (Array.isArray(contactUsers) && contactUsers.length > 0) {
    items = getItems(contactUsers)
    items.unshift(<UserListGroupHeader key='contact-gh' label='Contacts' />)
  }
  if (Array.isArray(otherUsers) && otherUsers.length > 0) {
    const items2 = getItems(otherUsers)
    items2.unshift(<UserListGroupHeader key='other-gh' label='Other Members' className='withStandardTopMargin' />)
    items = items ? items.concat(items2) : items2
  }

  return (
    <IonList className={`user-list ${className || ''}`}>
      {items}
    </IonList>
  )
}

export default UserList
