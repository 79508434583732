import React from 'react'
import { bulbOutline } from 'ionicons/icons'

import type { Contact } from '../../../../lib/core/definitions'
import WishList from '../../../../components/WishList/WishList'

type Props = {
  contact: Contact | null | undefined
  onOpenWishExternalUrl: (wishId: string, url: string) => void
  showUiMessage: (message: string) => void
}

const ThoughtsSection: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contact,
    onOpenWishExternalUrl,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // Rendering:
  if (!contact) {
    return null
  }

  let content: JSX.Element | undefined
  if (contact && contact.wishes && contact.wishes.length >= 0) {
    content = (
      <WishList
        wishes={contact.wishes}
        owner='contact'
        readOnly={false}
        helpText='Never forget that perfect gift idea!'
        contactId={contact.id}
        icon={bulbOutline}
        emptyListText={`You haven't added any thoughts for ${contact.toUser?.fullName || 'this contact'} yet.`}
        className='withStandardLeftMargin withStandardRightMargin'
        onOpenExternalUrl={onOpenWishExternalUrl}
        showUiMessage={showUiMessage}
      />
    )
  }

  return (
    <div className='autoOverflowContainer withStandardMargin'>
      {content}
    </div>
  )
}

export default ThoughtsSection
