import { isPlatform } from '@ionic/react'
import type { ApolloClient } from '@apollo/client'

import type { AppRoute } from '../../enums'
import type { OAuthUserInput, User } from '../../lib/core/definitions'
import type { ReloadActiveUserOptions } from '../../contexts/MimbleDataContext/definitions'
import { ErrorCode } from '../../lib/core/enums'
import api from '../../services/api'
import auth from '../../services/auth'
import firebase from '../../services/firebase'
import logger from '../../services/logger'

type Args = {
  ident?: string
  password?: string
  oAuthUser?: OAuthUserInput
  user?: User
  apolloClient: ApolloClient<any>
  reloadActiveUser: (options?: ReloadActiveUserOptions) => Promise<User | undefined>
  navigate: (route: AppRoute | string, replace?: boolean) => void
}

const handleSignIn = async (args: Args): Promise<User> => {
  logger.info('helpers.handleSignIn called.', { args: { ...args, password: 'xxx' } })

  const {
    ident,
    password,
    oAuthUser,
    apolloClient,
    reloadActiveUser,
    navigate,
  } = args
  let { user } = args

  if (!user) {
    if (oAuthUser) {
      try {
        user = await api.signInOAuthUser(oAuthUser, apolloClient)
      } catch (error) {
        logger.error('helpers.pageHelpers.handleSignIn: Error signing in.', { error })
        throw new Error(ErrorCode.SYSTEM_ERROR)
      }
    } else {
      // console.log('helpers.pageHelpers.handleSignIn called.', {
      //   // @ts-ignore
      //   apolloClientEndpoint: apolloClient.endpoint,
      // })
      try {
        user = await api.signIn(
          ident as string,
          password as string,
          apolloClient,
        )
      } catch (error) {
        logger.error('helpers.pageHelpers.handleSignIn: Error signing in.', { error })
        throw new Error(ErrorCode.SYSTEM_ERROR)
      }
    }
  }

  if (!user) {
    logger.error('helpers.pageHelpers.handleSignIn: sign in did not return a user.')
    throw new Error(ErrorCode.SYSTEM_ERROR)
  }

  auth.signIn(user, apolloClient, navigate)

  const reloadedUser = await reloadActiveUser({ reloadAll: true })

  if (isPlatform('capacitor')) {
    console.log('helpers.pageHelpers.handleSignIn: will call reportPushNotificationTokenToServer in 4 sec.')
    setTimeout(() => {
      console.log('helpers.pageHelpers.handleSignIn: calling reportPushNotificationTokenToServer.')
      firebase.reportPushNotificationTokenToServer(undefined, apolloClient)
    }, 4000)
  } else {
    console.log('helpers.pageHelpers.handleSignIn: not a native app, not calling reporting PN token.')
  }

  logger.info('helpers.pageHelpers.handleSignIn: success.')

  return reloadedUser || user
}

export default handleSignIn
