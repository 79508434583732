import type { UserDevicesQueryVariables } from '../definitions'
import type { UserDeviceListFilter } from '../../../lib/core/definitions'
import getUserDeviceListFilter from './getUserDeviceListFilter'

const getUserDevicesQueryVariables = (
  listFilter: UserDeviceListFilter | undefined,
  userId: string,
): UserDevicesQueryVariables => ({
  filter: listFilter || getUserDeviceListFilter(userId),
})

export default getUserDevicesQueryVariables
