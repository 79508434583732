import React from 'react'

import './styles.css'
import type { Chat } from '../../../lib/core/definitions'
import { IonList } from '@ionic/react'
import { useMimbleData } from '../../../contexts/MimbleDataContext/MimbleDataContext'
import coreHelpers from '../../../lib/core/helpers'
import OrgChatListItem from './OrgChatListItem'

export enum SortMethod {
  CHRONOLOGICALLY = 'chronologically',
  BY_CONTACT_NAME = 'by-contact-name',
}

type Props = {
  chats: Chat[] | null | undefined
  onOpenChat: (chatId: string) => void
  sortMethod?: SortMethod
}

const OrgChatList: React.FC<Props> = (props): JSX.Element | null => {
  const { chats, onOpenChat, sortMethod } = props
  // console.log('ChatList.render called.', { props })

  // ===================================================================================================================
  // State:
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  // ===================================================================================================================
  // Helpers:
  if (!Array.isArray(chats) || chats.length < 1) {
    return null
  }

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSelectedChatId = (chatId: string): void => {
    if (onOpenChat) {
      onOpenChat(chatId)
    }
  }

  // ===================================================================================================================
  // Rendering:
  const chatListItems = chats
    .slice(0)
    .sort((chat1: Chat, chat2: Chat): number => {
      if (sortMethod === SortMethod.BY_CONTACT_NAME) {
        // Sorted alphabetically by contact name
        const chat1Contact = coreHelpers.models.compareId(chat1.fromUserId, activeUserId) ? chat1.toUser : chat1.fromUser
        const chat2Contact = coreHelpers.models.compareId(chat2.fromUserId, activeUserId) ? chat2.toUser : chat2.fromUser
        if (!chat1Contact || !chat2Contact) {
          return 0
        }
        return (chat1Contact.fullName || chat1Contact.username || '')
          .localeCompare(chat2Contact.fullName || chat2Contact.username || '') || 0
      }
      if (!sortMethod || sortMethod === SortMethod.CHRONOLOGICALLY) {
        return parseInt(chat2.updatedAt || '') - parseInt(chat1.updatedAt || '')
      }
      return 0
    })
    .map((chat: Chat) => <OrgChatListItem key={chat.id} chat={chat} onClick={onChangeSelectedChatId} />)

  return (
    <IonList className='mimble-team-chat-contact-list'>
      {chatListItems}
    </IonList>
  )
}

export default OrgChatList
