/*
import { getAuth } from 'firebase/auth'

import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import serviceData from './serviceData'

export default (): void => {
  console.log('firebase.signOut called.')
  if (!serviceData.isServiceActive(globalCacheData.getEnvironment())) {
    console.log('firebase.signOut: service not active')
    return
  }
  const firebaseAuth = getAuth()
  if (!firebaseAuth) {
    console.log('firebase.signOut: no auth available')
    return
  }
  firebaseAuth.signOut().then(() => {
    globalCacheData.clearOAuthProvider()
    console.log('firebase.signOut: finished.')
  }, (error) => {
    console.error(error)
  })
}
*/
import { ErrorCode } from '../../lib/core/enums'

export default (): void => {
  throw new Error(ErrorCode.NOT_IMPLEMENTED)
}
