import type { Chat, ChatAttachmentInfo, ChatMessage } from '../../../definitions'

export interface GetChatAndAttachmentInfoResult {
  chatMessage?: ChatMessage;
  chatAttachmentInfo?: ChatAttachmentInfo;
}

const getChatAndAttachmentInfo = (chat: Chat, chatMessageId: string, chatAttachmentId: string): GetChatAndAttachmentInfoResult => {
  if (
    !chat ||
    !Array.isArray(chat.messages) ||
    chat.messages.length < 1
  ) {
    return {}
  }
  const chatMessage = chat.messages.find(m => m.id === chatMessageId)
  if (
    !chatMessage ||
    !chatMessage.metadata ||
    !Array.isArray(chatMessage.metadata.attachments) ||
    chatMessage.metadata.attachments.length < 1
  ) {
    return {}
  }
  const chatAttachmentInfo = chatMessage.metadata.attachments.find(
    a => a.chatAttachmentId === chatAttachmentId,
  )
  return { chatMessage, chatAttachmentInfo }
}

export default getChatAndAttachmentInfo
