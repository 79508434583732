import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import { GlobalCacheDataKey } from '../../contexts/GlobalCacheContext/enum'
import type {
  SignInOAuthUserMutationData,
  SignInOAuthUserMutationVariables,
} from '../apollo/definitions'
import type { OAuthUserInput, User, UserDeviceInput } from '../../lib/core/definitions'
import apollo from '../apollo'
import globalCacheData from '../../contexts/GlobalCacheContext/contextData'
import upsertUserDevice from './upsertUserDevice'
import logger from '../logger'

const signInOAuthUser = (
  oAuthUserInput: OAuthUserInput,
  apolloClient: ApolloClient<any>,
): Promise<User | undefined> => (
  new Promise((resolve, reject) => {
    // console.log('api.signInOAuthUser called.', { oAuthUserInput })

    if (!apolloClient) {
      logger.error('api.signInOAuthUser: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const options: MutationOptions<SignInOAuthUserMutationData, SignInOAuthUserMutationVariables> = {
      mutation: apollo.mutations.signInOAuthUser,
      variables: { user: oAuthUserInput },
      context: { skipAuthentication: true },
    }

    apolloClient.mutate<SignInOAuthUserMutationData, SignInOAuthUserMutationVariables>(options)
      .then((resp) => {
        // console.log('api.signInOAuthUser: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.signInOAuthUser
        ) {
          logger.error('api.signInOAuthUser: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        const user = resp.data.signInOAuthUser
        if (oAuthUserInput) {
          user.oAuthProvider = oAuthUserInput.provider
        }
        resolve(user)

        // Report device:
        if (user && user.id && user.authToken) {
          const pushNotificationToken = globalCacheData.getValue(GlobalCacheDataKey.FCM_TOKEN)
          if (pushNotificationToken) {
            const userDeviceInput: UserDeviceInput = {
              deviceUuid: globalCacheData.getDeviceId(),
              pushNotificationToken,
              userId: user.id,
            }
            upsertUserDevice(
              userDeviceInput,
              undefined,
              undefined,
              user.id,
              apolloClient,
              undefined,
              { updateList: false },
            ).then(undefined, (error) => {
              logger.error('services.firebase.reportSessionState: upsertUserDevice returned error.', { error })
              reject(error)
            })
          }
        }
      }, (error) => {
        logger.error('api.signInOAuthUser: error', { error })
        reject(error)
      })
  })
)

export default signInOAuthUser
