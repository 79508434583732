import { IonItem } from '@ionic/react'
import moment from 'moment'
import React from 'react'

import './styles.css'
import type { StoredProductListItem } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import typeHelpers from '../../../lib/core/helpers/type'

type Props = {
  storedProductListItem: StoredProductListItem;
  showDetails?: boolean;
  onEditStoredProduct?: (productId: string, productOptionId: string, storedProductId: string) => void;
};

const StoredProductListRow: React.FC<Props> = (props): JSX.Element | null => {
  const {
    storedProductListItem,
    showDetails,
    onEditStoredProduct,
  } = props

  const storedProductId: string = storedProductListItem.id as string
  const formattedAmount = coreHelpers.ui.formatAmount(
    storedProductListItem.amount,
    storedProductListItem.fundType,
    storedProductListItem.currency,
    false,
    0,
  )
  const formattedDiscount = coreHelpers.ui.formatPercentAmount(storedProductListItem.discount, 2)

  const onClickStoredProduct = (storedProductId: string): void => {
    // if (onSelectStoredProduct) {
    //   onSelectStoredProduct(storedProductId)
    // }
  }

  const onEditStoredProductLocal = (): void => {
    if (storedProductListItem && onEditStoredProduct) {
      onEditStoredProduct(storedProductListItem.productId, storedProductListItem.productOptionId, storedProductId)
    }
  }

  let details
  if (showDetails) {
    details = (
      <div className='storedProductListRow0Col1'>
        {/* <IonButton className="storedProductListRowButton" onClick={onClickEditStoredProduct}>Edit</IonButton> */}
      </div>
    )
  }

  return (
    <IonItem lines='none' className='storedProductListRow' onClick={onEditStoredProductLocal}>
      <div className='storedProductListRowItemContent'>
        <div className='storedProductListRow0'>
          <div className='storedProductListRowCol storedProductListRowCol0'>
            {storedProductListItem.merchantName}
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col1'>
            {formattedAmount}
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col2'>
            {formattedDiscount}
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col3'>
            {storedProductListItem.code}
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col4'>
            {moment(parseInt(storedProductListItem.createdAt)).format('MM/DD/YY')}
          </div>
          <div className='storedProductListRowCol storedProductListRow0Col5'>
            {typeHelpers.storedProductStatus.getLabel(storedProductListItem.status)}
          </div>
        </div>
        {details}
      </div>
    </IonItem>
  )
}

export default StoredProductListRow
