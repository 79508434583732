import type { WalletsQueryVariables } from '../definitions'
import type { WalletListFilter } from '../../../lib/core/definitions'
import getWalletListFilter from './getWalletListFilter'

const getWalletsQueryVariables = (
  listFilter: WalletListFilter | undefined,
  activeUserId: string,
): WalletsQueryVariables => ({
  filter: listFilter || getWalletListFilter(activeUserId),
})

export default getWalletsQueryVariables
