import { AnimationName } from '../lib/core/enums'
import balloons from './1664-balloons.json'
import confettiCannon from './7893-confetti-cannons.json'
import confettiCenter from './35875-confetti-on-transparent-background.json'
import confettiSmall from './53513-confetti.json'
import confettiFalling from './17252-colorful-confetti.json'
import greenFireworks from './3287-fireworks.json'
import mimbleFireworks from './00001-mimble-fireworks.json'
import pastelFireworks from './7393-fireworks.json'
import redFireworks from './1801-fireworks.json'
import snow from './13351-snowfall.json'

export default {
  fullPage: {
    [AnimationName.BALLOONS]: balloons,
    [AnimationName.CONFETTI_CANNON]: confettiCannon,
    [AnimationName.CONFETTI_CENTER]: confettiCenter,
    [AnimationName.CONFETTI_SMALL]: confettiSmall,
    [AnimationName.CONFETTI_FALLING]: confettiFalling,
    [AnimationName.GREEN_FIREWORKS]: greenFireworks,
    [AnimationName.MIMBLE_FIREWORKS]: mimbleFireworks,
    [AnimationName.PASTEL_FIREWORKS]: pastelFireworks,
    [AnimationName.RED_FIREWORKS]: redFireworks,
    [AnimationName.SNOW]: snow,
  },
}
