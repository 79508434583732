import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type { Product, ProductListItem } from '../../../lib/core/definitions'
import type {
  ProductQueryData,
  ProductQueryVariables,
  ProductsQueryData,
  ProductsQueryVariables,
} from '../../apollo/definitions'
import apollo from '../../apollo'
import coreHelpers from '../../../lib/core/helpers'
import logger from '../../logger'

const updateInList = (
  product: Product,
  queryListVariables: ProductsQueryVariables,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateProduct.updateInList called.', { product })
  if (!product) {
    logger.error('api.cache.updateProduct.updateInList: no product given.')
    return
  }

  try {
    const cachedProductsData = apolloClient.readQuery<ProductsQueryData, ProductsQueryVariables>({
      query: apollo.queries.products,
      variables: queryListVariables,
    })

    if (
      !cachedProductsData ||
      !Array.isArray(cachedProductsData.products) ||
      cachedProductsData.products.length < 1
    ) {
      const listItem = coreHelpers.models.product.updateProductListItemFromProduct(undefined, product)
      apolloClient.writeQuery<ProductsQueryData, ProductsQueryVariables>({
        query: apollo.queries.products,
        variables: queryListVariables,
        data: { products: [listItem] },
      })
      return
    }

    const updatedProducts = coreHelpers.models.updateModelInList<ProductListItem, Product>(
      cachedProductsData.products,
      product,
      undefined,
      true,
      coreHelpers.models.product.updateProductListItemFromProduct,
    )

    apolloClient.writeQuery<ProductsQueryData, ProductsQueryVariables>({
      query: apollo.queries.products,
      variables: queryListVariables,
      data: { products: updatedProducts },
    })
  } catch (error) {
    logger.error('api.cache.updateProduct.updateInList: error.', { error })
  }
}

const updateProduct = (
  product: Product,
  queryListVariables: ProductsQueryVariables | undefined,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateProduct called.', { product })
  if (!apolloClient) {
    logger.error('api.cache.updateProduct: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!product || !product.id) {
    logger.error('api.cache.updateProduct: no product given.', { product })
    return
  }

  try {
    apolloClient.writeQuery<ProductQueryData, ProductQueryVariables>({
      query: apollo.queries.product(),
      variables: { productId: product.id },
      data: { product },
    })

    if (queryListVariables) {
      updateInList(
        product,
        queryListVariables,
        apolloClient,
      )
    }
  } catch (error) {
    logger.error('api.cache.updateProduct: error.', { product, error })
  }
}

export default updateProduct
