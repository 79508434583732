import React, { useContext, useState } from 'react'
import { IonContent, IonPage, IonToast } from '@ionic/react'
import { Update } from 'history'
import { useLocation, useHistory, useParams } from 'react-router-dom'

// import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import { JoinPageStep } from './helpers'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import logger from '../../services/logger'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import SignUpForm from '../../components/SignUpForm/SignUpForm'

const appPageId = AppPage.JoinPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

type Params = {
  inviteCode: string
}

const JoinPage: React.FC = (props): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { inviteCode: inviteCodeFromParams } = useParams<keyof Params>() as unknown as Params

  logger.info('JoinPage.render called.', { inviteCodeFromParams })

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const pageMessages = useContext(PageMessagesContext)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [pageStep, setPageStep] = useState(JoinPageStep.INITIAL)

  // ===================================================================================================================
  // Helpers:
  const isProcessing = false

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // ===================================================================================================================
  // Event Handlers:
  const onNextFromSignUpForm = () => {
    navigate(AppRoute.HOME)
  }

  const onGoToSignIn = () => {
    navigate(AppRoute.SIGN_IN, true)
  }

  // ===================================================================================================================
  // Rendering:
  let stepContent: JSX.Element | undefined
  switch (pageStep) {
    case JoinPageStep.INITIAL:
      stepContent = (
        <SignUpForm
          inviteCodeFromParams={inviteCodeFromParams}
          onGoToSignIn={onGoToSignIn}
          onNext={onNextFromSignUpForm}
        />
      )
      break
  }
  return (
    <IonPage className='app-page-public join-page'>
      <NavBar
        title='Join Mimble'
        isProcessing={isProcessing}
      />
      <div className='g-centered-box-outer'>
        <IonContent forceOverscroll={false} className='g-content-with-padding g-centered-content'>
          <PageMessages />
          {stepContent}
        </IonContent>
        <AppPageFooter
        scope={appPageDef.appTabScope}
      />
        <IonToast
          isOpen={showToast}
          onDidDismiss={(): void => { setShowToast(false) }}
          message={toastMessage}
          duration={4000}
        />
      </div>
    </IonPage>
  )
}

export default JoinPage
