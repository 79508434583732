import React from 'react'
import { IonButton } from '@ionic/react'

import './styles.css'
import { AppRoute } from '../../../enums'
import { RewardType, FundType, TokenName } from '../../../lib/core/enums'
import type { RewardConfig } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import MimbleTokenImage from '../../../components/MimbleTokenImage/MimbleTokenImage'

type Props = {
  config: RewardConfig
  isSelected?: boolean
  onSelect: (rewardType: RewardType) => void
  onNavigateTo: (route: string) => void
}

const actionRoutes = {
  [RewardType.ADD_CONTACT]: AppRoute.CONTACTS,
  [RewardType.ADD_GIFT_IDEA]: AppRoute.CONTACTS,
  [RewardType.ADD_WISH]: `${AppRoute.USER_ACCOUNT}/wish-list`,
  [RewardType.GIFT_CARD_UPLOAD]: '/select-brand/upload-card',
  [RewardType.MARKETPLACE_PURCHASE]: AppRoute.MARKETPLACE,
  [RewardType.NEW_USER_INVITATION]: AppRoute.NEW_INVITATION,
  [RewardType.PURCHASE_TRANSFER]: AppRoute.WALLET,
  [RewardType.SHARE_BIRTHDAY]: AppRoute.USER_ACCOUNT,
  [RewardType.SHARE_WISH_LIST]: `${AppRoute.USER_ACCOUNT}/wish-list`,
}

const EarnRewardItem: React.FC<Props> = (props): JSX.Element | null => {
  const {
    config,
    isSelected,
    onSelect,
    onNavigateTo,
  } = props
  // @ts-ignore
  const actionRoute = actionRoutes[config.rewardType as RewardType] as string | undefined

  const handleSeeMore = () => {
    if (config && config.rewardType) {
      onSelect(config.rewardType)
    }
  }

  let detailsSection: JSX.Element | undefined

  if (config.descriptionEn) {
    if (isSelected) {
      detailsSection = (
        <div className='details-section'>
          {config.descriptionEn}
        </div>
      )
    } else {
      detailsSection = (
        <div onClick={handleSeeMore} className='see-more'>see more</div>
      )
    }
  }

  const displayAmount = config.amount
    ? coreHelpers.ui.formatAmount(
      config.amount,
      FundType.TOKEN,
      TokenName.MIMBLE_TOKEN,
      true,
    )
    : '(MIT varying)'

  const handleClick = (): void => {
    if (actionRoute) {
      onNavigateTo(actionRoute)
    }
  }

  let actionButton
  if (actionRoute) {
    actionButton = (
      <IonButton
        size='small'
        color='primary'
        onClick={handleClick}
      >
        Earn
      </IonButton>
    )
  }

  return (
    <div key={config.rewardType} className='earn-reward-item'>
      <div className='row0'>
        <div className='rowWithCenterAlignedItems'>
          <MimbleTokenImage className='token-image' size={30} />
          <span>
            <span className='withBoldText'>
              {displayAmount}
            </span>
            &nbsp;for&nbsp;
            <span className='withBoldText'>
              {config.labelEn}
            </span>
          </span>
        </div>
        <div>
          {actionButton}
        </div>
      </div>
      {detailsSection}
    </div>
  )
}

export default EarnRewardItem
