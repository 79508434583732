import React from 'react'

import './styles.css'
import type { Contact, User } from '../../lib/core/definitions'
import { LocalPreference } from '../../enums'
import type { TopNavTabDef } from '../TopNavBar/definitions'
import { TopNavTabId } from '../TopNavBar/enums'
import { useGlobalCache } from '../../contexts/GlobalCacheContext/GlobalCacheContext'
import Avatar from '../Avatar/Avatar'
import coreHelpers from '../../lib/core/helpers'
import FavoriteContactButton from '../../components/FavoriteContactButton/FavoriteContactButton'
import TopNavBar from '../../components/TopNavBar/TopNavBar'

const topNavTabDefs: TopNavTabDef[] = [
  { id: TopNavTabId.CONTACT_MESSAGES, label: 'Messages' },
  { id: TopNavTabId.CONTACT_IDEAS, label: 'Ideas' },
  { id: TopNavTabId.CONTACT_PROFILE, label: 'Profile' },
]

type Props = {
  contact: Contact | null | undefined
  user: User | null | undefined
  topNavTabId: TopNavTabId | undefined
  onOpenContact?: (contactId: string | null | undefined, userId: string | null | undefined, tabId: TopNavTabId) => void
  showUiMessage: (message: string) => void
}

const ContactHeader: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contact,
    user,
    topNavTabId,
    onOpenContact,
    showUiMessage,
  } = props

  // ===================================================================================================================
  // State:
  const { getPreference } = useGlobalCache()
  const expand = getPreference(LocalPreference.EXPAND_CONTACT_HEADER) !== 'false'

  // ===================================================================================================================
  // Helpers:
  const contactId = contact ? contact.id : undefined
  let userId = user ? user.id : undefined
  if (!userId && contact) {
    userId = contact.toUserId
  }

  let fullName = user ? user.fullName : undefined
  if (!fullName && contact && contact.toUser) {
    fullName = contact.toUser.fullName
  }

  let username = user ? user.username : undefined
  if (!username && contact && contact.toUser) {
    username = contact.toUser.username
  }

  // ===================================================================================================================
  // Event Handlers:
  const onClickTopNavTab = (navKey: TopNavTabId): void => {
    // console.log('ContactHeader.onClickTopNavTab called.', { navKey, onOpenContact })
    if (onOpenContact) {
      // console.log('ContactHeader.onClickTopNavTab: calling onOpenContact.', { contactId, userId, navKey })
      onOpenContact(contactId, userId, navKey)
    }
  }

  const onOpenContactInfo = (): void => {
    if (onOpenContact) {
      onOpenContact(contactId, userId, TopNavTabId.CONTACT_PROFILE)
    }
  }

  // ===================================================================================================================
  // Rendering:
  let avatar: JSX.Element | undefined
  if (user) {
    avatar = (
      <Avatar
        user={user}
        className='avatar'
      />
    )
  }

  let navTabs: JSX.Element | undefined
  if (expand && user && !user.isOrganization) {
    navTabs = (
      <TopNavBar
        tabs={topNavTabDefs}
        onClickTab={onClickTopNavTab}
        activeTabId={topNavTabId || TopNavTabId.CONTACT_MESSAGES}
      />
    )
  }

  return (
    <div className='contact-header'>
      <div className='row0'>
        <div className='center-block' onClick={onOpenContactInfo}>
          {avatar}
          <div className='withStandardPadding'>
            <div key='line1' className='contact-name'>
              {coreHelpers.models.contact.getContactNames(contact, user).composite}
            </div>
            <div key='line2'>
              <span className='lightText'><span className='handleGlyph'>@</span>{username}</span>
            </div>
          </div>
        </div>
        <div className='favorite-button'>
          <FavoriteContactButton
            contactId={contact && contact.id}
            isFavorite={contact && contact.favorite}
            showUiMessage={showUiMessage}
          />
        </div>
      </div>
      {navTabs}
    </div>
  )
}

export default ContactHeader
