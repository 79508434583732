import React from 'react'

import type { ShoppingCart, ShoppingCartItem } from '../../../lib/core/definitions'
import type { TransactionProcessor } from '../../../lib/core/enums'
import ChipButton from '../../../components/ChipButton/ChipButton'
import coreHelpers from '../../../lib/core/helpers/'
import MimbleTokenImage from '../../../components/MimbleTokenImage/MimbleTokenImage'

type Props = {
  shoppingCart: ShoppingCart
  item: ShoppingCartItem
  index: number
  transactionProcessor: TransactionProcessor
  readonly: boolean | undefined
  onEdit: () => void
  onRemoveItem: (itemId: string) => void
}

const ShoppingCartRowToken: React.FC<Props> = (props): JSX.Element | null => {
  const {
    shoppingCart,
    item,
    readonly,
    onEdit,
    onRemoveItem,
  } = props
  const { productCount } = item
  const paymentFundType = shoppingCart && shoppingCart.paymentFundType
  const paymentCurrency = shoppingCart && shoppingCart.paymentCurrency
  const paymentExchangeRate = shoppingCart && shoppingCart.paymentExchangeRate
  const amountsInfo = coreHelpers.models.shoppingCartItem.getAmountsInfo(
    item,
    paymentFundType,
    paymentCurrency,
    paymentExchangeRate,
  )
  const formattedPurchaseAmount = coreHelpers.ui.formatAmount(
    productCount,
    amountsInfo.productAmount.fundType,
    amountsInfo.productAmount.currency,
    false,
    amountsInfo.productAmount.precision,
  )
  const formattedPaymentAmount = amountsInfo.paymentAmount.amount && amountsInfo.paymentAmount.amount > 0
    ? coreHelpers.ui.formatAmount(
      amountsInfo.paymentAmount.amount,
      amountsInfo.paymentAmount.fundType,
      amountsInfo.paymentAmount.currency,
      false,
      amountsInfo.paymentAmount.precision,
    )
    : ''

  const handleRemoveItem = () => {
    if (!item || !item.id) {
      return
    }
    onRemoveItem(item.id)
  }

  let editButton: JSX.Element | undefined
  if (!readonly) {
    editButton = (
      <ChipButton
        text='Edit'
        block={false}
        onClick={onEdit}
      />
    )
  }

  let removeButton: JSX.Element | undefined
  if (!readonly) {
    removeButton = (
      <ChipButton
        text='Remove'
        block={false}
        color='var(--ion-color-medium)'
        className='withStandardLeftMargin'
        onClick={handleRemoveItem}
      />
    )
  }

  let buttonRow: JSX.Element | undefined
  if (!readonly) {
    buttonRow = (
      <div className='withSmallTopMargin'>
        {editButton}
        {removeButton}
      </div>
    )
  }

  return (
    <div key={item.id} className='cart-row'>
      <div className='cart-col cart-col0'>
        <div className='withCenteredColumnContent'>
          <MimbleTokenImage size={26} className='token-image' />
        </div>
      </div>
      <div className='cart-col cart-col1'>
        <div>Mimble Tokens (MIT)</div>
        {buttonRow}
      </div>
      <div className='cart-col cart-col2 withRightTextAlign'>
        {formattedPurchaseAmount}
      </div>
      <div className='cart-col cart-col3 withRightTextAlign'>
        {formattedPaymentAmount}
      </div>
    </div>
  )
}

export default ShoppingCartRowToken
