import React, { useEffect, useState } from 'react'
import { IonButton, IonCheckbox, IonInput } from '@ionic/react'

import './styles.css'
import type { CryptoCurrency } from '../../../lib/core/enums'
import { FiatCurrency, FundType, TokenName } from '../../../lib/core/enums'
import type { ProductOption, ProductOptionInput } from '../../../lib/core/definitions'
import coreHelpers from '../../../lib/core/helpers'
import FormItem from '../../../components/FormItem/FormItem'
import validationHelpers from '../../../helpers/validationHelpers'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'

const getActiveFundType = (fundType?: FundType, productOption?: ProductOption | null): FundType => {
  if (fundType) {
    return fundType
  }
  if (productOption && productOption.fundType) {
    return productOption.fundType
  }
  return FundType.FIAT
}

const getActiveCurrency = (currency?: CryptoCurrency | FiatCurrency | string, productOption?: ProductOption | null): CryptoCurrency | FiatCurrency | string => {
  if (currency) {
    return currency
  }
  if (productOption && productOption.currency) {
    return productOption.currency
  }
  return FiatCurrency.USD
}

type Props = {
  productOption?: ProductOption | null
  onGoBack: () => void
  onSave: (productOptionInput: ProductOptionInput) => void
}

const ProductOptionForm: React.FC<Props> = ({
  productOption = null,
  onSave,
  onGoBack,
}): JSX.Element => {
  const [orderIndex, setOrderIndex] = useState<number | undefined>()
  const [orderIndexValidationError, setOrderIndexValidationError] = useState<string | undefined>()
  const [labelEn, setLabelEn] = useState<string | undefined>()
  const [labelEnValidationError, setLabelEnValidationError] = useState<string | undefined>()
  const [amount, setAmount] = useState(undefined as number | null | undefined)
  const [amountValidationError, setAmountValidationError] = useState<string | undefined>()
  const [fundType, setFundType] = useState(undefined as FundType | undefined)
  const [fundTypeValidationError, setFundTypeValidationError] = useState<string | undefined>()
  const [currency, setCurrency] = useState(undefined as CryptoCurrency | FiatCurrency | string | undefined)
  const [currencyValidationError, setCurrencyValidationError] = useState<string | undefined>()
  const [reward, setReward] = useState(undefined as number | null | undefined)
  const [rewardValidationError, setRewardValidationError] = useState<string | undefined>()
  const [adminNotes, setAdminNotes] = useState<string | undefined>()
  const [adminNotesValidationError, setAdminNotesValidationError] = useState<string | undefined>()
  const [listed, setListed] = useState(undefined as boolean | undefined)

  // ===================================================================================================================
  // Helpers:
  const orderIndexChanged = orderIndex !== undefined && !(productOption && orderIndex === productOption.orderIndex)
  const labelEnChanged = labelEn !== undefined && !(productOption && labelEn === productOption.labelEn)
  const amountChanged = amount !== undefined && !(productOption && amount === productOption.amount)
  const fundTypeChanged = fundType !== undefined && !(productOption && fundType === productOption.fundType)
  const currencyChanged = currency !== undefined && !(productOption && currency === productOption.currency)
  const rewardChanged = reward !== undefined && !(productOption && reward === productOption.reward)
  const adminNotesChanged = adminNotes !== undefined && !(productOption && adminNotes === productOption.adminNotes)
  const listedChanged = listed !== undefined && !(productOption && listed === productOption.listed)

  const isDirty = (
    orderIndexChanged ||
    labelEnChanged ||
    amountChanged ||
    fundTypeChanged ||
    currencyChanged ||
    rewardChanged ||
    adminNotesChanged ||
    listedChanged
  )
  const isValid = (
    !orderIndexValidationError &&
    !labelEnValidationError &&
    !amountValidationError &&
    !fundTypeValidationError &&
    !currencyValidationError &&
    !rewardValidationError &&
    !adminNotesValidationError
  )

  const resetForm = (): void => {
    setOrderIndex(undefined)
    setOrderIndexValidationError('')
    setLabelEn(undefined)
    setLabelEnValidationError('')
    setAmount(undefined)
    setAmountValidationError('')
    setFundType(undefined)
    setFundTypeValidationError('')
    setCurrency(undefined)
    setCurrencyValidationError('')
    setReward(undefined)
    setRewardValidationError('')
    setAdminNotes(undefined)
    setAdminNotesValidationError('')
    setListed(undefined)
  }

  // ===================================================================================================================
  // Effect Handlers:
  useEffect((): void => {
    console.log('ProductOptionForm: new productOption received - resetting form.')
    resetForm()
  }, [productOption])

  // ===================================================================================================================
  // Event Handlers:

  const onChangeOrderIndex = (event: any): void => {
    if (
      (productOption && (event.detail.value === productOption.orderIndex || (!event.detail.value && !productOption.orderIndex))) ||
      (!productOption && !event.detail.value)
    ) {
      setOrderIndexValidationError('')
      setOrderIndex(undefined)
      return
    }
    setOrderIndex(parseInt(event.detail.value, 10))
    if (event.detail.value) {
      setOrderIndexValidationError(validationHelpers.validateOrderIndex(event.detail.value))
    } else {
      setOrderIndexValidationError('')
    }
  }

  const onChangeLabelEn = (event: any): void => {
    if (
      (productOption && (event.detail.value === productOption.labelEn || (!event.detail.value && !productOption.labelEn))) ||
      (!productOption && !event.detail.value)
    ) {
      setLabelEnValidationError('')
      setLabelEn(undefined)
      return
    }
    setLabelEn(event.detail.value)
    if (event.detail.value) {
      setLabelEnValidationError(validationHelpers.validateProductOptionLabel(event.detail.value))
    } else {
      setLabelEnValidationError('')
    }
  }

  const onChangeAmount = (event: any): void => {
    const displayAmount = parseFloat(event.detail.value)
    const activeFundType = getActiveFundType(fundType, productOption)
    const activeCurrency = getActiveCurrency(currency, productOption)
    const internalAmount = coreHelpers.ui.getInternalMoneyAmount(displayAmount, activeFundType, activeCurrency)

    if (productOption && internalAmount === productOption.amount) {
      setAmountValidationError('')
      setAmount(undefined)
      return
    }
    setAmount(event.detail.value === '' ? null : internalAmount)
    setAmountValidationError(validationHelpers.validateProductOptionAmount(internalAmount))
  }

  const onChangeFundType = (event: any): void => {
    if (
      (productOption && (event.detail.value === productOption.fundType || (!event.detail.value && !productOption.fundType))) ||
      (!productOption && !event.detail.value)
    ) {
      setFundTypeValidationError('')
      setFundType(undefined)
      return
    }
    setFundType(event.detail.value)
    if (event.detail.value) {
      setFundTypeValidationError(validationHelpers.validateFundType(event.detail.value))
    } else {
      setFundTypeValidationError('')
    }
  }

  const onChangeCurrency = (event: any): void => {
    if (
      (productOption && (event.detail.value === productOption.currency || (!event.detail.value && !productOption.currency))) ||
      (!productOption && !event.detail.value)
    ) {
      setCurrencyValidationError('')
      setCurrency(undefined)
      return
    }
    setCurrency(event.detail.value)
    if (event.detail.value) {
      setCurrencyValidationError(validationHelpers.validateCurrency(event.detail.value))
    } else {
      setCurrencyValidationError('')
    }
  }

  const onChangeReward = (event: any): void => {
    const displayReward = parseFloat(event.detail.value)
    const internalReward = coreHelpers.ui.getInternalMoneyAmount(displayReward, FundType.TOKEN, TokenName.MIMBLE_TOKEN)

    if (productOption && internalReward === productOption.reward) {
      setRewardValidationError('')
      setReward(undefined)
      return
    }
    setReward(event.detail.value === '' ? null : internalReward)
    setRewardValidationError(validationHelpers.validateProductOptionReward(internalReward))
  }

  const onChangeAdminNotes = (event: any): void => {
    if (
      (productOption && (event.detail.value === productOption.adminNotes || (!event.detail.value && !productOption.adminNotes))) ||
      (!productOption && !event.detail.value)
    ) {
      setAdminNotesValidationError('')
      setAdminNotes(undefined)
      return
    }
    setAdminNotes(event.detail.value)
    if (event.detail.value) {
      setAdminNotesValidationError(validationHelpers.validateAdminNotes(event.detail.value))
    } else {
      setAdminNotesValidationError('')
    }
  }

  const onChangeListed = (event: any): void => {
    if (
      (productOption && event.detail.checked === productOption.listed) ||
      (!productOption && event.detail.checked)
    ) {
      setListed(undefined)
      return
    }
    setListed(event.detail.checked)
  }

  const onClickSaveButton = (event: any): void => {
    event.preventDefault()
    const updatedProductOption: ProductOptionInput = {}
    if (productOption && productOption.id) {
      updatedProductOption.id = productOption.id
    }
    if (
      orderIndex !== undefined &&
      orderIndex !== null &&
      (!productOption || orderIndex !== productOption.orderIndex)
    ) {
      updatedProductOption.orderIndex = orderIndex
    }
    if (labelEn !== undefined && (!productOption || labelEn !== productOption.labelEn)) {
      updatedProductOption.labelEn = labelEn || null
    }
    if (
      amount !== undefined &&
      amount !== null &&
      (!productOption || amount !== productOption.amount)
    ) {
      updatedProductOption.amount = amount
    }
    if (fundType && fundTypeChanged) {
      updatedProductOption.fundType = fundType
    }
    if (
      (!productOption || !productOption.id) &&
      !updatedProductOption.fundType
    ) {
      updatedProductOption.fundType = FundType.FIAT
    }
    if (
      currency !== undefined &&
      currency !== null &&
      (!productOption || currency !== productOption.currency)
    ) {
      updatedProductOption.currency = currency
    }
    if (
      reward !== undefined &&
      reward !== null &&
      (!productOption || reward !== productOption.reward)
    ) {
      updatedProductOption.reward = reward
    }
    if (
      (!productOption || !productOption.id) &&
      !updatedProductOption.currency
    ) {
      updatedProductOption.currency = FiatCurrency.USD
    }
    if (adminNotes !== undefined && (!productOption || adminNotes !== productOption.adminNotes)) {
      updatedProductOption.adminNotes = adminNotes || null
    }
    if (listed !== undefined && (!productOption || listed !== productOption.listed)) {
      updatedProductOption.listed = listed
    }
    onSave(updatedProductOption)
  }

  const activeFundType = getActiveFundType(fundType, productOption)
  const activeCurrency = getActiveCurrency(currency, productOption)
  let internalAmount: number | null | undefined = productOption ? productOption.amount : undefined
  let amountDisplay = ''
  if (amount !== undefined) {
    internalAmount = amount
  }
  if (internalAmount !== null && internalAmount !== undefined) {
    amountDisplay = coreHelpers.ui.getDisplayMoneyAmount(
      internalAmount,
      activeFundType,
      activeCurrency,
    ).toString()
  }

  let internalReward: number | null | undefined = productOption ? productOption.reward : undefined
  let rewardDisplay = ''
  if (reward !== undefined) {
    internalReward = reward
  }
  if (internalReward !== null && internalReward !== undefined) {
    rewardDisplay = coreHelpers.ui.getDisplayMoneyAmount(
      internalReward,
      activeFundType,
      activeCurrency,
    ).toString()
  }

  let listedDisplay = productOption ? productOption.listed === true : true
  if (listed !== undefined) {
    listedDisplay = listed
  }

  return (
    <form onSubmit={onClickSaveButton}>
      <FormItem
        label='Order Index (0, 1, 2, ...)'
        validationError={orderIndexValidationError}
        withBottomMargin
      >
        <IonInput
          type='number'
          inputmode='numeric'
          onIonChange={onChangeOrderIndex}
          placeholder='0, 1, 2, ...'
          value={orderIndex !== undefined ? orderIndex.toString() : (productOption && productOption.orderIndex ? productOption.orderIndex.toString() : '')}
        />
      </FormItem>
      <FormItem
        label='Label'
        validationError={labelEnValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeLabelEn}
          placeholder='i.e. $50'
          value={labelEn !== undefined ? labelEn : (productOption ? productOption.labelEn : '')}
        />
      </FormItem>
      <FormItem
        label='Amount'
        validationError={amountValidationError}
        withBottomMargin
      >
        <IonInput
          type='number'
          inputmode='numeric'
          onIonChange={onChangeAmount}
          placeholder='amount'
          value={amountDisplay}
        />
      </FormItem>
      <FormItem
        label='Fund Type'
        validationError={fundTypeValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeFundType}
          placeholder='fiat or crypto or token'
          value={fundType !== undefined ? fundType : (productOption ? productOption.fundType : FundType.FIAT)}
        />
      </FormItem>
      <FormItem
        label='Currency'
        validationError={currencyValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeCurrency}
          placeholder='i.e. USD'
          value={currency !== undefined ? currency : (productOption ? productOption.currency : FiatCurrency.USD)}
        />
      </FormItem>
      <FormItem
        label='Reward'
        validationError={rewardValidationError}
        withBottomMargin
      >
        <IonInput
          type='number'
          inputmode='numeric'
          onIonChange={onChangeReward}
          placeholder='reward'
          value={rewardDisplay}
        />
      </FormItem>
      <FormItem
        label='Admin notes'
        validationError={adminNotesValidationError}
        withBottomMargin
      >
        <IonInput
          onIonChange={onChangeAdminNotes}
          placeholder='optional admin notes'
          value={adminNotes !== undefined ? adminNotes : (productOption ? productOption.adminNotes : '')}
        />
      </FormItem>
      <FormItem label='Listed?'>
        <IonCheckbox
          onIonChange={onChangeListed}
          checked={listedDisplay}
        />
      </FormItem>
      <div className='formButtonWrapper'>
        <IonButton
          color='light'
          className='withStandardRightMargin'
          onClick={onGoBack}
        >
          Back
        </IonButton>
        <SubmitButton
          onClick={onClickSaveButton}
          disabled={!isDirty || !isValid}
        >
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default ProductOptionForm
