import getRecipientIdentType from './getRecipientIdentType'
import getRecipientIdent from './getRecipientIdent'
import validateSignedInUser from './validateSignedInUser'
import evaluateData from './evaluateData'

const giftReceivedPageHelpers = {
  evaluateData,
  getRecipientIdent,
  getRecipientIdentType,
  validateSignedInUser,
}

export default giftReceivedPageHelpers
