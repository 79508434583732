import { AdminTaskType } from '../../admin/enum'

export interface AdminTaskParamDef {
  name: string;
  label?: string;
  description?: string;
}

const labelsEn = Object.freeze({
  [AdminTaskType.ADD_INVITE_CODE_TO_USER]: 'Add extended invite code to user/organization',
  [AdminTaskType.CANCEL_TRANSACTION]: '',
  [AdminTaskType.CLEAR_TASKS]: '',
  [AdminTaskType.CREATE_CHAT_MESSAGE]: '',
  [AdminTaskType.CREATE_CONTACT]: '',
  [AdminTaskType.CREATE_CORE_MESSAGE_TEMPLATES]: 'Create core message templates',
  [AdminTaskType.CREATE_USER_INVITATION]: '',
  [AdminTaskType.CREATE_MISSING_CONTACTS]: 'Create missing contacts',
  [AdminTaskType.CREATE_MISSING_REWARDS]: '',
  [AdminTaskType.CREATE_MISSING_TOKEN_DELIVERY_TRANSACTIONS]: '',
  [AdminTaskType.CREATE_ORDER]: 'Create order from shopping cart',
  [AdminTaskType.CREATE_PURCHASE]: '',
  [AdminTaskType.CREATE_TRANSFER_TARGET_PURCHASES_FOR_USER]: 'Assign pending gifts',
  [AdminTaskType.CREATE_TRANSACTION]: '',
  [AdminTaskType.DECRYPT_STRING]: '',
  [AdminTaskType.DO_DAILY_FRAUD_CONTROL_MAINTENANCE]: 'Run daily fraud control',
  [AdminTaskType.DO_DATA_MAINTENANCE]: 'Run data maintenance',
  [AdminTaskType.DELETE_REMOTE_BANKING_USER]: '',
  [AdminTaskType.DELETE_USER]: 'Delete user',
  [AdminTaskType.DELIVER_AVAILABLE_PRODUCTS]: 'Deliver available products',
  [AdminTaskType.EXECUTE_CODE]: 'Execute code',
  [AdminTaskType.LOAD_DB_CACHE]: 'Refresh Database Cache',
  [AdminTaskType.MERGE_USERS]: 'Merge two users',
  [AdminTaskType.PROCESS_TRANSACTION]: 'Process transaction',
  [AdminTaskType.QUERY_DB_VERSION]: '',
  [AdminTaskType.REBUILD_WALLET_EVENT_HISTORY]: 'Rebuild Wallet Event History',
  [AdminTaskType.REMAP_INVENTORY]: 'Remap Inventory',
  [AdminTaskType.REMOVE_APP_FEATURE]: 'Remove app feature',
  [AdminTaskType.REMOVE_TASK]: 'Removes an app task',
  [AdminTaskType.RESET_DAILY_CC_PURCHASE_LIMIT]: 'Reset daily purchase CC limits',
  [AdminTaskType.RESET_USER_PASSWORD]: '',
  [AdminTaskType.RUN_DB_TASK]: '',
  [AdminTaskType.RUN_MIGRATION]: '',
  [AdminTaskType.SEND_CAMPAIGN_MESSAGES]: 'Send campaign messages',
  [AdminTaskType.SEND_GIFTING_EVENT_REMINDERS]: 'Send gifting event reminders',
  [AdminTaskType.SEND_PURCHASE_TRANSFER_NOTIFICATION]: '',
  [AdminTaskType.SEND_PUSH_NOTIFICATION]: 'Send push notification',
  [AdminTaskType.SET_USER_PASSWORD]: '',
  [AdminTaskType.START_SHUTDOWN]: '',
  [AdminTaskType.UPDATE_CHAT_METADATA]: 'Update chat metadata',
  [AdminTaskType.UPDATE_INBOX]: '',
  [AdminTaskType.UPDATE_MARKETPLACE_METADATA]: 'Update marketplace metadata',
  [AdminTaskType.UPDATE_REWARD_CONFIGS]: 'Update reward configuration',
  [AdminTaskType.VERIFY_USER_PASSWORD]: '',
})

const paramDefs = Object.freeze({
  [AdminTaskType.ADD_INVITE_CODE_TO_USER]: [
    { name: 'userId', label: 'User ID' },
    { name: 'inviteCodeType', label: 'Type', description: 'One of: \'campaign\'.' },
    { name: 'name', label: 'Name' },
    { name: 'description', label: 'Description' },
  ],
  [AdminTaskType.CANCEL_TRANSACTION]: null,
  [AdminTaskType.CLEAR_TASKS]: null,
  [AdminTaskType.CREATE_CHAT_MESSAGE]: null,
  [AdminTaskType.CREATE_CONTACT]: null,
  [AdminTaskType.CREATE_CORE_MESSAGE_TEMPLATES]: null,
  [AdminTaskType.CREATE_USER_INVITATION]: null,
  [AdminTaskType.CREATE_MISSING_CONTACTS]: [
    { name: 'userId', label: 'User ID', description: 'Leave empty to do all users.' },
  ],
  [AdminTaskType.CREATE_MISSING_REWARDS]: null,
  [AdminTaskType.CREATE_MISSING_TOKEN_DELIVERY_TRANSACTIONS]: null,
  [AdminTaskType.CREATE_ORDER]: [
    { name: 'shoppingCartId', label: 'Shopping cart ID', description: 'Specify either the shopping cart, or the transaction ID' },
    { name: 'paymentTransactionId', label: 'Payment transaction ID' },
  ],
  [AdminTaskType.CREATE_PURCHASE]: null,
  [AdminTaskType.CREATE_TRANSFER_TARGET_PURCHASES_FOR_USER]: [
    { name: 'userId', label: 'User ID' },
  ],
  [AdminTaskType.CREATE_TRANSACTION]: null,
  [AdminTaskType.DECRYPT_STRING]: null,
  [AdminTaskType.DO_DAILY_FRAUD_CONTROL_MAINTENANCE]: null,
  [AdminTaskType.DO_DATA_MAINTENANCE]: [
    { name: 'schedule', label: 'Schedule', description: 'One of: \'hourly\', \'daily\', \'weekly\', \'monthly\'.' },
  ],
  [AdminTaskType.DELETE_REMOTE_BANKING_USER]: null,
  [AdminTaskType.DELETE_USER]: [
    { name: 'userId', label: 'User ID' },
    { name: 'delete-physically', label: 'Delete physically (y/n)', description: 'Set to \'y\' to completely remove the user, not only to mark it as deleted.' },
  ],
  [AdminTaskType.DELIVER_AVAILABLE_PRODUCTS]: null,
  [AdminTaskType.EXECUTE_CODE]: [{ name: 'code', label: 'Code' }],
  [AdminTaskType.LOAD_DB_CACHE]: null,
  [AdminTaskType.MERGE_USERS]: [
    { name: 'sourceUserId', label: 'Source Member ID' },
    { name: 'targetUserId', label: 'Target Member ID' },
  ],
  [AdminTaskType.PROCESS_TRANSACTION]: [
    { name: 'transactionId', label: 'Transaction ID' },
  ],
  [AdminTaskType.QUERY_DB_VERSION]: null,
  [AdminTaskType.REMAP_INVENTORY]: [
    { name: 'fromProductId', label: 'Source Product ID' },
    { name: 'targetProductId', label: 'Target Product ID' },
  ],
  [AdminTaskType.REBUILD_WALLET_EVENT_HISTORY]: [
    { name: 'walletId', label: 'Wallet ID' },
  ],
  [AdminTaskType.REMOVE_APP_FEATURE]: [
    { name: 'appFeature', label: 'App feature' },
  ],
  [AdminTaskType.REMOVE_TASK]: [
    { name: 'queue', label: 'Queue' },
    { name: 'taskId', label: 'Task ID' },
  ],
  [AdminTaskType.RESET_DAILY_CC_PURCHASE_LIMIT]: null,
  [AdminTaskType.RESET_USER_PASSWORD]: null,
  [AdminTaskType.RUN_DB_TASK]: null,
  [AdminTaskType.RUN_MIGRATION]: null,
  [AdminTaskType.SEND_CAMPAIGN_MESSAGES]: [
    { name: 'campaignId', label: 'Campaign ID' },
    { name: 'resend', label: 'Resend', description: '\'yes\' to resend messages' },
  ],
  [AdminTaskType.SEND_GIFTING_EVENT_REMINDERS]: [
    { name: 'userId', label: 'User ID', description: 'Leave empty to do all members.' },
    { name: 'localHour', label: 'Local hour', description: 'Hour of the day for the recipient of the notifications.' },
  ],
  [AdminTaskType.SEND_PURCHASE_TRANSFER_NOTIFICATION]: null,
  [AdminTaskType.SEND_PUSH_NOTIFICATION]: [
    { name: 'userId', label: 'User ID', description: 'Member ID.' },
    { name: 'title', label: 'Title' },
    { name: 'body', label: 'Body' },
    { name: 'appLink', label: 'App Link' },
  ],
  [AdminTaskType.SET_USER_PASSWORD]: null,
  [AdminTaskType.START_SHUTDOWN]: null,
  [AdminTaskType.UPDATE_CHAT_METADATA]: [
    { name: 'chatId', label: 'Chat ID', description: 'Leave empty to do all chats.' },
  ],
  [AdminTaskType.UPDATE_INBOX]: null,
  [AdminTaskType.UPDATE_MARKETPLACE_METADATA]: null,
  [AdminTaskType.UPDATE_REWARD_CONFIGS]: [
    { name: 'recreateTable', label: 'Recreate Table?', description: 'Enter \'true\' to recreate the table and apply structural changes. Otherwise leave empty.' },
  ],
  [AdminTaskType.VERIFY_USER_PASSWORD]: null,
})

export default {
  getLabel: (taskType?: AdminTaskType | null, language?: string): string => {
    if (!taskType) {
      return ''
    }
    if (!language || language === 'US') {
      return labelsEn[taskType]
    }
    return ''
  },
  getParamDefs: (taskType: AdminTaskType): AdminTaskParamDef[] | null => paramDefs[taskType],
}
