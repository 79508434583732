import { IonList } from '@ionic/react'
import React from 'react'

import './styles.css'
import type { PurchaseListItem } from '../../../../lib/core/definitions'
import { PurchaseListScope } from '../../../../lib/core/enums'
import { useGlobalCache } from '../../../../contexts/GlobalCacheContext/GlobalCacheContext'
import coreHelpers from '../../../../lib/core/helpers'
import GiftListRow from './GiftListRow'
import pageHelpers from '../../../../helpers/pageHelpers'

interface Props {
  purchases?: PurchaseListItem[] | null;
  selectedPurchaseId?: string | null;
  searchText?: string;
  onSelectPurchase: (purchaseId: string) => void;
}

const GiftList: React.FC<Props> = ({
  purchases,
  selectedPurchaseId,
  searchText,
  onSelectPurchase,
}): JSX.Element | null => {
  const { getActiveUserPrefs } = useGlobalCache()
  const activeUserPrefs = getActiveUserPrefs()
  const contrastPennies = activeUserPrefs ? activeUserPrefs.contrastPennies : false

  if (!Array.isArray(purchases) || purchases.length < 1) {
    return null
  }

  const items = pageHelpers
    .filterPurchaseList(purchases, searchText, PurchaseListScope.TRANSFERRABLE)
    .map((p): any => (
      <GiftListRow
        key={p.id as string}
        purchase={p}
        contrastPennies={contrastPennies}
        isSelected={!!selectedPurchaseId && coreHelpers.models.compareId(p.id, selectedPurchaseId)}
        onSelectPurchase={onSelectPurchase}
      />
    ))

  return (
    <IonList className='gift-list'>
      {items}
    </IonList>
  )
}

export default GiftList
