import { gql } from '@apollo/client'

export default gql`
  query Q {
    rewardConfigs {
      id
      rewardType
      occurrence
      invitationLevel
      amount
      fundType
      currency
      labelEn
      descriptionEn
      metadata
      recordStatus
    }
  }`
