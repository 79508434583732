import { gql } from '@apollo/client'

export default gql`
  query Q($userId: String!) {
    userSensitiveInfo (userId: $userId) {
      id
      userId
      legalName
      gender
      dob
      addressLine1
      addressLine2
      city
      regionCode
      postalCode
      countryCode
      ccNameOnCard
      ccAddressLine1
      ccAddressLine2
      ccCity
      ccRegionCode
      ccPostalCode
      ccCountryCode
      ipAddress
      metadata
      adminNotes
      recordStatus
      createdAt
      updatedAt
    }
  }
`
