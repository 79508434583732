import React, { useContext, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from '@ionic/react'
import type { RefresherEventDetail } from '@ionic/core'
import { Update } from 'history'
import { useApolloClient, useQuery } from '@apollo/client'
import { useLocation, useHistory, useParams } from 'react-router-dom'

import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import type { MerchantListFilter } from '../../lib/core/definitions'
import type { MerchantsQueryData, MerchantsQueryVariables } from '../../services/apollo/definitions'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../services/api'
import apollo from '../../services/apollo'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import auth from '../../services/auth'
import GiftFlowStepper from '../../components/GiftFlowStepper/GiftFlowStepper'
import ListHeader from '../../components/ListHeader/ListHeader'
import MerchantGrid from '../../components/MerchantGrid/MerchantGrid'
import NavBar from '../../components/NavBar/NavBar'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'

const appPageId = AppPage.SelectMerchantPage
const appPageDef = pageHelpers.appPageDefs[appPageId]
let refreshEvent: CustomEvent<RefresherEventDetail> | undefined
let filter: MerchantListFilter

type Params = {
  nextRoute: string
}

const SelectMerchantPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)
  const { nextRoute } = useParams<keyof Params>() as unknown as Params

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { activeUser, isLoadingActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [searchText, setSearchText] = useState<string | undefined>()

  // ===================================================================================================================
  // Apollo Hooks:
  const {
    data: merchantsData,
    loading: isLoadingMerchants,
    refetch: reloadMerchants,
  } = useQuery<MerchantsQueryData, MerchantsQueryVariables>(
    apollo.queries.merchants(true), {
      variables: { filter: { listed: true } },
      notifyOnNetworkStatusChange: true,
      onError (error) {
        console.error(error)
        // setToastMessage(error.message)
        // setShowToast(true)
      },
      onCompleted: () => {
        if (refreshEvent) {
          refreshEvent.detail.complete()
          refreshEvent = undefined
        }
      },
    },
  )
  const merchants = merchantsData ? merchantsData.merchants : undefined

  // ===================================================================================================================
  // Helpers:
  const isProcessing = isLoadingActiveUser || isLoadingMerchants

  if (!filter) {
    filter = {
      offset: 0,
      limit: 1000,
      version: new Date().getTime(),
    }
  }
  if (filter.searchText !== searchText) {
    filter.searchText = searchText
    // For now we don't pull a new, filtered list from the server, but filter it client-side:
    // filter.version = new Date().getTime();
    // refresh = true;
  }

  // ===================================================================================================================
  // Event Handlers:
  const onApplyListFilter = (newSearchText: string): void => {
    if (newSearchText !== searchText) {
      setSearchText(newSearchText)
    }
  }

  const onSelectMerchant = (merchantId: string): void => {
    api.loadMerchant(
      merchantId,
      undefined,
      apollo.getMerchantListFilter(),
      apolloClient,
      undefined,
      { updateList: true },
    ).then(() => {
      navigate(`/${nextRoute}/${merchantId}`)
    }, (error) => {
      console.error(error)
    })
  }

  const doRefresh = (event?: CustomEvent<RefresherEventDetail>): void => {
    if (refreshEvent) {
      return
    }
    pageMessages && pageMessages.clear()
    refreshEvent = event
    reloadMerchants().then(() => {
      if (refreshEvent) {
        refreshEvent.detail.complete()
        refreshEvent = undefined
      }
    }, (error) => {
      console.error(error)
    })
  }

  const onOpenUserAccount = (): void => {
    navigate(AppRoute.USER_ACCOUNT)
  }

  // ===================================================================================================================
  // Rendering:
  auth.redirectIfUnauthorized(appPageDef, location, navigate)

  return (
    <IonPage className='app-page-public select-merchant-page'>
      <NavBar
        title='Select a Brand'
        goBackUri
        userInfo={activeUser}
        isProcessing={isProcessing}
        // onContextMenu={onContextMenu}
        onOpenUserAccount={onOpenUserAccount}
        onRefresh={doRefresh}
      />
      <GiftFlowStepper
        size='large'
        className='withStandardTopMargin'
        parent='marketplace'
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <ListHeader
          searchText={searchText}
          showSearch
          onApply={onApplyListFilter}
          className='withStandardMargin'
        />
        <MerchantGrid
          searchText={searchText}
          merchants={merchants}
          onSelectMerchant={onSelectMerchant}
        />
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default SelectMerchantPage
