import type { Reward } from '../../../definitions'
import { RewardType } from '../../../enums'

const canOpenChat = (reward: Reward): boolean => {
  if (!reward || !reward.rewardType) {
    return false
  }

  if (
    [
      RewardType.CARD_BALANCE_UPDATE,
      RewardType.GIFT_CARD_UPLOAD,
      RewardType.MARKETPLACE_PURCHASE,
    ].includes(reward.rewardType)
  ) {
    return false
  }

  if (
    reward.rewardType === RewardType.INVITED_BY_USER ||
    reward.rewardType === RewardType.NEW_USER_INVITATION
  ) {
    if (!reward.forInvitation) {
      return false
    }
    return !!reward.forInvitation.toUserId || !!reward.forInvitation.toUser
  }

  if (
    (
      reward.rewardType === RewardType.ADD_CONTACT ||
      reward.rewardType === RewardType.ADDED_AS_A_CONTACT ||
      reward.rewardType === RewardType.ADD_GIFT_IDEA
    ) &&
    reward.forContact &&
    (reward.forContact.toUserId || reward.forContact.toUser)
  ) {
    return true
  }

  return (
    reward.rewardType === RewardType.PURCHASE_TRANSFER &&
    !!reward.forPurchaseTransfer &&
    (!!reward.forPurchaseTransfer.toUser || !!reward.forPurchaseTransfer.toUserId)
  )
}

export default canOpenChat
