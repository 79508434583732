import React, { useContext, useEffect, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonToast,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from '@ionic/react'
import { Update } from 'history'
import { useApolloClient } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'

// import './styles.css'
import { AppPage, AppRoute } from '../../enums'
import type { AsyncTask, OAuthUser, OAuthUserInput } from '../../lib/core/definitions'
import { CodeSignInStage, OAuthUserProvider, SignInMethod } from '../../lib/core/enums'
import { useMimbleData } from '../../contexts/MimbleDataContext/MimbleDataContext'
import AppPageFooter from '../../components/AppPageFooter/AppPageFooter'
import EnvironmentControl from '../../components/EnvironmentControl/EnvironmentControl'
import firebase from '../../services/firebase'
import type { FormRadioItem } from '../../components/FormRadioGroup/FormRadioGroup'
import FormRadioGroup from '../../components/FormRadioGroup/FormRadioGroup'
import IdentForCodeForm from './IdentForCodeForm'
import logger from '../../services/logger'
import MultiPress from '../../components/MultiPress/MultiPress'
import NavBar from '../../components/NavBar/NavBar'
import OAuthButtons from '../../components/OAuthButtons.ts/OAuthButtons'
import pageHelpers from '../../helpers/pageHelpers'
import PageMessages from '../../components/PageMessages/PageMessages'
import PageMessagesContext from '../../contexts/pageMessagesContext'
import PasswordForm from './PasswordForm'
import SubmitButton from '../../components/SubmitButton/SubmitButton'
import UnlockCodeForm from './UnlockCodeForm'

const appPageId = AppPage.SignInPage
const appPageDef = pageHelpers.appPageDefs[appPageId]

const SignInPage: React.FC = (): JSX.Element => {
  // const navigate = useNavigate()
  const locationUpdate: Update = useLocation()
  const location = locationUpdate.location || window.location
  // const isActivePage = appPageDef.routeMatches(location && location.pathname)

  // react-router@5 fix (remove when upgrading to @6)
  const history = useHistory()
  const navigate = (
    route: AppRoute | string | number,
    replace?: boolean,
    state?: any,
  ) => pageHelpers.navigate(route, history, replace, state)
  // /react-router@5 fix

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const pageMessages = useContext(PageMessagesContext)
  const { reloadActiveUser } = useMimbleData()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | undefined>()

  const [signInMethod, setSignInMethod] = useState(SignInMethod.PASSWORD)
  const [asyncTask, setAsyncTask] = useState<AsyncTask | undefined>()
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [codeSignInStage, setCodeSignInStage] = useState(CodeSignInStage.UNSET)

  const [oAuthProvider, setOAuthProvider] = useState<OAuthUserProvider | undefined>()
  const [oAuthUser, setOAuthUser] = useState<OAuthUser | undefined>()
  const [isWaitingForOAuthUser, setIsWaitingForOAuthUser] = useState(false)

  const [showEnvironmentControl, setShowEnvironmentControl] = useState(false)

  // ===================================================================================================================
  // Helpers:
  const enableFirebase = firebase.isAuthActive()

  const signInMethodOptions: FormRadioItem[] = [
    {
      value: SignInMethod.PASSWORD,
      label: 'Password',
      isChecked: signInMethod === SignInMethod.PASSWORD,
    },
    {
      value: SignInMethod.CODE,
      label: 'Send Unlock Code',
      isChecked: signInMethod === SignInMethod.CODE,
    },
  ]

  // ===================================================================================================================
  // Effect Hooks:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // useEffect(() => {
  //   // see: https://github.com/remix-run/history/issues/567
  //   window.location.reload()
  // }, [])

  // Page:
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  useIonViewWillEnter(() => {
    if (enableFirebase) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      firebase.addAuthStateChangeListener('SignInPage', onFirebaseAuthStateChange)
    }
  })

  useIonViewDidLeave(() => {
    pageMessages && pageMessages.clear()
    setToastMessage(undefined)
    setCodeSignInStage(CodeSignInStage.UNSET)
    if (enableFirebase) {
      firebase.removeAuthStateChangeListener('SignInPage')
    }
  })

  useEffect(() => {
    logger.info('SignInPage.useEffect([oAuthUser)] called.', { oAuthUser, isWaitingForOAuthUser, oAuthProvider })

    if (!oAuthUser || !isWaitingForOAuthUser || !oAuthProvider) {
      logger.info('SignInPage.onFirebaseAuthStateChange: not waiting.')
      return
    }

    logger.info('SignInPage.onFirebaseAuthStateChange called.', { oAuthUser })
    setIsWaitingForOAuthUser(false)

    const oAuthUserInput = { ...oAuthUser } as OAuthUserInput
    if (!oAuthUserInput.provider && oAuthProvider) {
      oAuthUserInput.provider = oAuthProvider
    }

    setIsSigningIn(true)
    pageHelpers.handleSignIn({
      oAuthUser: oAuthUserInput,
      apolloClient,
      reloadActiveUser,
      navigate,
    }).then(() => {
      setIsSigningIn(false)
      navigate(AppRoute.HOME, true)
    }, (error) => {
      logger.error('SignInPage: received error.', { error })
      setIsSigningIn(false)
      setToastMessage('Failed to sign you in. Please check your input and try again.')
      setShowToast(true)
    })
  }, [oAuthUser])

  // ===================================================================================================================
  // Event Handlers:
  const onChangeSignInMethod = (newSignInMethod?: SignInMethod): void => {
    const newMethod = newSignInMethod ||
      (
        signInMethod === SignInMethod.CODE
          ? SignInMethod.PASSWORD
          : SignInMethod.CODE
      )
    setSignInMethod(newMethod)
  }

  const showUiMessage = (message: string): void => {
    setToastMessage(message)
    setShowToast(true)
  }

  const onNavigate = (route: AppRoute | string, resetHistory?: boolean): void => {
    if (resetHistory) {
      navigate(route, true)
    } else {
      navigate(route)
    }
  }

  const onSignUp = (): void => {
    navigate(AppRoute.JOIN, true)
  }

  const onFirebaseAuthStateChange = (oAuthUser: OAuthUser) => {
    // console.log('SignInPage.onFirebaseAuthStateChange: called.', { oAuthUser })
    setOAuthUser(oAuthUser)
  }

  const onSignInWithFacebook = () => {
    // console.log('SignUpForm.onSignInWithFacebook: called.')
    setOAuthProvider(OAuthUserProvider.FACEBOOK)
    setIsWaitingForOAuthUser(true)
    firebase.onSignInWithFacebook()
  }

  const onSignInWithGoogle = () => {
    // console.log('SignInForm.onSignUpWithGoogle: called.')
    setOAuthProvider(OAuthUserProvider.GOOGLE)
    setIsWaitingForOAuthUser(true)
    firebase.onSignInWithGoogle()
  }

  const onSignInWithTwitter = () => {
    // console.log('SignUpForm.onSignInWithTwitter: called.')
    setOAuthProvider(OAuthUserProvider.TWITTER)
    setIsWaitingForOAuthUser(true)
    firebase.onSignInWithTwitter()
  }

  const onNextFromIdentForCodeForm = (reloadedAsyncTask: AsyncTask): void => {
    // console.log('SignUpForm.onNextFromIdentForCodeForm: called.', { asyncTask })
    setCodeSignInStage(CodeSignInStage.NOTIFICATION_SENT)
    setAsyncTask(reloadedAsyncTask)
  }

  const onBackFromUnlockCodeForm = (): void => {
    // console.log('SignUpForm.onBackFromUnlockCodeForm called.')
    setCodeSignInStage(CodeSignInStage.UNSET)
  }

  const onShowEnvironmentControl = (): void => {
    setShowEnvironmentControl(!showEnvironmentControl)
  }

  // ===================================================================================================================
  // Rendering:
  let oAuthButtons: JSX.Element | undefined
  let mimbleAccountSectionCaption: JSX.Element | undefined
  if (enableFirebase) {
    oAuthButtons = (
      <div className='section'>
        <div className='sectionCaption'>Use a linked account</div>
        <OAuthButtons
          onSignInWithFacebook={onSignInWithFacebook}
          onSignInWithGoogle={onSignInWithGoogle}
          onSignInWithTwitter={onSignInWithTwitter}
        />
      </div>
    )
    mimbleAccountSectionCaption = (
      <div className='sectionCaption'>Or your Mimble account</div>
    )
  }

  let form: JSX.Element | undefined
  if (signInMethod === SignInMethod.CODE) {
    if (codeSignInStage === CodeSignInStage.UNSET) {
      form = (
        <IdentForCodeForm
          onNext={onNextFromIdentForCodeForm}
          showUiMessage={showUiMessage}
        />
      )
    } else if (codeSignInStage === CodeSignInStage.NOTIFICATION_SENT && asyncTask) {
      form = (
        <UnlockCodeForm
          asyncTask={asyncTask}
          onBack={onBackFromUnlockCodeForm}
          onNavigate={onNavigate}
          showUiMessage={showUiMessage}
        />
      )
    }
  } else if (signInMethod === SignInMethod.PASSWORD) {
    form = (
      <PasswordForm
        onNavigate={onNavigate}
        onSwitchToCodeSignInMethod={onChangeSignInMethod}
        showUiMessage={showUiMessage}
      />
    )
  }

  let environmentInfo: JSX.Element | undefined
  if (showEnvironmentControl) {
    environmentInfo = <EnvironmentControl className='withDoubleBottomMargin' />
  }

  return (
    <IonPage className='app-page-public sign-in-page'>
      <NavBar
        title='Sign Into Mimble'
      />
      <IonContent className='g-content-with-padding'>
        <PageMessages />
        <div className='viewWrapper' style={{ maxWidth: 600 }}>
          {oAuthButtons}
          <div className='section'>
            {mimbleAccountSectionCaption}
            <div className='lightText withDoubleBottomMargin'>
              You can sign in with
              an <MultiPress useSpan onActivate={onShowEnvironmentControl}>unlock code</MultiPress> we
              send to your mobile phone,
              or your password.
            </div>
            {environmentInfo}
            <div className='withCenteredColumnContent withTripleBottomMargin'>
              <FormRadioGroup
                items={signInMethodOptions}
                onChange={onChangeSignInMethod}
              />
            </div>
            {form}
          </div>
          <div className='lightText smallText withCenteredTextAlign withStandardBottomMargin'>
            Or, if you are not a member yet:
          </div>
          <SubmitButton
            fill='outline'
            expand='block'
            onClick={onSignUp}
          >
            Create Account
          </SubmitButton>
        </div>
      </IonContent>
      <AppPageFooter
        scope={appPageDef.appTabScope}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={(): void => { setShowToast(false) }}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  )
}

export default SignInPage
