import getDiffText from './getDiffText'
import getMessageText from './getMessageText'
import getRemindersText from './getRemindersText'
import getUnusedConfigs from './getUnusedConfigs'

export default {
  getDiffText,
  getMessageText,
  getRemindersText,
  getUnusedConfigs,
}
