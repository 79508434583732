import { CdnAssetType } from '../../services/cdn/enums'
import type { PurchaseCdnUris } from '../../definitions'
import type { Merchant } from '../../lib/core/definitions'
import cdn from '../../services/cdn'

const getPurchaseCdnUrisFromMerchant = (
  merchant?: Merchant | null,
  useCloudfront = true,
): PurchaseCdnUris => {
  if (!merchant) {
    return {
      giftCardBackgroundUri: '',
      merchantLogoUri: '',
    }
  }
  return {
    giftCardBackgroundUri: merchant.mainProduct
      ? cdn.getUri(
        CdnAssetType.GIFT_CARD,
        merchant.mainProduct.imageSourceFrontSide,
        useCloudfront,
      ) || ''
      : '',
    merchantLogoUri: cdn.getUri(
      CdnAssetType.MERCHANT_LOGO,
      merchant.largeLogoImageSource,
      useCloudfront,
    ) || '',
  }
}

export default getPurchaseCdnUrisFromMerchant
