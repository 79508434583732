import React from 'react'

import type { Purchase } from '../../../lib/core/definitions'
import BalanceHistorySection from './BalanceHistorySection'
import GiftCardReceivedSection from './GiftCardReceivedSection'
import GiftCardSentSection from './GiftCardSentSection'
import ModelEventsSection from './ModelEventsSection'
import OrderInfoSection from './OrderInfoSection'
import ProductInfo from '../../../components/ProductInfo/ProductInfo'

type Props = {
  purchase: Purchase;
  onOpenContact: (
    contactId: string | null | undefined,
    contactUserId: string | null | undefined,
    chatId: string | null | undefined
  ) => void
  onOpenOrder: (orderId: string) => void
  onOpenTransaction: (transactionId: string) => void
  onCancelTransfer?: () => void;
  onEditTransfer?: () => void;
  onSendTransferNotification?: () => void;
  showUiMessage: (message: string) => void;
};

const PurchaseInfoTab: React.FC<Props> = ({
  purchase,
  onOpenContact,
  onOpenOrder,
  onOpenTransaction,
  onCancelTransfer,
  onEditTransfer,
  onSendTransferNotification,
  showUiMessage,
}: Props): JSX.Element | null => {
  if (!purchase) {
    return null
  }

  return (
    <>
      <GiftCardSentSection
        purchase={purchase}
        onCancelTransfer={onCancelTransfer}
        onEditTransfer={onEditTransfer}
        onOpenContact={onOpenContact}
        onSendTransferNotification={onSendTransferNotification}
        showUiMessage={showUiMessage}
      />
      <GiftCardReceivedSection
        purchase={purchase}
        onOpenContact={onOpenContact}
      />
      <ProductInfo product={purchase.product} />
      <BalanceHistorySection purchase={purchase} />
      <OrderInfoSection
        purchase={purchase}
        onOpenOrder={onOpenOrder}
        onOpenTransaction={onOpenTransaction}
      />
      {/* <ChangeHistoryCard purchase={purchase} /> */}
      <ModelEventsSection purchase={purchase} />
    </>
  )
}

export default PurchaseInfoTab
