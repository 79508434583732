import type { ApolloClient, MutationOptions } from '@apollo/client'

import { ErrorCode } from '../../lib/core/enums'
import type {
  ApiClientOptions,
  UpsertUserSensitiveInfoMutationData,
  UpsertUserSensitiveInfoMutationVariables,
} from '../apollo/definitions'
import type {
  ApiQueryOptions,
  UserSensitiveInfoListFilter,
  UserSensitiveInfo,
  UserSensitiveInfoInput,
} from '../../lib/core/definitions'
import apollo from '../apollo'
import loadUserSensitiveInfo from './loadUserSensitiveInfo'
import logger from '../logger'

const upsertUserSensitiveInfo = (
  userSensitiveInfoInput: UserSensitiveInfoInput,
  isInTargetStateFunc: ((userSensitiveInfo: UserSensitiveInfo) => boolean) | 'watch-updated-at' | undefined,
  listFilter: UserSensitiveInfoListFilter | undefined,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
  queryOptions?: ApiQueryOptions,
  clientOptions?: ApiClientOptions,
): Promise<UserSensitiveInfo | undefined> => (
  // console.log('api.upsertUserSensitiveInfo called.', { userSensitiveInfoInput })
  new Promise((resolve, reject) => {
    if (!apolloClient) {
      logger.error('api.upsertUserSensitiveInfo: no Apollo client available.')
      reject(new Error(ErrorCode.SYSTEM_ERROR))
      return
    }

    const queryParams: MutationOptions<UpsertUserSensitiveInfoMutationData, UpsertUserSensitiveInfoMutationVariables> = {
      mutation: apollo.mutations.upsertUserSensitiveInfo,
      variables: { userSensitiveInfo: userSensitiveInfoInput },
    }

    apolloClient.mutate<UpsertUserSensitiveInfoMutationData, UpsertUserSensitiveInfoMutationVariables>(queryParams)
      .then((resp) => {
        // console.log('api.upsertUserSensitiveInfo: mutation succeeded.', resp)
        if (
          !resp ||
          !resp.data ||
          !resp.data.upsertUserSensitiveInfo ||
          !resp.data.upsertUserSensitiveInfo.id ||
          !resp.data.upsertUserSensitiveInfo.userId
        ) {
          logger.error('api.upsertUserSensitiveInfo: mutation did not return expected data.', { resp })
          reject(new Error(ErrorCode.SYSTEM_ERROR))
          return
        }

        let activeIsInTargetStateFunc: ((userSensitiveInfo: UserSensitiveInfo) => boolean) | undefined
        if (isInTargetStateFunc === 'watch-updated-at') {
          if (!userSensitiveInfoInput.id) {
            logger.error('api.upsertUserSensitiveInfo: watch-updated-at should not be used on a new insert.')
          } else {
            const oldUpdatedAt = resp.data.upsertUserSensitiveInfo.updatedAt
            activeIsInTargetStateFunc = (userSensitiveInfo: UserSensitiveInfo) => userSensitiveInfo.updatedAt !== oldUpdatedAt
          }
        } else if (isInTargetStateFunc) {
          activeIsInTargetStateFunc = isInTargetStateFunc
        }

        if (
          !activeIsInTargetStateFunc ||
          activeIsInTargetStateFunc(resp.data.upsertUserSensitiveInfo)
        ) {
          // const queryListVariables = clientOptions && clientOptions.updateList
          //   ? apollo.getUserSensitiveInfosQueryVariables(listFilter, activeUserId as string)
          //   : undefined
          // cache.updateUserSensitiveInfo(
          //   resp.data.upsertUserSensitiveInfo,
          //   queryListVariables,
          //   activeUserId as string,
          //   apolloClient,
          // )
          resolve(resp.data.upsertUserSensitiveInfo)
          return
        }

        const userId = resp.data.upsertUserSensitiveInfo.userId as string

        setTimeout(() => {
          // console.log('api.upsertUserSensitiveInfo: calling loadUserSensitiveInfo')
          loadUserSensitiveInfo(
            userId,
            activeIsInTargetStateFunc,
            apolloClient,
            queryOptions,
            clientOptions,
          )
            .then((userSensitiveInfo) => {
              // console.log('api.upsertUserSensitiveInfo: resolving with userSensitiveInfo', userSensitiveInfo)
              resolve(userSensitiveInfo)
            }, (error) => {
              logger.error('api.upsertUserSensitiveInfo: error', { error })
              reject(error)
            })
        }, 1000)
      }, (error) => {
        logger.error('api.upsertUserSensitiveInfo: error', { error })
        reject(error)
      })
  })
)

export default upsertUserSensitiveInfo
