import type { Purchase } from '../../lib/core/definitions'
import type { PurchaseCdnUris } from '../../definitions'
import { CdnAssetType } from '../../services/cdn/enums'
import cdn from '../../services/cdn'

const getPurchaseCdnUrisFromPurchase = (
  purchase?: Purchase | null,
  useCloudfront = true,
): PurchaseCdnUris => {
  const result = {
    giftCardBackgroundUri: '',
    merchantLogoUri: '',
  }
  if (!purchase) {
    return result
  }
  if (purchase.product) {
    result.giftCardBackgroundUri = cdn.getUri(
      CdnAssetType.GIFT_CARD,
      purchase.product.imageSourceFrontSide,
      useCloudfront,
    ) || ''
    if (purchase.product.merchant && purchase.product.merchant.largeLogoImageSource) {
      result.merchantLogoUri = cdn.getUri(
        CdnAssetType.MERCHANT_LOGO,
        purchase.product.merchant.largeLogoImageSource,
        useCloudfront,
      ) || ''
    }
  }
  return result
}

export default getPurchaseCdnUrisFromPurchase
