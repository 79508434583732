const isSearchTextMatchForUsernameOrFullName = (
  searchText: string,
  username: string | null | undefined,
  fullName: string | null | undefined,
): boolean => {
  if (!searchText) {
    return false
  }
  if (searchText.length < 3) {
    const searchTextLowerCase = searchText.toLowerCase()
    const usernameLowerCase = username && username.toLowerCase()
    const fullNameLowerCase = fullName && fullName.toLowerCase()
    if (
      (usernameLowerCase && usernameLowerCase.startsWith(searchTextLowerCase)) ||
      (fullNameLowerCase && fullNameLowerCase.startsWith(searchTextLowerCase))
    ) {
      return true
    }
    const nameParts = fullNameLowerCase ? fullNameLowerCase.split(' ') : []
    return (
      nameParts.length > 0 &&
      nameParts[nameParts.length - 1].startsWith(searchTextLowerCase)
    )
  }
  const regex = new RegExp(searchText, 'i')
  return (
    (!!username && username.search(regex) !== -1) ||
    (!!fullName && fullName.search(regex) !== -1)
  )
}

export default isSearchTextMatchForUsernameOrFullName
