import type { ReactElement } from 'react'
import React from 'react'
import { Text as PdfText } from '@react-pdf/renderer'
import type { Style } from '@react-pdf/types'

type Props = {
  htmlElement: Partial<ReactElement> | null
  style?: Style
}

const Text: React.FC<Props> = (props): JSX.Element | null => {
  // console.log('###########################Text.render called.', { props })
  const { htmlElement, style } = props
  if (!htmlElement) {
    return null
  }

  let mergedStyle: Style | undefined
  if (style && htmlElement && htmlElement.props && htmlElement.props.style) {
    mergedStyle = { ...(style), ...htmlElement.props.style }
  } else {
    mergedStyle = style
  }

  const children = (htmlElement && htmlElement.props) ? htmlElement.props.children : undefined
  const text: string = children ? (children as string)[0] : '--'
  return (
    <PdfText
      style={mergedStyle}
    >
      {text}
    </PdfText>
  )
}
export default Text
