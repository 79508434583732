import type { MemberMessageTemplateListFilter } from '../../../lib/core/definitions'
import type { MemberMessageTemplatesQueryVariables } from '../definitions'
import getMemberMessageTemplateListFilter from './getMemberMessageTemplateListFilter'

const getMemberMessageTemplatesQueryVariables = (
  listFilter: MemberMessageTemplateListFilter | undefined,
): MemberMessageTemplatesQueryVariables => ({
  filter: listFilter || getMemberMessageTemplateListFilter(),
})

export default getMemberMessageTemplatesQueryVariables
