export default {
  environment: process.env.ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT,
  mimbledata: {
    hmac: {
      headerNames: {
        authType: 'x-authorization-auth-type',
        consumer: 'x-authorization-consumer',
        deviceId: 'x-authorization-device',
        fcmToken: 'x-authorization-fcm-token',
        proxy: 'x-authorization-proxy',
        signature: 'x-authorization-content-sha256',
        timestamp: 'x-authorization-timestamp',
        timezone: 'x-authorization-timezone',
        contentVar: 'x-authorization-content-var',
      },
    },
    routes: {
      graphQL: '/appdata/graphql',
      openGraphQL: '/appdata/graphql-open',
      barcode: '/appdata/barcode',
      fileDownload: '/appdata/file-download',
      fileUpload: '/appdata/file-upload',
      runCode: '/appdata/run-code',
      healthCheck: '/appdata/health-check',
      reports: '/appdata/reports',
    },
    meteredRequestRules: {
      checkEmailAvailability: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      checkPhoneNumberAvailability: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      lookupPostalCode: {
        maxCount: 20,
        timePeriodInSeconds: 3600,
      },
      resetPassword: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      resetUserPin: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      signIn: {
        maxCount: 10,
        timePeriodInSeconds: 30,
      },
      signUp: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      signUpVerifyPhone: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      updatePassword: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      updatePhoneNumber: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      updateUserPin: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      validateInviteCode: {
        maxCount: 10,
        timePeriodInSeconds: 30,
      },
      verifyUserPin: {
        maxCount: 6,
        timePeriodInSeconds: 30,
      },
      verifyEmail: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
      verifyPhoneNumber: {
        maxCount: 5,
        timePeriodInSeconds: 3600,
      },
    },
    signInWithSms: {
      expiresInMinutes: 60, // 1 hour
    },
    verifyEmail: {
      expiresInMinutes: 2880, // 2 days
    },
  },

  // -------------------------------------------------------------------------------------------------------------------
  mimblemod: {
    routes: {
      // cardReceived: '/gift',
      updatePassword: '/update-password',
      resetPassword: '/reset-password',
      verifyEmail: '/verify-email',
      confirmToken: '/confirm',
    },
    externalLinks: {
      mimbleHelpUri: 'https://www.mimble.co/support/',
      mimbleFaqUri: 'https://www.mimble.co/faq',
      mimblePrivacyStatementUri: 'https://www.mimble.co/privacy',
      mimbleTermsOfUse: 'https://www.mimble.co/terms-of-use',
    },
    auth: {
      smsCodeLength: 6,
      passwordValidation: {
        rules: ['exists', 'passwordLength', 'passwordNotCommon', 'hasNoSpaces'],
        minLength: 8,
        maxLength: 20,
        requireUpperAndLowercase: true,
        requireNumber: true,
        requireSpecialCharacter: false,
        specialCharacters: ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '-', '=', '+', '{', '}', '[', ']', '|', ';', ':', '<', '>', ',', '.', '/', '?'],
        allowReuseOld: true,
        forbidden: [
          '123456',
          '123456789',
          'qwerty',
          '12345678',
          '111111',
          '1234567890',
          '1234567',
          'password',
          '123123',
          '987654321',
          'qwertyuiop',
          'mynoob',
          '123321',
          '666666',
          '18atcskd2w',
          '7777777',
          '1q2w3e4r',
          '654321',
          '555555',
          '3rjs1la7qe',
          'google',
          '1q2w3e4r5t',
          '123qwe',
          'zxcvbnm',
          '1q2w3e',
        ],
      },
    },
  },
}
