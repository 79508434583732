import { gql } from '@apollo/client'

export default gql`
  query Q {
    orgChats {
      id
      toUser {
          id
          username
          fullName
          email
          phoneNumber
      }
    }
  }
`
