import type { PurchaseListItem } from '../../../definitions'

const getSourcePurchaseFromTargetPurchaseForTransfer = (
  toPurchaseId: string,
  purchaseItems?: PurchaseListItem[] | null,
): PurchaseListItem | undefined => {
  if (
    !toPurchaseId ||
    !Array.isArray(purchaseItems) ||
    purchaseItems.length < 1
  ) {
    return undefined
  }
  return purchaseItems.find(p => (
    !!p.metadata &&
    p.metadata.sentTransfer &&
    p.metadata.sentTransfer.toPurchaseId === toPurchaseId
  ))
}

export default getSourcePurchaseFromTargetPurchaseForTransfer
