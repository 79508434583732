import type { ChatMetadata } from '../../../definitions'

const isMetadataIdentical = (
  chatMetadata1: ChatMetadata | null | undefined,
  chatMetadata2: ChatMetadata | null | undefined,
): boolean => {
  if (!chatMetadata1 && !chatMetadata2) {
    return true
  }

  if (
    (chatMetadata1 && !chatMetadata2) ||
    (!chatMetadata1 && chatMetadata2)
  ) {
    return false
  }

  if (!chatMetadata1 || !chatMetadata2) {
    return false
  }

  if (chatMetadata1.updatedAt !== chatMetadata2.updatedAt) {
    return false
  }

  if (
    (chatMetadata1.fromUser && !chatMetadata2.fromUser) ||
    (!chatMetadata1.fromUser && chatMetadata2.fromUser) ||
    (chatMetadata1.toUser && !chatMetadata2.toUser) ||
    (!chatMetadata1.toUser && chatMetadata2.toUser)
  ) {
    return false
  }

  if (
    (chatMetadata1.fromUser && !chatMetadata2.fromUser) ||
    (!chatMetadata1.fromUser && chatMetadata2.fromUser) ||
    (chatMetadata1.toUser && !chatMetadata2.toUser) ||
    (!chatMetadata1.toUser && chatMetadata2.toUser)
  ) {
    return false
  }

  if (
    chatMetadata1.fromUser &&
    chatMetadata2.fromUser &&
    (
      chatMetadata1.fromUser.username !== chatMetadata2.fromUser.username ||
      chatMetadata1.fromUser.fullName !== chatMetadata2.fromUser.fullName ||
      chatMetadata1.fromUser.email !== chatMetadata2.fromUser.email ||
      chatMetadata1.fromUser.phoneNumber !== chatMetadata2.fromUser.phoneNumber ||
      chatMetadata1.fromUser.imageUrl !== chatMetadata2.fromUser.imageUrl ||
      chatMetadata1.fromUser.unreadMessageCount !== chatMetadata2.fromUser.unreadMessageCount
    )
  ) {
    return false
  }

  if (
    chatMetadata1.toUser &&
    chatMetadata2.toUser &&
    (
      chatMetadata1.toUser.username !== chatMetadata2.toUser.username ||
      chatMetadata1.toUser.fullName !== chatMetadata2.toUser.fullName ||
      chatMetadata1.toUser.email !== chatMetadata2.toUser.email ||
      chatMetadata1.toUser.phoneNumber !== chatMetadata2.toUser.phoneNumber ||
      chatMetadata1.toUser.imageUrl !== chatMetadata2.toUser.imageUrl ||
      chatMetadata1.toUser.unreadMessageCount !== chatMetadata2.toUser.unreadMessageCount
    )
  ) {
    return false
  }

  return true
}

export default isMetadataIdentical
