import { CdnAssetType } from '../../services/cdn/enums'
import type { PurchaseCdnUris } from '../../definitions'
import type { PurchaseListItem } from '../../lib/core/definitions'
import cdn from '../../services/cdn'

const getPurchaseCdnUrisFromPurchaseListItem = (
  purchaseListItem?: PurchaseListItem | null,
  useCloudfront = true,
): PurchaseCdnUris => {
  if (!purchaseListItem) {
    return {
      giftCardBackgroundUri: '',
      merchantLogoUri: '',
    }
  }
  return {
    giftCardBackgroundUri: cdn.getUri(
      CdnAssetType.GIFT_CARD,
      purchaseListItem.productImageSourceFrontSide,
      useCloudfront,
    ) || '',
    merchantLogoUri: cdn.getUri(
      CdnAssetType.MERCHANT_LOGO,
      purchaseListItem.merchantLargeLogoImageSource,
      useCloudfront,
    ) || '',
  }
}

export default getPurchaseCdnUrisFromPurchaseListItem
