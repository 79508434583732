import type { ApolloClient } from '@apollo/client'
import type {
  Chat,
  ContactUserListItem,
  Inbox,
  InboxItem,
  MemberMessage,
  PurchaseListItem,
  User,
  WalletListItem,
} from '../../lib/core/definitions'
import type { MimbleDataContextValue } from './definitions'

const defaultMimbleDataContextValue: MimbleDataContextValue = {
  // Active User:
  activeUser: undefined,
  activeUserWallets: undefined,
  activeUserUpdatingError: undefined,
  isLoadingActiveUser: false,
  isLoadingActiveUserWallets: false,
  isLoadingRewardConfigs: false,
  isUpdatingActiveUser: false,
  clearActiveUser: (): void => {
    console.log('MimbleDataContext.defaultContextValue.clearActiveUser called.')
  },
  getActiveUserMimbleWallet: (): WalletListItem | null => {
    console.log('MimbleDataContext.defaultContextValue.reloadActiveUser getActiveUserMimbleWallet.')
    return null
  },
  reloadActiveUser: async (): Promise<User | undefined> => {
    console.log('MimbleDataContext.defaultContextValue.reloadActiveUser called.')
    return undefined
  },
  reloadActiveUserWallets: async (): Promise<WalletListItem[] | undefined> => {
    console.log('MimbleDataContext.defaultContextValue.reloadActiveUserWallets called.')
    return undefined
  },
  updateActiveUser: async (): Promise<User | undefined> => {
    console.log('MimbleDataContext.defaultContextValue.updateActiveUser called.')
    return undefined
  },

  // Chats:
  chats: undefined,
  chatsLoadingError: undefined,
  isLoadingChats: false,
  chatsLoadingNetworkStatus: undefined,
  reloadChats: async (): Promise<Chat[] | undefined> => {
    console.log('MimbleDataContext.defaultMimbleDataContextValue.reloadChats called.')
    return undefined
  },

  // Contacts:
  contacts: undefined,
  contactsLoadingError: undefined,
  isLoadingContacts: false,
  contactsLoadingNetworkStatus: undefined,
  reloadContacts: async (): Promise<ContactUserListItem[] | undefined> => {
    console.log('ContactsContext.defaultContactsContextValue.reloadContacts called.')
    return undefined
  },

  // Inbox:
  inbox: undefined,
  inboxLoadingError: undefined,
  isLoadingInbox: false,
  inboxLoadingNetworkStatus: undefined,
  deleteInboxItem: (
    itemId: string,
    modelId: string,
    userId: string,
    apolloClient: ApolloClient<any>,
  ): InboxItem | undefined => {
    console.log('InboxContext.defaultContextValue.deleteInboxItem called.', { itemId, modelId, userId, apolloClient })
    return undefined
  },
  reloadInbox: async (): Promise<Inbox | undefined> => {
    console.log('InboxContext.defaultContextValue.reloadInbox called.')
    return undefined
  },
  clearInbox: () => {
    console.log('InboxContext.defaultContextValue.clearInbox called.')
  },
  // addModelChangeListener: (id: string, fnc: (event: InboxContextEvent, data?: InboxContextListenerData) => void) => {
  //   console.log('InboxContext.defaultContextValue.addListener called.', { id, fnc })
  // },
  // removeModelChangeListener: (id: string) => {
  //   console.log('InboxContext.defaultContextValue.removeListener called.', { id })
  // },

  // Purchases:
  purchases: undefined,
  purchasesLoadingError: undefined,
  isLoadingPurchases: false,
  purchasesLoadingNetworkStatus: undefined,
  reloadPurchases: async (): Promise<PurchaseListItem[] | undefined> => {
    console.log('MimbleDataContext.defaultMimbleDataContextValue.reloadPurchases called.')
    return undefined
  },

  // Model Changes:
  modelChangedMessage: undefined,

  // Reward Configs:
  rewardConfigs: undefined,
  getRewardConfigs: () => new Promise<undefined>(() => []),

  // Unread In-App Messages:
  unreadInAppMessages: undefined,
  unreadInAppMessagesLoadingError: undefined,
  isLoadingUnreadInAppMessages: false,
  unreadInAppMessagesLoadingNetworkStatus: undefined,
  reloadUnreadInAppMessages: async (): Promise<MemberMessage[] | undefined> => {
    console.log('MimbleDataContext.defaultMimbleDataContextValue.reloadPurchases called.')
    return undefined
  },
}

export default defaultMimbleDataContextValue
