import { OrgProductType, PurchaseType } from '../../enums'

const labelsEn = Object.freeze({
  [PurchaseType.MARKETPLACE_PRODUCT]: 'Marketplace Purchase',
  [PurchaseType.ORG_PRODUCT]: 'Mimble Product',
  [PurchaseType.TOKEN]: 'Mimble Tokens',
})

export default {
  getLabel: (productType: PurchaseType | null | undefined, language = 'en'): string => {
    if (!productType) {
      return ''
    }
    if (language === 'en') {
      return labelsEn[productType]
    }
    return ''
  },
  isValid: (type: PurchaseType): boolean => Object.values(PurchaseType).includes(type),
  getPurchaseTypeFromProductType: (productType: OrgProductType): PurchaseType => {
    switch (productType) {
      case OrgProductType.GIFT_CARD:
        return PurchaseType.MARKETPLACE_PRODUCT
      case OrgProductType.DISCOUNT_CODE:
        return PurchaseType.MARKETPLACE_PRODUCT
      case OrgProductType.PROMO_CODE:
        return PurchaseType.MARKETPLACE_PRODUCT
      case OrgProductType.MIMBLE_TOKEN:
        return PurchaseType.TOKEN
    }
    return PurchaseType.MARKETPLACE_PRODUCT
  },
}
