import { gql } from '@apollo/client'

const contactGql = gql`
  query Q($contactId: String $fromUserId: String $toUserId: String $createIfNotExist: Boolean) {
    contact (contactId: $contactId fromUserId: $fromUserId toUserId: $toUserId createIfNotExist: $createIfNotExist) {
      id
      fromUserId
      toUserId
      contactType
      nickname
      status
      favorite
      source
      metadata
      # adminNotes
      createdAt
      updatedAt
      recordStatus
      toUser {
        id
        username
        fullName
        email
        phoneNumber
        imageUrl
        birthday
        roles
        isOrganization
        createdAt
        updatedAt
        recordStatus
        wishes {
          id
          contactId
          userId
          merchantId
          title
          notes
          productUrl
          budgetAmount
          sortIndex
          recordStatus
          createdAt
          updatedAt
        }
      }
      invitation {
        fromUserId
        toUserId
        createdAt
      }
      wishes {
        id
        contactId
        userId
        merchantId
        title
        notes
        productUrl
        budgetAmount
        sortIndex
        recordStatus
        createdAt
        updatedAt
      }
      events {
        id
        contactId
        eventType
        eventDate
        name
        notes
        adminNotes
        metadata
        recordStatus
        createdAt
        updatedAt
        reminders {
          id
          eventId
          timing
          notificationMethod
          messageText
          sentAt
          sendReport
          adminNotes
          metadata
          recordStatus
          createdAt
          updatedAt
        }
      }
      favoriteOrganizations {
        id
        userId
        organizationId
        organizationName
        smallLogoImageSource
        largeLogoImageSource
        organizationIsMerchant
        organizationIsDonationTarget
        merchantInMarketplace
        metadata
      }
    }
  }`

export default contactGql
