import moment from 'moment'
import React, { useState } from 'react'
import { IonAlert, IonButton } from '@ionic/react'
import { useApolloClient } from '@apollo/client'

import './styles.css'
import type { Contact } from '../../../../../lib/core/definitions'
import { GiftingEventType } from '../../../../../lib/core/enums'
import { useMimbleData } from '../../../../../contexts/MimbleDataContext/MimbleDataContext'
import api from '../../../../../services/api'
import ContactEventIcon from '../../../../../components/ContactEventIcon/ContactEventIcon'
import coreHelpers from '../../../../../lib/core/helpers'
import EventForm from '../EventForm/EventForm'
import SubmitButton from '../../../../../components/SubmitButton/SubmitButton'

type Props = {
  contact: Contact
  eventId: string
  editingEventId: string | undefined
  setEditingEventId: (eventId: string | undefined) => void
  showUiMessage: (message: string) => void
}

const EventListItem: React.FC<Props> = (props): JSX.Element | null => {
  const {
    contact,
    eventId,
    editingEventId,
    setEditingEventId,
    showUiMessage,
  } = props
  const event = (
    contact &&
    contact.events &&
    contact.events.find(e => e.id === eventId)
  )
  const eventInfo = (
    contact &&
    contact.metadata &&
    contact.metadata.events &&
    contact.metadata.events.find(e => e.id === eventId)
  )
  const isEditing = editingEventId && eventId === editingEventId
  const eventName = event && event.name
  const eventType = event && event.eventType && coreHelpers.models.contactEvent.getLabel(event)
  const caption = eventName || eventType
  const formattedEventDate = (
    eventInfo &&
    eventInfo.parsedDate &&
    eventInfo.parsedDate.nextDate &&
    moment(eventInfo.parsedDate.nextDate).format('MMM Do')
  )

  // ===================================================================================================================
  // State:
  const apolloClient = useApolloClient()
  const { activeUser } = useMimbleData()
  const activeUserId = activeUser && activeUser.id

  const [isDeleting, setIsDeleting] = useState(false)
  const [showConfirmDeleteAlert, setShowConfirmDeleteAlert] = useState(false)

  // ===================================================================================================================
  // Event Handlers:
  const onDelete = (confirmed = false): void => {
    if (!confirmed) {
      setShowConfirmDeleteAlert(true)
      return
    }
    setIsDeleting(true)
    api.deleteContactEvent(
      eventId,
      apolloClient,
    ).then(() => {
      api.loadContact(
        contact.id,
        undefined,
        undefined,
        undefined,
        undefined,
        activeUserId as string,
        apolloClient,
      ).then(() => {
        setIsDeleting(false)
      }, (error) => {
        console.error(error)
        setIsDeleting(false)
      })
    }, (error) => {
      console.error(error)
      setIsDeleting(false)
    })
  }

  const onSetIsEditing = (state: boolean): void => {
    setEditingEventId(state && eventInfo ? eventInfo.id as string : undefined)
  }

  // ===================================================================================================================
  // Rendering:
  if (!eventInfo) {
    return null
  }

  let content: JSX.Element | undefined
  if (isEditing && event) {
    content = (
      <EventForm
        event={event}
        contact={contact}
        onClose={() => onSetIsEditing(false)}
        setEditingEventId={setEditingEventId}
        showUiMessage={showUiMessage}
      />
    )
  } else {
    let deleteButton: JSX.Element | undefined
    if (eventInfo && eventInfo.eventType !== GiftingEventType.BIRTHDAY) {
      deleteButton = (
        <SubmitButton
          fill='clear'
          size='small'
          color='medium'
          className='withSmallRightMargin'
          isProcessing={isDeleting}
          onClick={() => onDelete()}
        >
          Delete
        </SubmitButton>
      )
    }

    let reminders: JSX.Element | undefined
    if (
      event &&
      Array.isArray(event.reminders) &&
      event.reminders.length > 0
    ) {
      reminders = (
        <div className='withStandardTopMargin'>
          <div className='lightText'>Reminders</div>
          <div>{coreHelpers.models.contactEventReminder.getRemindersText(event.reminders)}</div>
        </div>
      )
    }

    let eventLabel: JSX.Element | undefined
    let contactAgeInfo: JSX.Element | undefined
    if (eventInfo.eventType === GiftingEventType.BIRTHDAY) {
      if (eventInfo.parsedDate && eventInfo.parsedDate.year > 0) {
        contactAgeInfo = (
          <div className='withSmallLeftMargin'>
            {`- age ${moment().diff(eventInfo.eventDate, 'years')}`}
          </div>
        )
      }
    }

    let notes: JSX.Element | undefined
    if (eventInfo.notes) {
      notes = (
        <div className='notes'>{eventInfo.notes}</div>
      )
    }

    content = (
      <>
        <div className={'contact-event-info'}>
          <div className='rowWithCenterAlignedItems withBoldText'>
            <ContactEventIcon
              eventType={eventInfo.eventType}
              className='withStandardRightMargin'
            />
            {caption}
          </div>
          <div className='rowWithCenterAlignedItems withSmallTopMargin'>
            {formattedEventDate}
            &nbsp;{eventLabel}
            {contactAgeInfo}
          </div>
          {notes}
        </div>
        {reminders}
        <div className='withRightTextAlign'>
          {deleteButton}
          <IonButton
            fill='outline'
            size='small'
            onClick={() => onSetIsEditing(true)}
          >
            Edit
          </IonButton>
        </div>
      </>
    )
  }

  return (
    <div className='event-list-item'>
      {content}
      <IonAlert
        isOpen={showConfirmDeleteAlert}
        onDidDismiss={(): void => { setShowConfirmDeleteAlert(false) }}
        header='Delete Event'
        subHeader=''
        message='Are you sure you want to delete this event?'
        buttons={[
          { text: 'Yes, delete', handler: (): void => { onDelete(true) } },
          { text: 'Cancel', cssClass: 'secondary', handler: (): void => { setShowConfirmDeleteAlert(false) } },
        ]}
      />
    </div>
  )
}

export default EventListItem
