import type { ApolloClient } from '@apollo/client'
import { ErrorCode } from '../../../lib/core/enums'
import type {
  FavoriteOrganizationQueryVariables,
  FavoriteOrganizationQueryData,
} from '../../apollo/definitions'
import type { FavoriteOrganizationInput } from '../../../lib/core/definitions'
import apollo from '../../apollo'
import logger from '../../logger'

const updateFavoriteOrganization = (
  favoriteOrganization: FavoriteOrganizationInput,
  activeUserId: string,
  apolloClient: ApolloClient<any>,
): void => {
  // console.log('api.cache.updateFavoriteOrganization called.', { wish })
  if (!apolloClient) {
    logger.error('api.cache.updateFavoriteOrganization: Apollo client not available.')
    throw (new Error(ErrorCode.SYSTEM_ERROR))
  }

  if (!favoriteOrganization || !favoriteOrganization.id) {
    logger.error('api.cache.updateFavoriteOrganization: no favoriteOrganization given.', { favoriteOrganization })
    return
  }

  try {
    apolloClient.writeQuery<FavoriteOrganizationQueryData, FavoriteOrganizationQueryVariables>({
      query: apollo.queries.favoriteOrganization,
      variables: { favoriteOrganizationId: favoriteOrganization.id },
      data: { favoriteOrganization },
    })
  } catch (error) {
    logger.error('api.cache.updateFavoriteOrganization: error received.', { favoriteOrganization, error })
  }
}

export default updateFavoriteOrganization
