import { gql } from '@apollo/client'

export default gql`
  query Q($storedProductId: String!) {
    storedProduct (storedProductId: $storedProductId) {
      id
      productId
      productOptionId
      purchaseId
      importId
      status
      code
      pin
      amount
      fundType
      currency
      discount
      issuedAt
      expiresAt
      receivedAt
      paidAt
      poNumber
      refUrl
      metadata
      source
      adminNotes
      recordStatus
      createdAt
      updatedAt
      updatedBy
    }
  }`
