import type { Purchase } from '../../../definitions'
import { FiatCurrency } from '../../../enums'
import formatAmount from '../../ui/formatAmount'

const getFormattedBalance = (purchase?: Purchase | null, addCurrency = true): string => {
  if (!purchase) {
    return ''
  }
  if (!purchase.balance || purchase.balance < 1) {
    return '--'
  }
  const formattedBalance = formatAmount(
    purchase.balance,
    purchase.fundType,
    purchase.currency,
  )
  if (!addCurrency) {
    return formattedBalance
  }
  if (purchase.currency === FiatCurrency.USD) {
    return `$${formattedBalance}`
  } else if (purchase.currency === FiatCurrency.EUR) {
    return `${formattedBalance} ${purchase.currency}`
  }
  return `${purchase.currency} ${formattedBalance}`
}

export default getFormattedBalance
