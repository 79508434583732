import { PurchaseType, TransactionType } from '../../enums'

const labelsEn = Object.freeze({
  [TransactionType.CASHBACK]: 'Cashback',
  [TransactionType.CASHBACK_REFUND]: 'Cashback refund',
  [TransactionType.CONTRIBUTION]: 'Contribution',
  [TransactionType.GOAL_BOOST]: 'Goal boost',
  [TransactionType.GOAL_TO_SAVINGS]: 'Goal to savings',
  [TransactionType.GOAL_WITHDRAWAL]: 'Withdrawal',
  [TransactionType.INITIAL]: 'Initial deposit',
  [TransactionType.PURCHASE]: 'Marketplace Purchase',
  [TransactionType.RECURRING]: 'Recurring Deposit',
  [TransactionType.REFUND]: 'Refund',
  [TransactionType.REFUND_TO_LINKED]: 'Refund to checking',
  [TransactionType.REWARD]: 'Reward',
  [TransactionType.SAVINGS_BOOST]: 'Savings boost',
  [TransactionType.SAVINGS_TO_GOAL]: 'Savings to goal',
  [TransactionType.SAVINGS_WITHDRAWAL]: 'Savings withdrawal',
  [TransactionType.TOKEN_TRANSFER]: 'MIT transfer',
  [TransactionType.TOKEN_DELIVERY]: 'MIT purchase',
  [TransactionType.UNKNOWN]: 'unknown',
  [TransactionType.USER_FEE]: 'Fee',
  [TransactionType.USER_PROMOTION]: 'Promotion',
})

const getLabel = (
  type: TransactionType | null | undefined,
  purchaseType?: PurchaseType | null,
  language = 'en',
): string => {
  if (!type) {
    return ''
  }
  if (language === 'en') {
    if (type === TransactionType.PURCHASE) {
      switch (purchaseType) {
        case PurchaseType.MARKETPLACE_PRODUCT:
          return 'Purchase'
        case PurchaseType.TOKEN:
          return 'MIT purchase'
      }
      return 'Purchase'
    }
    return labelsEn[type]
  }
  return ''
}

export default {
  getLabel,
  isAPurchase: (type: TransactionType): boolean => (
    type === TransactionType.PURCHASE
  ),
  isValid: (type: TransactionType | string | null | undefined): boolean => (
    !!type && Object.values(TransactionType).includes(type as TransactionType)
  ),
  isGoingToOrg: (type: TransactionType): boolean => (
    type === TransactionType.PURCHASE ||
    type === TransactionType.CASHBACK_REFUND ||
    type === TransactionType.USER_FEE
  ),
  isGoingToUser: (type: TransactionType): boolean => (
    type === TransactionType.CASHBACK ||
    type === TransactionType.CONTRIBUTION ||
    type === TransactionType.GOAL_BOOST ||
    type === TransactionType.GOAL_TO_SAVINGS ||
    type === TransactionType.GOAL_WITHDRAWAL ||
    type === TransactionType.INITIAL ||
    type === TransactionType.RECURRING ||
    type === TransactionType.REFUND ||
    type === TransactionType.REFUND_TO_LINKED ||
    type === TransactionType.REWARD ||
    type === TransactionType.SAVINGS_BOOST ||
    type === TransactionType.SAVINGS_TO_GOAL ||
    type === TransactionType.SAVINGS_WITHDRAWAL ||
    type === TransactionType.TOKEN_DELIVERY ||
    type === TransactionType.USER_PROMOTION
  ),
  isComingFromOrg: (type: TransactionType): boolean => (
    type === TransactionType.CASHBACK ||
    type === TransactionType.REFUND ||
    type === TransactionType.REWARD ||
    type === TransactionType.TOKEN_DELIVERY ||
    type === TransactionType.USER_PROMOTION
  ),
  isComingFromUser: (type: TransactionType): boolean => (
    type === TransactionType.CONTRIBUTION ||
    type === TransactionType.GOAL_BOOST ||
    type === TransactionType.GOAL_TO_SAVINGS ||
    type === TransactionType.GOAL_WITHDRAWAL ||
    type === TransactionType.INITIAL ||
    type === TransactionType.PURCHASE ||
    type === TransactionType.RECURRING ||
    type === TransactionType.CASHBACK_REFUND ||
    type === TransactionType.SAVINGS_BOOST ||
    type === TransactionType.SAVINGS_TO_GOAL ||
    type === TransactionType.SAVINGS_WITHDRAWAL ||
    type === TransactionType.USER_FEE
  ),
}
