import React from 'react'

import '../../theme/default-html-content.css'
import type { Product } from '../../lib/core/definitions'

type Props = {
  product: Product | null | undefined,
}

const TermsOfUseSection: React.FC<Props> = ({ product }): JSX.Element | null => {
  // const onVisitmerchantWebsite = (): void => {
  //   if (!product || !product.url) {
  //     return
  //   }
  //   window.open(product.url as string, '_system')
  // }

  if (!product || (!product.termsEn && !product.termsUrl)) {
    return null
  }

  const parts: JSX.Element[] = []
  if (product.termsEn) {
    if (product.termsEn.startsWith('<')) {
      parts.push(
        <div
          key='terms'
          dangerouslySetInnerHTML={{ __html: product.termsEn }}
          className='withStandardBottomMargin'
        />,
      )
    } else {
      parts.push(
        <div key='terms' className='withStandardBottomMargin'>
          {product.termsEn}
        </div>,
      )
    }
  }
  if (product.termsUrl) {
    parts.push(
      <a key='link' href={product.termsUrl} target='_blank' rel='noreferrer'>
        View terms &amp; conditions online
      </a>,
      // <div className='linkText' onClick={onOpenTermsUrl}>
      //   {product.termsUrl}
      // </div>
    )
  }

  return (
    <div className='withStandardPadding'>
      <div className='sectionCaption'>Terms And Conditions</div>
      {parts}
    </div>
  )
}

export default TermsOfUseSection
