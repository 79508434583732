import React from 'react'

import { CdnAssetType } from '../../services/cdn/enums'
import { IonButton, IonImg } from '@ionic/react'
import type { Merchant } from '../../lib/core/definitions'
import cdn from '../../services/cdn'

type Props = {
  merchant: Merchant | null | undefined,
}

const MerchantInfo: React.FC<Props> = ({ merchant }): JSX.Element | null => {
  const onVisitMerchantWebsite = (): void => {
    if (!merchant || !merchant.url) {
      return
    }
    window.open(merchant.url as string, '_system')
  }

  if (!merchant) {
    return null
  }

  const sections: JSX.Element[] = []

  if (merchant.largeLogoImageSource) {
    const merchantLogoImageUri = cdn.getUri(CdnAssetType.MERCHANT_LOGO, merchant.largeLogoImageSource) || ''
    sections.push(
      <div key='logo' className='withCenteredColumnContent withStandardTopMargin withDoubleBottomMargin'>
        <IonImg src={merchantLogoImageUri} className='with50PercentWidth' />
      </div>,
    )
  }

  if (merchant.name && !merchant.largeLogoImageSource) {
    sections.push(
      <div key='name' className='lightText withCenteredTextAlign withStandardBottomMargin'>
        {merchant.name}
      </div>,
    )
  }

  if (merchant.description) {
    if (merchant.description.startsWith('<')) {
      sections.push(
        <div
          key='desc'
          dangerouslySetInnerHTML={{ __html: merchant.description }}
          className='withDoubleBottomMargin'
        />,
      )
    } else {
      sections.push(
        <div key='desc' className='withDoubleBottomMargin'>
          {merchant.description}
        </div>,
      )
    }
  }

  if (merchant.url) {
    sections.push(
      <div key='btn' className='bottomButtonWrapper'>
        <IonButton key='btn' onClick={onVisitMerchantWebsite}>Visit Online</IonButton>
      </div>,
    )
  }

  return (
    <div className='g-with-safe-padding'>
      {sections}
    </div>
  )
}

export default MerchantInfo
