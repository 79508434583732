import React from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import { search, addOutline } from 'ionicons/icons'

import './styles.css'
import IconButton from '../../components/IconButton/IconButton'

type Props = {
  searchText?: string;
  onApply: (searchText: string) => void;
  onAddContact?: () => void;
};

const ContactsPageHeader: React.FC<Props> = ({
  searchText,
  onApply,
  onAddContact,
}): JSX.Element => {
  const onChangeSearchText = (event: any): void => {
    onApply(event.detail.value)
  }

  return (
    <>
      <div className='contacts-page-header withStandardPadding'>
        <div className='row0'>
          <div className='rowWithCenterAlignedItems'>
            <div className='searchBarWrapper' style={{ flex: 1 }}>
              <IonIcon icon={search} class='searchBarSearchIcon' />
              <IonInput
                value={searchText}
                onIonChange={onChangeSearchText}
                placeholder='Search contacts'
                className='searchBar'
                clearInput
              />
            </div>
            <div>
              <IconButton
                icon={addOutline}
                text='Add'
                layout='vertical'
                className='button'
                iconSize={36}
                onClick={onAddContact}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactsPageHeader
