import { AppFeature, RecordStatus } from '../../../enums'
import type { User } from '../../../definitions'
import hasAppFeature from './hasAppFeature'

const isTrusted = (user: Partial<User> | null | undefined): boolean => (
  !!user &&
  user.recordStatus === RecordStatus.ACTIVE &&
  !!user.trustLevel &&
  user.trustLevel > 100 &&
  hasAppFeature(user.appFeatures, AppFeature.BUY_WITH_CREDIT_CARD) &&
  !!user.maxPurchaseAmountCc &&
  user.maxPurchaseAmountCc > 0
)

export default isTrusted
